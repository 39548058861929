import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Flex,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from '@chakra-ui/react';
import LaunchdeckButton from '../../global/components/helper-components/components/LaunchdeckButton';
import { displayReactIcon } from '../helpers/dsr-display-helpers';

const DsrUnsavedChangePrompt = ({ when }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [lastLocation, setLastLocation] = useState(null);
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const cancelRef = useRef();
  const history = useHistory();
  const unblockRef = useRef(null); // Ref to store the unblock function

  useEffect(() => {
    if (when) {
      unblockRef.current = history.block((tx) => {
        setIsOpen(true);
        setLastLocation(tx); // Capture the intended location
        return false; // Prevent navigation
      });
    } else if (unblockRef.current) {
      unblockRef.current(); // Unblock if the condition changes
      unblockRef.current = null;
    }

    return () => {
      if (unblockRef.current) {
        unblockRef.current(); // Clean up by unblocking when component unmounts
      }
    };
  }, [history, when]);

  const handleConfirmNavigationClick = () => {
    setIsOpen(false);
    setShouldRedirect(true);
  };

  const handleCancelClick = () => {
    setIsOpen(false);
    setLastLocation(null);
  };

  useEffect(() => {
    if (shouldRedirect && lastLocation) {
      if (unblockRef.current) {
        unblockRef.current(); // Unblock the navigation
      }
      history.push(lastLocation.pathname); // Redirect to the intended route
    }
  }, [shouldRedirect, lastLocation, history]);

  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={handleCancelClick}
      >
        <AlertDialogOverlay>
          <AlertDialogContent minW='500px' maxW='900px' w='50%' p='1em'>
            <AlertDialogHeader ><Flex justify='flex-start' align='center' gap={2}>
            <Box color='brandPink.500'>{displayReactIcon('alert')}</Box>
            <Box>Unsaved changes</Box>
            </Flex>
          </AlertDialogHeader>
            <AlertDialogBody fontSize='0.875em'>We noticed there are unsaved changes. Leave & discard the changes?</AlertDialogBody>
            <AlertDialogFooter gap={6} mt='2em'>
              <LaunchdeckButton
                ref={cancelRef}
                onClick={handleCancelClick}
                color="brandDark.500"
                bg="gray.400"
                fontSize='0.8em'
                // hover={{ bg: 'gray.700' }}
              >
                Go back
              </LaunchdeckButton>
              <LaunchdeckButton
                bg="brandPink.500"
                onClick={handleConfirmNavigationClick}
               fontSize='0.8em'
                color="white"
                // hover={{ bg: 'red.700' }}
              >
                Leave
              </LaunchdeckButton>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default DsrUnsavedChangePrompt;
