import * as types from '../actions/auth-action-types';
import { axiosInstance } from '../../../config/app-specific-config/axiosConfig';
import auth from '../../../config/app-specific-config/auth';


// Delete Account
function accessAuthenticationRequest() {
	return {
		type: types.ACCESS_PAGE_AUTHENTICATION_REQUEST
	};
}

function accessAuthenticationSuccess(response) {
	return {
		type: types.ACCESS_PAGE_AUTHENTICATION_SUCCESS,
		payload: response.data
	};
}

function accessAuthenticationFailure(signupFailureResponse) {
	return {
		type: types.ACCESS_PAGE_AUTHENTICATION_FAILURE,
		payload: signupFailureResponse
	};
}

export function accessAuthentication(accessAuthPayload) {
	return async dispatch => {
		dispatch(accessAuthenticationRequest());
		try {
			const response = await axiosInstance.post('/dsr/access-auth', accessAuthPayload);  

			if (response.data.data.userIdToken) {
				localStorage.setItem('USER_ID_TOKEN', response.data.data.userIdToken);
			}

			if (response.data.data.userType) {
				localStorage.setItem('USER_CATG', response.data.data.userType); 
			}

			if (response.data.data.token) {
				auth.storeToken(response.data.data.token)
				// if (accessAuthPayload.authProcess === "set-password-edit-profile" && response.data.data.nextProcess === "redirect-user-to-dsr") {
				// 	redirectToNewPage(`/dsr/${dsrId}`)
				// }
			}

			dispatch(accessAuthenticationSuccess(response));
		} catch (error) {
				const deleteAccountFailureResponse = { 
					deleteAccountError: true,
					type: 'Unexpected Error',
					message: 'There was an unexpected error. Please contact us and let us get this sorted'
				}

				dispatch(accessAuthenticationFailure(deleteAccountFailureResponse));
		}
	}
}


export const accessAuthenticationReset = () => {
	return {
		type: types.ACCESS_PAGE_AUTHENTICATION_RESET
	};
};

export function resetAccessAuthentication() {

	return async (dispatch) => {
	
		try {
			dispatch(accessAuthenticationReset());

		} catch (error) {
			dispatch(accessAuthenticationFailure(error));
		}
	};
}