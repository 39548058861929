import auth from '../../../config/app-specific-config/auth';
import * as types from './customer-support-actions-types';
import { axiosInstance } from '../../../config/app-specific-config/axiosConfig';


// Launchdeck Customer Support Requests
export const sendCustomerSupportMessageSuccess = (response) => {
	return {
		type: types.SEND_CUSTOMER_SUPPORT_MESSAGE_SUCCESS,
		payload: response.data
	};
};

export const sendCustomerSupportMessageFailure = (error) => {
	return {
		type: types.SEND_CUSTOMER_SUPPORT_MESSAGE_FAILURE,
		error
	};
};

export const sendCustomerSupportMessageRequest = () => {
	return {
		type: types.SEND_CUSTOMER_SUPPORT_MESSAGE_REQUEST
	};
};

export function sendCustomerSupportMessage(submittedMessagePayload) {
	
	return async (dispatch) => {
		dispatch(sendCustomerSupportMessageRequest());
		try {
			const url = '/support/new-customer-query';
			const headers = await auth.getAccessToken();
			const response = await axiosInstance.post(url, {submittedMessagePayload}, { headers });

			dispatch(sendCustomerSupportMessageSuccess(response));
		} catch (error) {
			dispatch(sendCustomerSupportMessageFailure(error));
		}
	};
}

export const sendCustomerSupportMessageReset = () => {
	return {
		type: types.SEND_CUSTOMER_SUPPORT_MESSAGE_RESET
	};
};

export function resetSendCustomerSupportMessageOutcome() {

	return async (dispatch) => {

		try {
			dispatch(sendCustomerSupportMessageReset());

		} catch (error) {
			dispatch(sendCustomerSupportMessageFailure(error));
		}
	};
}


