import { Tooltip } from '@chakra-ui/react'
import React from 'react'

export const withTooltipWrapper = (children) => (
  <Tooltip
    label="Recording is only supported in Chrome. Others are ‘coming soon’"
    aria-label="button-tooltip"
    fontSize="4xl"
  >
    <div>
      {children}
    </div>
  </Tooltip>
)
