import React, { memo, useRef, useState, useEffect } from 'react'

import { Box, Flex, Tooltip } from '@chakra-ui/react'
import '../styles/dsr-display.scss'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { displayReactIcon } from '../helpers/dsr-display-helpers';

import { displayBrandingLabel } from '../../global/helpers/global-helpers'
import { RiQuestionFill } from 'react-icons/ri'

function DsrProspectSideMenu({
  pageArray,
  selectedDisplaySubpage,
  selectedDisplayPageId,
  handleSelectSubpage,
  headerMenuHeight,
  isDsrAdmin,
  closeMenuDrawer,
  isMobileView,
  userPrivileges
}) {

  const initRef = useRef(false);
  const [showBranding, setShowBranding] = useState(false)

  useEffect(() => {
    if (userPrivileges && !initRef.current) {
      const { displayBrand } = userPrivileges || {}
      if (displayBrand === true || displayBrand === false) {
        setShowBranding(displayBrand)
        initRef.current = true
      }
    }
  }, [userPrivileges]);


  function renderSubPageTabs(category) {
    if (!pageArray) {
      return null
    }

    let pagesToRender = [];

    if (category === 'essential-pages') {
      pagesToRender = pageArray?.filter(tabs => tabs.pageType !== 'customised-page')
    } else if (category === 'custom-pages') {
      pagesToRender = pageArray?.filter(tabs => tabs.pageType === 'customised-page')
    }

    if (pagesToRender) {
      const pageTabs = pagesToRender?.map((tabs, tabIndex) => {
        const { pageId, pageTitle, pageType, displayPage, lockedPage } = tabs || {};

        if (displayPage === true) {
          if (pageType === 'customised-page') {
            if (lockedPage) {
              return (
                <Flex key={`dsr_page_tab_${tabIndex}-${pageId}`} justify='flex-start' align='center' px='1.1em' py='0.875em'
                  className="locked-subpage"
                >
                  <Flex justify='flex-start' align='center'>
                    <Box>{displayReactIcon("lock", '1.5em', displayPage)}</Box>
                    <Box whiteSpace='pre-wrap' minW='11em'>{pageTitle}</Box>
                  </Flex>
                </Flex>
              )
            } else {
            return (
              <Flex key={`dsr_page_tab_${tabIndex}-${pageId}`} justify='flex-start' align='center' px='1.1em' py='0.875em'
                className={(selectedDisplayPageId === `${pageId}`) ? "chosen-subpage-option" : "unchosen-subpage-option"}
                onClick={() => {
                  handleSelectSubpage(pageId, "")
                  closeMenuDrawer && closeMenuDrawer()
                }}
              >
                {displayReactIcon(pageType, '1.5em')}
                <Box whiteSpace='pre-wrap' minW='11em'>{pageTitle}</Box>
              </Flex>
            )
          }
          } else {
            return (
              <Flex key={`dsr_page_tab_${tabIndex}-${pageId}`} justify='flex-start' align='center' px='1.1em' py='0.875em'
                className={(selectedDisplayPageId === `${pageId}`) ? "chosen-subpage-option" : "unchosen-subpage-option"}
                onClick={() => {
                  handleSelectSubpage(pageId, "")
                  closeMenuDrawer && closeMenuDrawer()
                }}
              >
                {displayReactIcon(pageType, '1.5em')}
                <Box>{pageTitle}</Box>
              </Flex>
            )
          }
        } else {
          return (
            <Flex
              justify='flex-start' align='center' px='1.1em' py='0.875em'
              className="unchosen-subpage-option"
              w='100%'
            />
          )
        }
      }
      )

      return pageTabs;

    }

    else return null


  }

  const categoryLabel = (labelText, toolTipText) => {
    return (
      <Flex mt='1em' justify='flex-start' align='center' color='gray.500' cursor='default' className='unchosen-subpage-option'>
        <Box fontSize='0.75em' fontWeight='600' whiteSpace='pre'>{labelText}</Box>
        <Tooltip fontSize='0.8em' label={toolTipText} placement="auto-start">
          <Box ml='0.5em'>
            <Box as={RiQuestionFill} boxSize="1em" py='3%' />
          </Box>
        </Tooltip>
      </Flex>
    )
  }

  const renderAdminPageTabs = () => {
    return (
      <Box pb='1.5em' pt='5em'>
        {categoryLabel('ADMINISTRATOR PAGES', 'Info accessible only by Room Administrators')}

        {/* TODO: Analytics for Prospect DSRs */}
        {/* <Flex justify='flex-start' align='center' px='1.1em' py='0.875em'
          className={(selectedDisplaySubpage === "insights-subpage") ? "chosen-subpage-option" : "unchosen-subpage-option"}
          onClick={() => {
            handleSelectSubpage("", 'insights-subpage')
            closeMenuDrawer && closeMenuDrawer()
          }}
        >
          <Box fill="gray.300">{displayReactIcon('worktool', '1.5em')}</Box>
          <Box lineHeight='1.5em' ml='1em' color='gray.500'> Insights</Box>
        </Flex> */}

        <Flex justify='flex-start' align='center' px='1.1em' py='1em'
          className={(selectedDisplaySubpage === "prospector-team-notes-subpage") ? "chosen-subpage-option" : "unchosen-subpage-option"}
          onClick={() => {
            handleSelectSubpage("", 'prospector-team-notes-subpage')
            closeMenuDrawer && closeMenuDrawer()
          }}
        >
          {displayReactIcon('worktool', '1.5em')}
          <Box lineHeight='1.5em' ml='1em'> Team Notes</Box>

        </Flex>

        <Flex justify='flex-start' align='center' px='1.1em' py='0.875em'
          className={(selectedDisplaySubpage === "room-settings-subpage") ? "chosen-subpage-option" : "unchosen-subpage-option"}
          onClick={() => {
            handleSelectSubpage("", 'prospect-room-settings-subpage')
            closeMenuDrawer && closeMenuDrawer()
          }}
        >
          {displayReactIcon('worktool', '1.5em')}
          <Box lineHeight='1.5em' ml='1em'> Settings </Box>
        </Flex>

      </Box>
    )
  }

  return (
    <Flex
      flexWrap='wrap'
      justify='flex-start'
      pt='2em'
      maxWidth='280px'
      height={`calc(100vh - ${headerMenuHeight})`}
      overflowX="hidden"
      overflowY="auto"
    >
      <Flex direction='column' justify='space-between' align='flex-start' maxWidth='fit-content'>
        <Box>
          {/* {renderSubPageTabs('essential-pages')} */}
          {renderSubPageTabs('custom-pages')}
        </Box>

        <Flex justify='center' w='100%' position='absolute' bottom={isDsrAdmin ? '15%' : '2.5em'}>
          {isDsrAdmin ?
            renderAdminPageTabs()
            : showBranding &&
            displayBrandingLabel(isMobileView ? "mobile" : "web")
          }
        </Flex>
      </Flex>

    </Flex>
  )
}

const mapStateToProps = (state) => {
  return {
    selectedDisplayPageId: state.dsrPage.selectedDisplayPageId,
    selectedDisplaySubpage: state.dsrPage.selectedDisplaySubpage,
    isDsrAdmin: state.dsrAdmin.isDsrAdmin,
    userPrivileges: state.userPrivileges.results,
    isMobileView: state.mobileViewMode.isMobileView,
  }
}


export default memo(withRouter(connect(mapStateToProps, null)(DsrProspectSideMenu)))

