import auth from '../../../config/app-specific-config/auth';
import * as types from './payments-action-types';
import { axiosInstance } from '../../../config/app-specific-config/axiosConfig';


//Create a new Stripe session for Subscription
function createStripeCheckoutSessionRequest() {
	return {
		type: types.CREATE_CHECKOUT_SESSION_REQUEST
	};
}

function createStripeCheckoutSessionSuccess(response) {
	return {
		type: types.CREATE_CHECKOUT_SESSION_SUCCESS,
		payload: response.data
	};
}

function createStripeCheckoutSessionFailure(error) {
	return {
		type: types.CREATE_CHECKOUT_SESSION_FAILURE,
		payload: error
	};
}

export function createStripeCheckoutSession(sourceRoute) {
	return async (dispatch) => {
		dispatch(createStripeCheckoutSessionRequest());
		try {
			const url = `/payments/checkout-session`;
			const headers = await auth.getAccessToken();

			const response = await axiosInstance.post(
				url,
				{ sourceRoute }, 
				{ headers }
			);

			if (response) {
				dispatch(createStripeCheckoutSessionSuccess(response));
				return response.data.data.checkoutSession;
			}
			

		} catch (error) {
			dispatch(createStripeCheckoutSessionFailure(error.message));
		}
	};
}


//Create a new Stripe Customer Portal Session
function createStripeCustomerPortalSessionRequest() {
	return {
		type: types.CREATE_CUSTOMER_PORTAL_REQUEST
	};
}

function createStripeCustomerPortalSessionSuccess(response) {
	return {
		type: types.CREATE_CUSTOMER_PORTAL_SUCCESS,
		payload: response.data
	};
}

function createStripeCustomerPortalSessionFailure(error) {
	return {
		type: types.CREATE_CUSTOMER_PORTAL_FAILURE,
		payload: error
	};
}

export function createStripeCustomerPortalSession() {
	return async (dispatch) => {
		dispatch(createStripeCustomerPortalSessionRequest());
		try {
			const url = `/payments/customer-portal`; 

			const headers = await auth.getAccessToken();
			const response = await axiosInstance.post(
				url,
				{ }, 
				{ headers }
			);

			dispatch(createStripeCustomerPortalSessionSuccess(response));

			return response.data.data.customerPortalSession;

		} catch (error) {
			dispatch(createStripeCustomerPortalSessionFailure(error.message));
		}
	};
}