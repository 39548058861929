import React from 'react';

import { Box, Flex } from '@chakra-ui/react';
import WarningTextMessage from '../../../global/components/helper-components/components/WarningTextMessage';
import LaunchdeckButton from '../../../global/components/helper-components/components/LaunchdeckButton';
import FormTextInput from '../../../forms/TextInputField/components/FormTextInput';

export default function PublicDsrJoinRoomForm({ handleCheckUserEmail, userEmailInput, onChangeHandler, showEmailInputError }) {


	return (
		<React.Fragment>
			<Flex fontSize='1em' color='brandDark.500' fontWeight='600' mt='1em' textAlign='center'>
				Join the Room
			</Flex>


			<Flex fontSize='0.875em' mt='0.5em' color='gray.500' textAlign='center'>
				Ask questions. Connect with Room members. Get insights.
			</Flex>

			<Box w="100%" mt='3em' maxW='27em' align="center">
				<Box px='1em' mt='1em'>

					<Box mt='1em' w='100%' >
						<FormTextInput
							label={''}
							name={'email'}
							placeholder={'me@email.com'}
							onChange={onChangeHandler}
							defaultValue={userEmailInput}
							mb='0.2em'
							textAlign='center'
						/>
						<WarningTextMessage errorLogic={showEmailInputError} message='Please provide a valid email' />
					</Box>
				</Box>
			</Box>

			<Flex mt='1em' py='0.625em'>
					<Flex justify='flex-end' w='100%' >
						<LaunchdeckButton bg='brand.500' px='3em' color='gray.50' w='100%' label='Join Room' onClick={() => handleCheckUserEmail()} />
					</Flex>
				</Flex>
		</React.Fragment>

	);
};


