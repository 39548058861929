import { RESET_DSR_PAGES, SET_DSR_PAGES } from '../../actions/dsr-actions-types'

const INITIAL_STATE_DSR_PAGES = { pages: '', }

export function dsrPagesReducer(state = INITIAL_STATE_DSR_PAGES, action) {
    switch (action.type) {
        case SET_DSR_PAGES:
            return { pages: action.payload };
        case RESET_DSR_PAGES:
            return INITIAL_STATE_DSR_PAGES
        default:
            return state;
    }
}