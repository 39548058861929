import React, { useState, useEffect, useRef } from 'react';
import { Flex, Box, Stack, Text, Grid } from '@chakra-ui/react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import ProfileFormTextInput from '../../forms/TextInputField/components/ProfileFormTextInput';

import { editUserProfile, uploadProfilePicture } from '../actions/users-profile-actions';
import UserProfileImageDisplay from '../../global/components/helper-components/components/UserProfileImageDisplay';
import UserProfileImageUploadButton from '../../global/components/helper-components/components/UserProfileImageUploadButton';
import { resizeImage } from '../../global/helpers/global-helpers';
import LaunchdeckButton from '../../global/components/helper-components/components/LaunchdeckButton';


function UserEditPersonalInfoForm(props) {

  const {
    userAccountProfile,
    handleCloseModal,
    // Action
    editUserProfile,
    uploadProfilePicture,
    // Redux States
    uploadProfilePictureResults,
    uploadProfilePictureInProgress,
    userProfilePicture
  } = props || {};
  const initRef = useRef(false);

  const [localState, setLocalState] = useState({
    isEditMode: false,
    isSetupMode: false,
    isLoading: true,
    displayValidationError: false,
    imageIsLoading: true,
    firstName: "",
    lastName: "",
    email: "",
    phoneCountryCode: "",
    phoneAreaCode: "",
    phoneNumber: "",
    meetingLink: ""
  })
  const [profilePicDisplay, setProfilePicDisplay] = useState(userProfilePicture)

  useEffect(() => {
    if (userAccountProfile && !initRef.current) {
      const {
        firstName,
        lastName,
        email,
        meetingLink,
        phoneCountryCode,
        phoneAreaCode,
        phoneNumber
      } = userAccountProfile || {}

      setLocalState(prevState => ({
        ...prevState,
        ...firstName && { firstName },
        ...lastName && { lastName },
        ...email && { email },
        ...meetingLink && { meetingLink },
        ...phoneCountryCode && { phoneCountryCode },
        ...phoneAreaCode && { phoneAreaCode },
        ...phoneNumber && { phoneNumber }
      }));

      initRef.current = true
    }
  }, [userAccountProfile])


  function handleChange(event) {
    const value = event.target.value;

    setLocalState(prevState => ({
      ...prevState,
      [event.target.name]: value
    }));
  }

  const handleSubmitEditProfile = async () => {
    const { firstName, lastName, email, phoneCountryCode, phoneAreaCode, phoneNumber, meetingLink } = localState;
    const allRequiredFieldsFilled = (firstName && lastName && email) ? true : false;

    if (allRequiredFieldsFilled === true) {
      try {
        setLocalState(prevState => ({
          ...prevState,
          displayValidationError: false, 
        }));

        const updatedUserParams = {
          firstName: firstName ? firstName.trim() : null,
          lastName: lastName ? lastName.trim() : null,
          ...phoneCountryCode && { phoneCountryCode },
          ...phoneAreaCode && { phoneAreaCode },
          ...phoneNumber && { phoneNumber },
          ...meetingLink && { meetingLink }
          // Do not allow change of email here
        }

        await editUserProfile(updatedUserParams);

        handleCloseModal()
      } catch (error) {
        console.log('Update User Profile Error', error);
      }

    } else {
      setLocalState(prevState => ({
        ...prevState,
        displayValidationError: true
      }));
    }
  }


  const onChangeUploadPicture = async (event) => {
    let providedImageFile = event.target.files[0];
    let { name, size } = providedImageFile || {}
    let resizedFile = '';
    let imageFileSizeLimit = 20000;
    const resizeNeeded = size > imageFileSizeLimit ? true : false;

    if (resizeNeeded) {
      let resizedImage = await resizeImage(event.target.files[0]);
      let newFileName = '100x100-' + name;
      resizedFile = new File([resizedImage], newFileName, { type: "image/png" })
    }

    let uploadedPicture = resizeNeeded ? resizedFile : providedImageFile;

    setLocalState(prevState => ({
      ...prevState,
      uploadedPicture
    }));
  };


  useEffect(() => {
    if (localState.uploadedPicture) {
      uploadProfilePicture(localState.uploadedPicture);
    }
  }, [localState.uploadedPicture, uploadProfilePicture])


  useEffect(() => {
    if (Object.keys(uploadProfilePictureResults).length !== 0) {
      setLocalState( prevState =>
        ({
          ...prevState,
          uploadedPicture: ""
        }))

      setProfilePicDisplay(userProfilePicture)
    }

  }, [uploadProfilePictureResults, userProfilePicture])

  const { fullName, firstName, lastName, email,
    phoneCountryCode, phoneAreaCode, phoneNumber, meetingLink,
    displayValidationError } = localState || {};


  return (
    <React.Fragment>
      <Stack w='100%' mb='2em' px='2em' py='1em' overflowY='auto' maxHeight='75vh'>
        <Flex w='100%' justify='center' align='center' mb='2.5em'>
          <Box position='relative'>
            <UserProfileImageDisplay
              name={fullName}
              size='5em'
              profilePicture={profilePicDisplay}
              uploadProfilePictureInProgress={uploadProfilePictureInProgress}

            />

            <UserProfileImageUploadButton onChangeUploadPicture={onChangeUploadPicture} uploadProfilePictureInProgress={uploadProfilePictureInProgress} />

          </Box>
        </Flex>

        <Flex direction='column' w='100%' pb='0.7em'>
          <ProfileFormTextInput name="email" label="Email Address" htmlFor="email" variant="filled" defaultValue={email} isDisabled={true} />
        </Flex>

        <Grid w='100%' pb='0.7em' templateColumns="1fr 1fr" columnGap="0.5em">
          <Flex direction='column' w='100%'>
            <ProfileFormTextInput name="firstName" label="First Name" htmlFor="firstName" variant="outline" defaultValue={firstName} value={firstName} onChange={handleChange} />
            {displayValidationError && !firstName ? <Text fontSize='0.7em' color='red.500' className="scale-in-hor-left">Missing Required Field</Text> : <Box fontSize='0.7em'>&nbsp;</Box>}
          </Flex>
          <Flex direction='column' w='100%'>
            <ProfileFormTextInput name="lastName" label="Last Name(s)" htmlFor="lastName" variant="outline" defaultValue={lastName} value={lastName} onChange={handleChange} />
            {displayValidationError && !lastName ? <Text fontSize='0.7em' color='red.500' className="scale-in-hor-left">Missing Required Field</Text> : <Box fontSize='0.7em'>&nbsp;</Box>}
          </Flex>
        </Grid>


        <Grid w='100%' templateColumns="1fr 1fr 6fr" columnGap="0.5em">
          <ProfileFormTextInput name="phoneCountryCode" label="Country" htmlFor="phoneCountryCode" variant="outline" defaultValue={phoneCountryCode} value={phoneCountryCode} onChange={handleChange} />
          <ProfileFormTextInput name="phoneAreaCode" label="Area" htmlFor="phoneAreaCode" variant="outline" defaultValue={phoneAreaCode} value={phoneAreaCode} onChange={handleChange} />
          <ProfileFormTextInput name="phoneNumber" label="Phone Number" htmlFor="phoneNumber" variant="outline" defaultValue={phoneNumber} value={phoneNumber} onChange={handleChange} />
        </Grid>
        <Box fontSize='0.75em' mt='0.5em' pl='0.5em' color='gray.500' fontStyle='italic'>Provide your business contact number if you'd want your clients to call you directly </Box>
        
        <Flex direction='column' w='100%'>
          <ProfileFormTextInput name="meetingLink" label="Book-a-meeting link" htmlFor="meetingLink" variant="outline" defaultValue={meetingLink} value={meetingLink} onChange={handleChange} />
          <Box fontSize='0.75em' mt='0.5em' pl='0.5em' color='gray.500' fontStyle='italic'>Your private Calendly or Google meet link for your clients to contact you </Box>
        </Flex>
      </Stack>

      <Flex w='100%' p='2em'>
        <Flex justify='center' w='100%'>
          <LaunchdeckButton bg='brand.500' color='gray.50' w='100%' label='Save Changes' onClick={() => handleSubmitEditProfile()} />
        </Flex>
      </Flex>


    </React.Fragment>

  );

}


function mapStateToProps(state) {
  return {
    userAccountProfile: state.userProfile.accountUserProfile,
    uploadProfilePictureResults: state.uploadProfilePicture.results,
    uploadProfilePictureInProgress: state.uploadProfilePicture.isLoading,
    userProfilePicture: state.setUserProfilePicture.userProfilePicture
  };
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
      editUserProfile,
      uploadProfilePicture
		},
		dispatch,
	)

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserEditPersonalInfoForm));