import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';

import { updateOrgUsers, updateOrgUserReset } from '../../../profile/actions/admin-user-actions'

import WarningTextMessage from '../../../global/components/helper-components/components/WarningTextMessage';
import ProfileFormTextInput from '../../../forms/TextInputField/components/ProfileFormTextInput';

import { Box, Flex, useToast } from '@chakra-ui/react';
import { BsCheckCircle, BsCircle } from 'react-icons/bs';
import ClaverModalButtonBar from '../../../global/components/helper-components/components/ClaverModalButtonBar';
import UserProfileImageDisplay from '../../../global/components/helper-components/components/UserProfileImageDisplay';
import { triggerLaunchdeckToast } from '../../../global/helpers/global-helpers';


function EditTeamMemberModule(props) {
  const { orgUser, role, orgIdToken, isLastAdmin, handleCloseModal, manageAdminOrgUsersOutcome, isMobileView } = props || {};

  const { userId, memberFirstName, memberLastName, memberName, userOrgTitle, memberProfilePicSrc } = orgUser || {};
  const applyLastAdminRestriction = (isLastAdmin === true && role === 'admin-user') ? true : false;

  const toast = useToast();
  const [showNoRoleError, setShowNoRoleError] = useState(false);
  const [removingInProgress, setRemovingInProgress] = useState(false);
  const [editingInProgress, setEditingInProgress] = useState(false);

  const [showRemoveMemberModal, setShowRemoveMemberModal] = useState(false)

  const [chosenRole, setChosenRole] = useState(role)
  const [userOrgTitleDisplay, setUserOrgTitle] = useState(userOrgTitle)
  const [memberFirstNameDisplay, setUserFirstName] = useState(memberFirstName)
  const [memberLastNameDisplay, setUserLastName] = useState(memberLastName)

  // Input validation Errors
  const [showMissingTitleError, setShowMissingTitleError] = useState(false)
  const [showMissingFirstNameError, setShowMissingFirstNameError] = useState(false)
  const [showMissingLastNameError, setShowMissingLastNameError] = useState(false)

  // UX & State Management
  useEffect(() => {
    if (manageAdminOrgUsersOutcome) {
      const { updatedSuccessfully, errorMessage } = manageAdminOrgUsersOutcome.data || {};

      const closeModalAndReset = () => {
        setEditingInProgress(false)
        setRemovingInProgress(false)
        handleCloseModal()
        props.action.updateOrgUserReset()
      }

      if (manageAdminOrgUsersOutcome.data === undefined && manageAdminOrgUsersOutcome.hasErrors) {
        toast(
          triggerLaunchdeckToast({
            useCase: "show-error-state",
            bannerText: "Error",
            label: manageAdminOrgUsersOutcome.errorMessage,
            isMobileView
          })
        )

        closeModalAndReset()
      }

      if (updatedSuccessfully === true) {
        toast(
					triggerLaunchdeckToast({
						useCase: "show-success-state",
						// bannerText: "",
						label: "Team member successfully updated",
						isMobileView
					})
				)

        closeModalAndReset()
      }
      else if (updatedSuccessfully === false && errorMessage) {
        toast(
          triggerLaunchdeckToast({
            useCase: "show-error-state",
            bannerText: "Error",
            label: errorMessage,
            isMobileView
          })
        )

        closeModalAndReset()
      }
    }

  }, [manageAdminOrgUsersOutcome, props.action, handleCloseModal, toast]);



  // Component functionality
  const handleChange = (event) => {
    const value = event.target.value;
    const variableName = event.target.name;
    switch (variableName) {
      case ('userOrgTitle'): {
        setUserOrgTitle(value)
        break;
      }
      case ('memberFirstName'): {
        setUserFirstName(value)
        break;
      }
      case ('memberLastName'): {
        setUserLastName(value)
        break;
      }
      default: {
        console.log('There is no variable with this name', variableName)
        break;
      }
    }
  }

  const handleChooseRole = (role) => {
    // Scenario: When user wants to remove his choice 
    if (chosenRole === role) {
      setChosenRole('')
      setShowNoRoleError(false)
    } else {
      // Scenario: When user picks a role for the first time
      // Scenario: Also when user switches from one role to the other role
      setChosenRole(role)
      setShowNoRoleError(false)
    }
  }

  const handleEditTeamMember = () => {
    setEditingInProgress(true)
    const roleChanged = (role === chosenRole) ? false : true;

    if (!userOrgTitleDisplay) {
      setShowMissingTitleError(true)
    }

    if (!memberFirstNameDisplay) {
      setShowMissingFirstNameError(true)
    }

    if (!memberLastNameDisplay) {
      setShowMissingLastNameError(true)
    }

    if (userOrgTitleDisplay && memberFirstNameDisplay && memberLastNameDisplay) {

      let newOrgUserProperties = {
        userId,
        updateEvent: 'update-org-user',
        currentRole: role,
        ...(memberFirstNameDisplay !== memberFirstName) && { firstName: memberFirstNameDisplay.trim() },
        ...(memberLastNameDisplay !== memberLastName) && { lastName: memberLastNameDisplay.trim() },
        ...(userOrgTitleDisplay !== userOrgTitle) && { userOrgTitle: userOrgTitleDisplay.trim() },
        ...(roleChanged === true) && { newOrgRole: chosenRole }
      }

      props.action.updateOrgUsers(newOrgUserProperties, orgIdToken)

    }
  }

  const showRemoveConfirmModal = () => {
    setShowRemoveMemberModal(true)
  }

  const handleRemoveMember = () => {
    // This function is changes the status of a member from 'active' or 'pending', to 'deactivated'
    setRemovingInProgress(true)

    let newOrgUserProperties = {
      userId,
      updateEvent: 'deactivate-org-user'
    }

    props.action.updateOrgUsers(newOrgUserProperties, orgIdToken)
  }



  if (!showRemoveMemberModal) {
    return (
      <React.Fragment>
        <Box bg='gray.50'>
          <Flex direction='row' py='1rem' px='2em' justify='space-between' alignItems='center'>
            <UserProfileImageDisplay name={memberName} size='2em' profilePicture={memberProfilePicSrc} />
            <Box flex='70%' pl='3%'>
              <Box lineHeight='1.375em' fontWeight='500' color='gray.700'>{memberName}</Box>
              <Box lineHeight='1.25em' fontSize='0.875em' fontWeight='400' color='gray.500'>{userOrgTitle}</Box>
            </Box>

            <Flex flex='20%' justify='flex-end'>
              {(applyLastAdminRestriction === false) &&
                <Box color='pink.500' cursor='pointer' fontSize='0.75em' fontWeight='400' onClick={applyLastAdminRestriction ? null : () => showRemoveConfirmModal()}>Deactivate</Box>
              }
            </Flex>

          </Flex>
        </Box>
        <Box px='2em' mb='2em'>
          <Box>
            {(applyLastAdminRestriction === true) ?
              <Box rounded='0.25em' bg='pink.50' mt='1.5em' p='1em' fontSize='0.8em'>
                You are the sole Administrator to help manage team users and their settings
              </Box>
              :
              <React.Fragment>
                <Box lineHeight='1.375em' mt='1.5em' fontWeight='500' color='gray.700'>
                  Role
                </Box>

                <Flex direction={['column', 'row', 'row', 'row']} justify='space-evenly' align='stretch' mt='1em'>
                  <Flex flex='1 1 0' border='1px solid' borderColor={(chosenRole === 'admin-user') ? 'brand.300' : 'gray.300'} bg={(chosenRole === 'admin-user') && 'gray.50'} rounded='0.25em' py='0.75em' px='1.25em' cursor='pointer' onClick={() => handleChooseRole('admin-user')}>
                    <Box className='flip-in-hor-bottom'>
                      {
                        (chosenRole === 'admin-user') ? <Box as={BsCheckCircle} className='flip-in-hor-bottom' color='brand.500' width='2em' height='1.4em' mr='0.5em' /> :
                          <Box as={BsCircle} className='flip-in-hor-bottom' color='gray.300' width='2em' height='1.4em' mr='0.5em' />
                      }
                    </Box>
                    <Box>
                      <Box color='gray.700' lineHeight='1.375em'>Administrator</Box>
                      <Box mt='0.5em' fontSize='0.75em' color='gray.500' fontWeight='400'> Administrators can manage Rooms like all its users, plus set organization-wide settings for its users</Box>
                    </Box>
                  </Flex>

                  <Box w='6%' />

                  <Flex flex='1 1 0' mt={['1em', '0', '0', '0']} border='1px solid' borderColor={(chosenRole === 'basic-user') ? 'brand.300' : 'gray.300'} bg={(chosenRole === 'basic-user') && 'gray.50'} rounded='0.25em' py='0.75em' px='1.25em' cursor='pointer' onClick={() => handleChooseRole('basic-user')}>
                    <Box className='flip-in-hor-bottom'>
                      {
                        (chosenRole === 'basic-user') ? <Box as={BsCheckCircle} className='flip-in-hor-bottom' color='brand.500' width='2em' height='1.4em' mr='0.5em' /> :
                          <Box as={BsCircle} className='flip-in-hor-bottom' color='gray.300' width='2em' height='1.4em' mr='0.5em' />
                      }
                    </Box>
                    <Box>
                      <Box color='gray.700' lineHeight='1.375em'>User</Box>
                      <Box mt='0.5em' fontSize='0.75em' color='gray.500' fontWeight='400'> Users have access to features and settings chosen by their Administrators</Box>
                    </Box>
                  </Flex>
                </Flex>
              </React.Fragment>

            }

            <Box mt='0.5em'>
              <WarningTextMessage errorLogic={showNoRoleError} message='Please indicate a role for this user' />
            </Box>

            <Box>
              <ProfileFormTextInput name='memberFirstName' label='First Name' defaultValue={memberFirstName} placeholder='' mt='1em' onChange={handleChange} />
              <Box mt='0.5em'>
                <WarningTextMessage errorLogic={showMissingFirstNameError} message='Please provide a first name for this user' />
              </Box>
            </Box>

            <Box>
              <ProfileFormTextInput name='memberLastName' label='Last Name' defaultValue={memberLastName} placeholder='' mt='1em' onChange={handleChange} />
              <Box mt='0.5em'>
                <WarningTextMessage errorLogic={showMissingLastNameError} message='Please provide a last name for this user' />
              </Box>
            </Box>

            <Box>
              <ProfileFormTextInput name='userOrgTitle' label='Title' defaultValue={userOrgTitle} placeholder='e.g. Head of Marketing, Cofounder' mt='1em' onChange={handleChange} />
              <Box mt='0.5em'>
                <WarningTextMessage errorLogic={showMissingTitleError} message='Please provide an official title for this user' />
              </Box>
            </Box>

          </Box>

        </Box>

        <ClaverModalButtonBar
          handleCloseModal={handleCloseModal}
          loadingLogic={editingInProgress}
          ctaFunction={() => handleEditTeamMember()}
          ctaLabel='Save Changes'
          cancelLabel='Cancel'
        />

      </React.Fragment>
    )
  }

  if (showRemoveMemberModal) {
    return (
      <React.Fragment>
        <Box px='2.5em' my='1.5em' fontWeight='400' color='gray.700' fontSize='0.875em'>

          <Box lineHeight='1.375em'>
            Once deactivated, {memberName}'s account and its content will be removed from your team.
          </Box>

          <Box lineHeight='1.375em' mt='1em'>
          Proceed?
            </Box>
        </Box>

        <ClaverModalButtonBar
          handleCloseModal={() => setShowRemoveMemberModal(false)}
          loadingLogic={removingInProgress}
          ctaFunction={() => handleRemoveMember()}
          ctaLabel='Deactivate & Remove'
          cancelLabel='Cancel'
          bg='red.400'
          color='white'
        />

      </React.Fragment>
    )
  }
}


function mapStateToProps(state) {
  return {
    manageAdminOrgUsersOutcome: state.manageAdminOrgUsersOutcome.results,
		isMobileView: state.mobileViewMode.isMobileView
  };
}

function mapDispatchToProps(dispatch) {
  return {
    action: bindActionCreators(
      {
        updateOrgUsers,
        updateOrgUserReset
      },
      dispatch
    )
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditTeamMemberModule));