import React, {Component} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { changeToNewPassword } from '../actions/auth-actions';
import ClaverButton from '../../global/components/helper-components/components/ClaverButton';
import { Box, Flex } from '@chakra-ui/react';
import ProfileFormTextInput from '../../forms/TextInputField/components/ProfileFormTextInput';
import WarningTextMessage from '../../global/components/helper-components/components/WarningTextMessage';
import LaunchdeckButton from '../../global/components/helper-components/components/LaunchdeckButton';

// TODO: UUU change this to functional component
class LoggedInUserChangePasswordForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			currentPassword:"",
			inputPassword: "",
			repeatPassword:"",
			showError:true,
			errorMessage:'',
		}
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this); 
	}

	handleSubmit(event) {
		event.preventDefault();
	}

	handleChange(event) {
		const value = event.target.value;
		this.setState({
			...this.state,
			showError:false,
			[event.target.name]: value
		});
	}

	submitSetNewPassword = () => {
		const { currentPassword, inputPassword, repeatPassword } = this.state;
		const { changeToNewPassword } = this.props.action;

		const validateInputs = () => {
			if (inputPassword !== repeatPassword) {
				this.setState({
					showError: true,
					errorMessage: 'Oops. You might be facing a typo situation. Your New and Confirmation passwords needs to be identical.'
				})

				return false;
			}

			if (inputPassword.length < 6) {
				this.setState({
					showError: true,
					errorMessage: 'Your new password must be 6 characters or longer'
				})

				return false;
			}

			if (!currentPassword) {
				this.setState({
					showError: true,
					errorMessage: 'Your current password is required to authorize a change to a new password'
				})

				return false;
			}

			return true;
		}

		const validateResults = validateInputs();

		if (validateResults) {
			changeToNewPassword(
				currentPassword,
				inputPassword
			)
		} 

	}

	redirectBack = () => {
		window.location.href = "/profile";
	}

render() {
	const {showError, errorMessage} = this.state;
	const {updatePasswordOutcome, passwordIsUpdating} = this.props || {};
	const {passwordUpdateStatus} = updatePasswordOutcome || {};
	
	const isWrongPassword = (passwordUpdateStatus=== 'wrong-password') ? true : false;
	const isPasswordChangedSuccessfully = (passwordUpdateStatus=== 'password-changed') ? true : false;

	if (isPasswordChangedSuccessfully === false) {
		return (
			// <form onSubmit={this.handleSubmit}>
				<React.Fragment>
				<Box m="3%">
					<ProfileFormTextInput name="currentPassword" label="CURRENT PASSWORD" htmlFor="currentPassword" variant="flushed" type="password" onChange={this.handleChange}/>
					<WarningTextMessage errorLogic={isWrongPassword} message='Your current password is incorrect. Please try again.' />
				</Box>

				<Box my='3em' />

				<Box m="3%">
					<ProfileFormTextInput name="inputPassword" label="NEW PASSWORD" htmlFor="inputPassword" variant="flushed" type="password" onChange={this.handleChange}/>
      	</Box>

				<Box m="3%">
					<ProfileFormTextInput name="repeatPassword" label="CONFIRM PASSWORD" htmlFor="repeatPassword" variant="flushed" type="password" onChange={this.handleChange}/>
      	</Box>
			
				<Box mt='2%' alignItems='center'>
			
						<Flex justify='center' >
						<LaunchdeckButton bg='brand.500' color='white' loadingLogic={passwordIsUpdating}  onClick={this.submitSetNewPassword} label='Set new password' />
						</Flex> 
						
						{showError && <Box>{errorMessage}</Box>}
	
				</Box>
			
			{/* </form> */}
			</React.Fragment>
			
	
	);} else if (isPasswordChangedSuccessfully === true) {
		return (
			<React.Fragment>
				<Box textAlign='center'>Your password has been successfully changed</Box>
				<Flex align='center' justify='center' mt='3%'><ClaverButton onClick={this.redirectBack} label='Head Back' /></Flex>
			</React.Fragment>
		);
	}

}
};

function mapStateToProps(state) {
	return {
		updatePasswordOutcome: state.updatePassword.results.data,
		passwordIsUpdating: state.updatePassword.isLoading
	};
}


function mapDispatchToProps(dispatch) {
	return {
		action: bindActionCreators(
			{
				changeToNewPassword 
			},
			dispatch
		)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(LoggedInUserChangePasswordForm);


