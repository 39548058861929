import React from 'react';
import { Box } from '@chakra-ui/react';


export default function ValidationWarningBanner(props) {
  const {borderColor, emoji} = props;
  const displayedEmoji = emoji ? emoji : '⚠️';
  const displayedBorderColor = borderColor ? borderColor : 'pink.500';
  return (
    <Box border='1px' borderColor={displayedBorderColor} rounded={8} bg='white' mb='1em' w='100%' px={['3%', '', '', '1.5em']} py={['3%', '', '', '1em']}>
      <Box fontSize='0.875em'> <span role="img" aria-label="warning">{displayedEmoji}</span> {props.children}</Box>
    </Box>
  )
}
