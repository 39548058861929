import _ from 'lodash';

export const insert = (arr, index, elem) => {
  const copy = arr.slice();
  copy.splice(index, 0, elem);
  return copy;
};

export const convertContentArrToObj = (page) => {
  const clonedPageContent = _.cloneDeep(page.pageContent);
  const clonedPageContentByKeys = _.keyBy(clonedPageContent, 'contentBlockId');
  Object.keys(clonedPageContentByKeys).forEach((widgetKey, index) => {
  let widgetContent = clonedPageContentByKeys[widgetKey].widgetContent = _.keyBy(clonedPageContentByKeys[widgetKey].widgetContent, 'columnId')
    Object.keys(widgetContent).forEach((columnKey, index) => {
      widgetContent[columnKey].subWidgets = _.keyBy(widgetContent[columnKey].subWidgets, 'id')
    })
  });
  return clonedPageContentByKeys;
}

export const convertDndObjToArr = (pageContent) => {
  const clondedItemsData = _.cloneDeep(pageContent);
  const convertedPageContent = _.values(clondedItemsData).map((widget) => {
    return {
      ...widget,
      widgetContent: _.values(widget.widgetContent).map((column) => {
        return {
          ...column,
          subWidgets: _.values(column.subWidgets)
        }
      })
    };
  });

  return convertedPageContent;
}