import {SET_PAGINATED_USER_NOTIFICATIONS} from '../actions/user-notifications-action-types';

const INITIAL_PAGINATED_USER_NOTIFICATIONS_STATE = { currentUserNotificationBatch: '' }

export function setPaginatedUserNotificationsReducer(state = INITIAL_PAGINATED_USER_NOTIFICATIONS_STATE, action) {
    switch (action.type) {
        case SET_PAGINATED_USER_NOTIFICATIONS:
            return { currentUserNotificationBatch: action.payload }
        default:
            return state;
    }
}