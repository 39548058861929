import React, { useEffect, useState } from 'react'
import {
  Box,
  Flex, Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay, Switch,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs, Text,
  useToast
} from "@chakra-ui/react";
import LaunchdeckButton from '../../../global/components/helper-components/components/LaunchdeckButton';
import LaunchdeckCloseButton from '../../../global/components/helper-components/components/LaunchdeckCloseButton';
import InputTextAreaExpandable from '../../../forms/TextareaField/components/InputTextAreaExpandable';
import { displayReactIcon } from '../../helpers/dsr-display-helpers';
import { triggerLaunchdeckToast } from '../../../global/helpers/global-helpers';


export const CustomPageSettingsModal = ({
  pageId,
  showModal,
  pageTitle,
  pageDescription,
  displayPage,
  lockedPage,
  handleClosePageSettingsModal,
  handleRemovePage,
  handleEditPageData,
  handleDuplicatePageClick
}) => {
  const [pageSettingsState, setPageSettingsState] = useState({
    pageIcon: '',
    pageName: pageTitle ? pageTitle : '',
    customPageDescription: pageDescription ? pageDescription : '',
    isPageVisible: displayPage,
    isPageLocked: lockedPage ? lockedPage : false
  });
  const toast = useToast()
  // const [confirmDelete, setConfirmDelete] = useState(false);
  const [error, setError] = useState('');

  const nameCharacterLimit = 50;
  const descriptionCharacterLimit = 280;

  useEffect(() => {
    if (displayPage !== pageSettingsState.isPageVisible) {
      setPageSettingsState(prevState => ({
        ...prevState,
        isPageVisible: displayPage
      }))
    }

    if (displayPage !== pageSettingsState.isPageLocked) {
      setPageSettingsState(prevState => ({
        ...prevState,
        isPageLocked: lockedPage
      }))
    }
  }, [displayPage, lockedPage]);


  const handleClickClosePageSettingsModal = () => {
    // setConfirmDelete(false);
    setError('')
    handleClosePageSettingsModal()
  }

  const onChangeHandler = (event) => {
    setError('')

    setPageSettingsState(prevPageSettings => ({
      ...prevPageSettings,
      [event.target.name]: event.target.value
    }))
  };

  const handleSavePageSettings = () => {
    const { pageName, customPageDescription, isPageVisible, isPageLocked } = pageSettingsState;
    if (pageName.length <= 0) {
      setError('pageName')
      return;
    }

    if (customPageDescription.length > descriptionCharacterLimit) {
      setError('pageDescription')
      return;
    }

    if (!error) {
      let customPageData = {};

      if (pageName !== pageTitle) {
        customPageData.pageTitle = pageName;
      }

      if (customPageDescription !== pageDescription) {
        customPageData.pageDescription = customPageDescription;
      }

      if (isPageVisible !== displayPage) {
        customPageData.displayPage = isPageVisible;
        if (isPageVisible === false) {
          customPageData.lockedPage = false;
        }
        
      }

      if (isPageLocked !== lockedPage) {
        customPageData.lockedPage = isPageLocked;
      }

      if (Object.keys(customPageData).length > 0) {
        handleEditPageData(customPageData, pageId)
      }

      handleClickClosePageSettingsModal()
    }
  }

  const handleClickDeletePageButton = () => {
    // if (confirmDelete) {
      handleRemovePage(pageId)
      // setConfirmDelete(false);
      handleClosePageSettingsModal()
    // } else {
    //   setConfirmDelete(true);
    // }
  };

  const handleClickDuplicatePageButton = () => {
    handleDuplicatePageClick(pageId)
    handleClosePageSettingsModal()

    toast(
      triggerLaunchdeckToast({
        useCase: "work-in-progress-state",
        label: `${pageTitle} is being duplicated`,
        isMobileView: false
      })
    )
  }

  const cancelButton = (
    <LaunchdeckButton bg='gray.500' color='white' onClick={() => handleClickClosePageSettingsModal(false)} px='0.5em' label='Cancel' />
  )

  return (
    <Modal isOpen={showModal} closeOnOverlayClick={false} isCentered={true} preserveScrollBarGap={true} >
      <ModalOverlay />
      <ModalContent display="inline-block" rounded='0.25em'  minW={['90%', '500px']} maxW='700px' h='auto'>
        <ModalBody px='0em'>
          <Flex justify='space-between' align='center' w='100%'>
            <Flex px='1.5em' py='0.5em' justify='flex-start' align='center' ml='0.5em'>
              <Box fontSize='1.25em' lineHeight='1.375em' color='brandDark.500'>Page settings</Box>
            </Flex>
            <Box pr='1.5em' display='flex' alignItems='center'>
              <LaunchdeckCloseButton handleCloseModal={handleClickClosePageSettingsModal} />
            </Box>
          </Flex>

          <Flex direction='column' justify='space-around' alignItems='center' w='100%'>
            <Box width='100%'>
              <Tabs width='100%'>
                <TabList padding='0 24px'>
                  <Tab fontSize='0.875em' pr='1em'>Page information</Tab>
                  <Tab fontSize='0.875em' px='1em'>Duplicate page</Tab>
                  {/* <Tab isDisabled={true}    >Template settings</Tab> */}
                  <Tab fontSize='0.875em' px='1em'>Delete page</Tab>
                </TabList>

                <TabPanels padding='24px'>
                  <TabPanel>
                    <Box margin='20px -10px' minHeight='380px'>
                      <Box px='1em'>
                        <Box mb='24px'>
                          <Flex w='100%' justify='space-between' align='center' mb='10px'>
                            <Box fontSize='0.875em'>Page name<Box as='span' color='pink.500'>*</Box></Box>
                            <Box display='flex' fontSize='0.875em'><Box fontWeight='600'>{pageSettingsState.pageName.trim().length}</Box>/{nameCharacterLimit}</Box>
                          </Flex>
                          <Input
                            name='pageName'
                            value={pageSettingsState.pageName}
                            onChange={onChangeHandler}
                            placeholder="Enter page name"
                            maxLength={nameCharacterLimit}
                            borderColor={error === 'pageName' ? 'pink.500' : ""}
                            mb='0.2em'
                            h='1.4em'
                            fontSize='0.875em'
                            fontWeight='400'
                            color='brandDark.500'
                            border='1px solid #A0AEC0'
                            borderRadius='4px'
                            padding='16px'
                          />
                          {error === 'pageName' && <Text color='pink.500' fontSize='0.8em'>Give your page a name</Text>}
                        </Box>

                        <Box mb='24px'>
                          <Flex w='100%' justify='space-between' align='center' mb='10px'>
                            <Box fontSize='0.875em'>Page description</Box>
                            <Box display='flex' fontSize='0.875em'>
                              <Box fontWeight='600'>{pageSettingsState.customPageDescription.trim().length}</Box>/{descriptionCharacterLimit}
                            </Box>
                          </Flex>
                          <InputTextAreaExpandable
                            name='customPageDescription'
                            onChangeHandler={onChangeHandler}
                            // inputValue={noteContent}
                            maxLength={descriptionCharacterLimit}
                            inputValue={pageSettingsState.customPageDescription}
                            border="1px solid"
                            borderColor='gray.400'
                            placeholder={"A short explanation about this page. This internal note is only available to you and your team."}
                          />
                          {error === 'pageDescription' && <Text color='pink.500' fontSize='0.8em'>Maximum {descriptionCharacterLimit} characters</Text>}
                        </Box>

                        <Box mb='24px'>
                          <Flex w='100%' justify='space-between' align='center' mb='10px'>
                            <Box fontSize='0.875em'>Page visible to everyone</Box>
                            <Box display='flex' fontSize='0.875em'>
                              <Box>
                                <Switch
                                  size='lg'
                                  name='isPageVisible'
                                  isChecked={pageSettingsState.isPageVisible}
                                  value={pageSettingsState.isPageVisible}
                                  onChange={(event) => setPageSettingsState({
                                    ...pageSettingsState,
                                    isPageVisible: event.target.checked,
                                    ...(event.target.checked === false && {isPageLocked: false})
                                  })}
                                />
                              </Box>
                            </Box>
                          </Flex>
                        </Box>

                        <Box mb='24px'>
                          <Flex w='100%' justify='space-between' align='center' mb='10px'>
                            <Box fontSize='0.875em'>Lock page</Box>
                            <Box display='flex' fontSize='0.875em'>
                              <Box>
                                <Switch
                                  size='lg'
                                  name='isPageLocked'
                                  isChecked={pageSettingsState.isPageLocked}
                                  value={pageSettingsState.isPageLocked}
                                  onChange={(event) => setPageSettingsState({
                                    ...pageSettingsState,
                                    isPageLocked: event.target.checked
                                  })}
                                />
                              </Box>
                            </Box>
                          </Flex>
                        </Box>
                      </Box>
                    </Box>
                    <Flex w='100%'>
                      <Flex align='center' justify='space-between' w='100%'>
                        {cancelButton}
                        <LaunchdeckButton bg='brand.500' color='white' onClick={handleSavePageSettings} px='0.5em' label='OK' />

                      </Flex>
                    </Flex>
                  </TabPanel>

                  <TabPanel>
                    <Box margin='20px -10px' minHeight='380px' height='100%'>
                      <Flex height='100%' minHeight='380px' justifyContent='center' alignItems='center' flexDirection='column'>
                        <Box fontSize='20px' fontWeight='600'>Duplicate page</Box>
                        <Box maxWidth='27em' mb='1.5em' mt='1em' textAlign='center' color='#2D3748' fontSize='0.875em'>
                          An exact copy of <strong>{pageTitle}</strong> will be created. Proceed?
                        </Box>
                        <Flex justifyContent='center' m='24px' width='100%' maxWidth='430px'>
                          <Box
                            display='flex'
                            justifyContent='center'
                            cursor='pointer'
                            alignItems='center'
                            onClick={handleClickDuplicatePageButton}
                            width='100%'
                            height='40px'
                            borderRadius='300px'
                            backgroundColor={'brandDark.500'}
                          >
                            <Flex
                              color={'white'}
                              fontSize='0.875em'
                              align='center'
                            >
                              {displayReactIcon( 'add-new-page','1em')}
                              Duplicate page
                            </Flex>
                          </Box>
                        </Flex>
                      </Flex>
                    </Box>
                  </TabPanel>
                  <TabPanel >
                    <Box margin='20px -10px' minHeight='380px' height='100%'>
                      <Flex height='100%' minHeight='380px' justifyContent='center' alignItems='center' flexDirection='column'>
                        <Box fontSize='20px' fontWeight='600'>Remove page</Box>
                        <Box maxWidth='27em' mb='1.5em' mt='1em' textAlign='center' color='#2D3748' fontSize='0.875em'>
                          <i>{pageTitle}</i> and its content will be removed from this Room. Are you sure?
                        </Box>
                        <Flex justifyContent='center' m='24px' width='100%' maxWidth='430px'>
                          <Box
                            display='flex'
                            justifyContent='center'
                            cursor='pointer'
                            alignItems='center'
                            onClick={handleClickDeletePageButton}
                            width='100%'
                            height='40px'
                            borderRadius='300px'
                            backgroundColor={'brandPink.500'}
                            // backgroundColor={confirmDelete ? 'brandPink.500' : '#da37391a'}
                          >
                            <Flex
                              color={'white'}
                                fontSize='0.875em'
                                align='center'
                            >
                              {displayReactIcon( 'remove','1em')}
                              Yes, remove this page
                              {/* {!confirmDelete
                                ? 'Delete page permanently'
                                : 'Click one more time to confirm'
                              } */}
                            </Flex>
                          </Box>
                        </Flex>
                      </Flex>
                    </Box>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Box>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
