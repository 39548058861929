import React, { useState, useEffect } from 'react';
import { Flex, Box, Stack, useToast } from '@chakra-ui/react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';

import { updateOrgProfile, updateOrgProfileReset } from '../../../organizations/actions/organizations-actions';
import ProfileFormTextInput from '../../../forms/TextInputField/components/ProfileFormTextInput';
import ClaverModalButtonBar from '../../../global/components/helper-components/components/ClaverModalButtonBar';
import { triggerLaunchdeckToast } from '../../../global/helpers/global-helpers';


function EditOrgBillingForm(props) {

  const { orgProfile, handleCloseModal, manageAdminOrgProfileOutcome, isMobileView, getAdminOrgProfile } = props || {};
  const toast = useToast();

  const {orgId, orgRegisteredCountry, orgRegisteredAddress, orgRegisteredName, orgRegisteredNumber} = orgProfile || {}
  const [editingInProgress, setEditingInProgress] = useState(false);

  // Inputs
	const [orgRegisteredNameDisplay, setOrgRegisteredNameDisplay] = useState(orgRegisteredName)
	const [orgRegisteredNumberDisplay, setOrgRegisteredNumberDisplay] = useState(orgRegisteredNumber)
	const [orgRegisteredCountryDisplay, setOrgRegisteredCountryDisplay] = useState(orgRegisteredCountry)
	const [orgRegisteredAddressDisplay, setOrgRegisteredAddressDisplay] = useState(orgRegisteredAddress)


  // UX & State Management
	useEffect(() => {
		if (manageAdminOrgProfileOutcome) {
			const { updatedSuccessfully, errorMessage } = manageAdminOrgProfileOutcome || {};

			const closeModalAndReset = () => {
				setEditingInProgress(false)
				handleCloseModal()
				props.action.updateOrgProfileReset()
			}

			if (updatedSuccessfully === true) {
        getAdminOrgProfile()

        toast(
					triggerLaunchdeckToast({
						useCase: "show-success-state",
						// bannerText: "",
						label: "Your organization info has been updated",
						isMobileView
					})
				)

			  closeModalAndReset()
			}

			if (updatedSuccessfully === false) {
				if (errorMessage) {

					 toast(
          	triggerLaunchdeckToast({
							useCase: "show-error-state",
							bannerText: "Error",
							label: errorMessage,
							isMobileView
						})
					)
	
					closeModalAndReset()
				}
			}
		}
	}, [manageAdminOrgProfileOutcome, props.action, handleCloseModal, toast]);

  // Component functionality
	const handleChange = (event) => {
		const value = event.target.value;
		const variableName = event.target.name;
		switch (variableName) {
      case ('orgRegisteredName'): {
				setOrgRegisteredNameDisplay(value)
				break;
			}
			case ('orgRegisteredCountry'): {
				setOrgRegisteredCountryDisplay(value)
				break;
			}
			case ('orgRegisteredAddress'): {
				setOrgRegisteredAddressDisplay(value)
				break;
			}
			case ('orgRegisteredNumber'): {
				setOrgRegisteredNumberDisplay(value)
				break;
			}
			default: {
				console.log('There is no variable with this name', variableName)
				break;
			}
		}
	}

  const handleSubmitEditProfile = () => {
		setEditingInProgress(true)

		let updateOrgUserProperties = {
			orgRegisteredName: orgRegisteredNameDisplay ? orgRegisteredNameDisplay.trim() : "",
			orgRegisteredCountry: orgRegisteredCountryDisplay && orgRegisteredCountryDisplay.trim(),
			orgRegisteredAddress: orgRegisteredAddressDisplay ? orgRegisteredAddressDisplay.trim() : "",
			orgRegisteredNumber: orgRegisteredNumberDisplay ? orgRegisteredNumberDisplay.trim() : ""
		}

		props.action.updateOrgProfile(updateOrgUserProperties, orgId, 'edit-org-billing-details')
  }


  return (
    <Box overflowY='auto' maxHeight='75vh' >
      <Stack w='100%' px='2em' py='1em'>
        <Flex direction='column' w='100%'>
          <ProfileFormTextInput name="orgRegisteredCountry" label="Incorporated In" htmlFor="orgRegisteredCountry" variant="outline" defaultValue={orgRegisteredCountryDisplay} value={orgRegisteredCountryDisplay} onChange={handleChange} />
        </Flex>
        <Flex direction='column' w='100%'>
          <ProfileFormTextInput name="orgRegisteredAddress" label="Registered Address" htmlFor="orgRegisteredAddress" variant="outline" defaultValue={orgRegisteredAddressDisplay} value={orgRegisteredAddressDisplay} onChange={handleChange} />
        </Flex>
        <Flex direction='column' w='100%'>
          <ProfileFormTextInput name="orgRegisteredName" label="Legal Entity Name" htmlFor="couorgRegisteredNamentry" variant="outline" defaultValue={orgRegisteredNameDisplay} value={orgRegisteredNameDisplay} onChange={handleChange} />
        </Flex>
        <Flex direction='column' w='100%'>
          <ProfileFormTextInput name="orgRegisteredNumber" label="Business Registration Number" htmlFor="orgRegisteredNumber" variant="outline" defaultValue={orgRegisteredNumberDisplay} value={orgRegisteredNumberDisplay} onChange={handleChange} />
        </Flex>
      </Stack>

			<Box mt='2em'>
				<ClaverModalButtonBar
					handleCloseModal={handleCloseModal}
					loadingLogic={editingInProgress}
					ctaFunction={() => handleSubmitEditProfile()}
					ctaLabel='Save Changes'
					cancelLabel='Cancel'
				/>
			</Box>
    </Box>
  );
}

function mapStateToProps(state) {
  return {
		manageAdminOrgProfileOutcome: state.manageAdminOrgProfileOutcome.results.data,
		isMobileView: state.mobileViewMode.isMobileView
  };
}

function mapDispatchToProps(dispatch) {
  return {
    action: bindActionCreators(
      {
        updateOrgProfile,
        updateOrgProfileReset,
      },
      dispatch
    )
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditOrgBillingForm));