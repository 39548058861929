import { SET_DSR_LATEST_NOTIFICATIONS } from '../../actions/dsr-actions-types'

const INITIAL_STATE_SET_DSR_NOTIFICATIONS = { results: '' }

export function setDsrNotificationsReducer(state = INITIAL_STATE_SET_DSR_NOTIFICATIONS, action) {
    switch (action.type) {
        case SET_DSR_LATEST_NOTIFICATIONS:
            return { results: action.payload }
        default:
            return state;
    }
}