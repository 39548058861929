import React, { useEffect, useRef, useState } from "react";

import { Box, Flex } from "@chakra-ui/react";
import DsrAddNewEmojiReactionButton from './DsrAddNewEmojiReactionButton';
import { displayEmoji, getEmojiReactionList } from '../helpers/dsr-helpers';
import DsrPostTextAreaInput from '../../forms/TextareaField/components/DsrPostTextAreaInput';

import { tagUserInPost, untagUserInPost } from '../../digital-sales-room/actions/user-tag-actions'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import { MdOutlineModeComment } from "react-icons/md";

function DsrMessageToolbar(props) {

  const { postReactions,
    replyCount,
    userIdToken,
    messageIndex,
    postId,
    dsrId,
    handleEditPost,
    handleAddRemoveMessageReaction,
    handleCreateNewPostReply,
    createNewDsrFeedReplyOutcome,
    editDsrFeedReplyOutcome
  } = props || {};


  const [showReplyBar, setShowReplyBar] = useState(null)
  // User tagging feature management
  const [activateUserTagMenu, setActivateUserTagMenu] = useState(false)
  const buttonRef = useRef(null);
  

  useEffect(() => {
    if (createNewDsrFeedReplyOutcome) {
      setShowReplyBar(false)
    }

  },[createNewDsrFeedReplyOutcome])

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (buttonRef.current !== e.target) {
        setActivateUserTagMenu(false)
      }
    }
    if (activateUserTagMenu) {
      window.addEventListener('click', handleClickOutside)
    }

    return () => {
      window.addEventListener('click', handleClickOutside)
    };
  }, [activateUserTagMenu]);

  let emojiReactionsUsed = 0;
  const maxEmojiReactions = getEmojiReactionList()?.length;

  const editPostPayload = {
    postId,
    userActionRequired: 'create-new-reply'
  }


  const handleClickCommentButton = () => {
    setShowReplyBar(showReplyBar !== null ? !showReplyBar : true);
    handleEditPost(editPostPayload)
  }

  
  function renderCommentNumber(replyCount) {
    switch (true) {
      case (replyCount === 1): {
        return `1 comment`
      }
      case (replyCount > 0): {
        return `${replyCount} comments`
      }
      default: {
        return null;
      }
    }
  }

  return (
    <React.Fragment>

      <Flex align='center' pt='0.8em' justify='space-between' px='0.5em'>
        {/* // Reactions & Reply Management */}
        <Flex>
          {
            postReactions?.map((reaction, reactionIndex) => {

              const { reactionType, reactionCount, reactionOwnerUserIdTokens } = reaction || {};
              const displayedEmoji = displayEmoji(reactionType);

              if (reactionCount > 0) {
                const userMadeThisReaction = reactionOwnerUserIdTokens ? reactionOwnerUserIdTokens.includes(userIdToken) : false;
                const reactionProps = {
                  ...reaction,
                  postId,
                  userMadeThisReaction
                }
                emojiReactionsUsed = emojiReactionsUsed + 1;

                return <Box key={`messageReaction_${reactionIndex}`}
                  px='0.6em'
                  py='0.1em'
                  mr='0.5em'
                  rounded='0.8em'
                  border="1px"
                  borderColor={userMadeThisReaction ? 'brand.500' : 'gray.100'}
                  bg={userMadeThisReaction ? 'gray.100' : ""}
                  cursor='pointer'
                  onClick={() => handleAddRemoveMessageReaction(reactionProps)}
                >
                  <Flex>
                    <Box fontSize='0.8em' >  {displayedEmoji} </Box>
                    <Box pl='0.5em' fontSize='0.8em'> {reactionCount}</Box>
                  </Flex>

                </Box>
              } else return null;

            })
          }

          {(maxEmojiReactions > emojiReactionsUsed) ?
            <Box key={`dsr_reactions_${messageIndex}`} minWidth='50px'>
              <DsrAddNewEmojiReactionButton
                messageIndex={messageIndex}
                postReactions={postReactions}
                handleAddRemoveMessageReaction={handleAddRemoveMessageReaction}
                postId={postId}
              />
            </Box> : null
          }

          <Flex 
            px='0.6em' 
            py='0.1em' 
            mx='1em' 
            justify='center'
            align='center'
            rounded='0.8em' 
            border="1px"
            borderColor='gray.100'
            cursor='pointer' pl='1em' 
            color='gray.800' fontSize='0.75em' fontWeight='600' 
            onClick={() => handleClickCommentButton()}
          >
            <MdOutlineModeComment />
            <Box pl='0.5em' >Comment</Box>
          </Flex>
        </Flex>

        <Flex width='100%' justifyContent='flex-end' display={['none','none','flex','flex']}>
          {(replyCount > 0) && <Box color='gray.500' fontSize='0.75em' lineHeight='1.125em'> {renderCommentNumber(replyCount)} </Box>}
        </Flex>
      </Flex>

      {
        showReplyBar && <Box>
          <Flex mt='0.625em' justify='flex-start' width='100%'>
            <DsrPostTextAreaInput
              userActionRequired='create-new-reply'
              name="createPostReplyContent"
              sublabel=""
              placeholder="Respond to post"
              fontSize='0.875em'
              htmlFor="createPostReplyContent"
              onClickCreateReply={handleCreateNewPostReply}
              createNewDsrFeedReplyOutcome={createNewDsrFeedReplyOutcome}
              editDsrFeedReplyOutcome={editDsrFeedReplyOutcome}
              dsrId={dsrId}
              postId={postId}
            />
          </Flex>
        </Box>
      }

    </React.Fragment>
  );

}


function mapDispatchToProps(dispatch) {
  return {
    action: bindActionCreators(
      {
        // User Tagging management
        tagUserInPost,
        untagUserInPost
      },
      dispatch
    )
  };
}

export default withRouter(connect(null, mapDispatchToProps)(DsrMessageToolbar));