import React, { useState } from 'react';
import { Box } from "@chakra-ui/react";
import LaunchdeckTag from './LaunchdeckTag';


export default function LaunchdeckInteractiveButton({ label, endLabel, onClick }) {

	const [startStatus, setStartStatus] = useState(true)

	const handleButtonClick = () => {
		onClick();
		setStartStatus(false)
	}


	return (
		<React.Fragment>
			{(startStatus) ?
				<LaunchdeckTag bg='brandPink.500' color='white' fontWeight='600' label={label} onClick={handleButtonClick} />
				:
				<Box className="flip-in-hor-bottom">
					<LaunchdeckTag bg='brandDark.500' color='white' fontWeight='600' label={endLabel} />
				</Box>
			}
		</React.Fragment>

	)

}


