import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';

import { Box, Flex, Grid, InputGroup, Input, Skeleton } from "@chakra-ui/react";
import '../styles/dsr-display.scss';
import './styles/mutual-action-page-display.scss'

import { createNewDsrAction } from '../actions/dsr-actions';
import PageLoader from '../../global/components/helper-components/components/PageLoader';
import { BsCircle } from 'react-icons/bs';

function CreateNewActionBarForm(props) {

  const { dsrId, mapCategoryId, focusCategoryId, setFocusCategoryId, createNewDsrActionOutcome, isLoadingState } = props || {};

  const ref = useRef(null);

  // Input management
  const [titleInput, setTitleInput] = useState("")

  // UX Management
  const [showLoader, setShowLoader] = useState(false)
  const [showActionTitleInput, setShowActionTitleInput] = useState(false)
  const [lastClickedInput, setLastClickedInput] = useState(null)
  const [showSavingState, setShowSavingState] = useState(false)

  useEffect(() => {
    if (createNewDsrActionOutcome) {
      setShowLoader(false)
    }

  }, [createNewDsrActionOutcome, props.action]);

  useEffect(() => {
    if (isLoadingState && (mapCategoryId === focusCategoryId)) {
      setShowSavingState(true)
    } else if ((isLoadingState === false)) {
      setShowSavingState(false)
    }

  }, [isLoadingState, mapCategoryId, focusCategoryId]);


  const handleClickEditInput = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      clickOutsideAutosave()
    } else {
      setLastClickedInput(event.target.name)
    }
  };

  const handleHitEnterKey = (event) => {
    if (event.key === "Enter") {
      if (ref.current && !ref.current.contains(event.target)) {
        clickOutsideAutosave()
      } else {
        setLastClickedInput(event.target.name)
      }
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleHitEnterKey, true);
    document.addEventListener("click", handleClickEditInput, true);
    return () => {
      document.removeEventListener("keydown", handleHitEnterKey, true);
      document.removeEventListener("click", handleClickEditInput, true);
    };
  });

  const clickOutsideAutosave = () => {
    setShowActionTitleInput(false)

    if (lastClickedInput === "action-title") {
      handleCreateNewAction()
    }

    // Resets to prevent savings for subsequent user actions
    setLastClickedInput(null)
  }

  const handleInputChange = (event) => {
    if (event.target.name === "action-title") {
      setTitleInput(event.target.value)
    }
  }

  const handleEnter = (event) => {
    if (event.key === 'Enter' && event.target.name==="action-title") {
      clickOutsideAutosave()
    }
  }

  const handleCreateNewAction = () => {
    if (titleInput.trim().length > 0) {
      const newActionMetadata = {
        dsrId: dsrId,
        event: "create-new-action",
        mapCategoryId,
        actionTaskTitle: titleInput
      }

      props.action.createNewDsrAction(newActionMetadata)
      setTitleInput("")
    }
  }

  const handleClickCreateNewAction = () => {
    setShowActionTitleInput(true)
    setLastClickedInput("action-title")
    setFocusCategoryId(mapCategoryId)
  }


  if (showLoader) {
    return (
      <Box w="100%" align="center" >
        <Box px='1em'>
          <PageLoader />
        </Box>
      </Box>
    )
  }

  if (!showLoader) {

    return (
      <Grid className='item-container' py='1.1em' templateColumns="1.5em auto" gap='0.5em' align='center' position='relative'>
        <Box as={BsCircle} color='gray.300' boxSize='1em' mx='0.5em' />
        <Box width="100%">
      
          {
            showSavingState ? <Skeleton><Flex fontSize='1em' lineHeight='1.875em'>Sample Text Sample Text Sample Text</Flex></Skeleton>
              : showActionTitleInput ?
                <Flex id="action-title" name="action-title" w='100%' justify='space-between' minWidth='max-content' ref={ref}>
                  <InputGroup
                    size="2em"
                    width="100%"
                    border='none'
                  >
                    <Input
                      name="action-title"
                      value={titleInput}
                      onChange={handleInputChange}
                      onKeyDown={handleEnter}
                      placeholder={titleInput}
                      autoFocus={true}
                      fontSize='0.875em'
                      border='none'
                      lineHeight='inherit'
                      fontWeight="400"
                      w='100%'
                      pl='0.6em'
                    />
                  </InputGroup>
                </Flex>
                :
                <Box className="action-input-display" onClick={() => handleClickCreateNewAction()}>
                  Write your action item here
                </Box>
          }
        </Box>
      </Grid>

    )
  }

}


function mapStateToProps(state) {
  return {
    createNewDsrActionOutcome: state.createNewDsrActionOutcome.results.data,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    action: bindActionCreators(
      {
        createNewDsrAction
      },
      dispatch
    )
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateNewActionBarForm));
