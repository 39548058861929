import { SET_DSR_LAST_UPDATED } from '../../actions/dsr-actions-types'

const INITIAL_STATE_DSR_LAST_UPDATED = { results: "" }

export function setDsrLastUpdatedReducer(state = INITIAL_STATE_DSR_LAST_UPDATED, action) {
    switch (action.type) {
        case SET_DSR_LAST_UPDATED:
            return { results: action.payload };
        default:
            return state;
    }
}