import { SET_DSR_DRAFT_CURRENT_PAGE } from '../../actions/dsr-actions-types'

const INITIAL_STATE_DSR_DRAFT_CURRENT_PAGE = { current: 1 }

export function draftDsrCurrentPageReducer(state = INITIAL_STATE_DSR_DRAFT_CURRENT_PAGE, action) {
    switch (action.type) {
        case SET_DSR_DRAFT_CURRENT_PAGE:
            return { current: action.payload };
        default:
            return state;
    }
}