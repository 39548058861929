import { Card, CardBody, Stack, StackDivider } from "@chakra-ui/react";
import React from "react";
import { StackViewSharedDocCard } from "./StackViewSharedDocCard";
import { getDocData } from "./helper";

export const StackViewSharedDocsList = ({ sharedDocsArray }) => {
  return (
    <>
      <Card>
        <CardBody rounded="0.25em">
          <Stack divider={<StackDivider />} spacing="4">
            {sharedDocsArray?.map((docObject) => {
              const {
                docId,
                docName,
                createdAt,
                formattedDocLink,
                memberName,
              } = getDocData({ docObject });

              return (
                <StackViewSharedDocCard
                  docId={docId}
                  docName={docName}
                  docSharedAt={createdAt}
                  formattedDocLink={formattedDocLink}
                  memberName={memberName}
                />
              );
            })}
          </Stack>
        </CardBody>
      </Card>
    </>
  );
};
