import React, { useState, useEffect } from "react";

import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Avatar, Box, Flex, Text, Collapse, Link, Grid, GridItem, useDisclosure } from "@chakra-ui/react";

import '../../digital-sales-room/styles/dsr-overview-display.scss'
import DisplayDsrTeamMemberList from "../dsr-team-mgmt/DisplayDsrTeamMemberList";
import { IoCalendarOutline } from "react-icons/io5";
import { AiOutlineMail, AiOutlinePhone } from 'react-icons/ai'
import { getUserDetailsUsingDsrMembersArray } from "../../profile/helpers/user-helper";
import { LoomVideoDisplay } from "../dsr-content-components/LoomVideoDisplay";
import { openInNewTab } from "../../global/helpers/global-helpers";
import { formatDisplayDateOnly, formatDisplayLocalDateTime, formatTimestamp } from "../helpers/timestamp-display-helper";
import { determineNotificationMessage } from '../../profile/helpers/user-notification-helper';
import LaunchdeckTag from "../../global/components/helper-components/components/LaunchdeckTag";
import { displayElapsedDurationSinceTimestamp } from "../../global/helpers/time-format-helpers";
import { FiExternalLink } from 'react-icons/fi';

function OverviewDisplay({ loomWelcomeVideo, textTileTitle, textTileDescription, dsrSharedDocsOverview,
  dsrMapOverview, dsrLastSaved, mainContactUserId, dsrTeamMembers, dsrTitleRedux, tileSortOrder, tileDisplaySettings,
  dsrPropertiesRedux, handleSelectSubpage, dsrNotifications, isMobileView }) {

  const { isOpen, onToggle } = useDisclosure()
  const [mainContactUserProfile, setMainContactUserProfile] = useState(mainContactUserId)

  const {showGuestList} = dsrPropertiesRedux || {}

  // Tile arrangement
  const tileOrder = Array.isArray(tileSortOrder) ? tileSortOrder : [
    "intro-tile", "free-text-tile", "contact-tile", "notifications-tile", "room-members-tile", "mutual-plan-tile", "shared-doc-tile"
  ]

  const overviewTileDisplaySettings = tileDisplaySettings ? tileDisplaySettings : {
    introTileDisplay: true,
    freeTextTileDisplay: true,
    contactTileDisplay: true,
    notificationsTileDisplay: true,
    membersTileDisplay: true,
    planTileDisplay: true,
    sharedDocTileDisplay: true
  }

  useEffect(() => {
    if (mainContactUserId && dsrTeamMembers) {
      setMainContactUserProfile(getUserDetailsUsingDsrMembersArray(dsrTeamMembers, mainContactUserId))
    }
  }, [dsrTeamMembers, mainContactUserId]);

  const handleOpenMeetingLink = (meetingLink) => {
    openInNewTab(meetingLink)
  }

  const handleOpenEmailLink = (email) => {
    const emailSubject = `Query - ${dsrTitleRedux}`;

    window.open(`mailto:${email}?subject=${emailSubject}`)
  }


  function renderRoomMembersTile() {
    return (
      <Box className="tile-container">
        <Box>
          <Box fontSize='1.5em' fontWeight='500' lineHeight='1.5em' textAlign={['center', 'center', 'left', 'left']}>  Room Members</Box>
          <DisplayDsrTeamMemberList dsrTeamMembers={dsrTeamMembers} showGuestList={showGuestList} isMobileView={isMobileView} />
        </Box>
      </Box>
    )
  }


  function renderContactTile() {
    // Get the userId & user profile of the nominated contact here
    const { memberName, memberProfilePicSrc, email, memberOrgTitle, meetingLink, contactPhoneNumber } = mainContactUserProfile || {};
    const subText = (memberOrgTitle) ? memberOrgTitle : email;

    return (
      <Box className="tile-container">
        <Flex w='100%' justify='center' align='center'>
          <Box border='1px solid' borderColor='gray.200' rounded='50%'>
            <Avatar h='5em' w='5em' maxW='5em' name={memberName} bg='gray.100' color='gray.700' src={memberProfilePicSrc} />
          </Box>
        </Flex>

        <Box my='1em'>
          <Flex w='100%' justify='center' fontSize='0.875em'>{memberName}</Flex>
          <Flex w='100%' justify='center' fontSize='0.75em' color='gray.500'>{subText}</Flex>
        </Box>

<>
        <Flex w='100%' justify='center' align='center' mt='2em'>
          <Flex justify='space-between'>
            <Box border='1px solid' borderColor='gray.200' rounded='50%' cursor='pointer' p='0.5em' onClick={() => handleOpenEmailLink(email)}>
              <Box as={AiOutlineMail} />
            </Box>
            {contactPhoneNumber && 
              <Box border='1px solid' borderColor='gray.200' rounded='50%' ml='1.5em' cursor='pointer' p='0.5em' onClick={onToggle}>
                <Box as={AiOutlinePhone} />
              </Box>
            }
            {meetingLink &&
              <Box border='1px solid' borderColor='gray.200' rounded='50%' ml='1.5em' cursor='pointer' p='0.5em' onClick={() => handleOpenMeetingLink(meetingLink)}>
                <Box as={IoCalendarOutline} />
              </Box>
            }
          </Flex>

      

        </Flex>

        <Collapse in={isOpen} animateOpacity>
        <Flex
          p='1em'
          mt='1em'
          rounded='0.5em'
          justify='center'
          align='center'
          direction='column'
          border='1px solid'
          borderColor='gray.100'
          gap='0.25em'
        >
           <Box  color='brandDark.500' fontSize='0.875em' fontWeight='600'>{contactPhoneNumber}</Box>
           <a href={`tel:${contactPhoneNumber}`}> <Box color='brand.500' fontSize='0.6em' fontWeight='600' textTransform={'uppercase'}>Contact Number</Box></a>
        </Flex>
      </Collapse>
      </>
      </Box>
    )
  }

  function renderLoomWelcomeVideo(loomVideo) {
    if (loomVideo) {
      return (
        <Box className="tile-container-loom-video__black-background loader-img" rounded='8px'>
          <LoomVideoDisplay loomVideo={loomVideo} />
       </Box>
      )
    } else return null;
  }


  function renderTextTile() {

    const displayedDescString = textTileDescription?.length > 180 ? `${textTileDescription.substring(0, 180)}...` : textTileDescription;

    return (

      <Box className="tile-container" gridColumn={!isMobileView &&'span 2'}>
        <Box>
          <Flex mb="0.5em" justify={isMobileView ? 'center' : 'flex-start'} align='center'>
            <Box
              display='flex'
              alignItems='center'
              fontWeight="500"
              fontSize="1.5em"
              lineHeight="1.5em"
              color="brandDark.500"
              border='1px solid'
              borderColor='transparent'
              paddingLeft='5px'
              // height='40px'
              mb='1em'
            >
              <Box transform='translateY(1px)' textAlign={['center', 'left']}>{textTileTitle ? textTileTitle : ''}</Box>
            </Box>
          </Flex>
        </Box>
        <Box>
          <Flex mb="0.5em" justify='center' textAlign={isMobileView ? 'center' : "left"}>
            <Box w='100%' h='100%'>
              <Flex mb="0.5em"
                justify='flex-start' align='center'
              >
                <Box
                  fontWeight="500"
                  fontSize="0.875em"
                  lineHeight="22px"
                  color='#718096'
                  border='1px solid'
                  borderColor='transparent'
                  paddingLeft='5px'
                  whiteSpace='pre-line'
                  pt='5px'>
                  {displayedDescString ? displayedDescString : ''}
                </Box>


              </Flex>
            </Box>
          </Flex>
        </Box>
      </Box>
    )
  }


  function renderNotificationsTile() {
    const renderedList = dsrNotifications?.length > 0 ? dsrNotifications.map((notification, noteIndex) => {
      const { notificationId, notificationType, createdAt: notificationDate } = notification || {}
      const displayedNotificationMessage = notificationType ? determineNotificationMessage(notification) : "";


      if (displayedNotificationMessage) {
        return (
          <Box key={`header-notification--${notificationId}-${noteIndex}`} color='gray.700'
            py='0.5em'
            minW={['0', '20em']}
            maxW='30em'
          >
            <Box >
              <Text fontSize='0.8em'>
                {displayedNotificationMessage}
              </Text>
              <Flex justify='flex-start' align='center'>
                <Text fontSize='0.6em' color='gray.500' mt='0.2em'>
                  {formatDisplayLocalDateTime(notificationDate)}
                </Text>
              </Flex>

            </Box>
          </Box>
        )
      } else return <Box key={`header-notification--${notificationId}-${noteIndex}`} />;

    }) : <Box fontSize='0.8em' color='gray.700' textAlign={['center', 'center', 'left', 'left']} mt='1em'>
      None right now. You are all caught up.
    </Box>

    return (
      <Box className="tile-container">
        <Box fontSize='1.5em' fontWeight='500' lineHeight='1.5em' alignSelf='stretch' textAlign={['center', 'center', 'left', 'left']}>Recent activity</Box>
        {renderedList}
      </Box>
    )
  }

  function renderMapOverviewTile() {
    const { latestActionItems, actionItemsWithOutstandingDueDates } = dsrMapOverview || {}

    const renderLatestActionItemList = Array.isArray(latestActionItems) ? latestActionItems?.map(item => {
      const { actionId, actionTaskTitle, actionUpdatedAt, actionCreatedAt, assignedToUserId } = item || {}
      const renderAvatarPics = Array.isArray(assignedToUserId) ? assignedToUserId?.map(userId => {
        const memberInfo = dsrTeamMembers && getUserDetailsUsingDsrMembersArray(dsrTeamMembers, userId)
        const { memberName, memberProfilePicSrc } = memberInfo || {};

        return <Avatar h='2em' w='2em' maxW='2em' mr='0.5em' name={memberName} bg='gray.100' color='gray.700' src={memberProfilePicSrc} />
      }) : null;

      return <Flex key={'latest-map-' + actionId}
        direction={['column-reverse', 'column-reverse', 'row', 'row']}
        w='100%' align='flex-start' p={['0.5em 0', '0.5em']}
        _hover={{ bg: "gray.100" }}
        rounded='0.5em'
      >
        <Box flex='7'>
          <Box fontSize='0.875em'>{actionTaskTitle}</Box>
          <Box mt='0.2em'>{renderAvatarPics}</Box>
        </Box>

        <Box flex='3'>
          <Box fontSize='0.6em' mt='0.25em' pl={['0', '0.5em']} textAlign={'right'}>{displayElapsedDurationSinceTimestamp(actionUpdatedAt ? actionUpdatedAt : actionCreatedAt)}</Box>
        </Box>
      </Flex>
    }) : <Box fontSize='0.875em' mt='1em' textAlign={'left'}> Nothing yet</Box>

    const renderOutstandingItemList = Array.isArray(actionItemsWithOutstandingDueDates) ? actionItemsWithOutstandingDueDates?.map(item => {
      const { actionId, actionTaskTitle, assignedToUserId, actionDueDate } = item || {}
      const renderAvatarPics = Array.isArray(assignedToUserId) ? assignedToUserId?.map(userId => {
        const memberInfo = dsrTeamMembers && getUserDetailsUsingDsrMembersArray(dsrTeamMembers, userId)
        const { memberName, memberProfilePicSrc } = memberInfo || {};

        return <Avatar h='2em' w='2em' maxW='2em' name={memberName} bg='gray.100' color='gray.700' src={memberProfilePicSrc} />
      })

        : null;


      return <Flex key={'due-map-' + actionId} direction={['column-reverse', 'column-reverse', 'row', 'row']} w='100%' align={['left', 'left', 'center', 'center']} p={isMobileView ? '0.5em 0' : '0.5em'} _hover={{ bg: "gray.100" }} rounded='0.5em' >
        <Box flex='7'>
          <Box fontSize='0.875em'>{actionTaskTitle}</Box>
          <Box>{renderAvatarPics}</Box>
        </Box>

        <Box flex='3'>
          <Flex justify={['flex-start', 'flex-start', 'flex-end', 'flex-end']}>
            {isMobileView ? <Box color='brandPink.500' fontSize='0.6em' fontWeight='600' mt='0.2em'>{formatTimestamp(actionDueDate)}</Box> : <LaunchdeckTag bg='brandPink.500' color='white' fontWeight='600' label={formatTimestamp(actionDueDate)} />}
          </Flex>
        </Box>
      </Flex>
    }) : <Box fontSize='0.875em' mt='1em' textAlign={'left'}> Nothing due for now</Box>

    return (
      <Box className="tile-container">
        <Box fontSize='1.5em' fontWeight='500' lineHeight='1.5em' alignSelf='stretch' textAlign={['center', 'center', 'left', 'left']}>Upcoming actions</Box>

        {actionItemsWithOutstandingDueDates?.length > 0 &&
          <><Box fontSize='0.875em' fontWeight='600' color='brandPink.500' mt='2em' mb='1em'>Due soon</Box>
            <Box>{renderOutstandingItemList}</Box>
          </>
        }
        {latestActionItems ? <>
          <Box fontSize='0.875em' color='brand.500' mt='2em' fontWeight='600' >Most recent</Box>
          <Box mb='1em'>{renderLatestActionItemList}</Box>
        </> : <>
          <Box fontSize='0.8em' mt='1em' textAlign={'left'}> No agreed actions set yet</Box>
          <Box>

          </Box>
        </>}

        <Box mt='1.5em'>
          <LaunchdeckTag fontSize='0.75em' bg='brand.500' color='white' label={latestActionItems ? 'See all' : "Review action plan"}  border='1px dotted' borderColor='gray.300'
            onClick={() => handleSelectSubpage("", 'action-list-subpage')} />
        </Box>
      </Box>
    )
  }

  function renderSharedDocTile() {
    const renderedList = dsrSharedDocsOverview?.length > 0 ? dsrSharedDocsOverview.map((sharedDocs, docIndex) => {
      const { docId, docLink, docName, docOwnerId, createdAt: docDateCreated } = sharedDocs || {}
      const dateDisplayed = docDateCreated ? formatDisplayDateOnly(docDateCreated) : "";
      const docOwner = docOwnerId ? getUserDetailsUsingDsrMembersArray(dsrTeamMembers, docOwnerId) : {}

      if (docName && docOwner) {
        return (
          <Grid
            key={`header-notification--${docId}-${docIndex}`}
            color='gray.700'
            // display='flex'
            py='0.5em'
            minW='20em'
            maxW='30em'
            gap={6}
            align='flex-start'
            justifyContent='space-between'
            w='100%'
            gridTemplateColumns="auto 5em"
          >

            <GridItem display='flex' pr='0.25em'>
              <Box py='0.2em' pr='0.5em'>
                <Link
                  href={docLink}
                  fontSize="1em"
                  isExternal
                >
                  <FiExternalLink />
                </Link>

              </Box>

              <Box>
                <Box fontSize='0.8em'>
                  {docName}
                </Box>
                <Flex justify='flex-start' align='center'>
                  {/* {(viewed === false ) && <Box color='brandPink.500' fontSize='0.7em' mr='0.5em'>•</Box>} */}
                  <Text fontSize='0.6em' color='gray.500' mt='0.2em'>
                    Owner:  {docOwner.memberName}
                  </Text>
                </Flex>
              </Box>

            </GridItem>

            <GridItem fontSize='0.7em' textAlign={'right'}>
              {dateDisplayed}
            </GridItem>
          </Grid>
        )
      } else return <Box key={`header-notification--${docId}-${docIndex}`} />;

    }) : <Box fontSize='0.8em' color='gray.700' textAlign={['center', 'center', 'left', 'left']} mt='1em'>
      None right now. You are all caught up.
    </Box>

    return (
      <Box className="tile-container">
        <Box opacity={1.0}>
          <Box mb='1em' fontSize='1.5em' fontWeight='500' lineHeight='1.5em' alignSelf='stretch' textAlign={['center', 'center', 'left', 'left']}>Shared Documents</Box>
          {renderedList}
       
       {dsrSharedDocsOverview?.length > 0 &&
         <Box mt='1.5em'>
         <LaunchdeckTag fontSize='0.75em' bg='brand.500' label={'See all documents'} color='white' border='1px dotted' borderColor='gray.300'
           onClick={() => handleSelectSubpage("", 'shared-docs-folder-subpage')} />
       </Box>
       }
         

        </Box>
      </Box>
    )
  }

  function renderGrid() {
    const renderedGrid = tileOrder.map((tile, index) => {
      //  "intro-tile", "contact-tile","notifications-tile","room-members-tile"
      switch (tile) {
        case ("intro-tile"): {
          if (!!overviewTileDisplaySettings.introTileDisplay && loomWelcomeVideo) {
            return (
              renderLoomWelcomeVideo(loomWelcomeVideo)
            )
          } else return null;
        }
        case ("free-text-tile"): {
          if (!!overviewTileDisplaySettings.freeTextTileDisplay && (textTileDescription || textTileTitle)) {
            return (
              renderTextTile()
            )
          } else return null;
        }
        case ("contact-tile"): {
          if (!!overviewTileDisplaySettings.contactTileDisplay) {
            return (
              renderContactTile(mainContactUserProfile)
            )
          } else return null;
        }
        case ("notifications-tile"): {
          if (!!overviewTileDisplaySettings.notificationsTileDisplay) {
            return (
              renderNotificationsTile()
            )
          } else return null;
        }
        case ("room-members-tile"): {
          if (!!overviewTileDisplaySettings.membersTileDisplay) {
            return (
              renderRoomMembersTile()
            )
          } else return null;

        }
        case ("mutual-plan-tile"): {
          if (!!overviewTileDisplaySettings.planTileDisplay) {
            return (
              renderMapOverviewTile()
            )
          } else return null;
        }
        case ("shared-doc-tile"): {
          if (!!overviewTileDisplaySettings.sharedDocTileDisplay) {
            return (
              renderSharedDocTile()
            )
          } else return null;
        }
        default: {
          return null
        }
      }
    })

    return renderedGrid
  }

  return (
    <Box>
      <Box mb={isMobileView && '0.5em'} fontWeight="600" fontSize="1.5em" color="brandDark.500" textAlign={["center", "center", "left", "left"]}>
        Overview
      </Box>

      {dsrLastSaved && !isMobileView &&
        <Box fontSize='0.65em' color='gray.500'>
          <Box fontWeight='500' >
            Last updated:  {formatDisplayLocalDateTime(dsrLastSaved)}
          </Box>
          <Box >

          </Box>
        </Box>
      }

      <Box className={isMobileView ? "tiles-wrapper-mobile" : "tiles-wrapper"}>
        {renderGrid()}
      </Box>
    </Box>

  )
}


function mapStateToProps(state) {
  return {
    dsrTitleRedux: state.dsrTitle.title,
    isMobileView: state.mobileViewMode.isMobileView,
    dsrNotifications: state.dsrNotifications.results,
    dsrMapOverview: state.dsrMapOverview.results,
    dsrLastSaved: state.dsrLastSaved.results,

    dsrSharedDocsOverview: state.dsrSharedDocOverview.results,
    dsrPropertiesRedux: state.draftDsrProperties.properties,
  };
}


export default withRouter(connect(mapStateToProps, null)(OverviewDisplay));


