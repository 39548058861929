import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';

import { Avatar, Box, Button, Flex, Menu, MenuButton, MenuItem, MenuList, useToast } from '@chakra-ui/react';
import ClaverModalButtonBar from '../../../global/components/helper-components/components/ClaverModalButtonBar';
import { triggerLaunchdeckToast } from '../../../global/helpers/global-helpers';
import LaunchdeckFormInput from '../../../forms/TextInputField/components/LaunchdeckFormInput';
import { IoChevronDownSharp } from 'react-icons/io5';
import { manageExistingOrganization, resetManageExistingOrganization } from '../../actions/app-admin-actions';


function AppAdminEditOrgTeamMemberModule({
  orgIdToken, 
  orgMemberToEdit,
  memberIsAdministrator,
  handleCloseModal,
  orgMemberStatusOptions,
  // Actions
  manageExistingOrganization,
  resetManageExistingOrganization,
  // Redux
  isMobileView,
  manageExistingOrgOutcome,
  manageOrgIsProcessing,
}) {

  const { userId, memberStatus, memberName, memberOrgTitle, memberProfilePicSrc } = orgMemberToEdit || {};

  const toast = useToast();

  const [changesMade, setChangesMade] = useState(false)
  const [userOrgTitleDisplay, setUserOrgTitle] = useState(memberOrgTitle)
  const [chosenMemberStatus, setChosenMemberStatus] = useState(memberStatus)


  // UX & State Management
  useEffect(() => {
   
    if (manageExistingOrgOutcome) {
      const { caseProcessed, orgUserUpdatedSuccessfully, unexpectedError } = manageExistingOrgOutcome || {};

      const closeModalAndReset = () => {
        handleCloseModal()
        resetManageExistingOrganization()
      }

      if (!!unexpectedError) {
        toast(
          triggerLaunchdeckToast({
            useCase: "show-error-state",
            bannerText: "Error",
            label: manageExistingOrgOutcome.errorMessage,
            isMobileView
          })
        )

        closeModalAndReset()
      }

      if (caseProcessed === "save-edit-organization-membership" && orgUserUpdatedSuccessfully === true) {
   
        toast(
  				triggerLaunchdeckToast({
  					useCase: "show-success-state",
  					bannerText: "Success!",
  					label: "Team member successfully updated",
  					isMobileView
  				})
  			)

        closeModalAndReset()
      }
      else if (orgUserUpdatedSuccessfully === false) {
   
        toast(
          triggerLaunchdeckToast({
            useCase: "show-error-state",
            bannerText: "Error",
            label: "Unable to update org user",
            isMobileView
          })
        )

        closeModalAndReset()
      }
    }

  }, [manageExistingOrgOutcome, handleCloseModal, toast]);



  // Component functionality
  const handleChange = (event) => {
    const value = event.target.value;
    const variableName = event.target.name;
 
    switch (variableName) {
      case ('memberOrgTitle'): {
        setChangesMade(true)
        setUserOrgTitle(value)
        break;
      }
      default: {
        console.log('There is no variable with this name', variableName)
        break;
      }
    }
  }


  const handleEditOrgMember = () => {

    if (changesMade) {
      manageExistingOrganization({
        useCase: "save-edit-organization-membership",
        manageExistingOrganizationPayload: {
          orgIdToken: orgIdToken,
          memberIsAdministrator: memberIsAdministrator,
          userIdToken: userId,
          userOrgTitle: userOrgTitleDisplay,
          memberStatus: chosenMemberStatus
        }
      })
    } else {
      toast(
        triggerLaunchdeckToast({
          useCase: "show-error-state",
          bannerText: "Nothing to save",
          label: "No changes made",
          isMobileView
        })
      )
    }
   
  }

  const renderMemberStatusOptions = () => {
    if (Array.isArray(orgMemberStatusOptions)) {
      const options = orgMemberStatusOptions?.map((option, optionIndex) => {
        return <MenuItem key={`org_member_status-option-${optionIndex}`}
          onClick={() => {
            setChosenMemberStatus(option)
            setChangesMade(true)
          }}
          fontSize='0.875em'
        >
          {option}
        </MenuItem>
      })

      return options;
    } else return null
  }

  return (
    <React.Fragment>
      <Box bg='gray.50'>
        <Flex direction='row' py='1rem' px='2em' justify='space-between' alignItems='center'>
          <Avatar mb='1em' h='4em' w='4em' name={memberName} bg='gray.100' color='gray.700' src={memberProfilePicSrc} />
          <Box flex='70%' pl='3%'>
            <Box lineHeight='1.375em' fontWeight='500' color='gray.700'>{memberName}</Box>
            <Box lineHeight='1.25em' fontSize='0.875em' fontWeight='400' color='gray.500'>{memberOrgTitle}</Box>
          </Box>


        </Flex>
      </Box>
      <Box px='2em' mb='2em'>
        <Box>


          <Box mt='1em'>
            <LaunchdeckFormInput
              name={'memberOrgTitle'}
              onChange={handleChange}
              label="Org Title"
              placeholder=""
              value={userOrgTitleDisplay}
              // errorLogic={showMissingTitleError}
              // errorMessage={'Org Title cannot be left blank'}
            />
          </Box>

          {orgMemberStatusOptions &&
            <Box mt='1em' px='0.5em'>
              <Box fontSize='0.8em' fontWeight='600' mb='0.5em'>Org Member Status</Box>
              <Menu>
                <MenuButton as={Button} rightIcon={<IoChevronDownSharp />} fontSize='0.8em' p='1.2em 1em'>
                  {chosenMemberStatus}
                </MenuButton>
                <MenuList>
                  {renderMemberStatusOptions()}
                </MenuList>
              </Menu>
            </Box>
          }

        </Box>

      </Box>

      <ClaverModalButtonBar
        handleCloseModal={handleCloseModal}
        loadingLogic={manageOrgIsProcessing}
        ctaFunction={() => handleEditOrgMember()}
        ctaLabel='Save Changes'
        cancelLabel='Cancel'
      />

    </React.Fragment>
  )
}


function mapStateToProps(state) {
  return {
    manageExistingOrgOutcome: state.manageExistingOrgOutcome.results,
		manageOrgIsProcessing: state.manageExistingOrgOutcome.isLoading,
    isMobileView: state.mobileViewMode.isMobileView
  };
}


const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      manageExistingOrganization,
      resetManageExistingOrganization
    },
    dispatch,
  )

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppAdminEditOrgTeamMemberModule));