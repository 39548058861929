import React from 'react';

import { useHistory } from 'react-router-dom';

import { Box, Flex, Image, Modal, ModalOverlay, ModalBody, ModalContent  } from '@chakra-ui/react';
import NudgeModalGraphic from '../styles/images/convert-plan-join-waitlist.svg'
import StripeCheckoutButton from '../../payments/components/StripeCheckoutButton';
import LaunchdeckCloseButton from '../../global/components/helper-components/components/LaunchdeckCloseButton';


export default function SubscriptionNudgeModal({ copyText, handleCloseModal }) {

  const history = useHistory()
  const displayCopyText = copyText ? copyText : "To start publishing Rooms & Prospectors, please consider setting up your organization's billing details first"

  const displayContent = () => {
    return (
      <Box>

        <Flex direction='column' mt='1em' justify='center' alignItems='center' px='2em'>
        <Image src={NudgeModalGraphic} height='12em'/>

        <Flex w='100%' mt='2em' fontSize='0.875em' color='brandDark.500' fontWeight='500' my='1em' textAlign='center'>
           {displayCopyText}
          </Flex>

          <Flex justify='center' align='center' w='100%' my='2em'>
             <StripeCheckoutButton sourceRoute={`${history.location.pathname}`} />
          </Flex>

        </Flex>

      </Box>
    )
  }

  return (
    <Modal isOpen={true} closeOnOverlayClick={false} isCentered={true} >
      <ModalOverlay className="modal-background" />
      <ModalContent rounded='0.25em' minW={['90%', '500px']} maxW='700px'>
        <ModalBody px='0em'>
          <Flex direction='column' justify='space-around' alignItems='center' w='100%' p='1em'>
            <Flex justify='flex-end' w='100%'>
              <Box pr='1.5em'>
                <LaunchdeckCloseButton handleCloseModal={() => handleCloseModal()} />
              </Box>
            </Flex>

            <React.Fragment>
              {displayContent()}
            </React.Fragment>

          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )


}

