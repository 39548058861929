import { CHANGE_SHOW_DSR_MODAL } from '../../actions/dsr-actions-types'

const INITIAL_STATE_DSR_MODAL = { showDsrModal: false }

export function dsrModalReducer(state = INITIAL_STATE_DSR_MODAL, action) {
    switch (action.type) {
        case CHANGE_SHOW_DSR_MODAL:
            return { showDsrModal: action.payload };
        default:
            return state;
    }
}