import React from 'react'
import { Box, Divider, Menu, MenuButton, MenuList } from "@chakra-ui/react";
import { RiSettings3Line } from "react-icons/ri";
import { MdOutlineAlignHorizontalLeft } from "react-icons/md";
import { MdOutlineAlignHorizontalCenter } from "react-icons/md";
import { MdOutlineAlignHorizontalRight } from "react-icons/md";
import { optionTypes } from "./types/subWidgetOptionsTypes";
import { IoDuplicate } from 'react-icons/io5';


export const SubWidgetOptionMenu = ({ type, subWidgetId, handleSaveSubWidgetOptions, subWidgetOptions, handleEditItemByClick, handleDuplicateSubWidget }) => {
  const typesWithOptionsMenu = ['content-divider', 'content-spacer', 'cta-button', 'youtube-video'];
  const alignableSubWidgetTypes = ['title-text', 'normal-text', 'cta-button', 'user-signature'];
  const duplicableSubWidgetTypes = ['title-text', 'normal-text', 'cta-button', 'user-signature', 'content-spacer', 'content-divider']

  const handleChangeSubWidgetOption = (param, value) => {
    let options = {
      ...subWidgetOptions,
      [param]: value
    }

    handleSaveSubWidgetOptions(options)
  }



  const optionMenuItems = (type) => {
    switch (type) {
      case 'content-divider':
        return (
          <Box margin='10px' color='gray.500'>
            <Box display="flex" alignItems='center' my='0.5em' onClick={() => handleChangeSubWidgetOption(optionTypes.STRENGTH, 'thin')}>
              <Divider width="12px" borderBottomWidth='1px' borderColor='#CBD5E0' marginRight='10px' />
              <Box color={subWidgetOptions[optionTypes.STRENGTH] === 'thin' && 'brandDark.500'} fontWeight={subWidgetOptions[optionTypes.STRENGTH] === 'thin' ? 'bold' : 'normal'}>Thin</Box>
            </Box>
            <Box display="flex" alignItems='center' my='0.5em' onClick={() => handleChangeSubWidgetOption(optionTypes.STRENGTH, 'medium')}>
              <Divider width="12px" borderBottomWidth='2px' borderColor='#CBD5E0' marginRight='10px' />
              <Box color={subWidgetOptions[optionTypes.STRENGTH] === 'medium' && 'brandDark.500'} fontWeight={subWidgetOptions[optionTypes.STRENGTH] === 'medium' ? 'bold' : 'normal'}>Medium</Box>
            </Box>
            <Box display="flex" alignItems='center' my='0.5em' onClick={() => handleChangeSubWidgetOption(optionTypes.STRENGTH, 'bold')}>
              <Divider width="12px" borderBottomWidth='3px' borderColor='#CBD5E0' marginRight='10px' />
              <Box color={subWidgetOptions[optionTypes.STRENGTH] === 'bold' && 'brandDark.500'} fontWeight={subWidgetOptions[optionTypes.STRENGTH] === 'bold' ? 'bold' : 'normal'}>Bold</Box>
            </Box>
          </Box>
        )

      case 'content-spacer':
        return (
          <Box margin='10px'>
            <Box display="flex" alignItems='center' my='0.5em' onClick={() => handleChangeSubWidgetOption(optionTypes.HEIGHT, '1em')}>
              <Box fontWeight={subWidgetOptions[optionTypes.HEIGHT] === '1em' ? 'bold' : 'normal'}>Small</Box>
            </Box>
            <Box display="flex" alignItems='center' my='0.5em' onClick={() => handleChangeSubWidgetOption(optionTypes.HEIGHT, '2em')}>
              <Box fontWeight={subWidgetOptions[optionTypes.HEIGHT] === '2em' ? 'bold' : 'normal'}>Medium</Box>
            </Box>
            <Box display="flex" alignItems='center' my='0.5em' onClick={() => handleChangeSubWidgetOption(optionTypes.HEIGHT, '3em')}>
              <Box fontWeight={subWidgetOptions[optionTypes.HEIGHT] === '3em' ? 'bold' : 'normal'}>Large</Box>
            </Box>
          </Box>
        )

      case 'cta-button':
        return (
          <Box margin='10px'>
            <Box display="flex" alignItems='center' onClick={() => handleChangeSubWidgetOption(optionTypes.VARIANT, 'primary')}>
              <Box fontWeight={subWidgetOptions[optionTypes.VARIANT] === 'primary' ? 'bold' : 'normal'}>Primary</Box>
            </Box>
            <Box display="flex" alignItems='center' onClick={() => handleChangeSubWidgetOption(optionTypes.VARIANT, 'secondary')}>
              <Box fontWeight={subWidgetOptions[optionTypes.VARIANT] === 'secondary' ? 'bold' : 'normal'}>Secondary</Box>
            </Box>
          </Box>
        )
      case 'youtube-video':
        return (
          <Box p='0.5em 10px'>
            <Box display="flex" alignItems='center' onClick={() => handleEditItemByClick()}>
              <Box fontSize='0.875em'>Edit Link</Box>
            </Box>
          </Box>
        )
      default:
        return null
    }
  }

  return (
    <>
      {alignableSubWidgetTypes.includes(type) &&
        <>
          <Box marginRight='8px' onClick={() => handleChangeSubWidgetOption(optionTypes.ALIGN, 'left')}>
            <MdOutlineAlignHorizontalLeft fill="#fff" />
          </Box>
          <Box marginRight='8px' onClick={() => handleChangeSubWidgetOption(optionTypes.ALIGN, 'center')}>
            <MdOutlineAlignHorizontalCenter fill="#fff" />
          </Box>
          <Box marginRight='8px' onClick={() => handleChangeSubWidgetOption(optionTypes.ALIGN, 'right')}>
            <MdOutlineAlignHorizontalRight fill="#fff" />
          </Box>
        </>
      }

      {typesWithOptionsMenu.includes(type) &&
        <Menu placement="bottom-end">
          <MenuButton>
            <Box marginRight='8px' >
              <RiSettings3Line fill="#fff" />
            </Box>
          </MenuButton>
          <MenuList>
            {optionMenuItems(type)}
          </MenuList>
        </Menu>
      }

      {duplicableSubWidgetTypes.includes(type) &&
        <>
          <Box marginRight='8px' onClick={() => handleDuplicateSubWidget(subWidgetId)}>
            <IoDuplicate fill="#fff" />
          </Box>
        </>
      }
    </>
  )
}
