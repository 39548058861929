import React, { memo } from 'react'
import { Box, Flex, Skeleton } from '@chakra-ui/react'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { changeDsrModalState, changeDsrPopupState } from '../actions/dsr-actions'

import '../../layout/components/header/styles/header.scss';
import DsrManageOrgLogosModule from '../dsr-draft/DsrManageOrgLogosModule';
import DsrPageSideMenuDrawer from "./DsrPageSideMenuDrawer";
import LaunchdeckButton from '../../global/components/helper-components/components/LaunchdeckButton'

// import LaunchdeckLogo from '../../global/styles/images/launchdeck_logo_white.svg';

function DsrHeaderMobileDisplay({
  isEditMode,
  isSellingTeamMember,
  handleViewTeamMembers,
  currentDsrTitle,
  // Action
  changeDsrPopupState,
  changeDsrModalState
}) {


  if (isEditMode) {
    // Currently do not allow users to edit drafts in mobile mode
    return null
  } else {
    if (isSellingTeamMember === true) {
      return (
        <>
          <Flex className='dsr-header__section'>
            <Flex className='dsr-header__container' alignItems='center'>
              <Flex justify='flex-start' align='center'>
                <Box display={['flex', 'flex', 'none', 'none']}>
                  <DsrPageSideMenuDrawer />
                </Box>
                <Box
                  className="page-title"
                  fontSize={['1em','1em','1.25em','1.25em']} fontWeight='600' lineHeight='1.85em' color='white' ml='1em'>
                  Manage Room
                </Box>
              </Flex>
              <Flex>
                
              <Box rounded='0.25em'>
                <LaunchdeckButton width='100%' bg='brand.500' color='white' onClick={() => {

                  if (handleViewTeamMembers) {
                    handleViewTeamMembers()
                  } else {
                    changeDsrPopupState('view-team-members')
                    changeDsrModalState(true)
                  }
                }} label='Share' />

              </Box>
              </Flex>
            </Flex>
          </Flex>

          <Flex className='dsr-content__dsr-top-banner' py='1.5em'>
            <Flex flexDirection={['column', 'column', 'row', 'row']} justifyContent='space-between' width='100%' alignItems='center' p='0 1em'>
              <Flex flexDirection={['column', 'column', 'row', 'row']} alignItems='center' >
                <Flex>
                  <DsrManageOrgLogosModule logoDisplayMode={'default-dsr'} />
                </Flex>
                {currentDsrTitle ?
                  <Flex fontWeight='600' fontSize='1.25em' lineHeight='1.875em' justify='flex-start' textAlign='center'>
                    {currentDsrTitle}
                  </Flex>
                  : <Skeleton className="loading-skeleton-bar"><Flex fontSize='1.25em' width={["5em", "5em", "8em", "8em"]}></Flex></Skeleton>}
              </Flex>
            </Flex>
          </Flex>
        </>
      )
    }
    else if (isSellingTeamMember === false) {
      return (
        <>
          <Flex className='dsr-header__section'>
            <Flex className='dsr-header__container' alignItems='center'>
              <Flex justify='flex-start' align='center'>
                <DsrPageSideMenuDrawer />
              </Flex>

                <Box rounded='0.25em'>
                  <LaunchdeckButton width='100%' bg='brand.500' color='white'  onClick={() => {
                    if (handleViewTeamMembers) {
                      handleViewTeamMembers()
                    } else {
                      changeDsrPopupState('view-team-members')
                      changeDsrModalState(true)
                    }
                  }} label='Share' />
                </Box>
            </Flex>
          </Flex>

          <Flex className='dsr-content__dsr-top-banner' py='1.5em'>
            <Flex flexDirection={['column', 'column', 'row', 'row']} justifyContent='space-between' width='100%' alignItems='center' p='0 1em'>
              <Flex flexDirection={['column', 'column', 'row', 'row']} alignItems='center' >
                <Flex>
                  <DsrManageOrgLogosModule logoDisplayMode={'default-dsr'} />
                </Flex>
                {currentDsrTitle ?
                  <Flex fontWeight='600' fontSize='1.25em' lineHeight='1.875em' justify='flex-start' textAlign='center'>
                    {currentDsrTitle}
                  </Flex>
                  : <Skeleton className="loading-skeleton-bar"><Flex fontSize='1.25em' width={["5em", "5em", "8em", "8em"]}></Flex></Skeleton>}
              </Flex>
            </Flex>
          </Flex>
        </>
      )
    } else {
      return null;
    }
  }

}


const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      changeDsrPopupState,
      changeDsrModalState
    },
    dispatch,
  )

export default memo(withRouter(connect(null, mapDispatchToProps)(DsrHeaderMobileDisplay)))