import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { createStripeCustomerPortalSession } from '../actions/payments-actions';
import LaunchdeckButton from '../../global/components/helper-components/components/LaunchdeckButton';
import { Flex } from '@chakra-ui/react';


function StripeCustomerPortalButton(props) {

  const { createStripeCustomerPortalSession } = props.action;

  const handleClick = async (event) => {
    // Call backend to create the Customer Portal session.
    const session = await createStripeCustomerPortalSession();
    const url = session ? session.url : "";
    if (session) {
      window.open(url)
    } else return null;

  };

  return (
    <Flex direction='column' justify='center' alignItems='center'>
      <Flex justify={['center', 'flex-end', 'flex-end', 'flex-end']} w='100%'></Flex>
      <LaunchdeckButton bg='brand.500' color='gray.50' onClick={handleClick} label='Manage Billing' />
    </Flex>
  );
}


function mapDispatchToProps(dispatch) {
  return {
    action: bindActionCreators(
      {
        createStripeCustomerPortalSession
      },
      dispatch
    )
  };
}


export default withRouter(connect(null, mapDispatchToProps)(StripeCustomerPortalButton));