import React, { memo, useEffect, useRef, useState } from 'react'

import { Box, Divider, Flex, Tooltip } from '@chakra-ui/react'
import '../styles/dsr-display.scss'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { displayReactIcon } from '../helpers/dsr-display-helpers';

import { displayBrandingLabel } from '../../global/helpers/global-helpers'
import { RiQuestionFill } from 'react-icons/ri'


function DsrPageSideMenu({
  pageArray,
  selectedDisplaySubpage,
  selectedDisplayPageId,
  handleSelectSubpage,
  isDsrAdmin,
  closeMenuDrawer,
  headerMenuHeight,
  isPagePreviewMode,
  userPrivileges,
  isProspector,
}) {

  const initRef = useRef(false);
  const [showBranding, setShowBranding] = useState(false)

  useEffect(() => {
    if (userPrivileges && !initRef.current) {
      const { displayBrand } = userPrivileges || {}
      if (displayBrand === true || displayBrand === false) {
        setShowBranding(displayBrand)
        initRef.current = true
      }

    }
  }, [userPrivileges]);

  const mutualActionPlanTab = () => {
    return (
      <Flex justify='flex-start' align='center' px='1.1em' py='0.875em'
        className={(selectedDisplaySubpage === `action-list-subpage`) ? "chosen-subpage-option" : "unchosen-subpage-option"}
        onClick={() => {
          handleSelectSubpage("", 'action-list-subpage')
          closeMenuDrawer && closeMenuDrawer()
        }}
      >

        {displayReactIcon('circle-check', '1.5em')}
        <Box lineHeight='1.5em'>  Mutual Action Plan</Box>
      </Flex>
    )
  }

  const docFolderTab = () => {
    return (
      <Flex justify='flex-start' align='center' px='1.1em' py='0.875em'
        className={(selectedDisplaySubpage === `shared-docs-folder-subpage`) ? "chosen-subpage-option" : "unchosen-subpage-option"}
        onClick={() => {
          handleSelectSubpage("", 'shared-docs-folder-subpage')
          closeMenuDrawer && closeMenuDrawer()
        }}
      >

        {displayReactIcon('folder', '1.5em')}
        <Box lineHeight='1.5em'>Shared Documents</Box>
      </Flex>
    )
  }

  const updatesPageTab = () => {
    return (
      <Flex justify='flex-start' align='center' px='1.1em' py='0.875em'
        className={(selectedDisplaySubpage === "updates-subpage") ? "chosen-subpage-option" : "unchosen-subpage-option"}
        onClick={() => {
          handleSelectSubpage("", 'updates-subpage')
          closeMenuDrawer && closeMenuDrawer()
        }}
      >
        {displayReactIcon('star', '1.5em')}
        <Box lineHeight='1.5em'> Updates</Box>

      </Flex>
    )
  }

  const renderAdminPageTabs = () => {
    return (
      <Box mt='3em'>
        {categoryLabel('ADMINISTRATOR PAGES', 'Info accessible only by Room Administrators')}
        <Flex justify='flex-start' align='center' px='1.1em' py='0.875em'
          className={(selectedDisplaySubpage === "insights-subpage") ? "chosen-subpage-option" : "unchosen-subpage-option"}
          onClick={() => {
            handleSelectSubpage("", 'insights-subpage')
            closeMenuDrawer && closeMenuDrawer()
          }}
        >
          {displayReactIcon('worktool', '1.5em')}
          <Box lineHeight='1.5em' ml='1em'> Insights</Box>

        </Flex>

        <Flex justify='flex-start' align='center' px='1.1em' py='0.875em'
          className={(selectedDisplaySubpage === "team-notes-subpage") ? "chosen-subpage-option" : "unchosen-subpage-option"}
          onClick={() => {
            handleSelectSubpage("", 'team-notes-subpage')
            closeMenuDrawer && closeMenuDrawer()
          }}
        >
          {displayReactIcon('worktool', '1.5em')}
          <Box lineHeight='1.5em' ml='1em'> Team Notes</Box>

        </Flex>

        <Flex justify='flex-start' align='center' px='1.1em' py='0.875em'
          className={(selectedDisplaySubpage === "room-settings-subpage") ? "chosen-subpage-option" : "unchosen-subpage-option"}
          onClick={() => {
            handleSelectSubpage("", 'room-settings-subpage')
            closeMenuDrawer && closeMenuDrawer()
          }}
        >
          {displayReactIcon('worktool', '1.5em')}
          <Box lineHeight='1.5em' ml='1em'> Settings</Box>

        </Flex>
      </Box>
    )
  }

  const categoryLabel = (labelText, toolTipText) => {
    return (
      <Flex mt='1em' justify='flex-start' align='center' color='gray.500' cursor='default' className='unchosen-subpage-option'>
        <Box fontSize='0.75em' fontWeight='600' whiteSpace='pre'>{labelText}</Box>
        <Tooltip fontSize='0.8em' label={toolTipText} placement="auto-start">
          <Box ml='0.5em'>
            <Box as={RiQuestionFill} boxSize="1em" py='3%' />
          </Box>
        </Tooltip>
      </Flex>
    )
  }

  function renderSubPageTabs(category) {
    if (!pageArray) {
      return null
    }

    let pagesToRender = [];

    if (category === 'essential-pages') {
      pagesToRender = pageArray?.filter(tabs => tabs.pageType !== 'customised-page')
    } else if (category === 'custom-pages') {
      pagesToRender = pageArray?.filter(tabs => tabs.pageType === 'customised-page')
    }

    if (pagesToRender) {
      const pageTabs = pagesToRender?.map((tabs, tabIndex) => {
        const { pageId, pageTitle, pageType, displayPage, lockedPage } = tabs || {};

        if (displayPage === true) {
          if (pageType === 'customised-page') {
            if (lockedPage) {
              // UUU TODO: Have UI to suggest page is locked when user tries to click it
              return (
                <Flex key={`dsr_page_tab_${tabIndex}-${pageId}`} justify='flex-start' align='center' px='1.1em' py='0.875em'
                  className="locked-subpage"
                >
                  <Flex justify='flex-start' align='center'>
                    <Box>{displayReactIcon("lock", '1.5em', displayPage)}</Box>
                    <Box whiteSpace='pre' minW='11em'>{pageTitle}</Box>
                  </Flex>
                </Flex>
              )
            } else {
              return (
                <Flex key={`dsr_page_tab_${tabIndex}-${pageId}`} justify='flex-start' align='center' px='1.1em' py='0.875em'
                  className={(selectedDisplayPageId === `${pageId}`) ? "chosen-subpage-option" : "unchosen-subpage-option"}
                  onClick={() => {
                    handleSelectSubpage(pageId, "")
                    closeMenuDrawer && closeMenuDrawer()
                  }}
                >
                  <Flex justify='flex-start' align='center'>
                    <Box>{displayReactIcon(pageType, '1.5em', displayPage)}</Box>
                    <Box whiteSpace='pre-wrap' minW='11em'>{pageTitle}</Box>
                  </Flex>
                </Flex>
              )
            }

          } else {
            // The first page is the default overview page, then also display the updates page alongside it. Let Overview be the first page to show by default.
            if (tabIndex === 0) {
              return (
                <React.Fragment key={`dsr_page_tab_${tabIndex}-${pageId}`}>
                  <Flex justify='flex-start' align='center' px='1.1em' py='0.875em'
                    className={(selectedDisplayPageId === `${pageId}`) ? "chosen-subpage-option" : "unchosen-subpage-option"}
                    onClick={() => {
                      handleSelectSubpage(pageId, "")
                      closeMenuDrawer && closeMenuDrawer()
                    }}
                  >
                    {displayReactIcon(pageType, '1.5em')}
                    <Box>{pageTitle}</Box>
                  </Flex>

                  {updatesPageTab()}
                </React.Fragment>
              )

            } else {
              return (
                <Flex key={`dsr_page_tab_${tabIndex}-${pageId}`} justify='flex-start' align='center' px='1.1em' py='0.875em'
                  className={(selectedDisplayPageId === `${pageId}`) ? "chosen-subpage-option" : "unchosen-subpage-option"}
                  onClick={() => {
                    handleSelectSubpage(pageId, "")
                    closeMenuDrawer && closeMenuDrawer()
                  }}
                >
                  {displayReactIcon(pageType, '1.5em')}
                  <Box>{pageTitle}</Box>
                </Flex>
              )
            }

          }
        } else {
          return null;
        }
      }
      )

      if (category === 'essential-pages') {
        return (
          <React.Fragment>
            {pageTabs}
            {!isProspector && <>
              {mutualActionPlanTab()}
              {docFolderTab()}
            </>
            }
          </React.Fragment>
        )
      } else if (category === 'custom-pages') {
        if (pageTabs?.length > 0) {
          if (isProspector) {
            return (
              <>
              <Box pt='1.5em'>
                {pageTabs}
                </Box>
              </>
            )
          } else {
            return (
              <>
                <Box p='1em 25% 1em 20%'>
                  <Divider color='gray.50'/>
                </Box>
                {pageTabs}
              </>
            )
          }
         
        } else return null;
       
      } else return null;
    }

    else return null
  }

  return (
    <Flex
      flexWrap='wrap'
      justify='flex-start'
      pt='2em'
      maxWidth='280px'
      height={`calc(100vh - ${headerMenuHeight})`}
      overflowX="hidden"
      overflowY="auto"
    >
      <Flex direction='column' justify='space-between' align='flex-start' maxWidth='fit-content'>
        <Box>
          {/* {updatesPageTab()} */}
          {renderSubPageTabs('essential-pages')}
          {/* {mutualActionPlanTab()} */}

          {renderSubPageTabs('custom-pages')}
        </Box>

        <React.Fragment>
          <Box>
            {(isDsrAdmin && !isPagePreviewMode) &&
              <React.Fragment>
                {renderAdminPageTabs()}
              </React.Fragment>
            }
          </Box>
          <Flex w='100%' display={['none', 'none', "flex", "flex"]}>
            {showBranding && displayBrandingLabel('web')}
          </Flex>
        </React.Fragment>
      </Flex>
    </Flex>
  )


}

const mapStateToProps = (state) => {
  return {
    selectedDisplayPageId: state.dsrPage.selectedDisplayPageId,
    selectedDisplaySubpage: state.dsrPage.selectedDisplaySubpage,
    isDsrAdmin: state.dsrAdmin.isDsrAdmin,
    userPrivileges: state.userPrivileges.results
  }
}


export default memo(withRouter(connect(mapStateToProps, null)(DsrPageSideMenu)))

