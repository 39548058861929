import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import _ from 'lodash'

import { redirectToNewPage } from '../../../global/helpers/global-helpers'
import { removeEmptyWidgets } from "../../../digital-sales-room/helpers/dsr-draft-helpers";

import { Box, Flex } from '@chakra-ui/react'
import DsrGenericErrorModal from '../../components/DsrGenericErrorModal';
import DsrProspectHeader from '../DsrProspectHeader';
import DsrDynamicModal from '../../messages/DsrDynamicModal';
import RenderProspectDraftContent from './RenderProspectDraftContent';
import PageLoader from '../../../global/components/helper-components/components/PageLoader';
import LaunchdeckButton from '../../../global/components/helper-components/components/LaunchdeckButton';
import RenderProspectPreviewContent from "../../dsr-draft/RenderProspectPreviewContent";
import DsrUnsavedChangePrompt from '../../dsr-draft/DsrUnsavedChangePrompt';


export const ProspectEditDraftPage = ({
  updatePdsr,
  getProspectDsr,
  dsrPagesRedux,
  dsrStatusRedux,
  showDsrModalRedux,
  userActionRequiredRedux,
  userProfileRedux,
  showDsrModalLoaderRedux,
  dsrTitleRedux,
  resetDsrTitle,
  resetDsrSelectedDisplaySubpage,
  resetDsrPages,
  updatePdsrReset,
  dsrInvalidRequestRedux,
  changeDsrPopupState,
  changeDsrModalState,
  signOut,
  isMobileView
}) => {
  const { pDsrId } = useParams()
  const initRef = useRef(false);

  let [pdsrPagesLocalState, setPdsrPagesLocalState] = useState(null)
  let [dsrHasUnsavedChanges, setDsrHasUnsavedChanges] = useState(false)
  let [pageLoadingState, setPageLoadingState] = useState(true)
  let [isPagePreviewMode, setIsPagePreviewMode] = useState(false)
  const clearedPdsrPagesLocalState = removeEmptyWidgets(pdsrPagesLocalState)


  useEffect(() => {
    if (!initRef.current && pDsrId !== 'new') {
      document.title = 'Launchdeck Digital Sales Room'
      document.description = 'Create customised private digital sales rooms that let Sales & Purchasing teams agree on deals quickly'

      getProspectDsr({ pDsrId, status: 'draft' })
      initRef.current = true
    }
  }, [pDsrId, getProspectDsr])

  useEffect(() => {
    if (((!pDsrId) || (dsrInvalidRequestRedux === true)) && initRef.current) {
      redirectToNewPage('/invalid') 
    }
  }, [pDsrId, dsrInvalidRequestRedux])


  useEffect(() => {
    if (dsrPagesRedux) {
      setPdsrPagesLocalState(_.cloneDeep(dsrPagesRedux))
      setPageLoadingState(false)
    }

  }, [dsrPagesRedux])

  useEffect(() => {
    if (pdsrPagesLocalState) setDsrHasUnsavedChanges(!_.isEqual(pdsrPagesLocalState, dsrPagesRedux))
  }, [dsrPagesRedux, pdsrPagesLocalState, setDsrHasUnsavedChanges])

  useEffect(() => {
    const handleBeforeUnload = event => {
      if (dsrHasUnsavedChanges) {
        event.preventDefault();
        event.returnValue = ''; // This is necessary for the browser to display the confirmation prompt
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [dsrHasUnsavedChanges])

  const handleDsrModalClose = () => {
    changeDsrPopupState('')
    changeDsrModalState(false)
  }


  // PDSR status Management
  const handlePublishDsr = () => {
    if (dsrStatusRedux === 'draft') {
      changeDsrModalState(true)
      changeDsrPopupState('publish-prospect-dsr')
    }

    if (dsrStatusRedux === 'reverted-draft') {
      changeDsrModalState(true)
      changeDsrPopupState('re-publish-prospect-dsr')
    }
  }

  const handlerSetDsrPages = (pages) => {
    setPdsrPagesLocalState(pages)
  }

  const handlerAddDsrPage = (newDsrPage) => {
    setPdsrPagesLocalState((prevState) => [...prevState, newDsrPage])
  }

  const handlerRemoveDsrPage = (dsrPageId) => {
    setPdsrPagesLocalState((prevState) => prevState.filter((page) => page.pageId !== dsrPageId))
  }

  useEffect(() => {
    return () => resetDsrTitle() && resetDsrSelectedDisplaySubpage() && resetDsrPages() && updatePdsrReset()
  }, [resetDsrTitle, resetDsrSelectedDisplaySubpage, resetDsrPages, updatePdsrReset])

  if (isMobileView) {
    return (
      <Flex margin='-2% -7% -7% -7%' height='100vh' justifyContent='center' alignItems='center' background='white' flexDirection='column'>
         <Flex mb='0.625em' px='2em' justify='center' textAlign='center'>
          For the best experience, we recommend editing the Room using a computer's wider display
        </Flex>
        <Flex direction='column' mt='1em' justify='center' alignItems='center'>
          <Flex justify={['center', 'flex-end', 'flex-end', 'flex-end']} w='100%'>
            <LaunchdeckButton bg='brand.500' color='white' onClick={() => window.location.replace('/home')} label='Edit on a computer later' />
          </Flex>
        </Flex>
      </Flex>
    )
  }

  return (
    <Flex direction="column" height='100%'>
      <Flex direction="row" flexGrow="1">
    
        <Flex w="100%" direction="column" alignItems="center" bg="white">
        {pageLoadingState ? <PageLoader /> :
        <React.Fragment>
            <DsrUnsavedChangePrompt
            when={dsrHasUnsavedChanges}
            // dsrTitle={dsrTitle}
            // message="You have unsaved changes. Are you sure you want to leave?"
          />

          <Box width='100%' position='fixed' zIndex='2'>
            <DsrProspectHeader
              pDsrId={pDsrId}
              dsrTitle={dsrTitleRedux ? dsrTitleRedux : "A New Prospect Room"}
              updatePdsr={updatePdsr}
              pdsrPagesLocalState={pdsrPagesLocalState}
              handlePublishDsr={handlePublishDsr}
              userProfile={userProfileRedux}
              signOut={signOut}
              dsrStatus={dsrStatusRedux}
              isPagePreviewMode={isPagePreviewMode}
              setIsPagePreviewMode={setIsPagePreviewMode}
            />
          </Box>

      
          <Flex w="100%" className="authentication-container" marginTop='140px' justify="center">
            { isPagePreviewMode
                ? (
                    <RenderProspectPreviewContent
                        dsrId={pDsrId}
                        pagesLocalState={pdsrPagesLocalState}
                        dsrTitle={dsrTitleRedux ? dsrTitleRedux : "A New Prospect Room"}
                    />
                )
                : (
                    <RenderProspectDraftContent
                        pagesLocalState={pdsrPagesLocalState}
                        replaceDsrPages={handlerSetDsrPages}
                        addDsrPage={handlerAddDsrPage}
                        removeDsrPage={handlerRemoveDsrPage}
                    />
                )
            }
            <DsrGenericErrorModal dsrId={pDsrId} />

            <DsrDynamicModal
              pDsrId={pDsrId}
              userActionRequired={userActionRequiredRedux}
              showDsrModal={showDsrModalRedux}
              showDsrModalLoader={showDsrModalLoaderRedux}
              handleDsrModalClose={handleDsrModalClose}
              dsrPages={clearedPdsrPagesLocalState}
            />

          </Flex>
          </React.Fragment>
        }
        </Flex>
      </Flex>
    </Flex>
  )
}
