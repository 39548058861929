import React from "react";

import { Box, Flex, Avatar } from "@chakra-ui/react";


export default function DsrSignatureDisplay({ contentBlock }) {

  const { authorName, authorProfilePicSrc, authorPosition, sellerOrganisationName, authorCompanyName } = contentBlock || {};

  return (
    <React.Fragment>
      <Flex align='flex-start' mt='1em'>
        {authorProfilePicSrc && 
        <Flex as='span' px='0.5em'>
          <Avatar h='4.2em' w='4.2em' name={authorName} bg='gray.100' color='gray.700' src={authorProfilePicSrc} />
        </Flex>
        }

        <Flex className="white-space-preline">
          <Box fontWeight='600' fontSize='0.875em' lineHeight='1.25em' color='brandDark.500'>
            {authorName}
          
              <Box fontSize='0.8em' lineHeight='1em' fontWeight='400' color='gray.500' mt='2px'>
                {authorPosition} {sellerOrganisationName ? `@ ${sellerOrganisationName}` : authorCompanyName ? `@ ${authorCompanyName}` : ""}
              </Box>
           
          </Box>
        </Flex>
      </Flex>
    </React.Fragment>
  );

}
