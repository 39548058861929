import { theme, extendTheme } from "@chakra-ui/react";
import { tableTheme } from "./launchdeckTableTheme.ts";

const claverTheme = extendTheme({
  // ...theme,
  components: {
    // ...theme.components,
    Form: {
      // ...theme.components.Form,
      variants: {
        floating: {
          container: {
            _focusWithin: {
              label: {
                transform: "scale(0.85) translateY(-24px)",
                color: "#2D3748",
                fontWeight: "700",
              },
            },
            "input:not(:placeholder-shown) + label, .chakra-select__wrapper + label, textarea:not(:placeholder-shown) ~ label":
              {
                transform: "scale(0.85) translateY(-24px)",
              },
            label: {
              top: 0,
              left: 0,
              zIndex: 2,
              position: "absolute",
              backgroundColor: "white",
              pointerEvents: "none",
              mx: 3.5,
              px: 4,
              my: 7,
              transformOrigin: "left top",
            },
          },
        },
      },
    },
    Table: tableTheme,
  },
  breakpoints: ["30em", "48em", "62em", "80em"],
  fonts: {
    body: "'Inter', sans-serif",
    heading: "'Inter', sans-serif",
    // body: "'Plus Jakarta Sans', sans-serif",
    // heading: "'Plus Jakarta Sans', sans-serif",
    // heading: '"Avenir Next", sans-serif',
    // body: '"Avenir Next", sans-serif',
    mono: "Menlo, monospace",
  },
  fontSizes: {
    xs: "0.75rem",
    sm: "0.875rem",
    md: "1rem",
    lg: "0.9em", // Hack for expandable Textarea
    // lg: "1.125rem", (original)
    xl: "1em",
    // xl: "1.25rem", (original)
    "2xl": "1.5rem",
    "3xl": "1.875rem",
    "4xl": "2.25rem",
    "5xl": "3rem",
    "6xl": "4rem",
  },
  colors: {
    ...theme.colors,
    brand: {
      50: "#dff4ff",
      100: "#b3dcfe",
      200: "#87c3f7",
      300: "#5aabf1",
      400: "#2d92ec",
      500: "#29ABE2",
      600: "#085ea4",
      700: "#014377",
      800: "#00284a",
      900: "#000e1e",
    },
    brandDark: {
      // 50: "#dff4ff",
      // 100: "#b3dcfe",
      // 200: "#87c3f7",
      // 300: "#5aabf1",
      // 400: "#2d92ec",
      500: "#2D3748",
      // 600: "#085ea4",
      // 700: "#014377",
      // 800: "#00284a",
      // 900: "#000e1e",
    },
    brandPink: {
      500: "#FF2847",
    },
    brandGreen: {
      // 50: "#dff4ff",
      // 100: "#b3dcfe",
      // 200: "#87c3f7",
      // 300: "#5aabf1",
      // 400: "#2d92ec",
      500: "#48BB78",
      // 600: "#085ea4",
      // 700: "#014377",
      // 800: "#00284a",
      // 900: "#000e1e",
    },
  },
  styles: {
    global: {
      // ul: {
      //   listStylePosition:
      //     "inside" /* Align bullet points inside the list item */,
      // },
      // ol: {
      //   listStylePosition:
      //     "inside" /* Align bullet points inside the numbered list item */,
      // },
      h1: {
        fontSize: "2em",
      },
      h2: {
        fontSize: "1.5em",
      },
      h3: {
        fontSize: "1.17em",
      },
      h4: {
        fontSize: "1em",
      },
      h5: {
        fontSize: "0.83em",
      },
      h6: {
        fontSize: "0.67em",
      },
    },
  },
});

export default claverTheme;
