import React from 'react';
import { Box, Text } from '@chakra-ui/react';


export default function HighlightBanner(props) {
  return (
    <Box border='1px' borderColor='brandGreen.500' rounded={8} bg='white' mb='1em' w='100%' px={['3%', '', '', '1.5em']} py={['3%', '', '', '1em']}>
      <Text fontSize='0.875em' textAlign='center'> <span role="img" aria-label="Sunglasses">😎</span> {props.children}</Text>
    </Box>
  )
}




