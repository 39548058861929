
import { setCookie } from './cookie-helpers';

export function manageUrlTags(urlStringQuery) {
  if (urlStringQuery) {
    const urlTags = new URLSearchParams(urlStringQuery);

    // Manage unique Marketing User ID
    const muid = urlTags.get('muid');
    if (muid) {
      setCookie('cvr_muid', muid, 7)
    }
  }
}

export function manageDsrUrlTags(urlStringQuery, paramName) {
  if (urlStringQuery) {
    const urlTags = new URLSearchParams(urlStringQuery);
    const tagValue = urlTags.get(paramName);

    switch (paramName) {
      case('view'): {
        return `${tagValue}-subpage`;
      }
      case('uid'): {
        return `${tagValue}`;
      }
      default: {
        return "";
      }
    }

  } else return null;
}

export function queryParamArrayParser(fieldName, arrayOfStringValues) {
  
  if (Array.isArray(arrayOfStringValues)) {
  
    if (arrayOfStringValues.length > 0) {
      let parsedUrlSection = '';

      arrayOfStringValues.map((value) => {
        if (parsedUrlSection !== '') {
          parsedUrlSection = parsedUrlSection + `&&${fieldName}[]=${value}` 
        } else {
          parsedUrlSection = parsedUrlSection + `?${fieldName}[]=${value}` 
        }

        return null; // No value needs to be returned
      })
  
      return parsedUrlSection;
    
    } else {
  
      return "" 
    }
    
  }
}
