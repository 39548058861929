import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import WarningTextMessage from '../../../global/components/helper-components/components/WarningTextMessage';


import FormTextInput from '../../../forms/TextInputField/components/FormTextInput';

export default function AppAdminCreateUserForm({
	handleChange,
	localPageState,
	isInvalidEmail
}) {

	const {emailInput, nameInput} = localPageState || {};

	return (
		<Box my='2em' w='100%' gap='0.5em'>	
				<Box mb='0.5em'>
					<FormTextInput name={'emailInput'} label={'User email'} sublabel={''} htmlFor={'emailInput'} mb='0.2em' value={emailInput && emailInput} onChange={handleChange} />
					<WarningTextMessage errorLogic={isInvalidEmail} message='Please provide a valid email' />
				</Box>

				<Box mb='0.5em'>
					<FormTextInput name={'nameInput'} label={'User name'} sublabel={''} mb='0.2em' htmlFor={'nameInput'} value={nameInput && nameInput}  onChange={handleChange} />
					<Text visibility='hidden' fontSize='0.8em'>	&nbsp;</Text>
				</Box>

				<Box />

		</Box>
	);
};

