import { Box } from '@chakra-ui/react'
import { renderDraftEmptyCustomisedPage, renderPageContent, reorderPinnedContentFirst } from '../helpers/dsr-helpers'
import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import DsrActionListPage from '../dsr-action-list/DsrActionListPage'
import OverviewDraftPage from './overview/OverviewDraftPage'
import DsrInsightsPage from '../dsr-analytics/DsrInsightsPage'
import DsrTeamNotesPage from '../dsr-analytics/DsrTeamNotesPage'
import DsrRoomSettingsPage from '../dsr-analytics/DsrRoomSettingsPage'
import DsrSharedDocsPage from '../dsr-shared-docs/DsrSharedDocsPage';

function RenderDraftSubpageContent({
  dsrPagesLocalState,
  selectedDisplaySubpage,
  selectedDisplayPageId,
  dsrId,
  dsrLastUpdated,
  dsrTeamMembersRedux,
  dsrProperties,
  dsrActionList,
  replacePages,
  handleChangeUserActionRequired
}) {
  const pageArray = dsrPagesLocalState;

  const [fixedSubpageToDisplay, setFixedSubpageToDisplay] = useState("")
  const [pageToDisplay, setPageToDisplay] = useState(null)
  // Initialize

  useEffect(() => {
    if (pageArray) {
      let foundPageToDisplay = "";

      if (!selectedDisplayPageId) {
        foundPageToDisplay = pageArray?.filter(page => page.pageType === "overview");

      } else if (selectedDisplayPageId) {
        if (selectedDisplayPageId === "no-visible-pages") {
          // Scenario: When user go from edit to preview and back to edit, and inherit the 'no-visible-pages' page id value
          foundPageToDisplay = pageArray?.filter(page => page.pageId === pageArray[0].pageId);
          setPageToDisplay(foundPageToDisplay[0])
        } else {
          foundPageToDisplay = pageArray?.filter(page => page.pageId === selectedDisplayPageId);
        }
       
      }

      if (foundPageToDisplay) {
        setPageToDisplay(foundPageToDisplay[0])
      }
    }

  }, [pageArray, selectedDisplayPageId]);

  useEffect(() => {
    // For MAP page
    if (pageArray) {

      if (selectedDisplaySubpage === "overview-subpage") {
        const foundPageToDisplay = pageArray?.filter(page => page.pageType === "overview");

        if (foundPageToDisplay) {
          setPageToDisplay(foundPageToDisplay[0])
        }
      } else {
        setFixedSubpageToDisplay(selectedDisplaySubpage ? selectedDisplaySubpage : "")
      }

    } else {
      setFixedSubpageToDisplay("")
    }
  }, [selectedDisplaySubpage, pageArray])


  // Render Content
  const updateDsrPages = (newPage) => {
    if (newPage) {
      const updatedPages = dsrPagesLocalState.map((page) => (page.pageId !== newPage.pageId ? page : newPage))

      replacePages(updatedPages)
    }
  }

  const pickAndRenderPage = () => {
    if (fixedSubpageToDisplay) {
      switch (fixedSubpageToDisplay) {
        case 'action-list-subpage': {
          return (
            <DsrActionListPage
              dsrId={dsrId}
              dsrActionList={dsrActionList}
              dsrTeamMembers={dsrTeamMembersRedux}
            />
          )
        }
        case ('insights-subpage'): {
          return (
            <DsrInsightsPage
              dsrId={dsrId}
            />
          )
        }
        case ('team-notes-subpage'): {
          return (
            <DsrTeamNotesPage
              dsrId={dsrId}
              dsrProperties={dsrProperties}
            />
          )
        }
        case ('room-settings-subpage'): {
          return (
            <DsrRoomSettingsPage
              dsrId={dsrId}
              dsrProperties={dsrProperties}
            />
          )
        }

        case ('shared-docs-folder-subpage'): {
          return (
            <DsrSharedDocsPage
              dsrId={dsrId}
              dsrTeamMembers={dsrTeamMembersRedux}
              handleChangeUserActionRequired={handleChangeUserActionRequired}
              // handleOpenSignupOrLoginModal={handleOpenSignupOrLoginModal}
              // isPagePreviewMode={isPagePreviewMode}
            />
          )
        }
        case ('overview-subpage'):
        case ('updates-subpage'): {
          return null
        }
        default: {
          console.log(`There is no subpage type: ${fixedSubpageToDisplay}`)
          return null;
        }

      }
    } else if (!fixedSubpageToDisplay && pageToDisplay) {
      // if (pageToDisplay === "no-visible-pages") {
      //   return (
      //     <EmptyStateStandardDisplay
      //     reactIcon={MdOutlineInsertPageBreak}
      //     infoText={'There are no pages to display in this Room'}
      //   />
      //   )
      // }
      const { pageType, pageTitle, pageContent } = pageToDisplay || {}
      switch (pageType) {
        case 'overview':
          const {
            pageSubTitle,
            mainContactUserId,
            loomWelcomeVideo,
            textTileTitle,
            textTileDescription
          } = pageToDisplay || {}

          return (
            <OverviewDraftPage
              dsrPagesLocalState={dsrPagesLocalState}
              pageContent={pageContent}
              pageTitle={pageTitle}
              pageSubTitle={pageSubTitle}
              mainContactUserId={mainContactUserId}
              dsrTeamMembers={dsrTeamMembersRedux}
              loomWelcomeVideo={loomWelcomeVideo}
              textTileTitle={textTileTitle}
              textTileDescription={textTileDescription}
              dsrLastUpdated={dsrLastUpdated}
            />
          )
        case 'customised-page':
          return (
            <CustomizedPage
              page={pageToDisplay}
              updateDsrPages={updateDsrPages}
              pageTitle={pageTitle}
            />
          )
        case 'faq':
          return (
            <Faq
              pageContent={pageContent}
              pageTitle={pageTitle}
            />
          )
        case 'customer-references':
          return (
            <CustomerReferences
              pageContent={pageContent}
              pageTitle={pageTitle}
            />
          )
        default: {
          console.log('There is no pageContent type:', pageType)
          return null
        }
      }
    }

  }


  return <>
    {pickAndRenderPage()}
  </>
}


const CustomizedPage = ({ page, updateDsrPages }) => {
  return (
    <Box >
      {renderDraftEmptyCustomisedPage(updateDsrPages, page)}
    </Box>
  )
}
const Faq = ({ pageContent }) => {
  return (
    <Box>
      {renderPageContent(pageContent)}
    </Box>
  )
}
const CustomerReferences = ({ pageContent, pageTitle }) => {
  // Reorder array to display pinned content first, if applicable
  const pinnedPageContent = reorderPinnedContentFirst(pageContent)

  return (
    <Box>
      <Box mb="1.25em" fontWeight="600" fontSize="1.5em" lineHeight="2em" color="brandDark.500">
        {pageTitle}
      </Box>
      {renderPageContent(pinnedPageContent)}
    </Box>
  )
}

const mapStateToProps = (state) => ({
  selectedDisplaySubpage: state.dsrPage.selectedDisplaySubpage,
  dsrTeamMembersRedux: state.dsrTeamMembers.members,
  selectedDisplayPageId: state.dsrPage.selectedDisplayPageId
})

export default withRouter(connect(mapStateToProps)(RenderDraftSubpageContent))
