import React, { useEffect, useState } from 'react';

import '../styles/header.scss';
import { MdKeyboardArrowDown } from "react-icons/md";
import {
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	MenuGroup,
	Text,
	Box,
	Flex,
	Divider,
	useMediaQuery
} from "@chakra-ui/react";
import NavigationMenuHeader from './NavigationMenuHeader';
import HeaderLogo from './HeaderLogo';
import { getUserAccountType } from '../../../../profile/helpers/user-helper';
import config from '../../../../../config/app-config';
import UserNotificationsModule from './UserNotificationsModule';


export default function DefaultHeader(props) {
	const { userAccountProfile, history } = props || {};
	const [displayedName, setDisplayedName] = useState('Welcome')
	const [allowAdminPages, setAllowAdminPages] = useState(false)
	const [showHowToGuide, setShowHowToGuide] = useState(false)
	let pathname = history ? history.location.pathname : '';
	const [isLargerThan1000] = useMediaQuery('(min-width: 1000px)')

	useEffect(() => {
		if (userAccountProfile) {
			const {
				firstName,
				lastName
			} = userAccountProfile || {}
			const userNameDisplay = firstName + " " + lastName

			setDisplayedName(userNameDisplay.trim())
		}
	}, [userAccountProfile])

	useEffect(() => {
		const userType = getUserAccountType();

		switch (userType) {
			case ("super-app-admin"): {
				setAllowAdminPages(true)
				setShowHowToGuide(true)
				break;
			}
			case ("active-sub-org-user"):
			case ("no-sub-genesis-org-admin"): {
				setShowHowToGuide(true)
				break;
			}
			default: {
				break;
			}
		}
	}, [])

	const openHowToGuide = () => {
		const { displayUrl } = config;
		window.open(`${displayUrl}/pdsr/launchdeck-how-to-guide`)
	}

	function renderNavigationMenu() {
		switch (pathname) {
			//"No distraction" pages
			case ('/welcome'):
			case ('/setup-profile'):
			case ('/profile/change-password'):
			case ('/organization/choose-plan'): {
				return null
			}
			default:
				return <NavigationMenuHeader isMobileView={!isLargerThan1000} />
		}
	}

	function renderAdminDropdown() {
		const onPressLogout = () => {
			props.onPressLogout()
			window.location.hash = 'logout';
		}

		switch (pathname) {
			//"No distraction" pages
			case ('/welcome'):
			case ('/setup-profile'):
			case ('/profile/change-password'):
			case ('/organization/choose-plan'): {
				return null
			}
			default:
				return (
					<Flex w={["", "", "30%", "15%"]} mr='2em' alignItems='center' justify='flex-end'>

						{pathname !== "/notifications" &&
							<Flex mr='0.5em' position='relative'>
								<UserNotificationsModule />
							</Flex>
						}
						<Menu ml='2em' placement="bottom-end">
							<MenuButton fontSize="0.9em" color='gray.600' >
								<Flex justify='space-apart' alignItems='center'>
									<Text mr='0.3em' width='max-content'>{displayedName} </Text>
									<Box as={MdKeyboardArrowDown} fontSize='1.2em' color="brand.500" />
								</Flex>
							</MenuButton>
							<MenuList minW='8em' px='0.5em'>
								<MenuGroup title='Profile' ml='0.5em' mr='4em' fontSize='0.875em'>
									<MenuItem color='gray.700' py='0.2em' onClick={() => props.history.push('/profile')}>
										<Text className="header__profile-info-type">
											My Account
										</Text>
									</MenuItem>

									<MenuItem py='0.2em' onClick={() => props.history.push('/support')} >
										<Text className="header__profile-info-type">
											Support
										</Text>
									</MenuItem>
								</MenuGroup>

								{!!allowAdminPages &&
									<MenuGroup title='Super App Admin' mt='2em' ml='0.5em' mr='4em' fontSize='0.875em'>
										<MenuItem py='0.2em' onClick={() => props.history.push('/saa/manage')} >
											<Text className="header__profile-info-type">
												User & Org Setup
											</Text>
										</MenuItem>

									</MenuGroup>
								}



								<Divider mt='2em' mb='0.5em' />

								<MenuGroup ml='0.5em' mr='4em' >
									{!!showHowToGuide &&
										<MenuItem py='0.2em' onClick={() => openHowToGuide()} >
											<Text className="header__profile-info-type">
												Launchdeck's How-To Guide
											</Text>
										</MenuItem>
									}
									<MenuItem onClick={onPressLogout} >
										<Box verticalAlign='center' className="header__profile-info-type">
											Log out
										</Box>
									</MenuItem>

								</MenuGroup>
							</MenuList>
						</Menu>

					</Flex>
				)
		}
	}



	return (
		<nav className={`${props.className} header header--default`}>
			<Flex w='100%' justify='center'>
				<Flex className='dsr-header-item-container'
				// w='100%' maxWidth='1366px' justify='space-between' align='center' 
				>
					<Box className="header__container">
						<HeaderLogo />
					</Box>

					<div className="header__container header__title">
						{renderNavigationMenu()}
					</div>

					{renderAdminDropdown()}
				</Flex>
			</Flex>
		</nav>
	);
}

