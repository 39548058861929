// Log analytics for buyers/guest
export const LOG_GUEST_DSR_ANALYTICS_REQUEST = 'log_buyer_dsr_analytics_request';
export const LOG_GUEST_DSR_ANALYTICS_SUCCESS = 'log_buyer_dsr_analytics_success';
export const LOG_GUEST_DSR_ANALYTICS_FAILURE = 'log_buyer_dsr_analytics_failure';

// Get DSR analytics
export const GET_DSR_ANALYTICS_REQUEST = 'get_dsr_analytics_request';
export const GET_DSR_ANALYTICS_SUCCESS = 'get_dsr_analytics_success';
export const GET_DSR_ANALYTICS_FAILURE = 'get_dsr_analytics_failure';

// Get DSR analytics specific details 
export const GET_DSR_ANALYTICS_CATEGORY_DETAILS_REQUEST = 'get_dsr_analytics_category_details_request';
export const GET_DSR_ANALYTICS_CATEGORY_DETAILS_SUCCESS = 'get_dsr_analytics_category_details_success';
export const GET_DSR_ANALYTICS_CATEGORY_DETAILS_FAILURE = 'get_dsr_analytics_category_details_failure';