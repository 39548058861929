import React, { useState, useEffect, useRef } from 'react';
import { useOutsideClick } from '@chakra-ui/react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';

import { Box, Flex, Grid, Text } from "@chakra-ui/react";
import FormTextInput from '../../forms/TextInputField/components/FormTextInput';
// import FormTextAreaInput from '../../forms/TextareaField/components/FormTextAreaInput';

import '../styles/dsr-display.scss';

import { updateDsrAction } from '../actions/dsr-actions';
import PageLoader from '../../global/components/helper-components/components/PageLoader';
import { AiFillCheckCircle } from 'react-icons/ai'
import { GoCircle } from 'react-icons/go'
import { GoChevronDown } from 'react-icons/go';
import LaunchdeckDatePicker from '../../forms/DatePickerField/components/LaunchdeckDatePicker';
import LaunchdeckButton from '../../global/components/helper-components/components/LaunchdeckButton';
import InputTextAreaExpandable from '../../forms/TextareaField/components/InputTextAreaExpandable';


function EditActionForm(props) {

  const { dsrId, mapCategoryId, actionItemToEdit, handleCloseEditActionModal, updateDsrActionOutcome, isMobileView } = props || {};
  const { actionId, actionStatus, actionTaskTitle, actionTaskDescription, actionDueDate } = actionItemToEdit || {};
  const titleCharacterLimit = 100;

  // Input management
  const [titleInput, setTitleInput] = useState(actionTaskTitle ? actionTaskTitle : "")
  const [descriptionInput, setDescriptionInput] = useState(actionTaskDescription ? actionTaskDescription : "")
  const [dueDateInput, setDueDateInput] = useState(actionDueDate ? actionDueDate : "")
  const [actionStatusInput, setActionStatusInput] = useState(actionStatus)

  // Validation for required input
  const [showNoTitleError, setShowNoTitleError] = useState(false)

  // UX Management
  const [showLoader, setShowLoader] = useState(false)
  const [showDatePicker, setShowDatePicker] = useState(false)
  const [showStatusChoiceDropdown, setShowStatusChoiceDropdown] = useState(false)

  // Manage dropdown menu UX
  const datePickerDropdownRef = useRef(null);
  const statusChoiceDropdownRef = useRef(null);


  useOutsideClick({
    ref: statusChoiceDropdownRef,
    handler: () => setShowStatusChoiceDropdown(false),
  })

  useOutsideClick({
    ref: datePickerDropdownRef,
    handler: () => setShowDatePicker(false),
  })

  // Functions
  const onChangeHandler = (event) => {
    setShowNoTitleError(false)

    switch (event.target.name) {
      case ('action-title'): {
        setShowNoTitleError(false)
        setTitleInput(event.target.value.trim())
        break;
      }
      case ('action-description'): {
        setDescriptionInput(event.target.value)
        break;
      }
      default: {
        break;
      }
    }
  };

  const handlePickDate = (pickedDate) => {
    setDueDateInput(pickedDate)
    setShowDatePicker(false)
  }

  const handleClickCloseEditActionModal = () => {
    setTitleInput("")
    setDescriptionInput("")
    setDueDateInput("")
    setShowStatusChoiceDropdown(false)
    setActionStatusInput(actionStatus)

    handleCloseEditActionModal()
  }

  const handleEditAction = () => {
    if (titleInput.trim().length < 1) {
      setShowNoTitleError(true)
    } else {
      const updateActionMetadata = {
        dsrId,
        mapCategoryId,
        actionId: actionId,
        actionTaskTitle: titleInput,
        actionTaskDescription: descriptionInput,
        actionDueDate: dueDateInput,
        updatedActionStatus: actionStatusInput,
        // assignedToUserId: assignedPersonUserId,
        event: "update-action-general"
      }

      setShowLoader(true);
      props.action.updateDsrAction(updateActionMetadata)
    }
  }

  const handlePickActionStatusChoice = (choice) => {
    setActionStatusInput(choice)
    setShowStatusChoiceDropdown(false)
  }
 
  const renderStatusTag = () => {
    switch (actionStatusInput) {
      case ('active'): {
        return (
          <Flex position='relative' justify='flex-start' fontSize='1.1em' ref={statusChoiceDropdownRef} >
            <Flex rounded='36px' bg='#EDF2F7' color='gray.500' px='1.2em' py='0.375em' justify='space-apart' align='center' cursor='pointer' onClick={() => setShowStatusChoiceDropdown(!showStatusChoiceDropdown)}>
              <Box as={GoCircle} />
              <Box px='0.5em'>To do</Box>
              <Box as={GoChevronDown} boxSize="0.75em" color='#2D3748' />
            </Flex>
            {
              showStatusChoiceDropdown &&
              <Box position='absolute' top='3em' left='0em' width='max-content' className="category-button-dropdown">
                <Box color="#718096" p='0.5em 1em' lineHeight='1.375em' cursor='pointer' onClick={() => handlePickActionStatusChoice("active")}>To do</Box>
                <Box color="#718096" p='0.5em 1em' lineHeight='1.375em' cursor='pointer' onClick={() => handlePickActionStatusChoice("completed")}>Completed</Box>
              </Box>
            }
          </Flex>
        )
      }
      case ('completed'): {
        return (
          <Flex position='relative' justify='flex-start'>
            <Flex rounded='36px' bg='#48BB78' color='white' px='0.5em' py='0.375em' ref={statusChoiceDropdownRef} justify='space-apart' cursor='pointer' align='center' onClick={() => setShowStatusChoiceDropdown(!showStatusChoiceDropdown)}>
              <Box as={AiFillCheckCircle} />
              <Box px='0.5em'>Completed</Box>
              <Box as={GoChevronDown} boxSize="0.75em" />
            </Flex>
            {
              showStatusChoiceDropdown &&
              <Box position='absolute' top='3em' left='0em' width='max-content' className="category-button-dropdown">
                <Box color="brandDark.500" p='0.5em 1em' lineHeight='1.375em' cursor='pointer' onClick={() => handlePickActionStatusChoice("active")}>To do</Box>
                <Box color="brandDark.500" p='0.5em 1em' lineHeight='1.375em' cursor='pointer' onClick={() => handlePickActionStatusChoice("completed")}>Completed</Box>
              </Box>
            }

          </Flex>
        )
      }
      default: {
        console.log('Error Code BSTBOJ: There is no action status available.')
        return null;
      }
    }
  }

  useEffect(() => {
    if (updateDsrActionOutcome) {
      setShowLoader(false)
      handleClickCloseEditActionModal()
    }

  }, [updateDsrActionOutcome]);

  if (showLoader) {
    return (
      <Box w="100%" align="center" >
        <Box px='1em'>
          <Box>
            <PageLoader />
          </Box>
        </Box>
      </Box>
    )
  }

  if (!showLoader) {
    if (isMobileView) {
      return (
        <Box w='100%' >
            <React.Fragment>
          <Box w="100%" align="center" px='1.5em' pb='2em'>
        
            <Box className="action-detail-label__mobile">
              Task name
            </Box>

            <Box className="action-detail-text-value">
              <FormTextInput
                name={'action-title'}
                onChange={onChangeHandler}
                charLimit={titleCharacterLimit}
                fontSize='14px'
                mb='0.2em'
                borderColor={showNoTitleError ? 'pink.500' : ""}
                defaultValue={titleInput}
              />
              {/* {showNoTitleError && <Text color='pink.500' fontSize='0.8em'>Give your action task a title</Text>} */}
            </Box>

            <Box className="action-detail-label__mobile" mt='1.5em'>
              Description
            </Box>

            <Box className="action-detail-text-value">
              <InputTextAreaExpandable
                name="action-description"
                onChangeHandler={onChangeHandler}
                inputValue={descriptionInput}
                fontSize='1.1em'
                placeholder={`Provide further details if required (optional)`}
                border={'1px solid'}
                borderColor='gray.200'
              />
            </Box>

            <Box className="action-detail-label__mobile" mt='1.5em'>
              Due date
            </Box>

            <Box ref={datePickerDropdownRef}>
              <LaunchdeckDatePicker
                top='2.5em'
                left='0em'
                handleClick={setShowDatePicker}
                handlePickDate={handlePickDate}
                showDatePicker={showDatePicker}
                dateInput={dueDateInput}
              />
            </Box>

            <Box className="action-detail-label__mobile" mt='1.5em'>
              Status
            </Box>

            <Box className="action-detail-display">
              {renderStatusTag(actionStatus)}
            </Box>

           
          </Box>
          </React.Fragment>

          <Flex w='100%' mt='2em' px='1.5em' py='0.625em'>
              <Flex justify='space-between' w='100%'>
                <LaunchdeckButton bg='gray.300' color='brandDark.500' fontSize='0.875em' px='1em' onClick={handleClickCloseEditActionModal} label='Cancel' />
                <LaunchdeckButton bg='brand.500' color='white' fontSize='0.875em' px='1em' onClick={() => handleEditAction()} label='Save' />
              </Flex>
            </Flex>
     

        </Box>
      )
    } else {
      return (
        <Box w="100%" align="center" >
          <React.Fragment>
            <Grid className='edit-action-detail-container' templateColumns="7.75em auto" gap='2em' align='center'>
              <Box className="action-detail-label">
                Task name
              </Box>

              <Box className="action-detail-text-value">
                <FormTextInput
                  name={'action-title'}
                  onChange={onChangeHandler}
                  charLimit={titleCharacterLimit}
                  fontSize='14px'
                  mb='0.2em'
                  borderColor={showNoTitleError ? 'pink.500' : ""}
                  defaultValue={titleInput}
                />
                {showNoTitleError && <Text color='pink.500' fontSize='0.8em'>Give your action task a title</Text>}
              </Box>
            </Grid>

            <Grid className='edit-action-detail-container' templateColumns="7.75em auto" gap='2em' align='center'>
              <Box className="action-detail-label">
                Description
              </Box>

              <Box className="action-detail-text-value">
                <InputTextAreaExpandable
                  name="action-description"
                  onChangeHandler={onChangeHandler}
                  inputValue={descriptionInput}
                  fontSize='1.1em'
                  placeholder={`Provide further details if required (optional)`}
                  border={'1px solid'}
                  borderColor='gray.200'
                />
              </Box>
            </Grid>

            <Grid className='edit-action-detail-container' templateColumns="7.75em auto" gap='2em' align='center'>
              <Box className="action-detail-label">
                Due date
              </Box>

              <Box ref={datePickerDropdownRef}>
                <LaunchdeckDatePicker
                  top='0em'
                  left='11em'
                  handleClick={setShowDatePicker}
                  handlePickDate={handlePickDate}
                  showDatePicker={showDatePicker}
                  dateInput={dueDateInput}
                />
              </Box>
            </Grid>

            <Grid className='edit-action-detail-container' templateColumns="7.75em auto" gap='2em' align='center'>
              <Box className="action-detail-label">
                Status
              </Box>

              <Box className="action-detail-display">
                {renderStatusTag(actionStatus)}
              </Box>
            </Grid>

          </React.Fragment>


          {/* <Box mt='1.5em'>
              <Flex w='100%' justify='space-between' align='center'>
                <Box>Assigned To <Box as='span' color='gray.500'>(Optional)</Box></Box>
              </Flex>
  
              <NewActionFormAssignToMenu
                dsrTeamMembers={dsrTeamMembers}
                setAssignedPersonUserId={setAssignedPersonUserId}
                assignedPersonUserId={assignedPersonUserId}
              />
            </Box>
          </Box>  */}

          <Flex w='100%' mt='2em' px='1em' py='0.625em' borderTop='1px solid' borderTopColor='gray.300'>
            <Flex justify='space-between' w='100%'>
              <LaunchdeckButton bg='gray.300' color='brandDark.500' onClick={handleClickCloseEditActionModal} label='Cancel' />
              <LaunchdeckButton bg='brand.500' color='white' onClick={() => handleEditAction()} label='Save' />
            </Flex>
          </Flex>


        </Box>
      )
    }

  }
}


function mapStateToProps(state) {
  return {
    updateDsrActionOutcome: state.updateDsrActionOutcome.results.data,
    isMobileView: state.mobileViewMode.isMobileView
  };
}

function mapDispatchToProps(dispatch) {
  return {
    action: bindActionCreators(
      {
        updateDsrAction
      },
      dispatch
    )
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditActionForm));
