import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import { ChakraBaseProvider, Flex, Box, Text, Image } from '@chakra-ui/react';
import claverTheme from '../../layout/components/layout/components/theme/claverTheme.js';
import PrivacyTos from './PrivacyTos.jsx';
import GeneralPageFooter from './GeneralPageFooter.jsx';
import PageLoader from '../../global/components/helper-components/components/PageLoader.jsx';
import PublicUserHeader from './PublicUserHeader.jsx';

import ClaverSignupImage from '../styles/images/claver-signup-icon.svg';
import { getCookie } from '../../global/helpers/cookie-helpers.js'
import { manageUrlTags } from '../../global/helpers/url-tag-helpers.js';
import JoinWaitlistForm from './JoinWaitlistForm.jsx';


function WaitlistPage(props) {

	const location = useLocation();

	const { login, signup } = props || {};
	const { signupError, type, message } = signup.results || {};
	const signupProcessInProgress = (signup.loading === true) ? true : "";

	const loginProcessInProgress = (login.loading === true) ? true : "";
	const [muid, setMuid] = useState(null);
	const [prepopEmailDisplay, setPrepopEmailDisplay] = useState(null);
	const [prepopNameDisplay, setPrepopNameDisplay] = useState(null)
	const [redirectBackRoute, setRedirectBackRoute] = useState("")

	const query = new URLSearchParams(props.location.search)
	const redirectUrl = query.get("redirect")


	useEffect(() => {
    const cookieMuid = getCookie('cvr_muid');
		if (cookieMuid) {
			setMuid(cookieMuid)
		}

		manageUrlTags(window.location.search);
  },[]);

	useEffect(() => {
		if (location.state) {
			
			const { prepopEmail, prepopName, redirectBackRoute } = location.state;

			setPrepopEmailDisplay(prepopEmail) 
			setRedirectBackRoute(redirectBackRoute)
			prepopName && setPrepopNameDisplay(prepopName)
		}
  },[location.state]);

	if (signupProcessInProgress || loginProcessInProgress) {
		return (
			<ChakraBaseProvider theme={claverTheme}>
				{/* <CSSReset /> */}
				<Box minHeight='18em'><PageLoader /></Box>
			</ChakraBaseProvider>
		)
	}


	return (
		<ChakraBaseProvider theme={claverTheme}>
			<PublicUserHeader loginEnabled redirectUrl={redirectUrl} showBackOption= {true} previousRoute={redirectBackRoute}/>

			<Box height='inherit'>
				<Box className="authentication-container">
					<Flex maxW="1440px" direction={['column-reverse', 'row', 'row', 'row']} w='100%' px={['1em', '10em', '10em', '10em']} py='2em' justify='space-betweeen' align='stretch' margin='0 auto'>
						<Flex direction={['column-reverse', 'column', 'column', 'column']} justify='center' alignItems={['center', 'flex-start', 'flex-start', 'flex-start']} flex='1' px='2em'>
							
							<Box mb='3em'>
								<Box fontSize='2em' lineHeight='3em' color='brandDark.500' fontWeight='600'>Join our waitlist</Box>
								<Box mt='0.5em' fontSize='0.875em' color='brandDark.500' fontWeight='500'>Why waitlist?</Box>
								<Box fontSize='0.875em' color='gray.500' fontWeight='400'>We want to ensure we have adequate capacity to provide great support to all our users.</Box>

								<Box mt='2em' fontSize='0.875em' color='brandDark.500' fontWeight='500'>How long is the wait?</Box>
								<Box fontSize='0.875em' color='gray.500' fontWeight='400'>We'll get in touch with you within 1 - 2 days!</Box>
							</Box>

							<Flex w='100%' mt={['3em', '3em', '3em', '3em']} mb='2em' px='1em' justify='flex-start'>
								<Image src={ClaverSignupImage}></Image>
							</Flex>
						</Flex>

						<Box flex='1' className='fade-in-left default-box-shadow' maxWidth={['', '37.5em', '37.5em', '37.5em']} rounded='0.25em'>

							<Flex direction='column' alignItems='center' bg='white' rounded='0.25em' h='100%'>
								<Box w='100%' borderBottom='1px solid' borderColor='gray.300' py='0.8em' >
									{/* <Box w='100%' fontSize='1.2em' lineHeight='1.875em' fontWeight='500' textAlign='center'>Sign Up for Launchdeck</Box> */}
									<Box w='100%' fontSize='1.2em' lineHeight='1.875em' fontWeight='500' textAlign='center'>Join waitlist</Box>
								</Box>

								<Box w='100%' px='3em' h='100%'>
									{!signupError && !signupProcessInProgress && <JoinWaitlistForm marketingUserTrackId={muid} prepopEmailDisplay={prepopEmailDisplay} prepopNameDisplay={prepopNameDisplay}/>}
									{signupError && type === 'Account already exists' && <ExistingAccountSignupError message={message} />}
								</Box>
								
								<Box borderTop='1px solid' borderColor='gray.300' w='100%' py='1em' px='2em'>
									<PrivacyTos />
								</Box>
							</Flex>
						</Box>
					</Flex>
					
					<GeneralPageFooter />

				</Box>
			</Box>
		</ChakraBaseProvider>
	);

}

function ExistingAccountSignupError({ message }) {

	return (
		<Flex my='3%' minHeight='18em' justify='center' w={['90%', '100%', '100%', '100%']}>
			<Box fontSize='0.8em' borderWidth="1px" borderColor='pink.300' rounded={8} p='2%' my='auto'>
				<Text textAlign='center'>{message} </Text>
				<Link to="/reset-password">
					<Flex alignItems='baseline' justify='center'>
						<Text color='gray.500' textAlign='center' mt='1.5em' textDecoration='underline'>I had forgotten my password </Text>
						<span role="img" aria-label="shameful"> 😔 </span>
					</Flex>
				</Link>
			</Box>
		</Flex>

	)
}

function mapStateToProps(state) {
	return {
		signup: state.auth.data.signup,
		login: state.auth.data.login,
	};
}


export default connect(mapStateToProps, null)(WaitlistPage);
