import React, { useRef, useState, useEffect } from 'react'
import { Box, Flex } from "@chakra-ui/react";

import { RiArrowDropDownLine } from "react-icons/ri";
import { redirectToNewPage } from '../../global/helpers/global-helpers';
import '../styles/user-profile-mobile-display.scss'


export default function UserProfileMobileNavMenu({ isAdministrator, sectionIndex }) {

  const dropdownRef = useRef(null);
  const [showMobilePageChoiceList, setShowMobilePageChoiceList] = useState(false)
  const [localState, setLocalState] = useState({
    mainDisplay: "",
    option1Display: "",
    option1Route: "",
    option2Display: "",
    option2Route: ""
  })

  useEffect(() => {
    // Add event listener when the component mounts
    document.addEventListener('mousedown', handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    switch (sectionIndex) {
      case (1): {
        setLocalState({
          mainDisplay: "Organization",
          option1Display: "Profile",
          option1Route:  "/profile?section=0",
          option2Display: "Billing",
          option2Route: "/profile?section=2"
        })
        break;
      }
      case (2): {
        setLocalState({
          mainDisplay: "Billing",
          option1Display: "Profile",
          option1Route: "/profile?section=0",
          option2Display: "Organization",
          option2Route: "/profile?section=1"
        })
        break;
      }
      case (0):
      default: {
        setLocalState({
          mainDisplay: "Profile",
          option1Display: "Organization",
          option1Route: "/profile?section=1",
          option2Display: "Billing",
          option2Route: "/profile?section=2"
        })
      }
    }
  }, [sectionIndex]);


  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      // Clicked outside the dropdown, close it
      setShowMobilePageChoiceList(false);
    }
  };

  const mobileDirectToNewPage = (route) => {
    if (route) {
      redirectToNewPage(route)
    } else {
      console.log('Error Code HBNMO5 : There are no routes provided')
    }
  }

  const { mainDisplay, option1Display, option1Route, option2Display, option2Route } = localState || {}

  if (isAdministrator) {
    return (
      <Box className="user-profile-title-mobile" ref={dropdownRef} >
        <Flex onClick={() => setShowMobilePageChoiceList(!showMobilePageChoiceList)}
  
          justify='flex-start'
          align='center'
        >{mainDisplay} <Box ml='0.6em' as={RiArrowDropDownLine} />
          {/* Dropdown bar */}
          {showMobilePageChoiceList &&
            <Box className='mobile-accounts-nav-dropdown-menu__dropdown'
              display={(showMobilePageChoiceList) ? 'inline-block' : 'none'}
            >
              <Box className='mobile-accounts-nav-dropdown-menu__dropdown-option-display'
                onClick={() => mobileDirectToNewPage((option1Route))}
              >{option1Display}</Box>
  
              <Box className='mobile-accounts-nav-dropdown-menu__dropdown-option-display'
                onClick={() => mobileDirectToNewPage((option2Route))}
              >{option2Display}</Box>
  
            </Box>
          }
  
        </Flex>
      </Box>
    )
  } else {
    return (
      <Box className="user-profile-title-mobile" >
      {/* No Dropdown bar */}
      <Flex justify='flex-start' align='center'>Profile </Flex>
    </Box>
    )
  }

  
}