import * as types from '../actions/users-self-setup-action-types';



const INITIAL_USER_SELF_SETUP_STATE = {
	isLoading: false,
	results: {},
	error: '',
};


export function userSelfSetupReducer(state = INITIAL_USER_SELF_SETUP_STATE, action) {
	switch (action.type) {
		case types.SELF_SETUP_NEW_ORG_USER_RESET:
			return INITIAL_USER_SELF_SETUP_STATE
		case types.SELF_SETUP_NEW_ORG_USER_REQUEST:
			return { ...state, isLoading: true, results: {} };
		case types.SELF_SETUP_NEW_ORG_USER_SUCCESS:
			return { ...state, isLoading: false, results: action.payload };
		case types.SELF_SETUP_NEW_ORG_USER_FAILURE:
			return { ...state, isLoading: false, error: 'Error helping user do self setup' };
		default:
			return state;
	}
}

