import React from 'react';

import '../styles/header.scss';
import LaunchdeckLogo from '../styles/images/launchdeck_logo.svg';
import { Box } from '@chakra-ui/react';
import HeaderLogo from './HeaderLogo';


export default function GeneralHeader({logoToMainLandingPage}) {
	
	return (
		<nav className={`header header--default`}>
			<div className="header__container">
				{logoToMainLandingPage ?
					<Box ml='4em'>
						<a href={'https://www.launchdeck.app'}>
							<img className="user-header__logo" src={LaunchdeckLogo} alt="Launchdeck Logo" />
						</a>
					</Box> :
					<HeaderLogo />
				}
			</div>
		</nav>
	);
}
