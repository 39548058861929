import React, { useEffect, useState } from 'react';
import { Box, Flex } from "@chakra-ui/react";

import { redirectToNewPage } from '../../../global/helpers/global-helpers';
import { IoCalendarOutline, IoEyeOutline, IoEllipseSharp } from "react-icons/io5";
import { MdModeEdit } from "react-icons/md";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import { formatDisplayDateOnly } from '../../../digital-sales-room/helpers/timestamp-display-helper';
import '../../styles/vault.scss';


export default function ProspectDsrCardDisplay({ dsrData }) {
  const { pDsrId, dsrTitle, status, dsrProperties, createdAt, pdsrConversionCount
    // dsrId, dsrOwnerUsername, buyerEngagementLevel, ownerProfilePic, audienceLogoCardDisplay
  } = dsrData || {};

  const { roomAnalytics } = dsrProperties || {};
  const {roomViews} = roomAnalytics || {};
  
  const [redirectLink, setRedirectLink] = useState('')
  const [showOptionButton, setShowOptionButton] = useState(false)


  useEffect(() => {
    if (status) {
      switch (status) {
        case ('reverted-draft'):
        case ('draft'): {
          setRedirectLink(`/draft/pdsr/${pDsrId}`)
          break;
        }
        case ('active'): {
          setRedirectLink(`/pdsr/${pDsrId}`)
          break;
        }
        default: {
          console.log(`There has been an error - there is no status for this prospector dsr card ${pDsrId}`)
          break;
        }
      }
    }
  }, [pDsrId, status]);


  const renderProspectDsrMetrics = (status) => {

    if (status==='draft') {
      return (
        <Flex className="stats-display">
          <Flex align='center'>
            <Box>Never been published</Box>
          </Flex>
        </Flex>
      )
    } else {
      return (
        <Flex className="stats-display">
          <Flex align='center'>
            <Box as={IoEyeOutline} />
            <Box ml='0.25em'> {roomViews ? roomViews : 0} </Box>
          </Flex>
  
        
            <Flex align='center' ml='0.5em'>
              <Box as={AiOutlineUsergroupAdd} />
              <Box ml='0.25em'> {pdsrConversionCount} </Box>
            </Flex>
       
        </Flex>
      )
    }
  }

  return (
    <Flex className="card-display"
      onMouseOver={() => setShowOptionButton(true)} onMouseOut={() => setShowOptionButton(false)}
    >

      <Box className="card-content-stack">
        <Flex align='center' justify='flex-start'>
          <StatusBadge status={status} />

        </Flex>
        <Box opacity={showOptionButton ? '1.0' : '0'}>
        </Box>
      </Box>


      <Box w='100%' onClick={() => redirectToNewPage(redirectLink)} cursor='pointer'>
        <Box className="card-content-stack__column">
          <Box maxWidth={['60vw', '20vw']} className="dsr-title-display" title={dsrTitle ? dsrTitle : "-"}>
            {dsrTitle ? dsrTitle : "-"}
          </Box>
          <Flex className="stats-display">
            <Flex align='center'>
              <Box as={IoCalendarOutline} />
              <Box ml='0.25em'> {formatDisplayDateOnly(createdAt)} </Box>
            </Flex>           
          </Flex>
        </Box>

        <Box className="card-content-stack" mt='1.5em'>
          {renderProspectDsrMetrics(status)}
        </Box>

      </Box>
    </Flex>
  )
}


function StatusBadge({ status }) {
  return (
    <div>
      {
        {
          'draft':
            <Flex className="status-tag__draft-container status-tag__text-light">
              <Box as={MdModeEdit} />
              <Box >Draft</Box>
            </Flex>,
          'reverted-draft':
            <Flex className="status-tag__draft-container status-tag__text-light">
              <Box as={MdModeEdit} />
              <Box >Draft</Box>
            </Flex>,
          'active':
            <Flex className="status-tag__active-container status-tag__text-dark">
              <Box as={IoEllipseSharp} color='green' />
              <Box >Active</Box>
            </Flex>,
          'null': null,
        }[status]
      }
    </div>
  );
}


