import React from 'react';

import { Box, Modal, ModalOverlay, ModalBody, ModalContent, Flex } from "@chakra-ui/react";
import PageLoader from '../../global/components/helper-components/components/PageLoader';


export default function DsrGenericLoadingModal({ modalText }) {

  const renderModalContent = () => {
    return (
      <React.Fragment>

        <Flex direction='column' justify='center' align='center' my='1.6em' w='100%'>
          <Box px='3%' w='100%'>
            <PageLoader />
            <Box w="100%" mb="2em" align="center" color='brandDark.500' textAlign='center'>
              {modalText ? modalText : "Working on it"}
            </Box>
          </Box>
        </Flex>
        
      </React.Fragment>
    )

  }

  return (
    <Modal isOpen={true} closeOnOverlayClick={false} isCentered={true}>
      <ModalOverlay />
      <ModalContent rounded='0.25em' minW={['90%', '500px']} maxW='700px'>
        <ModalBody>
          <Flex direction='column' justify='space-around' alignItems='center' w='100%'>
            {renderModalContent()}
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )

}
