import { setupReducersPerStateType } from './legacy-reducers';
import * as types from '../actions/auth-action-types';
// import { clearSession } from '../reducers/auth';
import authHelper from '../../../config/app-specific-config/auth';

// Login reducer
const LOGIN_INITIAL_STATE = {
	// loading: false,
	// results: null,
	// token: null
};

function login(state = LOGIN_INITIAL_STATE, action) {
	switch (action.type) {
		case types.LOGIN_REQUEST:
			return { ...state, loading: true, results: [], token: null};
		case types.LOGIN_SUCCESS:
			return { ...state, loading: false, results: action.payload, token: action.payload.token, };
		case types.LOGIN_FAIL:
			return { ...state, loading: false, results: action.payload, error: 'Error while trying to login', token: null};
		default:
			return state;
	}
}

const JWT_INITIAL_STATE = {
	isExpired: false,
};

function jwt(state = JWT_INITIAL_STATE, action) {
	switch (action.type) {
		case types.SET_JWT_EXPIRED:
			return { ...state, isExpired: true };
		case types.UNSET_JWT_EXPIRED:
			return { ...state, isExpired: false };
		default:
			return state;
	}
}

// Signout reducer
const SIGNOUT_INITIAL_STATE = {
	jwt:null,
	currentUser: null,
	error: null,
	results: null,
};

function signout(state = SIGNOUT_INITIAL_STATE, action) {
	switch (action.type) {
		case types.SIGNOUT_CLEAR:
		case types.SIGNOUT_REQUEST:
			return SIGNOUT_INITIAL_STATE
		case types.SIGNOUT_SUCCESS:
			authHelper.clearSession();
			return {
				...SIGNOUT_INITIAL_STATE,
				results: {
					sign_out: true
				}
			};
		default:
			return state;
	}
}

// Signup reducer
const SIGNUP_INITIAL_STATE = {
	loading: null,
	results: []
};

function signup(state = SIGNUP_INITIAL_STATE, action) {
	switch (action.type) {
		case types.SIGNUP_REQUEST:
			return { ...state, loading: true, results: [] };
		case types.SIGNUP_SUCCESS:
			return { ...state, loading: false, results: action.payload };
		case types.SIGNUP_FAIL:
			return { ...state, loading: false, results: action.payload, error: 'Error while trying to signup' };
		default:
			return state;
	}
}


// Auth loading reducer
const authLoadingInitialState = {
	isLoading: true,
};

function authLoading(state = authLoadingInitialState, action) {
		switch (action.type) {
			case types.LOGIN_REQUEST:
				return { isLoading: true };
			case types.SIGNUP_REQUEST:
				return { isLoading: true };
			case types.LOGIN_SUCCESS:
				return { isLoading: false };
			case types.SIGNUP_SUCCESS:
				return { isLoading: false };
			default:
				return state;
	}
}


// Email Verification Status reducer
const INITIAL_STATE_GET_EMAIL_VERIFICATION_STATUS = {
	isLoading: false,
	results: {},
	error: '',
};

export function emailVerificationStatusReducer(state = INITIAL_STATE_GET_EMAIL_VERIFICATION_STATUS, action) {
	switch (action.type) {
		case types.VERIFY_EMAIL_REQUEST:
			return { ...state, isLoading: true, results: {} };
		case types.VERIFY_EMAIL_SUCCESS:
			return { ...state, isLoading: false, results: action.payload };
		case types.VERIFY_EMAIL_FAIL:
			return { ...state, isLoading: false, error: 'Error while trying to get email verification status' };
		default:
			return state;
	}
}

// Reset Password Request Status reducer
// const INITIAL_SEND_PASSWORD_RESET_EMAIL_STATUS = {
// 	isLoading: false,
// 	results: {},
// 	error: '',
// };

// export function sendResetPasswordEmailReducer(state = INITIAL_SEND_PASSWORD_RESET_EMAIL_STATUS, action) {
// 	switch (action.type) {
// 		case types.RESET_PASSWORD_REQUEST:
// 			return { ...state, isLoading: true, results: {} };
// 		case types.RESET_PASSWORD_SUCCESS:
// 			return { ...state, isLoading: false, results: action.payload };
// 		case types.RESET_PASSWORD_FAIL:
// 			return { ...state, isLoading: false, error: 'Error while trying to send email with reset password link' };
// 		default:
// 			return state;
// 	}
// }


const DELETE_ACCOUNT_INITIAL_STATE = {
	isLoading: false,
	error: '',
};

export function deleteAccount(state = DELETE_ACCOUNT_INITIAL_STATE, action) {
	switch (action.type) {
		case types.DELETE_ACCOUNT_REQUEST:
			return { ...state, loading: true, results: [], token: null};
		case types.DELETE_ACCOUNT_SUCCESS:
			return { ...state, loading: false, results: action.payload };
		case types.DELETE_ACCOUNT_FAILURE:
			return { ...state, loading: false, results: action.payload, error: 'Error while trying to delete account' };
		default:
			return state;
	}
}

// Reducer for set new password to feedback success or not

const SET_NEW_PASSWORD_INITIAL_STATE = {
	isLoading: false,
	results: {},
	error: '',
};

export function setNewPasswordReducer(state = SET_NEW_PASSWORD_INITIAL_STATE, action) {
	switch (action.type) {
		case types.SET_NEW_PASSWORD_REQUEST:
			return { ...state, isLoading: true, results: {} };
		case types.SET_NEW_PASSWORD_SUCCESS:
			return { ...state, isLoading: false, results: action.payload };
		case types.SET_NEW_PASSWORD_FAIL:
			return { ...state, isLoading: false, results: action.payload, error: 'Error while trying to set password for user' };
		// case types.SET_NEW_USER_PASSWORD_REQUEST:
		// 	return { ...state, isLoading: true, results: {} };
		// case types.SET_NEW_USER_PASSWORD_SUCCESS:
		// 	return { ...state, isLoading: false, results: action.payload };
		// case types.SET_NEW_USER_PASSWORD_FAIL:
		// 	return { ...state, isLoading: false, results: action.payload, error: 'Error while trying to set password for new invited user' };
		default:
			return state;
	}
}


export const auth = setupReducersPerStateType({
	ui: {
		authLoading
	},
	data: {
		jwt,
		login,
		signout,
		signup,
		deleteAccount
	}
});