import React from 'react';
import { Box } from '@chakra-ui/react';
import WarningTextMessage from '../../../global/components/helper-components/components/WarningTextMessage';


import FormTextInput from '../../../forms/TextInputField/components/FormTextInput';


export default function AppAdminOffboardUserForm({
	handleChange,
	localPageState,
	isInvalidEmail
}) {

	const {emailInput} = localPageState || {};

		return (
			<Box my='2em' w='100%' gap='0.5em'>	
					<Box mb='0.5em'>
						<FormTextInput name={'emailInput'} label={'User email'} sublabel={''} htmlFor={'emailInput'} mb='0.2em' value={emailInput && emailInput} onChange={handleChange} />
						<WarningTextMessage errorLogic={isInvalidEmail} message='Please provide a valid email' />
					</Box>
			</Box>
		);
	

};
