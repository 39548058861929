import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { fetchVaultData, resetFetchVaultData } from './actions/vault-actions';
import { bindActionCreators } from 'redux'
import VaultReceivedRoomsPage from './VaultReceivedRoomsPage'

import { changeDsrModalState, changeDsrPopupState } from '../digital-sales-room/actions/dsr-actions'

const VaultReceivedRoomsPageContainer = (props) => {
  return (
    <VaultReceivedRoomsPage {...props} />
  )
}

const mapStateToProps = (state) => ({
  allUserDsrs: state.allUserDsrs.results.data,
  vaultIsProcessing: state.allUserDsrs.isLoading
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchVaultData, // TODO: Update to fetch only receied rooms data
      resetFetchVaultData,
      changeDsrModalState,
      changeDsrPopupState
    },
    dispatch,
  )

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VaultReceivedRoomsPageContainer))
