import { Box, Collapse, Flex, Image } from "@chakra-ui/react";
import ShareDocIconImage from "./styles/images/shared-doc-folders.svg";
import { IoMdAddCircle } from "react-icons/io";
import { CreateSharedDoc } from "./CreateSharedDoc";
import React from "react";

export const DisplayEmptyState = ({
  dsrId,
  isMobileView,
  showAddDocumentForm,
  setShowAddDocumentForm,
  displaySignupPrompt,
  handleOpenSignupOrLoginModal,
}) => {
  return (
    <Box>
      <Box textAlign={["center", "center", "left", "left"]}>
        <Box
          color="brandDark.500"
          fontSize="1.5em"
          fontWeight="600"
          lineHeight="2.25em"
        >
          Shared Documents
        </Box>
      </Box>

      <Flex
        display={showAddDocumentForm ? "none" : "flex"}
        mt="2em"
        justify="center"
        align="center"
        py="2.75em"
        border="1px solid #E5EAEF"
        rounded="0.5em"
        bg="white"
      >
        <Flex direction="column" justify="center" align="center">
          <Image
            src={ShareDocIconImage}
            alt="Empty Shared Doc Page"
            width="4em"
          />

          <Box
            fontSize="0.875em"
            fontWeight="400"
            lineHeight="1.5em"
            color="gray.500"
            py="1.5em"
            textAlign="center"
          >
            Share documents with your fellow Room members, all in one place
          </Box>

          <Box whiteSpace="nowrap">
            <Flex
              className="create-new-category-button"
              onClick={() =>
                displaySignupPrompt
                  ? handleOpenSignupOrLoginModal()
                  : setShowAddDocumentForm(!showAddDocumentForm)
              }
            >
              
              <Box className="create-new-category-button__button-text">
                Add Document
              </Box>
              <Box
                as={IoMdAddCircle}
                boxSize="1.5em"
                className="create-new-room-button__add-icon"
              />
            </Flex>
          </Box>
        </Flex>
      </Flex>
      {/* <CreateSharedDoc
        dsrId={dsrId}
        isMobileView={isMobileView}
        showAddDocumentForm={showAddDocumentForm}
        setShowAddDocumentForm={setShowAddDocumentForm}
      /> */}

{showAddDocumentForm && (
          <Box opacity={"1.0"}>
            {/* <Collapse in={true} animateOpacity> */}
              <CreateSharedDoc
                dsrId={dsrId}
                isMobileView={isMobileView}
                setShowAddDocumentForm={setShowAddDocumentForm}
                showAddDocummentForm={showAddDocumentForm}
              />
            {/* </Collapse> */}
          </Box>
        )}
    </Box>
  );
};
