import auth from '../../../config/app-specific-config/auth'
import * as types from './dsr-page-accessibility-actions-types'
import { axiosInstance } from '../../../config/app-specific-config/axiosConfig'
import { setDsrPages } from './dsr-actions';




////////////////////////////////////////////////////////////
// DSR POST TAG SERVICES
////////////////////////////////////////////////////////////
// POST method
export const manageDsrCustomPagesAccessibilitySuccess = (response) => {
	return {
		type: types.MANAGE_DSR_CUSTOM_PAGES_ACCESSIBILITY_SUCCESS,
    payload: response
	};
};

export const manageDsrCustomPagesAccessibilityFailure = (error) => {
	return {
		type: types.MANAGE_DSR_CUSTOM_PAGES_ACCESSIBILITY_FAILURE,
    error
	};
};

export const manageDsrCustomPagesAccessibilityRequest = () => {
	return {
		type: types.MANAGE_DSR_CUSTOM_PAGES_ACCESSIBILITY_REQUEST
	};
};


export function manageDsrCustomPagesAccessibility(managePageAccessibilityMetadata) {

	// POST API
	return async (dispatch) => {
		dispatch(manageDsrCustomPagesAccessibilityRequest());
		try {
			const url = '/dsr/manage-custom-page-accessibility'; 
			const headers = await auth.getAccessToken(); 

			const response = await axiosInstance.post(
				url,
				{managePageAccessibilityMetadata},
				{ headers }
			);
			
			if (response.data.data.updatedDsrPages) {
				dispatch(setDsrPages(response.data.data.updatedDsrPages))
			}
			
			dispatch(manageDsrCustomPagesAccessibilitySuccess(response.data.data));

		} catch (error) {
			dispatch(manageDsrCustomPagesAccessibilityFailure(error));
		}
	};
}


export const manageDsrCustomPagesAccessibilityReset = () => {
	return {
		type: types.MANAGE_DSR_CUSTOM_PAGES_ACCESSIBILITY_RESET
	};
};

export function resetManageDsrCustomPagesAccessibility() {

	return async (dispatch) => {
	
		try {
			dispatch(manageDsrCustomPagesAccessibilityReset());

		} catch (error) {
			dispatch(manageDsrCustomPagesAccessibilityFailure(error));
		}
	};
}