import { Box, Flex } from '@chakra-ui/react'
import DsrPageSideMenu from '../components/DsrPageSideMenu'
import DisplayNewsFeedPublic from '../dsr-content-components/DisplayNewsFeedPublic'

import React, { memo, useEffect, useRef, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  changeDsrModalState,
  changeDsrPopupState,
  changeSelectSubpage,
  changeDsrPageDisplay
} from '../actions/dsr-actions'
import { logGuestDsrAnalytics } from '../../analytics/actions/dsr-analytics-actions'
import { bindActionCreators } from 'redux'
import RenderSubpageContent from '../components/RenderSubpageContent'
import '../../layout/components/layout/styles/dsr-content.scss'
import DsrDynamicModal from '../messages/DsrDynamicModal'

const RenderProspectPreviewContent = ({
  dsrId,
  dsrTitle,
  selectedDisplaySubpage,
  selectedDisplayPageId,
  changeSelectSubpage,
  changeDsrPageDisplay,
  changeDsrPopupState,
  userActionRequiredState,
  changeDsrModalState,
  showDsrModalState,
  pagesLocalState,

  // Redux states
  faqPageIdRedux,
  dsrPropertiesRedux,
  dsrTeamMembersRedux,
  dsrFeedContent,
  dsrActionList
}) => {

  const [localState, setLocalState] = useState({
    showDsrModalLoader: false,
    showConfirmationModal: false,
    confirmationType: '',
    postContentToEdit: '',
    faqPageId: '',
    replyIdToEdit: '',
    userIdToken: '',
    modalTransferPayload: '',
    postTagProperties: [],
  })

  
  const initRef = useRef(false);

  useEffect(() => {
    if ((!initRef.current && pagesLocalState)) {
 
      const visiblePages = pagesLocalState.filter(page => page.displayPage === true)

      if (visiblePages.length > 0) {
        changeDsrPageDisplay(visiblePages[0].pageId)
      } else {
        changeDsrPageDisplay('no-visible-pages')
      }

      // TODO: Implement GA for Prospect DSR
      initRef.current = true
    }
}, [pagesLocalState, changeDsrPageDisplay])

  
  const handleChangeUserActionRequired = (newUserActionRequired, modalTransferPayload) => {
    changeDsrPopupState(newUserActionRequired)
    changeDsrModalState(true)
    setLocalState((prevState) => ({
      ...prevState,
      modalTransferPayload,
    }))
  }


  const handleDsrModalClose = () => {
    changeDsrPopupState('')
    changeDsrModalState(false)

    setLocalState((prevState) => ({
      ...prevState,
      postIdToEdit: '',
    }))
  }


  const handleOpenPagePreviewMessageModal = () => {
    changeDsrPopupState("show-preview-page-action-modal")
    changeDsrModalState(true)
  }



  const handleSelectSubpage = (newSelectedDisplayPageId, newSelectedDisplayFixedSubpageType) => {

    if (newSelectedDisplayPageId && newSelectedDisplayFixedSubpageType) {
      // Error state if both values are provided to avoid showing
      changeSelectSubpage("")
      changeDsrPageDisplay("")
    } else {
      if (newSelectedDisplayPageId && !newSelectedDisplayFixedSubpageType && (newSelectedDisplayPageId !== selectedDisplayPageId)) {
        // Takes care of Analytics before changing pageId/ subpageType

        changeDsrPageDisplay(newSelectedDisplayPageId)
      } else if (!newSelectedDisplayPageId && newSelectedDisplayFixedSubpageType && (newSelectedDisplayFixedSubpageType !== selectedDisplaySubpage)) {

        changeSelectSubpage(newSelectedDisplayFixedSubpageType)
      }
    }

  }

  return (
    <Flex className='dsr-content__section'>
      <Flex className='dsr-content__container'>
        <Flex direction="column" align="flex-start" w='100%' minHeight="40em">
          <Flex direction="row" align="flex-start" width="100%" height='100%'>
            <Flex bg="white" position='sticky' top='140px'>
              <DsrPageSideMenu
                pageArray={pagesLocalState}
                selectedDisplayPageId={selectedDisplayPageId}
                handleSelectSubpage={handleSelectSubpage}
                headerMenuHeight="140px"
                isPagePreviewMode={true}
                isProspector={true}
              />
            </Flex>
            <Flex
              direction={['column', 'column', 'row', 'row']}
              justify="center"
              flexWrap="wrap"
              width="100%"
              bg='#F4F4F4'
              height='100%'
            >
              <Box mt={'2em'} w="100%" px="3em" maxWidth='900px' pb='3em'>
                {selectedDisplaySubpage === 'updates-subpage' &&

                  <DisplayNewsFeedPublic
                    dsrTitle={dsrTitle}
                    displayedPostContent={dsrFeedContent}
                    dsrTeamMembers={dsrTeamMembersRedux}
                    showDsrModal={showDsrModalState}
                    showDsrModalLoader={localState.showDsrModalLoader}
                    modalTransferPayload={localState.modalTransferPayload}
                    handleChangeUserActionRequired={handleChangeUserActionRequired}
                    handleDsrModalClose={handleDsrModalClose}
                    faqPageId={faqPageIdRedux}
                    handleSelectSubpage={handleSelectSubpage}
                    handleOpenSignupOrLoginModal={handleOpenPagePreviewMessageModal}
                  />

                }

                {/* Dev Note: This component is to display static public content, sharing RenderSubpageContent with the normal DSR display */}
                <RenderSubpageContent
                  pageArray={pagesLocalState}
                  selectedDisplaySubpage={selectedDisplaySubpage}
                  selectedDisplayPageId={selectedDisplayPageId}
                  dsrTeamMembers={dsrTeamMembersRedux}
                  isPagePreviewMode={true}

                  dsrId={dsrId}
                  dsrActionList={dsrActionList? dsrActionList : []}
                  dsrProperties={dsrPropertiesRedux}
                  handleOpenSignupOrLoginModal={handleOpenPagePreviewMessageModal}
                  handleSelectSubpage={handleSelectSubpage}
                />
              </Box>
            </Flex>

            <DsrDynamicModal
              dsrId = {dsrId}
              userActionRequired={userActionRequiredState}
              showDsrModal={showDsrModalState}
              showDsrModalLoader={localState.showDsrModalLoader}
              handleDsrModalClose={handleDsrModalClose}
              postContentToEdit={localState.postContentToEdit}
              postTagProperties={localState.postTagProperties}
              postId={localState.postIdToEdit}
              handleChangeUserActionRequired={handleChangeUserActionRequired}
              modalTransferPayload={localState.modalTransferPayload}
            />

          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )

}

const mapStateToProps = (state) => {
  return {
    dsrFeedContent: state.dsrFeedContent.results,
    userActionRequiredState: state.dsrPopup.UserActionRequired,
    showDsrModalState: state.dsrModal.showDsrModal,
    selectedDisplaySubpage: state.dsrPage.selectedDisplaySubpage,
    selectedDisplayPageId: state.dsrPage.selectedDisplayPageId,
    dsrPagesRedux: state.dsrPages.pages,
    faqPageIdRedux: state.faqPageId.id,

    dsrPropertiesRedux: state.draftDsrProperties.properties,
    dsrTeamMembersRedux: state.dsrTeamMembers.members,
    dsrUserTeam: state.dsrUserTeam.userTeam,

    dsrActionList: state.dsrActionList.results.data,
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {

      changeDsrPopupState,
      changeDsrModalState,
      changeSelectSubpage,
      changeDsrPageDisplay,
      logGuestDsrAnalytics,
    },
    dispatch,
  )

export default memo(withRouter(connect(mapStateToProps, mapDispatchToProps)(RenderProspectPreviewContent)))
