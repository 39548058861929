import React from "react";
import { Box, Input } from "@chakra-ui/react";

export default function ProfileFormTextInput(props) {
  
  const {name, label, subLabel, htmlFor, variant, type, placeholder, defaultValue, isDisabled, mt} = props;
  const displayedVariant = variant ? variant : 'outline';
  const topMargin = mt ? mt : '1.5em';

  return (
    <Box w='100%' mt={topMargin} htmlFor={htmlFor}>
      
      <Box fontSize='0.875em' lineHeight='1.375em' fontWeight='500' color='gray.700'> 
        {label} 
      </Box>
      {subLabel && <Box fontSize='0.75em' lineHeight='1.25em' mt='0.2em' fontWeight='400' color='gray.500'> 
        {subLabel} 
      </Box>
      }
      <Box mt='0.625em'>
        <Input 
          name={name} 
          variant={displayedVariant} 
          defaultValue={defaultValue} 
          placeholder={(!defaultValue && placeholder) ? placeholder : ""}
          type={type} 
          isDisabled={isDisabled}
          w='100%' 
          h='2.5em' 
          fontSize='0.875em' 
          lineHeight='1.375em' 
          rounded ='0.25em' 
          onChange={(event) => props.onChange(event)}/>
      </Box>
    </Box>
  );
  
}


