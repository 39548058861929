
export function getUserDetailsUsingDsrMembersArray(dsrTeamMembers, providedUserId) {
  if (!dsrTeamMembers) return null

  const sellerTeamArray = dsrTeamMembers["sellerTeam"] ? dsrTeamMembers["sellerTeam"] : []
  const buyerTeamArray = dsrTeamMembers["buyerTeam"] ? dsrTeamMembers["buyerTeam"] : []
  const combinedDsrTeamMembersArray = [...sellerTeamArray, ...buyerTeamArray]

  if (combinedDsrTeamMembersArray && providedUserId) {
    for (const organization of combinedDsrTeamMembersArray) {
      const {orgTeamMembers} = organization || {};

      for (const memberProfile of orgTeamMembers) {
        if (memberProfile.userId === providedUserId) {
          return memberProfile
        }
      }  
    }   
  }

   // Defaults to return empty profile if nothing was found
   return null;
}

// export function getUserDetailsUsingCombinedDsrMembersArray(combinedDsrTeamMembersArray, providedUserId) {
//   if (!providedUserId || !combinedDsrTeamMembersArray) return null

//   if (Array.isArray(combinedDsrTeamMembersArray) && providedUserId) {
//     for (const organization of combinedDsrTeamMembersArray) {
//       const {orgTeamMembers} = organization || {};

//       for (const memberProfile of orgTeamMembers) {
//         if (memberProfile.userId === providedUserId) {
//           return memberProfile
//         }
//       }  
//     }   
//   }

//    // Defaults to return empty profile if nothing was found
//    return null;
// }

export function getFullName(userPersonalProfile) {
  const {firstName, lastName} = userPersonalProfile || {};
  if (!firstName && !lastName) {
    return ""
  } else if (firstName && !lastName) {
    return firstName
  } else if (!firstName && lastName){
    return lastName
  } else {
    return firstName + ' ' + lastName
  }
}

// This function is used to automatically retrieve first & last names based on user's entry into a single name input
export function splitNameInput(str) {
  const words = str.trim().split(/\s+/);
  if (words.length === 1) {
    return { firstName: words[0].trim(), lastName: "" };
  } else {
    return { firstName: words[0].trim(), lastName: words.slice(1).join(" ").trim() };
  }
};

export function getUserAccountType() {
  const userCategory = localStorage.getItem('USER_CATG');

  return userCategory;
}

export function determineIsNonCustomerUserAccount() {
  const userCategory = localStorage.getItem('USER_CATG');

  if (userCategory==="no-org-user" || userCategory==="no-category-user") {
    return true
  } else return false
}

export function combineAllMembersArray(dsrTeamMembers) {
  const { sellerTeam, buyerTeam } = dsrTeamMembers || {};

  if (sellerTeam) {
    const allMembersArray = (sellerTeam && buyerTeam) ? [...sellerTeam, ...buyerTeam] : [...sellerTeam]
    return allMembersArray
  } else return null;
}

export function sortArrayToListActiveMembersFirst(orgTeamMembers) {
  let sortedArray = "";

  if (Array.isArray(orgTeamMembers)) {
    if (orgTeamMembers.length >1) {
      sortedArray = orgTeamMembers.slice().sort((a, b) => {
        if (a.userStatus === 'active' && b.userStatus === 'pending') {
          return -1; // 'active' comes before 'pending'
        } else if (a.userStatus === 'pending' && b.userStatus === 'active') {
          return 1; // 'pending' comes after 'active'
        } else {
          return 0; // Keep the original order if status values are the same
        }
      });

    } else {
      sortedArray = orgTeamMembers;
    }
  } 

  return sortedArray;
}

// export function checkMatchCurrentLoggedInUser(compareValue) {
//   // Purpose of this function is to feed a value to here, and compare whether it is the user id token of this logged in user (session)
//   const thisUserIdToken = localStorage.getItem('USER_ID_TOKEN');

//   if (thisUserIdToken && (thisUserIdToken === compareValue)) {
//     return true
//   } else return false
// }