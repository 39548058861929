import React from 'react';

import { Box, Modal, ModalOverlay, ModalBody, ModalContent, Flex  } from "@chakra-ui/react";

import DsrPostTextAreaInput from '../../forms/TextareaField/components/DsrPostTextAreaInput';
import PageLoader from '../../global/components/helper-components/components/PageLoader';
import LaunchdeckCloseButton from '../../global/components/helper-components/components/LaunchdeckCloseButton';


export default function DsrLoomRecordModal({
    userActionRequired,
    handleCloseModal,
    handleCreateNewPost,
    showDsrModalLoader
  }) {
  switch (userActionRequired) {
    case ('create-video-post'): {
      return (
        <Modal isOpen={true} closeOnOverlayClick={false} isCentered={true} preserveScrollBarGap={true}>
          <ModalOverlay />
          <ModalContent rounded='0.25em' minW={['90%', '500px']} maxW='700px'>
            <ModalBody p='0em'>
              <Flex direction='column' justify='space-around' alignItems='center'>
                <Flex w='100%' p='1em' justify='space-between' align='center' borderBottom='1px solid' borderBottomColor='gray.100' >
                  <Flex w='100%' fontSize='1.25em' fontWeight='500' lineHeight='1.375em'>Create a Video Post</Flex>
                  <Flex justify='flex-end' w='100%'><LaunchdeckCloseButton handleCloseModal={handleCloseModal} /></Flex>
                </Flex>


                {showDsrModalLoader === true ? <PageLoader /> :
                  <Flex justify='center' w='100%'>
                    <Box w="100%" align="center" px='0.5em'>
                      <DsrPostTextAreaInput
                        userActionRequired={userActionRequired}
                        name="createDsrPostContent"
                        placeholder='Start a conversation here'
                        fontSize='0.875em'
                        htmlFor="createDsrPostContent"
                        onClickCreatePost={handleCreateNewPost}
                      />
                    </Box>
                  </Flex>
                }
              </Flex>
            </ModalBody>
          </ModalContent>
        </Modal>
      )
    }
    
    default: {
      console.log('There is no associated action with this user action:', userActionRequired)
      return null;
    }
  }

}
