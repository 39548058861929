import { SET_DSR_FAQ_CONTENT } from '../../actions/dsr-actions-types'

const INITIAL_STATE_DSR_FAQ_PAGE_CONTENT = { faqArray: '' }

export function faqPageContentReducer(state = INITIAL_STATE_DSR_FAQ_PAGE_CONTENT, action) {
    switch (action.type) {
        case SET_DSR_FAQ_CONTENT:
            return { faqArray: action.payload };
        default:
            return state;
    }
}