import * as types from '../actions/admin-user-action-types';



const INITIAL_ADMIN_USER_ORG_PROFILE_STATE = {
	isLoading: false,
	results: {},
	error: '',
};

export function getAdminOrgProfileReducer(state = INITIAL_ADMIN_USER_ORG_PROFILE_STATE, action) {
	switch (action.type) {
		case types.GET_ADMIN_ORG_PROFILE_REQUEST:
			return { ...state, isLoading: true, results: {} };
		case types.GET_ADMIN_ORG_PROFILE_SUCCESS:
			return { ...state, isLoading: false, results: action.payload };
		case types.GET_ADMIN_ORG_PROFILE_FAILURE:
			return { ...state, isLoading: false, error: 'Error getting admin user org profile.' };
		default:
			return state;
	}
}


// Update Users 
const INITIAL_MANAGE_ORG_USERS_STATE = {
	isLoading: false,
	results: {},
	error: '',
};

export function adminOrgUserManagementReducer(state = INITIAL_MANAGE_ORG_USERS_STATE, action) {
	switch (action.type) {
		// Create
		case types.CREATE_ORG_USERS_RESET:
			return INITIAL_MANAGE_ORG_USERS_STATE;
		case types.CREATE_ORG_USERS_REQUEST:
			return { ...state, isLoading: true, results: {} };
		case types.CREATE_ORG_USERS_SUCCESS:
			return { ...state, isLoading: false, results: action.payload };
		case types.CREATE_ORG_USERS_FAILURE:
			return { ...state, isLoading: false, error: 'Error creating new org user' };
			// Update
		case types.UPDATE_ORG_USERS_RESET:
			return INITIAL_MANAGE_ORG_USERS_STATE;
		case types.UPDATE_ORG_USERS_REQUEST:
			return { ...state, isLoading: true, results: {} };
		case types.UPDATE_ORG_USERS_SUCCESS:
			return { ...state, isLoading: false, results: action.payload };
		case types.UPDATE_ORG_USERS_FAILURE:
			return { ...state, isLoading: false, error: 'Error updating org user profile.' };
		default:
			return state;
	}
}

const INITIAL_ADMIN_USER_RESET_ACTIVATION_LINK_STATE = {
	isLoading: false,
	results: {},
	error: '',
};

export function resetActivationLinkReducer(state = INITIAL_ADMIN_USER_RESET_ACTIVATION_LINK_STATE, action) {

	switch (action.type) {
		case types.RESET_PENDING_USER_ACTIVATION_LINK_RESET:
			return INITIAL_ADMIN_USER_RESET_ACTIVATION_LINK_STATE;
		case types.RESET_PENDING_USER_ACTIVATION_LINK_REQUEST:
			return { ...state, isLoading: true, results: {} };
		case types.RESET_PENDING_USER_ACTIVATION_LINK_SUCCESS:
			return { ...state, isLoading: false, results: action.payload };
		case types.RESET_PENDING_USER_ACTIVATION_LINK_FAILURE:
			return { ...state, isLoading: false, error: 'Unable to reset activation link' };
		default:
			return state;
	}
}