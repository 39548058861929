import {
  Box,
  Flex,
  Link,
  Text,
} from "@chakra-ui/react";
import { FaFileCircleCheck } from "react-icons/fa6";
import { FiExternalLink } from "react-icons/fi";
import { formatDisplayDateOnly } from "../helpers/timestamp-display-helper";
import React from "react";

export const StackViewSharedDocCard = ({
  docId,
  docName,
  formattedDocLink,
  memberName,
  docSharedAt,
}) => {
  return (
    <>
      <Box key={`shared-doc-${docId}`}>
        <Flex justify="flex-start" align="center">
          <Box
            fill="brandDark.500"
            as={FaFileCircleCheck}
            width="2em"
            height="1em"
            my="0.75em"
          />
          <Box
            className="truncate-long-text"
            fontSize="0.875em"
            maxW="12em"
            mr="0.5em"
          >
            {docName}
          </Box>
          <Link
            href={formattedDocLink}
            lineHeight="1.625em"
            fontSize="0.875em"
            isExternal
          >
            <FiExternalLink />
          </Link>
        </Flex>

        <Text
          pt="2"
          px="0.5em"
          fontSize="0.8em"
          fontWeight="600"
          color="gray.700"
        >
          {memberName}
        </Text>
        <Text pt="2" px="0.5em" fontSize="0.75em" color="gray.500">
          {formatDisplayDateOnly(docSharedAt)}
        </Text>
      </Box>
    </>
  );
};
