import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { createStripeCheckoutSession } from '../actions/payments-actions';

// import { loadStripe } from '@stripe/stripe-js'; // Uninstall the npm package if this is confirmed not required anymroe
import { Flex, useToast } from '@chakra-ui/react';
import LaunchdeckButton from '../../global/components/helper-components/components/LaunchdeckButton';
import { triggerLaunchdeckToast } from '../../global/helpers/global-helpers';



function StripeCheckoutButton( { isFreeTrial, buttonText, sourceRoute, isMobileView, createStripeCheckoutSession } ) {
  const [buttonIsLoading, setButtonIsLoading] = useState(false);
  const [hideButton, setHideButton] = useState(false);
  // const { stripeApiKey } = config;
  // const stripePromise = loadStripe(stripeApiKey);



  const toast = useToast();
  const handleClick = async (event) => {
    //Set Button to loading
    setButtonIsLoading(true);

    // Call your backend to create the Checkout session.
    const stripeCheckoutSession = await createStripeCheckoutSession(sourceRoute);
    

    if (stripeCheckoutSession) {
      const {url} = stripeCheckoutSession || {};
      setButtonIsLoading(false)
      setHideButton(true)
      
      if (url) {
        window.open(url)
      } else {

        toast(
          triggerLaunchdeckToast({
          useCase: "show-error-state",
          label: "Unfortunately something went wrong. Please contact us to resolve this.", 
          isMobileView
          })
        )
      }
    }
   
    // When the customer clicks on the button, redirect them to Checkout.
    // const stripe = await stripePromise;
    // const { error } = await stripe.redirectToCheckout({ sessionId: stripeCheckoutSession.id });

    // if (error) {
    //   // TODO: Display something went wrong modal for reporting
    //   console.log('Error if any in stripePromise Checkout Session', error)
    // }
  };

  if (!hideButton) {
    return (
      <Flex direction='column' justify='center' alignItems='center'>
        <Flex justify={['center', 'flex-end', 'flex-end', 'flex-end']} w='100%'>
          <LaunchdeckButton bg='yellow.400' color='brandDark.500' noHover loadingLogic={buttonIsLoading} onClick={handleClick} label={buttonText ? buttonText : isFreeTrial ? 'Use promo code' : 'Set up billing'} />
        </Flex>
      </Flex>
    )
  }
}

// Redux state is there. Review if needed - Uncomment. If not required - delete
function mapStateToProps(state) {
  return {
  isMobileView: state.mobileViewMode.isMobileView

  };
}

// function mapDispatchToProps(dispatch) {
//   return {
//     action: bindActionCreators(
//       {
//         createStripeCheckoutSession
//       },
//       dispatch
//     )
//   };
// }
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createStripeCheckoutSession
    },
    dispatch,
  )


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StripeCheckoutButton));