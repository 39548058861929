import axios from 'axios';
import {setJwtExpired, signOut} from '../../features/auth/actions/auth-actions';
import { isJWTExpired} from "../../features/auth/helpers/auth-check-jwt-expiration";

// Global Axios Config
export const axiosInstance = axios.create({
	baseURL: process.env.REACT_APP_BASE_URL,
	headers: { name: 'Content-Type', value: 'application/json', }
});


// Axios Request Interceptor
export const AxiosInterceptors = {
	setupInterceptors(store) {
    axiosInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
          if (error.response.status === 401) {
              await isJWTExpired() ? store.dispatch(setJwtExpired()) : store.dispatch(signOut());
          }

          return Promise.reject(error);
      }
    );
	}

	// responseInterceptor(history) {
	// 	axiosInstance.interceptors.response.use(
	// 		(response) => {
	// 			return response;
	// 		},
	// 		(error) => {

	// 			if (error.response.status === 401) {
	// 				// store.dispatch(signOut());
	// 				localStorage.removeItem('JWT_TOKEN_KEY');
	// 				history.push('/login');
	// 			}
	// 			return Promise.reject(error);
	// 		}
	// 	);
	// }
};






