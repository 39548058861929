import React from 'react';

import {Modal, ModalOverlay, ModalBody, ModalContent, Flex , Box} from "@chakra-ui/react";

import DsrPostTextAreaInput from '../../forms/TextareaField/components/DsrPostTextAreaInput';
import PageLoader from '../../global/components/helper-components/components/PageLoader';
import LaunchdeckCloseButton from '../../global/components/helper-components/components/LaunchdeckCloseButton';


export default function DsrEditReplyModal(props) {

  const {
    dsrId,
    postId,
    replyId,
    handleCloseModal, 
    postContentToEdit,
    handleEditPostReply,
    editDsrFeedReplyOutcome,
    handleStopDsrModalLoader,
    showDsrModalLoader
  } = props;
  
  return (
    <Modal isOpen={true} closeOnOverlayClick={false} isCentered={true}>
      <ModalOverlay />
      <ModalContent rounded='0.25em' minW={['90%', '500px']} maxW='700px'>
      <ModalBody p='0em'>
          <Flex direction='column' justify='space-around' alignItems='center'>
            <Flex w='100%' p='1em' justify='space-between' align='center' borderBottom='1px solid' borderBottomColor='gray.100' >
              <Flex w='100%' fontSize='1.25em' fontWeight='500' lineHeight='1.375em'>Edit reply</Flex>
              <Flex justify='flex-end' w='100%'><LaunchdeckCloseButton handleCloseModal={handleCloseModal} /></Flex>
            </Flex>

            <Flex justify='center' w='100%'>
              <Flex w="100%" align="center">
                <DsrPostTextAreaInput
                  userActionRequired='edit-dsr-reply'
                  name="updatePostReplyContent"
                  sublabel=""
                  placeholder=""
                  // defaultValue={postContentToEdit}
                  currentValue={postContentToEdit}
                  fontSize='1em'
                  htmlFor="updatePostReplyContent"
                  onClickEditPost={handleEditPostReply}
                  editDsrFeedReplyOutcome={editDsrFeedReplyOutcome}
                  dsrId={dsrId}
                  postId={postId}
                  replyId={replyId}
                  handleStopDsrModalLoader={handleStopDsrModalLoader}
                />
              </Flex>
            </Flex>

            {showDsrModalLoader &&
              <Box position='absolute' zIndex='2'>
                <PageLoader />
              </Box>
            }
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
