import React from 'react';
import { ChakraBaseProvider, Flex, Box } from '@chakra-ui/react';
import claverTheme from '../../layout/components/layout/components/theme/claverTheme';
import GeneralPageFooter from './GeneralPageFooter';
import PublicUserHeader from './PublicUserHeader';
import ContactLaunchdeckForm from './ContactLaunchdeckForm';


export default function ContactLaunchdeckPage() {

		
	return (
		<ChakraBaseProvider theme={claverTheme}>
			<PublicUserHeader signUpEnabled/>
			<Box w='100%' px='15%' className="authentication-container">

				<Flex direction='column' h='100%' pb='2%'>

					<Flex direction='row' flexGrow='1' py='5em'>
						<Flex p='5em 2em' w='100%' direction='column' justify='flex-start' alignItems='center' bg='white' rounded='0.25em' className="default-box-shadow">
			
							<Box fontSize='1.4em' mb='0.5em' color='brandDark.500'>Send us a message</Box>
							<Box fontSize={['0.875em','0.875em','0.9em','0.9em']} color='gray.500' textAlign='center'>Tell us what you're thinking about and we'll be in touch shortly! </Box>
							
							<ContactLaunchdeckForm />
						</Flex>

					</Flex>

					<GeneralPageFooter />

				</Flex>
			</Box>
		</ChakraBaseProvider>
	);
	
}