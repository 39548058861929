import React from 'react';
import {Box, Flex, Input, Select, Text, Textarea} from "@chakra-ui/react";
import PageLoader from '../../global/components/helper-components/components/PageLoader';
import LaunchdeckButton from '../../global/components/helper-components/components/LaunchdeckButton';


export function CreateDsrForm(props) {
  const {
    createDsrForm,
    error,
    showLoader,
    onChangeHandler,
    handleClickCloseEditActionModal,
    handleMoveToBrandingStep,
  } = props || {};
  const titleCharacterLimit = 100;
  const descriptionCharacterLimit = 280;


  if (showLoader) {
    return (
      <Box w="100%" align="center" >
        <Box px='1em'>
          <Box>
            <PageLoader />
          </Box>
        </Box>
      </Box>
    )
  }

  if (!showLoader) {
    return (
      <Box m='1.5em' w="100%" align="center" >
          <Box mb='1.5em' fontSize='0.875em'>
            <Flex w='100%' justify='space-between' align='center' mb='10px'>
              <Box fontWeight='500'>Room name<Box as='span' color='pink.500'>*</Box></Box>
              <Box display='flex' fontSize='14px'><Box fontWeight='600'>{createDsrForm.dsrTitle.trim().length}</Box>/{titleCharacterLimit}</Box>
            </Flex>
            <Input
              name='dsrTitle'
              value={createDsrForm.dsrTitle}
              onChange={onChangeHandler}
              placeholder="e.g. Request for quotation & product intro"
              maxLength={titleCharacterLimit}
              isInvalid={(error === 'dsrTitle') && 'true'}
              errorBorderColor='pink.400'
              mb='0.2em'
              w='100%'
              h='1.4em'
              fontSize='1em'
              fontWeight= '400'
              color='brandDark.500'
              border='1px solid #A0AEC0'
              borderRadius='0.25em'
              padding='1em'
            />
            {error === 'dsrTitle' && <Text color='pink.500' fontSize='0.8em' textAlign='left'>Give your Room a good name</Text>}
          </Box>

          <Box mb='1.5em' fontSize='0.875em'>
            <Flex w='100%' justify='space-between' align='center' mb='10px'>
              <Box fontWeight='500'>Room description</Box>
              <Box display='flex' fontSize='14px'>
                <Box fontWeight='600'>{createDsrForm.dsrDescription.trim().length}</Box>/{descriptionCharacterLimit}
              </Box>
            </Flex>
            <Textarea
              name='dsrDescription'
              value={createDsrForm.dsrDescription}
              onChange={(e) => onChangeHandler(e)}
              placeholder="Enter notes that'll help you and your team manage the Room"
              maxLength={descriptionCharacterLimit}
              borderColor={error === 'dsrDescription' ? 'pink.500' : ""}
              mb='0.2em'
              w='100%'
              h='6em'
              fontSize='inherit'
              fontWeight= '400'
              color='brandDark.500'
              border='1px solid #A0AEC0'
              borderRadius='0.25em'
              padding='0.5em 1em'
              resize='none'
            />
            {error === 'dsrDescription' && <Text color='pink.500' fontSize='0.8em'>Maximum {descriptionCharacterLimit} characters</Text>}
          </Box>

          <Box mb='1.5em' fontSize='0.875em'>
            <Flex w='100%' justify='space-between' align='center' mb='10px'>
              <Box fontWeight='500'>Client name</Box>
            </Flex>
            <Input
              name='dsrClientName'
              value={createDsrForm.dsrClientName}
              onChange={onChangeHandler}
              placeholder="e.g. Traders Joe"
              maxLength={titleCharacterLimit}
              borderColor={error === 'dsrClientName' ? 'pink.500' : ""}
              mb='0.2em'
              w='100%'
              h='1.4em'
              fontSize='1em'
              fontWeight= '400'
              color='brandDark.500'
              border='1px solid #A0AEC0'
              borderRadius='0.25em'
              padding='1em'
            />
          </Box>

          <Box mb='1.5em' fontSize='0.875em'>
            <Flex w='100%' justify='space-between' align='center' mb='10px'>
              <Box fontWeight='500'>Client website</Box>
            </Flex>
            <Input
              name='dsrClientWebsite'
              value={createDsrForm.dsrClientWebsite}
              onChange={onChangeHandler}
              placeholder="e.g. https://www.traderjoes.com"
              borderColor={error === 'dsrClientWebsite' ? 'pink.500' : ""}
              mb='0.2em'
              w='100%'
              h='1.4em'
              fontSize='1em'
              fontWeight= '400'
              color='brandDark.500'
              border='1px solid #A0AEC0'
              borderRadius='0.25em'
              padding='1em'
            />
            {error === 'dsrClientWebsite' && <Text color='pink.500' fontSize='0.8em'>Invalid website URL. Correct URL example: 'https://www.google.com'</Text>}
          </Box>

          <Box mb='1.5em' fontSize='0.875em'>
            <Flex w='100%' justify='space-between' align='center' mb='10px'>
              <Box fontWeight='500'>Room value</Box>
            </Flex>
            <Box display='flex'>
              <Select
                name='dsrRoomCurrency'
                onChange={onChangeHandler}
                maxWidth='45px'
                height='30px'
                borderColor={error === 'dsrRoomCurrency' ? 'pink.500' : ""}
                focusBorderColor='#A0AEC0'
                mb='0.2em'
                w='100%'
                fontSize='1em'
                fontWeight= '400'
                color='brandDark.500'
                border='1px solid #A0AEC0'
                borderRadius='0.25em 0 0 0.25em'
                cursor='pointer'
              >
                <option defaultChecked value="USD">$</option>
                <option value="EUR">€</option>
                <option value="GBP">£</option>
              </Select>
              <Input
                type='number'
                name='dsrRoomValue'
                value={createDsrForm.dsrRoomValue}
                onChange={onChangeHandler}
                focusBorderColor='#A0AEC0'
                placeholder="Enter room value"
                maxLength={titleCharacterLimit}
                borderColor={error === 'dsrRoomValue' ? 'pink.500' : ""}
                mb='0.2em'
                w='100%'
                h='1.4em'
                fontSize='1em'
                fontWeight= '400'
                color='brandDark.500'
                border='1px solid #A0AEC0'
                borderLeftColor='transparent'
                borderRadius='0 0.25em 0.25em 0'
                padding='1em 1em 1em 5px'
              />
            </Box>
          </Box>

        <Flex w='100%' mt='2em' py='0.625em'>
          <Flex justify='space-between' w='100%'>
            <LaunchdeckButton name='closeCreateActionModal' borderColor="gray.500" label='Cancel' fontSize='0.9em' py='0.8em' onClick={handleClickCloseEditActionModal} />
            <LaunchdeckButton label='Proceed' borderRadius='300px' fontSize="0.9em" bg='brand.500' color='gray.50' onClick={handleMoveToBrandingStep} />
          </Flex>
        </Flex>


      </Box>
    )
  }
}
