import React from 'react';
import { ChakraBaseProvider } from "@chakra-ui/react";

import '../styles/layout.scss';
import claverTheme from './theme/claverTheme';


export default function PublicPageLayout(props) {

		return (
			<ChakraBaseProvider theme={claverTheme}>
				{props.children}
			</ChakraBaseProvider>
		);

	}

