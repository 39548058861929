import auth from '../../../config/app-specific-config/auth'
import * as types from './file-upload-actions-types'
import { axiosInstance } from '../../../config/app-specific-config/axiosConfig'



////////////////////////////////////////////////////////////
// DSR IMAGE UPLOAD SERVICES
////////////////////////////////////////////////////////////
// POST method
export const uploadTempAudienceLogoSuccess = (response) => {
	return {
		type: types.UPLOAD_TEMP_AUDIENCE_LOGO_SUCCESS,
    payload: response.data
	};
};

export const uploadTempAudienceLogoFailure = (error) => {
	return {
		type: types.UPLOAD_TEMP_AUDIENCE_LOGO_FAILURE,
    error
	};
};

export const uploadTempAudienceLogoRequest = () => {
	return {
		type: types.UPLOAD_TEMP_AUDIENCE_LOGO_REQUEST
	};
};


export function uploadTempAudienceLogo(uploadedLogoMetadata) {

	// POST API
	return async (dispatch) => {
		dispatch(uploadTempAudienceLogoRequest());
		try {
			const {dsrId, uploadedImage} = uploadedLogoMetadata || {};
			const logoImage = new FormData(); 
			logoImage.append('tempDsrAudienceLogo', uploadedImage)  

			const url = `/dsr/${dsrId}/image/temp-audience-logo`; 
			const headers = await auth.getAccessToken(); 

			const response = await axiosInstance.put(url, logoImage, {headers});
		
			dispatch(uploadTempAudienceLogoSuccess(response));

		} catch (error) {
			dispatch(uploadTempAudienceLogoFailure(error));
		}
	};
}
