export const CREATE_EXCLUSIVE_INVITE_REQUEST = 'create_exclusive_invite_request';
export const CREATE_EXCLUSIVE_INVITE_SUCCESS = 'create_exclusive_invite_success';
export const CREATE_EXCLUSIVE_INVITE_FAILURE = 'create_exclusive_invite_failure';

// Join waitlist
export const CREATE_JOIN_WAITLIST_REQUEST = 'create_join_waitlist_request'
export const CREATE_JOIN_WAITLIST_SUCCESS = 'create_join_waitlist_success'
export const CREATE_JOIN_WAITLIST_FAILURE = 'create_join_waitlist_failure'

// Contact Launchdeck
export const SEND_LAUNCHDECK_MESSAGE_REQUEST = 'send_launchdeck_message_request'
export const SEND_LAUNCHDECK_MESSAGE_SUCCESS = 'send_launchdeck_message_success'
export const SEND_LAUNCHDECK_MESSAGE_FAILURE = 'send_launchdeck_message_failure'
export const SEND_LAUNCHDECK_MESSAGE_RESET = 'send_launchdeck_message_reset'