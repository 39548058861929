import React, {useRef, useEffect, useState} from "react";
import { Box, Flex, Input } from "@chakra-ui/react";

export default function FormTextInput(props) {
  
    const { label, radioKey, sublabel, name, variant, mt, color, bg,
      defaultValue, placeholder, w, minW, type, mb, charLimit, cursor,
      isDisabled, onKeyDown, value, focusThisInput, fontSize, textAlign, noBorder, unfocusedNoBorder,
     } = props;

    const displayedVariant = variant ? variant : 'outline';
    const displayMarginBottom = mb ? mb : '1.5em';
    const inputKey = `${radioKey}`;
    const inputRef = useRef(null);

    const [isFocused, setIsFocused] = useState(false);

    const handleFocus = () => {
      setIsFocused(true);
    };
  
    const handleBlur = () => {
      setIsFocused(false);
    };

    useEffect(() => {
      // Access and use the forwardedRef in the child component
      if (inputRef.current && focusThisInput) {
        inputRef.current.focus();
      }
    }, [focusThisInput]);

    return (
      <Box mb={displayMarginBottom} w={w ? w: ''} minW={minW && minW} textAlign='left'>
        <Box lineHeight='1.275em' fontSize='0.875em' fontWeight='500' color={color ? color : 'gray.700'}> 
          {label} 
        </Box>
        {sublabel && <Box fontSize='0.75em' lineHeight='1.25em' mt='0.2em' fontWeight='400' color='gray.500'> 
          {sublabel} 
        </Box>
        }
      <Flex mt={mt ? mt : '0.625em'} className='text-input-container' bg={isDisabled && 'gray.50'}>
     
          <Input 
            key={inputKey} 
            ref={inputRef}
            name={name} 
            type={type && type}
            variant={displayedVariant} 
            value={value} // Review whether this new added property affects other things
            defaultValue={defaultValue} 
            placeholder={(!defaultValue && placeholder) ? placeholder : ""}
            className="text-input-container__input-area"
            w='100%' 
            py='1.2em'
            fontSize={fontSize ? fontSize : '0.875em' }
            rounded ='0.25em' 
            // focusBorderColor='brand.500'
            border={(noBorder || unfocusedNoBorder && !isFocused) ? '0px' : (unfocusedNoBorder && isFocused) && '1px'}
            borderColor={isFocused ? 'brand.500' : 'gray.200'}
            // borderColor={borderColor && borderColor} // Review whether this is required
            maxLength={charLimit && charLimit}
            onChange={(event) => props.onChange(event)}
            onKeyDown={onKeyDown && onKeyDown}
            fontWeight= '400'
            color='brandDark.500'
            isDisabled={isDisabled && isDisabled}
            bg={bg && bg}
            textAlign={textAlign && textAlign}
   
            cursor={cursor && cursor}
            onFocus={handleFocus}
            onBlur={handleBlur}
            />
        </Flex>

      </Box>
    );
  
}


