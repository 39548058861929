import React from 'react'
import { Box, Image, Textarea } from '@chakra-ui/react';
import Zoom from 'react-medium-image-zoom'

export const ImageSubWidget = ({ subWidgetId, editableItemId,setSubWidgetContent, subWidgetContent}) => {
  const defaultImage = "https://placehold.jp/44c5f7/ffffff/400x200.png";


  return (
    editableItemId === subWidgetId
      ? <Box width="100%">
          <Textarea
            autoFocus
            value={subWidgetContent} 
            onChange={(e) => setSubWidgetContent(e.target.value)}
            resize="none"
            boxSize="lg"
          />
        </Box>
      : <Box padding="10px">
          <Zoom>
           <Image src={subWidgetContent ? subWidgetContent : defaultImage} alt="Sub-widget image" />
          </Zoom>
      
      </Box>
  )
};
