import React from 'react';
import { Box, Flex, Spinner } from "@chakra-ui/react";


export default function LaunchdeckButton(props) {
	
	const { label, w, width, onClick, border, borderColor, px, mx, hover, textTransform, dummy, bg, fontSize, color, name, value, fontWeight, loadingLogic } = props;
	const isDummy = dummy ? true:false;
	const isFullWidth = (width==='100%' || w==='100%') ? true : false;

	
	return (
		<Box
			display={!isFullWidth && 'inline-flex'}
			name={name ? name : ''}
			value={value ? value : ''}
			onClick={loadingLogic? null : onClick } 
			px={px ? px : "1em"}
			py='0.4em'
			rounded='18.75em'
			fontSize={fontSize ? fontSize : "0.875em"}
			bg={bg ? bg : "#f5f6f7"}
			border= {border ? border : "1px solid"}
			borderColor={borderColor ? borderColor : bg ? bg : "brand.500"}
			color={color ? color : "#4b4f56"}
			textTransform={textTransform ? textTransform : ''}
			cursor={(isDummy || loadingLogic) ? 'default' : 'pointer'}
			w={width ? width : w ? w : ''}
			fontWeight={fontWeight ? fontWeight : '400'}
			textAlign='center'
			justifyContent='center'
			whiteSpace='pre'
			mx={mx && mx}
			minW='8em'
			_hover={(hover) && { color: "brand.500", bg: "brand.50", border:"1px solid", borderColor:'brand.200' }}
		>
			{loadingLogic ? <Flex minWidth='5em' justify='center' w='100%'><Spinner boxSize='1em' my='0.2em' mx='0.4em'/></Flex> : label ? label : props.children}		
		</Box>

	)
	
}
