import React, { useEffect, useRef, useState } from 'react'
import { Box, Button } from '@chakra-ui/react';
import { optionTypes } from "../types/subWidgetOptionsTypes";
import { openInNewTab } from '../../../../global/helpers/global-helpers';
import LaunchdeckFormInput from '../../../../forms/TextInputField/components/LaunchdeckFormInput';


export const CtaButton = ({
  subWidgetId,
  editableItemId,
  setSubWidgetContent,
  subWidgetContent,
  isDragging,
  handleEditItemByClick,
  handleSaveItemByClickOutside,
  options
}) => {
  const [ctaText, setCtaText] = useState(subWidgetContent ? subWidgetContent.split('/?&/')[0] : "Button");
  const [ctaLink, setCtaLink] = useState(subWidgetContent ? subWidgetContent.split('/?&/')[1] : "");
  const [isFocused, setIsFocused] = useState(false);
  const containerRef = useRef(null);

  let buttonAlign = '0';
  let buttonVariant = options[optionTypes.VARIANT];

  switch (options[optionTypes.ALIGN]) {
    case 'left':
    default:
      buttonAlign = '-1'
      break;
    case 'center':
      buttonAlign = '0'
      break;
    case 'right':
      buttonAlign = '1'
      break;
  }

  useEffect(() => {
    const newCtaContent = `${ctaText}/?&/${ctaLink}`;

    if (newCtaContent !== subWidgetContent) {
      setSubWidgetContent(newCtaContent)
    }
  }, [ctaText, ctaLink]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if ((isFocused && containerRef.current) && !containerRef?.current.contains(event.target)) {
        setIsFocused(false);
        handleSaveItemByClickOutside();
      }
    };

    if (isFocused) {
      setTimeout(() => window.addEventListener('click', handleClickOutside), 1)
    } else {
      window.removeEventListener('click', handleClickOutside);
    }

    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, [isFocused]);

  const handleClick = () => {
    if (!isDragging) {
      handleEditItemByClick()
      setIsFocused(true)
    }
  }

  return (
    editableItemId === subWidgetId
      ? <Box ref={containerRef} width="100%" py='1em' minH='14em'>
        {/* <Box fontSize="0.875em" mb='0.5em' fontWeight='600' px='0.5em'>Button text</Box> */}
        {/* <Input
          value={ctaText}
          onChange={(e) => setCtaText(e.target.value)}
          placeholder="Button text"
          mb='0.2em'
          w='100%'
          h='1.4em'
          fontSize='0.875em'
          fontWeight= '400'
          color='brandDark.500'
          border='1px solid #A0AEC0'
          borderRadius='0.25em'
          padding='1em'
        /> */}
        <Box mb='3em'>
          <LaunchdeckFormInput
            onChange={(e) => setCtaText(e.target.value)}
            value={ctaText}
            label="Button text"
          />
        </Box>

        <LaunchdeckFormInput
          onChange={(e) => setCtaLink(e.target.value)}
          value={ctaLink}
          label="Redirection link"
        />
      </Box>
      : <Box
        display='grid'
        gridTemplateColumns={'1fr 1fr 1fr'}
        justifyContent='center'
        alignItems='center'
        width='100%'
        height='100%'
        padding="10px"
      >
        <Box
          width='100%'
          height='100%'
          onClick={handleClick}
        ></Box>

        <Box
          order={Number(buttonAlign)}
          display='flex'
          justifyContent={options[optionTypes.ALIGN]}
          alignItems='center' width='100%'
        >
          <Button
            onClick={ctaLink
              ? () => openInNewTab(ctaLink)
              : () => handleClick()
            }
            bg={buttonVariant === 'primary' ? 'brandDark.500' : '#EAF4FD'}
            fontSize='0.875em'
            rounded='0.85em'
            px='5em'
            height="50px"
            color={buttonVariant === 'primary' ? 'white' : '#3295EC'}
            _hover={{ color: "brandDark.500", bg: "gray.200" }}
          >
            {ctaText}
          </Button>
        </Box>

        <Box
          width='100%'
          height='100%'
          onClick={handleClick}
        ></Box>
      </Box>
  )
}
