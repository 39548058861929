import React from 'react';
import { Box, Modal, ModalOverlay, ModalBody, ModalContent, Flex } from "@chakra-ui/react";

import DsrPostTextAreaInput from '../../forms/TextareaField/components/DsrPostTextAreaInput';
import PageLoader from '../../global/components/helper-components/components/PageLoader';
import LaunchdeckCloseButton from '../../global/components/helper-components/components/LaunchdeckCloseButton';
import FileUploadProgress from '../dsr-create-post/FileUploadProgress';


export default function CreateDsrPostInputModal(props) {

  const {
    dsrId,
    userActionRequired,
    handleCloseModal,
    handleCreateNewPost,
    showDsrModalLoader,
    showPostUploadImageOption
  } = props;

  switch (userActionRequired) {
    case ('create-new-post'): {
      return (
        <Modal isOpen={true} closeOnOverlayClick={false} isCentered={true} preserveScrollBarGap={true}>
          <ModalOverlay />
          <ModalContent rounded='0.25em' minW={['90%', '500px']} maxW='700px'>
            <ModalBody p='0em'>
              <Flex direction='column' justify='space-around' alignItems='center'>
                <Flex w='100%' p='1em' justify='space-between' align='center' borderBottom='1px solid' borderBottomColor='gray.100' >
                  <Flex w='100%' fontSize='1.1em' fontWeight='500' lineHeight='1.375em' pl='0.5em'>{"Create a Post"}</Flex>
                  {!showDsrModalLoader && <Flex justify='flex-end' w='100%'><LaunchdeckCloseButton handleCloseModal={handleCloseModal} /></Flex>}
                </Flex>


                {showDsrModalLoader ?
                  <Box py='2em'>
                    <FileUploadProgress showLoader={showDsrModalLoader} />
                  </Box>
                  :
                  <Flex justify='center' w='100%'>
                    <Box w="100%" align="center" px='0.5em'>
                      <DsrPostTextAreaInput
                        userActionRequired={userActionRequired}
                        name="createDsrPostContent"
                        placeholder='Start a conversation here'
                        htmlFor="createDsrPostContent"
                        onClickCreatePost={handleCreateNewPost}
                        dsrId={dsrId}
                        showPostUploadImageOption={showPostUploadImageOption}
                      />
                    </Box>
                  </Flex>
                }
              </Flex>
            </ModalBody>
          </ModalContent>
        </Modal>
      )
    }
    case ('create-faq-question'): {
      const titleCopyContent = "Post a question"
      const subTextCopyContent = "Once it's answered, the response from a Host team member will be found on the Q&A page"
      return (
        <Modal isOpen={true} closeOnOverlayClick={false} isCentered={true} preserveScrollBarGap={true}>
          <ModalOverlay />
          <ModalContent rounded='0.25em' minW={['90%', '500px']} maxW='700px'>
            <ModalBody p='0em'>
              <Flex direction='column' justify='space-around' alignItems='center' w='100%'>
                <Flex w='100%' p='1em' justify='space-between' align='center' borderBottom='1px solid' borderBottomColor='gray.100' >
                  <Flex w='100%' fontSize='1.25em' fontWeight='500' lineHeight='1.375em' px='0.5em'>{titleCopyContent}</Flex>
                  <Flex justify='flex-end' w='100%'><LaunchdeckCloseButton handleCloseModal={handleCloseModal} /></Flex>
                </Flex>

                <Box w='100%' py='1em' color='brandDark.500' fontSize='0.875em' lineHeight='1.25em' borderBottom='1px solid' borderBottomColor='gray.100'>
                  <Flex w='100%' px='1em' justify='flex-start'>    
                  <Box px='1em'> {subTextCopyContent} </Box></Flex>
                </Box>

                {showDsrModalLoader === true ? <PageLoader /> :
                  <Flex justify='center' w='100%'>

                    <Box w="100%" align="center">
                      <DsrPostTextAreaInput
                        userActionRequired={userActionRequired}
                        name="createDsrPostQuestion"
                        placeholder='Type your question here'
                        fontSize='0.875em'
                        htmlFor="createDsrPostQuestion"
                        onClickCreatePost={handleCreateNewPost}
                      />
                    </Box>
                  </Flex>
                }
              </Flex>
            </ModalBody>
          </ModalContent>
        </Modal>
      )
    }
    case ('prepare-faq-question-in-draft'): {
      return (
        <Modal isOpen={true} closeOnOverlayClick={false} isCentered={true} preserveScrollBarGap={true}>
          <ModalOverlay />
          <ModalContent rounded='0.25em' minW={['90%', '500px']} maxW='700px'>
            <ModalBody p='0em'>
              <Flex direction='column' justify='space-around' alignItems='center' w='100%'>
                <Flex w='100%' p='1em' justify='space-between' align='center' borderBottom='1px solid' borderBottomColor='gray.100' >
                  <Flex w='100%' fontSize='1.25em' fontWeight='500' lineHeight='1.375em'>Prepare a question</Flex>
                  <Flex justify='flex-end' w='100%'><LaunchdeckCloseButton handleCloseModal={handleCloseModal} /></Flex>
                </Flex>
                <Box w='100%' py='1em' color='brandDark.500' fontSize='0.875em' lineHeight='1.25em' borderBottom='1px solid' borderBottomColor='gray.100'>
                  <Flex w='100%' px='1em' justify='flex-start'>Help your audience understand more quickly by providing answers to commonly asked questions</Flex>
                </Box>

                {showDsrModalLoader === true ? <PageLoader /> :
                  <Flex justify='center' w='100%'>

                    <Box w="100%" align="center">
                      <DsrPostTextAreaInput
                        userActionRequired={userActionRequired}
                        name="prepareDsrPostQuestion"
                        placeholder='Type your question here'
                        fontSize='1em'
                        htmlFor="prepareDsrPostQuestion"
                        onClickCreatePost={handleCreateNewPost}
                      />
                    </Box>
                  </Flex>
                }
              </Flex>
            </ModalBody>
          </ModalContent>
        </Modal>
      )
    }
    default: {
      console.log('There is no associated action with this user action:', userActionRequired)
      return null;
    }
  }

}
