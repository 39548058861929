import React from "react";
import { Avatar, AvatarGroup } from "@chakra-ui/react";


export default function DsrDisplayAssignedMembersAvatar({ dsrTeamMembers, assignedToUsers, maxDisplayedAvatars, isMobileView }) {

  const sellerTeam = dsrTeamMembers && dsrTeamMembers["sellerTeam"];
  const buyerTeam = dsrTeamMembers && dsrTeamMembers["buyerTeam"];
  const allTeams = [...sellerTeam, ...buyerTeam];

  let teamMembersToRender = [];

  for (const organization of allTeams) {
    const { orgTeamMembers } = organization || {};
    teamMembersToRender = (Array.isArray(orgTeamMembers) && orgTeamMembers.length > 0) && [...teamMembersToRender, ...orgTeamMembers]
  }

  teamMembersToRender = (teamMembersToRender && Array.isArray(assignedToUsers)) && teamMembersToRender.filter((member) => assignedToUsers.includes(member.userId));

  
  const displayTeam = teamMembersToRender && teamMembersToRender?.map((teamMember, teamMemberIndex) => {
    const { memberName, memberProfilePicSrc, memberStatus, userId } = teamMember || {};

    if (memberStatus === 'active' || memberStatus === 'pending') {
      return (
        <Avatar key={`team_member_display_${userId}_${teamMemberIndex}`}
          bg='gray.100' 
          color='gray.700'
          h={ isMobileView ? "3.2em" : "3.5em" }
          w={ isMobileView ? "3.2em" : "3.5em" }
          name={memberName}
          src={memberProfilePicSrc ? memberProfilePicSrc : ""} />
      );
    } else return null;
  })

  if (displayTeam?.length > 0) {
    return (
        <AvatarGroup  
          fontSize='2em'
          max={maxDisplayedAvatars ? maxDisplayedAvatars : 2} 
          spacing='-1.5em'
          pl={ isMobileView ? "0.2em" : "0.5em" }
          className="custom-avatar-group"
        >
          {displayTeam}
        </AvatarGroup>
    )
  } else {
    return null
  } 
  

}
