import React from "react";
import { Box, FormControl, Input, FormLabel, FormHelperText, FormErrorMessage } from "@chakra-ui/react";

export default function LaunchdeckFormInput({
  name, placeholder, onChange, onBlur, value, label, errorMessage, errorLogic,
  onPressEnter, isRequired, isInvalid, cursor,
  // Styling options
  p, bg, fontSize, labelFontSize, fontWeight, noBorder
}) {

  return (
    // <Box p="8px 0" h='36px' onKeyDown={onPressEnter && onPressEnter} w='100%'>
      <FormControl variant="floating" id="first-name" w='100%' onKeyDown={onPressEnter && onPressEnter} isRequired={isRequired} isInvalid={isInvalid || errorLogic}>
        <Input
          name={name && name}
          fontSize= {fontSize ? fontSize : '0.875em'}
          p={p ? p : '1.2em 1em'}
          //  autoFocus
          onBlur={onBlur && onBlur}
          value={value && value}
          placeholder={""}
          onChange={onChange && onChange}
         // Styling
          mb='0.2em'
          w='100%'
          h='1.4em'
          fontWeight={fontWeight ? fontWeight : '400'}
          color='brandDark.500'
          my='0.5em'
          borderRadius='0.25em'
          className='fade-in'
          bg={bg ? bg : "white"}
          border={noBorder && 'none'}
          cursor={cursor && cursor}
        />
        {/* It is important that the Label comes after the Control due to css selectors */}
        <FormLabel fontSize={labelFontSize ? labelFontSize : '0.875em'} bg='none' color='gray.500'>{label}</FormLabel>

        <Box pl='1em'>
          {(placeholder && !errorLogic) && <FormHelperText fontSize='0.8em'>{placeholder}</FormHelperText>}
          {errorLogic && <FormErrorMessage fontSize='0.8em'>{errorMessage}</FormErrorMessage>}
        </Box>
      </FormControl>
    // {/* </Box> */}
  );

}


