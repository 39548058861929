export const SET_USER_ACCOUNT_PROFILE = 'set_user_account_profile';
export const SET_USER_PROFILE_PICTURE = 'set_user_profile_picture';
export const SET_USER_IS_ORG_ADMIN = 'set_user_is_org_admin';
export const SET_USER_ORG_ACTIVE_TEAM_MEMBERS = 'set_user_org_active_team_members'


// OG
export const GET_USER_PROFILE_REQUEST = 'get_user_profile_request';
export const GET_USER_PROFILE_SUCCESS = 'get_user_profile_success';
export const GET_USER_PROFILE_FAILURE = 'get_user_profile_failure';

export const EDIT_USER_PROFILE_REQUEST = 'edit_user_profile_request';
export const EDIT_USER_PROFILE_SUCCESS = 'edit_user_profile_success';
export const EDIT_USER_PROFILE_FAILURE = 'edit_user_profile_failure';

export const UPDATE_USER_ACTION_EVENT_REQUEST = 'update_user_action_event_request';
export const UPDATE_USER_ACTION_EVENT_SUCCESS = 'update_user_action_event_success';
export const UPDATE_USER_ACTION_EVENT_FAILURE = 'update_user_action_event_failure';

export const UPLOAD_PROFILE_PICTURE_REQUEST = 'upload_profile_picture_request';
export const UPLOAD_PROFILE_PICTURE_SUCCESS = 'upload_profile_picture_success';
export const UPLOAD_PROFILE_PICTURE_FAILURE = 'upload_profile_picture_failure';
// export const UPLOAD_PROFILE_PICTURE_RESET = 'upload_profile_picture_reset';

export const RESEND_EMAIL_VERIFICATION_REQUEST = 'resend_email_verification_request';
export const RESEND_EMAIL_VERIFICATION_SUCCESS = 'resend_email_verification_success';
export const RESEND_EMAIL_VERIFICATION_FAILURE = 'resend_email_verification_failure';

export const UPDATE_USER_SETTINGS_REQUEST = 'update_user_settings_request';
export const UPDATE_USER_SETTINGS_SUCCESS = 'update_user_settings_success';
export const UPDATE_USER_SETTINGS_FAILURE = 'update_user_settings_failure';

export const GET_USER_SETTINGS_REQUEST = 'get_user_settings_request';
export const GET_USER_SETTINGS_SUCCESS = 'get_user_settings_success';
export const GET_USER_SETTINGS_FAILURE = 'get_user_settings_failure';

export const UPDATE_USER_PASSWORD_REQUEST = 'update_user_password_request';
export const UPDATE_USER_PASSWORD_SUCCESS = 'update_user_password_success';
export const UPDATE_USER_PASSWORD_FAILURE = 'update_user_password_failure';

export const GET_USER_ORG_TEAM_MEMBERS_REQUEST = 'get_user_org_team_members_request';
export const GET_USER_ORG_TEAM_MEMBERS_SUCCESS = 'get_user_org_team_members_success';
export const GET_USER_ORG_TEAM_MEMBERS_FAILURE = 'get_user_org_team_members_failure';