import React from 'react';
import { Modal, ModalOverlay, ModalBody, ModalContent, useDisclosure, Flex, Icon, Box } from "@chakra-ui/react";
import LaunchdeckCloseButton from './LaunchdeckCloseButton';


export default function ClaverModal(props) {
  const {showModal, isWarning, noCancelOption} = props || {};
  const { isOpen, onClose } = useDisclosure();
  
  return (
    <Modal 
      isOpen={showModal ? showModal : isOpen} 
      onClose={onClose} 
      preserveScrollBarGap={true} 
      returnFocusOnClose={false} 
      // size="10em"
      // size={['90%', '90%', '600px', '600px']} 
      maxHeight='80vh' 
      closeOnOverlayClick={false} 
      isCentered={true} 
      position='fixed'
      // scrollBehavior='inside'
      >
      <ModalOverlay />
      <ModalContent rounded='0.5em' mx={'5%'}  minW={['90%', '500px']} maxW='700px' >
        <ModalBody px='0%'>
          <Flex direction='column' justify='space-between'>
            <Flex w='100%' justify='space-between' align='center' borderBottom='1px solid' borderBottomColor='gray.300' px='2em' py='0.4em'>
              <Flex justify='flex-start' align='center'>
              {isWarning && <Icon name="warning" boxSize="1em" color="red.400" mr='1em'/>}<Box as='span' fontSize='1.1em' lineHeight='22px' color='brand.700' fontWeight='500' > {props.title} </Box> 
              </Flex>
              {noCancelOption ? null : <LaunchdeckCloseButton handleCloseModal={props.handleCloseModal} />}
            </Flex>
            <Flex direction='column'>
              {props.children}
            </Flex>
          </Flex>
        </ModalBody>

      </ModalContent>
    </Modal>
  )  

}
