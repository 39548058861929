import React from "react";
import { Button, Flex } from '@chakra-ui/react';

import { Box, Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";
import { RiMenu5Line } from "react-icons/ri";

export default function DsrActionOptionsButton(props) {
    const {isActive, actionId, mapCategoryId, handleClickViewDetailsOption, handleClickEditActionOption, handleClickDeleteActionOption} = props || {};

    return (
      <React.Fragment>
        <Menu closeOnSelect={true} >
          <MenuButton as={Button} bg='white' rounded='0.5em' height='5em' width='5em' justifyContent='flex-end' p='0.5em' justify='center'>
            <Flex w='100%' justify='center'>
              <RiMenu5Line fontSize="1.8em"/>
            </Flex>
          </MenuButton>
          <MenuList  minWidth="15em" boxShadow="xs">
            <Box color='gray.700'> <MenuItem name='showEndAgreementModal' className='filter-menu__dropdown-option-display' onClick={()=>handleClickViewDetailsOption(mapCategoryId, actionId)}  >View details</MenuItem> </Box>
            <Box color='gray.500'> <MenuItem name='showEndAgreementModal' className='filter-menu__dropdown-option-display' isDisabled={isActive ? false : true} onClick={()=>handleClickEditActionOption(mapCategoryId, actionId)}  >Edit action item</MenuItem> </Box>
            <Box color='red.400'> <MenuItem name='showEndAgreementModal' className='filter-menu__dropdown-option-display' isDisabled={isActive ? false : true}  onClick={()=>handleClickDeleteActionOption(mapCategoryId, actionId)}  >Delete action item</MenuItem> </Box>
          </MenuList>
        </Menu>

        
      </React.Fragment>
    );
  
}

