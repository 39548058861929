import { SET_DSR_DRAFT_MODAL } from '../../actions/dsr-actions-types'

const INITIAL_STATE_DSR_DRAFT_MODAL = { show: false }

export function showDsrModalReducer(state = INITIAL_STATE_DSR_DRAFT_MODAL, action) {
    switch (action.type) {
        case SET_DSR_DRAFT_MODAL:
            return { show: action.payload };
        default:
            return state;
    }
}