import React, { Component } from 'react'
import { connect } from 'react-redux'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'

import Layout from './features/layout/components/layout/components/Layout'
import Home from './features/home/components/Home'
import Login from './features/auth/components/Login'
import Signup from './features/auth/components/Signup'
import auth from './config/app-specific-config/auth'
import PasswordResetPage from './features/auth/components/PasswordResetPage'
import SetNewPasswordPage from './features/auth/components/SetNewPasswordPage'
import VerifyEmailPage from './features/auth/components/VerifyEmailPage'
import TermsOfService from './features/global/components/helper-components/components/TermsOfService'
import ScrollToTop from './features/global/components/helper-components/components/ScrollToTop'
import PaymentsSuccess from './features/payments/components/PaymentsSuccess'
import PageNotFoundContent from './features/global/components/helper-components/components/PageNotFoundContent'

import CustomerSupportContact from './features/support/components/CustomerSupportContact'
import ChangeNewPasswordPage from './features/auth/components/ChangeNewPasswordPage'

import './features/global/styles/shared.scss'
import './features/global/styles/global-styling.scss'
import './features/global/styles/shame.scss'

import DsrManageAccessPage from './features/digital-sales-room/dsr-access-mgmt/DsrManageAccessPage'
import InvalidRequestPage from './features/global/components/helper-components/components/InvalidRequestPage'
import JWTExpiredModal from './features/auth/components/JwtExpiredModal'
import AuthProvider from './features/auth/components/AuthProvider'
import DsrDisplayPageContainer from './features/digital-sales-room/components/DsrDisplayPageContainer'
import DsrEditDraftPageContainer from './features/digital-sales-room/dsr-draft/DsrEditDraftPageContainer'
import VaultPageContainer from './features/vault/VaultPageContainer'
import VaultReceivedRoomsPageContainer from './features/vault/VaultReceivedRoomsPageContainer'
import UserProfilePageContainer from './features/profile/components/UserProfilePageContainer'
import { LoomProvider } from './features/digital-sales-room/dsr-record-content/LoomContext'
import ProspectEditDraftPageContainer from './features/digital-sales-room/dsr-prospecting/draft-prospect-dsr/ProspectEditDraftPageContainer'
import VaultProspectorsPageContainer from './features/vault/VaultProspectorsPageContainer'
import ProspectDsrDisplayPageContainer from './features/digital-sales-room/dsr-prospecting/ProspectDsrDisplayPageContainer'
import StaticDsrDisplayContainer from './features/digital-sales-room/components/StaticDsrDisplayContainer'
import PublicErrorPage from './features/global/components/helper-components/components/PublicErrorPage'
import AppAdminCustomerSetupPage from './features/profile/components/saa-setup/AppAdminCustomerSetupPage'
import GenesisUserWelcomePage from './features/auth/components/GenesisUserWelcomePage'
import NewOrgUserWelcomePage from './features/auth/components/NewOrgUserWelcomePage'
import ContactLaunchdeckPage from './features/auth/components/ContactLaunchdeckPage'
import CurrentlyUnavailablePage from './features/global/components/helper-components/components/CurrentlyUnavailablePage'
import UserNotificationPage from './features/dashboard/components/notifications/UserNotificationPage'
import WaitlistPage from './features/auth/components/WaitlistPage'


const Error = () => {
  if (auth.isLoggedIn() !== null) {
    return <PageNotFoundContent />;
  } else {
    return <Redirect to="/page-not-found" />;
  }
};

const ErrorVisitor = () => {
  return (
    <PublicErrorPage />
  );
};

const PrivateRoute = ({ component: Component, ...rest }) => {
  return <Route {...rest} render={props => {

    return (
        <AuthProvider props={props}>
          <Component props={props}/>
          <JWTExpiredModal/>
        </AuthProvider>
    )
  }} />
};

// function querystring(name, url = window.location.href) {
//   name = name.replace(/[[]]/g, "\\$&");

//   const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)", "i");
//   const results = regex.exec(url);

//   if (!results) {
//     return null;
//   }
//   if (!results[2]) {
//     return "";
//   }

//   return decodeURIComponent(results[2].replace(/\+/g, " "));
// }

const LoginRedirectRoute = ({ component: Component, login, ...rest }) => {
  // const redirectUrl = querystring("redirect");
  return (
    <Route {...rest} render={
      props => {
        const query = new URLSearchParams(props.location.search)
        const redirectUrl = query.get("redirect")
        return (
          !login.data && !auth.isLoggedIn()
            ?
            <Component {...props} />
            :
            <Redirect
              to={redirectUrl === "" || redirectUrl === null ? "/" : redirectUrl}
            />
        )
      }} />
  )
}

const SignupRedirectRoute = ({ component: Component, login, signup, ...rest }) => {
  // const redirectUrl = querystring("redirect");
  return (
    <Route {...rest} render={
      props => {
        // const query = new URLSearchParams(props.location.search)
        // const redirectUrl = query.get("redirect")
        return (
          // !login.data && !signup.data && !auth.isLoggedIn()
          //   ?
            <Component {...props} />
            // :
            // <Redirect
            //   to={redirectUrl === "" || redirectUrl === null ? "/" : redirectUrl}
            // />
        )
      }} />
  )
}


class Routes extends Component {

  render() {
    return (
      <BrowserRouter>
        <ScrollToTop>
          <Switch>
            <LoginRedirectRoute path="/login" component={Login} login={this.props.login} />
            <SignupRedirectRoute path="/signup" component={Signup} login={this.props.login} 
            // signup={this.props.signup} 
            />
            <SignupRedirectRoute path="/activate" component={Signup} login={this.props.login} />
            <SignupRedirectRoute path="/waitlist" component={WaitlistPage} login={this.props.login} signup={this.props.signup} />

            <Route path="/reset-password" component={PasswordResetPage} />
            <Route path="/set-new-password/:resetPasswordToken" component={SetNewPasswordPage} />
            <Route path="/verify-email/:verifyEmailToken" component={VerifyEmailPage} />
    
            <Route path="/welcome/:verifyToken" component={GenesisUserWelcomePage} />
            <Route path="/org/welcome/:orgId/:verifyToken" component={NewOrgUserWelcomePage} />

            <Route path="/dsr/access/:dsrId" exact component={DsrManageAccessPage} />
            <Route path="/pdsr/:pDsrId" exact component={ProspectDsrDisplayPageContainer} />
            <Route path="/dsr/pub/:dsrId" exact component={StaticDsrDisplayContainer} /> 

            <Route path="/contact-launchdeck" component={ContactLaunchdeckPage} />
            <Route path="/page-not-found" component={ErrorVisitor} />
            <Route path="/privacy-terms-of-service" exact component={TermsOfService} />

            <Layout>
              <LoomProvider>
              <Switch>
                <PrivateRoute path="/" component={Home} exact  />

                <PrivateRoute path="/unavailable-dsr" component={CurrentlyUnavailablePage} exact  />
                <PrivateRoute path="/dsr/:dsrId" component={DsrDisplayPageContainer} exact  />
                <PrivateRoute path="/dsr/:dsrId/:pageIdToken" component={DsrDisplayPageContainer} exact  />{/* Note: pageIdToken does not contain "pg_" prefix. Not included for security */}
                <PrivateRoute path="/draft/:dsrId" component={DsrEditDraftPageContainer} exact  />
                {/* <PrivateRoute path="/template/:dsrId" component={DsrTemplateEditPageContainer} exact  /> */}
                               
                <PrivateRoute path="/draft/pdsr/:pDsrId" component={ProspectEditDraftPageContainer} exact/>

                <PrivateRoute path="/home" component={VaultPageContainer} exact  />  
                <PrivateRoute path="/vault" component={VaultPageContainer} exact  /> {/* TODO: Refactor and remove /vault route eventually */}
                <PrivateRoute path="/prospectors" component={VaultProspectorsPageContainer} exact  />
                <PrivateRoute path="/received-rooms" component={VaultReceivedRoomsPageContainer} exact  />

                <PrivateRoute path="/notifications" component={UserNotificationPage} exact  />
                <PrivateRoute path="/profile" component={UserProfilePageContainer} exact  />
                <PrivateRoute path="/saa/manage" component={AppAdminCustomerSetupPage} exact  />
                <PrivateRoute path="/profile/change-password" component={ChangeNewPasswordPage} exact  />
                <PrivateRoute path="/support" component={CustomerSupportContact} exact  />

                <PrivateRoute path="/subscribe-successful/:paymentToken" component={PaymentsSuccess}  />
               
                <PrivateRoute path="/invalid" component={InvalidRequestPage} exact  />

                {/* R&D */}
                <PrivateRoute component={Error} />

              </Switch>
              </LoomProvider>
            </Layout>

          </Switch>
        </ScrollToTop>
      </BrowserRouter>
    );
  }
}
const mapStateToProps = state => ({
  login: state.auth.data.login,
  signup: state.auth.data.signup,
});
export default connect(mapStateToProps)(Routes);
