import { useState } from 'react';
import { Collapse } from '@chakra-ui/react'
import SubscriptionNudgeBanner from "../../banners/SubscriptionNudgeBanner";
import NoOrgUserSignUpNudgeBanner from '../../banners/NoOrgUserSignUpNudgeBanner';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { bindActionCreators } from 'redux'
import { updateUserSettings } from '../../../../profile/actions/users-profile-actions';
import DemoAcquisitionNudgeBanner from '../../banners/DemoAcquisitionNudgeBanner';

// Banner management
function DynamicAppBannerDisplay({bannerType, updateUserSettings, freeTrialCode, isUndismissable}) {

  const [showBanner, setShowBanner] = useState(true)


const handleCloseBanner = () => {
  setShowBanner(false)
}

  if (!bannerType) {
    return null
  }  

  const determineBanner = () => {
    switch(bannerType) {
      case ("free-trial-subscribe-nudge"):{
        return <SubscriptionNudgeBanner  handleCloseBanner={handleCloseBanner} updateUserSettings={updateUserSettings} offerFreeTrial={true} freeTrialCode={freeTrialCode} isUndismissable={isUndismissable}/>
      }        
      case("subscription-nudge"): {
        return <SubscriptionNudgeBanner  handleCloseBanner={handleCloseBanner} updateUserSettings={updateUserSettings} isUndismissable={isUndismissable}/>
      }
      case("audience-sign-up-nudge"): {
        return <NoOrgUserSignUpNudgeBanner handleCloseBanner={handleCloseBanner} updateUserSettings={updateUserSettings}/>
      }
      case("demo-conversion-nudge"): {
        return <DemoAcquisitionNudgeBanner handleCloseBanner={handleCloseBanner} updateUserSettings={updateUserSettings}/>;
      }
      default: {
        return null;
      }
    }
  }
  
  return (
    < >

      <Collapse in={showBanner} animateOpacity>
        {determineBanner()}
      </Collapse>
  </>
  )



}

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
      {
				updateUserSettings
      },
      dispatch,
    )

export default withRouter(connect(null, mapDispatchToProps)(DynamicAppBannerDisplay))