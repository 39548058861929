import * as types from '../actions/organizations-actions-types';

//Update Org Profile
const UPDATE_ORG_PROFILE_INITIAL_STATE = {
	isLoading: false,
	results: [],
	error: '',
};


// Launchdeck version
export function adminOrgProfileManagementReducer(state = UPDATE_ORG_PROFILE_INITIAL_STATE, action) {
	switch (action.type) {
		case types.UPDATE_ORG_PROFILE_RESET:
			return UPDATE_ORG_PROFILE_INITIAL_STATE;
		case types.UPDATE_ORG_PROFILE_REQUEST:
			return { ...state, isLoading: true, results: [] };
		case types.UPDATE_ORG_PROFILE_SUCCESS:
			return { ...state, isLoading: false, results: action.payload };
		case types.UPDATE_ORG_PROFILE_FAILURE:
			return { ...state, isLoading: false, error: 'Error while trying to update Org Profile' };
		default:
			return state;
	}
}


//Upload Org Logo
const UPLOAD_ORG_LOGO_INITIAL_STATE = {
	isLoading: false,
	results: "",
	error: '',
};

export function uploadOrgLogoImageReducer(state = UPLOAD_ORG_LOGO_INITIAL_STATE, action) {
	switch (action.type) {
		case types.GET_ORG_LOGO_IMAGE_REQUEST:
			return { ...state, isLoading: true, results: "" };
		case types.GET_ORG_LOGO_IMAGE_SUCCESS:
			return { ...state, isLoading: false, results: action.payload, imageUploaded: true };
		case types.GET_ORG_LOGO_IMAGE_FAILURE:
			return { ...state, isLoading: false, imageUploaded: false, error: 'Error while trying to upload Org Logo' };
    case types.GET_ORG_LOGO_IMAGE_RESET:
			return UPLOAD_ORG_LOGO_INITIAL_STATE;
		default:
			return state;
	}
}

//Set Org Logo Url
const SET_ORG_LOGO_INITIAL_STATE = {
  isLoading: false,
  results: [],
  error: ''
};

export function setOrgLogoImageReducer(state = SET_ORG_LOGO_INITIAL_STATE, action) {
  switch (action.type) {
    case types.SET_ORG_LOGO_IMAGE_REQUEST:
      return { ...state, isLoading: true, results: [] };
    case types.SET_ORG_LOGO_IMAGE_SUCCESS:
      return { ...state, isLoading: false, results: action.payload, imageSet: true };
    case types.SET_ORG_LOGO_IMAGE_FAILURE:
      return { ...state, isLoading: false, imageSet: false, error: 'Error while trying to set Org Logo url' };
    case types.SET_ORG_LOGO_IMAGE_RESET:
      return UPDATE_ORG_PROFILE_INITIAL_STATE;
    default:
      return state;
  }
}
