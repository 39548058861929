import React, { useEffect, useRef, useState } from 'react'

import { Box, Flex } from '@chakra-ui/react'

import { removeEmptySubWidgets, removeEmptyWidgets } from "../helpers/dsr-draft-helpers";
import DsrHeader from '../components/DsrHeader'
import DsrGenericErrorModal from '../components/DsrGenericErrorModal'
import DsrDynamicModal from '../messages/DsrDynamicModal'
import { useParams, useHistory } from 'react-router-dom'
import RenderDraftDsrContent from './RenderDraftDsrContent'
import RenderPreviewDsrContent from "./RenderPreviewDsrContent"
import _ from 'lodash'
import LaunchdeckButton from '../../global/components/helper-components/components/LaunchdeckButton';
import DsrUnsavedChangePrompt from './DsrUnsavedChangePrompt';

export const DsrEditDraftPage = ({
  dsrTitle,
  isRoomTemplate,
  dsrPagesRedux,
  dsrTeamMembersRedux,
  dsrStatusRedux,
  showDsrModalRedux,
  userActionRequiredRedux,
  userProfileRedux,
  showDsrModalLoaderRedux,
  currentDsrId,
  fetchInitialDsrData,
  resetDsrFeedContent,
  resetDsrTitle,
  resetDsrSelectedDisplaySubpage,
  resetDsrPages,
  updateDsrReset,
  selectedDisplaySubpage,
  getDsrFeedContent,
  dsrFeedContent,
  dsrInvalidRequestRedux,
  changeDsrPopupState,
  changeDsrModalState,
  isMobileView,
  duplicateDsrPage
}) => {
  const { dsrId } = useParams();
  const history = useHistory();

  const initRef = useRef(false);
  let [dsrPagesLocalState, setDsrPagesLocalState] = useState(null)
  let [dsrHasUnsavedChanges, setDsrHasUnsavedChanges] = useState(false)
  let [modalTransferPayload, setModalTransferPayload] = useState('')
  let [isPagePreviewMode, setIsPagePreviewMode] = useState(false)
  const clearedWidgets = removeEmptySubWidgets(dsrPagesLocalState);
  const clearedDsrPagesLocalState = removeEmptyWidgets(clearedWidgets);

  // console.log('UUU isRoomTemplate', isRoomTemplate)

  useEffect(() => {
    if (!initRef.current && dsrId !== 'new') {
      document.title = 'Launchdeck Digital Sales Room'
      document.description =
        'Create customised private digital sales rooms that let Sales & Purchasing teams agree on deals quickly'

      fetchInitialDsrData(dsrId, ['draft', 'reverted-draft'])
      initRef.current = true
    }
  }, [dsrId, fetchInitialDsrData])

  useEffect(() => {
    if (dsrInvalidRequestRedux === true) {
      history.push(`/invalid`, {
        redirectionUri: `/home`
      })
    }

  }, [currentDsrId, dsrId, dsrInvalidRequestRedux])

  useEffect(() => {
    if (selectedDisplaySubpage === 'updates-subpage' && ((dsrFeedContent.length === 0) || (currentDsrId !== dsrId))) getDsrFeedContent(dsrId)
  }, [currentDsrId, dsrFeedContent, dsrId, getDsrFeedContent, selectedDisplaySubpage])

  useEffect(() => {
    dsrPagesRedux && setDsrPagesLocalState(_.cloneDeep(dsrPagesRedux))
  }, [dsrPagesRedux])

  useEffect(() => {
    if (dsrPagesLocalState) setDsrHasUnsavedChanges(!_.isEqual(dsrPagesLocalState, dsrPagesRedux))
  }, [dsrPagesRedux, dsrPagesLocalState, setDsrHasUnsavedChanges])

  useEffect(() => {
    const handleBeforeUnload = event => {
      if (dsrHasUnsavedChanges) {
        event.preventDefault();
        event.returnValue = ''; // This is necessary for the browser to display the confirmation prompt
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [dsrHasUnsavedChanges])

  const handleDsrModalClose = () => {
    changeDsrPopupState('')
    changeDsrModalState(false)
  }

  // Team Members Management
  const handleViewTeamMembers = () => {
    changeDsrModalState(true)
    changeDsrPopupState('view-team-members')
  }

  // DSR status Management
  // TODO Refactor: Rename this to handlePublishDsr
  const handleChangeDsrStatus = () => {
    if (dsrStatusRedux === 'draft') {
      changeDsrModalState(true)
      changeDsrPopupState('publish-dsr')
    }

    if (dsrStatusRedux === 'reverted-draft') {
      changeDsrModalState(true)
      changeDsrPopupState('re-publish-dsr')
    }
  }

  const handleChangeUserActionRequired = (newUserActionRequired, modalTransferPayload) => {
    setModalTransferPayload(modalTransferPayload)
    changeDsrModalState(true)
    changeDsrPopupState(newUserActionRequired)
  }

  const handlerSetDsrPages = (pages) => {
    setDsrPagesLocalState(pages)
  }

  const handlerAddDsrPage = (newDsrPage) => {
    setDsrPagesLocalState((prevState) => [...prevState, newDsrPage])
  }

  const handlerRemoveDsrPage = (dsrPageId) => {
    setDsrPagesLocalState((prevState) => prevState.filter((page) => page.pageId !== dsrPageId))
  }

  useEffect(() => {
    return () => resetDsrFeedContent() && resetDsrTitle() && resetDsrSelectedDisplaySubpage() && resetDsrPages() && updateDsrReset()
  }, [resetDsrFeedContent, resetDsrTitle, resetDsrSelectedDisplaySubpage, resetDsrPages, updateDsrReset])



  if (isMobileView) {
    return (
      <Flex margin='-2% -7% -7% -7%' height='100vh' justifyContent='center' alignItems='center' background='white' flexDirection='column'>
        <Flex mb='0.625em' px='2em' justify='center' textAlign='center'>
          For the best experience, we recommend editing the Room using a computer's wider display
        </Flex>
        <Flex direction='column' mt='1em' justify='center' alignItems='center'>
          <Flex justify={['center', 'flex-end', 'flex-end', 'flex-end']} w='100%'>
            <LaunchdeckButton bg='brand.500' color='white' onClick={() => window.location.replace('/home')} label='Edit on a computer later' />
          </Flex>
        </Flex>
      </Flex>
    )
  }

  // UX Listener
  return (
    <Flex direction="column" height='100%'>
      <Flex direction="row" flexGrow="1">
        <Flex w="100%" direction="column" alignItems="center" bg="white" rounded="0.8em">
            <DsrUnsavedChangePrompt
            when={dsrHasUnsavedChanges}
            // dsrTitle={dsrTitle}
            // message="You have unsaved changes. Are you sure you want to leave?"
          />

          <Box width='100%' position='fixed' zIndex='2'>
            <DsrHeader
              handleViewTeamMembers={handleViewTeamMembers}
              handleChangeDsrStatus={handleChangeDsrStatus}
              dsrTeamMembers={dsrTeamMembersRedux}
              userProfile={userProfileRedux}
              dsrId={dsrId}
              dsrPages={clearedDsrPagesLocalState}
              isEditMode={true}
              isRoomTemplate={isRoomTemplate}
              isPagePreviewMode={isPagePreviewMode}
              setIsPagePreviewMode={setIsPagePreviewMode}
            />
          </Box>

          <Flex w="100%" className="authentication-container" justify="center" marginTop='140px'>
            { isPagePreviewMode
              ? (
                <RenderPreviewDsrContent
                  dsrId={dsrId}
                  pagesLocalState={dsrPagesLocalState}
                  dsrTitle={dsrTitle}
                />
              )
              : (
                <RenderDraftDsrContent
                  isRoomTemplate={isRoomTemplate}
                  pagesLocalState={dsrPagesLocalState}
                  replaceDsrPages={handlerSetDsrPages}
                  addDsrPage={handlerAddDsrPage}
                  removeDsrPage={handlerRemoveDsrPage}
                  duplicateDsrPage={duplicateDsrPage}
                  handleChangeUserActionRequired={handleChangeUserActionRequired}
                />
              )
            }
            <DsrGenericErrorModal dsrId={dsrId} />
            <DsrDynamicModal
              dsrId={dsrId}
              userActionRequired={userActionRequiredRedux}
              showDsrModal={showDsrModalRedux}
              showDsrModalLoader={showDsrModalLoaderRedux}
              handleDsrModalClose={handleDsrModalClose}
              dsrPages={clearedDsrPagesLocalState}
              handleChangeUserActionRequired={handleChangeUserActionRequired}
              modalTransferPayload={modalTransferPayload}
            />

          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}
