import React from 'react';
import { Redirect } from 'react-router-dom';
import auth from '../../../config/app-specific-config/auth';

export const JWT_TOKEN_KEY = 'JWT_TOKEN_KEY';

export const redirectToHomepageIfLoggedIn = () => {
	// If the user is already signed in, redirect him to the dashboard screen
	if (auth.isLoggedIn() !== null) {
		return <Redirect to={'/home'} />;
	}
};

export const redirectToDsrIfLoggedIn = (dsrId) => {	
	// If the user is already signed in, redirect him to the specific DSR
	if (auth.isLoggedIn() !== null) {
		return <Redirect to={`/dsr/${dsrId}`} />;
	}
};


