//
// Helpers that set business logic on which roles can perform which actions
//

import { getUserDetailsUsingDsrMembersArray } from "../../profile/helpers/user-helper";


export function checkUserTeam(dsrTeamMembers, thisUserIdToken) {

  const foundMember = getUserDetailsUsingDsrMembersArray(dsrTeamMembers, thisUserIdToken)
  const {role} = foundMember || {};

  if (role ==='seller-member' || role === 'seller-lead') {
    return 'host' // New terminology for 'seller team', which is a legacy term
  } else if (role ==='buyer-member') {
    return 'audience' // New terminology for 'buyer team', which is a legacy term
  } else return null;
}

export function checkUserIsDsrAdmin(dsrTeamMembers, thisUserIdToken) {

  const foundMember = getUserDetailsUsingDsrMembersArray(dsrTeamMembers, thisUserIdToken)
  const {role} = foundMember || {};

  if (role === 'seller-lead') {
    return true
  } else return false;
}


