import React, { useState, useEffect, useRef } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';

import { Box, Flex } from "@chakra-ui/react";
import InputTextAreaExpandable from '../../../forms/TextareaField/components/InputTextAreaExpandable';
import { editFaqAnswer, resetDeleteFaqAnswer, editFaqQuestion, resetEditFaqQuestion } from '../../../digital-sales-room/actions/dsr-actions';
import LaunchdeckCloseButton from "../../../global/components/helper-components/components/LaunchdeckCloseButton";
import DsrDynamicModal from "../../../digital-sales-room/messages/DsrDynamicModal";
import LaunchdeckButton from "../../../global/components/helper-components/components/LaunchdeckButton";



function EditQuestionAndAnswerModule(props) {

  const { htmlFor, 
    placeholder, 
    dsrId, 
    questionId,
    isDsrAdmin,
    handleCloseInputArea,
    editFaqAnswerOutcome, 
    deleteFaqAnswerOutcome, 
    editFaqQuestionOutcome, 
    editedFaqQuestionIsSaving,
    currentQuestionTextValue,
    currentAnswerTextValue,
    //Actions
    editFaqAnswer,
    // resetDeleteFaqAnswer,
    editFaqQuestion,
    resetEditFaqQuestion
  } = props;

  const originalQuestionValue = useRef(currentQuestionTextValue ? currentQuestionTextValue : null)
  const originalAnswerValue = useRef(currentAnswerTextValue ? currentAnswerTextValue : null)

  // Inputs
  const [inputQuestionValue, setInputQuestionValue] = useState(currentQuestionTextValue ? currentQuestionTextValue : "");
  const [inputAnswerValue, setInputAnswerValue] = useState(currentAnswerTextValue ? currentAnswerTextValue : "");

  // UX
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false)
const [ questionIsSaving, setQuestionIsSaving] = useState(false)
const [ answerIsSaving, setAnswerIsSaving] = useState(false)

  const [showUpdateErrorTag, setShowUpdateErrorTag] = useState(null)
  const [displayEmptyInputError, setDisplayEmptyInputError] = useState(false)
  const [isInvalidInput, setIsInvalidInput] = useState("");


  useEffect(() => {
    if (inputQuestionValue && inputAnswerValue) {
      setDisplayEmptyInputError(false)
    }
  }, [inputQuestionValue, inputAnswerValue]);


  useEffect(() => {
    if (editFaqAnswerOutcome) {
      const { faqAnswerSuccessfullyUpdated
        // , updatedAnswer 
      } = editFaqAnswerOutcome || {}
      if (faqAnswerSuccessfullyUpdated === true) {
        setShowUpdateErrorTag(false)
        handleCloseInputArea()
        // setAwaitingNewAnswer(true)
        // setUpdatedAnswerDisplay(updatedAnswer)
      }

      if (!faqAnswerSuccessfullyUpdated) {
        setShowUpdateErrorTag(true)
      }
      setAnswerIsSaving(false)
    }
  }, [editFaqAnswerOutcome, handleCloseInputArea]);

  useEffect(() => {
    if (deleteFaqAnswerOutcome) {
      const { faqAnswerSuccessfullyUpdated } = deleteFaqAnswerOutcome || {}
      if (faqAnswerSuccessfullyUpdated === true) {
        setShowUpdateErrorTag(null)
        !questionIsSaving && handleCloseInputArea()
        resetDeleteFaqAnswer()

      }

      if (faqAnswerSuccessfullyUpdated === false) {
        setShowUpdateErrorTag(true)
      }
      setAnswerIsSaving(false)
    }
  }, [deleteFaqAnswerOutcome, handleCloseInputArea, resetDeleteFaqAnswer]);

  useEffect(() => {
    if (editFaqQuestionOutcome) {
      const { faqQuestionSuccessfullyUpdated } = editFaqQuestionOutcome || {}

      if (faqQuestionSuccessfullyUpdated === true) {
        setShowUpdateErrorTag(false)
        !answerIsSaving && handleCloseInputArea()
        resetEditFaqQuestion()
      }

      if (!faqQuestionSuccessfullyUpdated) {
        setShowUpdateErrorTag(true)
      }

      setQuestionIsSaving(false)
    }
  }, [editFaqQuestionOutcome, handleCloseInputArea, resetEditFaqQuestion]);


  const onChangeHandler = event => {
    if (!inputQuestionValue || !inputAnswerValue) {
      setDisplayEmptyInputError(false)
    }

    if (event.target.name === "faqQuestion") {
      setInputQuestionValue(event.target.value);
    } else if (event.target.name === "faqAnswer") {
      setInputAnswerValue(event.target.value)
    }
 
    setIsInvalidInput("")
  };

  const handleEmptyInput = () => {
    setIsInvalidInput(true)
    setDisplayEmptyInputError(true)
  }

  const handleClickEditFaqQuestion = () => {
    if (!inputQuestionValue) {
      setDisplayEmptyInputError(true)
    } 
    else if (originalQuestionValue !== inputQuestionValue) {
      const editFaqQuestionMetadata = {
        dsrId: dsrId,
        questionId: questionId,
        faqQuestionContent: inputQuestionValue 
      }

      editFaqQuestion(editFaqQuestionMetadata)
      setInputQuestionValue(currentQuestionTextValue ? currentQuestionTextValue : "")
      setQuestionIsSaving(true)
    }
  }

  const handleClickEditFaqAnswer = () => {
    if (!inputAnswerValue) {
      setDisplayEmptyInputError(true)
    } else if (originalAnswerValue !== inputAnswerValue) {
      const editFaqAnswerMetadata = {
        dsrId: dsrId,
        questionId: questionId,
        faqAnswerContent: inputAnswerValue
      }

      editFaqAnswer(editFaqAnswerMetadata)
      setInputAnswerValue(currentAnswerTextValue ? currentAnswerTextValue : "")
      setAnswerIsSaving(true)
    }
  }



  const handleSave = () => {
    if (originalQuestionValue.current === inputQuestionValue?.trim() && originalAnswerValue.current  === inputAnswerValue?.trim()) {
      handleCloseInputArea()
      setDisplayEmptyInputError(false)
    }

    if (originalQuestionValue.current !== inputQuestionValue?.trim()) {
      handleClickEditFaqQuestion()
    }

    if (originalAnswerValue.current  !== inputAnswerValue?.trim()) {
      handleClickEditFaqAnswer()
    }
  }

  const handleClickCloseButton = () => {
    handleCloseInputArea()
    setDisplayEmptyInputError(false)
    setInputAnswerValue("")
    setInputQuestionValue("")
  }

  const handleCloseModalButton = () => {
    setShowConfirmDeleteModal(false)
  }



  return (
    <Box htmlFor={htmlFor} w='100%'>
      <React.Fragment>
        <Flex w='100%' justify='space-between' align='center' px='1em' pt='0.5em'>
          <Box fontSize='0.6em' p='0.25em 1em' bg='brandPink.500' color='white' fontWeight='600' rounded='0.5em'>EDIT MODE</Box>
            <LaunchdeckCloseButton handleCloseModal={handleClickCloseButton} />
        </Flex>
        <Box p='1em'>
          <Flex w='100%' mb='0.5em' fontSize='0.75em' fontWeight='600' color='brandDark.500' justify='flex-start' px='0.5em'>QUESTION</Flex>
        <InputTextAreaExpandable
            name="faqQuestion"
            onChangeHandler={onChangeHandler}
            inputValue={inputQuestionValue}
            placeholder={placeholder}
            isInvalidInput={isInvalidInput}
            isLoadingState={editedFaqQuestionIsSaving}
            bg='white'
          /> 

          <Flex w='100%' mt='2em' mb='0.5em' fontSize='0.75em' fontWeight='600' color='brandDark.500' justify='flex-start' px='0.5em'>ANSWER</Flex>
          <InputTextAreaExpandable
              name="faqAnswer"
            onChangeHandler={onChangeHandler}
            inputValue={inputAnswerValue}
            placeholder={placeholder}
            isInvalidInput={isInvalidInput}
            isLoadingState={editedFaqQuestionIsSaving}
                 bg='white'
          />
          {displayEmptyInputError && <Box fontSize="0.8em" color="pink.500" mt='0.2em'>Did you want to delete the question? Simply use the delete button below. </Box>}

          <Flex mt='0.5em' justify='flex-end' w='100%'>

            <DisplayButtonMode
              showUpdateErrorTag={showUpdateErrorTag}
              setShowConfirmDeleteModal={setShowConfirmDeleteModal}
              handleCtaButtonClick={handleSave}
              handleEmptyInput={handleEmptyInput}
              submittedInputIsSaving={editedFaqQuestionIsSaving}
              allowSave={(inputAnswerValue && inputQuestionValue) ? true : false}
              buttonMode={'update'}
              buttonText={'Save changes'}
              isDsrAdmin={isDsrAdmin}
            />

          </Flex>
        </Box>
        <DsrDynamicModal
          dsrId={dsrId}
          userActionRequired={'confirm-delete-faq-question'} 
          showDsrModal={showConfirmDeleteModal}
          questionId={questionId}
          handleDsrModalClose={handleCloseModalButton}
        />
        
      </React.Fragment>
  </Box>
  )
  
}

function DisplayButtonMode({
  isDsrAdmin,
  showUpdateErrorTag,
  setShowConfirmDeleteModal,
  handleCtaButtonClick,
  submittedInputIsSaving,
  handleEmptyInput,
  allowSave,
  buttonMode,
  buttonText
}) {
  
  return (
    <React.Fragment>
      {
        {
          true: <Box>Error Updating</Box>,
          false: null, // No need to display anything if it is successfully updated
          null: <React.Fragment>
            <Flex mt='0.5em' w='100%' justify={isDsrAdmin ? 'space-between' : 'flex-end'} align='flex-end'>
              {isDsrAdmin &&
                <Box mr='1em' color='gray.500' cursor='pointer' fontSize='0.75em'  onClick={() => setShowConfirmDeleteModal(true)}>
                  Delete this entire question & answer
                </Box>
              }
              <LaunchdeckButton bg='brand.500' color='white' fontSize='0.8em' loadingLogic={submittedInputIsSaving}  onClick={() => allowSave ? handleCtaButtonClick() : handleEmptyInput()} label={buttonText ? buttonText : 'Submit'} />
              </Flex>
          </React.Fragment>
        }[showUpdateErrorTag]
      }
    </React.Fragment>
  );
}


function mapStateToProps(state) {
  return {
    faqAnswerIsSaving: state.createFaqAnswerOutcome.isLoading,
    editedFaqAnswerIsSaving: state.editFaqAnswerOutcome.isLoading,
    deleteFaqAnswerOutcome: state.deleteFaqAnswerOutcome.results.data,

    editedFaqQuestionIsSaving: state.editFaqQuestionOutcome.isLoading,
    editFaqQuestionOutcome: state.editFaqQuestionOutcome.results.data,
  };
}


const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
      editFaqAnswer,
      resetDeleteFaqAnswer,
      editFaqQuestion,
      resetEditFaqQuestion
		},
		dispatch,
	)

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditQuestionAndAnswerModule));


