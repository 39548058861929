import React from 'react'
import './ToggleButton.scss'

export const ToggleButton = ({offLabelText, onLabelText, isChecked, setIsChecked}) => {
  const handleChange = () => {
    setIsChecked(prevState => !prevState);
  };

  return (
    <>
      <input
        type="checkbox"
        id="toggle"
        checked={isChecked}
        onChange={handleChange}
        className="toggleCheckbox"
      />
      <label htmlFor="toggle" className='toggleContainer'>
        <div>{offLabelText ? offLabelText : 'Off'}</div>
        <div>{onLabelText ? onLabelText : 'On'}</div>
      </label>
    </>
  )
}
