import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux'
import { useLocation, withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'

import { Box, Text, Grid, Flex, useToast, Divider } from "@chakra-ui/react";
import '../../global/styles/custom-animations.scss'

import { getUserProfile } from '../../profile/actions/users-profile-actions';
import { sendCustomerSupportMessage, resetSendCustomerSupportMessageOutcome } from '../../global/actions/customer-support-actions'
import InputTextAreaExpandable from '../../forms/TextareaField/components/InputTextAreaExpandable';
import WarningTextMessage from '../../global/components/helper-components/components/WarningTextMessage';
import LaunchdeckButton from '../../global/components/helper-components/components/LaunchdeckButton';
import FormTextInput from '../../forms/TextInputField/components/FormTextInput';
import { IoLogoSlack } from "react-icons/io5";
import { SiTelegram } from "react-icons/si";
import { MdOutlineMarkEmailUnread } from "react-icons/md";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { redirectToNewPage, triggerLaunchdeckToast } from '../../global/helpers/global-helpers';

function CustomerSupportContact({
	getUserProfile,
	sendCustomerSupportMessage,
	resetSendCustomerSupportMessageOutcome,
	// Redux
	isMobileView,
	sendCustomerSupportMessageOutcome,
	userProfile
}) {
	const initRef = useRef(false);
	const userEmailRef = useRef("");
	const toast = useToast()
	const location = useLocation();

	const {suggestedSupportMessage} = location.state || {};

	const [customerSupportMessage, setCustomerSupportMessage] = useState(suggestedSupportMessage ? suggestedSupportMessage : "")
	const [commsMethod, setCommsMethod] = useState("slack")
	const [providedContact, setProvidedContact] = useState("")

	const [emptyMessageError, setEmptyMessageError] = useState(false)
	const [emptyProvidedCommsError, setEmptyProvidedCommsError] = useState(false)
	const [showSupportRequestCreatedSuccess, setShowSupportRequestCreatedSuccess] = useState(false)



	// Fetch data
	useEffect(() => {
		if (getUserProfile && !initRef.current) {
			getUserProfile({
				getOrgProfile: true
			})
			initRef.current = true
		}
	}, [getUserProfile])

	useEffect(() => {
		if (userProfile && !userEmailRef.current) {
			const { userPersonalProfile } = userProfile || {}
			userEmailRef.current = userPersonalProfile.email
		}
	}, [userProfile])

	const onChangeHandler = (event) => {

		switch (event.target.name) {
			case ('customer-support-message'): {
				setEmptyMessageError(false)
				setCustomerSupportMessage(event.target.value)
				break;
			}
			case ('preferred-comms-method'): {
				setEmptyProvidedCommsError(false)
				setProvidedContact(event.target.value)
				// setProvidedContact(true)
				break;
			}
			default: {
				break;
			}
		}
	};

	const handleSubmitMessage = () => {
		if (customerSupportMessage?.trim().length > 0 && providedContact) {
			sendCustomerSupportMessage({
				customerSupportMessage,
				preferredCommsMethod: commsMethod,
				preferredProvidedContact: providedContact
			})
		} else {
			if (!customerSupportMessage) setEmptyMessageError(true)
			if (!providedContact) setEmptyProvidedCommsError(true)
		}
	}

	const handlePickCommsMethod = (value) => {
		setEmptyProvidedCommsError(false)
		setProvidedContact(value === "email" ? userEmailRef.current : "")
		setCommsMethod(value)
	}

	const renderInputRequired = () => {
		switch (commsMethod) {
			case ("slack"): {
				return (
					<>
						<Text fontWeight='semibold' className='fade-in'>Your Slack account email</Text>
						<Box my='0.5em'>
							<FormTextInput name={'preferred-comms-method'} mb='0.2em' value={providedContact} placeholder={`e.g. mySlackAccountEmail@company.com`} onChange={onChangeHandler} />
							<WarningTextMessage errorLogic={emptyProvidedCommsError} message='Please let us know how to contact you' />
						</Box>
					</>
				)
			}
			case ("telegram"): {
				return (
					<>
						<Text fontWeight='semibold' className='fade-in'>Your Telegram handle</Text>
						<Box my='0.5em'>
							<FormTextInput name={'preferred-comms-method'} mb='0.2em' value={providedContact} placeholder={`e.g. @MyTelegramHandle`} onChange={onChangeHandler} />
							<WarningTextMessage errorLogic={emptyProvidedCommsError} message='Please let us know how to contact you' />
						</Box>
					</>
				)
			}
			case ("email"): {
				return (
					<>
						<Text fontWeight='semibold' className='fade-in'>Your Email</Text>
						<Box my='0.5em' className='fade-in'>
							<Flex w='100%' p="9px 1rem" border='1px solid' borderColor='brandDark.500' bg='gray.100' rounded='0.25em'>
								<Box color='brandDark.500' fontSize='0.875em' fontWeight='500' lineHeight-='1.25em'>{providedContact}</Box>
							</Flex>
						</Box>
					</>
				)
			}
			default: {
				return null;
			}
		}
	}

	useEffect(() => {
		if (sendCustomerSupportMessageOutcome) {
			const { sendNotificationStatus, sourceEvent } = sendCustomerSupportMessageOutcome || {};

			switch (sourceEvent) {
				case ("send-customer-support-message"): {
					if (sendNotificationStatus) {
						setShowSupportRequestCreatedSuccess(true)
					} else if (!sendNotificationStatus) {
						toast(
							triggerLaunchdeckToast({
								useCase: "show-error-state",
								bannerText: "Something went wrong",
								label: "Unfortunately something went wrong. Please try again later.",
								isMobileView
							})
						)
					}

					resetSendCustomerSupportMessageOutcome()
					break;
				}
				default: {
					console.log(`There is no such sourceEvent: ${sourceEvent}`)
					break;
				}
			}
		}
	}, [sendCustomerSupportMessageOutcome, toast, resetSendCustomerSupportMessageOutcome]);


	const renderSuccessState =() => {
		return (
			<Flex className="flip-in-ver-left" mt='2em' justify='center' align='center' py='2.75em' border='1px solid #E5EAEF' rounded='0.5em' bg='white'>
          <Flex direction='column' justify='center' align='center'>

            <Box as={IoMdCheckmarkCircleOutline} color='brandDark.500' boxSize="3em" />

            <Box fontWeight='400' lineHeight='1.5em' color='gray.500' py='1.5em'>
              Successfully submitted!
            </Box>

						<Box whiteSpace='nowrap' >
              <Flex className='create-new-category-button'
                onClick={() => redirectToNewPage(`/home`)}>
                <Box className='create-new-category-button__button-text'>Back to homepage</Box>
                {/* <Box as={IoMdAddCircle} boxSize='1.5em' className='create-new-room-button__add-icon' /> */}
              </Flex>
            </Box>

          </Flex>
        </Flex>
		)
	}
	return (
		<Box bg='white' px='3%' pt='1.5em' pb='3em' minHeight='40em' rounded={8}>
			<	Text fontSize='1.5em' letterSpacing='1px' color='brand.500'>SUPPORT</Text>
			<Divider mb='2em' />

			{showSupportRequestCreatedSuccess === true ?
				renderSuccessState()
				:
				<React.Fragment>

					<Box mb='1em'>
						<Text fontWeight='semibold'>Your preferred communication method</Text>
						<Text fontSize='0.8em' color='gray.600' mb='0.8em'>Seek straight-forward solutions quickly from a human</Text>
					</Box>

					<Grid templateColumns={["repeat(2, 1fr)", "repeat(3, 1fr)"]} gap='0.5em' mt='1em'>
						<Flex justify='flex-start' align='center' border='1px solid' borderColor={commsMethod === "slack" ? "brand.500" : 'gray.300'} bg={commsMethod === "slack" ? "white" : 'gray.100'} boxShadow='xs' p='1em' rounded='0.25em' cursor="pointer" onClick={() => handlePickCommsMethod('slack')}>
							<Box as={IoLogoSlack} color={commsMethod === "slack" && 'brand.500'} />
							<Box ml='1em'>Slack</Box>
						</Flex>

						<Flex justify='flex-start' align='center' border='1px solid' borderColor={commsMethod === "telegram" ? "brand.500" : 'gray.300'} bg={commsMethod === "telegram" ? "white" : 'gray.100'} boxShadow='xs' p='1em' rounded='0.25em' cursor="pointer" onClick={() => handlePickCommsMethod('telegram')}>

							<Box as={SiTelegram} color={commsMethod === "telegram" && 'brand.500'} />
							<Box ml='1em'>Telegram</Box>
						</Flex>


						<Flex justify='flex-start' align='center' border='1px solid' borderColor={commsMethod === "email" ? "brand.500" : 'gray.300'} bg={commsMethod === "email" ? "white" : 'gray.100'} boxShadow='xs' p='1em' rounded='0.25em' cursor="pointer" onClick={() => handlePickCommsMethod('email')}>

							<Box as={MdOutlineMarkEmailUnread} color={commsMethod === "email" && 'brand.500'} />
							<Box ml='1em'>Email</Box>

						</Flex>
					</Grid>

					<Box mt='2em' mb='1em'>
						{renderInputRequired()}
					</Box>

					<Box mb='1em' mt='3em' >
						<Text fontWeight='semibold'>Talk to us</Text>
					</Box>

					<Box mt='1em' w='100%'>
						<InputTextAreaExpandable
							name={'customer-support-message'}
							onChangeHandler={onChangeHandler}
							inputValue={customerSupportMessage}
							placeholder={`Tell us what you're experiencing`}
							border={'1px solid #CBD5E0'}
						/>
					</Box>
					<WarningTextMessage errorLogic={emptyMessageError} message={`Please tell us what you are experiencing to help us provide better assistance quickly.`} />


					<Flex direction='column' mt='1em' justify='center' alignItems='center'>
						<Flex justify={['center', 'flex-end', 'flex-end', 'flex-end']} w='100%'>
							<LaunchdeckButton bg='brand.500' color='white' onClick={() => handleSubmitMessage()} label='Seek support' />
						</Flex>
					</Flex>
				</React.Fragment>
			}

		</Box>



	)

}


const mapStateToProps = (state) => ({
	userProfile: state.profile.results.data,
	sendCustomerSupportMessageOutcome: state.sendCustomerSupportMessageOutcome.results.data,
	isMobileView: state.mobileViewMode.isMobileView
})

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			getUserProfile,
			sendCustomerSupportMessage,
			resetSendCustomerSupportMessageOutcome
		},
		dispatch,
	)

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomerSupportContact))

