import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import {
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	MenuGroup,
	Text,
	Box,
	IconButton,
	Flex,
	Spinner,
	useToast
} from "@chakra-ui/react";
import { FaRegBell } from 'react-icons/fa6';
import { getUserNotifications, markAllUserNotificationsAsViewed, markUserNotificationAsViewed } from '../../../../profile/actions/user-notifications-actions';
import { determineNotificationMessage } from '../../../../profile/helpers/user-notification-helper';
import { formatDisplayLocalDateTime } from '../../../../global/helpers/time-format-helpers';
import { redirectToNewPage, triggerLaunchdeckToast } from '../../../../global/helpers/global-helpers';
import LaunchdeckTag from '../../../../global/components/helper-components/components/LaunchdeckTag';



function UserNotificationsModule({
	// Redux
	currentUserNotifications,
	userNotificationsArray,
	markAllNotificationsAsViewedOutcome,
	isMobileView,
	// Action
	getUserNotifications,
	markUserNotificationAsViewed,
	markAllUserNotificationsAsViewed
}) {
	
	const toast = useToast();

	const [hideViewedIndicator, setHideViewedIndicator] = useState(false)
	const [loadingState, setLoadingState] = useState(false)

	useEffect(() => {
		if (!currentUserNotifications) {
			getUserNotifications({
				requestCategory: "nav-header-notifications",
				pageNumber: 1,
				itemsPerPage: 5,
				sortRequest: "most-recent-first"
			})
		}

	}, [currentUserNotifications]);

	useEffect(() => {
		if (markAllNotificationsAsViewedOutcome) {
			const { successfullyProcessed } = markAllNotificationsAsViewedOutcome || {}

			if (successfullyProcessed) {
				setHideViewedIndicator(true)

				toast(
					triggerLaunchdeckToast({
						useCase: "show-success-state",
						label: "All notifications marked as viewed",
						isMobileView
					})
				)

				setLoadingState(false)

			} else {
				toast(
					triggerLaunchdeckToast({
						useCase: "show-error-state",
						label: "Unfortunately something went wrong. Please try again later.",
						isMobileView
					})
				)
			}
		}
	}, [markAllNotificationsAsViewedOutcome]);

	const handleNotificationClick = (notificationId, notificationRedirectLink, relatedNotificationIds) => {
		if (notificationRedirectLink) {
			const notificationsToMarkAsViewed = relatedNotificationIds ? [notificationId, ...relatedNotificationIds] : notificationId ? [notificationId] : ""
			notificationsToMarkAsViewed && markUserNotificationAsViewed([notificationsToMarkAsViewed])
			redirectToNewPage(notificationRedirectLink)
		}
	}

	const handleMarkAllAsViewed = () => {
		markAllUserNotificationsAsViewed()
		setHideViewedIndicator(true)
		setLoadingState(true)
	}


	const renderNotificationsShortList = () => {
		let displayArray = "";

		if (Array.isArray(userNotificationsArray)) {
			displayArray = userNotificationsArray.slice(0, 5);

		} else {
			return (
				<Box fontSize='0.875em' color='gray.700' p='0.25em 1em'>
					There was an unexpected error. Please try again later.
				</Box>
			)
		}

		const renderedList = displayArray?.length > 0 ? displayArray.map((notification, noteIndex) => {
			const { notificationId, notificationType, createdAt: notificationDate, viewed, notificationRedirectLink, relatedNotificationsCount, relatedNotificationIds } = notification || {}
			const displayedNotificationMessage = notificationType ? determineNotificationMessage(notification) : "";

			if (displayedNotificationMessage) {
				return (
					<MenuItem key={`header-notification--${notificationId}-${noteIndex}`} color='gray.700'
						py='0.5em'
						minW='20em'
						maxW='30em'
						cursor={notificationRedirectLink ? 'pointer' : "default"} onClick={() => handleNotificationClick(notificationId, notificationRedirectLink, relatedNotificationIds)}
					>
						<Box >
							<Text className={"header__profile-info-type"}>
								{displayedNotificationMessage}

							</Text>
							<Flex justify='flex-start' align='center'>
								{(viewed === false && !hideViewedIndicator) && <Box color='brandPink.500' fontSize='0.7em' mr='0.5em'>•</Box>}
								<Text fontSize='0.6em' color='gray.500' mt='0.2em'>
									{formatDisplayLocalDateTime(notificationDate)} 	{!!relatedNotificationsCount && `• ${relatedNotificationsCount} related notifications`}
								</Text>
							</Flex>

						</Box>
					</MenuItem>
				)
			} else return <Box key={`header-notification--${notificationId}-${noteIndex}`} />;

		}) : <Box fontSize='0.8em' px='0.5em' color='gray.700'>
			None right now. You are all caught up.
		</Box>


		return <>
			<Box p='0.5em' maxW='30em'>
				{renderedList}
			</Box>

			<Flex w='100%' justify='space-between' align='center' py='0.5em' pl='0.75em' >
				<LaunchdeckTag bg='gray.100' color='brandDark.500' label={'More notifications'} cursor='pointer' onClick={() => redirectToNewPage('/notifications')} />

				{loadingState ? <Spinner
					thickness="2px"
					speed="0.65s"
					emptyColor="gray.200"
					color="brand.500"
					boxSize="1.2em"
					mr='1em' /> 
					: hideViewedIndicator 
					? null 
					: displayArray?.length > 0 
					? <Box cursor='pointer' onClick={() => handleMarkAllAsViewed()} color='gray.500' fontSize='0.65em' mr='0.5em'>Mark all as read</Box>
					: null
				}
			</Flex>



		</>
	}

	return (
		<Menu ml='2em' placement="bottom-end">
			<MenuButton fontSize="0.9em" color='gray.600' >
				<Box position='relative'>
				<IconButton
					isRound={true}
					// variant='solid'
					colorScheme='white'
					color='brandDark.500'
					aria-label='Done'
					fontSize='0.875em'
					p='0.2em'
					icon={<FaRegBell />}
				/>
				{userNotificationsArray?.length > 0 && !hideViewedIndicator && 
				<Box position='absolute' bottom='0.1em' right='0.1em'>
					<Box rounded='50%' bg='brandPink.500' boxSize='0.4em'/>
				</Box>
}
					
				</Box>
			
			</MenuButton>
			<MenuList minW='8em' px='0.5em'>
				<MenuGroup title='Unread notifications' ml='0.5em' mr='4em' pl='0.5em' fontSize='0.875em' color='brandDark.500'>
					{renderNotificationsShortList()}
				</MenuGroup>

			</MenuList>
		</Menu>
	)
}


function mapStateToProps(state) {
	return {
		currentUserNotifications: state.currentUserNotifications.currentUserNotifications,
		userNotificationsArray: state.userNotificationsOutcome.results.data,
		markAllNotificationsAsViewedOutcome: state.markAllNotificationsAsViewedOutcome.results.data,
		isMobileView: state.mobileViewMode.isMobileView
	};
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			getUserNotifications,
			markUserNotificationAsViewed,
			markAllUserNotificationsAsViewed
		},
		dispatch,
	)

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserNotificationsModule));

