import React, { Fragment, useState, useEffect } from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import config from "../../../config/app-config";

import { Avatar, Box, Flex, Image } from '@chakra-ui/react'
import { formatDisplayLocalDateTime } from '../../global/helpers/time-format-helpers'
import PublicDsrMessageToolbarDecoy from './public-dsr/PublicDsrMessageToolbarDecoy'
import PublicDsrRepliesDisplay from './public-dsr/PublicDsrRepliesDisplay'
import PublicDsrCreatePostDecoy from './public-dsr/PublicDsrCreatePostDecoy'
// Images
import DsrStartIcon from '../styles/images/dsr-start-icon.png'
import { getUserDetailsUsingDsrMembersArray } from '../../profile/helpers/user-helper'
import { getUrlInPostContent } from "../helpers/dsr-display-helpers";
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { ReactTinyLink } from 'react-tiny-link'
import '../styles/dsr-display.scss'

// Redux
import {
  changeSelectSubpage,
} from '../actions/dsr-actions'
import { LoomVideoDisplay } from './LoomVideoDisplay';
import { Mention, MentionsInput } from "react-mentions";
import { mentionStyle } from "../../forms/TextareaField/components/InputTextAreaWithUserTagging";
import LaunchdeckButton from '../../global/components/helper-components/components/LaunchdeckButton'
import { openInNewTab } from '../../global/helpers/global-helpers'
import PageLoader from '../../global/components/helper-components/components/PageLoader'


function DisplayNewsFeedPublic(props) {

  const {
    dsrTitle,
    displayedPostContent,
    // loadingMoreContent,
    dsrTeamMembers,
    handleSelectSubpage,
    faqPageId,

    handleOpenSignupOrLoginModal
  } = props || {};

  // UX for image carousell
  const configs = {
    autoPlay: false,
    infiniteLoop: true,
    showThumbs: false,
    swipeable: true,
    showStatus: false,
    showIndicators: true,
    showArrows: true,
    dynamicHeight: false,
  };

  const [showLoader, setShowLoader] = useState(true);
  const [showDecoyFirstPost, setShowDecoyFirstPost] = useState(false)

  useEffect(() => {
    if (Array.isArray(displayedPostContent)) {
      setShowLoader(false)

      if (displayedPostContent?.length === 0) {
        setShowDecoyFirstPost(true)
      }
    }

  }, [displayedPostContent]);


  // Messages
  const displayedMessages = displayedPostContent && displayedPostContent.sort((a, b) => new Date(b.postCreatedAt) - new Date(a.postCreatedAt)).map((message, messageIndex) => {

    const { postType, postId, postStatus, authorUserId, postContent, postCreatedAt, postReactions, postUpdatedAt, postReplies, postDeletedAt, images, video, loomVideo } = message || {};
    const messageTimestamp = moment(postCreatedAt).format('lll')
    const isEdited = postUpdatedAt ? true : false;
    const authorProfile = getUserDetailsUsingDsrMembersArray(dsrTeamMembers, authorUserId);
    const { memberName, memberProfilePicSrc } = authorProfile || {};
    const replyCount = (postReplies && Array.isArray(postReplies)) ? postReplies.length : 0;
    const { corsProxy } = config;



    if (postStatus === 'deleted' && postDeletedAt) {
      return (<Box key={`message_${messageIndex}`} my='3em' color='gray.500'>
        <Flex align='flex-start' border='1px solid' borderColor='gray.300' rounded='0.5em' p='0.2em'>
          <Box p='0.5em' fontSize='0.8em'>Message deleted by {memberName} on {formatDisplayLocalDateTime(postDeletedAt)}</Box>
        </Flex>
      </Box>
      )
    }

    const renderPostTextOrMedia = (content) => {
      return (
        <Box
          key={`message_${messageIndex}`}
          w='100%'
          mt='1em' mb='2em' p='1em'
          bg='white'
          rounded='0.25em' border='1px solid rgba(203, 213, 224, 0.5)'
        >
          <Flex width='100%' justify='space-between'>
            <Box>
              <Flex justify='flex-start' align='center'>
                <Avatar h='4.2em' w='4.2em' name={memberName} bg='gray.100' color='gray.700' src={memberProfilePicSrc} />
                <Box pl='1em'>
                  <Flex justify='flex-start' fontSize='0.9em'>
                    <Box fontWeight='semibold'>{memberName}</Box>
                    <Box pl='0.4em'>posted {postType === "shared-url" && "a link"}</Box>
                  </Flex>
                  <Box fontSize='0.8em' color='gray.500'>{messageTimestamp} {isEdited ?
                    <Box as='span' fontSize='1em' color='gray.500' lineHeight='1em'> • Edited</Box> : null}</Box>
                </Box>

              </Flex>
            </Box>


          </Flex>

          <Box>
            <Flex align='flex-start' px='0.2em' py='1em'>
              <Flex className="white-space-preline" width="100%">
                {postType === "shared-url" ?
                  <Box width="100%">
                    <Box fontSize='0.875em'>
                      <MentionsInput
                        readOnly={true}
                        value={postContent}
                      >
                        <Mention
                          markup='@[__display__]userId-(__id__)'
                          style={mentionStyle}
                          displayTransform={(id, display) => { return `@${display}` }}
                        />
                      </MentionsInput>
                    </Box>

                    <Box mt='1em'>
                      <ReactTinyLink
                        cardSize="small"
                        showGraphic={true}
                        width="100%"
                        maxLine={2}
                        minLine={1}
                        url={getUrlInPostContent(postContent)}
                        proxyUrl={corsProxy}
                        onClick={(e) => { openInNewTab(getUrlInPostContent(postContent)) }}
                      />
                    </Box>
                  </Box>

                  :
                  <Box fontSize='0.875em'>
                    <MentionsInput
                      readOnly={true}
                      value={postContent}
                    >
                      <Mention
                        markup='@[__display__]userId-(__id__)'
                        style={mentionStyle}
                        displayTransform={(id, display) => { return `@${display}` }}
                      />
                    </MentionsInput>
                  </Box>
                  }
              </Flex>
            </Flex>

            {content && content()}


            <PublicDsrMessageToolbarDecoy
              postReactions={postReactions}
              replyCount={replyCount}
              messageIndex={messageIndex}
              handleOpenSignupOrLoginModal={handleOpenSignupOrLoginModal}
            />


          </Box>



          <Box key={`dsr_replies_${messageIndex}`}>
            <PublicDsrRepliesDisplay
              messageRepliesArray={postReplies}
              postId={postId}
              dsrTeamMembers={dsrTeamMembers}
            />
          </Box>

        </Box>
      )
    }

    const imagesContent = () => {
      const imageStyle = { maxHeight: '360px', objectFit: 'contain', background: '#F7FAFC', padding: "16px 0" }

      return (
        <Fragment>
          {images && images.length > 0 && <div className="post__images">
            {images.length === 1 ?
              images.map((item, index) => {
                if (item.url) {
                  if (item.url.endsWith(".jpg") || item.url.endsWith(".jpeg") || item.url.endsWith(".png") || item.url.endsWith(".gif")) {
                    // URL is for an image
                    return (
                      // eslint-disable-next-line jsx-a11y/img-redundant-alt,no-mixed-operators
                      <img key={item && item.key || item + index} src={item.url || item} style={imageStyle}
                        alt={`post-image-${index}`} />

                    )
                  } else {
                    // URL is not for a video or an image
                    // eslint-disable-next-line no-mixed-operators
                    return <div key={item && item.key || item + index} />
                  }
                } else {
                  return (
                    // eslint-disable-next-line jsx-a11y/img-redundant-alt,no-mixed-operators
                    <img key={item && item.key || item + index} src={item} style={imageStyle}
                      alt={`post-image-${index}`} />
                  )
                }


              }) : <Carousel
                {...configs}
              >
                {images.map((item, index) => {
                  return (
                    // <div style={{ margin: '2px' }}>
                    // eslint-disable-next-line jsx-a11y/img-redundant-alt
                    <img key={item + index}
                      style={imageStyle}
                      className="post__image" src={item.url || item} alt={`post-image-${index}`} />
                    // </div>
                  )
                })}
              </Carousel>
            }

          </div>}
        </Fragment>
      )
    };

    const videoContent = () => {
      return (
        <Box w='100%' my='2em' py='1em' bg='gray.100' rounded='0.25em'>
          {video && video.length > 0 &&
            <div className="post__images">
              {(video[0].url.endsWith(".mp4") || video[0].url.endsWith(".ogg") || video[0].url.endsWith(".webm"))
                &&
                <video src={video[0].url} controls style={{ width: "auto", height: "25em", objectFit: "contain" }} />
              }

            </div>
          }
        </Box>
      )
    };

    const loomVideoContent = () => {
      if (!loomVideo?.sharedUrl) return null

      return <LoomVideoDisplay loomVideo={loomVideo} />
    }

    switch (postType) {
      case ("images"): {
        return (
          <div key={`dsr_replies_${messageIndex}`}>{renderPostTextOrMedia(imagesContent)}</div>
        );
      }
      case ("uploaded-video"): {
        return (
          <div key={`dsr_replies_${messageIndex}`}>{renderPostTextOrMedia(videoContent)}</div>
        );
      }
      case ("short-text"): {
        return (
          <div key={`dsr_replies_${messageIndex}`}>{renderPostTextOrMedia()}</div>
        );
      }
      case ("shared-url"): {
        return (
          <div key={`dsr_replies_${messageIndex}`}>{renderPostTextOrMedia()}</div>
        );
      }
      case ("loom-video-post"): {
        return (
          <div key={`dsr_replies_${messageIndex}`}>{renderPostTextOrMedia(loomVideoContent)}</div>
        );
      }
      case ("dsr-created"): {
        return (
          <Flex flexDirection={['column', 'column', 'row']} key={`message_${messageIndex}`} fontSize='0.9em' p='1em' w='100%' bg='white' rounded='0.25em' border='1px solid rgba(203, 213, 224, 0.5)' align='center' >
            <Image src={DsrStartIcon} />
            <Box pl='1em' fontWeight='semibold'> {memberName}</Box>
            <Box pl='0.3em'> created </Box>
            <Box textAlign='center' pl='0.5em' color='brand.500' fontWeight='semibold'> {dsrTitle}</Box>
            <Box pl='0.5em' fontWeight='400' lineHeight='1.25em' fontSize='0.75em' color='gray.500'> • {moment(postCreatedAt).format('lll')}</Box>
          </Flex>

        )
      }
      case ("team-changes"): {
        const { changeType, membersChanged, postId } = message || {};

        const displayActionText = (changeType === 'add-member') ? 'added new members to the team' : (changeType === 'remove-member') ? 'removed members from the team' : (changeType === 're-add-member') ? 'added members back to the team' : "posted"

        const memberCards = membersChanged?.map((member, memberIndex) => {
          const { userId } = member || {};
          const addedMemberProfile = getUserDetailsUsingDsrMembersArray(dsrTeamMembers, userId);

          const { email, memberName, memberProfilePicSrc, memberOrgTitle, memberOrgName } = addedMemberProfile || {}
          const supplementaryUserInfo = (memberOrgTitle && memberOrgName) ? `${memberOrgTitle}, ${memberOrgName}` : memberOrgName ? memberOrgName : email;
          return (
            <React.Fragment key={`team_changes_${changeType}_${postId}${memberIndex}`}>
              <Flex justify='flex-start' align='center' mt='1em'>
                <Avatar h='4.2em' w='4.2em' name={memberName} bg='gray.100' color='gray.700' src={memberProfilePicSrc} />
                <Box pl='1em'>
                  <Box fontWeight='400' fontSize='0.875em' lineHeight='1.25em' color='brandDark.500'>{memberName ? memberName : email}</Box>
                  <Box fontWeight='500' fontSize='0.75em' lineHeight='1em' color='gray.500'>{supplementaryUserInfo}</Box>
                </Box>
              </Flex>
            </React.Fragment>
          )
        })

        return (
          <Box key={`message_${messageIndex}`} p='1em' mb='2em' w='100%' bg='white' rounded='0.25em' border='1px solid rgba(203, 213, 224, 0.5)' >
            <Flex align='center'>
              <Image src={DsrStartIcon} />
              <Box pl='1em'>
                <Box fontSize='0.9em'>
                  <span style={{ fontWeight: 800 }}
                  >{memberName}</span>{" "}
                  <span>{displayActionText}</span>
                </Box>

                <Box fontSize='0.8em' color='gray.500'>{moment(postCreatedAt).format('lll')}</Box>
              </Box>
            </Flex>
            <Box>
              {memberCards}
            </Box>
          </Box>
        )
      }
      case ("faq-question-post"): {
        const { questionTextContent } = message || {};

        return (
          <Box
            key={`message_${messageIndex}`}
            w='100%'
            mt='1em' mb='2em' p='1em'
            bg='white'
            rounded='0.25em' border='1px solid rgba(203, 213, 224, 0.5)'
          >
            <Flex width='100%' justify='space-between'>
              <Box>
                <Flex justify='flex-start' align='center'>
                  <Avatar h='4.2em' w='4.2em' name={memberName} bg='gray.100' color='gray.700' src={memberProfilePicSrc} />
                  <Box pl='1em'>
                    <Flex justify='flex-start' fontSize='0.9em'>
                      <Box fontWeight='semibold'>{memberName}</Box>
                      <Box pl='0.4em'>posted a question</Box>
                    </Flex>
                    <Box fontSize='0.8em' color='gray.500'>{messageTimestamp}  {isEdited ? <Box as='span' fontSize='1em' color='gray.500' lineHeight='1em' >  •  Edited</Box> : null}</Box>
                  </Box>
                </Flex>
              </Box>
            </Flex>

            <Box>
              <Flex align='flex-start' px='0.2em' py='1em'>
                <Box w='100%' bg='gray.100' rounded='0.25em' p='0.5em'>
                  <Flex className="white-space-preline">
                    <Box fontSize='0.875em' lineHeight='1.5em'>{questionTextContent}</Box>
                  </Flex>
                </Box>
              </Flex>

              <Box fontSize='0.8em' color='brand.500' mt='1em' cursor='pointer' px='0.5em'
                onClick={() => handleSelectSubpage(faqPageId, "")}
              >See all Q&A questions</Box>

              <PublicDsrMessageToolbarDecoy
                postReactions={postReactions}
                messageIndex={messageIndex}
                handleOpenSignupOrLoginModal={handleOpenSignupOrLoginModal}
              />

            </Box>


            <Box key={`dsr_replies_${messageIndex}`}>
              <PublicDsrRepliesDisplay
                messageRepliesArray={postReplies}
                postId={postId}
                dsrTeamMembers={dsrTeamMembers}
              />
            </Box>

          </Box>
        );
      }
      case ("faq-answer-post"): {
        const { questionTextContent, answerTextContent } = message || {};

        return (
          <Box
            key={`message_${messageIndex}`}
            w='100%'
            mt='1em' mb='2em' p='1em'
            bg='white'
            rounded='0.25em' border='1px solid rgba(203, 213, 224, 0.5)'
          >
            <Flex width='100%' justify='space-between'>
              <Box>
                <Flex justify='flex-start' align='center'>
                  <Avatar h='4.2em' w='4.2em' name={memberName} bg='gray.100' color='gray.700' src={memberProfilePicSrc} />
                  <Box pl='1em'>
                    <Flex justify='flex-start' fontSize='0.9em'>
                      <Box fontWeight='semibold'>{memberName}</Box>
                      <Box pl='0.4em'>answered a question</Box>
                    </Flex>
                    <Box fontSize='0.8em' color='gray.500'>{messageTimestamp}  {isEdited ? <Box as='span' fontSize='1em' color='gray.500' lineHeight='1em' >  •  Edited</Box> : null}</Box>
                  </Box>
                </Flex>
              </Box>

            </Flex>

            <Box>
              <Flex direction='column' align='flex-start' px='0.2em' py='1em'>
                <Box w='100%' rounded='0.25em' p='0.5em'>
                  <Flex className="white-space-preline">
                    <Box fontSize='0.875em' lineHeight='1.5em'>{questionTextContent}</Box>
                  </Flex>
                </Box>
                <Box w='100%' bg='gray.100' rounded='0.25em' p='0.5em' mt='1.2em'>
                  <Flex className="white-space-preline">
                    <Box fontSize='0.875em' lineHeight='1.5em'>{answerTextContent}</Box>
                  </Flex>
                </Box>

              </Flex>


              <Box fontSize='0.8em' color='brand.500' mt='1em' cursor='pointer' px='0.5em'
                onClick={() => handleSelectSubpage(faqPageId, "")}
              >See all Q&A questions</Box>


              <PublicDsrMessageToolbarDecoy
                postReactions={postReactions}
                messageIndex={messageIndex}
                handleOpenSignupOrLoginModal={handleOpenSignupOrLoginModal}
              />
            </Box>

            <Box key={`dsr_replies_${messageIndex}`}>
              <PublicDsrRepliesDisplay
                messageRepliesArray={postReplies}
                postId={postId}
                dsrTeamMembers={dsrTeamMembers}
              />
            </Box>

          </Box>
        );
      }
      default: {
        console.log('There are post types that cannot be rendered:', postType)
        return null
      }
    }
  }
  );

  const displayDecoyFirstPost = () => {
    return (
      <Flex flexDirection={['column', 'column', 'row']} fontSize='0.9em' p='1em' w='100%' bg='white' rounded='0.25em' border='1px solid rgba(203, 213, 224, 0.5)' align='center' >
        <Image src={DsrStartIcon} />
        <Box pl='1em' fontWeight='semibold'> XXX</Box>
        <Box pl='0.3em'> created </Box>
        <Box textAlign='center' pl='0.5em' color='brand.500' fontWeight='semibold'> {dsrTitle}</Box>
        <Box pl='0.5em' fontWeight='400' lineHeight='1.25em' fontSize='0.75em' color='gray.500'> • {moment(new Date()).format('lll')}</Box>
      </Flex>
    )
  }

  return (
    <Box>
      <Flex direction='column' w='100%' pt='1em'>
        <PublicDsrCreatePostDecoy handleOpenSignupOrLoginModal={handleOpenSignupOrLoginModal} />

        <Box >
          {showLoader ? <PageLoader /> : showDecoyFirstPost ? displayDecoyFirstPost() : (displayedMessages)}

        </Box>
        <Box display="flex" justifyContent="center" mt="1.5em" >
          <LaunchdeckButton bg='brand.500' color='white'
            onClick={() => handleOpenSignupOrLoginModal()}
            label='More posts' />
        </Box>
      </Flex>
    </Box>
  )

}



function mapDispatchToProps(dispatch) {
  return {
    action: bindActionCreators(
      {
        changeSelectSubpage
      },
      dispatch
    )
  };
}

export default withRouter(connect(null, mapDispatchToProps)(DisplayNewsFeedPublic));
