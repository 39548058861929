/*
About Builds and Deploy Environments:
https://github.com/facebook/create-react-app/issues/4071
*/

const appConfig = {
	baseUrl: process.env.REACT_APP_BASE_URL,
	displayUrl: process.env.REACT_APP_DISPLAY_URL,
	saaRoute: process.env.REACT_APP_SAA_ROUTE,
	stripeApiKey: process.env.REACT_APP_STRIPE_API_KEY,
	craBuildEnv: process.env.NODE_ENV, // development, test or production
	distEnv: process.env.REACT_APP_DIST_ENV, // development, test, stagin, production etc.
  corsProxy: process.env.REACT_APP_CORS_PROXY,
	mobileWidthBreakpoint: 420,
	tabletWidthBreakpoint: 770,
	maxVideoFileSize: 250 * 1024 * 1024, // 250 MB
	minVideoFileSizeForMultithreadedUpload: 5 * 1024 * 1024, // 5 MB
	maxImageFileSize: 20 * 1024 * 1024, // 20 MB max per image
	maxPdfFileSize: 250 * 1024 * 1024, // 250 MB max for 1 PDF file
	displayMaxFileSize: 200 * 1024 * 1024, // 200 MB
	minPdfFileSizeForMultithreadedUpload: 5 * 1024 * 1024, // 5 MB
	maxImageFileUploadLimit: 5, // max 5 images in a single upload
}

export default appConfig
