import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { fetchVaultData, resetFetchVaultData } from '../vault/actions/vault-actions';
import { bindActionCreators } from 'redux'
import VaultPage from './VaultPage'


const VaultPageContainer = (props) => {
  return (
    <VaultPage {...props} />
  )
}

const mapStateToProps = (state) => ({
  allUserDsrs: state.allUserDsrs.results.data,
  vaultIsProcessing: state.allUserDsrs.isLoading
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchVaultData,
      resetFetchVaultData
    },
    dispatch,
  )

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VaultPageContainer))
