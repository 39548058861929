import React, { useEffect, useState, useRef } from 'react';
import { Redirect } from 'react-router-dom';
import moment from 'moment'

import { Flex, Spinner, Box, Divider, Image, Tabs, TabList, TabPanels, Tab, TabPanel, useToast, PopoverContent, Popover, PopoverTrigger, PopoverHeader, PopoverCloseButton, PopoverBody, PopoverFooter, Icon, Avatar } from '@chakra-ui/react';
import { RiVerifiedBadgeFill } from "react-icons/ri";
import '../styles/user-profile.scss'
import ClaverModal from '../../global/components/helper-components/components/ClaverModal';
import UserEditPersonalInfoForm from './UserEditPersonalInfoForm';
import UserChangePasswordModule from './change-password/UserChangePasswordModule';
import DeleteUserAccountModule from './user-settings/DeleteUserAccountModule';
import OrgManagementModule from './manage-organization/OrgManagementModule';
import UserProfileImageDisplay from '../../global/components/helper-components/components/UserProfileImageDisplay';
import LaunchDeckTooltip from '../../global/components/helper-components/components/LaunchDeckTooltip';
import { getFullName } from '../helpers/user-helper';
import OrgUpCtaModule from './OrgUpCtaModule';
import UserProfileMobileNavMenu from './UserProfileMobileNavMenu';
import LaunchdeckButton from '../../global/components/helper-components/components/LaunchdeckButton';
import { triggerLaunchdeckToast } from '../../global/helpers/global-helpers';
import OrgBillingModule from './manage-organization/OrgBillingModule';
import LaunchdeckInteractiveButton from '../../global/components/helper-components/components/LaunchdeckInteractiveButton';
import DeleteOrganizationAccountModule from './user-settings/DeleteOrganizationAccountModule';
import LoggedOutConfirmationModal from '../../digital-sales-room/dsr-general-mgmt/LoggedOutConfirmationModal';
import { MdOutlineTipsAndUpdates } from 'react-icons/md';

export default function UserProfilePage({
	userProfile,
	userProfilePicture,
	resendEmailVerification,
	getUserProfile,
	getAdminOrgProfile,
	adminOrgProfile,
	manageAdminOrgUsersOutcome,
	editProfileOutcome,
	isMobileView
}) {

	const [localState, setLocalState] = useState({
		pageIsLoading: true,
		isSetupMode: false,
		firstName: "",
		lastName: "",
		fullName: "",
		email: "",
		meetingLink: "",
		phoneCountryCode: "",
		phoneAreaCode: "",
		phoneNumber: "",
		profilePicture: "",
		orgLogo: "",
		isVerified: "",
		createdAt: "",
		profileIsEmpty: "",
		isAdministrator: null,
		isNoOrgUser: null,
		userOrgTitle: "",
		// UX
		sectionIndex: 0,
		// Profile Image management
		uploadedPicture: ""
	})
	// UX handling
	const [showEditProfileModal, setShowEditProfileModal] = useState(false)
	const [hideVerifyEmailPopover, setHideVerifyEmailPopover] = useState(false)

	const initRef = useRef(false);
	const toast = useToast();

	// Fetch data
	useEffect(() => {
		if (getUserProfile && !initRef.current) {
			getUserProfile({
				getOrgProfile: true
			})
			initRef.current = true
		}
	}, [getUserProfile])

	// Initialize obtained info
	useEffect(() => {
		const initializeState = (userProfile) => {
			const { userPersonalProfile, userOrgProfile, isNoOrgUser } = userProfile || {};
			
			const { email, firstName, lastName, meetingLink, contactPhoneNumber, phoneCountryCode, phoneAreaCode, phoneNumber, profilePicture, isVerified, createdAt } = userPersonalProfile || {};
			const { orgName, isAdministrator, userOrgTitle, orgLogo } = userOrgProfile || {};
			const fullName = getFullName(userPersonalProfile);

			// const { referral } = userMetaData || {};
			// const referralCode = referral && referral.personalReferralCode;
			const profileIsEmpty = firstName ? true : false;

			// Manage Query
			const queryString = window.location.search;
			const urlParams = new URLSearchParams(queryString);
			const sectionIndex = parseInt(urlParams.get('section'));

			setLocalState(prevState => ({
				...prevState,
				firstName,
				lastName,
				fullName,
				email,
				meetingLink,
				phoneCountryCode,
				phoneAreaCode,
				phoneNumber,
				profilePicture,
				pageIsLoading: false,
				// referralCode,
				isVerified,
				createdAt,
				profileIsEmpty,
				isAdministrator,
				isNoOrgUser,
				userOrgTitle,
				contactPhoneNumber,
				// imageIsLoading: profilePicture ? true : false,
				orgName,
				orgLogo,
				// logoIsLoading: orgLogo ? true : false,
				...sectionIndex && { sectionIndex }
			}));
		}

		if (userProfile) {
			initializeState(userProfile)
		}
	}, [userProfile])


	useEffect(() => {

		if (editProfileOutcome) {
			const updatedProfile = editProfileOutcome.users[0];
			const { email, firstName, lastName, meetingLink, phoneCountryCode, phoneAreaCode, phoneNumber, profilePicture, isVerified } = updatedProfile || {};

			setLocalState(prevState => ({
				...prevState,
				firstName,
				lastName,
				fullName: firstName + ' ' + lastName,
				email,
				meetingLink,
				phoneCountryCode,
				phoneAreaCode,
				phoneNumber,
				profilePicture,
				isVerified
			}));


			toast(
				triggerLaunchdeckToast({
					useCase: "show-success-state",
					label: "Profile updated successfully",
					isMobileView
				})
			)
		}
	}, [editProfileOutcome, toast, isMobileView])


	const resendVerificationEmail = () => {
		resendEmailVerification()
		setHideVerifyEmailPopover(true)
	}

	const preloadImages = () => {
		const { profilePicture, orgLogo } = localState;
		return (
			<Box className="image-loading__in-progress">
				{profilePicture && <Image src={profilePicture} />}
				{orgLogo && <Image src={orgLogo} />}
			</Box>
		)
	}

	const handleShowModal = () => {
		setShowEditProfileModal(true)
	}

	const handleCloseModal = () => {
		setShowEditProfileModal(false)
	}

	const { isAdministrator, firstName, lastName, pageIsLoading, profileIsEmpty, sectionIndex, isNoOrgUser, orgName } = localState;

	const dataIsLoading = (!!pageIsLoading) ? true : false;


	if (dataIsLoading === true) {
		return (
			<Flex w='100%' h='70rem' alignItems='center' justify='center' height='50%' my='3%'>
				<Spinner
					thickness="5px"
					speed="0.65s"
					emptyColor="gray.200"
					color="brand.500"
					boxSize="5em"
				/>
				{preloadImages()}
			</Flex>
		)
	}

	if (dataIsLoading === false) {

		return (
			<Flex direction='column' align='flex-start' pb='3em' minHeight='40em' bg='white' rounded='0.25em' px={isMobileView && '1em'} >
				{isMobileView ?
					<UserProfileMobileNavMenu
						isAdministrator={isAdministrator}
						sectionIndex={sectionIndex}
					/>
					:


					<Box w='100%'>
						<Box className="page-title" pt='1.1em' pb='0.5em' px='1em'>My Account</Box>
						<Divider borderColor='gray.300' opacity='50%' />
					</Box>
				}


				<Flex direction={['column', 'column', 'column', 'row']} justify='space-between' flexWrap='wrap' width='100%'>
					<Box mt={'2em'} w='100%' >
						<Tabs display={isMobileView ? '' : 'flex'} variant="unstyled" orientation={'vertical'} w='100%' defaultIndex={sectionIndex}>
							<TabList bg='white.50' rounded='0.5em' flexWrap='wrap' justify='flex-start' visibility={isMobileView ? 'hidden' : ''} h={isMobileView ? '1px' : ''}>
								<Tab _selected={{ bg: 'gray.50', color: "brand.500", fontWeight: '600', borderRight: '1px solid', borderColor: 'brand.500' }} fontSize='1em'><Box py='0.8em' textAlign='left' w='100%' minW='12em' maxW='17.5em' px='1em'>Profile</Box></Tab>
								{isAdministrator && <Tab _selected={{ bg: 'gray.50', color: "brand.500", fontWeight: '600', borderRight: '1px solid', borderColor: 'brand.500' }} fontSize='1em'><Box py='0.8em' textAlign='left' w='100%' minW='12em' maxW='17.5em' px='1em'>Organization</Box></Tab>}
								{isAdministrator && <Tab _selected={{ bg: 'gray.50', color: "brand.500", fontWeight: '600', borderRight: '1px solid', borderColor: 'brand.500' }} fontSize='1em'><Box py='0.8em' textAlign='left' w='100%' minW='12em' maxW='17.5em' px='1em'>Billing</Box></Tab>}
								{/* <Tab _selected={{ bg:'gray.50', color: "brand.500", fontWeight:'600', borderRight:'1px solid', borderColor:'brand.500'}} fontSize='1em'><Box py='0.8em' textAlign='left' w='100%' minW='12em' maxW='17.5em' px='1em'>Email Settings</Box></Tab> */}
								<Tab _selected={{ bg: 'gray.50', color: "brand.500", fontWeight: '600', borderRight: '1px solid', borderColor: 'brand.500' }} fontSize='1em'><Box py='0.8em' textAlign='left' w='100%' minW='12em' maxW='17.5em' px='1em'>Settings</Box></Tab>

							</TabList>

							{!isMobileView && <Divider orientation="vertical" borderColor='gray.300' opacity='50%' mx='0' />}

							<TabPanels mx={['0', '2em', '2em', '2em']} w='100%' minH={isMobileView ? '' : '80vh'} px={['0em', '2em', '2em', '2em']}>
								<TabPanel>
									<PersonalInformationSection
										userProfilePicture={userProfilePicture}
										stateData={localState}
										resendVerificationEmail={resendVerificationEmail}
										handleShowModal={handleShowModal}
										handleCloseModal={handleCloseModal}
										showEditProfileModal={showEditProfileModal}
										isMobileView={isMobileView}
										hideVerifyEmailPopover={hideVerifyEmailPopover}
									/>
									<Box>
										{isNoOrgUser === true ?
											<OrgUpCtaModule /> :
											<OrganizationMemberInformationSection
												stateData={localState}
												isMobileView={isMobileView}
											/>
										}
									</Box>
								</TabPanel>

								{isAdministrator &&
									<TabPanel>
										<Flex direction='column' w='100%'>
											<OrgManagementModule
												getAdminOrgProfile={getAdminOrgProfile}
												adminOrgProfile={adminOrgProfile}
												manageAdminOrgUsersOutcome={manageAdminOrgUsersOutcome}
											/>
										</Flex>
									</TabPanel>
								}

								{isAdministrator &&
									<TabPanel>
										<OrgBillingModule
											adminOrgProfile={adminOrgProfile}
											getAdminOrgProfile={getAdminOrgProfile}
											manageAdminOrgUsersOutcome={manageAdminOrgUsersOutcome}
										/>
									</TabPanel>
								}
								{/* <TabPanel>
										<Flex direction='column' w='100%'>
											<Box className="user-profile-section__first-label"> Email Settings </Box>
											<Box color='gray.500' maxWidth='500px'>Choose events you would like to be notified for. </Box>
											<UserEmailSettings />
										</Flex>
									</TabPanel> */}

								<TabPanel>
									<Flex direction='column' w='100%'>
										<UserChangePasswordModule />
										{isNoOrgUser ?
											<React.Fragment>

												<Divider borderColor='gray.300' opacity='50%' pt='5em' />
												<DeleteUserAccountModule firstName={firstName} lastName={lastName} />

											</React.Fragment>
											: isAdministrator &&
											<Box mt='8em'>
												<DeleteOrganizationAccountModule orgName={orgName} />
											</Box>
										}
									</Flex>
								</TabPanel>

							</TabPanels>
						</Tabs>

					</Box>
				</Flex>
				<LoggedOutConfirmationModal />
			</Flex>
		);
	}
	else if (profileIsEmpty === true) {
		return (
			<Redirect to="/setup-profile" />
		)
	}
	else {
		console.log('There is no profile state to load')
		return null;
	}
}


function PersonalInformationSection({ stateData, userProfilePicture, resendVerificationEmail, handleShowModal, handleCloseModal, showEditProfileModal, isMobileView, hideVerifyEmailPopover }) {
	const { fullName, firstName, lastName, email, meetingLink, contactPhoneNumber, isVerified, profilePicture, createdAt } = stateData;
	const userProfile = { firstName, lastName, email, profilePicture };

	return (
		<Box>
			<Flex w='100%' justify='space-between' align='flex-start'>
				<Flex justify='flex-start' align='center' mb='2.5em'>
					<Box position='relative'>
						<UserProfileImageDisplay name={fullName} size='5em' profilePicture={userProfilePicture} />
					</Box>

					<Box ml='1.6em'>
						<Box fontSize='20px' lineHeight='30px' fontWeight='semibold'>{fullName && fullName}</Box>
						<Box color='gray.500' fontSize='0.8em'>Joined since {moment(createdAt).format('MMM YYYY')}</Box>
					</Box>

				</Flex>

				{!isMobileView && <Box color='brand.500' fontWeight='500' cursor='pointer' onClick={handleShowModal}>Edit</Box>}
			</Flex>


			<Box mb='5em'>

				<Flex w='100%' justify='space-between' align='center' >
					<Box className="user-profile-section__label"> Personal </Box>

					{isMobileView &&
						<Flex direction='column' justify='center' alignItems='center'>
							<Flex justify={['center', 'flex-end', 'flex-end', 'flex-end']} w='100%'>
								<LaunchdeckButton bg='brand.500' color='white' onClick={handleShowModal} label='Edit' />
							</Flex>
						</Flex>
					}
				</Flex>

				<Flex mt='1.5em' justify='space-apart' align='flex-start' direction={['column', 'row', 'row', 'row']}>
					<Box flex='1 1 0'>
						<Flex justify='flex-start' align='center'>
							<Box className="user-profile-section__sublabel">Email Address</Box>
							{isVerified ? <Box as='span'><Box as={RiVerifiedBadgeFill} boxSize='1em' color='brand.500' ml='0.5em' /></Box>
								: <Box as='span' ml='1em'>
									<Flex justify='flex-start' align='center'>
										<LaunchdeckInteractiveButton label='Verify email' endLabel='Verification email sent' onClick={resendVerificationEmail} />
										<Box ml='0.5em'>

											{!hideVerifyEmailPopover &&
												<Popover placement={isMobileView ? 'top' : 'right'}>
													<PopoverTrigger>
														<Icon cursor='pointer' mt={isMobileView && '0.5em'} />
													</PopoverTrigger>
													<PopoverContent w={isMobileView ? '20em' : '25em'} color='brandDark.500' bg='white' borderColor='brand.500' boxShadow='lg'>

														<PopoverHeader pt={4} fontWeight='bold' border='0' px='1em'>
															Why verify email?
														</PopoverHeader>
														<PopoverCloseButton top='1em' right='1em' fontSize='0.75em' />

														<PopoverBody fontSize='0.875em' my='0.875em' px='1em'>
															<Flex w='100%' px='2em' justify='center'>
																<Flex w='100%' my='1em' justify='flex-start' align='center' bg='gray.50' border='1px solid' borderColor='gray.300' rounded='0.25em' p='0.75em 2em'>
																	<Avatar h='3.8em' w='3.8em' bg='gray.200' color='gray.700' src={""} />

																	<Box ml='1em'>
																		<Flex align='center' gap={4} mb='0.2em'>
																			<Box height='0.8em' w='8em' rounded='0.5em' bg='gray.500' />
																			<Box as='span'>
																				<Box as={RiVerifiedBadgeFill} boxSize='1.6em' color='brand.500' />
																			</Box>
																		</Flex>
																		<Box height='0.8em' w='12em' rounded='0.5em' bg='gray.300' />
																	</Box>
																</Flex>
															</Flex>

															<Box mt='1.5em'>
																Get a blue tick next and let your prospects trust they are dealing with a real person
															</Box>

														</PopoverBody>
														<PopoverFooter
															border='0'
															display='flex'
															alignItems='center'
															justifyContent='space-between'
															pb={4}
														>

															<Flex pl='0.5em' fontSize='0.7em' fontWeight='600' color='brandPink.500' gap={2} justify='center' align='center'>
																<MdOutlineTipsAndUpdates />
																Launchdeck Tips</Flex>

														</PopoverFooter>
													</PopoverContent>
												</Popover>
											}
										</Box>
									</Flex>
								</Box>
							}
						</Flex>
						<Box color='brandDark.500' fontSize='1em' lineHeight='22px' mt='0.5em'>{email}</Box>
					</Box>
				</Flex>

				<Flex mt='1.5em' justify='space-apart' align='flex-start' direction={['column', 'row', 'row', 'row']}>
					<Box flex='1 1 0'>
						<Flex justify='flex-start' align='center'>
							<Box className="user-profile-section__sublabel">Book-a-meeting link</Box>
						</Flex>
						<Box color='brandDark.500' fontSize='1em' lineHeight='22px' mt='0.5em'>{meetingLink ? meetingLink : "-"}</Box>
					</Box>
				
				</Flex>
				<Flex mt='1.5em' justify='space-apart' align='flex-start' direction={['column', 'row', 'row', 'row']}>
	<Box flex='1 1 0' mt={isMobileView && '1.5em'}>
						<Flex justify='flex-start' align='center'>
							<Box className="user-profile-section__sublabel">Contact Number</Box>
						</Flex>

							<Box color='brandDark.500' fontSize='1em' lineHeight='22px' mt='0.5em'>{contactPhoneNumber ? contactPhoneNumber : "-"}</Box>
					</Box>

				
					</Flex>
			</Box>

			<ClaverModal
				title='Edit Personal Info'
				handleCloseModal={handleCloseModal}
				showModal={showEditProfileModal}
			>
				<UserEditPersonalInfoForm userProfile={userProfile} handleCloseModal={handleCloseModal} />
			</ClaverModal>
		</Box>
	)
}

function OrganizationMemberInformationSection({ stateData, isMobileView }) {
	const { orgName, userOrgTitle } = stateData;

	return (
		<Box mt='2em'>
			<Divider borderColor='gray.300' opacity='50%' pt='1.8em' />
			<Flex w='100%' mt='1.5em' justify='flex-start' align={['', 'center', 'center', 'center']} direction={['column', 'row', 'row', 'row']}>
				<Box className="user-profile-section__label"> Organization </Box>
				{isMobileView ? <Box fontSize='0.8em' color='gray.500'>Managed by your organization's Admininstrators</Box>
					:
					<Box ml='1em'>
						<LaunchDeckTooltip hoverMessage={`This section is managed by your organization's Admininstrator(s)`} />
					</Box>
				}
			</Flex>
			<Flex mt='1.5em' justify='space-apart' align='flex-start' direction={['column', 'row', 'row', 'row']}>
				<Box flex='1 1 0'>
					<Flex justify='flex-start' align='center'>
						<Box className="user-profile-section__sublabel">Company name</Box>
					</Flex>
					<Box color='brandDark.500' fontSize='1em' lineHeight='22px' mt='0.5em'>{orgName}</Box>
				</Box>
			</Flex>
			<Box pt='2em'>
				<Box className="user-profile-section__sublabel">Title</Box>
				<Box color={'brandDark.500'} fontSize='1em' lineHeight='22px' mt='0.5em'>{userOrgTitle ? userOrgTitle : '-'}</Box>
			</Box>
		</Box>
	)
}



