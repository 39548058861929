import { tableAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tableAnatomy.keys)

const baseStyle = definePartsStyle({
  // define the part you're going to style
  td: {
    fontSize:"0.875em",
     padding: "0.5em 0",
    // fontFamily: 'mono', // change the font family
    color: 'gray.600', // change the td text color
  },
})

export const tableTheme = defineMultiStyleConfig({ baseStyle })