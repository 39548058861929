import auth from '../../../config/app-specific-config/auth';
import * as types from './app-admin-action-types';
import { axiosInstance } from '../../../config/app-specific-config/axiosConfig';
import config from "../../../config/app-config";



// Org Users CRUD

/////Create User profile
export const createGenesisUserSuccess = (response) => {
	return {
		type: types.CREATE_GENESIS_USER_SUCCESS,
		payload: response.data
	};
};

export const createGenesisUserFailure = (error) => {
	return {
		type: types.CREATE_GENESIS_USER_FAILURE,
		error
	};
};

export const createGenesisUserRequest = () => {
	return {
		type: types.CREATE_GENESIS_USER_REQUEST
	};
};

export const createGenesisUser = (createdGenesisUserParams) => {
	return async (dispatch) => {
		dispatch(createGenesisUserRequest());
		try {
			const { saaRoute } = config;

			const url = `/${saaRoute}/create`; 
			const headers = await auth.getAccessToken();
		
			const response = await axiosInstance.post(
				url,
				createdGenesisUserParams,
				{ headers }
			);
			dispatch(createGenesisUserSuccess(response));
	
		} catch (error) {
			dispatch(createGenesisUserFailure(error));
		}
	};
};

// Reset state for updating Org User Profile
export const createGenesisUserOutcomeReset = () => {
	return {
		type: types.CREATE_GENESIS_USER_RESET
	};
};

export function createGenesisUserReset() {

	return async (dispatch) => {
	
		try {
			dispatch(createGenesisUserOutcomeReset());

		} catch (error) {
			dispatch(createGenesisUserFailure(error));
		}
	};
}


///// Offboard User
export const offboardUserSuccess = (response) => {
	return {
		type: types.OFFBOARD_USER_SUCCESS,
		payload: response.data
	};
};

export const offboardUserFailure = (error) => {
	return {
		type: types.OFFBOARD_USER_FAILURE,
		error
	};
};

export const offboardUserRequest = () => {
	return {
		type: types.OFFBOARD_USER_REQUEST
	};
};

export const offboardUser = (offboardingUserEmail) => {
	return async (dispatch) => {
		dispatch(offboardUserRequest());
		try {
			const url = `/admin/offboard/user`; 
			const headers = await auth.getAccessToken();
		
			const response = await axiosInstance.post(
				url,
				{ offboardingUserEmail },
				{ headers }
			);
			dispatch(offboardUserSuccess(response));
	
		} catch (error) {
			dispatch(offboardUserFailure(error));
		}
	};
};

// Reset state for updating Org User Profile
export const offboardUserOutcomeReset = () => {
	return {
		type: types.OFFBOARD_USER_RESET
	};
};

export function offboardUserReset() {

	return async (dispatch) => {
	
		try {
			dispatch(offboardUserOutcomeReset());

		} catch (error) {
			dispatch(offboardUserFailure(error));
		}
	};
}



// Edit Genesis User
export const editGenesisUserSuccess = (response) => {
	return {
		type: types.EDIT_GENESIS_USER_SUCCESS,
		payload: response.data
	};
};

export const editGenesisUserFailure = (error) => {
	return {
		type: types.EDIT_GENESIS_USER_FAILURE,
		error
	};
};

export const editGenesisUserRequest = () => {
	return {
		type: types.EDIT_GENESIS_USER_REQUEST
	};
};

export const editGenesisUser = (updatedUserParams) => {
	return async (dispatch) => {
		dispatch(editGenesisUserRequest());
		try {
			const url = `/admin/genesis/edit`; 
			const headers = await auth.getAccessToken();
		
			const response = await axiosInstance.post(
				url,
				updatedUserParams,
				{ headers }
			);
			dispatch(editGenesisUserSuccess(response));
	
		} catch (error) {
			dispatch(editGenesisUserFailure(error));
		}
	};
};

// Reset state for editing Genesis user in frontend
export const editGenesisUserReset = () => {
	return {
		type: types.EDIT_GENESIS_USER_RESET
	};
};

export function resetEditGenesisUser() {

	return async (dispatch) => {
	
		try {
			dispatch(editGenesisUserReset());

		} catch (error) {
			dispatch(editGenesisUserFailure(error));
		}
	};
}



// Edit Existing User
export const editExistingUserSuccess = (response) => {
	return {
		type: types.EDIT_EXISTING_USER_SUCCESS,
		payload: response.data
	};
};

export const editExistingUserFailure = (error) => {
	return {
		type: types.EDIT_EXISTING_USER_FAILURE,
		error
	};
};

export const editExistingUserRequest = () => {
	return {
		type: types.EDIT_EXISTING_USER_REQUEST
	};
};

export const manageExistingUser = (manageExistingUserPayload) => {
	return async (dispatch) => {
		dispatch(editExistingUserRequest());
		try {
			const url = `/admin/existing-user/manage`; 
			const headers = await auth.getAccessToken();
		
			const response = await axiosInstance.post(
				url,
				manageExistingUserPayload,
				{ headers }
			);
			dispatch(editExistingUserSuccess(response));
	
		} catch (error) {
			dispatch(editExistingUserFailure(error));
		}
	};
};

// Reset state for editing existing user in frontend
export const editExistingUserReset = () => {
	return {
		type: types.EDIT_EXISTING_USER_RESET
	};
};

export function resetManageExistingUser() {
	return async (dispatch) => {
		try {
			dispatch(editExistingUserReset());

		} catch (error) {
			dispatch(editExistingUserFailure(error));
		}
	};
}



// Create New Organization for existing users
export const createOrgForExistingUserSuccess = (response) => {
	return {
		type: types.CREATE_NEW_ORG_FOR_EXISTING_USER_SUCCESS,
		payload: response.data
	};
};

export const createOrgForExistingUserFailure = (error) => {
	return {
		type: types.CREATE_NEW_ORG_FOR_EXISTING_USER_FAILURE,
		error
	};
};

export const createOrgForExistingUserRequest = () => {
	return {
		type: types.CREATE_NEW_ORG_FOR_EXISTING_USER_REQUEST
	};
};

export const createOrgForExistingUser = (createNewOrgForExistingUserParams) => {
	return async (dispatch) => {
		dispatch(createOrgForExistingUserRequest());
		try {
			const { saaRoute } = config;

			const url = `/${saaRoute}/create-org-for-existing-user`; 
			const headers = await auth.getAccessToken();
		
			const response = await axiosInstance.post(
				url,
				createNewOrgForExistingUserParams,
				{ headers }
			);
			dispatch(createOrgForExistingUserSuccess(response));
	
		} catch (error) {
			dispatch(createOrgForExistingUserFailure(error));
		}
	};
};

// Reset state for updating Org User Profile
export const createNewOrgForExistingUserReset = () => {
	return {
		type: types.CREATE_NEW_ORG_FOR_EXISTING_USER_RESET
	};
};

export function resetCreateNewOrgForExistingUser() {

	return async (dispatch) => {
	
		try {
			dispatch(createNewOrgForExistingUserReset());

		} catch (error) {
			dispatch(createOrgForExistingUserFailure(error));
		}
	};
}

// Edit Existing Organization
export const editExistingOrganizationSuccess = (response) => {
	return {
		type: types.EDIT_EXISTING_ORGANIZATION_SUCCESS,
		payload: response
	};
};

export const editExistingOrganizationFailure = (error) => {
	return {
		type: types.EDIT_EXISTING_ORGANIZATION_FAILURE,
		error
	};
};

export const editExistingOrganizationRequest = () => {
	return {
		type: types.EDIT_EXISTING_ORGANIZATION_REQUEST
	};
};

export const manageExistingOrganization = (manageExistingOrganizationPayload) => {
	return async (dispatch) => {
		dispatch(editExistingOrganizationRequest());
		try {
			const url = `/admin/existing-org/manage`; 
			const headers = await auth.getAccessToken();
			
			const response = await axiosInstance.post(
				url,
				manageExistingOrganizationPayload,
				{ headers }
			);

			dispatch(editExistingOrganizationSuccess(response.data.data));
	
		} catch (error) {
			dispatch(editExistingOrganizationFailure(error));
		}
	};
};

// Reset state for editing existing user in frontend
export const editExistingOrganizationReset = () => {
	return {
		type: types.EDIT_EXISTING_ORGANIZATION_RESET
	};
};

export function resetManageExistingOrganization() {
	return async (dispatch) => {
		try {
			dispatch(editExistingOrganizationReset());

		} catch (error) {
			dispatch(editExistingOrganizationFailure(error));
		}
	};
}