import React from "react";
import { Box, Flex } from "@chakra-ui/react";
import LaunchdeckAudioPlayer from "../../../global/components/helper-components/components/LaunchdeckAudioPlayer";

const PublishedDsrAudioFilePlayer = ({ subWidgetContent }) => {

  const  audioFilePlaceholder = <Flex direction="column" alignItems="center" justifyContent="center" height="80px">

</Flex>;

return (
  subWidgetContent
    ?   <Box p='10px' my='1em'>
    <LaunchdeckAudioPlayer
    autoPlay={false}
    file={subWidgetContent[0]}
    showFileName={true}
  />
    </Box>
    : audioFilePlaceholder
);
}

export default PublishedDsrAudioFilePlayer;
