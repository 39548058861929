export const GET_DSR_CONFIG_REQUEST = 'get_dsr_config_request';
export const GET_DSR_CONFIG_SUCCESS = 'get_dsr_config_success';
export const GET_DSR_CONFIG_FAILURE = 'get_dsr_config_failure';

export const CHANGE_DSR_POPUP = 'change_dsr_popup';
export const CHANGE_SHOW_DSR_MODAL = 'change_show_dsr_modal';
export const CHANGE_SELECT_SUBPAGE = 'change_select_subpage'; // UUU TODO: Review and deprecate
export const CHANGE_DSR_PAGE_DISPLAY = 'change_dsr_page_display'; 

export const SET_DSR_PAGES = 'set_dsr_pages';
export const SET_DSR_INVALID_REQUEST = 'set_dsr_invalid_request';
export const RESET_DSR_PAGES = 'reset_dsr_pages';
export const SET_DSR_TEAM_MEMBERS = 'set_dsr_team_members';
export const SET_ROOM_MAIN_CONTACT_INFO = 'set_room_main_contact_info';
export const SET_DSR_DRAFT_CURRENT_PAGE = 'set_dsr_draft_current_page';
export const SET_DSR_DRAFT_STATUS = 'set_dsr_draft_status';
export const SET_DSR_DRAFT_MODAL = 'set_dsr_draft_modal';
export const SET_DSR_DRAFT_USER_ACTION = 'set_dsr_draft_user_action';
export const SET_DSR_DRAFT_TITLE = 'set_dsr_draft_title';
export const RESET_DSR_TITLE = 'reset_dsr_title';
export const SET_DSR_DRAFT_USER_PROFILE = 'set_dsr_draft_user_profile';
export const RESET_DSR_SELECTED_DISPLAY_SUBPAGE = 'reset_dsr_selected_display_subpage';
export const SET_DSR_DRAFT_SHOW_LOAD_MORE = 'set_dsr_draft_show_load_more';
export const SET_DSR_DRAFT_FAQ_PAGE_ID = 'set_dsr_draft_faq_page_id';
export const SET_DSR_DRAFT_MODAL_LOADER = 'set_dsr_draft_modal_loader';
export const SET_DSR_DRAFT_PROPERTIES = 'set_dsr_draft_properties';
export const SET_DSR_USER_TEAM = 'set_dsr_user_team';
export const SET_IS_DSR_ADMIN = 'set_is_dsr_admin';
export const SET_DSR_FAQ_CONTENT = 'set_dsr_faq_content';
export const SET_DSR_SHARED_DOCS = 'set_dsr_shared_docs';
export const SET_DSR_LOGO_IMAGES = 'set_dsr_logo_images';
export const SET_JOIN_DSR_REQUESTS = 'set_join_dsr_requests';
export const SET_DSR_UI_INDICATORS = 'set_dsr_ui_indicators';
export const SET_DSR_LATEST_NOTIFICATIONS = 'set_dsr_latest_notifications';
export const SET_DSR_ACTION_LIST_OVERVIEW = 'set_dsr_action_list_overview';
export const SET_DSR_LAST_UPDATED = 'set_dsr_last_updated';
export const SET_DSR_SHARED_DOCS_OVERVIEW = 'set_dsr_shared_docs_overview';

export const EDIT_DSR_TEAM_MEMBERS_REQUEST = 'edit_dsr_team_members_request';
export const EDIT_DSR_TEAM_MEMBERS_SUCCESS = 'edit_dsr_team_members_success';
export const EDIT_DSR_TEAM_MEMBERS_FAILURE = 'edit_dsr_team_members_failure';
export const EDIT_DSR_TEAM_MEMBERS_RESET = 'edit_dsr_team_members_reset';

export const EDIT_DSR_MEMBER_ROLE_REQUEST = 'edit_dsr_member_role_request';
export const EDIT_DSR_MEMBER_ROLE_SUCCESS = 'edit_dsr_member_role_success';
export const EDIT_DSR_MEMBER_ROLE_FAILURE = 'edit_dsr_member_role_failure';
export const EDIT_DSR_MEMBER_ROLE_RESET = 'edit_dsr_member_role_reset';

// FEED CONTENT
// Feed post CRUD
export const GET_DSR_FEED_CONTENT_REQUEST = 'get_dsr_feed_content_request';
export const GET_DSR_FEED_CONTENT_SUCCESS = 'get_dsr_feed_content_success';
export const GET_DSR_FEED_CONTENT_FAILURE = 'get_dsr_feed_content_failure';
export const UPDATE_DSR_FEED_CONTENT_SUCCESS = 'update_dsr_feed_content_success';
export const REPLACE_DSR_FEED_CONTENT = 'replace_dsr_feed_content';
export const REPLACE_UPDATED_DSR_FEED_CONTENT = 'replace_updated_dsr_feed_content';
export const RESET_DSR_FEED_CONTENT_STATE = 'reset_dsr_feed_content_state';

export const CREATE_NEW_DSR_FEED_POST_REQUEST = 'create_new_dsr_feed_post_request';
export const CREATE_NEW_DSR_FEED_POST_SUCCESS = 'create_new_dsr_feed_post_success';
export const CREATE_NEW_DSR_FEED_POST_FAILURE = 'create_new_dsr_feed_post_failure';
export const CREATE_NEW_DSR_FEED_POST_RESET = 'create_new_dsr_feed_post_reset'; 
export const CREATE_FIRST_FAQ_QUESTION_OUTCOME = 'create-first-faq-question-outcome';

export const EDIT_DSR_FEED_POST_REQUEST = 'edit_dsr_feed_post_request';
export const EDIT_DSR_FEED_POST_SUCCESS = 'edit_dsr_feed_post_success';
export const EDIT_DSR_FEED_POST_FAILURE = 'edit_dsr_feed_post_failure';
export const EDIT_DSR_FEED_POST_RESET = 'edit_dsr_feed_post_reset'; 

export const DELETE_DSR_FEED_POST_REQUEST = 'delete_dsr_feed_post_request';
export const DELETE_DSR_FEED_POST_SUCCESS = 'delete_dsr_feed_post_success';
export const DELETE_DSR_FEED_POST_FAILURE = 'delete_dsr_feed_post_failure';
export const DELETE_DSR_FEED_POST_RESET = 'delete_dsr_feed_post_reset'; 

// Feed reply CRUD
// Read ability is included in get dsr feed content

export const CREATE_NEW_DSR_FEED_REPLY_REQUEST = 'create_new_dsr_feed_reply_request';
export const CREATE_NEW_DSR_FEED_REPLY_SUCCESS = 'create_new_dsr_feed_reply_success';
export const CREATE_NEW_DSR_FEED_REPLY_FAILURE = 'create_new_dsr_feed_reply_failure';
export const CREATE_NEW_DSR_FEED_REPLY_RESET = 'create_new_dsr_feed_reply_reset'; 

export const EDIT_DSR_FEED_REPLY_REQUEST = 'edit_dsr_feed_reply_request';
export const EDIT_DSR_FEED_REPLY_SUCCESS = 'edit_dsr_feed_reply_success';
export const EDIT_DSR_FEED_REPLY_FAILURE = 'edit_dsr_feed_reply_failure';
export const EDIT_DSR_FEED_REPLY_RESET = 'edit_dsr_feed_reply_reset'; 

export const DELETE_DSR_FEED_REPLY_REQUEST = 'delete_dsr_feed_reply_request';
export const DELETE_DSR_FEED_REPLY_SUCCESS = 'delete_dsr_feed_reply_success';
export const DELETE_DSR_FEED_REPLY_FAILURE = 'delete_dsr_feed_reply_failure';
export const DELETE_DSR_FEED_REPLY_RESET = 'delete_dsr_feed_reply_reset';

// Feed post reaction CRUD
export const EDIT_DSR_REACTION_REQUEST = 'edit_dsr_reaction_request';
export const EDIT_DSR_REACTION_SUCCESS = 'edit_dsr_reaction_success';
export const EDIT_DSR_REACTION_FAILURE = 'edit_dsr_reaction_failure';
export const EDIT_DSR_REACTION_RESET = 'edit_dsr_reaction_reset';


//////
// DSR Action List CRUD
// Get Action List
export const GET_DSR_ACTION_LIST_REQUEST = 'get_dsr_action_list_request';
export const GET_DSR_ACTION_LIST_SUCCESS = 'get_dsr_action_list_success';
export const GET_DSR_ACTION_LIST_FAILURE = 'get_dsr_action_list_failure';
export const UPDATE_REDUX_DSR_ACTION_LIST = 'update_redux_dsr_action_list'

// Create New Action
export const CREATE_NEW_DSR_ACTION_REQUEST = 'create_new_dsr_action_request';
export const CREATE_NEW_DSR_ACTION_SUCCESS = 'create_new_dsr_action_success';
export const CREATE_NEW_DSR_ACTION_FAILURE = 'create_new_dsr_action_failure';
export const CREATE_NEW_DSR_ACTION_RESET = 'create_new_dsr_action_reset';

// Update Action
export const UPDATE_DSR_ACTION_REQUEST = 'update_dsr_action_request';
export const UPDATE_DSR_ACTION_SUCCESS = 'update_dsr_action_success';
export const UPDATE_DSR_ACTION_FAILURE = 'update_dsr_action_failure';
export const UPDATE_DSR_ACTION_RESET = 'update_dsr_action_reset';


//////
// Product marketing & Ops CRUD
export const CREATE_NEW_ERROR_REPORT_REQUEST = 'create_new_error_report_request';
export const CREATE_NEW_ERROR_REPORT_SUCCESS = 'create_new_error_report_success';
export const CREATE_NEW_ERROR_REPORT_FAILURE = 'create_new_error_report_failure';



// DSR Auth CRUD
export const GET_DSR_AUTH_REQUEST = 'get_dsr_auth_request';
export const GET_DSR_AUTH_SUCCESS = 'get_dsr_auth_success';
export const GET_DSR_AUTH_FAILURE = 'get_dsr_auth_failure';


// Loom Management Service CRUD
export const GET_LOOM_TOKEN_REQUEST = 'get_loom_token_request';
export const GET_LOOM_TOKEN_SUCCESS = 'get_loom_token_success';
export const GET_LOOM_TOKEN_FAILURE = 'get_loom_token_failure';

export const GET_LOOM_VIDEOS_REQUEST = 'get_loom_video_request';
export const GET_LOOM_VIDEOS_SUCCESS = 'get_loom_video_success';
export const GET_LOOM_VIDEOS_FAILURE = 'get_loom_video_failure';


// Manage DSR CRUD (Create, Edit, Delete)
export const CREATE_NEW_DSR_REQUEST = 'create_new_dsr_request';
export const CREATE_NEW_DSR_SUCCESS = 'create_new_dsr_success';
export const CREATE_NEW_DSR_FAILURE = 'create_new_dsr_failure';

export const CREATE_DUPLICATE_DSR_REQUEST = 'create_duplicate_dsr_request';
export const CREATE_DUPLICATE_DSR_SUCCESS = 'create_duplicate_dsr_success';
export const CREATE_DUPLICATE_DSR_FAILURE = 'create_duplicate_dsr_failure';

export const UPDATE_DSR_REQUEST = 'update_dsr_request';
export const UPDATE_DSR_SUCCESS = 'update_dsr_success';
export const UPDATE_DSR_FAILURE = 'update_dsr_failure';
export const UPDATE_DSR_RESET = 'update_dsr_reset';

// Upload and delete files
export const UPLOAD_FILES_REQUEST = 'upload_files_request';
export const UPLOAD_FILES_FAILURE = 'upload_files_failure';
export const UPLOAD_FILES_SUCCESS = 'upload_files_success';
export const UPLOAD_FILES_RESET = 'upload_files_reset';

export const DELETE_FILES_REQUEST = 'delete_files_request';
export const DELETE_FILES_FAILURE = 'delete_files_failure';
export const DELETE_FILES_SUCCESS = 'delete_files_success';
export const DELETE_FILES_RESET = 'delete_files_reset';

// Manage Prospect DSR CRUD (Create, Read, Update/Edit, Delete)
export const CREATE_NEW_PROSPECT_DSR_REQUEST = 'create_new_prospect_dsr_request';
export const CREATE_NEW_PROSPECT_DSR_SUCCESS = 'create_new_prospect_dsr_success';
export const CREATE_NEW_PROSPECT_DSR_FAILURE = 'create_new_prospect_dsr_failure';

export const CREATE_PROSPECT_DSR_QUERY_REQUEST = 'create_prospector-dsr_query_request';
export const CREATE_PROSPECT_DSR_QUERY_SUCCESS = 'create_prospector-dsr_query_success';
export const CREATE_PROSPECT_DSR_QUERY_FAILURE = 'create_prospector-dsr_query_failure';
export const CREATE_PROSPECT_DSR_QUERY_RESET = 'create_prospector-dsr_query_reset';

export const UPDATE_PROSPECT_DSR_REQUEST = 'update_prospect_dsr_request';
export const UPDATE_PROSPECT_DSR_SUCCESS = 'update_prospect_dsr_success';
export const UPDATE_PROSPECT_DSR_FAILURE = 'update_prospect_dsr_failure';
export const UPDATE_PROSPECT_DSR_RESET = 'update_prospect_dsr_reset';

export const GET_PROSPECT_DSR_REQUEST = 'get_prospect_dsr_request';
export const GET_PROSPECT_DSR_SUCCESS = 'get_prospect_dsr_success';
export const GET_PROSPECT_DSR_FAILURE = 'get_prospect_dsr_failure';


// DSR Upload Progress
export const SET_UPLOAD_PROGRESS_METRIC = 'set_upload_progress_metric';
export const RESET_UPLOAD_PROGRESS_METRIC = 'reset_upload_progress_metric';

//////
// DSR Q&A CRUD
//////
// Create New Q&A Answer
export const CREATE_FAQ_ANSWER_REQUEST = 'create_faq_answer_request';
export const CREATE_FAQ_ANSWER_SUCCESS = 'create_faq_answer_success';
export const CREATE_FAQ_ANSWER_FAILURE = 'create_faq_answer_failure';
export  const CREATE_FAQ_ANSWER_RESET = 'create_faq_answer_reset';

// // EDIT Q&A Answer
export const EDIT_FAQ_ANSWER_REQUEST = 'edit_faq_answer_request';
export const EDIT_FAQ_ANSWER_SUCCESS = 'edit_faq_answer_success';
export const EDIT_FAQ_ANSWER_FAILURE = 'edit_faq_answer_failure';
export const EDIT_FAQ_ANSWER_RESET = 'edit_faq_answer_reset';

// DELETE Q&A Answer
export const DELETE_FAQ_ANSWER_REQUEST = 'delete_faq_answer_request';
export const DELETE_FAQ_ANSWER_SUCCESS = 'delete_faq_answer_success';
export const DELETE_FAQ_ANSWER_FAILURE = 'delete_faq_answer_failure';
export const DELETE_FAQ_ANSWER_RESET = 'delete_faq_answer_reset';

// Create New Q&A Question
export const CREATE_FAQ_QUESTION_REQUEST = 'create_faq_question_request';
export const CREATE_FAQ_QUESTION_SUCCESS = 'create_faq_question_success';
export const CREATE_FAQ_QUESTION_FAILURE = 'create_faq_question_failure';
export const CREATE_FAQ_QUESTION_RESET = 'create_faq_question_reset';

// // // EDIT Q&A Question
export const EDIT_FAQ_QUESTION_REQUEST = 'edit_faq_question_request';
export const EDIT_FAQ_QUESTION_SUCCESS = 'edit_faq_question_success';
export const EDIT_FAQ_QUESTION_FAILURE = 'edit_faq_question_failure';
export const EDIT_FAQ_QUESTION_RESET = 'edit_faq_question_reset';

// // DELETE Q&A Question
export const DELETE_FAQ_QUESTION_REQUEST = 'delete_faq_question_request';
export const DELETE_FAQ_QUESTION_SUCCESS = 'delete_faq_question_success';
export const DELETE_FAQ_QUESTION_FAILURE = 'delete_faq_question_failure';
export const DELETE_FAQ_QUESTION_RESET = 'delete_faq_question_reset';

// // Manage Shared Doc
export const MANAGE_SHARED_DOC_REQUEST = 'manage_shared_doc_request';
export const MANAGE_SHARED_DOC_SUCCESS = 'manage_shared_doc_success';
export const MANAGE_SHARED_DOC_FAILURE = 'manage_shared_doc_failure';
export const MANAGE_SHARED_DOC_RESET = 'manage_shared_doc_reset';

// // Delete Shared Doc
// export const DELETE_SHARED_DOC_REQUEST = 'delete_shared_doc_request';
// export const DELETE_SHARED_DOC_SUCCESS = 'delete_shared_doc_success';
// export const DELETE_SHARED_DOC_FAILURE = 'delete_shared_doc_failure';
// export const DELETE_SHARED_DOC_RESET = 'delete_shared_doc_reset';