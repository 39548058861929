// Org Users CRUD ops
export const CREATE_GENESIS_USER_REQUEST = 'create_genesis_user_request';
export const CREATE_GENESIS_USER_SUCCESS = 'create_genesis_user_success';
export const CREATE_GENESIS_USER_FAILURE = 'create_genesis_user_failure';
/// RESET
export const CREATE_GENESIS_USER_RESET = 'create_genesis_user_reset'


export const OFFBOARD_USER_REQUEST = 'offboard_user_request';
export const OFFBOARD_USER_SUCCESS = 'offboard_user_success';
export const OFFBOARD_USER_FAILURE = 'offboard_user_failure';
/// RESET
export const OFFBOARD_USER_RESET = 'offboard_user_reset'

export const EDIT_GENESIS_USER_REQUEST = 'edit_genesis_user_request';
export const EDIT_GENESIS_USER_SUCCESS = 'edit_genesis_user_success';
export const EDIT_GENESIS_USER_FAILURE = 'edit_genesis_user_failure';
/// RESET
export const EDIT_GENESIS_USER_RESET = 'edit_genesis_user_reset'

export const EDIT_EXISTING_USER_REQUEST = 'edit_existing_user_request';
export const EDIT_EXISTING_USER_SUCCESS = 'edit_existing_user_success';
export const EDIT_EXISTING_USER_FAILURE = 'edit_existing_user_failure';
/// RESET
export const EDIT_EXISTING_USER_RESET = 'edit_existing_user_reset'



// Organization CRUD ops
export const CREATE_NEW_ORG_FOR_EXISTING_USER_REQUEST = 'create_new_org_for_existing_user_request';
export const CREATE_NEW_ORG_FOR_EXISTING_USER_SUCCESS = 'create_new_org_for_existing_user_success';
export const CREATE_NEW_ORG_FOR_EXISTING_USER_FAILURE = 'create_new_org_for_existing_user_failure';
/// RESET
export const CREATE_NEW_ORG_FOR_EXISTING_USER_RESET = 'create_new_org_for_existing_user_reset'

export const EDIT_EXISTING_ORGANIZATION_REQUEST = 'edit_existing_organization_request';
export const EDIT_EXISTING_ORGANIZATION_SUCCESS = 'edit_existing_organization_success';
export const EDIT_EXISTING_ORGANIZATION_FAILURE = 'edit_existing_organization_failure';
/// RESET
export const EDIT_EXISTING_ORGANIZATION_RESET = 'edit_existing_organization_reset'