import React from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';

import { Box, Flex } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import LaunchdeckButton from '../../global/components/helper-components/components/LaunchdeckButton';


function OrgUpCtaModule({ userAccountProfile }) {

  const history = useHistory();
  const { email, firstName, lastName } = userAccountProfile || {};

  const handleClickSetupEnquiry = () => {
    const prepopName = (firstName && lastName) ? firstName + ' ' + lastName : firstName ? firstName : "";
    history.push('/signup', { prepopEmail: email, prepopName: prepopName.trim(), redirectBackRoute: "/profile" });
  }

  return (
    <Box mt='2em' rounded='0.25em' bg='blue.50' p='1em' border='1px solid' borderColor='gray.300' boxShadow='xs'>
      <Flex align='center' justify='center' w='100%'>
        <Box fontSize='2em'><span role="img" aria-label="thinking">🤔</span> </Box>
        <Box ml='1em' fontSize='0.875em'>Thinking about setting up your own Rooms to engage audiences & stakeholders?</Box>
      </Flex>

      <Flex justify={'center'} w='100%' mt='2em'>
        <LaunchdeckButton bg='brand.500' color='white' onClick={() => handleClickSetupEnquiry()} label='Get business account' />
      </Flex>
    </Box>
  )
}


const mapStateToProps = (state) => ({
  userAccountProfile: state.userProfile.accountUserProfile
})

export default withRouter(connect(mapStateToProps, null)(OrgUpCtaModule))