import React from 'react';
import { connect } from 'react-redux';
import { Box, Flex, Text, Image } from '@chakra-ui/react';

import { displayEmoji } from '../../../digital-sales-room/helpers/dsr-helpers';
import ClaverSignupImage from '../../styles/images/claver-signup-icon.svg';
import PrivacyTos from '../PrivacyTos';
import SignupForm from './SignupForm';
import { Link } from 'react-router-dom';


function OrganicSignupPageContent({
  marketingUserTrackId,
  prepopEmailDisplay,
  prepopNameDisplay,
  setShowSignupLoader,
  // Redux
  signupOutcome,
}) {


  const { signupError, type, message } = signupOutcome.results || {};
  const signupProcessInProgress = (signupOutcome.loading === true) ? true : "";

  // if (showSuccessState) {
  // 	return (
  // 			<Flex p='1em' height='100%' justify='center' align='center'><span role="img" aria-label="party-face">🥳</span> <Box ml='0.5em'>Thanks! We've send an activation link to your email. Head over to your mailbox and look out for it. Tip: If you don't see it in a few minutes, check out your Junk folder!</Box> </Flex>
  // 	)
  // } 
  // else {
  return (
    <Flex maxW="1440px" direction={['column-reverse', 'row', 'row', 'row']} w='100%' px={['1em', '10em', '10em', '10em']} py='2em' justify='space-betweeen' align='stretch' margin='0 auto'>
      <Flex direction={['column-reverse', 'column', 'column', 'column']} justify='center' alignItems={['center', 'flex-start', 'flex-start', 'flex-start']} flex='1' px='2em'>

        <Box mb='3em'>
          <Box fontSize='2em' lineHeight='3em' color='brandDark.500' fontWeight='600'>Sign up for Launchdeck</Box>
          <Box mt='0.5em' fontSize='0.875em' color='brandDark.500' fontWeight='600' mb='0.25em'> Why?</Box>
          <Box fontSize='0.875em' color='gray.600' fontWeight='400'> 1. Shorten your sales time-to-close with Rooms</Box>
          <Box fontSize='0.875em' color='gray.600' fontWeight='400' my='0.75em'> 2. Onboard new clients seamlessly, from Sales to Customer Success</Box>
          <Box fontSize='0.875em' color='gray.600' fontWeight='400'> 3. Coordinate highly dynamic situations with stakeholders </Box>
          <Box fontSize='0.875em' color='gray.600' fontWeight='400' mt='0.75em'> ...and more! </Box>
          <Box w='100%' bg='white' mt='2em' rounded='0.5em' p='0.5em' fontSize='0.875em' border='1px solid' borderColor='brandPink.500'>
            <Flex fontWeight='600' gap={2}>{displayEmoji('celebrate')} Limited offer: Free trial codes</Flex>
            <Box mt='1em' color='brandDark.500'>30-day free codes provided to all new signups</Box>
            <Box fontSize='0.75em' color='gray.500'>Cancel anytime, no questions asked</Box>
          </Box>

        </Box>

        <Flex w='100%' mt={['3em', '3em', '3em', '3em']} mb='2em' px='1em' justify='flex-start'>
          <Image src={ClaverSignupImage}></Image>
        </Flex>
      </Flex>

      <Box flex='1' className='fade-in-left default-box-shadow' maxWidth={['', '37.5em', '37.5em', '37.5em']} rounded='0.25em'>

        <Flex direction='column' alignItems='center' bg='white' rounded='0.25em' h='100%' >
          {/* <Box w='100%' borderBottom='1px solid' borderColor='gray.300' py='0.8em' > */}
          {/* <Box w='100%' fontSize='1.2em' lineHeight='1.875em' fontWeight='500' textAlign='center'>Sign Up for Launchdeck</Box> */}
          {/* <Box w='100%' fontSize='1.2em' lineHeight='1.875em' fontWeight='500' textAlign='center'>Join waitlist</Box> */}
          {/* </Box> */}

          {/* <GoogleLogin
            clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
            onSuccess={handleGoogleLogin}
            onFailure={handleGoogleLogin}
            cookiePolicy={'single_host_origin'}
            render={renderProps => (
              <Flex w='100%' py='2em' px='3em' alignItems='center' justify='center'>
                <Flex flex='1' px='1em' py='0.625em' bg='gray.100' rounded='0.25em' cursor='pointer' onClick={renderProps.onClick} disabled={renderProps.disabled}>
                  <Image src={GoogleLogo}></Image>
                  <Box pl='1em'>
                    Sign up with Google
                  </Box>
                </Flex>
              </Flex>
            )}
          />
  
          <Box w='100%' position='relative'>
            <Box position='absolute' bg='white' color='gray.500' zIndex='10' top='-0.5em' mx='45%' px='1em' fontSize='0.875em' fontWeight='500'>OR</Box>
            <Divider />
          </Box> */}

          <Box w='100%' px='3em' h='100%'>
            {!signupError && !signupProcessInProgress && <SignupForm
              marketingUserTrackId={marketingUserTrackId}
              prepopEmailDisplay={prepopEmailDisplay}
              prepopNameDisplay={prepopNameDisplay}
              setShowSignupLoader={setShowSignupLoader}
            />}
            {signupError && type === 'Account already exists' && <ExistingAccountSignupError message={message} />}
          </Box>

          <Box borderTop='1px solid' borderColor='gray.300' w='100%' py='1em' px='2em'>
            <PrivacyTos />
          </Box>
        </Flex>
      </Box>
    </Flex>
  )

  // }

};

function ExistingAccountSignupError({ message }) {

  return (
    <Flex my='3%' minHeight='18em' justify='center' w={['90%', '100%', '100%', '100%']}>
      <Box fontSize='0.8em' borderWidth="1px" borderColor='pink.300' rounded={8} p='2%' my='auto'>
        <Text textAlign='center'>{message} </Text>
        <Link to="/reset-password">
          <Flex alignItems='baseline' justify='center'>
            <Text color='gray.500' textAlign='center' mt='1.5em' textDecoration='underline'>I had forgotten my password </Text>
            <span role="img" aria-label="shameful"> 😔 </span>
          </Flex>
        </Link>
      </Box>
    </Flex>

  )
}



function mapStateToProps(state) {
  return {
    signupOutcome: state.auth.data.signup,
    // login: state.auth.data.login,
  };
}


export default connect(mapStateToProps, null)(OrganicSignupPageContent);
