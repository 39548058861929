import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'

import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputLeftAddon,
  Image,
  InputRightElement,
  Popover, PopoverTrigger, PopoverContent, PopoverArrow, PopoverHeader, useDisclosure,
  useOutsideClick,
  useMediaQuery
} from "@chakra-ui/react";
import PageLoader from '../global/components/helper-components/components/PageLoader';

import { Redirect } from 'react-router-dom';
import SearchIcon from './styles/images/search-icon.svg';
import CreateDsrModal from '../dashboard/components/CreateDsrModal';
import VaultDisplayDsrModule from './VaultDisplayDsrModule';
import { prospectorDsrSearch } from './actions/vault-actions';
import VaultDisplaySearchResultsModule from './VaultDisplaySearchResultsModule';

import { GoChevronDown } from 'react-icons/go';
import { IoMdAddCircle, IoIosClose } from 'react-icons/io';
import { checkIfDismissedBanner, redirectToNewPage } from '../global/helpers/global-helpers';
import VaultMobileNavMenu from './VaultMobileNavMenu';
import DynamicAppBannerDisplay from '../global/components/helper-components/components/DynamicAppBannerDisplay';
import { getUserProfile, getUserSettings } from '../profile/actions/users-profile-actions';
import LoggedOutConfirmationModal from '../digital-sales-room/dsr-general-mgmt/LoggedOutConfirmationModal';

function VaultProspectorPage({
  vaultIsProcessing,
  allUserDsrs,
  fetchVaultData,
  prospectorDsrSearch,
  prospectorSearchResults,
  vaultSearchIsProcessing,
  resetFetchVaultData,
  isMobileView,
  userPrivileges,
  // Actions
  getUserProfile,
  getUserSettings,
  // Redux States
  userAccountProfile,
  isOrgAdmin,
  userSettings
}) {

  const initRef = useRef(false);
  const [localState, setLocalState] = useState({
    isLoading: true,
    // isMobileView: false,
    isVerified: null,
    // isOnboarded: null,

    isEmptyState: '',
    requestCategory: "initialize-prospectors",

    //Data Mgmt
    displayedDsrs: "",

    // UX Management
    showCreateDsrModal: "",
    itemsPerPage: 9, // TODO: Allow user configurability
    pageNumber: 1
  })

  // Manage banner
  const [showAlertBanner, setShowAlertBanner] = useState(false)
  const [offerFreeTrial,setOfferFreeTrial] = useState(false)
  const [freeTrialCode, setFreeTrialCode] = useState("")

  // Manage search bar
  const [displaySearchResults, setDisplaySearchResults] = useState(false)
  const [searchInputValue, setSearchInputValue] = useState("");

  // Manage Tab status
  const [chosenStatusTab, setChosenStatusTab] = useState("published")

  // Manage Sort function
  const [showSortDropdown, setShowSortDropdown] = useState(false)
  const [chosenSortValue, setChosenSortValue] = useState("Latest")

  // Mange Filter function
  const [showFilterDropdown, setShowFilterDropdown] = useState(false)
  const [chosenFilterValue, setChosenFilterValue] = useState("All Prospectors")

  // Onboarding UX
  const [disableDraftDsrDisplay, setDisableDraftDsrDisplay] = useState(false)

  useEffect(() => {
    if (!initRef.current) {
      document.title = 'Launchdeck Digital Sales Room'
      document.description =
        'Create customised private digital sales rooms that let Sales & Purchasing teams agree on deals quickly'

      fetchVaultData({ requestCategory: "initialize-prospectors", pageNumber: 1, itemsPerPage: localState.itemsPerPage, sortRequest: "default" })
      initRef.current = true
    }
  }, [fetchVaultData, localState.itemsPerPage])

  useEffect(() => {
		if (!userAccountProfile) {
			getUserProfile({
				getOrgProfile: true
			})
		} else {
      const {userMetaData} = userAccountProfile || {};
      const {productMarketing} = userMetaData || {};
      const {offerFreeTrial, freeTrialCode} =  productMarketing || {};

      if (offerFreeTrial && freeTrialCode) {
        setOfferFreeTrial(true)
        setFreeTrialCode(freeTrialCode)
      }
    }
	}, [userAccountProfile, getUserProfile])

  useEffect(() => {
		if (!userSettings) {
			getUserSettings({emailSettingsRequest: false, bannerSettingsRequest: true, checkDemoSignupUserRequest: false})
		} else if (userSettings && userPrivileges.subscriptionReminder === true) {
      const requiredBannerType = isOrgAdmin ? "org-admin-subscribe-reminder" : ""
     
      if (requiredBannerType) {
        const isDismissedBanner = checkIfDismissedBanner(userSettings, requiredBannerType)

        if (!!isDismissedBanner) {
          setShowAlertBanner(false)
        } else {
          setShowAlertBanner(true)
        }
      } 
    }

	}, [userSettings, getUserSettings, isOrgAdmin, userPrivileges.subscriptionReminder])


  useEffect(() => {
    if (allUserDsrs) {
      const { isVerified, isNoOrgUser, foundDsrsTotal, displayedDsrs, disableDraftDisplay } = allUserDsrs || {};
      const isEmptyState = displayedDsrs ? false : true;

      if (isNoOrgUser === true) {
        redirectToNewPage('/received-rooms')
      } else {
        setLocalState((localState) => ({
          ...localState,
          isLoading: false,
          // isMobileView,
          isVerified,
          isEmptyState,
          displayedDsrs,
          foundDsrsTotal
        }))

        if (!!disableDraftDisplay) {
          setDisableDraftDsrDisplay(true)
        }

        resetFetchVaultData()
      }
    }
  }, [allUserDsrs, resetFetchVaultData])


  const toggleFilter = (requestCategory, pageNumber, sortRequest) => {
    if (requestCategory) {

      setLocalState({
        ...localState,
        requestCategory,
        pageNumber,
        displayedDsrs: ""
      })

      fetchVaultData({
        requestCategory,
        sortRequest: sortRequest ? sortRequest : "default",
        pageNumber: pageNumber ? pageNumber : 1,
        itemsPerPage: parseInt(localState.itemsPerPage)
      })

      setDisplaySearchResults(false)
    } else if (!requestCategory) {
      setLocalState({
        ...localState,
        requestCategory: "initialize-prospectors",
        pageNumber
      })

    }
  }

  const onTextSearch = ({query, pickedSortRequest, pickedDsrStatus, pickedDsrOwnership }) => {
    let sortRequest = "default";
    let requestCategory = "default";
    const requiredStatus = pickedDsrStatus ? pickedDsrStatus : chosenStatusTab;

   
    switch (pickedSortRequest? pickedSortRequest : chosenSortValue) {
      case ('Latest'): {
        sortRequest = "latest"
        break;
      }
      case ('Room name (A - Z)'): {
        sortRequest = "a-to-z"
        break;
      }
      case ('Room name (Z - A)'): {
        sortRequest = "z-to-a"
        break;
      }
      default: {
        break;
      }
      
    }
  

    switch (pickedDsrOwnership ? pickedDsrOwnership : chosenFilterValue) {
      case ("All Prospectors"): {
        if (requiredStatus === "published") {
          requestCategory = "search-host-team-active-dsr";
        } else if (requiredStatus === "drafts") {
          requestCategory = "search-host-team-draft-dsr";
        }
        break;
      }
      case ("Private"): {
        if (requiredStatus === "published") {
          requestCategory = "search-user-owned-active-dsr";
        } else if (requiredStatus === "drafts") {
          requestCategory = "search-user-owned-draft-dsr";
        }
        break;
      }
      default: {

        break;
      }
    }

    const searchDsrMetadata = {
      queryInput: query,
      requestCategory,
      sortRequest
    }
    
    setDisplaySearchResults(true)
    prospectorDsrSearch(searchDsrMetadata)

  }

  const onClickCreateDsr = () => {
    setLocalState({
      ...localState,
      showCreateDsrModal: true
    })
  }

  const handleCloseCreateDsrModal = () => {
    setLocalState({
      ...localState,
      showCreateDsrModal: false
    })
  }

  const resetAllFilterValues = () => {
    setChosenFilterValue("All Prospectors")
    setChosenSortValue("Latest")
  }

  const renderVaultPageDisplay = () => {
    const { isLoading, foundDsrsTotal, itemsPerPage, pageNumber, requestCategory,
      isOnboarded, isEmptyState, displayedDsrs } = localState;


    if (isLoading === true) {
      return <PageLoader />
    }
    else if (isLoading === false) {
      if (isOnboarded === false) {
        return <Redirect to={'/welcome'} />
      }

      if (isEmptyState === false || isEmptyState === null) {


        if (displaySearchResults === true) {
          return (
            <React.Fragment>
              <VaultSearchToolbar
                onTextSearch={onTextSearch}
                toggleFilter={toggleFilter}
                onClickCreateDsr={onClickCreateDsr}
                showSortDropdown={showSortDropdown}
                setShowSortDropdown={setShowSortDropdown}
                chosenSortValue={chosenSortValue}
                setChosenSortValue={setChosenSortValue}
                showFilterDropdown={showFilterDropdown}
                setShowFilterDropdown={setShowFilterDropdown}
                chosenFilterValue={chosenFilterValue}
                setChosenFilterValue={setChosenFilterValue}
                chosenStatusTab={chosenStatusTab}
                setChosenStatusTab={setChosenStatusTab}
                searchInputValue={searchInputValue}
                setSearchInputValue={setSearchInputValue}
                isMobileView={isMobileView}
                disableDraftDsrDisplay={disableDraftDsrDisplay}
              />
              <Box className="display-dsr-container">
                <VaultDisplaySearchResultsModule
                  vaultSearchResults={prospectorSearchResults}
                  itemsPerPage={itemsPerPage}
                  currentPageNumber={pageNumber}
                  isMobileView={isMobileView}
                  vaultSearchIsProcessing={vaultSearchIsProcessing}
                  ///
                  chosenSortValue={chosenSortValue}
                  chosenFilterValue={chosenFilterValue}
                  chosenStatusTab={chosenStatusTab}
                  resetAllFilterValues={resetAllFilterValues}
                  ///
                  toggleFilter={toggleFilter}
                  setDisplaySearchResults={setDisplaySearchResults}
                  setSearchInputValue={setSearchInputValue}
                  isPdsrDisplayMode={true}
                />
              </Box>
            </React.Fragment>
          )
        } else {
          return (
            <React.Fragment>
              <VaultToolbar
                displaySearchResults={displaySearchResults}
                toggleFilter={toggleFilter}
                onTextSearch={onTextSearch}
                onClickCreateDsr={onClickCreateDsr}
                pageNumber={localState.pageNumber}
                requestCategory={localState.requestCategory}
                showSortDropdown={showSortDropdown}
                setShowSortDropdown={setShowSortDropdown}
                chosenSortValue={chosenSortValue}
                setChosenSortValue={setChosenSortValue}
                showFilterDropdown={showFilterDropdown}
                setShowFilterDropdown={setShowFilterDropdown}
                chosenFilterValue={chosenFilterValue}
                setChosenFilterValue={setChosenFilterValue}
                chosenStatusTab={chosenStatusTab}
                setChosenStatusTab={setChosenStatusTab}
                searchInputValue={searchInputValue}
                setSearchInputValue={setSearchInputValue}
                isMobileView={isMobileView}
                disableDraftDsrDisplay={disableDraftDsrDisplay}
              />
              <Box className="display-dsr-container">
                <VaultDisplayDsrModule
                  requestCategory={requestCategory}
                  displayedDsrs={displayedDsrs}
                  isMobileView={isMobileView}
                  vaultIsProcessing={vaultIsProcessing}
                  foundDsrsTotal={foundDsrsTotal}
                  itemsPerPage={itemsPerPage}
                  toggleFilter={toggleFilter}
                  currentPageNumber={pageNumber}
                  vaultSearchIsProcessing={vaultSearchIsProcessing}
                  chosenSortValue={chosenSortValue}
                  isPdsrDisplayMode={true}
                  showEmptyProspectDsrState={!!disableDraftDsrDisplay && true}
                  onClickCreateDsr={onClickCreateDsr}
                />
              </Box>
            </React.Fragment>
          )
        }




      }
    }
  }


  return (
    <React.Fragment>
      {showAlertBanner && <DynamicAppBannerDisplay bannerType={offerFreeTrial ? "free-trial-subscribe-nudge" : "subscription-nudge"} freeTrialCode={freeTrialCode} />}
      <Box>
        {renderVaultPageDisplay()}
      </Box>
      <Box>
        <CreateDsrModal
          showModal={localState.showCreateDsrModal}
          handleCloseCreateDsrModal={handleCloseCreateDsrModal}
          isMobileView={isMobileView}
          formType={"prospect-dsr"}
        />
        <LoggedOutConfirmationModal />
      </Box>
    </React.Fragment>

  )
}

function VaultToolbar({ toggleFilter, onTextSearch, onClickCreateDsr, requestCategory, pageNumber,
  showSortDropdown, setShowSortDropdown, chosenSortValue, setChosenSortValue,
  showFilterDropdown, setShowFilterDropdown, chosenFilterValue, setChosenFilterValue,
  chosenStatusTab, setChosenStatusTab,
  searchInputValue, setSearchInputValue,
  isMobileView, disableDraftDsrDisplay
}) {
  const { isOpen, onToggle, onClose } = useDisclosure()
  const ownerDropdownRef = useRef(null);
  const sortOrderDropdownRef = useRef(null);

  const isDraftFilter = (chosenStatusTab === 'drafts') ? true : false;
  const isPublishedFilter = (chosenStatusTab === 'published' || chosenStatusTab === '') ? true : false;
  const [isError, setIsError] = useState(false);
  const [isLargerThan1000] = useMediaQuery('(min-width: 1000px)')

  useOutsideClick({
    ref: ownerDropdownRef,
    handler: () => setShowFilterDropdown(false),
  })

  useOutsideClick({
    ref: sortOrderDropdownRef,
    handler: () => setShowSortDropdown(false),
  })


  const handleChange = (event) => {
    setSearchInputValue(event.target.value)
    isError && setIsError(false)
  }

  const handleClearSearchText = () => {
    setSearchInputValue("")
    toggleFilter("initialize-prospectors", 1, "default")
    isError && setIsError(false)
  }

  const handleEnter = (event) => {
    if (event.key === 'Enter' && event.target.name === "vault-page-search-bar") {
      if (searchInputValue.trim().length > 2) {
        onTextSearch({query: searchInputValue})
      } else {
        setIsError(true)
        onToggle()
      }
    }
  }

  const handlePickSortOption = (option) => {
    let sortRequest = "default";
    switch (option) {
      case ('Latest'): {
        sortRequest = "latest"
        break;
      }
      case ('Room name (A - Z)'): {
        sortRequest = "a-to-z"
        break;
      }
      case ('Room name (Z - A)'): {
        sortRequest = "z-to-a"
        break;
      }
      default: {
        break;
      }
    }
    if (option) {
      setChosenSortValue(option)
      toggleFilter(requestCategory, pageNumber, sortRequest)
    }
  }

  const handlePickDsrStatusTab = (pickedTab) => {

    if (pickedTab) {

      let sortRequest = "default";
      let requestCategory = "default";

      switch (chosenSortValue) {
        case ('Latest'): {
          sortRequest = "latest"
          break;
        }
        case ('Room name (A - Z)'): {
          sortRequest = "a-to-z"
          break;
        }
        case ('Room name (Z - A)'): {
          sortRequest = "z-to-a"
          break;
        }
        default: {
          break;
        }
      }

      switch (pickedTab) {
        case ('published'): {
          requestCategory = "active-pdsrs"
          break;
        }
        case ('drafts'): {
          requestCategory = "draft-pdsrs"
          break;
        }
        default: {
          break;
        }
      }

      setChosenStatusTab(pickedTab)
      toggleFilter(requestCategory, pageNumber, sortRequest)
    }
  }

  const handlePickFilterOption = (filterOption) => {
    if (filterOption) {
      let sortRequest = "default";
      let requestCategory = "default";

      switch (chosenSortValue) {
        case ('Latest'): {
          sortRequest = "latest"
          break;
        }
        case ('Room name (A - Z)'): {
          sortRequest = "a-to-z"
          break;
        }
        case ('Room name (Z - A)'): {
          sortRequest = "z-to-a"
          break;
        }
        default: {
          break;
        }
      }

      // switch (chosenFilterValue) { // Check if this logic works for other pages
      switch (filterOption) {
        case ("All Prospectors"): {
          if (chosenStatusTab === "published") {
            requestCategory = "all-org-prospectors-active";
          } else if (chosenStatusTab === "drafts") {
            requestCategory = "all-org-prospectors-draft";
          }

          break;
        }
        case ("Private"): {

          if (chosenStatusTab === "published") {

            requestCategory = "private-user-prospectors-active";
          } else if (chosenStatusTab === "drafts") {

            requestCategory = "private-user-prospectors-draft";
          }
          break;
        }
        default: {

          break;
        }
      }

      setChosenFilterValue(filterOption)
      toggleFilter(requestCategory, pageNumber, sortRequest)
    }

  }

  return (
    <>
      {!isLargerThan1000 ?
        <VaultMobileNavMenu currentMode="prospectors" />
        :
        <Flex w='100%' justify='space-between' align='center' flexDirection={['column', 'row']}>
          <Box>
          <Box className="page-title">Prospectors</Box>
          <Box fontSize='0.8em'> Let anyone discover & contact you about your products & services</Box> 
          </Box>

          <Box whiteSpace='nowrap' >
            <Flex className='create-new-room-button'
              onClick={() => onClickCreateDsr()}>
              <Box className='create-new-room-button__button-text'>New Prospector</Box>
              <Box as={IoMdAddCircle} boxSize='1.5em' className='create-new-room-button__add-icon' />
            </Flex>
          </Box>
        </Flex>
      }

      <Flex justifyContent='center' style={{ gap: '10px' }} flexDirection={['column', 'column', 'row', 'row']} className='menu-bar'>
        <Flex justifyContent={['center', 'center', 'flex-start', 'flex-start']} align='center' w='100%' mb={isMobileView && '1em'}>
          <Box
            className={isPublishedFilter ? "chosen-category-tab" : "unchosen-category-tab"}
            onClick={() => !isPublishedFilter && handlePickDsrStatusTab('published')}>Published</Box>
             {disableDraftDsrDisplay ? 
            <Box className="disabled-tab">
              Drafts
            </Box>
            :
          <Box
            className={isDraftFilter ? "chosen-category-tab" : "unchosen-category-tab"}
            onClick={() => !isDraftFilter && handlePickDsrStatusTab('drafts')}>Drafts</Box>
             }
        </Flex>

        <Flex w='100%' justify='flex-end' style={{ gap: '8px' }}  flexDirection={['column', 'column', 'row', 'row']} align='center' >

          <Flex className='filter-menu' onClick={() => { setShowSortDropdown(!showSortDropdown); setShowFilterDropdown(false) }} ref={sortOrderDropdownRef}>
            <Flex>
              <Box mr='0.5em' color='gray.500' fontSize='0.875em' fontWeight='400' lineHeight='1.375em'>Sort By:</Box>
              <Box className='chosen-option-text-display'>{chosenSortValue}</Box>
            </Flex>

            <Box as={GoChevronDown} boxSize="0.75em" color="gray.300" />

            {/* Show Dropdown bar here */}
            <Box className='filter-menu__dropdown' display={showSortDropdown ? 'inline-block' : 'none'}>
              <React.Fragment>
                <Box className={(chosenSortValue !== 'Latest') ? 'filter-menu__dropdown-option-display' : 'filter-menu__dropdown-chosen-option-display'} onClick={() => (chosenSortValue !== 'Latest') && handlePickSortOption('Latest')}>Latest</Box>
                <Box className={(chosenSortValue !== 'Room name (A - Z)') ? 'filter-menu__dropdown-option-display' : 'filter-menu__dropdown-chosen-option-display'} onClick={() => (chosenSortValue !== 'Room name (A - Z)') && handlePickSortOption('Room name (A - Z)')}>Room name (A - Z)</Box>
                <Box className={(chosenSortValue !== 'Room name (Z - A)') ? 'filter-menu__dropdown-option-display' : 'filter-menu__dropdown-chosen-option-display'} onClick={() => (chosenSortValue !== 'Room name (Z - A)') && handlePickSortOption('Room name (Z - A)')}>Room name (Z - A)</Box>
              </React.Fragment>
            </Box>

          </Flex>

          <Flex className='filter-menu' ml={['0', '0', '0', '1em']} onClick={() => { setShowFilterDropdown(!showFilterDropdown); setShowSortDropdown(false) }} ref={ownerDropdownRef}>
            <Flex >
              <Box mr='0.5em' color='gray.500' fontSize='0.875em' fontWeight='400' lineHeight='1.375em'>Show:</Box>
              <Box className='chosen-option-text-display'>{chosenFilterValue}</Box>
            </Flex>

            <Box as={GoChevronDown} boxSize="0.75em" color="gray.300" />

            {/* Show Dropdown bar here */}
            <Box className='filter-menu__dropdown' display={showFilterDropdown ? 'inline-block' : 'none'}>

              <Box className={(chosenFilterValue !== 'All Prospectors') ? 'filter-menu__dropdown-option-display' : 'filter-menu__dropdown-chosen-option-display'} onClick={() => (chosenFilterValue !== 'All Prospectors') && handlePickFilterOption('All Prospectors')}>All Prospectors</Box>
              <Box className={(chosenFilterValue !== 'Private') ? 'filter-menu__dropdown-option-display' : 'filter-menu__dropdown-chosen-option-display'} onClick={() => (chosenFilterValue !== 'Private') && handlePickFilterOption('Private')}>Private</Box>
            </Box>
          </Flex>

          {/* Search bar */}
          <Popover isOpen={isOpen} onClose={onClose}>
            <PopoverTrigger isOpen={isError}>
              <Flex w='100%' justify='space-between' minWidth='max-content' maxWidth='256px'>
                <InputGroup
                  size="2em"
                  m="0 1em"
                  width="100%"
                  maxWidth='20em'>
                  <InputLeftAddon roundedTopLeft='0.25em' roundedBottomLeft='0.25em'>
                    <Image src={SearchIcon} mx="0.75em" />
                  </InputLeftAddon>
                  <Input
                    name="vault-page-search-bar"
                    value={searchInputValue}
                    onChange={handleChange}
                    onKeyDown={handleEnter}
                    placeholder="Search"
                    padding="0.5em"
                    rounded='0.25em'
                    fontSize='0.875em'
                    color={isError ? '#ff0000' : ''}
                  />

                  {searchInputValue && <InputRightElement children={<Box as={IoIosClose} cursor='pointer' boxSize='1em'
                    onClick={() => {
                      handleClearSearchText("")
                      isError && setIsError(false)
                    }} />} top="0" bottom="0" right="8px" m="auto" />
                  }
                </InputGroup>
              </Flex>
            </PopoverTrigger>
               
            <PopoverContent zIndex={4}>
              <PopoverArrow />
              <PopoverHeader fontSize='0.875em'>Search with 3 or more characters</PopoverHeader>
            </PopoverContent>
          </Popover>
        </Flex>
      </Flex>
    </>

  )

}

function VaultSearchToolbar({ onTextSearch, toggleFilter, onClickCreateDsr,
  showSortDropdown, setShowSortDropdown, chosenSortValue, setChosenSortValue,
  showFilterDropdown, setShowFilterDropdown, chosenFilterValue, setChosenFilterValue,
  chosenStatusTab, setChosenStatusTab,
  searchInputValue, setSearchInputValue,
  isMobileView, disableDraftDsrDisplay
}) {
  const { isOpen, onToggle, onClose } = useDisclosure()
  const ownerDropdownRef = useRef(null);
  const sortOrderDropdownRef = useRef(null);

  const isDraftFilter = (chosenStatusTab === 'drafts') ? true : false;
  const isPublishedFilter = (chosenStatusTab === 'published' || chosenStatusTab === '') ? true : false;
  const [isError, setIsError] = useState(false);
  const [isLargerThan1000] = useMediaQuery('(min-width: 1000px)')

  useOutsideClick({
    ref: ownerDropdownRef,
    handler: () => setShowFilterDropdown(false),
  })

  useOutsideClick({
    ref: sortOrderDropdownRef,
    handler: () => setShowSortDropdown(false),
  })


  const handleChange = (event) => {
    setSearchInputValue(event.target.value)
    isError && setIsError(false)
  }

  const handleClearSearchText = () => {
    setSearchInputValue("")
    toggleFilter("initialize-prospectors", 1, "default")
    isError && setIsError(false)
  }

  const handleEnter = (event) => {
    if (event.key === 'Enter' && event.target.name === "vault-search-toolbar-two") {
      if (searchInputValue.trim().length > 2) {
        onTextSearch({query: searchInputValue})
        onClose()
      } else {
        // setIsError(true)
        onToggle()
      }

    }
  }

  const handlePickSortOption = (option) => {
    if (option) {
      setChosenSortValue(option)
      onTextSearch({query: searchInputValue, pickedSortRequest: option})
    }
  }

  const handlePickDsrStatusTab = (pickedTab) => {
    if (pickedTab) {
      setChosenStatusTab(pickedTab)
      onTextSearch({query: searchInputValue, pickedDsrStatus: pickedTab})
    }
  }

  const handlePickFilterOption = (filterOption) => {

    if (filterOption) {
      setChosenFilterValue(filterOption)
      onTextSearch({query: searchInputValue, pickedDsrOwnership: filterOption})
    }

  }

  return (
    <>
      {!isLargerThan1000 ?
        <VaultMobileNavMenu currentMode="prospectors" />
        :
        <Flex w='100%' justify='space-between' align='center'>

          <Box className="page-title">Prospectors</Box>

          <Box whiteSpace='nowrap' >
            <Flex className='create-new-room-button'
              onClick={() => onClickCreateDsr()}>
              <Box className='create-new-room-button__button-text'>New Room</Box>
              <Box as={IoMdAddCircle} boxSize='1.5em' className='create-new-room-button__add-icon' />
            </Flex>
          </Box>

        </Flex>
      }

      <Flex justifyContent='center' flexDirection={['column', 'column', 'row', 'row']} className='menu-bar'>
        <Flex justifyContent={['center', 'center', 'flex-start', 'flex-start']} align='center' w='100%' mb={isMobileView && '1em'} >
          <Box
            className={isPublishedFilter ? "chosen-category-tab" : "unchosen-category-tab"}
            onClick={() => !isPublishedFilter && handlePickDsrStatusTab('published')}>Published</Box>
             {disableDraftDsrDisplay ? 
            <Box className="disabled-tab">
              Drafts
            </Box>
            :
          <Box
            className={isDraftFilter ? "chosen-category-tab" : "unchosen-category-tab"}
            onClick={() => !isDraftFilter && handlePickDsrStatusTab('drafts')}>Drafts</Box>
             }
        </Flex>

        <Flex gap={10} w='100%' justify='space-around' flexDirection={['column', 'column', 'row', 'row']} align='center'>

          <Flex className='filter-menu' onClick={() => { setShowSortDropdown(!showSortDropdown); setShowFilterDropdown(false) }} ref={sortOrderDropdownRef}>
            <Flex>
              <Box mr='0.5em' color='gray.500' fontSize='0.875em' fontWeight='400' lineHeight='1.375em'>Sort By:</Box>
              <Box className='chosen-option-text-display'>{chosenSortValue}</Box>
            </Flex>

            <Box as={GoChevronDown} boxSize="0.75em" color="gray.300" />

            {/* Show Dropdown bar here */}
            <Box className='filter-menu__dropdown' display={showSortDropdown ? 'inline-block' : 'none'}>
              <React.Fragment>
                <Box className={(chosenSortValue !== 'Latest') ? 'filter-menu__dropdown-option-display' : 'filter-menu__dropdown-chosen-option-display'} onClick={() => (chosenSortValue !== 'Latest') && handlePickSortOption('Latest')}>Latest</Box>
                <Box className={(chosenSortValue !== 'Room name (A - Z)') ? 'filter-menu__dropdown-option-display' : 'filter-menu__dropdown-chosen-option-display'} onClick={() => (chosenSortValue !== 'Room name (A - Z)') && handlePickSortOption('Room name (A - Z)')}>Room name (A - Z)</Box>
                <Box className={(chosenSortValue !== 'Room name (Z - A)') ? 'filter-menu__dropdown-option-display' : 'filter-menu__dropdown-chosen-option-display'} onClick={() => (chosenSortValue !== 'Room name (Z - A)') && handlePickSortOption('Room name (Z - A)')}>Room name (Z - A)</Box>
              </React.Fragment>
            </Box>

          </Flex>

          <Flex className='filter-menu' ml={['0', '0', '0', '1em']} onClick={() => { setShowFilterDropdown(!showFilterDropdown); setShowSortDropdown(false) }} ref={ownerDropdownRef}>
            <Flex >
              <Box mr='0.5em' color='gray.500' fontSize='0.875em' fontWeight='400' lineHeight='1.375em'>Show:</Box>
              <Box className='chosen-option-text-display'>{chosenFilterValue}</Box>
            </Flex>

            <Box as={GoChevronDown} boxSize="0.75em" color="gray.300" />

            {/* Show Dropdown bar here */}
            <Box className='filter-menu__dropdown' display={showFilterDropdown ? 'inline-block' : 'none'}>

              <Box className={(chosenFilterValue !== 'All Prospectors') ? 'filter-menu__dropdown-option-display' : 'filter-menu__dropdown-chosen-option-display'} onClick={() => (chosenFilterValue !== 'All Prospectors') && handlePickFilterOption('All Prospectors')}>All Prospectors</Box>
              <Box className={(chosenFilterValue !== 'Private') ? 'filter-menu__dropdown-option-display' : 'filter-menu__dropdown-chosen-option-display'} onClick={() => (chosenFilterValue !== 'Private') && handlePickFilterOption('Private')}>Private</Box>
            </Box>
          </Flex>

          {/* Search bar */}
     
             
              <Popover
          isOpen={isOpen}
          onClose={onClose}
          >
            <PopoverTrigger isOpen={isError}>
            <Flex w='100%' justify='space-between' minWidth='max-content' maxWidth='256px'>
                <InputGroup
                  size="2em"
                  m="0 1em"
                  width="100%"
                  maxWidth='20em'>
                  <InputLeftAddon roundedTopLeft='0.25em' roundedBottomLeft='0.25em'>
                    <Image src={SearchIcon} mx="0.75em" />
                  </InputLeftAddon> <Input
                    name="vault-search-toolbar-two"
                    value={searchInputValue}
                    onChange={handleChange}
                    onKeyDown={handleEnter}
                    placeholder="Search"
                    padding="0.5em"
                    rounded='0.25em'
                    fontSize='0.875em'
                    color={isError ? '#ff0000' : ''}
                  />
                  {searchInputValue && <InputRightElement children={<Box as={IoIosClose} cursor='pointer' boxSize='1em' onClick={() => {
                    handleClearSearchText()
                    isError && setIsError(false)
                    }} />} top="0" bottom="0" right="8px" m="auto" />}
                </InputGroup>
              </Flex>
            </PopoverTrigger>
            <PopoverContent zIndex={4}>
              <PopoverArrow />
              <PopoverHeader fontSize='0.875em'>Search with 3 or more characters</PopoverHeader>
            </PopoverContent>
          </Popover>
        </Flex>
      </Flex>
    </>

  )

}

const mapStateToProps = (state) => ({
  prospectorSearchResults: state.prospectorSearchResults.results.data,
  vaultSearchIsProcessing: state.prospectorSearchResults.isLoading,
  isMobileView: state.mobileViewMode.isMobileView,
  userPrivileges: state.userPrivileges.results,
  userAccountProfile: state.userProfile.accountUserProfile,
  isOrgAdmin:  state.isOrgAdmin.results,
  userSettings: state.userSettings.results.data
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      prospectorDsrSearch,
      getUserProfile,
      getUserSettings
    },
    dispatch,
  )

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VaultProspectorPage))
