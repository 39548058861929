import React from 'react';

import { Box, Divider, Flex } from '@chakra-ui/react';
import WarningTextMessage from '../../../global/components/helper-components/components/WarningTextMessage';
import LaunchdeckButton from '../../../global/components/helper-components/components/LaunchdeckButton';
import FormTextInput from '../../../forms/TextInputField/components/FormTextInput';


export default function PublicDsrJoinRoomCreateUserForm({ handleCreateRoomJoiner, onChangeHandler, showCreatedPasswordInputError, showNameInputError }) {

	return (
		<Box w='100%'>
			<Box fontSize='1em' color='brandDark.500' fontWeight='600' my='1em' textAlign='center'>
				Introduce yourself
			</Box>

			<Box fontSize='0.875em' color='gray.500' my='1em' textAlign='center'>
				Help your team know how to address you
			</Box>

			<Box w="100%" align="center">
				<Box px='1em' mt='1em'>

				<Box mt='1em' w='100%'>
						<FormTextInput
							label='Your name *'
							name={'joiner-name-input'}
							placeholder={'e.g. John Anderson'}
							onChange={onChangeHandler}
							mb='0.2em'
							borderColor={showNameInputError ? 'pink.500' : ""}
						/>
						<WarningTextMessage errorLogic={showNameInputError} message='Help Room members know how to address you' />
					</Box>

					<Box mt='1em' w='100%'>
						<FormTextInput
							label='Create a password *'
							name={'created-password-input'}
							placeholder={'Password to help you enter Rooms easily in the future'}
							onChange={onChangeHandler}
							mb='0.2em'
							borderColor={showCreatedPasswordInputError ? 'pink.500' : ""}
							type="password"
						/>
						<WarningTextMessage errorLogic={showCreatedPasswordInputError} message='Please consider a longer password' />
					</Box>

					<Flex mt='1em' w='100%' justify='flex-end' color='pink.500' fontSize='0.75em'>
						* Required
					</Flex>
					<Divider my='0.5em' />

					<Box mt='1em' w='100%'>
						<FormTextInput
							label='Company name'
							name={'company-name-input'}
							placeholder={'e.g. Acme Inc'}
							onChange={onChangeHandler}
							mb='0.2em'
						/>
					</Box>

					<Box mt='1em' w='100%'>
						<FormTextInput
							label='Company title'
							name={'company-role-input'}
							placeholder={'e.g. Purchasing Director'}
							onChange={onChangeHandler}
							mb='0.2em'
						/>
					</Box>
				</Box>

				<Flex w='100%' mt='2em' px='1em' py='0.625em'>
					<Flex justify='flex-end' w='100%'>
						<LaunchdeckButton bg='brand.500' color='gray.50' w='100%' label='Enter Room' onClick={() => handleCreateRoomJoiner()} />
					</Flex>
				</Flex>

			</Box>
		</Box>

	);
};


