import React from 'react'

import {Box , Flex, Image, Modal, ModalBody, ModalContent, ModalOverlay} from '@chakra-ui/react'
import '../styles/dsr-display.scss'
import logo from '../../global/styles/images/launchdeck_logo.svg';
import LaunchdeckCloseButton from '../../global/components/helper-components/components/LaunchdeckCloseButton';


function DsrPreviewPageActionModal(props) {
  const { handleCloseModal } = props || {};

  return (
    <Modal isOpen={true} closeOnOverlayClick={false} isCentered={true} >
      <ModalOverlay className="modal-background" />
      <ModalContent rounded='0.25em' minW={['90%', '500px']} maxW='700px'>
        <ModalBody px='0em'>
          <Flex direction='column' justify='space-around' alignItems='center' w='100%' p='1em'>
            <Flex justify='flex-end' w='100%'>

              <Box>
                <LaunchdeckCloseButton handleCloseModal={() => handleCloseModal()} />
              </Box>
            </Flex>

            <React.Fragment>
              <Box w='100%'>
                <Flex direction='column' mt='1em' justify='center' alignItems='center' w='100%'>
                  <Flex w='100%' justify='center'>
                    <Image src={logo} alt="Launchdeck Logo" width='50%' minWidth='1em' maxWidth='10em' mb='2em' />
                  </Flex>
                  <Flex mb='2em' fontSize='1em' fontWeight='500'>
                    You're currently in Preview mode
                  </Flex>
                </Flex>
              </Box>
            </React.Fragment>

          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default DsrPreviewPageActionModal
