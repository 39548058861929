import { SET_DSR_DRAFT_PROPERTIES } from '../../actions/dsr-actions-types'

const INITIAL_STATE_DSR_DRAFT_PROPERTIES = { properties: '' }

export function draftDsrPropertiesReducer(state = INITIAL_STATE_DSR_DRAFT_PROPERTIES, action) {

    switch (action.type) {
        case SET_DSR_DRAFT_PROPERTIES:
            return { properties: action.payload };
        default:
            return state;
    }
}