import React from 'react';
import moment from 'moment';
import 'moment-timezone';


//Format Time Display
export function formatTimestamp(rawTimestamp) {
  const jsTimestamp = new Date(rawTimestamp)

  return jsTimestamp.toDateString().slice(4);
}

export function getCurrentDateEs6() {
  return new Date().toLocaleDateString('en-GB', {  
    day:   'numeric',
    month: 'short',
    year:  'numeric',
  });
}

export function getCurrentTimeEs6() {
  return new Date().toLocaleTimeString(undefined, {
    hour:   '2-digit',
    minute: '2-digit',
    second: '2-digit',
	});
}

export function formatDisplayLocalDateTime(rawTimestamp) {
  if (!rawTimestamp) {
    return null;
  }

  const formattedTimestamp = new Date(rawTimestamp);

  const formattedDate = formattedTimestamp.toLocaleDateString('en-US', {  
    day:   'numeric',
    month: 'short',
    year:  'numeric',
  });

  const formattedTime = formattedTimestamp.toLocaleTimeString('en-US', {hour: '2-digit', minute:'2-digit'});

  return (formattedDate + ', ' + formattedTime)

}

export function formatTimestampPlusZone(rawTimestamp) {
  const jsTimestamp = new Date(rawTimestamp);

  return jsTimestamp.toTimeString()

}

// export function formatTimezone(rawTimestamp) {
//   if (!rawTimestamp) {
//     return null;
//   }

//   let momentTimestamp = moment(rawTimestamp);
//   let timestampWithTimezone = momentTimestamp.tz('Europe/London').format('HH:mm z');
//   return <span className="display-timezone-format">{'    ' + timestampWithTimezone}</span>;
// }

export function formatTimezoneToGmt(rawTimestamp) {
  if (!rawTimestamp) {
    return null;
  }

  let momentTimestamp = moment(rawTimestamp);
  let timestampWithTimezone = momentTimestamp.tz('Europe/London').format('HH:mm z'); 
  return <span>{'    ' + timestampWithTimezone}</span>;
}

export function displayElapsedDurationSinceTimestamp(timeStamp) {
  if (timeStamp) {
    return moment(timeStamp).fromNow()
  } else return "--"
}

export function formatSecondsValueDisplay(valueInSeconds) {
  const hours = Math.floor(valueInSeconds / 3600);
  const minutes = Math.floor((valueInSeconds % 3600) / 60);
  const remainingSeconds = valueInSeconds % 60;

  if (valueInSeconds <=0) {
    return '-'
  }

  if (hours < 1) {
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(remainingSeconds).padStart(2, '0');
  
    return `${formattedMinutes}min ${formattedSeconds}s`;
  } else if (minutes < 1) {
    return `Less than 1 min`;
  } else {
    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(remainingSeconds).padStart(2, '0');
  
    return `${formattedHours}hr ${formattedMinutes}min ${formattedSeconds}s`;
  }
  
}

export function displayInLocalTime(timestamp) {

  if (timestamp) {
    const date = new Date(timestamp);

    const options = {
      // weekday: 'long',
      // year: 'numeric',
      // month: 'long',
      // day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      // second: 'numeric',
      // timeZoneName: 'short',
    };
    
     return date.toLocaleString(undefined, options);
  } return null

}
