import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { Box, Flex } from '@chakra-ui/react';
import LaunchdeckButton from '../../global/components/helper-components/components/LaunchdeckButton';
import WarningTextMessage from '../../global/components/helper-components/components/WarningTextMessage';
import { validateEmail } from '../../global/helpers/global-helpers';
import FormTextInput from '../../forms/TextInputField/components/FormTextInput';
import PageLoader from '../../global/components/helper-components/components/PageLoader';

function DsrReturningLoginForm(props) {
	const { defaultEmail, handleSubmitLoginRequest, loginOutcome, isProcessingLogin } = props || {};

	// Inputs
	const [emailInput, setEmailInput] = useState(defaultEmail);
	const [passwordInput, setPasswordInput] = useState('');

	const [showInputError, setShowInputError] = useState(false);
	const [showValidationError, setShowValidationError] = useState(false);
	const [showLoginError, setShowLoginError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("")


	useEffect(() => {
		if (loginOutcome) {
			const { loginError, message } = loginOutcome || {}
			
			if (loginError) {
				setShowLoginError(true)
				setErrorMessage(message)
				setPasswordInput("")
			}
		}
	}, [loginOutcome])

	// function onPressEnterAndLogin(e) {
	// 	if (e.key === 'Enter') {
	// 		handleSubmit();
	// 	}
	// }

	const onChangeHandler = (event) => {

		switch (event.target.name) {
			case ('email'): {
				if (showValidationError) {
					setShowValidationError(false)
				}

				setEmailInput(event.target.value.trim())
				break;
			}
			case ('password-input'): {
				if (showInputError) {
					setShowInputError(false)
				}

				if (showLoginError) {
					setShowLoginError(false)
				}

				setPasswordInput(event.target.value.trim())
				break;
			}
			default: {
				break;
			}
		}
	};

	const handleSubmit = () => {
		const isValidEmail = validateEmail(emailInput);
		const isValidPassword = (passwordInput && passwordInput.length >= 6) ? true : false

		if (!isValidEmail) {
			setShowValidationError(true)
		} else if (!isValidPassword) {
			setShowInputError(true)
		} else if (isValidEmail && isValidPassword) {
			handleSubmitLoginRequest(emailInput, passwordInput);
		}

	}

	return (
		<Box w="100%" align="center">
			<Box px='1em' mt='1em'>

				{isProcessingLogin ? <PageLoader /> :
					<React.Fragment>
						<Box mt='1em' w='100%'>
							<FormTextInput
								label={'Email'}
								name={'email'}
								placeholder={'me@email.com'}
								onChange={onChangeHandler}
								defaultValue={emailInput}
								mb='0.2em'
							/>
							<WarningTextMessage errorLogic={showValidationError} message='Please provide a valid email' />
						</Box>

						<Box mt='1em'>

							<FormTextInput
								name={'password-input'}
								label={'Password'}
								onChange={onChangeHandler}
								mb='0.2em'
								borderColor={showInputError ? 'pink.500' : ""}
								type='password'
							/>
							<WarningTextMessage errorLogic={showInputError} message=' Please set a secure password that contains more than 8 characters' />
						</Box>
					</React.Fragment>
				}
			</Box>

			{/* // If there is login error */}
			<Flex w='100%' justify='center' my='1em'>
				<WarningTextMessage errorLogic={showLoginError} message={`${errorMessage}`} />
			</Flex>

			<Flex w='100%' mt='1.5em' px='1em' py='0.625em'>
				<Flex justify='flex-end' w='100%'>
					<LaunchdeckButton bg={isProcessingLogin ? 'gray.300' : 'brand.500'} color={isProcessingLogin ? 'gray.500': 'gray.50'} w='100%' label='Enter Room' dummy={isProcessingLogin} onClick={() => !isProcessingLogin && handleSubmit()} />
				</Flex>
			</Flex>

			<Flex mt='2em' w='100%' justify='center' color='gray.500' fontSize={['0.5em', '0.5em', '0.5em', '0.7em']}>
				<Link to="/reset-password">Forgot Password?</Link>
			</Flex>

		</Box>
	)
}


function mapStateToProps(state) {
	return {
		loginOutcome: state.auth.data.login.results,
		isProcessingLogin: state.auth.data.login.loading,
	};
}



export default withRouter(connect(mapStateToProps, null)(DsrReturningLoginForm));