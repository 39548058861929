import React, { useEffect, useState } from 'react'
import { Box, Flex, Tab, TabList, TabPanel, TabPanels, Tabs, Image, useToast } from "@chakra-ui/react";
import { RiErrorWarningLine } from "react-icons/ri";
import { IoCloudUploadOutline } from "react-icons/io5";
import ImageUploading from "react-images-uploading";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PageLoader from "../../..//global/components/helper-components/components/PageLoader";
import LaunchdeckButton from '../../../global/components/helper-components/components/LaunchdeckButton';
import OrganizationLogoByUrl from "./OrganizationLogoByUrl";
import {resizeLogoImage, triggerLaunchdeckToast} from "../../../global/helpers/global-helpers";
import { uploadOrgLogoImage, resetOrgLogoImageOutcome, resetUploadOrgLogoImage } from "../../actions/organizations-actions";
import config from "../../../../config/app-config";


const OrganizationChangeLogoForm = ( {...props} ) => {
  const { currentOrgLogo, updatedLogoSrc, setOrgLogo, imageSaved, isMobileView, handleCloseModal, 
    uploadedImageSaved,
    uploadOrgLogoImage,
    resetOrgLogoImageOutcome,
    resetUploadOrgLogoImage
  } = props

  const { maxImageFileSize } = config;
  const [logoFile, setLogoFile] = useState(null)
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast()

  useEffect(() => {
    if (setOrgLogo) {
      setIsLoading(false)
      
      if (!!imageSaved) {
        toast(
              triggerLaunchdeckToast({
              useCase: "show-success-state",
              label: "Logo saved successfully", 
              isMobileView
              })
            )
      } else if (imageSaved === false) {
        toast(
          triggerLaunchdeckToast({
          useCase: "show-error-state",
          label: "Unfortunately something went wrong, and we can't save your logo. Please try again later.", 
          isMobileView
          })
        )
      }

      resetOrgLogoImageOutcome()
      handleCloseModal()
    }
  }, [setOrgLogo]);

  useEffect(() => {
    if (updatedLogoSrc) {
      setIsLoading(false)

      if (!!uploadedImageSaved) {
        toast(
              triggerLaunchdeckToast({
              useCase: "show-success-state",
              label: "Logo saved successfully", 
              isMobileView
              })
            )
      } else if (uploadedImageSaved === false) {
        toast(
          triggerLaunchdeckToast({
          useCase: "show-error-state",
          label: "Unfortunately something went wrong, and we can't save your logo. Please try again later.", 
          isMobileView
          })
        )
      }

      resetUploadOrgLogoImage()
      handleCloseModal()
    }

  }, [updatedLogoSrc, uploadedImageSaved, resetUploadOrgLogoImage]);


  const handleUploadOrgLogo = () => {
    if (logoFile) {
      setIsLoading(true)
      uploadOrgLogoImage(logoFile[0].file);
    }
  }


  return (
    <Box width='100%'>
      <Tabs width='100%' >
        <TabList padding='0 24px'>
          <Tab fontSize='0.875em' fontWeight='500' p='0.75em 1em'>Proposed logo</Tab>
          <Tab fontSize='0.875em' fontWeight='500' p='0.75em 1em'>Upload logo</Tab>
        </TabList>

        <TabPanels padding='1.5em'>
          <TabPanel>
            <Box margin='0 1em'>
              <OrganizationLogoByUrl currentOrgLogo={currentOrgLogo} />
            </Box>
          </TabPanel>
          <TabPanel>
            <Box margin='1em' display='flex'>
              <UploadingLogo logoFile={logoFile} setLogoFile={setLogoFile} isLoading={isLoading} maxImageFileSize={maxImageFileSize}/>
            </Box>
            <Flex w='100%' py='0.625em' px='50px'>
              <Flex justify='center' w='100%'>
                {(logoFile && !isLoading)
                  && 
                  <Box>
                  <LaunchdeckButton px='4em' bg='brand.500' color='gray.50' onClick={handleUploadOrgLogo} label='Upload logo image' />
                  <Box mt='0.5em' fontSize='0.8em' color='gray.500' cursor='pointer' onClick={()=>setLogoFile(null)} textAlign={'center'}>Discard & use another image</Box>
                  </Box>
                }
              </Flex>
            </Flex>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  )
}

function UploadingLogo({ logoFile, setLogoFile, isLoading, maxImageFileSize }) {
  const maxFileSize = maxImageFileSize;
  const acceptType = ['jpg', 'png', 'jpeg'];

  const onChangeUploadOrgLogo = async (image) => {
    let providedImageFile = image;

    let { name, size } = providedImageFile[0].file || {}
    let resizedFile = '';
    let imageFileSizeLimit = 20000;
    const resizeNeeded = size > imageFileSizeLimit;

    if (resizeNeeded) {
      let resizedImage = await resizeLogoImage(providedImageFile[0].file);
      let newFileName = '200x200-logo-' + name;
      resizedFile = new File([resizedImage], newFileName, { type: "image/png" })
    }

    let uploadedLogo = resizeNeeded ? [{...image[0], file: resizedFile}] : providedImageFile;

    setLogoFile(uploadedLogo)
  };

  if (isLoading) {
    return (
      <Box w="100%" align="center" >
        <Box px='1em'>
          <Box>
            <PageLoader />
          </Box>
        </Box>
      </Box>
    )
  }

  return (
    <ImageUploading
      maxFileSize={maxFileSize}
      value={logoFile}
      onChange={onChangeUploadOrgLogo}
      acceptType={acceptType}
      dataURLKey="logoUrl"
    >
      {({
          imageList,
          onImageUpload,
          errors,
          isDragging,
          dragProps,
        }) => (
        <Box
          style={isDragging ? { color: 'red' } : undefined}
          border={imageList.length === 0 && '1px dashed #b7b7b7'}
          borderRadius='10px'
          minH='200px'
          width='100%'
          display='flex'
          justifyContent='center'
          alignItems='center'
          // margin='0 40px'
        >
          {imageList.length === 0
            ? <Box
              display='flex'
              flexDirection='column'
              justifyContent='center'
              alignItems='center'
              cursor='pointer'
              padding='16px'
              width="100%"
              onClick={onImageUpload}
              {...dragProps}
            >
              <Box mb='24px' height='50px' width='50px'>
                <IoCloudUploadOutline style={{ width: '100%', height: '100%' }} fill='#b7b7b7' />
              </Box>
              <Box mb='12px'>
                <p>Select a file or drag and drop here</p>
              </Box>
              <Box fontSize='12px' color='#b7b7b7' mb='24px'>
                <p>JPG, PNG or PDF, file size no more than 10MB</p>
              </Box>

              <Flex mt='1em' align='center' justify='center'>
                <LaunchdeckButton label='Select image' fontSize='0.9em' py='0.8em' px='3em' bg='brandDark.500' color='gray.50' />
              </Flex>
            </Box>
            : imageList.length > 0
            && 
            <Flex boxSize='8em' direction='column' mb='1em' justify='center' align='center' border='1px solid' borderColor='gray.200' rounded='0.25em' p='0.5em'>

              <Image
                src={logoFile[0]?.['logoUrl']} alt={""}
                height='5em'
              />
              </Flex>
          }
          {errors &&
            <><RiErrorWarningLine color="red" boxSize={20} /> &nbsp; Upload limit is 10MB per image</>
          }
        </Box>
      )}
    </ImageUploading>
  )
}


const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
      uploadOrgLogoImage,
      resetOrgLogoImageOutcome,
      resetUploadOrgLogoImage
		},
		dispatch,
	)

function mapStateToProps(state) {
  return {
    updatedLogoSrc: state.uploadOrgLogo.results,
    uploadedImageSaved: state.uploadOrgLogo.imageUploaded,
    setOrgLogo: state.setOrgLogo.results.data,
    imageSaved: state.setOrgLogo.imageSet,
    isMobileView: state.mobileViewMode.isMobileView
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationChangeLogoForm);


