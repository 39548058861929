import React from 'react';
import { Box } from "@chakra-ui/react";


export default function ReactIconDisplay(props) {
	
	const { reactIcon, color, fontWeight, mr } = props;
	

	return (
		<Box as={reactIcon} color={color} boxSize={fontWeight} mr={mr ? mr : '0.5em'}/>

	)
	
}
