import React from 'react';
import {Box, Flex, Input, Text} from "@chakra-ui/react";
import PageLoader from '../../../global/components/helper-components/components/PageLoader';
import LaunchdeckButton from '../../../global/components/helper-components/components/LaunchdeckButton';


export function CreateProspectDsrForm({
  prospectDsrFormInput,
  error,
  showLoader,
  onChangeHandler,
  handleClickCloseEditActionModal,
  handleCreateNewProspectDsr,
}) {
  
  const titleCharacterLimit = 100;


  if (showLoader) {
    return (
      <Box w="100%" align="center" >
        <Box px='1em'>
          <Box>
            <PageLoader />
          </Box>
        </Box>
      </Box>
    )
  }

  if (!showLoader) {
    return (
      <Flex direction='column' m='24px' w="100%" align="center" justify='space-between' >
        <Box px='1em' w='100%'>
          <Box mb='24px'>
            <Flex w='100%' justify='space-between' align='center' mb='10px'>
              <Box fontSize='16px' fontWeight='500'>Prospector name<Box as='span' color='pink.500'>*</Box></Box>
              <Box display='flex' fontSize='14px'><Box fontWeight='600'>{prospectDsrFormInput.dsrTitle.trim().length}</Box>/{titleCharacterLimit}</Box>
            </Flex>
            <Input
              name='dsrTitle'
              value={prospectDsrFormInput.dsrTitle}
              onChange={onChangeHandler}
              placeholder="Give your Prospector a good name"
              maxLength={titleCharacterLimit}
              borderColor={error === 'dsrTitle' ? 'pink.500' : ""}
              mb='0.2em'
              h='1.4em'
              fontSize='0.875em'
              fontWeight= '400'
              color='brandDark.500'
              border='1px solid #A0AEC0'
              borderRadius='4px'
              padding='16px'
            />
            {error === 'dsrTitle' && <Text color='pink.500' fontSize='0.8em'>Give your Prospector a good title</Text>}
          </Box>
        </Box>

        <Flex w='100%' mt='2em' px='1em' py='0.625em'>
          <Flex justify='space-between' w='100%'>
            <LaunchdeckButton bg='gray.200' color='brandDark.500' onClick={handleClickCloseEditActionModal} label='Cancel' />
            <LaunchdeckButton bg='brand.500' color='white' onClick={handleCreateNewProspectDsr} label='Create Prospector' />
          </Flex>
        </Flex>
      </Flex>
    )
  }
}
