import { useState, useEffect } from 'react';

const useLoadingTimeout = (timeoutDuration) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (loading) {
        // setLoading(false);
        setError(true);
      }
    }, timeoutDuration);

    return () => clearTimeout(timeout);
  }, [loading, timeoutDuration]);

    // Effect to handle when loading state changes
    useEffect(() => {
      if (!loading) {
        setError(false); // Reset error if loading completes
      }
    }, [loading]);

  return { loading, error, setLoading, setError };
};

export default useLoadingTimeout;
