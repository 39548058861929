import { combineReducers } from 'redux';
import _ from 'lodash';

export function setupReducersPerStateType(obj) {
	const { ui, data, app } = obj;

	return combineReducers({
		ui: !_.isEmpty(ui) ? combineReducers(ui) : {},
		data: !_.isEmpty(data) ? combineReducers(data) : {},
		app: !_.isEmpty(app) ? combineReducers(app) : {}
	});
}
