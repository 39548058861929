import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';

import { Box, Modal, ModalOverlay, ModalBody, ModalContent, Flex  } from "@chakra-ui/react";
import { MdError } from "react-icons/md";
import { reloadPage } from '../../global/helpers/global-helpers';

import { createNewErrorReport } from '../actions/dsr-actions';
import PageLoader from '../../global/components/helper-components/components/PageLoader';
import { checkIsLoggedInUser } from '../../auth/helpers/auth-checker';
import LaunchdeckCloseButton from '../../global/components/helper-components/components/LaunchdeckCloseButton';
import LaunchdeckButton from '../../global/components/helper-components/components/LaunchdeckButton';


function DsrGenericErrorModal(props) {
  // This modal displays a generic error message with an OK option for the user
  // This can be used for non performing backend operations, some unexpected error
  // Click OK will also use an endpoint to report the error to us, with the error code to help with tracking
  
  const { dsrId, isProspectDsr, handleCloseOtherModals,
    createNewDsrFeedPostOutcome, createErrorReportOutcome, 
    dsrActionList, createNewDsrActionOutcome, updateDsrActionOutcome, // Action List user actions
    createFaqAnswerOutcome, editFaqAnswerOutcome, deleteFaqAnswerOutcome, // Q&A Management
    createNewProspectDsrOutcome, updateProspectDsrOutcome, sendProspectDsrQueryOutcome,
    joinPublicDsrOutcome
  } = props;


  const [showGenericErrorModal, setShowGenericErrorModal] = useState(false)
  const [showLoadingState, setShowLoadingState] = useState(false)
  const [errorReportSuccess, setErrorReportSuccess] = useState(null)
  const [outcomeMessage, setOutcomeMessage] = useState('')

  const [displayErrorCode, setErrorCode] = useState(null)
  const [displayErrorMessage, setErrorMessage] = useState(null)

  // Before sending report
  useEffect(() => {
    if (createNewDsrFeedPostOutcome) {
      const {hasErrors, errorMessage, errorCode} = createNewDsrFeedPostOutcome || {}; // May need a different arrangement once there are more errors with the same standard variable names from other redux states

      if (hasErrors=== true) {
        setShowGenericErrorModal(true)
        setErrorCode(errorCode)
        setErrorMessage(errorMessage)
      }
    }

    if (dsrActionList) {
      const {hasErrors, errorMessage, errorCode} = dsrActionList || {}; // May need a different arrangement once there are more errors with the same standard variable names from other redux states

      if (hasErrors=== true) {
        setShowGenericErrorModal(true)
        setErrorCode(errorCode)
        setErrorMessage(errorMessage)
      }
    }

    if (createNewDsrActionOutcome) {
      const {hasErrors, errorMessage, errorCode} = createNewDsrActionOutcome || {}; // May need a different arrangement once there are more errors with the same standard variable names from other redux states

      if (hasErrors=== true) {
        setShowGenericErrorModal(true)
        setErrorCode(errorCode)
        setErrorMessage(errorMessage)
      }
    }

    if (updateDsrActionOutcome) {
      const {hasErrors, errorMessage, errorCode} = updateDsrActionOutcome || {}; // May need a different arrangement once there are more errors with the same standard variable names from other redux states

      if (hasErrors=== true) {
        setShowGenericErrorModal(true)
        setErrorCode(errorCode)
        setErrorMessage(errorMessage)
      }
    }

    // Q&A Management
    if (createFaqAnswerOutcome) {
      const {hasErrors, errorMessage, errorCode} = createFaqAnswerOutcome || {}; // May need a different arrangement once there are more errors with the same standard variable names from other redux states

      if (hasErrors=== true) {
        setShowGenericErrorModal(true)
        setErrorCode(errorCode)
        setErrorMessage(errorMessage)
      }
    }

    if (editFaqAnswerOutcome) {
      const {hasErrors, errorMessage, errorCode} = editFaqAnswerOutcome || {}; // May need a different arrangement once there are more errors with the same standard variable names from other redux states

      if (hasErrors=== true) {
        setShowGenericErrorModal(true)
        setErrorCode(errorCode)
        setErrorMessage(errorMessage)
      }
    }

    if (deleteFaqAnswerOutcome) {
      const {hasErrors, errorMessage, errorCode} = deleteFaqAnswerOutcome || {}; // May need a different arrangement once there are more errors with the same standard variable names from other redux states

      if (hasErrors=== true) {
        setShowGenericErrorModal(true)
        setErrorCode(errorCode)
        setErrorMessage(errorMessage)
      }
    }

    if (createNewProspectDsrOutcome) {
      const {hasErrors, errorMessage, errorCode} = createNewProspectDsrOutcome || {}; 

      if (hasErrors=== true) {
        setShowGenericErrorModal(true)
        handleCloseOtherModals()
        setErrorCode(errorCode)
        setErrorMessage(errorMessage)
      }
    }

    if (updateProspectDsrOutcome) {
      const {hasErrors, errorMessage, errorCode} = updateProspectDsrOutcome || {}; // May need a different arrangement once there are more errors with the same standard variable names from other redux states

      if (hasErrors=== true) {
        setShowGenericErrorModal(true)
        handleCloseOtherModals()
        setErrorCode(errorCode)
        setErrorMessage(errorMessage)
      }
    }

    if (sendProspectDsrQueryOutcome) {
      const {hasErrors, errorMessage, errorCode} = sendProspectDsrQueryOutcome || {}; 

      if (hasErrors=== true) {
        setShowGenericErrorModal(true)
        handleCloseOtherModals()
        setErrorCode(errorCode)
        setErrorMessage(errorMessage)
      }
    }
    
    if (joinPublicDsrOutcome) {
      const {hasErrors, errorMessage, errorCode} = joinPublicDsrOutcome || {}; 

      if (hasErrors=== true) {
        setShowGenericErrorModal(true)
        handleCloseOtherModals()
        setErrorCode(errorCode)
        setErrorMessage(errorMessage)
      }
    }

   }
  , [createNewDsrFeedPostOutcome, 
    dsrActionList, 
    createNewDsrActionOutcome, 
    updateDsrActionOutcome,
    // Q&A management
    createFaqAnswerOutcome,
    editFaqAnswerOutcome,
    deleteFaqAnswerOutcome,
    updateProspectDsrOutcome,
    createNewProspectDsrOutcome,
    sendProspectDsrQueryOutcome,
    // Public DSR
    joinPublicDsrOutcome,
    handleCloseOtherModals
  ]);

  
  // After sending report
  useEffect(() => {
    if (createErrorReportOutcome) {
    const {createErrorReportSuccess, outcomeMessage} = createErrorReportOutcome || {};
    setOutcomeMessage(outcomeMessage)
    setErrorReportSuccess(createErrorReportSuccess)
    }
   }
   , [createErrorReportOutcome]);
  


  const handleClickSendErrorReport = async () => {
    const isLoggedInUser = checkIsLoggedInUser();

    if (isLoggedInUser) {
      setShowLoadingState(true)
      const errorReportMetadata = {
        dsrId: dsrId,
        errorCode: displayErrorCode,
        errorMessage: displayErrorMessage,
        internalNotes: isProspectDsr ? 'Is Prospect DSR' : null
      }

      await props.action.createNewErrorReport(errorReportMetadata)
    } else {
      reloadPage()
      // handleCloseOtherModals()
      // setShowGenericErrorModal(false)
    }

  }
  



  const renderConfirmationModalContent = () => {

    if (errorReportSuccess===true) {
      return (
        <Box w="100%" my="2em" px='1em' align="center" color='brandDark.500'>
          <Box>{outcomeMessage}</Box>
          <Flex justify='flex-end' w='100%' mt='1em'>
            <LaunchdeckButton name='okBackToDsr' bg='brandDark.500' color='white' px='2em'  fontSize='0.9em'  w={["100%", "100%", "auto", "auto"]} onClick={() => reloadPage()} label='Back' />
          </Flex>
        </Box>
      )
    }

    if (showLoadingState) { 
      return (
        <Box>
          <PageLoader />
        </Box>
      )
    }

    return (
      <React.Fragment>
        <Flex justify='space-between' align='center' w='100%' >
          <Flex px='1em' justify='flex-start' align='center'>
            <Box as={MdError} color='pink.500' 
            boxSize="1.6em" />
            <Box ml='1em' fontWeight='500' fontSize='1.25em' lineHeight='1.375em' color='brandDark.500'>Uh oh, there had been an unexpected error</Box>
          </Flex>
          <LaunchdeckCloseButton handleCloseModal={()=> reloadPage()} /> 
        </Flex>

        <Flex justify='center' mt='1em' mb='1.6em' w='100%'>
        <Box w="100%" my="2em" px='1em' align="center" color='brandDark.500'>
          
          <Box>
            {displayErrorMessage ? displayErrorMessage : "There has been an unexpected error"}
          </Box>
          <Box fontSize='0.7em' fontWeight='500' color='gray.500' mt='1.5em'>
            Error Code: {displayErrorCode}
          </Box>
          </Box>
        </Flex>
      

        <Flex w='100%' px='1em'>
          <Flex my='0.625em' justify='flex-end'  w='100%'>
            <LaunchdeckButton name='showGenericErrorOkOption' bg='gray.700' color='white' px='2em'  fontSize='0.9em'  w={["100%", "100%", "auto", "auto"]} onClick={() => handleClickSendErrorReport()} label='Send error report' />
          </Flex>
        </Flex>
      </React.Fragment>
    )
      
  }


  if (showGenericErrorModal=== true) {
    return (
      <Modal isOpen={true} closeOnOverlayClick={false} isCentered={true} preserveScrollBarGap={true}>
        <ModalOverlay />
        <ModalContent rounded='0.25em' minW={['90%', '500px']} maxW='700px'>
          <ModalBody>
            <Flex direction='column' justify='space-around' alignItems='center' w='100%'>
  
              {renderConfirmationModalContent()}
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    )
  } else {
    return null;
  }
}

function mapStateToProps(state) {
  return {
    // Future Scope: Capture Error states from Frontend / infrastructure (unsuccessful API calls)
    // Future Scope: Catch-all error for all other error types when API calls to backend are initiated

    // Capture Error states from Backend
    // This will still show successful API call but backend will return an error message
    deleteDsrFeedPostOutcome: state.deleteDsrFeedPostOutcome.results, // Used to display error modal if delete action fails
    createNewDsrFeedPostOutcome: state.createNewDsrFeedPostOutcome.results,

    // Action List related errors
    dsrActionList: state.dsrActionList.results,
    createNewDsrActionOutcome: state.createNewDsrActionOutcome.results,
    updateDsrActionOutcome: state.updateDsrActionOutcome.results,
    
    // After sending error report
    createErrorReportOutcome: state.createErrorReportOutcome.results.data,

    // After attempting to create a new DSR from a prospect DSR

    // Q&A questions & answers management 
    createFaqAnswerOutcome: state.createFaqAnswerOutcome.results.data,
    editFaqAnswerOutcome: state.editFaqAnswerOutcome.results.data,
    deleteFaqAnswerOutcome: state.deleteFaqAnswerOutcome.results.data,

    // Update PDSR
    updateProspectDsrOutcome: state.updateProspectDsrOutcome.results,

    sendProspectDsrQueryOutcome: state.sendProspectDsrQueryOutcome.results,

    // Join Public DSR 
    joinPublicDsrOutcome: state.joinPublicDsrOutcome.results.data,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    action: bindActionCreators(
      {
       createNewErrorReport
      },
      dispatch
    )
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DsrGenericErrorModal));