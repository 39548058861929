import React, { useState, useEffect } from 'react';
import { Box, Flex, Grid} from "@chakra-ui/react";
import VaultEmptyState from './VaultEmptyState';
import DsrCardDisplay from '../dashboard/components/vault/DsrCardDisplay';

import PageLoader from '../global/components/helper-components/components/PageLoader';
import './styles/vault-display.scss'
import DsrGuestCardDisplay from '../dashboard/components/vault/DsrGuestCardDisplay';
import ProspectDsrCardDisplay from '../dashboard/components/vault/ProspectDsrCardDisplay';
import EmptyRoomIcon from '../vault/styles/images/received-rooms-invite.png';


export default function VaultDisplayDsrModule(props) {
  const { 
    displayedDsrs,
    isMobileView,
    vaultIsProcessing,
    foundDsrsTotal,
    itemsPerPage,
    toggleFilter,
    currentPageNumber,
    requestCategory,
    chosenSortValue,
    isReceivedRoomsPage,
    isPdsrDisplayMode,
    showEmptyDsrState,
    showEmptyProspectDsrState,
    onClickCreateDsr,
    handleDuplicateDsrModal,
    handleCreateDsrTemplateModal
   } = props || {};
   
  const [imageClassName, setImageClassName] = useState('image-loading__in-progress')
  const [totalPages, setTotalPages] = useState(1)

  const handlePageButtonClick = (pageNumber) => {
    let sortRequest= "default";
    
    switch (chosenSortValue) {
      case('Latest'): {
        sortRequest = "latest"
        break;
      }
      case('Room name (A - Z)'): {
        sortRequest = "a-to-z"
        break;
      }
      case('Room name (Z - A)'): {
        sortRequest = "z-to-a"
        break;
      }
      default: {
        break;
      }
    }

    toggleFilter(requestCategory, pageNumber, sortRequest)
  }
  useEffect(() => {
    if (foundDsrsTotal && itemsPerPage) {
      setTotalPages(Math.ceil(foundDsrsTotal/itemsPerPage))
    }
  }, [foundDsrsTotal, itemsPerPage])


  function handleImageLoaded() {
    setImageClassName("image-loading__completed");
  }

  const renderPaginationButtons = () => {
    const maxButtonsDisplayed = 5;
    let nextNumber = 0;
    let buttonTextArray = [];

    const lowerBound = (maxButtonsDisplayed > totalPages) ? totalPages : maxButtonsDisplayed;
    const includeTruncateButton = (maxButtonsDisplayed > totalPages) ? false : true;

    while (lowerBound > nextNumber) {
      buttonTextArray = [...buttonTextArray, nextNumber+1]
      nextNumber++;
    }

    if (Array.isArray(buttonTextArray)) {
      const renderedButtons = buttonTextArray?.map((element, index) => {
        const pageNumber = index + 1;
        return (
          <Box key={`page-button-${index}`} 
          className={currentPageNumber === pageNumber ? "chosen-page-button" : "unchosen-page-button"}
          onClick={()=>handlePageButtonClick(pageNumber)}>
            {element}
          </Box>
        )
      })

      return <Flex w='100%' justify='center' mt='1em'>
        <Flex>
        {(currentPageNumber > 1) &&
          <Box className="unchosen-page-button" onClick={()=>handlePageButtonClick(currentPageNumber-1)}> {`<`} </Box>
        }
          {renderedButtons}
         
          {(currentPageNumber < nextNumber) &&
          <>
            {includeTruncateButton && <Box>...</Box>}
            <Box className="unchosen-page-button" onClick={()=>handlePageButtonClick(currentPageNumber+1)}> {`>`} </Box>
          </>
          }
          </Flex>
     
        </Flex>;    
  }
}

  const renderDsrCards = () => {
    return (
      <Grid templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)', 'repeat(2, 1fr)', 'repeat(3, 1fr)']} gap={12} w='100%'>
      {displayedDsrs.map((dsr, index) => {
        return (
          <Box key={`dsr-card-${dsr.dsrId}`} className='flip-in-hor-bottom'>
            {isReceivedRoomsPage ? 
            <DsrGuestCardDisplay dsrData={dsr} />
            :
            <DsrCardDisplay 
              dsrData={dsr} 
              handleDuplicateDsrModal={handleDuplicateDsrModal}
              handleCreateDsrTemplateModal={handleCreateDsrTemplateModal}
              />
            }
          </Box>
        )
      })}
    </Grid>
    )
  }

  const renderProspectDsrCards =() => {

    return (
      <Grid templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)', 'repeat(2, 1fr)', 'repeat(3, 1fr)']} gap={12} w='100%'>
        {displayedDsrs.map((dsr) => {
          return (
            <Box key={`dsr-card-${dsr.pDsrId}`} className='flip-in-hor-bottom'>
              <ProspectDsrCardDisplay dsrData={dsr} />
            </Box>
          )
        })}
      </Grid>
    )
  }

  if (vaultIsProcessing=== true || !displayedDsrs 
    // || vaultSearchIsProcessing === true
    ) {
    return <PageLoader /> 
  } else {

    if (displayedDsrs?.length === 0) {
      return (
        <Flex w='100%' direction={['column', 'row', 'row', 'row']} justify='center' alignItems={['center', 'center', '', '']} flexWrap='wrap' px={['', '1em', '1em', '1em']} py='1em'>
          <VaultEmptyState 
          isMobileView={isMobileView}
          onClickCreateDsr={onClickCreateDsr}
          iconImage={EmptyRoomIcon}
          displayType={!!showEmptyDsrState ? 'no-published-rooms-vault' : !!showEmptyProspectDsrState ? "no-published-prospectors-vault" : ""} 
          handleImageLoaded={()=> handleImageLoaded()} 
          imageClassName={imageClassName} 
          imageSize={'5em'}
          />
          
        </Flex>)
    }

    if (displayedDsrs?.length > 0) {
      return (
        <React.Fragment>
          {isPdsrDisplayMode ? renderProspectDsrCards() : renderDsrCards()}
          {(totalPages > 1) && renderPaginationButtons()}
        </React.Fragment>
        
      )
    }
  }
}
