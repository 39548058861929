import React, { memo, useRef, useEffect, useState } from 'react';

import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Flex, InputGroup, Input, Box } from '@chakra-ui/react';

import DsrManageOrgLogosModule from '../dsr-draft/DsrManageOrgLogosModule';
import '../../layout/components/header/styles/header.scss';
import SaveDraftProspectDsrButton from './draft-prospect-dsr/SaveDraftProspectDsrButton';
import DsrNavigationDrawerMenu from '../../layout/components/sidebar/components/DsrNavigationDrawerMenu';
import CreateProspectDsrQueryDrawer from './create-pdsr-query/CreateProspectDsrQueryDrawer';
import ShareProspectDsrButton from './ShareProspectDsrButton';
import DsrPageSideMenuDrawer from "../components/DsrPageSideMenuDrawer";
import { signOut } from '../../auth/actions/auth-actions';
import LaunchdeckButton from '../../global/components/helper-components/components/LaunchdeckButton';
import LaunchdeckTag from '../../global/components/helper-components/components/LaunchdeckTag';
import { changeDsrModalState, changeDsrPopupState } from '../actions/dsr-actions';
import { ToggleButton } from "../dsr-draft/page-designer/toggle-button/ToggleButton";

function DsrProspectHeader({
  pDsrId,
  dsrTitle,
  pdsrPagesLocalState,
  updatePdsr,
  handlePublishDsr,
  userProfile,
  dsrStatus,
  isLoggedInUser,
  dsrMainContact,
  // Redux action
  signOut,
  changeDsrPopupState,
  changeDsrModalState,
  // Redux
  isMobileView,
  userPrivileges,
  isPagePreviewMode,
  setIsPagePreviewMode,
}) {

	const {publishDsr} = userPrivileges || {}

	const headerTitleInputRef = useRef(null);
	const [currentDsrTitle, setCurrentDsrTitle] = useState("")
	const [showEditTitleInput, setShowEditTitleInput] = useState(false)
	const isDraft = ((dsrStatus === "draft") || (dsrStatus === "reverted-draft")) ? true : false
	const ctaCopyText = isDraft ? `Edit Prospector Room` : "";

	// UX listeners
	useEffect(() => {
		document.addEventListener("keydown", handleHitKey, true);
		document.addEventListener("click", handleClickEditInput, true);
		return () => {
			document.removeEventListener("keydown", handleHitKey, true);
			document.removeEventListener("click", handleClickEditInput, true);
		};
	});

	const handleInputChange = (event) => {
		if (event.target.name === "title") {
			setCurrentDsrTitle(event.target.value)
		}
	}

	const handleHitKey = (event) => {
		if (event.key === "Escape") {
			setShowEditTitleInput(false)
		} else if (event.key === 'Enter') {
			if (event.target.name === "title") {
				clickOutsideAutosave()
			}
		}
	};

	const clickOutsideAutosave = () => {
		setShowEditTitleInput(false)

		if (currentDsrTitle.trim().length > 0) {
			updatePdsr({
				pDsrId,
				dsrTitle: currentDsrTitle,
				// dsrPages: pdsrPagesLocalState,
				sourceEvent: "update-pdsr-title"
			})
		} else (
			setCurrentDsrTitle(dsrTitle ? dsrTitle : "-")
		)
	}

	const handleClickEditInput = (event) => {
		if (headerTitleInputRef.current && !headerTitleInputRef.current.contains(event.target)) {
			clickOutsideAutosave()
		}
	};

	const renderDsrTitle = () => {

		if (showEditTitleInput) {
			return (
				<Flex fontWeight='600' lineHeight='1.875em' ref={headerTitleInputRef} >
					<InputGroup
						size="2em"
						width="100%"
						maxWidth='35%'
						minWidth='25em'
						border='none'
					>
						<Input
							name="title"
							value={currentDsrTitle}
							onChange={handleInputChange}
							onKeyDown={handleHitKey}
							autoFocus={true}
							// onFocus={e => e.currentTarget.select()}
							fontSize='1.25em'
							border='1px solid'
							borderColor='#3295EC'
							borderRadius='0.25em'
							paddingLeft='5px'
							lineHeight='1.875em'
							fontWeight="600"
						/>

					</InputGroup></Flex>
			)

		} else {
			return (
				<Flex fontWeight='600' fontSize='1.25em' lineHeight='1.875em' justify={['center', 'center', 'flex-start', 'flex-start']} pb={['1em', '1em', '0', '0']}>
					<Flex
						cursor={isDraft && 'pointer'}
						onClick={() => isDraft && setShowEditTitleInput(!showEditTitleInput)}
						border='1px solid'
						borderColor='transparent'
						paddingLeft='5px'
					>
						{dsrTitle}
					</Flex>
				</Flex>
			)
		}
	}

	if (isDraft === true) {
		return (
			<>
				<Flex className='dsr-header__section'>
					<Flex className='dsr-header__container'>
						<Flex justify='flex-start' align='center'>
							<DsrNavigationDrawerMenu userProfile={userProfile} signOut={signOut} darkMode={true} />
							<Box
								className="page-title"
								fontSize='1.25em' fontWeight='600' lineHeight='1.85em' color='white'
								display='inline-block' ml='2em'>
								{ctaCopyText}
							</Box>
							<Box ml='0.5em'>
								<LaunchdeckTag bg='gray.600' color='white' label='Draft' />
							</Box>
						</Flex>

						<Flex>
							<ToggleButton
								offLabelText="Edit"
								onLabelText="Preview"
								isChecked={isPagePreviewMode}
								setIsChecked={setIsPagePreviewMode}
							/>
						</Flex>

						<Flex>
							<Flex>
								<Box mr='1em'>
									<SaveDraftProspectDsrButton
										pDsrId={pDsrId}
										dsrPages={pdsrPagesLocalState}
									/>
								</Box>
								<Box>
									<LaunchdeckButton bg='brandGreen.500' color='white' 
									onClick={() => {
										if (publishDsr=== false) {
											changeDsrPopupState('subscription-nudge')
											changeDsrModalState(true)
										} else {
											handlePublishDsr()
										}
									}
										
									} 
									label='Save & Publish' />
								</Box>
							</Flex>
						</Flex>

					</Flex>
				</Flex>

				<Flex className='dsr-content__dsr-top-banner'>
					<Flex align='center'>
						<DsrManageOrgLogosModule logoDisplayMode={'prospect-dsr'} />
						{renderDsrTitle()}
					</Flex>

				</Flex>
			</>
		);
	} else if (isDraft === false) {

		if (!!isMobileView) {
			return (
				<>
					<Flex className='dsr-header__section' align='center'>
						<Flex className='dsr-header__container'>
							<Flex justify='flex-start' align='center'>
								<DsrPageSideMenuDrawer isProspectPage={true} />
								{(!!isLoggedInUser) &&
									<Box
										className="page-title"
										fontSize={['1em', '1em', '1.25em', '1.25em']} fontWeight='600' lineHeight='1.85em' color='white'
										display='inline-block' ml={isMobileView ? '1em' : '2em'}>
										Manage Prospector
									</Box>
								}

							</Flex>
						</Flex>


						<Box mr='1em'>
							<Box>
								<ShareProspectDsrButton pDsrId={pDsrId} dsrStatus={dsrStatus} isMobileView={isMobileView} />
							</Box>
							<Box className="cta-lower-right-button-container" display={isLoggedInUser && "none"}>
								<CreateProspectDsrQueryDrawer pDsrId={pDsrId} dsrMainContact={dsrMainContact} />
							</Box>
						</Box>


					</Flex>

					<Box className='dsr-content__dsr-top-banner'>
						<DsrManageOrgLogosModule logoDisplayMode={'prospect-dsr'} />
						<Flex w='100%' justify='center'>{renderDsrTitle()}</Flex>
					</Box>
				</>
			)
		} else {
			return (
				<>
					{isLoggedInUser &&
						<Flex className='dsr-header__section' align='center'>
							<Flex className='dsr-header__container'>
								<Flex justify='flex-start' align='center'>
									<DsrNavigationDrawerMenu userProfile={userProfile} signOut={signOut} darkMode={true} />
									<Box
										className="page-title"
										fontSize={['1em', '1em', '1.25em', '1.25em']} fontWeight='600' lineHeight='1.85em' color='white'
										display='inline-block' ml={isMobileView ? '1em' : '2em'}>
										Manage Prospector
									</Box>
									<Box ml='0.5em'>
										<LaunchdeckTag bg='green.500' color='white' label='Active' />
									</Box>
								</Flex>
							</Flex>


						</Flex>
					}


					<React.Fragment>
						<Flex className='dsr-content__dsr-top-banner'>
							<Box display={['flex', 'flex', 'flex', 'none', 'none']}>
								<DsrPageSideMenuDrawer isProspectPage={true} />
							</Box>
							<Flex align='center'>
								<DsrManageOrgLogosModule logoDisplayMode={'prospect-dsr'} />
								{renderDsrTitle()}
							</Flex>

							<Flex>
								<Box mr='1em'>
									<ShareProspectDsrButton pDsrId={pDsrId} dsrStatus={dsrStatus} isMobileView={isMobileView} />
								</Box>
								<Box>
									<CreateProspectDsrQueryDrawer pDsrId={pDsrId} dsrMainContact={dsrMainContact} />
								</Box>
							</Flex>


						</Flex>
					</React.Fragment>

				</>
			)
		}
	} else return null

};


const mapStateToProps = (state) => ({
	isMobileView: state.mobileViewMode.isMobileView,
	userPrivileges: state.userPrivileges.results
})


const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			signOut,
			changeDsrPopupState,
			changeDsrModalState
		},
		dispatch,
	)

export default memo(withRouter(connect(mapStateToProps, mapDispatchToProps)(DsrProspectHeader)))
