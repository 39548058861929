import React, { useEffect, useState } from 'react';
import { Box, Flex, Image, Tooltip, Avatar, CircularProgress, CircularProgressLabel } from "@chakra-ui/react";

import DsrCardOptionsButton from './DsrCardOptionsButton';
import { displayMoney, redirectToNewPage } from '../../../global/helpers/global-helpers';

import '../../styles/vault.scss';
import { IoCalendarOutline } from "react-icons/io5";
import { MdModeEdit } from "react-icons/md";
import { RiMoneyPoundCircleLine } from "react-icons/ri";
import { formatDisplayDateOnly } from '../../../digital-sales-room/helpers/timestamp-display-helper';
import { displayEmoji } from '../../../digital-sales-room/helpers/dsr-helpers';

export default function DsrCardDisplay({ dsrData, handleDuplicateDsrModal, handleCreateDsrTemplateModal }) {
  const { dsrId, dsrTitle, dsrOwnerUsername, buyerEngagementLevel,
    status, dsrProperties, ownerProfilePic,
    audienceLogoCardDisplay, updatedAt,
  } = dsrData || {};

  const { dealValue } = dsrProperties || {};
  const { currency, numberValue } = dealValue || {};
  const displayedDealValue = displayMoney(currency, numberValue)
  const [redirectLink, setRedirectLink] = useState('')
  const [showOptionButton, setShowOptionButton] = useState(false)


  useEffect(() => {
    if (status) {
      switch (status) {
        case ('reverted-draft'):
        case ('draft'): {
          setRedirectLink(`/draft/${dsrId}`)
          break;
        }
        case ('active'): {
          setRedirectLink(`/dsr/${dsrId}`)
          break;
        }
        default: {
          console.log(`There has been an error - there is no status for this dsr card ${dsrId}`)
          break;
        }
      }
    }
  }, [dsrId, status]);

  const renderEngagementLevel = () => {
    let displayedEmoji = '';
    let hovertext = '';
    let progressValue = 0;
    let progressColor = '';

    switch (buyerEngagementLevel) {
      case ('low'): {
        displayedEmoji = 'think'
        hovertext = 'Low engagement level'
        progressValue = 15;
        progressColor = 'gray.500'
        break;
      }
      case ('high'): {
        displayedEmoji = 'fire'
        hovertext = 'High engagement level'
        progressValue = 80;
        progressColor = 'brandGreen.500'
        break;
      }
      case ('intent-to-buy'): {
        displayedEmoji = 'target'
        hovertext = 'Audience likely to convert'
        progressValue = 95;
        progressColor = 'brand.500'
        break;
      }
      default: {
        break;
      }
    }

    if (displayedEmoji) {
      // Note: The color="" property is needed for Chakra UI hack to ensure the color of the progress bar changes.
      return (
        <Tooltip 
        css={{ 
          whiteSpace: "pre",
        display: "inline-block",
        overflow: "hidden",
        // textOverflow: "ellipsis",
        width: "100%", // Ensure tooltip does not exceed specified width
        minWidth: "16em",
        maxWidth: "30em"
        }}
  
        label={hovertext || ""} fontSize='0.8em' p='1em' textAlign='center' fontWeight='400' w='10em' placement="bottom" bg="gray.50" border='1px solid' borderColor='blackAlpha.200' rounded='0.25em' color='brandDark.500'>
          <Flex justify='center' align='center' w='1.5em' h='1.5em' >
            <CircularProgress value={progressValue} color={progressColor} thickness='4px' size='1.5em'>
              <CircularProgressLabel fontSize='0.75em'>  {displayEmoji(displayedEmoji)}</CircularProgressLabel>
            </CircularProgress>
          </Flex>
        </Tooltip>
      )
    } else return null
  }

  const renderAudienceLogo = () => {
    
    const { orgLogo, orgName, textLogoString } = audienceLogoCardDisplay || {};

    if (orgLogo) {
      if (orgName) {
        return (
          <Box mr='0.5em'>
            <Tooltip label={orgName} fontSize='0.8em' fontWeight='400' placement="bottom" bg="#4A5568" rounded='0.25em'>
              <Box p='0.2em' rounded='0.25em' border='1px solid' borderColor='gray.200'>
                <Image maxH={'2em'} src={orgLogo} />
              </Box>
            </Tooltip>
          </Box>
        )
      } else if (!orgName) {
        return (
          <Box mr='0.5em'>
            <Box p='0.2em' rounded='0.25em' border='1px solid' borderColor='gray.200'>
              <Image maxH={'2em'} src={orgLogo} />
            </Box>
          </Box>
        )
      }


    } else {
      if (textLogoString) {
        return (
        
           <Box mr='0.5em'>
           <Tooltip label={orgName} fontSize='0.8em' fontWeight='400' placement="bottom" bg="#4A5568" rounded='0.25em'>
             <Box p='0.5em' rounded='0.25em' border='1px solid' borderColor='gray.200'>
              
             {/* <Flex className="status-tag__draft-container status-tag__text-light" lineHeight='1.8em' mr='0.5em'> */}
            <Box className=" truncate-long-text default-text-logo-font" cursor='default' maxW='5em'>{textLogoString}</Box>
          {/* </Flex> */}
             </Box>
           </Tooltip>
         </Box>
        )
  
      } else if (orgName) {
        return (
          <Flex className="status-tag__draft-container status-tag__text-light" mr='0.5em'>
            <Box >{orgName}</Box>
          </Flex>
        )
      } else return <Box w='1px' h='2em' />;
    }
    

  }

  return (
    <Flex className="card-display"
      onMouseOver={() => setShowOptionButton(true)} onMouseOut={() => setShowOptionButton(false)}
    >

      <Box className="card-content-stack">
        <Flex align='center' justify='flex-start'>
          <Box>{renderAudienceLogo()}</Box>
          <StatusBadge status={status} />

        </Flex>
        <Box opacity={showOptionButton ? '1.0' : '0'}>
          <DsrCardOptionsButton
            dsrId={dsrId}
            dsrTitle={dsrTitle}
            dsrStatus={status}
            handleDuplicateDsrModal={handleDuplicateDsrModal}
            handleCreateDsrTemplateModal={handleCreateDsrTemplateModal}
          />
        </Box>
      </Box>


      <Box w='100%'
        onClick={() => redirectToNewPage(redirectLink)} cursor='pointer'
      >
        <Box className="card-content-stack__column">
          <Box maxWidth={['60vw', '20vw']} className="dsr-title-display" title={dsrTitle ? dsrTitle : "-"}>
            {dsrTitle ? dsrTitle : "-"}
          </Box>
          <Flex className="stats-display">
            <Flex align='center'>
              <Box as={IoCalendarOutline} />
              <Box ml='0.25em'> {formatDisplayDateOnly(updatedAt)} </Box>
            </Flex>

            {displayedDealValue &&
              <Flex align='center' ml='0.5em'>
                <Box as={RiMoneyPoundCircleLine} />
                <Box ml='0.25em'> {displayedDealValue} </Box>
              </Flex>
            }
          </Flex>
        </Box>

        <Box className="card-content-stack" mt='0.75em'>
          <Box boxSize='2em'>
            <Tooltip label={`Owned by ${dsrOwnerUsername}`} fontSize='0.8em' fontWeight='400' placement="bottom" bg="#4A5568" rounded='0.25em'>
              <Avatar h="3em" w="3em" name={dsrOwnerUsername} bg='gray.100' color='gray.700' src={ownerProfilePic} />
            </Tooltip>
          </Box>

          {buyerEngagementLevel && renderEngagementLevel()}
        </Box>

      </Box>
    </Flex>


  )
}


function StatusBadge({ status }) {
  return (
    <div>
      {
        {
          'draft':
            <Flex className="status-tag__draft-container status-tag__text-light">
              <Box as={MdModeEdit} />
              <Box >Draft</Box>
            </Flex>,
          'reverted-draft':
            <Flex className="status-tag__draft-container status-tag__text-light">
              <Box as={MdModeEdit} />
              <Box >Draft</Box>
            </Flex>,
          'null': null,
        }[status]
      }
    </div>
  );
}


