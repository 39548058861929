import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { setOrgLogoImage } from "../../actions/organizations-actions";

import { Box, Flex, Image, } from "@chakra-ui/react";
import { validateUrlFormat } from '../../../global/helpers/global-helpers';
import LaunchdeckButton from '../../../global/components/helper-components/components/LaunchdeckButton';
import FormTextInput from '../../../forms/TextInputField/components/FormTextInput';
import PageLoader from '../../../global/components/helper-components/components/PageLoader';
import LaunchdeckSpinner from '../../../global/components/helper-components/components/LaunchdeckSpinner';

import '../../../digital-sales-room/styles/dsr-display.scss';
import '../../../digital-sales-room/styles/dsr-invite-display.scss';
import '../../../global/styles/custom-animations.scss'


function OrganizationLogoByUrl(props) {
  const { currentOrgLogo, imageIsSaving } = props || {};

  const [uriInput, setUriInput] = useState("")
  const [feedUriInput, setFeedUriInput] = useState("")

  // UX management
  const [showlogoLoader, setShowlogoLoader] = useState(true)
  const [currentLogoImage, setCurrentLogoImage] = useState(currentOrgLogo ? currentOrgLogo : "")
  const [showLogoImage, setShowLogoImage] = useState(false)
  const [showInvalidUrlError, setShowInvalidUrlError] = useState(false)
  const [showImageError, setShowImageError] = useState(false)
  const [showButtons, setShowButtons] = useState(false)
  const [showChangeLogoButton, setShowChangeLogoButton] = useState(true)

  const [showLogoInput, setShowLogoInput] = useState(false)


  const handleImageError = () => {
    setShowLogoImage(false)
    setShowImageError(true)
    setShowlogoLoader(false)
  }

  const handleInputChange = (event) => {
    setUriInput(event.target.value)
  }

  const handlePressEnter = (event) => {
    if (event.key === 'Enter' && event.target.name === "logo-search-input") {
      const isValidUrl = uriInput ? validateUrlFormat(uriInput) : false;
      setShowImageError(false)
      setCurrentLogoImage(false)

      if (isValidUrl && uriInput) {
        setFeedUriInput(uriInput)
        setShowLogoImage(true)
      } else {
        setShowInvalidUrlError(true)
      }

    }
  }

  const handleGetCbLogo = () => {
    setShowlogoLoader(true)
    const isValidUrl = uriInput ? validateUrlFormat(uriInput) : false;
    setShowImageError(false)
    setCurrentLogoImage(false)

    if (isValidUrl && uriInput) {
      setFeedUriInput(uriInput)
      setShowLogoImage(true)
      // setShowButtons(true)
      setShowInvalidUrlError(false)

    } else {
      setShowInvalidUrlError(true)
    }
  }

  const handleSetOrgLogo = () => {
    props.action.setOrgLogoImage(`https://logo.clearbit.com/${feedUriInput}`)
  }

  const displaySearchLogoModule = () => {
    return (
      <Box w='80%' my='1em'>
        <FormTextInput
          name="logo-search-input"
          w='100%'
          color='#2D3748'
          mb='0em'
          onChange={handleInputChange}
          onKeyDown={handlePressEnter}
          borderColor='gray.400'
        />
        <Box fontSize='0.7em' textAlign='center' color={showInvalidUrlError ? 'pink.500' : 'gray.500'} my='0.5em' fontStyle='italic'>Example: If you want to use Nike's logo, simply key in its homepage above • 'https://www.nike.com'</Box>
        <Flex mt='0.8em' align='center' justify='center'>
          <LaunchdeckButton name='userProviderUri' label='Find Logo' fontSize='0.9em' py='0.8em' bg='brandDark.500' color='gray.50' onClick={() => handleGetCbLogo()} />
        </Flex>
      </Box>
    )
  }

  const handleFindAnotherLogo = () => {
    setShowLogoInput(true)
    setShowlogoLoader(false)
    setShowChangeLogoButton(false)
    setShowButtons(false)
  }

  const displayAndEditLogoModule = () => {

    if (showLogoImage) {
      return (
        <React.Fragment>
          {/* <Box mb='1em' color='brandDark.500' fontWeight='600' textAlign='center'>{!showlogoLoader && "Current Logo"}</Box> */}
          <Flex boxSize='128px' direction='column' mb='3em' justify='center' align='center' border='1px solid' borderColor='gray.100'>

            <Image 
            // display={showlogoLoader ? "none" : "block"} 
            fallback={<PageLoader />}
            src={`https://logo.clearbit.com/${feedUriInput}`} onError={handleImageError} onLoad={handleImageFound} />

          </Flex>
          {showChangeLogoButton &&
            <LaunchdeckButton bg='gray.500' color='white' onClick={() => { setShowLogoInput(true); setShowChangeLogoButton(false) }} label='Change Logo' />
          }
        </React.Fragment>
      )
    } else if (!showImageError && !showLogoImage) {
      
      return (
        <React.Fragment>
          <Flex boxSize='128px' direction='column' mb='3em' rounded='0.5em' justify='center' align='center' border='1px solid' borderColor='gray.100'>
            {currentLogoImage ? <Image 
              fallback={<LaunchdeckSpinner />}
              src={`${currentLogoImage}`} 
              onError={handleImageError} 
            /> :
            <Box fontSize='0.875em' color='gray.500'>
              No logo image
            </Box>
    }
          </Flex>
          {showChangeLogoButton &&
            <LaunchdeckButton bg='gray.500' color='white' onClick={() => { setShowLogoInput(true); setShowChangeLogoButton(false) }} label='Change Logo' />
          }
        </React.Fragment>
      )
    } else if (showImageError) {
      return (

        <React.Fragment>
          <Flex boxSize='128px' direction='column' mb='3em' justify='center' align='center' border='1px solid' borderColor='gray.100'>
            <Box display={showlogoLoader ? "block" : "none"}>
              <PageLoader />
            </Box>
            <Box>Can't find Logo</Box>
          </Flex>
          {showChangeLogoButton &&
            <LaunchdeckButton bg='gray.500' color='white' onClick={() => { setShowLogoInput(true); setShowChangeLogoButton(false) }} label='Change Logo' />
          }
        </React.Fragment>
      )
    } else return null;
  }


  const handleImageFound = () => {
    setShowLogoInput(false)
    setShowChangeLogoButton(false)
    setShowButtons(true)
    setShowlogoLoader(false)
  }



  return (
    <Flex direction='column' align='center' justify='center' w='100%' px='2em'>
      {imageIsSaving ? <PageLoader /> 
      :
      <React.Fragment>
        <Box>
          {displayAndEditLogoModule()}
        </Box>
  
        {showLogoInput && displaySearchLogoModule()}
  
        <Flex w='100%' py='0.625em'>
          <Flex justify='flex-end' w='100%'>
            {(showButtons && !showInvalidUrlError && !showImageError) &&
              <Flex w='100%' align='center' justify='center'>
                <LaunchdeckButton bg='gray.500' color='white' onClick={handleFindAnotherLogo} label='Find another logo' />
                <Box px='1em' />
                <LaunchdeckButton bg='brand.500' color='gray.50' onClick={handleSetOrgLogo} label='Use this logo' />
              </Flex>
            }
          </Flex>
        </Flex>
      </React.Fragment>
      }
    </Flex>
  )
  
 
}

function mapStateToProps(state) {
  return {
    imageIsSaving: state.setOrgLogo.isLoading,
    imageSaved: state.setOrgLogo.imageSet
  };
}

function mapDispatchToProps(dispatch) {
  return {
    action: bindActionCreators(
      {
        setOrgLogoImage
      },
      dispatch
    )
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrganizationLogoByUrl));
