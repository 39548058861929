import React from "react";

import { Box } from "@chakra-ui/react";
import { displayEmoji, getEmojiReactionList } from '../helpers/dsr-helpers';


export default function EmojiMenu({messageIndex, postReactions, handleAddRemoveMessageReaction, setShowEmojiMenu, postId}) {

  const emojiList = getEmojiReactionList();
  function handleChooseEmoji(reactionProps) {
    handleAddRemoveMessageReaction(reactionProps) 
    setShowEmojiMenu(false)
  }

  return (
    <React.Fragment>

      {emojiList.map((emojiName, emojiIndex) => {
       
        const relevantReaction = postReactions.find(reaction => reaction.reactionType === emojiName);
        const {reactionCount} = relevantReaction || {};
        
        // Only display the emoji if it hasnt been used before 
        if (!relevantReaction || reactionCount === 0) {
          
          const reactionProps = {
            ...relevantReaction,
            postId,
            reactionType: emojiName, 
            userMadeThisReaction: false //First time adding this reaction, value is false by default
          }
        
          return (
            <React.Fragment key={'reaction_'+ emojiIndex + '_' + messageIndex}>
              <Box cursor='pointer' px='0.5em' fontSize='1em' onClick={()=>handleChooseEmoji(reactionProps)}>  {displayEmoji(emojiName)} </Box>
            </React.Fragment>
          )
        } else return null;
  
       
      })}

    </React.Fragment>
  );

}
