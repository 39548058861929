import * as types from '../actions/user-notifications-action-types';



const INITIAL_USER_NOTIFICATIONS_INITIAL_STATE = {
	isLoading: false,
	results: {},
	error: '',
};

export function userNotificationsReducer(state = INITIAL_USER_NOTIFICATIONS_INITIAL_STATE, action) {
	switch (action.type) {
		// case types.USER_NOTIFICATIONS_OUTCOME_RESET:
		// 	return INITIAL_USER_NOTIFICATIONS_INITIAL_STATE
		case types.GET_USER_NOTIFICATIONS_REQUEST:
			return { ...state, isLoading: true, results: {} };
		case types.GET_USER_NOTIFICATIONS_SUCCESS:
			return { ...state, isLoading: false, results: action.payload };
		case types.GET_USER_NOTIFICATIONS_FAILURE:
			return { ...state, isLoading: false, error: 'Error getting user notifications' };
		default:
			return state;
	}
}

const MARK_VIEWED_USER_NOTIFICATION_INITIAL_STATE = {
	isLoading: false,
	results: {},
	error: '',
};

export function viewedUserNotificationReducer(state = MARK_VIEWED_USER_NOTIFICATION_INITIAL_STATE, action) {
	switch (action.type) {
		case types.MARK_USER_NOTIFICATION_AS_VIEWED_REQUEST:
			return { ...state, isLoading: true, results: {} };
		case types.MARK_USER_NOTIFICATION_AS_VIEWED_SUCCESS:
			return { ...state, isLoading: false, results: action.payload };
		case types.MARK_USER_NOTIFICATION_AS_VIEWED_FAILURE:
			return { ...state, isLoading: false, error: 'Error marking user notification as viewed' };
		default:
			return state;
	}
}

const MARK_VIEWED_ALL_USER_NOTIFICATIONS_INITIAL_STATE = {
	isLoading: false,
	results: "",
	error: '',
};

export function viewedAllUserNotificationsReducer(state = MARK_VIEWED_ALL_USER_NOTIFICATIONS_INITIAL_STATE, action) {
	switch (action.type) {
		case types.MARK_ALL_USER_NOTIFICATIONS_AS_VIEWED_REQUEST:
			return { ...state, isLoading: true, results: {} };
		case types.MARK_ALL_USER_NOTIFICATIONS_AS_VIEWED_SUCCESS:
			return { ...state, isLoading: false, results: action.payload };
		case types.MARK_ALL_USER_NOTIFICATIONS_AS_VIEWED_FAILURE:
			return { ...state, isLoading: false, error: 'Error marking all user notifications as viewed' };
		default:
			return state;
	}
}