import React, {useEffect, useState} from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { manageSignup } from '../../actions/auth-actions';
import { Box, Flex, Text, Grid} from '@chakra-ui/react';
import WarningTextMessage from '../../../global/components/helper-components/components/WarningTextMessage';
import FormTextInput from '../../../forms/TextInputField/components/FormTextInput';
import LaunchdeckButton from '../../../global/components/helper-components/components/LaunchdeckButton';
import { validateEmail } from '../../../global/helpers/global-helpers';
import PageLoader from '../../../global/components/helper-components/components/PageLoader';


function SignupForm({
	marketingUserTrackId, 
	prepopEmailDisplay, 
	prepopNameDisplay,
	setShowSignupLoader,
	// Actions
	manageSignup,
}) {	
	const [emailInput, setEmailInput] = useState("")
	const [nameInput, setNameInput] = useState("")

	const [isInvalidEmail, setIsInvalidEmail] = useState(false)
	// const [showSuccessState, setShowSuccessState]= useState(null)

	useEffect(() => {
		if (prepopEmailDisplay) {
			setEmailInput(prepopEmailDisplay)
		}
  },[prepopEmailDisplay]);
	

	useEffect(() => {
		if (prepopNameDisplay) {
			setNameInput(prepopNameDisplay)
		}
  },[prepopNameDisplay]);


	useEffect(() => {
		if (prepopEmailDisplay) {
			setEmailInput(prepopEmailDisplay)
		}
  },[prepopEmailDisplay]);
	

	useEffect(() => {
		if (prepopNameDisplay) {
			setNameInput(prepopNameDisplay)
		}
  },[prepopNameDisplay]);

	const handleChange = (event) => {

		if (event.target.name === "emailInput") {
			setEmailInput(event.target.value)
		} else if (event.target.name === "nameInput") {
			setNameInput(event.target.value)
		}
	}


	const createNewSignupUser = () => {
		const isValidEmail = validateEmail(emailInput.trim());
		const params = new URLSearchParams(window.location.search);
		const referralCode = params.has('ref') ? params.get('ref') : 'non-referral';

		if (!!isValidEmail) {
			setShowSignupLoader(true)

			manageSignup({
				event: 'create-signup-user',
				payload: {
					newUserEmail: emailInput.trim(),
					newUserName: nameInput,
					marketingUserTrackId,
					referralCode
				}
			})
		} else {
			setIsInvalidEmail(true)
		}
	}

	
	// if (showSuccessState) {
	// 	return (
	// 			<Flex p='1em' height='100%' justify='center' align='center'><span role="img" aria-label="party-face">🥳</span> <Box ml='0.5em'>Thanks! You're now on the waitlist and we'll contact you very soon.</Box> </Flex>
	// 	)
	// } else {
		return (
			<Flex maxW='350px' mx='auto' align='flex-start' h='100%'>
			<Grid my='5em' w='100%' templateRows="1fr 1fr auto 1fr" gap='0.5em'>	
					<Box mb='0.5em'>
						<FormTextInput name={'emailInput'} label={'Email'} sublabel={''} htmlFor={'emailInput'} mb='0.2em' placeholder='email@mycompany.com' value={emailInput && emailInput} onChange={handleChange} />
						<WarningTextMessage errorLogic={isInvalidEmail} message='Please provide a valid email' />
					</Box>
	
					<Box mb='0.5em'>
						<FormTextInput name={'nameInput'} label={'Your name'} sublabel={''} mb='0.2em' htmlFor={'nameInput'} placeholder='Alex Doe' value={nameInput && nameInput}  onChange={handleChange} />
						<Text visibility='hidden' fontSize='0.8em'>	&nbsp;</Text>
					</Box>
	
					<Box />
	
	
					<Flex w='100%' direction='column' mt='1em' justify='center' alignItems='center'>
						<Flex w='100%'>
							<LaunchdeckButton bg='brand.500' color='white' w='100%' onClick={() => createNewSignupUser()} label='Create Launchdeck account' />
						</Flex>
					</Flex>
			</Grid>
			</Flex>
		);
	// }
	
};


const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			manageSignup
		},
		dispatch,
	)


export default connect(null, mapDispatchToProps)(SignupForm);
