import React from "react";
import { Box, Flex } from "@chakra-ui/react";
import { HiOutlineUserAdd } from "react-icons/hi";


export default function AddMemberAvatarButton(props) {

  const { handleClickAddMemberButton, isMobileView } = props;
  const iconSize= isMobileView ? '0.8em' : '1em'
  return (
    <React.Fragment>
     <Flex boxSize={isMobileView ? '1.75em' : '2em'} cursor='pointer' onClick={()=>handleClickAddMemberButton()} rounded='50%' bg='brand.50' justify='center' align='center'>
      <Box as={HiOutlineUserAdd} h={iconSize} w={iconSize} color='brand.300'/>
     </Flex>
    </React.Fragment>
  );
  
}


