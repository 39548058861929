import React, { memo } from 'react'
import { Box, Flex } from '@chakra-ui/react'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { changeDsrModalState, changeDsrPopupState } from '../actions/dsr-actions'

import '../../layout/components/header/styles/header.scss';
import DsrManageOrgLogosModule from '../dsr-draft/DsrManageOrgLogosModule';
import DsrPageSideMenuDrawer from "./DsrPageSideMenuDrawer";
import LaunchdeckButton from '../../global/components/helper-components/components/LaunchdeckButton'


function StaticDsrHeaderMobileDisplay({
  isLoadingData,
  currentDsrTitle,
  // Action
  changeDsrPopupState,
  changeDsrModalState
}) {

  if (isLoadingData) {
    return null;
  }

  return (
    <>
      <Flex className='dsr-header__section'>
        <Flex className='dsr-header__mobile-sticky-top' alignItems='center'>
          <Flex justify='flex-start' align='center'>
            <Box display={['flex', 'flex', 'none', 'none']}>
              <DsrPageSideMenuDrawer />
            </Box>
          </Flex>

          <Flex>

            <Box>
              <Box rounded='0.25em'>
                <LaunchdeckButton label='Join Room' width='100%' bg='brand.500' color='white'
                  onClick={() => {
                    changeDsrPopupState("show-signup-login-prompt")
                    changeDsrModalState(true)
                  }} />
              </Box>
            </Box>
          </Flex>
        </Flex>
      </Flex>

      <Flex className='dsr-content__dsr-top-banner'>
        <Flex flexDirection={['column', 'column', 'row', 'row']} justifyContent='space-between' width='100%' alignItems='center' p='0 1em'>
          <Flex flexDirection={['column', 'column', 'row', 'row']} alignItems='center' >
            <Flex mr='1em'>
              <DsrManageOrgLogosModule logoDisplayMode={'default-dsr'} />
            </Flex>
            <Flex fontWeight='600' fontSize='1.25em' lineHeight='1.875em' justify='flex-start' textAlign='center'>
              {currentDsrTitle}
            </Flex>
          </Flex>

        </Flex>
      </Flex>
    </>
  )



}

const mapStateToProps = (state) => ({
  isLoadingData: state.publicDsrConfiguration.isLoading
})


const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      changeDsrPopupState,
      changeDsrModalState,
      // signOut
    },
    dispatch,
  )

export default memo(withRouter(connect(mapStateToProps, mapDispatchToProps)(StaticDsrHeaderMobileDisplay)))