import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';

import DefaultHeader from './DefaultHeader';
import { signOut } from '../../../../auth/actions/auth-actions';
import MobileHeader from './MobileHeader';

function Header(props)  {
	
		const {signOut} = props.action;
		const {displayMode, userAccountProfile, 
			// isMobileView
		 } = props || {};
		 
		if (displayMode === 'mobile') {
			return (
				<MobileHeader
					onPressLogout={signOut}
					className={props.className}
					history={props.history}
					userProfile={userAccountProfile}
					// isMobileView={isMobileView}
				/>
			);
		} else
			return (
				<DefaultHeader
					onPressLogout={signOut}
					className={props.className}
					history={props.history}
					userAccountProfile={userAccountProfile}
				/>
			);

}

function mapStateToProps(state) {
  return {
    userAccountProfile: state.userProfile.accountUserProfile,
		// isMobileView: state.mobileViewMode.isMobileView
  };
}

function mapDispatchToProps(dispatch) {
	return {
		action: bindActionCreators(
			{
				signOut
			},
			dispatch
		)
	};
}

Header.propTypes = {
	// user: PropTypes.string.isRequired,
	// userType: PropTypes.string.isRequired,
	className: PropTypes.string,
	// headerStyles: PropTypes.object,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
