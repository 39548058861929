import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';

import { sendContactLaunchdeckMessage, resetSendContactLaunchdeckMessage } from '../../marketing/actions/marketing-actions'
import { Flex, Box, useToast } from '@chakra-ui/react';

import WarningTextMessage from '../../global/components/helper-components/components/WarningTextMessage';
import FormTextInput from '../../forms/TextInputField/components/FormTextInput';
import LaunchdeckButton from '../../global/components/helper-components/components/LaunchdeckButton';
import { redirectToNewPage, triggerLaunchdeckToast } from '../../global/helpers/global-helpers';
import InputTextAreaExpandable from '../../forms/TextareaField/components/InputTextAreaExpandable';
import PageLoader from '../../global/components/helper-components/components/PageLoader';


function ContactLaunchdeckForm({
	contactLaunchdeckOutcome,
	contactLaunchdeckInProgress,
	isMobileView,
	sendContactLaunchdeckMessage,
	resetSendContactLaunchdeckMessage
}) {

	const toast = useToast();

	const [showSubmitted, setShowSubmitted] = useState(false)
	const [isInvalidEmail, setIsInvalidEmail] = useState(false)
	const [localState, setLocalState] = useState({
		userName: "",
		email: "",
		message: "",
	})

	// useEffect(() => {
	// 	if (location.state) {

	// 		const { prePopMessage } = location.state;

	// 		setLocalState(prevState => ({
	// 			...prevState,
	// 			message: prePopMessage
	// 		}));
	// 	}
	// }, [location.state]);

	useEffect(() => {
		if (contactLaunchdeckOutcome) {
			const { messageSentSuccessfully } = contactLaunchdeckOutcome || {}

			if (!!messageSentSuccessfully) {
				setShowSubmitted(true)
			} else {
				toast(
					triggerLaunchdeckToast({
						useCase: "show-error-state",
						label: "Unfortunately something went wrong. Please try again later.",
						isMobileView
					})
				)
			}

			resetSendContactLaunchdeckMessage()
		}
	}, [contactLaunchdeckOutcome, isMobileView, toast, resetSendContactLaunchdeckMessage]);



	const handleChange = (event) => {
		const value = event.target.value;

		if (event.target.name === "email") {
			setIsInvalidEmail(false)
		}

		setLocalState(prevState => ({
			...prevState,
			[event.target.name]: value
		}));
	}

	const handleSubmitForm = () => {
		const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(localState.email);

		if (!isValidEmail) {
			setIsInvalidEmail(true)
		} else {
			sendContactLaunchdeckMessage({
				userName: localState.userName,
				email: localState.email,
				message: localState.message
			})
		}
	}


	const { email, userName, message } = localState || {};

	if (contactLaunchdeckInProgress) {
		return <PageLoader />
	} else {
		return (
			<Flex justify='center' w='100%'>
				{showSubmitted === false ?
					<Flex my='0.5em' w='80%' justify='center'>
						<Flex direction='column' w='100%' maxW='35em'>
							<Box mt='1.5em' w='100%'>
								<FormTextInput name="userName" htmlFor='userName' label="Tell us your name" placeholder="" mb='0.2em' value={userName} onChange={handleChange} />
							</Box>
							<Box mt='1.5em' w='100%'>
								<FormTextInput name="email" htmlFor='email' label="Your email" placeholder="" mb='0.2em' value={email} onChange={handleChange} />
							</Box>

							<Box mt='1.5em' w='100%'>
								<Box mb='0.8em' lineHeight='1.275em' fontSize='0.875em' fontWeight='500' color={'gray.700'}>
									Tell us what you're thinking
								</Box>
								<InputTextAreaExpandable
									name={'message'}
									onChangeHandler={handleChange}
									inputValue={message}
									placeholder={`Your thoughts here...`}
									border={'1px solid #E5EAEF'}
								/>
							</Box>

							<WarningTextMessage errorLogic={isInvalidEmail} message='Please use a valid email' />


							<Flex direction='column' mt='3em' justify='center' alignItems='center'>
								<Flex justify='center' w='100%'>
									<LaunchdeckButton bg='brand.500' color='white' px='5em' w={["100%", "100%", "auto", "auto"]} onClick={() => handleSubmitForm()} label='Send message' />
								</Flex>
							</Flex>

						</Flex>
					</Flex>
					: <Flex direction='column' w={['100%', '100%', '60%', '60%']} >
						<Box border='1px' borderColor='brand.500' rounded={8} bg='white' px='2%' py='0.8em' mt='2em' my='2em' >
							<Box textAlign='center' className='flip-in-hor-bottom'>   <span role="img" aria-label="ok-hand"> 👌</span> We have received your message  </Box>
							<Box mt='1em' textAlign='center' fontSize='0.8em'>Meanwhile, how about signing up for free or log in to take Launchdeck for a quick spin? </Box>
						</Box>
						<Flex alignItems='center' justify='center' mt='2em'>
							<LaunchdeckButton bg='brand.500' color='white' onClick={() => redirectToNewPage('/home')} label='OK' />
						</Flex>

					</Flex>
				}
			</Flex>
		);
	}


};


function mapStateToProps(state) {
	return {
		contactLaunchdeckOutcome: state.contactLaunchdeckOutcome.results.data,
		contactLaunchdeckInProgress: state.contactLaunchdeckOutcome.isLoading,

		isMobileView: state.mobileViewMode.isMobileView
	};
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			sendContactLaunchdeckMessage,
			resetSendContactLaunchdeckMessage
		},
		dispatch,
	)

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContactLaunchdeckForm));