import { getThisUserIdToken } from "../../global/helpers/global-helpers";
import NotificationUserPost from '../styles/images/user-post-notification-icon.png'
import DefaultNotificationIcon from '../styles/images/default-notification-icon.svg'
import ReplyNotificationIcon from '../styles/images/post-reply-notification-icon.svg'
import UserTagIcon from '../styles/images/user-tag-notification-icon.png'
import ShareDocIcon from '../styles/images/share-doc-notification-icon.png'
import AnswerQuestionIcon from '../styles/images/answer-question-notification-icon.png'
import AskQuestionIcon from '../styles/images/ask-question-notification-icon.png'
import ListIcon from '../styles/images/list-notification-icon.png'
import MembershipIcon from '../styles/images/membership-icon.png'
import PublishIcon from '../styles/images/publish-notification-icon.png'
import ProspectQueryIcon from '../styles/images/new-prospect-query-icon.png'

export function determineNotificationMessage(notification) {
  const {notificationType, dsrTitle, actionTakerUserId, actionTakerName, inviteeEmail} = notification || {}
  const thisUserIdToken = getThisUserIdToken()
  const isSameUser = (actionTakerUserId && thisUserIdToken === actionTakerUserId) ?  true : false;
  let message = "";

  switch(notificationType) {
    case("dsr-published"): {
      if (actionTakerName && dsrTitle) {
        if (isSameUser) {
          message = <><strong>You</strong> have published <strong>{dsrTitle}</strong></>
        } else {
          message = <><strong>{actionTakerName}</strong> had published <strong>{dsrTitle}</strong></>
        }
      }
      break;
    }
    case("dsr-republished"):{
      if (actionTakerName && dsrTitle) {
        if (isSameUser) {
          message = <><strong>You</strong> have republished <strong>{dsrTitle}</strong></>
        } else {
          message = <><strong>{actionTakerName}</strong> has republished <strong>{dsrTitle}</strong></>
        }
      }
      break;
    }
    case("dsr-reverted-draft"):{
      if (actionTakerName && dsrTitle) {
        if (isSameUser) {
          message =  <><strong>You</strong> have unpublished <strong>{dsrTitle}</strong>. It is now back in draft mode.</>
        } else {
          message =  <><strong>{actionTakerName}</strong> has unpublished <strong>{dsrTitle}</strong> for the time being</>
        }
      }
      break;
    }
    case("member-left-dsr"): {
      if (actionTakerName && dsrTitle) {
        if (isSameUser) {
          message = <><strong>You</strong> have left <strong>{dsrTitle}</strong></>
        } else {
          message = <><strong>{actionTakerName}</strong> had left <strong>{dsrTitle}</strong></>
        }
      }
      break;
    }
    case("member-joined-dsr"): {

      if ((actionTakerName || inviteeEmail) && dsrTitle) {
        if (isSameUser) {
          message = <><strong>You</strong> have joined <strong>{dsrTitle}</strong></>
        } else {
          if (actionTakerName) {
            message = <><strong>{actionTakerName}</strong> is now a member of <strong>{dsrTitle}</strong></>
          } else if (inviteeEmail) {
            message = <><strong><i>{inviteeEmail}</i></strong> has been invited to <strong>{dsrTitle}</strong></>
          }
         
        }
      }
      break;
    }
    case("dsr-post-created"): {
      if (actionTakerName && dsrTitle) {
        if (isSameUser) {
          message = <><strong>You</strong> posted something new in  <strong>{dsrTitle}'s</strong> Updates page</>
        } else {
          message = <><strong>{actionTakerName}</strong> posted something new in <strong>{dsrTitle}'s</strong> Updates page</>
        }
      }
      break;
    }
    
    case("dsr-post-reply-created"): {
      if (actionTakerName && dsrTitle) {
        if (isSameUser) {
          message = <><strong>You</strong> replied to a post in  <strong>{dsrTitle}</strong></>
        } else {
          message = <><strong>{actionTakerName}</strong> replied to a post in <strong>{dsrTitle}</strong></>
        }
      }
      break;
    }

    case("dsr-faq-question-created"): {
      if (actionTakerName && dsrTitle) {
        if (isSameUser) {
          message = <><strong>You</strong> asked a new question in  <strong>{dsrTitle}'s</strong> FAQ page</>
        } else {
          message = <><strong>{actionTakerName}</strong> asked a new question in <strong>{dsrTitle}'s</strong> FAQ page</>
        }
      }
      break;
    }

    case("dsr-faq-answer-created"): {
      if (actionTakerName && dsrTitle) {
        if (isSameUser) {
          message = <><strong>You</strong> answered a question in  <strong>{dsrTitle}'s</strong> FAQ page</>
        } else {
          message = <><strong>{actionTakerName}</strong> answered a question in <strong>{dsrTitle}'s</strong> FAQ page</>
        }
      }
      break;
    }
    
    case("dsr-new-shared-doc"): {
      if (actionTakerName && dsrTitle) {
        if (isSameUser) {
          message = <><strong>You</strong> shared a new document with everyone in  <strong>{dsrTitle}</strong>. View it in the Shared Document page</>
        } else {
          message = <><strong>{actionTakerName}</strong> has just shared a new document with everyone in <strong>{dsrTitle}</strong>. View it in the Shared Document page</>
        }
      }
      break;
    }

    case("dsr-action-item-created"): {
      if (actionTakerName && dsrTitle) {
        if (isSameUser) {
          message = <><strong>You</strong> created a new action item in <strong>{dsrTitle}</strong></>
        } else {
          message = <><strong>{actionTakerName}</strong> created a new action item in <strong>{dsrTitle}</strong></>
        }
      }
      break;
    }
    case("dsr-map-item-assigned-to-user"): {
      if (actionTakerName && dsrTitle) {
        message = <><strong>{actionTakerName}</strong> has assigned an action item to you in <strong>{dsrTitle}</strong></>
      }
      break;
    }
    case("tagged-in-dsr-post"): {
      if (actionTakerName && dsrTitle) {
        message = <><strong>{actionTakerName}</strong> tagged you in a new post in <strong>{dsrTitle}</strong></>
      }
      break;
    }

    case("tagged-in-dsr-reply"): {
      if (actionTakerName && dsrTitle) {
        message = <><strong>{actionTakerName}</strong> tagged you in a reply in <strong>{dsrTitle}</strong></>
      }
      break;
    }

    case("new-prospector-query"): {
      if (actionTakerName && dsrTitle) {
        message = <>You received a new prospector query in <strong>{dsrTitle}</strong></>
      }
      break;
    }
    default: {
      
      break;
    }
  }

  return message
}

export function determineNotificationImage(notificationType) {
  let image = null;
  switch(notificationType) {
    case("dsr-republished"):
    case("dsr-published"): {
      image = PublishIcon;
      break;
    }
    // case("dsr-reverted-draft"):{
  
    //   break;
    // }
    case("member-left-dsr"):
    case("member-joined-dsr"): {
      image = MembershipIcon
    break;
    }
    case("dsr-post-created"): {
      image = NotificationUserPost;
      break;
    }
    
    case("dsr-post-reply-created"): {
      image = ReplyNotificationIcon;
      break;
    }

    case("dsr-faq-question-created"): {
     image = AskQuestionIcon
      break;
    }

    case("dsr-faq-answer-created"): {
    image = AnswerQuestionIcon
      break;
    }
    
    case("dsr-new-shared-doc"): {
      image = ShareDocIcon;
      break;
    }
    case("dsr-map-item-assigned-to-user"): 
    case("dsr-action-item-created"): {
      image = ListIcon;
      break;
    }
    case("tagged-in-dsr-reply"):
    case("tagged-in-dsr-post"): {
   image = UserTagIcon
      break;
    }

    case("new-prospector-query"): {
    image = ProspectQueryIcon;
      break;
    }
    default: {
      image = DefaultNotificationIcon
      break;
    }
  }

  return image
 
}

