import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';

import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  useToast
} from "@chakra-ui/react";
import '../styles/dsr-display.scss';
import { MdError } from "react-icons/md";
import { updateDsr, editDsrTeamMembers, changeDsrModalState, changeDsrPopupState, editDsrTeamMembersReset } from '../actions/dsr-actions';
import { getThisUserIdToken, redirectToNewPage, triggerLaunchdeckToast } from '../../global/helpers/global-helpers';
import PageLoader from '../../global/components/helper-components/components/PageLoader';
import LaunchdeckButton from '../../global/components/helper-components/components/LaunchdeckButton';
import LaunchdeckCloseButton from '../../global/components/helper-components/components/LaunchdeckCloseButton';



function DsrConfirmRemoveMemberModal(props) {

  const { modalTransferPayload, handleCloseModal, handleChangeUserActionRequired,
    dsrConfiguration, updateDsrOutcome, editDsrTeamMembersOutcome,
    isMobileView } = props || {};
  const { removedUserId, role } = modalTransferPayload || {}
  const { dsrId, dsrOwnerId } = dsrConfiguration || {};
  const thisUserId = getThisUserIdToken();
  const isDsrOwner = (dsrOwnerId && (dsrOwnerId === thisUserId)) ? true : false;
  const isRemovingOwner = (removedUserId && (removedUserId === dsrOwnerId)) ? true : false;
  const isRemovingSelf = (removedUserId === thisUserId) ? true : false;


  // UX Management
  const toast = useToast();
  const [showLoader, setShowLoader] = useState(false)

  // Determine team
  const teamToUpdate = (role === 'buyer-member') ? "buyer-team" : "seller-team";

  useEffect(() => {
    if (updateDsrOutcome) {
      const { updateDsrStatus, sourceEvent } = updateDsrOutcome || {};
      setShowLoader(false);

      if (updateDsrStatus && sourceEvent==="remove-dsr-member") {
        handleCloseModal()
      }
    }

  }, [updateDsrOutcome, handleCloseModal]);

  useEffect(() => {
    if (editDsrTeamMembersOutcome) {
      const {updatedTeamMembers, removeUserAccessToRoom, statusMessage} = editDsrTeamMembersOutcome || {}

      if (removeUserAccessToRoom === true) {
        redirectToNewPage('/home')
      }

      if (updatedTeamMembers=== true) {
        setShowLoader(false)
        handleChangeUserActionRequired('view-team-members', null)

        toast(
          triggerLaunchdeckToast({
          useCase: "show-success-state",
          bannerText: "Member removed",
          label: statusMessage, 
          isMobileView
          })
        )

      } else {
        handleCloseModal();

        toast(
          triggerLaunchdeckToast({
          useCase: "show-error-state",
          bannerText: "Unexpected error",
          label: "Member cannot be removed at this time. Please try again later", 
          isMobileView
          })
        )
      }

      props.action.editDsrTeamMembersReset()
    }

  }, [editDsrTeamMembersOutcome, toast, handleCloseModal, handleChangeUserActionRequired]);

  const handleConfirmRemoveMember = () => {
    setShowLoader(true);

    const updateParams = {
      teamToUpdate, // Need to decide whether to remove it from the buying or the selling team
      updateAction: 'remove-member',
      userId: removedUserId // userId of the person who is picked
    }
    const updateTeamParams = { dsrId, updateParams }

    props.action.editDsrTeamMembers(updateTeamParams)
  }

  const handleConfirmRemoveOwner = () => {
    setShowLoader(true);

    // Instead of changing members, hange the status of the DSR to 'archived'
    // Archive status allows team members to continue viewing information inside DSR but not take any actions
    props.action.updateDsr({
      dsrId,
      status: 'archived',
      sourceEvent: "remove-dsr-member"
    })
  }

  function displayContent() {
    if (showLoader) {
      return (
        <Box w="100%" align="center" >
          <Box px='1em'>
            <Box>
              <PageLoader />
            </Box>
          </Box>
        </Box>
      )
    }

    if (!showLoader) {
      if (isDsrOwner && isRemovingOwner) {
        return (
          <Flex justify='flex-start' px='1.5em' mt='1em' w='100%'>
            <Box w='100%'>

              <Box w="100%" my="1em" color='brandDark.500'>
                <Box>
                  As the owner of this Room, are you sure about removing yourself and archiving this Room?
                </Box>
                {/* // Future Scope: Allowing owner to nominate a new owner for the Room */}


              </Box>

              <Flex w='100%' mt='1em'>
                <Flex my='0.625em' justify='flex-end' w='100%'>
                  <LaunchdeckButton bg='gray.700' color='white' onClick={()=> handleConfirmRemoveOwner()} label='Confirm' />
                </Flex>
              </Flex>
            </Box>


          </Flex>
        )
      }

      if (isRemovingOwner && !isDsrOwner) {
        return (
          <Flex justify='flex-start' px='1.5em' mt='1em' w='100%'>
            <Box w='100%'>

              <Box w="100%" my="1em" color='brandDark.500'>
                <Box>
                  You are trying to remove the owner of this Room. Please contact them about removing themselves from the Room if necessary
                </Box>
                {/* // Future Scope: Mention the identity of the Room owner */}


              </Box>

              <Flex w='100%' mt='1em'>
                <Flex my='0.625em' justify='flex-end' w='100%'>
                  <LaunchdeckButton bg='gray.700' color='white' onClick={() => handleChangeUserActionRequired('view-team-members', null)} label='Back' />
                </Flex>
              </Flex>
            </Box>


          </Flex>
        )
      }

      // Default scenario
      return (
        <Flex justify='flex-start' px='1.5em' mt='1em' w='100%'>
          <Box w='100%'>

            <Box w="100%" my="1em" color='brandDark.500'>
              {(isRemovingSelf && !isDsrOwner) ?
                <Box fontSize='0.875em'>
                   Are you sure about removing yourself from this Room?
                </Box>
                :
                <Box fontSize='0.875em'>
                  Are you sure about removing this member? They will no longer have access to the Room.
                </Box>
              }

            </Box>

            <Flex w='100%' mt='1em'>
              <Flex my='0.625em' justify='flex-end' w='100%'>
                <LaunchdeckButton bg='gray.700' color='white' onClick={()=> handleConfirmRemoveMember()} label='Confirm' />
              </Flex>
            </Flex>
          </Box>


        </Flex>
      )
    }
  }

  return (
    <Modal isOpen={true} closeOnOverlayClick={false} isCentered={true} preserveScrollBarGap={true} >
      <ModalOverlay className="modal-background" />
      <ModalContent rounded='0.25em' minW={['90%', '500px']} maxW='700px'>
        <ModalBody px='0em'>
          <Flex direction='column' justify='space-around' alignItems='center' w='100%'>
            <Flex justify='space-between' w='100%' borderBottom='1px solid' borderBottomColor='gray.300'>
              <Flex px='1em' justify='flex-start' align='center'>
                <Box as={MdError} color='pink.500'
                  boxSize="1.6em" />
                <Box px='1.5em' py='0.5em' color='brandDark.500'>{isRemovingOwner ? "Leave Room" : "Remove member"}</Box>
              </Flex>

              <Box pr='1.5em'>
                <LaunchdeckCloseButton handleCloseModal={() => handleCloseModal()} />
              </Box>
            </Flex>

            <React.Fragment>
              {displayContent()}
            </React.Fragment>

          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

function mapStateToProps(state) {
  return {
    updateDsrOutcome: state.updateDsrOutcome.results.data,
    dsrConfiguration: state.dsrConfiguration.results.data,
    editDsrTeamMembersOutcome: state.editDsrTeamMembersOutcome.results.data,
    isMobileView: state.mobileViewMode.isMobileView
  };
}

function mapDispatchToProps(dispatch) {
  return {
    action: bindActionCreators(
      {
        updateDsr,
        editDsrTeamMembers,
        editDsrTeamMembersReset,
        // Remove User Confirmation
        // Checks if user is the owner. If so, warn that he will close the DSR
        
			changeDsrPopupState,
			changeDsrModalState,
      },
      dispatch
    )
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DsrConfirmRemoveMemberModal));
