import React from 'react'
import { Flex } from '@chakra-ui/react';
import { optionTypes } from "../types/subWidgetOptionsTypes";

export const ContentSpacer = ({ options }) => {
  return (
    <Flex 
      bg='#FAFAFA' 
      width='90%' 
      height={options[optionTypes.HEIGHT]} 
      border='1px dashed'
      borderColor='gray.300'
      letterSpacing='1px'
      justify='center'
      align='center'
      color='gray.400'
      rounded='0.25em'
    >
      SPACER
    </Flex>
  )
};
