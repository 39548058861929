import React, { useState, useEffect } from 'react';
import { Flex, Box, Stack, useToast } from '@chakra-ui/react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';

import { updateOrgProfile, updateOrgProfileReset } from '../../../organizations/actions/organizations-actions';
import ProfileFormTextAreaInput from '../../../forms/TextInputField/components/ProfileFormTextAreaInput';
import ProfileFormTextInput from '../../../forms/TextInputField/components/ProfileFormTextInput';
import WarningTextMessage from '../../../global/components/helper-components/components/WarningTextMessage';
import ClaverModalButtonBar from '../../../global/components/helper-components/components/ClaverModalButtonBar';
import { triggerLaunchdeckToast } from '../../../global/helpers/global-helpers';


function EditOrgInfoForm(props) {

	const { orgProfile, handleCloseModal, manageAdminOrgProfileOutcome, isMobileView, getAdminOrgProfile } = props || {};
	const toast = useToast();

	const { orgName, orgId, orgDescription } = orgProfile || {}
	const [editingInProgress, setEditingInProgress] = useState(false);

	// Inputs
	const [orgNameDisplay, setOrgNameDisplay] = useState(orgName)
	const [orgDescriptionDisplay, setOrgDescriptionDisplay] = useState(orgDescription)


	// Input validation Errors
	const [showMissingorgNameError, setShowMissingorgNameError] = useState(false)

	// UX & State Management
	useEffect(() => {
		if (manageAdminOrgProfileOutcome) {
			const { updatedSuccessfully, errorMessage } = manageAdminOrgProfileOutcome || {};

			const closeModalAndReset = () => {
				setEditingInProgress(false)
				handleCloseModal()
				props.action.updateOrgProfileReset()
			}

			if (updatedSuccessfully === true) {
				getAdminOrgProfile()

				toast(
					triggerLaunchdeckToast({
						useCase: "show-success-state",
						// bannerText: "",
						label: "Your organization info has been updated",
						isMobileView
					})
				)

				closeModalAndReset()
			}

			if (updatedSuccessfully === false) {
				if (errorMessage) {

					toast(
						triggerLaunchdeckToast({
							useCase: "show-error-state",
							bannerText: "Error",
							label: errorMessage,
							isMobileView
						})
					)

					closeModalAndReset()
				}
			}
		}
	}, [manageAdminOrgProfileOutcome, props.action, handleCloseModal, toast]);

	// Component functionality
	const handleChange = (event) => {
		const value = event.target.value;
		const variableName = event.target.name;
		switch (variableName) {
			case ('orgName'): {
				setOrgNameDisplay(value)
				break;
			}
			case ('orgDescription'): {
				setOrgDescriptionDisplay(value)
				break;
			}
			default: {
				console.log('There is no variable with this name', variableName)
				break;
			}
		}
	}

	const handleSubmitEditProfile = () => {
		if (!orgNameDisplay) {
			setShowMissingorgNameError(true)
		} else if (orgNameDisplay) {
			setShowMissingorgNameError(false)
		}

		if (orgNameDisplay) {
			setEditingInProgress(true)

			let updateOrgUserProperties = {
				orgName: orgNameDisplay.trim(),
				orgDescription: orgDescriptionDisplay ? orgDescriptionDisplay.trim() : ""
			}

			props.action.updateOrgProfile(updateOrgUserProperties, orgId, 'edit-org-profile-info')
		}
	}


	return (
		<Box overflowY='auto' maxHeight='75vh' >
			<Stack w='100%' px='2em' py='1em'>
				<Flex direction='column' w='100%'>
					<ProfileFormTextInput name="orgName" label="Organization Name" htmlFor="orgName" variant="outline" defaultValue={orgNameDisplay} value={orgNameDisplay} onChange={handleChange} />
					<Box mt='0.5em'>
						<WarningTextMessage errorLogic={showMissingorgNameError} message='Missing Required Field' />
					</Box>
				</Flex>
				<Flex direction='column' w='100%'>
					<ProfileFormTextAreaInput name="orgDescription" label="About" htmlFor="orgDescription" variant="outline" value={orgDescriptionDisplay} charLimit='250' onChange={handleChange} />
				</Flex>
			</Stack>

			<Box mt='2em'>
				<ClaverModalButtonBar
					handleCloseModal={handleCloseModal}
					loadingLogic={editingInProgress}
					ctaFunction={() => handleSubmitEditProfile()}
					ctaLabel='Save Changes'
					cancelLabel='Cancel'
				/>
			</Box>
		</Box>

	);
}

function mapStateToProps(state) {
	return {
		manageAdminOrgProfileOutcome: state.manageAdminOrgProfileOutcome.results.data,
		isMobileView: state.mobileViewMode.isMobileView
	};
}

function mapDispatchToProps(dispatch) {
	return {
		action: bindActionCreators(
			{
				updateOrgProfile,
				updateOrgProfileReset,
			},
			dispatch
		)
	};
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditOrgInfoForm));