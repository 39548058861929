import React from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import SetNewPasswordForm from './SetNewPasswordForm';
import { ChakraBaseProvider, Flex, Box} from '@chakra-ui/react';
import claverTheme from '../../layout/components/layout/components/theme/claverTheme';
import GeneralPageFooter from './GeneralPageFooter';
import PublicUserHeader from './PublicUserHeader';
import { redirectToNewPage } from '../../global/helpers/global-helpers';
import LaunchdeckButton from '../../global/components/helper-components/components/LaunchdeckButton';


function SetNewPasswordPage(props) {

		const resetPasswordToken = props.match.params.resetPasswordToken;
		const {updateStatus} = props.setNewPasswordOutcome || {};

		const renderContent = () => {
			if (updateStatus === true) {
				return (
					<React.Fragment>
					<Box textAlign='center'>Your password has been reset. Try logging in with your new password now.</Box>
						<Flex align='center' justify='center' mt='3%'>
							<LaunchdeckButton bg='brand.500' color='white' onClick={() => redirectToNewPage('/login')} label='Log in' />
								</Flex>
					</React.Fragment>
				)
			} 

			if (updateStatus === false) {
				return (
					<React.Fragment>
						<Box my='3%' textAlign='center' fontSize='1.2em'>
							The previous request to set a new password has expired. 
						</Box>
						<Box my='3%' textAlign='center' fontSize='0.9em'>
							Please retry your request to change your password.
						</Box>
						<Flex align='center' justify='center' mt='3%'>
						<LaunchdeckButton bg='gray.200' color='gray.700' onClick={() => redirectToNewPage('/login')} label='Go back' />

						</Flex>
					</React.Fragment>
				)
			} 

			if (updateStatus === undefined || !updateStatus) {
				return (
					<SetNewPasswordForm resetPasswordToken={resetPasswordToken} />
				)
			}
		}

		return (
			<ChakraBaseProvider theme={claverTheme}>
				{/* <CSSReset /> */}
				<PublicUserHeader />
				<Box w='100%' px='15%' className="authentication-container">

					<Flex direction='column' h='100%' pb='2%'>

						<Flex direction='row' py='5em'>
							<Flex className="default-box-shadow" flex='5' w='100%' direction='column' alignItems='center' bg='white' rounded='0.8em' py='2em'>
								{renderContent()}
							</Flex>

						</Flex>

						<GeneralPageFooter />

					</Flex>
				</Box>
			</ChakraBaseProvider>
		);
	
}


function mapStateToProps(state) {
  return {
    setNewPasswordOutcome: state.setNewPasswordOutcome.results.data,
  };
}


export default withRouter(connect(mapStateToProps, null)(SetNewPasswordPage));
