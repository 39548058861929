import React, { useState, useEffect } from 'react';

import { Box, Modal, ModalOverlay, ModalBody, ModalContent, Flex } from "@chakra-ui/react";

import { RiDeleteBin2Fill } from "react-icons/ri";
import LaunchdeckCloseButton from '../../global/components/helper-components/components/LaunchdeckCloseButton';
import LaunchdeckButton from '../../global/components/helper-components/components/LaunchdeckButton';


export default function DsrConfirmationModal(props) {

  const {
    postIdToEdit,
    replyIdToEdit,
    confirmationType,
    handleCloseConfirmationModal,

    // Different function options,
    handleDeleteMessagePost,
    handleDeleteReply
  } = props;


  const [accentColor, setAccentColor] = useState('brandDark.500');
  const [confirmationTitle, setConfirmationTitle] = useState('');
  const [confirmationContent, setConfirmationContent] = useState('');
  const [confirmationButtonLabel, setConfirmationButtonLabel] = useState('');

  useEffect(() => {
    if (confirmationType) {
      switch (confirmationType) {
        case ('confirm-delete-post'): {
          setAccentColor('red.400') //#F56565
          setConfirmationTitle('Delete post')
          setConfirmationContent('Are you sure about deleting this post? This action cannot be undone.')
          setConfirmationButtonLabel('Delete post')
          break;
        }
        case ('confirm-delete-reply'): {
          setAccentColor('red.400') //#F56565
          setConfirmationTitle('Delete reply')
          setConfirmationContent('Are you sure about deleting your reply? This action cannot be undone.')
          setConfirmationButtonLabel('Delete reply')
          break;
        }
        default: {
          console.log(`ERROR: There is no such confirmationType: ${confirmationType}`)
          break
        }
      }
    }
  }, [confirmationType, handleDeleteMessagePost]);


  const renderConfirmationModalContent = () => {
    switch (confirmationType) {
      case ('confirm-delete-post'): {
        return (
          <React.Fragment>
            <Flex justify='space-between' align='center' w='100%' >
              <Flex p='0.5em 1em' justify='flex-start' align='center'>
                <Box as={RiDeleteBin2Fill} color={accentColor} boxSize="1em" />
                <Box ml='0.5em' fontWeight='500' fontSize='1.15em' lineHeight='1.375em' color='brandDark.500'>{confirmationTitle}</Box>
              </Flex>
              <LaunchdeckCloseButton handleCloseModal={handleCloseConfirmationModal} />
            </Flex>

            <Flex justify='center' mt='1em' mb='1.6em' w='100%'>
              <Box w="100%" color='brandDark.500' p="1em" fontSize='0.875em' textAlign={['center', 'center', 'left', 'left']}>
                {confirmationContent}
              </Box>
            </Flex>

            <Flex w='100%' >
              <Flex my='0.625em' justify='space-between' w='100%' p='0 0.5em'>
                <LaunchdeckButton bg='gray.200' color='brandDark.500' fontSize='0.8em' onClick={handleCloseConfirmationModal} label='Cancel' />
                <LaunchdeckButton bg={accentColor} color='white' fontSize='0.8em' onClick={() => handleDeleteMessagePost(postIdToEdit)} label={confirmationButtonLabel} />
              </Flex>
            </Flex>
          </React.Fragment>
        )
      }
      case ('confirm-delete-reply'): {
        return (
          <React.Fragment>
            <Flex justify='space-between' align='center' w='100%' >
              <Flex p='0.5em  1em' justify='flex-start' align='center'>
                <Box as={RiDeleteBin2Fill} color={accentColor} boxSize="1em" />
                <Box ml='1em' fontWeight='500' fontSize='1.25em' lineHeight='1.375em' color='brandDark.500'>{confirmationTitle}</Box>
              </Flex>
              <LaunchdeckCloseButton handleCloseModal={handleCloseConfirmationModal} />
            </Flex>

            <Flex justify='center' mt='1em' mb='1.6em' w='100%'>
              <Box w="100%" my="2em" px='1em' color='brandDark.500'>
                {confirmationContent}
              </Box>
            </Flex>

            <Flex w='100%' >
              <Flex my='0.625em' justify='space-between' w='100%'>
                <LaunchdeckButton bg='gray.200' color='brandDark.500' fontSize='0.8em' onClick={handleCloseConfirmationModal} label='Cancel' />
                <LaunchdeckButton bg={accentColor} color='white' fontSize='0.8em' onClick={() => handleDeleteReply({ postIdToEdit, replyIdToEdit })} label={confirmationButtonLabel} />
              </Flex>
            </Flex>
          </React.Fragment>
        )
      }

      default: {
        console.log('There is no associated action with this confirmation type:', confirmationType)
        return null
      }
    }
  }

  return (
    <Modal isOpen={true} closeOnOverlayClick={false} isCentered={true} preserveScrollBarGap={true}>
      <ModalOverlay />
      <ModalContent rounded='0.25em' minW={['90%', '500px']} maxW='700px'>
        <ModalBody>
          <Flex direction='column' justify='space-around' alignItems='center' w='100%'>
            {renderConfirmationModalContent()}
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )

}
