import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { launchdeckLogin } from '../actions/auth-actions';
// import { validateLogin } from '../helpers/auth-validation';
import { Box, Flex } from '@chakra-ui/react';
import LaunchdeckButton from '../../global/components/helper-components/components/LaunchdeckButton';
import FormTextInput from '../../forms/TextInputField/components/FormTextInput';
import { connect } from 'react-redux';
import WarningTextMessage from '../../global/components/helper-components/components/WarningTextMessage';
import ValidationWarningBanner from '../../global/components/helper-components/components/ValidationWarningBanner';
import PageLoader from '../../global/components/helper-components/components/PageLoader';
import { redirectToHomepageIfLoggedIn } from '../helpers/auth-redirect';


function LoginForm({ prepopEmail, launchdeckLogin, loginFeedback }) {

	const [loginProcessInProgress, setLoginProcessInProgress] = useState(false)
	const [emailInput, setEmailInput] = useState("")
	const [passwordInput, setPasswordInput] = useState("")

	const [showEmailError, setShowEmailError] = useState(false)
	const [emailErrorMessage, setEmailErrorMessage] = useState("")

	const [showPasswordError, setShowPasswordError] = useState(false)
	const [passwordErrorMessage, setPasswordErrorMessage] = useState("")

	// Feedback output
	const [errorMessageDisplay, setErrorMessageDisplay] = useState("")
	const [displayLoginError, setDisplayLoginError] = useState(false)

	useEffect(() => {
		if (prepopEmail) {

			setEmailInput(prepopEmail)

		}
	}, [prepopEmail]);


	useEffect(() => {
		if (loginFeedback) {

			const { results, loading } = loginFeedback || {};
			setLoginProcessInProgress(loading === true ? true : false)

			const { data: logingErrorFeedback } = results || {};
			const { isLoginError, errorPayload } = logingErrorFeedback || {};
			if (isLoginError === true) {
				setDisplayLoginError(true)
				setErrorMessageDisplay(errorPayload.errorMessage ? errorPayload.errorMessage : "There was an unexpected error. Please try again later.")
			} else {
				redirectToHomepageIfLoggedIn()
			}

		}
	}, [loginFeedback]);

	const onPressEnterAndLogin = (e) => {
		if (e.key === 'Enter') {
			handleSubmit();
		}
	}

	const handleChange = (event) => {

		if (event.target.name === "emailInput") {
			setEmailInput(event.target.value)
			setShowEmailError(false)
		} else if (event.target.name === "passwordInput") {
			setPasswordInput(event.target.value)
			setShowPasswordError(false)
		}
	}

	const handleSubmit = () => {
		if (!emailInput) {
			setShowEmailError(true)
			setEmailErrorMessage('Email Required')

		} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(emailInput)) {
			setShowEmailError(true)
			setEmailErrorMessage('Invalid email address')
		}

		if (!passwordInput) {
			setShowPasswordError(true)
			setPasswordErrorMessage('Password Required')

		} else if (passwordInput.length < 6) {
			setShowPasswordError(true)
			setPasswordErrorMessage('Please provide a valid password')
		}

		if (emailInput && passwordInput && !showEmailError && !showPasswordError) {
			launchdeckLogin(emailInput, passwordInput)
		}

	}

	if (loginProcessInProgress) {
		return (
			<Box px='1.5em'>
				<PageLoader />
			</Box>
		)
	}

	return (
		<Box px='1.5em'>
			<Box mb='0.5em' w='100%' mt='3%' onKeyDown={(e) => onPressEnterAndLogin(e)}>
				{/* <form className="authentication-form__container" onSubmit={props.handleSubmit} onKeyDown={(e) => onPressEnterAndLogin(e)}> */}
				<Box className="authentication-form__container">
					<Flex direction='column' w='100%' >
						<Box mb='0.1em'>

							<FormTextInput name={'emailInput'} label={'Email'} sublabel={''} htmlFor={'emailInput'} mb='0.2em' value={prepopEmail ? prepopEmail : emailInput} onChange={handleChange} />
							<WarningTextMessage errorLogic={showEmailError} message={emailErrorMessage} />
						</Box>


						<Box mt='1em' mb='0.1em' position='relative'>
							{/* <Flex justify='flex-end' color='gray.500' fontSize='0.7em' position='absolute' top='1em' right='0.5em'>
								<Link to="/reset-password">Forgot Password?</Link>
							</Flex> */}

							<FormTextInput name={'passwordInput'} label={'Password'} sublabel={''} htmlFor={'passwordInput'} type='password' mb='0.2em' onChange={handleChange} />
							<WarningTextMessage errorLogic={showPasswordError} message={passwordErrorMessage} />
						</Box>
						<Flex justify='flex-end' color='gray.500' fontSize='0.7em' w='100%'>
							<Link to="/reset-password">Forgot Password?</Link>
						</Flex>

					</Flex>
				</Box>
			</Box>
			{displayLoginError && <Box p='5%'><ValidationWarningBanner>{errorMessageDisplay}</ValidationWarningBanner></Box>}
			<Flex direction='column' justify='center' alignItems='center' my='2em'>
				<Flex justify={['center', 'center', 'flex-end', 'flex-end']} w='100%'>
					<LaunchdeckButton bg='brand.500' color='white' w={["100%", "100%", "auto", "auto"]} onClick={handleSubmit} label='Login' />
				</Flex>
			</Flex>
		</Box>
	);
};


function mapStateToProps(state) {
	return {
		loginFeedback: state.auth.data.login
	};
}


const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			launchdeckLogin
		},
		dispatch,
	)


export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);

// LoginForm.propTypes = {
// 	handleSubmit: PropTypes.func.isRequired
// };

// export default reduxForm({
// 	form: 'login',
// 	validate: validateLogin,
// 	onSubmit: login
// })(LoginForm);


