import auth from '../../../config/app-specific-config/auth';
import * as types from './organizations-actions-types';
import { axiosInstance } from '../../../config/app-specific-config/axiosConfig';

// Org Profile CRU(D)
// export const createOrgProfileSuccess = (response) => {
// 	return {
// 		type: types.CREATE_ORG_PROFILE_SUCCESS,
// 		payload: response.data
// 	};
// };

// export const createOrgProfileFailure = (error) => {
// 	return {
// 		type: types.CREATE_ORG_PROFILE_FAILURE,
// 		error
// 	};
// };

// export const createOrgProfileRequest = () => {
// 	return {
// 		type: types.CREATE_ORG_PROFILE_REQUEST
// 	};
// };

// export function createOrgProfile(
// 	orgName,
// 	orgRegisteredName, 
// 	orgRegisteredAddress,
// 	orgRegisteredCountry,
// 	orgRegisteredNumber,
// 	signatoryOrgTitle
// ) {
// 	return async (dispatch) => {
// 		dispatch(createOrgProfileRequest());
// 		try {
// 			const url = `/organizations/profile/create`;
// 			const headers = await auth.getAccessToken();
// 			const response = await axiosInstance.post(
// 				url,
// 				{
// 					orgName,
// 					orgRegisteredName, 
// 					orgRegisteredAddress,
// 					orgRegisteredCountry,
// 					orgRegisteredNumber,
// 					signatoryOrgTitle
// 				},
// 				{ headers }
// 			);
	
// 			dispatch(createOrgProfileSuccess(response));
// 		} catch (error) {
// 			dispatch(createOrgProfileFailure(error));
// 		}
// 	};
// }


// Upload Organization Logo
export const uploadOrgLogoImageSuccess = (response) => {
	return {
		type: types.GET_ORG_LOGO_IMAGE_SUCCESS,
		payload: response
	};
};

export const uploadOrgLogoImageFailure = (error) => {
	return {
		type: types.GET_ORG_LOGO_IMAGE_FAILURE,
		error
	};
};

export const uploadOrgLogoImageRequest = () => {
	return {
		type: types.GET_ORG_LOGO_IMAGE_REQUEST
	};
};
export const uploadOrgLogoImageReset = () => {
  return {
    type: types.GET_ORG_LOGO_IMAGE_RESET
  }
};

export function uploadOrgLogoImage(values) {
	return async (dispatch) => {
		dispatch(uploadOrgLogoImageRequest());
		try {
			const orgLogo = new FormData(); 
			orgLogo.append('imageType', "uploaded-org-logo")  
			orgLogo.append('orgLogo', values)  

			const url = '/organizations/profile/upload-logo-image';
			const headers = await auth.getAccessToken();
		
			const response = await axiosInstance.put(url, orgLogo, {headers});
			
			dispatch(uploadOrgLogoImageSuccess(response.data.data));

		} catch (error) {
			dispatch(uploadOrgLogoImageFailure(error));
		}
	};
}

export function resetUploadOrgLogoImage() {
	return async (dispatch) => {
		try {
			dispatch(uploadOrgLogoImageReset());

		} catch (error) {
			dispatch(uploadOrgLogoImageFailure(error));
		}
	};
}

// Set Organization Logo Url
export const setOrgLogoImageSuccess = (response) => {
  return {
    type: types.SET_ORG_LOGO_IMAGE_SUCCESS,
    payload: response.data
  };
};
export const setOrgLogoImageFailure = (error) => {
  return {
    type: types.SET_ORG_LOGO_IMAGE_FAILURE,
    error
  };
};

export const setOrgLogoImageRequest = () => {
  return {
    type: types.SET_ORG_LOGO_IMAGE_REQUEST
  };
};
export const setOrgLogoImageReset = () => {
  return {
    type: types.SET_ORG_LOGO_IMAGE_RESET
  }
};

export function setOrgLogoImage(value) {
  return async (dispatch) => {
    dispatch(setOrgLogoImageRequest());
    try {
      const orgLogo = { orgLogo: value };

      const url = '/organizations/profile/set-logo-image';
      const headers = await auth.getAccessToken();

      const response = await axiosInstance.put(url, orgLogo, {headers});
      dispatch(setOrgLogoImageSuccess(response));
      // dispatch(setOrgLogoImageReset());
    } catch (error) {
      dispatch(setOrgLogoImageFailure(error));
    }

  };
}

export function resetOrgLogoImageOutcome() {

  return async (dispatch) => {
		try {
			dispatch(setOrgLogoImageReset());

		} catch (error) {
			dispatch(setOrgLogoImageFailure(error));
		}
	};
}


// Update Organization Profile's Information
export const updateOrgProfileSuccess = (response) => {
	return {
		type: types.UPDATE_ORG_PROFILE_SUCCESS,
		payload: response.data
	};
};

export const updateOrgProfileFailure = (error) => {
	return {
		type: types.UPDATE_ORG_PROFILE_FAILURE,
		error
	};
};

export const updateOrgProfileRequest = () => {
	return {
		type: types.UPDATE_ORG_PROFILE_REQUEST
	};
};

export function updateOrgProfile(updateOrgUserProperties, orgIdToken, updateEvent) {
	return async (dispatch) => {
		dispatch(updateOrgProfileRequest());
		try {
			const url = `/organizations/admin/profile/update/${orgIdToken}`;
			const headers = await auth.getAccessToken();
		
			const response = await axiosInstance.put(
				url,
				{
					updateOrgUserProperties,
					updateEvent
				},
				{ headers }
			);
			
			dispatch(updateOrgProfileSuccess(response));
		} catch (error) {
			dispatch(updateOrgProfileFailure(error));
		}

	};
}

// Reset
export const resetUpdateOrgProfile = () => {
	return {
		type: types.UPDATE_ORG_PROFILE_RESET
	};
};

export function updateOrgProfileReset() {

	return async (dispatch) => {

		try {
			dispatch(resetUpdateOrgProfile());

		} catch (error) {
			dispatch(updateOrgProfileFailure(error));
		}
	};
}

