import auth from '../../../config/app-specific-config/auth'
import * as types from './public-dsr-actions-types'
import { axiosInstance } from '../../../config/app-specific-config/axiosConfig'
import { findPageIdByPageType } from '../helpers/dsr-helpers'
import { setInvalidRequest, setDsrPages, setFaqPageId, setDsrFaqContent, setDsrLogos, 
	setDsrProperties, setDsrTeamMembers, setDsrTitle, setDsrDraftStatus } from './dsr-actions';
import { getUserProfile } from '../../profile/actions/users-profile-actions';
import { displayModeCheck } from '../../global/helpers/global-helpers';
import { setIsMobileView } from '../../global/actions/global-actions';


// Get Public DSR Configuration
export const setPublicDsrEnvironment = (p) => {
	return {
		type: types.SET_PUBLIC_DSR_DISPLAY,
		payload: p
	};
}

export const getPublicDsrConfigSuccess = (response) => {
	return {
		type: types.GET_PUBLIC_DSR_CONFIG_SUCCESS,
		payload: response.data
	};
};

export const getPublicDsrConfigFailure = (error) => {
	return {
		type: types.GET_PUBLIC_DSR_CONFIG_FAILURE,
		error
	};
};

export const getPublicDsrConfigRequest = () => {
	return {
		type: types.GET_PUBLIC_DSR_CONFIG_REQUEST
	};
};

export function fetchPublicDsrData(dsrId) {
	return async (dispatch) => {
		dispatch(getPublicDsrConfig(dsrId)) 
	}
}

export function getPublicDsrConfig(dsrId) {
	
	return async (dispatch) => {
		dispatch(getPublicDsrConfigRequest());
		try {
			const url = `/dsr/pub/config/${dsrId}`;
			const response = await axiosInstance.get(url, {});

			if (response.data.data.invalidRequest === true) {
				dispatch(setInvalidRequest(response.data.data.invalidRequest))
			} else {
				dispatch(setPublicDsrEnvironment(true))
				dispatch(getPublicDsrConfigSuccess(response));
				dispatch(setDsrPages(response.data.data.dsrPages))

				// Get other metadata
				const {pageId, dsrPage} = findPageIdByPageType(response.data.data.dsrPages, 'faq')
				
				if (dsrPage) {
					dispatch(setFaqPageId(pageId))
					const faqArray = dsrPage.pageContent[0].faqArray;
					dispatch(setDsrFaqContent(faqArray))
				}

				dispatch(setDsrLogos(response.data.data.dsrDisplayLogos))
				dispatch(setDsrProperties(response.data.data.dsrProperties)) // Scrubbed
				dispatch(setDsrTeamMembers(response.data.data.dsrTeamMembers)) 
				dispatch(setDsrTitle(response.data.data.dsrTitle))
				dispatch(setDsrDraftStatus(response.data.data.status))

				// Determine view
				const isMobileView = displayModeCheck();
				dispatch(setIsMobileView(isMobileView))
			}
				
		} catch (error) {
			dispatch(getPublicDsrConfigFailure(error));
		}
	};
}


export const joinPublicDsrSuccess = (response) => {
	return {
		type: types.JOIN_PUBLIC_DSR_SUCCESS,
		payload: response.data
	};
};

export const joinPublicDsrFailure = (error) => {
	return {
		type: types.JOIN_PUBLIC_DSR_FAILURE,
		error
	};
};

export const joinPublicDsrRequest = () => {
	return {
		type: types.JOIN_PUBLIC_DSR_REQUEST
	};
};

export function joinPublicDsr(joinPublicDsrPayload) {
	
	return async (dispatch) => {
		dispatch(joinPublicDsrRequest());
		try {
			const url = `/dsr/pub/join`;
			const response = await axiosInstance.post(
				url,
				joinPublicDsrPayload,
				{}
			);


			if (response.data.data.invalidRequest) {
				dispatch(setInvalidRequest(response.data.data.invalidRequest))
			} else {
				
				if (response.data.data.userSessions && response.data.data.nextStep === "login-and-redirect-to-dsr") {
					// Logs in user
					const userSession = response.data.data.userSessions;
					const userData = userSession && userSession.user;
					
					const {userType, token} = userSession || {};
					const {idToken} = userData || {};

					const userPrivileges = response.data.data.userPrivileges;
		
					if (token && userType && idToken) {
						auth.storeToken(token);
						userPrivileges && localStorage.setItem('USER_PRIVILEGES', JSON.stringify(userPrivileges));
						localStorage.setItem('USER_CATG', userType);  
						localStorage.setItem('USER_ID_TOKEN', idToken); 
					}
					
					dispatch(getUserProfile({
						getOrgProfile: true
					}));
				}

				dispatch(joinPublicDsrSuccess(response));
			}
				
		} catch (error) {
			dispatch(joinPublicDsrFailure(error));
		}
	};
}

export const joinPublicDsrReset = () => {
	return {
		type: types.JOIN_PUBLIC_DSR_RESET
	};
};

export function resetJoinPublicDsr() {

	return async (dispatch) => {

		try {
			dispatch(joinPublicDsrReset());

		} catch (error) {
			dispatch(joinPublicDsrFailure(error));
		}
	};
}
