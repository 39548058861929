import React from 'react';
import LaunchdeckButton from './LaunchdeckButton';


export default function LaunchdeckCreatePostButton(props) {

	const {
		label,
		onClickCreatePost,
		userActionRequired,
		setInputValue,
		inputValue,
		files,
		postType,
		loomVideo,
		setShowUploadImageOption,
		setFiles,
		userTagProperties
	} = props;

	const payload = {
		userActionRequired,
		inputValue,
		files,
		loomVideo,
		postType,
		userTagProperties
	}

	return (
		<LaunchdeckButton 
			bg='brand.500' 
			color={'white'}
			onClick={label === "Back" ? () => {
				setShowUploadImageOption(false);
				setFiles([]);
			} : ((inputValue || files?.length > 0 || loomVideo) ? () => {
				onClickCreatePost(payload);
				setInputValue("")
			} : () => {
				console.log('Check inputValue')
			})}
			label='Create' 
		/>
	)

}
