import React from 'react'
import {Box , Flex, Modal, ModalBody, ModalContent, ModalOverlay} from "@chakra-ui/react";
import OrganizationChangeLogoForm from "./OrganizationChangeLogoForm";
import LaunchdeckCloseButton from '../../../global/components/helper-components/components/LaunchdeckCloseButton';

export const OrganizationLogoModal = ({
  handleCloseModal,
  currentOrgLogo,
}) => {
  return (
    <Modal isOpen={true} closeOnOverlayClick={false} isCentered={true} preserveScrollBarGap={true} >
      <ModalOverlay />
      <ModalContent rounded='0.25em' minW={['90%', '500px']} maxW='700px'>
        <ModalBody px='0em'>
          <Flex justify='space-between' align='center' w='100%'>
            <Flex px='1.5em' py='0.5em' justify='flex-start' align='center'>
              <Box fontWeight='500' fontSize='1.25em' lineHeight='1.375em' color='brandDark.500'>Set organization logo</Box>
            </Flex>
            <Box pr='1.5em' display='flex' alignItems='center'>
              <LaunchdeckCloseButton handleCloseModal={() => handleCloseModal()} />
            </Box>
          </Flex>
          <Flex direction='column' justify='space-around' alignItems='center' w='100%'>
            <OrganizationChangeLogoForm
              currentOrgLogo={currentOrgLogo}
              handleCloseModal={() => handleCloseModal()}
            />
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
