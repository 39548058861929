import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'

import { Box , Flex, Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/react'
import '../styles/dsr-display.scss'
import { MdError } from 'react-icons/md'
import { updateDsr } from '../actions/dsr-actions'
import { redirectToNewPage } from '../../global/helpers/global-helpers'
import PageLoader from '../../global/components/helper-components/components/PageLoader'
import LaunchdeckButton from '../../global/components/helper-components/components/LaunchdeckButton'
import LaunchdeckCloseButton from '../../global/components/helper-components/components/LaunchdeckCloseButton'


function DsrRevertToDraftModal(props) {

  const { dsrId, handleCloseModal, updateDsrOutcome } = props || {};

  // UX Management
  const [showLoader, setShowLoader] = useState(false)

  useEffect(() => {
    if (updateDsrOutcome) {
      const {updateDsrStatus, sourceEvent} = updateDsrOutcome || {};

      if (updateDsrStatus && sourceEvent ==="revert-dsr-to-draft") {
        redirectToNewPage(`/draft/${dsrId}`)
      }
    }

  }, [updateDsrOutcome, dsrId]);

  const handleClickUnpublishButton = () => {
    setShowLoader(true);
    props.action.updateDsr({ dsrId, status: 'reverted-draft', sourceEvent:"revert-dsr-to-draft" })
  }

  function displayContent() {
    if (showLoader) {
      return (
        <Box w="100%" align="center" >
          <Box px='1em'>
            <Box>
              <PageLoader />
            </Box>
          </Box>
        </Box>
      )
    }

    if (!showLoader) {

      return (
        <Flex justify='flex-start' px='1.5em' mt='1em' w='100%'>
          <Box w='100%'>

            <Box w="100%" my="1em" color='brandDark.500'>

              <Box>
                Go ahead?
              </Box>
            </Box>

            <Flex w='100%' mt='1em'>
              <Flex my='0.625em' justify='flex-end' w='100%'>
              <LaunchdeckButton bg='gray.700' color='white' onClick={() => handleClickUnpublishButton()} label='Unpublish' />
                      
              </Flex>
            </Flex>
          </Box>


        </Flex>
      )
    }
  }

  return (
    <Modal isOpen={true} closeOnOverlayClick={false} isCentered={true} >
      <ModalOverlay bg='rgba(0, 0, 0, 0.5)' />
      <ModalContent rounded='0.25em' minW={['90%', '500px']} maxW='700px'>
        <ModalBody px='0em'>
          <Flex direction='column' justify='space-around' alignItems='center' w='100%'>
            <Flex justify='space-between' w='100%' borderBottom='1px solid' borderBottomColor='gray.300'>
              <Flex px='1em' justify='flex-start' align='center'>
                <Box as={MdError} color='pink.500'
                  boxSize="1.6em" />
                <Box px='1.5em' py='0.5em' color='brandDark.500'>You are about to unpublish this room</Box>
              </Flex>

              <Box pr='1.5em'>
                <LaunchdeckCloseButton handleCloseModal={() => handleCloseModal()} />
              </Box>
            </Flex>

            <React.Fragment>
              {displayContent()}
            </React.Fragment>

          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

function mapStateToProps(state) {
  return {
    updateDsrOutcome: state.updateDsrOutcome.results.data
  };
}

function mapDispatchToProps(dispatch) {
  return {
    action: bindActionCreators(
      {
        updateDsr
      },
      dispatch
    )
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DsrRevertToDraftModal));
