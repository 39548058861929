import React, { useState, useEffect } from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { IoPersonCircleOutline } from 'react-icons/io5';

import { Box, Avatar, Flex, Image, useToast } from "@chakra-ui/react";
import LaunchdeckButton from '../../global/components/helper-components/components/LaunchdeckButton';
import { manageJoinRoom, resetManageJoinRoom } from '../../digital-sales-room/actions/manage-dsr-member-actions';
import PageLoader from '../../global/components/helper-components/components/PageLoader';
import mapEmptyStateImage from '../dsr-action-list/styles/images/map-empty-state.svg';
import { triggerLaunchdeckToast } from '../../global/helpers/global-helpers';

function DsrJoinRequestMgmtModule(props) {

  const toast = useToast();

  const {
    dsrId,
    // Action
    manageJoinRoom,
    resetManageJoinRoom,
    // Redux states
    isMobileView,
    joinRoomRequests,
    manageJoinRoomRequestOutcome,
    processingJoinRoomRequest
  } = props;


  const [activeJoinRequests, setActiveJoinRequests] = useState("")

  // UX management
  useEffect(() => {
    // if (joinRoomRequests && !initRef.current) {
     
    if (Array.isArray(joinRoomRequests)) {
      setActiveJoinRequests(joinRoomRequests)
    }

    // initRef.current = true

  }, [joinRoomRequests]);

  useEffect(() => {
    if (manageJoinRoomRequestOutcome) {
      const { eventProcessed, updateSuccessful } = manageJoinRoomRequestOutcome || {};

      if (updateSuccessful) {
        setActiveJoinRequests(joinRoomRequests) // Still required?
      }

      if (eventProcessed === "approve-join-request" && updateSuccessful) {
        toast(
          triggerLaunchdeckToast({
            useCase: "show-success-state",
            // bannerText: "",
            label: "Succesfully added as a Guest member",
            isMobileView
          })
        )
      }

      resetManageJoinRoom()
    }

  }, [manageJoinRoomRequestOutcome, toast, resetManageJoinRoom, joinRoomRequests]);



  const handleJoinRoomAction = (actionRequired, requestUserEmail) => {
    let manageJoinRoomPayload = "";

    if (actionRequired === "decline-join-request") {
      manageJoinRoomPayload = {
        event: "decline-join-request",
        dsrId: dsrId,
        providedRequestUserEmail: requestUserEmail
      }
    } else if (actionRequired === "approve-join-request") {
      manageJoinRoomPayload = {
        event: "approve-join-request",
        dsrId: dsrId,
        providedRequestUserEmail: requestUserEmail
      }
    }

    manageJoinRoom(manageJoinRoomPayload)
  }

  const renderJoinRequestEmptyState = () => {
      return (
        <Flex justify='center' align='center' py='2.75em' border='1px dotted #E5EAEF' rounded='0.5em' bg='white' w='100%' h='100%'>
  
        <Flex direction='column' justify='center' align='center'>
          {mapEmptyStateImage ? <Image src={mapEmptyStateImage} alt="" /> : <PageLoader />}
  
          <Flex  lineHeight='1.5em'  py='1.5em' fontSize='0.875em' color='gray.500'>
            No outstanding join requests
          </Flex>
  
        </Flex>
  
      </Flex>
      )    
  }

  const renderJoinRequests = () => {
    if (activeJoinRequests) {
      if (activeJoinRequests.length > 0) {
        const displayedJoinRequests = activeJoinRequests?.map((request, requestIndex) => {
          const { requestUserEmail, requestUsername, requestUserProfilePic, 
            // requestTimestamp, requestUserIdToken 
          } = request || {}

          return (

            <Flex key={`join-room-request-${requestIndex}`} justify='space-between' align='center' w='100%' my='1em'>
              <Flex align='center' justify='flex-start'>

                {requestUserProfilePic ?
                  // <Box height='2.5em' width='2.5em'>
                    <Avatar h='2.5em' w='2.5em' bg='gray.100' color='gray.700' name={requestUsername} src={requestUserProfilePic} />
                  // </Box>
                  :
                  // Change this to fallback Avatar image with the same imagery. Currently this is slightly smaller than any Avatar image
                  <Box as={IoPersonCircleOutline} boxSize="2.5em" color="brandDark.500" />
                }


                <Box ml='1em'>
                  <Flex align='baseline' justify='flex-start'>
                    <Box fontSize='0.875em' color='brandDark.500'>{requestUsername}</Box>
                  </Flex>
                  <Box fontSize='0.75em' color='gray.500'>{requestUserEmail}</Box>
                </Box>
              </Flex>

              <Flex>
                <Flex justify={['center', 'flex-end', 'flex-end', 'flex-end']} w='100%' mr='1em'>
                  <LaunchdeckButton bg='pink.50' borderColor='brandPink.500' color='brandPink.500' onClick={() => handleJoinRoomAction("decline-join-request", requestUserEmail)} label='Decline' />
                </Flex>
                <Flex justify={['center', 'flex-end', 'flex-end', 'flex-end']} w='100%'>
                  <LaunchdeckButton bg='brand.500' color='white' onClick={() => handleJoinRoomAction("approve-join-request", requestUserEmail)} label='Approve' />
                </Flex>
              </Flex>

            </Flex>
          )
        })

        return displayedJoinRequests;
      } else {
        return (
        <Flex w='100%' h='100%'>{renderJoinRequestEmptyState()}</Flex>
        )
      }

    } else return (
      <Flex w='100%' h='100%'>{renderJoinRequestEmptyState()}</Flex>
      )
  }


  if (processingJoinRoomRequest === true) {
    return (
      <Flex direction='column' justify='space-around' alignItems='center' py='1em' mt='1em'>
        <PageLoader />
      </Flex>
    )
  } else {
    return (
      <Flex direction='column' justify='space-around' alignItems='center' py='1em' mt='1em'>
        {renderJoinRequests()}
      </Flex>
    )
  }

}


const mapStateToProps = (state) => ({
  joinRoomRequests: state.joinRoomRequests.results,
  manageJoinRoomRequestOutcome: state.manageJoinRoomRequestOutcome.results.data,
  processingJoinRoomRequest: state.manageJoinRoomRequestOutcome.isLoading,
  isMobileView: state.mobileViewMode.isMobileView
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      manageJoinRoom,
      resetManageJoinRoom
    },
    dispatch,
  )



export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DsrJoinRequestMgmtModule));
