import React from 'react'
import { Divider } from '@chakra-ui/react';
import { optionTypes } from "../types/subWidgetOptionsTypes";

export const ContentDivider = ({options}) => {
  let dividerStrength = '1px';

  switch (options[optionTypes.STRENGTH]) {
    case 'thin':
      dividerStrength = '1px'
      break;

    case 'medium':
      dividerStrength = '2px'
      break;

    case 'bold':
      dividerStrength = '3px'
      break;

    default:
      dividerStrength = '1px';
      break
  }

  return (<Divider width="90%" borderBottomWidth={dividerStrength} borderColor='#CBD5E0'/>)
};
