import auth from '../../../config/app-specific-config/auth'
import * as types from './dsr-analytics-action-types'
import { axiosInstance } from '../../../config/app-specific-config/axiosConfig'


///// DSR ANALYTICS - LOGGING

export const logGuestDsrAnalyticsSuccess = (response) => {
	return {
		type: types.LOG_GUEST_DSR_ANALYTICS_SUCCESS,
		payload: response.data
	};
};

export const logGuestDsrAnalyticsFailure = (error) => {
	return {
		type: types.LOG_GUEST_DSR_ANALYTICS_FAILURE,
		error
	};
};

export const logGuestDsrAnalyticsRequest = () => {
	return {
		type: types.LOG_GUEST_DSR_ANALYTICS_REQUEST
	};
};


export const logGuestDsrAnalytics = (analyticsPayload) => {
	return async (dispatch) => {
		dispatch(logGuestDsrAnalyticsRequest());
		try {
			const url = '/analytics/dsr/guest';
			// const headers = await auth.getAccessToken();

			const response = await axiosInstance.put(
				url,
				analyticsPayload,
				{ 
					// External public API to cater for PDSRs       
				}
			);

			dispatch(logGuestDsrAnalyticsSuccess(response));
		
		} catch (error) {
			dispatch(logGuestDsrAnalyticsFailure(error));
		}
	};
};


///// DSR ANALYTICS - RETRIEVAL

export const getDsrAnalyticsSuccess = (response) => {
	return {
		type: types.GET_DSR_ANALYTICS_SUCCESS,
		payload: response.data
	};
};

export const getDsrAnalyticsFailure = (error) => {
	return {
		type: types.GET_DSR_ANALYTICS_FAILURE,
		error
	};
};

export const getDsrAnalyticsRequest = () => {
	return {
		type: types.GET_DSR_ANALYTICS_REQUEST
	};
};


export const getDsrAnalytics = (dsrAnalyticsRequestPayload) => {
	return async (dispatch) => {
		dispatch(getDsrAnalyticsRequest());
		try {
			const url = `/analytics/dsr`;
			const headers = await auth.getAccessToken();
			// const response = await axiosInstance.get(url, {
			// 	headers
			// });
			const response = await axiosInstance.post(
				url,
				{dsrAnalyticsRequestPayload},
				{ headers }
			);

			dispatch(getDsrAnalyticsSuccess(response));
		
		} catch (error) {
			dispatch(getDsrAnalyticsFailure(error));
		}
	};
};

///// DSR ANALYTICS - RETRIEVAL

export const getDsrAnalyticsCategoryDetailsSuccess = (response) => {
	return {
		type: types.GET_DSR_ANALYTICS_CATEGORY_DETAILS_SUCCESS,
		payload: response.data
	};
};

export const getDsrAnalyticsCategoryDetailsFailure = (error) => {
	return {
		type: types.GET_DSR_ANALYTICS_CATEGORY_DETAILS_FAILURE,
		error
	};
};

export const getDsrAnalyticsCategoryDetailsRequest = () => {
	return {
		type: types.GET_DSR_ANALYTICS_CATEGORY_DETAILS_REQUEST
	};
};


export const getDsrAnalyticsCategoryDetails = (dsrAnalyticsDetailsRequestPayload) => {
	return async (dispatch) => {
		dispatch(getDsrAnalyticsCategoryDetailsRequest());
		try {
			const url = `/analytics/dsr/details`;
			const headers = await auth.getAccessToken();
			const response = await axiosInstance.post(
				url,
				{dsrAnalyticsDetailsRequestPayload},
				{ headers }
			);

			dispatch(getDsrAnalyticsCategoryDetailsSuccess(response));
		
		} catch (error) {
			dispatch(getDsrAnalyticsCategoryDetailsFailure(error));
		}
	};
};