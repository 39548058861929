import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'

import { Box , Flex, Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/react'

import { MdError } from 'react-icons/md'
import { setDsrPages, updatePdsr, resetUpdateDsrOutcome } from '../../actions/dsr-actions'
import { redirectToNewPage } from '../../../global/helpers/global-helpers'
import PageLoader from '../../../global/components/helper-components/components/PageLoader'
import LaunchdeckButton from '../../../global/components/helper-components/components/LaunchdeckButton'
import LaunchdeckCloseButton from '../../../global/components/helper-components/components/LaunchdeckCloseButton'
// import '../styles/dsr-display.scss'

function ProspectDsrRepublishModal(props) {

  const { pDsrId, dsrPages, handleCloseModal, updateProspectDsrOutcome } = props || {};

  // UX Management
  const [showLoader, setShowLoader] = useState(false)

  useEffect(() => {
    if (updateProspectDsrOutcome) {
      const {updatePdsrStatus, sourceEvent} = updateProspectDsrOutcome || {};

      if (updatePdsrStatus && sourceEvent==="re-publish-prospector-dsr") {
        redirectToNewPage(`/pdsr/${pDsrId}`)
      }
    }

  }, [updateProspectDsrOutcome, pDsrId]);

  const handleClickRepublishButton = () => {
    setShowLoader(true);
    props.action.setDsrPages(dsrPages)
    props.action.updatePdsr({ 
      pDsrId, 
      firstPublish: true, // Used to help guide backend to create instances in tables upon publishing a PDSR
      sourceEvent: "re-publish-prospector-dsr", 
      status: 'active', 
      dsrPages 
    }) 
  }

  function displayContent() {
    if (showLoader) {
      return (
        <Box w="100%" align="center" >
          <Box px='1em'>
            <Box>
              <PageLoader />
            </Box>
          </Box>
        </Box>
      )
    }

    if (!showLoader) {

      return (
        <Flex justify='flex-start' px='1.5em' mt='1em' w='100%'>
          <Box w='100%'>
            <Box w="100%" my="1em" color='brandDark.500'>
              <Box>
                Members will soon be able to view the contents inside this room again. Proceed?
              </Box>
            </Box>

            <Flex w='100%' mt='1em'>
              <Flex my='0.625em' justify='flex-end' w='100%'>
                <LaunchdeckButton name='showRepublishBackToActive' label='Publish again' fontSize='0.9em' py='0.8em' bg='gray.700' color='white' onClick={() => handleClickRepublishButton()} />
              </Flex>
            </Flex>
          </Box>
        </Flex>
      )
    }
  }

  return (
    <Modal isOpen={true} closeOnOverlayClick={false} isCentered={true} >
      <ModalOverlay className="modal-background" />
      <ModalContent rounded='0.25em' minW={['90%', '90%', '50%', '50%']}  >
        <ModalBody px='0em'>
          <Flex direction='column' justify='space-around' alignItems='center' w='100%'>
            <Flex justify='space-between' w='100%' borderBottom='1px solid' borderBottomColor='gray.300'>
              <Flex px='1em' justify='flex-start' align='center'>
                <Box as={MdError} color='pink.500'
                  boxSize="1.6em" />
                <Box px='1.5em' py='0.5em' color='brandDark.500'>You are about to publish the Room once more</Box>
              </Flex>

              <Box pr='1.5em'>
                <LaunchdeckCloseButton handleCloseModal={() => handleCloseModal()} />
              </Box>
            </Flex>

            <React.Fragment>
              {displayContent()}
            </React.Fragment>

          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

function mapStateToProps(state) {
  return {
    updateProspectDsrOutcome: state.updateProspectDsrOutcome.results.data
  };
}

function mapDispatchToProps(dispatch) {
  return {
    action: bindActionCreators(
      {
        updatePdsr,
        setDsrPages,
        resetUpdateDsrOutcome
      },
      dispatch
    )
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProspectDsrRepublishModal));
