import React, { memo, useState, useEffect } from 'react'

import { Box, Flex, Skeleton, Tooltip, useToast } from '@chakra-ui/react'
import '../styles/dsr-display.scss'
import { createNewDsrPage, createPlaceholderDuplicateDsrPage } from '../helpers/dsr-feed-helpers'
import { RiQuestionFill } from 'react-icons/ri'

import { useParams, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import { displayReactIcon } from '../helpers/dsr-display-helpers'
import { SortableCustomPageMenuTab } from './page-designer/SortableCustomPageMenuTab'

import {
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
  DragOverlay
} from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
  arrayMove,
} from "@dnd-kit/sortable";
import {
  restrictToWindowEdges,
  restrictToVerticalAxis
} from '@dnd-kit/modifiers';
import { duplicateDsrPage, resetDuplicateDsrPage } from '../actions/duplicate-dsr-page-actions'
import { triggerLaunchdeckToast } from '../../global/helpers/global-helpers'


function DsrDraftSideMenu({
  isRoomTemplate,
  pageArray,
  selectedDisplayPageId,
  selectedDisplaySubpage,
  handleSelectSubpage,
  addNewDsrPage,
  removeDsrPage,
  replaceDsrPages,
  isDsrAdmin,
  duplicateDsrPage,
  resetDuplicateDsrPage,
  duplicatedDsrPageOutcome,
  isMobileView
  // closeMenuDrawer TODO: Implement this only when drafting DSR is allowed in mobile view
}) {
  const toast = useToast();
  const { dsrId } = useParams()
  const [activeId, setActiveId] = useState(null);


useEffect(() => {
  if (duplicatedDsrPageOutcome && pageArray) {
    const { successfulPageDuplication, duplicatedPage} = duplicatedDsrPageOutcome || {}

    if (!!successfulPageDuplication) {
      const {pageTitle, pageId} = duplicatedPage || {};
      if (pageId && Array.isArray(pageArray)) {
        const foundPageIndex = pageArray?.findIndex(page => page.pageId === pageId)
        if (foundPageIndex !== -1) {
          pageArray[foundPageIndex] = duplicatedPage;
          replaceDsrPages([...pageArray])
        }
      }
      toast(
        triggerLaunchdeckToast({
          useCase: "show-success-state",
          label: `${pageTitle} duplicated`,
          isMobileView
        })
      )
    } else if (successfulPageDuplication=== false) {
      toast(
        triggerLaunchdeckToast({
          useCase: "show-error-state",
          label: `Unfortunately your page cannot be duplicated. Please try again later.`,
          isMobileView
        })
      )
    }

    resetDuplicateDsrPage()
  }
}, [duplicatedDsrPageOutcome, isMobileView]);

  const sensors = useSensors(
    useSensor(PointerSensor),
  );

  const handleDragStart = (event) => {
    setActiveId(event.active.id);
  };

  const handleDragEnd = (event) => {
    setActiveId(null);
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const pagesIdArray = pageArray.map(tab => { return tab.pageId })
      const activeIndex = pagesIdArray.indexOf(active.id);
      const overIndex = pagesIdArray.indexOf(over.id);
      const sortedPages = arrayMove(pageArray, activeIndex, overIndex)

      if (sortedPages) {
        replaceDsrPages(sortedPages)
      }
    }
  };

  function renderSubPageTabs(category) {
    let pagesToRender = [];

    if (!pageArray) {
      return null
    }

    if (category === 'essential-pages') {
      pagesToRender = pageArray?.filter(tabs => tabs.pageType !== 'customised-page')
    } else if (category === 'custom-pages') {
      pagesToRender = pageArray?.filter(tabs => tabs.pageType === 'customised-page')
    }

    if (pagesToRender) {
      const pageTabs = pagesToRender?.map((tabs, tabIndex) => {
        const { pageId, pageTitle, pageType } = tabs || {};

        if (pageType === 'customised-page') {
          return (
            <SortableCustomPageMenuTab
              key={pageId}
              tabs={tabs}
              tabIndex={tabIndex}
              selectedDisplayPageId={selectedDisplayPageId}
              handleSelectSubpage={handleSelectSubpage}
              handleRemovePageClick={handleRemovePageClick}
              handleEditPageData={handleEditPageData}
              handleDuplicatePageClick={handleDuplicatePageClick}
            />
          )
        }

        if (tabIndex === 0) {
          return (
            <React.Fragment key={`dsr_page_tab_${tabIndex}-${pageId}`}>
              <Flex justify='flex-start' align='center' px='1.1em' py='0.875em'
                className={(selectedDisplayPageId === `${pageId}`) ? "chosen-subpage-option" : "unchosen-subpage-option"}
                onClick={() => handleSelectSubpage(pageId, "")}
              >
                {displayReactIcon(pageType, '1.5em')}
                <Box ml='1em' whiteSpace='pre'>{pageTitle}</Box>
              </Flex>

              {updatesPageTab()}
            </React.Fragment>
          )

        } else {
          return (
            <Flex
              key={`dsr_page_tab_${tabIndex}-${pageId}`}
              justify='flex-start' align='center' px='1.1em' py='0.875em'
              className={(selectedDisplayPageId === `${pageId}`) ? "chosen-subpage-option" : "unchosen-subpage-option"}
              onClick={() => handleSelectSubpage(pageId, "")}
            >
              {displayReactIcon(pageType, '1.5em')}
              <Box ml='1em' whiteSpace='pre'>{pageTitle}</Box>
            </Flex>
          )
        }
      })

      return (
        <>
          <DndContext
            sensors={sensors}
            onDragEnd={handleDragEnd}
            onDragStart={handleDragStart}
            modifiers={[restrictToVerticalAxis]}
          >
            <SortableContext items={pagesToRender.map(tab => { return tab.pageId })} strategy={verticalListSortingStrategy}>
              {pageTabs}
              <DragOverlay modifiers={[restrictToWindowEdges]}>
                {activeId ? (
                  <Skeleton height="20px" my="10px" />
                ) : null}
              </DragOverlay>
            </SortableContext>
          </DndContext>
        </>
      );

    } else return null
  }

  function handleCreateNewPageClick() {
    const newDsrPage = createNewDsrPage() // For UX: To make it appear instantly
    addNewDsrPage(newDsrPage)
    // Add function to add new object to DB
    handleSelectSubpage(newDsrPage.pageId, "") // Defaults to this new subpage
    // handleSelectSubpage(`${newDsrPage.pageId}-subpage`) // Defaults to this new subpage
  }

  function handleRemovePageClick(pageId) {
    removeDsrPage(pageId)
    handleSelectSubpage("", "overview-subpage") // Defaults back to overview page when page removed
  }


  function handleDuplicatePageClick(pageId) {
    const pageToCopy = Array.isArray(pageArray) && pageArray?.find(page => page.pageId === pageId)

    if (pageToCopy) {
      const newDsrPage = createPlaceholderDuplicateDsrPage(pageToCopy) // For UX: To make it appear instantly for the time being
      addNewDsrPage(newDsrPage) 

      duplicateDsrPage({
        newPageId: newDsrPage.pageId,
        duplicatedPageId: selectedDisplayPageId,
        pageToCopy,
        dsrId
      })
    }
  }

  const docFolderTab = () => {
    return (
      <Flex justify='flex-start' align='center' px='1.1em' py='0.875em'
        className={(selectedDisplaySubpage === `shared-docs-folder-subpage`) ? "chosen-subpage-option" : "unchosen-subpage-option"}
        onClick={() => {
          handleSelectSubpage("", 'shared-docs-folder-subpage')
          // () => handleSelectSubpage("", 'action-list-subpage')
          // closeMenuDrawer && closeMenuDrawer()
        }}
      >

        {displayReactIcon('folder', '1.5em')}
        <Box lineHeight='1.5em' ml='1em' whiteSpace='pre'>Shared Documents</Box>
      </Flex>
    )
  }

  
  const updatesPageTab = () => {
    return (
      <Flex justify='flex-start' align='center' px='1.1em' py='0.875em'
        className={(selectedDisplaySubpage === "updates-subpage") ? "chosen-subpage-option" : "unchosen-subpage-option"}
        onClick={() => handleSelectSubpage("", 'updates-subpage')}
      >
        {displayReactIcon('star', '1.5em')}
        <Box lineHeight='1.5em' ml='1em' whiteSpace='pre'>Updates</Box>

      </Flex>

    )
  }

  const mutualActionPlanTab = () => {
    return (
      <Flex justify='flex-start' align='center' px='1.1em' py='0.875em'
        className={(selectedDisplaySubpage === `action-list-subpage`) ? "chosen-subpage-option" : "unchosen-subpage-option"}
        onClick={() => handleSelectSubpage("", 'action-list-subpage')}
      >
        {displayReactIcon('circle-check', '1.5em')}
        <Box lineHeight='1.5em' ml='1em' whiteSpace='pre'>Mutual Action Plan</Box>
      </Flex>
    )
  }

  const createNewPageTab = () => {
    return (
      <Flex  justify='flex-start' align='center' px='1.1em' py='0.875em' 
        color='brand.500'
        className='unchosen-subpage-option'
        onClick={() => handleCreateNewPageClick()}
      >
      {displayReactIcon('add-new-page', '1.5em')}
      <Box lineHeight='1.5em' ml='1em' whiteSpace='pre'>Create New Page</Box>

    </Flex>  
    )
  }

  const categoryLabel = (labelText, toolTipText) => {
    return (
      <Flex mt='1em' justify='flex-start' align='center' color='gray.500' cursor='default' className='unchosen-subpage-option'>
        <Box fontSize='0.75em' fontWeight='600' whiteSpace='pre'>{labelText}</Box>
        <Tooltip fontSize='0.8em' label={toolTipText} placement="auto-start">
          <Box ml='0.5em'>
            <Box as={RiQuestionFill} boxSize="1em" py='3%' />
          </Box>
        </Tooltip>
      </Flex>
    )
  }

  const handleEditPageData = (newPageData, pageId) => {
    const updatedPages = pageArray.map((page) => page.pageId === pageId ? { ...page, ...newPageData } : page);

    if (updatedPages) {
      replaceDsrPages(updatedPages)
    }
  }

  const renderAdminPageTabs = () => {
    
    return (
      <Box>
        <Flex justify='flex-start' align='center' px='1.1em' py='0.875em'
          className={(selectedDisplaySubpage === "insights-subpage") ? "chosen-subpage-option" : "unchosen-subpage-option"}
          onClick={() => {
            handleSelectSubpage("", 'insights-subpage')
            // closeMenuDrawer && closeMenuDrawer()
          }}
        >
          {displayReactIcon('worktool', '1.5em')}
          <Box lineHeight='1.5em' ml='1em'> Insights</Box>

        </Flex>

        <Flex justify='flex-start' align='center' px='1.1em' py='0.875em'
          className={(selectedDisplaySubpage === "team-notes-subpage") ? "chosen-subpage-option" : "unchosen-subpage-option"}
          onClick={() => {
            handleSelectSubpage("", 'team-notes-subpage')
            // closeMenuDrawer && closeMenuDrawer()
        }}
        >
          {displayReactIcon('worktool', '1.5em')}
          <Box lineHeight='1.5em' ml='1em'> Team Notes</Box>

        </Flex>

        <Flex justify='flex-start' align='center' px='1.1em' py='0.875em'
          className={(selectedDisplaySubpage === "room-settings-subpage") ? "chosen-subpage-option" : "unchosen-subpage-option"}
          onClick={() => {
            handleSelectSubpage("", 'room-settings-subpage')
            // closeMenuDrawer && closeMenuDrawer()
          }}
        >
          {displayReactIcon('worktool', '1.5em')}
          <Box lineHeight='1.5em' ml='1em'> Settings</Box>

        </Flex>
      </Box>
    )
  }


  return (
    <Flex
      flexWrap='wrap'
      justify='flex-start'
      pt={isRoomTemplate ? '3em' : '2em'}
      maxWidth='280px'
      height='calc(100vh - 140px)'
      overflowX="hidden"
      overflowY="auto"
    >
      <Box maxWidth='fit-content'>
        {categoryLabel('DEFAULT PAGES', 'Essential pages for your Room')}
        {renderSubPageTabs('essential-pages')}
        {mutualActionPlanTab()}
        {docFolderTab()}

        {categoryLabel('CUSTOM PAGES', 'Create additional custom pages for your Room')}
        {renderSubPageTabs('custom-pages')}
        {createNewPageTab()}

      </Box>

      <React.Fragment>
          <Box>
            {(isDsrAdmin && !isRoomTemplate) &&
              <React.Fragment>
                {categoryLabel('ADMINISTRATOR PAGES', 'Info accessible only by Room Administrators')}
                {renderAdminPageTabs()}
              </React.Fragment>
            }
            {/* {renderBrandingLabel()} */}
          </Box>
        </React.Fragment>
    </Flex>
  )


}

const mapStateToProps = (state) => {
  return {
    selectedDisplayPageId: state.dsrPage.selectedDisplayPageId,
    selectedDisplaySubpage: state.dsrPage.selectedDisplaySubpage,
    duplicatedDsrPageOutcome: state.duplicateDsrPageOutcome.results,
    isDsrAdmin: state.dsrAdmin.isDsrAdmin,
    isMobileView: state.mobileViewMode.isMobileView
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      duplicateDsrPage,
      resetDuplicateDsrPage
    },
    dispatch,
  )

export default memo(withRouter(connect(mapStateToProps, mapDispatchToProps)(DsrDraftSideMenu)))
