import * as types from '../actions/onboarding-actions-types';

const INITIAL_STATE_ONBOARDING_USER = {
	isLoading: false,
	results: {},
	error: '',
};

export function onboardingReducer(state = INITIAL_STATE_ONBOARDING_USER, action) {
	switch (action.type) {
		case types.SELF_ONBOARD_DEMO_USER_REQUEST:
			return { ...state, isLoading: true, results: {} };
		case types.SELF_ONBOARD_DEMO_USER_SUCCESS:
			return { ...state, isLoading: false, results: action.payload };
		case types.SELF_ONBOARD_DEMO_USER_FAILURE:
			return { ...state, isLoading: false, error: 'Error while trying to onboard demo user DIY style' };
		default:
			return state;
	}
}