import React, { Fragment } from 'react'
import moment from 'moment'

import { Avatar, Box, Flex, Image } from '@chakra-ui/react'
import { formatDisplayLocalDateTime } from '../../global/helpers/time-format-helpers'
import DsrRepliesDisplayModule from '../messages/DsrRepliesDisplayModule'

// Images
import DsrStartIcon from '../styles/images/dsr-start-icon.png'
import { getUserDetailsUsingDsrMembersArray } from '../../profile/helpers/user-helper'
import { getUrlInPostContent } from "../helpers/dsr-display-helpers"
import config from "../../../config/app-config"
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { ReactTinyLink } from 'react-tiny-link'
import '../styles/dsr-display.scss'
import { LoomVideoDisplay } from './LoomVideoDisplay'
import { Mention, MentionsInput } from "react-mentions"
import { mentionStyle } from "../../forms/TextareaField/components/InputTextAreaWithUserTagging"
import LaunchdeckButton from '../../global/components/helper-components/components/LaunchdeckButton'
import { openInNewTab } from '../../global/helpers/global-helpers'
import UpdatesEmptyState from '../../digital-sales-room/styles/images/updates-post.svg'


export default function DisplayStaticNewsFeed({
  dsrTitle,
  postContent,
  loadMoreFeedContent, // TTT refactor these load more content names or simplify? Too similar sounding. Not human readable friendly.
  showLoadMore,
  loadingMoreContent,
  dsrTeamMembers,
  handleSelectSubpage,
  faqPageId
}) {

  const configs = {
    autoPlay: false,
    infiniteLoop: true,
    showThumbs: false,
    swipeable: true,
    showStatus: false,
    showIndicators: true,
    showArrows: true,
    dynamicHeight: false,
  };

  // Messages
  const displayedMessages = postContent && postContent.sort((a, b) => new Date(b.postCreatedAt) - new Date(a.postCreatedAt)).map((message, messageIndex) => {

    const { postType, postId, postStatus, authorUserId, postContent, postCreatedAt,
      postUpdatedAt, postReplies, postDeletedAt, images, video, loomVideo } = message || {};
    const messageTimestamp = moment(postCreatedAt).format('lll')
    const isEdited = postUpdatedAt ? true : false;
    const authorProfile = getUserDetailsUsingDsrMembersArray(dsrTeamMembers, authorUserId);
    const { memberName, memberProfilePicSrc } = authorProfile || {};
    const { corsProxy } = config;


    // Determine whether current user is the post author

    if (postStatus === 'deleted' && postDeletedAt) {
      return (<Box key={`message_${messageIndex}`} my='3em' color='gray.500'>
        <Flex align='flex-start' border='1px solid' borderColor='gray.300' rounded='0.5em' p='0.2em'>
          <Box p='0.5em' fontSize='0.8em'>Message deleted by {memberName} on {formatDisplayLocalDateTime(postDeletedAt)}</Box>
        </Flex>
      </Box>
      )
    }

    const renderPostTextOrMedia = (content) => {
      return (
        <Box
          key={`message_${messageIndex}`}
          w='100%'
          mt='1em' mb='2em' p='1em'
          bg='white'
          rounded='0.25em' border='1px solid rgba(203, 213, 224, 0.5)'
        >
          <Flex width='100%' justify='space-between'>
            <Box>
              <Flex justify='flex-start' align='center'>
                <Avatar h='4.2em' w='4.2em' name={memberName} bg='gray.100' color='gray.700' src={memberProfilePicSrc} />
                <Box pl='1em'>
                  <Flex justify='flex-start' fontSize='0.9em'>
                    <Box fontWeight='semibold'>{memberName}</Box>
                    <Box pl='0.4em'>posted {postType === "shared-url" && "a link"}</Box>
                  </Flex>
                  <Box fontSize='0.8em' color='gray.500'>{messageTimestamp} {isEdited ?
                    <Box as='span' fontSize='1em' color='gray.500' lineHeight='1em'> • Edited</Box> : null}</Box>
                </Box>

              </Flex>
            </Box>

          </Flex>

          <Box>
            <Flex align='flex-start' px='0.2em' py='1em'>
              <Flex className="white-space-preline" width="100%">
                {postType === "shared-url"
                  ? (
                    <Box width="100%">
                      <Box fontSize='0.875em'>
                        <MentionsInput
                          readOnly={true}
                          value={postContent}
                        >
                          <Mention
                            markup='@[__display__]userId-(__id__)'
                            style={mentionStyle}
                            displayTransform={(id, display) => { return `@${display}` }}
                          />
                        </MentionsInput>
                      </Box>
                      <Box mt='1em'>
                        <ReactTinyLink
                          cardSize="small"
                          showGraphic={true}
                          maxLine={2}
                          minLine={1}
                          url={getUrlInPostContent(postContent)}
                          proxyUrl={corsProxy}
                          // onClick={(e) => { window.open(getUrlInPostContent(postContent), "_blank") }}
                          onClick={(e) => { openInNewTab(getUrlInPostContent(postContent)) }}
                        />
                      </Box>
                    </Box>
                  )
                  : (
                    <Box fontSize='0.875em'>
                      <MentionsInput
                        readOnly={true}
                        value={postContent}
                      >
                        <Mention
                          markup='@[__display__]userId-(__id__)'
                          style={mentionStyle}
                          displayTransform={(id, display) => { return `@${display}` }}
                        />
                      </MentionsInput>
                    </Box>
                  )
                }
              </Flex>
            </Flex>
            {/* // UUU What is this "content"? Too generic & not human readable friendly - better name for it? */}
            {content && content()}


          </Box>

          <Box key={`dsr_replies_${messageIndex}`}>
            <DsrRepliesDisplayModule
              messageRepliesArray={postReplies}
              postId={postId}
              handleEditPost={""} // isStaticMode = true; will not allow editing of post
              dsrTeamMembers={dsrTeamMembers}
              onClickDeleteReplyOption={""} // isStaticMode = true; will not allow editing of post
              confirmationType={'confirm-delete-reply'}
              isStaticMode={true}
            />
          </Box>

        </Box>
      )
    }

    const imagesContent = () => {
      const imageStyle = { maxHeight: '360px', objectFit: 'contain', background: '#F7FAFC', padding: "16px 0" }

      return (
        <Fragment>
          {images && images.length > 0 && <div className="post__images">
            {images.length === 1 ?
              images.map((item, index) => {
                if (item.url) {
                  if (item.url.endsWith(".jpg") || item.url.endsWith(".jpeg") || item.url.endsWith(".png") || item.url.endsWith(".gif")) {
                    // URL is for an image
                    return (
                      // eslint-disable-next-line jsx-a11y/img-redundant-alt,no-mixed-operators
                      <img key={item && item.key || item + index} src={item.url || item} style={imageStyle}
                        alt={`post-image-${index}`} />

                    )
                  } else {
                    // URL is not for a video or an image
                    // eslint-disable-next-line no-mixed-operators
                    return <div key={item && item.key || item + index} />
                  }
                } else {
                  return (
                    // eslint-disable-next-line jsx-a11y/img-redundant-alt,no-mixed-operators
                    <img key={item && item.key || item + index} src={item} style={imageStyle}
                      alt={`post-image-${index}`} />
                  )
                }


              }) : <Carousel
                {...configs}
              >
                {images.map((item, index) => {
                  return (
                    // <div style={{ margin: '2px' }}>
                    // eslint-disable-next-line jsx-a11y/img-redundant-alt
                    <img key={item + index}
                      style={imageStyle}
                      className="post__image" src={item.url || item} alt={`post-image-${index}`} />
                    // </div>
                  )
                })}
              </Carousel>
            }

          </div>}
        </Fragment>
      )
    };

    const videoContent = () => {
      return (
        <Box w='100%' my='2em' py='1em' bg='gray.100' rounded='0.25em'>
          {video && video.length > 0 &&
            <div className="post__images">
              {(video[0].url.endsWith(".mp4") || video[0].url.endsWith(".ogg") || video[0].url.endsWith(".webm"))
                &&
                <video src={video[0].url} controls style={{ width: "auto", height: "25em", objectFit: "contain" }} />
              }

            </div>
          }
        </Box>
      )
    };

    const loomVideoContent = () => {
      if (!loomVideo?.sharedUrl) return null

      return <LoomVideoDisplay loomVideo={loomVideo} />
    };

    switch (postType) {
      case ("images"): {
        return (
          <div key={`dsr_replies_${messageIndex}`}>{renderPostTextOrMedia(imagesContent)}</div>
        );
      }
      case ("uploaded-video"): {
        return (
          <div key={`dsr_replies_${messageIndex}`}>{renderPostTextOrMedia(videoContent)}</div>
        );
      }
      case ("short-text"): {
        return (
          <div key={`dsr_replies_${messageIndex}`}>{renderPostTextOrMedia()}</div>
        );
      }
      case ("shared-url"): {
        return (
          <div key={`dsr_replies_${messageIndex}`}>{renderPostTextOrMedia()}</div>
        );
      }
      case ("loom-video-post"): {
        return (
          <div key={`dsr_replies_${messageIndex}`}>{renderPostTextOrMedia(loomVideoContent)}</div>
        );
      }
      case ("dsr-created"): {
        return (
          <Flex key={`message_${messageIndex}`} fontSize='0.9em' p='1em' w='100%' bg='white' rounded='0.25em' border='1px solid rgba(203, 213, 224, 0.5)' align='center' >
            <Image src={DsrStartIcon} />
            <Box pl='1em' fontWeight='semibold'> {memberName}</Box>
            <Box pl='0.3em'> created </Box>
            <Box pl='0.5em' color='brand.500' fontWeight='semibold'> {dsrTitle}</Box>
            <Box pl='0.5em' fontWeight='400' lineHeight='1.25em' fontSize='0.75em' color='gray.500'> • {moment(postCreatedAt).format('lll')}</Box>
          </Flex>

        )
      }
      case ("team-changes"): {
        const { changeType, membersChanged, postId } = message || {};

        const displayActionText = (changeType === 'add-member') ? 'added new members to the team' : (changeType === 'remove-member') ? 'removed members from the team' : (changeType === 're-add-member') ? 'added members back to the team' : "posted"

        const memberCards = membersChanged?.map((member, memberIndex) => {
          const { userId } = member || {};
          const addedMemberProfile = getUserDetailsUsingDsrMembersArray(dsrTeamMembers, userId);

          const { email, memberName, memberProfilePicSrc, memberOrgTitle, memberOrgName } = addedMemberProfile || {}
          const supplementaryUserInfo = (memberOrgTitle && memberOrgName) ? `${memberOrgTitle}, ${memberOrgName}` : memberOrgName ? memberOrgName : email;
          return (
            <React.Fragment key={`team_changes_${changeType}_${postId}${memberIndex}`}>
              <Flex justify='flex-start' align='center' mt='1em'>
                <Avatar h='4.2em' w='4.2em' name={memberName} bg='gray.100' color='gray.700' src={memberProfilePicSrc} />
                <Box pl='1em'>
                  <Box fontWeight='400' fontSize='0.875em' lineHeight='1.25em' color='brandDark.500'>{memberName ? memberName : email}</Box>
                  <Box fontWeight='500' fontSize='0.75em' lineHeight='1em' color='gray.500'>{supplementaryUserInfo}</Box>
                </Box>
              </Flex>
            </React.Fragment>
          )
        })

        return (
          <Box key={`message_${messageIndex}`} p='1em' mb='2em' w='100%' bg='white' rounded='0.25em' border='1px solid rgba(203, 213, 224, 0.5)' >
            <Flex align='center'>
              <Image src={DsrStartIcon} />
              <Box pl='1em'>
                <Box fontSize='0.9em'>
                  <span style={{ fontWeight: 800 }}
                  >{memberName}</span>{" "}
                  <span>{displayActionText}</span>
                </Box>

                <Box fontSize='0.8em' color='gray.500'>{moment(postCreatedAt).format('lll')}</Box>
              </Box>
            </Flex>

            <Box>
              {memberCards}
            </Box>
          </Box>
        )
      }
      case ("faq-question-post"): {
        const { answerTimestamp, answerAuthorUserId, questionTextContent, postQuestionAnswered } = message || {};
        let answererName = null;

        if (postQuestionAnswered) {
          const answerAuthorProfile = getUserDetailsUsingDsrMembersArray(dsrTeamMembers, answerAuthorUserId);
          if (answerAuthorProfile) {
            answererName = answerAuthorProfile.memberName
          }

        }

        return (
          <Box
            key={`message_${messageIndex}`}
            w='100%'
            mt='1em' mb='2em' p='1em'
            bg='white'
            rounded='0.25em' border='1px solid rgba(203, 213, 224, 0.5)'
          >
            <Flex width='100%' justify='space-between'>
              <Box>
                <Flex justify='flex-start' align='center'>
                  <Avatar h='4.2em' w='4.2em' name={memberName} bg='gray.100' color='gray.700' src={memberProfilePicSrc} />
                  <Box pl='1em'>
                    <Flex justify='flex-start' fontSize='0.9em'>
                      <Box fontWeight='semibold'>{memberName}</Box>
                      <Box pl='0.4em'>posted a question</Box>
                    </Flex>
                    <Box fontSize='0.8em' color='gray.500'>{messageTimestamp}  {isEdited ? <Box as='span' fontSize='1em' color='gray.500' lineHeight='1em' >  •  Edited</Box> : null}</Box>
                  </Box>
                </Flex>
              </Box>
            </Flex>

            <Box mt='1em'>
              <Flex align='flex-start' px='0.2em' py='1em'>
                <Box w='100%' bg='gray.100' rounded='0.25em' p='0.5em'>
                  <Flex className="white-space-preline">
                    <Box fontSize='0.9em' lineHeight='1.5em'>{questionTextContent}</Box>
                  </Flex>
                </Box>
              </Flex>

              {postQuestionAnswered &&
                  <Flex color='gray.500' fontSize='0.8em' fontStyle='italic'>
                    <Box>Answered by</Box>
                    <Box pl='0.5em' fontWeight='500'>{`${answererName} `} </Box>
                    <Box pl='0.5em' >on</Box>
                    <Box pl='0.5em' >{` ${moment(answerTimestamp).format('lll')}`}</Box>
                  </Flex>
              }
              <Box fontSize='0.8em' color='brand.500' mt='1em' cursor='pointer' onClick={() => handleSelectSubpage(faqPageId, "")} px='0.5em'>See all Q&A questions</Box>
            </Box>

            <Box key={`dsr_replies_${messageIndex}`}>
              <DsrRepliesDisplayModule
                messageRepliesArray={postReplies}
                postId={postId}
                handleEditPost={""} // isStaticMode = true; will not allow editing of post
                onClickDeleteReplyOption={""} // isStaticMode = true; will not allow editing of post
                confirmationType={'confirm-delete-reply'}
                isStaticMode={true}
              />
            </Box>

          </Box>
        );
      }
      default: {
        console.log('There are post types that cannot be rendered:', postType)
        return null
      }
    }
  }
  );

  function displayEmptyState() {
    return (
      <Flex mt='2em' justify='center' align='center' py='2.75em' border='1px solid #E5EAEF' rounded='0.5em' bg='white'>
        <Flex direction='column' justify='center' align='center'>

          <Image src={UpdatesEmptyState} boxSize="3em" />

          <Box fontWeight='400' fontSize='0.875em' lineHeight='1.5em' color='gray.500' py='1.5em' textAlign='center'>
            <Box>No posts yet, but not to worry.</Box>
            <Box>An automated welcome post will be created when the Room is published</Box>
          </Box>

        </Flex>
      </Flex>
    )
  }


  return (
    <Box>
      <Flex direction='column' w='100%'>

        {(postContent?.length > 0) ? displayedMessages : displayEmptyState()}

        {showLoadMore &&
          <Box display="flex" justifyContent="center" mt="1.5em" >
            <LaunchdeckButton bg='brand.500' color='white'
              loadingLogic={loadingMoreContent}
              onClick={loadMoreFeedContent}
              label='More posts' />
          </Box>}

      </Flex>
    </Box>
  )

}
