import React, { useState, useEffect } from "react";
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { Box, Flex } from "@chakra-ui/react";
import InputTextAreaExpandable from './InputTextAreaExpandable';
import { IoMdImage } from 'react-icons/io';
// import { RiVideoFill } from 'react-icons/ri'; // Note: Reason - Feature currently hidden
import { DsrLoomRecordButton } from "../../../digital-sales-room/dsr-record-content/DsrLoomRecordButton";
import DsrPostImagesUpload from "../../../digital-sales-room/dsr-create-post/DsrPostImagesUpload";
import DsrVideoUpload from "../../../digital-sales-room/dsr-create-post/DsrVideoUpload";
import InputTextAreaWithUserTagging from "./InputTextAreaWithUserTagging";
import { bindActionCreators } from "redux";
import {
  tagUserInPost,
  untagUserInPost,
  tagUserInReply,
  untagUserInReply
} from "../../../digital-sales-room/actions/user-tag-actions";
import { getDsrUpdatedFeedContent } from "../../../digital-sales-room/actions/dsr-actions";
import LaunchdeckButton from "../../../global/components/helper-components/components/LaunchdeckButton";
import LaunchdeckCreatePostButton from "../../../global/components/helper-components/components/LaunchdeckCreatePostButton";
import WarningTextMessage from "../../../global/components/helper-components/components/WarningTextMessage";
import LaunchdeckCloseButton from "../../../global/components/helper-components/components/LaunchdeckCloseButton";


function DsrPostTextAreaInput(props) {

  const { dsrId, postId, replyId, dsrFeedContent, htmlFor, currentValue, placeholder,
    onClickCreatePost, userActionRequired, onClickEditPost, onClickCreateReply,
    createNewDsrFeedPostOutcome, editDsrFeedPostOutcome, createNewDsrFeedReplyOutcome, editDsrFeedReplyOutcome, tagUserInPost, untagUserInPost,
    tagUserInReply, untagUserInReply, postTagProperties, getDsrUpdatedFeedContent,
    handleStopDsrModalLoader,
    dsrTeamMembers, showPostUploadImageOption
  } = props || {};

  const [thisDsrTeamMembers, setAllDsrTeamMembers] = useState(null);
  const [inputValue, setInputValue] = useState(currentValue ? currentValue : "");
  const [files, setFiles] = useState([]);
  const [loomVideo, setLoomVideo] = useState(null);
  const [postType, setPostType] = useState(null)
  
  const [displayEmptyInputError, setDisplayEmptyInputError] = useState(false)
  const [isInvalidInput, setIsInvalidInput] = useState(""); // UUU TODO: logic for warning invalid input message 
  const [previewLink, setPreviewLink] = useState(null)

  // Upload feature management
  const [showUploadImageOption, setShowUploadImageOption] = useState(showPostUploadImageOption)
  const [showUploadVideoOption, setShowUploadVideoOption] = useState(false)
  const [showDismissButton,setShowDismissButton] = useState(false)

  // User tagging feature management
  const [userTagProperties, setUserTagProperties] = useState(postTagProperties ? postTagProperties : [])
  const [taggedUserIds, setTaggedUserIds] = useState([]);
  const [untaggedUserIds, setUntaggedUserIds] = useState([]);
  const [replyTagProperties, setReplyTagProperties] = useState([]);

  useEffect(() => {
    // Tagging on post creating / Tagging post editing / Reply tagging
    let updatedUserTagProperties = replyId ? replyTagProperties : postTagProperties ? [...postTagProperties] : [];

    for (const taggedUserId of taggedUserIds) {
      const foundIndex = Array.isArray(updatedUserTagProperties) && updatedUserTagProperties?.findIndex(taggedUser => taggedUser.taggedUserId === taggedUserId)

      if (foundIndex < 0) {
        const userTagObject = {
          taggedUserId,
          notificationSent: null,
          untagged: false,
          taggedAt: new Date().toISOString(),
          tagPostId: postId || null,
        }
        updatedUserTagProperties = [...updatedUserTagProperties, userTagObject]
      }

      setUserTagProperties([...updatedUserTagProperties])
    }

  }, [taggedUserIds, untaggedUserIds]);


  useEffect(() => {  
    if (dsrTeamMembers) {
      const { sellerTeam, buyerTeam } = dsrTeamMembers || {};
      const allMembersArray = (sellerTeam && buyerTeam) ? [...sellerTeam, ...buyerTeam] : [...sellerTeam]
      setAllDsrTeamMembers(allMembersArray)
    }

  }, [dsrTeamMembers]);

  useEffect(() => {
    if (dsrFeedContent) {
      const post = dsrFeedContent.find(post => { return post.postId === postId })
      const reply = post?.postReplies.find(reply => { return reply.replyId === replyId })

      if (reply && reply.replyTaggedUsers.length > 0) {
        setReplyTagProperties([...reply.replyTaggedUsers])
      }
    }

  }, [dsrFeedContent, postId, replyId]);

  useEffect(() => {
    if (!inputValue) {
      setDisplayEmptyInputError(true)
    } else {
      setDisplayEmptyInputError(false)
    }
  }, [inputValue]);


  //Tagging in post
  useEffect(() => {
    if (createNewDsrFeedPostOutcome && taggedUserIds) {
      const { newPostCreated } = createNewDsrFeedPostOutcome || {};

      if (newPostCreated) {
        usersTagging(dsrId, newPostCreated.postId).then()
      }
    }
  }, [createNewDsrFeedPostOutcome]);

  useEffect(() => {
    if (editDsrFeedPostOutcome) {
      const { updatedSuccessfully } = editDsrFeedPostOutcome || {};

      if (updatedSuccessfully) {
        if (taggedUserIds.length > 0) {
          usersTagging(dsrId, postId).then()
        }

        if (untaggedUserIds.length > 0) {
          usersUntagging(dsrId, postId).then()
        }
      }

      handleStopDsrModalLoader()
    }

  }, [editDsrFeedPostOutcome]);

  const usersTagging = async (dsrId, postId) => {

    for (const userId of taggedUserIds) {
      const foundIndex = Array.isArray(userTagProperties) && userTagProperties?.findIndex(taggedUser => taggedUser.taggedUserId === userId)

      if (userTagProperties[foundIndex].notificationSent !== true || userTagProperties[foundIndex].untagged === true) {
        await tagUserInPost({ dsrId, postId, taggedUserId: userId })
      }
    }

    getDsrUpdatedFeedContent(dsrId);
  }

  const usersUntagging = async (dsrId, postId) => {
    for (const userId of untaggedUserIds) {

      const foundIndex = Array.isArray(userTagProperties) && userTagProperties?.findIndex(taggedUser => taggedUser.taggedUserId === userId)
      if (userTagProperties[foundIndex].untagged === false) {
        await untagUserInPost({ dsrId: dsrId, postId: postId, untaggedUserId: userId })
      }
    }

    getDsrUpdatedFeedContent(dsrId);
  }

  //Tagging in replies

  useEffect(() => {
    if (createNewDsrFeedReplyOutcome && taggedUserIds && dsrId && postId) {
      const { newReplyId } = createNewDsrFeedReplyOutcome || {};

      if (newReplyId) {
        usersTaggingInReply({ dsrId: dsrId, postId: postId, replyId: newReplyId }).then()
      }

    }
  }, [createNewDsrFeedReplyOutcome]);

  useEffect(() => {
    if (editDsrFeedReplyOutcome) {
      const { updatedSuccessfully, updatedReplyId } = editDsrFeedReplyOutcome || {};

      if (updatedSuccessfully && updatedReplyId) {
        if (taggedUserIds.length > 0) {
          usersTaggingInReply({ dsrId: dsrId, postId: postId, replyId: updatedReplyId }).then()
        }

        if (untaggedUserIds.length > 0) {
          usersUntaggingInReply({ dsrId: dsrId, postId: postId, replyId: updatedReplyId }).then()
        }
      }
    }

  }, [editDsrFeedReplyOutcome]);


  const usersTaggingInReply = async ({ dsrId, postId, replyId }) => {
    for (const userId of taggedUserIds) {
      const foundIndex = Array.isArray(userTagProperties) && userTagProperties?.findIndex(taggedUser => taggedUser.taggedUserId === userId)

      if (userTagProperties[foundIndex].notificationSent !== true || userTagProperties[foundIndex].untagged === true) {
        await tagUserInReply({ dsrId: dsrId, postId: postId, replyId: replyId, taggedUserId: userId })
      }
    }

    getDsrUpdatedFeedContent(dsrId);
  }

  const usersUntaggingInReply = async ({ dsrId, postId, replyId }) => {
    for (const userId of untaggedUserIds) {
      const foundIndex = Array.isArray(userTagProperties) && userTagProperties?.findIndex(taggedUser => taggedUser.taggedUserId === userId)

      if (userTagProperties[foundIndex].untagged === false) {
        await untagUserInReply({ dsrId: dsrId, postId: postId, replyId: replyId, untaggedUserId: userId })
      }
    }

    getDsrUpdatedFeedContent(dsrId);
  }

  const onChangeHandler = event => {
    setInputValue(event.target.value);
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const urls = event.target.value.match(urlRegex);
    const containsTwoDots = (event.target.value.match(/\./g) || []).length >= 2;
    
    if (urls && urls.length === 1 && containsTwoDots) {
   
      setPreviewLink(urls[0]);
      setPostType("shared-url")
    } else {
      setPreviewLink(null)
    }

    setIsInvalidInput("")
  };

  const handleDismissPreviewLink = () => {
    setPreviewLink(null)
    setPostType("short-text")
  }

  const handleClickCreateReply = () => {
    const newReplyContent = {
      "replyContent": inputValue
    }
    onClickCreateReply(newReplyContent)
    setInputValue("")
  }

  const getFiles = (files, type) => {
    setPostType(type)
    if (type === "loom-video-post") {
      setLoomVideo(files)
    } else {
      setFiles(files)
    }
  }

  const resetFileUploadState = () => {
      setShowUploadVideoOption(false)
      setShowUploadImageOption(false);
      setFiles([]);
  }


  switch (userActionRequired) {
    // TODO: Change 'edit' to something more specific - e.g. 'edit-dsr-post'

    case ('create-new-post'): {
      return (
        <Box py='1em' w='100%' bg='white' rounded='0.25em'>
            <>
              <Flex justify='flex-start' width='100%' px='1em' minH='4em'>
                <InputTextAreaWithUserTagging
                  onChangeHandler={onChangeHandler}
                  inputValue={inputValue}
                  previewLink={previewLink}
                  placeholder={placeholder}
                  dsrTeamMembers={thisDsrTeamMembers}
                  userTagProperties={userTagProperties}
                  taggedUserIds={taggedUserIds}
                  setTaggedUserIds={setTaggedUserIds}
                  untaggedUserIds={untaggedUserIds}
                  setUntaggedUserIds={setUntaggedUserIds}
                  handleDismissPreviewLink={handleDismissPreviewLink}
                />
              </Flex>


              {(files.length === 0 && !showUploadVideoOption && !showUploadImageOption) && !previewLink &&
                <Flex w='100%' justify='space-between' align='baseline' px='1em' pt='3em'>
                  <Flex>


                    <Flex justify='center' align='center' bg='gray.50' border='1px solid' borderColor='brand.100' rounded='0.25em' p='0.2em 0.5em' cursor='pointer'
                      onClick={() => {
                        setShowUploadImageOption(true);
                        setShowUploadVideoOption(false)
                    }} >

                      <Box as={IoMdImage} boxSize="1.2em" color='brand.500'/>

                      <Box fontSize='0.75em' ml='0.5em'>Add Images</Box>
                    </Flex>


                    {/* <Flex ml='0.5em' justify='center' align='center' bg='gray.50' border='1px solid' borderColor='brand.100' rounded='0.25em' p='0.2em 0.5em' cursor='pointer'
                      onClick={() => {
                        setShowUploadVideoOption(true);
                        setShowUploadImageOption(false)
                      }}
                    >

                      <Box as={RiVideoFill} boxSize="1.2em" color='brandPink.500' />

                      <Box fontSize='0.75em' ml='0.5em'>Add Video</Box>
                    </Flex> */}

                  </Flex>
                </Flex>}
            </>


          <Box>
            {showUploadVideoOption && 
            <Flex direction='column' justifyContent="center" alignItems="center" 
            bg='gray.50'
            border='1px dashed'  borderColor='gray.300' rounded='0.5em' m='1em'>
              <Flex w='100%' justify='flex-end' p='0.5em'>
                {showDismissButton ? 
                <LaunchdeckButton bg='gray.100' color='brandDark.500' border='1px solid' borderColor='gray.400' p='0.2em 1em' fontSize='0.7em' onClick={() => resetFileUploadState()} label='Remove video' />
                :
                <LaunchdeckCloseButton handleCloseModal={() => resetFileUploadState()} />
                }
              </Flex>
      
              <DsrVideoUpload getFiles={getFiles} setShowDismissButton={setShowDismissButton}/>
            </Flex>
            }

            {showUploadImageOption && 
             <Flex direction='column' justifyContent="center" alignItems="center" bg='gray.100' rounded='0.5em' m='1em'>
             <Flex w='100%' justify='flex-end' p='0.5em'>
               <LaunchdeckCloseButton handleCloseModal={() => resetFileUploadState()} />
             </Flex>
             <DsrPostImagesUpload getFiles={getFiles} />
           </Flex>
            }
          </Box>



          <Flex flex='1' mt='1em' justify='flex-end' borderTop='1px solid' borderTopColor='gray.100'>
           
            <Flex mt='0.625em' px='1em'>

              <LaunchdeckCreatePostButton
                label='Create Post'
                inputValue={inputValue}
                files={files}
                postType={postType}
                onClickCreatePost={onClickCreatePost}
                setInputValue={setInputValue}
                showUploadImageOption={showUploadImageOption}
                userActionRequired={userActionRequired}
                userTagProperties={userTagProperties}
              />
            </Flex>
          </Flex>
        </Box>
      );
    }
    case ('edit'): {
      const updatedPostContent = {
        "postContent": inputValue
      }

      return (
        <Flex direction='column' mb='2.2em' mx='1em' htmlFor={htmlFor} w='100%'>

          <Box mt='0.625em'>
            <InputTextAreaWithUserTagging
              onChangeHandler={onChangeHandler}
              inputValue={inputValue}
              previewLink={previewLink}
              placeholder={placeholder}
              dsrTeamMembers={thisDsrTeamMembers}
              userTagProperties={userTagProperties}
              taggedUserIds={taggedUserIds}
              setTaggedUserIds={setTaggedUserIds}
              untaggedUserIds={untaggedUserIds}
              setUntaggedUserIds={setUntaggedUserIds}
              handleDismissPreviewLink={handleDismissPreviewLink}
            />
            {displayEmptyInputError && <Box fontSize="0.8em" color="pink.500" mt='0.2em'>Your edited message is empty</Box>}

          </Box>

          <Flex w='100%' mt='3em' justify='center' alignItems='center'>
            <Flex justify={['center', 'flex-end', 'flex-end', 'flex-end']} w='100%'>
              <LaunchdeckButton 
                bg='gray.50'
                color={displayEmptyInputError ? 'gray.500' : 'brand.500'}
                borderColor='brand.300'
                onClick={((displayEmptyInputError === false) && (inputValue !== currentValue))
                  ? () => onClickEditPost(updatedPostContent)
                  : null
                }
                label='Save changes'
              />
            </Flex>
          </Flex>
        </Flex>
      )
    }
    case ('create-new-reply'): {

      return (
        <Box htmlFor={htmlFor} w='100%'>
          <Box p='0.5em 0.875em' mt='0.5em' border='1px solid' borderColor='gray.300' rounded='0.25em'>
            <InputTextAreaWithUserTagging
              onChangeHandler={onChangeHandler}
              inputValue={inputValue}
              previewLink={previewLink}
              placeholder={placeholder}
              dsrTeamMembers={thisDsrTeamMembers}
              userTagProperties={userTagProperties}
              taggedUserIds={taggedUserIds}
              setTaggedUserIds={setTaggedUserIds}
              untaggedUserIds={untaggedUserIds}
              setUntaggedUserIds={setUntaggedUserIds}
              handleDismissPreviewLink={handleDismissPreviewLink}
            />
          </Box>
          <WarningTextMessage errorLogic={isInvalidInput} message='Empty reply message' />
          <Flex mt='0.5em' justify='flex-end' w='100%'>

            <LaunchdeckButton bg='brand.500' color='white' onClick={() => inputValue.trim().length > 0 ? handleClickCreateReply() : setIsInvalidInput(true)} label='Comment' />

          </Flex>
        </Box>
      );
    }
    case ('edit-dsr-reply'): {
      const updatedPostContent = {
        "replyContent": inputValue
      }

      return (
        <Flex direction='column' mb='2.2em' mx='1em' htmlFor={htmlFor} w='100%'>

          <Box mt='0.625em'>
            <InputTextAreaWithUserTagging
              onChangeHandler={onChangeHandler}
              inputValue={inputValue}
              previewLink={previewLink}
              placeholder={placeholder}
              dsrTeamMembers={thisDsrTeamMembers}
              userTagProperties={userTagProperties}
              taggedUserIds={taggedUserIds}
              setTaggedUserIds={setTaggedUserIds}
              untaggedUserIds={untaggedUserIds}
              setUntaggedUserIds={setUntaggedUserIds}
              handleDismissPreviewLink={handleDismissPreviewLink}
            />
            {displayEmptyInputError && <Box fontSize="0.8em" color="pink.500" mt='0.2em'>Your edited message is empty</Box>}
          </Box>

          <Flex w='100%' mt='3em' justify='center' alignItems='center'>
            <Flex justify={['center', 'flex-end', 'flex-end', 'flex-end']} w='100%'>
              <LaunchdeckButton bg='gray.50' color={displayEmptyInputError ? 'gray.500' : 'brand.500'} onClick={(displayEmptyInputError === false) ? () => onClickEditPost(updatedPostContent) : null} label='Edit reply' />
            </Flex>
          </Flex>
        </Flex>
      )
    }
    case ('create-faq-question'): {
      return (
        <Box py='1em' w='100%' bg='white' rounded='0.25em'>
          <Flex justify='flex-start' width='100%' px='1em'>
            <InputTextAreaExpandable
              onChangeHandler={onChangeHandler}
              inputValue={inputValue}
              placeholder={placeholder}
            />
          </Flex>


          <Flex flex='1' mt='1em' justify='flex-end' borderTop='1px solid' borderTopColor='gray.100'>
            <Flex mt='0.625em' px='1em'>
              <LaunchdeckCreatePostButton
                label='Post'
                inputValue={inputValue}
                onClickCreatePost={onClickCreatePost}
                setInputValue={setInputValue}
                userActionRequired={userActionRequired}
              />
            </Flex>
          </Flex>

        </Box>
      );
    }
    case ('prepare-faq-question-in-draft'): {
      return (
        <Box py='1em' w='100%' bg='white' rounded='0.25em'>
          <Flex justify='flex-start' width='100%' px='1em'>
            <InputTextAreaExpandable
              onChangeHandler={onChangeHandler}
              inputValue={inputValue}
              placeholder={placeholder}
            />
          </Flex>


          <Flex flex='1' mt='1em' justify='flex-end' borderTop='1px solid' borderTopColor='gray.100'>
            <Flex mt='0.625em' px='1em'>
              <LaunchdeckButton bg='brand.500' color='white' onClick={() => onClickCreatePost(inputValue)} label='Create question' />
            </Flex>
          </Flex>

        </Box>
      );
    }
    case ('create-video-post'): {
      return (
        <Box py='1em' w='100%' bg='white' rounded='0.25em'>
          <Flex justify='flex-start' width='100%' px='1em'>
            <InputTextAreaExpandable
              onChangeHandler={onChangeHandler}
              inputValue={inputValue}
              placeholder={placeholder}
              fontSize='0.875em'
            />
          </Flex>
          <Flex w='100%' justify='flex-end' align='center' px='1em' pt='1em'>
            <DsrLoomRecordButton getFiles={getFiles} sending={false} submitRecording={(v) => { }} />
          </Flex>
          <Flex flex='1' mt='1em' justify='flex-end' borderTop='1px solid' borderTopColor='gray.100'>
            <Flex mt='0.625em' px='1em'>
              <LaunchdeckCreatePostButton
                label='Create Video Post'
                inputValue={inputValue}
                loomVideo={loomVideo}
                postType={postType}
                onClickCreatePost={onClickCreatePost}
                setInputValue={setInputValue}
                userActionRequired={userActionRequired}
                userTagProperties={userTagProperties}
              />
            </Flex>
          </Flex>
        </Box>
      );
    }
    default: {
      return null;
    }
  }
}

const mapStateToProps = (state) => ({
  createNewDsrFeedPostOutcome: state.createNewDsrFeedPostOutcome.results.data,
  editDsrFeedPostOutcome: state.editDsrFeedPostOutcome.results.data,
  dsrFeedContent: state.dsrFeedContent.results,
  dsrTeamMembers: state.dsrTeamMembers.members
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      tagUserInPost,
      untagUserInPost,
      tagUserInReply,
      untagUserInReply,
      getDsrUpdatedFeedContent
    },
    dispatch
  )

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DsrPostTextAreaInput))
