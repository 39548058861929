import React from 'react';
import { Spinner, Flex} from "@chakra-ui/react";


const PageLoader = ({boxSize}) => {
	return (
		<Flex w='100%' alignItems='center' justify='center' height='30vh' >
			<Spinner
				thickness="5px"
				speed="0.65s"
				emptyColor="gray.200"
				color="brand.500"
				boxSize={boxSize ? boxSize : "7em"}
			/>
		</Flex>
	);
};

export default PageLoader;
