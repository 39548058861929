import React, {useEffect, useState} from 'react';
// import { connect } from 'react-redux';
// import { bindActionCreators } from 'redux';

// import { login, 
// 	// googleLogin 
// } from '../actions/auth-actions';

import LoginForm from './LoginForm';
import { ChakraBaseProvider, Flex, Box } from '@chakra-ui/react';
import claverTheme from '../../layout/components/layout/components/theme/claverTheme';
import PrivacyTos from './PrivacyTos';
import GeneralPageFooter from './GeneralPageFooter';
// import PageLoader from '../../global/components/helper-components/components/PageLoader';

import PublicUserHeader from './PublicUserHeader';
import { useLocation } from 'react-router-dom';
// import GoogleLogo from '../styles/images/google-login-logo.svg';
// import { GoogleLogin } from 'react-google-login';


export default function Login({ ...props }) {
	const location = useLocation();
	const [prepopEmailDisplay,setPrepopEmailDisplay]= useState("")

	useEffect(() => {
		if (location.state) {
			
			const { prepopEmail } = location.state;
			setPrepopEmailDisplay(prepopEmail) 
		}
  },[location.state]);

	// const loginProcessFinished = (login.loading === false) ? true : "";
	// const loginProcessInProgress = (loginFeedback.loading === true) ? true : "";
	// const { loginError, message } = login.results || {};
	const query = new URLSearchParams(props.location.search)
  const redirectUrl = query.get("redirect")



	// TODO: Use dispatch action to log person in
	// if (loginProcessInProgress) {
	// 	return (
	// 		<ChakraBaseProvider theme={claverTheme}>
	// 			<PageLoader />
	// 		</ChakraBaseProvider>
	// 	)
	// }

	// const handleGoogleLogin = async (googleData) => {
	// 	if (!googleData.error) {
	// 		// marketingUserId attribution is not relevant for login cases, represented by ''
	// 		props.action.googleLogin(googleData, '')
	// 	}
	// }

	return (
		<ChakraBaseProvider theme={claverTheme}>
			<Flex direction='column' w='100%' h='100%'>
			<PublicUserHeader signUpEnabled redirectUrl={redirectUrl}/>
			{/* {loginProcessFinished && redirectToHomepageIfLoggedIn()} */}

			<Box className="authentication-container">
				<Flex direction={['column-reverse', 'column-reverse', 'row', 'row']} w='100%' px={['1em', '0em', '0em', '0em']} py='2em' justify='space-betweeen' align='stretch'></Flex>
				<Box direction='column' maxWidth='37.5em' m={['0 10%', '0 10%', 'auto', 'auto']} className='fade-in-right default-box-shadow' rounded='0.25em'>
					<Flex direction='column' alignItems='center' bg='white' rounded='0.25em' >
						<Box w='100%' borderBottom='1px solid' borderColor='gray.300' py='0.8em' >
							<Box w='100%' fontSize='1.2em' lineHeight='1.875em' fontWeight='500' textAlign='center'>Log in to Launchdeck</Box>
						</Box>

						<Box w='100%' px='1.5em'>
							<LoginForm prepopEmail={prepopEmailDisplay}/>
						</Box>

						{/* <Box w='100%' position='relative'>
							<Box position='absolute' bg='white' color='gray.500' zIndex='10' top='-0.5em' mx='45%' px='1em' fontSize='0.875em' fontWeight='500'>OR</Box>
							<Divider />
						</Box> */}

						{/* // UUU Disable Google log in temporarily during development phase. To uncomment after development */}
						{/* <GoogleLogin
							clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
							onSuccess={handleGoogleLogin}
							onFailure={handleGoogleLogin}
							cookiePolicy={'single_host_origin'}
							render={renderProps => (
								<Flex w='100%' py='2em' px='3em' alignItems='center' justify='center'>
									<Flex flex='1' px='1em' py='0.625em' bg='gray.100' rounded='0.25em' cursor='pointer' onClick={renderProps.onClick} disabled={renderProps.disabled}>
										<Image src={GoogleLogo}></Image>
										<Box pl='1em'>
											Log in with Google
										</Box>
									</Flex>
								</Flex>
							)}
						/> */}

						<Box borderTop='1px solid' borderColor='gray.300' w='100%' py='1em' px='2em'>
							<PrivacyTos />
						</Box>
					</Flex>
				</Box>

				<Box pt='2em' bg='gray.50'>
					<GeneralPageFooter />
				</Box>
			</Box>
			</Flex>
		</ChakraBaseProvider>
	);
}



// function mapStateToProps(state) {
// 	return {
// 		loginFeedback: state.auth.data.login,
// 		// loginFeedback: state.auth // TODO: Review if required?
// 	};
// }

// function mapDispatchToProps(dispatch) {
// 	return {
// 		action: bindActionCreators(
// 			{
// 				login,
// 				// googleLogin
// 			},
// 			dispatch
// 		)
// 	};
// }

