import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';

import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay
} from "@chakra-ui/react";
import '../../styles/dsr-display.scss';
import { MdError } from "react-icons/md";
import { deleteFaqQuestion, resetDeleteFaqQuestion } from '../../actions/dsr-actions';
import PageLoader from '../../../global/components/helper-components/components/PageLoader';
import LaunchdeckCloseButton from '../../../global/components/helper-components/components/LaunchdeckCloseButton';
import LaunchdeckButton from '../../../global/components/helper-components/components/LaunchdeckButton';



function ConfirmDeleteFaqQuestionModal({ dsrId, questionId, handleCloseModal, deleteFaqQuestionOutcome, deleteFaqQuestion, resetDeleteFaqQuestion  }) {

  // // UX Management
  const [showLoader, setShowLoader] = useState(false)

  useEffect(() => {
    if (deleteFaqQuestionOutcome) {
      const {faqQuestionSuccessfullyUpdated} = deleteFaqQuestionOutcome || {};
      if (faqQuestionSuccessfullyUpdated) {
        setShowLoader(false);
        handleCloseModal()
      }

      resetDeleteFaqQuestion()
    }

  }, [deleteFaqQuestionOutcome, handleCloseModal, resetDeleteFaqQuestion()]);

  const handleConfirmDeleteFaqQuestionButton = () => {
    setShowLoader(true);
    
    const deleteFaqQuestionMetadata = {
      dsrId: dsrId,
      questionId: questionId
    }
    
    deleteFaqQuestion(deleteFaqQuestionMetadata)
  }

  function displayContent() {
    if (showLoader) {
      return (
        <Box w="100%" align="center" >
          <Box px='1em'>
            <Box>
              <PageLoader />
            </Box>
          </Box>
        </Box>
      )
    }

    if (!showLoader) {

      return (
        <Flex justify='flex-start' px='1.5em' mt='1em' w='100%'>
          <Box w='100%'>

            <Box w="100%" my="1em" color='brandDark.500'>

              <Box fontSize='0.875em'>
                This action cannot be undone. Go ahead?
              </Box>
            </Box>

            <Flex w='100%' mt='1em'>
              <Flex my='0.625em' justify='flex-end' w='100%'>
               <LaunchdeckButton name='showDeleteFaqQuestion' bg='gray.700' color='white' px='2em'  fontSize='0.8em'  w={["100%", "100%", "auto", "auto"]} onClick={() => handleConfirmDeleteFaqQuestionButton()} label='Delete Question' />
              </Flex>
            </Flex>
          </Box>


        </Flex>
      )
    }
  }

  return (
    <Modal isOpen={true} closeOnOverlayClick={false} isCentered={true}>
      <ModalOverlay className="modal-background" />
      <ModalContent rounded='0.25em' minW={['90%', '500px']} maxW='700px'>
        <ModalBody px='0em'>
          <Flex direction='column' justify='space-around' alignItems='center' w='100%'>
            <Flex justify='space-between' w='100%' borderBottom='1px solid' borderBottomColor='gray.300'>
              <Flex px='1em' justify='flex-start' align='center'>
                <Box as={MdError} color='pink.500'
                  boxSize="1.6em" />
                <Box px='1.5em' py='0.5em' fontSize='0.9em' color='brandDark.500'>You are about to delete this Q&A question</Box>
              </Flex>

              <Box pr='1.5em'>
                <LaunchdeckCloseButton handleCloseModal={() => handleCloseModal()} />
              </Box>
            </Flex>

            <React.Fragment>
              {displayContent()}
            </React.Fragment>

          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

function mapStateToProps(state) {
  return {
    deleteFaqQuestionOutcome: state.deleteFaqQuestionOutcome.results.data
  };
}

// function mapDispatchToProps(dispatch) {
//   return {
//     action: bindActionCreators(
//       {
//         deleteFaqQuestion,
//         resetDeleteFaqQuestion
//       },
//       dispatch
//     )
//   };
// }

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
      deleteFaqQuestion,
      resetDeleteFaqQuestion
		},
		dispatch,
	)

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ConfirmDeleteFaqQuestionModal));
