import _ from 'lodash'
export function removeEmptyWidgets(pages) {
  const pagesCopy = _.cloneDeep(pages)

  for (let i = 0; i <= pagesCopy?.length - 1; i++) {
    if (pagesCopy[i].pageType === 'customised-page') {
      pagesCopy[i].pageContent = pagesCopy[i].pageContent.filter(content => {
        return content.widgetContent[0].subWidgets.length > 0;
      });
    }
  }
  return pagesCopy;
}

export function removeEmptySubWidgets(pages) {
  const pagesCopy = _.cloneDeep(pages)

  for (let i = 0; i < pagesCopy?.length; i++) {
    if (pagesCopy[i].pageType === 'customised-page') {
      pagesCopy[i].pageContent.forEach(content => {
        content.widgetContent.forEach(widget => {
          widget.subWidgets = widget.subWidgets.filter(subWidget => subWidget.content !== "");
        });
      });
    }
  }

  return pagesCopy;
}

export function getWidgetUploadedFileKeys (widget){
  const types = ["uploaded-image", "uploaded-banner", "uploaded-video", "uploaded-pdf", "uploaded-audio"];
 
  const keysArray = [];

  for (const columnId in widget.widgetContent) {
    const column = widget.widgetContent[columnId];
    for (const subWidgetId in column.subWidgets) {
      const subWidget = column.subWidgets[subWidgetId];

      if (types.includes(subWidget.contentBlockType)) {
        if (subWidget.content && Array.isArray(subWidget.content)) {
          subWidget.content.forEach((item) => {
            if (item.key) {
              keysArray.push({ Key: item.key });
            }
          });
        }
      }
    }
  }

  return keysArray;
}