import { Box, Flex, Fade } from "@chakra-ui/react";
import LaunchdeckButton from "../../../global/components/helper-components/components/LaunchdeckButton";
import { redirectToNewPage } from "../../../global/helpers/global-helpers";

export default function SignupErrorStateContent() {
  return (
    <Flex w='100%' pt='2em' justify='center'>
      <Fade in={true}>
        <Box textAlign='center' pt='3em' maxW='700px' mx='auto'>
          <Box fontSize='1em' fontWeight='600' color='brandDark.500'>Invalid or deactivated account </Box>
          <Box color='brandDark.500' fontSize='0.875em' mt='1.5em'>You, or an administrator, may have deactivated your Launchdeck account before </Box>
          <Box color='brandDark.500' fontSize='0.875em'>
            If you require a Launchdeck account with this email account, please seek assistance from us
          </Box>
          <Flex direction='column' mt='3em' justify='center' alignItems='center'>

            <Flex justify='center' minW='250px'>
              <LaunchdeckButton w='100%' bg='brandPink.500' color='white' onClick={() => redirectToNewPage('/contact-launchdeck')} label='Seek assistance' />
            </Flex>
          </Flex>

        </Box>
      </Fade>
    </Flex>
  )
}