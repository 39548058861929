import React, { useCallback, useState } from 'react';

import { Box, CircularProgress, Flex, Image } from "@chakra-ui/react";
import { Carousel } from "react-responsive-carousel";
import { FaRegImages } from "react-icons/fa";
import useLoadingTimeout from '../../../global/components/helper-components/useComponents/useLoadingTimeout';
import { Controlled as Zoom } from 'react-medium-image-zoom';
import { TbZoomInArea } from "react-icons/tb";
import 'react-medium-image-zoom/dist/styles.css'


const DsrImageDisplay = ({ subWidgetContent }) => {
  const { loading, setLoading, setError } = useLoadingTimeout(5000); // 10 seconds timeout
  const [isZoomed, setIsZoomed] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const handleZoomChange = useCallback((shouldZoom) => {
    setIsZoomed(shouldZoom);
  }, []);

  const handleZoomButtonClick = (e) => {
    setIsZoomed(true);
  };

  const handleImageLoad = () => {
    setLoading(false);
    setError(false);
  };

  const handleImageError = () => {
    setLoading(false);
    setError(true);
  };


  const imgPlaceholder = <Flex direction="column" alignItems="center" justifyContent="center" height="70px">
    <Box as={FaRegImages} width="70px" height="50px" mb="5px" />
    <p>Upload your images</p>
  </Flex>;

  const configs = {
    autoPlay: false,
    infiniteLoop: true,
    showThumbs: false,
    swipeable: true,
    showStatus: false,
    showIndicators: true,
    showArrows: true,
    dynamicHeight: false,
  };

  return (

    <Box mb='2em'>
      {
        (Array.isArray(subWidgetContent) && subWidgetContent.length > 1)
          ? (
            <Carousel
              {...configs}
            >
              {subWidgetContent?.map((image, index) => (
                <div key={index} style={{ position: 'relative' }}>
                  <img
                    src={image['url']} alt={""}
                    className="carousel-image"
                  />
                </div>
              ))}
            </Carousel>
          )
          : subWidgetContent
            ?

            <Box minH={loading && '18em'} border='1px solid' borderColor='gray.100' rounded='0.25em'
              position='relative'
              display='inline-block'
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >

              {loading && (
                <CircularProgress
                  isIndeterminate
                  color="brand.500"
                  thickness='0.3em'
                  size='1.5em'
                  position="absolute"
                  top="50%"
                  left="50%"
                  transform="translate(-50%, -50%)"
                />
              )}
              <Zoom isZoomed={isZoomed} onZoomChange={isZoomed ? handleZoomChange : undefined}>
                <Image
                  onLoad={handleImageLoad}
                  onError={handleImageError}
                  src={typeof subWidgetContent === 'string' ? subWidgetContent : subWidgetContent[0].url}
                  alt="Sub-widget image"
                  width="100%"
                  rounded='0.25em'
                  display={loading ? 'none' : 'block'}
                  style={{
                    cursor: isZoomed ? 'zoom-out' : 'default', // Change cursor style based on zoom state
                  }}
                />
              </Zoom>


              {isHovered && (
                <button
                  onClick={handleZoomButtonClick}
                  style={{
                    position: 'absolute',
                    top: '10px',
                    right: '10px',
                    padding: '10px',
                    background: 'rgba(0, 0, 0, 0.5)',
                    color: 'white',
                    border: 'none',
                    borderRadius: '5px',
                    cursor: 'pointer',
                  }}
                >
                  <Box as={TbZoomInArea} boxSize='1.2em' />
                </button>
              )}
            </Box>
            : imgPlaceholder
      }
    </Box>
  );
};

export default DsrImageDisplay;
