import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import _ from 'lodash';

import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Image,
  useToast,
  TabPanel,
  TabPanels,
  Tabs,
  Tab,
  TabList,
  Input
} from "@chakra-ui/react";
import '../styles/dsr-display.scss';
import '../styles/dsr-invite-display.scss';


import { triggerLaunchdeckToast, validateUrlFormat } from '../../global/helpers/global-helpers';
import LaunchdeckButton from '../../global/components/helper-components/components/LaunchdeckButton';
import FormTextInput from '../../forms/TextInputField/components/FormTextInput';

import '../../global/styles/custom-animations.scss'
import { updateDsr, resetUpdateDsrOutcome, deleteFiles, deleteFilesReset } from '../../digital-sales-room/actions/dsr-actions';
import PageLoader from '../../global/components/helper-components/components/PageLoader';
import LaunchdeckCloseButton from '../../global/components/helper-components/components/LaunchdeckCloseButton';
import { TbBrandCake } from "react-icons/tb";

import { uploadFiles, uploadFilesReset } from "../../digital-sales-room/actions/dsr-actions";
import { RiErrorWarningLine } from "react-icons/ri";
import { IoCloudUploadOutline } from "react-icons/io5";
import ImageUploading from "react-images-uploading";
import WarningTextMessage from '../../global/components/helper-components/components/WarningTextMessage';
import FileUploadProgress from '../dsr-create-post/FileUploadProgress';
import config from "../../../config/app-config";

function ManageDsrLogoModal(props) {

  const toast = useToast();
  const { dsrId, handleCloseModal, updateDsrOutcome, isUpdating, dsrPropertiesRedux, isMobileView, dsrDisplayLogoImages,
    updateDsr,
    resetUpdateDsrOutcome,

    uploadFiles,
    uploadFilesReset,
    uploadFilesState,

    deleteFiles,
    deleteFilesReset,
    deleteFilesState
  } = props || {};

  const originalDsrProperties = useRef(dsrPropertiesRedux);
  const { maxImageFileSize } = config;


  const [uriInput, setUriInput] = useState("") // Need an input and a feeder to limit Clearbit API calls
  const [feedUriInput, setFeedUriInput] = useState("")
  const [newCbLogoFound, setNewCbLogoFound] = useState(false)

  // UX management
  const latestDsrProperties = dsrPropertiesRedux ? dsrPropertiesRedux : {}
  const [currentLogoImage, setCurrentLogoImage] = useState("")
  const [showLogoImage, setShowLogoImage] = useState(false)
  const [showInvalidUrlError, setShowInvalidUrlError] = useState(false)
  const [showImageError, setShowImageError] = useState(false)
  const [showButtons, setShowButtons] = useState(false)

  const [showLogoLoader, setShowLogoLoader] = useState(false)
  const [showLogoInput, setShowLogoInput] = useState(false)
  const [showChangeLogoButton, setShowChangeLogoButton] = useState(true)

  const [logoType, setLogoType] = useState("") // Possible values : cb-suggested :: textual :: uploaded-image
  const [defaultTab, setDefaultTab] = useState(null)
  const [textLogo, setTextLogo] = useState("")

  // Uploading Logo
  const [dsrUploadedLogoUrl, setDsrUploadedLogoUrl] = useState("")
  const [logoFile, setLogoFile] = useState(null)

  const [uploadLogoInProgress, setUploadLogoInProgress] = useState(false)
  // const [displayUploadLogoError, setDisplayUploadLogoError] = useState(false)
  const [showConfirmUseUploadedLogo, setShowConfirmUseUploadedLogo] = useState(false)
  const [uploadedLogoImageKey, setUploadedLogoImageKey] = useState("")

  const [retrievedLogoImageKey, setRetrievedLogoImageKey] = useState("")
  const [showDefaultIsUploadedDsrLogo, setShowDefaultIsUploadedDsrLogo] = useState(false)

  const [audienceOrgLogo, setAudienceOrgLogo] = useState("")
  const [showPickGuestLogo, setShowUseGuestLogo] = useState(false)

  useEffect(() => {
    if (dsrPropertiesRedux) {
      const { dsrLogoType } = dsrPropertiesRedux || {};
      dsrLogoType && setLogoType((dsrLogoType === "no-logo") ? "cb-dsr-logo" : dsrLogoType ? dsrLogoType : "cb-dsr-logo")

      switch (dsrLogoType) {
        case ("cb-dsr-logo"): {
          setDefaultTab(0)

          const { displayedAudienceLogo, foundAudienceOrgLogo } = dsrDisplayLogoImages || {}
          setFeedUriInput(displayedAudienceLogo)
          setShowLogoImage(true)
          foundAudienceOrgLogo && setAudienceOrgLogo(foundAudienceOrgLogo)
          setShowUseGuestLogo(!!foundAudienceOrgLogo)
          break
        }
        case ("uploaded-dsr-logo"): {
          setDefaultTab(1)
          const { displayedAudienceLogo, displayedLogoImageKey, foundAudienceOrgLogo } = dsrDisplayLogoImages || {}
          setDsrUploadedLogoUrl(displayedAudienceLogo)
          setRetrievedLogoImageKey(displayedLogoImageKey)
          setShowDefaultIsUploadedDsrLogo(true)
          foundAudienceOrgLogo && setAudienceOrgLogo(foundAudienceOrgLogo)
          setShowUseGuestLogo(!!foundAudienceOrgLogo)
          break
        }
        case ("textual-dsr-logo"): {
          setDefaultTab(2)

          const { textLogoString, foundAudienceOrgLogo } = dsrDisplayLogoImages || {}

          setTextLogo(textLogoString ? textLogoString : "")
          foundAudienceOrgLogo && setAudienceOrgLogo(foundAudienceOrgLogo)
          setShowUseGuestLogo(!!foundAudienceOrgLogo)
          break
        }
        case ("audience-chosen-org-logo"): {
          setDefaultTab(3)

          const { foundAudienceOrgLogo } = dsrDisplayLogoImages || {}
          setAudienceOrgLogo(foundAudienceOrgLogo)
          setShowUseGuestLogo(true)
          break
        }
        default: {
          setDefaultTab(0)
          break;
        }
      }
    }
  }, [dsrPropertiesRedux, setDefaultTab, dsrDisplayLogoImages]);

  useEffect(() => {
    if (updateDsrOutcome) {
      const { updateDsrStatus, updatedDsrId, sourceEvent, dsrDisplayLogos } = updateDsrOutcome || {};
      
      if (sourceEvent === "save-guest-logo") {
        if (updateDsrStatus && updatedDsrId === dsrId && dsrId !== undefined) {
          const { displayedAudienceLogo, textLogoString, foundAudienceOrgLogo } = dsrDisplayLogos || {}

          toast(
            triggerLaunchdeckToast({
              useCase: "show-success-state",
              label: (displayedAudienceLogo || textLogoString || (foundAudienceOrgLogo && logoType === "audience-chosen-org-logo")) ? "Logo updated successfully" : "No logo will be used",
              isMobileView
            })
          )

        } else if (!updateDsrStatus) {
          toast(
            triggerLaunchdeckToast({
              useCase: "show-error-state",
              label: "Unfortunately something went wrong. Please try again later.",
              isMobileView
            })
          )

        }
        handleCloseModal()
        resetUpdateDsrOutcome()
      }
    }
  }, [updateDsrOutcome, toast, dsrId, resetUpdateDsrOutcome, handleCloseModal]);

  useEffect(() => {
    if (uploadFilesState) {

      const { isLoading, results, error } = uploadFilesState;
      setUploadLogoInProgress(isLoading)

      if (!isLoading && error) {
        // setDisplayUploadLogoError(error)

        toast(
          triggerLaunchdeckToast({
            useCase: "show-error-state",
            label: "Unfortunately we weren't able to set the image as the Room logo. Please try again later.",
            isMobileView
          })
        )
      }

      if (!isLoading && !error && results.files) {

        setDsrUploadedLogoUrl(results?.files[0]?.url)
        setUploadedLogoImageKey(results?.files[0]?.key)
        setShowConfirmUseUploadedLogo(true)

        uploadFilesReset();
      }

    }
  }, [uploadFilesState, setUploadLogoInProgress, setDsrUploadedLogoUrl])

  useEffect(() => {
    if (deleteFilesState) {

      const { deleted: deletedObjects } = deleteFilesState || {}

      if (Array.isArray(deletedObjects)) {

        if (deletedObjects.length > 0) {
          setLogoFile(null)
          setShowConfirmUseUploadedLogo(false)

          if (logoType === "uploaded-dsr-logo") {
            toast(
              triggerLaunchdeckToast({
                useCase: "show-success-state",
                label: "Logo successfully removed",
                isMobileView
              })
            )
          }

        }
      }


      deleteFilesReset()
    }
  }, [deleteFilesState]);

  const handleInputChange = (event) => {
    setUriInput(event.target.value)
  }

  const handlePressEnter = (event) => {
    if (event.key === 'Enter' && event.target.name === "logo-search-input") {
      setShowLogoLoader(true)
      setShowInvalidUrlError(false)
      const isValidUrl = uriInput ? validateUrlFormat(uriInput) : false;
      setShowImageError(false)
      setCurrentLogoImage(false)

      if (isValidUrl && uriInput) {
        setFeedUriInput(`https://logo.clearbit.com/${uriInput}`)
        setShowLogoImage(true)
        setShowButtons(true)
        setNewCbLogoFound(true)

      } else {
        setShowInvalidUrlError(true)
      }

    }
  }

  const handleGetCbLogo = () => {
    setShowLogoLoader(true)
    setShowInvalidUrlError(false)
    const isValidUrl = uriInput ? validateUrlFormat(uriInput) : false;
    setShowImageError(false)
    setCurrentLogoImage(false)

    if (isValidUrl && uriInput) {
      // setFeedUriInput(uriInput)
      setFeedUriInput(`https://logo.clearbit.com/${uriInput}`)
      setShowLogoImage(true)
      setShowButtons(true)
      setNewCbLogoFound(true)

    } else {
      setShowInvalidUrlError(true)
    }
  }

  const handleSearchNewCbLogo = () => {
    setFeedUriInput("")
    setShowLogoImage(false)
    setShowButtons(false)
    // setNewCbLogoFound(false)
    setShowImageError(false)
    setShowLogoLoader(false)
  }

  const handleSaveGuestLogoInDsrProperties = (userObjective) => {
    // Validation & Checks
    const noSaveRequired = _.isEqual(originalDsrProperties, latestDsrProperties);
    let updateDsrRequired = false;

    if (latestDsrProperties && userObjective) {

      if (userObjective === "add-logo") {
        latestDsrProperties.dsrLogoType = logoType;
 
        switch (logoType) {
          case ("cb-dsr-logo"): {
            latestDsrProperties.dsrLogoUrl = `${feedUriInput}`
            latestDsrProperties.dsrTextLogoString = ""
            latestDsrProperties.dsrUploadedLogoUrl = ""
            latestDsrProperties.displayedLogoImageKey = ""
            break;
          }
          case ("textual-dsr-logo"): {
            latestDsrProperties.dsrTextLogoString = textLogo.trim()
            latestDsrProperties.dsrLogoUrl = ""
            latestDsrProperties.dsrUploadedLogoUrl = ""
            latestDsrProperties.displayedLogoImageKey = ""
            break;
          }
          case ("uploaded-dsr-logo"): {
            latestDsrProperties.dsrUploadedLogoUrl = dsrUploadedLogoUrl
            latestDsrProperties.displayedLogoImageKey = uploadedLogoImageKey
            latestDsrProperties.dsrLogoUrl = ""
            latestDsrProperties.dsrTextLogoString = ""
            latestDsrProperties.refGuestUserId = ""
            break;
          }
          case ("audience-chosen-org-logo"): {
            latestDsrProperties.dsrUploadedLogoUrl = ""
            latestDsrProperties.displayedLogoImageKey = ""
            latestDsrProperties.dsrLogoUrl = ""
            latestDsrProperties.dsrTextLogoString = ""
            break;
          }
          default: {
            break;
          }
        }

        updateDsrRequired = true;
      } else if (userObjective === "remove-logo") {
        latestDsrProperties.dsrLogoType = "no-logo"
        latestDsrProperties.dsrLogoUrl = "";
        latestDsrProperties.dsrTextLogoString = "";
        latestDsrProperties.dsrUploadedLogoUrl = "";

        updateDsrRequired = true;
      }

      if (userObjective === "delete-uploaded-logo") {
        deleteFiles([{ Key: uploadedLogoImageKey }])
      }

      if (retrievedLogoImageKey) {
        deleteFiles([{ Key: retrievedLogoImageKey }])
      }

    }

    if (noSaveRequired === false && updateDsrRequired === true) {
      updateDsr({
        dsrId: dsrId,
        dsrProperties: latestDsrProperties,
        sourceEvent: "save-guest-logo"
      })
    }
  }

  const handleImageFound = () => {
    setShowLogoInput(false)
    setShowChangeLogoButton(false)
    setShowButtons(true)
    setShowLogoLoader(false)
  }

  const handleImageError = () => {
    setShowLogoImage(false)
    setShowImageError(true)
    setShowLogoLoader(false)
  }

  const displaySearchLogoModule = () => {
    return (<Box minW='20em' my='1em'>
      <Box mt='1em' textAlign='center' fontSize='0.875em' color={'brandDark.500'}>
        Use their website's logo
      </Box>
      <FormTextInput
        name="logo-search-input"
        w='100%'
        color='#2D3748'
        mb='0em'
        onChange={handleInputChange}
        onKeyDown={handlePressEnter}
        borderColor='gray.400'
        placeholder='e.g. www.google.com'
        textAlign='center'
      />
      <WarningTextMessage textAlign='center' errorLogic={showInvalidUrlError} message='Please use a valid weblink' />

      <Flex mt='2em' align='center' justify='center'>
        <LaunchdeckButton name='userProviderUri' label='Search for logo' fontSize='0.9em' py='0.8em' bg='brandDark.500' color='gray.50' onClick={() => handleGetCbLogo()} />
      </Flex>

    </Box>)
  }

  const handeUploadLogo = () => {
    uploadFiles({ dsrId: dsrId, files: [logoFile[0].file], subWidgetId: "" })
  }

  const handleClearUploadedImageFile = () => {
    setLogoFile(null)
  }

  function renderConfirmUseUploadedLogo() {
    return (
      <>
        <Flex direction='column' align='center' justify='center' w='100%' px='2em'>
          <Flex boxSize='128px' mt='1em' direction='column' mb='3em' justify='center' align='center' border='1px solid' borderColor='gray.200' rounded='0.25em' p='0.5em'>

            <Image src={dsrUploadedLogoUrl}
              onError={handleImageError} />

          </Flex>

          <Flex justify='center' align='center' gap={8}>
            <LaunchdeckButton bg='brand.500' color='white' onClick={() => handleSaveGuestLogoInDsrProperties("add-logo")} label='Looks good, set it as logo' />
          </Flex>
          <Box mt='1em' fontSize='0.8em' color='gray.500'
            textAlign='center'
            cursor='pointer'
            fontStyle='italic'
            onClick={() => handleSaveGuestLogoInDsrProperties("delete-uploaded-logo")}
          >
            Discard & use another image
          </Box>


        </Flex>
      </>

    )

  }

  function renderCurrentUploadedDsrLogo() {
    return (
      <>
        <Flex direction='column' align='center' justify='center' w='100%' px='2em'>
          <Flex boxSize='128px' mt='1em' direction='column' mb='3em' justify='center' align='center' border='1px solid' borderColor='gray.200' rounded='0.25em' p='0.5em'>

            <Image src={dsrUploadedLogoUrl}
              onError={handleImageError} />

          </Flex>

          <Flex justify='center' align='center' gap={8}>
            <LaunchdeckButton bg='brandDark.500' color='white' onClick={() => {
              setDsrUploadedLogoUrl("")
              setShowDefaultIsUploadedDsrLogo(false)
            }
            } label='Upload another logo image' />
          </Flex>
        </Flex>
      </>

    )
  }

  function renderContent() {
    const displayAndEditLogoModule = () => {

      if (showLogoImage) {
        return (
          <React.Fragment>
            <Flex boxSize='128px' direction='column' mb='3em' justify='center' align='center' border='1px solid' borderColor='gray.200' rounded='0.25em' p='0.5em'>
              <Box display={showLogoLoader ? "block" : "none"}>
                <PageLoader />
              </Box>
              <Image src={`${feedUriInput}`} onError={handleImageError} onLoad={handleImageFound} />
            </Flex>
            {showChangeLogoButton && !showLogoLoader &&
              <LaunchdeckButton bg='gray.500' color='white' onClick={() => { setShowLogoInput(true); setShowChangeLogoButton(false) }} label='Change Logo' />
            }
          </React.Fragment>
        )
      } else if (!showImageError && !showLogoImage) {

        if (currentLogoImage) {
          return (
            <React.Fragment>
              {/* <Box mb='1em' color='brandDark.500' fontWeight='600' textAlign='center'>Current Logo</Box> */}
              <Flex boxSize='5em' direction='column' mb='3em' justify='center' align='center' border='1px solid' borderColor='gray.200' rounded='0.25em' p='0.5em'>
                <Box display={showLogoLoader ? "block" : "none"}>
                  <PageLoader />
                </Box>
                <Image src={`${currentLogoImage}`} onError={handleImageError} />
              </Flex>
              {showChangeLogoButton &&
                <LaunchdeckButton bg='gray.500' color='white' onClick={() => { setShowLogoInput(true); setShowChangeLogoButton(false) }} label='Change Logo' />
              }
            </React.Fragment>
          )
        } else if (!currentLogoImage) {

          return (
            <Flex direction='column' align='center' justify='center' minW='75%'>
              <Flex boxSize='8em' direction='column' justify='center' align='center' >
                <Box display={showLogoLoader ? "block" : "none"}>
                  {!showInvalidUrlError && <PageLoader />}
                </Box>
                <TbBrandCake size='5em' />

              </Flex>
              {displaySearchLogoModule()}
            </Flex>
          )
        }

      } else if (showImageError) {
        return (
          <Flex direction='column' align='center' justify='center' w='100%' px='2em' mb='3em'>
            <Box mt='1.2em' fontSize='0.875em' color='brandDark.500'>Unable to find any Logo</Box>
          </Flex>
        )
      } else return null;

    }

    if (isUpdating) {
      return (
        <Box>
          <PageLoader />
        </Box>
      )
    } else {

      return (
        <>
          <Flex direction='column' align='center' justify='center' w='100%' px='2em'>
            <Box maxW='20em'>
              {displayAndEditLogoModule()}
            </Box>

            {showLogoInput && displaySearchLogoModule()}

            {showButtons && !showLogoLoader &&
              <Flex direction='column' height='100%' justify="space-between" align='center' mt='1em'>
                <Flex justify='center' align='center' w='100%' mb='1.5em'>
                  <Flex justify='space-between' align='center' gap='1.5em'>
                    <LaunchdeckButton name='resetCbLogo' label={`Change logo`} borderColor="gray.300" fontSize='0.9em' py='0.8em' onClick={() => handleSearchNewCbLogo()} />
                    {
                      feedUriInput && newCbLogoFound && !showImageError &&
                      <LaunchdeckButton label='Use this logo' fontSize="0.9em" bg='brand.500' color='gray.50' onClick={() => handleSaveGuestLogoInDsrProperties('add-logo')} />
                    }
                  </Flex>

                </Flex>


              </Flex>
            }

          </Flex>

        </>
      )

    }
  }

  return (
    <Modal isOpen={true} closeOnOverlayClick={false} isCentered={true} preserveScrollBarGap={true} >
      <ModalOverlay className="modal-background" />
      <ModalContent rounded='0.25em' minW={['90%', '500px']} maxW='700px'>
        <ModalBody px='0em'>
          <Flex direction='column' justify='space-around' alignItems='center' w='100%'>
            <Flex justify='space-between' w='100%' borderBottom='1px solid' borderBottomColor='gray.300'>
              <Flex px='1em' justify='flex-start' align='center'>
                <Box px='1.5em' py='0.5em' color='brandDark.500'>Manage Guest Logo</Box>
              </Flex>

              <Flex pr='1.5em' align='center' justify='center'>
                <Box
                  onClick={() => handleSaveGuestLogoInDsrProperties('remove-logo')}
                  cursor='pointer'
                  mr='1em'
                  fontSize='0.75em'
                  fontWeight='500'
                  color='gray.500'
                >
                  Don't use any logo
                </Box>
                <LaunchdeckCloseButton handleCloseModal={() => handleCloseModal()} />
              </Flex>
            </Flex>

            {defaultTab !== null &&
              <Tabs defaultIndex={defaultTab} width='100%' >
                <TabList padding='0 1.5em' border='none'>
                  <Tab fontSize='0.875em' fontWeight='500' p='0.75em 1em' onClick={() => setLogoType("cb-dsr-logo")}>Search logo</Tab>
                  <Tab fontSize='0.875em' fontWeight='500' p='0.75em 1em' onClick={() => setLogoType("uploaded-dsr-logo")}>Upload logo</Tab>
                  <Tab fontSize='0.875em' fontWeight='500' p='0.75em 1em' onClick={() => setLogoType("textual-dsr-logo")}>Textual logo</Tab>
                  {showPickGuestLogo && <Tab fontSize='0.875em' fontWeight='500' p='0.75em 1em' onClick={() => setLogoType("audience-chosen-org-logo")}>Guest chosen logo</Tab>}
                </TabList>
                <TabPanels padding='1.5em' minH='30em' pt='2em'>
                  <TabPanel>


                    <Box margin='1em 0.75em' minH='16em' position='relative'>
                      {renderContent()}
                    </Box>


                    {showButtons && <Flex justify='center' px='1em'>
                      <Flex fontSize='12px' color='gray.400' mb='1em' position='absolute' bottom='0'>
                        Logos provided by <Box pl='0.25em'><a href="https://clearbit.com" target="_blank" rel="noopener noreferrer"> Clearbit</a></Box>
                      </Flex>
                    </Flex>

                    }
                  </TabPanel>

                  <TabPanel>
                    {showDefaultIsUploadedDsrLogo ?
                      renderCurrentUploadedDsrLogo()
                      : showConfirmUseUploadedLogo
                        ?
                        renderConfirmUseUploadedLogo()
                        :
                        <Box margin='1em 0.75em' minH='16em'>
                          <UploadingLogo logoFile={logoFile} setLogoFile={setLogoFile} isLoading={uploadLogoInProgress} maxImageFileSize={maxImageFileSize}/>

                          <Flex w='100%' py='0.625em'>
                            <Flex justify='center' w='100%'>
                              {(logoFile && !uploadLogoInProgress)
                                &&
                                <Box>
                                  <LaunchdeckButton bg='brand.500' color='gray.50' fontSize='0.9em' onClick={handeUploadLogo} label='Upload image as logo' />
                                  <Box fontSize='0.8em' color='gray.500' mt='0.5em' fontStyle='italic' cursor='pointer' textAlign='center' onClick={handleClearUploadedImageFile}>Use another image</Box>
                                </Box>

                              }
                            </Flex>
                          </Flex>
                        </Box>
                    }
                  </TabPanel>

                  <TabPanel>
                    <Box margin='1em 0.75em' minH='16em'>
                      <Flex w='100%' justify='center' pb='2em'>
                        <Flex boxSize='128px' justifyContent='center' alignItems='center' textAlign='center' color={!textLogo && 'gray.300'} className="default-text-logo-font" fontSize='1.4em' border='1px solid' borderColor='gray.200' p='0.5em' rounded='0.25em'>
                          {textLogo ? textLogo : 'Preview'}
                        </Flex>
                      </Flex>

                      <Flex w='100%' justify='center'>
                        <Box width='24em'>
                          <Input
                            name='dsrTitle'
                            value={textLogo}
                            onChange={(e) => setTextLogo(e.target.value)}
                            placeholder="Enter logo text"
                            maxLength={20}
                            mb='0.2em'
                            h='1.4em'
                            fontSize='0.875em'
                            fontWeight='400'
                            color='brandDark.500'
                            border='1px solid'
                            borderColor='gray.200'
                            borderRadius='4px'
                            padding='16px'
                            textAlign='center'
                          />
                          <Flex w='100%' justify='flex-end' align='center' mb='10px'>
                            <Flex align='center'>
                              <Box fontWeight='600' fontSize='0.875em'>{textLogo.trim().length}</Box>/20
                            </Flex>

                          </Flex>
                        </Box>
                      </Flex>
                    </Box>
                    <Flex w='100%'>
                      <Flex justify='center' w='100%'>
                        {textLogo
                          ?
                          <LaunchdeckButton bg='brand.500' color='gray.50' onClick={() => handleSaveGuestLogoInDsrProperties('add-logo')} label='Use this logo' />
                          :
                          <LaunchdeckButton bg='#CBD5E0' color='white' dummy label='Use this logo' />
                        }
                      </Flex>
                    </Flex>
                  </TabPanel>

                  {
                    showPickGuestLogo && <TabPanel>


                      <>
                        <Flex direction='column' align='center' justify='center' w='100%' px='2em'>
                          <Flex boxSize='128px' mt='1em' direction='column' mb='3em' justify='center' align='center' border='1px solid' borderColor='gray.200' rounded='0.25em' p='0.5em'>

                            <Image src={audienceOrgLogo}
                              onError={handleImageError} />

                          </Flex>

                          <Box mt='1em' textAlign='center' fontSize='0.875em' color='brandDark.500'>
                            Use the logo your guest has chosen for their organization
                          </Box>
                          <Flex justify='center' mt='2em'>

                            <LaunchdeckButton bg='brand.500' color='gray.50' onClick={() => handleSaveGuestLogoInDsrProperties('add-logo')} label='Use this logo' />
                          </Flex>


                        </Flex>
                      </>
                    </TabPanel>
                  }

                </TabPanels>


              </Tabs>
            }

          </Flex>


        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

function UploadingLogo({ logoFile, setLogoFile, isLoading, maxImageFileSize }) {
  const maxFileSize = maxImageFileSize;
  const acceptType = ['jpg', 'gif', 'png', 'jpeg'];

  const onChange = (image) => {
    setLogoFile(image);
  };

  if (isLoading) {
    return (
      <Box w="100%" align="center" >
        <Box py='2em'>
          <FileUploadProgress showLoader={isLoading} />
        </Box>
      </Box>
    )
  }

  return (
    <ImageUploading
      maxFileSize={maxFileSize}
      value={logoFile}
      onChange={onChange}
      acceptType={acceptType}
      dataURLKey="logoUrl"
    >
      {({
        imageList,
        onImageUpload,
        errors,
        isDragging,
        dragProps,
      }) => (
        <Box>
          {imageList.length === 0
            ? <Flex
              style={isDragging ? { color: 'red' } : undefined}
              border='1px dashed #b7b7b7'
              borderRadius='10px'
              minH='16em'
              width='100%'
              display='flex'
              justifyContent='center'
              alignItems='center'
              mb='2em'
            >
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                alignItems='center'
                cursor='pointer'
                padding='16px'
                width="100%"
                onClick={onImageUpload}
                {...dragProps}
              >
                <Box mb='1.5em' height='50px' width='50px'>
                  <IoCloudUploadOutline style={{ width: '100%', height: '100%' }} fill='#b7b7b7' />
                </Box>
                <Box mb='0.75em' fontSize='0.875em'>
                  Upload an image from your computer
                </Box>
                <Box fontSize='12px' color='#b7b7b7' mb='24px'>
                  <p>JPG or PNG, file size no more than 10MB</p>
                </Box>

               <Flex mt='1em' align='center' justify='center'>
                  <LaunchdeckButton label='Select image' fontSize='0.9em' py='0.8em' bg='brandDark.500' color='gray.50' />
                </Flex>
              </Box>
            </Flex>
            : imageList.length > 0
            && <Flex
              direction='column'
              width='100%'
              justifyContent='center'
              alignItems='center'
              mb='2em'
            >

              <Flex boxSize='8em' direction='column' mb='1em' justify='center' align='center' border='1px solid' borderColor='gray.200' rounded='0.25em' p='0.5em'>

                <Image src={logoFile[0]?.['logoUrl']} alt={""}
                // onError={handleImageError} 
                />
              </Flex>

             
            </Flex>
          }
          {errors &&
            <><RiErrorWarningLine color="red" boxSize={20} /> &nbsp; Upload limit is 10MB per image</>
          }
        </Box>
      )}
    </ImageUploading>
  )
}

function mapStateToProps(state) {
  return {
    updateDsrOutcome: state.updateDsrOutcome.results.data,
    isUpdating: state.updateDsrOutcome.isLoading,
    dsrPropertiesRedux: state.draftDsrProperties.properties,
    isMobileView: state.mobileViewMode.isMobileView,
    dsrDisplayLogoImages: state.dsrDisplayLogoImages.dsrDisplayLogos,
    uploadFilesState: state.uploadFilesOutcome,
    deleteFilesState: state.deleteFilesOutcome.results,
  };
}


const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateDsr,
      resetUpdateDsrOutcome,
      uploadFiles,
      uploadFilesReset,
      deleteFiles,
      deleteFilesReset
    },
    dispatch,
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ManageDsrLogoModal));
