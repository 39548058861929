import React, { useState, useRef, useEffect } from 'react';
import { NavHashLink as NavLink } from 'react-router-hash-link';
import { Box, Flex, Text, Stack, Image, Drawer, DrawerBody, DrawerHeader, DrawerOverlay, DrawerContent, IconButton, useDisclosure } from "@chakra-ui/react";
import { AiOutlineMenu} from 'react-icons/ai';
import LaunchdeckLogo from '../../../../global/styles/images/launchdeck_logo_white.svg';
import DsrUserProfilePanelDisplay from '../../../../digital-sales-room/dsr-content-components/DsrUserProfilePanelDisplay';

import '../styles/sidebar.scss';
import { getUserAccountType } from '../../../../profile/helpers/user-helper';
import { IoChevronBack } from "react-icons/io5";

function SidebarLink({ title, linkPath, ...rest }) {
  return (
    <Box p={5} {...rest}>
      <NavLink to={linkPath}>
        <Text fontSize="1em" color="gray.50" pl="5%" >{title}</Text>
      </NavLink>
    </Box>
  );
}


function DsrNavigationDrawerMenu({darkMode, userProfile, signOut}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [imageClassName, setImageClassName] = useState('image-loading__in-progress')
  const [isNoOrgUser, setIsNoOrgUser] = useState(null)
  const initRef = useRef(false);

   useEffect(() => {
    if (!initRef.current) {
      const userType = getUserAccountType();
      const isNoOrgUser = (userType ==="no-org-user") ? true : false;

      setIsNoOrgUser(isNoOrgUser)
      initRef.current = true
    }
  }, [])

  const onPressLogout = () => {
    signOut()
    window.location.hash = 'logout';
  }

  
  return (
    <>
      <IconButton
        aria-label="Launchdeck Menu Sidebar"
        fontSize="2rem"
        fontWeight='semibold'
        color={darkMode ? '#C4CDD5' : "brandDark.500"}
        icon={<AiOutlineMenu />}
        onClick={onOpen}
        bg={darkMode ? "#2D3747" :'none'}
      />
      <Drawer placement='left' onClose={onClose} isOpen={isOpen} preserveScrollBarGap={true} >
        <DrawerOverlay />
        <DrawerContent backgroundColor='#2D3748' maxW={'25em'}>
          <DrawerHeader borderBottomWidth="1px">
            <Flex direction='row' width='100%' alignItems='center' justify='flex-start' py='1em' pl='1em'>
              <IconButton
                aria-label="Launchdeck Menu Sidebar"
                fontSize="1.4em"
                fontWeight='semibold'
                color='white'
                icon={<IoChevronBack />}
                onClick={onClose}
                bg='#2D3748'
              />
              <Image src={LaunchdeckLogo} className={imageClassName} onLoad={()=>setImageClassName('image-loading__completed')} 
              width="auto" height='1.2em' ml='1.5em'
              />
            </Flex>
          </DrawerHeader>
          <DrawerBody>
            <Flex direction='column' justify='space-between' height='90vh'>
              <Box >
                <Box ml='1em'>
                 <DsrUserProfilePanelDisplay userProfile={userProfile} />
                </Box>
                <Stack spacing={1} pt="10%" color='white' fontSize='1em'>
                  {isNoOrgUser=== false && <SidebarLink title="Rooms" linkPath="/vault" onClick={onClose}/>}
                  {isNoOrgUser=== false && <SidebarLink title="Prospectors" linkPath="/prospectors" onClick={onClose}/>}
                  <SidebarLink title="Received Rooms" linkPath="/received-rooms" onClick={onClose}/>
                </Stack>
                </Box>
              

                <Box>
                  <Stack spacing={1} pt="10%" color='white' fontSize='1em'>
                    {/* <SidebarLink title="Notifications" linkPath="/#" /> */}
                    <SidebarLink title="My Account" linkPath="/profile" onClick={onClose}/>
                    <SidebarLink title="Help" linkPath="/support" onClick={onClose}/>
                  </Stack>
                  <Flex p={5}>
                      <Text fontSize="1em" color="gray.50" pl="5%" cursor='pointer' pb='3em' onClick={onPressLogout} >Log out</Text>
                  </Flex>
                </Box>
            
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default DsrNavigationDrawerMenu;
