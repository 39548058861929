import React from 'react';

import { Flex, Box, Text} from '@chakra-ui/react';
import LoggedInUserChangePasswordForm from './LoggedInUserChangePasswordForm';


export default function ChangeNewPasswordPage() {

		return (
					<Box h='100%' py='2%'>
							<Flex  w='100%' direction='column' alignItems='center' bg='white' py='2em' rounded='1em'>
								<Text fontSize='1.2em' letterSpacing='1px' textTransform='uppercase'>Change Password</Text>
								<Box my='3%' w='60%'>
									<LoggedInUserChangePasswordForm  />
								</Box>
							</Flex>
			
					</Box>
		);
}

