import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';

import { Box, Flex, Text } from "@chakra-ui/react";
import LaunchdeckButton from '../../global/components/helper-components/components/LaunchdeckButton';
import FormTextInput from '../../forms/TextInputField/components/FormTextInput';
import FormTextAreaInput from '../../forms/TextareaField/components/FormTextAreaInput';
import '../styles/dsr-display.scss';

import { createNewDsrAction, resetCreateNewDsrAction } from '../actions/dsr-actions';
import PageLoader from '../../global/components/helper-components/components/PageLoader';


function CreateNewCategoryForm(props) {

  const { dsrId, handleCloseModal,
    createNewDsrActionOutcome,
    createNewDsrAction,
    resetCreateNewDsrAction
  } = props || {};
  const titleCharacterLimit = 100;

  // Input management
  const [categoryInput, setCategoryInput] = useState("")
  const [mapDescriptionInput, setMapDescriptionInput] = useState("")

  // Validation for required input
  const [showNoCategoryError, setShowNoCategoryError] = useState(false)

  // UX Management
  const [showLoader, setShowLoader] = useState(false)

  const onChangeHandler = (event) => {
    setShowNoCategoryError(false)

    switch (event.target.name) {
      case ('action-category'): {
        setShowNoCategoryError(false)
        setCategoryInput(event.target.value.trim())
        break;
      }
      case ('action-category-description'): {
        setMapDescriptionInput(event.target.value.trim())
        break;
      }
      default: {
        break;
      }
    }
  };

  const handleCloseCreateActionCategoryModal = () => {
    setCategoryInput("")
    setMapDescriptionInput("")

    handleCloseModal()
  }

  const handleCreateNewAction = () => {

    if (categoryInput.trim().length < 1) {
      setShowNoCategoryError(true)
    } else {
      const newActionMetadata = {
        dsrId,
        mapCategoryName: categoryInput,
        mapCategoryDescription: mapDescriptionInput,
        event: "create-new-category"
      }

      setShowLoader(true)
      createNewDsrAction(newActionMetadata)
    }


  }

  useEffect(() => {
    if (createNewDsrActionOutcome) {
      setShowLoader(false)
      // handleCloseCreateActionCategoryModal()
      setCategoryInput("")
      setMapDescriptionInput("")
      handleCloseModal()
    }

    resetCreateNewDsrAction()

  }, [createNewDsrActionOutcome, resetCreateNewDsrAction, handleCloseCreateActionCategoryModal]);


  if (showLoader) {
    return (
      <Box w="100%" align="center" >
        <Box px='1em'>
          <Box>
            <PageLoader />
          </Box>
        </Box>
      </Box>
    )
  }

  if (!showLoader) {
    return (
      <Box w="100%" align="center" >
        <Box px='2em'>
          <Box>
            <Flex w='100%' justify='space-between' align='center'>
              <Box fontSize='0.875em'>Category name<Box as='span' color='pink.500'>*</Box></Box>
              <Box fontSize='0.8em' color='gray.500'>{categoryInput.length}/{titleCharacterLimit}</Box>
            </Flex>
            <FormTextInput
              name={'action-category'}
              onChange={onChangeHandler}
              charLimit={titleCharacterLimit}
              mb='0.2em'
              borderColor={showNoCategoryError ? 'pink.500' : ""}
            />
            {showNoCategoryError && <Text color='pink.500' fontSize='0.8em'>Provide a name for your Category </Text>}
          </Box>

          <Box mt='1em'>
            <Flex w='100%' justify='space-between' align='center'>
              <Flex justify='flex-start' align='baseline' fontSize='0.875em'>Description<Box fontSize='0.7em' color='gray.500' ml='1em'>OPTIONAL</Box></Flex>

            </Flex>
            <FormTextAreaInput
              name="action-category-description"
              fontSize='0.875em'
              onChange={onChangeHandler} />
          </Box>
        </Box>

        <Flex w='100%' mt='4em' px='2em' py='0.625em' borderTop='1px solid' borderTopColor='gray.300'>
          <Flex justify='space-between' w='100%'>
            <LaunchdeckButton name='closeCreateActionCategoryModal' label='Cancel' fontSize='0.9em' py='0.8em' borderColor='gray.300' onClick={()=>handleCloseCreateActionCategoryModal()} />

            <LaunchdeckButton label='Create' fontSize="0.9em" bg='brand.500' color='gray.50' onClick={() => handleCreateNewAction()} />
          </Flex>
        </Flex>


      </Box>
    )
  }

}


function mapStateToProps(state) {
  return {
    createNewDsrActionOutcome: state.createNewDsrActionOutcome.results.data,
  };
}


const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createNewDsrAction,
      resetCreateNewDsrAction
    },
    dispatch,
  )

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateNewCategoryForm));
