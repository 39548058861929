import React from "react";

import { Box, Flex, Image } from "@chakra-ui/react";
import AddEmojiIconPng from '../../../digital-sales-room/styles/images/add-reaction-icon.png'

export default function DsrAddNewEmojiReactionButton({ handleOpenSignupOrLoginModal }) {

  return (
    <React.Fragment>
      <Flex
        px='0.6em'
        py='0.1em'
        mx='0.5em'
        justify='center'
        align='center'
        rounded='0.8em'
        border="1px"
        borderColor='gray.100'
        cursor='pointer'
        fontSize='0.8em'
        onClick={() => handleOpenSignupOrLoginModal()}
      >

        <Box onClick={() => handleOpenSignupOrLoginModal()} cursor='pointer'>
          <Box py='0.1em'>
            <Image src={AddEmojiIconPng} boxSize="1.3em" objectFit="cover" alt="Add Emoji Reaction" />
          </Box>
        </Box>

      </Flex>
    </React.Fragment>
  );

}


