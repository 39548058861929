import React, { useState } from 'react';
import { Box, Flex, Text, Divider, Collapse, useToast, Card, CardHeader, Heading, CardBody, CardFooter, SimpleGrid, Avatar } from '@chakra-ui/react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import LaunchdeckButton from '../../../global/components/helper-components/components/LaunchdeckButton';
import { copyToUserClipboard, triggerLaunchdeckToast } from '../../../global/helpers/global-helpers';
import LaunchdeckFormInput from '../../../forms/TextInputField/components/LaunchdeckFormInput';
import ClaverModal from '../../../global/components/helper-components/components/ClaverModal';
import AppAdminEditOrgTeamMemberModule from './AppAdminEditOrgTeamMemberModule';
import { IoCopyOutline } from 'react-icons/io5';
import '../../styles/saa-portal.scss'
import AppAdminEditOrgModule from './AppAdminEditOrgModule';


function AppAdminEditOrgForm({
	handleChange,
	localPageState,
	handleManageExistingOrg,
	handleClearOrgSearch,
	existingOrgNotFound,
	isMobileView,
	manageOrgIsProcessing
}) {


	const toast = useToast();
	const { orgIdInput, foundOrgId, orgName, orgStatus, orgSeats, orgLogo,
		orgAdminUsers,
		orgBasicUsers,
		stripeOrgCustomerId,
		orgDescription,
		orgMemberStatusOptions,
		orgStatusOptions } = localPageState || {};


	const [showEditOrgModal, setShowEditOrgModal] = useState(false)
	const [showEditMemberModal, setShowEditMemberModal] = useState(false)
	const [memberUserToEdit, setMemberUserToEdit] = useState("")
	const [memberIsAdministrator, setMemberIsAdministrator] = useState("")


	const handleEditMember = (chosenMember, memberIsAdmin) => {
		if (chosenMember) {
			setShowEditMemberModal(true)
			setMemberUserToEdit(chosenMember)
			setMemberIsAdministrator(memberIsAdmin)
		}
	}

	const handleCopyLink = (value) => {
		copyToUserClipboard(value)

		toast(
			triggerLaunchdeckToast({
				useCase: "show-success-state",
				// bannerText: "",
				label: "Successfully copied",
				isMobileView
			})
		)
	}


	const renderOrgMembers = () => {
		const adminMembers = Array.isArray(orgAdminUsers) ?
			orgAdminUsers?.map((adminMember, adminIndex) => {
				const { memberName, email, memberProfilePicSrc, memberStatus, userId, userStatus } = adminMember || {}
				const memberIsAdmin = true;
				return (

					<Card key={`org-admin-${adminIndex}`} p='0.5em' bg='gray.50'>
						<CardHeader>
							<Avatar mb='1em' h='4em' w='4em' name={memberName} bg='gray.100' color='gray.700' src={memberProfilePicSrc} />
							<Heading size='1em'> {memberName} </Heading>
							<Box fontSize='0.75em' className="truncate-long-text">{email}</Box>
						</CardHeader>
						<CardBody>
							<Divider mb='1em' />
							<Box fontWeight='600' fontSize='0.75em' color='gray.700'>User ID Token</Box>
							<Flex w='100%'>
								<Box fontSize='0.8em' className='truncate-long-text'>{userId}</Box>
								<Flex ml='0.5em' color='brand.500' cursor='pointer' align='center' onClick={() => handleCopyLink(userId)}>
									<Box as={IoCopyOutline} color='brandDark.500' boxSize='1.2em' />
								</Flex>
							</Flex>


							<Box mt='1em' fontWeight='600' fontSize='0.75em' color='gray.700'>User Status</Box>
							<Box fontSize='0.8em'>{userStatus}</Box>

							<Box mt='1em' fontWeight='600' fontSize='0.75em' color='gray.700'>Org Membership Status</Box>
							<Box fontSize='0.875em'>{memberStatus}</Box>
						</CardBody>
						<CardFooter>
							<LaunchdeckButton bg='brand.500' color='white' w='100%' fontSize='0.8em' onClick={() => handleEditMember(adminMember, memberIsAdmin)} label='Edit' />
						</CardFooter>
					</Card>

				)
			})
			: null

		const basicMembers = Array.isArray(orgBasicUsers) ?
			orgBasicUsers?.map((basicMember, basicIndex) => {
				const { memberName, email, memberProfilePicSrc, memberStatus, userId, userStatus } = basicMember || {}
				const memberIsAdmin = false;
				const isAllOkay = (memberStatus === "active" && (userStatus === "active" || userStatus === "pending")) ? true : false
				return (

					<Card key={`org-admin-${basicIndex}`} p='0.5em' bg={isAllOkay ? 'gray.50' : 'pink.50'}>
						<CardHeader>

							<Avatar mb='1em' h='4em' w='4em' name={memberName} bg='gray.100' color='gray.700' src={memberProfilePicSrc} />
							<Heading size='1em'> {memberName} </Heading>
							<Box fontSize='0.75em' className="truncate-long-text">{email}</Box>
						</CardHeader>
						<CardBody>
							<Divider mb='1em' />
							<Box fontWeight='600' fontSize='0.75em' color='gray.700'>User ID Token</Box>
							<Flex w='100%'>
								<Box fontSize='0.8em' className='truncate-long-text'>{userId}</Box>
								<Flex ml='0.5em' color='brand.500' cursor='pointer' align='center' onClick={() => handleCopyLink()}>
									<Box as={IoCopyOutline} color='brandDark.500' boxSize='1.2em' />
								</Flex>
							</Flex>

							<Box mt='1em' fontWeight='600' fontSize='0.75em' color='gray.700'>User Status</Box>
							<Box fontSize='0.8em'>{userStatus}</Box>

							<Box mt='1em' fontWeight='600' fontSize='0.75em' color='gray.700'>Org Membership Status</Box>
							<Box fontSize='0.875em'>{memberStatus}</Box>
						</CardBody>
						<CardFooter>
							<LaunchdeckButton bg='brand.500' color='white' w='100%' fontSize='0.8em' onClick={() => handleEditMember(basicMember, memberIsAdmin)} label='Edit' />
						</CardFooter>
					</Card>

				)
			})
			: null
		return (
			<>
				<Box my='1em'>Administrators</Box>
				<SimpleGrid spacing={26} templateColumns='repeat(auto-fill, minmax(200px, 1fr))'>
					{adminMembers}

				</SimpleGrid>

				{basicMembers &&
					<React.Fragment>
						<Box mt='3em' mb='1em'>Basic Members</Box>
						<SimpleGrid spacing={26} templateColumns='repeat(auto-fill, minmax(200px, 1fr))'>
							{basicMembers}
						</SimpleGrid>
					</React.Fragment>
				}
			</>
		)
	}




	return (
		<Box my='2em' w='100%' gap='0.5em'>
			<Box>
				<Box mt='3em' mb='1em' bg='gray.100' rounded='0.25em' p='0.875em'>
					<Box fontSize='0.875em' fontWeight='semibold'>Organization Details</Box>
					{/* <Text fontSize='0.8em' color='gray.600'>Important fieid. This user will be automatically be assigned as the new Organization's Administrator</Text> */}
				</Box>
				<Box mb='0.5em'>
					{foundOrgId ?
						<Box px='0.5em'>
							<Box className="saa-label">Organization ID</Box>
							<Box fontSize='0.875em' fontStyle='italic'>{foundOrgId} </Box>
						</Box>

						:
						<LaunchdeckFormInput
							name={'orgIdInput'}
							onChange={handleChange}
							label="Organization ID"
							placeholder="Example: org__xxxxxx"
							value={orgIdInput ? orgIdInput : ""}
							errorLogic={existingOrgNotFound}
							errorMessage={'No organization found with this orgId'}
						/>
					}

				</Box>
			</Box>

			<Flex w='100%' justify='flex-end'>
				{foundOrgId ?
					<LaunchdeckButton
						label='Clear' bg={"brandDark.500"} color='white' onClick={handleClearOrgSearch} />
					:
					<LaunchdeckButton
						loadingLogic={manageOrgIsProcessing}
						label='Search' bg={orgIdInput ? 'brand.500' : "gray.200"} color={orgIdInput ? 'gray.50' : "brandDark.500"} dummy={orgIdInput ? false : true} onClick={() => orgIdInput && handleManageExistingOrg({ isEditOrg: false })} />
				}
			</Flex>


			<Collapse in={foundOrgId} animateOpacity>
				<Divider my='2em' />

				<Flex w='100%' justify='space-between' align='center' mt='3em' mb='1em' bg='gray.100' rounded='0.25em' p='0.875em'>
					<Box >
						<Box fontSize='0.875em' fontWeight='semibold'>Retrieved Organization Information </Box>
						<Text fontSize='0.8em' color='gray.600'>View / Edit the information for an organization here. Please use with caution.</Text>
					</Box>

					<LaunchdeckButton
						label='Edit Org Info' bg={"brand.500"} color='white' onClick={() => setShowEditOrgModal(true)} />
				</Flex>

				<Box px='0.875em' mt='3em'>
					<Box>
						<Box className="saa-label">
							Organization Status
						</Box>
						<Box textTransform={'capitalize'}>{orgStatus}</Box>
					</Box>

					<Flex justify='space-between' gap={16} my='1em'>
						<Box flex='1'>
							<Box className="saa-label">
								Organization Name
							</Box>
							<Box textTransform={'capitalize'}>{orgName}</Box>
						</Box>

						<Box gap='2em' />
					</Flex>

					<Box>
						<Box className="saa-label">
							Org Seats
						</Box>
						<Box textTransform={'capitalize'}>{orgSeats}</Box>
					</Box>

					<Box>
						<Box className="saa-label">
							Logo Link
						</Box>

						<Flex justify='flex-start' align='center'>
							<Box textTransform={'capitalize'} className="truncate-long-text" maxW='35em'>{orgLogo ? orgLogo : "-"}</Box>
							{orgLogo && <Flex ml='0.5em' color='brand.500' cursor='pointer' align='center' onClick={() => handleCopyLink(orgLogo)}>
								<Box as={IoCopyOutline} color='brandDark.500' boxSize='1.2em' ml='1em' />
							</Flex>}
						</Flex>
					</Box>

					<Box>
						<Box className="saa-label">
							Description
						</Box>
						<Box textTransform={'capitalize'}>{orgDescription ? orgDescription : "-"}</Box>
					</Box>

					<Box>
						<Box className="saa-label">
							Stripe Customer ID
						</Box>
						<Flex justify='flex-start' align='center'>
							<Box textTransform={'capitalize'} className="truncate-long-text" maxW='35em'>{stripeOrgCustomerId ? stripeOrgCustomerId : "-"}</Box>
							{stripeOrgCustomerId && <Flex ml='0.5em' color='brand.500' cursor='pointer' align='center' onClick={() => handleCopyLink(stripeOrgCustomerId)}>
								<Box as={IoCopyOutline} color='brandDark.500' boxSize='1.2em' ml='1em' />
							</Flex>}
						</Flex>
					</Box>


					{
						orgAdminUsers &&
						<>
							<Box mt='3em' mb='1em' bg='gray.100' rounded='0.25em' p='0.875em'>
								<Box fontSize='0.875em' fontWeight='semibold'>Team Members</Box>
								{/* <Text fontSize='0.8em' color='gray.600'>Important fieid. This user will be automatically be assigned as the new Organization's Administrator</Text> */}
							</Box>

							{renderOrgMembers()}
						</>
					}

				</Box>


			</Collapse>

			<Box />

			<ClaverModal
				title={showEditMemberModal ? 'Edit Org Member User' : showEditOrgModal ? "Edit Organization" : "Edit"}
				handleCloseModal={() => {
					setShowEditMemberModal(false)
					setShowEditOrgModal(false)
				}}
				showModal={showEditMemberModal || showEditOrgModal}
			>

				{showEditMemberModal &&
					<AppAdminEditOrgTeamMemberModule
						orgIdToken={foundOrgId}
						orgMemberToEdit={memberUserToEdit}
						memberIsAdministrator={memberIsAdministrator}
						handleCloseModal={() => setShowEditMemberModal(false)}
						orgMemberStatusOptions={orgMemberStatusOptions}
					/>
				}

				{showEditOrgModal && <AppAdminEditOrgModule
					orgIdToken={foundOrgId}
					orgName={orgName}
					orgSeats={orgSeats}
					orgStatus={orgStatus}
					orgLogo={orgLogo}
					handleCloseModal={() => setShowEditOrgModal(false)}
					orgStatusOptions={orgStatusOptions}
				/>}
			</ClaverModal>

		</Box>
	);

};

function mapStateToProps(state) {
	return {
		manageExistingOrgOutcome: state.manageExistingOrgOutcome.results,
		manageOrgIsProcessing: state.manageExistingOrgOutcome.isLoading,
		isMobileView: state.mobileViewMode.isMobileView
	};
}

export default withRouter(connect(mapStateToProps, null)(AppAdminEditOrgForm));