import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { updateUserPassword } from '../../actions/users-profile-actions';
import { Box, Flex } from "@chakra-ui/react";
import WarningTextMessage from '../../../global/components/helper-components/components/WarningTextMessage';
import HighlightBanner from '../../../global/components/helper-components/components/HighlightBanner';
import ProfileFormTextInput from '../../../forms/TextInputField/components/ProfileFormTextInput';
import ClaverCleanModal from '../../../global/components/helper-components/components/ClaverCleanModal';
import { reloadPage } from '../../../global/helpers/global-helpers';
import LaunchdeckButton from '../../../global/components/helper-components/components/LaunchdeckButton';


function UserChangePasswordModule(props) {

	const minPasswordLength = 6;
	const { updatePasswordOutcome, passwordIsUpdating } = props;
	const { passwordUpdateStatus } = updatePasswordOutcome || {};

	const [newPasswordInput, setNewPasswordInput] = useState('');
	const [newPasswordConfirmationInput, setNewPasswordConfirmationInput] = useState('');
	const [currentPasswordInput, setCurrentPasswordInput] = useState('');

	const [emptyPasswordError, setEmptyPasswordError] = useState(false);
	const [displayConfirmationError, setDisplayConfirmationError] = useState(false);
	const [displayShortPasswordError, setDisplayShortPasswordError] = useState(false);

	const sameInputs = (newPasswordInput.length > 5 && newPasswordInput === newPasswordConfirmationInput) ? true : false;

	const isWrongPassword = (passwordUpdateStatus === 'wrong-password') ? true : "";
	const passwordChangeSuccessful = (passwordUpdateStatus === 'password-changed') ? true : "";


	function handleSetNewPassword() {
		const { updateUserPassword } = props.action;

		if (!currentPasswordInput) {
			setEmptyPasswordError(true)
		} else setEmptyPasswordError(false)

		if (newPasswordInput.length <= minPasswordLength) {
			setDisplayShortPasswordError(true)
		} else setDisplayShortPasswordError(false)

		if (!sameInputs && newPasswordInput.length > minPasswordLength) {
			setDisplayConfirmationError(true)
		} else setDisplayConfirmationError(false)

		if (sameInputs) {
			updateUserPassword(currentPasswordInput, newPasswordInput)
		}
	}

	function handleChange(event) {
		const value = event.target.value;

		switch (event.target.name) {
			case ('currentPassword'): {
				setCurrentPasswordInput(value)
				break;
			}
			case ('inputPassword'): {
				setNewPasswordInput(value)
				break;
			}
			case ('repeatPassword'): {
				setNewPasswordConfirmationInput(value)
				break;
			}
			default: break;
		}

	}

	return (
		<Box>
			<Box className="user-profile-section__first-label"> Change Password </Box>
			<Box color='gray.500' maxWidth='500px' fontSize='0.8em'>Passwords must contain at least 6 characters </Box>


			<Box maxW='31.25em'>
				<ProfileFormTextInput name="currentPassword" label="Current Password" htmlFor="currentPassword" variant="outline" type="password" onChange={handleChange} />
				{!emptyPasswordError && isWrongPassword && <WarningTextMessage errorLogic={isWrongPassword} message='Your password is incorrect. Please try again' />}
				<WarningTextMessage errorLogic={emptyPasswordError} message='Please enter your CURRENT password' />

				<ProfileFormTextInput name="inputPassword" label="New Password" htmlFor="inputPassword" variant="outline" type="password" onChange={handleChange} />
				<WarningTextMessage errorLogic={displayShortPasswordError} message={`Your password must contain more than ${minPasswordLength} characters.`} />

				<ProfileFormTextInput name="repeatPassword" label="Repeat New Password" htmlFor="repeatPassword" variant="outline" type="password" onChange={handleChange} />
				<WarningTextMessage errorLogic={displayConfirmationError} message={`Please ensure that your confirmation & new passwords match`} />

				<Flex direction='column' mt='1em' justify='center' alignItems='center'>
        <Flex justify={['center', 'flex-end', 'flex-end', 'flex-end']} w='100%'>
          <LaunchdeckButton bg='brand.500' color='white' loadingLogic={passwordIsUpdating} onClick={handleSetNewPassword} label='Set new password' />
        </Flex>
      </Flex>
			</Box>


			<ClaverCleanModal showModal={passwordChangeSuccessful} >
				<Box m='2em'>
					<Box mt='1em'>
						<Box>
							<HighlightBanner>
								Your Password has been changed
							</HighlightBanner>
							<Flex justify='center' mt='2em'>

							<LaunchdeckButton bg='brandDark.500' color='white' px='3em' onClick={() => reloadPage()} label='Back' />
							</Flex>
						</Box>
					</Box>
				</Box>
			</ClaverCleanModal>
		</Box>


	)


}


function mapStateToProps(state) {
	return {
		updatePasswordOutcome: state.updatePassword.results.data,
		passwordIsUpdating: state.updatePassword.isLoading
	};
}

function mapDispatchToProps(dispatch) {
	return {
		action: bindActionCreators(
			{
				updateUserPassword
			},
			dispatch
		)
	};
}


export default connect(mapStateToProps, mapDispatchToProps)(UserChangePasswordModule);
