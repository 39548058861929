import React, { useState, useEffect, useRef } from 'react'
import { Box, Flex, useOutsideClick } from '@chakra-ui/react';
import { IoLogoYoutube } from 'react-icons/io';
import LaunchdeckFormInput from '../../../../forms/TextInputField/components/LaunchdeckFormInput';
import LaunchdeckButton from '../../../../global/components/helper-components/components/LaunchdeckButton';
import { getYoutubeEmbedLink } from '../../../../global/helpers/global-helpers';


export const YouTubeVideo = ({
  subWidgetId,
  editableItemId,
  setSubWidgetContent,
  subWidgetContent,
  isDragging,
  handleEditItemByClick,
  handleSaveItemByClickOutside
}) => {

  const [videoLoadError, setVideoLoadError] = useState(null);
  const [displayVideoUrl, setDisplayVideoUrl] = useState("")
  const [displayNoVideoState, setDisplayNoVideoState] = useState(false)

  const saveRef = useRef(null);

 

  useEffect(() => {
    const embedVideoUrl = (url) => {
      const embedUrl = getYoutubeEmbedLink(url)
      if (embedUrl) {
        setDisplayNoVideoState(false);
        return embedUrl;
      } else {
        setDisplayNoVideoState(true);
        return null;
      }  
    };

    if (subWidgetContent) {
      const embedYoutubeUrl = embedVideoUrl(subWidgetContent)

      if (embedYoutubeUrl) {
        setDisplayVideoUrl(embedYoutubeUrl)
      } else {
        setDisplayVideoUrl("")
        setDisplayNoVideoState(true)
      }

    }
  }, [subWidgetContent]);

  const placeholder = <Flex direction="column" alignItems="center" justifyContent="center" height="70px">
    <Box><Box fill='#FF0000' as={IoLogoYoutube} width="80px" height="60px" mb="5px" /></Box>
    <Box mt='1em'>
      <LaunchdeckButton bg='brandDark.500' color='white'  fontSize='0.875em' label='Add Youtube video' />
    </Box>
  </Flex>;




  const handleClick = () => {
    if (!isDragging) {
      // setVideoLoadError(false)
      setDisplayNoVideoState(false)
      handleEditItemByClick()
    }
  }

  const onPressEnter = (e) => {
    if (e.key === 'Enter') {
      if (subWidgetContent) {
        handleClick();
      } else {
        setVideoLoadError(true)
      }

    }
  }

  const handleVideoError = () => {
    setVideoLoadError(true);
  };

  const determineWidgetHeight = () => {

    if (editableItemId && editableItemId === subWidgetId) {
      if (displayNoVideoState) {
        return "120px"
      }
      if (subWidgetContent) {
        return "650px"
      } else {
        return "100px"
      }
    } else if (editableItemId && editableItemId !== subWidgetId) {
      if (subWidgetContent && displayVideoUrl) {
        return "490px"
      } else {
        return "340px"
      }
    }
  }

  const handleClickOutsideYoutubeSubwidget = () => {
    if (displayNoVideoState || videoLoadError) {
      setSubWidgetContent("")
      handleSaveItemByClickOutside()
    } else {
      handleSaveItemByClickOutside()
    }
  }

  useOutsideClick({
    ref: saveRef,
    handler: () => handleClickOutsideYoutubeSubwidget(),
  })

  return (
    <Box
      overflow={"hidden"}
      transition='all 0.5s ease'
      h={determineWidgetHeight}
      width='100%'
    >
      {
        editableItemId === subWidgetId
          ? <Box width="100%"
            // onBlur={() => handleClickOutsideYoutubeSubwidget()}
            ref={saveRef}
          >
            <LaunchdeckFormInput
              onChange={(e) => {
                setSubWidgetContent(e.target.value)
                // setVideoLoadError(false)
              }
              }
              onPressEnter={(e) => onPressEnter(e)}
              // onBlur={handleSaveItemByClickOutside}
              value={subWidgetContent}
              label='Youtube link'
              errorLogic={displayNoVideoState}
              errorMessage={'Invalid youtube link. Please check your link and try again'}
            />

            {subWidgetContent &&
              <Box
                padding="5em 1em"
                width="100%"
                minHeight={480}
              >
                {(!videoLoadError && !displayNoVideoState) &&
                  <Box>

                    <Flex justify='center' w='100%' fontSize='0.875em' color='gray.500' fontWeight='400' mb='1em'>Preview</Flex>
                    <iframe
                      width="100%"
                      height="480px"
                      title="Sub widget video"
                      src={displayVideoUrl}
                      allowFullScreen
                      style={{ "borderRadius": "15px" }}
                      onError={() => handleVideoError()}
                    />
                  </Box>
                }
              </Box>
            }


          </Box>
          : (subWidgetContent && displayVideoUrl)
            ? <Box
              padding="10px"
              width="100%"
              minHeight={480}
              onClick={handleClick}
            >
              <iframe
                width="100%"
                height="480px"
                title="Sub widget video"
                src={displayVideoUrl}
                allowFullScreen
                style={{ "borderRadius": "15px" }}
                onError={() => handleVideoError()}
              />
            </Box>
            : <Box
              className='animate__animated animate__fadeIn'
              display='flex'
              justifyContent='center'
              alignItems='center'
              backgroundColor='#FDFDFD'
              border='1px solid #CBD5E0'
              borderRadius='8px'
              minHeight={340}
              width='100%'
              onClick={handleClick}
            >
              {placeholder}
            </Box>
      }
    </Box>
  )
}
