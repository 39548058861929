import {SET_USER_NOTIFICATIONS} from '../actions/user-notifications-action-types';

const INITIAL_USER_NOTIFICATIONS_STATE = { currentUserNotifications: '' }

export function setUserNotificationsReducer(state = INITIAL_USER_NOTIFICATIONS_STATE, action) {
    switch (action.type) {
        case SET_USER_NOTIFICATIONS:
            return { currentUserNotifications: action.payload }
        default:
            return state;
    }
}