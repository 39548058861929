import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';

import { Box, Flex } from '@chakra-ui/react';
import { resetUploadOrgLogoImage } from '../../organizations/actions/organizations-actions';
import ImageDisplayWithLoader from '../../global/components/helper-components/components/ImageDisplayWithLoader';
import OrgLogoImageUploadButton from '../../profile/components/manage-organization/OrgLogoImageUploadButton';
import { OrganizationLogoModal } from "./organization-logo/OrganizationLogoModal";

function OrgLogoModule(props) {
  const { orgName, orgLogo, isAdmin, updatedLogoSrc, setOrgLogo, isMobileView, resetUploadOrgLogoImage } = props;
  const [isOrgLogoModalOpen, setIsOrgLogoModalOpen] = useState(false)

  const [displayedOrgLogo, setDisplayedOrgLogo] = useState(null)


  const handleClickEditOrgLogoButton = () => {
    setIsOrgLogoModalOpen(!isOrgLogoModalOpen)
  }

  const handleCloseModal = () => {
    setIsOrgLogoModalOpen(false)
  }

  useEffect(() => {
    if (orgLogo) {
      setDisplayedOrgLogo(orgLogo + `?${new Date().getTime()}`);
    }
  }, [orgLogo]);


  useEffect(() => {
    if (updatedLogoSrc) {
      const { newOrgLogoImageUrl } = updatedLogoSrc || {};
      setDisplayedOrgLogo(newOrgLogoImageUrl + `?${new Date().getTime()}`)
      resetUploadOrgLogoImage()
    }

    if (setOrgLogo) {
      const { newOrgLogoImageUrl } = setOrgLogo || {};
      setDisplayedOrgLogo(newOrgLogoImageUrl + `?${new Date().getTime()}`)
      resetUploadOrgLogoImage()
    }
  }, [updatedLogoSrc, setOrgLogo])

  return (
    <Flex direction='column' alignItems='center' justify='flex-start' >
      <Box position='relative'>
        <ImageDisplayWithLoader imageUrl={displayedOrgLogo} width='6.25em' height='6.25em' altText={`${orgName} Logo`} border='1px' borderColor='gray.400' rounded='1em' />
        {isAdmin && !isMobileView && <OrgLogoImageUploadButton onClickEditOrgLogoButton={handleClickEditOrgLogoButton} />}
      </Box>

      {isOrgLogoModalOpen &&
       <OrganizationLogoModal
         currentOrgLogo={displayedOrgLogo}
         handleCloseModal={handleCloseModal}
       />
      }
    </Flex>
  )

}

function mapStateToProps(state) {
  return {
    updatedLogoSrc: state.uploadOrgLogo.results,
    setOrgLogo: state.setOrgLogo.results.data,
    isMobileView: state.mobileViewMode.isMobileView
  };
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
      // uploadOrgLogoImage,
      resetUploadOrgLogoImage
		},
		dispatch,
	)


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrgLogoModule));
