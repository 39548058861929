import { SET_DSR_UI_INDICATORS } from '../../actions/dsr-actions-types'

const INITIAL_STATE_DSR_UI_INDICATORS = { dsrUiIndicators: "" }

export function setDsrUiIndicatorsReducer(state = INITIAL_STATE_DSR_UI_INDICATORS, action) {
    switch (action.type) {
        case SET_DSR_UI_INDICATORS:
            return { dsrUiIndicators: action.payload };
        default:
            return state;
    }
}