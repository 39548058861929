import React, { useState, useEffect } from 'react';
import { Box, Text, Flex, Divider } from '@chakra-ui/react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';

import { uploadOrgLogoImage, updateOrgProfileReset } from '../../organizations/actions/organizations-actions';
import ClaverModal from '../../global/components/helper-components/components/ClaverModal';
import EditOrgInfoForm from '../../profile/components/manage-organization/EditOrgInfoForm';
import OrgLogoModule from './OrgLogoModule';
import LaunchdeckButton from '../../global/components/helper-components/components/LaunchdeckButton';


function OrgInfoDisplay(props) {
	const { mode, adminOrgProfile, isMobileView, manageAdminOrgProfileOutcome, getAdminOrgProfile } = props || {};
	const [showEditOrgInfoModal, setShowEditOrgInfoModal] = useState(false);

	const [isAdmin, setIsAdmin] = useState(false)
	const [orgProfile, setOrgProfile] = useState(null)

	const [orgName, setOrgName] = useState("")
	const [orgLogo, setOrgLogo] = useState("")
	const [orgDescription, setOrgDescription] = useState("")
	const [orgRegisteredCountry, setOrgRegisteredCountry] = useState("")
	const [orgRegisteredAddress, setOrgRegisteredAddress] = useState("")
	const [orgRegisteredName, setOrgRegisteredName] = useState("")
	const [orgRegisteredNumber, setOrgRegisteredNumber] = useState("")


	useEffect(() => {
		if (adminOrgProfile) {
			const { isAdministrator, orgId, orgName, orgLogo, orgDescription, orgRegisteredCountry, orgRegisteredAddress, orgRegisteredName, orgRegisteredNumber } = adminOrgProfile || {}
			const orgProfile = { orgId, orgName, orgDescription, orgRegisteredCountry, orgRegisteredAddress, orgRegisteredName, orgRegisteredNumber };

			setIsAdmin(isAdministrator)
			setOrgName(orgName)
			setOrgLogo(orgLogo)
			setOrgDescription(orgDescription)
			setOrgRegisteredCountry(orgRegisteredCountry)
			setOrgRegisteredAddress(orgRegisteredAddress)
			setOrgRegisteredName(orgRegisteredName)
			setOrgRegisteredNumber(orgRegisteredNumber)
			setOrgProfile(orgProfile)
		}

	}, [adminOrgProfile])


	useEffect(() => {
		if (manageAdminOrgProfileOutcome) {
			const { updatedSuccessfully, updatedAdminOrgProfile } = manageAdminOrgProfileOutcome || {};

			if (updatedSuccessfully === true) {
				const { isAdmin, orgName, orgLogo, orgDescription, orgRegisteredCountry, orgRegisteredAddress, orgRegisteredName, orgRegisteredNumber } = updatedAdminOrgProfile || {}
		
				setIsAdmin(isAdmin)
				setOrgName(orgName)
				setOrgLogo(orgLogo)
				setOrgDescription(orgDescription)
				setOrgRegisteredCountry(orgRegisteredCountry)
				setOrgRegisteredAddress(orgRegisteredAddress)
				setOrgRegisteredName(orgRegisteredName)
				setOrgRegisteredNumber(orgRegisteredNumber)
				setShowEditOrgInfoModal(false)
			}
		}

	}, [manageAdminOrgProfileOutcome, props.action, adminOrgProfile])

	if (mode === "billing") {
		return (

			<Box>
				<Flex direction={['column', 'row', 'row', 'row']} justify='space-between' pt='1.5em'>
					<Box flex='1 1 0'>
						<Text color='gray.500' fontSize='0.875em' lineHeight='1.25em'>
							Legal entity name
						</Text>
						<Text mt='0.5em' lineHeight='1.375em' fontWeight='400'>{orgRegisteredName ? orgRegisteredName : "-"}</Text>

						<Text mt='2em' color='gray.500' fontSize='0.875em' lineHeight='1.25em'>
							Country incorporated
						</Text>
						<Text mt='0.5em' lineHeight='1.375em' fontWeight='400'>{orgRegisteredCountry ? orgRegisteredCountry : '-'}</Text>
					</Box>

					<Box flex='1 1 0' mt={['2em', '0', '0', '0']} pl={['0em', '1em', '1em', '1em']}>
						<Text color='gray.500' fontSize='0.875em' lineHeight='1.25em'>
							Registration number
						</Text>
						<Text mt='0.5em' lineHeight='1.375em' fontWeight='400'>{orgRegisteredNumber ? orgRegisteredNumber : "-"}</Text>

						<Text mt='2em' color='gray.500' fontSize='0.875em' lineHeight='1.25em'>
							Registered address
						</Text>
						<Text mt='0.5em' lineHeight='1.375em' fontWeight='400'>{orgRegisteredAddress ? orgRegisteredAddress : "-"}</Text>
					</Box>

				</Flex>

			</Box>
		);
	} else {
		if (isMobileView) {
			return <Flex direction='column' justify='center' align='center' w='100%' py='1em'>

				<Flex w='100%' alignItems='center' justify='space-between'>
					<Flex justify={['flex-start', 'center', 'center', 'center']} alignItems={['flex-start', 'center', 'center', 'center']} direction={['column', 'row', 'row', 'row']}>
						<Box className="user-profile-section__first-label"> Organization </Box>
					</Flex>

					<Flex w='100%' justify='flex-end'>
						<Flex direction='column' justify='center' alignItems='center'>
							<Flex justify={['center', 'flex-end', 'flex-end', 'flex-end']} w='100%'>
								<LaunchdeckButton bg='brand.500' color='white' onClick={() => setShowEditOrgInfoModal(true)} label='Edit' />
							</Flex>
						</Flex>
					</Flex>

				</Flex>

				<Divider />

				<Box mt='2em'>
					<OrgLogoModule
						isAdmin={isAdmin}
						orgName={orgName}
						orgLogo={orgLogo}
					/>

				</Box>
				{orgName && <Box fontSize='1.25em' lineHeight='1.875em' color='brandDark.500'>{orgName} </Box>}


				<Flex direction={['column', 'column', 'row', 'row']} justify={['center', 'center', 'space-between', 'space-between']}>
					<Box flex='1 1 0' mt={['2em', '0', '0', '0']} pl={['0em', '1em', '1em', '1em']}>
						<Text color='gray.500' fontSize='0.875em' lineHeight='1.25em' textAlign={['center', 'center', 'left', 'left']}>About</Text>
						<Text mt='0.5em' lineHeight='1.375em' fontWeight='400' className="white-space-preline">{orgDescription ? orgDescription : "-"}</Text>

					</Box>
				</Flex>


				<ClaverModal
					title='Edit Organization'
					handleCloseModal={() => setShowEditOrgInfoModal(false)}
					showModal={showEditOrgInfoModal}
				>
					<EditOrgInfoForm
						orgProfile={orgProfile}
						handleCloseModal={() => setShowEditOrgInfoModal(false)}
						getAdminOrgProfile={getAdminOrgProfile}
					/>
				</ClaverModal>

			</Flex>
		} else {
			return (

				<Box>
					<Box>
						<Flex>
							<Box flex='1 0 auto' position='relative'>
								<Box maxWidth='100em' w='100%' height='8em' bg='brand.300' rounded='0.25em' />
								<Flex mt='0.4em' justify='flex-start' align='center' position='absolute' bottom='-2em' left='1.5em'>
									<OrgLogoModule
										isAdmin={isAdmin}
										orgName={orgName}
										orgLogo={orgLogo}
									/>
									<Box color='gray.50' fontSize='1.25em' lineHeight='1.875em' pl='1em'>{orgName ? orgName : "Organization Name"} </Box>
								</Flex>
							</Box>
						</Flex>

					</Box>

					<Flex w='100%' justify='flex-end' pt='1em'>
						<Box color='brand.500' fontWeight='500' cursor='pointer' onClick={() => setShowEditOrgInfoModal(true)}>Edit</Box>
					</Flex>

					<Flex direction={['column', 'row', 'row', 'row']} justify='space-between' mt='3em' pt='1.5em'>
						<Box flex='1 1 0' mt={['2em', '0', '0', '0']} pl={['0em', '1em', '1em', '1em']}>
							<Text color='gray.500' fontSize='0.875em' lineHeight='1.25em'>About</Text>
							<Text mt='0.5em' lineHeight='1.375em' fontWeight='400' className="white-space-preline">{orgDescription ? orgDescription : "-"}</Text>

						</Box>
					</Flex>

					<ClaverModal
						title='Edit Organization'
						handleCloseModal={() => setShowEditOrgInfoModal(false)}
						showModal={showEditOrgInfoModal}
					>
						<EditOrgInfoForm
							orgProfile={orgProfile}
							handleCloseModal={() => setShowEditOrgInfoModal(false)}
							getAdminOrgProfile={getAdminOrgProfile}
						/>
					</ClaverModal>
				</Box>
			);
		}

	}

}

function mapStateToProps(state) {
	return {
		adminOrgProfile: state.adminOrgProfile.results.data,
		manageAdminOrgProfileOutcome: state.manageAdminOrgProfileOutcome.results.data,
		isMobileView: state.mobileViewMode.isMobileView
	};
}

function mapDispatchToProps(dispatch) {
	return {
		action: bindActionCreators(
			{
				uploadOrgLogoImage,
				updateOrgProfileReset
			},
			dispatch
		)
	};
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrgInfoDisplay));