import React from "react";

import { Box, Flex } from "@chakra-ui/react";
// import { SiLoom } from "react-icons/si";
import { LoomVideoDisplay } from "../LoomVideoDisplay";
import { BsPersonVideo } from "react-icons/bs";

const PublishedDsrLoomVideo = ({ subWidgetContent }) => {
  const  loomPlaceholder = <Flex direction="column" alignItems="center" justifyContent="center" height="80px">
    <Box color={'#4b42ad'} as={BsPersonVideo} width="90px" height="70px" mb="5px" />
    {/* <p>No loom video has been recorded yet</p> */}
  </Flex>;

  return (
    subWidgetContent
      ? <Box className="loader-img" width='100%' minHeight='300px'>
        <LoomVideoDisplay loomVideo={subWidgetContent[0]} />
      </Box>
      : loomPlaceholder
  );

}

export default PublishedDsrLoomVideo;
