import { SET_DSR_SHARED_DOCS } from '../../actions/dsr-actions-types'

const INITIAL_STATE_SET_DSR_SHARED_DOCS = { dsrSharedDocs: '' }

export function setDsrSharedDocReducer(state = INITIAL_STATE_SET_DSR_SHARED_DOCS, action) {
    switch (action.type) {
        case SET_DSR_SHARED_DOCS:
            return { dsrSharedDocs: action.payload };
        default:
            return state;
    }
}