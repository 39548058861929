import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { fetchVaultData, resetFetchVaultData } from './actions/vault-actions';
import { bindActionCreators } from 'redux'
import VaultProspectorPage from './VaultProspectorPage'


const VaultProspectorsPageContainer = (props) => {
  return (
    <VaultProspectorPage {...props} />
  )
}

const mapStateToProps = (state) => ({
  allUserDsrs: state.allUserDsrs.results.data,
  vaultIsProcessing: state.allUserDsrs.isLoading
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchVaultData,
      resetFetchVaultData
    },
    dispatch,
  )

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VaultProspectorsPageContainer))
