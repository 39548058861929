import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { Box, Flex, AccordionItem, Accordion, AccordionButton, AccordionIcon, AccordionPanel, Divider } from "@chakra-ui/react";
import moment from 'moment';
import { getUserDetailsUsingDsrMembersArray } from '../../../profile/helpers/user-helper';
import DsrFaqTextAreaInput from "../../../forms/TextareaField/components/DsrFaqTextAreaInput";
import LaunchdeckButton from "../../../global/components/helper-components/components/LaunchdeckButton";
import EditQuestionAndAnswerModule from "./EditQuestionAndAnswerModule";
import { displayReactIcon } from '../../helpers/dsr-display-helpers';

function AnsweredFaqDisplay({ dsrId, dsrTeamMembers, answeredQuestions, editFaqAnswerOutcome, isDsrAdmin }) {

  const [showEditInput, setShowEditInput] = useState(false)
  const [showEditQuestionInput, setShowEditQuestionInput] = useState(false)
  const [actOnQuestionId, setActOnQuestionId] = useState("")
  const [openedQuestionId, setOpenedQuestionId] = useState("")
  const [unfocusBackground, setUnfocusBackground] = useState(false)


  useEffect(() => {
    if (editFaqAnswerOutcome) {
      // Resets
      setShowEditInput(false)
      setActOnQuestionId("")
      setUnfocusBackground(false)
    }
  }, [editFaqAnswerOutcome]);


  function handleEditAnswerClick(questionId, userIsAuthor) {
    !!userIsAuthor ? setShowEditQuestionInput(true) : setShowEditInput(true)
    setActOnQuestionId(questionId)
    setUnfocusBackground(true)
  }

  function handleCloseEditInput() {
    setShowEditInput(false)
    setShowEditQuestionInput(false)
    // setOpenedQuestionId("")
    setUnfocusBackground(false)
  }


  function handleOpenAccordion(questionId) {
    setOpenedQuestionId(questionId)
  }


  const faqSet = (answeredQuestions?.length > 0) && answeredQuestions.map((question, questionIndex) => {
    const { questionId, questionStatus, questionTextContent, questionAuthorUserId,
      answerTextContent, questionAnswered, answerCreatedAt, answerEditedAt, answerAuthorUserId } = question || {};
    const isThisQuestion = (actOnQuestionId === questionId) ? true : false;

    if (questionStatus === 'active' && questionAnswered) {
      // Checks if current session user is a seller member. If yes, then render editing answer option.
      const thisUserIdToken = localStorage.getItem('USER_ID_TOKEN');
      const questionAuthorProfile = getUserDetailsUsingDsrMembersArray(dsrTeamMembers, questionAuthorUserId);
      const answerAuthorProfile = getUserDetailsUsingDsrMembersArray(dsrTeamMembers, answerAuthorUserId);

      const userIsQuestionAuthor = (questionAuthorProfile?.userId === thisUserIdToken) ? true : false;
      const userIsAuthor = (answerAuthorProfile?.userId === thisUserIdToken) ? true : false;
 
      const isEdited = answerEditedAt ? true : false;

      return (
        <Box key={`qn_` + questionIndex + questionId} mb='2.6em'
          bg={(unfocusBackground && isThisQuestion) ? 'gray.200' : 'white'} transition="background-color 0.8s ease"
          border='1px solid rgba(203, 213, 224, 0.5)' rounded='0.25em'>


          {(showEditQuestionInput && isThisQuestion && userIsAuthor) ? <Box p='1em'>
            <EditQuestionAndAnswerModule
              name="editFaqQ&A"
              label="Edit"
              sublabel=""
              placeholder=""
              fontSize='1em'
              htmlFor="editFaqQ&A"
              handleCloseInputArea={handleCloseEditInput}
              dsrId={dsrId}
              questionId={actOnQuestionId}
              currentQuestionTextValue={questionTextContent}
              currentAnswerTextValue={answerTextContent}
              isDsrAdmin={isDsrAdmin}
            />
          </Box> :
            <Accordion width='100%' allowToggle>
              <AccordionItem border='0px' px='1em' py='1.25em'>
                <AccordionButton _hover={{ background: 'none', }} fontSize='1em' onClick={() => handleOpenAccordion((openedQuestionId === questionId) ? "" : questionId)} fontWeight={((openedQuestionId === questionId)) ? '800' : '500'} lineHeight='1.5em'>
                  <Box flex="1" textAlign="left">
                    {(showEditQuestionInput && isThisQuestion) ?
                      <DsrFaqTextAreaInput
                        userActionRequired='edit-faq-question'
                        name="editFaqQuestion"
                        label="Edit"
                        sublabel=""
                        placeholder=""
                        fontSize='1em'
                        htmlFor="editFaqQuestion"
                        handleCloseInputArea={handleCloseEditInput}
                        dsrId={dsrId}
                        questionId={actOnQuestionId}
                        currentValue={questionTextContent}
                      />
                      : <Box color='brandDark.500'>{questionTextContent}</Box>}
                    {(answerAuthorProfile && answerCreatedAt) &&
                      <Flex fontStyle='italic' fontSize='0.75em' color={(showEditInput && !userIsQuestionAuthor && isThisQuestion) ? 'brandDark.500': 'gray.500'} fontWeight='400' align='center'>
                        {(showEditInput && !userIsQuestionAuthor && isThisQuestion) ? <>
                          <Box>{displayReactIcon('lock','1em')}</Box>
                       <Box>Question owned by</Box>
                       <Box pl='0.5em' fontWeight='semibold'> {answerAuthorProfile.memberName}</Box>
                        </>
                        : 
                        <>
                        <Box>Answered by</Box>
                        <Box pl='0.5em' fontWeight='semibold'> {answerAuthorProfile.memberName}</Box>
                        <Box pl='0.5em'>  {moment(answerCreatedAt).fromNow()} </Box>
                        </>
    }
                      </Flex>
                    }
                  </Box>

                  <AccordionIcon />
                </AccordionButton>

                <AccordionPanel>
                  {(showEditInput && isThisQuestion) ? <React.Fragment>
                    <DsrFaqTextAreaInput
                      userActionRequired='edit-faq-answer'
                      name="EditFaqAnswer"
                      label=""
                      sublabel=""
                      currentValue={answerTextContent}
                      fontSize='1em'
                      htmlFor="editFaqAnswer"
                      handleCloseInputArea={handleCloseEditInput}
                      dsrId={dsrId}
                      questionId={actOnQuestionId}
                      bg={(unfocusBackground && isThisQuestion) && 'white'}
                    />
                  </React.Fragment> :

                    <React.Fragment>
                      <Divider />
                      <Box my='1.5em'>
                        <Box fontWeight='400' lineHeight='1.5em' fontSize='0.9em' color='black' whiteSpace='pre-line'>{answerTextContent}</Box>
                        {isEdited && <Box fontSize='0.6em' color='gray.700'>EDITED</Box>}
                      </Box>

                      {(userIsAuthor || isDsrAdmin) &&
                        <Flex justify='flex-end'>
                          <LaunchdeckButton bg='brand.500' color='white' fontSize='0.75em' onClick={() => handleEditAnswerClick(questionId, userIsAuthor)} label='Edit' />
                        </Flex>
                      }
                    </React.Fragment>}

                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          }
        </Box>
      )
    } else return null
  }
  )

  return faqSet


}

function mapStateToProps(state) {
  return {
    editFaqAnswerOutcome: state.editFaqAnswerOutcome.results.data,
    isDsrAdmin: state.dsrAdmin.isDsrAdmin,
  };
}


export default withRouter(connect(mapStateToProps, null)(AnsweredFaqDisplay));
