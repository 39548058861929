import React from "react";

import { Box, Flex } from "@chakra-ui/react";


export default function DsrPdfDeckPostDisplay({contentBlock}) {

  const { contentBlockId, contentSource, contentDisplay } = contentBlock || {};
  const { contentHeader, contentText, contentSrc } = contentDisplay || {};


  const switchIframeBasedOnSource = () => {
    switch (contentSource) {
      case ('google-drive'): {
        let sliceStartPosition = contentSrc.search(/\/d\//i) + 3; // 3 characters for '/d/'
        let sliceEndPosition = contentSrc.search(/\/view?/i);
        let isInCorrectFormat = (contentSrc.search(/\/preview/i) >0) ? true : false;
        let gFileTokenString = '';

   
        if (isInCorrectFormat) {
          return (
            <Flex w='100%' height='100%' direction='column' overflow='hidden'>
              <iframe title={contentBlockId} src={contentSrc} frameBorder="0" height="1000"></iframe>
            </Flex>
           
          )
        } else if (sliceEndPosition >= 0) {
          gFileTokenString = contentSrc.slice(sliceStartPosition,sliceEndPosition)
          let formattedSrcUrl = `https://drive.google.com/file/d/${gFileTokenString}/preview`

          return (
            <Flex w='100%' height='100%' direction='column' overflow='hidden'>
              <iframe title={contentBlockId} src={formattedSrcUrl} frameBorder="0" height="1000"></iframe>
            </Flex>
          )
        } else {
          console.log('The pdf G source link is missing or not recognised:',gFileTokenString)
          return null;
        }

      
      }
      default: {
        console.log('There was an empty or unrecognised source to embed slides:', contentSource)
        break;
      }
    }
  }

  return (
    <React.Fragment>
      <Box mb='0.5em' fontWeight='500' fontSize='1.25em' lineHeight='1.8em' color='brandDark.500'>{contentHeader}</Box>

      <Box mb='1em' fontWeight='400' lineHeight='1.5em' color='brandDark.500'>{contentText}</Box>

     {switchIframeBasedOnSource()}
    </React.Fragment>
  );

}
