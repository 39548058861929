import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'

import { useToast } from '@chakra-ui/react'

import { setDsrPages, updatePdsr, resetUpdatePdsrOutcome } from '../../actions/dsr-actions'
import LaunchdeckButton from '../../../global/components/helper-components/components/LaunchdeckButton'
import { triggerLaunchdeckToast } from '../../../global/helpers/global-helpers'


function SaveDraftProspectDsrButton(props) {
  const toast = useToast();
  const {pDsrId, dsrPages, updateProspectDsrOutcome, isMobileView} = props || {};

  useEffect(() => {
    if (updateProspectDsrOutcome) {
      const {updatePdsrStatus, updatedPdsrId, sourceEvent} = updateProspectDsrOutcome || {};

      if ( sourceEvent==="save-draft-prospect-pdsr") {
        if (updatePdsrStatus === 'success' && updatedPdsrId === pDsrId && pDsrId !== undefined) {
          toast(
            triggerLaunchdeckToast({
              useCase: "show-success-state",
              // bannerText: "",
              label: "Draft successfully saved",
              isMobileView
            })
          )

        } else if (!updatePdsrStatus) {
          toast(
            triggerLaunchdeckToast({
            useCase: "show-error-state",
            bannerText: "Error copying link",
            label: "Unfortunately something went wrong. Please try again later.", 
            isMobileView
            })
          )
        }
  
          props.action.resetUpdatePdsrOutcome()
      }
    }
  }, [updateProspectDsrOutcome, toast, pDsrId, props.action]);

  function handleClick() {
    if (pDsrId && dsrPages) {
      props.action.updatePdsr({
        pDsrId: pDsrId,
        // dsrTitle: currentDsrTitle,
        dsrPages: dsrPages,
        sourceEvent: "save-draft-prospect-pdsr"
      })
  
      props.action.setDsrPages(dsrPages)
    }
  }

  return (
    <LaunchdeckButton
      label='Save'
      bg='white'
      color='brandDark.500'
      fontSize='0.875em'
      onClick={() => handleClick()}
    />

  );
}

function mapStateToProps(state) {
  return {
    updateProspectDsrOutcome: state.updateProspectDsrOutcome.results.data,
    isMobileView: state.mobileViewMode.isMobileView
  };
}

function mapDispatchToProps(dispatch) {
  return {
    action: bindActionCreators(
      {
        updatePdsr,
        setDsrPages, 
        resetUpdatePdsrOutcome
      },
      dispatch
    )
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SaveDraftProspectDsrButton));
