// Login action types
export const LOGIN_REQUEST = 'login_request';
export const LOGIN_SUCCESS = 'login_success';
export const LOGIN_FAIL = 'login_fail';

// Login action types
export const SIGNOUT_REQUEST = 'signout_request';
export const SIGNOUT_SUCCESS = 'signout_success';
export const SIGNOUT_CLEAR = 'signout_clear';
export const SET_JWT_EXPIRED = 'set_jwt_expired';
export const UNSET_JWT_EXPIRED = 'unset_jwt_expired';

// User priviledges
export const SET_USER_PRIVILEGES = 'set_user_privileges';

// Signup action types
export const SIGNUP_REQUEST = 'signup_request';
export const SIGNUP_SUCCESS = 'signup_success';
export const SIGNUP_FAIL = 'signup_fail';


// Reset Password action types
export const RESET_PASSWORD_REQUEST = 'reset_password_request';
export const RESET_PASSWORD_SUCCESS = 'reset_password_success';
export const RESET_PASSWORD_FAIL = 'reset_password_fail';

// Set New Password action types
export const SET_NEW_PASSWORD_REQUEST = 'set_new_password_request';
export const SET_NEW_PASSWORD_SUCCESS = 'set_new_password_success';
export const SET_NEW_PASSWORD_FAIL = 'set_new_password_fail';

// export const SET_NEW_USER_PASSWORD_REQUEST = 'set_new_user_password_request';
// export const SET_NEW_USER_PASSWORD_SUCCESS = 'set_new_user_password_success';
// export const SET_NEW_USER_PASSWORD_FAIL = 'set_new_user_password_fail';

// Verify Email action types
export const VERIFY_EMAIL_REQUEST = 'verify_email_request';
export const VERIFY_EMAIL_SUCCESS = 'verify_email_success';
export const VERIFY_EMAIL_FAIL = 'verify_email_fail';

// Delete Account action types
export const DELETE_ACCOUNT_REQUEST = 'delete_account_request';
export const DELETE_ACCOUNT_SUCCESS = 'delete_account_success';
export const DELETE_ACCOUNT_FAILURE = 'delete_account_failure';

// Access page authentication actions
export const ACCESS_PAGE_AUTHENTICATION_REQUEST = 'access_page_authentication_request';
export const ACCESS_PAGE_AUTHENTICATION_SUCCESS = 'access_page_authentication_success';
export const ACCESS_PAGE_AUTHENTICATION_FAILURE = 'access_page_authentication_failure';
export const ACCESS_PAGE_AUTHENTICATION_RESET = 'access_page_authentication_reset';
