import React, { useState, useEffect, memo } from 'react';

import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux';

import { Box, Flex, Modal, ModalOverlay, ModalBody, ModalContent, Switch, useToast } from '@chakra-ui/react';
import LaunchdeckCloseButton from '../../global/components/helper-components/components/LaunchdeckCloseButton';
import LaunchdeckButton from '../../global/components/helper-components/components/LaunchdeckButton'
import { manageDsrCustomPagesAccessibility, resetManageDsrCustomPagesAccessibility } from '../actions/dsr-page-accessibility-actions.js';

import { IoEyeSharp } from "react-icons/io5";
import { triggerLaunchdeckToast } from '../../global/helpers/global-helpers.js';


function DsrMassManageCustomPagesModal(props) {
  const {
    dsrId,
    pDsrId,
    handleCloseModal,
    // Actions
    manageDsrCustomPagesAccessibility,
    resetManageDsrCustomPagesAccessibility,
    // Redux states
    isMobileView,
    updatedDsrCustomPagesView
  } = props || {};

  const toast = useToast();

  // Local state
  const [localState, setLocalState] = useState({
    dsrIdToDuplicate: "",
    dsrTitle: "",
    isShowAllPages: true,
    isLockAllPages: "",
  })


  useEffect(() => {
    if (updatedDsrCustomPagesView) {
      const { successfulPageAccessibilityUpdate, statusMessage } = updatedDsrCustomPagesView || {}

      if (successfulPageAccessibilityUpdate === true) {
        toast(
          triggerLaunchdeckToast({
            useCase: "show-success-state",
            label: statusMessage ? statusMessage : 'All custom pages updated',
            isMobileView
          })
        )
      } else {
        toast(
          triggerLaunchdeckToast({
            useCase: "show-error-state",
            label: "Unfortunately something went wrong. Please try again later.",
            isMobileView
          })
        )
      }

      resetManageDsrCustomPagesAccessibility()
    }
  }, [updatedDsrCustomPagesView])


  const handleChangeButton = () => {
    manageDsrCustomPagesAccessibility({
      ...dsrId && { dsrId },
      ...pDsrId && { pDsrId },
      isHideAllPages: !localState.isShowAllPages,
      isLockAllPages: localState.isLockAllPages
    })


  }


  const handleClickModalCloseButton = () => {
    handleCloseModal()
  }

  const renderContent = () => {
    // if (showLoader) {
    //   return <DsrGenericLoadingModal modalText={`Working on duplicating ${localState.dsrTitle}...`} />

    // } else {
    return (<React.Fragment>
      <Flex w='100%' justify='center' mt='1em'>
        <Box as={IoEyeSharp} boxSize="3em" color="brand.500" />
      </Flex>
      <Flex fontSize='1.25em' color='brandDark.500' fontWeight='600' mt='1em' mb='2em' textAlign='center'>
        <Box>Manage custom page content</Box>
      </Flex>


      <Box w="100%" align="center">

        <Flex w={isMobileView ? '100%' : '80%'} minW={!isMobileView && '30em'} direction='column' align='center' mb='2em' px='2em'>

          <Flex w='100%' my='1em'>

            <Flex justify='center' w='100%'>

              <Box w='100%' maxW={!isMobileView && '25em'} p={isMobileView ? '1em' : '1em 2em'} justify='center' rounded='0.25em' bg='gray.50' border='1px solid' borderColor='gray.300' mb={'2em'}>
                {/* <Text textAlign={'left'} mb='1em' fontSize='0.875em' fontWeight='600'>What to copy over</Text> */}
                <Flex w='100%' direction='column' justify='center' align='center' gap={4}>

                  <Box mb='1em' w='100%'>
                    <Flex w='100%' justify='space-between' align='center'>
                      <Box fontSize='0.875em'>Hidden pages</Box>
                      <Flex fontSize='0.875em' justify='space-between' align='center'>
                        <Box fontSize='0.8em' mr='0.5em'>Hide all</Box>
                        <Box>
                          <Switch
                            size='lg'
                            name='isShowAllPages'
                            isChecked={localState.isShowAllPages}
                            value={localState.isShowAllPages}
                            onChange={(event) => setLocalState({
                              ...localState,
                              isShowAllPages: event.target.checked
                            })}
                          />
                        </Box>
                        <Box fontSize='0.8em' ml='0.5em'>Show all</Box>
                      </Flex>

                    </Flex>
                    <Box textAlign='left' mt='0.2em' fontSize='0.6em' color='gray.500'>Hidden pages will not appear to your audiences</Box>
                  </Box>

                  <Box mb='1em' w='100%'>
                    <Flex w='100%' justify='space-between' align='center'>
                      <Box fontSize='0.875em'>Locked pages</Box>

                      <Flex fontSize='0.875em' justify='space-between' align='center'>
                        <Box fontSize='0.8em' mr='0.5em'>Unlock all</Box>
                        <Box>
                          <Switch
                            size='lg'
                            name='isLockAllPages'
                            isChecked={localState.isLockAllPages}
                            value={localState.isLockAllPages}
                            onChange={(event) => setLocalState({
                              ...localState,
                              isLockAllPages: event.target.checked
                            })}
                          />
                        </Box>
                        <Box fontSize='0.8em' ml='0.5em'>Lock all</Box>
                      </Flex>

                    </Flex>
                    <Box textAlign='left' mt='0.2em' fontSize='0.6em' color='gray.500'>Locked pages indicates pages whose content are made unavailable temporarily</Box>
                  </Box>
                </Flex>

              </Box>
            </Flex>
          </Flex>

          <Flex w='100%'>

          </Flex>
        </Flex>

        <Flex justify='center' px='1em' py='0.625em' mb='1em' gap={8}>
          <LaunchdeckButton bg='gray.200' color='brandDark.500' px='3em' label='Cancel' onClick={() => handleClickModalCloseButton()} />
          <LaunchdeckButton bg='brand.500' color='white' px='3em' label='Save' onClick={() => handleChangeButton()} />
        </Flex>

      </Box>
    </React.Fragment>)

    // }
  }




  const displayContent = () => {
    return (
      <Box>
        <Flex direction='column' mt='1em' justify='center' alignItems='center' px={isMobileView ? '1em' : '0.5em'} w='100%'>
          {renderContent()}
          {/* {localState.dsrIdToDuplicate} */}
        </Flex>
      </Box>
    )
  }


  return (
    <Modal isOpen={true} closeOnOverlayClick={false} isCentered={true} >
      <ModalOverlay className="modal-background" />
      <ModalContent rounded='0.25em' minW={['90%', '500px']} maxW='700px'>
        <ModalBody px='0em'>
          <Flex direction='column' justify='space-around' alignItems='center' w='100%' p='1em'>
            <Flex justify='flex-end' w='100%'>

              <Box pr={isMobileView ? '0em' : '1.5em'}>
                <LaunchdeckCloseButton handleCloseModal={() => handleClickModalCloseButton()} />
              </Box>
            </Flex>

            <Box my='1em' border={'1px solid'} borderColor={'gray.100'} rounded='0.25em'>
              {displayContent()}
            </Box>

          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}


const mapStateToProps = (state) => ({
  isMobileView: state.mobileViewMode.isMobileView,
  updatedDsrCustomPagesView: state.dsrCustomPagesAccessibility.results
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      manageDsrCustomPagesAccessibility,
      resetManageDsrCustomPagesAccessibility
    },
    dispatch,
  )


export default memo(withRouter(connect(mapStateToProps, mapDispatchToProps)(DsrMassManageCustomPagesModal)))
