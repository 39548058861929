import React from 'react';
import { Spinner, Flex} from "@chakra-ui/react";


const ComponentLoader = () => {
	return (
		<Flex w='100%' align='center' justify='center' >
			<Spinner
				thickness="2px"
				speed="0.65s"
				emptyColor="gray.200"
				color="brand.500"
				boxSize="2em"
			/>
		</Flex>
	);
};

export default ComponentLoader;
