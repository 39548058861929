import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { Box, Button, Flex, Skeleton, Image } from "@chakra-ui/react";

import {
  DndContext,
  DragOverlay,
  PointerSensor,
  useSensors,
  useSensor,
  defaultDropAnimation,
} from "@dnd-kit/core";
import _ from 'lodash';
import {
  SortableContext,
  arrayMove,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { restrictToWindowEdges } from '@dnd-kit/modifiers';

import { Sortable } from './Sortable'
import Item from "./Item";
import Widget from "./Widget";
import { NormalText } from './sub-widgets/NormalText';
import { TitleText } from './sub-widgets/TitleText';
import { ImageSubWidget } from './sub-widgets/ImageSubWidget';
import { UploadedImages } from './sub-widgets/UploadedImages';
import { YouTubeVideo } from './sub-widgets/YouTubeVideo';
import { CtaButton } from './sub-widgets/CtaButton';
import { EmbedDocument } from './sub-widgets/EmbedDocument';
import { ContentDivider } from './sub-widgets/ContentDivider';
import { UploadedVideo } from './sub-widgets/UploadedVideo';
import { UploadedPdfDocument } from './sub-widgets/UploadedPdfDocument';
import { LoomVideo } from './sub-widgets/LoomVideo';
import { ContentSpacer } from "./sub-widgets/ContentSpacer";
import { UploadedBanner } from "./sub-widgets/UploadedBanner";
import { Signature } from "./sub-widgets/Signature";
 
import { generateRandomString } from '../../../global/helpers/global-helpers';
import { insert, convertContentArrToObj, convertDndObjToArr } from './dsr-dnd-helpers/helpers';
import { GoPlus } from "react-icons/go";
import emptyWidgetsList from '../../styles/images/empty-widget-list.png';
import LaunchdeckButton from "../../../global/components/helper-components/components/LaunchdeckButton";
import LaunchdeckFormInput from "../../../forms/TextInputField/components/LaunchdeckFormInput";
import { UploadedAudio } from './sub-widgets/UploadedAudio';



export function CustomPageBuilder({updateDsrPages, page}) {
  const [items, setItems] = useState({});
  const [activeId, setActiveId] = useState(null);
  const [editableItemId, setEditableItemId] = useState(null);
  const isDragging = !!activeId

  // Disable drag and drop when editing a sub-widget
  const isDraggable = !_.isNil(editableItemId)

  useEffect(() => {
    setItems(convertContentArrToObj(page));
  }, [page]);

  useEffect(() => {
    const prevWidgetState = page.pageContent;
    const newWidgetState = convertDndObjToArr(items)

    if (activeId === null && !_.isEqual(prevWidgetState, newWidgetState) && newWidgetState.length > 0) {
      handleUpdate({
        ...page,
        pageContent: convertDndObjToArr(items),
      })
    }
  }, [items])

  const sensors = useSensors(
    useSensor(
      PointerSensor, {
      // Require the pointer to move by 10 pixels before activating
      activationConstraint: {
        distance: 10,
      }
    }
    )
  );

  const handleUpdate = (data) => {
    updateDsrPages(data)
  }

  const handleChangePageTitle = (updatedPageTitle) => {
    handleUpdate({
      ...page,
      pageTitle: updatedPageTitle
    })
  }

  const findContainer = (id) => {
    if (id in items) {
      return id;
    }

    return Object.keys(items).find((key) => Object.keys(Object.values(items[key].widgetContent)[0].subWidgets).includes(id));
  };

  const handleDragStart = ({ active }) => {
    setActiveId(active.id);
  };

  const handleDragOver = ({ active, over }) => {
    const overId = over?.id;

    if (!overId) {
      return;
    }

    if (overId == null || active.id in items) {
      return;
    }

    const overContainer = findContainer(overId);
    const activeContainer = findContainer(active.id);

    if (!overContainer || !activeContainer) {
      return;
    }

    if (activeContainer !== overContainer) {

      setItems((items) => {
        const activeItems = Object.values(items[activeContainer].widgetContent)[0].subWidgets || [];
        const overItems = Object.values(items[overContainer].widgetContent)[0].subWidgets || [];
        const activeColumnId = Object.values(items[activeContainer].widgetContent)[0].columnId;
        const overColumnId = Object.values(items[overContainer].widgetContent)[0].columnId

        const overIndex = Object.keys(overItems).indexOf(overId);
        const activeIndex = Object.keys(activeItems).indexOf(active.id)

        let newIndex;

        if (overId in items) {
          // console.log("->?<-", overId, items);
          newIndex = _.values(overItems).length + 1;
        } else {
          const isBelowLastItem =
            over &&
            overIndex === _.values(overItems).length - 1 &&
            active.rect.current.translated &&
            active.rect.current.translated.offsetTop >
            over.rect.offsetTop + over.rect.height;

          // console.log(active.rect.current.translated);
          // console.log(over.rect.offsetTop);

          const modifier = isBelowLastItem ? 1 : 0;

          newIndex =
            overIndex >= 0 ? overIndex + modifier : _.values(overItems).length + 1;
        }

        const objWithRemovedItem = {
          ...items[activeContainer],
          widgetContent: {
            ...items[activeContainer].widgetContent,
            [activeColumnId]: {
              ...items[activeContainer].widgetContent[activeColumnId],
              subWidgets: {
                ..._.keyBy(_.values(items[activeContainer].widgetContent[activeColumnId].subWidgets).filter((item) => item.id !== active.id), 'id')
              }
            }
          }
        };

        const objectWithNewItem = {
          ...items[overContainer],
          widgetContent: {
            ...items[overContainer].widgetContent,
            [overColumnId]: {
              ...items[overContainer].widgetContent[overColumnId],
              subWidgets: {
                ..._.keyBy(insert(
                  _.values(items[overContainer].widgetContent[overColumnId]?.subWidgets),
                  newIndex,
                  _.values(items[activeContainer].widgetContent[activeColumnId]?.subWidgets)[activeIndex]
                ), 'id')
              }
            }
          }
        };

        return {
          ...items,
          [activeContainer]: objWithRemovedItem,
          [overContainer]: objectWithNewItem
        };
      });
    }
  };

  const handleDragEnd = ({ active, over }) => {
    const overId = over?.id;

    if (!overId) {
      setActiveId(null);
      return;
    }

    if (items[active.id] && items[overId]) {
      if (active.id !== overId) {
        // console.log("move board");

        setItems((container) => {
          const ids = Object.keys(container);
          const activeIndex = ids.indexOf(active.id);
          const overIndex = ids.indexOf(overId);

          return Object.fromEntries(
            arrayMove(Object.entries(container), activeIndex, overIndex)
          );
        });
      }

      setActiveId(null);
      return;
    }

    if (items[active.id] || items[overId]) {
      setActiveId(null);
      return;
    }

    const activeContainer = findContainer(active.id);

    if (!activeContainer) {
      setActiveId(null);
      return;
    }

    const overContainer = findContainer(overId);

    if (activeContainer && overContainer) {
      const overIndex = Object.keys(Object.values(items[overContainer].widgetContent)[0].subWidgets).indexOf(overId);
      const activeIndex = Object.keys(Object.values(items[activeContainer].widgetContent)[0].subWidgets).indexOf(active.id)
      const columnId = Object.values(items[activeContainer].widgetContent)[0].columnId

      if (activeIndex !== overIndex) {
        const sortedItems = {
          ...items[overContainer],
          widgetContent: {
            ...items[overContainer].widgetContent,
            [columnId]: {
              ...items[overContainer].widgetContent[columnId],
              subWidgets: _.keyBy(arrayMove(
                _.values(items[overContainer].widgetContent[columnId].subWidgets),
                activeIndex,
                overIndex
              ), 'id')
            }
          }
        };

        setItems((items) => ({
          ...items,
          [overContainer]: sortedItems
        }));
      }
    }

    setActiveId(null);
  };

  const handleDragCancel = () => {
    setActiveId(null);
  };

  const addWidget = () => {
    let newWidget = {
      sortId: 1,
      widgetContent: [{
        columnId: 'col-1_' + generateRandomString(10),
        subWidgets: [
          {
            id: generateRandomString(10),
            contentBlockType: "normal-text",
            content: "Add your own captivating content here "
          }
        ]
      }],
      contentBlockId: "wg__" + generateRandomString(10),
    };

    if (newWidget.widgetContent.length > 0) {
      const updatedPageContent = [
        ...page.pageContent,
        newWidget,
      ].map((item, index) => ({
        ...item,
        sortId: index + 1,
      }));

      handleUpdate({
        ...page,
        pageContent: updatedPageContent,
      })
    }
  }

  const removeWidget = (widgetId) => {
    const updatedPageContent = [...page.pageContent].filter((widget) => {
      return widget.contentBlockId !== widgetId
    })

    handleUpdate({
      ...page,
      pageContent: updatedPageContent
    })
  };

  const removeSubWidget = (widgetId, subWidgetId) => {
    const columnId = Object.values(items[widgetId].widgetContent)[0].columnId

    const updatedPageContent = {
      ...items[widgetId],
      widgetContent: {
        ...items[widgetId].widgetContent,
        [columnId]: {
          ...items[widgetId].widgetContent[columnId],
          subWidgets: {
            ..._.keyBy(_.values(items[widgetId].widgetContent[columnId].subWidgets).filter((item) => item.id !== subWidgetId), 'id')
          }
        }
      }
    };

    setItems((items) => ({
      ...items,
      [widgetId]: updatedPageContent
    }));
  };

  if (Object.keys(items).length < 1) {
    return (
      <Flex justifyContent='center' alignItems='center' flexDirection='column' bg='white' py='55px' border='1px solid #CBD5E0' borderRadius='8px'>
        <Box mb='12px'>
          <Image src={emptyWidgetsList} width='64px' height='64px' alt='empty widget list image'/>
        </Box>
        <Box mb='24px' maxWidth='450px' textAlign='center' color='#718096' fontSize='0.875em'>
          <Box fontWeight='500'>Your page seems a little empty</Box>
          <Box fontWeight='400'>Let’s start by adding some widgets & content to it</Box>
          
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <LaunchdeckButton bg='brand.500' color='white' onClick={() => addWidget()} label='Add content' />
        </Box>
      </Flex>
    )
  }

  return (
    <DndContext
      sensors={sensors}
      onDragStart={handleDragStart}
      onDragOver={handleDragOver}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
      modifiers={[restrictToWindowEdges]}
    >
      <Box p='0.5em 1em' bg='white' rounded='0.25em'>
        <Box fontSize='0.7em' pl='0.25em' fontWeight='600' color='brand.300'>PAGE TITLE</Box>
        <LaunchdeckFormInput
          onChange={(e) => handleChangePageTitle(e.target.value)}
          value={page.pageTitle}
          label=""
          fontSize='20px'
          labelFontSize='16px'
          fontWeight='600'
          errorLogic={page.pageTitle ? false : true}
          errorMessage="Page title cannot be blank"
          p='0.75em 0.25em'
          noBorder
          cursor='pointer'
        />
      </Box>
       
      <SortableContext
        items={Object.keys(items)}
        strategy={verticalListSortingStrategy}
      >

        {Object.keys(items).map((widgetId) => (
          <Sortable key={widgetId} id={widgetId} isDraggable={isDraggable}>
                   
            <Widget
              handleUpdate={handleUpdate}
              page={page}
              widget={items[widgetId]}
              items={items}
              setItems={setItems}
              removeWidget={removeWidget}
              activeId={activeId}
              editableItemId={editableItemId}
            >
            
              <SortableContext
                key={widgetId}
                items={Object.keys(Object.values(items[widgetId].widgetContent)[0].subWidgets)}
                strategy={verticalListSortingStrategy}
              >
                {Object.keys(Object.values(items[widgetId].widgetContent)[0].subWidgets).map((subWidgetId) => {
                  let Component;
                  const subWidget = Object.values(items[widgetId].widgetContent)[0].subWidgets[subWidgetId];

                  switch (Object.values(items[widgetId].widgetContent)[0].subWidgets[subWidgetId].contentBlockType) {
                  case 'title-text':
                      Component = <TitleText />;
                      break;
                    case 'normal-text':
                      Component = <NormalText />;
                      break;
                    case 'uploaded-image':
                      Component = <UploadedImages />;
                      break;
                    case 'uploaded-banner':
                      Component = <UploadedBanner />;
                      break;
                    case 'url-image':
                      Component = <ImageSubWidget />;
                      break;
                    case 'cta-button':
                      Component = <CtaButton />;
                      break;
                    case 'youtube-video':
                      Component = <YouTubeVideo />;
                      break;
                    case 'uploaded-audio':
                      Component = <UploadedAudio />;
                      break;
                    case 'uploaded-video':
                      Component = <UploadedVideo />;
                      break;
                    case 'uploaded-pdf':
                      Component = <UploadedPdfDocument />;
                      break;
                    case 'embed-document':
                      Component = <EmbedDocument />;
                      break;
                    case 'content-divider':
                      Component = <ContentDivider />;
                      break;
                    case 'content-spacer':
                      Component = <ContentSpacer />;
                      break;
                    case 'loom-video':
                      Component = <LoomVideo />;
                      break;
                    case 'user-signature':
                      Component = <Signature />;
                      break;
                    default:
                      Component = "";
                  };
                  
                  return (
                    <Sortable key={subWidgetId} id={subWidgetId} isDraggable={isDraggable}>
                      <Item
                        key={subWidgetId}
                        widget={items[widgetId]}
                        subWidget={subWidget}
                        removeSubWidget={removeSubWidget}
                        setEditableItemId={setEditableItemId}
                        editableItemId={editableItemId}
                        setItems={setItems}
                        items={items}
                        isDragging={isDragging}
                      >
                        {Component ? Component : Object.values(items[widgetId].widgetContent)[0].subWidgets[subWidgetId].contentBlockType}
                      </Item>
                    </Sortable>
                  )
                })
                }
              </SortableContext>
            </Widget>
          </Sortable>
        ))}
      </SortableContext>

      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <Button
          borderWidth="1px"
          borderRadius="20px"
          h="40px"
          w="max-content"
          color="white"
          bg="#29ABE2"
          fontSize="14px"
          fontWeight='400'
          p="0 20px 0 20px"
          _hover={{ color: "brand.500", bg: "brand.50", border:"1px solid", borderColor:'brand.200', fontWeight:'600' }}
          onClick={() => addWidget()}
        >
          Add content block <Box pl='5px'><GoPlus style={{height: '20px', width: '20px'}}/></Box>

        </Button>
      </Box>

      {createPortal(
        <DragOverlay dropAnimation={{...defaultDropAnimation}}>
          {activeId ? (
            items[activeId] ? (
              <Widget items={items} setItems={setItems} widget={items[activeId]}>
                {Object.keys(Object.values(items[activeId].widgetContent)[0].subWidgets).map((subWidget) => (
                  <Skeleton key={subWidget.id} height="20px" my="10px" />
                ))}
              </Widget>
            ) : (
              <Skeleton height="20px" my="10px" />
            )
          ) : null}
        </DragOverlay>,
        document.body
      )}
    </DndContext>
  );
}
