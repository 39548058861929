import React, { useState, useEffect, memo } from 'react';

import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux';

import { Box, Flex, Image, Modal, ModalOverlay, ModalBody, ModalContent  } from '@chakra-ui/react';
import PublicDsrJoinRoomForm from './PublicDsrJoinRoomForm';
import { joinPublicDsr, resetJoinPublicDsr } from '../../../digital-sales-room/actions/public-dsr-actions.js'
import logo from '../../../global/styles/images/launchdeck_logo.svg';
import { redirectToNewPage, validateEmail } from '../../../global/helpers/global-helpers.js';
import PageLoader from '../../../global/components/helper-components/components/PageLoader.jsx';
import PublicDsrJoinRoomLoginForm from './PublicDsrJoinRoomLoginForm.jsx';
import PublicDsrJoinRoomCreateUserForm from './PublicDsrJoinRoomCreateUserForm.jsx';
import { splitNameInput } from '../../../profile/helpers/user-helper.js';
import LaunchdeckCloseButton from '../../../global/components/helper-components/components/LaunchdeckCloseButton.jsx';
import DsrAccessSetPasswordForm from '../../dsr-access-mgmt/DsrAccessSetPasswordForm.jsx';


function SignupLoginModal(props) {

  const {
    dsrId, 
    handleCloseModal,
    // Actions
    joinPublicDsr,
    resetJoinPublicDsr,
    // Redux states
    joinPublicDsrOutcome,
    joinPublicDsrIsProcessing,
    dsrDisplayLogoImages,
    isMobileView
  } = props || {};

  const [displayedForm, setDisplayedForm] = useState("check-email-form")

  const [processedEmailAddress, setProcessedEmailAddress] = useState("")
  const [greetingName, setGreetingName] = useState("")
  const [createUserAuthToken, setCreateUserAuthToken] = useState("")
  const [processedUserIdToken, setProcessedUserIdToken] = useState("")
  const [newProfileToken, setNewProfileToken] = useState("")

  // Inputs
  const [userEmailInput, setUserEmailInput] = useState("")
  const [loginPasswordInput, setLoginPasswordInput] = useState("")
  const [joinerNameInput, setJoinerNameInput] = useState("")
  const [companyNameInput, setCompanyNameInput] = useState("")
  const [companyRoleInput, setCompanyRoleInput] = useState("")
  const [createdPasswordInput, setCreatedPasswordInput] = useState("")

  // Error Handling
  const [showEmailInputError, setShowEmailInputError] = useState(false);
  const [showPasswordError, setShowPasswordError] = useState(false);
  const [showIncorrectPasswordError, setShowIncorrectPasswordError] = useState(false);
  const [showNameInputError, setShowNameInputError] = useState(false);
  const [showCreatedPasswordInputError, setShowCreatedPasswordInputError] = useState(false);

  // UX
  const [redirectingUser ,setRedirectingUser] = useState(false);


  useEffect(() => {
    if (joinPublicDsrOutcome) {
      const { nextStep, processedEmail, pendingUserIdToken, pendingUserNewProfiletoken, greetingName, createNewUserToken, processSuccessful } = joinPublicDsrOutcome || {}
      if (processSuccessful && nextStep) {
        switch (nextStep) {
          case ("password-login-prompt"): {
            setDisplayedForm("existing-user-login-form")
            break;
          }
          case ("create-new-user-account"): {
            setDisplayedForm("create-new-user-form")
            setCreateUserAuthToken(createNewUserToken)
            break;
          }
          case("login-and-redirect-to-dsr"): {
            redirectToNewPage(dsrId ? `/dsr/${dsrId}` : `/home`)
            setRedirectingUser(true)
            break;
          }
          case("show-unsuccessful-login"): {
            setLoginPasswordInput("")
            setShowIncorrectPasswordError(true)
            setDisplayedForm("existing-user-login-form")
          
            break;
          }
          case("set-password-edit-profile"): {
            setDisplayedForm("set-password-form")
            setProcessedUserIdToken(pendingUserIdToken)
            setNewProfileToken(pendingUserNewProfiletoken)
            break;
          }
          default: {
            setDisplayedForm("check-email-form")
            break;
          }
        }
      }

      if (processedEmail) {
        setProcessedEmailAddress(processedEmail)
      }

      if (greetingName) {
        setGreetingName(greetingName)
      }

      resetJoinPublicDsr()
    }
  }, [joinPublicDsrOutcome, resetJoinPublicDsr])

  const onChangeHandler = (event) => {

    switch (event.target.name) {
      case ('email'): {
        setUserEmailInput(event.target.value.trim())
        break;
      }
      case ('login-password-input'): {
        if (showPasswordError) {
          setShowPasswordError(false)
        }

        setLoginPasswordInput(event.target.value.trim())
        break;
      }
      case("created-password-input"): {
        if (showCreatedPasswordInputError) {
          setShowCreatedPasswordInputError(false)
        }

        setCreatedPasswordInput(event.target.value)
        break;
      }
      case("joiner-name-input"): {
        if (showNameInputError) {
          setShowNameInputError(false)
        }

        setJoinerNameInput(event.target.value.trim())
        break;
      }
      case("company-name-input"): {

        setCompanyNameInput(event.target.value.trim())
        break;
      }
      case("company-role-input"): {
       
        setCompanyRoleInput(event.target.value.trim())
        break;
      }
      default: {
        break;
      }
    }
  };

  const handleCheckUserEmail = () => {
    resetAllInputsAndErrorStates()
    const isValidEmail = validateEmail(userEmailInput)

    if (!isValidEmail) {
      setShowEmailInputError(true)
    } else {
      const joinPublicDsrPayload = {
        event: 'check-if-existing-user',
        email: userEmailInput.toLowerCase().trim()
      }
      joinPublicDsr(joinPublicDsrPayload)
    }
  }

  const handleLoginJoinRoom = () => {
    if (loginPasswordInput?.length < 6) {
      setShowPasswordError(true)
    } else if (loginPasswordInput && processedEmailAddress) {

      const joinPublicDsrPayload = {
        event: 'login-existing-user',
        email: processedEmailAddress,
        passwordInput: loginPasswordInput,
        dsrId: dsrId
      }

      joinPublicDsr(joinPublicDsrPayload)
    }
  }

  const handleCreateRoomJoiner = () => {

    if (!joinerNameInput) {
      setShowNameInputError(true)
    } 
    
    if (createdPasswordInput?.length < 6) {
      setShowCreatedPasswordInputError(true)
    } 
    
    if (joinerNameInput && createdPasswordInput?.length >= 6) {
      const {firstName, lastName} = splitNameInput(joinerNameInput)

      const joinPublicDsrPayload = {
        event: 'create-new-joiner-account',
        email: processedEmailAddress,
        dsrId: dsrId, 
        firstName,
        lastName,
        companyName: companyNameInput,
        companyRole: companyRoleInput,
        createdPassword: createdPasswordInput,
        createUserAuthToken: createUserAuthToken
      }

      joinPublicDsr(joinPublicDsrPayload)
    }
    
  }

  const resetAllInputsAndErrorStates = () => {
    setShowEmailInputError(false)
    setShowPasswordError(false)
    setShowNameInputError(false)
    setShowIncorrectPasswordError(false)
    setShowCreatedPasswordInputError(false)
    setDisplayedForm("")
    setProcessedEmailAddress("")
    setUserEmailInput("")
    setLoginPasswordInput("")
    setJoinerNameInput("")
    setCompanyNameInput("")
    setCompanyRoleInput("")
    setCreatedPasswordInput("")
    setProcessedUserIdToken("")
  }

  const handleClickModalCloseButton = () => {
    resetAllInputsAndErrorStates()
    handleCloseModal()
  }

  const handleSetPasswordProfile = (newPasswordInput, newProfileToken) => {
    if (dsrId && newPasswordInput && newProfileToken) {
      const joinPublicDsrPayload = {
        event: 'pending-existing-user-join-public-dsr',
        email: processedEmailAddress,
        dsrId: dsrId, 
        newPasswordInput,
        newProfileToken
      }

      joinPublicDsr(joinPublicDsrPayload)
    }
  }

  const renderForm = () => {
    if (joinPublicDsrIsProcessing || redirectingUser) {
      return <PageLoader />
    } else {
      switch (displayedForm) {
        case ('existing-user-login-form'): {
          return (
            <PublicDsrJoinRoomLoginForm
              handleLoginJoinRoom={handleLoginJoinRoom}
              onChangeHandler={onChangeHandler}
              showPasswordError={showPasswordError}
              greetingName={greetingName}
              showIncorrectPasswordError={showIncorrectPasswordError}
            />
          );
        }
        case ('create-new-user-form'): {
          return ( 
            <PublicDsrJoinRoomCreateUserForm 
            handleCreateRoomJoiner={handleCreateRoomJoiner}
            onChangeHandler={onChangeHandler}
            showCreatedPasswordInputError={showCreatedPasswordInputError}
            showNameInputError={showNameInputError}
            />
          );
        }
        case ('set-password-form'): {
          return (
            <>
              <Box textAlign='center' fontSize='1.5em' color='brandDark.500' mb='0.5em'>Set a password</Box>
              <Box textAlign='center' fontSize='0.875em' color='gray.500' fontWeight='400' lineHeight='1.375em' mb='3.5em'>It'll help you access this Room more easily in the future </Box>
              <DsrAccessSetPasswordForm
              newUserId={processedUserIdToken}
              newProfileToken={newProfileToken}
              defaultEmail={processedEmailAddress}
              handleSetPasswordProfile={handleSetPasswordProfile}
            />
          </>
          )
        }
        default:
        case ('check-email-form'): {
          if (redirectingUser) return null
          else {
            return (
              <PublicDsrJoinRoomForm
                handleCheckUserEmail={handleCheckUserEmail}
                onChangeHandler={onChangeHandler}
                showEmailInputError={showEmailInputError}
                userEmailInput={userEmailInput}
              />
            )
          } 
        }
      }
    }
  }

  const renderLogos = () => {
    const {
      displayedAudienceLogo,
      displayedHostLogo
    } = dsrDisplayLogoImages || {};

    if (displayedHostLogo) {
      return (
        <Flex w='100%' justify='center'>
          <Image src={displayedHostLogo} alt="Host Logo" width='50%' minWidth='1em' maxWidth='5em' mb='2em' />
          {displayedAudienceLogo && <Image src={displayedAudienceLogo} alt="Guest Logo" width='50%' minWidth='1em' maxWidth='5em' mb='2em' ml='1.5em' />}
        </Flex>
      )

    } else {
      return (

        <Flex w='100%' justify='center'>
          <Image src={logo} alt="Launchdeck Logo" width='50%' minWidth='1em' maxWidth='5em' mb='2em' />
        </Flex>
      )
    }
  }


  const displayContent = () => {
    return (
      <Box w='100%'>
        <Flex direction='column' mt='1em' justify='center' alignItems='center' px={isMobileView ? '1em' :'2em'} w='100%'>
          {renderLogos()}
          {renderForm()}
        </Flex>
      </Box>
    )
  }

 
  return (
    <Modal isOpen={true} closeOnOverlayClick={false} isCentered={true} >
      <ModalOverlay className="modal-background" />
      <ModalContent rounded='0.25em' minW={['90%', '500px']} maxW='700px'>
        <ModalBody px='0em'>
          <Flex direction='column' justify='space-around' alignItems='center' w='100%' p='1em'>
            <Flex justify='flex-end' w='100%'>

              <Box pr={isMobileView ? '0em':'1.5em'}>
                <LaunchdeckCloseButton handleCloseModal={() => handleClickModalCloseButton()} />
              </Box>
            </Flex>

            <React.Fragment>
              {displayContent()}
            </React.Fragment>

          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )


}



const mapStateToProps = (state) => ({
  dsrDisplayLogoImages: state.dsrDisplayLogoImages.dsrDisplayLogos,
  joinPublicDsrOutcome: state.joinPublicDsrOutcome.results.data,
  joinPublicDsrIsProcessing: state.joinPublicDsrOutcome.isLoading,
	isMobileView: state.mobileViewMode.isMobileView
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      joinPublicDsr,
      resetJoinPublicDsr
    },
    dispatch,
  )

export default memo(withRouter(connect(mapStateToProps, mapDispatchToProps)(SignupLoginModal)))
