import * as types from '../actions/vault-actions-types';

// Get all DSRs belonging to this user
const INITIAL_STATE_GET_USER_VAULT_DSR = {
	isLoading: false,
	results: {},
	error: '',
};

export function getVaultQueriedDsrReducer(state = INITIAL_STATE_GET_USER_VAULT_DSR, action) {
	switch (action.type) {
		case types.GET_USER_VAULT_DSR_RESET:
			return INITIAL_STATE_GET_USER_VAULT_DSR;
		case types.GET_USER_VAULT_DSR_REQUEST:
			return { ...state, isLoading: true, results: {} };
		case types.GET_USER_VAULT_DSR_SUCCESS:
			return { ...state, isLoading: false, results: action.payload };
		case types.GET_USER_VAULT_DSR_FAILURE:
			return { ...state, isLoading: false, error: 'Error while trying to fetch all dsr from this user' };
		default:
			return state;
	}
}

// Get all found DSRs based on search input
const INITIAL_STATE_GET_VAULT_SEARCH_RESULTS = {
	isLoading: false,
	results: {},
	error: '',
};

export function getVaultSearchResultsReducer(state = INITIAL_STATE_GET_VAULT_SEARCH_RESULTS, action) {
	switch (action.type) {
		case types.VAULT_SEARCH_REQUEST:
			return { ...state, isLoading: true, results: {} };
		case types.VAULT_SEARCH_SUCCESS:
			return { ...state, isLoading: false, results: action.payload };
		case types.VAULT_SEARCH_FAILURE:
			return { ...state, isLoading: false, error: 'Error while trying to searching dsr based on search term' };
		default:
			return state;
	}
}

// Get all found Received DSRs based on search input
const INITIAL_STATE_GET_RECEIVED_ROOMS_SEARCH_RESULTS = {
	isLoading: false,
	results: {},
	error: '',
};

export function getReceivedRoomsSearchResultsReducer(state = INITIAL_STATE_GET_RECEIVED_ROOMS_SEARCH_RESULTS, action) {
	switch (action.type) {
		case types.RECEIVED_ROOMS_SEARCH_REQUEST:
			return { ...state, isLoading: true, results: {} };
		case types.RECEIVED_ROOMS_SEARCH_SUCCESS:
			return { ...state, isLoading: false, results: action.payload };
		case types.RECEIVED_ROOMS_SEARCH_FAILURE:
			return { ...state, isLoading: false, error: 'Error while trying to searching received dsr based on search term' };
		default:
			return state;
	}
}

// Get all found Received DSRs based on search input
const INITIAL_STATE_GET_PROSPECTOR_SEARCH_RESULTS = {
	isLoading: false,
	results: {},
	error: '',
};

export function getProspectorSearchResultsReducer(state = INITIAL_STATE_GET_PROSPECTOR_SEARCH_RESULTS, action) {
	switch (action.type) {
		case types.PROSPECTOR_SEARCH_REQUEST:
			return { ...state, isLoading: true, results: {} };
		case types.PROSPECTOR_SEARCH_SUCCESS:
			return { ...state, isLoading: false, results: action.payload };
		case types.PROSPECTOR_SEARCH_FAILURE:
			return { ...state, isLoading: false, error: 'Error while trying to searching received dsr based on search term' };
		default:
			return state;
	}
}
