import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';

import { Box, Button, Menu, MenuButton, MenuItem, MenuList, useToast } from '@chakra-ui/react';
import ClaverModalButtonBar from '../../../global/components/helper-components/components/ClaverModalButtonBar';
import { triggerLaunchdeckToast } from '../../../global/helpers/global-helpers';
import LaunchdeckFormInput from '../../../forms/TextInputField/components/LaunchdeckFormInput';
import { IoChevronDownSharp } from 'react-icons/io5';
import { manageExistingOrganization, resetManageExistingOrganization } from '../../actions/app-admin-actions';


function AppAdminEditOrgModule({
  orgIdToken, 
  orgName,
  orgSeats,
  orgStatus,
  orgLogo,
  orgStatusOptions,
  handleCloseModal,
  // Actions
  manageExistingOrganization,
  resetManageExistingOrganization,
  // Redux
  isMobileView,
  manageExistingOrgOutcome,
  manageOrgIsProcessing,
}) {


  const toast = useToast();

  const [changesMade, setChangesMade] = useState(false)
  const [orgNameDisplay, setOrgNameDisplay] = useState(orgName)
  const [orgSeatsDisplay, setOrgSeatsDisplay] = useState(orgSeats)
  const [chosenOrgStatus, setChosenOrgStatus] = useState(orgStatus)
  const [orgLogoLinkDisplay, setOrgLogoLinkDisplay] = useState(orgLogo)

  // UX & State Management
  useEffect(() => {
   
    if (manageExistingOrgOutcome) {
      const { caseProcessed, orgInfoUpdatedSuccessfully, unexpectedError } = manageExistingOrgOutcome || {};

      const closeModalAndReset = () => {
        handleCloseModal()
        resetManageExistingOrganization()
      }

      if (!!unexpectedError) {
        toast(
          triggerLaunchdeckToast({
            useCase: "show-error-state",
            bannerText: "Error",
            label: manageExistingOrgOutcome.errorMessage,
            isMobileView
          })
        )
        
        closeModalAndReset()
      }

      if (caseProcessed === "save-edit-org-info" && orgInfoUpdatedSuccessfully === true) {
   
        toast(
  				triggerLaunchdeckToast({
  					useCase: "show-success-state",
  					bannerText: "Success!",
  					label: `Organization info for ${orgNameDisplay} successfully updated`,
  					isMobileView
  				})
  			)

        closeModalAndReset()
      }
      else if (orgInfoUpdatedSuccessfully === false) {
   
        toast(
          triggerLaunchdeckToast({
            useCase: "show-error-state",
            bannerText: "Error",
            label: "Unable to update org user",
            isMobileView
          })
        )
        
        closeModalAndReset()
      }
    }

  }, [manageExistingOrgOutcome, handleCloseModal, toast]);



  // Component functionality
  const handleChange = (event) => {
    const value = event.target.value;
    const variableName = event.target.name;
 
    switch (variableName) {
      case ('orgName'): {
        setChangesMade(true)
        setOrgNameDisplay(value)
        break;
      }
      case ('orgSeats'): {
        setChangesMade(true)
        const integerValue = parseInt(value.trim())
        setOrgSeatsDisplay(integerValue ? integerValue : 0)
        break;
      }
      case ('orgStatus'): {
        setChangesMade(true)
        setChosenOrgStatus(value)
        break;
      }
      case ('orgLogo'): {
        setChangesMade(true)
        setOrgLogoLinkDisplay(value ? value.trim() : "")
        break;
      }
      default: {
        console.log('There is no variable with this name', variableName)
        break;
      }
    }
  }


  const handleEditOrgMember = () => {

    if (!!changesMade) {
      const validatedOrgSeats = parseInt(orgSeatsDisplay)
      const isScrubbed = Number.isInteger(validatedOrgSeats)

      if (isScrubbed) {
        manageExistingOrganization({
          useCase: "save-edit-org-info",
          manageExistingOrganizationPayload: {
            orgIdToken: orgIdToken,
            orgName: orgNameDisplay,
            orgSeats: validatedOrgSeats,
            orgStatus: chosenOrgStatus,
            orgLogo: orgLogoLinkDisplay
          }
        })
      }
     
    } else {
      toast(
        triggerLaunchdeckToast({
          useCase: "show-error-state",
          bannerText: "Nothing to save",
          label: "No changes made",
          isMobileView
        })
      )
    }
   
  }

  const renderOrgStatusOptions = () => {
    if (Array.isArray(orgStatusOptions)) {
      const options = orgStatusOptions?.map((option, optionIndex) => {
        return <MenuItem key={`org_member_status-option-${optionIndex}`}
          onClick={() => {
            setChosenOrgStatus(option)
            setChangesMade(true)
          }}
          fontSize='0.875em'
        >
          {option}
        </MenuItem>
      })

      return options;
    } else return null
  }

  return (
    <React.Fragment>
     
      <Box px='2em' mb='2em'>
        <Box>
          <Box mt='1em'>
            <LaunchdeckFormInput
              name={'orgName'}
              onChange={handleChange}
              label="Org Name"
              placeholder=""
              value={orgNameDisplay}
              // errorLogic={showMissingTitleError}
              // errorMessage={'Org Title cannot be left blank'}
            />
          </Box>

          <Box mt='1em'>
            <LaunchdeckFormInput
              name={'orgSeats'}
              onChange={handleChange}
              label="Org Seats"
              placeholder=""
              value={orgSeatsDisplay}
              // errorLogic={showMissingTitleError}
              // errorMessage={'Org Title cannot be left blank'}
            />
          </Box>

          <Box mt='1em'>
            <LaunchdeckFormInput
              name={'orgLogo'}
              onChange={handleChange}
              label="Org Logo"
              placeholder="e.g. https://logo.clearbit.com/www.website.com"
              value={orgLogoLinkDisplay}
              // errorLogic={showMissingTitleError}
              // errorMessage={'Org Title cannot be left blank'}
            />
          </Box>

          {orgStatusOptions &&
            <Box mt='1em' px='0.5em'>
              <Box fontSize='0.8em' fontWeight='600' mb='0.5em'>Org Status</Box>
              <Menu>
                <MenuButton as={Button} rightIcon={<IoChevronDownSharp />} fontSize='0.8em' p='1.2em 1em'>
                  {chosenOrgStatus}
                </MenuButton>
                <MenuList>
                  {renderOrgStatusOptions()}
                </MenuList>
              </Menu>
            </Box>
          }

        </Box>

      </Box>

      <ClaverModalButtonBar
        handleCloseModal={handleCloseModal}
        loadingLogic={manageOrgIsProcessing}
        ctaFunction={() => handleEditOrgMember()}
        ctaLabel='Save Changes'
        cancelLabel='Cancel'
      />

    </React.Fragment>
  )
}


function mapStateToProps(state) {
  return {
    manageExistingOrgOutcome: state.manageExistingOrgOutcome.results,
		manageOrgIsProcessing: state.manageExistingOrgOutcome.isLoading,
    isMobileView: state.mobileViewMode.isMobileView
  };
}


const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      manageExistingOrganization,
      resetManageExistingOrganization
    },
    dispatch,
  )

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppAdminEditOrgModule));