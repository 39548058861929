import * as types from '../actions/dsr-analytics-action-types';

const INITIAL_STATE_DSR_ANALYTICS = { dsrAnalytics: '' }

export function dsrAnalyticsReducer(state = INITIAL_STATE_DSR_ANALYTICS, action) {

    switch (action.type) {
			case types.GET_DSR_ANALYTICS_REQUEST:
				return { ...state, isLoading: true, results: {} };
			case types.GET_DSR_ANALYTICS_SUCCESS:
				return { ...state, isLoading: false, results: action.payload };
			case types.GET_DSR_ANALYTICS_FAILURE:
				return { ...state, isLoading: false, error: 'Error while trying to fetch dsr analytics' };
			default:
				return state;
    }
}

const INITIAL_STATE_DSR_ANALYTICS_DETAILS = { dsrAnalyticsDetails: '' }

export function dsrAnalyticsDetailsReducer(state = INITIAL_STATE_DSR_ANALYTICS_DETAILS, action) {

    switch (action.type) {
        case types.GET_DSR_ANALYTICS_CATEGORY_DETAILS_REQUEST:
			return { ...state, isLoading: true, results: {} };
		case types.GET_DSR_ANALYTICS_CATEGORY_DETAILS_SUCCESS:
			return { ...state, isLoading: false, results: action.payload };
		case types.GET_DSR_ANALYTICS_CATEGORY_DETAILS_FAILURE:
			return { ...state, isLoading: false, error: 'Error while trying to fetch dsr analytics details' };
		default:
			return state;
    }
}