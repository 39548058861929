import React from 'react';

import {
  Avatar,
  AvatarBadge,
  Box,
  Flex,
  Icon
} from "@chakra-ui/react";
import '../styles/dsr-display.scss';
import '../styles/dsr-invite-display.scss';

import LaunchdeckTag from '../../global/components/helper-components/components/LaunchdeckTag';
import { BiChevronsUp } from "react-icons/bi";


export default function DisplayDsrTeamMemberList({ dsrTeamMembers, isMobileView, showGuestList }) {

  let renderTeam = '';
  let teamToRender = '';
  let teamHeading = '';
  let displayGuestList = true;


  function renderTeamDisplay(perspective) {
    let membersRendered = 0;

    if (perspective === 'seller') {
      teamToRender = dsrTeamMembers ? dsrTeamMembers["sellerTeam"] : [];
      teamHeading = 'Hosts'

    } else if (perspective === 'buyer') {
      teamToRender = dsrTeamMembers ? dsrTeamMembers["buyerTeam"] : [];
      teamHeading = 'Guests'
      displayGuestList = typeof showGuestList === "boolean" && showGuestList;
    } else return null;


    renderTeam = teamToRender?.map((organization, organizationIndex) => {
      const { orgName, orgTeamMembers } = organization || {};

      const renderOrgMembers = Array.isArray(orgTeamMembers) && orgTeamMembers?.map((member, memberIndex) => {
        membersRendered = membersRendered + 1;

        if (membersRendered <= 3) {
          const { role, email, memberName, memberProfilePicSrc, memberStatus, memberOrgName, memberOrgTitle } = member || {};

          const isDsrAdmin = (role === "seller-lead") ? true : false
          const supplementaryUserInfo = (memberOrgTitle && memberOrgName) ? `${memberOrgTitle}, ${memberOrgName}` : memberOrgName ? memberOrgName : email;



          if (memberStatus === 'active' || memberStatus === 'pending') {
            return (
              <Flex key={`${perspective}_team_member_${memberIndex}`} justify='space-between' align='center' w='100%' my='1em'>
                <Flex align='center' justify='flex-start'>
                  <Avatar h='4.2em' w='4.2em' name={memberName} bg='gray.100' color='gray.700' src={memberProfilePicSrc ? memberProfilePicSrc : ""}>
                    {isDsrAdmin &&
                      <AvatarBadge boxSize="1.25em" borderColor="transparent" bg="white">
                        <Icon as={BiChevronsUp} w="20px" h="20px" color='brand.500' />
                      </AvatarBadge>
                    }
                  </Avatar>

                  <Box ml='1em'>
                    <Flex justify='flex-start' align='center'>
                      <Box className="truncate-long-text" maxW={isMobileView ? "12em" :"15em"} fontSize='0.875em' >{memberName}</Box>
                    </Flex>
                    <Box fontSize='0.7em' color='gray.500' textAlign='left'>{supplementaryUserInfo}</Box>
                  </Box>
                </Flex>

              </Flex>
            )
          } else if (memberStatus === 'invited') {
            return (
              <Flex key={`${perspective}_team_member_${memberIndex}`} justify='space-between' align='center' w='100%' mt='1em'>
                <Flex align='center' justify='flex-start'>
                  <Avatar h='4.2em' w='4.2em' name={""} bg='gray.100' color='gray.700' src={""} />
                  <Box ml='1em' textAlign={'left'}>
                    <Box fontSize='0.875em' className="truncate-long-text" maxW={isMobileView ? "12em" :"15em"} color='gray.500'>{email}</Box>
                    <Box fontSize='0.7em' color='gray.500' textAlign='left' fontStyle='italic'>Invited</Box>
                  </Box>
                </Flex>
              </Flex>
            )
          } else return null;
        } else return null;

      })

      return (
        <Box key={`${orgName}_team_member_${organizationIndex}`}>
          {renderOrgMembers}
        </Box>
      )

    }
    )

    if (!renderTeam) {
      return null
    }

    if (renderTeam.length === 0) {
      return null
    } else {

      const otherMembersCount = (membersRendered >= 3) ? membersRendered - 3 : 0

      if (membersRendered > 0) {
        if (displayGuestList) {
          return (
            <Box>
              <Box lineHeight='1.375em' color='gray.500' mt='1em' textAlign={['flex-start', 'flex-start', 'flex-start', 'flex-start']}>{teamHeading}</Box>
              <Box mt='0.5em' mb='1.5em' textAlign={['center', 'center', 'flex-start', 'flex-start']}>{renderTeam}</Box> 
        
              {(otherMembersCount > 0) &&
                <Flex w='100%' justify='center'>
                  <LaunchdeckTag fontSize='0.75em' bg='gray.50' color='brandDark.500' border='1px dotted' borderColor='gray.300'>
                    {perspective === 'seller' ? `+ ${otherMembersCount} other host(s)` : `+ ${otherMembersCount} other guest(s)`}
                  </LaunchdeckTag>
                </Flex>
              }
            </Box>
          );
        } else {
          return (
            <Box>
                       <LaunchdeckTag fontSize='0.75em' bg='gray.50' color='brandDark.500' border='1px dotted' borderColor='gray.300'>
                       with {membersRendered} other guests
                  </LaunchdeckTag>
            </Box>
          );
        }
        
      } else return null;
     
    }
  }


  return (
    <Box w='100%' py='1em'>
      <Box mb='1em' w='100%'>

        <Box mb='2em'>
          {renderTeamDisplay('seller')}
        </Box>

        <Box>
          {renderTeamDisplay('buyer')}
        </Box>
      </Box>
    </Box>

  )
}