import {Box, Flex} from "@chakra-ui/react";
import React from "react";

export function SharedDocPageTitle() {
  return (
    <Flex justify="space-between" align="center">
      <Box>
        <Box
          color="brandDark.500"
          fontSize="1.5em"
          lineHeight="1.4em"
          fontWeight="600"
        >
          Shared Documents
        </Box>
      </Box>
    </Flex>
  );
}