import React from 'react';
import { Spinner } from "@chakra-ui/react";


export default function LaunchdeckSpinner(props) {

	return (
		<Spinner
			thickness="2px"
			speed="0.65s"
			emptyColor="gray.200"
			color="brand.500"
			boxSize="1.2em"
			/>

	)
	
}
