import auth from "../../../config/app-specific-config/auth";
import { jwtDecode } from "jwt-decode";

export const isJWTExpired = async () => {
    try {
        const {exp} = jwtDecode(await auth.getToken())

        return exp <= Math.round(Date.now() / 1000);
    } catch (e) {
        console.log('isJWTExpired error')
        throw new Error(e?.message)
    }
}
