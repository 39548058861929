// import * as types from '../actions/users-profile-actions-types';


// DSR
// const INITIAL_USER_PROFILE_PICTURE_STATE = {
// 	isLoading: false,
// 	results: {},
// 	error: '',
// };

// export function setUserProfilePictureReducer(state = INITIAL_USER_PROFILE_PICTURE_STATE, action) {
// 	switch (action.type) {
// 		case types.SET_USER_PROFILE_PICTURE:
// 			return { ...state, isLoading: true, results: {} };
// 		case types.GET_DSR_USER_PROFILE_SUCCESS:
// 			return { ...state, isLoading: false, results: action.payload };
// 		case types.GET_DSR_USER_PROFILE_FAILURE:
// 			return { ...state, isLoading: false, error: 'Error while trying to fetch DSR user profile' };
// 		default:
// 			return state;
// 	}
// }


import {SET_USER_PROFILE_PICTURE} from '../actions/users-profile-actions-types';

const INITIAL_USER_PROFILE_PICTURE_STATE = { userProfilePicture: '' }

export function setUserProfilePictureReducer(state = INITIAL_USER_PROFILE_PICTURE_STATE, action) {
    switch (action.type) {
        case SET_USER_PROFILE_PICTURE:
            return { userProfilePicture: action.payload }
        default:
            return state;
    }
}