import React, { useState, memo } from 'react';

import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux';

import {
  Box, Flex, Modal, ModalOverlay, ModalBody, ModalContent,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  useDisclosure
} from '@chakra-ui/react';
import LaunchdeckCloseButton from '../../global/components/helper-components/components/LaunchdeckCloseButton.jsx';
import LaunchdeckButton from '../../global/components/helper-components/components/LaunchdeckButton.jsx'

import { manageSharedDoc } from '../actions/dsr-actions';
import FormTextInput from '../../forms/TextInputField/components/FormTextInput.jsx';
import { IoCloseOutline, IoDocumentText } from 'react-icons/io5';
import { FaFileCircleCheck } from 'react-icons/fa6';



function EditSharedDocModal(props) {
  const {
    dsrId,
    modalTransferPayload,
    handleCloseModal,
    // Actions
    manageSharedDoc,
    // Redux states
    isMobileView,
  } = props || {};


  // const toast = useToast();

  const { isOpen, onOpen, onClose, onToggle } = useDisclosure();

  const { editedSharedDocId, editedSharedDocName, editedSharedDocLink, editedSharedDocFileKey } = modalTransferPayload || {};

  // Local state
  const [sharedDocLink, setSharedDocLink] = useState(editedSharedDocFileKey ? "" : editedSharedDocLink)
  const [sharedDocName, setSharedDocName] = useState(editedSharedDocName)
  const [saveInProgress, setSaveInProgress] = useState(false)

  const handleSaveButton = () => {
    if (sharedDocName) {
      manageSharedDoc({
        dsrId: dsrId,
        manageEvent: "edit-shared-doc",
        docId: editedSharedDocId,
        editedParams: {
          updatedDocName: sharedDocName,
          ...sharedDocLink && { updatedDocLink: sharedDocLink }
        }
      })
    }

    setSaveInProgress(true)
  }

  const handleClickModalCloseButton = () => {
    handleCloseModal()
  }

  const handleChange = (event) => {

    if (event.target.name === "shareDocName") {
      setSharedDocName(event.target.value)
    }

    if (event.target.name === "shareDocLink") {
      setSharedDocLink(event.target.value)
    }


  }

  const renderContent = () => {
    return (<React.Fragment>
      <Flex w='100%' justify='center' mt='1em'>
        <Box as={IoDocumentText} boxSize="3em" color="brand.500" />
      </Flex>
      <Flex fontSize='1.25em' color='brandDark.500' fontWeight='600' mt='1em' mb='2em' textAlign='center'>
        <Box>Edit <i>{editedSharedDocName ? editedSharedDocName : 'shared document'}</i></Box>
      </Flex>


      <Box w="100%" align="center">

        <Flex w={isMobileView ? '100%' : '80%'} minW={!isMobileView && '30em'} direction='column' align='center' mb='2em' px='2em'>

          <Flex w='100%' my='1em'>

            <Flex justify='center' w='100%'>

              <Box w='100%' maxW={!isMobileView && '25em'} p={isMobileView ? '1em' : '1em 2em'} justify='center' rounded='0.25em' bg='gray.50' border='1px solid' borderColor='gray.300' mb={'2em'}>
                {/* <Text textAlign={'left'} mb='1em' fontSize='0.875em' fontWeight='600'>What to copy over</Text> */}
                <Flex w='100%' direction='column' justify='center' align='center' gap={4}>

                  <Box mb='1em' w='100%'>
                    <Box w='100%'>
                      <Box fontSize='0.875em' fontWeight='600' textAlign='left'>Document name</Box>
                      <Box w='100%'>
                        <FormTextInput name="shareDocName" htmlFor='shareDocName' label="" placeholder="" bg='white'
                          mb='0.2em' value={sharedDocName} onChange={handleChange} isDisabled={saveInProgress} />
                      </Box>

                    </Box>

                  </Box>

                  <Box mb='1em' w='100%'>
                    <Box w='100%'>
                      <Box fontSize='0.875em' fontWeight='600' textAlign='left'> {editedSharedDocFileKey ? "File" : "Link"}</Box>

                      {(editedSharedDocFileKey) ?
                        <Flex w='100%' mt='0.5em' justify='flex-start'>
                          <Flex position='relative' justify='flex-start' border='1px solid' borderColor='brandDark.500' p='0.875em' rounded='0.25em' maxW='20em'>
                            <Box position='absolute' top='0.5em' right='0.5em' mb='1em'>

                              <Popover
                                isOpen={isOpen}
                                // initialFocusRef={firstFieldRef}
                                onOpen={onOpen}
                                onClose={onClose}
                                placement="right"
                                closeOnBlur={false}
                                closeOnEsc
                              >
                                <PopoverTrigger>
                                  <Box>
                                    <Box as={IoCloseOutline} boxSize='0.875em' cursor='pointer'
                                      onClick={() => { onToggle() }}
                                    />
                                  </Box>
                                </PopoverTrigger>
                                <PopoverContent p={5} minW='20em' boxShadow={'xs'} ml='1em'>
                                  <PopoverArrow />
                                  <Box w='100%' >
                                    <Flex fontSize='0.875em' color='brandPink.500' fontWeight='600'>
                                      Replacing this file?
                                    </Flex>
                                    <Flex mt='1em' fontSize='0.8em' justify='flex-start' textAlign='left'>
                                      It's recommended to delete & create a new shared document.
                                    </Flex>

                                    <Flex mt='1.5em' fontSize='0.7em' fontWeight='600' color='gray.700'>
                                      Why?
                                    </Flex>
                                    <Flex mt='0.5em' fontSize='0.8em' justify='flex-start' textAlign='left' color='gray.500'>
                                      This provides clearer communications to Room members that a new document is available rather than quietly replaced.
                                    </Flex>
                                  </Box>
                                </PopoverContent>
                              </Popover>


                            </Box>

                            <Box>
                              <Box
                                fill="brandDark.500"
                                as={FaFileCircleCheck}
                                width="2.5em"
                                height="1.5em"
                                my="0.75em"
                              />
                              <Box fontSize='0.875em' maxW='15em' className='truncate-long-text'>{editedSharedDocName}</Box>
                            </Box>


                          </Flex>

                        </Flex>
                        :
                        <Box w='100%'>
                          <FormTextInput name="shareDocLink" htmlFor='shareDocLink' label="" placeholder="" bg='white'
                            mb='0.2em' value={sharedDocLink} onChange={handleChange} isDisabled={saveInProgress} />
                        </Box>
                      }


                    </Box>
                    {/* <Box textAlign='left' mt='0.2em' fontSize='0.6em' color='gray.500'>Locked pages indicates pages whose content are made unavailable temporarily</Box> */}
                  </Box>
                </Flex>

              </Box>
            </Flex>
          </Flex>

          <Flex w='100%'>

          </Flex>
        </Flex>

        <Flex justify='center' px='1em' py='0.625em' mb='1em' gap={8}>
          <LaunchdeckButton bg='brand.500' color='white' px='3em' label='Save' onClick={() => handleSaveButton()} loadingLogic={saveInProgress} />
        </Flex>

      </Box>
    </React.Fragment>)

    // }
  }




  const displayContent = () => {
    return (
      <Box>
        <Flex direction='column' mt='1em' justify='center' alignItems='center' px={isMobileView ? '1em' : '0.5em'} w='100%'>
          {renderContent()}
        </Flex>
      </Box>
    )
  }


  return (
    <Modal isOpen={true} closeOnOverlayClick={false} isCentered={true} >
      <ModalOverlay className="modal-background" />
      <ModalContent rounded='0.25em' minW={['90%', '500px']} maxW='700px'>
        <ModalBody px='0em'>
          <Flex direction='column' justify='space-around' alignItems='center' w='100%' p='1em'>
            <Flex justify='flex-end' w='100%'>

              <Box pr={isMobileView ? '0em' : '1.5em'}>
                <LaunchdeckCloseButton handleCloseModal={() => handleClickModalCloseButton()} />
              </Box>
            </Flex>

            <Box my='1em' border={'1px solid'} borderColor={'gray.100'} rounded='0.25em'>
              {displayContent()}
            </Box>

          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}


const mapStateToProps = (state) => ({
  isMobileView: state.mobileViewMode.isMobileView
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      manageSharedDoc
    },
    dispatch,
  )


export default memo(withRouter(connect(mapStateToProps, mapDispatchToProps)(EditSharedDocModal)))
