import * as types from '../actions/public-dsr-actions-types';


// Get Public DSR Config
const INITIAL_STATE_JOIN_PUBLIC_DSR = {
	isLoading: false,
	results: {},
	error: '',
};

export function joinPublicDsrReducer(state = INITIAL_STATE_JOIN_PUBLIC_DSR, action) {
	switch (action.type) {
		case types.JOIN_PUBLIC_DSR_RESET:
			return INITIAL_STATE_JOIN_PUBLIC_DSR;
		case types.JOIN_PUBLIC_DSR_REQUEST:
			return { ...state, isLoading: true, results: {} };
		case types.JOIN_PUBLIC_DSR_SUCCESS:
			return { ...state, isLoading: false, results: action.payload };
		case types.JOIN_PUBLIC_DSR_FAILURE:
			return { ...state, isLoading: false, error: 'Error while trying to help user join public dsr' };
		default:
			return state;
	}
}
