import React from 'react';
import { Box, Flex } from "@chakra-ui/react";
import '../../../../global/styles/global-components.scss';
import '../../../../global/styles/custom-animations.scss'
import {IoCloseSharp} from 'react-icons/io5'

export default function TempDisplayBanner(props) {

	const { isVisible, handleCloseThisBanner, displayMessage, w } = props || {};

	if (isVisible) {
		return (
			<Flex mt='1em' justify='space-between' align='center' className="temporary-banner fade-in" w={w ? w : '100%'}>
				<Box fontSize='0.875em'>{displayMessage}</Box>
				<Box cursor='pointer' onClick={()=> handleCloseThisBanner()}>
					<Box as={IoCloseSharp} boxSize='1em' />
				</Box>
			</Flex>
		) 
	} else return null
	
}
