import React, { useState, useEffect } from "react";
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import { Box, Flex, Tag, TagLabel, TagLeftIcon, Collapse, useDisclosure, Text } from "@chakra-ui/react";
import moment from 'moment';
import { getUserDetailsUsingDsrMembersArray } from '../../../profile/helpers/user-helper';
import DsrFaqTextAreaInput from "../../../forms/TextareaField/components/DsrFaqTextAreaInput";
import { getThisUserIdToken } from "../../../global/helpers/global-helpers";
import LaunchdeckButton from "../../../global/components/helper-components/components/LaunchdeckButton";
import { TiMessage } from "react-icons/ti";

function PendingFaqDisplay(props) {

  const { dsrId, dsrTeamMembers, isDsrAdmin, showPendingQuestions, pendingQuestions, dsrUserTeam, 
    createFaqAnswerOutcome, deleteFaqQuestionOutcome } = props || {};

  const { isOpen, onClose, onToggle } = useDisclosure()
  const [editQuestionMode, setEditQuestionMode] = useState(false)
  const [actOnQuestionId, setActOnQuestionId] = useState("")
  const [unfocusBackground, setUnfocusBackground] = useState(false)

  // Checks if current session user is a seller member. If yes, then render this. If not, don't return anything
  const isSellerTeam = (dsrUserTeam === "host") ? true : false;

useEffect(() => {
  if (createFaqAnswerOutcome) {
    const { faqAnswerSuccessfullyUpdated } = createFaqAnswerOutcome || {}
    if (faqAnswerSuccessfullyUpdated) {
      setActOnQuestionId("")
      onClose()
    }
    setUnfocusBackground(false)
  }
}, [createFaqAnswerOutcome])

  function handleClickCreateFaqAnswer(questionId) {
    onToggle()
    setActOnQuestionId(questionId)
    setUnfocusBackground(false)
  }

  function handleCloseInputArea() {
    setActOnQuestionId("")
    setEditQuestionMode(false)
    setUnfocusBackground(false)
    onClose()
  }

  function handleClickEditQuestion(questionId) {
    setEditQuestionMode(true)
    setActOnQuestionId(questionId)
    setUnfocusBackground(true)
    onClose()
  }

  function renderPendingQuestions() {
    const thisUserId = getThisUserIdToken();
    const faqSet = (pendingQuestions?.length > 0) && pendingQuestions.map((question, questionIndex) => {

      const {
        questionId, questionStatus, questionTextContent,
        questionCreatedAt, questionAuthorUserId } = question || {};
      const questionAuthorProfile = getUserDetailsUsingDsrMembersArray(dsrTeamMembers, questionAuthorUserId);
      const { memberName } = questionAuthorProfile || {};
      const isAuthor = (questionAuthorUserId === thisUserId) ? true : false;
      const isThisQuestion = (questionId === actOnQuestionId) ? true : false
      const unfocusThisQuestion = (editQuestionMode === true && !isThisQuestion) ? true : false;

      if (editQuestionMode === true && isThisQuestion) {
        return (
          <Box key={`qn_` + questionIndex + questionId} 
          bg={(unfocusBackground && isThisQuestion) ? 'gray.200' : 'white'} transition="background-color 0.8s ease"
          mb='2.6em' border='1px solid rgba(203, 213, 224, 0.5)' rounded='0.25em'>
            <DsrFaqTextAreaInput
              userActionRequired='edit-faq-question'
              name="editFaqQuestion"
              label="Edit"
              sublabel=""
              placeholder=""
              fontSize='1em'
              htmlFor="editFaqQuestion"
              handleCloseInputArea={handleCloseInputArea}
              dsrId={dsrId}
              questionId={actOnQuestionId}
              currentValue={questionTextContent}
              bg={(unfocusBackground && isThisQuestion) && 'white'}
            />
          </Box>
        )
      }

      if (questionStatus === 'active' && isSellerTeam === false) {
        return (
          <Box key={`qn_` + questionIndex + questionId} mb='2.6em' bg='white' p='1.2em 1.5em' opacity={unfocusThisQuestion ? "0.5" : "1.0"} border='1px solid rgba(203, 213, 224, 0.5)' rounded='0.25em'>
            <Box textAlign="left">
              <Box fontWeight='500' fontSize='1em' lineHeight='1.5em' color='brandDark.500'>{questionTextContent}</Box>
            </Box>
            <Box fontStyle='italic' fontSize='0.8em' fontWeight='400'>
              <Flex align='center' justify='flex-start' mt='0.2em'>
                <Text inline fontWeight='semibold'> {memberName}</Text>
                {questionCreatedAt && <Box fontWeight='500' ml='0.5em'> - {moment(questionCreatedAt).fromNow()}</Box>}
              </Flex>
              {(isAuthor || isDsrAdmin) && <><Text inline mx='0.5em'>•</Text><Text inline cursor='pointer' onClick={() => handleClickEditQuestion(questionId)}> Edit Question</Text></>}
            </Box>
          </Box>
        )
      }

      if (questionStatus === 'active' && isSellerTeam === true) {
        return (
          <Box key={`qn_` + questionIndex + questionId} mb='2.6em' 
          bg='white'
           p='1.2em 1.5em' 
          // bg={(isThisQuestion) ? 'gray.200' : 'white'} transition="background-color 0.8s ease"
          // opacity={unfocusThisQuestion ? "0.5" : "1.0"} 
          border='1px solid rgba(203, 213, 224, 0.5)' rounded='0.25em'>
            <Box textAlign="left">
              <Box fontWeight='500' fontSize='1em' lineHeight='1.5em' color='brandDark.500'>{questionTextContent}</Box>
            </Box>

            <Box fontStyle='italic' fontSize='0.8em' fontWeight='400'>
              <Flex align='center' justify='flex-start' mt='0.2em'>
                <Text inline fontWeight='semibold'> {memberName}</Text>
                {questionCreatedAt && <Box fontWeight='500' ml='0.5em'> - {moment(questionCreatedAt).fromNow()}</Box>}
              </Flex>

              <Flex justify='space-between' align='center' display={(actOnQuestionId) && 'none'} mt='2em'>
                {(isAuthor || isDsrAdmin) &&
                  <LaunchdeckButton
                    bg='gray.500' color='white'
                    onClick={() => handleClickEditQuestion(questionId)}
                    label='Edit Question' />
                }

                <LaunchdeckButton
                  mx='1em'
                  bg='brand.500' color='white'
                  onClick={() => handleClickCreateFaqAnswer(questionId)}
                  label='Answer' />
              </Flex>
            </Box>

<Box  mt='1em' >
            <Collapse in={(actOnQuestionId === questionId) && isOpen} animateOpacity>
              <Box
                p='0.5em 1em'
                color='white'
                bg='gray.200'
                rounded='0.25em'
                // roundedBottom='0.25em'
              >
                <DsrFaqTextAreaInput
                  userActionRequired='create-faq-answer'
                  name="createFaqAnswer"
                  label="Answer"
                  sublabel=""
                  placeholder={memberName ? `Provide an answer to ${memberName}` : `Provide an answer here`}
                  fontSize='0.875em'
                  htmlFor="createFaqAnswer"
                  handleCloseInputArea={handleCloseInputArea}
                  dsrId={dsrId}
                  questionId={actOnQuestionId}
                  bg={(unfocusBackground && isThisQuestion) && 'white'}
                />
              </Box>
            </Collapse>
            </Box>

          </Box>
        )
      }

      else return null;

    }
    )
    return faqSet
  }

  useEffect(() => {
    if (deleteFaqQuestionOutcome) {
      
      const { faqQuestionSuccessfullyUpdated } = deleteFaqQuestionOutcome || {}

      if (faqQuestionSuccessfullyUpdated) {
        handleCloseInputArea()
      }
    }
  }, [deleteFaqQuestionOutcome, handleCloseInputArea]);

  if (showPendingQuestions) {
    return (
      <React.Fragment>
        <Flex w='100%' bg='white' rounded='0.25em' border='1px solid' borderColor='gray.200' mb='0.5em' p='1em 1em'>
          <Tag bg='white' fontSize='1.2em' fontWeight='600' lineHeight='2.25em'  >
            <TagLeftIcon color='brandPink.500' as={TiMessage} />
            <TagLabel pl='0.5em'>Pending answers</TagLabel>
          </Tag>
        </Flex>
        <Box>{renderPendingQuestions()}</Box>
      </React.Fragment>
    );
  } else return null;

}




function mapStateToProps(state) {
  return {
    isDsrAdmin: state.dsrAdmin.isDsrAdmin,
    dsrUserTeam: state.dsrUserTeam.userTeam,
    deleteFaqQuestionOutcome: state.deleteFaqQuestionOutcome.results.data,
    createFaqAnswerOutcome: state.createFaqAnswerOutcome.results.data
  };
}


export default withRouter(connect(mapStateToProps, null)(PendingFaqDisplay));
