import React from 'react'
import { Box, Button } from "@chakra-ui/react";
import { optionTypes } from "../../dsr-draft/page-designer/types/subWidgetOptionsTypes";
import { openInNewTab } from '../../../global/helpers/global-helpers';

const PublishedDsrCtaButtonDisplay = ({ subWidgetContent, subWidgetOptions }) => {
  const ctaText = subWidgetContent ? subWidgetContent.split('/?&/')[0] : "CTA Button";
  const ctaLink = subWidgetContent ? subWidgetContent.split('/?&/')[1] : "https://www.google.com";
  let buttonAlign = subWidgetOptions[optionTypes.ALIGN];
  let buttonVariant = subWidgetOptions[optionTypes.VARIANT];

  
  return (
    <Box display='flex' justifyContent={buttonAlign ? buttonAlign : 'center'} padding="10px">
      <Button
        onClick={() => ctaLink && openInNewTab(ctaLink)}
        bg={buttonVariant === 'primary' ? 'brandDark.500' : '#EAF4FD'}
        color={buttonVariant === 'primary' ? 'white' : '#3295EC'}
        _hover={{ color: "brandDark.500", bg: "gray.200" }}
        fontSize='0.875em'
        rounded='0.85em'
        px='5em'
        height="50px"
      >
        {ctaText}
      </Button>
    </Box>
  )
}

export default PublishedDsrCtaButtonDisplay;
