import { Box } from "@chakra-ui/react";
import React, { forwardRef } from "react";

export const ButtonWithIcon = forwardRef(
  ({ icon, label, disabled, mode, onClick }, ref) => {
    const revertOrder = mode === "create-post-button";

    return (
      <Box
        as="button"
        opacity={disabled ? 0.5 : 1}
        onClick={onClick}
        ref={ref}
        className="loom-purple-button"
      >
        {revertOrder ? (
          <>
            <Box as={icon} boxSize="1.5em" />
            <Box fontSize="0.875em">{label}</Box>
          </>
        ) : (
          <>
            <Box fontSize="0.875em">{label}</Box>
            <Box as={icon} boxSize="1.5em" />
          </>
        )}
      </Box>
    );
  },
);
