import React from "react";
import { Box, Textarea} from "@chakra-ui/react";

export default function FormTextAreaInput(props) {
 
  const {name, label, sublabel, htmlFor, type, defaultValue, charLimit, isDisabled, placeholder, fontSize} = props;
 
  return (
    <Box htmlFor={htmlFor} w='100%'
    // mb='2.2em' 
    >
      
      <Box fontSize='1em' lineHeight='1.375em' fontWeight='500' color='gray.700'> 
        {label}
      </Box>
      {sublabel && <Box fontSize='0.8em' lineHeight='1.25em' mt='0.2em' fontWeight='400' color='gray.500'> 
        {sublabel} 
      </Box>
      }

      <Box mt='0.625em'>
        <Textarea 
          name={name} 
          variant={'outline'} 
          defaultValue={defaultValue && defaultValue}
          // value={defaultValue}
          placeholder={placeholder && placeholder}
          type={type} 
          isDisabled={isDisabled}
          className='white-space-preline'
          maxLength={charLimit}
          w='100%' 
          h='2.5em' 
          fontSize={ fontSize ? fontSize :'1em' }
          lineHeight='1.375em' 
          rounded ='0.25em' 
          onChange={(event) => props.onChange(event)}/>
          
          {/* {charLimit && <Flex justify='flex-end' w='100%' color='gray.500' fontSize='0.7em' pt='0.2em'>
            {currentCharacters ? currentCharacters : "0"} / {charLimit} characters
          </Flex>
          } */}
      </Box>

    </Box>
  );
  
}


