import React from "react";
// import { connect } from 'react-redux';
// import { withRouter } from 'react-router';
// import { bindActionCreators } from 'redux';

import { Button } from '@chakra-ui/react';

import { Box, Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";
import { GoKebabHorizontal } from "react-icons/go";


export default function DsrMessageOptionsButton(props) {
  
  const { 
    onClickDeleteMessageOption,
    confirmationType,
    postId,
    handleEditPost,
    isPostAuthor
   } = props;

   const editPostPayload = {
    postId, 
    userActionRequired: 'edit-dsr-message-post'
   }
    
   if (isPostAuthor) {
    return (
      <React.Fragment>
        <Menu closeOnSelect={true} >
          <MenuButton as={Button} rounded='0.25em' bg='white' px='1em' ml='2em'>
            <Box as={GoKebabHorizontal} boxSize="2.5rem" py='3%' />
          </MenuButton>
          <MenuList zIndex={2}  minWidth="15em" mr={['3em','8em','8em','8em']}>
        
            <Box > <MenuItem name='showEndAgreementModal' className='filter-menu__dropdown-option-display' onClick={()=>handleEditPost(editPostPayload)}  >Edit</MenuItem> </Box>
            <Box > <MenuItem name='showEndAgreementModal' className='filter-menu__dropdown-option-display' onClick={()=>onClickDeleteMessageOption({postId}, confirmationType)}  >Delete</MenuItem> </Box>
            
          </MenuList>
        </Menu>

        
      </React.Fragment>
    );
   } else {
    return null
   }
   
  
}
