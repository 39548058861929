import * as types from '../../actions/manage-dsr-member-action-types';



const INITIAL_CHECK_IF_EXISTING_USER_STATE = {
	isLoading: false,
	results: {},
	error: '',
};

export function checkIfExistingUserReducer(state = INITIAL_CHECK_IF_EXISTING_USER_STATE, action) {
	switch (action.type) {
		case types.CHECK_IF_EXISTING_USER_RESET:
			return INITIAL_CHECK_IF_EXISTING_USER_STATE;
		case types.CHECK_IF_EXISTING_USER_REQUEST:
			return { ...state, isLoading: true, results: {} };
		case types.CHECK_IF_EXISTING_USER_SUCCESS:
			return { ...state, isLoading: false, results: action.payload };
		case types.CHECK_IF_EXISTING_USER_FAILURE:
			return { ...state, isLoading: false, error: 'Error checking existing user status' };
		default:
			return state;
	}
}
