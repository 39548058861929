import React, { useRef } from 'react';
import { Box } from '@chakra-ui/react';
import {FiEdit} from 'react-icons/fi';


export default function UserProfileImageUploadButton(props) {
    const hiddenFileInput = useRef(null)
    const {onChangeUploadPicture} = props;
    const {uploadProfilePictureInProgress} = props;

    function handleClick(event) {
      hiddenFileInput.current.click();
    }
    
    if (uploadProfilePictureInProgress) {
      return null
    } else {
      return (
        <Box bg='gray.50' rounded='50%' position='absolute' bottom='-0.5em' right='-0.5em' zIndex='10' p='0.5em' cursor='pointer' onClick={handleClick} >
            <Box as={FiEdit} boxSize='1em' rounded='50%' color='brand.500'/>
            <input
              type="file"
              title="&nbsp;"
              ref={hiddenFileInput}
              name="profilePicture"
              onChange={onChangeUploadPicture}
              style={{display: 'none'}}
            />
  
        </Box>
      )
    }
          
}
