import { Flex } from "@chakra-ui/react";
import CreateSharedDocForm from "./CreateSharedDocForm";
import React from "react";

export const CreateSharedDoc = ({
  dsrId,
  isMobileView,
  setShowAddDocumentForm,
}) => {
  return (
    <Flex
      w="100%"
      className="shared-doc-table-container"
      justify="space-between"
      align="center"
      bg={isMobileView && "brand.50"}
      roundedTopLeft={isMobileView && "0.3em"}
      roundedTopRight={isMobileView && "0.3em"}
    >
      <CreateSharedDocForm
        dsrId={dsrId}
        handleCloseForm={() => setShowAddDocumentForm(false)}
      />
    </Flex>
  );
};
