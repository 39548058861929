import React from 'react';
import { Flex, CircularProgress, CircularProgressLabel } from "@chakra-ui/react";
import { displayEmoji } from '../../digital-sales-room/helpers/dsr-helpers';


export default function DsrInterestLevelDisplay({ buyerEngagementLevel }) {

  let displayedEmoji = '';
  // let hovertext = '';
  let progressValue = 0;
  let progressColor = '';

  switch (buyerEngagementLevel) {
    case ('low'): {
      displayedEmoji = 'cold'
      // hovertext = 'Low audience engagement'
      progressValue = 20;
      progressColor = 'gray.500'
      break;
    }
    case ('high'): {
      displayedEmoji = 'fire'
      // hovertext = 'High audience engagement'
      progressValue = 70;
      progressColor = 'brandGreen.500'
      break;
    }
    case ('intent-to-buy'): {
      displayedEmoji = 'target'
      // hovertext = 'Your audience seems likely to convert'
      progressValue = 90;
      progressColor = 'brand.500'
      break;
    }
    default: {
      break;
    }
  }

  return (
    // <Tooltip label={hovertext && hovertext} fontSize='0.8em' fontWeight='400' placement="bottom" bg="#4A5568" rounded='0.25em'>
    <Flex justify='center' align='center'>
      <CircularProgress value={progressValue} color={progressColor} size='1.5em'>
        {displayedEmoji && <CircularProgressLabel fontSize='0.75em' >  {displayEmoji(displayedEmoji)}</CircularProgressLabel>}
      </CircularProgress>
    </Flex>
    // </Tooltip>
  )
}