import React from 'react';
import { Box, Text} from '@chakra-ui/react';
import { Link } from 'react-router-dom';


const PrivacyTos = () => {
	return (
	
			<Box>
				<Link to={'/privacy-terms-of-service'}>
					<Text textAlign='center' color='brand.500' mb='0.7em' fontSize={['0.75em']}> Privacy • Terms of Service</Text>	
				</Link>
				<Text fontSize='0.6em' fontWeight='300' textAlign='center'>By signing up for a Launchdeck account and using its services, you will be accepting its Terms of Service. </Text>
			</Box>
	);
};

export default PrivacyTos;
