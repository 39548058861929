import React from 'react';
import { Box } from '@chakra-ui/react';
import { FiEdit } from 'react-icons/fi';

export default function OrgLogoImageUploadButton(props) {
  const { onClickEditOrgLogoButton } = props;

  return (
    <Box bg='gray.50' rounded='50%' position='absolute' bottom='-0.5em' right='-0.5em' zIndex='10' p='0.5em' cursor='pointer' onClick={onClickEditOrgLogoButton} >
      <Box as={FiEdit} boxSize='1em' rounded='50%' color='brand.500' />
    </Box>
  )

}