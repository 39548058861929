import React from "react";
import { Box, Flex, Textarea} from "@chakra-ui/react";

export default function ProfileFormTextAreaInput(props) {
 
  const {name, label, subLabel, htmlFor, variant, type, value, charLimit, isDisabled} = props;
  const displayedVariant = variant ? variant : 'outline';
 
  return (
    <Box w='100%' mt='1.5em' htmlFor={htmlFor}>
      
      <Box fontSize='0.875em' lineHeight='1.375em' fontWeight='500' color='gray.700'> 
        {label} 
      </Box>
      {subLabel && <Box fontSize='0.875em' lineHeight='1.25em' mt='0.2em' fontWeight='400' color='gray.500'> 
        {subLabel} 
      </Box>
      }
      <Box mt='0.625em'>
        <Textarea 
          name={name} 
          variant={displayedVariant} 
          value={value}
          type={type} 
          isDisabled={isDisabled}
          className='white-space-preline'
          maxLength={charLimit}
          w='100%' 
          h='2.5em' 
          fontSize='0.875em' 
          lineHeight='1.375em' 
          rounded ='0.25em' 
          onChange={(event) => props.onChange(event)}/>
          
          {charLimit && <Flex justify='flex-end' w='100%' color='gray.500' fontSize='0.7em' pt='0.2em'>
            {value ? value.length : "0"} / {charLimit} characters
          </Flex>
          }
      </Box>
    </Box>
  );
  
}


