import * as types from '../actions/app-admin-action-types';

const INITIAL_CREATE_GENESIS_USER_ORG_STATE = {
	isLoading: false,
	results: {},
	error: '',
};

export function createGenesisUserReducer(state = INITIAL_CREATE_GENESIS_USER_ORG_STATE, action) {
	switch (action.type) {
		case types.CREATE_GENESIS_USER_RESET:
			return INITIAL_CREATE_GENESIS_USER_ORG_STATE;
		case types.CREATE_GENESIS_USER_REQUEST:
			return { ...state, isLoading: true, results: {} };
		case types.CREATE_GENESIS_USER_SUCCESS:
			return { ...state, isLoading: false, results: action.payload };
		case types.CREATE_GENESIS_USER_FAILURE:
			return { ...state, isLoading: false, error: 'Error getting admin user org profile.' };
		default:
			return state;
	}
}


// Offboardin user
const INITIAL_OFFBOARD_USER_ORG_STATE = {
	isLoading: false,
	results: {},
	error: '',
};

export function offboardUserReducer(state = INITIAL_OFFBOARD_USER_ORG_STATE, action) {
	switch (action.type) {
		case types.OFFBOARD_USER_RESET:
			return INITIAL_OFFBOARD_USER_ORG_STATE;
		case types.OFFBOARD_USER_REQUEST:
			return { ...state, isLoading: true, results: {} };
		case types.OFFBOARD_USER_SUCCESS:
			return { ...state, isLoading: false, results: action.payload };
		case types.OFFBOARD_USER_FAILURE:
			return { ...state, isLoading: false, error: 'Error offboarding user' };
		default:
			return state;
	}
}


// Update Genesis Users 
const INITIAL_EDIT_GENESIS_USER_STATE = {
	isLoading: false,
	results: {},
	error: '',
};

export function editGenesisUserReducer(state = INITIAL_EDIT_GENESIS_USER_STATE, action) {
	switch (action.type) {
// 		// Create
		case types.EDIT_GENESIS_USER_RESET:
			return INITIAL_EDIT_GENESIS_USER_STATE;
// 			// Update
		case types.EDIT_GENESIS_USER_REQUEST:
			return { ...state, isLoading: true, results: {} };
		case types.EDIT_GENESIS_USER_SUCCESS:
			return { ...state, isLoading: false, results: action.payload };
		case types.EDIT_GENESIS_USER_FAILURE:
			return { ...state, isLoading: false, error: 'Error editing genesis user' };
		default:
			return state;
	}
}


const INITIAL_CREATE_NEW_ORG_FOR_EXISTING_USER_STATE = {
	isLoading: false,
	results: {},
	error: '',
};

export function createNewOrgForExistingUserReducer(state = INITIAL_CREATE_NEW_ORG_FOR_EXISTING_USER_STATE, action) {
	switch (action.type) {
		case types.CREATE_NEW_ORG_FOR_EXISTING_USER_RESET:
			return INITIAL_CREATE_NEW_ORG_FOR_EXISTING_USER_STATE;
		case types.CREATE_NEW_ORG_FOR_EXISTING_USER_REQUEST:
			return { ...state, isLoading: true, results: {} };
		case types.CREATE_NEW_ORG_FOR_EXISTING_USER_SUCCESS:
			return { ...state, isLoading: false, results: action.payload };
		case types.CREATE_NEW_ORG_FOR_EXISTING_USER_FAILURE:
			return { ...state, isLoading: false, error: 'Error creating new org for existing user' };
		default:
			return state;
	}
}


// Update Genesis Users 
const INITIAL_MANAGE_EXISTING_USER_STATE = {
	isLoading: false,
	results: {},
	error: '',
};

export function manageExistingUserReducer(state = INITIAL_MANAGE_EXISTING_USER_STATE, action) {
	switch (action.type) {
		case types.EDIT_EXISTING_USER_RESET:
			return INITIAL_MANAGE_EXISTING_USER_STATE;
		case types.EDIT_EXISTING_USER_REQUEST:
			return { ...state, isLoading: true, results: {} };
		case types.EDIT_EXISTING_USER_SUCCESS:
			return { ...state, isLoading: false, results: action.payload };
		case types.EDIT_EXISTING_USER_FAILURE:
			return { ...state, isLoading: false, error: 'Error managing existing user - SAA' };
		default:
			return state;
	}
}


// Manage existing Organization
const INITIAL_MANAGE_EXISTING_ORG_STATE = {
	isLoading: false,
	results: {},
	error: '',
};

export function manageExistingOrgReducer(state = INITIAL_MANAGE_EXISTING_ORG_STATE, action) {
	switch (action.type) {
		case types.EDIT_EXISTING_ORGANIZATION_RESET:
			return INITIAL_MANAGE_EXISTING_ORG_STATE;
		case types.EDIT_EXISTING_ORGANIZATION_REQUEST:
			return { ...state, isLoading: true, results: {} };
		case types.EDIT_EXISTING_ORGANIZATION_SUCCESS:
			return { ...state, isLoading: false, results: action.payload };
		case types.EDIT_EXISTING_ORGANIZATION_FAILURE:
			return { ...state, isLoading: false, error: 'Error editing existing org' };
		default:
			return state;
	}
}