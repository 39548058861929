import React, { useState, useEffect, useRef } from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { GoChevronDown } from 'react-icons/go'

import {
  Box, Text, Flex, Avatar, Button, Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useToast,
  useOutsideClick,
  Alert,
  AlertIcon
} from "@chakra-ui/react";
import { getThisUserIdToken, triggerLaunchdeckToast } from '../../global/helpers/global-helpers';
import { editDsrTeamMembers, resetEditDsrTeamMembers } from '../actions/dsr-actions';
import { checkIfExistingUser, resetCheckIfExistingUser } from '../actions/manage-dsr-member-actions';
import PageLoader from '../../global/components/helper-components/components/PageLoader';
import LaunchdeckButton from '../../global/components/helper-components/components/LaunchdeckButton';

import { getUserProfile } from '../../profile/actions/users-profile-actions';
import { getUserDetailsUsingDsrMembersArray } from '../../profile/helpers/user-helper';
import { displayUserTeamRolePermissions } from '../helpers/dsr-helpers';


function DsrAddInternalTeamMemberForm(props) {
  const { dsrId, editDsrTeamMembersOutcome, dsrUserTeam,
    isDsrAdmin,
    editingTeamInProgress, handleCloseModal,
    editDsrTeamMembers,
    resetEditDsrTeamMembers,
    checkIfExistingUser,
    orgActiveTeamMembers,
    getUserProfile,
    checkIfExistingUserOutcome,
    resetCheckIfExistingUser,
    isMobileView,
    dsrTeamMembersRedux
  } = props;

  const toast = useToast();
  // const { updatedTeamMembers } = editDsrTeamMembersOutcome || {};

  // Local States
  const [memberCardLocalState, setMemberCardLocalState] = useState({ nextActionPath: "" })

  // Inputs
  const [emailInput, setEmailInput] = useState("")


  const [teamNameInputDisplay, setTeamNameInputDisplay] = useState("")
  const [updatedTeamStatus, setUpdatedTeamStatus] = useState(null)

  // Validation
  const [showIncompleteFormError, setShowIncompleteFormError] = useState(null)
  const [allInputsValidated, setAllInputsValidated] = useState(null)

  // UX management
  const [teamInput, setTeamInput] = useState("")
  const [showPickTeamDropdown, setShowPickTeamDropdown] = useState(false)
  const [buttonType, setbuttonType] = useState('proceed') // Possible values: 'proceed' & 'start-invite' & 'no-further-steps'

  const [showMemberCard, setShowMemberCard] = useState(false)

  const [chosenRole, setChosenRole] = useState("") // Possible Values: "" / "Member" / "Admin"
  const [showPickRoleDropdown, setShowPickRoleDropdown] = useState(false)

  const [teamMemberPicked, setTeamMemberPicked] = useState("")
  const [isAlreadyDsrMember, setIsAlreadyDsrMember] = useState("")

  const [isAddingUser, setIsAddingUser] = useState(false)

  const orgTeamMembersRef = useRef(null)
  const teamPickerDropdownRef = useRef(null);
  const rolePickerDropdownRef = useRef(null);

  useOutsideClick({
    ref: teamPickerDropdownRef,
    handler: () => setShowPickTeamDropdown(false),
  })

  useOutsideClick({
    ref: rolePickerDropdownRef,
    handler: () => setShowPickRoleDropdown(false),
  })


  useEffect(() => {
    // Business Logic: If user is in Audience/Buyer team, they can only invite other users to the Audience / Buyer team
    // There will be no inputs to determine which team to add for Audience
    // This is required to pass validation checks 
    if (dsrUserTeam === 'audience') {
      setTeamInput('buyer-team')
    }

  }, [dsrUserTeam]);

  useEffect(() => {
    // Business Logic: Validate & allow user to click on Add user button
    if ((teamMemberPicked && teamInput === "seller-team" && chosenRole) || (teamMemberPicked && teamInput === "buyer-team")) {
      setAllInputsValidated(true)

    } else {
      setAllInputsValidated(false)
      setbuttonType('proceed')
    }
  }, [teamMemberPicked, teamInput, chosenRole]);


  useEffect(() => {
    if (editDsrTeamMembersOutcome) {
      const {updatedTeamMembers} = editDsrTeamMembersOutcome || {}

      if (updatedTeamMembers) {
        toast(
          triggerLaunchdeckToast({
            useCase: "show-success-state",
            label: "Your co-worker has been added to the Room successfully",
            isMobileView
          })
        )

        resetForm()

      } else if (!updatedTeamMembers) {
        // Case: Duplicated email user
        setUpdatedTeamStatus(updatedTeamMembers)
      }

      resetEditDsrTeamMembers()
    }
  }, [editDsrTeamMembersOutcome, resetEditDsrTeamMembers]);

  useEffect(() => {
    if (checkIfExistingUserOutcome) {
      const { nextActionPath, userCardData } = checkIfExistingUserOutcome || {};

      setMemberCardLocalState({
        ...memberCardLocalState,
        nextActionPath,
        ...userCardData && { userCardData }
      })
      
      if (nextActionPath === "show-cannot-add-user-card") {
        
        toast(
          triggerLaunchdeckToast({
          useCase: "show-error-state",
          duration: "9000",
          bannerText: "User cannot be added to this Room",
          label: "This user has been deactivated or has left their organization", 
          isMobileView
          })
        )

        resetForm()

      } else {
        if (dsrTeamMembersRedux && userCardData) {
          const isDsrMember = getUserDetailsUsingDsrMembersArray(dsrTeamMembersRedux, userCardData.memberIdToken)
          setIsAlreadyDsrMember(isDsrMember)
          setbuttonType(isDsrMember ? 'no-further-steps' : 'start-invite')
        }
  
        setShowMemberCard(true)
      
      }

      resetCheckIfExistingUser()
    }
  }, [checkIfExistingUserOutcome, resetCheckIfExistingUser, memberCardLocalState, dsrTeamMembersRedux])

  useEffect(() => {
    if (!orgActiveTeamMembers && !orgTeamMembersRef.current) {
      getUserProfile({ getOrgTeamMembers: true })
      orgTeamMembersRef.current = true
    }

  }, [orgActiveTeamMembers])


  const renderMemberCard = () => {
    if (showMemberCard) {
      const { userCardData } = memberCardLocalState || {};
      const { memberFirstName, memberLastName, memberEmail, memberProfilePicSrc, memberOrgName, memberOrgTitle } = userCardData || {};

      const credentials = (memberOrgName && memberOrgTitle) ? memberOrgTitle + ', ' + memberOrgName : memberOrgName ? memberOrgName : memberEmail ? memberEmail : emailInput;
      const memberFullName = (memberFirstName && memberLastName) ? (memberFirstName + ' ' + memberLastName).trim() : memberFirstName ? memberFirstName.trim() : memberLastName ? memberLastName.trim() : "";
      return (
        <>
          <Flex justify="flex-start" align='center' className='flip-in-hor-bottom' >
            {memberProfilePicSrc ?
              <Avatar width='3.8em' height='3.8em' bg='gray.100' color='gray.700' src={memberProfilePicSrc} />
              :
              <Box boxSize='2.6em' rounded='100%' border='1px dashed' borderColor='gray.500' />
            }
            <Box ml='1em'>
              <Box fontSize='0.875em' lineHeight='1.375em' fontWeight='500' color='brandDark.500'>{memberFullName && memberFullName}</Box>
              <Box fontSize='0.75em' lineHeight='1em' fontWeight='400' color='gray.500'>{credentials}</Box>
            </Box>
          </Flex>

          {isAlreadyDsrMember &&
            <Alert status='error' fontSize='0.875em' mt='2em' rounded='0.25em'>
              <AlertIcon />
              {memberFirstName ? memberFirstName : memberEmail} is already a member of this Room
            </Alert>
          }
        </>
      )
    }
  }


  const resetForm = () => {
    setEmailInput("")
    setTeamInput("")
    setTeamNameInputDisplay("")
    setChosenRole("")
    setbuttonType("proceed")
    setShowMemberCard(false)
    setMemberCardLocalState({ nextActionPath: "" })

    setTeamMemberPicked("")
    setIsAlreadyDsrMember("")
    setIsAddingUser(false)
  }


  const handleAddMemberButton = () => {

    if (allInputsValidated === true && teamMemberPicked) {
      const updateParams = {
        teamToUpdate: teamInput,
        updateAction: 'add-member',
        inviteeEmail: teamMemberPicked.email,
        chosenRole: chosenRole ? chosenRole : 'Member', // defaults to Member (no input required for Guests adding other guests) 
        firstName: teamMemberPicked.memberFirstName,
        lastName: teamMemberPicked.memberLastName,
        userId: teamMemberPicked.userId
      }

      handleEditDsrMembers(updateParams);

      // UUU UX: Then loading state, show adding is successful, and have a state that shows successful, OK button to bring back to team tab to show pending invite user
      setIsAddingUser(true)
    }

  }

  // UX: Pick Team
  const handlePickTeamOption = (teamToUpdate) => {
    setTeamInput(teamToUpdate);

    if (teamToUpdate === 'buyer-team') {
      setTeamNameInputDisplay("Guests")
      setChosenRole(isDsrAdmin ? "" : "Member")
      setShowPickRoleDropdown(false)
    } else if (teamToUpdate === 'seller-team') {
      setTeamNameInputDisplay("Hosts")
      setChosenRole(isDsrAdmin ? "" : "Member")
    }

    setShowIncompleteFormError(false);
  }

  const handlePickRoleOption = (pickedRole) => {
    setChosenRole(pickedRole)
  }

  const handlePickOrgTeamMemberOption = (pickedMember) => {
    setTeamMemberPicked(pickedMember)
  }

  const handleClickProceedButton = () => {
    if (teamMemberPicked) {
      checkIfExistingUser(teamMemberPicked.email)
    }

  }

  const displayDuplicateMemberDetected = () => {
    const handleButtonClick = () => {
      handleCloseModal()
      setUpdatedTeamStatus("")
      resetEditDsrTeamMembers()
    }

    return (
      <Box my='1em' w='100%' bg='gray.50' rounded="0.25em" p='1em'>
        <Box textAlign={'center'}>{teamMemberPicked.memberName} has already joined this Room. You won't have to do anything else!</Box>
        <Flex w='100%' justify='center' mt='1.5em'>
          <LaunchdeckButton bg='brand.500' color='white' onClick={() => handleButtonClick()} label='OK' />
        </Flex>
      </Box>
    )
  }

  const handleEditDsrMembers = async (updateParams) => {

    const updateTeamParams = { dsrId, updateParams }
    await editDsrTeamMembers(updateTeamParams);
  }

  const renderOrgTeamMemberPicker = () => {
    const renderMemberOptionCards = () => {
      const thisUserIdToken = getThisUserIdToken();

      const optionsAvailable = Array.isArray(orgActiveTeamMembers) && orgActiveTeamMembers.filter(user => user.userId !== thisUserIdToken).map((member, memberIndex) => {
        const { userId, memberName, memberProfilePicSrc } = member || {}

        return (
          <MenuItem key={`org_team_member_${memberIndex}${userId}`} minH='100%' p='0.5em 1em' gap={8} onClick={() => handlePickOrgTeamMemberOption(member)}>
            <Avatar h="2.6em" w="2.6em" name={memberName} bg='gray.100' color='gray.700' src={memberProfilePicSrc} />
            <span><Box fontSize='0.875em' >{memberName}</Box> </span>
          </MenuItem>
        )
      }

      )

      return optionsAvailable
    }

    return (
      <>
        <Box mb='0.55em' lineHeight='1.275em' fontWeight='500' fontSize='0.875em'>Co-worker</Box>
        <Menu >
          <MenuButton as={Button} fontSize='0.875em' fontWeight='400' justify='space-between' rightIcon={<GoChevronDown />}
            bg='white'
            border='1px solid #E2E8F0'
            rounded='0.25em'
            height='2.6em'
            minW='18em'
            _hover={{ bg: "none" }}
          >

            <Flex justify='flex-start' align='center' gap={8}>
              {teamMemberPicked && <Avatar h="3em" w="3em" name={teamMemberPicked.memberName} bg='gray.100' color='gray.700' src={teamMemberPicked.memberProfilePicSrc} />}
              <span><Box fontSize='1em' p='4px 16px 4px 4px' color={teamMemberPicked ? 'brandDark.500' : 'gray.300'}> {teamMemberPicked ? teamMemberPicked.memberName : "Pick one"}</Box> </span>
            </Flex>

          </MenuButton>
          <MenuList minW='16em' >

            {renderMemberOptionCards()}

          </MenuList>
        </Menu>

      </>
    )
  }



  const renderCtaButton = () => {
    const className = allInputsValidated ? "invite-form-button__activated" : "invite-form-button__deactivated"

    switch (buttonType) {
      case ('proceed'): {
        return (
          <Box className={className} onClick={() => allInputsValidated && handleClickProceedButton()}>
            <Box fontWeight='500' lineHeight='1.5em' fontSize='0.875em'>Next</Box>
          </Box>
        )
      }
      case ('start-invite'): {
        return (
          <Flex>
            <LaunchdeckButton noHover bg='gray.200' color='brandDark.500' onClick={() => resetForm()} label='Back' />
            <Box mx='0.5em' />
            <LaunchdeckButton bg='brand.500' color='white' loadingLogic={isAddingUser} onClick={() => allInputsValidated && handleAddMemberButton()} label='Add to Room' />
          </Flex>
        )
      }
      case ('no-further-steps'): {
        return (
          <Flex>
            <LaunchdeckButton noHover bg='brand.500' color='white' onClick={() => resetForm()} label='OK' />
          </Flex>
        )
      }
      default: {
        return null;
      }
    }
  }

  if (editingTeamInProgress) {
    return <PageLoader />
  } else {
    return (
      <Flex direction='column' justify={['flex-start', 'flex-start', 'center', 'center']} align='center' mt='2em' mb='1em' p='1em' border='1px dotted #E5EAEF' rounded='0.5em' bg='gray.50' w='100%' h={['auto', 'auto', '100%', '100%']}>
        <Flex mb='1em' justify='flex-start' w='100%' fontSize='0.875em' color='brandDark.500' px='1em' fontWeight='600'>Pick & add someone from your team</Flex>
        {updatedTeamStatus === false ? displayDuplicateMemberDetected() :
          <React.Fragment>
            <Box className="invite-form-input-container">
              <Box w='100%' mt='1em'>
                {(dsrUserTeam === 'host') &&
                  <Box className="invite-form-input-container__team-input-container">
                    <Box w='100%'>

                      <Box mb='2em'>

                        {
                          showMemberCard ?
                            renderMemberCard()
                            :
                            renderOrgTeamMemberPicker()
                        }

                      </Box>

                      {!isAlreadyDsrMember &&
                        <>
                          <Box mb='0.55em' lineHeight='1.275em' fontWeight='500' fontSize='0.875em'> Team </Box>

                          <Flex className='user-invite-dropdown-menu' onClick={() => setShowPickTeamDropdown(!showPickTeamDropdown)} ref={teamPickerDropdownRef}>
                            {teamNameInputDisplay ? <Box color='brandDark.500'>{teamNameInputDisplay}</Box> : <Box color="gray.300" fontSize='0.875em'>Pick one</Box>}
                            <Box as={GoChevronDown} boxSize="0.75em" color="gray.500" />
                            <Box className='user-invite-dropdown-menu__dropdown' display={(showPickTeamDropdown) ? 'inline-block' : 'none'}>
                              <Box className='user-invite-dropdown-menu__dropdown-option-display' onClick={() => handlePickTeamOption('seller-team')}>{"Hosts"}</Box>
                              <Box className='user-invite-dropdown-menu__dropdown-option-display' onClick={() => handlePickTeamOption('buyer-team')}>{"Guests"}</Box>
                            </Box>
                          </Flex>
                        </>

                      }
                    </Box>
                  </Box>
                }

                {
                  (dsrUserTeam === 'audience') &&
                  <Box className="invite-form-input-container__team-input-container">
                    <Box w='100%'>
                      <Box mb='0.55em' lineHeight='1.275em' fontWeight='500'> Role </Box>
                      <Flex className='user-invite-display-only-menu'>
                        <Box color='brandDark.500'>Guests</Box>
                      </Flex>
                    </Box>
                  </Box>
                }

                {
                  (teamInput === 'seller-team' && isDsrAdmin) &&
                  <Box className="invite-form-input-container__team-input-container">
                    <Box w='100%'>
                      <Box mb='0.55em' lineHeight='1.275em' fontWeight='500' fontSize='0.875em'> Role </Box>
                      <Flex className='user-invite-dropdown-menu' onClick={() => setShowPickRoleDropdown(!showPickRoleDropdown)} ref={rolePickerDropdownRef}>
                        {chosenRole ? <Box color='brandDark.500'>{chosenRole}</Box> : <Box color="gray.300">Choose Role</Box>}
                        <Box as={GoChevronDown} boxSize="0.75em" color="gray.500" />
                        <Box className='user-invite-dropdown-menu__dropdown' display={(showPickRoleDropdown) ? 'inline-block' : 'none'}>
                          <Box className='user-invite-dropdown-menu__dropdown-option-display' onClick={() => handlePickRoleOption('Member')}>{"Member"}</Box>
                          <Box className='user-invite-dropdown-menu__dropdown-option-display' onClick={() => handlePickRoleOption('Admin')}>{"Admin"}</Box>
                        </Box>
                      </Flex>
                    </Box>
                  </Box>
                }

                {displayUserTeamRolePermissions({dsrUserTeam, teamInput, chosenRole, isMobileView})}

              </Box>
            </Box>

            <Flex w='100%' height='auto'>

              {showIncompleteFormError && <Text textAlign='center' color='pink.500' fontSize='0.8em'>Please ensure all the fields are filled in </Text>}

            </Flex>

            <Flex w='100%' justify='flex-end' mt='2em'>
              {renderCtaButton()}
            </Flex>
          </React.Fragment>
        }

      </Flex>
    )
  }
}


function mapStateToProps(state) {
  return {
    isDsrAdmin: state.dsrAdmin.isDsrAdmin,
    editDsrTeamMembersOutcome: state.editDsrTeamMembersOutcome.results.data,
    editingTeamInProgress: state.editDsrTeamMembersOutcome.isLoading,
    dsrUserTeam: state.dsrUserTeam.userTeam,
    checkIfExistingUserOutcome: state.checkIfExistingUserOutcome.results.data,
    dsrTeamMembersRedux: state.dsrTeamMembers.members,
    orgActiveTeamMembers: state.userActiveOrgTeamMembers.orgActiveTeamMembers,
    isMobileView: state.mobileViewMode.isMobileView
  };
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      editDsrTeamMembers,
      resetEditDsrTeamMembers,
      checkIfExistingUser,
      resetCheckIfExistingUser,
      getUserProfile
    },
    dispatch,
  )


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DsrAddInternalTeamMemberForm));
