import {
  Flex,
  Skeleton,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { getDocData } from "./helper";
import { TableViewSharedDocCard } from "./TableViewSharedDocCard";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import {
  DndContext,
  DragOverlay,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  restrictToVerticalAxis,
  restrictToWindowEdges,
} from "@dnd-kit/modifiers";

export const TableViewSharedDocsList = ({
  isDsrAdmin,
  sharedDocsArray,
  showDeleteSpinner,
  showPopoverDocId,
  setShowPopoverDocId,
  handleSharedDocOrderChange,
  handleDeleteSharedDoc,
  setShowDeleteSpinner,
  handleEditSharedDoc,
  isOpen,
  onOpen,
  onClose,
}) => {
  const HEADERS = ["Name", "Owner", "Created", ""];

  const sensors = useSensors(useSensor(PointerSensor));
  const [activeId, setActiveId] = useState(null);

  const handleDragStart = (event) => {
    setActiveId(event.active.id);
  };

  const handleDragEnd = (event) => {
    setActiveId(null);
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const pagesIdArray = sharedDocsArray?.map(
        (docObject) => docObject?.docId,
      );

      const activeIndex = pagesIdArray.indexOf(active.id);
      const overIndex = pagesIdArray.indexOf(over.id);
      handleSharedDocOrderChange(activeIndex, overIndex, sharedDocsArray);
    }
  };

  return (
    <>
      <Flex
        w="100%"
        className="task-category-group-container"
        justify="space-between"
        align="center"
        p="1em 1.5em"
      >
        <DndContext
          sensors={sensors}
          onDragEnd={handleDragEnd}
          onDragStart={handleDragStart}
          modifiers={[restrictToVerticalAxis]}
        >
          <TableContainer w="100%" fontSize="0.875em">
            <SortableContext
              items={sharedDocsArray?.map((docObject) => docObject?.docId)}
              strategy={verticalListSortingStrategy}
            >
              <Table size="2em">
                <Thead>
                  <Tr>
                  <Th></Th>
                    {HEADERS.map((header) => (
                      <Th>{header}</Th>
                    ))}
                  </Tr>
                </Thead>
                <Tbody>
                  {sharedDocsArray?.map((docObject) => {
                    const {
                      docId,
                      docName,
                      createdAt,
                      formattedDocLink,
                      memberName,
                      fileKey,
                    } = getDocData({ docObject });

                    return (
                      <TableViewSharedDocCard
                        isDsrAdmin={isDsrAdmin}
                        key={docId}
                        docId={docId}
                        docName={docName}
                        docSharedAt={createdAt}
                        formattedDocLink={formattedDocLink}
                        memberName={memberName}
                        fileKey={fileKey}
                        showDeleteSpinner={showDeleteSpinner}
                        showPopoverDocId={showPopoverDocId}
                        setShowPopoverDocId={setShowPopoverDocId}
                        handleDeleteSharedDoc={handleDeleteSharedDoc}
                        handleEditSharedDoc={handleEditSharedDoc}
                        isOpen={isOpen}
                        onOpen={onOpen}
                        onClose={onClose}
                      />
                    );
                  })}
                </Tbody>
              </Table>

              <DragOverlay modifiers={[restrictToWindowEdges]}>
                {activeId ? <Skeleton height="20px" my="10px" /> : null}
              </DragOverlay>
            </SortableContext>
          </TableContainer>
        </DndContext>
      </Flex>
    </>
  );
};
