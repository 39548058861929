import React, { memo, useEffect } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'

import { Box, Flex, Skeleton, Collapse, useDisclosure, AbsoluteCenter, Divider } from "@chakra-ui/react";
import { getDsrAnalyticsCategoryDetails } from '../../analytics/actions/dsr-analytics-actions'
import { getUserDetailsUsingDsrMembersArray } from '../../profile/helpers/user-helper';

import { formatSecondsValueDisplay } from '../../global/helpers/time-format-helpers';
import LaunchdeckButton from '../../global/components/helper-components/components/LaunchdeckButton';
import LaunchdeckTag from '../../global/components/helper-components/components/LaunchdeckTag';


function DsrPageStats({
  dsrId,
  pageStatsAvailable,
  dsrPageAnalytics,
  handleLoadPageData,
  getDsrAnalyticsCategoryDetails,
  dsrTeamMembersRedux,
  isMobileView
}) {

  const { isOpen, onToggle } = useDisclosure()

  useEffect(() => {
    if (!pageStatsAvailable && getDsrAnalyticsCategoryDetails) {
      getDsrAnalyticsCategoryDetails({ dsrId, requestType: 'retrieve-dsr-page-analytics' })

      handleLoadPageData()
    }
  }, [getDsrAnalyticsCategoryDetails, handleLoadPageData, dsrId]);


  const handleDisplayBreakdown = () => {
    // setBreakdownPageId(pageIdentifier)
    onToggle()
  }

  const renderStatRows = () => {
    if (Array.isArray(dsrPageAnalytics)) {
      const displayRows = dsrPageAnalytics?.map((pageStats) => {
        const { pageIdentifier, pageTitle, pageTotalEngagementTime, pageUniqueViews, pageUniqueVisitors, pageUniqueViewsBreakdown } = pageStats || {};


        const renderBreakdownStats = () => {
          if (Array.isArray(pageUniqueViewsBreakdown)) {

            if (pageUniqueViewsBreakdown.length < 1) {
              return <Box fontSize='0.875em' my='1em'>No breakdown available</Box>
            }
            const displayBreakdownRows = pageUniqueViewsBreakdown?.map(((userPageStat, rowIndex) => {
              const { visitorUserId, visitorEngagementTimeSeconds, visitorSessonCount } = userPageStat || {};
              const userProfile = getUserDetailsUsingDsrMembersArray(dsrTeamMembersRedux, visitorUserId);
              const { memberName } = userProfile || {}

              return (
                <Flex key={`detailed-breakdown-${visitorUserId}-${rowIndex}`} w='100%' align='center' direction={["column", "column", "row", "row"]}
                  py='0.5em'
                  bg='gray.50'
                  rounded='0.25em'
                  mb={['0.5em', '0.5em', '0', '0']}
                >

                  <Flex flex='5' justify='flex-start' fontSize={['0.75em', '0.75em', '0.875em', '0.875em']} fontWeight='400' lineHeight='1.5em' color='brandDark.500' textAlign={["center", "center", "", ""]} pl={['0', '0', '1em', '1em']}>
                    <Flex justify='flex-start' flexWrap='wrap' w='100%' textAlign='left' maxW='20em' px='2em'>{memberName}</Flex>
                    </Flex>
                  <Flex flex='5' w='100%' my={["0.5em", "0.5em", "", ""]} >
                    <Box flex='2' justify='flex-start' fontSize={['0.75em', '0.75em', '0.875em', '0.875em']} fontWeight='400' lineHeight='1.5em' color='brandDark.500'>
                      <Box color='gray.500' textAlign={['center', 'center', 'center', 'center']} display={['', '', 'none', 'none']}>Sessions</Box>
                      <Box textAlign={["center", "center", "center", "center"]} fontSize={["0.8em", "0.8em", "0.875em", "0.875em"]}> {visitorSessonCount}</Box>
                    </Box>
                    <Box flex='2' justify='flex-start' fontSize={['0.75em', '0.75em', '0.875em', '0.875em']} fontWeight='400' lineHeight='1.5em' color='brandDark.500'>
                      <Box color='gray.500' textAlign={['center', 'center', 'center', 'center']} display={['', '', 'none', 'none']}>Time Spent</Box>
                      <Box textAlign={["center", "center", "center", "center"]} fontSize={["0.8em", "0.8em", "0.875em", "0.875em"]}>  {formatSecondsValueDisplay(visitorEngagementTimeSeconds)}</Box>
                    </Box>

                    <Box flex='1' display={['none', 'none', 'block', 'block']} color='brandDark.500' justify='flex-end' align='center'>
                      {/* <AccordionIcon /> */}
                    </Box>
                  </Flex>

                </Flex>
              )
            }))

            return displayBreakdownRows
          } else {
            return null;

          }
        }

        return (
          <Box key={`dsr-analytics-stats-${pageIdentifier}`} w='100%' py='0.5em' align='center' px={['0', '0', '1.5em', '1.5em']}>
            <Flex w='100%' align='center' direction={["column", "column", "row", "row"]} >
              <Flex flex='3' justify={isMobileView ? 'center' : "flex-start"} align='center' fontSize='0.75em' fontWeight='400' lineHeight='1.5em' color='brandDark.500'
              bg={isMobileView && 'gray.100'} rounded='0.5em' w={isMobileView && '100%'} p={isMobileView && '0.2em'}>
                {pageTitle}
              </Flex>

              <Flex flex='7' w='100%' my={["0.5em", "0.5em", "", ""]} >
                <Box flex='2' justify='flex-start' fontSize='0.875em' fontWeight='400' lineHeight='1.5em' color='brandDark.500'>
                  <Box fontSize='0.75em' color='gray.500' textAlign={['center', 'center', 'center', 'center']} display={['', '', 'none', 'none']}>Visitors</Box>
                  <Box textAlign={["center", "center", "", ""]} fontSize={["0.8em", "0.8em", "0.875em", "0.875em"]}> {pageUniqueVisitors}</Box>
                </Box>

                <Box flex='2' justify='flex-start' fontSize='0.875em' fontWeight='400' lineHeight='1.5em' color='brandDark.500'>
                  <Box fontSize='0.75em' color='gray.500' textAlign={['center', 'center', 'center', 'center']} display={['', '', 'none', 'none']}>Views</Box>
                  <Box textAlign={["center", "center", "", ""]} fontSize={["0.8em", "0.8em", "0.875em", "0.875em"]}> {pageUniqueViews}</Box>
                </Box>
                <Box flex='2' justify='flex-start' fontSize='0.875em' fontWeight='400' lineHeight='1.5em' color='brandDark.500'>
                  <Box fontSize='0.75em' color='gray.500' textAlign={['center', 'center', 'center', 'center']} display={['', '', 'none', 'none']}>Time spent</Box>
                  <Box textAlign={["center", "center", "", ""]} fontSize={["0.8em", "0.8em", "0.875em", "0.875em"]}> {formatSecondsValueDisplay(pageTotalEngagementTime)}</Box>
                </Box>

                <Box flex='1' display={['none', 'none', 'block', 'block']} fontSize='0.875em' color='brandDark.500' justify='flex-end' align='center'>

                </Box>
              </Flex>

            </Flex>

            <Collapse in={isOpen} animateOpacity>
              <Box mt='1.5em' position='relative' mb={['1.5em', '1.5em', '0.5em', '0.5em']} px={['25%', '25%', '15em', '15em']}>
                <Divider borderColor='brand.300' />
                <AbsoluteCenter bg='white' px='1em' fontSize='0.7em' fontWeight='600' color='gray.700'>
                  Breakdown
                </AbsoluteCenter>
              </Box>
              <Box my='1em'>
                {renderBreakdownStats()}
              </Box>
              <Divider />
            </Collapse>


          </Box>
        )
      })

      return <Box minH='50vh'>{displayRows}</Box>;
    } else {
      const skeleton = Array.from({ length: 8 }, (_, index) => (
        <Flex w='100%' px='1.5em' py='0.5em' borderBottom='1px solid' borderColor='gray.200'>
          <Box flex='1' py='0.5em'>
            <Skeleton w='4em' h='1.25em' py='0.5em' />
          </Box>
          <Box flex='1' py='0.5em'>
            <Skeleton w='2em' h='1.25em' py='0.5em' />
          </Box>
          <Box flex='1' py='0.5em'>
            <Skeleton w='5em' h='1.25em' py='0.5em' />
          </Box>
        </Flex>
      ));

      return (
        <Box>
          {skeleton}
        </Box>
      )
    }
  }

  return (
    <Box w='100%' bg='white' rounded='0.5em' h='auto' px='1.5em'>

      <Flex w='100%' py='1em' borderBottom='1px solid' borderColor='gray.200' px={['0', '0', '1.5em', '1.5em']}>
        <Flex display={["none", "none", "flex", "flex"]} w='100%' align='center' direction={["column", "column", "row", "row"]}>
          <Box flex='3' justify='flex-start' fontSize='0.75em' fontWeight='400' lineHeight='1.5em' color='gray.500'>
            Page
          </Box>

          <Flex flex='7' w='100%' my={["0.5em", "0.5em", "", ""]} align='center'>

            <Box flex='2' fontSize='0.75em' fontWeight='400' lineHeight='1.5em' color='gray.500' textAlign='center'>
              Unique visitors
            </Box>

            <Box flex='2' fontSize='0.75em' fontWeight='400' lineHeight='1.5em' color='gray.500' textAlign='center'>
              Views
            </Box>

            <Box flex='2' fontSize='0.75em' fontWeight='400' lineHeight='1.5em' color='gray.500' textAlign='center'>
              Time spent
            </Box>
         
            <Flex flex='1' justify='flex-end' color='brand.500' fontWeight='500'  >
              <LaunchdeckTag fontSize='0.75em' bg='brand.500' color='white' onClick={() => handleDisplayBreakdown()}>
                <Box>Details</Box>
              </LaunchdeckTag>
            </Flex>
          </Flex>
        </Flex>

        <Flex display={["flex", "flex", "none", "none"]} w='100%' align='center' justify='space-between' pl={['0', '0', '1em', '1em']}>
          <Box fontSize='1.25em' fontWeight='500' lineHeight='1.3em' color='brandDark.500' >Page Stats</Box>
          <LaunchdeckButton fontSize='0.75em' bg='brandDark.500' color='white' onClick={() => handleDisplayBreakdown()} label='Details' />
        </Flex>

      </Flex>
      {renderStatRows()}
    </Box>

  )
}


const mapStateToProps = (state) => {
  return {
    dsrTeamMembersRedux: state.dsrTeamMembers.members,
    isMobileView: state.mobileViewMode.isMobileView
  }
}


const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getDsrAnalyticsCategoryDetails
    },
    dispatch,
  )

export default memo(withRouter(connect(mapStateToProps, mapDispatchToProps)(DsrPageStats)))