import * as types from '../actions/customer-support-actions-types';


const INITIAL_STATE_SEND_CUSTOMER_SUPPORT_MESSAGE = {
	isLoading: false,
	results: {},
	error: '',
};

export function sendCustomerSupportMessageReducer(state = INITIAL_STATE_SEND_CUSTOMER_SUPPORT_MESSAGE, action) {
	switch (action.type) {
		case types.SEND_CUSTOMER_SUPPORT_MESSAGE_RESET:
			return INITIAL_STATE_SEND_CUSTOMER_SUPPORT_MESSAGE
		case types.SEND_CUSTOMER_SUPPORT_MESSAGE_REQUEST:
			return { ...state, isLoading: true, results: {} };
		case types.SEND_CUSTOMER_SUPPORT_MESSAGE_SUCCESS:
			return { ...state, isLoading: false, results: action.payload };
		case types.SEND_CUSTOMER_SUPPORT_MESSAGE_FAILURE:
			return { ...state, isLoading: false, error: 'Error while trying to send customer support message' };
		default:
			return state;
	}
}
