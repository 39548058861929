import { Box, Flex } from '@chakra-ui/react'
import DsrHeader from './DsrHeader'
import DsrGenericErrorModal from './DsrGenericErrorModal'
import React, { memo, useEffect, useRef, useState } from 'react'
import RenderDsrContent from './RenderDsrContent'
import { useParams, useHistory } from 'react-router-dom'
import { getThisUserIdToken, generateSessionId } from '../../global/helpers/global-helpers'
import { sendToGA } from '../../global/helpers/google-tag-helpers'


const DsrDisplayPage = ({
  dsrConfiguration,
  dsrStatusRedux,
  dsrFeedContent,
  getDsrFeedContent,
  selectedDisplaySubpage,
  currentFeedContentDsrId,
  dsrTeamMembersRedux,
  userProfileRedux,
  dsrTitleRedux,
  fetchInitialDsrData,
  resetDsrFeedContent,
  resetDsrTitle,
  resetDsrSelectedDisplaySubpage,
  resetDsrPages,
  updateDsrReset,
  dsrInvalidRequestRedux,
  dsrUserTeam,
  logGuestDsrAnalytics
}) => {
  const { dsrId } = useParams()
  const history = useHistory();

  const initRef = useRef(false);
  const analyticsRef = useRef(false);
  const [isSellingTeamMember, setIsSellingTeamMember] = useState(null)
  const [sessionId, setSessionId] = useState(null)

  const isMobileView = window.screen.width < 770;
  const headerMenuHeight = isSellingTeamMember ? isMobileView ? '200px' : '140px' : isMobileView ? '170px' : '70px';

  // Page Setup
  useEffect(() => {
    if (!initRef.current && dsrId !== 'new') {
      document.title = 'Launchdeck Digital Sales Room'
      document.description =
        'Create customised private digital sales rooms that let Sales & Purchasing teams agree on deals quickly'

      fetchInitialDsrData(dsrId, ['active'])

      const generatedSessionId = generateSessionId()
      setSessionId(generatedSessionId)
      // GA4
      const thisUserIdToken = getThisUserIdToken()

      sendToGA('view_dsr', {
        'eventTimestamp': new Date().toISOString(),
        'dsrId': dsrId,
        'userIdToken': thisUserIdToken
        // 'pageId': 'overview-subpage' // Need overview subpage pageId
      });

      initRef.current = true
    }
  }, [dsrId, fetchInitialDsrData])

  // Analytics
  useEffect(() => {
    if (!analyticsRef.current) {
      if (dsrUserTeam === "host") {
        // No analytics logged for Host seller team
        analyticsRef.current = true
      }

      if (dsrUserTeam === "audience") {
        // Analytics logged for Guest buyer team
        logGuestDsrAnalytics({
          eventType: 'guest-enters-dsr',
          analyticsPayload: {
            userId: getThisUserIdToken(),
            dsrId,
            sessionId: sessionId
          }
        })
      }
    }

  }, [dsrUserTeam])

  // Business Logic
  useEffect(() => {
    if (dsrInvalidRequestRedux === true) {
      history.push(`/invalid`, {
        redirectionUri: `/home`
      })
    } else {
      switch (dsrStatusRedux) {
        case (undefined): {
          history.push(`/invalid`, {
            redirectionUri: `/home`
          })

          break;
        }
        case ("reverted-draft"):
        case ("draft"): {
          history.push(`/invalid`, {
            redirectionUri: `/home`
          })

          break;
        }
        default: {
          // Do not put any response here (activated while page is loading)
          break;
        }
      }
    }


    // TODO: Redirect users if it is NOT an active status DSR. 
    // However there is a UX issue if user goes to draft DSR first, and then Vault, and then active DSR, there is a temp state where it is still 'draft' status, and user will be redirected to invalid page

    // if (dsrConfiguration) {
    //   const {status} =  dsrConfiguration || {}
    //   if (status === 'draft' || status === 'reverted-draft') {
    //     redirectToNewPage('/invalid') // TODO use react router
    //   }
    // }

  }, [currentFeedContentDsrId, dsrConfiguration, dsrId, dsrInvalidRequestRedux, dsrStatusRedux])

  useEffect(() => {
    if (selectedDisplaySubpage === 'updates-subpage') getDsrFeedContent(dsrId)
  }, [dsrId, getDsrFeedContent, selectedDisplaySubpage])

  useEffect(() => {
    if (dsrUserTeam) {
      const isSellerMember = (dsrUserTeam === "host") ? true : false;

      setIsSellingTeamMember(isSellerMember)
    }

  }, [setIsSellingTeamMember, dsrUserTeam])

  useEffect(() => {
    return () => resetDsrFeedContent() && resetDsrTitle() && resetDsrSelectedDisplaySubpage() && resetDsrPages() && updateDsrReset()
  }, [resetDsrFeedContent, resetDsrTitle, resetDsrSelectedDisplaySubpage, resetDsrPages, updateDsrReset])

  return (
    <Flex direction="column">
      <Flex direction="row" flexGrow="1">
        <Flex w="100%" direction="column" alignItems="center" bg="white">
          <Box width='100%' position='fixed' top='0' zIndex='2'>
            <DsrHeader
              dsrTeamMembers={dsrTeamMembersRedux}
              userProfile={userProfileRedux}
              dsrTitle={dsrTitleRedux}
              isSellingTeamMember={isSellingTeamMember}
            // isPagePreviewMode={isPagePreviewMode}
            />
          </Box>
          <Flex w="100%" className="authentication-container" justify="center" pt={headerMenuHeight} bg={'#F4F4F4'}>
            <RenderDsrContent
              dsrId={dsrId}
              sessionId={sessionId}
              userProfile={userProfileRedux}
              dsrFeedContent={dsrFeedContent}
              getDsrFeedContent={getDsrFeedContent}
              dsrConfiguration={dsrConfiguration}
              isSellingTeamMember={isSellingTeamMember}
              headerMenuHeight={headerMenuHeight}
            />
            <DsrGenericErrorModal dsrId={dsrId} />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default memo(DsrDisplayPage)
