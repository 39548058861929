import * as types from '../actions/auth-action-types';


// Access Authentication
const INITIAL_STATE_ACCESS_AUTHENTICATION_REQUEST = {
	isLoading: false,
	results: {},
	error: '',
};

export function accessAuthenticationReducer(state = INITIAL_STATE_ACCESS_AUTHENTICATION_REQUEST, action) {

	switch (action.type) {
		case types.ACCESS_PAGE_AUTHENTICATION_RESET:
			return INITIAL_STATE_ACCESS_AUTHENTICATION_REQUEST;
		case types.ACCESS_PAGE_AUTHENTICATION_REQUEST:
			return { ...state, isLoading: true, results: {} };
		case types.ACCESS_PAGE_AUTHENTICATION_SUCCESS:
			return { ...state, isLoading: false, results: action.payload };
		case types.ACCESS_PAGE_AUTHENTICATION_FAILURE:
			return { ...state, isLoading: false, error: 'Error while trying to perform access authentication' };
		default:
			return state;
	}
}