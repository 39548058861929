import auth from '../../../config/app-specific-config/auth';
import * as types from './users-profile-actions-types';
import { axiosInstance } from '../../../config/app-specific-config/axiosConfig';
import { displayModeCheck } from '../../global/helpers/global-helpers';
import { setIsMobileView } from '../../global/actions/global-actions';


// Setting User Information
export const setThisUserAccountProfile = (p) => {
	return {
		type: types.SET_USER_ACCOUNT_PROFILE,
		payload: p
	};
};

export const setUserProfilePicture = (p) => {
	return {
		type: types.SET_USER_PROFILE_PICTURE,
		payload: p
	};
};

export const setUserIsOrgAdmin = (isOrgAdmin) => {
	return {
		type: types.SET_USER_IS_ORG_ADMIN,
		payload: isOrgAdmin
	};
};

export const setUserOrgActiveTeamMembers = (isOrgAdmin) => {
	return {
		type: types.SET_USER_ORG_ACTIVE_TEAM_MEMBERS,
		payload: isOrgAdmin
	};
};


// OG
export const getUserProfileSuccess = (response) => {
	return {
		type: types.GET_USER_PROFILE_SUCCESS,
		payload: response.data
	};
};

export const getUserProfileFailure = (error) => {
	return {
		type: types.GET_USER_PROFILE_FAILURE,
		error
	};
};

export const getUserProfileRequest = () => {
	
	return {
		type: types.GET_USER_PROFILE_REQUEST
	};
};

export const getUserProfile = ({getOrgProfile, getOrgTeamMembers}) => {
	
	return async (dispatch) => {
		
		dispatch(getUserProfileRequest());
		try {
			const url = getOrgProfile ? `/me/get-user-profiles?getOrgProfile=${getOrgProfile}` : getOrgTeamMembers ? `/organizations/get-active-team-members` : '/me';
			
			const headers = await auth.getAccessToken();
			const response = await axiosInstance.get(url, {
				headers
			});

			if (!!response.data.data.logUserOut) {
				// Log out inactive users
				dispatch(setThisUserAccountProfile({
					logUserOut: true
				}));
			} else {
				if (!!getOrgProfile) {

					const {isAdministrator} = response.data.data.userOrgProfile || {}
					const {orgName, userOrgTitle} = response.data.data.userOrgProfile || {}
					dispatch(setUserIsOrgAdmin(isAdministrator ? isAdministrator : false));
					dispatch(setThisUserAccountProfile({
						...response.data.data.userPersonalProfile, 
						orgName, 
						userOrgTitle
					}));
				} else if (!!getOrgTeamMembers) {
	
					dispatch(setUserOrgActiveTeamMembers(response.data.data))
				} else if (response.data.data) {
					dispatch(setThisUserAccountProfile(response.data.data));
				} 
	
				if (response.data.data.userPersonalProfile.profilePicture) {
					dispatch(setUserProfilePicture(response.data.data.userPersonalProfile.profilePicture))
				}
			}
		

			// Mobile View setting
			const isMobileView = displayModeCheck();
			dispatch(setIsMobileView(isMobileView))

			dispatch(getUserProfileSuccess(response));
		
		} catch (error) {
			dispatch(getUserProfileFailure(error));
		}
	};
}


// Upload Profile Picture

export const uploadProfilePictureSuccess = (response) => {
	return {
		type: types.UPLOAD_PROFILE_PICTURE_SUCCESS,
		payload: response.data
	};
};

export const uploadProfilePictureFailure = (error) => {
	return {
		type: types.UPLOAD_PROFILE_PICTURE_FAILURE,
		error
	};
};

export const uploadProfilePictureRequest = () => {
	return {
		type: types.UPLOAD_PROFILE_PICTURE_REQUEST
	};
};

export function uploadProfilePicture(values) {
	return async (dispatch) => {
		dispatch(uploadProfilePictureRequest());
		try {
			const profilePicture = new FormData(); 
			profilePicture.append('imageType', "user-profile-pic")  
			profilePicture.append('profilePicture', values)  
	

			const url = '/me/upload-profile-image';
			const headers = await auth.getAccessToken();
		
			const response = await axiosInstance.put(url, profilePicture, {headers});

			if (response.data.data.newProfilePicUrl) {
				dispatch(setUserProfilePicture(response.data.data.newProfilePicUrl))
			}

			dispatch(uploadProfilePictureSuccess(response));
		} catch (error) {
			dispatch(uploadProfilePictureFailure(error));
		}

	};
}

// export const uploadProfilePictureReset = () => {
// 	return {
// 		type: types.UPLOAD_PROFILE_PICTURE_RESET
// 	};
// };

// export function resetUploadProfilePictureOutcome() {
// 	return async (dispatch) => {
// 		try {
// 			dispatch(uploadProfilePictureReset());
// 		} catch (error) {
// 			dispatch(uploadProfilePictureFailure(error));
// 		}

// 	};
// }



/////Edit User profile
export const editUserProfileSuccess = (response) => {
	return {
		type: types.EDIT_USER_PROFILE_SUCCESS,
		payload: response.data
	};
};

export const editUserProfileFailure = (error) => {
	return {
		type: types.EDIT_USER_PROFILE_FAILURE,
		error
	};
};

export const editUserProfileRequest = () => {
	return {
		type: types.EDIT_USER_PROFILE_REQUEST
	};
};

export const editUserProfile = (updatedUserParams) => {
	return async (dispatch) => {
		dispatch(editUserProfileRequest());
		try {
			const url = '/me';
			const headers = await auth.getAccessToken();
		
			const response = await axiosInstance.put(
				url,
				updatedUserParams,
				{ headers }
			);

			dispatch(setThisUserAccountProfile(response.data.data.users[0]));
			dispatch(editUserProfileSuccess(response));
	
		} catch (error) {
			dispatch(editUserProfileFailure(error));
		}
	};
};


/////Update User Action Event

export const editUserActionEventSuccess = (response) => {
	return {
		type: types.UPDATE_USER_ACTION_EVENT_SUCCESS,
		payload: response.data
	};
};

export const editUserActionEventFailure = (error) => {
	return {
		type: types.UPDATE_USER_ACTION_EVENT_FAILURE,
		error
	};
};

export const editUserActionEventRequest = () => {
	return {
		type: types.UPDATE_USER_ACTION_EVENT_REQUEST
	};
};

export const updateUserActionEvent = (newActionEvent) => {
	return async (dispatch) => {
		dispatch(editUserActionEventRequest());
		try {
			const url = '/me/user-action-event';
			const headers = await auth.getAccessToken();

			const userMetaData = {
				...newActionEvent,
				// ...userMetaData,
			}
			const response = await axiosInstance.put(
				url,
				{
					userMetaData
				},
				{ headers }
			);
			dispatch(editUserActionEventSuccess(response));
			
		} catch (error) {
			dispatch(editUserActionEventFailure(error));
		}
	};
};

/////

export const resendEmailVerificationSuccess = (response) => {
	return {
		type: types.RESEND_EMAIL_VERIFICATION_SUCCESS,
		payload: response.data
	};
};

export const resendEmailVerificationFailure = (error) => {
	return {
		type: types.RESEND_EMAIL_VERIFICATION_FAILURE,
		error
	};
};

export const resendEmailVerificationRequest = () => {
	return {
		type: types.RESEND_EMAIL_VERIFICATION_REQUEST
	};
};

export const resendEmailVerification = () => {
	return async (dispatch) => {
		dispatch(resendEmailVerificationRequest());
		try {
			const url = '/emailer/resend-verify-email';
			const headers = await auth.getAccessToken();

			const response = await axiosInstance.post(
				url,
				{},
				{ headers }
			);
			dispatch(resendEmailVerificationSuccess(response));
		} catch (error) {
			dispatch(resendEmailVerificationFailure(error));
		}
	};
};


/////Update User Email Settings
export const updateUserSettingsSuccess = (response) => {
	return {
		type: types.UPDATE_USER_SETTINGS_SUCCESS,
		payload: response.data
	};
};

export const updateUserSettingsFailure = (error) => {
	return {
		type: types.UPDATE_USER_SETTINGS_FAILURE,
		error
	};
};

export const updateUserSettingsRequest = () => {
	return {
		type: types.UPDATE_USER_SETTINGS_REQUEST
	};
};


export const updateUserSettings = (settingsPayload) => {
	return async (dispatch) => {
		dispatch(updateUserSettingsRequest());
		try {
			const url = '/me/user-settings';
			const headers = await auth.getAccessToken();
		
			const response = await axiosInstance.put(
				url,
				settingsPayload,
				{ headers }
			);
			dispatch(updateUserSettingsSuccess(response));
	
		} catch (error) {
			dispatch(updateUserSettingsFailure(error));
		}
	};
};

/////Get User Email Settings
export const getUserSettingsSuccess = (response) => {
	return {
		type: types.GET_USER_SETTINGS_SUCCESS,
		payload: response.data
	};
};

export const getUserSettingsFailure = (error) => {
	return {
		type: types.GET_USER_SETTINGS_FAILURE,
		error
	};
};

export const getUserSettingsRequest = () => {
	return {
		type: types.GET_USER_SETTINGS_REQUEST
	};
};

export const getUserSettings = (requestPayload) => {
	return async (dispatch) => {
		dispatch(getUserSettingsRequest());
		try {
			const {emailSettingsRequest, bannerSettingsRequest, checkDemoSignupUserRequest} = requestPayload || {};

			const url = `/me/get-user-settings?email=${emailSettingsRequest}&banner=${bannerSettingsRequest}&checkDemoSignupUser=${checkDemoSignupUserRequest}`;
			const headers = await auth.getAccessToken();
			const response = await axiosInstance.get(url, {headers});

			dispatch(getUserSettingsSuccess(response));
	
		} catch (error) {
			dispatch(getUserSettingsFailure(error));
		}
	};
};

/////Update User Password
export const updateUserPasswordSuccess = (response) => {
	return {
		type: types.UPDATE_USER_PASSWORD_SUCCESS,
		payload: response.data
	};
};

export const updateUserPasswordFailure = (error) => {
	return {
		type: types.UPDATE_USER_PASSWORD_FAILURE,
		error
	};
};

export const updateUserPasswordRequest = () => {
	return {
		type: types.UPDATE_USER_PASSWORD_REQUEST
	};
};

export const updateUserPassword = (currentPasswordInput, newPassword) => {
	return async (dispatch) => {
		dispatch(updateUserPasswordRequest());
		try {
			const url = '/me/user-new-pw';
			const headers = await auth.getAccessToken();
			const response = await axiosInstance.put(
				url,
				{
					currentPasswordInput,
					newPassword
				},
				{ headers }
			);

			dispatch(updateUserPasswordSuccess(response));
	
		} catch (error) {
			dispatch(updateUserPasswordFailure(error));
		}
	};
};