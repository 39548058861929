import auth from '../../../config/app-specific-config/auth'
import * as types from './manage-dsr-member-action-types'
import { axiosInstance } from '../../../config/app-specific-config/axiosConfig'
import { setDsrTeamMembers, setDsrUiIndicators, setJoinRoomRequests } from './dsr-actions';




////////////////////////////////////////////////////////////
// DSR MEMBER MANAGEMENT
////////////////////////////////////////////////////////////
// POST method
export const checkifExistingUserSuccess = (response) => {
	return {
		type: types.CHECK_IF_EXISTING_USER_SUCCESS,
    payload: response.data
	};
};

export const checkifExistingUserFailure = (error) => {
	return {
		type: types.CHECK_IF_EXISTING_USER_FAILURE,
    error
	};
};

export const checkifExistingUserRequest = () => {
	return {
		type: types.CHECK_IF_EXISTING_USER_REQUEST
	};
};


export function checkIfExistingUser( userEmail ) {

	// POST API
	return async (dispatch) => {
		dispatch(checkifExistingUserRequest());
		try {
				const url = `/users/check-existing/${userEmail}`;
				const headers = await auth.getAccessToken();
				const response = await axiosInstance.get(url, {
					headers
				});
			
			dispatch(checkifExistingUserSuccess(response));

		} catch (error) {
			dispatch(checkifExistingUserFailure(error));
		}
	};
}


export const checkIfExistingUserReset = () => {
	return {
		type: types.CHECK_IF_EXISTING_USER_RESET
	};
};

export function resetCheckIfExistingUser() {

	return async (dispatch) => {

		try {
			dispatch(checkIfExistingUserReset());

		} catch (error) {
			dispatch(checkifExistingUserFailure(error));
		}
	};
}

////////////////////////////////////////////////////////////
// DSR JOIN ROOM REQUEST MANAGEMENT
////////////////////////////////////////////////////////////
// POST method

export const manageJoinRoomSuccess = (response) => {
	return {
		type: types.MANAGE_JOIN_ROOM_SUCCESS,
    payload: response.data
	};
};

export const manageJoinRoomFailure = (error) => {
	return {
		type: types.MANAGE_JOIN_ROOM_FAILURE,
    error
	};
};

export const manageJoinRoomRequest = () => {
	return {
		type: types.MANAGE_JOIN_ROOM_REQUEST
	};
};


export function manageJoinRoom( manageJoinRoomPayload ) {

	// POST API
	return async (dispatch) => {
		dispatch(manageJoinRoomRequest());
		try {
				const url = `/dsr/manage-join-room-request`; 
				const headers = await auth.getAccessToken();
				const response = await axiosInstance.post(
					url,
					{ manageJoinRoomPayload },
					{ headers }
				);
		
				const {updateSuccessful, updatedJoinRoomRequests, updatedDsrTeamMembers, uiIndicators} = response.data.data || {}
				if (updateSuccessful && updatedJoinRoomRequests) {
					const {requestCount, activeRequests} = updatedJoinRoomRequests || {};
					dispatch(setJoinRoomRequests(requestCount ? activeRequests : ""))
				}

				if (updateSuccessful && updatedDsrTeamMembers) {
					dispatch(setDsrTeamMembers(updatedDsrTeamMembers)) 
				}
				
				if (uiIndicators) {
					dispatch(setDsrUiIndicators(uiIndicators))
				}

			
	
			dispatch(manageJoinRoomSuccess(response));

		} catch (error) {
			dispatch(manageJoinRoomFailure(error));
		}
	};
}


export const manageJoinRoomReset = () => {
	return {
		type: types.MANAGE_JOIN_ROOM_RESET
	};
};

export function resetManageJoinRoom() {

	return async (dispatch) => {

		try {
			dispatch(manageJoinRoomReset());

		} catch (error) {
			dispatch(manageJoinRoomFailure(error));
		}
	};
}