import React from 'react'
import { Box } from '@chakra-ui/react';
import { optionTypes } from "../types/subWidgetOptionsTypes";
import InputTextAreaExpandableWithFormatting from '../../../../forms/TextareaField/components/InputTextAreaExpandableWithFormatting';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';


export const NormalText = ({
  subWidgetId,
  editableItemId,
  options,
  setSubWidgetContent,
  subWidgetContent,
  isDragging,
  handleEditItemByClick,
  handleSaveItemByClickOutside
}) => {


  const handleClick = () => {
    if (!isDragging) {
      handleEditItemByClick()
    }
  }

  return (
    editableItemId === subWidgetId
      ? <Box width="100%">
        <InputTextAreaExpandableWithFormatting
          autoFocus
          onBlur={handleSaveItemByClickOutside}
          fontSize="1em"
          inputValue={subWidgetContent}
          textAlign={options[optionTypes.ALIGN]}
          backgroundColor="#FDFDFD"
          setSubWidgetContent={setSubWidgetContent}
          onChangeHandler={(e) => setSubWidgetContent(e.target.value)}
          placeholder={"Add your text content here"}
        />
      </Box>
      : <Box onClick={handleClick} padding="10px" whiteSpace='pre-line' listStylePosition="inside" textAlign={options[optionTypes.ALIGN]} w='100%'>
        <ReactMarkdown
          children={subWidgetContent ? subWidgetContent : "Lorem ipsum... Add your text content here"}
          remarkPlugins={[remarkGfm]}
        />
      </Box>
  )
}
