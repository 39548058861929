import * as types from '../actions/global-actions-types';


const INITIAL_STATE_SEND_SYSTEM_NOTIFICATION_EMAIL_FEEDBACK = {
	isLoading: false,
	results: {},
	error: '',
};

export function sendSystemNotificationEmailReducer(state = INITIAL_STATE_SEND_SYSTEM_NOTIFICATION_EMAIL_FEEDBACK, action) {
	switch (action.type) {
		case types.SEND_SYSTEM_NOTIFICATION_EMAIL_REQUEST:
			return { ...state, isLoading: true, results: {} };
		case types.SEND_SYSTEM_NOTIFICATION_EMAIL_SUCCESS:
			return { ...state, isLoading: false, results: action.payload };
		case types.SEND_SYSTEM_NOTIFICATION_EMAIL_FAILURE:
			return { ...state, isLoading: false, error: 'Error while trying to send system notification email' };
		default:
			return state;
	}
}



