import React, { useState, useEffect } from 'react';
import { bindActionCreators } from "redux";
import { sendProspectorQuery, resetSendProspectorQuery } from "../../actions/dsr-actions";
import { connect } from "react-redux";
import { withRouter } from 'react-router';

import { Box, Text, Flex, useToast } from "@chakra-ui/react";
import FormTextInput from '../../../forms/TextInputField/components/FormTextInput';

import { triggerLaunchdeckToast, validateEmail } from '../../../global/helpers/global-helpers';
import LaunchdeckButton from '../../../global/components/helper-components/components/LaunchdeckButton';
import { splitNameInput } from '../../../profile/helpers/user-helper';
import InputTextAreaExpandable from '../../../forms/TextareaField/components/InputTextAreaExpandable';


function CreateDsrFromProspectDsrForm({
	pDsrId,
	mainContactName,
	handleCloseDrawer,
	sendProspectDsrQueryOutcome,
	dsrTitleRedux,
	sendProspectorQuery,
	resetSendProspectorQuery,
	isMobileView
}) {

	const toast = useToast();

	const [nameInput, setNameInput] = useState("");
	const [nameError, setNameError] = useState(false);

	const [emailInput, setEmailInput] = useState("");
	const [emailError, setEmailError] = useState(false);

	const [formQueryInput, setFormQueryInput] = useState(`Hi, I came across your Room (${dsrTitleRedux}), and would like to discuss things in greater details please.`);

	useEffect(() => {
		if (sendProspectDsrQueryOutcome) {
			const { pDsrConversionCreated } = sendProspectDsrQueryOutcome || {};

			if (!!pDsrConversionCreated) {
				toast(
					triggerLaunchdeckToast({
						useCase: "show-success-state",
						bannerText: "Query successfully sent",
						label: "The owner of this Room has been notified and they will be in touch shortly",
						isMobileView
					})
				)

				handleCloseDrawer()
				resetSendProspectorQuery()
			}


		}

	}, [sendProspectDsrQueryOutcome, handleCloseDrawer, resetSendProspectorQuery, toast]);


	const onChangeHandler = (event) => {
		switch (event.target.name) {
			case ('nameInput'): {
				setNameError(false)
				setNameInput(event.target.value)
				break;
			}
			case ('emailInput'): {
				setEmailError(false)
				setEmailInput(event.target.value)
				break;
			}
			case ('formQueryInput'): {
				setFormQueryInput(event.target.value)
				break;
			}
			default: {
				break;
			}
		}
	};

	const handleSubmitNewProspectUser = () => {
		if (emailInput === "" || !validateEmail(emailInput)) return setEmailError(true);
		if (nameInput === "") return setNameError(true);

		if (nameInput && emailInput) {
			let { firstName, lastName } = splitNameInput(nameInput)
			const sendQueryMetadata = {
				pDsrId,
				firstName,
				lastName,
				email: emailInput.trim().toLowerCase(),
				...formQueryInput && { formQueryInput }
			};
			sendProspectorQuery(sendQueryMetadata);
		}
	};




	return (
		<Box w="100%" align="center" >
			<Box px='1em'>

				<Flex w='100%' justify='space-between' align='center' fontSize='0.875em'>
					<Box color='white'>Email<Box as='span' color='pink.500'>*</Box></Box>
				</Flex>

				{/* <Box bg='white'> */}
				<FormTextInput
					defaultValue={emailInput}
					name={'emailInput'}
					onChange={onChangeHandler}
					mb='0.2em'
					type={'email'}
					borderColor={emailError ? 'pink.500' : ""}
					bg='white'
				/>
				{/* </Box> */}

				{emailError && <Text color='pink.500' fontSize='0.8em'>Please provide a valid email</Text>}


				<Flex w='100%' justify='space-between' align='center' mt='1em'>
					<Box color='white' fontSize='0.875em'>Your Name<Box as='span' color='pink.500'>*</Box></Box>
				</Flex>
				<FormTextInput
					defaultValue={nameInput}
					name={'nameInput'}
					onChange={onChangeHandler}
					mb='0.2em'
					type={'text'}
					borderColor={nameError ? 'pink.500' : ""}
					bg='white'
				/>
				{nameError && <Text color='pink.500' fontSize='0.8em'>Please help me address you correctly</Text>}

				<Flex w='100%' justify='space-between' align='center' mt='1em'>
					<Box color='white' fontSize='0.875em'>{mainContactName ? `Your note to ${mainContactName}` : 'Your query'}</Box>
				</Flex>
				<Flex justify='flex-start' width='100%' mt='0.625em'>
					<InputTextAreaExpandable
						name={'formQueryInput'}
						onChangeHandler={onChangeHandler}
						// defaultValue={formQueryInput}
						inputValue={formQueryInput}
						bg='white'
					/>
				</Flex>

				<Flex w='100%' mt='2em'>
					<Flex justify='flex-end' w='100%'>
						<LaunchdeckButton label='Submit' fontSize="0.9em" bg='brand.500' color='gray.50' onClick={handleSubmitNewProspectUser} />
					</Flex>
				</Flex>
			</Box>
		</Box>
	)


}


function mapStateToProps(state) {
	return {
		sendProspectDsrQueryOutcome: state.sendProspectDsrQueryOutcome.results.data,
		dsrTitleRedux: state.dsrTitle.title,
		isMobileView: state.mobileViewMode.isMobileView
	};
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			sendProspectorQuery,
			resetSendProspectorQuery
		},
		dispatch,
	)

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateDsrFromProspectDsrForm));
