import { RESET_DSR_TITLE, SET_DSR_DRAFT_TITLE } from '../../actions/dsr-actions-types'

const INITIAL_STATE_DSR_DRAFT_TITLE = { title: '' }

export function dsrTitleReducer(state = INITIAL_STATE_DSR_DRAFT_TITLE, action) {
    switch (action.type) {
        case SET_DSR_DRAFT_TITLE:
            return { title: action.payload };
        case RESET_DSR_TITLE:
            return INITIAL_STATE_DSR_DRAFT_TITLE
        default:
            return state;
    }
}