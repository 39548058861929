import React  from "react";
import { Box, Flex } from "@chakra-ui/react";
// import DsrMiniTutorialModal from "../../dsr-draft/tutorial/DsrMiniTutorialModal";
// import { IoMdCheckmarkCircleOutline } from 'react-icons/io'


export default function DsrCustomPageDisplay({ contentBlock }) {
  const { contentBlockId, contentBlockType, widgetContent } = contentBlock || {};
  // const [showTutorialModal, setShowTutorialModal] = useState(false)
  const renderWidgetContent = () => {

    const renderedContent = widgetContent && widgetContent?.map((column, columnIndex) => {
      const { columnId, columnContent } = column || {};

      return (
        <Box
          key={contentBlockId + '_' + columnId + '_'}
          w={contentBlockType !== 'custom_one_column' && '100%'}
          // bg={contentBlockType !== 'custom_one_column' && 'white'}
          rounded={contentBlockType !== 'custom_one_column' && '0.25em'}
          border={contentBlockType !== 'custom_one_column' && '1px solid'}
          borderColor={contentBlockType !== 'custom_one_column' && 'gray.300'}
          ml={columnIndex > 0 && '2.6em'}
        >

          <Box>{renderColumnSubWidgetContent(columnContent)}</Box>
        </Box>
      )
    })

    if (contentBlockType === 'custom_three_column') {
      return <Flex w='100%' justify='space-between' align='stretch'>{renderedContent}</Flex>
    } else if (contentBlockType === 'custom_two_column') {
      return <Flex w='100%' justify='space-between' align='stretch'>{renderedContent}</Flex>
    } else {
      return <Box w='100%'>{renderedContent}</Box>;
    }
  }

  const renderColumnSubWidgetContent = (columnContent) => {
    if (columnContent && Array.isArray(columnContent)) {
      const renderedSubWidgets = columnContent?.map((subWidget, subWidgetIndex) => {
        const { subWidgetId, subWidgetType, subWidgetContent } = subWidget || {}
        switch (subWidgetType) {
          case ('title-text'): {
            return <Flex key={subWidgetId} w='100%' pt={subWidgetIndex === 0 && '1em'} fontSize='1.2em' fontWeight='semibold' justify='center' align='center'>{subWidgetContent}</Flex>
          }
          case ('normal-text'): {
            return <Flex key={subWidgetId} w='100%' pt={subWidgetIndex === 0 && '1em'} justify='center' align='center' mt={subWidgetIndex > 0 && '1em'} textAlign='center'>{subWidgetContent}</Flex>
          }
          case ('loom-video'): {
            return <Box key={subWidgetId} w='100%'><iframe width="100%" height='100%' title={subWidgetId} src={`${subWidgetContent}?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true`} frameBorder="0" webkitallowfullscreen="true" mozallowfullscreen="true" allowFullScreen={true}></iframe></Box>
          }
          // case ('dsr-tutorial'): {
          //   return <Flex key={subWidgetId} pt={subWidgetIndex === 0 && '1em'} mt={subWidgetIndex > 0 && '1em'}
          //     direction='column' w='100%'
          //     justify='center' align='center'  textAlign='center'
          //     cursor='pointer' 
          //     onClick={() => setShowTutorialModal(!showTutorialModal)}>
          //     <Box as={IoMdCheckmarkCircleOutline} size="3.5em" color='brand.500' />
          //     <Box mt='2.8em' mb='0.5em' fontWeight='600' fontSize='1.125em'>How to use this Room</Box>
          //     <Box fontWeight='400' fontSize='0.875em'>A practical guide to get you started in no time and help you navigate</Box>
          //     <DsrMiniTutorialModal
          //       showTutorialModal={showTutorialModal}
          //       closeTutorialModal={() => setShowTutorialModal(false)}
          //     />

          //   </Flex>
          // }
          default: {
            return null
          }
        }
      })

      return <Box w='100%'>{renderedSubWidgets}</Box>
    } else return null;
  }


  switch (contentBlockType) {
    case ('custom_one_column'): {
      return (
        <Box bg='white' rounded='0.25em' border='1px solid' borderColor='gray.300' p='1em'>
          <Flex w='100%' align='center' justify='center'>
            {renderWidgetContent()}
          </Flex>
        </Box>
      )
    }
    case ('custom_two_column'):
    case ('custom_three_column'): {
      return (
        <Box py='1em'>
          <Flex w='100%' align='center' justify='center'>
            {renderWidgetContent()}
          </Flex>
        </Box>
      )
    }
    default: {
      return null;
    }
  }



}
