import React, { useState, useEffect } from 'react';
import { Box, Flex, Spinner, Divider } from "@chakra-ui/react";

import OrgInfoDisplay from '../../../organizations/components/OrgInfoDisplay';
import ClaverModal from '../../../global/components/helper-components/components/ClaverModal';
import LaunchdeckButton from '../../../global/components/helper-components/components/LaunchdeckButton';
import StripeCustomerPortalButton from '../../../payments/components/StripeCustomerPortalButton';
import DynamicAppBannerDisplay from '../../../global/components/helper-components/components/DynamicAppBannerDisplay';
import EditOrgBillingForm from './EditOrgBillingForm';


export default function OrgBillingModule({ adminOrgProfile, getAdminOrgProfile, manageAdminOrgUsersOutcome }) {
	const [localState, setLocalState] = useState({
		orgName: "",
		orgRegisteredAddress: "",
		orgRegisteredName: "",
		orgRegisteredNumber: "",
		orgRegisteredCountry: "",
		//Upload
		uploadedLogo: "",
		orgSeats: "",
		//UX
		buttonIsLoading: false,
		noOrgFound: "",
		isAdministrator: "",
		logoImageIsLoading: true,
	})
	const [showEditOrgInfoModal, setShowEditOrgInfoModal] = useState(false);
	const [orgProfile, setOrgProfile] = useState(null)


	useEffect(() => {
		if (adminOrgProfile) {

			const {
				orgId,
				orgName,
				orgRegisteredAddress,
				orgRegisteredName,
				orgRegisteredNumber,
				orgRegisteredCountry,
				orgDescription,
				isAdministrator,
				orgSeats,
				subscriptionReminder
			} = adminOrgProfile || {};

			setOrgProfile({
				orgId,
				orgName,
				orgRegisteredCountry,
				orgRegisteredAddress,
				orgRegisteredName,
				orgRegisteredNumber,
				orgDescription
			})

			setLocalState(prevLocalState => ({
				...prevLocalState,
				isAdministrator,
				orgName,
				orgRegisteredAddress,
				orgRegisteredName,
				orgRegisteredNumber,
				orgRegisteredCountry,
				orgSeats,
				noOrgFound: false,
				subscriptionReminder
			}))
		} else {
			setLocalState(prevLocalState => ({
				...prevLocalState,
				noOrgFound: true
			}));
		}
	}, [adminOrgProfile])

	useEffect(() => {
		if (manageAdminOrgUsersOutcome) {
			const updateWithNewOutcome = (manageAdminOrgUsersOutcome) => {
				const { updatedSuccessfully, updatedOrgSeats } = manageAdminOrgUsersOutcome || {};

				if (updatedSuccessfully === true && updatedOrgSeats >=0) {
					
					setLocalState(prevState => ({
						...prevState,
						orgSeats: updatedOrgSeats
					}))
				}
			}
			updateWithNewOutcome(manageAdminOrgUsersOutcome)
		
		}
	}, [manageAdminOrgUsersOutcome])


	const {
		noOrgFound,
		isAdministrator,
		orgSeats,
		subscriptionReminder
	} = localState;



	const renderManageBilling = () => {
		if (!!subscriptionReminder) {
			return (
				<Box>
					<Flex justify='space-between' direction={['column', 'row', 'row', 'row']}>
						<Box className="user-profile-section__first-label"> Payment method </Box>
					</Flex>
					<Divider />
					<Box mt='2em'>
						<Box fontSize='0.875em' color='brandDark.500' mb='1em'>Start engaging clients & win deals ahead of competitors. Starts at just $1 a day.</Box>
						<DynamicAppBannerDisplay bannerType="subscription-nudge" isUndismissable/>					
					</Box>
				</Box>
			)
		} else {
			// TODO: Improve this by checking against the availability of stripeCustomerId to ensure stripe session can be created
			return (
				<Box>
					<Flex justify='space-between' direction={['row', 'row', 'row', 'row']}>

						<Box className="user-profile-section__first-label" display={['none', 'none', 'block', 'block']}> Invoices & payment method </Box>
						<Box className="user-profile-section__first-label" display={['block', 'block', 'none', 'none']}> Invoices </Box>

						<StripeCustomerPortalButton />

					</Flex>


					<Divider />
					<Box mt='2em'>

						<Box>
							<Box mt='2em'>
								<Box >

									<Box color='gray.500' fontSize='0.875em' lineHeight='1.25em'>Status</Box>
									<Box mt='0.5em' lineHeight='1.375em' fontWeight='400'>Active</Box>
								</Box>

								<Box mt='2em' >

									<Box color='gray.500' fontSize='0.875em' lineHeight='1.25em'>Seats</Box>
									<Box mt='0.5em' lineHeight='1.375em' fontWeight='400'>{orgSeats ? orgSeats : '-'}</Box>
								</Box>

							</Box>
							<Flex justify='flex-start' align='flex-start' mt='1.5em' >

							</Flex>
						</Box>

					</Box>
				</Box>
			)
		}
	}

	if (noOrgFound === false && isAdministrator) {
		return (
			<Flex w='100%' direction='column'>

				<Flex
					w='100%'
					justify={['space-between', 'space-between', 'space-between', 'space-between']} alignItems={['flex-start', 'center', 'center', 'center']}
				>
					<Box className="user-profile-section__first-label"> Billing details </Box>
					<LaunchdeckButton bg='brand.500' color='white' onClick={() => setShowEditOrgInfoModal(true)} label='Edit' />
				</Flex>

				<ClaverModal
					title='Edit billing details'
					handleCloseModal={() => setShowEditOrgInfoModal(false)}
					showModal={showEditOrgInfoModal}
				>
					<EditOrgBillingForm
						orgProfile={orgProfile}
						setOrgProfile={setOrgProfile}
						handleCloseModal={() => setShowEditOrgInfoModal(false)}
						getAdminOrgProfile={getAdminOrgProfile}
					/>
				</ClaverModal>
				<Divider />

				<OrgInfoDisplay mode='billing' />
				<Box mt='5em'>
					{renderManageBilling()}
					<Box mt='5em'>
						{/* <Flex alignItems='center' justify='space-between'>
							<Flex justify={['flex-start', 'center', 'center', 'center']} alignItems={['flex-start', 'center', 'center', 'center']} direction={['column', 'row', 'row', 'row']}>
								<Box className="user-profile-section__first-label"> Invoices </Box>
							</Flex>
						</Flex> */}
						<Divider />
					</Box>
				</Box>

			</Flex>
		)
	}

	if (!noOrgFound) {
		return (
			<Flex w='100%' h='70rem' alignItems='center' justify='center' height='50%' my='3%'>
				<Spinner
					thickness="5px"
					speed="0.65s"
					emptyColor="gray.200"
					color="brand.500"
					boxSize="5em"
				/>
			</Flex>
		)
	} else return null;
}