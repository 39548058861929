import React, { memo, useRef, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'

import { Box, Grid, Flex, Skeleton, Image } from '@chakra-ui/react'
import { getDsrAnalytics } from '../../analytics/actions/dsr-analytics-actions'
import DsrInterestLevelDisplay from '../components/DsrInterestLevelDisplay'
import './styles/insights-display.scss'
import timerIcon from './styles/images/timelapse.svg'
import viewsIcon from './styles/images/visibility.svg'
import clicksIcon from './styles/images/clickaction.svg'
import DsrGuestStats from './DsrGuestStats'
import { formatSecondsValueDisplay } from '../../global/helpers/time-format-helpers'
import DsrPageStats from './DsrPageStats'
import DsrActivityLog from './DsrActivityLog'
import EmptyStateBox from '../../global/styles/images/empty-state-analytics.svg';

function DsrInsightsPage({
  // Parent component
  dsrId,
  // Redux actions
  getDsrAnalytics,
  // Redux state
  dsrInsightAnalytics,
  dsrDetailedAnalytics,
  dsrStatusRedux,
  isMobileView
}) {


  const analyticsRef = useRef(false);
  const guestStatsRef = useRef(false);
  const pageStatsRef = useRef(false);

  const [localStateAnalytics, setLocalStateAnalytics] = useState({})
  const [analyticsCategory, setAnalyticsCategory] = useState('stats-overview')

  const [dsrActivityLog, setDsrActivityLog] = useState(null)
  const [dsrGuestAnalytics, setDsrGuestAnalytics] = useState(null)
  const [dsrPageAnalytics, setDsrPageAnalytics] = useState(null)

  useEffect(() => {
    if (!analyticsRef.current && dsrId) {
      document.title = 'Room Analytics Insights'
      document.description = 'Digital Sales Room Analytics Insights'

      getDsrAnalytics({ dsrId, requestType: 'retrieve-analytics-overview' })

      analyticsRef.current = true
    }
  }, [dsrId, getDsrAnalytics])


  useEffect(() => {
    if (dsrInsightAnalytics) {

      setLocalStateAnalytics(dsrInsightAnalytics)
    }
  }, [dsrInsightAnalytics])

  useEffect(() => {
    if (dsrDetailedAnalytics) {
      const { requestType, retrievedDsrAnalytics } = dsrDetailedAnalytics || {};

      switch (requestType) {
        case ("retrieve-dsr-activity-log"): {
          setDsrActivityLog(retrievedDsrAnalytics)
          break;
        }
        case ("retrieve-guest-analytics"): {
          setDsrGuestAnalytics(retrievedDsrAnalytics)
          break;
        }
        case ("retrieve-dsr-page-analytics"): {
          setDsrPageAnalytics(retrievedDsrAnalytics)
          break;
        }

        default: {
          break;
        }
      }
    }
  }, [dsrDetailedAnalytics])


  const { totalDsrEngagementTimeSeconds, dsrViewsByGuests, pageViewsByGuests, buyerEngagementLevel, noAnalyticsYet } = localStateAnalytics || {}

  const renderDetailedAnalyticsContent = () => {
    const reasonNoAnalytics = (dsrStatusRedux === 'draft') ? 'This Room has yet to be published' : 'No Guest member has visited the Room yet. Give them a nudge!'
    if (noAnalyticsYet === true) {
      return (
        <Flex w='100%' border='1px solid' borderColor='gray.100' rounded='0.25em' boxShadow='md' bg='white' py='2em' px='1em' minH='25em'>
          <Flex direction='column' justify='center' align='flex-start' w='100%'>

            <Flex justify='center' w='100%'>
              <Image src={EmptyStateBox} width='2.5em' height='2.5em' color='brand.500' />
            </Flex>
            <Flex justify='center' w='100%'>
              No analytics yet
            </Flex>

            <Flex w='100%' justify='center' mt='2em' fontSize='0.7em' color='gray.500' fontWeight='500'>
              Why?
            </Flex>

            <Flex w='100%' justify='center' mt='0.5em' fontSize='0.7em' color='brandDark.500' textAlign='center'>
              {reasonNoAnalytics}
            </Flex>
          </Flex>

        </Flex>

      )
    }

    switch (analyticsCategory) {
      case ('stats-overview'): {
        return (
          <DsrActivityLog
            dsrId={dsrId}
            dsrActivityLog={dsrActivityLog}
          />
        )
      }
      case ('guest-stats'): {
        const handleLoadGuestData = () => {
          guestStatsRef.current = true
        }

        return (
          <DsrGuestStats
            dsrId={dsrId}
            guestStatsAvailable={guestStatsRef.current}
            dsrGuestAnalytics={dsrGuestAnalytics}
            handleLoadGuestData={handleLoadGuestData}
          />
        )
      }
      case ('page-stats'): {
        const handleLoadPageData = () => {
          pageStatsRef.current = true
        }

        return (
          <DsrPageStats
            dsrId={dsrId}
            pageStatsAvailable={pageStatsRef.current}
            dsrPageAnalytics={dsrPageAnalytics}
            handleLoadPageData={handleLoadPageData}
          />
        )
      }
      default: {
        return null;
      }
    }


  }


  return (
    <Box px='1em'>
      <Flex justify='space-between' align='center'>
        <Box>
          <Box color='brandDark.500' fontSize='1.5em' lineHeight='1.4em' fontWeight='600'>Insights</Box>
          <Box color='brandDark.500' fontSize={['0.75em','0.75em','0.875em','0.875em']} fontWeight='400'>Sales intelligence analytics</Box>
        </Box>
      </Flex>

      <Box bg='white'>
        <Flex justify='flex-start' align='center' mt='1em' p='1em 1.5em' boxShadow='xs'>
          <Flex w='100%' gap='1em' flexWrap='wrap'>
            <Box flex='1' className={(analyticsCategory === "stats-overview") ? "analytics-category-button__chosen" : "analytics-category-button__unchosen"}
              onClick={() => (analyticsCategory !== "stats-overview") && setAnalyticsCategory('stats-overview')}>Overview</Box>
            <Box flex='1'
              className={(analyticsCategory === "guest-stats") ? "analytics-category-button__chosen" : "analytics-category-button__unchosen"}
              onClick={() => (analyticsCategory !== "guest-stats") && setAnalyticsCategory('guest-stats')}>Visitors</Box>

            <Box flex='1'
              className={(analyticsCategory === "page-stats") ? "analytics-category-button__chosen" : "analytics-category-button__unchosen"}
              onClick={() => (analyticsCategory !== "page-stats") && setAnalyticsCategory('page-stats')}>Pages</Box>
          </Flex>
        </Flex>
      </Box>

      <Grid my='2em' w='100%' gap='1em' mt='1em'
        templateColumns={isMobileView ? "repeat(2, 1fr)" : "repeat(4, 1fr)"}
        templateRows={isMobileView && "repeat(2, 1fr)"}
      >
        <Box bg='white' rounded='0.25em' border='1px solid' borderColor='gray.300' p='1.25em'>
          <Box>
            <Flex display={['flex','flex','none','none']} w='100%' justify='center' align='center' >
             <Image src={timerIcon} boxSize='2em' alt="Time spent in DSR" />
            </Flex>
           
            <Flex className='stats-card__metric-display' mt={isMobileView && '1em'} direction={isMobileView ? 'column-reverse' : "column"}>
              {noAnalyticsYet ? <Box textAlign={isMobileView && 'center'}>--</Box> : (totalDsrEngagementTimeSeconds || totalDsrEngagementTimeSeconds === 0) ? <Box h='1.5em' textAlign={isMobileView && 'center'}> {formatSecondsValueDisplay(totalDsrEngagementTimeSeconds)}</Box> : <Skeleton w='5em' h='1.5em' />}
            </Flex>

            <Flex justify='space-between' direction={isMobileView && "column-reverse"}>
              <Box className='stats-card__metric-description' textAlign={isMobileView && 'center'}>Total time spent</Box>
              <Image display={['none','none','block','block']}src={timerIcon} boxSize='2em' alt="Time spent in DSR" />
            </Flex>
          </Box>
        </Box>

        <Box bg='white' rounded='0.25em' border='1px solid' borderColor='gray.300' p='1.25em'>
          <Box>
            <Flex display={['flex','flex','none','none']} w='100%' justify='center' align='center' >
             <Image src={timerIcon} boxSize='2em' alt="Time spent in DSR" />
            </Flex>
           
            <Flex className='stats-card__metric-display' mt={isMobileView && '1em'} direction={isMobileView ? 'column-reverse' : "column"}>
               {noAnalyticsYet ? <Box textAlign={isMobileView && 'center'}>--</Box> : (dsrViewsByGuests || dsrViewsByGuests === 0) ? <Box h='1.5em' textAlign={isMobileView && 'center'}> {dsrViewsByGuests}</Box> : <Skeleton w='5em' h='1.5em' />}
            </Flex>

            <Flex justify='space-between' direction={isMobileView && "column-reverse"}>
            <Box className='stats-card__metric-description' textAlign={isMobileView && 'center'}>Total Room views</Box>
              <Image display={['none','none','block','block']}src={viewsIcon} boxSize='2em' alt="Total room views" />
            </Flex>
          </Box>

        </Box>



        <Box bg='white' rounded='0.25em' border='1px solid' borderColor='gray.300' p='1.25em'>
          <Box>
            <Flex display={['flex','flex','none','none']} w='100%' justify='center' align='center' >
             <Image src={clicksIcon} boxSize='2em' alt="Time spent in DSR" />
            </Flex>
           
            <Flex className='stats-card__metric-display' mt={isMobileView && '1em'} direction={isMobileView ? 'column-reverse' : "column"}>
              {noAnalyticsYet ? <Box textAlign={isMobileView && 'center'}>--</Box> : (pageViewsByGuests || pageViewsByGuests === 0) ? <Box h='1.5em' textAlign={isMobileView && 'center'}> {pageViewsByGuests}</Box> : <Skeleton w='5em' h='1.5em' />}
            </Flex>

            <Flex justify='space-between' direction={isMobileView && "column-reverse"}>
            <Box className='stats-card__metric-description' textAlign={isMobileView && 'center'}>Total Room views</Box>
              <Image display={['none','none','block','block']}src={clicksIcon} boxSize='2em' alt="Total page views" />
            </Flex>
          </Box>
        </Box>

        <Box bg='white' rounded='0.25em' border='1px solid' borderColor='gray.300' p='1.25em'>
          <Box>
            <Flex display={['flex','flex','none','none']} w='100%' justify='center' align='center' >
              <DsrInterestLevelDisplay buyerEngagementLevel={localStateAnalytics?.buyerEngagementLevel} />
            </Flex>
           
            <Flex className='stats-card__metric-display' mt={isMobileView && '1em'} direction={isMobileView ? 'column-reverse' : "column"}>
             {noAnalyticsYet ? <Box textAlign={isMobileView && 'center'}>--</Box> : buyerEngagementLevel ? <Box h='1.5em' textTransform="capitalize" textAlign={isMobileView && 'center'}> {(buyerEngagementLevel === "intent-to-buy") ? "Target to close" : buyerEngagementLevel}</Box> : <Skeleton w='5em' h='1.5em' />}
            </Flex>

            <Flex justify='space-between' direction={isMobileView && "column-reverse"}>
              <Box className='stats-card__metric-description' textAlign={isMobileView && 'center'}>Total Room views</Box>
              <Box display={['none','none','block','block']}>
                <DsrInterestLevelDisplay buyerEngagementLevel={localStateAnalytics?.buyerEngagementLevel} />
              </Box>
            </Flex>
          </Box>
        </Box>

      </Grid>

      {renderDetailedAnalyticsContent()}

    </Box>

  )

}

const mapStateToProps = (state) => {
  return {
    dsrInsightAnalytics: state.dsrInsights.results?.data,
    dsrDetailedAnalytics: state.dsrDetailedAnalytics.results?.data,
    dsrStatusRedux: state.dsrStatus.status,
    isMobileView: state.mobileViewMode.isMobileView
  }
}


const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getDsrAnalytics
    },
    dispatch,
  )

export default memo(withRouter(connect(mapStateToProps, mapDispatchToProps)(DsrInsightsPage)))
