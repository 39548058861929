import {CHANGE_DSR_POPUP} from "../../actions/dsr-actions-types";

const INITIAL_STATE_DSR_POPUP = { UserActionRequired: '' }
export function dsrPopupReducer(state = INITIAL_STATE_DSR_POPUP, action) {
    switch (action.type) {
        case CHANGE_DSR_POPUP:
            return { UserActionRequired: action.payload };
        default:
            return state;
    }
}