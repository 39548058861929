import React, { useState, useEffect } from 'react';
import { Box, Flex, useToast } from '@chakra-ui/react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';

import WarningTextMessage from '../../../global/components/helper-components/components/WarningTextMessage';
import FormTextInput from '../../../forms/TextInputField/components/FormTextInput';
import PageLoader from '../../../global/components/helper-components/components/PageLoader';
import LaunchdeckButton from '../../../global/components/helper-components/components/LaunchdeckButton';
import { triggerLaunchdeckToast, validateEmail } from '../../../global/helpers/global-helpers';
import { resetEditGenesisUser } from '../../../profile/actions/app-admin-actions'
import appConfig from '../../../../config/app-config';

function AppAdminResetTokenForm({
  handleChange,
  localPageState,
  handleEditGenesisUser,
  // Action
  resetEditGenesisUser,
  // Redux
  editGenesisUserOutcome
}) {

  const toast = useToast();
  const { displayUrl } = appConfig;

  const { emailInput } = localPageState || {};
  const [isInvalidEmail, setIsInvalidEmail] = useState(false)
  const [showLoader, setShowLoader] = useState(false)
  const [showResetTokenSuccess, setShowResetTokenSuccess] = useState(false)
  const [linkDisplay, setLinkDisplay] = useState("")
  const [displayUserName, setDisplayUserName] = useState("")

  useEffect(() => {
    if (editGenesisUserOutcome) {

      const { status, userName, updatedLink, updateFailureReason } = editGenesisUserOutcome || {};

      if (!status || status === "unauthorized" || status === "gen-user-not-updated") {
        // Show toast
        toast(
          triggerLaunchdeckToast({
            useCase: "show-error-state",
            label: updateFailureReason ? updateFailureReason : "Error Code OQ3RXL: Unexpected Error. Please report this. ",
            isMobileView: false // No mobile view for Admin
          })
        )

        handleResetAllLocalStates()
      }


      if (status === "gen-user-updated") {
        setDisplayUserName(userName)
        setLinkDisplay(`${displayUrl}/${updatedLink}`)
        setShowResetTokenSuccess(true)
      }

      resetEditGenesisUser()
    }
  }, [editGenesisUserOutcome, displayUrl, resetEditGenesisUser, toast]);


  const handleResetAllLocalStates = () => {
    setShowLoader(false)
    setShowResetTokenSuccess(false)
    setIsInvalidEmail(false)
    setLinkDisplay("")
    setDisplayUserName("")
  }

  const handleButtonClick = () => {
    setShowLoader(true)
    const isValidEmail = validateEmail(emailInput.trim());

    if (isValidEmail) {
      handleEditGenesisUser("reset-token", { genUserEmail: emailInput })
    } else {
      setShowLoader(false)
      setIsInvalidEmail(true)
    }


  }

  const copyNewLink = () => {
    navigator.clipboard.writeText(`${linkDisplay}`)

    toast(
      triggerLaunchdeckToast({
        useCase: "show-success-state",
        label: "New invite link copied",
        isMobileView: false  // No mobile view for Admin
      })
    )

  }

  if (showResetTokenSuccess) {
    return (
      <Flex direction='column' justify='center' align='center' bg='blackAlpha.100' rounded='0.25em' p='1em' border='1px solid' borderColor='gray.300'>
        <Flex p='1em' height='100%' justify='center' align='center'><span role="img" aria-label="party-face">🥳</span> <Box ml='0.5em'>Token successfully reset for {displayUserName}</Box> </Flex>

        <Box my='2em'>
          <Box fontSize='0.875em' fontWeight='600' pl='0.5em'>New invite Link</Box>
          <Flex mt='1em' justify='space-between' align='center' bg='white' rounded='0.25em' border='1px solid' borderColor='gray.300' p='0.5em 1em'>

            <Box className="truncate-long-text" maxW="30em" maxH="2em">{linkDisplay}</Box>

            <Box flex='1' pl='1em'>
              <LaunchdeckButton bg='brand.500' color='white' label='Copy' onClick={() => copyNewLink()} />
            </Box>

          </Flex>
        </Box>

        <Box mt='1em'>
          <LaunchdeckButton bg='brandGreen.500' color='white' label={`Done`} onClick={handleResetAllLocalStates} />
        </Box>
      </Flex>

    )
  }
  if (showLoader) {
    return <PageLoader />
  }

  else {
    return (
      <Box my='2em' w='100%' templateRows="1fr 1fr auto 1fr" gap='0.5em'>
        <Box mb='0.5em'>
          <FormTextInput name={'emailInput'} label={'User email'} sublabel={''} htmlFor={'emailInput'} mb='0.2em' value={emailInput && emailInput} onChange={handleChange} />
          <WarningTextMessage errorLogic={isInvalidEmail} message='Please provide a valid email' />
        </Box>
        <Box />

        <Flex direction='column' mt='1em' justify='center' alignItems='center'>
          <Flex justify={['center', 'flex-end', 'flex-end', 'flex-end']} w='100%'>
            <LaunchdeckButton bg='brand.500' color='white' onClick={() => handleButtonClick()} label='Reset Password Token' />
          </Flex>
        </Flex>
      </Box>
    );
  }
};




function mapStateToProps(state) {
  return {
    editGenesisUserOutcome: state.editGenesisUserOutcome.results.data
  };
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      resetEditGenesisUser
    },
    dispatch,
  )

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppAdminResetTokenForm));