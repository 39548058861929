import * as types from '../actions/public-dsr-actions-types';


// Get Public DSR Config
const INITIAL_STATE_GET_PUBLIC_DSR_CONFIG = {
	isLoading: false,
	results: {},
	error: '',
};

export function getPublicDsrConfigByDsrIdReducer(state = INITIAL_STATE_GET_PUBLIC_DSR_CONFIG, action) {
	switch (action.type) {
		case types.GET_PUBLIC_DSR_CONFIG_REQUEST:
			return { ...state, isLoading: true, results: {} };
		case types.GET_PUBLIC_DSR_CONFIG_SUCCESS:
			return { ...state, isLoading: false, results: action.payload };
		case types.GET_PUBLIC_DSR_CONFIG_FAILURE:
			return { ...state, isLoading: false, error: 'Error while trying to fetch public dsr configurations' };
		default:
			return state;
	}
}

