import auth from '../../../config/app-specific-config/auth';
export const JWT_TOKEN_KEY = 'JWT_TOKEN_KEY';

// Check if user is logged in
export const checkIsLoggedInUser = () => {
	if (auth.isLoggedIn() !== null) {
		return true
	} else return false
}


