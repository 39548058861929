import auth from "../../../config/app-specific-config/auth";
import {Redirect} from "react-router-dom";
import React from "react";
import {connect} from "react-redux";

const AuthProvider = ({children, props, signout}) => {
    const excluded = ["/", "/home"]
    const redirectUrl = `${props.location.pathname}${props.location.search}`
    const isManualLogout = props.location.hash === '#logout'

  if (signout?.results?.sign_out || !auth.isLoggedIn())
        return (excluded.includes(redirectUrl) || isManualLogout) ? <Redirect to='/login' /> : <Redirect to={`/login?redirect=${redirectUrl}`} />

    return <>{children}</>
};

const mapStateToProps = state => ({
    signout: state.auth.data.signout
});
export default connect(mapStateToProps)(AuthProvider);