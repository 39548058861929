import React from "react";
import moment from "moment";

import { Box, Flex, Avatar } from "@chakra-ui/react";
import { formatDisplayLocalDateTime } from "../../helpers/timestamp-display-helper";
import { getUserDetailsUsingDsrMembersArray } from "../../../profile/helpers/user-helper";
import { Mention, MentionsInput } from "react-mentions";
import { mentionStyleInReply } from "../../../forms/TextareaField/components/InputTextAreaWithUserTagging";


export default function PublicDsrRepliesDisplay(props) {

  const {
    dsrTeamMembers,
    messageRepliesArray,
    postId
  } = props;

  if (!messageRepliesArray) {
    return null;
  }

  const displayReplies = messageRepliesArray?.map((reply, replyIndex) => {
    const { replyCreatedAt, replyStatus, replyContent, replyUserId, replyUpdatedAt, replyDeletedAt } = reply;

   
    const isEditedReply = (replyUpdatedAt) ? true : false;

    const authorProfile = getUserDetailsUsingDsrMembersArray(dsrTeamMembers, replyUserId);
    const { memberName, memberProfilePicSrc } = authorProfile || {}

    if (replyStatus === 'deleted') {
      return (
        <React.Fragment key={`message_reply_${postId}${replyIndex}`}>
          <Flex px='0.5em' width='100%' mt='2em'>
            <Box pt='0.5em'>
              <Avatar h='4.2em' w='4.2em' name={memberName} bg='gray.100' color='gray.700' src={memberProfilePicSrc} />
            </Box>
            <Box ml='0.8em' p='1em' bg='gray.100' rounded='0.25em' width='100%'>
              <Flex align='center' justify='space-between'>
                <Flex align='center' justify='flex-start'>
                  <Box fontSize='0.8em' fontWeight='600' lineHeight='1.25em'>{memberName}</Box>
                </Flex>
              </Flex>

              <Box mt='1em' color='gray.500' fontSize='0.85em' lineHeight='1.25em' fontWeight='400' className="white-space-preline">Message deleted on {formatDisplayLocalDateTime(replyDeletedAt)}</Box>
            </Box>
          </Flex>
        </React.Fragment>
      )
    }

    return (<React.Fragment key={`message_reply_${postId}${replyIndex}`}>
      <Flex px='0.5em' width='100%' mt='1em'>
       <Box pt='0.5em' display={['none','none','block','block']}>
          <Avatar h='4.2em' w='4.2em' name={memberName} bg='gray.100' color='gray.700' src={memberProfilePicSrc} />
        </Box>

        <Box ml={['0','0','0.8em','0.8em']} p='0.5em 1em' bg='gray.100' rounded='0.25em' width='100%'>  
          <Flex align='center' justify='space-between'>
            <Flex align='center'>
              <Box pr='0.5em' display={['block','block','none','none']}>
                <Avatar h='3em' w='3em' name={memberName} bg='gray.100' color='gray.700' src={memberProfilePicSrc} />
              </Box>
              <Flex align={['left', 'left', 'center', 'center']} justify='flex-start' direction={['column', 'column', 'row', 'row']}>
                <Box fontSize='0.8em' fontWeight='600' lineHeight='1.25em'>{memberName}</Box>
                <Box color='gray.500' fontSize='0.75em' px={['0', '0', '0.5em', '0.5em']} fontWeight='400' lineHeight='1em'>{moment(replyCreatedAt).fromNow()}</Box>
                {isEditedReply ? <Box color='gray.600' px='0.5em' fontSize='0.75em' fontWeight='400'>EDITED</Box> : null}
              </Flex>
            </Flex>
          </Flex>

          <Box mt='1em' color='brandDark.500' fontSize='0.85em' lineHeight='1.25em' fontWeight='400' className="white-space-preline">
            {/* {replyContent} */}
            <MentionsInput
              readOnly={true}
              value={replyContent}
            >
              <Mention
                markup='@[__display__]userId-(__id__)'
                style={mentionStyleInReply}
                displayTransform={(id, display) => { return `@${display}` }}
              />
            </MentionsInput>
          </Box>
        </Box>

      </Flex>
    </React.Fragment>)
  }
  )

  return (
    <React.Fragment>
      <Box >
        {displayReplies}
      </Box>
    </React.Fragment>
  );

}