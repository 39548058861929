import React, { useState } from 'react';
import { Box, Flex, Modal, ModalOverlay, ModalBody, ModalContent, Image } from '@chakra-ui/react';
import LaunchdeckButton from '../../global/components/helper-components/components/LaunchdeckButton';
import NewUnlockedFeaturesImage from '../styles/images/self-onboard-new-features.png';
import NewUnlockedHowToGuideImage from '../styles/images/self-onboard-how-to-guide.png';
import NewUnlockedCreateRoom from '../styles/images/self-onboard-create-room.png';

export default function OnboardingModal({ showModal, contentType, handleCloseModal, handleCreateFirstRoom }) {

  const [selfOnboardSlideNumber, setSelfOnboardSlideNumber] = useState("slide-one")

  const handleClickOk = () => {
    switch (contentType) {
      case ("genesis"): {
        handleCloseModal()
        break;
      }
      case ("demo-user-self-onboarding"): {
        handleCreateFirstRoom()
        handleCloseModal()
        break;
      }
      default: {
        break;
      }
    }
  }

  const displayContent = () => {
    return (
      <Box>
        <Flex direction='column' mt='1em' justify='center' alignItems='center' px='2em'>
          <Box w='100%' mb='2em' fontSize='1.2em' color='brandDark.500' fontWeight='500' textAlign='center'>
            Welcome to the Rooms Vault
          </Box>
          <Box w='100%' mb='3em' fontSize='0.875em' color='brandDark.500' fontWeight='500' textAlign='center'>
            <Box >
              The Vault page lets you find & manage your Rooms easily.
            </Box>
            <Box >
              Start exploring, or try creating your first Room
            </Box>
          </Box>

          <Flex mt='3em' justify='center' align='center' w='100%'>
            <LaunchdeckButton mr='1em' bg='brand.500' color='white' onClick={() => handleClickOk()} label='Start exploring' />
          </Flex>
          <Box w='100%' mt='1em' fontSize='0.75em' color='gray.500' fontWeight='500' textAlign='center'>
            If you ever need assistance, use the Support page and we'll get a real human to help you
          </Box>

        </Flex>

      </Box>
    )
  }

  const displaySelfOnboardContent = () => {
    // Slide 1: Graphics - Highlight new stuff in Vault page
    // Slide 2: Graphics - Highlight how to guide
    // Slide 3: Graphics - Build A Room
    switch (selfOnboardSlideNumber) {
      case ("slide-one"): {
        return (
          <Box>
            <Flex direction='column' mt='1em' justify='center' alignItems='center' px='2em'>
              <Box w='100%' mb='2em' fontSize='1.2em' color='brandDark.500' fontWeight='600' textAlign='center'>
                Welcome to the Sales Team View
              </Box>
              <Box w='100%' mb='3em' px='3em' fontSize='0.875em' color='brandDark.500' fontWeight='500' textAlign='center'>
                <Box >
                  New features, such as creating Rooms, Propspectors
                </Box>
                <Box >
                  and Sales Insights are now available to you
                </Box>
              </Box>
              <Image src={NewUnlockedFeaturesImage} />

              <Flex mt='3em' mb='2em' justify='center' align='center' w='100%'>
                <LaunchdeckButton bg='brand.500' color='white' onClick={() => setSelfOnboardSlideNumber("slide-two")} label='Next - getting assistance' />
              </Flex>
            </Flex>

          </Box>
        )
      }
      case("slide-two"):{
        return (
          <Box>
          <Flex direction='column' mt='1em' justify='center' alignItems='center' px='2em'>
            <Box w='100%' mb='2em' fontSize='1.2em' color='brandDark.500' fontWeight='600' textAlign='center'>
              Guide to get you up to speed quickly
            </Box>
            <Box w='100%' mb='3em' px='3em' fontSize='0.875em' color='brandDark.500' fontWeight='500' textAlign='center'>
              <Box >
                We have a how-to guide to getting specific tasks done, step-by-step.
              </Box>
              <Box >
                All written in simple, direct language.
              </Box>
            </Box>
            <Image src={NewUnlockedHowToGuideImage} />
            <Flex mt='3em' justify='center' align='center' w='100%'>
              <LaunchdeckButton mr='1em' bg='brand.500' color='white' onClick={() => setSelfOnboardSlideNumber("slide-three")} label='Next - getting started' />
            </Flex>
            <Box w='100%' mt='1em' fontSize='0.75em' color='gray.500' fontWeight='500' textAlign='center'>
              For even further assistance, use the Support page for anything else
            </Box>
          </Flex>
        </Box>
        )
      }
      case("slide-three"):{
        return (
          <Box>
          <Flex direction='column' mt='1em' justify='center' alignItems='center' px='2em'>
            <Box w='100%' mb='2em' fontSize='1.2em' color='brandDark.500' fontWeight='600' textAlign='center'>
              Take it for a test drive
            </Box>
            <Box w='100%' mb='3em' px='3em' fontSize='0.875em' color='brandDark.500' fontWeight='500' textAlign='center'>
                See how your team can leverage Launchdeck to achieve your desired business objectives
            </Box>
            <Image src={NewUnlockedCreateRoom} maxW='25em'/>
            <Flex mt='3em' mb='2em' justify='center' align='center' w='100%'>
              <LaunchdeckButton mr='1em' bg='brand.500' color='white' onClick={() => handleClickOk()} label='Create your first Room' />
            </Flex>
          </Flex>

        </Box>
        )
      }
      default: {
        return null;
      }
    }
  }
  

switch (contentType) {
  case ("genesis"): {
    return (
      <Modal isOpen={showModal} closeOnOverlayClick={false} isCentered={true} >
        <ModalOverlay className="modal-background" />
        <ModalContent rounded='0.25em' minW={['90%', '500px']} maxW='700px'>
          <ModalBody px='0em'>
            <Flex direction='column' justify='space-around' alignItems='center' w='100%' p='1em'>

              <React.Fragment>
                {displayContent()}
              </React.Fragment>

            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    )
  }
  case ("demo-user-self-onboarding"): {
    return (
      <Modal isOpen={showModal} closeOnOverlayClick={false} isCentered={true} >
        <ModalOverlay className="modal-background" />
        <ModalContent rounded='0.25em' minW={['90%', '500px']} maxW='700px'>
          <ModalBody px='0em'>
            <Flex direction='column' justify='space-around' alignItems='center' w='100%' p='1em'>
              {displaySelfOnboardContent()}
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    )
  }
  default: {
    return null;
  }
}
  // if (contentType === "genesis") {
  //   return (
  //     <Modal isOpen={showModal} closeOnOverlayClick={false} isCentered={true} >
  //       <ModalOverlay className="modal-background" />
  //       <ModalContent rounded='0.25em' minW={['90%', '500px']} maxW='700px'>
  //         <ModalBody px='0em'>
  //           <Flex direction='column' justify='space-around' alignItems='center' w='100%' p='1em'>

  //             <React.Fragment>
  //               {displayContent()}
  //             </React.Fragment>

  //           </Flex>
  //         </ModalBody>
  //       </ModalContent>
  //     </Modal>
  //   )
  // } else if () {
  //   return (
  //     <Modal isOpen={showModal} closeOnOverlayClick={false} isCentered={true} >
  //       <ModalOverlay className="modal-background" />
  //       <ModalContent rounded='0.25em' minW={['90%', '500px']} maxW='700px'>
  //         <ModalBody px='0em'>
  //           <Flex direction='column' justify='space-around' alignItems='center' w='100%' p='1em'>

  //             <React.Fragment>
  //               {displayContent()}
  //             </React.Fragment>

  //           </Flex>
  //         </ModalBody>
  //       </ModalContent>
  //     </Modal>
  //   )
  // } else {
  //   return null;
  // }
}

