import React, { useState, useEffect, memo, useRef } from 'react';

import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux';

import { Box, Flex, Modal, ModalOverlay, ModalBody, ModalContent, Switch, Text } from '@chakra-ui/react';
import LaunchdeckCloseButton from '../../../global/components/helper-components/components/LaunchdeckCloseButton';
import { MdWorkOutline } from 'react-icons/md';
import LaunchdeckButton from '../../../global/components/helper-components/components/LaunchdeckButton.jsx';
import { createDuplicateDsr } from '../../../digital-sales-room/actions/dsr-actions.js';
import DsrGenericLoadingModal from '../../../digital-sales-room/components/DsrGenericLoadingModal';

function DuplicateDsrModal(props) {
  const {
    handleCloseModal,
    // Actions
    createDuplicateDsr,
    // Redux states
    isMobileView,
    modalTransferPayload
  } = props || {};
  
  const initRef = useRef(false);

  // UX
  const [showLoader,setShowLoader] = useState(false);

  // Local state
  const [localState, setLocalState] = useState({
    dsrIdToDuplicate: "",
    dsrTitle: "",
    isCopyFaq:true,
    isCopySharedDocs:true,
    isCopyPages: true
  })


  useEffect(() => {
    if (modalTransferPayload && !initRef.current) {
      // const { dsrIdToDuplicate } = modalTransferPayload || {};
      setLocalState(prevState => ({
        ...prevState,
        ...modalTransferPayload
      }))

      initRef.current = true
    }

  }, [modalTransferPayload])


  const handleClickDuplicateRoomButton = () => {
      setShowLoader(true)
      createDuplicateDsr({
        dsrIdToDuplicate: localState.dsrIdToDuplicate,
        isCopyPages: localState.isCopyPages,
        isCopyFaq: localState.isCopyFaq,
        isCopySharedDocs: localState.isCopySharedDocs
      })
    
  }


  const handleClickModalCloseButton = () => {
    handleCloseModal()
  }

  const renderContent = () => {
    if (showLoader) {
      return <DsrGenericLoadingModal modalText={`Working on duplicating ${localState.dsrTitle}...`} />

    } else {
      return (<React.Fragment>
        <Flex w='100%' justify='center' mt='1em'>
          <Box as={MdWorkOutline} boxSize="3em" color="brand.500" />
        </Flex>
        <Flex fontSize='1.25em' color='brandDark.500' fontWeight='600' mt='1em' mb='2em' textAlign='center'>
          <Box>Duplicating</Box> <Box ml='0.25em' fontStyle={'italic'}> {localState.dsrTitle}</Box>
        </Flex>


        <Box w="100%" align="center">

          <Flex w={isMobileView ? '100%' : '80%'} minW={!isMobileView && '30em'} direction='column' align='center' mb='2em' px='2em'>

            <Flex w='100%' my='1em'>

              <Flex justify='center' w='100%'>

                <Box w='100%' maxW={!isMobileView && '25em'} p={isMobileView ? '1em' :'1em 2em'} justify='center' rounded='0.25em' bg='gray.50' border='1px solid' borderColor='gray.300' mb={'2em'}>
                <Text textAlign={'left'} mb='1em' fontSize='0.875em' fontWeight='600'>What to copy over</Text>
                  <Flex w='100%' direction='column' justify='center' align='center' gap={4}>
               
                  <Box mb='1em' w='100%'>
                      <Flex w='100%' justify='space-between' align='center'>
                        <Box fontSize='0.875em'>Customised Pages</Box>
                        <Box display='flex' fontSize='0.875em'>
                          <Box>
                            <Switch
                              size='lg'
                              name='isCopyPages'
                              isChecked={localState.isCopyPages}
                              value={localState.isCopyPages}
                              onChange={(event) => setLocalState({
                                ...localState,
                                isCopyPages: event.target.checked
                              })}
                            />
                          </Box>
                        </Box>

                      </Flex>
                      <Box textAlign='left' mt='0.2em' fontSize='0.6em' color='gray.500'>Duplicate all your pages & content in the new Room</Box>
                    </Box>

                    <Box mb='1em' w='100%'>
                      <Flex w='100%' justify='space-between' align='center'>
                        <Box fontSize='0.875em'>FAQ page</Box>
                        <Box display='flex' fontSize='0.875em'>
                          <Box>
                            <Switch
                              size='lg'
                              name='isCopyFaq'
                              isChecked={localState.isCopyFaq}
                              value={localState.isCopyFaq}
                              onChange={(event) => setLocalState({
                                ...localState,
                                isCopyFaq: event.target.checked
                              })}
                            />
                          </Box>
                        </Box>

                      </Flex>
                      <Box textAlign='left' mt='0.2em' fontSize='0.6em' color='gray.500'>All questions & answers copied into the new Room</Box>
                    </Box>
                    
                    <Box mb='1em' w='100%'>
                      <Flex w='100%' justify='space-between' align='center'>
                        <Box fontSize='0.875em'>Shared Documents</Box>
                        <Box display='flex' fontSize='0.875em'>
                          <Box>
                            <Switch
                              size='lg'
                              name='isCopySharedDocs'
                              isChecked={localState.isCopySharedDocs}
                              value={localState.isCopySharedDocs}
                              onChange={(event) => setLocalState({
                                ...localState,
                                isCopySharedDocs: event.target.checked
                              })}
                            />
                          </Box>
                        </Box>

                      </Flex>
                      <Box textAlign='left' mt='0.2em' fontSize='0.6em' color='gray.500'>Shared documents copied into the new Room</Box>
                    </Box>

                
                  </Flex>

                </Box>
              </Flex>
            </Flex>

            <Flex w='100%'>

            </Flex>
          </Flex>

          <Flex justify='center' px='1em' py='0.625em' mb='1em'>
            <LaunchdeckButton bg='brandDark.500' color='white' px='5em' label='Duplicate Room' onClick={() => handleClickDuplicateRoomButton()} />
          </Flex>

        </Box>
      </React.Fragment>)

    }
  }




  const displayContent = () => {
    return (
      <Box>
        <Flex direction='column' mt='1em' justify='center' alignItems='center' px={isMobileView ? '1em' : '0.5em'} w='100%'>
          {renderContent()}
          {/* {localState.dsrIdToDuplicate} */}
        </Flex>
      </Box>
    )
  }


  return (
    <Modal isOpen={true} closeOnOverlayClick={false} isCentered={true} >
      <ModalOverlay className="modal-background" />
      <ModalContent rounded='0.25em' minW={['90%', '500px']} maxW='700px'>
        <ModalBody px='0em'>
          <Flex direction='column' justify='space-around' alignItems='center' w='100%' p='1em'>
            <Flex justify='flex-end' w='100%'>

              <Box pr={isMobileView ? '0em' : '1.5em'}>
                <LaunchdeckCloseButton handleCloseModal={() => handleClickModalCloseButton()} />
              </Box>
            </Flex>

            <Box my='1em' border={'1px solid'} borderColor={'gray.100'} rounded='0.25em'>
              {displayContent()}
            </Box>

          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}


const mapStateToProps = (state) => ({
  isMobileView: state.mobileViewMode.isMobileView,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createDuplicateDsr
    },
    dispatch,
  )


export default memo(withRouter(connect(mapStateToProps, mapDispatchToProps)(DuplicateDsrModal)))
