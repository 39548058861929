import * as types from '../actions/marketing-actions-types';

const INITIAL_STATE_SEND_CONTACT_LD_MESSAGE = {
	isLoading: false,
	results: {},
	error: '',
};

export function contactLaunchdeckMessageReducer(state = INITIAL_STATE_SEND_CONTACT_LD_MESSAGE, action) {
	switch (action.type) {
		case types.SEND_LAUNCHDECK_MESSAGE_RESET:
			return INITIAL_STATE_SEND_CONTACT_LD_MESSAGE;
		case types.SEND_LAUNCHDECK_MESSAGE_REQUEST:
			return { ...state, isLoading: true, results: {} };
		case types.SEND_LAUNCHDECK_MESSAGE_SUCCESS:
			return { ...state, isLoading: false, results: action.payload };
		case types.SEND_LAUNCHDECK_MESSAGE_FAILURE:
			return { ...state, isLoading: false, error: 'Error while trying to create waitlist entry' };
		default:
			return state;
	}
}