import { SET_USER_ORG_ACTIVE_TEAM_MEMBERS } from "../../../profile/actions/users-profile-actions-types";


const INITIAL_STATE_USER_ACTIVE_TEAM_ORG_MEMBERS = { orgActiveTeamMembers: "" }

export function setUserActiveOrgTeamMembersReducer(state = INITIAL_STATE_USER_ACTIVE_TEAM_ORG_MEMBERS, action) {
    switch (action.type) {
        case SET_USER_ORG_ACTIVE_TEAM_MEMBERS:
            return { orgActiveTeamMembers: action.payload }
        default:
            return state;
    }
}