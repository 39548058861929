import * as types from '../actions/payments-action-types';
// import { setupReducersPerStateType } from '../../auth/reducers/legacy-reducers';


const INITIAL_STATE_CHECKOUT_SESSION = {
	isLoading: false,
	results: "",
	error: '',
};

export function stripeCheckoutSessionReducer(state = INITIAL_STATE_CHECKOUT_SESSION, action) {
	switch (action.type) {
		case types.CREATE_CHECKOUT_SESSION_REQUEST:
			return { ...state, isLoading: true, results: {} };
		case types.CREATE_CHECKOUT_SESSION_SUCCESS:
			return { ...state, isLoading: false, results: action.payload };
		case types.CREATE_CHECKOUT_SESSION_FAILURE:
			return { ...state, isLoading: false, error: 'Error while trying to create Stripe checkout session' };
		default:
			return state;
	}
}

