import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useLocation } from 'react-router-dom';

import { manageSignup } from '../actions/auth-actions';
import { ChakraBaseProvider, Box } from '@chakra-ui/react';
import claverTheme from '../../layout/components/layout/components/theme/claverTheme';
import GeneralPageFooter from './GeneralPageFooter';
import PageLoader from '../../global/components/helper-components/components/PageLoader';
import PublicUserHeader from './PublicUserHeader';

import { getCookie } from '../../global/helpers/cookie-helpers.js'
import { manageUrlTags } from '../../global/helpers/url-tag-helpers';
import OrganicSignupPageContent from './signup-activation/OrganicSignupPageContent.jsx';
import SignupUserOnboardForm from './signup-activation/SignupUserOnboardForm.jsx';
import { redirectToNewPage } from '../../global/helpers/global-helpers.js';
import ReferToEmailContent from './signup-activation/ReferToEmailContent.jsx';
import SignupErrorStateContent from './signup-activation/SignupErrorStateContent.jsx';
import SignupUnexpectedError from './signup-activation/SignupUnexpectedError.jsx';

// import { GoogleLogin } from 'react-google-login';
// import GoogleLogo from '../styles/images/google-login-logo.svg';


function Signup({ manageSignup, login, signupOutcome }) {

	const initRef = useRef(false);
	const location = useLocation();

	const signupProcessInProgress = (signupOutcome.loading === true) ? true : "";

	// If user has an existing account already and uses 'Sign Up with Google' button
	const loginProcessInProgress = (login.loading === true) ? true : "";
	const [muid, setMuid] = useState(null);
	const [prepopEmailDisplay, setPrepopEmailDisplay] = useState(null);
	const [prepopNameDisplay, setPrepopNameDisplay] = useState(null)
	const [redirectBackRoute, setRedirectBackRoute] = useState("")
	const [displayState, setDisplayState] = useState("default")
	const [localState, setLocalState] = useState("")
	const [isExistingInvitedUser, setIsExistingInvitedUser] = useState("")

	const query = new URLSearchParams(location.search)
	const stateInstructions = query.get("state")
	const activationToken = query.get("token")
	const redirectUrl = query.get("redirect")

	const [showSignupLoader, setShowSignupLoader] = useState(false)

	// const handleGoogleLogin = async (googleData) => {
	// 	if (!googleData.error) {
	// 		props.action.googleLogin(googleData, muid)
	// 	}
	// }

	useEffect(() => {
		const cookieMuid = getCookie('cvr_muid');
		if (cookieMuid) {
			setMuid(cookieMuid)
		}

		manageUrlTags(window.location.search);
	}, []);

	useEffect(() => {
		if (location.state) {

			const { prepopEmail, prepopName, redirectBackRoute } = location.state;

			setPrepopEmailDisplay(prepopEmail)
			setRedirectBackRoute(redirectBackRoute)
			prepopName && setPrepopNameDisplay(prepopName)
		}
	}, [location.state]);

	useEffect(() => {
		if (location.pathname === "/activate") {
			// Show loading state
			// API call to get info from backend
			if (
				(stateInstructions === "initialise-user-activation"
					|| stateInstructions === "invited-user-self-signup")
				&& activationToken
				&& !initRef.current) {
				manageSignup({
					event: 'initialise-user-activation',
					payload: {
						activationToken: activationToken
					}
				})
				initRef.current = true;
			}
		}
	}, [location.pathname, stateInstructions]);

	useEffect(() => {
		if (stateInstructions) {
			setDisplayState(stateInstructions)
		}
	}, [stateInstructions]);

	useEffect(() => {
		if (signupOutcome) {

			const { processedEvent, nextEvent, retrievedPayload } = signupOutcome || {}

			switch (processedEvent) {
				case ("create-signup-user"): {
					const { signupUserCreated, redirectionLink } = retrievedPayload || {};

					if (signupUserCreated === true && redirectionLink && nextEvent === "new-signup-set-password") {
						redirectToNewPage(redirectionLink)
						// setShowSignupLoader(false)
					} else if (nextEvent) {
						if (nextEvent === "invited-user-self-signup") {
							setIsExistingInvitedUser(true)
						}
						setDisplayState(nextEvent)
						setLocalState(retrievedPayload)
						setShowSignupLoader(false)
					} else {
						setDisplayState("unexpected-error")
						setShowSignupLoader(false)
					}
					break;
				}
				case ("initialise-user-activation"): {
					setDisplayState(nextEvent)
					setLocalState(retrievedPayload)
					setShowSignupLoader(false)
					break;
				}
				case ("set-password-create-org"): {
					const { redirectToHomepage } = retrievedPayload || {}
					if (nextEvent === "log-in-and-redirect-to-homepage" && !!redirectToHomepage) {
						redirectToNewPage('/home')
						setShowSignupLoader(false)
					}
					break;
				}
				default: break;
			}
		}

	}, [signupOutcome])

	if (signupProcessInProgress || loginProcessInProgress) {
		return (
			<ChakraBaseProvider theme={claverTheme}>
				<Box minHeight='18em'><PageLoader /></Box>
			</ChakraBaseProvider>
		)
	}

	const displayContent = () => {
		switch (displayState) {
			case ("initialise-user-activation"): {
				return null
			}
			case ("new-signup-set-password"):
			case ("invited-user-self-signup"): {
				return (
					<SignupUserOnboardForm
						setupUserMetadata={localState.setupUser}
						token={activationToken ? activationToken : localState.activationToken}
						isExistingInvitedUser={isExistingInvitedUser && isExistingInvitedUser}
					/>
				)
			}
			case ("show-deleted-user-error-state"):
			case ("show-invalid-link-error"): {
				// State shows error state with OK button that goes back to sign up
				// Try to put in your email and name at sign up page again. We'll help you pick up where you left off
				return <SignupErrorStateContent />;
			}
			case ("redirect-to-genesis-user-setup-page"):
			case ("invited-user-turned-signup-set-password"):
			case ("new-org-user-activation"):
			case ("check-for-reactivation-email-to-continue"): {
				return (
					<ReferToEmailContent
						titleText={localState.titleText}
						bodyText={localState.bodyText}
						sendToEmail={localState.sendToEmail}
						redirectionLink={localState.redirectionLink}
						buttonText={localState.buttonText}
					/>
				)
			}
			case ("unexpected-error"): {
				return <SignupUnexpectedError />
			}
			case ("default"):
			default: {
				return (
					<>
						<OrganicSignupPageContent
							marketingUserTrackId={muid}
							prepopEmailDisplay={prepopEmailDisplay}
							prepopNameDisplay={prepopNameDisplay}
							setShowSignupLoader={setShowSignupLoader}
						/>
						<GeneralPageFooter />
					</>
				)
			}
		}
	}


	return (
		<ChakraBaseProvider theme={claverTheme}>
			<PublicUserHeader loginEnabled redirectUrl={redirectUrl} showBackOption={true} previousRoute={redirectBackRoute} />

			<Box height='inherit'>
				<Box className="authentication-container">
					{showSignupLoader ? <PageLoader /> : displayContent()}
				</Box>
			</Box>
		</ChakraBaseProvider>
	);

}


function mapStateToProps(state) {
	return {
		signupOutcome: state.auth.data.signup.results,
		login: state.auth.data.login,
	};
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			manageSignup,
			// googleLogin
		},
		dispatch,
	)

export default connect(mapStateToProps, mapDispatchToProps)(Signup);
