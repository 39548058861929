import React from 'react';
import { Tooltip, Icon } from '@chakra-ui/react';

export default function LaunchDeckTooltip({hoverMessage}) {		
  
  if (hoverMessage) {
    return (
      <Tooltip flex='1 1 0' label={hoverMessage} ml='1em' fontSize='0.8em' placement="right">
        <Icon name="info" />
      </Tooltip>
		)
  } else return null
}


// Repurpose as a tool tip label