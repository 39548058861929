import React from 'react'
import ReactIconDisplay from '../../global/components/helper-components/components/ReactIconDisplay'

import { MdSpaceDashboard, MdGrade, MdLibraryBooks, MdForum, MdVisibilityOff, MdBookmarkRemove } from "react-icons/md";
import { IoCheckmarkCircleSharp, IoThumbsUpSharp, IoChevronBackSharp, IoLockOpen, IoLockClosed } from 'react-icons/io5'
import { RiToolsFill } from "react-icons/ri";
import { MdDashboard } from "react-icons/md";
import { BiSolidMessageSquareAdd } from "react-icons/bi";
import { GoAlertFill } from "react-icons/go";
import { RiFolder5Fill } from "react-icons/ri";

export function displayReactIcon(reactIconValue, fontSize, isPageVisible, isPageLocked) {
  const displayFontSize = fontSize ? fontSize : '1em';
  let reactIcon = "";

  switch(reactIconValue) {
    case('star'): {
      reactIcon = MdGrade
      break;
      //  <ReactIconDisplay reactIcon={MdGrade} fontSize={displayFontSize} />
    }
    case('circle-check'): {
      reactIcon = IoCheckmarkCircleSharp
      break;
      // <ReactIconDisplay reactIcon={IoCheckmarkCircleSharp} fontSize={displayFontSize} />
    }
    case('customised-page'): {
      reactIcon = isPageVisible === false ? MdVisibilityOff : isPageLocked ? IoLockOpen : MdLibraryBooks
      break;
      // <ReactIconDisplay reactIcon={MdLibraryBooks} fontSize={displayFontSize} />
    }
    case('overview'): {
      reactIcon = MdSpaceDashboard
      break;
      // <ReactIconDisplay reactIcon={MdSpaceDashboard} fontSize={displayFontSize} />
    }
    case('customer-references'): {
      reactIcon = IoThumbsUpSharp
      break;
      // <ReactIconDisplay reactIcon={IoThumbsUpSharp} fontSize={displayFontSize} />
    }
    case('faq'): {
      reactIcon = MdForum
      break;
      // <ReactIconDisplay reactIcon={MdForum} fontSize={displayFontSize} />
    }
    case('worktool'): {
      reactIcon = RiToolsFill
      break;
      // <ReactIconDisplay reactIcon={RiToolsFill} fontSize={displayFontSize} />
    }
    case('dashboard'): {
      reactIcon = MdDashboard
      break;
    }
    case('add-new-page'): {
      reactIcon = BiSolidMessageSquareAdd
      break;
    }
    case('back-arrow'): {
      reactIcon = IoChevronBackSharp
      break;
    }
    case('alert'): {
      reactIcon = GoAlertFill
      break;
    }
    case('folder'):{
      reactIcon = RiFolder5Fill
      break;
    }
    case('remove'): {
      reactIcon = MdBookmarkRemove
      break;
    }
    case('lock'): {
      reactIcon = IoLockClosed
      break;
    }
    case('unlock'): {
      reactIcon = IoLockOpen
      break;
    }
    default: {
      break;
    }
  }

  if (reactIcon) {
    return <ReactIconDisplay reactIcon={reactIcon} fontSize={displayFontSize} />
  } else return null
}

export const dividerWidthByType = (type) => {
  let dividerStrength = '1px';

  switch (type) {
    case 'thin':
      dividerStrength = '1px'
      break;

    case 'medium':
      dividerStrength = '2px'
      break;

    case 'bold':
      dividerStrength = '3px'
      break;

    default:
      dividerStrength = '1px';
      break
  }

  return dividerStrength
}

export const getUrlInPostContent = (postContent) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const urls = postContent.match(urlRegex);

  let postUrl = ''

  if (urls && urls.length === 1) {
    postUrl = urls[0];
  }

  return postUrl;
}


