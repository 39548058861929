import React, { useState, useEffect, memo } from 'react';

import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux';

import { Box, Flex, Image, Modal, ModalOverlay, ModalBody, ModalContent, Avatar } from '@chakra-ui/react';
import { joinPublicDsr, resetJoinPublicDsr } from '../../actions/public-dsr-actions.js'
import logo from '../../../global/styles/images/launchdeck_logo.svg';
import { getThisUserIdToken, redirectToNewPage } from '../../../global/helpers/global-helpers.js';
import PageLoader from '../../../global/components/helper-components/components/PageLoader.jsx';
import LaunchdeckCloseButton from '../../../global/components/helper-components/components/LaunchdeckCloseButton.jsx';
import LaunchdeckButton from '../../../global/components/helper-components/components/LaunchdeckButton.jsx';
import { getUserProfile } from '../../../profile/actions/users-profile-actions';

function LoggedInUserJoinRoomModal(props) {
  const {
    dsrId,
    handleCloseModal,
    // Actions
    joinPublicDsr,
    resetJoinPublicDsr,
    getUserProfile,
    // Redux states
    userAccountProfile,
    joinPublicDsrOutcome,
    joinPublicDsrIsProcessing,
    dsrDisplayLogoImages,
    isMobileView
  } = props || {};

  // Data
  const [userProfilePic, setUserProfilePic] = useState("")
  const [userNameDisplay, setUserNameDisplay] = useState("")
  // UX
  const [redirectingUser, setRedirectingUser] = useState(false);


  useEffect(() => {
    if (joinPublicDsrOutcome) {
      const { nextStep, processSuccessful } = joinPublicDsrOutcome || {}

      if (processSuccessful && nextStep) {
        switch (nextStep) {

          case ("only-redirect-to-dsr"): {
            setRedirectingUser(true)
            redirectToNewPage(dsrId ? `/dsr/${dsrId}` : `/home`)

            break;
          }
          default: {

            break;
          }
        }
      }

      resetJoinPublicDsr()
    }
  }, [joinPublicDsrOutcome, resetJoinPublicDsr])

  useEffect(() => {
    if (getUserProfile && !userAccountProfile) {
      getUserProfile(
        {
          getOrgProfile: false
        }
      )
    }
  }, [getUserProfile])


  useEffect(() => {
    if (userAccountProfile) {
      const { firstName, lastName, userProfilePicSrc } = userAccountProfile || {}
      userProfilePicSrc && setUserProfilePic(userProfilePicSrc)
      firstName && setUserNameDisplay(lastName ? firstName + ' ' + lastName : firstName)
    }
  }, [userAccountProfile])



  const handleLoginJoinRoom = () => {
    const joinPublicDsrPayload = {
      event: 'add-logged-in-user-to-room',
      userIdToken: getThisUserIdToken(),
      dsrId: dsrId
    }

    joinPublicDsr(joinPublicDsrPayload)
  }


  const handleClickModalCloseButton = () => {
    handleCloseModal()
  }

  const renderContent = () => {
    if (joinPublicDsrIsProcessing || redirectingUser) {
      return <PageLoader />
    } else {
      return (<React.Fragment>
        <Flex fontSize='1em' color='brandDark.500' fontWeight='600' my='1em' textAlign='center'>
          You are about to join this Room
        </Flex>

        <Flex fontSize='0.875em' color='gray.500' my='1em' textAlign='center'>
          Connect with everyone. Ask questions. Get insights.
        </Flex>

        <Box w="100%" align="center">
          <Box px='1em' mt='1em'>

            <Flex justify='center' w='100%'>
              <Flex p='1em' justify='center' px='3em' rounded='0.25em' bg='gray.50' border='1px solid' borderColor='gray.300'>
                <Flex w='100%' justify='center' align='center'>
                  <Avatar h="2.5em" w="2.5em" name={userNameDisplay} bg='gray.100' color='gray.700' src={userProfilePic} />
                  <Box ml='1em'>
                    {userNameDisplay}
                  </Box>

                </Flex>
              </Flex>
            </Flex>
          </Box>

          <Flex justify='center' mt='2em' px='1em' py='0.625em'>
            <LaunchdeckButton bg='brand.500' color='gray.50' px='5em' label='Join Room' onClick={() => handleLoginJoinRoom()} />
          </Flex>

        </Box>
      </React.Fragment>)

    }
  }

  const renderLogos = () => {
    const {
      displayedAudienceLogo,
      displayedHostLogo
    } = dsrDisplayLogoImages || {};

    if (displayedHostLogo) {
      return (
        <Flex w='100%' justify='center'>
          <Image src={displayedHostLogo} alt="Host Logo" width='50%' minWidth='1em' maxWidth='5em' mb='2em' />
          {displayedAudienceLogo && <Image src={displayedAudienceLogo} alt="Guest Logo" width='50%' minWidth='1em' maxWidth='5em' mb='2em' ml='1.5em' />}
        </Flex>
      )

    } else {
      return (
        <Flex w='100%' justify='center'>
          <Image src={logo} alt="Launchdeck Logo" width='50%' minWidth='1em' maxWidth='5em' mb='2em' />
        </Flex>
      )
    }
  }


  const displayContent = () => {
    return (
      <Box w='100%'>
        <Flex direction='column' mt='1em' justify='center' alignItems='center' px={isMobileView ? '1em' : '2em'} w='100%'>
          {renderLogos()}
          {renderContent()}
        </Flex>
      </Box>
    )
  }


  return (
    <Modal isOpen={true} closeOnOverlayClick={false} isCentered={true} >
      <ModalOverlay className="modal-background" />
      <ModalContent rounded='0.25em' minW={['90%', '500px']} maxW='700px'>
        <ModalBody px='0em'>
          <Flex direction='column' justify='space-around' alignItems='center' w='100%' p='1em'>
            <Flex justify='flex-end' w='100%'>

              <Box pr={isMobileView ? '0em' : '1.5em'}>
                <LaunchdeckCloseButton handleCloseModal={() => handleClickModalCloseButton()} />
              </Box>
            </Flex>

            <React.Fragment>
              {displayContent()}
            </React.Fragment>

          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}


const mapStateToProps = (state) => ({
  dsrDisplayLogoImages: state.dsrDisplayLogoImages.dsrDisplayLogos,
  joinPublicDsrOutcome: state.joinPublicDsrOutcome.results.data,
  joinPublicDsrIsProcessing: state.joinPublicDsrOutcome.isLoading,
  isMobileView: state.mobileViewMode.isMobileView,
  userAccountProfile: state.userProfile.accountUserProfile,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      joinPublicDsr,
      resetJoinPublicDsr,
      getUserProfile
    },
    dispatch,
  )

export default memo(withRouter(connect(mapStateToProps, mapDispatchToProps)(LoggedInUserJoinRoomModal)))
