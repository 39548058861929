import React, { useState, useEffect, useRef } from "react";
import { Box, Flex, Avatar, Input, InputGroup, useOutsideClick } from "@chakra-ui/react";
import AddMemberAvatarButton from "./AddMemberAvatarButton";
import { combineAllMembersArray } from "../../profile/helpers/user-helper";
import { BsCheckCircle, BsCircle } from "react-icons/bs";


export default function DsrMemberPicker(props) {

  const { actionId,
    // focusActionId, 
    mapCategoryId, setFocusActionId,
    handleCheckboxClick, dsrTeamMembers, currentAssignedMembers,
    isMobileView,
    isPagePreviewMode,
    openPreviewModal
  } = props;
  const [showDropdown, setShowDropdown] = useState(false)

  // Manage search bar
  const [originalDsrTeamMembers, setOriginalDsrTeamMembers] = useState(null) // For search function
  const [displayDsrTeamMembers, setDisplayDsrTeamMembers] = useState(null)
  const [searchInputValue, setSearchInputValue] = useState("");

  // Manage dropdown menu UX
  const memberPickerDropdownRef = useRef(null);


  useOutsideClick({
    ref: memberPickerDropdownRef,
    handler: () => {
      setSearchInputValue("")
      setShowDropdown(false)
    }
  })

  // useEffect(() => {
  //   if (actionId !== focusActionId) {
  //     setShowDropdown(false)
  //   }
  // }, [actionId, focusActionId]);

  useEffect(() => {
    if (dsrTeamMembers) {
      let teamMembersArray = [];
      const combinedTeamMembersArray = combineAllMembersArray(dsrTeamMembers);

      for (const organization of combinedTeamMembersArray) {
        const { orgTeamMembers } = organization || {};
        if (orgTeamMembers) {
          for (const member of orgTeamMembers) {
            teamMembersArray = [...teamMembersArray, member]
          }
        }
      }

      setDisplayDsrTeamMembers([...teamMembersArray])
      setOriginalDsrTeamMembers([...teamMembersArray])
    }
  }, [dsrTeamMembers]);



  const handleClickAddMemberButton = () => {
    if (isPagePreviewMode) {
      openPreviewModal()
    } else {
      setShowDropdown(!showDropdown)
      setFocusActionId(actionId)
      setSearchInputValue("")
    }
  
  }

  const handleClickCheckBox = (userId, checkLogicValue) => {
    handleCheckboxClick(mapCategoryId, actionId, userId, checkLogicValue)
  }

  const handleChange = (event) => {
    const searchTerm = event.target.value;
    setSearchInputValue(searchTerm)
    const filteredMembersArray = originalDsrTeamMembers.filter(member => member.memberName.includes(searchTerm));
    setDisplayDsrTeamMembers([...filteredMembersArray])
  }



  const renderTeamMemberList = () => {

    if (displayDsrTeamMembers) {

      const renderedMemberList = displayDsrTeamMembers.map((member) => {
        const { memberName, memberProfilePicSrc, memberStatus, userId } = member || {};
        const memberIsAssigned = currentAssignedMembers && currentAssignedMembers.includes(userId) ? true : false;

        if (memberStatus === 'active') {
          return (
            <>
              <Flex w='100%' key={`assign-to-picker-${userId}`} justify='space-apart' align='center' py='0.5em'>
                <Flex justify='flex-start' align='center'>

                  <Flex cursor='pointer' align='center' onClick={() => handleClickCheckBox(userId, !memberIsAssigned)}>
                    <Box className='flip-in-hor-bottom'>
                      {
                        (memberIsAssigned) ? <Box as={BsCheckCircle} className='flip-in-hor-bottom' color='brand.500' width='1em' height='1em' mr='0.5em' /> :
                          <Box as={BsCircle} className='flip-in-hor-bottom' color='gray.300' width='1em' height='1em' mr='0.5em' />
                      }
                    </Box>
                  </Flex>

                </Flex>
                <Flex pr='0.5em' align='center' justify='center'>
                  <Avatar
                    bg='gray.100' color='gray.700'
                    h="3em" w="3em"
                    name={memberName}
                    src={memberProfilePicSrc ? memberProfilePicSrc : ""} />
                </Flex>
                <Box fontSize='0.75em' fontWeight='400' color='brandDark.500' w='100%' textAlign={'left'} className='truncate-long-text' maxW='30em'>
                  {memberName}
                </Box>
              </Flex>

            </>

          )
        } else return null;
      })

      return renderedMemberList;

    }
  }

  return (
    <Box position='relative' ref={memberPickerDropdownRef}>
      <AddMemberAvatarButton
        handleClickAddMemberButton={handleClickAddMemberButton}
        isMobileView={isMobileView}
      />

      {showDropdown &&
        <Box position='absolute' top='2.4em' right={!isMobileView && '0em'} left={isMobileView && '0em'} className="day-picker-container">
          <Box>
            {/* <Box fontSize='0.875em' lineHeight='1.25em' textAlign='left' pl='0.5em'>Assign to</Box> */}
            <Flex w='100%' pb='0.56em' my='0.5em'>
              <Flex w='100%' justify='space-between' minWidth='max-content'>
                <InputGroup width="100%">

                  <Input
                    value={searchInputValue}
                    onChange={handleChange}
                    placeholder="Search"
                    padding="1em"
                    rounded='0.25em'
                    fontSize='0.8em'
                  />

                </InputGroup>
              </Flex>
            </Flex>
            <Box>

              <Box minW='13.5em' maxW='20em'>
                {renderTeamMemberList()}
              </Box>

            </Box>
          </Box>
        </Box>
      }
    </Box>
  );

}


