import React, { useState } from 'react';
import { Flex } from "@chakra-ui/react";
import ClaverModal from '../../../global/components/helper-components/components/ClaverModal';
import AddNewOrgUserForm from '../add-member/AddNewOrgUserForm';
import LaunchdeckButton from '../../../global/components/helper-components/components/LaunchdeckButton';
import SubscriptionNudgeModal from '../../../marketing/components/SubscriptionNudgeModal';

export default function AddOrgMemberModule(props) {
	const [showEditOrgMemberModal, setShowEditOrgMemberModal] = useState(false);
	const [showSubscriptionNudge, setShowSubscriptionNudge] = useState(false);

	const { orgIdToken, subscriptionReminder } = props || {};
	
	return (
		<React.Fragment>
			<Flex w='100%' justify='flex-end'>
					<Flex direction='column' justify='center' alignItems='center'>
						<Flex justify={['center', 'flex-end', 'flex-end', 'flex-end']} w='100%'>
							<LaunchdeckButton bg='brand.500' color='white' onClick={subscriptionReminder ? 
								()=> {	
									setShowSubscriptionNudge(true)
								} 
								: () => setShowEditOrgMemberModal(true)} label='Add User' />
						</Flex>
					</Flex>
			</Flex>

			<ClaverModal
				title='Add Team Member'
				handleCloseModal={() => setShowEditOrgMemberModal(false)}
				showModal={showEditOrgMemberModal}
			>
				<AddNewOrgUserForm
					orgIdToken={orgIdToken} 
					handleCloseModal={() => setShowEditOrgMemberModal(false)} 
					role={''} // No role defined for brand new user yet
				/>
			</ClaverModal>

			{showSubscriptionNudge && <SubscriptionNudgeModal 
			copyText="To add more users to your organization, please consider setting up the billing details first"
			handleCloseModal={()=>setShowSubscriptionNudge(false)}/>}

		</React.Fragment>
	)

}