import React from "react";

import { Button } from '@chakra-ui/react';
import { Box, Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";
import { GoKebabHorizontal } from "react-icons/go";


export default function DsrReplyOptionsButton(props) {

  const { 
    postId,
    replyId,
    handleEditPost,
    onClickDeleteReplyOption,
    confirmationType
   } = props;

   const editReplyPayload = {
    postId, 
    replyId,
    userActionRequired:'edit-dsr-reply',
   }

    
    return (
      <React.Fragment>
        <Menu closeOnSelect={true} >
          <MenuButton as={Button} rounded='0.25em' px='1em' ml='2em'>
            <Box as={GoKebabHorizontal} boxSize="2.5rem" py='3%' />
          </MenuButton>
          <MenuList  width='auto' mr={['3em','3em','0','0']} zIndex={2}>
            {/* <MenuOptionGroup title="Options" color='gray.500' fontSize='0.7em' mb='1em' type="radio"> */}

            <Box > <MenuItem name='showEditReplyModal' className='filter-menu__dropdown-option-display' onClick={()=>handleEditPost(editReplyPayload)}  >Edit Reply</MenuItem> </Box>
            <Box > <MenuItem name='showDeleteReplyModal' className='filter-menu__dropdown-option-display' onClick={()=>onClickDeleteReplyOption({postId, replyId}, confirmationType)}  >Delete Reply</MenuItem> </Box>
            
         
            {/* </MenuOptionGroup> */}
          </MenuList>
        </Menu>

        
      </React.Fragment>
    );
  
}


