import React, { useEffect } from 'react';

function EngagementTimer({resetEngagementTimer, handleResetEngagementTimer, setActiveSeconds}) {

  let inactivityTimer;
  let timerIsPaused = false;

  const IDLE_THRESHOLD = 5000; // 5 seconds (adjust as needed)

  // Reset timer when an event calls for it
  useEffect(() => {
    if (resetEngagementTimer=== true) {
      setActiveSeconds(0)
      // If there is activity, reset the inactivity timer
      clearTimeout(inactivityTimer);

      // If the timer was paused, resume it
      timerIsPaused = false;
      startTimer();

      handleResetEngagementTimer()
    }
  }, [resetEngagementTimer]);


  const startTimer = () => {
    inactivityTimer = setTimeout(() => {
      // Handle inactivity (pause the timer)
      timerIsPaused = true;
    }, IDLE_THRESHOLD);
  };

  // const stopTimer = () => {
  //   // Handle stopping the timer (clear the inactivity timer)
  //   clearTimeout(inactivityTimer);
  // };

  const handleActivity = () => {
    // Handle user activity
    if (timerIsPaused) {
      // If the timer was paused, resume it
      timerIsPaused = false;
      startTimer();
    } else {
      // If there is activity, reset the inactivity timer
      clearTimeout(inactivityTimer);
      startTimer();
    }
  };

  const handleVisibilityChange = () => {
    if (document.hidden) {
      // If the page is hidden, pause the timer and reset the inactivity timer
      timerIsPaused = true;
      clearTimeout(inactivityTimer);
    } else {
      // If the page becomes visible, resume the timer
      timerIsPaused = false;
      startTimer();
    }
  };

  // Increment active seconds every second using setInterval
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!timerIsPaused) {
        setActiveSeconds(prevSeconds => prevSeconds + 1);
      }
    }, 1000);

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [timerIsPaused]);

  // Listen for user activity on various events
  useEffect(() => {
    const activityEvents = ['load', 'mousedown', 'touchstart', 'click', 'keypress', 'mousemove', 'keydown'];

    activityEvents.forEach(eventType => {
      document.addEventListener(eventType, handleActivity);
    });

    // Listen for visibility change events
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Clean up event listeners when the component unmounts
    return () => {
      activityEvents.forEach(eventType => {
        document.removeEventListener(eventType, handleActivity);
      });
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      clearTimeout(inactivityTimer);
    };
  }, []);

  // Start the timer when the component mounts
  useEffect(() => {
    startTimer();

    // Clean up the timer when the component unmounts
    return () => {
      clearTimeout(inactivityTimer);
    };
  }, []);

  // Display the elapsed time in a user-friendly format
  // const formatTime = (seconds) => {
  //   return `${seconds} seconds`;
  // };

  // It is a hidden timer. Display nothing.
  return null;
};

  

export default EngagementTimer;

