import React from 'react';
import { Box, Flex, Image } from '@chakra-ui/react';
import EmptyStateBox from '../../../../global/styles/images/empty-state-analytics.svg';

export default function EmptyStateStandardDisplay({ reactIcon, infoText, handleCtaButtonClick, ctaButtonText }) {

  return (
    <Box>
      <Flex justify='center' mt='2em' align='center' py='2.75em' border='1px solid #E5EAEF' rounded='0.5em' bg='white'>
        <Flex direction='column' justify='center' align='center'>

        {reactIcon ? <Box as={reactIcon} boxSize="3em" color="brandDark.500" /> :  <Image src={EmptyStateBox} width='2.5em' height='2.5em' color='brand.500' />}

          <Flex fontWeight='400' fontSize='0.875em' align='center' textAlign='center' lineHeight='1.5em' color='gray.500' py='1.5em' px='1em'>
            {infoText}
          </Flex>

          {handleCtaButtonClick && ctaButtonText &&
            <Box whiteSpace='nowrap' >
              <Flex className='create-new-category-button'
                onClick={handleCtaButtonClick}>
                <Box className='create-new-category-button__button-text'>{ctaButtonText}</Box>
                {/* <Box as={IoMdAddCircle} boxSize='1.5em' className='create-new-room-button__add-icon' /> */}
              </Flex>
            </Box>
          }

        </Flex>
      </Flex>
    </Box>
  )
}
