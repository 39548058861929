import React from 'react';

import {
  Avatar,
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay
} from "@chakra-ui/react";
import LaunchdeckCloseButton from '../../../global/components/helper-components/components/LaunchdeckCloseButton';

export default function PickDsrContactPersonModal(props) {

  const { handleCloseModal, dsrTeamMembers, mainContactUserId, handleAssignMainContact } = props || {};

  const handleClickSelectButton = (userId) => {
    handleAssignMainContact('other-team-member', userId)
    handleCloseModal()
  }

  function renderSellerTeam() {
    const teamToRender = dsrTeamMembers["sellerTeam"];

    const renderTeam = teamToRender?.map((organization, organizationIndex) => {
      const { orgName, orgTeamMembers } = organization || {};

      const renderOrgMembers = Array.isArray(orgTeamMembers) && orgTeamMembers?.map((member, memberIndex) => {
        const { userId, email, memberName, memberProfilePicSrc, memberStatus, memberOrgName, memberOrgTitle } = member || {};
        const supplementaryUserInfo = (memberOrgTitle && memberOrgName) ? `${memberOrgTitle}, ${memberOrgName}` : memberOrgName ? memberOrgName : email;

        if (memberStatus === 'active' || memberStatus === 'pending') {
          return (
            <Flex key={`seller_team_member_${memberIndex}`} justify='space-between' align='center' w='100%' my='1em' px='1em'>
              <Flex align='center' justify='flex-start'>
                <Avatar h='4.2em' w='4.2em' name={memberName} bg='gray.100' color='gray.700' src={memberProfilePicSrc ? memberProfilePicSrc : ""} />
                <Box ml='1em'>
                  <Flex align='baseline' justify='flex-start'>
                    <Box>{memberName}</Box>
                  </Flex>
                  <Box fontSize='0.7em' color='gray.500'>{supplementaryUserInfo}</Box>
                </Box>
              </Flex>

              {(userId !== mainContactUserId) && <Box className="tutorial-button" onClick={() => handleClickSelectButton(userId)}>Select</Box>}
            </Flex>
          )
        } else return null;
      })

      return (
        <Box key={`${orgName}_team_member_${organizationIndex}`}>
          {renderOrgMembers}
        </Box>
      )

    }
    )

    if (!renderTeam) {
      return null
    }

    if (renderTeam.length === 0) {
      return null
    } else {
      return (
        <Box>
          <Box mt='0.5em'>{renderTeam}</Box>
        </Box>
      );
    }
  }


  function renderTeamManagementContent() {

    return (
      <React.Fragment>
        <Box mb='1em' w='100%'>
          <Box mb='2em'>
            {renderSellerTeam()}
          </Box>
        </Box>
      </React.Fragment>
    )



  }

  return (
    <Modal isOpen={true} closeOnOverlayClick={false} isCentered={true} preserveScrollBarGap={true} >
      <ModalOverlay className="modal-background" />
      <ModalContent rounded='0.25em' minW={['90%', '500px']} maxW='700px'>
        <ModalBody px='0em'>
          <Flex direction='column' justify='space-around' alignItems='center' w='100%'>

            <React.Fragment>

              <Flex justify='space-between' align='center' w='100%' >
                <Flex w='100%' p='1em' justify='flex-start' align='center' whiteSpace="pre">
                  Change main contact member
                </Flex>
                <Flex justify='flex-end' w='100%' pr='1em'><LaunchdeckCloseButton handleCloseModal={handleCloseModal} /></Flex>
              </Flex>
              <Box w='100%' p='1em'
                height='70vh'
                overflowY='auto'
              >

                {renderTeamManagementContent()}
              </Box>
            </React.Fragment>


          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
