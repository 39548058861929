import React from 'react';
import { Flex } from '@chakra-ui/react';
import DashboardNavigationMenu from '../../sidebar/components/DashboardNavigationMenu';


export default function NavigationMenuHeader({isMobileView}) {

	return (
		<Flex direction='row' bg='white' justify="space-between" pt={['0.5em','','','']}>
			{isMobileView ? null : 
			<DashboardNavigationMenu />
			}
		</Flex>
	);
	
}


