import React, {useEffect, useState} from 'react';
import {Avatar, Box, Flex, Skeleton} from "@chakra-ui/react";
import { ReactTinyLink } from "react-tiny-link";
import { Mention, MentionsInput } from "react-mentions";
import config from "../../../../config/app-config"
import LaunchdeckCloseButton from '../../../global/components/helper-components/components/LaunchdeckCloseButton';
const isMobileView = window.innerWidth < 600

export default function InputTextAreaWithUserTagging(props) {
  const {
    onChangeHandler,
    dsrTeamMembers,
    inputValue,
    placeholder,
    previewLink,
    isLoadingState,
    taggedUserIds,
    setTaggedUserIds,
    setUntaggedUserIds,
    handleDismissPreviewLink
  } = props;
  const { corsProxy } = config;

  const [initiallyTaggedUsers, setInitiallyTaggedUsers] = useState([])
  const [allDsrUsers, setAllDsrUsers] = useState([])
  const [usersAvailableForTagging, setUsersAvailableForTagging] = useState([]);

  useEffect(() => {
    //Finding and save initially tagged users when editing post
    const taggedInPostUsers = findTagsInsideInput(inputValue);

    setInitiallyTaggedUsers(taggedInPostUsers);
  }, []);


  useEffect(() => {
    //Find all dsr users
    let allUsers = [];

    if (Array.isArray(dsrTeamMembers)) {
      if (dsrTeamMembers.length > 0) {
        dsrTeamMembers.map((organization) => {
          const { orgTeamMembers } = organization || {};

          if (orgTeamMembers && Array.isArray(orgTeamMembers)) {
            orgTeamMembers.map((user) => {

              if (user.memberStatus === "active") {
                allUsers.push({
                  id: user.userId,
                  display: user.memberName,
                  avatarSrc: user.memberProfilePicSrc
                })
              }
             
              // return null
            })
          }

          // return null
        })
      }
    }

    setAllDsrUsers(allUsers);
    setUsersAvailableForTagging(allUsers);
  }, [dsrTeamMembers]);

  useEffect(() => {
    //Setting tagged users to state and filtering available for tagging
    if (inputValue.length > 0) {
      const tagsInsideInput = findTagsInsideInput(inputValue);
      const uniqueUserIds = [... new Set(tagsInsideInput)];

      if (taggedUserIds.length > 0) {
        const filteredUsers = allDsrUsers.filter((user) => {
          return !taggedUserIds.includes(user.id);
        });

        setUsersAvailableForTagging(filteredUsers);
      }
      setTaggedUserIds(uniqueUserIds)
    }
  }, [inputValue]);

  useEffect(() => {
    //Finding and save untagged users when edit post
    if (initiallyTaggedUsers) {
      const untaggedUsers = initiallyTaggedUsers.filter((userId) => !taggedUserIds.includes(userId));

      setUntaggedUserIds(untaggedUsers)
    }
  }, [taggedUserIds]);


  const findTagsInsideInput = (inputValue) => {
    const regex = /userId-\(([^)]+)\)/g;
    const matches = inputValue.match(regex);

    return matches ? matches.map(match => match.split("userId-(")[1].slice(0, -1)) : [];
  }

  if (isLoadingState) {
    return (
      <Box position="relative" width='100%'>
        <Skeleton w="50%" height="1.2em" my="0.5em"/>
      </Box>
    )
  } else return (
    <Box position="relative" width='100%' zIndex='2' fontSize='0.875em' minH='6em'>
      <MentionsInput
        style={inputStyle}
        value={inputValue}
        onChange={onChangeHandler}
        placeholder={placeholder}
        customSuggestionsContainer={(children) => {
          return <Box borderColor='gray.300' p='0.5em'>{children}</Box>
        }}
        a11ySuggestionsListLabel={"Suggested mentions"}
      >
        <Mention
          trigger="@"
          data={usersAvailableForTagging}
          markup='@[__display__]userId-(__id__)'
          style={mentionStyle}
          displayTransform={(id, display) => { return `@${display}` }}
          appendSpaceOnAdd={'true'}
          renderSuggestion={(entry) => {
            return (
              <Box display='flex' justifyContent='flex-start'>
                <Avatar mr='5px' h='2em' w='2em' name={entry.display} bg='gray.100' color='gray.700' src={entry.avatarSrc} />
                { entry.display }
              </Box>
            )
          }}
        />
      </MentionsInput>

      {previewLink &&
        <Box mt='1em'>
          <Flex w='100%' justify='flex-end' maxW='640px' mb='0.5em'>
            <LaunchdeckCloseButton bg='gray.500' color='white' handleCloseModal={() => handleDismissPreviewLink()} />
          </Flex>
          <ReactTinyLink
            cardSize="small"
            showGraphic={true}
            maxLine={2}
            minLine={1}
            url={previewLink}
            proxyUrl={corsProxy}
            onClick={(e) => { window.open(previewLink, "_blank") }}
          />
        </Box>
      }
    </Box>
  );
};

//Styling for react-mentions elements
export const inputStyle = {
  suggestions: {
    list: {
      backgroundColor: 'white',
      border: '1px solid rgba(0,0,0,0.15)',
      fontSize: 14,
    },
    item: {
      padding: '5px 10px',
      borderBottom: '1px solid rgba(0,0,0,0.15)',
      '&focused': {
        backgroundColor: '#dff4ff',
      },
    },
  },
};

export const mentionStyle = {
  top: !isMobileView ? '-1px' : '0',
  left: !isMobileView ? '-1px' : '-3px',
  position: 'relative',
  zIndex: '1',
  color: '#2568f5',
  textShadow: '1px 1px 1px white, 1px -1px 1px white, -1px 1px 1px white, -1px -1px 1px white',
  pointerEvents: 'none',
};

export const mentionStyleInReply = {
  top: !isMobileView ? '-1px' : '0',
  left: !isMobileView ? '-1px' : '-3px',
  position: 'relative',
  zIndex: '1',
  color: '#2568f5',
  textShadow: '1px 1px 1px white, 1px -1px 1px white, -1px 1px 1px white, -1px -1px 1px white',
  pointerEvents: 'none',
};
