import { formatHttpLinks } from "../../global/helpers/global-helpers";
import { getUserDetailsUsingDsrMembersArray } from "../../profile/helpers/user-helper";
import store from "../../../config/redux/store";

export const getDocData = ({ docObject }) => {
  const state = store.getState();

  const dsrTeamMembers = state.dsrTeamMembers.members;

  const { docId, docName, docLink, docOwnerId, createdAt, fileKey } =
    docObject || {};
  const formattedDocLink = docLink ? formatHttpLinks(docLink) : "";

  const userProfile = getUserDetailsUsingDsrMembersArray(
    dsrTeamMembers,
    docOwnerId,
  );

  const { memberName } = userProfile || {};

  return {
    docId,
    docName,
    createdAt,
    formattedDocLink,
    memberName,
    fileKey,
  };
};
