// User privileges
export const SET_USER_NOTIFICATIONS = 'set_user_notifications';
export const SET_PAGINATED_USER_NOTIFICATIONS = 'set_paginated_user_notifications';

// Get user notifications
export const GET_USER_NOTIFICATIONS_REQUEST = 'get_user_notifications_request';
export const GET_USER_NOTIFICATIONS_SUCCESS = 'get_user_notifications_success';
export const GET_USER_NOTIFICATIONS_FAILURE = 'get_user_notifications_failure';
// export const GET_USER_NOTIFICATIONS_RESET = 'get_user_notifications_reset';

// Mark a set of user notifications as viewed
export const MARK_USER_NOTIFICATION_AS_VIEWED_REQUEST = 'mark_user_notification_as_viewed_request';
export const MARK_USER_NOTIFICATION_AS_VIEWED_SUCCESS = 'mark_user_notification_as_viewed_success';
export const MARK_USER_NOTIFICATION_AS_VIEWED_FAILURE = 'mark_user_notification_as_viewed_failure';

// Mark ALL user notifications as viewed
export const MARK_ALL_USER_NOTIFICATIONS_AS_VIEWED_REQUEST = 'mark_all_user_notifications_as_viewed_request';
export const MARK_ALL_USER_NOTIFICATIONS_AS_VIEWED_SUCCESS = 'mark_all_user_notifications_as_viewed_success';
export const MARK_ALL_USER_NOTIFICATIONS_AS_VIEWED_FAILURE = 'mark_all_user_notifications_as_viewed_failure';