import { Box, Collapse, Flex, ScaleFade } from "@chakra-ui/react";
import { SharedDocPageTitle } from "./SharedDocPageTitle";
import { Search } from "./Search";
import SharedDocsOwnerFilter from "./SharedDocsOwnerFilter";
import { IoMdAddCircle } from "react-icons/io";
import { CreateSharedDoc } from "./CreateSharedDoc";
import { SharedDocsList } from "./SharedDocsList";
import { EmptyTable } from "./EmptyTable";
import React from "react";

export const DisplayFullState = ({
  dsrId,
  sharedDocsArray,
  searchTextInput,
  clearSearch,
  onSearchChange,
  handleSearch,
  isMobileView,
  showAddDocumentForm,
  handleFilterOwner,
  handleSharedDocOrderChange,
  dsrTeamMembers,
  setShowAddDocumentForm,
  showDeleteSpinner,
  handleDeleteSharedDoc,
  setShowDeleteSpinner,
  showPopoverDocId,
  setShowPopoverDocId,
  isOpen,
  onOpen,
  onClose,
  displaySignupPrompt,
  handleOpenSignupOrLoginModal,
  handleEditSharedDoc,
  isDsrAdmin
}) => {
  const isValidSharedDocsArrayAndNotEmpty =
    Array.isArray(sharedDocsArray) && sharedDocsArray?.length > 0;

  return (
    <Box>
      <SharedDocPageTitle />

      <Flex w="100%" direction="column" mt="2em">
        <Search
          searchTextInput={searchTextInput}
          clearSearch={clearSearch}
          onSearchChange={onSearchChange}
          handleSearch={handleSearch}
        />

        <Flex
          direction={isMobileView && "column"}
          w="100%"
          align="center"
          justify="center"
          visibility={showAddDocumentForm ? "hidden" : "block"}
        >
          <SharedDocsOwnerFilter
            handleFilterOwner={handleFilterOwner}
            dsrTeamMembers={dsrTeamMembers}
            sharedDocs={sharedDocsArray}
          />

          <Box m={isMobileView ? "1em 0" : "0 2em"}>
            <Flex
              className="create-new-category-button"
              onClick={() =>
                displaySignupPrompt
                  ? handleOpenSignupOrLoginModal()
                  : setShowAddDocumentForm(!showAddDocumentForm)
              }
            >
              <Box className="create-new-category-button__button-text">
                Add Document
              </Box>
              <Box
                as={IoMdAddCircle}
                boxSize="1.5em"
                className="create-new-room-button__add-icon"
              />
            </Flex>
          </Box>
        </Flex>

        {showAddDocumentForm && (
          <Box opacity={"1.0"}>
            <Collapse in={true} animateOpacity>
              <CreateSharedDoc
                dsrId={dsrId}
                isMobileView={isMobileView}
                setShowAddDocumentForm={setShowAddDocumentForm}
                showAddDocummentForm={showAddDocumentForm}
              />
            </Collapse>
          </Box>
        )}
      </Flex>

      {!showAddDocumentForm && (
        <ScaleFade initialScale={0.9} in={true}>
          {isValidSharedDocsArrayAndNotEmpty ? (
            <SharedDocsList
              isMobileView={isMobileView}
              isDsrAdmin={isDsrAdmin}
              sharedDocsArray={sharedDocsArray}
              showDeleteSpinner={showDeleteSpinner}
              showPopoverDocId={showPopoverDocId}
              setShowPopoverDocId={setShowPopoverDocId}
              handleSharedDocOrderChange={handleSharedDocOrderChange}
              handleDeleteSharedDoc={handleDeleteSharedDoc}
              setShowDeleteSpinner={setShowDeleteSpinner}
              isOpen={isOpen}
              onOpen={onOpen}
              onClose={onClose}
              handleEditSharedDoc={handleEditSharedDoc}
            />
          ) : (
            <EmptyTable
              searchTextInput={searchTextInput}
              clearSearch={clearSearch}
            />
          )}
        </ScaleFade>
      )}
    </Box>
  );
};
