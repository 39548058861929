import React, {useState} from 'react';
import '../styles/authentication.scss';
import logo from '../../global/styles/images/launchdeck_logo.svg';
import { Flex, Box, Image } from '@chakra-ui/react';
import { redirectToNewPage, routeToDashboardPage } from '../../global/helpers/global-helpers';
import LaunchdeckButton from '../../global/components/helper-components/components/LaunchdeckButton';

// Component holding other authentication components
export default function PublicUserHeader(props) {
	const { redirectUrl, signUpEnabled, loginEnabled, showBackOption, previousRoute } = props;
	const [imageClassName, setImageClassName] = useState('image-loading__in-progress ');
	const redirectDestination = redirectUrl ? `?redirect=${redirectUrl}` : "";

	const renderOptions = () => {

		if (showBackOption && previousRoute) {
			return (
				<Flex direction={['column','row', 'row','row']} justify='center' align='center' fontSize='0.9em'>
							<Box display={['none','inline','inline','inline']}>	<LaunchdeckButton bg='gray.100' color='brandDark.500' noHover onClick={()=>redirectToNewPage(`${previousRoute}`)} label='Go back' /></Box>
				{/* <Box fontWeight='400' display={['none','inline','inline','inline']} cursor='pointer' onClick={()=>redirectToNewPage(`${previousRoute}`)}>Go Back </Box> */}
			</Flex>
			)
		} else {
			if (signUpEnabled) {
				return (
					<Flex direction={['column','row', 'row','row']} justify='center' align='center' fontSize='0.9em'>
					<Box fontWeight='400' display={['none','inline','inline','inline']}>No account yet? </Box><Box pl='0.6em' fontWeight='400' color='brand.500' cursor='pointer' onClick={()=>redirectToNewPage(`/signup`)}>Sign up</Box>
				</Flex>
				)
			} else {
				if (!signUpEnabled && loginEnabled) {
					return (
					<Flex justify='center' align='center' fontSize='0.9em'>
						<Box fontWeight='400' display={['none','inline','inline','inline']}>Already a member? </Box><Box pl='0.6em' fontWeight='400' color='brand.500' cursor='pointer' onClick={()=>redirectToNewPage(`/login${redirectDestination}`)}>Log in</Box>
					</Flex>
					) 
				}
			}
		}
	}

	return (
		<Flex justify='flex-start' className="general-header__section">
			<Flex className='general-header__container'>
			<Box my='1.2em'>
					<Image src={logo} width='8em' maxWidth='12em' alt="Launchdeck Logo" 
						className={imageClassName} onLoad={()=>setImageClassName('image-loading__completed pointer-cursor')}  
						onClick={()=>routeToDashboardPage()}/>
			</Box>

			{renderOptions()}
			{/* // Comment out for the time being in private beta */}
			{/* {signUpEnabled && 
				<Flex direction={['column','row', 'row','row']} justify='center' align='center' fontSize='0.9em'>
					<Box fontWeight='400' display={['none','inline','inline','inline']}>No account yet? </Box><Box pl='0.6em' fontWeight='400' color='brand.500' cursor='pointer' onClick={()=>redirectToNewPage(`/signup`)}>Sign up</Box>
				</Flex>
			} */}

			{/* {!signUpEnabled && loginEnabled && <Flex justify='center' align='center' fontSize='0.9em'>
				<Box fontWeight='400' display={['none','inline','inline','inline']}>Already have an account? </Box><Box pl='0.6em' fontWeight='400' color='brand.500' cursor='pointer' onClick={()=>redirectToNewPage(`/login${redirectDestination}`)}>Log in</Box>
			</Flex>} */}

			</Flex>
		</Flex>
	);
};

