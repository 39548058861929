import { SET_DSR_DRAFT_MODAL_LOADER } from '../../actions/dsr-actions-types'

const INITIAL_STATE_DSR_DRAFT_MODAL_LOADER = { show: false }

export function showDsrModalLoaderReducer(state = INITIAL_STATE_DSR_DRAFT_MODAL_LOADER, action) {
    switch (action.type) {
        case SET_DSR_DRAFT_MODAL_LOADER:
            return { show: action.payload };
        default:
            return state;
    }
}