import React, { useEffect, useState } from 'react';
import { Box, Flex, Text, Divider, Collapse, useToast } from '@chakra-ui/react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import FormTextInput from '../../../forms/TextInputField/components/FormTextInput';
import LaunchdeckButton from '../../../global/components/helper-components/components/LaunchdeckButton';
import WarningTextMessage from '../../../global/components/helper-components/components/WarningTextMessage';
import LaunchdeckTag from '../../../global/components/helper-components/components/LaunchdeckTag';
import { displayReactIcon } from '../../../digital-sales-room/helpers/dsr-display-helpers';
import { copyToUserClipboard, triggerLaunchdeckToast } from '../../../global/helpers/global-helpers';


function AppAdminEditUserForm({
	handleChange,
	localPageState,
	isInvalidEmail,
	handleManageExistingUser,
	existingUserNotFound,
	isMobileView,
	manageExistingUserOutcome,
	manageUserIsProcessing
}) {


	const toast = useToast();
	const { emailInput, userIdToken, status, firstName, lastName, userGroupName, inviteLinkType, inviteLinkUrl } = localPageState || {};
	const [isEditMode, setIsEditMode] = useState(false)

	useEffect(() => {
		setIsEditMode(userIdToken ? true : false)
	}, [userIdToken]);

	useEffect(() => {
		if (manageExistingUserOutcome) {
			const { caseProcessed, unexpectedError } = manageExistingUserOutcome || {};
			if (caseProcessed === "edit-existing-user" && !unexpectedError) {
				toast(
					triggerLaunchdeckToast({
						useCase: "show-success-state",
						label: "User succesfully updated",
						isMobileView
					})
				)

			} else if (caseProcessed === "edit-existing-user" && !!unexpectedError) {
				toast(
					triggerLaunchdeckToast({
						useCase: "show-error-state",
						label: "Unfortunately something went wrong. Please try again later.",
						isMobileView
					})
				)

			}

		}
	}, [manageExistingUserOutcome, toast, isMobileView]);

	const handleClickCopyLink = () => {
		copyToUserClipboard(inviteLinkUrl)

		toast(
			triggerLaunchdeckToast({
				useCase: "show-success-state",
				// bannerText: "",
				label: "User invite link copied",
				isMobileView: false
			})
		)
	}


	return (
		<Box my='2em' w='100%' gap='0.5em'>
			<Box>
				<Box mt='3em' mb='1em' bg='gray.100' rounded='0.25em' p='0.875em'>
					<Box fontSize='0.875em' fontWeight='semibold'>User Email</Box>
					<Text fontSize='0.8em' color='gray.600'>Important fieid. This user will be automatically be assigned as the new Organization's Administrator</Text>
				</Box>
				<Box mb='0.5em'>
					<FormTextInput isDisabled={isEditMode ? true : false} name={'emailInput'} label={''} sublabel={''} htmlFor={'emailInput'} mb='0.2em' value={emailInput ? emailInput : ""} onChange={handleChange} />
					<WarningTextMessage errorLogic={isInvalidEmail} message='Please provide a valid email' />
				</Box>
				{existingUserNotFound && <Flex align='center' w='100%' my='1em' p='0.5em 1em' border='1px solid' rounded='0.25em' fontSize='0.875em' borderColor='brandPink.500'>
					{displayReactIcon('alert', '1.5em')}
					No such user was found
				</Flex>}


				{!isEditMode && <LaunchdeckButton loadingLogic={manageUserIsProcessing} label='Search' bg={emailInput ? 'brand.500' : "gray.200"} color={emailInput ? 'gray.50' : "brandDark.500"} dummy={emailInput ? false : true} onClick={() => emailInput && handleManageExistingUser({ isEditUser: false })} />}
			</Box>


			<Collapse in={userIdToken} animateOpacity>
				<Divider my='2em' />


				<Box mt='3em' mb='1em' bg='gray.100' rounded='0.25em' p='0.875em'>
					<Box fontSize='0.875em' fontWeight='semibold'>Retrieved User Information </Box>
					<Text fontSize='0.8em' color='gray.600'>View / Edit the information for an user here. Please use with caution.</Text>
				</Box>

				<Box px='0.875em' mt='3em'>
					<Box>
						<Box lineHeight='1.275em' fontSize='0.875em' fontWeight='600' color={'gray.700'} mt='2em' mb='1em'>
							User Status
						</Box>
						<LaunchdeckTag fontSize='0.875em' bg={status === "active" ? 'brandGreen.500' : "brandDark.500"} color='white'>
							<Box textTransform={'capitalize'}>{status}</Box>
						</LaunchdeckTag>
					</Box>

					<Flex justify='space-between' gap={16} my='1em'>

						<Box flex='1'>
							<Box lineHeight='1.275em' fontSize='0.875em' fontWeight='600' color={'gray.700'} mt='1em' mb='1em'>
								First Name
							</Box>
							<FormTextInput name={'firstName'} htmlFor={'firstName'} mb='0.2em' value={firstName && firstName} onChange={handleChange} />
						</Box>

						<Box gap='2em' />

						<Box flex='1'>
							<Box lineHeight='1.275em' fontSize='0.875em' fontWeight='600' color={'gray.700'} mt='1em' mb='1em'>
								Last Name
							</Box>
							<FormTextInput name={'lastName'} htmlFor={'lastName'} mb='0.2em' value={lastName && lastName} onChange={handleChange} />

						</Box>

					</Flex>

					<Box mt='1em'>
						<Box lineHeight='1.275em' fontSize='0.875em' fontWeight='600' color={'gray.700'} mt='2em' mb='1em'>
							User Group
						</Box>
						<LaunchdeckTag fontSize='0.875em' bg={"brandDark.500"} color='white'>
							<Box textTransform={'capitalize'}>{userGroupName}</Box>
						</LaunchdeckTag>
					</Box>

					{inviteLinkUrl &&
						<Box mt='2em'>
							<Box lineHeight='1.275em' fontSize='0.875em' fontWeight='600' color={'gray.700'} mt='2em' mb='1em'>
								Onboarding
							</Box>

							<Box>
								<Box mb='1em'>
									{inviteLinkType === "org-created-user-link" ? "Org created user" : "Genesis User"}
								</Box>
							</Box>

							<Flex mt='1em'>
								<LaunchdeckButton bg='brand.500' color='white' fontSize='0.75em' onClick={() => handleClickCopyLink()} label="Copy" />
							</Flex>
						</Box>
					}
				</Box>


			</Collapse>

			<Box />


		</Box>
	);

};

function mapStateToProps(state) {
	return {
		manageExistingUserOutcome: state.manageExistingUserOutcome.results.data,
		manageUserIsProcessing: state.manageExistingUserOutcome.isLoading,
		isMobileView: state.mobileViewMode.isMobileView
	};
}

export default withRouter(connect(mapStateToProps, null)(AppAdminEditUserForm));






