import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';

import { verifyEmail } from '../actions/auth-actions';
import PublicUserHeader from './PublicUserHeader';

import claverTheme from '../../layout/components/layout/components/theme/claverTheme';
import { GoUnverified } from "react-icons/go";
import { ChakraBaseProvider, Skeleton, Flex, Divider, Text, Box, Image, Spinner } from "@chakra-ui/react";
import EmailVerifiedIcon from '../styles/images/email-verified.svg';
import '../styles/authentication.scss';


class VerifyEmailPage extends Component {

	componentDidMount() {
		const verifyEmailToken = this.props.match.params.verifyEmailToken;
		this.props.action.verifyEmail(verifyEmailToken)
	}

	render() {
		const { data } = this.props.retrievedEmailVerificationStatus;
		const isVerified = data ? data.verifiedUser.isVerified : "";

		return (
			<ChakraBaseProvider theme={claverTheme}>
				{/* <CSSReset /> */}
				<PublicUserHeader />

				<Box className="authentication-container">
					<Box pt='5em'>
						<Flex m='auto' direction='column' justify='center' alignItems='center' bg='white' maxWidth='37.5em' minHeight='20em' pb='3rem' rounded='0.25em' className='default-box-shadow'>
							<VerificationOutcomeMessage isVerified={isVerified} />
						</Flex>
					</Box>
				</Box>

			</ChakraBaseProvider>

		);
	}
}

function VerificationOutcomeMessage({ isVerified }) {
	return (
		<>
			{
				{
					"": <React.Fragment>
						<Flex m='3%' direction='column' alignItems='center' justify='center' minHeight='38vh'>
							<Flex justifiy='center'>
								<Spinner
									thickness="0.25em"
									speed="0.65s"
									emptyColor="gray.200"
									color="brand.500"
									boxSize="5em"
									zIndex='10'
								/>
							</Flex>
							<Box mt='2em' textAlign='center' color='gray.700'>Working on it...</Box>
						</Flex>
						<Divider my='3%' />
						<Skeleton height='2.5rem' />
					</React.Fragment>,
					false:
						<Box w='100%' p='2em'>

							<Flex w='100%' justify='center'>
								<Box as={GoUnverified} boxSize="5em" color="red.400" />
							</Flex>

							<Flex alignItems='center' justify='center' direction='column'>
								<Text fontSize={'1.5em'} lineHeight='1.875em' fontWeight='500' color='gray.700' textAlign='center' mt='2.2em' >
									Expired activation link
								</Text>
								<Text fontSize={'1em'} lineHeight='1.375em' fontWeight='400' color='gray.500' textAlign='center' mt='1em' >
									Please request for a new link and activate using it
								</Text>
								<Link to={`/login`}> <Text fontSize='0.9em' color='brand.500' fontWeight='semibold' mt='2em'>Back to Launchdeck </Text></Link>
							</Flex>
						</Box>,
					true:
						<Box w='100%' p='2em'>
							<Flex w='100%' justify='center'>
								<Image src={EmailVerifiedIcon} alt="Email Verified" />
							</Flex>

							<Flex alignItems='center' justify='center' direction='column'>
								<Text fontSize={'1.2em'} lineHeight='1.875em' fontWeight='500' color='gray.700' textAlign='center' mt='2.2em' >
									Email address verified
								</Text>
								<Text fontSize={'0.875em'} lineHeight='1.375em' fontWeight='400' color='gray.500' textAlign='center' mt='1em' >
									Thanks for it. We'll now be able to send important updates and notifications to your inbox.
								</Text>
							</Flex>
						</Box>
				}[isVerified]
			}
		</>
	);
}


function mapStateToProps(state) {
	return {
		retrievedEmailVerificationStatus: state.emailVerificationStatus.results
	};
}

function mapDispatchToProps(dispatch) {
	return {
		action: bindActionCreators(
			{
				verifyEmail
			},
			dispatch
		)
	};
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VerifyEmailPage));
