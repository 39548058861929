import React, { useState } from 'react';

import { Box, Flex } from '@chakra-ui/react';
import FormTextInput from '../../forms/TextInputField/components/FormTextInput';
import { validateEmail } from '../../global/helpers/global-helpers';
import LaunchdeckButton from '../../global/components/helper-components/components/LaunchdeckButton';
import WarningTextMessage from '../../global/components/helper-components/components/WarningTextMessage';

export default function DsrAccessSetPasswordForm(props) {

	const { newUserId, newProfileToken, defaultEmail, handleSetPasswordProfile} = props || {}

	const [emailInput, setEmailInput] = useState(defaultEmail);
	const [newPasswordInput, setNewPasswordInput] = useState('');
	const [confirmPasswordInput, setConfirmPasswordInput] = useState('');
	const [showPasswordError, setShowPasswordError] = useState('');

	const [showInputError, setShowInputError] = useState(false);
	const [showValidationError, setShowValidationError] = useState(false);



	//Function
	const handleSetNewPassword = () => {
		resetAllErrorStates()
		const isValidEmail = validateEmail(emailInput)
		if (!isValidEmail) {
			setShowInputError(true)
		} else {
			if (!newPasswordInput || newPasswordInput.length < 8) {
				setShowPasswordError(true)
			} else if (newPasswordInput !== confirmPasswordInput) {
				setShowValidationError(true)
			} else if (newPasswordInput && isValidEmail && newUserId) {
				handleSetPasswordProfile(newPasswordInput, newProfileToken)
			}
		}

		
	}

	const resetAllErrorStates = () => {
		setShowInputError(false)
		setShowPasswordError(false)
		setShowValidationError(false)
	}

	const onChangeHandler = (event) => {

		switch (event.target.name) {
			case ('email'): {
				if (showValidationError) {
					setShowValidationError(false)
				}

				setEmailInput(event.target.value.trim())
				break;
			}
			case ('password-input'): {
				if (showPasswordError) {
					setShowPasswordError(false)
				}

				setNewPasswordInput(event.target.value.trim())
				break;
			}
			case ('repeat-password'): {
				if (showPasswordError) {
					setShowPasswordError(false)
				}
			
				setConfirmPasswordInput(event.target.value.trim())
				break;
			}
			default: {
				break;
			}
		}
	};



	return (
		<Box w="100%" align="center">
			<Box px='1em' mt='1em'>

				{/* <Box>
					<Flex w='100%' justify='space-between' align='center'>
						<Box>Email<Box as='span' color='pink.500'></Box></Box>
					</Flex>
					<FormTextInput
						name={'email'}
						defaultValue={email}
						mb='0.2em'
						isDisabled={true}
					/>

				</Box> */}

				<Box mt='1em' w='100%'>
				
				<FormTextInput
					label={'Email'}
					name={'email'}
					// placeholder={'me@email.com'}
					onChange={onChangeHandler}
					defaultValue={emailInput}
					isDisabled={validateEmail(emailInput)}
					mb='0.2em'
				/>
			
			</Box>
			<WarningTextMessage errorLogic={showInputError} message='Please provide a valid email' />

				<Box mt='1em'>
					<FormTextInput
						name={'password-input'}
						label={'Password'}
						onChange={onChangeHandler}
						mb='0.2em'
						borderColor={showPasswordError ? 'pink.500' : ""}
						type='password'
					/>
					<WarningTextMessage errorLogic={showPasswordError} message=' Please set a secure password that contains more than 8 characters' />
				</Box>

				<Box mt='1em'>
						<FormTextInput name={'repeat-password'} label={'Confirm Password'} sublabel={''} htmlFor={'repeatPassword'} type='password' mb='0.2em' onChange={onChangeHandler} />
						<WarningTextMessage errorLogic={showValidationError} message=' Your confirmation password did not match. Pls try again.' />
					</Box>

			</Box>

			<Flex w='100%' mt='2em' px='1em' py='0.625em'>
				<Flex justify='flex-end' w='100%'>
					<LaunchdeckButton bg='brand.500' color='gray.50' w='100%' label='Enter Room' onClick={() => handleSetNewPassword()} />
				</Flex>
			</Flex>


		</Box>
	);
};


