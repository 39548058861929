import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Box, Flex } from '@chakra-ui/react';
import ProfileFormTextInput from '../../../forms/TextInputField/components/ProfileFormTextInput';
import ClaverModal from '../../../global/components/helper-components/components/ClaverModal';
import WarningTextMessage from '../../../global/components/helper-components/components/WarningTextMessage';
import { deleteAccount } from '../../../auth/actions/auth-actions';
import LaunchdeckButton from '../../../global/components/helper-components/components/LaunchdeckButton';


function DeleteUserAccountModule(props) {
  const { firstName, lastName } = props || {};

  const confirmationText = (firstName && lastName) ? `delete-${firstName.trim()}-${lastName.trim()}-account` : "delete-account-permanently";
  const displayConfirmationText = confirmationText && confirmationText.toLowerCase().replace(/ /g, '-');
  const [deleteConfirmationText, setDeleteConfirmationText] = useState('');
  const [showConfirmationError, setShowConfirmationError] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleChange = (event) => {
    const value = event.target.value;
    setDeleteConfirmationText(value);
  }

  const handleDeleteAccount = () => {
    if (!displayConfirmationText) {
      setShowConfirmationError(true)
    } else if (displayConfirmationText !== deleteConfirmationText.trim().toLowerCase()) {
      setShowConfirmationError(true)
    } else {
      setShowModal(true)
    }
  }

  const handleExecuteDeleteAccountFinal = async () => {
    const { deleteAccount } = props.action;
    await deleteAccount({ useCase: 'delete-no-org-user' });
  }


  return (
    <Box className='limit-max-width'>
      <Box className="user-profile-section__label"> Delete account </Box>
      <Box color='gray.500' maxWidth='500px' fontSize='0.8em'>Confirm you want to delete this account by typing in the text exactly as shown below</Box>

      <ProfileFormTextInput name="delete-confirm" label="" subLabel={``} htmlFor="deleteConfirm" placeholder={displayConfirmationText} variant="outline" onChange={(event) => handleChange(event)} />
      <WarningTextMessage errorLogic={showConfirmationError} message={`Your input text did not match`} />

      <Flex direction='column' mt='1em' justify='center' alignItems='center'>
        <Flex justify={['center', 'flex-end', 'flex-end', 'flex-end']} w='100%'>
          <LaunchdeckButton bg='red.400' color='white' onClick={handleDeleteAccount} label='Delete account' />
        </Flex>
      </Flex>


      <ClaverModal
        title='Important Information'
        handleCloseModal={() => setShowModal(false)}
        showModal={showModal}
      >
        <Box p='2em'>
          <Box> We are extremely sorry to see you want to go. We want to let you know what will happen once you acknowledge and proceed with this last step.</Box>
          <Box mt='1.5em' color='gray.700'>Key Considerations</Box>
          <Box whiteSpace='pre-line' mt='1em' fontSize='0.875em' color='gray.700'>{'1. This account will be deleted immediately and this action is irreversible. We will not be able to restore your account if you were to change your mind. \n\n2. Any non-essential personal information will be removed from our system. We may be required by GDPR to retain some information (e.g. legal reasons).'}
          </Box>

          <Box mt='6em' mb='1em' fontSize='0.75em'>I have read and understood the above considerations. By clicking the button below, I thereby confirm my decision to delete this account.</Box>

          <Flex w='100%' justify='center' alignItems='center'>
            <Flex justify='center' w='100%'>
              <LaunchdeckButton w='100%' bg="red.400" color='white' onClick={handleExecuteDeleteAccountFinal} label='Confirm & Delete Account' />
            </Flex>
          </Flex>

          <Flex w='100%' mt='1em' justify='center' alignItems='center'>
            <Flex justify='center' w='100%'>
              <LaunchdeckButton w='100%' bg="brand.500" color='white' onClick={() => setShowModal(false)} label='Keep Account' />
            </Flex>
          </Flex>
        </Box>

      </ClaverModal>

    </Box>
  )
}


function mapDispatchToProps(dispatch) {
  return {
    action: bindActionCreators(
      {
        deleteAccount
      },
      dispatch
    )
  };
}


export default connect(null, mapDispatchToProps)(DeleteUserAccountModule);