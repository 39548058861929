import React, { useEffect, useState } from 'react';

import { Box, Flex } from '@chakra-ui/react';
import LaunchdeckButton from '../../global/components/helper-components/components/LaunchdeckButton';
import WarningTextMessage from '../../global/components/helper-components/components/WarningTextMessage';
import InputTextAreaExpandable from '../../forms/TextareaField/components/InputTextAreaExpandable';
import { MdEmail } from "react-icons/md";
import FormTextInput from '../../forms/TextInputField/components/FormTextInput';

export default function DsrRequestInviteForm({ handleRequestInvite, requestUserEmail, requestUsername }) {

	const [lockUsername, setLockUsername] = useState(false)
	const [requestMessageInput, setRequestMessageInput] = useState('');
	const [requestUserNameInput, setRequestUserNameInput] = useState("");
	const [showMessageInputError, setShowMessageInputError] = useState(false);
	const [showNameInputError, setShowNameInputError] = useState(false);

	useEffect(() => {
		if (requestUsername) {
			setLockUsername(true)
			setRequestUserNameInput(requestUsername)
		}
	}, [requestUsername]);

	//Function
	const handleButtonClick = () => {
		if (requestMessageInput && requestUserNameInput) {
			handleRequestInvite(requestMessageInput, requestUserNameInput)
		} else {
			if (!requestMessageInput) setShowMessageInputError(true)
			if (!requestUserNameInput) setShowNameInputError(true)

		}
	}

	const onChangeHandler = (event) => {
		setShowMessageInputError(false)

		switch (event.target.name) {
			case ('request-message'): {
				setShowMessageInputError(false)
				setRequestMessageInput(event.target.value)
				break;
			}
			case ('request-user-name'): {
				setShowNameInputError(false)
				setRequestUserNameInput(event.target.value)
				break;
			}
			default: {
				break;
			}
		}
	};


	return (
		<Box w="100%" align="center">

			{lockUsername ?
				<Box mt='1em' w='100%'>
					<Box fontSize='0.875em'>
						{requestUserNameInput}
					</Box>
				</Box>
				:
				<Box mt='1em' w='100%'>
					<FormTextInput
						name={'request-user-name'}
						placeholder={'John Doe'}
						onChange={onChangeHandler}
						defaultValue={requestUserNameInput}
						mb='0.2em'
					/>
					<WarningTextMessage errorLogic={showNameInputError} message='Help the Administrator know who they are allowing access to' />
				</Box>
			}
			<Box mt='1em' w='100%'>
				<InputTextAreaExpandable
					name={'request-message'}
					onChangeHandler={onChangeHandler}
					inputValue={requestMessageInput}
					placeholder={`Let the Room administrators know why you'd like to join the room`}
					border={'1px solid'}
				/>
			</Box>
			<WarningTextMessage errorLogic={showMessageInputError} message='A short message will help Room administrators act more quickly' />




			<Box w='100%' mt='0.25em' bg='gray.100' color='brandDark.500' rounded='0.25em' p='0.5em 1em' >
				<Flex w='100%' justify='flex-start' align='center' fontSize='0.8em'>
					<Box as={MdEmail} boxSize="1em" mr='1em' />
					<Box>{requestUserEmail}</Box>
				</Flex>
			</Box>

			<Flex w='100%' mt='2em' py='0.625em'>
				<Flex justify='center' w='100%'>
					<LaunchdeckButton bg='brand.500' color='gray.50' w='100%' label='Send Request' onClick={() => handleButtonClick()} />
				</Flex>
			</Flex>
		</Box>
	);
};

