import React from 'react';
import { Box} from '@chakra-ui/react';


const GeneralPageFooter = () => {
	var currentDate = new Date();
	var currentYear = currentDate.getFullYear();

	return (
		<Box py={['1em', '1%']} textAlign='center' fontSize='0.7em'>© {currentYear} Launchdeck All Rights Reserved • Version number: v1.3.1</Box>
	);
};

export default GeneralPageFooter;
