
import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { ProspectEditDraftPage } from './ProspectEditDraftPage'
import { withRouter } from 'react-router-dom'

import {
  getProspectDsr,
  updatePdsr,
  resetDsrPages,
  resetDsrSelectedDisplaySubpage,
  resetDsrTitle,
  // setShowDsrModal,
  // setUserActionRequired,
  updatePdsrReset,
  changeDsrPopupState,
  changeDsrModalState
} from '../../actions/dsr-actions'

// Get data
import { signOut } from '../../../auth/actions/auth-actions';


const ProspectEditDraftPageContainer = (props) => {
  return <ProspectEditDraftPage {...props} />
}

const mapStateToProps = (state) => ({
  dsrPagesRedux: state.dsrPages.pages,
  dsrTitleRedux: state.dsrTitle.title,
  dsrInvalidRequestRedux: state.dsrInvalidRequest.request,
  dsrStatusRedux: state.dsrStatus.status,
  showDsrModalRedux: state.dsrModal.showDsrModal,
  userActionRequiredRedux: state.dsrPopup.UserActionRequired,
  userProfileRedux: state.userProfile.profile,
  showDsrModalLoaderRedux: state.showDsrModalLoader.show,
	isMobileView: state.mobileViewMode.isMobileView
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getProspectDsr,
      resetDsrTitle,
      resetDsrSelectedDisplaySubpage,
      resetDsrPages,
      updatePdsrReset,
      changeDsrPopupState,
      changeDsrModalState,
      updatePdsr,
      signOut
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProspectEditDraftPageContainer),
)
