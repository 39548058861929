import { generateRandomString } from '../../global/helpers/global-helpers'

export function determineIsPostAuthor(authorUserId, currentUserId) {
  if (authorUserId.trim() === currentUserId.trim()) {
    return true
  } else return false
}

export function createNewDsrPage() {
  return  {
    "pageId": "pg__" + generateRandomString(10),
    "pageType": "customised-page",
    "pageTitle": "New Page",
    "pageContent": [],
    "displayPage": true
  }
}

export function createPlaceholderDuplicateDsrPage(referenceDsrPage) {

  // Check for PDF, images, videos. If they exist, to duplicate a copy in the backend
  const {pageId, pageTitle} = referenceDsrPage || {}
  if (pageId) {
    return {
      ...referenceDsrPage,
      pageId: "pg__" + generateRandomString(10),
      pageTitle: pageTitle ? pageTitle + " Copy" : "Duplicated page",
      awaitingPageGeneration: true
    }
  } else return null;  
}
