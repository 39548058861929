import { SET_DSR_SHARED_DOCS_OVERVIEW } from '../../actions/dsr-actions-types'

const INITIAL_STATE_SET_DSR_SHARED_DOCS_OVERVIEW = { results: '' }

export function setDsrSharedDocOverviewReducer(state = INITIAL_STATE_SET_DSR_SHARED_DOCS_OVERVIEW, action) {
    switch (action.type) {
        case SET_DSR_SHARED_DOCS_OVERVIEW:
            return { results: action.payload };
        default:
            return state;
    }
}