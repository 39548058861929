import React, { memo, useState, useEffect, useRef } from "react";
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import { Avatar, Box, Collapse, Flex, Image, Input, InputGroup, Link, Text, useDisclosure, Grid, GridItem } from "@chakra-ui/react";

import { getUserDetailsUsingDsrMembersArray } from "../../../profile/helpers/user-helper";
import { getThisUserIdToken } from "../../../global/helpers/global-helpers";
import { setDsrPages, updateDsr, resetUpdateDsrOutcome } from '../../actions/dsr-actions';

import { DsrLoomRecordButton } from '../../dsr-record-content/DsrLoomRecordButton';
import RemoveOverviewVideoConfirmationModal from "./RemoveOverviewVideoConfirmationModal";
import PickDsrContactPersonModal from "./PickDsrContactPersonModal";
import DisplayDsrTeamMemberList from "../../dsr-team-mgmt/DisplayDsrTeamMemberList";

import '../../styles/dsr-overview-display.scss';
import { IoCalendarOutline } from "react-icons/io5";
import { AiOutlineMail, AiOutlinePhone } from 'react-icons/ai'
import { MdEmergencyRecording, MdModeEditOutline } from "react-icons/md";
import loomLogo from './styles/images/loom-logo.svg';
import nominateUserEmptyState from './styles/images/nominate-user-empty-state.svg';
import { formatDisplayDateOnly, formatDisplayLocalDateTime, formatTimestamp } from "../../helpers/timestamp-display-helper";
import { determineNotificationMessage } from "../../../profile/helpers/user-notification-helper";
import { DndContext } from "@dnd-kit/core";
import { arrayMove, rectSortingStrategy, SortableContext } from "@dnd-kit/sortable";
import { SortableOverviewPageTiles } from '../page-designer/SortableOverviewPageTiles';
import LaunchdeckTag from "../../../global/components/helper-components/components/LaunchdeckTag";
import { displayElapsedDurationSinceTimestamp } from "../../../global/helpers/time-format-helpers";
import InputTextAreaExpandable from "../../../forms/TextareaField/components/InputTextAreaExpandable";
import { backwardCompatibleTiles } from "../../helpers/dsr-helpers";
import { FiExternalLink } from 'react-icons/fi';


function OverviewDraftPage(props) {

  const {
    dsrPagesLocalState, pageTitle, pageSubTitle, mainContactUserId, dsrTitleRedux,
    dsrTeamMembers, loomWelcomeVideo, textTileTitle, textTileDescription,
    updateDsrOutcome, dsrNotifications, dsrMapOverview, isMobileView,
    dsrSharedDocsOverview,
    dsrId, dsrLastSaved,
    dsrPropertiesRedux
  } = props || {};

  const ref = useRef(null);
  const { isOpen, onToggle } = useDisclosure()

  const [lastClickedInput, setLastClickedInput] = useState(null)

  const [initialOverviewPageLocalState, setInitialOverviewPageLocalState] = useState(null)
  const [overviewPageIndex, setOverviewPageIndex] = useState("")

  const [mainContactUserProfile, setMainContactUserProfile] = useState("")
  const [showPickContactModal, setShowPickContactModal] = useState(false)

  //Text Tile Title
  const [userTextTileTitle, setUserTextTileTitle] = useState(textTileTitle ? textTileTitle : "")
  const [showTextTileTitleInput, setShowTextTileTitleInput] = useState(false)

  //Text Tile Description
  const [userTextTileDescription, setUserTextTileDescription] = useState(textTileDescription ? textTileDescription : "")
  const [showTextTileDescriptionInput, setShowTextTileDescriptionInput] = useState(false)


  // Loom Video setting
  const [loomVideoObject, setLoomVideoObject] = useState(loomWelcomeVideo ? loomWelcomeVideo : null)
  const [showRemoveVideoModal, setShowRemoveVideoModal] = useState(false)

  // Edit Title
  const [showEditTitle, setShowEditTitle] = useState(false)
  const [showEditTitleInput, setShowEditTitleInput] = useState(false)
  const [userInputTitle, setUserInputTitle] = useState(pageTitle ? pageTitle : "Overview")

  // Edit Subtitle
  // const [showEditSubtitle, setShowEditSubtitle] = useState(false)
  // const [showEditSubtitleInput, setShowEditSubtitleInput] = useState(false)
  const [userInputSubtitle, setUserInputSubtitle] = useState(pageSubTitle ? pageSubTitle : "")

  const [updatedDsrPages, setUpdatedDsrPages] = useState(null)
  const {showGuestList} = dsrPropertiesRedux || {}

  // Tile arrangement
  const [tileOrder, setTileOrder] = useState([
    "intro-tile", "contact-tile", "notifications-tile", "room-members-tile", "mutual-plan-tile", "free-text-tile", "shared-doc-tile"
  ])
  const [tileDisplay, setTileDisplay] = useState({
    introTileDisplay: true,
    freeTextTileDisplay: true,
    contactTileDisplay: true,
    notificationsTileDisplay: true,
    membersTileDisplay: true,
    planTileDisplay: true,
    sharedDocTileDisplay: true
  })

  // Initialize
  useEffect(() => {
    if (dsrPagesLocalState) {
      const originalOverPage = dsrPagesLocalState.find(page => page.pageType === "overview")
      const foundIndex = dsrPagesLocalState.findIndex(page => page.pageType === "overview")
      let omittedObjects = [];

      const { tileSortOrder, tileDisplaySettings } = originalOverPage || {};

      if (originalOverPage && foundIndex >= 0) {
        setInitialOverviewPageLocalState(originalOverPage)
        setOverviewPageIndex(foundIndex)
      }

      if (Array.isArray(tileSortOrder)) {
        omittedObjects = tileOrder.filter(obj1 => !tileSortOrder.some(obj2 => obj2 === obj1))
        setTileOrder(tileSortOrder)
      }

      if (tileDisplaySettings) {
        let updatedTileDisplaySettings = "";

        if (Array.isArray(omittedObjects)) {
          const backwardCompatibilitySettings = backwardCompatibleTiles(omittedObjects)
          updatedTileDisplaySettings = {
            ...tileDisplaySettings,
            ...backwardCompatibilitySettings
          }
        }
        setTileDisplay(updatedTileDisplaySettings ? updatedTileDisplaySettings : tileDisplaySettings)
      }

      if (mainContactUserId && dsrTeamMembers) {
        setMainContactUserProfile(getUserDetailsUsingDsrMembersArray(dsrTeamMembers, mainContactUserId))
      }
    }
  }, [dsrPagesLocalState, mainContactUserId, dsrTeamMembers]);


  // Reset state after saving
  useEffect(() => {
    if (updateDsrOutcome) {
      const { updateDsrStatus, sourceEvent } = updateDsrOutcome || {};
      if (updateDsrStatus === "success") {
        if (updatedDsrPages) {
          props.action.setDsrPages(updatedDsrPages)
          setUpdatedDsrPages(null)
        }

        switch (sourceEvent) {
          case ("save-overview-title"):
          case ("save-overview-subtitle"):
          case ("save-dsr-main-contact"):
          case ("remove-loom-welcome-video"):
          case ("save-recorded-loom-welcome-video"):
          case ("save-text-tile-title"):
          case ("save-text-tile-description"): {
            // Need to reset the new local state whenever an update event is made to loom video
            props.action.resetUpdateDsrOutcome()
            break;
          }
          default: {
            break;
          }
        }
      }

    }
  }, [updateDsrOutcome, dsrId, props.action]);

  // UX: Click outside autosave
  const clickOutsideAutosave = () => {
    setShowEditTitleInput(false)
    // setShowEditSubtitleInput(false)
    setShowEditTitle(false)
    // setShowEditSubtitle(false)

    setShowTextTileTitleInput(false)
    // setShowEditTextTileTitle(false)
    setShowTextTileDescriptionInput(false)
    // setShowEditTextTileDescription(false)


    if (lastClickedInput === "title") {
      updateOverviewPage("pageTitle", { pageTitle: userInputTitle })
    } else if (lastClickedInput === "subtitle") {
      updateOverviewPage("pageSubTitle", { pageSubTitle: userInputSubtitle })
    } else if (lastClickedInput === "text-tile-title") {
      updateOverviewPage("textTileTitle", { textTileTitle: userTextTileTitle })
    } else if (lastClickedInput === "text-tile-description") {
      updateOverviewPage("textTileDescription", { textTileDescription: userTextTileDescription })
    }

    // Resets to prevent savings for subsequent user actions
    setLastClickedInput(null)
  }

  const handleHitEscapeKey = (event) => {
    if (event.key === "Escape") {
      if (lastClickedInput === "title") {
        setShowEditTitleInput(false)
        setUserInputTitle(pageTitle ? pageTitle : "Overview")
      } else if (lastClickedInput === "subtitle") {
        // setShowEditSubtitleInput(false)
        setUserInputSubtitle(pageSubTitle ? pageSubTitle : "")
      } else if (lastClickedInput === "text-tile-title") {
        setShowTextTileTitleInput(false)
        setUserTextTileTitle(textTileTitle ? textTileTitle : "Insert Title Text")
      } else if (lastClickedInput === "text-tile-description") {
        setShowTextTileDescriptionInput(false)
        setUserTextTileDescription(textTileDescription ? textTileDescription : "Some description text here")
      }
    }
  };

  const handleClickEditInput = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      clickOutsideAutosave()
    } else {
      setLastClickedInput(event.target.name)
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleHitEscapeKey, true);
    document.addEventListener("click", handleClickEditInput, true);
    return () => {
      document.removeEventListener("keydown", handleHitEscapeKey, true);
      document.removeEventListener("click", handleClickEditInput, true);
    };
  });

  const handleClosePickContactModal = () => {
    setShowPickContactModal(false)
  }

  const handleCloseRemoveVideoConfirmationModal = () => {
    setShowRemoveVideoModal(false)
  }

  const updateOverviewPage = (property, propertyValue) => {
    let cloneInitialOverviewPageLocalState = initialOverviewPageLocalState;
    let sourceEvent = "";

    switch (property) {
      case ('pageTitle'): {
        const { pageTitle } = propertyValue || {}
        cloneInitialOverviewPageLocalState.pageTitle = pageTitle;
        sourceEvent = 'save-overview-title'
        break;
      }
      case ('pageSubTitle'): {
        const { pageSubTitle } = propertyValue || {}
        cloneInitialOverviewPageLocalState.pageSubTitle = pageSubTitle;
        sourceEvent = 'save-overview-subtitle'
        break;
      }
      case ('mainContactUserId'): {
        const { mainContactUserId } = propertyValue || {}
        cloneInitialOverviewPageLocalState.mainContactUserId = mainContactUserId;
        sourceEvent = 'save-dsr-main-contact'
        break;
      }
      case ('loomWelcomeVideo'): {
        const { loomWelcomeVideo } = propertyValue || {}
        setLoomVideoObject(loomWelcomeVideo)
        cloneInitialOverviewPageLocalState.loomWelcomeVideo = loomWelcomeVideo
        sourceEvent = 'save-recorded-loom-welcome-video'
        break;
      }
      case ('removeLoomWelcomeVideo'): {
        setLoomVideoObject("")
        cloneInitialOverviewPageLocalState.loomWelcomeVideo = ""
        sourceEvent = 'remove-loom-welcome-video'
        break;
      }
      case ('textTileTitle'): {
        const { textTileTitle } = propertyValue || {}
        cloneInitialOverviewPageLocalState.textTileTitle = textTileTitle;
        sourceEvent = 'save-text-tile-title'
        break;
      }
      case ('textTileDescription'): {
        const { textTileDescription } = propertyValue || {}
        cloneInitialOverviewPageLocalState.textTileDescription = textTileDescription;
        sourceEvent = 'save-text-tile-description'
        break;
      }
      case ("reorderTiles"): {
        const { tileSortOrder } = propertyValue || {};
        cloneInitialOverviewPageLocalState.tileSortOrder = tileSortOrder;
        sourceEvent = 'reorder-overview-tiles'
        break;
      }
      case ("toggleTileDisplay"): {
        const { tileDisplaySettings } = propertyValue || {};
        cloneInitialOverviewPageLocalState.tileDisplaySettings = tileDisplaySettings;
        sourceEvent = 'toggle-overview-tile-display'
        break;
      }
      default: {
        break;
      }
    }

    if (overviewPageIndex >= 0 && sourceEvent) {
      let updatedDsrPages = dsrPagesLocalState;
      updatedDsrPages[overviewPageIndex] = cloneInitialOverviewPageLocalState

      props.action.updateDsr({
        dsrId,
        dsrPages: updatedDsrPages,
        sourceEvent
      })

      setUpdatedDsrPages(updatedDsrPages)
    }
  }

  const handleAssignMainContact = (mode, selectedUserId) => {
    switch (mode) {
      case ('this-user'): {
        const thisUserId = getThisUserIdToken();
        setMainContactUserProfile(getUserDetailsUsingDsrMembersArray(dsrTeamMembers, thisUserId))
        updateOverviewPage("mainContactUserId", { mainContactUserId: thisUserId })
        break;
      }
      case ('other-team-member'): {
        setMainContactUserProfile(getUserDetailsUsingDsrMembersArray(dsrTeamMembers, selectedUserId))
        updateOverviewPage("mainContactUserId", { mainContactUserId: selectedUserId })
        break;
      }
      default: {
        break;
      }
    }

  }

  const handleInputChange = (event) => {
    if (event.target.name === "title") {
      setUserInputTitle(event.target.value)
    } else if (event.target.name === "subtitle") {
      setUserInputSubtitle(event.target.value)
    } else if (event.target.name === "text-tile-title") {
      setUserTextTileTitle(event.target.value)
    } else if (event.target.name === "text-tile-description") {
      setUserTextTileDescription(event.target.value)
    }
  }

  const handleEnter = (event) => {
    if (event.key === 'Enter') {
      clickOutsideAutosave()
    }
  }

  function renderPageTitleModule() {
    if (showEditTitleInput) {
      return (
        <Flex id="title" w='100%' mb="0.5em" justify='space-between' minWidth='max-content' ref={ref}>
          <InputGroup
            size="2em"
            width="100%"
            maxWidth='35%'
            minWidth='25em'
            border='none'
          >
            <Input
              name="title"
              value={userInputTitle}
              onChange={handleInputChange}
              onKeyDown={handleEnter}
              autoFocus={true}
              onFocus={(e) => setLastClickedInput(e.target.name)}
              placeholder={userInputTitle}
              fontSize='1.5em'
              border='1px solid'
              borderColor='#3295EC'
              borderRadius='0.25em'
              paddingLeft='5px'
              lineHeight='2em'
              fontWeight="600"
            />

          </InputGroup>
        </Flex>
      )
    } else {
      return (
        <Box>
          <Flex
            mb="0.5em"
            justify='flex-start'
            align='center'
            border='1px solid'
            borderColor='transparent'
            paddingLeft='5px'
            onMouseOver={() => setShowEditTitle(true)} onMouseOut={() => setShowEditTitle(false)}
          >
            <Box fontWeight="600" fontSize="1.5em" lineHeight="2em" color="brandDark.500">{userInputTitle}</Box>
            <Box
              ml='0.5em'
              cursor='pointer'
              opacity={showEditTitle ? '1.0' : '0'}
              onClick={() => setShowEditTitleInput(true)} >
              <Box as={MdModeEditOutline} boxSize="1em" color="brand.500" />
            </Box>
          </Flex>
          {!isMobileView &&
            <Box fontSize='0.65em' color='gray.500' fontWeight='500' pl='0.5em' mt='-1.5em'>

              Last saved:  {formatDisplayLocalDateTime(dsrLastSaved)}

              <Box >



              </Box>
            </Box>
          }
        </Box>
      )
    }
  }

  // function renderPageSubtitleModule() {
  //   if (showEditSubtitleInput) {
  //     return (
  //       <Flex id="subtitle" w='100%' mb="1.25em" justify='space-between' minWidth='max-content' ref={ref}>
  //         <InputGroup
  //           size="2em"
  //           width="100%"
  //           maxWidth='35%'
  //           minWidth='25em'
  //           border='none'
  //         >
  //           <Input
  //             name="subtitle"
  //             value={userInputSubtitle}
  //             onChange={handleInputChange}
  //             onKeyDown={handleEnter}
  //             autoFocus={true}
  //             onFocus={(e) => setLastClickedInput(e.target.name)}
  //             placeholder={userInputSubtitle ? userInputSubtitle : "Add a personal message for your Room guests"}
  //             fontSize='0.875em'
  //             border='1px solid'
  //             borderColor='#3295EC'
  //             borderRadius='0.25em'
  //             paddingLeft='5px'
  //             lineHeight='2em'
  //             fontWeight="400"
  //             color="gray.500"
  //           />
  //         </InputGroup>
  //       </Flex>
  //     )
  //   } else {
  //     return (
  //       <Flex
  //         mb="1.25em"
  //         justify='flex-start'
  //         align='center'
  //         border='1px solid'
  //         borderColor='transparent'
  //         paddingLeft='5px'
  //         height='30px'
  //         onMouseOver={() => setShowEditSubtitle(true)} onMouseOut={() => setShowEditSubtitle(false)}
  //       >
  //         <Box fontWeight="400" fontSize="0.875em" lineHeight="1.6em" color="gray.500">
  //           {userInputSubtitle ? userInputSubtitle : "Add a personal message for your Room guests"}
  //         </Box>
  //         <Box ml='0.5em' cursor='pointer'
  //           opacity={showEditSubtitle ? '1.0' : '0'}
  //           onClick={() => setShowEditSubtitleInput(true)} >
  //           <Box as={MdModeEditOutline} boxSize="1em" color="brand.500" />
  //         </Box>
  //       </Flex>
  //     )
  //   }

  // }

  function renderRoomMembersTile(renderRoomMembersTile) {
    return (
      <Box className="tile-container">
        <Box opacity={renderRoomMembersTile ? 1.0 : 0.3}>
          <Box fontSize='1.5em' fontWeight='500' lineHeight='1.5em'>  Room Members</Box>
          <DisplayDsrTeamMemberList dsrTeamMembers={dsrTeamMembers} showGuestList={showGuestList}/>
        </Box>
      </Box>
    )
  }

  function renderContactTile(mainContactUserProfile, contactTileDisplay) {
    if (!mainContactUserProfile) {
      return (
        <Box className="tile-container" bg='white'>
          <Box opacity={contactTileDisplay ? 1.0 : 0.3}>
            <Flex w='100%' justify='center' align='center' mb='1em'>
              <Image src={nominateUserEmptyState} boxSize='4em' alt="Nominate main point of contact Launchdeck" mx='3em' />
            </Flex>

            <Box
              alignSelf="stretch"
              color='gray.500'
              textAlign='center'
              fontWeight='400'
              lineHeight='1.5em'
            >
              Assign a main contact person for guests
            </Box>

            <Flex mt='1.5em' justify='flex-start' w='100%' align='center'>
              <Box className="tutorial-button" onClick={() => handleAssignMainContact('this-user', null)}>Assign myself</Box>
              <Box className="tutorial-button" ml='1em' onClick={() => setShowPickContactModal(true)}>Assign team member</Box>
            </Flex>
          </Box>
        </Box>


      )
    } else if (mainContactUserProfile) {
      // Get the userId & user profile of the nominated contact here
      const { memberName, memberProfilePicSrc, email, memberOrgTitle, meetingLink, contactPhoneNumber } = mainContactUserProfile || {};

      const subText = (memberOrgTitle) ? memberOrgTitle : email;
      const emailSubject = dsrTitleRedux;
      const emailBody = `Hey there, we are connected through the ${dsrTitleRedux}, a Digital Sales Room powered by Launchdeck. I would like to ask you something by email.`

      return (
        <Box className="tile-container">
          <Box opacity={contactTileDisplay ? 1.0 : 0.3}>
            <Flex mt='1em' w='100%' justify='center' align='center'>
              <Box border='1px solid' borderColor='gray.200' rounded='50%'>
                <Avatar h='4.2em' w='4.2em' maxW='5em' name={memberName} bg='gray.100' color='gray.700' src={memberProfilePicSrc} />
              </Box>
            </Flex>

            <Box my='1em'>
              <Flex w='100%' justify='center' fontSize='0.875em'>{memberName}</Flex>
              <Flex w='100%' justify='center' fontSize='0.75em' color='gray.500'>{subText}</Flex>
              {/* <Flex w='100%' justify='center' fontSize='0.6em' fontWeight='600' color='brandDark.500' textTransform='uppercase'>{memberOrgName}</Flex> */}
            </Box>

            <Flex w='100%' justify='center' align='center' mt='2em'>
              <Flex justify='space-between'>
                <Box border='1px solid' borderColor='gray.200' rounded='50%' cursor='pointer' p='0.5em' onClick={() => window.open(`mailto:${email}?subject=${emailSubject}&body=${emailBody}`)}>
                  <Box as={AiOutlineMail} />
                </Box>
                {contactPhoneNumber &&
                  <Box border='1px solid' borderColor='gray.200' rounded='50%' ml='1.5em' cursor='pointer' p='0.5em' onClick={onToggle}>
                    <Box as={AiOutlinePhone} />
                  </Box>
                }
                {meetingLink &&
                  <Link href={meetingLink} isExternal>
                    <Box border='1px solid' borderColor='gray.200' rounded='50%' ml='1.5em' cursor='pointer' p='0.5em'>
                      <Box as={IoCalendarOutline} />
                    </Box>
                  </Link>
                }
              </Flex>
            </Flex>
            <Collapse in={isOpen} animateOpacity>
              <Flex
                p='1em'
                mt='1em'
                rounded='0.5em'
                justify='center'
                align='center'
                direction='column'
                border='1px solid'
                borderColor='gray.100'
                gap='0.25em'
              >
                <Box color='brandDark.500' fontSize='0.875em' fontWeight='600'>{contactPhoneNumber}</Box>
                <a href={`tel:${contactPhoneNumber}`}> <Box color='brand.500' fontSize='0.6em' fontWeight='600' textTransform={'uppercase'}>Contact number</Box></a>
              </Flex>
            </Collapse>

            <Flex mt='1.5em' justify='center' w='100%' align='center'>
              <Box className="tutorial-button" onClick={() => setShowPickContactModal(true)}>Change</Box>
            </Flex>
          </Box>
        </Box>
      )
    }

  }

  function renderLoomWelcomeVideo(loomVideoObject, tileDisplay) {

    const { embedUrl } = loomVideoObject || {};
    if (embedUrl) {
      return (
        <Box position='relative' minH='20em'>
          <Box opacity={tileDisplay ? 1.0 : 0.3} h='100%' >
            <iframe
            className="tile-container-loom-video__white-background loader-img iframe-round"
              title="dsrDraftIntroVideo"
              width='100%'
              minH='20em'
              height='100%'
              src={embedUrl}
              webkitallowfullscreen='true'
              mozallowfullscreen='true'
              allowFullScreen
            >
            </iframe>
          </Box>
          </Box>
      )
    } else {
      return (
        <Box className="tile-container" border='none'>
          <Box margin='1em' opacity={tileDisplay ? 1.0 : 0.3}>
            <Flex w='100%' justify='center' align='center' mb='1em' >
              <Image src={loomLogo} boxSize='4em' alt="Record a video" mx='3em' />
            </Flex>
            <Box
              alignSelf="stretch"
              color='gray.500'
              textAlign='center'
              fontWeight='400'
              lineHeight='1.5em'
              fontSize='0.8em'
              py='0.5em'
            >Record an easy video message to welcome your Room Guests using Loom</Box>
            <Flex w='100%' justify='center' align='center' my='1em' >
              <Box display="flex" width="100%" justifyContent="center">
                {!tileDisplay ?
                  <Box cursor='default'>
                    <Box
                      as="button"
                      className="loom-button-unclickable"
                    >
                      <Flex px='3em' gap={4}>
                        <Box fontSize="0.875em" >Record</Box>
                        <Box as={MdEmergencyRecording} boxSize="1.5em" />
                      </Flex>
                    </Box>
                  </Box>
                  : initialOverviewPageLocalState !== null
                    ? 
                    <>
                      <DsrLoomRecordButton handleSaveVideoToOverviewPage={updateOverviewPage} submitRecording={(v) => { }} />
                    </>
                    : <Box width='60px' height='60px' className='loader-img'></Box>
                }
              </Box>
            </Flex>
          </Box>
        </Box>
      )
    };

  }

  function renderTextTile(freeTextTileDisplay) {

    // const displayedDescString = userTextTileDescription.length > 100 ? `${userTextTileDescription.substring(0, 100)}...` : userTextTileDescription;

    const TextTileTitle = (showTextTileTitleInput && !!freeTextTileDisplay)
      ? (
        <Flex id="text-tile-title" w='100%' mb="0.5em" justify='space-between' ref={ref}>
          <Input
            name="text-tile-title"
            value={userTextTileTitle}
            onChange={handleInputChange}
            onKeyDown={handleEnter}
            autoFocus={true}
            onFocus={(e) => setLastClickedInput(e.target.name)}
            placeholder={'Insert Title Text'}
            fontSize='1.5em'
            border='1px solid'
            borderColor='#3295EC'
            borderRadius='0.25em'
            paddingLeft='5px'
            height='40px'
            lineHeight='24px'
            fontWeight="500"
          />
        </Flex>)
      : (
        <Flex mb="0.5em">
          <Box
            display='flex'
            alignItems='center'
            fontWeight="500"
            fontSize="1.5em"
            lineHeight="1.5em"
            color="brandDark.500"
            border='1px solid'
            borderColor='transparent'
            paddingLeft='5px'
          // height='40px'
          >
            <Box
              transform='translateY(1px)'
              alignSelf={'flex-start'}
              textAlign={isMobileView ? 'center' : 'left'}
              onClick={() => freeTextTileDisplay && setShowTextTileTitleInput(true)}
              cursor={freeTextTileDisplay ? 'pointer' : 'default'}
            >{userTextTileTitle ? userTextTileTitle : 'Insert Title Text'}</Box>
          </Box>
        </Flex>
      )

    const TextTileDescription = (showTextTileDescriptionInput && !!freeTextTileDisplay)
      ? (<Flex id="text-tile-description" w='100%' mb="0.5em" justify='space-between' ref={ref}>

        <InputTextAreaExpandable
          name="text-tile-description"
          onFocus={(e) => setLastClickedInput(e.target.name)}
          onChangeHandler={handleInputChange}
          inputValue={userTextTileDescription}
          placeholder={`Your text here`}
          border={'1px solid #E5EAEF'}
          autoFocus={true}
        />
      </Flex>)
      : (
        <Flex mb="0.5em"
          justify='flex-start' align='center'
        >

          <Box
            fontWeight="500"
            fontSize="0.875em"
            lineHeight="22px"
            color='#718096'
            border='1px solid'
            borderColor='transparent'
            paddingLeft='5px'
            whiteSpace='pre-line'
            pt='5px'
            cursor={freeTextTileDisplay ? 'pointer' : 'default'}
            onClick={() => freeTextTileDisplay && setShowTextTileDescriptionInput(true)}
          >
            {userTextTileDescription ? userTextTileDescription : 'Give it a good description'}
          </Box>

        </Flex>
      )
    return (
      <Box className="tile-container">
        <Box opacity={freeTextTileDisplay ? 1.0 : 0.3}>
          <Box mb='1em'>
            {TextTileTitle}
          </Box>
          <Box w='100%' h='100%'>
            {TextTileDescription}
          </Box>
        </Box>
      </Box>
    )
  }


  function renderNotificationsTile(notificationsTileDisplay) {
    const renderedList = dsrNotifications?.length > 0 ? dsrNotifications.map((notification, noteIndex) => {
      const { notificationId, notificationType, createdAt: notificationDate, relatedNotificationsCount } = notification || {}
      const displayedNotificationMessage = notificationType ? determineNotificationMessage(notification) : "";


      if (displayedNotificationMessage) {
        return (
          <Box key={`header-notification--${notificationId}-${noteIndex}`} color='gray.700'
            py='0.5em'
            minW='20em'
            maxW='30em'
          >
            <Box >
              <Text fontSize='0.8em'>
                {displayedNotificationMessage}
              </Text>
              <Flex justify='flex-start' align='center'>
                {/* {(viewed === false ) && <Box color='brandPink.500' fontSize='0.7em' mr='0.5em'>•</Box>} */}
                <Text fontSize='0.6em' color='gray.500' mt='0.2em'>
                  {formatDisplayLocalDateTime(notificationDate)} 	{!!relatedNotificationsCount && `• ${relatedNotificationsCount} related notifications`}
                </Text>
              </Flex>

            </Box>
          </Box>
        )
      } else return <Box key={`header-notification--${notificationId}-${noteIndex}`} />;

    }) : <Box fontSize='0.8em' color='gray.700' textAlign={['center', 'center', 'left', 'left']} mt='1em'>
      None right now. You are all caught up.
    </Box>

    return (
      <Box className="tile-container">
        <Box opacity={notificationsTileDisplay ? 1.0 : 0.3}>
          <Box fontSize='1.5em' fontWeight='500' lineHeight='1.5em' alignSelf='stretch' textAlign={['center', 'center', 'left', 'left']}>Recent activity</Box>
          {renderedList}
        </Box>
      </Box>
    )
  }

  function renderMapOverviewTile(planTileDisplay) {
    const { latestActionItems, actionItemsWithOutstandingDueDates } = dsrMapOverview || {}

    const renderLatestActionItemList = Array.isArray(latestActionItems) ? latestActionItems?.map(item => {
      const { actionId, actionTaskTitle, actionUpdatedAt, actionCreatedAt, assignedToUserId } = item || {}
      const renderAvatarPics = Array.isArray(assignedToUserId) ? assignedToUserId?.map(userId => {
        const memberInfo = dsrTeamMembers && getUserDetailsUsingDsrMembersArray(dsrTeamMembers, userId)
        const { memberName, memberProfilePicSrc } = memberInfo || {};

        return <Avatar h='2em' w='2em' maxW='2em' mr='0.5em' name={memberName} bg='gray.100' color='gray.700' src={memberProfilePicSrc} />
      }) : null;

      return <Flex key={'latest-map-' + actionId}
        direction={['column-reverse', 'column-reverse', 'row', 'row']}
        w='100%' align='flex-start' p={['0.5em 0', '0.5em']}
        _hover={{ bg: "gray.100" }}
        rounded='0.5em'
      >
        <Box flex='7'>
          <Box fontSize='0.875em'>{actionTaskTitle}</Box>
          <Box mt='0.2em'>{renderAvatarPics}</Box>
        </Box>

        <Box flex='3'>
          <Box fontSize='0.6em' mt='0.25em' pl={['0', '0.5em']} textAlign={'right'}>{displayElapsedDurationSinceTimestamp(actionUpdatedAt ? actionUpdatedAt : actionCreatedAt)}</Box>
        </Box>
      </Flex>
    }) : <Box fontSize='0.875em' mt='1em' textAlign={'left'}> Nothing yet</Box>

    const renderOutstandingItemList = Array.isArray(actionItemsWithOutstandingDueDates) ? actionItemsWithOutstandingDueDates?.map(item => {
      const { actionId, actionTaskTitle, assignedToUserId, actionDueDate } = item || {}
      const renderAvatarPics = Array.isArray(assignedToUserId) ? assignedToUserId?.map(userId => {
        const memberInfo = dsrTeamMembers && getUserDetailsUsingDsrMembersArray(dsrTeamMembers, userId)
        const { memberName, memberProfilePicSrc } = memberInfo || {};

        return <Avatar h='2em' w='2em' maxW='2em' name={memberName} bg='gray.100' color='gray.700' src={memberProfilePicSrc} />
      })

        : null;


      return <Flex key={'due-map-' + actionId} direction={['column-reverse', 'column-reverse', 'row', 'row']} w='100%' align={['left', 'left', 'center', 'center']} p={isMobileView ? '0.5em 0' : '0.5em'} _hover={{ bg: "gray.100" }} rounded='0.5em' >
        <Box flex='7'>
          <Box fontSize='0.875em'>{actionTaskTitle}</Box>
          <Box>{renderAvatarPics}</Box>
        </Box>

        <Box flex='3'>
          <Flex justify={['flex-start', 'flex-start', 'flex-end', 'flex-end']}>
            {isMobileView ? <Box color='brandPink.500' fontSize='0.6em' fontWeight='600' mt='0.2em'>{formatTimestamp(actionDueDate)}</Box> : <LaunchdeckTag bg='brandPink.500' color='white' fontWeight='600' label={formatTimestamp(actionDueDate)} />}
          </Flex>
        </Box>
      </Flex>
    }) : <Box fontSize='0.875em' mt='1em' textAlign={'left'}> Nothing due for now</Box>

    return (
      <Box className="tile-container">
        <Box opacity={planTileDisplay ? 1.0 : 0.3}>
          <Box fontSize='1.5em' fontWeight='500' lineHeight='1.5em' alignSelf='stretch' textAlign={['center', 'center', 'left', 'left']}>Upcoming actions</Box>

          {actionItemsWithOutstandingDueDates?.length > 0 &&
            <><Box fontSize='0.875em' fontWeight='600' color='brandPink.500' mt='2em' mb='1em'>Due soon</Box>
              <Box>{renderOutstandingItemList}</Box>
            </>
          }
          {latestActionItems ? <>
            <Box fontSize='0.875em' color='brand.500' mt='2em' fontWeight='600' >Most recent</Box>
            <Box mb='1em'>{renderLatestActionItemList}</Box>
          </> : <>
            <Box fontSize='0.8em' mt='1em' textAlign={'left'}> No agreed actions set yet</Box>
            <Box>

            </Box>
          </>}

        </Box>
      </Box>
    )
  }

  function renderSharedDocTile(sharedDocTileDisplay) {
    const renderedList = dsrSharedDocsOverview?.length > 0 ? dsrSharedDocsOverview.map((sharedDocs, docIndex) => {
      const { docId, docLink, docName, docOwnerId, createdAt: docDateCreated } = sharedDocs || {}
      const dateDisplayed = docDateCreated ? formatDisplayDateOnly(docDateCreated) : "";
      const docOwner = docOwnerId ? getUserDetailsUsingDsrMembersArray(dsrTeamMembers, docOwnerId) : {}

      if (docName && docOwner) {
        return (
          <Grid
            key={`header-notification--${docId}-${docIndex}`}
            color='gray.700'
            // display='flex'
            py='0.5em'
            minW='20em'
            maxW='30em'
            gap={6}
            align='flex-start'
            justifyContent='space-between'
            w='100%'
            gridTemplateColumns="auto 5em"
          >

            <GridItem display='flex' pr='0.25em'>
              <Box py='0.2em' pr='0.5em'>
                <Link
                  href={docLink}
                  fontSize="1em"
                  isExternal
                >
                  <FiExternalLink />
                </Link>

              </Box>

              <Box>
                <Box fontSize='0.8em'>
                  {docName}
                </Box>
                <Flex justify='flex-start' align='center'>
                  {/* {(viewed === false ) && <Box color='brandPink.500' fontSize='0.7em' mr='0.5em'>•</Box>} */}
                  <Text fontSize='0.6em' color='gray.500' mt='0.2em'>
                    Owner:  {docOwner.memberName}
                  </Text>
                </Flex>
              </Box>

            </GridItem>

            <GridItem fontSize='0.7em' textAlign={'right'}>
              {dateDisplayed}
            </GridItem>
          </Grid>
        )
      } else return <Box key={`header-notification--${docId}-${docIndex}`} />;

    }) : <Box fontSize='0.8em' color='gray.700' textAlign={['center', 'center', 'left', 'left']} mt='1em'>
      None right now. You are all caught up.
    </Box>

    return (
      <Box className="tile-container">
        <Box opacity={sharedDocTileDisplay ? 1.0 : 0.3}>
          <Box mb='1em' fontSize='1.5em' fontWeight='500' lineHeight='1.5em' alignSelf='stretch' textAlign={['center', 'center', 'left', 'left']}>Shared Documents</Box>
          {renderedList}
        </Box>
      </Box>
    )
  }

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const activeIndex = tileOrder.indexOf(active.id);
      const overIndex = tileOrder.indexOf(over.id);
      const sortedTiles = arrayMove(tileOrder, activeIndex, overIndex)

      if (sortedTiles) {
        setTileOrder([...sortedTiles])
        updateOverviewPage("reorderTiles", { tileSortOrder: sortedTiles })
      }
    }
  };

  const toggleTileDisplay = (tile, displaySetting) => {

    if (tile && (typeof displaySetting == "boolean")) {

      let propertyName = "";
      const propertySettings = displaySetting ? true : false;
      switch (tile) {
        case ("mutual-plan-tile"): {
          propertyName = "planTileDisplay"
          break;
        }
        case ("notifications-tile"): {
          propertyName = "notificationsTileDisplay"
          break;
        }
        case ("contact-tile"): {
          propertyName = "contactTileDisplay"
          break;
        }
        case ("room-members-tile"): {
          propertyName = "membersTileDisplay"
          break;
        }
        case ("intro-tile"): {
          propertyName = "introTileDisplay"
          break;
        }
        case ("free-text-tile"): {
          propertyName = "freeTextTileDisplay"
          break;
        }
        case ("shared-doc-tile"): {
          propertyName = "sharedDocTileDisplay"
          break;
        }
        default: {
          break;
        }
      }

      if (propertyName) {
        const updatedTileSettings = {
          ...tileDisplay,
          [propertyName]: propertySettings
        }

        setTileDisplay(updatedTileSettings)
        updateOverviewPage("toggleTileDisplay", { tileDisplaySettings: updatedTileSettings })
      }

    }

  }

  function renderGrid() {
    const { introTileDisplay, freeTextTileDisplay, contactTileDisplay, notificationsTileDisplay, membersTileDisplay, planTileDisplay, sharedDocTileDisplay } = tileDisplay || {};
    const renderedGrid = tileOrder.map((tile) => {

      switch (tile) {
        case ("intro-tile"): {
          return (
            <SortableOverviewPageTiles key={tile} id={tile} tileIsDisplayed={introTileDisplay} toggleTileDisplay={toggleTileDisplay} isEmbeddedLoom={true} showReplaceButton={loomVideoObject ? true : false} setShowRemoveVideoModal={setShowRemoveVideoModal}>
              {renderLoomWelcomeVideo(loomVideoObject, introTileDisplay)}
            </SortableOverviewPageTiles>
          )
        }
        case ("free-text-tile"): {
          const isEmptyState = (!userTextTileDescription && !userTextTileTitle) ? true : false;
          return (
            <SortableOverviewPageTiles key={tile} id={tile} tileIsDisplayed={freeTextTileDisplay} toggleTileDisplay={toggleTileDisplay} isFullWidthTile isMobileView={isMobileView} isEmptyState={isEmptyState}>
              {renderTextTile(freeTextTileDisplay)}
            </SortableOverviewPageTiles>
          )
        }
        case ("contact-tile"): {
          return (
            <SortableOverviewPageTiles key={tile} id={tile} tileIsDisplayed={contactTileDisplay} toggleTileDisplay={toggleTileDisplay}>
              {renderContactTile(mainContactUserProfile, contactTileDisplay)}
            </SortableOverviewPageTiles>
          )
        }
        case ("notifications-tile"): {
          return (
            <SortableOverviewPageTiles key={tile} id={tile} tileIsDisplayed={notificationsTileDisplay} toggleTileDisplay={toggleTileDisplay}>
              {renderNotificationsTile(notificationsTileDisplay)}
            </SortableOverviewPageTiles>
          )
        }
        case ("room-members-tile"): {
          return (
            <SortableOverviewPageTiles key={tile} id={tile} tileIsDisplayed={membersTileDisplay} toggleTileDisplay={toggleTileDisplay}>
              {renderRoomMembersTile(membersTileDisplay)}
            </SortableOverviewPageTiles>
          )
        }
        case ("mutual-plan-tile"): {
          return (
            <SortableOverviewPageTiles key={tile} id={tile} tileIsDisplayed={planTileDisplay} toggleTileDisplay={toggleTileDisplay}>
              {renderMapOverviewTile(planTileDisplay)}
            </SortableOverviewPageTiles>
          )
        }
        case ("shared-doc-tile"): {
          return (
            <SortableOverviewPageTiles key={tile} id={tile} tileIsDisplayed={sharedDocTileDisplay} toggleTileDisplay={toggleTileDisplay}>
              {renderSharedDocTile(sharedDocTileDisplay)}
            </SortableOverviewPageTiles>
          )
        }
        default: {
          return null
        }
      }
    })

    return renderedGrid
  }
  return (
    <Box>
      {renderPageTitleModule()}

      <>
        <DndContext onDragEnd={handleDragEnd}>
          <SortableContext items={tileOrder.map((tile) => { return tile })} strategy={rectSortingStrategy}>
            <Box className="tiles-wrapper">
              {renderGrid()}
            </Box>
          </SortableContext>
        </DndContext>
      </>

      {showPickContactModal === true &&
        <PickDsrContactPersonModal
          handleCloseModal={handleClosePickContactModal}
          dsrTeamMembers={dsrTeamMembers}
          mainContactUserId={mainContactUserId}
          handleAssignMainContact={handleAssignMainContact}
        />
      }

      {showRemoveVideoModal === true &&
        <RemoveOverviewVideoConfirmationModal
          handleCloseModal={handleCloseRemoveVideoConfirmationModal}
          handleRemoveVideoOverviewPage={updateOverviewPage}
        />
      }
    </Box>

  )


}



const mapStateToProps = (state) => {
  return {
    dsrTitleRedux: state.dsrTitle.title,
    dsrId: state.dsrConfiguration.results.data.dsrId,
    updateDsrOutcome: state.updateDsrOutcome.results.data,
    dsrNotifications: state.dsrNotifications.results,
    dsrMapOverview: state.dsrMapOverview.results,
    isMobileView: state.mobileViewMode.isMobileView,
    dsrLastSaved: state.dsrLastSaved.results,
    dsrSharedDocsOverview: state.dsrSharedDocOverview.results,
    dsrPropertiesRedux: state.draftDsrProperties.properties,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    action: bindActionCreators(
      {
        updateDsr,
        setDsrPages,
        resetUpdateDsrOutcome
      },
      dispatch
    )
  };
}


export default memo(withRouter(connect(mapStateToProps, mapDispatchToProps)(OverviewDraftPage)))


