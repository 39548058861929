import React, { memo, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'

import { Box, Flex, useToast } from '@chakra-ui/react'

import { updatePdsr, resetUpdatePdsrOutcome } from '../actions/dsr-actions';
import InputTextAreaExpandable from '../../forms/TextareaField/components/InputTextAreaExpandable'
import LaunchdeckButton from '../../global/components/helper-components/components/LaunchdeckButton'
import { triggerLaunchdeckToast } from '../../global/helpers/global-helpers'

function DsrProspectTeamNotesPage({
  // Parent component
  pDsrId,
  dsrProperties,
  // Redux actions
  updatePdsr,
  resetUpdatePdsrOutcome,
  // Redux state
  isMobileView,
  updateProspectDsrOutcome
}) {


  const toast = useToast();
  const [latestDsrProperties, setLatestDsrProperties] = useState(dsrProperties)

  const { dsrUserNotes } = latestDsrProperties || {};
  const { noteContent } = dsrUserNotes || {}

  useEffect(() => {
    if (updateProspectDsrOutcome) {
      const { updatePdsrStatus, updatedPdsrId, sourceEvent } = updateProspectDsrOutcome || {};
      if (sourceEvent === "save-prospect-dsr-properties") {
        if (updatePdsrStatus && updatedPdsrId === pDsrId && pDsrId !== undefined) {
          toast(
            triggerLaunchdeckToast({
            useCase: "show-success-state",
            // bannerText: "",
            label: "Team notes successfully updated", 
            isMobileView
            })
          )

        } else if (!updatePdsrStatus) {
          toast(
            triggerLaunchdeckToast({
            useCase: "show-error-state",
            bannerText: "Something went wrong",
            label: "Unfortunately something went wrong. Please try again later.", 
            isMobileView
            })
          )
        }

        resetUpdatePdsrOutcome()
      }
    }
  }, [updateProspectDsrOutcome, toast, pDsrId, resetUpdatePdsrOutcome]);

  const onChangeHandler = event => {
    switch (event.target.name) {
      case ('dsrNotes'): {
        setLatestDsrProperties({
          ...latestDsrProperties,
          dsrUserNotes: {
            ...dsrUserNotes,
            noteContent: event.target.value,
            noteEditedAt: new Date()
          }
        })
        break;
      }
      default: {
        break;
      }
    }
  };

  const handleSavePdsrProperties = () => {
    if (pDsrId) {
      updatePdsr({
        pDsrId: pDsrId,
        dsrProperties: latestDsrProperties,
        sourceEvent: "save-prospect-dsr-properties"
      })
    }
  }

  return (
    <Box>
      <Flex justify='space-between' align='center'>
        <Box>
          <Box color='brandDark.500' fontSize='1.5em' lineHeight='1.4em' fontWeight='600'>Team Notes</Box>
          <Box color='brandDark.500' fontSize='1em' fontWeight='400'>
            Private information that is available only to Administrators
          </Box>
        </Box>
      </Flex>

      <Box w='100%' mt='2em'>
        <InputTextAreaExpandable
          name={'dsrNotes'}
          onChangeHandler={onChangeHandler}
          inputValue={noteContent}
          placeholder={"Share team admin notes here"}
          backgroundColor='white'
          border='1px solid'
          borderColor='gray.200'
        />
      </Box>


      <Flex w='100%' justify='flex-end' mt='2em'>
        <LaunchdeckButton bg='brand.500' color='white' onClick={() => handleSavePdsrProperties()} label='Save' />
      </Flex>

    </Box>

  )

}

const mapStateToProps = (state) => {
  return {
    updateProspectDsrOutcome: state.updateProspectDsrOutcome.results.data,
    isMobileView: state.mobileViewMode.isMobileView
  }
}


const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updatePdsr,
      resetUpdatePdsrOutcome
    },
    dispatch,
  )

export default memo(withRouter(connect(mapStateToProps, mapDispatchToProps)(DsrProspectTeamNotesPage)))
