export const GET_ADMIN_ORG_PROFILE_REQUEST = 'get_admin_org_profile_request';
export const GET_ADMIN_ORG_PROFILE_SUCCESS = 'get_admin_org_profile_success';
export const GET_ADMIN_ORG_PROFILE_FAILURE = 'get_admin_org_profile_failure';

// export const UPDATE_ORG_PROFILE_REQUEST = 'update_org_profile_request';
// export const UPDATE_ORG_PROFILE_SUCCESS = 'update_org_profile_success';
// export const UPDATE_ORG_PROFILE_FAILURE = 'update_org_profile_failure';

// Org Users CRUD ops
export const CREATE_ORG_USERS_REQUEST = 'create_org_users_request';
export const CREATE_ORG_USERS_SUCCESS = 'create_org_users_success';
export const CREATE_ORG_USERS_FAILURE = 'create_org_users_failure';
///
export const CREATE_ORG_USERS_RESET = 'create_org_users_reset'


export const UPDATE_ORG_USERS_REQUEST = 'update_org_users_request';
export const UPDATE_ORG_USERS_SUCCESS = 'update_org_users_success';
export const UPDATE_ORG_USERS_FAILURE = 'update_org_users_failure';
///
export const UPDATE_ORG_USERS_RESET = 'update_org_users_reset'

// Reset pending user activation link
export const RESET_PENDING_USER_ACTIVATION_LINK_REQUEST = 'reset_pending_user_activation_link_request';
export const RESET_PENDING_USER_ACTIVATION_LINK_SUCCESS = 'reset_pending_user_activation_link_success';
export const RESET_PENDING_USER_ACTIVATION_LINK_FAILURE = 'reset_pending_user_activation_link_failure';
export const RESET_PENDING_USER_ACTIVATION_LINK_RESET = 'reset_pending_user_activation_link_reset'