// User Tagging
export const TAG_USER_REQUEST = 'tag_user_request';
export const TAG_USER_SUCCESS = 'tag_user_success';
export const TAG_USER_FAILURE = 'tag_user_failure';
// export const CREATE_FAQ_QUESTION_RESET = 'create_faq_question_reset';

export const UNTAG_USER_REQUEST = 'untag_user_request';
export const UNTAG_USER_SUCCESS = 'untag_user_success';
export const UNTAG_USER_FAILURE = 'untag_user_failure';

export const TAG_USER_IN_REPLY_REQUEST = 'tag_user_in_reply_request';
export const TAG_USER_IN_REPLY_SUCCESS = 'tag_user_reply_success';
export const TAG_USER_IN_REPLY_FAILURE = 'tag_user_reply_failure';

export const UNTAG_USER_IN_REPLY_REQUEST = 'untag_user_in_reply_request';
export const UNTAG_USER_IN_REPLY_SUCCESS = 'untag_user_reply_success';
export const UNTAG_USER_IN_REPLY_FAILURE = 'untag_user_reply_failure';