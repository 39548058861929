// Upload Organization Logo Image
export const GET_ORG_LOGO_IMAGE_REQUEST = 'get_org_logo_image_request';
export const GET_ORG_LOGO_IMAGE_SUCCESS = 'get_org_logo_image_success';
export const GET_ORG_LOGO_IMAGE_FAILURE = 'get_org_logo_image_failure';
export const GET_ORG_LOGO_IMAGE_RESET = 'get_org_logo_image_reset';

// Set Organization Logo Image Url
export const SET_ORG_LOGO_IMAGE_REQUEST = 'set_org_logo_image_request';
export const SET_ORG_LOGO_IMAGE_SUCCESS = 'set_org_logo_image_success';
export const SET_ORG_LOGO_IMAGE_FAILURE = 'set_org_logo_image_failure';
export const SET_ORG_LOGO_IMAGE_RESET = 'set_org_logo_image_reset';

// Update Organization Information
export const UPDATE_ORG_PROFILE_REQUEST = 'update_org_profile_request';
export const UPDATE_ORG_PROFILE_SUCCESS = 'update_org_profile_success';
export const UPDATE_ORG_PROFILE_FAILURE = 'update_org_profile_failure';
export const UPDATE_ORG_PROFILE_RESET = 'update_org_profile_reset';
