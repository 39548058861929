import auth from '../../../config/app-specific-config/auth'
import * as types from './dsr-template-actions-types'
// import {
// 	CREATE_DSR_TEMPLATE_REQUEST,
// 	CREATE_DSR_TEMPLATE_SUCCESS,
// 	CREATE_DSR_TEMPLATE_FAILURE
// } from './dsr-template-actions-types'
import { axiosInstance } from '../../../config/app-specific-config/axiosConfig'
import { getUserDetailsUsingDsrMembersArray } from '../../profile/helpers/user-helper'
import { displayModeCheck, getThisUserIdToken } from '../../global/helpers/global-helpers'
import { findPageIdByPageType } from '../helpers/dsr-helpers'
import { checkUserIsDsrAdmin, checkUserTeam } from '../helpers/dsr-permission-helpers'
import { setIsMobileView } from '../../global/actions/global-actions'
import { setUserPrivileges } from '../../auth/actions/auth-actions'
import { manageSharedDoc, setDsrActionListOverview, setDsrFaqContent, setDsrLastUpdated, setDsrLatestNotifications, setDsrPages, setDsrSharedDocsOverview, setDsrTitle, setDsrUiIndicators, setDsrUserTeam, setFaqPageId, setInvalidRequest, setIsDsrAdmin, setJoinRoomRequests, setUserProfile } from './dsr-actions'



// Get DSR Configuration
export const getDsrTemplateConfigSuccess = (response) => {
	return {
		type: types.GET_DSR_TEMPLATE_CONFIG_SUCCESS,
		payload: response.data
	};
};

export const getDsrTemplateConfigFailure = (error) => {
	return {
		type: types.GET_DSR_TEMPLATE_CONFIG_FAILURE,
		error
	};
};

export const getDsrTemplateConfigRequest = () => {
	return {
		type: types.GET_DSR_TEMPLATE_CONFIG_REQUEST
	};
};


// UUU clone this to derive getDsrTemplateConfig(dsrTemplateId)
export function getDsrTemplateConfig(dsrTemplateId) {

	// Note: dsrStatuses is an array
	// const parsedQueryParamString = queryParamArrayParser('statuses', dsrStatuses)
	
	return async (dispatch) => {
		dispatch(getDsrTemplateConfigRequest());
		try {
			const url = `/dsr/template-config/${dsrTemplateId}`;
			const headers = await auth.getAccessToken();
			const response = await axiosInstance.get(url, {
				headers
			});

			if (response.data.data.invalidRequest === true) {
					dispatch(setInvalidRequest(response.data.data.invalidRequest))				
			} 
			
			else {
				dispatch(getDsrTemplateConfigSuccess(response));
				dispatch(setDsrPages(response.data.data.dsrPages))

				// Get current user profile info
				const userProfile = getUserDetailsUsingDsrMembersArray(
					response.data.data.dsrTeamMembers,
					getThisUserIdToken(),
				)
				dispatch(setUserProfile(userProfile))

				// Get user's team in this DSR
				const userDsrTeam = checkUserTeam(response.data.data.dsrTeamMembers, getThisUserIdToken())
				const isDsrAdministrator = checkUserIsDsrAdmin(response.data.data.dsrTeamMembers, getThisUserIdToken())
			
				if (response.data.data.updatedAt) {
					dispatch(setDsrLastUpdated(response.data.data.updatedAt))
				}

				if (userDsrTeam) {
					dispatch(setDsrUserTeam(userDsrTeam)) // UUU : Review if needed
				}

				if (response.data.data.userPrivileges) {
					dispatch(setUserPrivileges(response.data.data.userPrivileges))
				}
				
				// Set these additional states if user is Room Administrator
				if (isDsrAdministrator){
					dispatch(setIsDsrAdmin(isDsrAdministrator)) // UUU : Review if needed

					// Requests invite
					if(response.data.data.joinRoomRequests) {
						const {requestCount, activeRequests} = response.data.data.joinRoomRequests || {};

						if (requestCount) {
							dispatch(setJoinRoomRequests(activeRequests))
						}
					}

					if (response.data.data.uiIndicators) {
						dispatch(setDsrUiIndicators(response.data.data.uiIndicators))
					}
				}

				// Get other metadata
				const {pageId, dsrPage} = findPageIdByPageType(response.data.data.dsrPages, 'faq')
				
				if (dsrPage) {
					dispatch(setFaqPageId(pageId))
					const faqArray = dsrPage.pageContent[0].faqArray;
					dispatch(setDsrFaqContent(faqArray))
				}

				if (response.data.data.latestDsrNotifications) {
					dispatch(setDsrLatestNotifications(response.data.data.latestDsrNotifications))
				}

				if (response.data.data.actionListOverview) {
					dispatch(setDsrActionListOverview(response.data.data.actionListOverview))
				}


				if (response.data.data.sharedDocsOverview) {
					dispatch(setDsrSharedDocsOverview(response.data.data.sharedDocsOverview))
				}

				// dispatch(setDsrLogos(response.data.data.dsrDisplayLogos))
				// dispatch(setDsrProperties(response.data.data.dsrProperties))
				// dispatch(setDsrTeamMembers(response.data.data.dsrTeamMembers)) 
				// dispatch(setDsrDraftStatus(response.data.data.status))
				dispatch(setDsrTitle(response.data.data.dsrTitle))
			}
				
		} catch (error) {
			dispatch(getDsrTemplateConfigFailure(error));
		}
	};
}

export function fetchDsrTemplateData(dsrTemplateId, type) {
	return async (dispatch) => {
		dispatch(getDsrTemplateConfig(dsrTemplateId, type))
		// dispatch(getDsrActionList(dsrId))
		dispatch(manageSharedDoc({manageEvent:"get-dsr-shared-doc",dsrTemplateId}))

		const isMobileView = displayModeCheck();
		dispatch(setIsMobileView(isMobileView))
	}
}


// Create DSR Template
export const createTemplateDsrSuccess = (response) => {
	return {
		type: types.CREATE_DSR_TEMPLATE_SUCCESS,
		payload: response.data
	};
};

export const createTemplateDsrFailure = (error) => {
	return {
		type: types.CREATE_DSR_TEMPLATE_FAILURE,
		error
	};
};

export const createTemplateDsrRequest = () => {
	return {
		type: types.CREATE_DSR_TEMPLATE_REQUEST
	};
};

export function createTemplateDsr(createDsrTemplateMetadata) {	
	return async (dispatch) => {
		dispatch(createTemplateDsrRequest());
		try {
			const url = `/template/create`;
			const headers = await auth.getAccessToken();
			const response = await axiosInstance.post(
				url,
				createDsrTemplateMetadata,
				{ headers }
			);


			dispatch(createTemplateDsrSuccess(response));
				
		} catch (error) {
			dispatch(createTemplateDsrFailure(error));
		}
	};
}