import React, { memo } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import LaunchdeckCloseButton from '../helper-components/components/LaunchdeckCloseButton';
import { withRouter } from 'react-router-dom';
import { GoAlertFill } from "react-icons/go";
import LaunchdeckButton from '../helper-components/components/LaunchdeckButton';
import { connect } from 'react-redux'
import { changeDsrModalState, changeDsrPopupState } from '../../../digital-sales-room/actions/dsr-actions';
import { bindActionCreators } from 'redux'

function DemoAcquisitionNudgeBanner({ handleCloseBanner, updateUserSettings, isUndismissable, changeDsrPopupState, changeDsrModalState }) {

	const handleDismissBanner = () => {

		if (!isUndismissable) {
			updateUserSettings({
				updatedBannerSettings: {
					bannerType: "demo-user-acquistion-reminder",
					doNotShowBanner: true
				}
			})
		}
	
		handleCloseBanner()
	}

	const handleSwitchToSalesTeamView = () => {
		changeDsrPopupState("switch-demo-user-to-org-admin-view")
		changeDsrModalState(true)
	}

	return (

		<Flex w='100%' position='relative' direction='column' bg='white' border='1px solid' borderColor='yellow.400' rounded='0.5em' p='0.5em 1em' justify='flex-start' mb='1.5em'>

			<Flex w='100%' justify='space-between' align='center'>
				<Flex align='center' justify='flex-start' w='100%'>
					<Box as={GoAlertFill} color='yellow.400' fontSize='1.2em' />
					<Box ml='1em' fontSize='0.9em' fontWeight='semibold' color='brandDark.500'>You're currently experiencing the Customer View </Box>
				</Flex>

				<Box top='1em' right='1em' visibility={isUndismissable ? "hidden" : ""} onClick={() => handleDismissBanner()}> <LaunchdeckCloseButton /></Box>
			</Flex>

			<Flex mt='0.5em' w='100%' justify='flex-start' align='center' fontSize='0.875em' pb='0.5em'>
				<Box color='brandDark.500' >Looking to explore further building Rooms and using Sales analytics? Switch over to Sales Team View with a free trial code. </Box>
				<Box ml='1.5em' ><LaunchdeckButton bg='yellow.400' color='brandDark.500' noHover='true' label='Switch over to Sales team view' onClick={() => handleSwitchToSalesTeamView()} /></Box>
			</Flex>

		</Flex>
	);

}


const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			changeDsrPopupState,
			changeDsrModalState,
		},
		dispatch,
	)

	
export default memo(withRouter(connect(null, mapDispatchToProps)(DemoAcquisitionNudgeBanner)))
