import React from "react";

import { Box, Flex, Image } from "@chakra-ui/react";
import mapEmptyStateImage from '../../../digital-sales-room/dsr-action-list/styles/images/map-empty-state.svg'


export default function PublicDsrMapSignupPrompt({ handleOpenSignupOrLoginModal }) {

  return (
    <Box>
      <Box textAlign={['center','center','left','left']}>
        <Box color='brandDark.500' fontSize='1.5em' fontWeight='600' lineHeight='2.25em'>
          Mutual Action Item
        </Box>
        <Box fontSize="1em" color="brandDark.500" fontWeight='400' lineHeight={['1.25em','1.25em','2em','2em']}>
          Create and assign important tasks to keep everyone involved and up-to-date
        </Box>
      </Box>


      <Flex mt='2em' justify='center' align='center' py='2.75em' border='1px solid #E5EAEF' rounded='0.5em' bg='white'>

        <Flex direction='column' justify='center' align='center'>
          <Image src={mapEmptyStateImage} alt="Empty Mutual Action Plan Page" />

          <Box fontWeight='400' lineHeight='1.5em' color='gray.500' p='1.5em 1em' textAlign='center'>
            The Room owner allows only its members to view the plan
          </Box>

          <Box whiteSpace='nowrap' >
            <Flex className='create-new-category-button'
              onClick={() => handleOpenSignupOrLoginModal()}>
              <Box className='create-new-category-button__button-text'>Join Room</Box>
            </Flex>
          </Box>
        </Flex>

      </Flex>


    </Box>
  );

}