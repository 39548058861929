import React, { memo } from 'react'
import { Box, Flex } from '@chakra-ui/react'
import DsrDisplayTeamAvatar from '../dsr-team-mgmt/DsrDisplayTeamAvatar'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { changeDsrModalState, changeDsrPopupState } from '../actions/dsr-actions'

import '../../layout/components/header/styles/header.scss';
import DsrManageOrgLogosModule from '../dsr-draft/DsrManageOrgLogosModule';
import StaticDsrHeaderMobileDisplay from './StaticDsrHeaderMobileDisplay'
import LaunchdeckButton from '../../global/components/helper-components/components/LaunchdeckButton'
import { displayReactIcon } from '../helpers/dsr-display-helpers'
import { redirectToNewPage } from '../../global/helpers/global-helpers'

// Component holding other authentication components
function StaticDsrHeader(props) {
	const {
		dsrTeamMembers,
		isLoggedInUser,
		// Action
		changeDsrPopupState,
		changeDsrModalState,
		// Redux
		isMobileView,
		dsrTitleRedux,
	} = props || {};

	const handleOnClick = () => {									
		if (isLoggedInUser) {
			changeDsrPopupState("show-add-logged-in-user-prompt")
			changeDsrModalState(true)
		} else {
			changeDsrPopupState("show-signup-login-prompt")
			changeDsrModalState(true)
		}
	}

	if (isMobileView) {
		return (
			<StaticDsrHeaderMobileDisplay currentDsrTitle={dsrTitleRedux} />
		)
	} else {
		// if (dsrTitleRedux) {
		return (
			<>
				<Flex className='dsr-header__section-guest-version'>
					{dsrTitleRedux &&
						<Flex className='dsr-header__container' alignItems='center'>
							<React.Fragment>
								<Flex align='center'>
									<Box mr='1em' />
									<Flex align='center'>
										<Flex align='center'>
											{isLoggedInUser &&
														<Flex color='white' align='center' cursor='pointer' onClick={()=>redirectToNewPage('/home')}>
															{displayReactIcon('back-arrow')}
															{/* <Box>Back</Box> */}
															
														</Flex>
										}
										<Box ml={isLoggedInUser ? '1.5em' : '0'} mr='1.5em'>
											<DsrManageOrgLogosModule logoDisplayMode={'default-dsr'} />
										</Box>
									
										</Flex>
										<Flex fontWeight='600' fontSize='1.25em' lineHeight='1.875em' color='white'>{dsrTitleRedux} </Flex>
									</Flex>
								</Flex>

								<Flex align='center'>
									<Flex>
										<Box><DsrDisplayTeamAvatar perspective='seller' dsrTeamMembers={dsrTeamMembers} /></Box>
										<Box><DsrDisplayTeamAvatar perspective='buyer' dsrTeamMembers={dsrTeamMembers} /></Box>
									</Flex>

									<Box rounded='0.25em'>
										<LaunchdeckButton label='Join Room' width='100%' bg='brand.500' color='white'
											onClick={() => handleOnClick()} />
									</Box>
								</Flex>
							</React.Fragment>
						</Flex>
					}
				</Flex>
			</>
		);


	}
}

const mapStateToProps = (state) => ({
	dsrTitleRedux: state.dsrTitle.title,
	isMobileView: state.mobileViewMode.isMobileView
})



const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			changeDsrPopupState,
			changeDsrModalState,
		},
		dispatch,
	)

	
export default memo(withRouter(connect(mapStateToProps, mapDispatchToProps)(StaticDsrHeader)))

