import React, { useEffect, useRef, useState } from "react";
import { Alert, AlertIcon, Box, Fade, Flex, Link, useDisclosure } from "@chakra-ui/react";
import FileUploadProgress from "../../../dsr-create-post/FileUploadProgress";
import config from "../../../../../config/app-config";
import { BsFiletypePdf } from "react-icons/bs";
import LaunchdeckButton from "../../../../global/components/helper-components/components/LaunchdeckButton";
import { FiExternalLink } from "react-icons/fi";

export const UploadedPdfDocument = ({
  dsrId,
  subWidgetId,
  setSubWidgetContent,
  subWidgetContent,
  uploadFiles,
  uploadFileMultithreaded,
  uploadFilesState,
  uploadFilesReset,
  subWidgetAutoSave,
  handleSaveItemByClickOutside,
}) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const { isLoading, results, error } = uploadFilesState;
  const containerRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  const [processingSubwidgetId, setProcessingSubwidgetId] = useState("")

  const fileUrl = subWidgetContent ? subWidgetContent[0].url : "";
  // const regex = /\/([^/]+)\/[^/]+\/[^/]+\.pdf/;
  const regex = /\/([A-Za-z0-9%\- ]+)\.pdf$/;

  const match = fileUrl.match(regex);
  const documentName = match ? decodeURIComponent(match[1]) : "PDF document";
  const {
    maxPdfFileSize,
    minPdfFileSizeForMultithreadedUpload,
    displayMaxFileSize,
  } = config;

  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isFocused &&
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setIsFocused(false);
        handleSaveItemByClickOutside();
      }
    };

    if (isFocused) {
      setTimeout(() => window.addEventListener("click", handleClickOutside), 1);
    } else {
      window.removeEventListener("click", handleClickOutside);
    }

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, [isFocused]);

  useEffect(() => {
    if (
      !isLoading &&
      !error &&
      Array.isArray(results?.files) &&
      results?.files.length > 0 &&
      results?.files[0]?.subWidgetId === subWidgetId
    ) {
      // Save uploaded file response to sub-widget content
      setSubWidgetContent(results.files);
      setProcessingSubwidgetId("")
      uploadFilesReset();
    }
  }, [results]);

  useEffect(() => {
    if (!isLoading && !error && subWidgetContent[0]?.key) {
      subWidgetAutoSave();
      setIsFocused(false);
    }
  }, [subWidgetContent]);

  const validateInput = (file) => {
    if (!file) {
      throw new Error("UploadedPdf must be object");
    }

    if (!file?.name) {
      throw new Error("UploadedPdf does not have filename");
    }

    if (file?.size > maxPdfFileSize) {
      // File size exceeds the limit
      setErrorMessage(
        `Your PDF file size has to be ${displayMaxFileSize / (1024 * 1024)}MB or below. If you require the use of larger PDF files, providing a link to Google drive or Dropbox folder may be a better option.`,
      );
      throw new Error("File size exceeds the limit");
    }
  };

  const handleFileInputChange = async (e) => {
    const file = e?.target?.files[0];

    try {
      validateInput(file);
    } catch (e) {
      if (process.env.NODE_ENV === "development") console.log(e);

      return;
    }

    setProcessingSubwidgetId(subWidgetId)
    file?.size <= minPdfFileSizeForMultithreadedUpload
      ? uploadFiles({ dsrId, files: [file], subWidgetId })
      : uploadFileMultithreaded({ dsrId, file, subWidgetId });
  };

  return subWidgetContent ? (
    <Box width="100%"
      onMouseEnter={onOpen}
      onMouseLeave={onClose}
      position='relative'>


      <Fade in={isOpen}>
        <Flex
          position="absolute"
          top={0}
          left={0}
          width="100%"
          align='center'
          justify='space-between'
          bg='blackAlpha.800'
          color="white"
          textAlign="center"
          p='0.75em 1.5em'
          zIndex={1}
          style={{ transition: "top 3s ease" }}
          roundedTop='0.5em'
        >
          <Box textAlign='left' color='white' fontSize='0.875em' p='0.2em 1em' >{documentName}</Box>
          <Link href={fileUrl} lineHeight='1.625em' fontSize='1em' isExternal>
            <FiExternalLink />
          </Link>
        </Flex>
      </Fade>

      <iframe
        className="loader-img"
        title={documentName}
        src={`${subWidgetContent[0].url}#toolbar=0`} // hide pdf preview menu
        width="100%"
        height="750"
      ></iframe>

    </Box>
  ) : (
    <Box
      width="100%"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      border="1px solid #CBD5E0"
      borderRadius="8px"
      backgroundColor="#FDFDFD"
    >
      {isLoading && (processingSubwidgetId === subWidgetId) && (
        <Flex w="100%" py="2em" justify="center">
          <FileUploadProgress showLoader={isLoading} />
        </Flex>
      )}

      {((!isLoading && !subWidgetContent) || (processingSubwidgetId !== subWidgetId)) && (
        <Flex
          ref={containerRef}
          justifyContent="center"
          alignItems="center"
          direction="column"
          mt="10px"
          minH="12.5em"
          p="5px"
          m="5px"
        >
          <Box>
            <Box
              fill="brandDark.500"
              as={BsFiletypePdf}
              width="48px"
              height="2.5em"
              my="2em"
            />
          </Box>
          <Flex width="300px" justifyContent={"center"} justifyItems={"center"}>
            <label>
              <input
                type="file"
                style={{ display: "none" }}
                accept="application/pdf"
                className="file-input"
                onChange={handleFileInputChange}
              />
              <LaunchdeckButton
                bg="brandDark.500"
                color="white"
                px="2em"
                label="Upload PDF file"
              />
            </label>
          </Flex>
          <Flex
            direction="column"
            my="1.5em"
            fontSize="0.8em"
            color="brandDark.500"
            justify="center"
            align="center"
          >
            <Box>Supported Format: PDF only</Box>
            <Box mt="0.2em">Upload limit: 100MB</Box>
          </Flex>
        </Flex>
      )}
      {/* Info Message (error or success) */}
      {errorMessage && (
        <Box justifyContent="center" mt="15px" mb="15px" px="1em">
          <Alert
            status={"error"}
            variant="solid"
            px="1em"
            bg="pink.50"
            rounded="0.25em"
            border="1px solid"
            borderColor="brandPink.500"
          >
            <AlertIcon boxSize={10} color="brandPink.500" />
            <Box fontSize="0.875em" color="brandDark.500" ml="1em" py="1em">
              <Box fontWeight="600">
                The current upload limit is {displayMaxFileSize / (1024 * 1024)}{" "}
                MB{" "}
              </Box>
              <Box mt="0.5em">
                For larger video files, we recommend using Dropbox/Google drive
                links to your PDF file{" "}
              </Box>
            </Box>
          </Alert>
        </Box>
      )}
    </Box>
  );
};
