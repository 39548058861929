import auth from '../../../config/app-specific-config/auth';
import * as types from './global-actions-types';
import { axiosInstance } from '../../../config/app-specific-config/axiosConfig';

export const setIsMobileView = (p) => {
	return {
		type: types.SET_IS_MOBILE_VIEW,
		payload: p
	};
};

export const sendSystemNotificationEmailSuccess = (response) => {
	return {
		type: types.SEND_SYSTEM_NOTIFICATION_EMAIL_SUCCESS,
		payload: response.data
	};
};

export const sendSystemNotificationEmailFailure = (error) => {
	return {
		type: types.SEND_SYSTEM_NOTIFICATION_EMAIL_FAILURE,
		error
	};
};

export const sendSystemNotificationEmailRequest = () => {
	return {
		type: types.SEND_SYSTEM_NOTIFICATION_EMAIL_REQUEST
	};
};

export function sendSystemNotificationEmail(submittedContent) {
	
	return async (dispatch) => {
		dispatch(sendSystemNotificationEmailRequest());
		try {
			const url = '/emailer/internal-notification-email';
			const headers = await auth.getAccessToken();
			const response = await axiosInstance.post(url, submittedContent, { headers });

			dispatch(sendSystemNotificationEmailSuccess(response));
		} catch (error) {
			dispatch(sendSystemNotificationEmailFailure(error));
		}
	};
}