import config from "../../../config/app-config";
import Resizer from 'react-image-file-resizer';
import React from 'react';
import { Flex, Box, Image, Text } from "@chakra-ui/react";
import ShortLogo from '../../global/styles/images/launchdeck-short-logo-icon.png';
import brandLabel from '../../digital-sales-room/styles/images/ld_brand_label.svg'
import ColorLaunchdeckLogo from '../../layout/components/header/styles/images/launchdeck_logo.svg';
import { displayEmoji } from "../../digital-sales-room/helpers/dsr-helpers";

//Detects whether a mobile display is used
export function validateEmail(emailField) {
  const reg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  if (!emailField || reg.test(emailField) === false) {
    return false;
  }

  return true;
}

export function validateUrlFormat(urlText) {

  // return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(urlText);

  const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator

  return !!pattern.test(urlText);

}

export function displayModeCheck() {
  const { tabletWidthBreakpoint } = config;
  const isMobileView = (window.innerWidth < tabletWidthBreakpoint) ? true : false;

  return isMobileView;
}

export function reloadPage() {
  window.location.reload();
}

export function routeToUnexpectedLoggedInErrorPage() {
  window.location.href = "/invalid";
}

export function routeToDashboardPage() {
  window.location.href = "/home";
}

export function redirectToNewPage(routeLink) {
  window.location.href = `${routeLink}`
}

export function redirectToLandingPage() {
  window.location.href = `https://www.launchdeck.app`
}


//Generate Random Key (For ES6 Map)
export function getRandomKey() {
  return Math.random().toString(36).substr(2, 5);
}

export function getThisUserIdToken() {
  return localStorage.getItem('USER_ID_TOKEN');
}

export function generateSessionId() {
  return 'ses__' + generateRandomString(10)
}

// Mothball - set this for invited user 
// export function getThisUserPrivileges() {
//   const userPrivileges = localStorage.getItem('USER_PRIVILEGES');
//   return JSON.parse(userPrivileges)
// }


// Single Layer Filter with single Criteria
export function filterTemplateArray(retrievedAllAgreementTemplates, filters) {
  const { genericTemplates, restrictedTemplates } = retrievedAllAgreementTemplates || {};
  const isOrgTemplates = (filters === 'restricted') ? true : false;

  const getValue = value => (typeof value === 'string' ? value.toLowerCase() : value);


  if (!retrievedAllAgreementTemplates || (!genericTemplates && !restrictedTemplates)) {
    return [];
  }

  if (isOrgTemplates && restrictedTemplates) {
    return restrictedTemplates.filter(item => {
      return item;
    });
  } else if (!filters) {
    return genericTemplates.filter(item => {
      return item;
    });
  } else {

    return genericTemplates.filter(item => {
      const templateTags = item.templateTags;

      return templateTags.find(tag => getValue(tag) === getValue(filters));
    });
  }

}


// Resize Images
export function resizeImage(file) {

  return new Promise((resolve, reject) => {
    Resizer.imageFileResizer(
      file,
      100,
      100,
      'JPEG',
      100,
      0,
      uri => {
        resolve(uri);
      },
      'blob',
      100,
      100,
    );
  })

}

export function resizeLogoImage(file) {

  return new Promise((resolve, reject) => {
    Resizer.imageFileResizer(
      file,
      200,
      200,
      'JPEG',
      100,
      0,
      uri => {
        resolve(uri);
      },
      'blob',
      200,
      200,
    );
  })

}

export function resizeBannerImage(file) {
  return new Promise((resolve, reject) => {
    Resizer.imageFileResizer(
      file,
      1120,
      160,
      'JPEG',
      100,
      0,
      uri => {
        resolve(uri);
      },
      'blob',
      1120,
      160,
    );
  })

}


// Parse Youtube links to Youtube embed links
export function getYoutubeEmbedLink(url) {
  const youtubeRegex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
  const match = url.match(youtubeRegex);
  if (match && match[1]) {
    const videoId = match[1];
    const urlParams = new URLSearchParams(new URL(url).search);
    const startTime = urlParams.get('t');
    const timeMatch = startTime ? startTime.match(/(\d+)s?/) : "";
    const scrubbedSTartTime = timeMatch ? parseInt(timeMatch[1]) : "";
    let embedUrl = `https://www.youtube.com/embed/${videoId}?controls=1&iv_load_policy=3&showinfo=0`;

    if (scrubbedSTartTime) {
      embedUrl += `&start=${scrubbedSTartTime}`;
    }

    return embedUrl;
  }
}

// User Search bar: Finding Agreements & Templates
export function searchBarResultsAgreements(query, document) {
  const queryIsAlphaNumeric = query ? (/[a-zA-Z]/).test(query) : false;
  const searchFields = document && document.agreement;
  const { agreementName, counterpartyUserName, counterpartyEmail, creatorUserName } = searchFields || {};

  if (queryIsAlphaNumeric) {
    return (agreementName && agreementName.toLowerCase().includes(query.toLowerCase())) ||
      (counterpartyUserName && counterpartyUserName.toLowerCase().includes(query.toLowerCase())) ||
      (counterpartyEmail && counterpartyEmail.toLowerCase().includes(query.toLowerCase())) ||
      (creatorUserName && creatorUserName.toLowerCase().includes(query.toLowerCase()))

  } else if (!queryIsAlphaNumeric) {
    // Non alphanumeric Emails are not returned
    return (agreementName && agreementName.includes(query)) ||
      (counterpartyUserName && counterpartyUserName.includes(query)) ||
      (creatorUserName && creatorUserName.includes(query))
    // Email taken out as a return field if search term is not alphanumeric
  }

}

// Open new tab
export function openInNewTab(url) {

  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    url = 'https://' + url;
  }

  window.open(url, '_blank')
}

// Formats link
export function formatHttpLinks(url) {
  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    url = 'https://' + url;
  }

  return url
}

// Generate random strings
export function generateRandomString(length) {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

// Copy text to user clipboard
export function copyToUserClipboard(textContent) {
  return navigator.clipboard.writeText(textContent)
}

// UX formatting
export function displayMoney(currency, numberValue) {
  if (currency && Number.isInteger(numberValue)) {
    return new Intl.NumberFormat('en-US', { style: 'currency', minimumFractionDigits: 0, currency: currency }).format(numberValue);
  } else if (!currency && Number.isInteger(numberValue)) {
    return new Intl.NumberFormat('en-US', { minimumFractionDigits: 0 }).format(numberValue);
  } else return "";
}

// Toast
export function triggerLaunchdeckToast({ useCase, isMobileView, bannerText, label, duration }) {

  switch (useCase) {
    case ("show-error-state"): {
      const displayedErrorMessage = label ? label : "Unfortunately something went wrong. Please try again later.";

      if (isMobileView) {
        return {
          position: 'bottom',
          duration: duration ? duration : 3600,
          isClosable: true,
          render: () => (
            <Flex m={8} rounded='0.5em' className="default-box-shadow" w='100%'>
              <Box rounded='0.5em' bg='pink.300' w='100%'>
                <Flex textAlign='center' justify='center' w='100%' px='1em' py='0.5em' fontSize='0.875em' color='white'>  {displayedErrorMessage} </Flex>
              </Box>
            </Flex>
          ),
        }
      } else {
        return {
          position: 'bottom-right',
          duration: 3600,
          isClosable: true,
          render: () => (
            <Box m={8} rounded='0.5em' className="default-box-shadow">
              <Flex className="toast-banner" bg='gray.700' color='white'>
              {displayEmoji('warning')}
                <Text textAlign='left' ml='0.5em' fontSize='0.875em' fontWeight='600' color='brandDark.500'>{bannerText ? bannerText : 'Something went wrong'}</Text>
              </Flex>
              <Box roundedBottomLeft='0.5em' roundedBottomRight='0.5em' bg='white'>
                <Text textAlign='left' color='gray.700' px='1em' py='0.5em' fontSize='0.875em'> {displayedErrorMessage} </Text>
              </Box>
            </Box>
          ),
        }
      }
    }
    case ("work-in-progress-state"): {
      const displayedWipMessage = label ? label : "We are working on it";

      if (isMobileView) {
        return {
          position: 'bottom',
          duration: duration ? duration : 3600,
          isClosable: true,
          render: () => (
            <Flex m={8} rounded='0.5em' className="default-box-shadow" w='100%'>
              <Box rounded='0.5em' bg='pink.300' w='100%'>
                <Flex textAlign='center' justify='center' w='100%' px='1em' py='0.5em' fontSize='0.875em' color='white'>  {displayedWipMessage} </Flex>
              </Box>
            </Flex>
          ),
        }
      } else {
        return {
          position: 'bottom-right',
          duration: 3600,
          isClosable: true,
          render: () => (
            <Box m={8} rounded='0.5em' className="default-box-shadow">
              <Flex className="toast-banner" bg='gray.700' color='white'>
                {displayEmoji('gear')}
                <Text textAlign='left' ml='0.5em' fontSize='0.875em' fontWeight='600' color='brandDark.500'>{bannerText ? bannerText : 'We are working on it'}</Text>
              </Flex>
              <Box roundedBottomLeft='0.5em' roundedBottomRight='0.5em' bg='white'>
                <Text textAlign='left' color='gray.700' px='1em' py='0.5em' fontSize='0.875em'> {displayedWipMessage} </Text>
              </Box>
            </Box>
          ),
        }
      }
    }
    case ("show-success-state"):
    default: {
      // Including success state
      const displayedSuccessMessage = label ? label : "Successful!"
      if (isMobileView) {
        return {
          position: 'bottom',
          duration: 3600,
          isClosable: true,
          render: () => (
            <Flex m={8} rounded='0.5em' className="default-box-shadow" w='100%'>
              <Box rounded='0.5em' bg='green.500' w='100%'>
                <Flex textAlign='center' justify='center' w='100%' px='1em' py='0.5em' fontSize='0.875em' color='white'>  {displayedSuccessMessage} </Flex>
              </Box>
            </Flex>
          ),
        }
      } else {
        return {
          position: 'bottom-right',
          duration: 3600,
          isClosable: true,
          render: () => (
            <Box m={8} rounded='0.5em' className="default-box-shadow">
              <Flex className="toast-banner">
                <Image src={ShortLogo} boxSize="0.9em" objectFit="cover" alt="Launchdeck Short Logo" />
                <Text textAlign='left' color='brandDark.500' ml='0.5em'>{bannerText ? bannerText : 'Success'}</Text>
              </Flex>
              <Box roundedBottomLeft='0.5em' roundedBottomRight='0.5em' bg='white'>
                <Text textAlign='left' color='gray.700' px='1em' py='0.5em' fontSize='0.875em'> {displayedSuccessMessage} </Text>
              </Box>
            </Box>
          ),
        }
      }
    }
  }


}

// Brand label display
export function displayBrandingLabel(brandType) {
  
  const handleClickBrandLabel = () => {
    window.location.href = "https://www.launchdeck.app"; // Consider adding marketing uid
  }

    switch (brandType) {
      case ("mobile"): {
        return (
          <Flex position='sticky' w='100%' justify='center'>
            <Flex cursor='pointer' w='100%' justify='center' fontSize='0.7em' onClick={() => handleClickBrandLabel()}>
              Powered by
              <Image src={ColorLaunchdeckLogo} maxW='7em' ml='0.5em' alt="Launchdeck brand label" />
            </Flex>
          </Flex>
        )
      }
      case ("web"):
      default: {
        return (
          <Flex position='sticky' w='100%' pb='1em' justify='center'>
            <Flex cursor='pointer' w='100%' onClick={() => handleClickBrandLabel()}>
              <Image src={brandLabel} maxW='14em' alt="Launchdeck brand label" mx='auto' />
            </Flex>
          </Flex>
        )
      }
    }
}


// Check if dismissed banner
export function checkIfDismissedBanner(userSettings, bannerTypeValue) {
  const {userBannerSettings} = userSettings || {}
  const {dismissedBanners} = userBannerSettings || {};

  if (Array.isArray(dismissedBanners)) {
    const hasObjectWithFieldValue = dismissedBanners.some(obj => obj["bannerType"] === bannerTypeValue);
    return hasObjectWithFieldValue
  } else {
    return false
  }
 
}

// Searching array of objects
export function searchArrayofObjects(array, searchString, propertiesArray) {
  return array.filter(obj => 
    propertiesArray.some(property => 
      obj[property] && obj[property].toString().toLowerCase().includes(searchString.toLowerCase())
    )
  );
}

// Timeout timers
// export function timeoutTool(timeInMilliseconds, callbackFunction) {
//   if (Number.isInteger(timeInMilliseconds)) {
//     return setTimeout(() => {
//       // After 3 seconds set the show value to false
//       callbackFunction()
//     }, timeInMilliseconds)
//   }
// }