import React from 'react';

import { Box, Modal, ModalOverlay, ModalBody, ModalContent, Flex  } from "@chakra-ui/react";
import EditCategoryForm from './EditCategoryForm';
import LaunchdeckCloseButton from '../../global/components/helper-components/components/LaunchdeckCloseButton';



export default function EditCategoryModal(props) {

  const { showModal, mapCategoryId, categoryToEdit, handleCloseModal, dsrId } = props || {}; 

  if (!showModal) {
    return null;
  }

  if (showModal) {
    return (
      <Modal isOpen={true}  closeOnOverlayClick={false} isCentered={true} preserveScrollBarGap={true} >
        <ModalOverlay />
        <ModalContent rounded='0.25em' minW={['90%', '500px']} maxW='700px'>
          <ModalBody px='0em'>
            <Flex direction='column' justify='space-around' alignItems='center' w='100%'>

              <Flex justify='space-between' align='center' w='100%' borderBottom='1px solid' borderBottomColor='gray.300'>
                <Flex px='1.5em' py='0.5em' justify='flex-start' align='center'>
                  <Box fontWeight='500' fontSize='1em' lineHeight='1.375em' color='brandDark.500'>Edit Category</Box>
                </Flex>
                <Box pr='1.5em'>
                  <LaunchdeckCloseButton handleCloseModal={() => handleCloseModal()} />
                </Box>
              </Flex>

              <Flex justify='center' mt='1em' w='100%'>
              <EditCategoryForm
                  dsrId={dsrId}
                  mapCategoryId={mapCategoryId}
                  categoryToEdit={categoryToEdit}
                  handleCloseModal={handleCloseModal}
                />

              </Flex>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    )
  }


}
