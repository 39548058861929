import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'

import { Box , Flex, Modal, ModalBody, ModalContent, ModalOverlay, useToast } from '@chakra-ui/react'
import { MdUnpublished } from "react-icons/md";
import { resetUpdatePdsrOutcome, updatePdsr } from '../../actions/dsr-actions'
import { redirectToNewPage, triggerLaunchdeckToast } from '../../../global/helpers/global-helpers'
import PageLoader from '../../../global/components/helper-components/components/PageLoader'
import LaunchdeckButton from '../../../global/components/helper-components/components/LaunchdeckButton'
import LaunchdeckCloseButton from '../../../global/components/helper-components/components/LaunchdeckCloseButton'



function ProspectDsrUnpublishModal({
  pDsrId,
  handleCloseModal,
  updateProspectDsrOutcome,
  updatePdsr,
  resetUpdatePdsrOutcome,
  isMobileView
}) {

  const toast = useToast();

  // UX Management
  const [showLoader, setShowLoader] = useState(false)

  useEffect(() => {

    if (updateProspectDsrOutcome) {
      const { updatePdsrStatus, sourceEvent } = updateProspectDsrOutcome || {};

      if (updatePdsrStatus && sourceEvent === "unpublish-prospector-dsr") {
        toast(
          triggerLaunchdeckToast({
            useCase: "show-success-state",
            bannerText: "Prospector room unpublished",
            label: "The Prospector Room is now back in draft state",
            isMobileView
          })
        )

        resetUpdatePdsrOutcome()
        redirectToNewPage(`/draft/pdsr/${pDsrId}`)
      }
    }

  }, [updateProspectDsrOutcome, pDsrId, resetUpdatePdsrOutcome, toast]);

  const handleClickUnpublishButton = () => {
    setShowLoader(true);
    updatePdsr({
      pDsrId,
      sourceEvent: "unpublish-prospector-dsr",
      status: 'reverted-draft'
    })
  }

  function displayContent() {
    if (showLoader) {
      return (
        <Box w="100%" align="center" >
          <Box px='1em'>
            <Box>
              <PageLoader />
            </Box>
          </Box>
        </Box>
      )
    }

    if (!showLoader) {

      return (
        <Flex justify='flex-start' px='1.5em' mt='1em' w='100%'>
          <Box w='100%'>

            <Box w="100%" my="1em" fontSize='0.875em' color='brandDark.500'>
              Guest & Host Members will not be able to view it when unpublished. Proceed?
            </Box>

            <Flex w='100%' mt='1em'>
              <Flex my='0.625em' justify='flex-end' w='100%'>
                <LaunchdeckButton name='showUnpublishToRevertedDraft' label='Unpublish' fontSize='0.9em' py='0.8em' bg='gray.700' color='white' onClick={() => handleClickUnpublishButton()} />
              </Flex>
            </Flex>
          </Box>
        </Flex>
      )
    }
  }

  return (
    <Modal isOpen={true} closeOnOverlayClick={false} isCentered={true} >
      <ModalOverlay className="modal-background" />
      <ModalContent rounded='0.25em' minW={['90%', '500px']} maxW='700px'>
        <ModalBody px='0em'>
          <Flex direction='column' justify='space-around' alignItems='center' w='100%'>
            <Flex justify='space-between' w='100%' borderBottom='1px solid' borderBottomColor='gray.300'>
              <Flex px='1em' justify='flex-start' align='center'>
                <Box as={MdUnpublished} color='brandPink.500' boxSize="1.2em" />
                <Box px='0.5em' py='0.5em' color='brandDark.500' fontWeight='600'>Unpublish this Prospector</Box>
              </Flex>

              <Box pr='1.5em'>
                <LaunchdeckCloseButton handleCloseModal={() => handleCloseModal()} />
              </Box>
            </Flex>

            <React.Fragment>
              {displayContent()}
            </React.Fragment>

          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

function mapStateToProps(state) {
  return {
    updateProspectDsrOutcome: state.updateProspectDsrOutcome.results.data,
    isMobileView: state.mobileViewMode.isMobileView
  };
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updatePdsr,
      resetUpdatePdsrOutcome
    },
    dispatch,
  )

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProspectDsrUnpublishModal));
