import { SET_JOIN_DSR_REQUESTS } from '../../actions/dsr-actions-types'

const INITIAL_STATE_JOIN_ROOM_REQUESTS = { results: "" }

export function setJoinRoomRequestsReducer(state = INITIAL_STATE_JOIN_ROOM_REQUESTS, action) {
    switch (action.type) {
        case SET_JOIN_DSR_REQUESTS:
            return { results: action.payload };
        default:
            return state;
    }
}