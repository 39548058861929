import React, { useState } from 'react';
import { Box, Flex, Spinner, Image, Text } from '@chakra-ui/react';


export default function ImageDisplayWithLoader(props) {
  const [imageIsLoading, setImageIsLoading] = useState(true);
  const { imageUrl, altText, width, height, border, borderColor, rounded } = props;

  if (imageUrl) {
    return (
      <Box width={width} height={height} bg='white' border={border} borderColor={borderColor} rounded={rounded} overflow='hidden'>
        {imageIsLoading &&
          <Flex alignItems='center' justify='center' w='100%' h='100%'>
            <Spinner
              thickness="3px"
              speed="0.65s"
              emptyColor="gray.200"
              color="brand.500"
              boxSize="2em"
              zIndex='10'
            />
          </Flex>
        }
        <Flex justify='center' alignItems='center' height='100%'>
          <Image src={imageUrl} objectFit="cover" alt={altText} visibility={imageIsLoading ? 'hidden' : ''} onLoad={() => setImageIsLoading(false)} />
        </Flex>
      </Box>
    )
  }

  if (!imageUrl) {
    return (
      <Flex w='100%' width={width} height={height ? height : 'inherit'} bg='gray.300' justify='center' alignItems='center' rounded='0.5em'>
        <Text color='#fff' textAlign='center'>No Logo</Text>
      </Flex>
    )
  }
}
