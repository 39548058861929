import auth from '../../../config/app-specific-config/auth';
import * as types from '../actions/genesis-setup-action-types';
import { axiosInstance } from '../../../config/app-specific-config/axiosConfig';
import { setUserPrivileges } from './auth-actions';


// Login actions
function genesisSetupRequest() {
	return {
		type: types.GENESIS_SETUP_REQUEST
	};
}

function genesisSetupSuccess(response) {
	return {
		type: types.GENESIS_SETUP_SUCCESS,
		payload: response.data
	};
}

function genesisSetupFailure(response) {
	return {
		type: types.GENESIS_SETUP_FAILURE,
		payload: response
	};
}

// GENESIS_SETUP_RESET
 
export function genesisSetup(setupMetadata) {

	return async (dispatch) => {
		dispatch(genesisSetupRequest());
		try {
			const url = '/user-sessions/genesis/setup';

			const response = await axiosInstance.post(
				url,
				{setupMetadata},
				{ }
			);

			// log in user in background if password has been setup successfully
			if (response.data.data.useCaseProcessed === "set-genesis-password") {
				if (response.data.data.userSessions[0]) {
					const userSession = response.data.data.userSessions[0];
					const userData = userSession && userSession.users[0];
					
					const {userType} = userSession || {};
					const {idToken} = userData || {};

					const userPrivileges = response.data.data.userPrivileges;

					auth.storeToken(userSession.token);
					userPrivileges && localStorage.setItem('USER_PRIVILEGES', JSON.stringify(userPrivileges));
					localStorage.setItem('USER_CATG', userType);  
					localStorage.setItem('USER_ID_TOKEN', idToken);
					
					if (userPrivileges) {
						dispatch(setUserPrivileges(userPrivileges))
					}
		
				}
			}
			dispatch(genesisSetupSuccess(response));

		} catch (error) {
			dispatch(genesisSetupFailure(error));
		}
	};
}

export const genesisSetupReset = () => {
	return {
		type: types.GENESIS_SETUP_RESET
	};
};

export function resetGenesisSetup() {

	return async (dispatch) => {
	
		try {
			dispatch(genesisSetupReset());

		} catch (error) {
			dispatch(genesisSetupFailure(error));
		}
	};
}
