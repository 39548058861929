import { Box, Flex } from "@chakra-ui/react";
import LaunchdeckCloseButton from "../../global/components/helper-components/components/LaunchdeckCloseButton";
import { IoSearchOutline } from "react-icons/io5";
import FormTextInput from "../../forms/TextInputField/components/FormTextInput";
import React, { useEffect } from "react";

export const Search = ({
  searchTextInput,
  clearSearch,
  onSearchChange,
  handleSearch,
}) => {
  const handleEnter = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  useEffect(() => {
    if (searchTextInput.length === 0) {
      clearSearch();
    }
  }, [searchTextInput]);

  return (
    <>
      <Flex w="100%" align="flex-start">
        <Flex
          w="100%"
          mb="1em"
          justify="center"
          align="center"
          bg="white"
          p="0.3em 1em"
          border="1px solid"
          borderColor="gray.200"
          rounded="0.75em"
        >
          <Box mr="1em">
            {searchTextInput.length ? (
              <LaunchdeckCloseButton handleCloseModal={() => clearSearch()} />
            ) : (
              <Box
                as={IoSearchOutline}
                boxSize="1.5em"
                rounded="0.5em"
                bg="gray.100"
                p="0.25em"
                cursor="pointer"
                onClick={() => handleSearch()}
              />
            )}
          </Box>
          <FormTextInput
            w="100%"
            mb="0em"
            mt="0em"
            border="0px"
            name="sharedDocSearchInput"
            htmlFor="sharedDocSearchInput"
            label=""
            placeholder="Search"
            noBorder={true}
            value={searchTextInput}
            onChange={onSearchChange}
            onKeyDown={handleEnter}
          />
        </Flex>
      </Flex>
    </>
  );
};
