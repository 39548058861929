import React from 'react';
import { Box, Skeleton, Textarea } from "@chakra-ui/react";
import { ReactTinyLink } from "react-tiny-link";


export default function InputTextAreaExpandable(props) {

	const { id, name, bg, color, border, borderColor, fontSize, 
		onBlur, onFocus, autoFocus, onChangeHandler, 
		inputValue, placeholder, isInvalidInput, previewLink, 
		isLoadingState, textAlign, backgroundColor } = props;

	if (isLoadingState) {
		return (
			<Box position="relative" width='100%'>
				<Skeleton w="50%" height="1.2em" my="0.5em" />
			</Box>
		)
	} else return (
		<Box position="relative" width='100%' minH='8em'>
			<Textarea
				id={id && id}
				autoFocus={autoFocus && autoFocus}
				onBlur={onBlur && onBlur}
				name={name}
				minH='2.8em'
				variant={'outline'}
				onChange={onChangeHandler}
				onFocus={onFocus && onFocus}
				value={inputValue ?? ''}
				w="full"
				h="full"
				position="absolute"
				left='0'
				overflow="hidden"
				resize="none"
				fontSize={fontSize ? fontSize : '0.875em'}
				color={color && color}
        textAlign={textAlign ? textAlign : 'left'}
        backgroundColor={backgroundColor ? backgroundColor : ''}
				placeholder={placeholder && placeholder}
				rounded='0.25em'
				isInvalid={isInvalidInput}
				border={border ? border : '0px'} // Manipulate this to show or hide the input box border
				borderColor={borderColor && borderColor}
				p='0.875em'
				bg={bg && bg}
			/>

			<Textarea
				visibility="hidden"
				isInvalid={isInvalidInput}
				minH='2.8em'
				p='0.875em'
				fontSize={fontSize ? fontSize : '0.875em'}
				as="div" whiteSpace="pre-wrap" h="auto">
				{inputValue}
				{inputValue && inputValue.charAt(inputValue.length - 1) === '\n' && '\n'}
			</Textarea>
			

			{previewLink &&
				<div style={{ marginTop: '20px' }}>
					<ReactTinyLink
						cardSize="small"
						showGraphic={true}
						maxLine={2}
						minLine={1}
						url={previewLink}
					/>
				</div>

			}
		</Box>

	)

}
