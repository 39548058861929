import React from 'react'

import { Box, Flex } from '@chakra-ui/react'
import { FiList } from 'react-icons/fi'
import { FaRegQuestionCircle } from 'react-icons/fa'
import { TiVideo } from 'react-icons/ti'
import { IoMdImages } from 'react-icons/io'
import { MdEmergencyRecording } from 'react-icons/md'

export default function DsrCreatePostTypeButton({type, handleSelectSubpage, handleOpenNewPostModal, isProductMarketing, handleOpenSignupOrLoginModal}) {

  let userActionRequired = '';
  let icon="";
  let labelName="";

  switch(type) {
    case('write-post'): {
      // For mobile display only
      icon= IoMdImages;
      labelName="Write";
      userActionRequired="create-new-post"; 
      break;
    }
    case('upload-image'): {
      icon= IoMdImages;
      labelName="Upload";
      userActionRequired="create-new-post";
      break;
    }
    case('ask-question'): {
      icon= FaRegQuestionCircle;
      labelName="Ask";
      userActionRequired="create-faq-question";
       break;
    }
    // case('post-poll'): {
    //   icon= FaPoll;
    //   labelName="Poll";
    //   userActionRequired="show-sales-cta";
    //    break;
    // }
    // case('make-voice-post'): {
    //   icon= MdKeyboardVoice;
    //   labelName="Voice";
    //   userActionRequired="show-sales-cta";
    //    break;
    // }
    case('make-video-post'): {
      icon= TiVideo;
      labelName="Video";
      userActionRequired="create-video-post";
       break;
    }
    case('record-loom-video-decoy'): {
      icon= MdEmergencyRecording;
      labelName="Record";
      // userActionRequired="create-faq-question";
       break;
    }
    case('create-action-item-decoy'): {
      icon= FiList;
      labelName="Action Item";
      break;
    }
    case('create-action-item'): {
      return (
        <React.Fragment>
        <Flex align='center' justify='center' px='0.5em' mx={['0','0','1em','1em']} cursor='pointer' onClick={() => handleSelectSubpage("", 'action-list-subpage')}>
          <Box as={FiList} boxSize="1.2em" />
          <Box ml='0.75em' fontSize='0.875em' white-space="pre">Action Item</Box>
        </Flex>
      </React.Fragment>
      )
    }
    default: {
      console.log('There is no such button type:', type)
      break;
    }
  }

  if (isProductMarketing) {
    return (
      <React.Fragment>
      <Flex align='center' justify='center' px='0.5em' mx={['0','0','1em','1em']} cursor='pointer' onClick={()=>handleOpenSignupOrLoginModal()}>
        <Box as={icon} boxSize="2.5rem" />
        <Box ml='0.75em' fontSize='0.875em'>{labelName}</Box>
      </Flex>
    </React.Fragment>
    )
  } else if (icon && labelName) {
    return (
      <React.Fragment>
        <Flex align='center' justify='center' px='0.5em' mx={['0','0','1em','1em']} cursor='pointer' onClick={()=>handleOpenNewPostModal(userActionRequired)}>
          <Box as={icon} boxSize="2.5rem" />
          <Box ml='0.75em' fontSize='0.875em'>{labelName}</Box>
        </Flex>
      </React.Fragment>
    );
  } else return null;


}
