import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import { Box, Flex, Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/react'
import '../styles/dsr-display.scss'
import { MdError } from 'react-icons/md'
import LaunchdeckButton from '../../global/components/helper-components/components/LaunchdeckButton'
import { signOut } from '../../auth/actions/auth-actions'


function LoggedOutConfirmationModal({ signOut, allUserDsrs, userAccountProfile }) {

  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    if (allUserDsrs) {
      const {logUserOut} = allUserDsrs || {};

      if (!!logUserOut) {
        setShowModal(true)
      } 
    }

  }, [allUserDsrs]);

  useEffect(() => {
    if (userAccountProfile) {
      const {logUserOut} = userAccountProfile || {};

      if (!!logUserOut) {
        setShowModal(true)
      } 
    }

  }, [userAccountProfile]);

  const handleClickButton = () => {
    signOut();
  }

  function displayContent() {
    return (
      <Flex justify='flex-start' px='1.5em' mt='1em' w='100%'>
        <Box w='100%'>

          <Box w="100%" my="1em" color='brandDark.500' fontSize='0.875em'>
            <Box>
              Your Administrator has logged you out of your account.
            </Box>
          </Box>

          <Flex w='100%' mt='1em'>
            <Flex my='0.625em' justify='flex-end' w='100%'>
              <LaunchdeckButton name='remoteLogOut' label='OK' fontSize='0.9em' py='0.8em' bg='brandPink.500' color='white' onClick={() => handleClickButton()} />
            </Flex>
          </Flex>
        </Box>
      </Flex>
    )

  }

  return (
    <Modal isOpen={showModal} closeOnOverlayClick={false} isCentered={true} >
      <ModalOverlay className="modal-background" />
      <ModalContent rounded='0.25em' minW={['90%', '500px']} maxW='700px'>
        <ModalBody px='0em'>
          <Flex direction='column' justify='space-around' alignItems='center' w='100%'>
            <Flex justify='flex-start' w='100%' borderBottom='1px solid' borderBottomColor='gray.300'>
              <Flex px='1em' justify='flex-start' align='center'>
                <Box as={MdError} color='brandPink.500'
                  boxSize="1.6em" />
                <Box px='1.5em' py='0.5em' color='brandDark.500'>You have been logged out</Box>
              </Flex>
            </Flex>

            <React.Fragment>
              {displayContent()}
            </React.Fragment>

          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

function mapStateToProps(state) {
  return {
    allUserDsrs: state.allUserDsrs.results.data,
    userAccountProfile: state.userProfile.accountUserProfile,
  };
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      signOut
    },
    dispatch,
  )

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoggedOutConfirmationModal));
