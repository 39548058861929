import React, { useEffect, useRef } from 'react';
import { Flex, Box, Select, Avatar } from "@chakra-ui/react";
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { useState } from 'react';
import { optionTypes } from "../types/subWidgetOptionsTypes";
import { useParams } from 'react-router-dom';


export const Signature = ({
  subWidgetId,
  editableItemId,
  subWidgetContent,
  isDragging,
  handleEditItemByClick,
  handleSaveItemByClickOutside,
  setSubWidgetContent,
  dsrTeamMembers,

  dsrOwnerId,
  userAccountProfile,

  options
}) => {
  
  const containerRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  const [allDsrUsers, setAllDsrUsers] = useState([]);
  const [currentUserSignatureId, setCurrentUserSignatureId] = useState('');
  const [currentUserSignatureObject, setCurrentUserSignatureObject] = useState({});

  const { pDsrId } = useParams()
  const [showPdsrSignature, setShowPdsrSignature] = useState(false)


  const signature = <Flex alignItems='center'>
    <Avatar width='5em' height='5em' name={currentUserSignatureObject?.display} bg='gray.100' color='gray.700' src={currentUserSignatureObject?.avatarSrc} />
    <Flex flexDirection='column' ml='0.8em'>
      <Box className="handwriting-font" fontSize='1.25em'>{currentUserSignatureObject?.display}</Box>
      {(currentUserSignatureObject?.memberOrgTitle || currentUserSignatureObject?.memberOrgName) &&
        <Box style={{ gap: '5px' }} display='flex' fontSize='0.875em' color='gray.700'>
          {currentUserSignatureObject.memberOrgTitle && <Box>{currentUserSignatureObject.memberOrgTitle}</Box>}
          {currentUserSignatureObject.memberOrgName && <Box>@ {currentUserSignatureObject.memberOrgName}</Box>}
        </Box>
      }
    </Flex>
  </Flex>
  
useEffect(() => {

  if (pDsrId) {
    setShowPdsrSignature(true)
    setCurrentUserSignatureId(dsrOwnerId);
  } 

}, [pDsrId]);

  useEffect(() => {
    if (dsrTeamMembers) {
      const { sellerTeam, buyerTeam } = dsrTeamMembers || {};
      const allMembersArray = (sellerTeam && buyerTeam) ? [...sellerTeam, ...buyerTeam] : [...sellerTeam]

      //Find all dsr users
      let allUsers = [];

      if (Array.isArray(allMembersArray)) {
        if (allMembersArray.length > 0) {
          allMembersArray.map((organization) => {
            const { orgTeamMembers } = organization || {};

            if (orgTeamMembers && Array.isArray(orgTeamMembers)) {
              orgTeamMembers.map((user) => {
                allUsers.push({
                  id: user.userId,
                  display: user.memberName,
                  avatarSrc: user.memberProfilePicSrc,
                  memberOrgTitle: user.memberOrgTitle,
                  memberOrgName: user.memberOrgName
                })

                return null
              })
            }

            return null;
          })
        }
      }

      setAllDsrUsers(allUsers);
    }


  }, [dsrTeamMembers]);

  useEffect(() => {
    if (subWidgetContent) {
      setCurrentUserSignatureId(subWidgetContent);
    } else {
      setCurrentUserSignatureId(dsrOwnerId);
    }
  }, []);

  useEffect(() => {
    if (currentUserSignatureId && allDsrUsers) {
      const userObject = allDsrUsers.find((user) => {
        return user.id === currentUserSignatureId
      })
     
      setCurrentUserSignatureObject(userObject)

      if (currentUserSignatureId) {
        setSubWidgetContent(currentUserSignatureId)
      }
    }
  }, [currentUserSignatureId]);



  useEffect(() => {
    const handleClickOutside = (event) => {
      if ((isFocused && containerRef.current) && !containerRef.current.contains(event.target)) {
        setIsFocused(false);
        handleSaveItemByClickOutside();
      }
    };

    if (isFocused) {
      setTimeout(() => window.addEventListener('click', handleClickOutside), 1)
    } else {
      window.removeEventListener('click', handleClickOutside);
    }

    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, [isFocused]);

  const onChange = (e) => {
    setCurrentUserSignatureId(e.target.value);
  };

  const handleClick = () => {
    if (!isDragging) {
      handleEditItemByClick()
      setIsFocused(true)
    }
  };
  
  if (showPdsrSignature) {
   
    const { firstName, lastName, profilePicture: avatarSrc, orgName: memberOrgName, userOrgTitle: memberOrgTitle } = userAccountProfile || {};
    const userName = (firstName && lastName) ? firstName + ' ' + lastName : firstName ? firstName : "";

    return (
      <Flex width='100%' justifyContent='flex-start' alignItems='center' minHeight='50px' onClick={handleClick}>
        <Box display='flex' justifyContent={options[optionTypes.ALIGN]} alignItems='center' width='100%'>
          <Box p='5px' m='5px'>
            <Flex alignItems='center'>
              {avatarSrc &&
                <Avatar width='5em' height='5em' name={userName} bg='gray.100' color='gray.700' src={avatarSrc} />
              }

              <Flex flexDirection='column' ml='0.8em'>
                <Box className="handwriting-font" fontSize='1.25em'>{userName}</Box>

                {(memberOrgTitle || memberOrgName) &&
                  <Box style={{ gap: '5px' }} display='flex' fontSize='0.875em' color='gray.700'>
                    {memberOrgTitle && <Box>{memberOrgTitle}</Box>}
                    {memberOrgName && <Box>@ {memberOrgName}</Box>}
                  </Box>
                }
              </Flex>
            </Flex>
          </Box>
        </Box>

      </Flex>
    )
  } else if (!showPdsrSignature) {
    return (
      <Flex width='100%' justifyContent='flex-start' alignItems='center' minHeight='50px' onClick={handleClick}>
        {
          subWidgetId === editableItemId
            ? (
              <Box ref={containerRef} minW='200px'>
                <Select
                  height='40px'
                  size="lg"
                  onChange={onChange}
                  placeholder='Select user'
                >
                  {
                    allDsrUsers.map((user) => {
                      return <option key={user.id} value={user.id}>{user.display}</option>
                    })
                  }
                </Select>
              </Box>
            )
            : (
              currentUserSignatureId
                ? (
                  <Box display='flex' justifyContent={options[optionTypes.ALIGN]} alignItems='center' width='100%'>
                    <Box p='5px' m='5px'>
                      {signature}
                    </Box>
                  </Box>
                )
                : 'Select user signature'
            )
        }
      </Flex>
    )
  }  
};
