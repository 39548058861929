import React, { useRef, useState } from 'react';

import { Box, Divider, Flex, Image } from '@chakra-ui/react';
import FormTextInput from '../../forms/TextInputField/components/FormTextInput';
import WarningTextMessage from '../../global/components/helper-components/components/WarningTextMessage';
import PageLoader from '../../global/components/helper-components/components/PageLoader';
import LaunchdeckButton from '../../global/components/helper-components/components/LaunchdeckButton';
import { useHistory } from 'react-router-dom';


export default function SetupProfileGenesisForm(props) {

	const { userProfile, orgProfile, handleSetupGenesisProfile } = props || {};
	const [showValidationError, setShowValidationError] = useState(false)
	const [showLoader, setShowLoader] = useState(false);

	const [localUserProfileState, setUserProfileLocalState] = useState({
		firstName: userProfile.firstName,
		lastName: userProfile.lastName,
		userIdToken: userProfile.idToken
	})
	const [localUserOrgProfileState, setUserOrgProfileLocalState] = useState({
		orgRegisteredAddress: orgProfile.orgRegisteredAddress,
		orgRegisteredCountry: orgProfile.orgRegisteredCountry,
		orgName: orgProfile.orgName,
		orgLogo: orgProfile.orgLogo
	})


	const formChangedRef = useRef(false);
	const history = useHistory()


	const handleChange = (event) => {
		const name = event.target.name;
		const value = event.target.value;

		if (name === "firstName" || name === "lastName") {
			setUserProfileLocalState(localUserProfileState => ({
				...localUserProfileState,
				[event.target.name]: value,
				showValidationError: false
			}));

			setShowValidationError(false)
			formChangedRef.current = true
		} else if (name === "orgName" || name === "orgRegisteredCountry" || name === "orgRegisteredAddress") {
			
			setUserOrgProfileLocalState(localUserOrgProfileState => ({
				...localUserOrgProfileState,
				[event.target.name]: value,
				showValidationError: false
			}));

			formChangedRef.current = true
		}

	}

	const handleSaveGenesisProfile = () => {
		const { firstName, lastName } = localUserProfileState;
		if (firstName && lastName) {
			// Go to homepage if no changes
			if (!formChangedRef.current) {
				history.push(`/home`, {
					showGenesisOnboarding: true
				})
			} else {
				setShowLoader(true)
				handleSetupGenesisProfile({ useCase: 'setup-user-and-org-info', userProfileInput: localUserProfileState, userOrgProfileInput: localUserOrgProfileState })
			}

		} else {

			setShowValidationError(true)
		}
	}


	if (showLoader) {
		return (
			<PageLoader />
		)
	} else {
		const { firstName, lastName } = localUserProfileState || {};
		const { orgLogo, orgName, orgRegisteredAddress, orgRegisteredCountry } = localUserOrgProfileState || {};

		return (
			<React.Fragment>

<Box mt={['1em','1em','0.5em','0.5em']} display={['auto','auto','none','none']}>
<Divider />
</Box>
				<Flex w='100%' justify='space-between' align='center' my='0.5em'>
					<Box fontSize='1.5em' color='brandDark.500' textAlign='left'>About you</Box>
				</Flex>

				<Box mb='2em' w='100%'>
					<Flex w='100%' direction={['column','column','row','row']} justify='space-between' align='flex-start'>
						<Box flex='1' w='100%' mr={['0em','0em','1em','1em']}>
							<Box fontSize='1em' fontWeight='600' textAlign='left'>First Name</Box>
							<FormTextInput name={'firstName'} htmlFor={'firstName'} mb='0.2em' defaultValue={firstName} onChange={handleChange} />
						</Box>

						<Box flex='1' w='100%'  ml={['0em','0em','1em','1em']}>
							<Box fontSize='1em' fontWeight='600' textAlign='left'>Last Name</Box>
							<FormTextInput name={'lastName'} htmlFor={'lastName'} mb='0.2em' defaultValue={lastName} onChange={handleChange} />
						</Box>
					</Flex>
					<WarningTextMessage errorLogic={showValidationError} message='Please provide your names to proceed' />

				</Box>


				<Divider />
				<Flex w='100%' justify='space-between' align='center' mt={['1em','1em','2em','2em']} mb='0.5em'>
					<Box textAlign='left'>
						<Box fontSize='1.5em' color='brandDark.500'>About your team</Box>
					</Box>

				</Flex>

				<Flex my='1em' w='100%' direction={['column','column','row','row']} >
					{orgLogo && <Flex flex='3' direction='column' justify='center' align='center'>
						<Image height={'5em'} src={orgLogo} />
						<Box fontSize='0.8em' color='gray.500' px='1em' mt='3em' textAlign='center' display={['none','none','block','block']}>
							<Box>Prefer another logo? </Box>
							<Box>Change it in your account settings at any time</Box>
						</Box>
					</Flex>}

					<Box flex='7' ml={['0em','0em','1em','1em']}>
						<Box>
							<Box fontSize='1em' fontWeight='600' textAlign='left'>Company Name</Box>
							<FormTextInput name={'orgName'} htmlFor={'orgName'} mb='0.2em' defaultValue={orgName} onChange={handleChange} />
						</Box>

						<Box mt='1em'>
							<Box fontSize='1em' fontWeight='600' textAlign='left'>Country registered in</Box>
							<FormTextInput name={'orgRegisteredCountry'} htmlFor={'orgRegisteredCountry'} mb='0.2em' defaultValue={orgRegisteredCountry} onChange={handleChange} />
						</Box>

						<Box mt='1em'>
							<Box fontSize='1em' fontWeight='600' textAlign='left'>Registered address</Box>
							<FormTextInput name={'orgRegisteredAddress'} htmlFor={'orgRegisteredAddress'} mb='0.2em' defaultValue={orgRegisteredAddress} onChange={handleChange} />
						</Box>
					</Box>
				</Flex>

				<Flex justify='center' alignItems='center' mt='3em'>
					<Flex justify={['center', 'center', 'center', 'center']} w='100%'>
						<LaunchdeckButton bg='brand.500' color='white' px={['2em','2em','8em','10em']} w={['100%','100%','auto','auto']}fontSize='1em' onClick={() => handleSaveGenesisProfile()} label='Looks good' />
					</Flex>
				</Flex>
			</React.Fragment>

		)
	}
};



