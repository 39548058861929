import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import { Flex, Modal, ModalBody, ModalContent, ModalOverlay, Text } from "@chakra-ui/react";
import { unsetJwtExpired } from "../actions/auth-actions";
import LaunchdeckButton from '../../global/components/helper-components/components/LaunchdeckButton';

const JwtExpiredModal = ({ jwtExpired, unsetJwtExpiredState }) => {
    const tokenExpiredMessage = 'Your session token has expired. You will be logged out.'

    const handleClick = async () => {
        unsetJwtExpiredState()
    }

    return (
        <Modal isOpen={!!jwtExpired} size='5xl' closeOnOverlayClick={false} isCentered>
            <ModalOverlay />
            <ModalContent rounded='0.25em' minW={['90%', '500px']} maxW='700px'>
                <ModalBody>
                    <Flex direction='column' justify='space-around' alignItems='center' w='100%' h="200px">
                        <Text fontSize="0.875em" >{tokenExpiredMessage}</Text>
                        <Flex w='100%' justify='center'>
                            <LaunchdeckButton label='OK' fontSize="0.875em" bg='brand.500' color='gray.50' onClick={handleClick} />
                        </Flex>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

function mapStateToProps(state) {
    return {
        jwtExpired: state.auth.data.jwt.isExpired,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        unsetJwtExpiredState: () => dispatch(unsetJwtExpired())
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(JwtExpiredModal));