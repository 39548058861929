import React from 'react';
import {
  Box, Icon, Flex, Text, Image, Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverCloseButton
} from '@chakra-ui/react';
import EmptyStateImage from './styles/images/vault-empty-state.svg';
import EmptyRoomIcon from '../vault/styles/images/rooms.png';
import EmptyProspectorsIcon from '../vault/styles/images/prospector.png'
import LaunchdeckButton from '../global/components/helper-components/components/LaunchdeckButton';
import { MdOutlineTipsAndUpdates } from "react-icons/md";

export default function VaultEmptyState({ 
  displayType, 
  handleImageLoaded, 
  iconImage,
  imageSize,
  imageClassName, 
  copyText,
  onClickCreateDsr,
  isMobileView,
  searchTerms,
  chosenFilterValue,
  chosenStatusTab,
  handleClearRoomsSearchResults
 }) {

  const renderDisplayTag = (label, content) => {
    return (
      <Flex fontSize='0.8em' align='center' justify='center' border='1px solid' borderColor='brandPink.500' rounded='5em'
      p='0.5em 1em' mx='0.5em'>
        <Box color='gray.500'>{label}:</Box>
        <Box ml='0.5em' color='brandDark.500' fontWeight='600'>{content}</Box>
      </Flex>
    )
  }

  if (!displayType) {
    return (
      <Flex w='100%' justify='center' align='center'>
        <Flex direction='column' justify='center' alignItems='center' m='auto' py='3em'>
          <Image src={iconImage ? iconImage : EmptyStateImage} className={imageClassName} h={imageSize && imageSize} onLoad={handleImageLoaded} alt="No Rooms Yet" />
          <Text mt='2.5em' fontWeight='500' lineHeight='1.375em' textAlign='center'>{copyText ? copyText : `Not participating as a Guest in any Rooms ... yet!`} </Text>
        </Flex>
      </Flex>
    )
  } else {
  
    switch (displayType) {
      case ("no-published-rooms-vault"): {
        return (
          <Box>
            <Flex w='100%' justify='center' align='center'>
              <Flex direction='column' justify='center' alignItems='center' m='auto' py='3em'>
                <Image src={EmptyRoomIcon} className={imageClassName} h='8em' onLoad={handleImageLoaded} alt="No Rooms Yet" />
                <Flex mt='1em' w='100%' justify='center' align='center' direction={isMobileView ? 'column':'row'}>
                  <Text mr='1em' fontWeight='500' lineHeight='1.375em' textAlign='center'>No published Rooms yet </Text>
                  <Popover placement={isMobileView ? 'top' : 'right'}>
                    <PopoverTrigger>
                      <Icon cursor='pointer' mt={isMobileView && '0.5em'}/>
                    </PopoverTrigger>
                    <PopoverContent w={isMobileView ? '20em' : '25em'} color='brandDark.500' bg='white' borderColor='brand.500'>

                      <PopoverHeader pt={4} fontWeight='bold' border='0'>
                        What is a 'Room'?
                      </PopoverHeader>
                      <PopoverCloseButton top='1em' right='1em' fontSize='0.75em' />

                      <PopoverBody fontSize='0.875em' my='0.875em'>
                        <Box>
                          A private space where you can customise its content to engage with specific people in mind.
                        </Box>
                        <Box mt='0.875em'>
                          Only you, your team, and audiences have access to the Room, its content, and the conversations within.
                        </Box>

                      </PopoverBody>
                      <PopoverFooter
                        border='0'
                        display='flex'
                        alignItems='center'
                        justifyContent='space-between'
                        pb={4}
                      >
                   
                   <Flex pl='0.5em' fontSize='0.7em' fontWeight='600' color='brandPink.500' gap={2} justify='center' align='center'>
                    <MdOutlineTipsAndUpdates />
                    Launchdeck Tips</Flex>

                      </PopoverFooter>
                    </PopoverContent>
                  </Popover>
                </Flex>
                <Box mt='2em' display={['none','none','block','block']}>
                  <LaunchdeckButton bg='brand.500' color='white' onClick={onClickCreateDsr} label='Create your first Room' />
                </Box>


              </Flex>
            </Flex>
          </Box>
        )
      }
      case ("no-published-prospectors-vault"): {
        return (
          <Box>
            <Flex w='100%' justify='center' align='center'>
              <Flex direction='column' justify='center' alignItems='center' m='auto' py='3em'>
                <Image src={EmptyProspectorsIcon} className={imageClassName} h='5em' onLoad={handleImageLoaded} alt="No Rooms Yet" />
                <Flex mt='3em' w='100%' justify='center' align='center' direction={isMobileView ? 'column':'row'}>
                  <Text mr='1em' fontWeight='500' lineHeight='1.375em' textAlign='center'>No published Prospectors yet </Text>
                  <Popover placement={isMobileView ? 'top' : 'right'}>
                    <PopoverTrigger>
                      <Icon cursor='pointer' mt={isMobileView && '0.5em'}/>
                    </PopoverTrigger>
                    <PopoverContent w={isMobileView ? '20em' : '25em'} color='brandDark.500' bg='white' borderColor='brand.500'>

                      <PopoverHeader pt={4} fontWeight='bold' border='0'>
                        What's a 'Prospector'?
                      </PopoverHeader>
                      <PopoverCloseButton top='1em' right='1em' fontSize='0.75em' />

                      <PopoverBody fontSize='0.875em' my='0.875em'>
                        <Box>
                          Prospectors are public digital spaces where anyone can come in and access your content, and engage with you & your team.
                        </Box>
                        <Box mt='0.875em'>
                          Use Prospectors to get the word out, drum up interest, and let qualified leads reach out when they are ready! 
                        </Box>

                      </PopoverBody>
                      <PopoverFooter
                        border='0'
                        display='flex'
                        alignItems='center'
                        justifyContent='space-between'
                        pb={4}
                      >
                   
                        
                   <Flex pl='0.5em' fontSize='0.7em' fontWeight='600' color='brandPink.500' gap={2} justify='center' align='center'>
                    <MdOutlineTipsAndUpdates />
                    Launchdeck Tips</Flex>
                      </PopoverFooter>
                    </PopoverContent>
                  </Popover>
                </Flex>
                <Box mt='2em' display={['none','none','block','block']}>
                  <LaunchdeckButton bg='brand.500' color='white' onClick={onClickCreateDsr} label='Create your first Prospector' />
                </Box>
              </Flex>
            </Flex>
          </Box>
        )
      }
      case ("no-search-results"): {
        return (
          <Flex w='100%' justify='center' align='center'>
            <Flex direction='column' justify='center' alignItems='center' m='auto' py='3em'>
              <Image src={EmptyStateImage} className={imageClassName} onLoad={handleImageLoaded} alt="No Rooms Yet" />
              <Text mt='2.5em' fontWeight='500' lineHeight='1.375em' textAlign='center'>{copyText ? copyText : `Nothing found`} </Text>
              <Flex mt='1em'>
               {renderDisplayTag("Search terms", searchTerms)}
               {renderDisplayTag("Status", chosenStatusTab === "drafts" ? "Drafts only" : "All active")}
               {renderDisplayTag("Owned by", (chosenFilterValue ==="Owned by me" || chosenFilterValue === "Private") ? "Me only" : "All team members")}
              </Flex>
              <Box mt='1.5em'>
              <LaunchdeckButton bg='brandDark.500' color='white' onClick={()=>handleClearRoomsSearchResults()} label='Clear search' />
      
              </Box>
             
           </Flex>
          </Flex>
        )
      }
      default: {
        return null;
      }
    }
  }

}