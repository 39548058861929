import * as types from '../actions/marketing-actions-types';

const INITIAL_STATE_JOIN_WAITLIST = {
	isLoading: false,
	results: {},
	error: '',
};

export function joinWaitlistReducer(state = INITIAL_STATE_JOIN_WAITLIST, action) {
	switch (action.type) {
		case types.CREATE_JOIN_WAITLIST_REQUEST:
			return { ...state, isLoading: true, results: {} };
		case types.CREATE_JOIN_WAITLIST_SUCCESS:
			return { ...state, isLoading: false, results: action.payload };
		case types.CREATE_JOIN_WAITLIST_FAILURE:
			return { ...state, isLoading: false, error: 'Error while trying to create waitlist entry' };
		default:
			return state;
	}
}

// const INITIAL_STATE = {
// 	isLoading: false,
// 	results: {},
// 	error: '',
// };

// export function getExclusiveInviteReducer(state = INITIAL_STATE, action) {
// 	switch (action.type) {
// 		case types.GET_EXCLUSIVE_INVITE_REQUEST:
// 			return { ...state, isLoading: true, results: {} };
// 		case types.GET_EXCLUSIVE_INVITE_SUCCESS:
// 			return { ...state, isLoading: false, results: action.payload };
// 		case types.GET_EXCLUSIVE_INVITE_FAILURE:
// 			return { ...state, isLoading: false, error: 'Error while trying to fetch exclusive invite' };
// 		default:
// 			return state;
// 	}
// }


// const ALL_EXCLUSIVE_INVITES_INITIAL_STATE = {
// 	isLoading: false,
// 	results: {},
// 	error: '',
// };

// export function getAllExclusiveInvitesReducer(state = ALL_EXCLUSIVE_INVITES_INITIAL_STATE, action) {
// 	switch (action.type) {
// 		case types.GET_ALL_EXCLUSIVE_INVITES_REQUEST:
// 			return { ...state, isLoading: true, results: {} };
// 		case types.GET_ALL_EXCLUSIVE_INVITES_SUCCESS:
// 			return { ...state, isLoading: false, results: action.payload };
// 		case types.GET_ALL_EXCLUSIVE_INVITES_FAILURE:
// 			return { ...state, isLoading: false, error: 'Error while trying to fetch all exclusive invites by user' };
// 		default:
// 			return state;
// 	}
// }

// const INITIAL_STATE_PARTNER_BENEFITS_INVITE = {
// 	isLoading: false,
// 	results: {},
// 	error: '',
// };

// export function getPartnerBenefitsInviteReducer(state = INITIAL_STATE_PARTNER_BENEFITS_INVITE, action) {
// 	switch (action.type) {
// 		case types.GET_PARTNER_BENEFITS_INVITE_REQUEST:
// 			return { ...state, isLoading: true, results: {} };
// 		case types.GET_PARTNER_BENEFITS_INVITE_SUCCESS:
// 			return { ...state, isLoading: false, results: action.payload };
// 		case types.GET_PARTNER_BENEFITS_INVITE_FAILURE:
// 			return { ...state, isLoading: false, error: 'Error while trying to fetch partner benefits invite' };
// 		default:
// 			return state;
// 	}
// }

// const EXCLUSIVE_INVITE_NEW_SIGNUP_INITIAL_STATE = {
// 	isLoading: false,
// 	results: {},
// 	error: '',
// };

// export function signupAsExclusiveInvitedUserReducer(state = EXCLUSIVE_INVITE_NEW_SIGNUP_INITIAL_STATE, action) {
// 	switch (action.type) {
// 		case types.SIGNUP_AS_EXCLUSIVE_INVITE_USER_REQUEST:
// 			return { ...state, isLoading: true, results: {} };
// 		case types.SIGNUP_AS_EXCLUSIVE_INVITE_USER_SUCCESS:
// 			return { ...state, isLoading: false, results: action.payload };
// 		case types.SIGNUP_AS_EXCLUSIVE_INVITE_USER_FAILURE:
// 			return { ...state, isLoading: false, results: action.payload, error: 'Error while trying to get feedback from exclusive invite new signup' };
// 		default:
// 			return state;
// 	}
// }


// const REFERRAL_CODE_CHECK_INITIAL_STATE = {
// 	isLoading: false,
// 	results: {},
// 	error: '',
// };

// export function getReferralCodeCheckReducer(state = REFERRAL_CODE_CHECK_INITIAL_STATE, action) {
// 	switch (action.type) {
// 		case types.GET_REFERRAL_CODE_VALIDITY_REQUEST:
// 			return { ...state, isLoading: true, results: {} };
// 		case types.GET_REFERRAL_CODE_VALIDITY_SUCCESS:
// 			return { ...state, isLoading: false, results: action.payload };
// 		case types.GET_REFERRAL_CODE_VALIDITY_FAILURE:
// 			return { ...state, isLoading: false, results: action.payload, error: 'Error while trying to get feedback from exclusive invite new signup' };
// 		default:
// 			return state;
// 	}
// }
