import React, { useEffect, useRef, useState } from "react";
import { Alert, AlertIcon, Box, Button, Flex } from "@chakra-ui/react";
import { IoDocumentText } from "react-icons/io5";
import { openInNewTab } from "../../../../global/helpers/global-helpers";
import LaunchdeckFormInput from "../../../../forms/TextInputField/components/LaunchdeckFormInput";

export const EmbedDocument = ({
  subWidgetId,
  editableItemId,
  setSubWidgetContent,
  subWidgetContent,
  isDragging,
  handleEditItemByClick,
  handleSaveItemByClickOutside
}) => {
  const [googleDocButtonText, setGoogleDocButtonText] = useState(subWidgetContent ? subWidgetContent.split('/?&/')[0] : "Google document");
  const [googleDocButtonLink, setGoogleDocButtonLink] = useState(subWidgetContent ? subWidgetContent.split('/?&/')[1] : "");
  const [isError, setIsError] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    const newCtaContent = `${googleDocButtonText}/?&/${googleDocButtonLink}`;

    if (newCtaContent !== subWidgetContent) {
      setSubWidgetContent(newCtaContent)
    }

    linkValidation(googleDocButtonLink)
  }, [googleDocButtonText, googleDocButtonLink]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if ((isFocused && containerRef.current) && !containerRef?.current.contains(event.target)) {
        setIsFocused(false);
        handleSaveItemByClickOutside();
      }
    };

    if (isFocused) {
      setTimeout(() => window.addEventListener('click', handleClickOutside), 1)
    } else {
      window.removeEventListener('click', handleClickOutside);
    }

    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, [isFocused]);

  const handleEnterToEditMode = () => {
    if (!isDragging) {
      handleEditItemByClick()
      setIsFocused(true)
    }
  }

  const handleClickToButton = () => {
    if (isError || googleDocButtonLink === '') {
      handleEnterToEditMode()
    } else {
      openInNewTab(googleDocButtonLink)
    }
  }

  const linkValidation = (link) => {
    const googleDocumentRegex = new RegExp('https://docs\\.google\\.com/document/d/[a-zA-Z0-9_-]+');
    const googlePresentationRegex = new RegExp('https://docs\\.google\\.com/presentation/d/[a-zA-Z0-9_-]+');
    const googleSpreadsheetsRegex = new RegExp('https://docs\\.google\\.com/spreadsheets/d/[a-zA-Z0-9_-]+');
    const googleDriveFileRegex = new RegExp('https://drive\\.google\\.com/file/d/[a-zA-Z0-9_-]+');

    const isDocument = googleDocumentRegex.test(link);
    const isPresentation = googlePresentationRegex.test(link);
    const isSpreadsheets = googleSpreadsheetsRegex.test(link);
    const isGenericDriveFile = googleDriveFileRegex.test(link);

    const isValidLink = isDocument || isPresentation || isSpreadsheets || isGenericDriveFile


    if (!isValidLink && link) {
      setIsError(true)
    } else {
      setIsError(false)
    }
  };

  return (
    editableItemId === subWidgetId
      ? <Box ref={containerRef} width="100%" py='1em' minH='14em'>

        <Box mb='3em'>
          <LaunchdeckFormInput
            onChange={(e) => setGoogleDocButtonText(e.target.value)}
            value={googleDocButtonText}
            label="Button text"
          />
        </Box>

        <LaunchdeckFormInput
          onChange={(e) => setGoogleDocButtonLink(e.target.value)}
          // placeholder="Example: www.google.com"
          value={googleDocButtonLink}
          label="Google document link"
          errorLogic={isError}
          errorMessage={'Invalid google document link'}
        />



      </Box>
      : <Box
        display='grid'
        gridTemplateColumns={'1fr max-content 1fr'}
        justifyContent='center'
        alignItems='center'
        width='100%'
        height='100%'
        padding="10px"
      >
        <Box
          width='100%'
          height='100%'
          onClick={handleEnterToEditMode}
        ></Box>

        <Flex flexDirection='column' justifyContent='center' alignItems='center'>
          <Button px='8em' rounded='0.85em' bg="brandDark.500" color='white' fontSize="0.875em" height="50px"
            _hover={{ color: "brandDark.500", bg: "gray.200" }}
            onClick={handleClickToButton}>
            {googleDocButtonText}
            <Box as={IoDocumentText}
              width="25px" height="25px" pl="5px"
              _hover={{ fill: 'brandDark.500' }}
            />
          </Button>

          {isError &&
            <Box mt='10px' fontSize='0.875em'>
              <Alert status="warning" bg='white' border='1px solid' borderColor='brandPink.500' rounded="0.5em" px='2em'>
                <AlertIcon color='brandPink.500'/>
                Invalid google document link
              </Alert>
            </Box>
          }
        </Flex>

        <Box
          width='100%'
          height='100%'
          onClick={handleEnterToEditMode}
        ></Box>
      </Box>
  )
};

// export const EmbedDocument = ({ subWidgetId, editableItemId, setSubWidgetContent, subWidgetContent }) => {
//
//   const placeholder = <Flex flexDirection="column">
//     <Flex justifyContent="center" alignItems="center" pb="10px">
//       <Box as={IoDocumentText} fill="#36a8f3" width="65px" height="65px" pr="10px"/>
//       <Box as={BsFileEarmarkSlidesFill} fill="#ffb959" width="60px" height="60px" pr="10px" />
//       <Box as={SiGooglesheets} fill="#29a75f" width="50px" height="50px"/>
//     </Flex>
//     <Box>
//       Embed google document. Add document <Link target="_blank" color="#0000ff" href="https://support.google.com/docs/answer/183965?hl=en&co=GENIE.Platform%3DDesktop">URL</Link>
//     </Box>
//   </Flex>;
//
//   const switchIframeBasedOnSource = () => {
//     //Presentation
//     const isPresentation = subWidgetContent.includes("/presentation/d/e");
//     //Document
//     const isDocument = subWidgetContent.includes('/document/d/e/');
//     //Spreadsheets
//     const isSpreadsheets = subWidgetContent.includes('/spreadsheets/d/e/');
//
//     if (isPresentation) {
//       return (
//         <Flex w='100%' height='100%' direction='column' overflow='hidden' pt="15px" pb="15px">
//           <iframe
//             className="loader-img"
//             loading="lazy"
//             title="Embed presentation"
//             src={subWidgetContent.includes("/pub?") ? subWidgetContent.replace("/pub?", "/embed?") : subWidgetContent}
//             frameBorder="0"
//             width="960"
//             height="569"
//             allowFullScreen={true}
//             mozallowfullscreen="true"
//             webkitallowfullscreen="true"
//           >
//             Text
//           </iframe>
//         </Flex>
//       );
//     };
//
//     if (isDocument || isSpreadsheets) {
//       return (
//         <Flex w='100%' height='100%' direction='column' overflow='hidden' pt="15px" pb="15px">
//           <iframe className="iframe-loader" title="Embed document" src={subWidgetContent} frameBorder="0" height="1000"></iframe>
//         </Flex>
//
//       )
//     };
//
//     return (
//       <Box>
//         Embed document have incorrect URL <Link target="_blank" color="#0000ff" href="https://support.google.com/docs/answer/183965?hl=en&co=GENIE.Platform%3DDesktop">here</Link> you can see hove to take correct link
//       </Box>
//     )
//
//   };
//
//   return (
//     editableItemId === subWidgetId
//       ? <Box width="100%">
//         <Textarea
//           autoFocus
//           value={subWidgetContent}
//           onChange={(e) => setSubWidgetContent(e.target.value)}
//           resize="none"
//           boxSize="lg"
//         />
//       </Box>
//       : !subWidgetContent
//         ? placeholder
//         : switchIframeBasedOnSource()
//   );
//
// };