import { SET_ROOM_MAIN_CONTACT_INFO } from '../../actions/dsr-actions-types'

const INITIAL_STATE_MAIN_CONTACT_INFO = { mainContactInfo: '' }

export function dsrMainContactReducer(state = INITIAL_STATE_MAIN_CONTACT_INFO, action) {
    switch (action.type) {
        case SET_ROOM_MAIN_CONTACT_INFO:
            return { mainContactInfo: action.payload };
        default:
            return state;
    }
}