// 'thin', 'medium', 'bold'
const STRENGTH = 'strength';

// 'left', 'center', 'right'
const ALIGN = 'align';

const HEIGHT = 'height';

// 'primary', 'secondary'
const VARIANT = 'variant';

export const optionTypes = {
  STRENGTH,
  ALIGN,
  HEIGHT,
  VARIANT
}