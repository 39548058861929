import * as types from './user-notifications-action-types';
import { axiosInstance } from '../../../config/app-specific-config/axiosConfig';
import auth from '../../../config/app-specific-config/auth';


// USER PRIVILEGES
export function setUserNotifications(p) {
	return {
		type: types.SET_USER_NOTIFICATIONS,
		payload: p
	}
}

export function setPaginatedUserNotifications(p) {
	return {
		type: types.SET_PAGINATED_USER_NOTIFICATIONS,
		payload: p
	}
}

// GET USER NOTIFICATIONS
export const getUserNotificationsRequest = () => {
	return {
		type: types.GET_USER_NOTIFICATIONS_REQUEST
	};
};

export const getUserNotificationsSuccess = (response) => {
	return {
		type: types.GET_USER_NOTIFICATIONS_SUCCESS,
		payload: response.data
	};
};

export const getUserNotificationsFailure = (error) => {
	return {
		type: types.GET_USER_NOTIFICATIONS_FAILURE,
		error
	};
};

export function getUserNotifications(getNotificationsMetadata) {
	
	return async (dispatch) => {
		dispatch(getUserNotificationsRequest());
		try {
			const url = `/users/get-notifications`;
			const headers = await auth.getAccessToken();
			const response = await axiosInstance.post(
				url,
				{ getNotificationsMetadata },
				{ headers }
			);

			if (getNotificationsMetadata.paginationRequirements) {
				dispatch(setPaginatedUserNotifications(response.data.data))
			} else {
				dispatch(setUserNotifications(response.data.data))
			}
		
			dispatch(getUserNotificationsSuccess(response));

		} catch (error) {
			dispatch(getUserNotificationsFailure(error));
		}
	};
}

// MARK USER NOTIFICATION AS VIEWED
export const markUserNotificationAsViewedRequest = () => {
	return {
		type: types.MARK_USER_NOTIFICATION_AS_VIEWED_REQUEST
	};
};

export const markUserNotificationAsViewedSuccess = (response) => {
	return {
		type: types.MARK_USER_NOTIFICATION_AS_VIEWED_SUCCESS,
		payload: response.data
	};
};

export const markUserNotificationAsViewedFailure = (error) => {
	return {
		type: types.MARK_USER_NOTIFICATION_AS_VIEWED_FAILURE,
		error
	};
};

export function markUserNotificationAsViewed(notificationsToMarkAsViewed) {
	return async (dispatch) => {
		dispatch(markUserNotificationAsViewedRequest());
		try {
			const url = `/users/viewed-notification`;

			const headers = await auth.getAccessToken();
			const response = await axiosInstance.post(
				url,
				{ notificationsToMarkAsViewed },
				{ headers }
			);

			dispatch(setUserNotifications(response.data.data))
			dispatch(markUserNotificationAsViewedSuccess(response));

		} catch (error) {
			dispatch(markUserNotificationAsViewedFailure(error));
		}
	};
}

// MARK ALL USER NOTIFICATIONS AS VIEWED
export const markAllUserNotificationsAsViewedRequest = () => {
	return {
		type: types.MARK_ALL_USER_NOTIFICATIONS_AS_VIEWED_REQUEST
	};
};

export const markAllUserNotificationsAsViewedSuccess = (response) => {
	return {
		type: types.MARK_ALL_USER_NOTIFICATIONS_AS_VIEWED_SUCCESS,
		payload: response.data
	};
};

export const markAllUserNotificationsAsViewedFailure = (error) => {
	return {
		type: types.MARK_ALL_USER_NOTIFICATIONS_AS_VIEWED_FAILURE,
		error
	};
};

export function markAllUserNotificationsAsViewed() {
	return async (dispatch) => {
		dispatch(markAllUserNotificationsAsViewedRequest());
		try {
			const url = `/users/viewed-all-notifications`; 

			const headers = await auth.getAccessToken();
			const response = await axiosInstance.post(
				url,
				{  },
				{ headers }
			);
			
			dispatch(setUserNotifications(response.data.data))
			dispatch(markAllUserNotificationsAsViewedSuccess(response));

		} catch (error) {
			dispatch(markAllUserNotificationsAsViewedFailure(error));
		}
	};
}


// export const getUserNotificationsReset = () => {
// 	return {
// 		type: types.SELF_SETUP_NEW_ORG_USER_RESET
// 	};
// };

// export function resetUserSelfSetup() {

// 	return async (dispatch) => {
	
// 		try {
// 			dispatch(getUserNotificationsReset());

// 		} catch (error) {
// 			dispatch(getUserNotificationsFailure(error));
// 		}
// 	};
// }
