import { SET_DSR_USER_TEAM } from '../../actions/dsr-actions-types'

const INITIAL_STATE_SET_DSR_USER_TEAM = { userTeam: '' }

export function setDsrUserTeamReducer(state = INITIAL_STATE_SET_DSR_USER_TEAM, action) {
    switch (action.type) {
        case SET_DSR_USER_TEAM:
            return { userTeam: action.payload };
        default:
            return state;
    }
}