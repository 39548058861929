import { SET_DSR_ACTION_LIST_OVERVIEW } from '../../actions/dsr-actions-types'

const INITIAL_STATE_SET_DSR_MAP_OVERVIEW = { results: '' }

export function setDsrActionListOverviewReducer(state = INITIAL_STATE_SET_DSR_MAP_OVERVIEW, action) {
    switch (action.type) {
        case SET_DSR_ACTION_LIST_OVERVIEW:
            return { results: action.payload }
        default:
            return state;
    }
}