import { SET_DSR_DRAFT_USER_ACTION } from '../../actions/dsr-actions-types'

const INITIAL_STATE_DSR_DRAFT_USER_ACTION = { action: '' }

export function userActionRequiredReducer(state = INITIAL_STATE_DSR_DRAFT_USER_ACTION, action) {
    switch (action.type) {
        case SET_DSR_DRAFT_USER_ACTION:
            return { action: action.payload };
        default:
            return state;
    }
}