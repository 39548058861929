import React, { useEffect, useState, useRef } from 'react'
import { useParams } from 'react-router-dom'

import {Box, ChakraBaseProvider, Flex} from '@chakra-ui/react'
import claverTheme from '../../layout/components/layout/components/theme/claverTheme'

// Components
import DsrProspectHeader from './DsrProspectHeader'
import DsrGenericErrorModal from '../components//DsrGenericErrorModal'
import { redirectToNewPage } from '../../global/helpers/global-helpers'
import RenderProspectDsrContent from './RenderProspectDsrContent'
import { checkIsLoggedInUser } from '../../auth/helpers/auth-checker'
import DsrDynamicModal from '../messages/DsrDynamicModal'
// import {tr} from "date-fns/locale";


export default function DsrProspectDisplayPage({
  getProspectDsr,
  dsrInvalidRequestRedux,
  dsrPagesRedux,
  dsrTitleRedux,
  dsrStatusRedux,
  changeDsrPopupState,
  changeDsrModalState,
  showDsrModalRedux,
  showDsrModalLoaderRedux,
  userActionRequiredRedux,
  dsrMainContact,
  signout,
  location
}) {

  const [localState, setLocalState] = useState({
    isDraft: "",
    renderContentType: '',
    currentDisplaySubpage: "",
    sectionIndex: 0,
    // DSR Metadata
    pDsrId: "",
    dsrCreatedAt: "",
    dsrPages: "",
    // DSR Team Members
    userProfile: "",
    // DSR Modal Management
    showDsrModal: false,
    showDsrModalLoader: false,
    userActionRequired: "",
    isLoggedInUser: ""
  })


  const { pDsrId } = useParams()
  const initRef = useRef(false);

  const { isLoggedInUser } = localState;

  const isMobileView = window.screen.width < 770
  const headerMenuHeight = isLoggedInUser ? isMobileView ? '8em' : '140px' : isMobileView ? '8em' : '70px'

  useEffect(() => {
    if (!initRef.current && pDsrId) {
      document.title = 'Launchdeck Digital Sales Room'
      document.description = 'Create customised private digital sales rooms that let Sales & Purchasing teams agree on deals quickly'

       // TODO Refactor: Process this in redux
       // Note: This logic only works as there is no team -> Only owner of PDSR 
      const isLoggedInUser = checkIsLoggedInUser();

      getProspectDsr({ pDsrId, status: 'active' })

      
      setLocalState((prevState) => ({
        ...prevState,
        isLoggedInUser
      }))
      initRef.current = true
    }
  }, [pDsrId, getProspectDsr])

  useEffect(() => {
    if (((!pDsrId) || (dsrInvalidRequestRedux === true)) && initRef.current) {
      redirectToNewPage('/invalid') // TODO use react router
    }
  }, [pDsrId, dsrInvalidRequestRedux])

  useEffect(() => {
    if (signout) {
      if (signout?.results?.sign_out) {
        const excluded = ["/", "/home"]
        const redirectUrl = `${location.pathname}${location.search}`
        const isManualLogout = location.hash === '#logout'

        if (excluded.includes(redirectUrl) || isManualLogout) {
          redirectToNewPage('/login')
        } else {
          redirectToNewPage(`/login?redirect=${redirectUrl}`)
        }
      }
    }
  }, [signout])

 

  const handleDsrModalClose = () => {
    changeDsrPopupState('')
    changeDsrModalState(false)
  }



  return (
    <ChakraBaseProvider theme={claverTheme}>
      {/* <CSSReset /> */}
      <Flex direction="column">
        <Flex direction="row" flexGrow="1">

          <Flex w="100%" direction="column" alignItems="center" bg="white">
      
            <React.Fragment>
              <Box width='100%' position='fixed' zIndex='2'>
                <DsrProspectHeader
                  pDsrId={pDsrId}
                  dsrTitle={dsrTitleRedux}
                  dsrMainContact={dsrMainContact}
                  pdsrPagesLocalState={dsrPagesRedux}
                  dsrStatus={dsrStatusRedux}
                  isLoggedInUser={isLoggedInUser}
                />
              </Box>

              <Flex w="100%" className="authentication-container" minHeight="100vh" justify="center" pt={headerMenuHeight} bg='#F4F4F4'>
           
                <RenderProspectDsrContent 
                  pagesLocalState={dsrPagesRedux} 
                  headerMenuHeight={headerMenuHeight}
                  isMobileView={isMobileView}
                />

                <DsrDynamicModal
                  pDsrId={pDsrId}
                  userActionRequired={userActionRequiredRedux}
                  showDsrModal={showDsrModalRedux}
                  showDsrModalLoader={showDsrModalLoaderRedux}
                  handleDsrModalClose={handleDsrModalClose}
                />

                <DsrGenericErrorModal 
                  dsrId={pDsrId} 
                  isProspectDsr={true}
                  handleCloseOtherModals={handleDsrModalClose} 
                />

              </Flex>


            </React.Fragment>
          </Flex>
        </Flex>
      </Flex>
    </ChakraBaseProvider>
  )
}
