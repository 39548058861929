import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'

import { Box, Flex } from '@chakra-ui/react'
import PendingFaqDisplay from './PendingFaqDisplay'
import AnsweredFaqDisplay from './AnsweredFaqDisplay'
import DsrDynamicModal from '../../messages/DsrDynamicModal'
import { MdOutlineQuestionAnswer } from 'react-icons/md'
import { IoMdAddCircle } from 'react-icons/io';

import { useHistory } from 'react-router-dom'
import {
  createNewDsrFeedPost,
  replaceDsrFeedContent,
  resetCreateNewDsrFeedPost,
  setDsrPages,
  createFaqQuestion,
  resetCreateFaqQuestion,
  resetCreateFaqAnswer,
  resetEditFaqAnswer
} from '../../actions/dsr-actions'
import LaunchdeckButton from '../../../global/components/helper-components/components/LaunchdeckButton'
import { getThisUserIdToken } from '../../../global/helpers/global-helpers'
import { getUserDetailsUsingDsrMembersArray } from '../../../profile/helpers/user-helper'
import { changeDsrModalState, changeDsrPopupState } from '../../../digital-sales-room/actions/dsr-actions'

function DsrFaqDisplay(props) {

  const {
    // Parent component
    handleOpenSignupOrLoginModal,
    // Redux states
    createFaqAnswerOutcome, editFaqAnswerOutcome, deleteFaqAnswerOutcome,
    createFaqQuestionOutcome, editFaqQuestionOutcome, deleteFaqQuestionOutcome,
    createNewDsrFeedPostOutcome, dsrFeedContent, dsrPagesRedux,
    dsrStatusRedux, 
    dsrTeamMembersRedux,
    isPublicDsrEnvironment, isPagePreviewMode, contentBlock,
    // dsrFaqArrayRedux, 
  } = props || {};

  const history = useHistory();
  const dsrStatus = dsrStatusRedux;
  const dsrTeamMembers = dsrTeamMembersRedux;

  const dsrId = props ? props.match.params.dsrId : "";

  const isDraftMode = (dsrStatus === 'draft') ? true : false;

  const [faqArray, setFaqArray] = useState(contentBlock.faqArray)
  const [showQuestionInput, setShowQuestionInput] = useState(false)

  const thisUserId = getThisUserIdToken();
  const isDsrMember = getUserDetailsUsingDsrMembersArray(dsrTeamMembersRedux, thisUserId)


  function handleCloseFirstQuestionModal() {
    setShowQuestionInput(false)
  }

  function handleCreateFirstFaqQuestion({ inputValue }) {

    const newDsrFeedPostMetadata = {
      dsrId: dsrId,
      postType: "faq-question-post",
      postContent: inputValue,
    };
    props.action.createNewDsrFeedPost(newDsrFeedPostMetadata);
  }

  function handleCreateFaqQuestionInDraftMode(inputValue) {
    const createFaqQuestionWithoutPostMetadata = {
      dsrId: dsrId,
      faqQuestionContent: inputValue,
    };

    props.action.createFaqQuestion(createFaqQuestionWithoutPostMetadata)

  }


  useEffect(() => {
    if (createNewDsrFeedPostOutcome) {
      const { updatedFaqQuestionArray, newPostCreated } = createNewDsrFeedPostOutcome || {};
      const newQuestion = updatedFaqQuestionArray.sort((a, b) => new Date(b.questionCreatedAt) - new Date(a.questionCreatedAt))[0];

      // Shows Question in FAQ subpage
      if (updatedFaqQuestionArray && Array.isArray(updatedFaqQuestionArray)) {
        setFaqArray(updatedFaqQuestionArray)
      }

      if (dsrFeedContent && dsrPagesRedux) {
        props.action.replaceDsrFeedContent([...dsrFeedContent, newPostCreated])

        const updatedPages = dsrPagesRedux.map((page, index) => {
          if (page.pageType === 'faq') {
            const updatedPageContent = { ...page.pageContent[0], faqArray: [...page.pageContent[0].faqArray, newQuestion] }

            return { ...page, pageContent: [updatedPageContent] }
          }

          return page
        })

        //Shows post in feed content subpage
        props.action.setDsrPages(updatedPages)
        setShowQuestionInput(false)
      }
    }

    props.action.resetCreateNewDsrFeedPost()
  }, [createNewDsrFeedPostOutcome, dsrFeedContent, dsrPagesRedux, props.action]);

  useEffect(() => {
    if (createFaqAnswerOutcome) {
      const { faqAnswerSuccessfullyUpdated, updatedFaqQuestionArray } = createFaqAnswerOutcome || {}

      if (faqAnswerSuccessfullyUpdated) {
        setFaqArray(updatedFaqQuestionArray)
        const updatedPages = dsrPagesRedux.map((page, index) => {
          if (page.pageType === 'faq') {
            const updatedPageContent = { ...page.pageContent[0], faqArray: updatedFaqQuestionArray }

            return { ...page, pageContent: [updatedPageContent] }
          }

          return page
        })
        props.action.setDsrPages(updatedPages)
      }
      props.action.resetCreateFaqAnswer()
    }
  }, [createFaqAnswerOutcome, dsrPagesRedux, props.action]);

  useEffect(() => {
    if (editFaqAnswerOutcome) {
      const { faqAnswerSuccessfullyUpdated, updatedFaqQuestionArray } = editFaqAnswerOutcome || {}

      if (faqAnswerSuccessfullyUpdated) {
        setFaqArray(updatedFaqQuestionArray)
        const updatedPages = dsrPagesRedux.map((page, index) => {
          if (page.pageType === 'faq') {
            const updatedPageContent = { ...page.pageContent[0], faqArray: updatedFaqQuestionArray }

            return { ...page, pageContent: [updatedPageContent] }
          }

          return page
        })
        props.action.setDsrPages(updatedPages)
      }
    }
    props.action.resetEditFaqAnswer()
  }, [editFaqAnswerOutcome, dsrPagesRedux, props.action]);

  useEffect(() => {
    if (editFaqQuestionOutcome) {
      const { faqQuestionSuccessfullyUpdated, updatedFaqQuestionArray } = editFaqQuestionOutcome || {}

      if (faqQuestionSuccessfullyUpdated) {
        setFaqArray(updatedFaqQuestionArray)
        const updatedPages = dsrPagesRedux.map((page, index) => {
          if (page.pageType === 'faq') {
            const updatedPageContent = { ...page.pageContent[0], faqArray: updatedFaqQuestionArray }

            return { ...page, pageContent: [updatedPageContent] }
          }

          return page
        })
        props.action.setDsrPages(updatedPages)
        setShowQuestionInput(false)
      }
    }
  }, [editFaqQuestionOutcome, dsrPagesRedux, props.action]);

  useEffect(() => {
    if (deleteFaqAnswerOutcome) {
      const { faqAnswerSuccessfullyUpdated, updatedFaqQuestionArray } = deleteFaqAnswerOutcome || {}

      if (faqAnswerSuccessfullyUpdated) {
        setFaqArray(updatedFaqQuestionArray)
        const updatedPages = dsrPagesRedux.map((page, index) => {
          if (page.pageType === 'faq') {
            const updatedPageContent = { ...page.pageContent[0], faqArray: updatedFaqQuestionArray }

            return { ...page, pageContent: [updatedPageContent] }
          }

          return page
        })
        props.action.setDsrPages(updatedPages)
      }
    }
  }, [deleteFaqAnswerOutcome, dsrPagesRedux, props.action]);

  useEffect(() => {
    if (createFaqQuestionOutcome) {
      const { faqQuestionSuccessfullyUpdated, updatedFaqQuestionArray } = createFaqQuestionOutcome || {}

      if (faqQuestionSuccessfullyUpdated) {
        setFaqArray(updatedFaqQuestionArray)
        const updatedPages = dsrPagesRedux.map((page, index) => {
          if (page.pageType === 'faq') {
            const updatedPageContent = { ...page.pageContent[0], faqArray: updatedFaqQuestionArray }

            return { ...page, pageContent: [updatedPageContent] }
          }

          return page
        })
        props.action.setDsrPages(updatedPages)
        setShowQuestionInput(false)
      }
      props.action.resetCreateFaqQuestion()
    }
  }, [createFaqQuestionOutcome, dsrPagesRedux, props.action]);

  useEffect(() => {
    if (deleteFaqQuestionOutcome) {
      const { faqQuestionSuccessfullyUpdated, updatedFaqQuestionArray } = deleteFaqQuestionOutcome || {}

      if (faqQuestionSuccessfullyUpdated) {
        setFaqArray(updatedFaqQuestionArray)

        const updatedPages = dsrPagesRedux.map((page, index) => {
          if (page.pageType === 'faq') {
            const updatedPageContent = { ...page.pageContent[0], faqArray: updatedFaqQuestionArray }

            return { ...page, pageContent: [updatedPageContent] }
          }

          return page
        })
        props.action.setDsrPages(updatedPages)
        setShowQuestionInput(false)
      }
    }
  }, [deleteFaqQuestionOutcome, dsrPagesRedux, props.action]);

  const handleAskQuestionButton = () => {
    if (isPublicDsrEnvironment) {
      if (isDsrMember) {
        history.push(`/dsr/${dsrId}`, {})
       
      } else {
        props.action.changeDsrPopupState("show-signup-login-prompt")
        props.action.changeDsrModalState(true)
      }
    } else {
        if (isPagePreviewMode) {
            props.action.changeDsrPopupState("show-preview-page-action-modal")
            props.action.changeDsrModalState(true)
        } else {
            setShowQuestionInput(true)
        }
    }
  }

  const handleClickCreateQuestionButton = () => {
      if (isPublicDsrEnvironment) {
          handleOpenSignupOrLoginModal()
      } else {
          if (isPagePreviewMode) {
              props.action.changeDsrPopupState("show-preview-page-action-modal")
              props.action.changeDsrModalState(true)
          } else {
              setShowQuestionInput(true)
          }
      }
  }

  let answeredQuestions = faqArray?.filter((question) => (question.questionStatus === 'active' && question.questionAnswered === true)).sort((a, b) => new Date(b.questionCreatedAt) - new Date(a.questionCreatedAt))
  let pendingQuestions = faqArray?.filter((question) => (question.questionStatus === 'active' && question.questionAnswered === false)).sort((a, b) => new Date(b.questionCreatedAt) - new Date(a.questionCreatedAt))

  const showAnsweredQuestions = answeredQuestions?.length > 0 ? true : false;
  const showPendingQuestions = pendingQuestions?.length > 0 ? true : false;


  if (showAnsweredQuestions === false && showPendingQuestions === false) {
    return (
      <Box>
        <Flex mt='2em' justify='center' align='center' py='2.75em' border='1px solid #E5EAEF' rounded='0.5em' bg='white'>
          <Flex direction='column' justify='center' align='center'>

            <Box as={MdOutlineQuestionAnswer} color='brandDark.500' boxSize="3em" />

            <Flex fontWeight='400' align='center' textAlign='center' lineHeight='1.5em' fontSize='0.875em' color='gray.500' py='1.5em' px='1em'>
              No questions yet. Get the first one going.
            </Flex>

            <Box whiteSpace='nowrap' >
              <Flex className='create-new-category-button'
                onClick={() => handleAskQuestionButton()}>
                <Box className='create-new-category-button__button-text'>{isDraftMode ? 'Prepare a question' : 'Ask a question'}</Box>
                <Box as={IoMdAddCircle} boxSize='1.5em' className='create-new-room-button__add-icon' />
              </Flex>
            </Box>

            <DsrDynamicModal
              dsrId={dsrId}
              userActionRequired={isDraftMode ? 'prepare-faq-question-in-draft' : 'create-faq-question'}
              showDsrModal={showQuestionInput}
              handleDsrModalClose={handleCloseFirstQuestionModal}
              handleCreateNewPost={isDraftMode ? handleCreateFaqQuestionInDraftMode : handleCreateFirstFaqQuestion}
              dsrStatus={dsrStatus}
            />

          </Flex>
        </Flex>
      </Box>
    )


  } else {
    return (
      <React.Fragment>
        <Box mb="1.25em" fontWeight="600" fontSize="1.5em" lineHeight="2em" color="brandDark.500">
          Q&A
        </Box>

        {showAnsweredQuestions &&
          <React.Fragment>
            <AnsweredFaqDisplay
              dsrId={dsrId}
              dsrTeamMembers={dsrTeamMembers}
              answeredQuestions={answeredQuestions}
            />
            
          </React.Fragment>
        }
        {showPendingQuestions &&
          <PendingFaqDisplay
            dsrId={dsrId}
            dsrTeamMembers={dsrTeamMembers}
            showPendingQuestions={showPendingQuestions}
            pendingQuestions={pendingQuestions}
          />
        }

        <Flex w='100%' justify='center' pb={'6em'}>
          <LaunchdeckButton bg='brand.500' color='white' onClick={handleClickCreateQuestionButton} label=' Create question' />
        </Flex>

        <Box>
          <DsrDynamicModal
            dsrId={dsrId}
            userActionRequired={isDraftMode ? 'prepare-faq-question-in-draft' : 'create-faq-question'}
            showDsrModal={showQuestionInput}
            handleDsrModalClose={handleCloseFirstQuestionModal}
            handleCreateNewPost={isDraftMode ? handleCreateFaqQuestionInDraftMode : handleCreateFirstFaqQuestion}
            dsrStatus={dsrStatus}
          />
        </Box>

      </React.Fragment>
    );
  }
}


function mapStateToProps(state) {
  return {
    createFaqAnswerOutcome: state.createFaqAnswerOutcome.results.data,
    editFaqAnswerOutcome: state.editFaqAnswerOutcome.results.data,
    deleteFaqAnswerOutcome: state.deleteFaqAnswerOutcome.results.data,
    createFaqQuestionOutcome: state.createFaqQuestionOutcome.results.data,
    editFaqQuestionOutcome: state.editFaqQuestionOutcome.results.data,
    deleteFaqQuestionOutcome: state.deleteFaqQuestionOutcome.results.data,
    createNewDsrFeedPostOutcome: state.createNewDsrFeedPostOutcome.results.data,

    dsrFeedContent: state.dsrFeedContent.results,
    dsrPagesRedux: state.dsrPages.pages,

    dsrStatusRedux: state.dsrStatus.status,
    // dsrFaqArrayRedux: state.faqPageContent.faqArray,
    dsrTeamMembersRedux: state.dsrTeamMembers.members,

    isPublicDsrEnvironment: state.publicDsrEnvironment.publicDsrEnv,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    action: bindActionCreators(
      {
        createNewDsrFeedPost,
        resetCreateNewDsrFeedPost,
        replaceDsrFeedContent,
        setDsrPages,
        createFaqQuestion,
        resetCreateFaqQuestion,
        resetCreateFaqAnswer,
        resetEditFaqAnswer,
        changeDsrPopupState,
        changeDsrModalState
      },
      dispatch
    )
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DsrFaqDisplay));
