import React, { useState, useEffect, useRef } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';

import { Box, Divider, Flex } from "@chakra-ui/react";
import InputTextAreaExpandable from './InputTextAreaExpandable';
import { createFaqAnswer, editFaqAnswer, resetDeleteFaqAnswer, editFaqQuestion, resetEditFaqQuestion } from '../../../digital-sales-room/actions/dsr-actions';
import LaunchdeckCloseButton from "../../../global/components/helper-components/components/LaunchdeckCloseButton";
import DsrDynamicModal from "../../../digital-sales-room/messages/DsrDynamicModal";
import LaunchdeckButton from "../../../global/components/helper-components/components/LaunchdeckButton";


function DsrFaqTextAreaInput(props) {

  const { htmlFor, name, currentValue, placeholder, userActionRequired,
    dsrId, questionId,
    handleCloseInputArea,
    faqAnswerIsSaving,
    editFaqAnswerOutcome, editedFaqAnswerIsSaving,
    deleteFaqAnswerOutcome, 
    editFaqQuestionOutcome, editedFaqQuestionIsSaving, bg } = props;

  const originalValue = useRef(currentValue ? currentValue : null)

  // Inputs
  const [inputValue, setInputValue] = useState(currentValue ? currentValue : "");

  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false)
  const [showNewAnswer, setShowNewAnswer] = useState(false);
  const [updatedAnswerDisplay, setUpdatedAnswerDisplay] = useState("");

  const [showUpdateErrorTag, setShowUpdateErrorTag] = useState(null)
  const [displayEmptyInputError, setDisplayEmptyInputError] = useState(false)
  const [isInvalidInput, setIsInvalidInput] = useState("");


  useEffect(() => {
    if (inputValue) {
      setDisplayEmptyInputError(false)
    }
  }, [inputValue]);


  useEffect(() => {
    if (editFaqAnswerOutcome) {
      const { faqAnswerSuccessfullyUpdated, updatedAnswer } = editFaqAnswerOutcome || {}
      if (faqAnswerSuccessfullyUpdated === true) {
        setShowUpdateErrorTag(false)
        handleCloseInputArea()
        setShowNewAnswer(true)
        setUpdatedAnswerDisplay(updatedAnswer)
      }

      if (!faqAnswerSuccessfullyUpdated) {
        setShowUpdateErrorTag(true)
      }
    }
  }, [editFaqAnswerOutcome, handleCloseInputArea]);

  useEffect(() => {
    if (deleteFaqAnswerOutcome) {
      const { faqAnswerSuccessfullyUpdated } = deleteFaqAnswerOutcome || {}
      if (faqAnswerSuccessfullyUpdated === true) {
        setShowUpdateErrorTag(null)
        handleCloseInputArea()
        props.action.resetDeleteFaqAnswer()

      }

      if (faqAnswerSuccessfullyUpdated === false) {
        setShowUpdateErrorTag(true)
      }

    }
  }, [deleteFaqAnswerOutcome, handleCloseInputArea, props.action]);

  useEffect(() => {
    if (editFaqQuestionOutcome) {
      const { faqQuestionSuccessfullyUpdated } = editFaqQuestionOutcome || {}
      if (faqQuestionSuccessfullyUpdated === true) {
        setShowUpdateErrorTag(false)
        handleCloseInputArea()
        props.action.resetEditFaqQuestion()
      }

      if (!faqQuestionSuccessfullyUpdated) {
        setShowUpdateErrorTag(true)
      }
    }
  }, [editFaqQuestionOutcome, handleCloseInputArea, props.action]);


  const onChangeHandler = event => {
    if (!inputValue) {
      setDisplayEmptyInputError(false)
    }

    setInputValue(event.target.value);
    setIsInvalidInput("")
  };

  const handleEmptyInput = () => {
    setIsInvalidInput(true)
    setDisplayEmptyInputError(true)
  }

  const handleClickCreateFaqAnswer = () => {
    if (!inputValue) {
      setDisplayEmptyInputError(true)
    } else {
      const createFaqAnswerMetadata = {
        dsrId: dsrId,
        questionId: questionId,
        faqAnswerContent: inputValue
      }

      props.action.createFaqAnswer(createFaqAnswerMetadata)
      setInputValue("")
    }
  }

  const handleClickEditFaqAnswer = () => {
    if (!inputValue) {
      setDisplayEmptyInputError(true)
    } else if (originalValue === inputValue) {
      handleCloseInputArea()
      setDisplayEmptyInputError(false)
    } else {
      const editFaqAnswerMetadata = {
        dsrId: dsrId,
        questionId: questionId,
        faqAnswerContent: inputValue
      }

      props.action.editFaqAnswer(editFaqAnswerMetadata)
      setInputValue(currentValue ? currentValue : "")
    }
  }

  const handleClickEditFaqQuestion = () => {
    if (!inputValue) {
      setDisplayEmptyInputError(true)
    } else if (originalValue === inputValue) {
      handleCloseInputArea()
      setDisplayEmptyInputError(false)
    } else {
      const editFaqQuestionMetadata = {
        dsrId: dsrId,
        questionId: questionId,
        faqQuestionContent: inputValue 
      }

      props.action.editFaqQuestion(editFaqQuestionMetadata)
      setInputValue(currentValue ? currentValue : "")
    }
  }

  const handleClickCloseButton = () => {
    handleCloseInputArea()
    setDisplayEmptyInputError(false)
    setInputValue("")
  }

  const handleCloseModalButton = () => {
    setShowConfirmDeleteModal(false)
  }


  switch (userActionRequired) {
    case ('create-faq-answer'): {
      return (
        <Box htmlFor={htmlFor} w='100%'>
          {showNewAnswer ? <Box>{updatedAnswerDisplay}</Box> :
            <React.Fragment>
              <Flex w='100%' my='0.5em' justify='space-between' align='center'>
              <Box fontSize='0.6em' p='0.25em 0.5em' bg='brandDark.500' color='white'  rounded='0.5em'>REPLY TO QUESTION</Box>
                <LaunchdeckCloseButton handleCloseModal={handleClickCloseButton} />
              </Flex>
              <InputTextAreaExpandable
                onChangeHandler={onChangeHandler}
                inputValue={inputValue}
                placeholder={placeholder}
                isInvalidInput={isInvalidInput}
                border='1px solid'
                borderColor='gray.200'
                color='brandDark.500'
                bg={bg && bg}
              />
              {displayEmptyInputError && <Box fontSize="0.8em" color="pink.500" mt='0.2em'>Please provide an answer before submitting</Box>}

              <Flex my='1em' justify='flex-end' w='100%'>

                <DisplayButtonMode
                  name={name}
                  showUpdateErrorTag={showUpdateErrorTag}
                  handleCtaButtonClick={handleClickCreateFaqAnswer}
                  handleEmptyInput={handleEmptyInput}
                  submittedInputIsSaving={faqAnswerIsSaving}
                  inputValue={inputValue}
                  buttonMode={'create'}
                  buttonText={'Create reply'}
                />

              </Flex>
            </React.Fragment>}
        </Box>
      );
    }
    case ('edit-faq-answer'): {
      return (
        <Box htmlFor={htmlFor} w='100%'>
          {showNewAnswer ? <Box>{updatedAnswerDisplay}</Box> :
            <React.Fragment>
                            <Divider />
              <Flex w='100%' my='1em' justify='space-between' align='center'>
                <Box fontSize='0.6em' p='0.25em 0.5em' bg='brandDark.500' color='white'  rounded='0.5em'>EDIT ANSWER</Box>

                <LaunchdeckCloseButton handleCloseModal={handleClickCloseButton} />
         
              </Flex>

              <InputTextAreaExpandable
                onChangeHandler={onChangeHandler}
                inputValue={inputValue}
                placeholder={placeholder}
                isInvalidInput={isInvalidInput}
                borderColor='gray.500'
                border='1px solid'
                bg={bg && bg}
              />
              {displayEmptyInputError && <Box fontSize="0.8em" color="pink.500" mt='0.2em'>Please provide an answer before submitting</Box>}

              <Flex mt='0.5em' justify='flex-end' w='100%'>

                <DisplayButtonMode
                  showUpdateErrorTag={showUpdateErrorTag}
                  setShowConfirmDeleteModal={setShowConfirmDeleteModal}
                  handleCtaButtonClick={handleClickEditFaqAnswer}
                  handleEmptyInput={handleEmptyInput}
                  submittedInputIsSaving={editedFaqAnswerIsSaving}
                  inputValue={inputValue}
                  buttonMode={'update'}
                />

              </Flex>
              <DsrDynamicModal
                dsrId={dsrId}
                userActionRequired={'confirm-delete-faq-answer'}
                showDsrModal={showConfirmDeleteModal}
                questionId={questionId}
                handleDsrModalClose={handleCloseModalButton}
              />
            </React.Fragment>}
        </Box>
      );
    }
    case ('edit-faq-question'): {
      return (
        <Box htmlFor={htmlFor} w='100%'>
          <React.Fragment>
            <Flex w='100%' justify='space-between' align='center' px='1em' pt='0.5em'>
              <Box fontSize='0.6em' p='0.25em 0.5em' bg='brandDark.500' color='white'  rounded='0.5em'>EDIT QUESTION</Box>
              <LaunchdeckCloseButton handleCloseModal={handleClickCloseButton} />
            </Flex>
            <Box p='1em'>
              <InputTextAreaExpandable
                onChangeHandler={onChangeHandler}
                inputValue={inputValue}
                placeholder={placeholder}
                isInvalidInput={isInvalidInput}
                isLoadingState={editedFaqQuestionIsSaving}
                bg={bg && bg}
              />
              {displayEmptyInputError && <Box fontSize="0.8em" color="pink.500" mt='0.2em'>Did you want to delete the question? Simply use the delete button below. </Box>}

              <Flex mt='0.5em' justify='flex-end' w='100%'>

                <DisplayButtonMode
                  showUpdateErrorTag={showUpdateErrorTag}
                  setShowConfirmDeleteModal={setShowConfirmDeleteModal}
                  handleCtaButtonClick={handleClickEditFaqQuestion}
                  handleEmptyInput={handleEmptyInput}
                  submittedInputIsSaving={editedFaqQuestionIsSaving}
                  inputValue={inputValue}
                  originalValue={originalValue}
                  buttonMode={'update'}
                  buttonText={'Edit'}
                />

              </Flex>
            </Box>
            <DsrDynamicModal
              dsrId={dsrId}
              userActionRequired={'confirm-delete-faq-question'} 
              showDsrModal={showConfirmDeleteModal}
              questionId={questionId}
              handleDsrModalClose={handleCloseModalButton}
            />
            
          </React.Fragment>
      </Box>
      )
    }
    default: {
      return null;
    }
  }
}

function DisplayButtonMode({
  name, 
  showUpdateErrorTag,
  setShowConfirmDeleteModal,
  handleCtaButtonClick,
  submittedInputIsSaving,
  handleEmptyInput,
  inputValue,
  buttonMode,
  buttonText
}) {
  
  return (
    <React.Fragment>
      {
        {
          true: <Box>Error Updating</Box>,
          false: null, // No need to display anything if it is successfully updated
          null: <React.Fragment>
               <Flex mt='0.5em' w='100%' justify={buttonMode==='create'? 'flex-end' : 'space-between'} align='flex-end'>
              {(buttonMode === 'update') &&
                <Box mx='0.5em' color='gray.500' cursor='pointer' fontSize='0.75em'  onClick={() => setShowConfirmDeleteModal(true)}>
                  Delete {name==="EditFaqAnswer" ? 'answer' : 'question'}
                </Box>
              }
              {/* {noChanges ? 
              // TODO: Consider a UX improvement of disabling button if there is no changes detected to prevent unnencessary API calls. 
              // const noChanges = (originalValue === inputValue) ? true : false;
              : */}
              <LaunchdeckButton bg='brand.500' color='white' fontSize='0.8em' loadingLogic={submittedInputIsSaving}  onClick={() => inputValue.trim().length > 0 ? handleCtaButtonClick() : handleEmptyInput()} label={buttonText ? buttonText : 'Submit'} />
              {/* } */}
              </Flex>
          </React.Fragment>
        }[showUpdateErrorTag]
      }
    </React.Fragment>
  );
}


function mapStateToProps(state) {
  return {
    faqAnswerIsSaving: state.createFaqAnswerOutcome.isLoading,
    editedFaqAnswerIsSaving: state.editFaqAnswerOutcome.isLoading,
    deleteFaqAnswerOutcome: state.deleteFaqAnswerOutcome.results.data,

    editedFaqQuestionIsSaving: state.editFaqQuestionOutcome.isLoading,
    editFaqQuestionOutcome: state.editFaqQuestionOutcome.results.data,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    action: bindActionCreators(
      {
        createFaqAnswer,
        editFaqAnswer,
        resetDeleteFaqAnswer,
        editFaqQuestion,
        resetEditFaqQuestion

      },
      dispatch
    )
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DsrFaqTextAreaInput));


