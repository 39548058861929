import React from 'react';

import '../styles/header.scss';
import { Flex } from "@chakra-ui/react";
import HeaderLogo from './HeaderLogo';
import NavigationMenuHeader from './NavigationMenuHeader';
import DsrNavigationDrawerMenu from '../../sidebar/components/DsrNavigationDrawerMenu';


export default function MobileHeader({ onPressLogout, userProfile, className, history }) {

	const pathname = history ? history.location.pathname : '';

  switch (pathname) {
    case (pathname.match(/^\/dsr/)?.input):
      return null
  }


	function renderNavigationMenu() {
		switch (pathname) {
			//"No distraction" pages
			case ('/welcome'):
			case ('/setup-profile'):
			case ('/profile/change-password'):
			case ('/organization/choose-plan'): {
				return null
			}
			default:
				return (
					<Flex ml='8%' align='center' justify='flex-start' mr='0.5em'>
						  <DsrNavigationDrawerMenu darkMode={false} userProfile={userProfile} signOut={onPressLogout} />
						{/* <div className="header__container header__title"> */}
							<NavigationMenuHeader isMobileView={true}/>
						{/* </div> */}
					</Flex>
				)

		}
	}

	return (
		<nav className={`${className} header header--default`}>
			{renderNavigationMenu()}
			<HeaderLogo isMobileView={true}/>
		</nav>
	);
}
