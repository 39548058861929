import React from 'react';
import { useLocation } from 'react-router-dom';
import { Flex, Box, Image, ChakraBaseProvider } from "@chakra-ui/react";
import PageNotFoundImage from '../styles/images/page-not-found.png';
import LaunchdeckButton from './LaunchdeckButton';
import { redirectToNewPage } from '../../../helpers/global-helpers';
import PublicUserHeader from '../../../../auth/components/PublicUserHeader';
import claverTheme from '../../../../layout/components/layout/components/theme/claverTheme';


export default function PublicErrorPage() {

	const location = useLocation();
	const { redirectionUri } = location.state || {};

	return (
		<ChakraBaseProvider theme={claverTheme}>
		{/* <CSSReset /> */}
		<PublicUserHeader 
			signUpEnabled 
			showBackOption= {redirectionUri ? true : false}
			previousRoute={redirectionUri}
			/>

		<Flex direction="column" align="center" bg='white' p='3%' mb='2em' rounded='0.8em'>
			<Box fontSize='1.2em' fontWeight='600'>Page not found </Box>
			<Box mt='1em' fontSize='1em' color='brandDark.500'>But you've found a sleeping dog instead!</Box>
			<Image src={PageNotFoundImage} alt="Invalid page error" />

			<Flex direction='column' mt='1em' justify='center' alignItems='center'>
				<Flex justify={['center', 'flex-end', 'flex-end', 'flex-end']} w='100%'>
					<LaunchdeckButton bg='brand.500' color='white' onClick={() => redirectToNewPage(redirectionUri ? redirectionUri : '/signup')} label='Ssshh...quietly head back?' />
				</Flex>
			</Flex>

		</Flex>

		</ChakraBaseProvider>

		
	)
}

