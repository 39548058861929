import React, { useRef } from "react";
import { Box, Flex, Skeleton, Textarea } from "@chakra-ui/react";
import { ReactTinyLink } from "react-tiny-link";
import { HighlightMenu } from "react-highlight-menu";
import { FiBold, FiItalic, FiList } from "react-icons/fi";
import { LuHeading1, LuHeading2, LuHeading3, LuHeading4, LuHeading5 } from "react-icons/lu";
import { PiListNumbersLight } from "react-icons/pi";
// import { FaRemoveFormat } from "react-icons/fa";

export default function InputTextAreaExpandableWithFormatting(props) {
  const {
    id,
    name,
    bg,
    color,
    border,
    borderColor,
    fontSize,
    onBlur,
    autoFocus,
    setSubWidgetContent,
    onChangeHandler,
    inputValue,
    placeholder,
    isInvalidInput,
    previewLink,
    isLoadingState,
    textAlign,
    backgroundColor,
  } = props;
  const menuRef = useRef();
  const [startIndex, setStartIndex] = React.useState(0);
  const [endIndex, setEndIndex] = React.useState(0);

  const handleTextFormatting = (formatType, selectedText) => {
    let modifiedString = "";
    let hFormatPrefix = "";

    const formatSelectedText = (start, end, newString) => {
      const before = inputValue.substring(0, start);
      const after = inputValue.substring(end);

      return before + newString + after;
    };

    switch (formatType) {
      case ("h1"): {
        hFormatPrefix = '#'
        break;
      }
      case ("h2"): {
        hFormatPrefix = '##'
        break;
      }
      case ("h3"): {
        hFormatPrefix = '###'
        break;
      }
      case ("h4"): {
        hFormatPrefix = '####'
        break;
      }
      case ("h5"): {
        hFormatPrefix = '#####'
        break;
      }
      case ("h6"): {
        hFormatPrefix = '######'
        break;
      }
      default: {
        break;
      }
    }

    switch (formatType) {
      case "h1":
      case "h2":
      case "h3":
      case "h4":
      case "h5":
      case "h6": {
        const lines = selectedText.split("\n");
        let updatedLines = lines.map(line => {
          // Remove existing header '#' characters
          const strippedLine = line.replace(/^#{1,6}\s*/, '');
          // Trim the stripped line
          const trimmedLine = strippedLine.trim();
          // Only add '#' if the trimmedLine is not empty
          if (trimmedLine !== '') {
            return `${hFormatPrefix} ${trimmedLine}`;
          } else {
            return '';
          }
        });

        // Join the lines back into a single string with newlines
        const formattedText = updatedLines.join("\n");

        // Modify the inputValue using formatSelectedText
        modifiedString = formatSelectedText(startIndex, endIndex, formattedText);
        break;
      }
      case "bold": {
        modifiedString = formatSelectedText(
          startIndex,
          endIndex,
          `**${selectedText}**`,
        );
        break;
      }
      case "italic": {
        modifiedString = formatSelectedText(
          startIndex,
          endIndex,
          `*${selectedText}*`,
        );
        break;
      }
      case "list": {
        const lines = selectedText.split("\n").filter(line => line.trim() !== "");
        const isBulletList = lines.every(line => /^-\s/.test(line.trim()));
        const isNumberedList = lines.every(line => /^\d+\)\s/.test(line.trim()));

        if (isBulletList) {
          // Remove bullets
          const modifiedLines = lines.map(line => line.replace(/^\-\s/, ''))
            .filter((line) => line !== "");

          modifiedString = inputValue.replace(
            selectedText,
            modifiedLines.join("\n"),
          );
        } else {
          if (isNumberedList) {
            // Remove numbering first
            const modifiedLines = lines.map(line => line.replace(/^\d+\)\s/, ''))
              .filter((line) => line !== "")
              // Apply bullets
              .map((line) => {
                const trimmedLine = line.trim();
                return trimmedLine ? `- ${trimmedLine}` : line;
              })
              .filter((line) => line !== "");
            modifiedString = inputValue.replace(
              selectedText,
              modifiedLines.join("\n"),
            );

          } else {
            const modifiedLines = lines
              .map((line) => {
                const trimmedLine = line.trim();
                return trimmedLine ? `- ${trimmedLine}` : line;
              })
              .filter((line) => line !== "");
            modifiedString = inputValue.replace(
              selectedText,
              modifiedLines.join("\n"),
            );
          }
        }


        break;
      }
      case "numbered-list": {
        const lines = selectedText.split("\n").filter(line => line.trim() !== "");;
        const isBulletList = lines.every(line => /^-\s/.test(line.trim()));
        const isNumberedList = lines.every(line => /^\d+\)\s/.test(line.trim()));

        if (isNumberedList) {
          // Remove numbering
          const modifiedLines = lines.map(line => line.replace(/^\d+\)\s/, ''))
            .filter((line) => line !== "");

          modifiedString = inputValue.replace(
            selectedText,
            modifiedLines.join("\n"),
          );
        } else {
          if (isBulletList) {
            // Remove bullets first
            const modifiedLines = lines.map(line => line.replace(/^\-\s/, ''))
              .filter((line) => line !== "")
              // Apply numbers
              .map((line, index) => {
                const trimmedLine = line.trim();
                return trimmedLine ? `${index + 1}) ${trimmedLine}` : line;
              })
              .filter((line) => line !== "");
            modifiedString = inputValue.replace(
              selectedText,
              modifiedLines.join("\n"),
            );
          } else {
            const modifiedLines = lines
              .map((line, index) => {
                const trimmedLine = line.trim();
                return trimmedLine ? `${index + 1}) ${trimmedLine}` : line;
              })
              .filter((line) => line !== "");

            modifiedString = inputValue.replace(
              selectedText,
              modifiedLines.join("\n"),
            );
          }

        }

        break;
      }
      // case "remove-markdown-format": {

      //   // Remove headers (##, ###, ######, etc.)
      //   selectedText = selectedText.replace(/^(#{1,6})\s+/gm, '');

      //   // Remove emphasis (*text* or _text_)
      //   selectedText = selectedText.replace(/(\*|_)(.*?)\1/g, '$2');

      //   // Remove strong emphasis (**text** or __text__)
      //   selectedText = selectedText.replace(/(\*\*|__)(.*?)\1/g, '$2');

      //   // Remove inline code (`code`)
      //   selectedText = selectedText.replace(/`([^`]+)`/g, '$1');

      //   // Remove horizontal rules (--- or ***)
      //   selectedText = selectedText.replace(/^(-{3,}|\*{3,})$/gm, '');

      //   // Remove unordered list markers (-, +, *)
      //   selectedText = selectedText.replace(/^\s*[-+*]\s+/gm, '');

      //   // Remove ordered list markers (1., 2., 3.)
      //   selectedText = selectedText.replace(/^\s*\d+\.\s+/gm, '');
      //   selectedText = selectedText.replace(/^\s*\d+\)\s+/gm, '');

      //   // Remove blockquotes (>)
      //   selectedText = selectedText.replace(/^\s*>/gm, '');

      //   // Remove links ([text](url) or [text][id])
      //   selectedText = selectedText.replace(/\[([^\]]+)](?:\[[^\]]+]| ?\(.*?\))/g, '$1');

      //   // Remove images ![alt text](url)
      //   selectedText = selectedText.replace(/!\[[^\]]*]\([^)]+\)/g, '');

      //   // Remove reference-style links and images [text][id]
      //   selectedText = selectedText.replace(/\[([^\]]+)]\[[^\]]+]/g, '$1');

      //   // Remove HTML tags (if any)
      //   selectedText = selectedText.replace(/<[^>]*>/g, '');

      //   // Trim leading and trailing whitespace
      //   selectedText = selectedText.trim();


      //   modifiedString = selectedText

      //   break;

      // }
      default: {
        break;
      }
    }

    if (modifiedString) setSubWidgetContent(modifiedString);
  };

  const handleSelect = (event) => {
    const { selectionStart, selectionEnd } = event.target;

    setStartIndex(selectionStart);
    setEndIndex(selectionEnd);
  };

  return isLoadingState ? (
    <Box position="relative" width="100%">
      <Skeleton w="50%" height="1.2em" my="0.5em" />
    </Box>
  ) : (
    <Box position="relative" width="100%" minH="8em">
      <HighlightMenu
        styles={{
          borderColor: "#2D3748",
          backgroundColor: "#2D3748",
          boxShadow: "0px 5px 5px 0px rgba(0, 0, 0, 0.15)",
          zIndex: 10,
          borderRadius: "5px",
          padding: "0.5em",
        }}
        target={menuRef}
        menu={({ selectedText }) => (
          <Flex gap={4}>
            <LuHeading1
              size="0.875em"
              color="white"
              onClick={() => handleTextFormatting("h1", selectedText)}
            />
            <LuHeading2
              size="0.875em"
              color="white"
              onClick={() => handleTextFormatting("h2", selectedText)}
            />
            <LuHeading3
              size="0.875em"
              color="white"
              onClick={() => handleTextFormatting("h3", selectedText)}
            />
            <LuHeading4
              size="0.875em"
              color="white"
              onClick={() => handleTextFormatting("h4", selectedText)}
            />
            <LuHeading5
              size="0.875em"
              color="white"
              onClick={() => handleTextFormatting("h5", selectedText)}
            />
            <FiBold
              size="0.875em"
              color="white"
              onClick={() => handleTextFormatting("bold", selectedText)}
            />
            <FiItalic
              size="0.875em"
              color="white"
              onClick={() => handleTextFormatting("italic", selectedText)}
            />
            <FiList
              size="0.875em"
              color="white"
              onClick={() => handleTextFormatting("list", selectedText)}
            />
            <PiListNumbersLight
              size="0.875em"
              color="white"
              onClick={() => handleTextFormatting("numbered-list", selectedText)}
            />
            {/* <FaRemoveFormat
              size="0.875em"
              color="white"
              onClick={() => handleTextFormatting("remove-markdown-format", selectedText)}
            /> */}
          </Flex>
        )}
      />
      <Textarea
        id={id && id}
        ref={menuRef}
        autoFocus={autoFocus && autoFocus}
        onBlur={onBlur && onBlur}
        name={name}
        minH="2.8em"
        variant={"outline"}
        onChange={onChangeHandler}
        onSelect={handleSelect}
        value={inputValue ?? ""}
        w="full"
        h="full"
        position="absolute"
        left="0"
        overflow="hidden"
        resize="none"
        fontSize={fontSize ? fontSize : "0.875em"}
        color={color && color}
        textAlign={textAlign ? textAlign : "left"}
        backgroundColor={backgroundColor ? backgroundColor : ""}
        placeholder={placeholder && placeholder}
        rounded="0.25em"
        isInvalid={isInvalidInput}
        border={border ? border : "0px"} // Manipulate this to show or hide the input box border
        borderColor={borderColor && borderColor}
        p="0.875em"
        bg={bg && bg}
      />

      <Textarea
        visibility="hidden"
        isInvalid={isInvalidInput}
        minH="2.8em"
        p="0.875em"
        fontSize={fontSize ? fontSize : "0.875em"}
        as="div"
        whiteSpace="pre-wrap"
        h="auto"
      >
        {inputValue}
        {inputValue &&
          inputValue.charAt(inputValue.length - 1) === "\n" &&
          "\n"}
      </Textarea>

      {previewLink && (
        <div style={{ marginTop: "20px" }}>
          <ReactTinyLink
            cardSize="small"
            showGraphic={true}
            maxLine={2}
            minLine={1}
            url={previewLink}
          />
        </div>
      )}
    </Box>
  );
}
