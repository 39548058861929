import React, { memo, useRef, useState, useEffect } from 'react'
import { Box, Flex, Skeleton, Input, InputGroup } from '@chakra-ui/react'
import DsrDisplayTeamAvatar from '../dsr-team-mgmt/DsrDisplayTeamAvatar'
import DsrNavigationDrawerMenu from '../../layout/components/sidebar/components/DsrNavigationDrawerMenu'
import SaveDsrDraftButton from '../dsr-draft/SaveDsrDraftButton'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { changeDsrModalState, changeDsrPopupState, updateDsr } from '../actions/dsr-actions'
import { signOut } from '../../auth/actions/auth-actions';

import '../../layout/components/header/styles/header.scss';
import DsrManageOrgLogosModule from '../dsr-draft/DsrManageOrgLogosModule';
import DsrPageSideMenuDrawer from "./DsrPageSideMenuDrawer";
import DsrHeaderMobileDisplay from './DsrHeaderMobileDisplay'
import LaunchdeckTag from '../../global/components/helper-components/components/LaunchdeckTag'
import LaunchdeckButton from '../../global/components/helper-components/components/LaunchdeckButton'
import { ToggleButton } from "../dsr-draft/page-designer/toggle-button/ToggleButton";

// Component holding other authentication components
function DsrHeader({
	isSellingTeamMember,
	handleViewTeamMembers,
	handleChangeDsrStatus,
	dsrTeamMembers,
	userProfile,
	dsrId,
	dsrPages,
	isEditMode,
	isRoomTemplate,
	changeDsrPopupState,
	changeDsrModalState,
	signOut,
	dsrTitleRedux,
	updateDsr,
	// Redux
	isMobileView,
	isPagePreviewMode,
	setIsPagePreviewMode,
	userPrivileges,
	dsrPropertiesRedux
}) {

	const { publishDsr } = userPrivileges || {}
	const { showGuestList } = dsrPropertiesRedux || {}
	const headerTitleInputRef = useRef(null);
	const [currentDsrTitle, setCurrentDsrTitle] = useState("")
	const [showEditTitleInput, setShowEditTitleInput] = useState(false)

	const showGuestMembers = (showGuestList === undefined || showGuestList) ? true : false

	const handleInputChange = (event) => {
		if (event.target.name === "title") {
			setCurrentDsrTitle(event.target.value)
		}
	}

	const handleHitKey = (event) => {
		if (event.key === "Escape") {
			setShowEditTitleInput(false)
		} else if (event.key === 'Enter') {
			if (event.target.name === "title") {
				clickOutsideAutosave()
			}
		}
	};

	const clickOutsideAutosave = () => {
		setShowEditTitleInput(false)

		if (currentDsrTitle.trim().length > 0) {
			updateDsr({
				dsrId,
				dsrTitle: currentDsrTitle,
				sourceEvent: "update-dsr-title"
			})
		} else (
			setCurrentDsrTitle(dsrTitleRedux ? dsrTitleRedux : "-")
		)
	}

	const handleClickEditInput = (event) => {
		if (headerTitleInputRef.current && !headerTitleInputRef.current.contains(event.target)) {
			clickOutsideAutosave()
		}
	};

	// UX listeners
	useEffect(() => {
		document.addEventListener("keydown", handleHitKey, true);
		document.addEventListener("click", handleClickEditInput, true);
		return () => {
			document.removeEventListener("keydown", handleHitKey, true);
			document.removeEventListener("click", handleClickEditInput, true);
		};
	});

	useEffect(() => {
		if (dsrTitleRedux) {
			setCurrentDsrTitle(dsrTitleRedux)
		}
	}, [dsrTitleRedux]);


	const renderDsrTitle = () => {

		if (showEditTitleInput) {
			return (
				<Flex fontWeight='600' lineHeight='1.875em' ref={headerTitleInputRef} >
					<InputGroup
						size="2em"
						width="100%"
						maxWidth='35%'
						minWidth='25em'
						border='none'
					>
						<Input
							name="title"
							value={currentDsrTitle}
							onChange={handleInputChange}
							onKeyDown={handleHitKey}
							autoFocus={true}
							// onFocus={e => e.currentTarget.select()}
							fontSize='1.25em'
							border='1px solid'
							borderColor='#3295EC'
							borderRadius='0.25em'
							paddingLeft='5px'
							lineHeight='1.875em'
							fontWeight="600"
						/>

					</InputGroup></Flex>
			)

		} else {
			return (
				<Flex fontWeight='600' fontSize='1.25em' lineHeight='1.875em' justify='flex-start'>
					<Flex
						cursor='pointer'
						border='1px solid'
						borderColor='transparent'
						paddingLeft='5px'
						onClick={() => setShowEditTitleInput(!showEditTitleInput)}
					>
						{currentDsrTitle}
					</Flex>
				</Flex>
			)
		}
	}

	if (isMobileView) {
		return (
			<DsrHeaderMobileDisplay
				isEditMode={isEditMode}
				isSellingTeamMember={isSellingTeamMember}
				currentDsrTitle={currentDsrTitle}
				userProfile={userProfile}
				handleViewTeamMembers={handleViewTeamMembers}
			/>
		)
	}

	// TODO Review refactor logic
	if (isEditMode) {

		if (isRoomTemplate) {
			// UUU
			return (
				<>
				<Flex className='dsr-header__section'>
					<Flex className='dsr-header__container' alignItems='center'>
						<Flex justify='flex-start' align='center'>
							<DsrNavigationDrawerMenu userProfile={userProfile} signOut={signOut} darkMode={true} />
							<Box
								className="page-title"
								fontSize={['1em', '1em', '1.25em', '1.25em']} fontWeight='600' lineHeight='1.85em' color='white'
								display='inline-block' ml='2em'>
								Edit Template
							</Box>
							<Box ml='0.5em'>
								<LaunchdeckTag bg='gray.600' color='white' label='Draft' />
							</Box>
						</Flex>

						<Flex>
							<ToggleButton
								offLabelText='Edit'
								onLabelText='Preview'
								isChecked={isPagePreviewMode}
								setIsChecked={setIsPagePreviewMode}
							/>
						</Flex>

						<Flex>

		
									<Flex>
										<Box mr='1em'>
											<SaveDsrDraftButton
												dsrId={dsrId}
												dsrPages={dsrPages}
											/>
										</Box>
										<Box >
										</Box>

									</Flex>
						
						</Flex>
					</Flex>
				</Flex>
				{isPagePreviewMode
					? (
						<Flex className='dsr-header__section-guest-version'>
							{dsrTitleRedux &&
								<Flex className='dsr-header__container' alignItems='center'>

									<React.Fragment>
										<Flex align='center'>
											<Box mr='1em' />
											<Flex align='center'>
												<Flex mr='1em'>
													<DsrManageOrgLogosModule logoDisplayMode={'default-dsr'} isPagePreviewMode={true} />
												</Flex>
												<Flex fontWeight='600' fontSize='1.25em' lineHeight='1.875em' color='white'>{dsrTitleRedux} </Flex>
											</Flex>
										</Flex>

										<Flex align='center'>
											<Flex>
												<Box><DsrDisplayTeamAvatar perspective='seller' dsrTeamMembers={dsrTeamMembers} /></Box>
												<Box>	{showGuestMembers && <DsrDisplayTeamAvatar perspective='buyer' dsrTeamMembers={dsrTeamMembers} />}</Box>
											</Flex>

											<Box rounded='0.25em'>
												<LaunchdeckButton label='Join Room' width='100%' bg='brand.500' color='white'
													onClick={() => {
														changeDsrPopupState("show-preview-page-action-modal")
														changeDsrModalState(true)
													}} />
											</Box>
										</Flex>
									</React.Fragment>
								</Flex>
							}
						</Flex>
					)
					: (
						<>
								<Flex justify='center' align='center' bg='brandGreen.500' letterSpacing={'1px'} fontSize='0.8em' color='white' fontWeight='600' py='0.2em'>
						 TEMPLATE MODE
						</Flex>
						<Box className='dsr-content__dsr-top-banner'>
							<Flex padding='1em 0' justifyContent='space-between' width='100%'>
								<Flex align='center'>
									{/* <Flex mr='1em'>
										<DsrManageOrgLogosModule logoDisplayMode={'default-dsr'} isPagePreviewMode={false} />
									</Flex> */}
									{(currentDsrTitle || showEditTitleInput) ? renderDsrTitle()
										: <Skeleton className="loading-skeleton-bar"><Flex fontSize='1.25em' lineHeight='1.875em' >Sample Text Sample Text Sample Text</Flex></Skeleton>}
								</Flex>

								{/* <Flex>
									<Flex justify='center' align='center'>
										<Box><DsrDisplayTeamAvatar perspective='seller' dsrTeamMembers={dsrTeamMembers} /></Box>
										<Box>
											{showGuestMembers && <DsrDisplayTeamAvatar perspective='buyer' dsrTeamMembers={dsrTeamMembers} />}
										</Box>
									</Flex>
									<Box display='flex' alignItems='center' justifyContent='center'>
										<Box px='1em'>

											<LaunchdeckButton label='Manage Access' fontSize='0.875em' py='0.4em' px='1em' bg='brand.500' color='white'
												onClick={() => {

													if (handleViewTeamMembers) {
														handleViewTeamMembers()
													} else {
														changeDsrPopupState('view-team-members')
														changeDsrModalState(true)
													}
												}} />
										</Box>
									</Box>
								</Flex> */}
							</Flex>
			

						
						</Box>
				
						
						</>
					)
				}
			</>
			)
		} else {
			return (
				<>
					<Flex className='dsr-header__section'>
						<Flex className='dsr-header__container' alignItems='center'>
							<Flex justify='flex-start' align='center'>
								<DsrNavigationDrawerMenu userProfile={userProfile} signOut={signOut} darkMode={true} />
								<Box
									className="page-title"
									fontSize={['1em', '1em', '1.25em', '1.25em']} fontWeight='600' lineHeight='1.85em' color='white'
									display='inline-block' ml='2em'>
									Edit Room
								</Box>
								<Box ml='0.5em'>
									<LaunchdeckTag bg='gray.600' color='white' label='Draft' />
								</Box>
							</Flex>
	
							<Flex>
								<ToggleButton
									offLabelText='Edit'
									onLabelText='Preview'
									isChecked={isPagePreviewMode}
									setIsChecked={setIsPagePreviewMode}
								/>
							</Flex>
	
							<Flex>
	
								{isEditMode ?
									<React.Fragment>
										<Flex>
											<Box mr='1em'>
												<SaveDsrDraftButton
													dsrId={dsrId}
													dsrPages={dsrPages}
												/>
											</Box>
											<Box >
	
												<Box>
													<LaunchdeckButton bg='brandGreen.500' color='white' onClick={() => {
														if (publishDsr === false) {
															changeDsrPopupState('subscription-nudge')
															changeDsrModalState(true)
														} else if (handleChangeDsrStatus) {
															handleChangeDsrStatus()
														} else {
															changeDsrPopupState('revert-dsr-to-draft')
															changeDsrModalState(true)
														}
													}} label='Save & Publish' />
												</Box>
											</Box>
	
										</Flex>
									</React.Fragment>
									:
									<Flex alignItems='center'>
										<Box>
											<Box rounded='0.25em'>
												<LaunchdeckButton width='100%' bg='brand.500' color='white' onClick={() => {
	
													if (handleViewTeamMembers) {
														handleViewTeamMembers()
													} else {
														changeDsrPopupState('view-team-members')
														changeDsrModalState(true)
													}
												}} label='Share' />
											</Box>
										</Box>
									</Flex>
								}
							</Flex>
						</Flex>
					</Flex>
					{isPagePreviewMode
						? (
							<Flex className='dsr-header__section-guest-version'>
								{dsrTitleRedux &&
									<Flex className='dsr-header__container' alignItems='center'>
	
										<React.Fragment>
											<Flex align='center'>
												<Box mr='1em' />
												<Flex align='center'>
													<Flex mr='1em'>
														<DsrManageOrgLogosModule logoDisplayMode={'default-dsr'} isPagePreviewMode={true} />
													</Flex>
													<Flex fontWeight='600' fontSize='1.25em' lineHeight='1.875em' color='white'>{dsrTitleRedux} </Flex>
												</Flex>
											</Flex>
	
											<Flex align='center'>
												<Flex>
													<Box><DsrDisplayTeamAvatar perspective='seller' dsrTeamMembers={dsrTeamMembers} /></Box>
													<Box>	{showGuestMembers && <DsrDisplayTeamAvatar perspective='buyer' dsrTeamMembers={dsrTeamMembers} />}</Box>
												</Flex>
	
												<Box rounded='0.25em'>
													<LaunchdeckButton label='Join Room' width='100%' bg='brand.500' color='white'
														onClick={() => {
															changeDsrPopupState("show-preview-page-action-modal")
															changeDsrModalState(true)
														}} />
												</Box>
											</Flex>
										</React.Fragment>
									</Flex>
								}
							</Flex>
						)
						: (
							<Flex className='dsr-content__dsr-top-banner'>
								<Flex padding='1em 0' justifyContent='space-between' width='100%'>
									<Flex align='center'>
										<Flex mr='1em'>
											<DsrManageOrgLogosModule logoDisplayMode={'default-dsr'} isPagePreviewMode={false} />
										</Flex>
										{(currentDsrTitle || showEditTitleInput) ? renderDsrTitle()
											: <Skeleton className="loading-skeleton-bar"><Flex fontSize='1.25em' lineHeight='1.875em' >Sample Text Sample Text Sample Text</Flex></Skeleton>}
									</Flex>
	
									<Flex>
										<Flex justify='center' align='center'>
											<Box><DsrDisplayTeamAvatar perspective='seller' dsrTeamMembers={dsrTeamMembers} /></Box>
											<Box>
												{showGuestMembers && <DsrDisplayTeamAvatar perspective='buyer' dsrTeamMembers={dsrTeamMembers} />}
											</Box>
										</Flex>
										<Box display='flex' alignItems='center' justifyContent='center'>
											<Box px='1em'>
	
												<LaunchdeckButton label='Manage Access' fontSize='0.875em' py='0.4em' px='1em' bg='brand.500' color='white'
													onClick={() => {
	
														if (handleViewTeamMembers) {
															handleViewTeamMembers()
														} else {
															changeDsrPopupState('view-team-members')
															changeDsrModalState(true)
														}
													}} />
											</Box>
										</Box>
									</Flex>
								</Flex>
							</Flex>
						)
					}
				</>
			);
		}
	

	} else {
		if (isSellingTeamMember === true) {
			return (
				<>
					<Flex className='dsr-header__section'>
						<Flex className='dsr-header__container' alignItems='center'>
							<Flex justify='flex-start' align='center'>
								<DsrNavigationDrawerMenu userProfile={userProfile} signOut={signOut} darkMode={true} />
								<Box
									className="page-title"
									fontSize={['1em', '1em', '1.25em', '1.25em']} fontWeight='600' lineHeight='1.85em' color='white'
									display='inline-block' ml='2em'>
									Manage Room
								</Box>
								<Box ml='0.5em'>
									<LaunchdeckTag bg='green.500' color='white' label='Active' />
								</Box>
							</Flex>

							<Flex>


								<React.Fragment>
									<Box>
										<Box rounded='0.25em'>
											<LaunchdeckButton width='100%' bg='brand.500' color='white' onClick={() => {

												if (handleViewTeamMembers) {
													handleViewTeamMembers()
												} else {
													changeDsrPopupState('view-team-members')
													changeDsrModalState(true)
												}
											}} label='Share' />
										</Box>
									</Box>
								</React.Fragment>

							</Flex>
						</Flex>
					</Flex>

					<Flex className='dsr-content__dsr-top-banner'>
						<Box display={['flex', 'flex', 'none', 'none']}>
							<DsrPageSideMenuDrawer />
						</Box>
						<Flex flexDirection={['column', 'row', 'row', 'row']} justifyContent='space-between' width='100%' alignItems='center' p={['0 1em', '1em', '1em', '1em']}>
							<Flex flexDirection={['column', 'row', 'row', 'row']} alignItems='center' >
								<Flex mr='1em'>
									<DsrManageOrgLogosModule logoDisplayMode={'default-dsr'} isPagePreviewMode={isPagePreviewMode} />
								</Flex>
								{currentDsrTitle ?
									<Flex fontWeight='600' fontSize='1.25em' lineHeight='1.875em' justify='flex-start' textAlign='center'>
										{currentDsrTitle}
									</Flex>
									: <Skeleton className="loading-skeleton-bar"><Flex fontSize='1.25em' lineHeight='1.875em' rounded='0.25em'>Sample Text Sample Text Sample Text</Flex></Skeleton>}
							</Flex>

							<Flex>
								<Flex>
									<Box><DsrDisplayTeamAvatar perspective='seller' dsrTeamMembers={dsrTeamMembers} /></Box>
									<Box>	{showGuestMembers &&
										<DsrDisplayTeamAvatar perspective='buyer' dsrTeamMembers={dsrTeamMembers} />
									}</Box>
								</Flex>
							</Flex>
						</Flex>
					</Flex>
				</>
			);
		} else {
			return (
				<>
					<Flex className='dsr-header__section-guest-version'>
						<Flex className='dsr-header__container' alignItems='center'>
							<Flex align='center'>
								{/* // TODO: A design solution is needed for a mobile view drawer to let users choose DSR pages & to go back to Vault (just one option to home page) */}
								<Box mr='1em'>
									<DsrNavigationDrawerMenu userProfile={userProfile} signOut={signOut} darkMode={true} />
								</Box>

								{dsrTitleRedux ?
									<Flex align='center'>
										<Flex mr='1em'>
											<DsrManageOrgLogosModule logoDisplayMode={'default-dsr'} isPagePreviewMode={isPagePreviewMode} />
										</Flex>
										<Flex fontWeight='600' fontSize='1.25em' lineHeight='1.875em' color='white'>{dsrTitleRedux} </Flex>
									</Flex>
									: <Skeleton className="loading-skeleton-bar"><Flex fontSize='1.25em' lineHeight='1.875em'>Sample Text Sample Text Sample Text</Flex></Skeleton>}
							</Flex>

							<Flex align='center'>
								<Flex>
									<Box><DsrDisplayTeamAvatar perspective='seller' dsrTeamMembers={dsrTeamMembers} /></Box>
									<Box>	{showGuestMembers && <DsrDisplayTeamAvatar perspective='buyer' dsrTeamMembers={dsrTeamMembers} />}</Box>
								</Flex>

								{/* // Display only after loading & setting states are completed */}
								{dsrTitleRedux &&
									<LaunchdeckButton noHover bg='brand.500' color='white' onClick={() => {
										if (handleViewTeamMembers) {
											handleViewTeamMembers()
										} else {
											changeDsrPopupState('view-team-members')
											changeDsrModalState(true)
										}
									}} label='Invite members' />
								}
							</Flex>
						</Flex>
					</Flex>
				</>
			);
		}
	}

}

const mapStateToProps = (state) => ({
	dsrTitleRedux: state.dsrTitle.title,
	dsrPropertiesRedux: state.draftDsrProperties.properties,
	isMobileView: state.mobileViewMode.isMobileView,
	userPrivileges: state.userPrivileges.results
})



const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			updateDsr,
			changeDsrPopupState,
			changeDsrModalState,
			signOut
		},
		dispatch,
	)

export default memo(withRouter(connect(mapStateToProps, mapDispatchToProps)(DsrHeader)))

