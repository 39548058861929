import React from 'react'
import { Box, Divider, Flex } from '@chakra-ui/react'
import { getRandomKey } from '../../global/helpers/global-helpers'
// import DsrVideoPostDisplay from '../dsr-content-components/DsrVideoPostDisplay'
import DsrPdfDeckPostDisplay from '../dsr-content-components/DsrPdfDeckPostDisplay'
import DsrFaqDisplay from '../dsr-content-components/dsr-faq/DsrFaqDisplay'
import DsrSignatureDisplay from '../dsr-content-components/DsrSignatureDisplay'
import DsrSlidesDisplay from '../dsr-content-components/DsrSlidesDisplay'
import DsrCustomPageDisplay from '../dsr-content-components/dsr-custom-page/DsrCustomPageDisplay'
import { CustomPageBuilder } from "../dsr-draft/page-designer/CustomPageBuilder";
import LaunchdeckTag from '../../global/components/helper-components/components/LaunchdeckTag'
import { IoCheckmarkCircle, IoCloseCircle } from "react-icons/io5";

export function getEmojiReactionList() {
  return [
    'thumbsUp',
    'thumbsDown'
  ]
}

export function displayEmoji(emojiValue) {
  switch (emojiValue) {
    // case('addReaction'): {
    //   return <Box><span role="img" aria-label="smiley-face">😀</span></Box>
    // }
    case ('thumbsUp'): {
      return <Box><span role="img" aria-label="thumbs-up">👍</span></Box>
    }
    case ('thumbsDown'): {
      return <Box><span role="img" aria-label="thumbs-down">👎</span></Box>
    }
    case ('fire'): {
      return <Box><span role="img" aria-label="fire">🔥</span></Box>
    }
    case ('target'): {
      return <Box><span role="img" aria-label="target">🎯</span></Box>
    }
    case ('moneyBag'): {
      return <Box><span role="img" aria-label="money-bag">💰</span></Box>
    }
    case ('wave'): {
      return <span role="img" aria-label="wave">👋</span>
    }
    case ('celebrate'): {
      return <Box><span role="img" aria-label="celebrate">🎉</span></Box>
    }
    case ('moai'): {
      return <Box><span role="img" aria-label="moai">🗿</span></Box>
    }
    case ('bulb'): {
      return <Box><span role="img" aria-label="bulb">💡</span></Box>
    }
    case ('warning'): {
      return <Box><span role="img" aria-label="warning">⚠️</span></Box>
    }
    case ('cold'): {
      return <Box><span role="img" aria-label="cold">❄️</span></Box>
    }
    case ('think'): {
      return <Box><span role="img" aria-label="think">🤔</span></Box>
    }
    case ('gift'): {
      return <Box><span role="img" aria-label="gift">🎁</span></Box>
    }
    case ('gear'): {
      return <Box><span role="img" aria-label="gear">⚙️</span></Box>
    }
    case ('sparkle'): {
      return <Box><span role="img" aria-label="sparkle">✨</span></Box>
    }
    case ('label'): {
      return <Box><span role="img" aria-label="label">🏷️</span></Box>
    }
    default: return false
  }
}

// UUU Review whether this function is still required
// export function createDsrPost({ inputValue, userName, userProfilePicSrc, userIdToken, postType }) {
//   // Move this function to be handled by backend
//   const newPost = {
//     "postId": getRandomKey(), //Improvement: Update with UUID or hash with msg prefix
//     "postStatus": "active",
//     "postCreatedAt": new Date(),
//     "userId": userIdToken ? userIdToken : "us_ihuhhewyrjn",
//     "userDisplayName": userName ? userName : "John Doe",
//     "userProfilePicSrc": userProfilePicSrc ? userProfilePicSrc : "",
//     "postType": postType ? postType : "short-text",
//     "postContent": inputValue,
//     "postReactions": [],
//     "postReplies": []
//   }

//   // Backend operations
//   // Creating new Q&A question, to add new question to the faqArray
//   return newPost;

//   // If postType is faq-question-post, then to create also a question inside faqArray inside dsr-configuration object in DB
// }

export function updateDsrPost({ dsrMessageContentArray, operation, postId, replyId, fieldsToUpdate, replaceDsrFeedContentAction }) {
  // TODO: If possible, Move this function to be handled by backend
  if (dsrMessageContentArray) {
    switch (operation) {
      case ('update-message-status'): {
        const foundIndex = dsrMessageContentArray && dsrMessageContentArray.findIndex(msg => msg.postId === postId);
        // check whether it is the author, if not, reject and return error
        let updatedPost = {
          ...dsrMessageContentArray[foundIndex],
          ...fieldsToUpdate
        }

        if (replaceDsrFeedContentAction) {
          // dsrMessageContentArray holds redux state slice "dsrFeedContent" so updating it in non-mutable way
          const newDataSet = dsrMessageContentArray.map((post, index) => index === foundIndex ?
            updatedPost : post
          )
          replaceDsrFeedContentAction(newDataSet)
          break;
        } else {
          // old logic, retained for compatibility.
          dsrMessageContentArray[foundIndex] = updatedPost;

          return dsrMessageContentArray; // Array has been updated and returned
        }
      }

      case ('update-deleted-reply-status'): {
        const foundIndex = dsrMessageContentArray && dsrMessageContentArray.findIndex(msg => msg.postId === postId);
        let repliesArray = dsrMessageContentArray[foundIndex].postReplies;
        const replyFoundIndex = repliesArray?.findIndex(reply => reply.replyId === replyId);

        let updatedReply = {
          ...repliesArray[replyFoundIndex],
          replyStatus: "deleted",
          replyDeletedAt: new Date()
        }

        if (replaceDsrFeedContentAction) {
          // dsrMessageContentArray holds redux state slice "dsrFeedContent" so updating it in non-mutable way
          const updatedReplies = repliesArray.map((reply, index) => index === replyFoundIndex ?
            updatedReply : reply
          )

          let updatedPost = {
            ...dsrMessageContentArray[foundIndex],
            postReplies: updatedReplies
          }

          const newDataSet = dsrMessageContentArray.map((post, index) => index === foundIndex ?
            updatedPost : post
          )

          replaceDsrFeedContentAction(newDataSet)
          break;
        } else {
          // old logic, retained for compatibility.
          repliesArray[replyFoundIndex] = updatedReply

          let updatedPost = {
            ...dsrMessageContentArray[foundIndex],
            postReplies: repliesArray
          }

          dsrMessageContentArray[foundIndex] = updatedPost

          return dsrMessageContentArray; // Array has been updated and returned
        }
      }
      default:
        console.log('Error in update dsr post')
    }
  }
}

export function createNewPostReply({ currentPostArray, postId, userIdToken, userName, userProfilePicSrc, replyContent, replaceDsrFeedContentAction }) {

  const messageToUpdate = findPostInArray(currentPostArray, postId)

  if (messageToUpdate) {
    let newReply = {
      "replyId": getRandomKey(), //TODO Review: Update with UUID or hash with rep prefix
      "replyCreatedAt": new Date(),
      "replyUpdatedAt": "",
      "replyStatus": "active",
      "replyUserId": userIdToken ? userIdToken : "us_ihuhhewyrjn",
      "replyUserDisplayName": userName ? userName : "John Doe",
      "replyUserProfilePicSrc": userProfilePicSrc ? userProfilePicSrc : "",
      "replyContent": replyContent,
      "replyReactions": []
    }

    let updatedReplyArray = messageToUpdate ? [...messageToUpdate.postReplies, newReply] : [newReply];

    //Update the message with this new reply
    const foundIndex = currentPostArray && currentPostArray.findIndex(msg => msg.postId === postId)
    let updatedMessageArray = currentPostArray;

    if (replaceDsrFeedContentAction) {
      // currentPostArray holds redux state slice "dsrFeedContent" so updating it in non-mutable way
      const newDataSet = currentPostArray.map((post, index) => index === foundIndex ?
        { ...post, postReplies: updatedReplyArray } : post
      )
      replaceDsrFeedContentAction(newDataSet)
    } else {
      // old logic, retained for compatibility.
      updatedMessageArray[foundIndex].postReplies = updatedReplyArray;


      return updatedMessageArray;
    }
  }
}

export function findPostInArray(dsrMessageContent, postId) {

  const foundMessage = dsrMessageContent && dsrMessageContent.find(message => message.postId === postId);

  if (foundMessage) {
    return foundMessage
  } else {
    console.log('There was no message found. Error code:FEF2$G')
    return {}
  }
}

export function findPageInArray(dsrPages, pageId) {

  const foundDsrPage = dsrPages && dsrPages.find(page => page.pageId === pageId);
  const foundIndex = dsrPages && dsrPages.findIndex(page => page.pageId === pageId);

  if (foundDsrPage) {
    return [foundDsrPage, foundIndex]
  } else {
    console.log('There was no DSR Page found. Error code:GEX1*6')
    return {}
  }
}

export function renderPageContent(pageContent, handleOpenSignupOrLoginModal, isPagePreviewMode) {
  if (pageContent) {
    const renderedPageContent = pageContent?.map((contentBlock, contentBlockIndex) => {
      const { contentBlockId, contentBlockType } = contentBlock || {};

      switch (contentBlockType) {
        case ('signature-content'): {
          return (
            <Box key={`page_content_block_${contentBlockId}_${contentBlockIndex}`} mt='1em'>
              <DsrSignatureDisplay contentBlock={contentBlock} />
            </Box>
          )
        }
        case ('welcome-header-content'): {
          const { contentText } = contentBlock || {};
          return (
            <Box key={`page_content_block_${contentBlockId}_${contentBlockIndex}`} fontSize='3em' color='brandDark.500' mt='1em' mb='0.5em' fontWeight='700'>{contentText}</Box>
          )

        }
        case ('text-content'): {
          const { contentText } = contentBlock || {};
          return (
            <Box key={`page_content_block_${contentBlockId}_${contentBlockIndex}`} mt='2em'>
              <Box lineHeight='1.5em' className="white-space-preline">{contentText}</Box>
            </Box>
          )
        }
        case ('embedded-pdf-content'): {
          return (
            <Box key={`page_content_block_${contentBlockId}_${contentBlockIndex}`} mt='2em'>
              <DsrPdfDeckPostDisplay contentBlock={contentBlock} />
            </Box>
          )
        }
        case ('embedded-slide-deck'): {
          return (
            <Box key={`page_content_block_${contentBlockId}_${contentBlockIndex}`} mt='2em'>
              <DsrSlidesDisplay contentBlock={contentBlock} />
            </Box>
          )

        }
        case ('faq-content'): {
          return (
            <Box key={`page_content_block_${contentBlockId}_${contentBlockIndex}`} mt='1em'>
              <DsrFaqDisplay
                handleOpenSignupOrLoginModal={handleOpenSignupOrLoginModal}
                isPagePreviewMode={isPagePreviewMode}
                contentBlock={contentBlock}
              // dsrTeamMembers={dsrTeamMembers}
              // refreshData={refreshData}
              // displayedPostContent={dsrMessageContent}
              />
            </Box>
          )
        }
        // case ('customer-reference-content'): {
        //   return (
        //     <Box key={`page_content_block_${contentBlockId}_${contentBlockIndex}`} mt='2em'>
        //       <DsrCustomerReferenceDisplay contentBlock={contentBlock} />
        //     </Box>
        //   )
        // }
        case ('spacer-no-content'): {
          return (
            <Box key={`page_content_block_${contentBlockId}_${contentBlockIndex}`} >
              <Divider my='2em' borderColor='gray.500' />
            </Box>
          )
        }
        case ('custom_one_column'):
        case ('custom_two_column'):
        case ('custom_three_column'): {
          return (
            <Box key={`page_content_block_${contentBlockId}_${contentBlockIndex}`} mt='2em'>
              <DsrCustomPageDisplay contentBlock={contentBlock} />
            </Box>
          )
        }
        default: {
          console.log('There is no such content block type:', contentBlockType)
          return null;
        }
      }
    })
    return renderedPageContent
  }

}

export function renderDraftEmptyCustomisedPage(updateDsrPages, page) {
  return (
    <Box key={`page_content_block_${page.pageId}`}>
      <CustomPageBuilder updateDsrPages={updateDsrPages} page={page} />
    </Box>
  )
}

export function reorderPinnedContentFirst(contentArray) {
  // Clone array to preserve original array
  let cloneArr = [...contentArray]
  const foundIndex = cloneArr && cloneArr.findIndex(content => content.referenceType === 'pinned');

  if (foundIndex > 0) {
    let pinnedContent = cloneArr.splice(foundIndex, 1);
    let newContentArray = [...pinnedContent, ...cloneArr]

    return newContentArray;
  } else {
    return contentArray;
  }

}

export function replaceWithUpdatedPageElement(originalArray, newPageElement) {

  if (originalArray && newPageElement) {
    let cloneArr = [...originalArray]
    const { pageId } = newPageElement;
    const foundIndex = cloneArr.findIndex(page => page.pageId === pageId);

    if (foundIndex > 0) {
      cloneArr[foundIndex] = newPageElement;
      // let pinnedContent = cloneArr.splice(foundIndex, 1);

      return cloneArr;
    } else {
      return originalArray;
    }
  }


}

// UUU Move this to backend and be returned as a value? 
export function checkAuthorizationToEditTeamRole(authorUserRole, targetUserRole) {
  switch (authorUserRole) {
    case ('seller-lead'): {
      return true
    }
    case ('seller-member'): {
      if (targetUserRole === 'buyer-member') {
        return true
      } else return false
    }
    case ('buyer-member'): {
      return false
    }
    default: {
      return false
    }
  }

}

export function isProfileCompleted(user) {
  const { firstName, lastName, email } = user || {};
  const checkOutcome = (firstName && lastName && email) ? true : false;

  return checkOutcome;
}

export function findPageIdByPageType(dsrPages, pageType) {
  const foundPage = dsrPages?.find(page => page.pageType === pageType);
  if (foundPage) {
    return { pageId: foundPage.pageId, dsrPage: foundPage };
  } else return ""
}

export function findActionItemInCategoryArray(actionListArray, mapCategoryId, actionId) {
  let foundActionItem = null;
  if (actionListArray && mapCategoryId && actionId) {
    const foundCategoryIndex = actionListArray?.findIndex(category => category.mapCategoryId === mapCategoryId)

    if (foundCategoryIndex >= 0) {
      const { mapCategoryActionItemList } = actionListArray[foundCategoryIndex] || {};

      if (!!mapCategoryActionItemList) {
        const foundActionItemIndex = mapCategoryActionItemList?.findIndex(action => action.actionId === actionId)

        if (foundActionItemIndex >= 0) {
          foundActionItem = mapCategoryActionItemList[foundActionItemIndex]
        }
      }

    }
  }

  return foundActionItem;
}

export function displayUserTeamRolePermissions({dsrUserTeam, teamInput, chosenRole, isMobileView}) {

  if ((dsrUserTeam === 'host') && (teamInput === 'buyer-team')) {
    return (
      <Box bg='white' p='1em' mt='1em' rounded='0.25em' border='1px solid' borderColor='gray.300'>
        <Box pl='1em' my='0.5em'>
          <LaunchdeckTag bg='gray.600' color='white' label='GUEST MEMBER PERMISSIONS' />
        </Box>
        <Flex direction={isMobileView && "column"} mt='2em' mb={isMobileView ? '0em' : '1em'} color='gray.600' fontSize='0.875em' px='1em' align={isMobileView ? 'flex-start' :'center'}>
          <Flex flex='1' justify='flex-start' align='center' color='brandDark.500'>
            <Box>
              <IoCheckmarkCircle />
            </Box>
            <Box ml='0.5em'>
              Invite Guest Members
            </Box>
          </Flex>
          <Flex flex='1' justify='flex-start' align='center' color='brandPink.500' opacity='0.8'>
            <Box>
              <IoCloseCircle />
            </Box>
            <Box ml='0.5em'>
              Invite Host Admins / Members
            </Box>
          </Flex>
        </Flex>

        <Flex direction={isMobileView && "column"} mb='1em' mt={isMobileView ? '0em' : '1em'} color='gray.600' fontSize='0.875em' px='1em' align={isMobileView ? 'flex-start' :'center'}>
          <Flex flex='1' justify='flex-start' align='center' color='brandPink.500' opacity='0.8'>
            <Box>
              <IoCloseCircle />
            </Box>
            <Box ml='0.5em'>
              View Room notes & stats
            </Box>
          </Flex>
          <Flex flex='1' justify='flex-start' align='center' color='brandPink.500' opacity='0.8'>
            <Box>
              <IoCloseCircle />
            </Box>
            <Box ml='0.5em'>
              Change Room content
            </Box>
          </Flex>
        </Flex>
      </Box>
    )
  } else if ((dsrUserTeam === 'host') && teamInput === 'seller-team' && chosenRole === "Member") {
    return (
      <Box bg='white' p='1em' mt='1em' rounded='0.25em' border='1px solid' borderColor='gray.300'>
        <Box pl='1em' my='0.5em'>
          <LaunchdeckTag bg='gray.600' color='white' label='HOST MEMBER PERMISSIONS' />
        </Box>
        <Flex direction={isMobileView && "column"} mt='2em' mb={isMobileView ? '0em' : '1em'} color='gray.600' fontSize='0.875em' px='1em' align={isMobileView ? 'flex-start' :'center'}>
        <Flex flex='1' justify='flex-start' align='center' color='brandDark.500'>
            <Box>
              <IoCheckmarkCircle />
            </Box>
            <Box ml='0.5em'>
              Invite Guest Members
            </Box>
          </Flex>
          <Flex flex='1' justify='flex-start' align='center' color='brandDark.500'>
            <Box>
              <IoCheckmarkCircle />
            </Box>
            <Box ml='0.5em'>
              Invite other Host Members
            </Box>
          </Flex>
        </Flex>

        <Flex direction={isMobileView && "column"} mb='1em' mt={isMobileView ? '0em' : '1em'} color='gray.600' fontSize='0.875em' px='1em' align={isMobileView ? 'flex-start' :'center'}>
          <Flex flex='1' justify='flex-start' align='center' color='brandPink.500' opacity='0.8'>
            <Box>
              <IoCloseCircle />
            </Box>
            <Box ml='0.5em'>
              View Room notes & stats
            </Box>
          </Flex>
          <Flex flex='1' justify='flex-start' align='center' color='brandPink.500' opacity='0.8'>
            <Box>
              <IoCloseCircle />
            </Box>
            <Box ml='0.5em'>
              Change Room content
            </Box>
          </Flex>
        </Flex>
      </Box>
    )
  } else if ((dsrUserTeam === 'host') && teamInput === 'seller-team' && chosenRole === "Admin") {
    return (
      <Box bg='white' p='1em' mt='1em' rounded='0.25em' border='1px solid' borderColor='gray.300'>
        <Box pl='1em' my='0.5em'>
          <LaunchdeckTag bg='gray.600' color='white' label='HOST ADMIMISTRATOR PERMISSIONS' />
        </Box>
        <Flex direction={isMobileView && "column"} mt='2em' mb={isMobileView ? '0em' : '1em'} color='gray.600' fontSize='0.875em' px='1em' align={isMobileView ? 'flex-start' :'center'}>
        <Flex flex='1' justify='flex-start' align='center' color='brandDark.500'>
            <Box>
              <IoCheckmarkCircle />
            </Box>
            <Box ml='0.5em'>
              Invite Guest Members
            </Box>
          </Flex>
          <Flex flex='1' justify='flex-start' align='center' color='brandDark.500'>
            <Box>
              <IoCheckmarkCircle />
            </Box>
            <Box ml='0.5em'>
              Invite Host Admins / Members
            </Box>
          </Flex>
        </Flex>

        <Flex direction={isMobileView && "column"} mb='1em' mt={isMobileView ? '0em' : '1em'} color='gray.600' fontSize='0.875em' px='1em' align={isMobileView ? 'flex-start' :'center'}>
          <Flex flex='1' justify='flex-start' align='center' color='brandDark.500'>
            <Box>
              <IoCheckmarkCircle />
            </Box>
            <Box ml='0.5em'>
              View Room notes & stats
            </Box>
          </Flex>
          <Flex flex='1' justify='flex-start' align='center' color='brandDark.500'>
            <Box>
              <IoCheckmarkCircle />
            </Box>
            <Box ml='0.5em'>
              Change Room content
            </Box>
          </Flex>
        </Flex>
      </Box>
    )
  } else {
    return null
  }
}

export function getPrettyDocumentName(fileUrl) {
  const regex = /\/([^\/]+)\.[^\/]+$/;

  const match = fileUrl.match(regex);
  const documentName = match ? decodeURIComponent(match[1]) : "";

  return documentName;
}

export function backwardCompatibleTiles(omittedObjects) {
  let backwardCompatibilitySettings = {};
   
  Array.isArray(omittedObjects) && omittedObjects.map(item => {
    switch (item) {
      case ("intro-tile"): {
        backwardCompatibilitySettings= {
          ...backwardCompatibilitySettings,
          introTileDisplay: false
        }
    
        break;
      }
      case ("contact-tile"): {
        backwardCompatibilitySettings= {
          ...backwardCompatibilitySettings,
          contactTileDisplay: false
        }
        break;
      }
      case ("notifications-tile"): {
        backwardCompatibilitySettings= {
          ...backwardCompatibilitySettings,
          notificationsTileDisplay: false
        }
        break;
      }
      case ("room-members-tile"): {
        backwardCompatibilitySettings= {
          ...backwardCompatibilitySettings,
          membersTileDisplay: false
        }
        break;
      }
      case ("mutual-plan-tile"): {
        backwardCompatibilitySettings= {
          ...backwardCompatibilitySettings,
          planTileDisplay: false
        }
        break;
      }
      case ("free-text-tile"): {
        backwardCompatibilitySettings= {
          ...backwardCompatibilitySettings,
          freeTextTileDisplay: false
        }
        break;
      }
      case ("shared-doc-tile"): {
        backwardCompatibilitySettings= {
          ...backwardCompatibilitySettings,
          sharedDocTileDisplay: false
        }
        break;
      }
      default: {
        
        break
      }


    }

    return null
  }
  )
 

  return backwardCompatibilitySettings
}