import * as types from '../actions/dsr-actions-types';


// Get DSR Config
const INITIAL_STATE_GET_DSR_CONFIG = {
	isLoading: false,
	results: {},
	error: '',
};

export function getDsrConfigByDsrIdReducer(state = INITIAL_STATE_GET_DSR_CONFIG, action) {
	switch (action.type) {
		case types.GET_DSR_CONFIG_REQUEST:
			return { ...state, isLoading: true, results: {} };
		case types.GET_DSR_CONFIG_SUCCESS:
			return { ...state, isLoading: false, results: action.payload };
		case types.GET_DSR_CONFIG_FAILURE:
			return { ...state, isLoading: false, error: 'Error while trying to fetch dsr configurations' };
		default:
			return state;
	}
}


/////
// TEAM MEMBER MGMT REDUCERS
/////
// Get outcome of editing Team Members (includes adding or removing)
const INITIAL_STATE_EDIT_DSR_TEAM_MEMBERS = {
	isLoading: false,
	results: {},
	error: '',
};


export function editDsrTeamMembersReducer(state = INITIAL_STATE_EDIT_DSR_TEAM_MEMBERS, action) {
	switch (action.type) {
		case types.EDIT_DSR_TEAM_MEMBERS_RESET:
			return INITIAL_STATE_EDIT_DSR_TEAM_MEMBERS;
		case types.EDIT_DSR_TEAM_MEMBERS_REQUEST:
			return { ...state, isLoading: true, results: {} };
		case types.EDIT_DSR_TEAM_MEMBERS_SUCCESS:
			return { ...state, isLoading: false, results: action.payload };
		case types.EDIT_DSR_TEAM_MEMBERS_FAILURE:
			return { ...state, isLoading: false, error: 'Error while trying to edit dsr team members' };
		default:
			return state;
	}
}

// Get outcome of editing a specific team member's role, within a specific DSR
const INITIAL_STATE_EDIT_DSR_MEMBER_ROLE = {
	isLoading: false,
	results: {},
	error: '',
};


export function editDsrMemberRoleReducer(state = INITIAL_STATE_EDIT_DSR_MEMBER_ROLE, action) {
	switch (action.type) {
		case types.EDIT_DSR_MEMBER_ROLE_RESET:
			return INITIAL_STATE_EDIT_DSR_MEMBER_ROLE
		case types.EDIT_DSR_MEMBER_ROLE_REQUEST:
			return { ...state, isLoading: true, results: {} };
		case types.EDIT_DSR_MEMBER_ROLE_SUCCESS:
			return { ...state, isLoading: false, results: action.payload };
		case types.EDIT_DSR_MEMBER_ROLE_FAILURE:
			return { ...state, isLoading: false, error: 'Error while trying to edit team member role' };
		default:
			return state;
	}
}

/////// ACTION LIST management
// Get DSR Action List
const INITIAL_STATE_GET_DSR_ACTION_LIST = {
	isLoading: false,
	results: {},
	error: '',
};

export function getDsrActionListByDsrIdReducer(state = INITIAL_STATE_GET_DSR_ACTION_LIST, action) {
	switch (action.type) {
		case types.GET_DSR_ACTION_LIST_REQUEST:
			return { ...state, isLoading: true, results: {} };
		case types.GET_DSR_ACTION_LIST_SUCCESS:
			return { ...state, isLoading: false, results: action.payload };
		case types.GET_DSR_ACTION_LIST_FAILURE:
			return { ...state, isLoading: false, error: 'Error while trying to get dsr action list' };
    case types.UPDATE_REDUX_DSR_ACTION_LIST:
      return { ...state, isLoading: false, results: { ...state.results, data: action.payload } };
		default:
			return state;
	}
}

// Create New Action : createNewAction
const INITIAL_STATE_CREATE_DSR_ACTION = {
	isLoading: false,
	results: {},
	error: '',
};

export function createNewDsrActionReducer(state = INITIAL_STATE_CREATE_DSR_ACTION, action) {
	switch (action.type) {
		case types.CREATE_NEW_DSR_ACTION_RESET:
			return INITIAL_STATE_CREATE_DSR_ACTION
		case types.CREATE_NEW_DSR_ACTION_REQUEST:
			return { ...state, isLoading: true, results: {} };
		case types.CREATE_NEW_DSR_ACTION_SUCCESS:
			return { ...state, isLoading: false, results: action.payload };
		case types.CREATE_NEW_DSR_ACTION_FAILURE:
			return { ...state, isLoading: false, error: 'Error while trying to create new dsr action' };
		default:
			return state;
	}
}


// Update action: updateDsrAction
const INITIAL_STATE_UPDATE_DSR_ACTION = {
	isLoading: false,
	results: {},
	error: '',
};

export function updateDsrActionReducer(state = INITIAL_STATE_UPDATE_DSR_ACTION, action) {
	switch (action.type) {
		case types.UPDATE_DSR_ACTION_RESET:
			return INITIAL_STATE_UPDATE_DSR_ACTION;
		case types.UPDATE_DSR_ACTION_REQUEST:
			return { ...state, isLoading: true, results: {} };
		case types.UPDATE_DSR_ACTION_SUCCESS:
			return { ...state, isLoading: false, results: action.payload };
		case types.UPDATE_DSR_ACTION_FAILURE:
			return { ...state, isLoading: false, error: 'Error while trying to update dsr action' };
		default:
			return state;
	}
}


/////// DSR management - Create, Edit, Delete
const INITIAL_STATE_CREATE_NEW_DSR = {
	isLoading: false,
	results: {},
	error: '',
};

export function createNewDsrReducer(state = INITIAL_STATE_CREATE_NEW_DSR, action) {
	switch (action.type) {
		case types.CREATE_NEW_DSR_REQUEST:
			return { ...state, isLoading: true, results: {} };
		case types.CREATE_NEW_DSR_SUCCESS:
			return { ...state, isLoading: false, results: action.payload };
		case types.CREATE_NEW_DSR_FAILURE:
			return { ...state, isLoading: false, error: 'Error while trying to create new dsr' };
		default:
			return state;
	}
}

const INITIAL_STATE_CREATE_DUPLICATE_DSR = {
	isLoading: false,
	results: {},
	error: '',
};

export function createDuplicateDsrReducer(state = INITIAL_STATE_CREATE_DUPLICATE_DSR, action) {
	switch (action.type) {
		case types.CREATE_DUPLICATE_DSR_REQUEST:
			return { ...state, isLoading: true, results: {} };
		case types.CREATE_DUPLICATE_DSR_SUCCESS:
			return { ...state, isLoading: false, results: action.payload };
		case types.CREATE_DUPLICATE_DSR_FAILURE:
			return { ...state, isLoading: false, error: 'Error while trying to duplicate dsr' };
		default:
			return state;
	}
}


const INITIAL_STATE_UPDATE_DSR = {
	isLoading: false,
	results: {},
	error: '',
};


export function updateDsrReducer(state = INITIAL_STATE_UPDATE_DSR, action) {
	switch (action.type) {
		case types.UPDATE_DSR_RESET:
			return INITIAL_STATE_UPDATE_DSR;
		case types.UPDATE_DSR_REQUEST:
			return { ...state, isLoading: true, results: {} };
		case types.UPDATE_DSR_SUCCESS:
			return { ...state, isLoading: false, results: action.payload };
		case types.UPDATE_DSR_FAILURE:
			return { ...state, isLoading: false, error: 'Error while trying to update dsr' };
		default:
			return state;
	}
}

// Reducer for getting Prospecting DSRs
const INITIAL_STATE_GET_DSR_PROSPECT = {
	isLoading: false,
	results: {},
	error: '',
};


export function getDsrProspectReducer(state = INITIAL_STATE_GET_DSR_PROSPECT, action) {
	switch (action.type) {
		case types.GET_PROSPECT_DSR_REQUEST:
			return { ...state, isLoading: true, results: {} };
		case types.GET_PROSPECT_DSR_SUCCESS:
			return { ...state, isLoading: false, results: action.payload };
		case types.GET_PROSPECT_DSR_FAILURE:
			return { ...state, isLoading: false, error: 'Error while trying to get prospect dsr' };
		default:
			return state;
	}
}

//Uploading files Reducer
const INITIAL_STATE_UPLOAD_FILES = {
	isLoading: false,
	results: {},
	error: '',
};

export function uploadFilesReducer(state = INITIAL_STATE_UPLOAD_FILES, action) {
	switch (action.type) {
		case types.UPLOAD_FILES_REQUEST:
			return { ...state, isLoading: true, results: {} };
		case types.UPLOAD_FILES_SUCCESS:
			return { ...state, isLoading: false, results: action.payload };
		case types.UPLOAD_FILES_FAILURE:
			return { ...state, isLoading: false, error: 'Error while trying to upload files' };
		case types.UPLOAD_FILES_RESET:
			return INITIAL_STATE_UPLOAD_FILES;
		default:
			return state;
	}
}

//Delete files Reducer
const INITIAL_STATE_DELETE_FILES = {
  isLoading: false,
  results: {},
  error: '',
};

export function deleteFilesReducer(state = INITIAL_STATE_DELETE_FILES, action) {
  switch (action.type) {
    case types.DELETE_FILES_REQUEST:
      return { ...state, isLoading: true, results: {} };
    case types.DELETE_FILES_SUCCESS:
      return { ...state, isLoading: false, results: action.payload };
    case types.DELETE_FILES_FAILURE:
      return { ...state, isLoading: false, error: 'Error while trying to delete files' };
    case types.DELETE_FILES_RESET:
      return INITIAL_STATE_DELETE_FILES;
    default:
      return state;
  }
}


/////
// DSR Q&A REDUCERS
/////

// Reducer for creating new Q&A Answer
const INITIAL_STATE_CREATE_FAQ_ANSWER = {
	isLoading: false,
	results: {},
	error: '',
};


export function createFaqAnswerReducer(state = INITIAL_STATE_CREATE_FAQ_ANSWER, action) {
	switch (action.type) {
		case types.CREATE_FAQ_ANSWER_REQUEST:
			return { ...state, isLoading: true, results: {} };
		case types.CREATE_FAQ_ANSWER_SUCCESS:
			return { ...state, isLoading: false, results: action.payload };
		case types.CREATE_FAQ_ANSWER_FAILURE:
			return { ...state, isLoading: false, error: 'Error while trying to create Q&A answer' };
    case types.CREATE_FAQ_ANSWER_RESET:
      return INITIAL_STATE_CREATE_FAQ_ANSWER;
		default:
			return state;
	}
}

// Reducer for editing Q&A Answer
const INITIAL_STATE_EDIT_FAQ_ANSWER = {
	isLoading: false,
	results: {},
	error: '',
};


export function editFaqAnswerReducer(state = INITIAL_STATE_EDIT_FAQ_ANSWER, action) {
	switch (action.type) {
		case types.EDIT_FAQ_ANSWER_REQUEST:
			return { ...state, isLoading: true, results: {} };
		case types.EDIT_FAQ_ANSWER_SUCCESS:
			return { ...state, isLoading: false, results: action.payload };
		case types.EDIT_FAQ_ANSWER_FAILURE:
			return { ...state, isLoading: false, error: 'Error while trying to edit Q&A answer' };
    case types.EDIT_FAQ_ANSWER_RESET:
      return INITIAL_STATE_EDIT_FAQ_ANSWER;
		default:
			return state;
	}
}

//Reducer for deleting Q&A answer
const INITIAL_STATE_DELETE_FAQ_ANSWER = {
	isLoading: false,
	results: {},
	error: '',
};


export function deleteFaqAnswerReducer(state = INITIAL_STATE_DELETE_FAQ_ANSWER, action) {
	switch (action.type) {
		case types.DELETE_FAQ_ANSWER_RESET:
			return INITIAL_STATE_DELETE_FAQ_ANSWER;
		case types.DELETE_FAQ_ANSWER_REQUEST:
			return { ...state, isLoading: true, results: {} };
		case types.DELETE_FAQ_ANSWER_SUCCESS:
			return { ...state, isLoading: false, results: action.payload };
		case types.DELETE_FAQ_ANSWER_FAILURE:
			return { ...state, isLoading: false, error: 'Error while trying to delete Q&A answer' };
		default:
			return state;
	}
}

// Reducer for creating new Q&A Question
const INITIAL_STATE_CREATE_FAQ_QUESTION = {
	isLoading: false,
	results: {},
	error: '',
};


export function createFaqQuestionReducer(state = INITIAL_STATE_CREATE_FAQ_QUESTION, action) {
	switch (action.type) {
		case types.CREATE_FAQ_QUESTION_RESET:
			return INITIAL_STATE_CREATE_FAQ_QUESTION;
		case types.CREATE_FAQ_QUESTION_REQUEST:
			return { ...state, isLoading: true, results: {} };
		case types.CREATE_FAQ_QUESTION_SUCCESS:
			return { ...state, isLoading: false, results: action.payload };
		case types.CREATE_FAQ_QUESTION_FAILURE:
			return { ...state, isLoading: false, error: 'Error while trying to create Q&A question' };
		default:
			return state;
	}
}

// Reducer for editing Q&A Question
const INITIAL_STATE_EDIT_FAQ_QUESTION = {
	isLoading: false,
	results: {},
	error: '',
};


export function editFaqQuestionReducer(state = INITIAL_STATE_EDIT_FAQ_QUESTION, action) {
	switch (action.type) {
		case types.EDIT_FAQ_QUESTION_RESET:
			return INITIAL_STATE_EDIT_FAQ_QUESTION;
		case types.EDIT_FAQ_QUESTION_REQUEST:
			return { ...state, isLoading: true, results: {} };
		case types.EDIT_FAQ_QUESTION_SUCCESS:
			return { ...state, isLoading: false, results: action.payload };
		case types.EDIT_FAQ_QUESTION_FAILURE:
			return { ...state, isLoading: false, error: 'Error while trying to edit Q&A question' };
		default:
			return state;
	}
}

// Reducer for deleting Q&A Question
const INITIAL_STATE_DELETE_FAQ_QUESTION = {
	isLoading: false,
	results: {},
	error: '',
};


export function deleteFaqQuestionReducer(state = INITIAL_STATE_DELETE_FAQ_QUESTION, action) {
	switch (action.type) {
		case types.DELETE_FAQ_QUESTION_RESET:
			return INITIAL_STATE_DELETE_FAQ_QUESTION;
		case types.DELETE_FAQ_QUESTION_REQUEST:
			return { ...state, isLoading: true, results: {} };
		case types.DELETE_FAQ_QUESTION_SUCCESS:
			return { ...state, isLoading: false, results: action.payload };
		case types.DELETE_FAQ_QUESTION_FAILURE:
			return { ...state, isLoading: false, error: 'Error while trying to delete Q&A question' };
		default:
			return state;
	}
}