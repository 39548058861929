import React, { useState, useEffect, memo } from 'react';

import { connect } from 'react-redux'
import { useHistory, withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux';

import { Box, Flex, Modal, ModalOverlay, ModalBody, ModalContent, Avatar, useToast } from '@chakra-ui/react';
import PageLoader from '../helper-components/components/PageLoader.jsx';
import LaunchdeckCloseButton from '../helper-components/components/LaunchdeckCloseButton.jsx';
import LaunchdeckButton from '../helper-components/components/LaunchdeckButton.jsx';
import LaunchdeckFormInput from '../../../forms/TextInputField/components/LaunchdeckFormInput.jsx';
import { MdWorkOutline } from "react-icons/md";
import WarningTextMessage from '../helper-components/components/WarningTextMessage.jsx';
import { selfOnboardDemoUser } from '../../../new-user-experience/actions/onboarding-actions.js';
import { triggerLaunchdeckToast } from '../../helpers/global-helpers.js';

function SwitchDemoToOrgAdminUserModal(props) {
  const {
    handleCloseModal,
    // Actions
    selfOnboardDemoUser,
    // Redux states
    userAccountProfile,
    onboardingOutcome,
    onboardingInProgress,
    isMobileView
  } = props || {};

  const history = useHistory();
  const toast = useToast();
  // Data
  const [userProfilePic, setUserProfilePic] = useState("")
  const [userNameDisplay, setUserNameDisplay] = useState("")
  // UX
  const [showError, setShowError] = useState(false);
  // Input
  const [orgNameInput, setOrgNameInput] = useState("");
  const [orgTitleInput, setOrgTitleInput] = useState("");


  useEffect(() => {
    if (userAccountProfile) {
      const { firstName, lastName, profilePicture } = userAccountProfile || {};
      setUserNameDisplay(firstName + " " + lastName)
      profilePicture && setUserProfilePic(profilePicture)
    }
  }, [userAccountProfile])

  useEffect(() => {
    if (onboardingOutcome) {
      const { selfOnboardedSuccess } = onboardingOutcome || {};

      if (!!selfOnboardedSuccess) {
        history.push(`/home`, {
          selfOnboardedSuccess
        })
      } else {
        toast(
          triggerLaunchdeckToast({
            useCase: "show-error-state",
            label: "Unfortunately we are unable to switch your account automatically right now. A member of staff will fix the issue and get back to you as quickly as we can.",
            isMobileView
          })
        )
      }
    }
  }, [onboardingOutcome])




  const handleSwitchView = () => {
    if (orgNameInput.trim() && orgTitleInput.trim()) {
      setShowError(false)
      selfOnboardDemoUser({
        demoUserOrgName: orgNameInput,
        demoUserOrgTitle: orgTitleInput
      })
    } else {
      setShowError(true)
    }
  }


  const handleClickModalCloseButton = () => {
    handleCloseModal()
  }

  const renderContent = () => {
    if (onboardingInProgress) {
      return <PageLoader />
    } else {
      return (<React.Fragment>
        <Flex w='100%' justify='center' mt='1em'>
          <Box as={MdWorkOutline} boxSize="3em" color="brand.500" />
        </Flex>
        <Flex fontSize='1em' color='brandDark.500' fontWeight='600' mt='1em' textAlign='center'>
          Create your organization's identity
        </Flex>

        <Flex fontSize='0.875em' color='gray.500' mb='1em' textAlign='center'>
          Help your clients know who they're engaging with
        </Flex>

        <Box w="100%" align="center">

          <Flex w='80%' minW='30em' direction='column' align='center' mb='2em' px='2em'>

            <Flex w='100%' my='1em'>

              <Flex justify='center' w='100%'>
                <Flex p='1em' justify='center' px='3em' rounded='0.25em' bg='gray.50' border='1px solid' borderColor='gray.300' mb={'2em'}>
                  <Flex w='100%' justify='center' align='center' gap={4}>
                    <Avatar h="3.8em" w="3.8em" name={userNameDisplay} bg='gray.100' color='gray.700' src={userProfilePic} />

                    <Box>
                      <Flex fontSize='0.875em' color='brandDark.500' justify='flex-start' className="truncate-long-text" maxW='20em'>{userNameDisplay} </Flex>
                      <Flex fontSize='0.8em' color='gray.500'>
                        {(orgTitleInput && orgNameInput) ? orgTitleInput + ', ' + orgNameInput : orgNameInput ? orgNameInput : orgTitleInput}
                      </Flex>
                    </Box>
                  </Flex>

                </Flex>
              </Flex>
            </Flex>

            <Flex w='100%'>
              <LaunchdeckFormInput
                name="demoOrgNameInput"
                onChange={(e) => setOrgNameInput(e.target.value)}
                // placeholder='e.g. Acme Inc'
                value={orgNameInput}
                label="Organization Name"
              />
            </Flex>
            <Flex w='100%' mt='1em' mb='2em'>
              <LaunchdeckFormInput
                name="demoOrgTitleInput"
                onChange={(e) => setOrgTitleInput(e.target.value)}
                // placeholder='e.g. Manager, Senior Director'
                value={orgTitleInput}
                label="Position Title"
              />
            </Flex>
          </Flex>


          <WarningTextMessage errorLogic={showError} message={`Please provide your organization's name and your title`} />
          <Flex justify='center' px='1em' py='0.625em' mb='1em'>
            <LaunchdeckButton bg='yellow.400' color='brandDark.500' px='5em' label='Switch to Sales team view' onClick={() => handleSwitchView()} />
          </Flex>

        </Box>
      </React.Fragment>)

    }
  }




  const displayContent = () => {
    return (
      <Box>
        <Flex direction='column' mt='1em' justify='center' alignItems='center' px={isMobileView ? '1em' : '0.5em'} w='100%'>
          {renderContent()}
        </Flex>
      </Box>
    )
  }


  return (
    <Modal isOpen={true} closeOnOverlayClick={false} isCentered={true} >
      <ModalOverlay className="modal-background" />
      <ModalContent rounded='0.25em' minW={['90%', '500px']} maxW='700px'>
        <ModalBody px='0em'>
          <Flex direction='column' justify='space-around' alignItems='center' w='100%' p='1em'>
            <Flex justify='flex-end' w='100%'>

              <Box pr={isMobileView ? '0em' : '1.5em'}>
                <LaunchdeckCloseButton handleCloseModal={() => handleClickModalCloseButton()} />
              </Box>
            </Flex>

            <Box my='1em' border={!onboardingInProgress && '1px solid'} borderColor={!onboardingInProgress && 'gray.100'} rounded='0.25em'>
              {displayContent()}
            </Box>

          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}


const mapStateToProps = (state) => ({
  onboardingOutcome: state.onboardingOutcome.results.data,
  onboardingInProgress: state.onboardingOutcome.isLoading,
  isMobileView: state.mobileViewMode.isMobileView,
  userAccountProfile: state.userProfile.accountUserProfile,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      selfOnboardDemoUser
    },
    dispatch,
  )


export default memo(withRouter(connect(mapStateToProps, mapDispatchToProps)(SwitchDemoToOrgAdminUserModal)))
