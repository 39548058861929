import React, { useEffect, useRef, useState } from "react";
import { Alert, AlertIcon, Box, Flex } from "@chakra-ui/react";
import { RiVideoFill } from 'react-icons/ri';
import './image-uploader.css'
import config from "../../../config/app-config";



const DsrVideoUpload = ({ getFiles, setShowDismissButton }) => {
  const fileInputRef = useRef(null)
  const [selectedFile, setSelectedFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const handleFileInputChange = (e) => {
    if (e.target.files[0]) {
      const file = e.target.files[0];
      const { maxVideoFileSize } = config; 
      
      if (file && file.size > maxVideoFileSize) {
        // File size exceeds the limit
        setErrorMessage("We support the display of video files that are 50 MB or below. To display larger file sizes, we recommend embedding Youtube videos, or use a button that leads to where your videos are hosted (e.g. Google Drive)");
        e.target.value = null;
      } else {
        setShowDismissButton(true)
        setSelectedFile(file);
        let files = [];
        files.push(file)
        getFiles(files, "uploaded-video")
      }

    }
  };
  useEffect(() => {
    if (errorMessage) {
      const timer = setTimeout(() => {
        setErrorMessage(null);
      }, 5000); // Hide the error message after 5 seconds

      return () => {
        clearTimeout(timer);
      };
    }
  }, [errorMessage]);

  // const handleRemoveFile = () => {
  //   setSelectedFile(null);
  //   fileInputRef.current.value = "";
  // };

  return (
    <Box className="video-uploader">
      <Box className="video-preview">
        {selectedFile ? (
          <div className="video-upload-container">
            <video preload="none" controls>
              <source src={`${URL.createObjectURL(selectedFile)}`} type="video/mp4" />
              Your browser does not support playing this video
            </video>
          </div>
        ) : (
          <Flex ref={fileInputRef} justifyContent="center" alignItems="center" direction="column" mt="10px" minH='12.5em' p='5px' m='5px'>
          <Box>
            <label className="file-input" style={{ color: '#29ABE2' }}>
              <input
                type="file"
                accept="video/mp4,video/avi,video/mpeg,video/webm,video/x-matroska"
                style={{ display: 'none' }}
                onChange={handleFileInputChange}
              />
              <Box as={RiVideoFill} boxSize="1.2em" color='brand.500' />
              <Box ml='0.5em'> Select video file</Box>
  
            </label>
          </Box>
          <Flex direction='column' my='1.5em' fontSize='0.8em' color='brandDark.500' justify='center' align='center'>
            <Box>
              Supported Formats: MP4, AVI, MPEG, MKV and WEBM
            </Box>
            <Box mt='0.2em'>
              Upload limit: 50MB
            </Box>
          </Flex>
        </Flex>
        )}
      </Box>
     

      <Box justifyContent="center" marginTop="15px" className="fade-in">
        {/* Error message */}
        {errorMessage && (
          <Alert px='1em' status="error" variant="solid" bg='brandPink.500' rounded='0.25em' color='white'>
            <AlertIcon boxSize='1em' />
            <Box fontSize='0.875em'>  {errorMessage}</Box>
          </Alert>
        )}
      </Box>

    </Box>
  );
};

export default DsrVideoUpload;

