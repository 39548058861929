import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Box, Flex } from '@chakra-ui/react';
import ClaverModal from '../../../global/components/helper-components/components/ClaverModal';
import { deleteAccount } from '../../../auth/actions/auth-actions';
import LaunchdeckButton from '../../../global/components/helper-components/components/LaunchdeckButton';
import { generateRandomString } from '../../../global/helpers/global-helpers';
import LaunchdeckFormInput from '../../../forms/TextInputField/components/LaunchdeckFormInput';
import { TbProgressAlert } from "react-icons/tb";

function DeleteOrganizationAccountModule({ orgName, deleteAccount } ) {

  const confirmationText = (orgName) ? `delete-${orgName.trim()}-account` : `delete-${generateRandomString(6)}`;
  const displayConfirmationText = confirmationText && confirmationText.toLowerCase();
  const [deleteConfirmationText, setDeleteConfirmationText] = useState('');
  const [showConfirmationError, setShowConfirmationError] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleChange = (event) => {
    const value = event.target.value;
    setDeleteConfirmationText(value);
  }

  const handleDeleteAccount = () => {
    if (!displayConfirmationText) {
      setShowConfirmationError(true)
    } else if (displayConfirmationText !== deleteConfirmationText.trim().toLowerCase()) {
      setShowConfirmationError(true)
    } else {
      setShowModal(true)
    }
  }

  const handleExecuteDeleteAccountFinal = async () => {
    await deleteAccount({useCase: 'delete-organization'});
  }


  return (
    <Box className='limit-max-width'>
      <Box className="user-profile-section__label"> Delete Organization </Box>
      <Box color='gray.500' maxWidth='500px' fontSize='0.8em'>Proceed with caution: All users assoicated with {orgName}, all its Rooms and the content will all be removed. Any active subscription will also be cancelled.</Box>

      {/* <ProfileFormTextInput name="delete-confirm" label="" subLabel={``} htmlFor="deleteConfirm" placeholder={displayConfirmationText} variant="outline" onChange={(event) => handleChange(event)} />
      <WarningTextMessage errorLogic={showConfirmationError} message={`Your input text did not match`} /> */}
     <Box mt='2em'>
      <LaunchdeckFormInput
     name="delete-confirm" 
     onChange={(event) => handleChange(event)}
          placeholder= {`To confirm, enter the following: ${displayConfirmationText}`}
          // value={googleDocButtonLink}
          label="Delete Organization confirmation text"
          errorLogic={showConfirmationError}
          errorMessage={'Your input text did not match'}
        />
        </Box>

      <Flex direction='column' mt='1em' justify='center' alignItems='center'>
        <Flex justify={['center', 'flex-end', 'flex-end', 'flex-end']} w='100%'>
          <LaunchdeckButton bg='red.400' color='white' onClick={handleDeleteAccount} label='Delete account' />
        </Flex>
      </Flex>


      <ClaverModal
        title='Important Information'
        handleCloseModal={() => setShowModal(false)}
        showModal={showModal}
      >
        <Box p='2em'>
          <Box> We are extremely sorry to see you want to go. We want to let you know what will happen once you acknowledge and proceed with this final step.</Box>
          <Flex mt='2.5em' justify='flex-start' align='center' gap={4}>
            <TbProgressAlert color='brandPink.500'/>
            <Box color='gray.700'> Key Considerations</Box>
           
            </Flex>
          <Box whiteSpace='pre-line' mt='1em' fontSize='0.875em' color='gray.700'>{`1. All accounts associated with ${orgName} will no longer have access to Launchdeck. Once confirmed, we will not be able to restore the accounts. \n\n2. All Rooms, Prospectors and content created by ${orgName} members will also be removed permanently. \n\n3. Any non-essential personal information will be removed from our system. We only retain what is legally required of us (e.g by GDPR)`}
          </Box>

          <Box mt='6em' mb='1em' fontSize='0.75em'>I have read and understood the above considerations. By clicking the button below, I thereby confirm my decision to delete all accounts associated with my Organization.</Box>

          <Flex w='100%' justify='center' alignItems='center'>
            <Flex justify='center' w='100%'>
              <LaunchdeckButton w='100%' bg="red.400" color='white' onClick={handleExecuteDeleteAccountFinal} label='Confirm & delete organization' />
            </Flex>
          </Flex>

          <Flex w='100%' mt='1em' justify='center' alignItems='center'>
            <Flex justify='center' w='100%'>
              <LaunchdeckButton w='100%' bg="brand.500" color='white' onClick={() => setShowModal(false)} label='Keep Account' />
            </Flex>
          </Flex>
        </Box>

      </ClaverModal>

    </Box>
  )
}


// function mapDispatchToProps(dispatch) {
//   return {
//     action: bindActionCreators(
//       {
//         deleteAccount
//       },
//       dispatch
//     )
//   };
// }

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			deleteAccount
		},
		dispatch,
	)


export default connect(null, mapDispatchToProps)(DeleteOrganizationAccountModule);