import React from 'react';
import { Modal, ModalOverlay, ModalBody, ModalContent, useDisclosure, Flex, Text } from "@chakra-ui/react";
import PageLoader from './PageLoader';
import LaunchdeckCloseButton from './LaunchdeckCloseButton';



export default function ClaverCleanModal(props) {
  const {showModal, isLoadingModal, modalText, isCloseable, handleCloseModal} = props || {};
  const { isOpen, onClose } = useDisclosure();
  
  if (isLoadingModal) {
    return (
      <Modal isOpen={showModal ? showModal : isOpen} onClose={onClose} preserveScrollBarGap={true} returnFocusOnClose={false} boxSize={['90%', '90%', '600px', '600px']} maxHeight='80%' closeOnOverlayClick={false} isCentered={true} position='fixed'>
      <ModalOverlay />
      <ModalContent rounded='0.5em' mx={['5%','','','']} minW={['90%', '500px']} maxW='700px'  >
        <ModalBody px='0%'>
          <PageLoader />
          <Flex w='100%' px='2em' justify='center'>
            {modalText}
          </Flex>
              
        </ModalBody>

      </ModalContent>
    </Modal>
    )
  } else return (
    <Modal isOpen={showModal ? showModal : isOpen} onClose={onClose} preserveScrollBarGap={true} returnFocusOnClose={false} boxSize={['90%', '90%', '600px', '600px']} maxHeight='80%' closeOnOverlayClick={false} isCentered={true} position='fixed'>
      <ModalOverlay />
      <ModalContent rounded='0.5em' mx={['5%','','','']} minW={['90%', '500px']} maxW='700px'  >
        <ModalBody px='0%'>
        <Flex direction='column' justify='space-between'>
            {isCloseable && <Flex w='100%' justify='flex-end' px='2em' py='0.4em'>
              <LaunchdeckCloseButton handleCloseModal={handleCloseModal} />
            </Flex>
            }
            <Text fontSize='20px' lineHeight='22px' color='brand.700' fontWeight='500' > {props.title} </Text> 
          <Flex direction='column'>
            {props.children}
          </Flex>
          </Flex>
        </ModalBody>

      </ModalContent>
    </Modal>
  )  

}
