import { SET_DSR_LOGO_IMAGES } from '../../actions/dsr-actions-types'

const INITIAL_STATE_DSR_LOGO_IMAGES = { dsrDisplayLogos: '' }

export function dsrDisplayLogoImageReducer(state = INITIAL_STATE_DSR_LOGO_IMAGES, action) {

    switch (action.type) {
        case SET_DSR_LOGO_IMAGES:
            return { dsrDisplayLogos: action.payload };
        default:
            return state;
    }
}