import React, { memo, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'

import {
  Box, Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem, Button, useToast
} from '@chakra-ui/react'

import { updateDsr, resetUpdateDsrOutcome, changeDsrModalState, changeDsrPopupState } from '../actions/dsr-actions';
import LaunchdeckButton from '../../global/components/helper-components/components/LaunchdeckButton'
import FormTextInput from '../../forms/TextInputField/components/FormTextInput'
import InputTextAreaExpandable from '../../forms/TextareaField/components/InputTextAreaExpandable'
import { triggerLaunchdeckToast } from '../../global/helpers/global-helpers'
import { QRCodeSVG } from "qrcode.react";
import config from "../../../config/app-config";
import { IoChevronDownOutline } from 'react-icons/io5';


function DsrRoomSettingsPage({
  // Parent component
  dsrId,
  dsrProperties,
  // Redux actions
  updateDsr,
  resetUpdateDsrOutcome,
  changeDsrPopupState,
  changeDsrModalState,
  // Redux state
  isMobileView,
  dsrStatusRedux,
  updateDsrOutcome,
  dsrTitleRedux
}) {


  const toast = useToast();
  const maxDsrTitleLength = 100;

  const [updatedDsrTitle, setUpdatedDsrTitle] = useState("")
  const [latestDsrProperties, setLatestDsrProperties] = useState(dsrProperties)

  const { dealValue, tempClientData, roomSettings } = latestDsrProperties || {};
  const { roomDescription } = roomSettings || {};
  const { currency, numberValue } = dealValue || {};
  const { clientName, clientWebsiteUrl } = tempClientData || {};

  const { displayUrl } = config;
  const routeLink = `/dsr/access/${dsrId}`;
  const publishedDsrLinkDisplay = (displayUrl && routeLink) ? `${displayUrl}${routeLink}` : null


  useEffect(() => {
    if (updateDsrOutcome) {
      const { updateDsrStatus, updatedDsrId, sourceEvent } = updateDsrOutcome || {};

      switch (sourceEvent) {
        case ("save-room-properties"): {
          if (updateDsrStatus && updatedDsrId === dsrId && dsrId !== undefined) {
            toast(
              triggerLaunchdeckToast({
              useCase: "show-success-state",
              label: "Room settings successfully updated", 
              isMobileView
              })
            )
          } else if (!updateDsrStatus) {
            toast(
              triggerLaunchdeckToast({
              useCase: "show-error-state",
              label: "Unfortunately something went wrong. Please try again later.", 
              isMobileView
              })
            )
          }

          resetUpdateDsrOutcome()
          break;
        }
        default: {
          console.log(`There is no such sourceEvent: ${sourceEvent}`)
          break;
        }
      }
    }
  }, [updateDsrOutcome, toast, dsrId, resetUpdateDsrOutcome]);

  const onChangeHandler = event => {
    switch (event.target.name) {
      // DSR Properties
      case ('roomDescription'): {
        setLatestDsrProperties({
          ...latestDsrProperties,
          roomSettings: {
            ...roomSettings,
            roomDescription: (event.target.value)
          }
        })

        break;
      }
      case ('clientName'): {
        setLatestDsrProperties({
          ...latestDsrProperties,
          tempClientData: {
            ...tempClientData,
            clientName: (event.target.value)
          }
        })

        break;
      }
      case ('clientWebsiteUrl'): {
        setLatestDsrProperties({
          ...latestDsrProperties,
          tempClientData: {
            ...tempClientData,
            clientWebsiteUrl: (event.target.value.trim())
          }
        })

        break;
      }
      case ('dealValue'): {
        setLatestDsrProperties({
          ...latestDsrProperties,
          dealValue: {
            ...dealValue,
            numberValue: parseInt(event.target.value.trim())
          }
        })

        break;
      }

      // DSR Room 
      case ('dsrTitle'): {
        if (event.target.value?.trim().length <= maxDsrTitleLength) {
          setUpdatedDsrTitle(event.target.value.trim())
        }

        break;
      }
      default: {
        break;
      }
    }
  };

  const handleSaveDsrProperties = () => {
    if (dsrId) {
      updateDsr({
        dsrId: dsrId,
        dsrProperties: latestDsrProperties,
        sourceEvent: "save-room-properties",
        ...updatedDsrTitle && { dsrTitle: updatedDsrTitle }
      })
    }
  }

  const handlePickCurrencyOption = (currency) => {
    setLatestDsrProperties({
      ...latestDsrProperties,
      dealValue: {
        ...dealValue,
        currency: currency
      }
    })
  }

  return (
    <Box>
      <Flex justify='space-between' align='center'>
        <Box>
          <Box color='brandDark.500' fontSize='1.5em' lineHeight='1.4em' fontWeight='600'>Room Settings</Box>
          <Box color='brandDark.500' fontSize={['0.75em','0.75em','0.875em','0.875em']} fontWeight='400'>
            Manage Room settings here
          </Box>
        </Box>
      </Flex>

      <Box bg='white' p='1em' rounded='0.5em' border='1px solid' borderColor='gray.300' mt='1.5em'>

        <Box color='brandDark.500' fontSize='1.25em' lineHeight='1.375em'>General</Box>

        <Box w='100%' my='1.5em'>
          <Flex w='100%' justify='space-between' align='center'>
            <Box lineHeight='1.275em' fontWeight='500' my='1em'>
              Room Name
            </Box>


            <Box>
              <Box display='flex' fontSize='14px'><Box fontWeight='600'>{dsrTitleRedux ? dsrTitleRedux.length : updatedDsrTitle.length}</Box> / {maxDsrTitleLength}</Box>
            </Box>
          </Flex>
          <FormTextInput
            name={'dsrTitle'}
            onChange={onChangeHandler}
            defaultValue={dsrTitleRedux}
            charLimit={maxDsrTitleLength}
            // color={textColor}
            mb='0em'
            mt='0em'
          />



          <Flex w='100%' justify='space-between' align='center' mt='1.5em'>
            <Box lineHeight='1.275em' fontWeight='500' my='1em'>
              Room Description
            </Box>
          </Flex>

          <Box w='100%' border='1px solid' borderColor='gray.200' rounded='0.25em'>
            <InputTextAreaExpandable
              name={'roomDescription'}
              onChangeHandler={onChangeHandler}
              inputValue={roomDescription}
              placeholder={""}
              // border="1px solid"
              // borderColor='gray.200'
            />
          </Box>


          <Flex w='100%' justify='flex-start' align='center' mt='1.5em'>
            <Box lineHeight='1.275em' fontWeight='500' my='1em'>
              Deal Value
            </Box>
          </Flex>


          <Flex mb='1em' w='100%' justify='center' align='center'>

            <Menu mb='0em'>
              <MenuButton as={Button} rightIcon={<IoChevronDownOutline />} height='2.5em' fontSize='0.875em' lineHeight='1.275em'>
                <Box px='0.5em'>{currency ? currency : "GBP"}</Box>
              </MenuButton>
              <MenuList>
                <MenuItem onClick={() => handlePickCurrencyOption('GBP')}>{"£"}</MenuItem>
                <MenuItem onClick={() => handlePickCurrencyOption('USD')}>{"$"}</MenuItem>
                <MenuItem onClick={() => handlePickCurrencyOption('EUR')}>{"€"}</MenuItem>
              </MenuList>
            </Menu>

            <FormTextInput
              name={'dealValue'}
              onChange={onChangeHandler}
              defaultValue={numberValue}
              mb='0em'
              mt='0em'
              w='100%'
            />
          </Flex>
        </Box>

        <Flex w='100%' justify='flex-end' mt='2em'>
          <LaunchdeckButton bg='brand.500' color='white' onClick={() => handleSaveDsrProperties()} label='Save' />
        </Flex>
      </Box>

      <Box bg='white' p='1em' rounded='0.5em' border='1px solid' borderColor='gray.300' mt='1.5em'>

        <Box color='brandDark.500' fontSize='1.25em' lineHeight='1.375em'>Client Organization Info</Box>

        <Flex w='100%' justify='flex-start' align='center'>
          <Box lineHeight='1.275em' fontWeight='500' my='1em'>
            Client name
          </Box>

        </Flex>

        <FormTextInput
          name={'clientName'}
          onChange={onChangeHandler}
          defaultValue={clientName}
          mb='0em'
          mt='0em'
        />

        <Flex w='100%' justify='flex-start' align='center'>
          <Box lineHeight='1.275em' fontWeight='500' my='1em'>
            Client website
          </Box>

        </Flex>

        <FormTextInput
          name={'clientWebsiteUrl'}
          onChange={onChangeHandler}
          defaultValue={clientWebsiteUrl}
          // color={textColor}
          mb='0em'
          mt='0em'
        />


        <Flex w='100%' justify='flex-end' mt='2em'>
          <LaunchdeckButton bg='brand.500' color='white' onClick={() => handleSaveDsrProperties()} label='Save' />
        </Flex>
      </Box>

      {dsrStatusRedux === "active" &&
        <Box bg='white' p='1em' rounded='0.5em' border='1px solid' borderColor='gray.300' my='1.5em'>
          <Box color='brandDark.500' fontSize='1.25em' lineHeight='1.375em' mb='1em'>Share Room with QR code</Box>
          <Box display='flex' width='100%' justifyContent='center' my='2em'>
            <QRCodeSVG value={publishedDsrLinkDisplay ? publishedDsrLinkDisplay : ''} />
          </Box>
        </Box>
      }

<Box bg='white' p='1em' rounded='0.5em' border='1px solid' borderColor='gray.300' my='1.5em'>

<Box color='brandDark.500' fontSize='1.25em' lineHeight='1.375em' mb='1em'>Page accessibility</Box>

<Flex w='100%' justify='space-between' align='center' py='1.5em'>
  <Box>
    <Box lineHeight='1.25em' fontWeight='500'>
      Content access 
    </Box>
    <Box fontSize='0.75em' lineHeight='1.25em' fontWeight='400' color='gray.500' mt='0.2em'>
      Choose whether to hide or lock your pages
    </Box> 
  </Box>
    <Box mt={['0','0','1em','1em']}>
      <LaunchdeckButton bg='brandDark.500' color='white' onClick={() => {
        changeDsrPopupState('mass-manage-dsr-custom-pages')
        changeDsrModalState(true)
      }} label='Manage' />
    </Box>


</Flex>
</Box>

      <Box bg='white' p='1em' rounded='0.5em' border='1px solid' borderColor='gray.300' my='1.5em'>

        <Box color='brandDark.500' fontSize='1.25em' lineHeight='1.375em' mb='1em'>Unpublish Room</Box>

        <Flex w='100%' justify='space-between' align='center' py='1.5em'>
          <Box>
            <Box lineHeight='1.25em' fontWeight='500'>
              Revert back to draft state
            </Box>
            {dsrStatusRedux === "active" ?
            <Box fontSize='0.75em' lineHeight='1.25em' fontWeight='400' color='gray.500' mt='0.2em' pr='2em'>
              Content is retained, members will be unable to access the Room until you re-publish it again
            </Box> :
             <Box fontSize='0.75em' lineHeight='1.25em' fontWeight='400' color='gray.500' mt='0.2em'>
             Room is currently unpublished
           </Box>}
          </Box>

          {dsrStatusRedux === "active" ?
            <Box mt={['0','0','1em','1em']}>
              <LaunchdeckButton bg='brandDark.500' color='white' onClick={() => {
                changeDsrPopupState('revert-dsr-to-draft')
                changeDsrModalState(true)
              }} label='Unpublish' />
            </Box>
            :
            <Box mt={['0','0','1em','1em']}>
               <LaunchdeckButton dummy={true} bg='gray.100' color='gray.500' label='Unpublish' />
            </Box>
          }


        </Flex>
      </Box>

      <Box bg='white' p='1em' rounded='0.5em' border='1px solid' borderColor='gray.300' my='1.5em'>
        <Box color='brandDark.500' fontSize='1.25em' lineHeight='1.375em' mb='1em'>Danger Zone</Box>

        <Flex w='100%' justify='space-between' align='center' py='1.5em'>
          <Box>
            <Box lineHeight='1.25em' fontWeight='500'>
              Delete this Room
            </Box>
            <Box fontSize='0.75em' lineHeight='1.25em' fontWeight='400' color='gray.500' mt='0.2em'>
              The Room will be completely removed. This action cannot be undone.
            </Box>
          </Box>

          <Box>
            <LaunchdeckButton bg='gray.100' color='#DA3739' onClick={() => {
              changeDsrPopupState('delete-dsr')
              changeDsrModalState(true)
            }} label='Delete' />
          </Box>


        </Flex>
      </Box>


    </Box>

  )

}

const mapStateToProps = (state) => {
  return {
    dsrStatusRedux: state.dsrStatus.status,
    updateDsrOutcome: state.updateDsrOutcome.results.data,
    dsrTitleRedux: state.dsrTitle.title,
    isMobileView: state.mobileViewMode.isMobileView
  }
}


const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateDsr,
      resetUpdateDsrOutcome,
      changeDsrPopupState,
      changeDsrModalState
    },
    dispatch,
  )

export default memo(withRouter(connect(mapStateToProps, mapDispatchToProps)(DsrRoomSettingsPage)))
