import React from 'react';
import '../styles/header.scss';
import logo from '../../../../global/styles/images/launchdeck_logo.svg';
import { NavLink } from 'react-router-dom';
import { determineIsNonCustomerUserAccount } from '../../../../profile/helpers/user-helper';


export default function HeaderLogo({isMobileView}) {

	if (isMobileView) {
		return <img className="user-header__logo-mobile" height='3em' src={logo} alt="launchdeck_logo" />
	}

	const isNoOrgUser = determineIsNonCustomerUserAccount();

	return (
	<NavLink to={isNoOrgUser ? "/received-rooms" : "/"} className="header__logo-link">
		<img className="user-header__logo" height='3em' src={logo} alt="launchdeck_logo" />
	</NavLink>
	)
}


