import React, { useEffect, useState } from 'react';

import {
  Box,
  Modal,
  ModalOverlay,
  ModalBody,
  ModalContent,
  Flex
} from "@chakra-ui/react";
import { CreateDsrForm } from './CreateDsrForm';
import { bindActionCreators } from "redux";
import { createNewDsr, createProspectDsr } from "../../digital-sales-room/actions/dsr-actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { redirectToNewPage, validateUrlFormat } from "../../global/helpers/global-helpers";
import CreatedDsrClientBranding from "./CreatedDsrClientBranding";
import { CreateProspectDsrForm } from '../../digital-sales-room/dsr-prospecting/draft-prospect-dsr/CreateProspectDsrForm';
import LaunchdeckCloseButton from '../../global/components/helper-components/components/LaunchdeckCloseButton';



function CreateDsrModal({
  showModal,
  handleCloseCreateDsrModal,
  dsrTeamMembers,
  isMobileView,
  formType,
  // Redux
  createdDsrPath,
  createNewProspectDsrOutcome,
  // Action
  createNewDsr,
  createProspectDsr

}) {
  const [error, setError] = useState('');
  const [showLoader, setShowLoader] = useState(false)
  const [isBrandingModal, setIsBrandingModal] = useState(false);

  const [createDsrForm, setCreateDsrForm] = useState({
    dsrTitle: '',
    dsrDescription: '',
    dsrClientName: '',
    dsrClientWebsite: '',
    dsrRoomValue: '',
    dsrRoomCurrency: 'USD',
    // Logo management
    dsrLogoType: '',
    dsrLogoUrl: '', // CB suggested
    dsrUploadedLogoUrl: '', // Uploaded Logo
    dsrTextLogoString: '', // Textual logo
  })
  const [prospectDsrFormInput, setProspectDsrFormInput] = useState({ dsrTitle: '' })

  useEffect(() => {
    if (createdDsrPath) {
      redirectToNewPage(createdDsrPath)
    }

  }, [createdDsrPath]);

  useEffect(() => {
    if (createNewProspectDsrOutcome) {
      const { pDsrCreated, createdProspectDsrPath } = createNewProspectDsrOutcome || {};
      if (pDsrCreated === true) {
        redirectToNewPage(createdProspectDsrPath)
      }
    }

  }, [createNewProspectDsrOutcome]);

  // Functions
  const onChangeHandler = (event) => {
    setError('')

    setCreateDsrForm({
      ...createDsrForm,
      [event.target.name]: event.target.value
    })
  };

  const onChangePdsrHandler = (event) => {
    setError('')

    setProspectDsrFormInput({
      ...prospectDsrFormInput,
      [event.target.name]: event.target.value
    })
  };

  const handleClickCloseEditActionModal = () => {
    setCreateDsrForm({
      dsrTitle: '',
      dsrDescription: '',
      dsrClientName: '',
      dsrClientWebsite: '',
      dsrRoomValue: '',
      dsrRoomCurrency: 'USD',
      dsrLogoType: '',
      dsrUploadedLogoUrl: '',
      dsrLogoUrl: '',
      dsrTextLogoString: ''
    })
    setProspectDsrFormInput({ dsrTitle: '' })

    setIsBrandingModal(false)
    handleCloseCreateDsrModal()
  }

  const handleMoveToBrandingStep = () => {
    if (createDsrForm.dsrTitle.trim().length < 1) {
      setError('dsrTitle')
    } else if (createDsrForm.dsrClientWebsite && !validateUrlFormat(createDsrForm.dsrClientWebsite)) {
      setError('dsrClientWebsite')
    } else {
      setIsBrandingModal(true)
    }
  }

  const handleCreateNewDsr = () => {
    setIsBrandingModal(false)

    // UUU review this relating to new properties with logo management. See ManageDsrLogoModal for reference
    const createNewDsrMetadata = {
      dsrTitle: createDsrForm.dsrTitle.trim(),
      dsrPropertiesObject: {
        dsrDescription: createDsrForm.dsrDescription.trim(),
        dsrLogoType: createDsrForm.dsrLogoType,
        dsrUploadedLogoUrl: createDsrForm.dsrUploadedLogoUrl,
        displayedLogoImageKey: createDsrForm.displayedLogoImageKey,
        // dsrUploadedLogoUrl: createDsrForm.dsrUploadedLogoUrl,
        dsrLogoUrl: createDsrForm.dsrLogoUrl,
        // tempAudienceLogoLocation: createDsrForm.dsrLogoUrl, 
        dsrTextLogoString: createDsrForm.dsrTextLogoString.trim(),
        // textLogoString: createDsrForm.dsrTextLogoString.trim(),  
        refGuestUserId: "", // Empty by default; field only used when user pick audience logo AFTER adding audience members
        tempClientData: {
          clientName: createDsrForm.dsrClientName.trim(),
          clientWebsiteUrl: createDsrForm.dsrClientWebsite.trim()
        },
        dealValue: {
          numberValue: createDsrForm.dsrRoomValue ? Number(createDsrForm.dsrRoomValue) : null,
          currency: createDsrForm.dsrRoomCurrency ? createDsrForm.dsrRoomCurrency : "",
        },
        roomSettings: {
          inviteMode: "invited-members-only",
          accessCodeProtected: false
        }
      }
    }

    setShowLoader(true);
    createNewDsr(createNewDsrMetadata)
  }

  const handleCreateNewProspectDsr = () => {

    const createNewProspectDsrMetadata = {
      dsrTitle: prospectDsrFormInput.dsrTitle.trim()
    }

    createProspectDsr(createNewProspectDsrMetadata)
  }

  const renderForm = () => {
    switch (formType) {
      case ("dsr"): {
        return (
          <>
            <Flex justify='space-between' align='center' w='100%' py='0.5em'>
              <Flex px='1.5em' justify='flex-start' align='center'>
                <Box fontWeight='500' fontSize='1.1em' lineHeight='1.375em' color='brandDark.500'>{isBrandingModal ? 'Client branding' : 'Create new Room'}</Box>
              </Flex>
              <Box pr='1.5em' display='flex' alignItems='center'>
                {isBrandingModal
                  && <Box
                    onClick={handleCreateNewDsr}
                    cursor='pointer'
                    mr='1em'
                    fontSize='0.75em'
                    fontWeight='500'
                    color='gray.500'
                  >
                    Skip and go to Room
                  </Box>}
                <LaunchdeckCloseButton handleCloseModal={() => handleClickCloseEditActionModal()} />
              </Box>
            </Flex>

            <Flex justify='center' mt='1em' w='100%'>
              {!isBrandingModal
                ? <CreateDsrForm
                  dsrTeamMembers={dsrTeamMembers}
                  createDsrForm={createDsrForm}
                  setCreateDsrForm={setCreateDsrForm}
                  error={error}
                  showLoader={showLoader}
                  onChangeHandler={onChangeHandler}
                  handleClickCloseEditActionModal={handleClickCloseEditActionModal}
                  handleMoveToBrandingStep={handleMoveToBrandingStep}
                />
                : <CreatedDsrClientBranding
                  handleCreateNewDsr={handleCreateNewDsr}
                  createDsrForm={createDsrForm}
                  setCreateDsrForm={setCreateDsrForm}
                  setIsBrandingModal={setIsBrandingModal}
                />
              }
            </Flex>
          </>
        )
      }
      case ("prospect-dsr"): {
        return (
          <>
            <Flex justify='space-between' align='center' w='100%' py='0.5em'>
              <Flex px='1.5em' justify='flex-start' align='center'>
                <Box fontWeight='500' fontSize='1.1em' lineHeight='1.375em' color='brandDark.500'>Create new Prospector</Box>
              </Flex>
              <Box pr='1.5em' display='flex' alignItems='center'>
                <LaunchdeckCloseButton handleCloseModal={() => handleClickCloseEditActionModal()} />
              </Box>
            </Flex>

            <Flex justify='center' mt='1em' w='100%'>

              <CreateProspectDsrForm
                prospectDsrFormInput={prospectDsrFormInput}
                error={error}
                showLoader={showLoader}
                onChangeHandler={onChangePdsrHandler}
                handleClickCloseEditActionModal={handleClickCloseEditActionModal}
                handleCreateNewProspectDsr={handleCreateNewProspectDsr}
              />

            </Flex>
          </>
        )
      }
      default: {
        return <Box />
      }
    }

  }

  if (!showModal) {
    return null;
  }

  if (showModal) {
    return (
      <Modal isOpen={true} closeOnOverlayClick={false} isCentered={true} preserveScrollBarGap={true} >
        <ModalOverlay />
        <ModalContent rounded='0.25em' minW={['90%', '500px']} maxW='700px'>
          <ModalBody px='0em'>
            <Flex direction='column' justify='space-around' alignItems='center' w='100%'>
              {renderForm()}
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    )
  }
}

function mapStateToProps(state) {
  return {
    createdDsrPath: state.createNewDsrOutcome.results.data,
    createNewProspectDsrOutcome: state.createNewProspectDsr.results.data,
  };
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createNewDsr,
      createProspectDsr
    },
    dispatch,
  )

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateDsrModal));
