import React, { useState } from 'react';

import { Box, Flex } from '@chakra-ui/react';
import FormTextInput from '../../forms/TextInputField/components/FormTextInput';
import WarningTextMessage from '../../global/components/helper-components/components/WarningTextMessage';
import PageLoader from '../../global/components/helper-components/components/PageLoader';
import LaunchdeckButton from '../../global/components/helper-components/components/LaunchdeckButton';

export default function SetNewOrgUserPasswordForm(props) {

	const {handleSetPassword} = props || {};
	const [localState, setLocalState] = useState({
		inputPassword: "",
		repeatPassword: "",
		showLoader: false,
		showValidationError: false,
	})

	const handleSubmit = (event) => {
		event.preventDefault();
	}

	const handleChange = (event) => {
		const value = event.target.value;
		setLocalState(prevState => ({
			...prevState,
			[event.target.name]: value,
			showValidationError: false
		}));
	}

	const submitSetNewPassword = () => {
		const { inputPassword, repeatPassword } = localState;
		const { resetPasswordToken } = props;


		const sameInputs = ((inputPassword && inputPassword.length > 5) && (inputPassword === repeatPassword)) ? true : false;

		if (sameInputs) {
			handleSetPassword({useCase:"set-new-user-password", inputPassword, resetPasswordToken})

			setLocalState(prevState => ({
				...prevState,
				showLoader: true
			}));
		}

		if (!sameInputs) {
			setLocalState(prevState => ({
				...prevState,
				showValidationError: true
			}));
		}

	}


	const { showValidationError, showLoader } = localState;

	if (showLoader) {
		return (
			<PageLoader />
		)
	} else {
		return (
			<React.Fragment>

				<Box my='3%' w={['80%', '80%', '60%', '60%']}>
					<form onSubmit={handleSubmit}>

						<Box m="3%">
							<FormTextInput name={'inputPassword'} label={'Password'} sublabel={''} htmlFor={'inputPassword'} type='password' mb='0.2em' onChange={handleChange} />
						</Box>

						<Box m="3%" pt='2em'>
							<FormTextInput name={'repeatPassword'} label={'Confirm Password'} sublabel={''} htmlFor={'repeatPassword'} type='password' mb='0.2em' onChange={handleChange} />
							<WarningTextMessage errorLogic={showValidationError} message=' Your confirmation password did not match, or needs to be more than 6 characters' />
						</Box>

						<Flex direction='column' mt='2em' justify='center' alignItems='center'>
							<Flex justify={['center', 'center', 'center', 'center']} w='100%'>
								<LaunchdeckButton bg='brand.500' color='white' w={["100%", "100%", "auto", "auto"]} onClick={() => submitSetNewPassword()} label='Set Password' />
							</Flex>
						</Flex>

					</form>
				</Box>

			</React.Fragment>

		)
	}
};