import { combineReducers } from 'redux'

import {
    auth,
    emailVerificationStatusReducer,
    setNewPasswordReducer,
} from '../../features/auth/reducers/auth-reducers'
import {
    editProfileReducer,
    getUserSettingsReducer,
    profileReducer,
    updateNewPasswordReducer,
    uploadProfilePictureReducer,
} from '../../features/profile/reducers/users-profile-reducer'
import {
    joinWaitlistReducer
} from '../../features/marketing/reducers/marketing-reducer'
import {
    adminOrgProfileManagementReducer,
    uploadOrgLogoImageReducer,
    setOrgLogoImageReducer
} from '../../features/organizations/reducers/organizations-reducer'
import { sendSystemNotificationEmailReducer } from '../../features/global/reducers/global-reducer'
import {
    createDuplicateDsrReducer,
    createFaqAnswerReducer,
    createNewDsrActionReducer,
    createNewDsrReducer,
    deleteFaqAnswerReducer,
    editDsrMemberRoleReducer,
    editDsrTeamMembersReducer,
    editFaqAnswerReducer,
    getDsrActionListByDsrIdReducer,
    getDsrConfigByDsrIdReducer,
    getDsrProspectReducer,
    updateDsrActionReducer,
    updateDsrReducer,
    createFaqQuestionReducer,
    editFaqQuestionReducer,
    deleteFaqQuestionReducer,
    uploadFilesReducer,
    deleteFilesReducer
} from '../../features/digital-sales-room/reducers/dsr-reducer'
import {
    getPublicDsrConfigByDsrIdReducer,
} from '../../features/digital-sales-room/reducers/public-dsr-reducer'
import {
    createNewDsrFeedPostReducer,
    createNewDsrFeedReplyReducer,
    deleteDsrFeedPostReducer,
    deleteDsrFeedReplyReducer,
    editDsrFeedPostReducer,
    editDsrFeedReplyReducer,
    editDsrReactionReducer,
    getDsrFeedContentByDsrIdReducer,
} from '../../features/digital-sales-room/reducers/dsr-feed-reducer'
import {
    createNewErrorReportReducer,
    getDsrAuthReducer,
    getLoomTokenReducer,
    getLoomVideosReducer,
} from '../../features/digital-sales-room/reducers/dsr-admin-reducer'
import {
    adminOrgUserManagementReducer,
    getAdminOrgProfileReducer,
    resetActivationLinkReducer,
} from '../../features/profile/reducers/admin-users-reducer'
import { dsrPopupReducer } from '../../features/digital-sales-room/components/reducers/dsr-popup-reducer'
import { dsrModalReducer } from '../../features/digital-sales-room/components/reducers/dsr-modal-reducer'
import { dsrPageReducer } from '../../features/digital-sales-room/components/reducers/dsr-page-reducer'
import { dsrStatusReducer } from '../../features/digital-sales-room/components/reducers/dsr-status-reducer'
import { showDsrModalReducer } from '../../features/digital-sales-room/components/reducers/show-dsr-modal-reducer'
import {
    userActionRequiredReducer,
} from '../../features/digital-sales-room/components/reducers/user-action-required-reducer'
import { userProfileReducer } from '../../features/digital-sales-room/components/reducers/user-profile-reducer'
import { dsrTitleReducer } from '../../features/digital-sales-room/components/reducers/dsr-title-reducer'
import {
    showDsrModalLoaderReducer,
} from '../../features/digital-sales-room/components/reducers/show-dsr-modal-loader-reducer'
import {
    dsrTeamMembersReducer,
} from '../../features/digital-sales-room/components/reducers/dsr-team-members-reducer'
import {
    setDsrUserTeamReducer,
} from '../../features/digital-sales-room/components/reducers/set-dsr-user-team-reducer'
import { dsrPagesReducer } from '../../features/digital-sales-room/components/reducers/dsr-pages-reducer'
import { faqPageIdReducer } from '../../features/digital-sales-room/components/reducers/faq-page-id-reducer'
import {
    draftDsrCurrentPageReducer,
} from '../../features/digital-sales-room/components/reducers/draft-dsr-current-page-reducer'
import { draftDsrPropertiesReducer } from '../../features/digital-sales-room/components/reducers/draft-dsr-properties'
import {
    dsrInvalidRequestReducer,
} from '../../features/digital-sales-room/components/reducers/dsr-invalid-request-reducer'
import { faqPageContentReducer } from '../../features/digital-sales-room/components/reducers/faq-page-content-reducer'
import { getReceivedRoomsSearchResultsReducer, getVaultQueriedDsrReducer, getVaultSearchResultsReducer, getProspectorSearchResultsReducer } from '../../features/vault/reducers/vault-reducer'
import { dsrDisplayLogoImageReducer } from '../../features/digital-sales-room/components/reducers/dsr-logo-image-display-reducer'
import { uploadTempAudienceLogoReducer } from '../../features/global/reducers/file-upload-reducer'
import { checkIfExistingUserReducer } from '../../features/digital-sales-room/components/reducers/dsr-invite-user-reducer'
import { accessAuthenticationReducer } from '../../features/auth/reducers/access-auth-reducers'
import { createProspectDsrReducer, sendProspectDsrQueryReducer, updateProspectDsrReducer } from '../../features/digital-sales-room/components/reducers/prospect-dsr-reducer'
import { dsrMainContactReducer } from '../../features/digital-sales-room/components/reducers/dsr-main-contact-reducer'
import { dsrAnalyticsReducer, dsrAnalyticsDetailsReducer } from '../../features/analytics/reducers/dsr-analytics-reducer'
import { setDsrAdminReducer } from '../../features/digital-sales-room/components/reducers/set-dsr-admin-reducer'
import { setPublicDsrEnvironmentReducer } from '../../features/digital-sales-room/reducers/set-public-dsr-environment-reducer'
import { joinPublicDsrReducer } from '../../features/digital-sales-room/reducers/join-public-dsr-reducer'
import { setJoinRoomRequestsReducer } from '../../features/digital-sales-room/components/reducers/set-join-room-requests-reducer'
import { manageJoinRoomRequestReducer } from '../../features/digital-sales-room/components/reducers/join-room-request-reducer'
import { setDsrUiIndicatorsReducer } from '../../features/digital-sales-room/components/reducers/set-ui-indicators-reducer'
import { setUserProfilePictureReducer } from '../../features/profile/reducers/set-user-profile-picture-reducer'
import { setIsMobileViewReducer } from '../../features/auth/reducers/set-mobile-view-reducer'
import { sendCustomerSupportMessageReducer } from '../../features/global/reducers/customer-support-reducer'
import { stripeCheckoutSessionReducer } from '../../features/payments/reducers/payments-reducer'
import { createGenesisUserReducer, offboardUserReducer, editGenesisUserReducer, createNewOrgForExistingUserReducer, manageExistingUserReducer, manageExistingOrgReducer } from '../../features/profile/reducers/app-admin-reducer'
import { setUserPrivilegesReducer } from '../../features/auth/reducers/set-user-privileges-reducer'
import { genesisSetupReducer } from '../../features/auth/reducers/genesis-setup-reducers'
import { setUploadProgressReducer } from '../../features/digital-sales-room/components/reducers/set-upload-progress-reducer'
import { userSelfSetupReducer } from '../../features/profile/reducers/user-self-setup-reducer'
import { setisOrgAdminReducer } from '../../features/digital-sales-room/components/reducers/set-is-org-admin-reducer'
import { setUserActiveOrgTeamMembersReducer } from '../../features/digital-sales-room/components/reducers/set-user-org-team-members-reducer'
import { contactLaunchdeckMessageReducer } from '../../features/marketing/reducers/send-ld-message-reducer'
import { onboardingReducer } from '../../features/new-user-experience/reducers/onboarding-reducer'
import { dsrSharedDocReducer } from '../../features/digital-sales-room/components/reducers/dsr-shared-doc-reducer'
import { userNotificationsReducer, viewedAllUserNotificationsReducer } from '../../features/profile/reducers/user-notifications-reducer'
import { setUserNotificationsReducer } from '../../features/profile/reducers/set-user-notifications-reducer'
import { setDsrSharedDocReducer } from '../../features/digital-sales-room/components/reducers/set-dsr-shared-doc-reducer'
import { setPaginatedUserNotificationsReducer } from '../../features/profile/reducers/set-paginated-user-notifications-reducer'
import { duplicateDsrPageReducer } from '../../features/digital-sales-room/reducers/duplicate-dsr-page-reducer'
import { manageDsrCustomPagesAccessibilityReducer } from '../../features/digital-sales-room/components/reducers/custom-page-accessibility-reducer'
import { setDsrNotificationsReducer } from '../../features/digital-sales-room/components/reducers/set-dsr-latest-notifications-reducer'
import { setDsrActionListOverviewReducer } from '../../features/digital-sales-room/components/reducers/set-dsr-action-list-overview-reducer'
import { setDsrLastUpdatedReducer } from '../../features/digital-sales-room/components/reducers/set-dsr-last-updated-reducer'
import { setDsrSharedDocOverviewReducer } from '../../features/digital-sales-room/components/reducers/set-dsr-shared-doc-overview-reducer'


const rootReducer = combineReducers({
    // form: formReducer,
    profile: profileReducer, //Move the necessary ones to DSR profile
  
    uploadOrgLogo: uploadOrgLogoImageReducer,
    setOrgLogo: setOrgLogoImageReducer,
    editProfile: editProfileReducer,
    updatePassword: updateNewPasswordReducer,
    uploadProfilePicture: uploadProfilePictureReducer,

    // Global UX
    mobileViewMode: setIsMobileViewReducer,

    // User Auth
    auth,
    emailVerificationStatus: emailVerificationStatusReducer,
    userSettings: getUserSettingsReducer,
    setNewPasswordOutcome: setNewPasswordReducer,
    userSelfSetupOutcome: userSelfSetupReducer,
    userNotificationsOutcome: userNotificationsReducer,
    markAllNotificationsAsViewedOutcome: viewedAllUserNotificationsReducer,
    currentUserNotifications: setUserNotificationsReducer,
    currentUserNotificationBatch: setPaginatedUserNotificationsReducer,

    // DSR Authentication
    accessAuthOutcome: accessAuthenticationReducer,
    dsrAuth: getDsrAuthReducer,

    // Product Marketing
    sendSystemNotificationOutcome: sendSystemNotificationEmailReducer,
    joinWaitlistOutcome: joinWaitlistReducer,
    contactLaunchdeckOutcome: contactLaunchdeckMessageReducer,

    // DSR User mgmt

    // DSR mgmt
    allUserDsrs: getVaultQueriedDsrReducer,
    vaultSearchResults: getVaultSearchResultsReducer,
    receivedRoomsSearchResults: getReceivedRoomsSearchResultsReducer,
    prospectorSearchResults: getProspectorSearchResultsReducer,
    dsrConfiguration: getDsrConfigByDsrIdReducer,
    dsrActionList: getDsrActionListByDsrIdReducer,
    createNewDsrActionOutcome: createNewDsrActionReducer,
    updateDsrActionOutcome: updateDsrActionReducer,

    publicDsrConfiguration: getPublicDsrConfigByDsrIdReducer,
    publicDsrEnvironment: setPublicDsrEnvironmentReducer,
    joinPublicDsrOutcome: joinPublicDsrReducer,

    // DSR Team Members
    dsrTeamMembers: dsrTeamMembersReducer,
    dsrMainContact: dsrMainContactReducer,
    editDsrTeamMembersOutcome: editDsrTeamMembersReducer,
    editDsrMemberRoleOutcome :editDsrMemberRoleReducer,
    checkIfExistingUserOutcome: checkIfExistingUserReducer,

    dsrPopup: dsrPopupReducer,
    dsrModal: dsrModalReducer,
    dsrPage: dsrPageReducer, // Review
    dsrPages: dsrPagesReducer, // Review
    dsrUserTeam: setDsrUserTeamReducer, 
    dsrAdmin: setDsrAdminReducer,
    dsrDisplayLogoImages: dsrDisplayLogoImageReducer,
    dsrInvalidRequest: dsrInvalidRequestReducer,
    draftDsrCurrentPage: draftDsrCurrentPageReducer,
    draftDsrProperties: draftDsrPropertiesReducer,
    joinRoomRequests: setJoinRoomRequestsReducer,
    manageJoinRoomRequestOutcome: manageJoinRoomRequestReducer,
    dsrLastSaved: setDsrLastUpdatedReducer,
    
    showDsrModal: showDsrModalReducer,
    userActionRequired: userActionRequiredReducer,
    dsrStatus: dsrStatusReducer,
    dsrTitle: dsrTitleReducer,
    dsrNotifications: setDsrNotificationsReducer,
    userProfile: userProfileReducer,
    faqPageId: faqPageIdReducer,
    faqPageContent: faqPageContentReducer,
    showDsrModalLoader: showDsrModalLoaderReducer,

    // DSR Image mgmt
    uploadTempAudienceLogoOutcome: uploadTempAudienceLogoReducer,

    // DSR content mgmt
    dsrFeedContent: getDsrFeedContentByDsrIdReducer,
    createNewDsrFeedPostOutcome: createNewDsrFeedPostReducer,
    editDsrFeedPostOutcome: editDsrFeedPostReducer, 
    deleteDsrFeedPostOutcome: deleteDsrFeedPostReducer, 

    createNewDsrFeedReplyOutcome: createNewDsrFeedReplyReducer, 
    editDsrFeedReplyOutcome: editDsrFeedReplyReducer,
    deleteDsrFeedReplyOutcome: deleteDsrFeedReplyReducer,  

    editDsrReactionOutcome:editDsrReactionReducer, 
    duplicateDsrPageOutcome: duplicateDsrPageReducer,
    dsrCustomPagesAccessibility: manageDsrCustomPagesAccessibilityReducer,
    dsrMapOverview: setDsrActionListOverviewReducer,

    // Q&A mgmt
    createFaqAnswerOutcome: createFaqAnswerReducer,
    editFaqAnswerOutcome: editFaqAnswerReducer,
    deleteFaqAnswerOutcome: deleteFaqAnswerReducer,

    createFaqQuestionOutcome: createFaqQuestionReducer,
    editFaqQuestionOutcome: editFaqQuestionReducer,
    deleteFaqQuestionOutcome : deleteFaqQuestionReducer,

    // DSR Shared Doc
    dsrSharedDocOutcome: dsrSharedDocReducer,
    currentDsrSharedDocs :setDsrSharedDocReducer,
    dsrSharedDocOverview: setDsrSharedDocOverviewReducer,

    // DSR Analytics
    dsrInsights: dsrAnalyticsReducer,
    dsrDetailedAnalytics: dsrAnalyticsDetailsReducer,

    // DSR Admin mgmt
    createErrorReportOutcome: createNewErrorReportReducer,
    loomToken: getLoomTokenReducer,
    loomVideos: getLoomVideosReducer,
    
    //Upload Progress
    fileUploadPercentProcessed: setUploadProgressReducer,

    //Upload files
    uploadFilesOutcome: uploadFilesReducer,

    //Delete files
    deleteFilesOutcome: deleteFilesReducer,

    // UI indicators
    dsrUiIndicators: setDsrUiIndicatorsReducer,

    // User DSR Dashboard Management
    createNewDsrOutcome: createNewDsrReducer,
    updateDsrOutcome: updateDsrReducer,
    createDuplicateDsrOutcome: createDuplicateDsrReducer,

    // Prospect DSR mgmt
    createNewProspectDsr: createProspectDsrReducer,
    dsrProspect: getDsrProspectReducer,
    updateProspectDsrOutcome: updateProspectDsrReducer,
    sendProspectDsrQueryOutcome: sendProspectDsrQueryReducer,

    // Admin User actions
    adminOrgProfile: getAdminOrgProfileReducer,
    manageAdminOrgUsersOutcome : adminOrgUserManagementReducer,
    manageAdminOrgProfileOutcome: adminOrgProfileManagementReducer,
    reactivateUserSetupLinkOutcome: resetActivationLinkReducer,

    // User Profile
    setUserProfilePicture: setUserProfilePictureReducer,
    userPrivileges: setUserPrivilegesReducer,
    userActiveOrgTeamMembers: setUserActiveOrgTeamMembersReducer,

    // Customer Support
    sendCustomerSupportMessageOutcome: sendCustomerSupportMessageReducer,

    // Payments
    stripeCheckoutSessionResponse: stripeCheckoutSessionReducer,

    // Genesis setup
    genesisSetupOutcome: genesisSetupReducer,
    
    // Super admin
    createGenesisUserOutcome: createGenesisUserReducer,
    offboardUserOutcome: offboardUserReducer,
    editGenesisUserOutcome: editGenesisUserReducer,
    createNewOrgForExistingUserOutcome :createNewOrgForExistingUserReducer,
    manageExistingUserOutcome: manageExistingUserReducer,
    manageExistingOrgOutcome: manageExistingOrgReducer,

    // Onboarding
    onboardingOutcome: onboardingReducer,

    // Others
    isOrgAdmin: setisOrgAdminReducer
});

export default rootReducer;
