import auth from '../../../config/app-specific-config/auth';
import * as types from '../actions/auth-action-types';
import * as userActionTypes from '../../profile/actions/users-profile-actions-types';
import { axiosInstance } from '../../../config/app-specific-config/axiosConfig';
import { getUserProfile } from '../../profile/actions/users-profile-actions';
// import { setIsMobileView } from '../../global/actions/global-actions';
// import { displayModeCheck } from '../../global/helpers/global-helpers';

// Sign Out
function signOutRequest() {
	return {
		type: types.SIGNOUT_REQUEST
	};
}

function signOutSuccess() {
	return {
		type: types.SIGNOUT_SUCCESS
	}
}

function signOutClear() {
	return {
		type: types.SIGNOUT_CLEAR
	}
}

export function setJwtExpired() {
	return {
		type: types.SET_JWT_EXPIRED
	};
}

function unsetJwt() {
	return {
		type: types.UNSET_JWT_EXPIRED
	};
}

export const unsetJwtExpired = () => {
	return async dispatch => {
		dispatch(unsetJwt());
		dispatch(signOut());
	}
}

export const signOut = () => {
	return async dispatch => {
		dispatch(signOutRequest());
		setTimeout(function() {
			dispatch(signOutSuccess());
		}, 300);
	}
}
// Login actions
function loginRequest() {
	return {
		type: types.LOGIN_REQUEST
	};
}

function loginSuccess(response) {
	return {
		type: types.LOGIN_SUCCESS,
		payload: response.data
	};
}

function loginFailure(loginFailureResponse) {
	return {
		type: types.LOGIN_FAIL,
		payload: loginFailureResponse
	};
}

// USER PRIVILEGES
export function setUserPrivileges(p) {
	return {
		type: types.SET_USER_PRIVILEGES,
		payload: p
	}
}

// Native Login
// OG version 
// TODO: harmonize this into launchdeckLogin
// This is currently being used in the main frontpage
// export const login = async (values, dispatch) => {
// 	// return async dispatch => {
// 	dispatch(signOutClear());
// 	dispatch(loginRequest());
// 	try {
// 		const response = await axiosInstance.post('/user-sessions', values);
// 		const { data } = response.data;
// 		const userSession = data && data.userSessions[0];
// 		const userData = userSession && userSession.users[0];
		
// 		const {userType} = userSession || {};
// 		const {idToken} = userData || {};

// 		const userPrivileges = data && data.userPrivileges;

// 		auth.storeToken(data.userSessions[0].token);
// 		userPrivileges && localStorage.setItem('USER_PRIVILEGES', JSON.stringify(userPrivileges));
// 		localStorage.setItem('USER_CATG', userType);  
// 		localStorage.setItem('USER_ID_TOKEN', idToken);
		
// 		if (userPrivileges) {
// 			dispatch(setUserPrivileges(userPrivileges))
// 		}

// 		const isMobileView = displayModeCheck();
// 		dispatch(setIsMobileView(isMobileView))

// 		dispatch(getUserProfile({
// 			getOrgProfile: true
// 		}));

// 		dispatch(loginSuccess(response));

// 	} catch (e) {
// 		if (e.message === 'Request failed with status code 500') {
			
// 			const loginFailureResponse = { 
// 				loginError: true,
// 				message: 'We are unable to authenticate this email or password'
// 			}
// 			dispatch(loginFailure(loginFailureResponse));
// 		} else if (e.message === 'Network Error') {
// 			dispatch(loginFailure(e.message));
// 			// throw new SubmissionError({
// 			// 	password: 'No connection to the network. Please try again later'
// 			// });
// 		}
// 	}
// };

// Launchdeck version 
export const launchdeckLogin = (email, password) => {
	return async dispatch => {
		dispatch(signOutClear());
		dispatch(loginRequest());
		try {
			const response = await axiosInstance.post('/user-sessions', {email, password});

			const { data } = response.data;
			const userSession = data.userSessions && data.userSessions[0];
			const userData = userSession && userSession.users[0];

			const {userType, token} = userSession || {};
			const {idToken} = userData || {};

			if (token) {
				const userPrivileges = data && data.userPrivileges;

				auth.storeToken(token);
				userPrivileges && localStorage.setItem('USER_PRIVILEGES', JSON.stringify(userPrivileges));
				localStorage.setItem('USER_CATG', userType);  
				localStorage.setItem('USER_ID_TOKEN', idToken); 
				dispatch(getUserProfile({
					getOrgProfile: true
				}));
			} else if (response.data.data.isLoginError) {
				const {errorMessage} = response.data.data.errorPayload
				dispatch(loginFailure({
					isLoginError: true,
					errorMessage: errorMessage? errorMessage : 'We are unable to authenticate this email or password'
				}));
				// set error messages here
			} else {

				dispatch(loginFailure({
					isLoginError: true,
					errorMessage: 'We are unable to authenticate this email or password'
				}));
			}
			// auth.storeToken(data.userSessions[0].token);
			

			// Google Tag
			// window.gtag('config', 'G-ZRC2SM4PSB' , {'user_id': idToken});
			


			dispatch(loginSuccess(response));

		} catch (e) {
			if (e.message === 'Request failed with status code 500') {
				
				const loginFailureResponse = { 
					loginError: true,
					message: 'We are unable to authenticate this email or password'
				}
				dispatch(loginFailure(loginFailureResponse));
			} else if (e.message === 'Network Error') {
				dispatch(loginFailure(e.message));
				// throw new SubmissionError({
				// 	password: 'No connection to the network. Please try again later'
				// });
			}
		}
	}
};


// TODO: Google Login (Currently mothballed for later implementation)
// export const googleLogin = (googleData, muid) => {
// 	return async dispatch => {
// 		dispatch(signOutClear());
// 		dispatch(loginRequest());
// 		try {
// 			const response = await axiosInstance.post('/user-sessions/google-login', {
// 				token: googleData.tokenId,
// 				muid
// 			});
// 			const { data } = response.data;
// 			const userSession = data && data.userSessions[0];
// 			const userData = userSession && userSession.users[0];
	
// 			const {userType} = userSession || {};
// 			const {idToken} = userData || {};
	
		
// 			auth.storeToken(data.userSessions[0].token);
// 			localStorage.setItem('USER_CATG', userType); 
// 			localStorage.setItem('USER_ID_TOKEN', idToken);
// 			dispatch(loginSuccess(response));
// 			dispatch(getUserProfile());
// 		} catch (e) {
// 			if (e.message === 'Request failed with status code 500') {
// 				const loginFailureResponse = { 
// 					loginError: true,
// 					message: 'Whoops, we are unable to authenticate with your Google credentials. Try logging in with your email in the mean time?'
// 				}
// 				dispatch(loginFailure(loginFailureResponse));
// 			} else if (e.message === 'Network Error') {
// 				dispatch(loginFailure(e.message));
// 				throw new SubmissionError({
// 					password: 'No connection to the network. Please try again later'
// 				});
// 			}
// 		}
// 	}
// }


// Signup actions
function signupRequest() {
	return {
		type: types.SIGNUP_REQUEST
	};
}

function signupSuccess(response) {
	return {
		type: types.SIGNUP_SUCCESS,
		payload: response
	};
}

function signupFailure(signupFailureResponse) {
	return {
		type: types.SIGNUP_FAIL,
		payload: signupFailureResponse
	};
}

export function manageSignup(signupPayload) {
	return async dispatch => {
		dispatch(signupRequest());
		try {
			const header = auth.getContentType();
			const response = await axiosInstance.post('/users', signupPayload, header);

			// Logs in user
			if (response.data.data.nextEvent === "log-in-and-redirect-to-homepage") {
				if (response.data.data.userSession) {
					// Logs in user
					const {userType, token, userIdToken} = response.data.data.userSession || {};
					const userPrivileges = response.data.data.userPrivileges;
		
					if (token && userType && userIdToken) {
						auth.storeToken(token);
						userPrivileges && localStorage.setItem('USER_PRIVILEGES', JSON.stringify(userPrivileges));
						localStorage.setItem('USER_CATG', userType);  
						localStorage.setItem('USER_ID_TOKEN', userIdToken); 
					}

					delete response.data.data.userSession;
					
					dispatch(getUserProfile({getOrgProfile: true}));
				}
			}
		
			dispatch(signupSuccess(response.data.data));
		} catch (error) {
			
			if (error.message === 'Request failed with status code 500') {
				const signupFailureResponse = { 
					signupError: true,
					type: 'Account already exists',
					message: 'You may have registered for an account with this email address.'
				}
				dispatch(signupFailure(signupFailureResponse));
				
			} else if (error.message === 'Network Error') {
				const signupFailureResponse = { 
					signupError: true,
					type: 'Network Error',
					message: 'Network connectivity issues detected. Please check your internet connection, and try again later.'
				}
				dispatch(signupFailure(signupFailureResponse));
			
			}
		}
	}
}


// Reset Password Request
function resetPasswordRequest() {
	return {
		type: types.RESET_PASSWORD_REQUEST
	};
}

function resetPasswordSuccess(response) {
	return {
		type: types.RESET_PASSWORD_SUCCESS,
		payload: response.data
	};
}

function resetPasswordFailure(error) {
	return {
		type: types.RESET_PASSWORD_FAIL,
		payload: error
	};
}

export function resetPassword(email) {
	return async dispatch => {
		dispatch(resetPasswordRequest());
		try {
			const header = auth.getContentType();
			const response = await axiosInstance.put('/users/reset-password', {email}, header);
		
			dispatch(resetPasswordSuccess(response));
		} catch (error) {
			dispatch(resetPasswordFailure(error.message));
		}
	}	
}

// Set New Password
function setNewPasswordRequest() {
	return {
		type: types.SET_NEW_PASSWORD_REQUEST
	};
}

function setNewPasswordSuccess(response) {
	return {
		type: types.SET_NEW_PASSWORD_SUCCESS,
		payload: response.data
	};
}

function setNewPasswordFailure(error) {
	return {
		type: types.SET_NEW_PASSWORD_FAIL,
		payload: error
	};
}

export function setNewPassword(inputPassword, resetPasswordToken) {
	
	return async dispatch => {
    dispatch(setNewPasswordRequest());
	try {
		
		const header = auth.getContentType();
		const response = await axiosInstance.put('/users/set-new-password', {inputPassword, resetPasswordToken}, header); 
	
		dispatch(setNewPasswordSuccess(response));
		
	} catch (error) {
		dispatch(setNewPasswordFailure(error.message));
		}
	}
}

// Set New User Password (Invited to DSR) 
// function setNewUserPasswordRequest() {
// 	return {
// 		type: types.SET_NEW_USER_PASSWORD_REQUEST
// 	};
// }

// function setNewUserPasswordSuccess(response) {
// 	return {
// 		type: types.SET_NEW_USER_PASSWORD_SUCCESS,
// 		payload: response.data
// 	};
// }

// function setNewUserPasswordFailure(error) {
// 	return {
// 		type: types.SET_NEW_USER_PASSWORD_FAIL,
// 		payload: error
// 	};
// }

// export function setNewUserPassword(inputPassword, newUserId) {
	
// 	return async dispatch => {
//     dispatch(setNewUserPasswordRequest());
// 	try {
		
// 		const header = auth.getContentType();
// 		const response = await axiosInstance.put('/users/invited/set-password', {inputPassword, newUserId}, header); 
		
// 		if (response.data.data.updateStatus === true) {
// 			auth.storeToken(response.data.data.token);
// 			localStorage.setItem('USER_ID_TOKEN', response.data.data.userIdToken);

// 			dispatch(setNewUserPasswordSuccess(response));
// 		}
		

		
		
// 	} catch (error) {
// 		dispatch(setNewUserPasswordFailure(error.message));
// 		}
// 	}
// }


// Verify New Email
function verifyEmailRequest() {
	return {
		type: types.VERIFY_EMAIL_REQUEST
	};
}

function verifyEmailSuccess(response) {
	return {
		type: types.VERIFY_EMAIL_SUCCESS,
		payload: response.data
	};
}

function verifyEmailFailure(error) {
	return {
		type: types.VERIFY_EMAIL_FAIL,
		payload: error
	};
}

export function verifyEmail(verifyEmailToken) {
	return async dispatch => {
    dispatch(verifyEmailRequest());
	try {
		const header = auth.getContentType();
		const response = await axiosInstance.put('/users/verify-email', {verifyEmailToken}, header); 
	
		dispatch(verifyEmailSuccess(response));
	} catch (error) {
		dispatch(verifyEmailFailure(error.message));
		}
	}
}

// Change To New Password (Special Import Used - DO NOT DUPLICATE )
function changeToNewPasswordRequest() {
	return {
		type: userActionTypes.UPDATE_USER_PASSWORD_REQUEST
	};
}

function changeToNewPasswordSuccess(response) {
	return {
		type: userActionTypes.UPDATE_USER_PASSWORD_SUCCESS,
		payload: response.data
	};
}

function changeToNewPasswordFailure(error) {
	return {
		type: userActionTypes.UPDATE_USER_PASSWORD_FAILURE,
		payload: error
	};
}

export function changeToNewPassword(currentPassword, inputPassword) {
	return async dispatch => {
    dispatch(changeToNewPasswordRequest());
	try {
		const url = '/me/user-new-pw';
			const headers = await auth.getAccessToken();
			const response = await axiosInstance.put(
				url,
				{
					currentPasswordInput: currentPassword,
					newPassword: inputPassword
				},
				{ headers }
			);

		dispatch(changeToNewPasswordSuccess(response));
	} catch (error) {
		dispatch(changeToNewPasswordFailure(error.message));
		}
	}
}



// Delete Account
function deleteAccountRequest() {
	return {
		type: types.DELETE_ACCOUNT_REQUEST
	};
}

function deleteAccountSuccess(response) {
	return {
		type: types.DELETE_ACCOUNT_SUCCESS,
		payload: response.data
	};
}

function deleteAccountFailure(signupFailureResponse) {
	return {
		type: types.DELETE_ACCOUNT_FAILURE,
		payload: signupFailureResponse
	};
}

export function deleteAccount({useCase}) {
	return async dispatch => {
		dispatch(deleteAccountRequest());
		try {
			const url = (useCase ==="delete-no-org-user") ? '/users/delete-account' : (useCase ==="delete-organization") ? '/organizations/delete-org-all-accounts' : ""; 
			const headers = await auth.getAccessToken();
			
			const response = await axiosInstance.post(url, {}, 	{ headers });

			if (useCase ==="delete-organization") {
				dispatch(signOut());
			}
			
			if (!!response.data.data.userDeleted) {
				dispatch(signOut());
			}

			// TODO: Review if deleteAccountSuccess response is required
			dispatch(deleteAccountSuccess(response));
		} catch (error) {
			
				const deleteAccountFailureResponse = { 
					deleteAccountError: true,
					type: 'Unexpected Error',
					message: 'There was an unexpected error. Please contact us and let us get this sorted'
				}
				dispatch(deleteAccountFailure(deleteAccountFailureResponse));
				
		}
	}
}
