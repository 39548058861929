import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';

import { Box, Modal, ModalOverlay, ModalBody, ModalContent, Flex  } from "@chakra-ui/react";

import PageLoader from '../../global/components/helper-components/components/PageLoader';
import { RiDeleteBin2Fill } from "react-icons/ri";
import { updateDsrAction } from '../actions/dsr-actions';
import LaunchdeckCloseButton from '../../global/components/helper-components/components/LaunchdeckCloseButton';
import LaunchdeckButton from '../../global/components/helper-components/components/LaunchdeckButton';


function DeleteActionModal(props) {

  const { dsrId, mapCategoryId, actionItemToEdit, showModal, handleCloseDeleteActionModal, updateDsrActionOutcome } = props;
  const accentColor = 'pink.500';

  // UX Management
  const [showLoader, setShowLoader] = useState(false)


  useEffect(() => {
    if (updateDsrActionOutcome) {
      setShowLoader(false)
      handleCloseDeleteActionModal()
    }

  }, [updateDsrActionOutcome, handleCloseDeleteActionModal]);


  // Functions
  function handleClickDeleteAction() {
    const { actionId } = actionItemToEdit || {};
   
    const updateActionMetadata = {
      dsrId,
      mapCategoryId,
      actionId,
      event: "delete-action"
    }

    setShowLoader(true);
    props.action.updateDsrAction(updateActionMetadata)

  }

  function renderDeleteActionModalContent() {
    if (showLoader) {
      return (
        <Box w="100%" align="center" >
          <Box px='1em'>
            <Box>
              <PageLoader />
            </Box>
          </Box>
        </Box>
      )
    }

    if (!showLoader) {
      return (
        <React.Fragment>
          <Flex justify='center' mt='1em' mb='1.6em' w='100%' px='1em'>
            <Box w="100%" m="2em 1em" textAlign="left" fontSize='0.875em' color='brandDark.500'>
              Are you sure about deleting this action task?
            </Box>
          </Flex>

          <Flex w='100%' borderTop='1px solid' borderTopColor='gray.300'>
            <Flex my='0.625em' justify='space-between' w='100%' px='1em'>
              <LaunchdeckButton fontSize='0.9em' bg='gray.200' onClick={handleCloseDeleteActionModal} label='Cancel' />
            	<LaunchdeckButton bg={accentColor} color='gray.50' fontSize='0.9em'  onClick={() => handleClickDeleteAction()} label='Yes, delete' />
            </Flex>
          </Flex>
        </React.Fragment>
      )
    }
  }


  // Render
  if (!showModal) {
    return null;
  }

  if (showModal) {
    return (
      <Modal isOpen={true} closeOnOverlayClick={false} isCentered={true} >
        <ModalOverlay />
        <ModalContent rounded='0.25em' minW={['90%', '500px']} maxW='700px'>
          <ModalBody px='0em'>
            <Flex direction='column' justify='space-around' alignItems='center' w='100%'>
              <Flex justify='space-between' align='center' w='100%' borderBottom='1px solid' borderBottomColor='gray.300'>
                <Flex px='1.5em' py='0.5em' justify='flex-start' align='center'>
                  <Box as={RiDeleteBin2Fill} color={accentColor} boxSize="1.6em" py='3%' />
                  <Box ml='1em' fontWeight='500' fontSize='1.1em' lineHeight='1.375em' color='brandDark.500'>Delete action item</Box>
                </Flex>
                <Box pr='1.5em'>
                  <LaunchdeckCloseButton handleCloseModal={handleCloseDeleteActionModal} />
                </Box>
              </Flex>

              {renderDeleteActionModalContent()}

            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    )
  }
}

function mapStateToProps(state) {
  return {
    updateDsrActionOutcome: state.updateDsrActionOutcome.results.data
  };
}

function mapDispatchToProps(dispatch) {
  return {
    action: bindActionCreators(
      {
        updateDsrAction
      },
      dispatch
    )
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DeleteActionModal));