import { CHANGE_SELECT_SUBPAGE, RESET_DSR_SELECTED_DISPLAY_SUBPAGE, CHANGE_DSR_PAGE_DISPLAY } from '../../actions/dsr-actions-types'

// Original
const INITIAL_STATE_DSR_SUBPAGE = { selectedDisplaySubpage: 'overview-subpage' }

// For development use only: to make a particular subpage as default for development convenience 
// const INITIAL_STATE_DSR_SUBPAGE = { selectedDisplaySubpage: 'action-list-subpage' }

export function dsrPageReducer(state = INITIAL_STATE_DSR_SUBPAGE, action) {
    switch (action.type) {
        case CHANGE_SELECT_SUBPAGE:
            return { selectedDisplaySubpage: action.payload };
        case CHANGE_DSR_PAGE_DISPLAY:
            return { selectedDisplayPageId: action.payload };
        case RESET_DSR_SELECTED_DISPLAY_SUBPAGE:
            return INITIAL_STATE_DSR_SUBPAGE
        default:
            return state;
    }
}