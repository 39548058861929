import React, { useState } from 'react';
import { Box, Menu, MenuButton, MenuItem, MenuList, Grid, Divider } from '@chakra-ui/react';
import { generateRandomString } from '../../../global/helpers/global-helpers';
import { IoTextSharp, IoLogoYoutube, IoDocumentTextOutline } from 'react-icons/io5';
import { MdOutlineImage, MdOutlineVideocam, MdOutlineCallToAction } from 'react-icons/md';
import { PiBracketsAngleBold, PiMinus } from 'react-icons/pi';
import { CgSpaceBetweenV } from "react-icons/cg";
import { SiLoom } from 'react-icons/si';
import { FiUserCheck } from "react-icons/fi";
import { optionTypes } from "./types/subWidgetOptionsTypes";
import { BsSoundwave } from 'react-icons/bs';

export const AddSubWidget = ({ items, setItems, widget, dsrOwnerId }) => {
  const [isSubWidgetDropdownOpen, setIsSubWidgetDropdownOpen] = useState(false);

  const subWidgetDefaultOptions = (type) => {
    let option = {};

    switch(type) {
      case 'content-divider':
        option = { [optionTypes.STRENGTH]: 'medium' }
        break;

      case 'normal-text':
        option = { [optionTypes.ALIGN]: 'left' }
        break;

      case 'title-text':
      option = { [optionTypes.ALIGN]: 'left' }
      break;

      case 'user-signature':
        option = { [optionTypes.ALIGN]: 'left' }
        break;

      case 'content-spacer':
        option = { [optionTypes.HEIGHT]: '1em' }
        break;

      case 'cta-button':
      option = {
        [optionTypes.ALIGN]: 'center',
        [optionTypes.VARIANT]: 'primary'
      }
      break;

      default:
        option = {}
        break
    }

    return option;
  }

  const subWidgetDefaultContent = (type) => {
    let content = '';

    switch(type) {
      case 'content-divider':
        content = 'divider'
        break;

      case 'content-spacer':
        content = 'spacer'
        break;

      case 'user-signature':
        content = `${dsrOwnerId}`
        break;

      default:
        content = ''
        break
    }

    return content;
  }


  const addItem = (type) => {
    const columnId = Object.values(widget.widgetContent)[0].columnId

    let newSubWidget = {
      id: generateRandomString(10),
      contentBlockType: type || null,
      content: subWidgetDefaultContent(type),
      options: subWidgetDefaultOptions(type)
    }

    if (newSubWidget.contentBlockType !== null) {
      const objectWithNewItem = {
        ...items[widget.contentBlockId],
        widgetContent: {
          ...items[widget.contentBlockId].widgetContent,
          [columnId]: {
            ...items[widget.contentBlockId].widgetContent[columnId],
            subWidgets: {
              ...items[widget.contentBlockId].widgetContent[columnId].subWidgets,
              [newSubWidget.id]: newSubWidget
            }
          }
        }
      }

      setItems({
        ...items,
        [widget.contentBlockId]: objectWithNewItem
      });
    }
  };

  const menuItems = [
    {
      type: 'title-text',
      text: 'Title text',
      subText: 'Pre-formatted section title to help with formatting',
      icon: <IoTextSharp />
    },
    {
      type: 'normal-text',
      text: 'Text block',
      subText: 'Paragraph body text. Perfect for descriptions',
      icon: <IoTextSharp />
    },
    {
      type: 'cta-button',
      text: 'Button',
      subText: 'Adds a call-to-action button',
      icon: <MdOutlineCallToAction />
    },
    {
      type: 'uploaded-image',
      text: 'Image',
      subText: 'Displays images uploaded from your device',
      icon: <MdOutlineImage />
    },
    {
      type: 'uploaded-banner',
      text: 'Banner image',
      subText: 'Displays a full-width banner image',
      icon: <MdOutlineImage />
    },
    // {
    //   type: 'url-image',
    //   text: 'Image by URL',
    //   subText: 'Add images by URL',
    //   icon: <MdOutlineImage />
    // },
    {
      type: 'uploaded-audio',
      text: 'Audio',
      subText: 'Plays an audio file chosen from your device',
      icon: <BsSoundwave />
    },
    {
      type: 'uploaded-video',
      text: 'Video',
      subText: 'Embeds an uploaded video chosen from your device',
      icon: <MdOutlineVideocam />
    },
    {
      type: 'uploaded-pdf',
      text: 'PDF',
      subText: 'Displays a PDF document in a scrollable reader',
      icon: <IoDocumentTextOutline />
    },
    {
      type: 'embed-document',
      text: 'Google document',
      subText: 'Button that opens up a Google doc',
      icon: <PiBracketsAngleBold />
    },
    {
      type: 'content-divider',
      text: 'Divider',
      subText: 'A simple line to help format content',
      icon: <PiMinus />
    },
    {
      type: 'content-spacer',
      text: 'Spacer',
      subText: 'An empty breather space to help format content',
      icon: <CgSpaceBetweenV />
    },
    {
      type: 'user-signature',
      text: 'Signature',
      subText: 'Add a dash of personal touch with a signature',
      icon: <FiUserCheck />
    }
  ];

  const specialMenuItems = [
    {
      type: 'youtube-video',
      text: 'YouTube video',
      subText: 'Embed a video from YouTube',
      icon: <IoLogoYoutube fill='red' />
    },
    {
      type: 'loom-video',
      text: 'Record a video',
      subText: 'Using Loom recording tool',
      icon: <SiLoom fill='#8468fd' />
    }
  ];

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <Menu placement="auto" strategy="fixed" isOpen={isSubWidgetDropdownOpen} onClose={() => setIsSubWidgetDropdownOpen(false)}>
        <MenuButton
          borderWidth="1px"
          borderRadius="20px"
          h="40px"
          w="40px"
          color="#29ABE2"
          fontSize="20px"
          pb="5px"
          onClick={() => { setIsSubWidgetDropdownOpen(!isSubWidgetDropdownOpen) }}
        >
          +
        </MenuButton>
        <MenuList zIndex='100' boxShadow={'lg'}>
          <Grid templateColumns="repeat(2, 1fr)" margin="10px" minW={'780px'}>
            {menuItems.map((item) => {

              return (
                <AddSubWidgetMenuItem key={generateRandomString(5)} item={item} addItem={addItem}/>
              )
            })}
          </Grid>

          <Divider mr="10px" ml="10px" borderBottomWidth="2px"/>

          <Grid templateColumns="repeat(2, 1fr)" margin="10px">
            {specialMenuItems.map((item) => {
              return (
                <AddSubWidgetMenuItem key={generateRandomString(5)} item={item} addItem={addItem}/>
              )
            })}
          </Grid>
        </MenuList>
      </Menu>
    </Box>
  )
};

const AddSubWidgetMenuItem = ({item, addItem}) => {
  return (
    <MenuItem onClick={() => addItem(item.type)}>
    <Box
      display="flex"
      justifyContent="left"
      alignItems="center"
      flexDirection="row"
      width="100%"
      marginBottom="10px"
      marginTop="10px"
      borderRadius="10px"
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        padding="5px"
        marginRight="10px"
        borderRadius="lg"
        borderWidth="1px"
        width="40px"
        height="40px"
        background="white"
      >
        {item.icon}
      </Box>
      <Box>
        <Box>{item.text}</Box>
        {item.subText && <Box color='grey' fontSize='12px'>{item.subText}</Box>}
      </Box>
    </Box>
  </MenuItem>
  )
}
