import { SET_DSR_DRAFT_STATUS } from '../../actions/dsr-actions-types'

const INITIAL_STATE_DSR_DRAFT_STATE = { status: '' }

export function dsrStatusReducer(state = INITIAL_STATE_DSR_DRAFT_STATE, action) {
    switch (action.type) {
        case SET_DSR_DRAFT_STATUS:
            return { status: action.payload };
        default:
            return state;
    }
}