import React from 'react';

import { Box, Modal, ModalOverlay, ModalBody, ModalContent, Flex , Grid } from "@chakra-ui/react";
import { getUserDetailsUsingDsrMembersArray } from '../../profile/helpers/user-helper';
import { IoCalendarOutline } from "react-icons/io5";
import { formatDisplayDateOnly } from '../helpers/timestamp-display-helper';
import DsrDisplayAssignedMembersAvatar from '../dsr-team-mgmt/DsrDisplayAssignedMembersAvatar';

import { AiFillCheckCircle } from 'react-icons/ai'
import { GoCircle } from 'react-icons/go'
import LaunchdeckCloseButton from '../../global/components/helper-components/components/LaunchdeckCloseButton';

export default function DisplayActionDetailsModal(props) {

  const { showModal, actionItem, handleCloseActionDetailsModal, dsrTeamMembers, isMobileView } = props || {};

  const renderStatusTag = (actionStatus) => {
    switch (actionStatus) {
      case ('active'): {

        return (
          <Flex position='relative' justify='flex-start'>
            <Flex rounded='36px' bg='#EDF2F7' color='gray.500' px='0.5em' py='0.375em' justify='space-apart' align='center'>
            <Box as={GoCircle} />
            <Box px='0.5em'>To do</Box>
            {/* <Box as={GoChevronDown} boxSize="0.75em" color='#2D3748'/> */}
          </Flex>
          </Flex>
        )
      }
      case ('completed'): {
        return (
          <Flex position='relative' justify='flex-start'>
            <Flex rounded='36px' bg='#48BB78' color='white' px='0.5em' py='0.375em' justify='space-apart' align='center'>
            <Box as={AiFillCheckCircle} />
            <Box px='0.5em'>Completed</Box>
            {/* <Box as={GoChevronDown} boxSize="0.75em" /> */}
          </Flex>
          </Flex>
         
        )
      }
      default: {
        console.log('Error Code BSTBOJ: There is no action status available.')
        return null;
      }
    }
  }

  const renderMemberName = (userId) => {
    let memberName = "";

    if (userId) {
      const userProfile = getUserDetailsUsingDsrMembersArray(dsrTeamMembers, userId);
      memberName = userProfile ? userProfile.memberName : '-'
    }

    return memberName;
    
  }

  if (!showModal) {
    return null;
  }

  if (showModal) {
    const { actionStatus, actionCreatedAt, actionDateCompleted, assignedToUserId, actionDueDate, actionTaskDescription, actionTaskTitle, actionCreatedByUserId, actionCompletedByUserId } = actionItem || {};

    const displayedDueDate = actionDueDate && formatDisplayDateOnly(actionDueDate)

    return (
      <Modal isOpen={true} closeOnOverlayClick={false} isCentered={true} preserveScrollBarGap={true} >
        <ModalOverlay />
        <ModalContent rounded='0.25em' minW={['90%', '500px']} maxW='700px'>
          <ModalBody px='0em'>
            <Flex direction='column' justify='space-around' alignItems='center' w='100%'>

              <Flex justify='space-between' align='center' w='100%' py='1em'>
                <Flex p='0.5em 2em' justify='flex-start' align='center'>
                  <Box fontWeight='500' fontSize='1.25em' lineHeight='1.375em' color='brandDark.500'>{actionTaskTitle}</Box>
                </Flex>
                <Box pr='1.5em'>
                  <LaunchdeckCloseButton handleCloseModal={() => handleCloseActionDetailsModal()} />
                </Box>
              </Flex>

              <Grid className='action-detail-container' templateColumns={isMobileView ? "5em auto" : "7.75em auto"} gap='2em' align='center'>
                <Box className="action-detail-label">
                  Description
                </Box>

                <Box className="action-detail-text-value">
                  {actionTaskDescription ? actionTaskDescription : "-"}
                </Box>
              </Grid>

              <Grid className='action-detail-container' templateColumns={isMobileView ? "5em auto" : "7.75em auto"} gap='2em' align='center'>
                <Box className="action-detail-label">
                  Due date
                </Box>

                <Box className="action-detail-display">
                  {displayedDueDate ?
                    <Flex whiteSpace='nowrap' w='100%' justify='flex-start'>
                      <Flex className='due-date-button' cursor='default'>
                        <Box as={IoCalendarOutline} boxSize='1em' className='due-date-button__icon' />
                        <Box className='due-date-button__button-text'>{displayedDueDate}</Box>

                      </Flex>
                    </Flex> : <Box className="action-detail-text-value">
                      {"-"}
                    </Box>
                  }
                </Box>
              </Grid>

              <Grid className='action-detail-container' templateColumns={isMobileView ? "5em auto" : "7.75em auto"} gap='2em' align='center'>
                <Box className="action-detail-label">
                  Status
                </Box>

                <Box className="action-detail-display">
                  {renderStatusTag(actionStatus)}
                </Box>
              </Grid>

              <Grid className='action-detail-container' templateColumns={isMobileView ? "5em auto" : "7.75em auto"} gap='2em' align='center'>
                <Box className="action-detail-label">
                  Assigned to
                </Box>

                <Box className="action-detail-display">
                  <Box ml={isMobileView ? '-1em' : '-1.5em'}>
                    <DsrDisplayAssignedMembersAvatar
                      dsrTeamMembers={dsrTeamMembers}
                      assignedToUsers={assignedToUserId}
                      maxDisplayedAvatars={5}
                    />
                  </Box>
                </Box>
              </Grid>

              <Grid className='action-detail-container' templateColumns={isMobileView ? "5em auto" : "7.75em auto"} gap='2em' align='center'>
                <Box className="action-detail-label">
                  Created
                </Box>

                <Box className="action-detail-display">
                  {actionCreatedAt ? `${formatDisplayDateOnly(actionCreatedAt)} by ${renderMemberName(actionCreatedByUserId)}` : "-"}
                </Box>
              </Grid>

              <Grid className='action-detail-container-last-row' templateColumns={isMobileView ? "5em auto" : "7.75em auto"} gap='2em' align='center'>
                <Box className="action-detail-label">
                  Completed
                </Box>

                <Box className="action-detail-display">
                  {actionDateCompleted ? `${formatDisplayDateOnly(actionDateCompleted)} by ${renderMemberName(actionCompletedByUserId)}` : "-"}
                </Box>
              </Grid>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    )
  }


}
