import React, { useEffect, useRef, useState } from "react";
import { Flex, useOutsideClick } from '@chakra-ui/react';

import { Box, } from "@chakra-ui/react";
import { FaChevronDown, FaTimes } from "react-icons/fa";
import { getUserDetailsUsingDsrMembersArray } from "../../profile/helpers/user-helper";

export default function SharedDocsOwnerFilter ({ 
  dsrTeamMembers, 
  sharedDocs, 
  handleFilterOwner 
}) {

  // Manage dropdown menu UX
  const ownerFilterButtonRef = useRef(null);

  const [ownerArray, setOwnerArray] = useState([])
  const [showMenu, setShowMenu] = useState(false)
  const [pickedOwner, setPickedOwner] = useState("")

  useOutsideClick({
    ref: ownerFilterButtonRef,
    handler: () => setShowMenu(false),
  })

  useEffect(() => {
    if (sharedDocs && dsrTeamMembers) {
      let uniqueOwnerArray = [];
      const docOwnerIds = sharedDocs.map(doc => doc.docOwnerId);
      const uniqueDocOwnerIds = [...new Set(docOwnerIds)];

      for (const docOwnerId of uniqueDocOwnerIds) {
        const owner = getUserDetailsUsingDsrMembersArray(dsrTeamMembers, docOwnerId)
        uniqueOwnerArray = owner && [...uniqueOwnerArray, owner]
      }

      setOwnerArray(uniqueOwnerArray)
    }
  }, [sharedDocs, dsrTeamMembers])


  const handlePickOwner = (owner) => {
    setPickedOwner(owner ? owner : "")
    handleFilterOwner(owner ? owner : "")
    setShowMenu(false)
  }

  const renderOwnerOptions = () => {
    const renderNames = () => {
      const nameList = Array.isArray(ownerArray) && ownerArray?.map(owner => {
        const { memberName, userId } = owner || {};

        return <Box key={`shared-doc-owner-${userId}`} py='0.2em'>
          <Box cursor='pointer' fontSize='0.875em' onClick={() => handlePickOwner(owner)}>{memberName}</Box>
        </Box>
      })
      return nameList
    }
    return <Box>
      {renderNames()}
    </Box>
  }

  return (
    <Box position='relative' ref={ownerFilterButtonRef}>
      <Flex className='shared-doc-page-filter-button'
        onClick={() => pickedOwner ? handlePickOwner("") : setShowMenu(!showMenu)}>
        <Box className='shared-doc-page-filter-button__button-text'>{pickedOwner ? pickedOwner.memberName : "Owner"}</Box>
        <Box as={pickedOwner? FaTimes: FaChevronDown} fill='brandDark.500' fontSize='0.875em' className='create-new-room-button__add-icon' />
      </Flex>

      {showMenu &&
        <Box position='absolute' top='3.5em' left='-3.5em' w='15em' bg='white' border='1px solid' borderColor='gray.200' rounded='0.5em'>
          <Box p='1em'>
            {renderOwnerOptions()}
          </Box>
        </Box>
      }
    </Box>
  )

}

