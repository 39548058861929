import React, { memo, useEffect } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { IconButton, Menu, MenuButton, MenuItem, MenuList, useToast } from '@chakra-ui/react'

import config from "../../../../config/app-config";
import { redirectToNewPage, triggerLaunchdeckToast } from '../../../global/helpers/global-helpers';
import { CgOptions } from "react-icons/cg";

function DsrCardOptionsButton({ 
  dsrId, 
  dsrTitle, 
  dsrStatus, 
  createDuplicateDsrOutcome, 
  isMobileView, 
  handleDuplicateDsrModal, 
  handleCreateDsrTemplateModal
}) {

  const toast = useToast();
  const { displayUrl } = config;


  useEffect(() => {
    if (createDuplicateDsrOutcome) {
      const { dsrSuccessfullyDuplicated, createdDsrPath } = createDuplicateDsrOutcome || {};

      if (dsrSuccessfullyDuplicated === true && createdDsrPath) {
        const redirectUrl = `${displayUrl}${createdDsrPath}`
        redirectToNewPage(redirectUrl)
      }
    }

  }, [createDuplicateDsrOutcome, displayUrl])

  const copyLink = () => {
    // Copy into clipboard
    const shareLink = (dsrStatus === "active") ? `${displayUrl}/dsr/access/${dsrId}` 
        : (dsrStatus === "draft" || dsrStatus === "reverted-draft") ? `${displayUrl}/draft/${dsrId}`
        : ""

    shareLink && navigator.clipboard.writeText(shareLink)

    toast(
      triggerLaunchdeckToast({
        label: "Link copied",
        isMobileView
      })
    )
  }

  const duplicateDsr = () => {
    handleDuplicateDsrModal("duplicate-dsr", {
      dsrIdToDuplicate: dsrId,
      dsrTitle
    })
  }

  const makeDsrTemplate = () => {
    handleCreateDsrTemplateModal("make-dsr-template", {
      sourceDsrId: dsrId,
      dsrTitle
    })
  }

  return (
    <React.Fragment>

      <Menu isLazy placement="bottom-end">
        <MenuButton
          as={IconButton}
          aria-label='Options'
          icon={<CgOptions />}
          variant='outline'
          size='2em'
          p='0.25em'
        />
        <MenuList px='0.5em' fontSize='0.875em'>
          <MenuItem name='copy-link-option' className='filter-menu__dropdown-option-display' onClick={() => copyLink()}  >Copy share link</MenuItem>
          <MenuItem name='duplicate-dsr-option' className='filter-menu__dropdown-option-display' onClick={() => duplicateDsr()}  >Duplicate new Room</MenuItem>
          {/* // UUU : Work in progress : org templates */}
          {/* <MenuItem name='make-template-option' className='filter-menu__dropdown-option-display' onClick={() => makeDsrTemplate()}>Make template</MenuItem> */}
        </MenuList>
      </Menu>

    </React.Fragment>
  );

}


const mapStateToProps = state => ({
  createDuplicateDsrOutcome: state.createDuplicateDsrOutcome.results.data, // UUU
  isMobileView: state.mobileViewMode.isMobileView
});


export default memo(withRouter(connect(mapStateToProps, null)(DsrCardOptionsButton)))