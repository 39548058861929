import {
  Box,
  Flex,
  Link,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Spinner,
  Td,
  Tr,
  useOutsideClick
} from "@chakra-ui/react";
import { formatDisplayDateOnly } from "../helpers/timestamp-display-helper";
import { FiExternalLink } from "react-icons/fi";
import React, { useRef, useState } from "react";
import { RiDeleteBin2Line } from "react-icons/ri";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { MdOutlineDragIndicator } from 'react-icons/md';
import { MdModeEdit } from 'react-icons/md';

export const TableViewSharedDocCard = ({
  isDsrAdmin,
  docId,
  docName,
  docSharedAt,
  formattedDocLink,
  memberName,
  fileKey,
  showDeleteSpinner,
  showPopoverDocId,
  setShowPopoverDocId,
  handleDeleteSharedDoc,
  handleEditSharedDoc,
  isOpen,
  onOpen,
  onClose,
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: docId });

  const sortableStyle = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? "100" : "auto",
    opacity: isDragging ? 0.3 : 1,
  };

  const [showOptions, setShowOptions] = useState(false)
  const tabRef = useRef(null);

  useOutsideClick({
    ref: tabRef,
    handler: () => setShowPopoverDocId(""),
  })

  return (
    <Tr key={docId} py="0.2em" style={sortableStyle} ref={setNodeRef} 
    onMouseOver={()=>isDsrAdmin && setShowOptions(true)}
    onMouseOut={()=>isDsrAdmin && setShowOptions(false)}
    >
      <Td {...listeners} {...attributes} cursor='grab'>
        {/* ⣿ */}
        {isDsrAdmin && <Box as={MdOutlineDragIndicator} color={'gray.500'} boxSize="1em" py='3%' />}
      </Td>
      <Td>
        <Flex textAlign={"left"} direction="column" justify="flex-start">
          <Link
            href={formattedDocLink}
            lineHeight="1.625em"
            fontSize="1.125em"
            isExternal
          >
            <Box className="truncate-long-text" maxW="22em">
              {docName}
            </Box>
          </Link>
        </Flex>
      </Td>
      <Td>{memberName}</Td>
      <Td>{formatDisplayDateOnly(docSharedAt)}</Td>
      <Td>
        <Flex justify="center" align="center">
          {showDeleteSpinner && showPopoverDocId === docId ? (
            <Spinner color="brand.500" />
          ) : (
            <Flex visibility={!!showOptions ? 'visible' : 'hidden'}>
              <Link
                href={formattedDocLink}
                fontSize="1.4em"
                isExternal
              >
                <FiExternalLink />
              </Link>

         
              <Box mx="0.5em">
                <Box
                  as={MdModeEdit}
                  color={"gray.500"}
                  boxSize="1.4em"
                  py="3%"
                  cursor="pointer"
                  onClick={() => handleEditSharedDoc(docId, docName, formattedDocLink, fileKey)}
                />
              </Box>
       

              <Popover
                isOpen={showPopoverDocId === docId && isOpen}
                // initialFocusRef={firstFieldRef}
                onOpen={onOpen}
                onClose={onClose}
                placement="right"
                closeOnBlur={false}
              >
                <PopoverTrigger>
                  <Box>
                    <Box
                      as={RiDeleteBin2Line}
                      color={"brandPink.500"}
                      boxSize="1.4em"
                      // py="3%"
                      cursor="pointer"
                      onClick={() => setShowPopoverDocId(docId)}
                    />
                  </Box>
                </PopoverTrigger>
                <PopoverContent p={5}>
                  <PopoverArrow />
                  <Flex
                    w="100%"
                    cursor="pointer"
                    onClick={() => handleDeleteSharedDoc(docId, fileKey)}
                  >
                    <Box color="brandPink.500">Yes, delete</Box>
                  </Flex>
                </PopoverContent>
              </Popover>
            </Flex>
          )}
        </Flex>
      </Td>
    
    </Tr>
  );
};
