import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'

import { Box , Flex, Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/react'
import '../styles/dsr-display.scss'
import { MdError } from 'react-icons/md'
import { updatePdsr, resetUpdatePdsrOutcome } from '../actions/dsr-actions'
import { redirectToNewPage } from '../../global/helpers/global-helpers'
import PageLoader from '../../global/components/helper-components/components/PageLoader'
import LaunchdeckButton from '../../global/components/helper-components/components/LaunchdeckButton'
import LaunchdeckCloseButton from '../../global/components/helper-components/components/LaunchdeckCloseButton'


function ProspectDsrDeleteModal(props) {

  const { pDsrId, handleCloseModal, updateProspectDsrOutcome,  updatePdsr,
    resetUpdatePdsrOutcome } = props || {};

  // UX Management
  const [showLoader, setShowLoader] = useState(false)

  useEffect(() => {
    if (updateProspectDsrOutcome) {
      const {updatePdsrStatus, sourceEvent} = updateProspectDsrOutcome || {};

      if (updatePdsrStatus && sourceEvent === "delete-prospect-dsr") {
        resetUpdatePdsrOutcome() 
        redirectToNewPage(`/prospectors`)
      }
    }

  }, [updateProspectDsrOutcome, resetUpdatePdsrOutcome]);

  const handleClickUnpublishButton = () => {
    setShowLoader(true);
    updatePdsr({ pDsrId, status: 'deleted', sourceEvent:"delete-prospect-dsr" })
  }

  function displayContent() {
    if (showLoader) {
      return (
        <Box w="100%" align="center" >
          <Box px='1em'>
            <Box>
              <PageLoader />
            </Box>
          </Box>
        </Box>
      )
    }

    if (!showLoader) {

      return (
        <Flex justify='flex-start' px='1.5em' mt='1em' w='100%'>
          <Box w='100%'>

            <Box w="100%" my="1em" color='brandDark.500' fontSize='0.875em'>
                You are about to delete this Prospector for good. Go ahead?
            </Box>

            <Flex w='100%' mt='1em'>
              <Flex my='0.625em' justify='flex-end' w='100%'>
                <LaunchdeckButton name='showRevertToDraft' label='Yes, delete this' fontSize='0.9em' py='0.8em' bg='pink.500' color='white' onClick={() => handleClickUnpublishButton()} />
              </Flex>
            </Flex>
          </Box>


        </Flex>
      )
    }
  }

  return (
    <Modal isOpen={true} closeOnOverlayClick={false} isCentered={true} >
      <ModalOverlay className="modal-background" />
      <ModalContent rounded='0.25em' minW={['90%', '500px']} maxW='700px'>
        <ModalBody px='0em'>
          <Flex direction='column' justify='space-around' alignItems='center' w='100%'>
            <Flex justify='space-between' w='100%' borderBottom='1px solid' borderBottomColor='gray.300'>
              <Flex px='1em' justify='flex-start' align='center'>
                <Box as={MdError} color='pink.500'
                  boxSize="1.6em" />
                <Box px='1em' py='0.5em' color='brandDark.500'>Delete Prospector</Box>
              </Flex>

              <Box pr='1.5em'>
                <LaunchdeckCloseButton handleCloseModal={() => handleCloseModal()} />
              </Box>
            </Flex>

            <React.Fragment>
              {displayContent()}
            </React.Fragment>

          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

function mapStateToProps(state) {
  return {
    updateProspectDsrOutcome: state.updateProspectDsrOutcome.results.data
  };
}

// function mapDispatchToProps(dispatch) {
//   return {
//     action: bindActionCreators(
//       {
//         updatePdsr,
//         resetUpdatePdsrOutcome
//       },
//       dispatch
//     )
//   };
// }

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
          updatePdsr,
          resetUpdatePdsrOutcome
        },
        dispatch,
    )

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProspectDsrDeleteModal));
