import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { IoChevronDownOutline } from 'react-icons/io5';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';

import {
  Avatar,
  Box,
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Menu, MenuButton, MenuList, MenuItem, Button, useToast,
  AvatarBadge,
  Icon
} from "@chakra-ui/react";
import '../styles/dsr-display.scss';
import '../styles/dsr-invite-display.scss';

import { editDsrMemberRole, resetEditDsrMemberRole, resetUpdateDsrOutcome, updateDsr } from '../actions/dsr-actions';
import { getUserAccountType, getUserDetailsUsingDsrMembersArray, sortArrayToListActiveMembersFirst } from '../../profile/helpers/user-helper';
import { getThisUserIdToken, triggerLaunchdeckToast } from '../../global/helpers/global-helpers';
import { checkAuthorizationToEditTeamRole } from '../helpers/dsr-helpers';
import ComponentLoader from '../../global/components/helper-components/components/ComponentLoader';
import DsrAddTeamMemberForm from './DsrAddTeamMemberForm';
import DsrInvitedUserOptionsButton from './DsrInvitedUserOptionsButton';
import DsrAccessSettingsForm from './DsrAccessSettingsForm';
import DsrJoinRequestMgmtModule from './DsrJoinRequestMgmtModule';
import LaunchdeckButton from '../../global/components/helper-components/components/LaunchdeckButton';
import LaunchdeckCloseButton from '../../global/components/helper-components/components/LaunchdeckCloseButton';
import DsrAddInternalTeamMemberForm from './DsrAddInternalTeamMemberForm';
import { BiChevronsUp } from 'react-icons/bi';
import { RiVerifiedBadgeFill } from 'react-icons/ri';

function DsrManageTeamModal(props) {

  const { dsrId, handleCloseModal,
    dsrUserTeam,
    isMobileView,
    isDsrAdmin, dsrTeamMembersRedux, dsrUiIndicators,
    editDsrTeamMembersOutcome, editDsrMemberRoleOutcome,
    handleChangeUserActionRequired,
    // Actions
    // updateDsr,
    // resetUpdateDsrOutcome,
    // Redux state
    // updateDsrOutcome,
    dsrPropertiesRedux

  } = props || {};

  const thisUserId = getThisUserIdToken();
  const toast = useToast();
  const initRef = useRef(false);

  const [displayTab, setDisplayTab] = useState('display-members-modal-tab');
  const [displayedTeamMembers, setDisplayedTeamMembers] = useState(dsrTeamMembersRedux)

  const [chosenUserId, setChosenUserId] = useState("")
  const [thisUserRole, setThisUserRole] = useState("")
  const [inviteMode, setInviteMode] = useState("search-email")

  // UX management
  const [showDropdownLoader, setShowDropdownLoader] = useState(false)
  const [displayGuestList, setDisplayGuestList] = useState(true);
  const [showIndicator, setShowIndicator] = useState(false);
  const [inviteMenuDisplayType, setInviteMenuDisplayType] = useState(false);

  useEffect(() => {
    const thisUserProfile = getUserDetailsUsingDsrMembersArray(dsrTeamMembersRedux, thisUserId);
    const userRole = thisUserProfile ? thisUserProfile.role : "";
    setThisUserRole(userRole)
    setDisplayedTeamMembers({ ...dsrTeamMembersRedux })
  }, [dsrTeamMembersRedux, thisUserId]);

  useEffect(() => {
    if (!initRef.current) {
      const userType = getUserAccountType();
      const isNoOrgUser = (userType === "no-org-user") ? true : false;

      if (!!isNoOrgUser) {
        setInviteMenuDisplayType('no-org-invite-options-only')
      } else {

        if (dsrUserTeam === "audience") {
          setInviteMenuDisplayType('guest-member-options')
        } else if (dsrUserTeam === "host") {
          if (isDsrAdmin) {
            setInviteMenuDisplayType('all-options')
          } else {
            setInviteMenuDisplayType('dsr-host-member-options')
          }
        }
      }

      initRef.current = true;
    }
  }, []);

  // Update new updated dsrTeamMembers
  useEffect(() => {
    if (editDsrMemberRoleOutcome) {
      setDisplayedTeamMembers(editDsrMemberRoleOutcome) // Refactor -> To update dsrTeamMembers state in redux
      setShowDropdownLoader(false)
      setChosenUserId("")

      toast(
        triggerLaunchdeckToast({
          useCase: "show-success-state",
          label: "Role successfully changed",
          isMobileView
        })
      )

      props.action.resetEditDsrMemberRole()
    }

  }, [editDsrMemberRoleOutcome, props.action, isMobileView, toast]);


  useEffect(() => {
    if (editDsrTeamMembersOutcome) {
      const { updatedSuccessfully, isRemovedUser, updatedTeamMembers } = editDsrTeamMembersOutcome || {};

      if (updatedSuccessfully === true && isRemovedUser) {
        setDisplayedTeamMembers(updatedTeamMembers)
      }

      //Show modal that something went wrong. Please try again later, using Generic Error Modal
      // if (updatedSuccessfully===false) {

      // }
    }

  }, [editDsrTeamMembersOutcome]);

  useEffect(() => {
    if (dsrUiIndicators) {
      const { outstandingJoinRequests } = dsrUiIndicators || {};

      if (typeof outstandingJoinRequests == "boolean") {
        setShowIndicator(outstandingJoinRequests)
      }
    }

  }, [dsrUiIndicators]);

  useEffect(()=> {
    if (dsrPropertiesRedux) {
      const {showGuestList} = dsrPropertiesRedux || {}
      if (typeof showGuestList === "boolean") {
        setDisplayGuestList(showGuestList)
      }
    }
  }, [dsrPropertiesRedux])

  const handleSaveDsrProperties = (event) => {
    if (dsrId && dsrPropertiesRedux) {

      if (event ==="save-hide-guest-list" && typeof displayGuestList === "boolean") {
        const latestDsrProperties = {
          ...dsrPropertiesRedux,
          showGuestList: !displayGuestList
        }

        props.action.updateDsr({
          dsrId: dsrId,
          dsrProperties: latestDsrProperties,
          sourceEvent: "save-room-properties"
        })
      }
     
    }
  }


  let renderTeam = '';
  let teamToRender = '';
  let teamHeading = '';
  let allowHideGuestList = false; 

  function renderTeamDisplay(perspective) {
    if (perspective === 'seller') {
      teamToRender = displayedTeamMembers["sellerTeam"];
      teamHeading = 'Hosts'
    } else if (perspective === 'buyer') {
      teamToRender = displayedTeamMembers["buyerTeam"];
      teamHeading = 'Guests'
      if (isDsrAdmin) {
        allowHideGuestList = true
      }
    } else return null;

    renderTeam = teamToRender?.map((organization, organizationIndex) => {
      const { orgName, orgTeamMembers } = organization || {};
      const sortedOrgTeamMembers = sortArrayToListActiveMembersFirst(orgTeamMembers);

      const renderOrgMembers = Array.isArray(sortedOrgTeamMembers) && sortedOrgTeamMembers?.map((member, memberIndex) => {
        const { userId, isVerified, role, email, memberName, memberProfilePicSrc, memberStatus, memberOrgName, memberOrgTitle } = member || {};

        const authorisedToEditRole = checkAuthorizationToEditTeamRole(thisUserRole, role)
        const displayDiyOptions = (userId === thisUserId) ? true : false;

        const isDsrAdmin = (role === "seller-lead") ? true : false;
        const supplementaryUserInfo = (memberOrgTitle && memberOrgName) ? `${memberOrgTitle}, ${memberOrgName}` : memberOrgName ? memberOrgName : email;

        if (memberStatus === 'active' || memberStatus === 'pending') {
          return (
            <Flex
              key={`${perspective}_team_member_${memberIndex}`}
              justifyContent='space-between'
              alignItems='center'
              w='100%'
              my='1em'
            >
              <Flex align='center' justify='flex-start' opacity={(allowHideGuestList && !displayGuestList) ? "0.5" : "1.0"}>
                <Avatar h='3.5em' w='3.5em' bg='gray.100' color='gray.700' name={memberName} src={memberProfilePicSrc}>
                  {isDsrAdmin &&
                    <AvatarBadge boxSize="1.25em" borderColor="transparent" bg="white">
                      <Icon as={BiChevronsUp} w="20px" h="20px" color='brand.500' />
                    </AvatarBadge>
                  }
                </Avatar>

                <Box mx='1em'>
                  {isMobileView ?
                    <Flex align='baseline' justify='flex-start' flexDirection={'column-reverse'}>
                      <Box fontSize='0.875em' color='brandDark.500' className="truncate-long-text" maxW='12em'>{memberName}</Box>
                    </Flex>
                    :

                    <Flex align='center' gap={4}>
                      <Box fontSize='0.875em' color='brandDark.500' className="truncate-long-text" maxW='12em'>{memberName}</Box>
                      {!!isVerified && <Box as={RiVerifiedBadgeFill} boxSize='0.875em' color='brand.500' />}
                    </Flex>
                  }
                  <Box fontSize='0.75em' color='gray.500' className={isMobileView && "user-row-card-mobile-view truncate-long-text"} maxW={isMobileView && '12em'}>{supplementaryUserInfo}</Box>
                </Box>
              </Flex>

              <Flex justify='flex-end'>
                {authorisedToEditRole && renderDropdownMenu(userId, role)}
                {!authorisedToEditRole && displayDiyOptions ? renderRemoveSelfOption(userId, role) : null}
              </Flex>
            </Flex>
          )
        } else if (memberStatus === 'invited') {
          return (
            <Flex key={`${perspective}_team_member_${memberIndex}`} justify='space-between' align='center' w='100%' mt='1em'>
              <Flex align='center' justify='flex-start' opacity={"0.5"}>
                <Box boxSize='2em' rounded='100%' border='1px dashed' borderColor='gray.500' />
                <Box ml='1em'>
                  <Flex align='center' gap={4}>
                    <Box fontSize='0.875em' color='brandDark.500' className="truncate-long-text" maxW='12em'>{memberName ? memberName : email}</Box>
                    {/* <Box as={RiVerifiedBadgeFill} boxSize='0.875em' color='brand.500'/> */}
                  </Flex>

                  <Box display='flex' flexWrap='wrap' style={{ gap: '5px' }} fontSize='0.75em' mt='2px' color='gray.400' fontStyle='italic'>
                    <Box className={isMobileView && "user-row-card-mobile-view"}>{supplementaryUserInfo}</Box>
                  </Box>
                </Box>
              </Flex>

              {authorisedToEditRole &&
                <DsrInvitedUserOptionsButton
                  userId={userId}
                  role={role}
                  handleRemoveUserAccess={handleRemoveUserAccess}
                />
              }
            </Flex>
          )
        } else return null;
      })

      if (renderOrgMembers) {

        return (
          <Box key={`${orgName}_team_member_${organizationIndex}`}>
            {renderOrgMembers}
          </Box>
        )

      } else return null;

    }
    )

    if (!renderTeam) {
      return null
    }

    if (renderTeam.length === 0) {
      return null
    } else {
      return (
        <Box pb='2em'>
          <Flex w='100%' justify='space-between'>
            <Box lineHeight='1.375em' color='gray.500'>{teamHeading}</Box>
            {allowHideGuestList && 
            <Flex justify='flex-end' align='center' gap={2}>
                <Box fontSize='0.7em' color='gray.500' className='fade-in'>{!displayGuestList && "Guest list hidden"}</Box>
               <Box as={ displayGuestList ? MdVisibility : MdVisibilityOff} color={'gray.500'} boxSize="1em" py='0.1em' cursor={"pointer"} onClick={()=> handleSaveDsrProperties('save-hide-guest-list')} />

              </Flex>
              }
       
          </Flex>
          <Box mt='0.5em'>{renderTeam}</Box>
        </Box>
      );
    }
  }

  function handlePickChoice(userId, choice, role) {
    if (choice === "remove-access") {
      return handleRemoveUserAccess(userId, role)

    } else if (choice === 'leave-dsr') {
      handleRemoveUserAccess(userId, role)

    } else {
      const editedUserParams = {
        dsrId,
        userId,
        selectedRole: choice
      }

      props.action.editDsrMemberRole(editedUserParams)
      setShowDropdownLoader(true)
    }

    // Resets
    // setShowChoiceList(false);
  }

  const renderSelectList = (userId, displayRole, userRole) => {
    if (userRole === "buyer-member") {
      return (
        <MenuItem>
          <Box className='member-role-options-dropdown-menu__dropdown-option-display'
            onClick={() => handlePickChoice(userId, "remove-access", userRole)}
          >
            Remove Access
          </Box>
        </MenuItem>
      )
    } else {
      const roleArray = ["Admin", "Member"];
      const roleChoices = roleArray.filter((role) => role !== displayRole)
      if (Array.isArray(roleChoices)) {
        if (roleChoices.length > 0) {
          const choiceList = roleChoices?.map((choice, index) => {
            return (
              <Box w='100%' className='member-role-options-dropdown-menu__dropdown-option-display' key={`choice-${choice}-${userId}`} value={choice}
                onClick={() => handlePickChoice(userId, choice, userRole)}
              >{choice}</Box>
            )
          })

          return (
            <React.Fragment>
              <MenuItem>{choiceList}</MenuItem>
              <Divider />
              <MenuItem>
                <Box
                  className='member-role-options-dropdown-menu__dropdown-option-display'
                  onClick={() => handlePickChoice(userId, "remove-access", userRole)}
                >
                  Remove Access
                </Box>
              </MenuItem>
            </React.Fragment>
          )
        }
      }
    }

  }

  function renderDropdownMenu(userId, role) {
    const displayRole = (role === "seller-lead") ? "Admin" : "Member";
    const isShowLoader = (showDropdownLoader && (chosenUserId === userId));

    return (
      <Box>
        {isShowLoader ? <ComponentLoader /> :
          <Menu closeOnSelect={true} placement="bottom-end">
            <MenuButton
              as={Button}
              py="9px"
              px="12px"
              w="128px"
              fontSize="14px"
              padding="16px 12px"
              background="rgba(255, 255, 255, 0.92)"
              border="1px solid #CBD5E0"
              {...{
                rightIcon: <IoChevronDownOutline />
              }}
            >
              <Box className='chosen-option-text-display'>{displayRole}</Box>
              {/* <Box as={GoChevronDown} boxSize="0.75em" color="gray.300" /> */}
            </MenuButton>
            <MenuList>
              {renderSelectList(userId, displayRole, role)}
            </MenuList>
          </Menu>
        }
      </Box>
    )
  }

  function renderRemoveSelfOption(userId, role) {
    const isShowLoader = (showDropdownLoader && (chosenUserId === userId));
    const displayRole = (role === "seller-lead") ? "Admin" : "Member";

    return (
      <Box>
        {isShowLoader ? <ComponentLoader /> :
          <Menu closeOnSelect={true} placement="bottom-end">
            <MenuButton
              as={Button}
              py="9px"
              px="12px"
              w="128px"
              fontSize="14px"
              padding="16px 12px"
              background="rgba(255, 255, 255, 0.92)"
              border="1px solid #CBD5E0"
              // className='member-role-options-dropdown-menu'
              {...{
                rightIcon: <IoChevronDownOutline />
              }}
            >
              <Box className='chosen-option-text-display'>{displayRole}</Box>
            </MenuButton>
            <MenuList>
              <MenuItem>
                <Box className='member-role-options-dropdown-menu__dropdown-option-display'
                  onClick={() => handlePickChoice(userId, "leave-dsr", role)}
                  color='red.500'
                >
                  Leave Room
                </Box>
              </MenuItem>
            </MenuList>
          </Menu>
        }
      </Box>
    )
  }

  function renderTabs() {
    return (
      <Flex fontSize={isMobileView ? '0.8em' : '0.875em'} >
        <Box px={isMobileView && '0.5em'} className={(displayTab === `display-members-modal-tab`) ? "chosen-modal-tab-option" : "unchosen-modal-tab-option"}
          onClick={() => setDisplayTab(`display-members-modal-tab`)}
          cursor='pointer'
        >
          <Flex w='100%' py='0.8em' justify='center' align='center'>
            Room members
          </Flex>
        </Box>

        <Box px={isMobileView && '0.5em'} className={(displayTab === `invite-modal-tab`) ? "chosen-modal-tab-option" : "unchosen-modal-tab-option"}
          onClick={() => setDisplayTab(`invite-modal-tab`)}
          cursor='pointer'
        >
          <Flex w='100%' py='0.8em' justify='center' align='center'>Add members</Flex>
        </Box>

        <Box px={isMobileView && '0.5em'} className={(displayTab === `access-settings-tab`) ? "chosen-modal-tab-option" : "unchosen-modal-tab-option"}
          onClick={() => setDisplayTab(`access-settings-tab`)}
          cursor='pointer'
        >
          <Flex w='100%' py='0.8em' justify='center' align='center'>Access Settings</Flex>
        </Box>
      </Flex>

    )
  }

  const renderInviteMenu = () => {
    let showInviteCoworkers = false;
    let showManageJoinRequests = false;

    switch (inviteMenuDisplayType) {
      case ("all-options"): {
        showInviteCoworkers = true
        showManageJoinRequests = true
        break;
      }
      case ("guest-member-options"): {
        showInviteCoworkers = true
        showManageJoinRequests = false
        break;
      }
      case ("dsr-host-member-options"): {
        showInviteCoworkers = true
        showManageJoinRequests = false
        break;
      }
      case ("no-org-invite-options-only"):
      default: {
        break;
      }
    }
    return (
      <Flex w='100%' justify='flex-start' align='center' mt='0.5em' fontSize='0.9em'>

        <Box>
          <Flex justify={['center', 'flex-end', 'flex-end', 'flex-end']} w='100%'>
            <LaunchdeckButton noHover bg={(inviteMode === "search-email") ? 'brand.100' : 'white'} color={'brandDark.500'} fontWeight={(inviteMode === "search-email") && '500'} isDummy={(inviteMode === "search-email") && true} onClick={() => setInviteMode('search-email')} label='External invite' />
          </Flex>
        </Box>

        {showInviteCoworkers &&
          <Box>
            <Flex justify={['center', 'flex-end', 'flex-end', 'flex-end']} w='100%'>
              <LaunchdeckButton noHover bg={(inviteMode === "add-internal-team") ? 'brand.100' : 'white'} color={'brandDark.500'} fontWeight={(inviteMode === "add-internal-team") && '500'} isDummy={(inviteMode === "add-internal-team")} onClick={() => setInviteMode('add-internal-team')} label='Add co-worker' />
            </Flex>
          </Box>
        }

        {showManageJoinRequests &&
          <Box>
            <Flex justify={['center', 'flex-end', 'flex-end', 'flex-end']} w='100%'>

              <Flex position='relative' align='center'>
                {showIndicator && <Box fontSize='1.5em' color='pink.500' position='absolute' left='6px'>•</Box>}
                <LaunchdeckButton noHover bg={(inviteMode === "join-requests") ? 'brand.100' : 'white'} px={showIndicator && '1.4em'} color={'brandDark.500'} fontWeight={(inviteMode === "join-requests") && '500'} isDummy={(inviteMode === "join-requests") && true} onClick={() => setInviteMode('join-requests')} label={'Join requests'} />

              </Flex>

            </Flex>
          </Box>
        }

      </Flex>
    )
  }

  function renderTeamManagementContent() {
    switch (displayTab) {
      case ('display-members-modal-tab'): {
        return (
          <React.Fragment>
            <Box mb='1em' w='100%' maxH='80vh'>

              <Box mb='2em'>
                {renderTeamDisplay('seller')}
              </Box>

              <Box>
                {renderTeamDisplay('buyer')}
              </Box>
            </Box>
          </React.Fragment>
        )
      }
      case ('invite-modal-tab'): {
        return (
          <Box w='100%' h='100%' minH='20em'>
            {inviteMenuDisplayType !== "no-org-invite-options-only" && renderInviteMenu()}
            <Box d='flex' height='100%'>
              {(inviteMode === "search-email")
                ? <DsrAddTeamMemberForm dsrId={dsrId} handleCloseModal={handleCloseModal} />
                : (inviteMode === "add-internal-team")
                  ? <DsrAddInternalTeamMemberForm dsrId={dsrId} handleCloseModal={handleCloseModal} />
                  : (inviteMode === "join-requests")
                    ? <DsrJoinRequestMgmtModule dsrId={dsrId} />
                    : null
              }
            </Box>
          </Box>
        )


      }
      case ('access-settings-tab'): {
        return (
          <Box w='100%'>
            <DsrAccessSettingsForm dsrId={dsrId} thisUserRole={thisUserRole} />
          </Box>
        )
      }
      default: {
        return null;
      }
    }
  }

  const handleRemoveUserAccess = async (userId, role) => {
    handleChangeUserActionRequired('confirm-dsr-remove-user', { removedUserId: userId, role })
  }


  return (
    <Modal isOpen={true} closeOnOverlayClick={false} isCentered={true} preserveScrollBarGap={true} >
      <ModalOverlay className='modal-background' />
      <ModalContent rounded='0.25em' minW={['90%', '500px']} maxW='700px'>
        <ModalBody px='0em'>
          <Flex direction='column' justify='space-around' alignItems='center' w='100%'>


            <React.Fragment>
              {isMobileView ?
                <Box justify='space-between' align='center' w='100%' >
                  <Flex justify='space-between' w='100%' p='1em'>
                    <Box fontWeight='600' fontSize='1.2em' color='brandDark.500'>Share</Box>
                    <LaunchdeckCloseButton handleCloseModal={handleCloseModal} />
                  </Flex>

                  <Flex px='1em' justify='flex-start' align='center'>
                    {renderTabs()}
                  </Flex>

                </Box>
                :
                <Flex justify='space-between' align='center' w='100%' >
                  <Flex px='1em' justify='flex-start' align='center'>
                    {renderTabs()}
                  </Flex>

                  <Flex justify='flex-end' w='100%' pr='1em'>
                    <LaunchdeckCloseButton handleCloseModal={handleCloseModal} />
                  </Flex>
                </Flex>
              }
              <Box w='100%'
                py='1em'
                px={isMobileView ? '1em' : '2em'}
                height={isMobileView ? '80vh' : 'auto'}
                overflowY='auto'
                minH='28em'
              >
                {displayedTeamMembers && renderTeamManagementContent()}
              </Box>
            </React.Fragment>


          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

function mapStateToProps(state) {
  return {
    isDsrAdmin: state.dsrAdmin.isDsrAdmin,
    editDsrTeamMembersOutcome: state.editDsrTeamMembersOutcome.results.data,
    editDsrMemberRoleOutcome: state.editDsrMemberRoleOutcome.results.data,
    dsrTeamMembersRedux: state.dsrTeamMembers.members,
    joinRoomRequests: state.joinRoomRequests.results,
    dsrUiIndicators: state.dsrUiIndicators.dsrUiIndicators,
    isMobileView: state.mobileViewMode.isMobileView,
    dsrUserTeam: state.dsrUserTeam.userTeam,
    updateDsrOutcome: state.updateDsrOutcome.results.data,
    dsrPropertiesRedux: state.draftDsrProperties.properties,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    action: bindActionCreators(
      {
        editDsrMemberRole,
        resetEditDsrMemberRole,
        updateDsr,
        resetUpdateDsrOutcome
      },
      dispatch
    )
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DsrManageTeamModal));
