import React, { useEffect } from 'react'
import { DsrLoomRecordButton } from "../../../dsr-record-content/DsrLoomRecordButton";
import { Box } from "@chakra-ui/react";
import { LoomVideoDisplay } from "../../../dsr-content-components/LoomVideoDisplay";
// import LoomRecordingLogo from "../../images/loom_recording.svg";
import { TiDelete } from "react-icons/ti";
import { BsPersonVideo } from "react-icons/bs";

export const LoomVideo = ({
  setSubWidgetContent,
  subWidgetContent,
  subWidgetAutoSave

}) => {
  useEffect(() => {
    if (subWidgetContent[0]) {
      subWidgetAutoSave()
    }
  }, [subWidgetContent])

  const handleSaveVideoToSubWidget = (video) => {
    setSubWidgetContent(video);
  }

  const handleClearSubWidgetContent = () => {
    setSubWidgetContent('');
  }

  return (
    subWidgetContent
      ? (
        <Box className="loader-img" width='100%' minHeight='300px' pt='10px' pb='10px'>
          <Box
            onClick={handleClearSubWidgetContent}
            color='red'
            position='absolute'
            top='30px'
            right='20px'
            zIndex='1'
            width='25px'
            height='25px'
            backgroundColor='red'
            borderRadius='30%'
          >
            <TiDelete size='lg' color='white'/>
          </Box>
          <LoomVideoDisplay loomVideo={subWidgetContent[0]} />
        </Box>
      )
      : (
        <Box display='flex' flexDir="column" justifyContent='center' alignItems='center' p='1em'>
          {/* <Box as={BsPersonVideo} h='3.5em' w='3.5em' color='gray.500' my='1em'/> */}
          <Box w='100%' color='brandDark.500' as={BsPersonVideo} height="3.5em" my="2em"  />
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            marginTop='5px'
            width='max-content'
            height='50px'
          >
            <DsrLoomRecordButton handleSaveVideoToSubWidget={handleSaveVideoToSubWidget} submitRecording={(v)=>{}}/>
          </Box>
        </Box>
      )
  )
}
