import React from 'react';
import { ChakraBaseProvider } from "@chakra-ui/react";

import Header from '../../header/components/Header';
import '../styles/layout.scss';
import claverTheme from './theme/claverTheme';
import useViewport from './Viewport';
import config from "../../../../../config/app-config";


export default function Layout(props) {

	const { width } = useViewport();
	const {pathname} = props.location  || {}
	let isDsrMode = "";

	// To determine whether it is DSR mode without admin header
	switch(pathname) {
		case(pathname.match(/^\/dsr/)?.input):
		case(pathname.match(/^\/draft/)?.input):
		case(pathname.match(/^\/template/)?.input): 
		case('/dsr'):{
			isDsrMode = true
			break;
		}
		case('/pdsr'):{
			isDsrMode = true
			break;
		}		
		default: {
			isDsrMode = false
			break;
		}
	}

	function renderLayout(layoutWidth)  {
		// Consider getting mobile view value from redux state instead. 
		const {mobileWidthBreakpoint, tabletWidthBreakpoint} = config;
		
		if (layoutWidth <= mobileWidthBreakpoint) {    
			return (
				<div className="app-layout-mobile">
					<Header className="app-layout__header" displayMode='mobile'/>
					<main className="app-layout__content">{props.children}</main>
				</div>
			);

		} else if (layoutWidth > mobileWidthBreakpoint && layoutWidth <= tabletWidthBreakpoint) {
			return (
				<div className="app-layout-mobile">
					<Header className="app-layout__header" displayMode='mobile'/>
					<main className="app-layout__content">{props.children}</main>
				</div>
			);

		} else
      if (layoutWidth > tabletWidthBreakpoint) {
			if (isDsrMode===true) {
				return (
					<div className="app-layout-new">
						<main className="app-layout__dsr_content">{props.children}</main>
					</div>
				);
			} 
			return (
				<div className="app-layout-new">
					<Header className="app-layout__header" displayMode=''/>
					<main className="app-layout__content">
						<div className="app-layout-new__content-display-optimization">
							{props.children}
						</div>
					</main>
				</div>
			);
		}

		else return null;
	};


		return (
			<ChakraBaseProvider theme={claverTheme}>
				{renderLayout(width)}
			</ChakraBaseProvider>
		);

	}

