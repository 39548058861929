import React, {useEffect, useState} from "react";

import { Box, Flex, Avatar } from "@chakra-ui/react";
import { connect } from "react-redux";
import { getUserDetailsUsingDsrMembersArray } from "../../profile/helpers/user-helper";
import { optionTypes } from "../dsr-draft/page-designer/types/subWidgetOptionsTypes";
import { useParams } from "react-router-dom";


function DsrUserSignature({ userId, dsrTeamMembers, dsrOwnerId, dsrMainContact, subWidgetOptions, isMobileView }) {
  const [currentUserSignatureObject, setCurrentUserSignatureObject] = useState({});
  const currentUserId = userId || dsrOwnerId
  const { pDsrId } = useParams()


  useEffect(() => {
    if (pDsrId) {
      const {firstName, lastName, profilePicture, orgName, userOrgTitle} = dsrMainContact || {}
      const memberName = (firstName && lastName) ? firstName + ' ' + lastName : firstName ? firstName : "";
      setCurrentUserSignatureObject({
        memberProfilePicSrc: profilePicture,
        memberName,
        memberOrgName: orgName,
        memberOrgTitle: userOrgTitle
      })
    }

    if (dsrTeamMembers && currentUserId) {
      setCurrentUserSignatureObject(getUserDetailsUsingDsrMembersArray(dsrTeamMembers, currentUserId))
    }
  }, [dsrTeamMembers, currentUserId, pDsrId]);


  if (!currentUserSignatureObject.memberName) {
    return  null
  }

  return (
    <Flex width='100%' justifyContent='flex-start' alignItems='center' minHeight='50px'>
    <Box display='flex' justifyContent={subWidgetOptions[optionTypes.ALIGN]} alignItems='center' width='100%'>
      <Box p='5px' m='5px'>
        <Flex alignItems='center'>
          {currentUserSignatureObject?.memberProfilePicSrc &&
            <Avatar width='5em' height='5em' name={currentUserSignatureObject.memberName} bg='gray.100' color='gray.700' src={currentUserSignatureObject?.memberProfilePicSrc} />
          }

          <Flex flexDirection='column' ml='0.8em'>
            <Box className="handwriting-font" fontSize='1.25em'>{currentUserSignatureObject.memberName}</Box>

            {(currentUserSignatureObject.memberOrgTitle || currentUserSignatureObject.memberOrgName) &&
              <Flex direction={isMobileView && 'column'} fontSize='0.875em' color='gray.700'>
                {currentUserSignatureObject.memberOrgTitle && <Box>{currentUserSignatureObject.memberOrgTitle}</Box>}
                {currentUserSignatureObject.memberOrgName && <Box pl={!isMobileView && '5px'} mt={isMobileView && '0.2em'} fontWeight={isMobileView ? '600' : '500'} fontSize={isMobileView && '0.9em'}>{isMobileView ? '' : "@"}  {currentUserSignatureObject.memberOrgName}</Box>}
              </Flex>
            }
          </Flex>
        </Flex>
      </Box>
    </Box>

  </Flex>
  );

}

const mapStateToProps = (state) => {

  return {
    isMobileView: state.mobileViewMode.isMobileView,
    dsrTeamMembers: state.dsrTeamMembers.members,
    dsrMainContact: state.dsrMainContact.mainContactInfo,
    dsrOwnerId: state.dsrConfiguration.results.data
      ? state.dsrConfiguration.results.data.dsrOwnerId
      : state.publicDsrConfiguration.results.data ?
        state.publicDsrConfiguration.results.data.dsrOwnerId
        : state.dsrProspect.results.data.dsrOwnerId 
          ? state.dsrProspect.results.data.dsrOwnerId : "" 
  }
}
export default connect(mapStateToProps, null)(DsrUserSignature);

