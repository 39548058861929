import React from 'react';
import { Box, Flex, Spinner } from "@chakra-ui/react";


export default function ClaverButton(props) {
	
	const { label, w, width, onClick, textTransform, dummy, bg, fontSize, color, name, value, fontWeight, loadingLogic, borderRadius } = props;
	const isDummy = dummy ? true:false;
	const isFullWidth = (width==='100%' || w==='100%') ? true : false;

	return (
		<Box
			display={!isFullWidth && 'inline-flex'}
      alignItems='center'
			name={name ? name : ''}
			value={value ? value : ''}
			onClick={onClick}
			px="0.8em"
			py='0.4em'
			rounded={borderRadius ? borderRadius : '0.25em'}
			fontSize={fontSize ? fontSize : "1em"}
			bg={bg ? bg : "#f5f6f7"}
			borderColor="#ccd0d5"
			color={color ? color : "#4b4f56"}
			textTransform={textTransform ? textTransform : ''}
			cursor={isDummy ? 'default' : 'pointer'}
			w={width ? width : ''}
			fontWeight={fontWeight ? fontWeight : '400'}
			textAlign='center'
			justifyContent='center'
			whiteSpace='pre'
		>
			{loadingLogic ? <Flex minWidth='5em' justify='center' w='100%'><Spinner boxSize='1em' my='0.2em' mx='0.4em'/></Flex> : label ? label : props.children}		
		</Box>

	)
	
}
