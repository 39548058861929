import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Box, Flex, Spinner } from '@chakra-ui/react';
import { AddSubWidget } from './AddSubWidget'
import { MdOutlineDeleteForever } from 'react-icons/md';
import { deleteFiles, deleteFilesReset } from "../../actions/dsr-actions";
import { getWidgetUploadedFileKeys } from "../../helpers/dsr-draft-helpers";


const Widget = ({
  children,
  widget,
  items,
  setItems,
  removeWidget,
  activeId,
  editableItemId,
  deleteFiles,
  deleteFilesState,
  deleteFilesReset,
  dsrOwnerId
}) => {
  const { isLoading, results, error } = deleteFilesState;
  const [isHover, setIsHover] = useState(false);
  const [removingWidgetId, setRemovingWidgetId] = useState('');

  useEffect(() => {
    if (removingWidgetId) {
      const uploadedFileKeys = getWidgetUploadedFileKeys(widget);
      const isIncludesUploadedFiles = uploadedFileKeys.length > 0;
      const response = results?.deleted ? results : {}

      if (!isLoading && !error && response?.deleted?.length > 0 && isIncludesUploadedFiles ) {
        const isCurrentWidget = uploadedFileKeys?.find((obj) => { return obj.Key === response?.deleted[0]?.Key} )

        if (isCurrentWidget) {
          removeWidget(widget.contentBlockId)
          deleteFilesReset()
          setRemovingWidgetId('')
        }
      }
    }
  }, [deleteFilesState])

  const handleOnMouseEnter = (event) => {
    setIsHover(true);
  }

  const handleOnMouseLeave = (event) => {
    setIsHover(false);
  }

  const handleRemoveWidget = () => {
    const uploadedFileKeys = getWidgetUploadedFileKeys(widget);
    const isIncludesUploadedFiles = uploadedFileKeys.length > 0;

    if (isIncludesUploadedFiles) {
      setRemovingWidgetId(widget.contentBlockId)
      deleteFiles(uploadedFileKeys)
    } else {
      removeWidget(widget.contentBlockId)
    }
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
      pt='20px'
    >
      <Box
        backgroundColor="white"
        borderRadius="lg"
        width="100%"
        borderWidth='2px'
        borderColor={(isHover || activeId) && !editableItemId ? `` : 'transparent'}
        pb='0px'
        mb="30px"
        position="relative"
      >
        <Box
          id={widget?.contentBlockId}
          height={'100%'}
          justifyContent="center"
          alignItems="center"
          display={"grid"}
          gridTemplateColumns={['1fr']}
          position="relative"
          bg="white"
          pb="10px"
        >
          {children}
          <AddSubWidget
            items={items}
            setItems={setItems}
            widget={widget}
            dsrOwnerId={dsrOwnerId}
          />
          {(widget?.contentBlockId === removingWidgetId) &&
            <Box
              position='absolute'
              top='20px'
              left='45%'
            >
              <Spinner
                thickness="0.25em"
                speed="0.65s"
                emptyColor="gray.200"
                color="brand.500"
                size="5em"
                zIndex='10'
              />
            </Box>
          }
        </Box>
        {isHover &&
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minWidth="30px"
            height="30px"
            background="#4A5568e6"
            position="absolute"
            right="0"
            top="-35px"
            borderRadius="15px"
            padding="15px"
          >
            <Flex onClick={handleRemoveWidget} gap={2} align='center' justify='center'>
              <MdOutlineDeleteForever fill="#fff" />
              <Box color='white' fontSize='0.75em'> Delete block</Box>
            </Flex>
          </Box>
        }
      </Box>
    </Box>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      deleteFiles,
      deleteFilesReset
    },
    dispatch,
  );

const mapStateToProps = (state) => {
  return {
    deleteFilesState: state.deleteFilesOutcome,
    dsrOwnerId: state.dsrConfiguration.results.data
      ? state.dsrConfiguration.results.data.dsrOwnerId
      : ''
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Widget);
