import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { Image, Flex, Box } from '@chakra-ui/react';
import GeneralPageFooter from '../../auth/components/GeneralPageFooter';
import { redirectToLandingPage } from '../../global/helpers/global-helpers';

import logo from '../../global/styles/images/launchdeck_logo.svg';
import LaunchdeckButton from '../../global/components/helper-components/components/LaunchdeckButton';

function UnauthorisedAccessDisplay({ isMobileView }) {

	return (
		<Box w={isMobileView && '90%'} margin='auto'>
			<Box alignItems='center' bg='white' rounded='0.25em' p='1em'>
				<Flex w='100%' justify='center'>
					<Image src={logo} alt="Launchdeck Logo" width='50%' minWidth='15em' maxWidth='20em' mb='2em' onClick={() => redirectToLandingPage()} />
				</Flex>

				<Box direction='column' maxWidth='37.5em' m='auto'>
					<Flex direction='column' alignItems='center' bg='white' rounded='0.25em' >
						<Box w='100%' py='0.8em' >
							<Box w='100%' fontSize='1.2em' lineHeight='1.875em' fontWeight='500' textAlign='center'>Invalid Link</Box>
						</Box>


						<Box w='100%' p='1.5em'>
							<Box textAlign='center' fontSize='0.875em'>
								Please contact your Room Host to make sure they had sent you the right link
							</Box>
							<Flex mt='3em' alighItems='center' justify='center'>
								<LaunchdeckButton bg='brand.500' color='white' onClick={() => redirectToLandingPage()} label='Return to Launchdeck' />
							</Flex>
						</Box>

					</Flex>
				</Box>
				<Box pt='2em'>
					<GeneralPageFooter />
				</Box>
			</Box>


		</Box>
	);
}

function mapStateToProps(state) {
	return {
		isMobileView: state.mobileViewMode.isMobileView
	};
}


export default withRouter(connect(mapStateToProps, null)(UnauthorisedAccessDisplay));
