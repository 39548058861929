import React from 'react';
import { Text } from "@chakra-ui/react";


export default function WarningTextMessage({errorLogic, message, textAlign}) {
		return (	
			<Text 
				display = {(errorLogic === true) ? '':'none'} 
				// visibility={(errorLogic === true) ? '':'hidden'} 
				className={(errorLogic === true) ? "flip-in-hor-bottom" : ''} 
				fontSize='0.8em' 
				color='red.500'
				textAlign={textAlign ? textAlign : ''}
			> 
				{message}
			</Text>
		)
}



