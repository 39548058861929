
import React, { useEffect, useRef } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter, useParams } from 'react-router-dom'

import { Box, Flex } from '@chakra-ui/react'
import {
  changeDsrPageDisplay,
  changeSelectSubpage,
  setNewDsrDraftCurrentPage,
} from '../../digital-sales-room/actions/dsr-actions'
import DsrProspectSideMenu from './DsrProspectSideMenu'
import RenderSubpageContent from '../components/RenderSubpageContent'


// Note: Render Dsr Content for DRAFT states
const RenderProspectDsrContent = ({
  changeDsrPageDisplay,
  changeSelectSubpage,
  selectedDisplaySubpage,
  pagesLocalState,
  selectedDisplayPageId,
  dsrPropertiesRedux,
  headerMenuHeight,
  isMobileView
}) => {

  const { pDsrId } = useParams()
  const initRef = useRef(false);
  
  useEffect(() => {
    if ((!initRef.current && pagesLocalState)) {
      const visiblePages = pagesLocalState.filter(page => page.displayPage === true)

      if (visiblePages.length > 0) {
        changeDsrPageDisplay(visiblePages[0].pageId)
      } else {
        changeDsrPageDisplay('no-visible-pages')
      }

      // TODO: Implement GA for Prospect DSR
      initRef.current = true
    }
}, [selectedDisplayPageId, pagesLocalState, changeDsrPageDisplay])

  // Page Management
  const handleSelectSubpage = (selectedDisplayPageId, selectedDisplayFixedSubpageType) => {

    if (selectedDisplayPageId && !selectedDisplayFixedSubpageType) {
     changeDsrPageDisplay(selectedDisplayPageId)

    } else if (!selectedDisplayPageId && selectedDisplayFixedSubpageType) {
     changeSelectSubpage(selectedDisplayFixedSubpageType)
     
    } else {
     // Error state if both values are provided to avoid showing 
     changeSelectSubpage("")
     changeDsrPageDisplay("")
    }
   
   }

  // Functions to change frontend local state of dsrPages
  // const handleDisplayPageChange = (pageId, event) => {
  //   const updatedPages = pagesLocalState.map(page =>
  //     ((page.pageId === pageId) && event.target) ? ({ ...page, displayPage: event.target.checked }) : page
  //   )

  //   replaceDsrPages(updatedPages)
  // }


  // const handleConfirmationModalClose = () => {
  //   setLocalState((prevState) => ({
  //     showConfirmationModal: false,
  //     confirmationType: '',
  //   }))
  // }


  return (
    <Flex className='dsr-content__section' justifyContent='flex-start'>
      <Flex className='dsr-content__container' height='100%'>
        <Flex direction="column" align="flex-start" w='100%' minHeight="40em">
        
          <Flex direction="row" align="flex-start" width="100%" height='100%'>
            <Flex display={['none', 'none','flex', 'flex']} position='sticky' top={headerMenuHeight} bg="white">
              <DsrProspectSideMenu
                pageArray={pagesLocalState}
                selectedDisplayPageId={selectedDisplayPageId}
                handleSelectSubpage={handleSelectSubpage}
                headerMenuHeight={headerMenuHeight}
              />
            </Flex>
            
            <Flex
              direction={['column', 'column', 'row', 'row']}
              justify="center"
              flexWrap="wrap"
              width="100%"
              bg='#F4F4F4'
              height='100%'
              pb='3em'
            >
              <Box mt={isMobileView ? '6em' : '2em'} w="100%" h='100%' px="3em" maxWidth='900px'>
              <RenderSubpageContent
                  pageArray={pagesLocalState}
                  selectedDisplaySubpage={selectedDisplaySubpage}
                  selectedDisplayPageId={selectedDisplayPageId}
                  pDsrId={pDsrId}
                  dsrProperties={dsrPropertiesRedux}
                  handleSelectSubpage={handleSelectSubpage}
                />

              </Box>
            </Flex>


            {/* // If any, use below section to house dynamically rendered components depending on user journey */}
          
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

const mapStateToProps = (state) => ({
  selectedDisplaySubpage: state.dsrPage.selectedDisplaySubpage,
  draftDsrCurrentPageRedux: state.dsrFeedContent.currentPage,
  dsrPropertiesRedux: state.draftDsrProperties.properties,
  selectedDisplayPageId: state.dsrPage.selectedDisplayPageId,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      changeDsrPageDisplay,
      changeSelectSubpage,
      setNewDsrDraftCurrentPage
    },
    dispatch,
  )

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RenderProspectDsrContent))
