import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  deleteFiles,
  deleteFilesReset,
  uploadFileMultithreaded,
  uploadFiles,
  uploadFilesReset,
} from "../../actions/dsr-actions";

import { Box, Spinner } from "@chakra-ui/react";
import { MdOutlineDeleteForever } from "react-icons/md";
import { SubWidgetOptionMenu } from "./SubWidgetOptionMenu";
import { getUserProfile } from "../../../profile/actions/users-profile-actions";
import { generateRandomString } from "../../../global/helpers/global-helpers";

const Item = ({
  children,
  removeSubWidget,
  widget,
  subWidget,
  setEditableItemId,
  editableItemId,
  items,
  setItems,
  uploadFiles,
  uploadFileMultithreaded,
  uploadFilesState,
  uploadFilesReset,
  deleteFiles,
  deleteFilesState,
  deleteFilesReset,
  isDragging,
  dsrTeamMembers,
  dsrOwnerId,

  userAccountProfile,
  getUserProfile,

  ...other
}) => {
  const { isLoading, results, error } = deleteFilesState;
  const [isHover, setIsHover] = useState(false);
  const [subWidgetContent, setSubWidgetContent] = useState(
    subWidget?.content || "",
  );
  const [removingSubWidgetId, setRemovingSubWidgetId] = useState("");
  const currentSubWidgetContentRef = useRef("");
  const { dsrId } = useParams();
  const { pDsrId } = useParams();

  useEffect(() => {
    currentSubWidgetContentRef.current = subWidgetContent;
  }, [subWidgetContent]);

  useEffect(() => {
    const types = [
      "uploaded-image",
      "uploaded-video",
      "uploaded-pdf",
      "uploaded-banner",
      "uploaded-audio"
    ];

    if (types.includes(subWidget.contentBlockType) && removingSubWidgetId) {
      const response = results?.deleted ? results : {};

      if (
        !isLoading &&
        !error &&
        response?.deleted?.length > 0 &&
        Array.isArray(subWidget.content)
      ) {
        const isCurrentSubWidget = subWidget.content?.find((obj) => {
          return obj.key === response?.deleted[0]?.Key;
        });

        if (isCurrentSubWidget) {
          removeSubWidget(widget.contentBlockId, subWidget?.id);
          deleteFilesReset();
          setRemovingSubWidgetId("");
        }
      }
    }
  }, [deleteFilesState]);

  useEffect(() => {
    const types = ["user-signature", "signature-content"];

    if (types.includes(subWidget.contentBlockType) && !userAccountProfile) {
      getUserProfile({ getOrgTeamMembers: false, getOrgProfile: true });
    }
  }, []);

  const updateSubWidget = () => {
    const columnId = Object.values(
      items[widget.contentBlockId].widgetContent,
    )[0].columnId;
    
    // UUU Monitor this
    // const updatedContent = currentSubWidgetContentRef.current
    // ? currentSubWidgetContentRef.current
    // : subWidgetContent

    const updatedContent = subWidgetContent ? subWidgetContent : currentSubWidgetContentRef.current

    const updatedSubWidgetContent = {
      ...items[widget.contentBlockId],
      widgetContent: {
        ...items[widget.contentBlockId].widgetContent,
        [columnId]: {
          ...items[widget.contentBlockId].widgetContent[columnId],
          subWidgets: {
            ...items[widget.contentBlockId].widgetContent[columnId].subWidgets,
            [subWidget.id]: {
              ...items[widget.contentBlockId].widgetContent[columnId]
                .subWidgets[subWidget.id],
              content: updatedContent
              // currentSubWidgetContentRef.current
              //   ? currentSubWidgetContentRef.current
              //   : subWidgetContent,
            },
          },
        },
      },
    };

    setItems((items) => ({
      ...items,
      [widget.contentBlockId]: updatedSubWidgetContent,
    }));
  };

  const duplicateSubWidget = (subWidgetId) => {
    if (subWidget) {
      const columnId = Object.values(
        items[widget.contentBlockId].widgetContent,
      )[0].columnId;

      const { subWidgets } =
        items[widget.contentBlockId].widgetContent[columnId] || {};

      const newSubWidget = {
        ...subWidget,
        id: generateRandomString(10),
      };

      // Convert to array to handle insertion of new object
      const updatedSubwidgetsArray = subWidgets
        ? Object.entries(subWidgets)
        : [];
      const index = updatedSubwidgetsArray.findIndex(
        ([key, value]) => value.id === subWidgetId,
      );
      updatedSubwidgetsArray.splice(index + 1, 0, [
        newSubWidget.id,
        newSubWidget,
      ]);

      // Convert the array of key-value pairs back to an object
      const updatedSubwidgetsObject = Object.fromEntries(
        updatedSubwidgetsArray,
      );

      const updatedSubWidgetContent = {
        ...items[widget.contentBlockId],
        widgetContent: {
          ...items[widget.contentBlockId].widgetContent,
          [columnId]: {
            ...items[widget.contentBlockId].widgetContent[columnId],
            subWidgets: updatedSubwidgetsObject,
          },
        },
      };

      setItems((items) => ({
        ...items,
        [widget.contentBlockId]: updatedSubWidgetContent,
      }));
    }
  };

  const updateSubWidgetOptions = (updatedOptionsObject) => {
    const columnId = Object.values(
      items[widget.contentBlockId].widgetContent,
    )[0].columnId;

    const updatedSubWidgetOptions = {
      ...items[widget.contentBlockId],
      widgetContent: {
        ...items[widget.contentBlockId].widgetContent,
        [columnId]: {
          ...items[widget.contentBlockId].widgetContent[columnId],
          subWidgets: {
            ...items[widget.contentBlockId].widgetContent[columnId].subWidgets,
            [subWidget.id]: {
              ...items[widget.contentBlockId].widgetContent[columnId]
                .subWidgets[subWidget.id],
              options: updatedOptionsObject,
            },
          },
        },
      },
    };

    setItems((items) => ({
      ...items,
      [widget.contentBlockId]: updatedSubWidgetOptions,
    }));
  };

  const handleEditItemByClick = () => {
    if (editableItemId !== subWidget?.id) {
      setEditableItemId(subWidget?.id);
    }
  };

  const handleSaveItemByClickOutside = () => {
    if (editableItemId === subWidget?.id) {
      if (currentSubWidgetContentRef.current !== subWidget.content) {
        updateSubWidget();
      }
      setEditableItemId(null);
    }
  };

  const subWidgetAutoSave = () => {
    setEditableItemId(null);
    updateSubWidget();
  };

  const handleRemoveSubWidget = () => {
    const isSubWidgetWithS3Files = subWidget.content[0]?.key;

    if (isSubWidgetWithS3Files) {
      const deletingFilesArray = subWidget.content.map((file) => {
        return { Key: file.key };
      });

      setRemovingSubWidgetId(subWidget?.id);
      deleteFiles(deletingFilesArray);
    } else {
      removeSubWidget(widget.contentBlockId, subWidget?.id);
    }
  };

  const handleOnMouseEnter = (event) => {
    setIsHover(true);
  };

  const handleOnMouseLeave = (event) => {
    setIsHover(false);
  };

  return (
    <Box
      id={subWidget?.id}
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
      paddingBottom="10px"
    >
      <Box
        padding="5px"
        margin="5px"
        height="max-content"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDir="column"
        position="relative"
        bg="white"
        borderWidth="1.5px"
        borderRadius="8px"
        borderStyle={subWidget?.id === editableItemId ? `solid` : "dashed"}
        borderColor={
          isHover || subWidget?.id === editableItemId
            ? `#3295EC`
            : "transparent"
        }
      >
        {React.Children.map(children, (child) => {
          if (React.isValidElement(child)) {
            return React.cloneElement(child, {
              key: subWidget?.id,
              subWidgetId: subWidget?.id,
              options: subWidget?.options || {},
              setEditableItemId,
              editableItemId,
              subWidgetContent,
              setSubWidgetContent,
              subWidgetAutoSave,
              handleEditItemByClick,
              handleSaveItemByClickOutside,
              uploadFiles,
              uploadFileMultithreaded,
              uploadFilesState,
              uploadFilesReset,
              dsrId: dsrId ? dsrId : pDsrId,
              isDragging,
              dsrTeamMembers,
              userAccountProfile: userAccountProfile || "",
              dsrOwnerId: dsrOwnerId || "",
            });
          }
          return child;
        })}

        {isHover && !editableItemId && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minWidth="30px"
            height="30px"
            background="#5C6677"
            position="absolute"
            bottom="-38px"
            right="0"
            borderRadius="24px"
            border="1px solid #CBD5E0"
            padding="15px"
            zIndex="2"
          >
            <SubWidgetOptionMenu
              subWidgetId={subWidget.id}
              type={subWidget.contentBlockType}
              handleSaveSubWidgetOptions={updateSubWidgetOptions}
              subWidgetOptions={subWidget.options || {}}
              handleEditItemByClick={handleEditItemByClick}
              handleDuplicateSubWidget={duplicateSubWidget}
            />
            <Box onClick={handleRemoveSubWidget}>
              <MdOutlineDeleteForever fill="#fff" />
            </Box>
          </Box>
        )}

        {isLoading && subWidget?.id === removingSubWidgetId && (
          <Box position="absolute">
            <Spinner
              thickness="0.25em"
              speed="0.65s"
              emptyColor="gray.200"
              color="brand.500"
              size="5em"
              zIndex="10"
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      uploadFiles,
      uploadFileMultithreaded,
      uploadFilesReset,
      deleteFiles,
      deleteFilesReset,
      getUserProfile,
    },
    dispatch,
  );

const mapStateToProps = (state) => {
  return {
    retrievedDsrProspect: state.dsrProspect.results.data,
    userAccountProfile: state.userProfile.accountUserProfile,

    uploadFilesState: state.uploadFilesOutcome,
    deleteFilesState: state.deleteFilesOutcome,
    dsrTeamMembers: state.dsrTeamMembers.members,
    dsrOwnerId: state.dsrConfiguration.results.data
      ? state.dsrConfiguration.results.data.dsrOwnerId
      : state.dsrProspect.results.data.dsrOwnerId
        ? state.dsrProspect.results.data.dsrOwnerId
        : "",
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Item);
