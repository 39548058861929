import { Box, Flex } from '@chakra-ui/react'
import DsrPageSideMenu from './DsrPageSideMenu'
import DisplayNewsFeed from '../dsr-content-components/DisplayNewsFeed'

import DsrConfirmationModal from './DsrConfirmationModal'
import React, { memo, useState, useEffect, useRef } from 'react'
import { createNewPostReply, updateDsrPost } from '../helpers/dsr-helpers'
import { withRouter, useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  changeDsrModalState,
  changeDsrPopupState,
  changeSelectSubpage,
  createNewDsrFeedPost,
  createNewDsrFeedReply,
  deleteDsrFeedPost,
  deleteDsrReply,
  editDsrFeedPost,
  editDsrReaction,
  editDsrReply,
  loadMoreFeedContentAct,
  replaceDsrFeedContent,
  changeDsrPageDisplay,
  getDsrUpdatedFeedContent // TODO: Review - Is this required?
} from '../actions/dsr-actions'
import {logGuestDsrAnalytics} from '../../analytics/actions/dsr-analytics-actions'
import { bindActionCreators } from 'redux'
import RenderSubpageContent from './RenderSubpageContent'
import '../../layout/components/layout/styles/dsr-content.scss'
import DsrDynamicModal from '../messages/DsrDynamicModal'
import { sendToGA } from '../../global/helpers/google-tag-helpers'
import { displayBrandingLabel, getThisUserIdToken } from '../../global/helpers/global-helpers'
import EngagementTimer from '../../global/components/timer/EngagementTimer'

// Note: Render Dsr Content for ACTIVE states
const RenderDsrContent = ({
  dsrFeedContent,
  dsrId,
  dsrConfiguration,
  dsrUserTeam,
  sessionId,
  userProfile,
  dsrActionList,
  selectedDisplaySubpage, // TODO Review: required for MAP page however.
  selectedDisplayPageId,
  changeSelectSubpage, 
  changeDsrPageDisplay,
  editDsrReaction,
  editDsrFeedPost,
  deleteDsrFeedPost,
  deleteDsrReply,
  createNewDsrFeedPost,
  createNewDsrFeedReply,
  editDsrReply,
  logGuestDsrAnalytics,
  changeDsrPopupState,
  userActionRequiredState,
  changeDsrModalState,
  showDsrModalState,
  showLoadMoreRedux,
  loadMoreFeedContentAct,
  dsrPagesRedux,
  faqPageIdRedux,
  replaceDsrFeedContent,
  dsrPropertiesRedux,
  dsrTeamMembersRedux,
  /////
  createNewDsrFeedPostOutcome,
  editDsrFeedPostOutcome,
  editDsrFeedReplyOutcome,
  headerMenuHeight,
  isMobileView,
  userPrivileges
}) => {
  
  const { pageIdToken } = useParams();
  const initRef = useRef(false);
  const initialPageRef = useRef(false);

  const [localState, setLocalState] = useState({
    showDsrModalLoader: false,
    showConfirmationModal: false,
    confirmationType: '',
    postContentToEdit: '',
    faqPageId: '',
    replyIdToEdit: '',
    userIdToken: '',
    modalTransferPayload: '',
    postTagProperties: [],
  })
  const [showBranding, setShowBranding] = useState(false)
  const [showPostUploadImageOption, setShowPostUploadImageOption] = useState(false)

  const [resetEngagementTimer, setResetEngagementTimer] = useState(false)
  const [activeSeconds, setActiveSeconds] = useState(0);
  const isGuestUser = (dsrUserTeam==="audience") ? true : false;

  useEffect(() => {
    if (createNewDsrFeedPostOutcome || editDsrFeedPostOutcome || editDsrFeedReplyOutcome) {
      changeDsrPopupState('');
      changeDsrModalState(false);
    }
  }, [createNewDsrFeedPostOutcome, editDsrFeedPostOutcome, editDsrFeedReplyOutcome, changeDsrPopupState, changeDsrModalState]);

  useEffect(() => {
    if (userPrivileges && !initRef.current) {
      const {displayBrand} = userPrivileges || {}
      if (displayBrand=== true || displayBrand=== false) {
        setShowBranding(displayBrand)
        initRef.current = true
      }
    
		}
	}, [userPrivileges]);

  const refreshData = (dataType, newDataSet) => {
    //Used by child components to update data in this parent component, after specific actions are taken
    dataType === 'updated-news-feed-content'
      ? setLocalState((prevState) => ({
          ...prevState,
          showDsrModalLoader: false,
        }))
      : console.log(`No such data type to refresh: ${dataType}`)

    dataType === 'updated-news-feed-content' && replaceDsrFeedContent(newDataSet)
  }

  useEffect(() => {
		if (pageIdToken) {
      if (pageIdToken === "updates") {
        changeSelectSubpage('updates-subpage')
      } else if (pageIdToken === "shared-docs"){
        changeSelectSubpage('shared-docs-folder-subpage')
      } else if (pageIdToken === "map") {
        changeSelectSubpage('action-list-subpage')
      } else {
        const pageId = 'pg__' + pageIdToken;
        changeDsrPageDisplay(pageId)
      }
     
    } 
    else if (!pageIdToken && !initialPageRef.current) {
      // Default page for the first time when page is loaded
      const overviewPageId = dsrPagesRedux ? dsrPagesRedux[0].pageId : "";
      
      if (overviewPageId) {
        changeDsrPageDisplay(overviewPageId)
        initialPageRef.current = true 
      }
     
    }
  },[pageIdToken, changeDsrPageDisplay, dsrPagesRedux]);

  // Analytics 
  useEffect(() => {
    // Register the duration in which the last subpage was viewed before user closes the window or exits the DSR
    const handleBeforeUnload = () => {
     
      logGuestDsrAnalytics({
        eventType: 'guest-views-subpage',
        analyticsPayload: {
          userId: getThisUserIdToken(), 
          dsrId, 
          sessionId: sessionId,
          engagementDurationSeconds: activeSeconds,
          viewedPageId: selectedDisplayPageId
        }
      })
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  },[logGuestDsrAnalytics, dsrId, activeSeconds, sessionId, selectedDisplayPageId])

  // UX Handlers
  const handleResetEngagementTimer = () => {
    setResetEngagementTimer(false)
  }

  const handleSetShowDsrModalLoader = () => {
    setLocalState((prevState) => ({
      ...prevState,
      showDsrModalLoader: true,
    }))
  }

  const handleStopDsrModalLoader = () => {
    setLocalState((prevState) => ({
      ...prevState,
      showDsrModalLoader: false,
    }))
  }

  const handleAddRemoveMessageReaction = (reactionProps) => {
    const updatePostReactionPayload = {
      postReactionPayload: reactionProps,
      dsrId: dsrId,
    }

    editDsrReaction(updatePostReactionPayload)
  }

  const handleConfirmationModalOpen = (payload, confirmationType) => {
    const { postId, replyId } = payload

    setLocalState((prevState) => ({
      ...prevState,
      showConfirmationModal: true,
      confirmationType,
      postIdToEdit: postId,
      replyIdToEdit: replyId,
    }))
  }

  const handleConfirmationModalClose = () => {
    setLocalState((prevState) => ({
      ...prevState,
      showConfirmationModal: false,
      confirmationType: '',
      postIdToEdit: '',
      modalTransferPayload: '',
    }))
  }

  const handleChangeUserActionRequired = (newUserActionRequired, modalTransferPayload) => {
    changeDsrPopupState(newUserActionRequired)
    changeDsrModalState(true)
    setLocalState((prevState) => ({
      ...prevState,
      modalTransferPayload,
    }))
  }

  const handleEditPost = (editPostPayload) => {
    // Data manipulation to smooth out frontend experience so that reloads are not required. Async updates in the backend
    // Future scope: Comment back in when UX issue of near real-time updates from other users are seen in feed.
    const { userActionRequired, postId, replyId } = editPostPayload || {}
    const foundIndex = dsrFeedContent.findIndex((msg) => msg.postId === postId)

    let postContentToEdit = ''

    switch (userActionRequired) {
      case 'edit-dsr-message-post': {
        postContentToEdit = dsrFeedContent && dsrFeedContent[foundIndex].postContent
        changeDsrModalState(true)
        break
      }
      case 'edit-dsr-reply': {
        const replyArray =
          dsrFeedContent && dsrFeedContent[foundIndex].postReplies
        const foundReplyIndex = replyArray?.findIndex((reply) => reply.replyId === replyId)
        postContentToEdit = replyArray[foundReplyIndex].replyContent
        changeDsrModalState(true)
        break
      }
      case 'create-new-reply': {
        changeDsrModalState(false)
        break
      }
      default: {
        break
      }
    }
    changeDsrPopupState(userActionRequired)

    setLocalState((prevState) => ({
      ...prevState,
      postContentToEdit,
      postTagProperties: dsrFeedContent[foundIndex].taggedUsers,
      postIdToEdit: postId,
      replyIdToEdit: replyId ? replyId : '',
    }))
  }

  const handleDsrModalClose = () => {
    changeDsrPopupState('')
    changeDsrModalState(false)

    setLocalState((prevState) => ({
      ...prevState,
      postIdToEdit: '',
    }))
  }

  const handleUpdatePostContent = (updatedContent) => {
    handleSetShowDsrModalLoader()

    let foundIndex = dsrFeedContent.findIndex((msg) => {
      return msg.postId === localState.postIdToEdit
    })

    let postToUpdate = dsrFeedContent[foundIndex]

    if (postToUpdate?.postContent.trim() !== updatedContent?.postContent.trim()) {
      const updatedPost = {
        ...postToUpdate,
        ...updatedContent,
        editedMessageAt: new Date(),
      }


      const newDataSet = dsrFeedContent.map((post, index) => {
        return index === foundIndex ? updatedPost : post
      })
      replaceDsrFeedContent(newDataSet)

      const editedPostPayload = {
        dsrId: dsrId,
        updatedPost,
      }
      editDsrFeedPost(editedPostPayload)
    } else {
      // No update required if string value is the same (i.e. not edits/changes). Just close the modal.

      changeDsrPopupState('')
      changeDsrModalState(false)
    }
  }

  const handleCreateNewPostReply = ({ replyContent }) => {
    const { userId, userName, memberProfilePicSrc } = userProfile || {}

    const newReplyMetadata = {
      currentPostArray: dsrFeedContent,
      postId: localState.postIdToEdit,
      replyContent,
      userIdToken: userId, // TODO: Change the userIdToken field to userId only
      userName,
      memberProfilePicSrc,
    }

    // Updated in state to provide real-time UX display
    createNewPostReply({ ...newReplyMetadata, replaceDsrFeedContentAction: replaceDsrFeedContent })
    changeDsrPopupState('')
    changeDsrModalState(false)

    // Update in backend DB
    const newDsrFeedReplyMetadata = {
      dsrId: dsrId,
      postId: localState.postIdToEdit,
      replyContent,
      // replyType
    }

    createNewDsrFeedReply(newDsrFeedReplyMetadata)
  }

  const handleEditPostReply = (updatedContent) => {
    handleSetShowDsrModalLoader()

    let foundIndex = dsrFeedContent.findIndex(
      (msg) => msg.postId === localState.postIdToEdit,
    )
    let postToUpdate = dsrFeedContent && dsrFeedContent[foundIndex]

    let { postReplies } = postToUpdate || {}
    let foundReplyIndex = postReplies.findIndex(
      (reply) => reply.replyId === localState.replyIdToEdit,
    )
    let replyToUpdate = postReplies && foundReplyIndex >= 0 ? postReplies[foundReplyIndex] : {}

    const { replyContent } = updatedContent || ''

    // Compare string values & update only values are different
    if (replyToUpdate.replyContent.trim() !== updatedContent.replyContent.trim()) {
      // Organise the reply
      const updatedReply = {
        ...replyToUpdate,
        replyContent,
        editedReplyAt: new Date(),
      }

      postReplies[foundReplyIndex] = updatedReply

      const updatedPost = {
        ...postToUpdate,
        updatedReply,
        // editedMessageAt: new Date(),
      }

      const newDataSet = dsrFeedContent.map((post, index) =>
        index === foundIndex ? updatedPost : post,
      )
      replaceDsrFeedContent(newDataSet)

      if (updatedContent.replyContent.trim() === '') {
        console.log('Throw error and inform user about empty field')
      } else {
        // Send to DB to save the edited reply
        const editedReplyPayload = {
          dsrId: dsrId,
          postIdToEdit: localState.postIdToEdit,
          replyIdToEdit: localState.replyIdToEdit,
          updatedReply: updatedReply,
        }

        editDsrReply(editedReplyPayload)
      }
    } else {
      changeDsrPopupState('')
      changeDsrModalState(false)

      // No update. Just close the modal.
    }
  }

  const handleOpenNewPostModal = (userActionRequired, payload) => {
    const {setShowUploadImage} = payload || {}

    changeDsrPopupState(userActionRequired)
    changeDsrModalState(true)
    setShowPostUploadImageOption(setShowUploadImage ? setShowUploadImage : false)
  }

  const handleDeleteMessagePost = (postId) => {
    if (!postId) {
      console.log('There is no postId')
    } else {
      const updateDsrMessageFields = {
        dsrMessageContentArray: dsrFeedContent,
        operation: 'update-message-status',
        postId,
        fieldsToUpdate: {
          postStatus: 'deleted',
          postDeletedAt: new Date(),
        },
      }

      // Updated in state to provide real-time UX display
      updateDsrPost({
        ...updateDsrMessageFields,
        replaceDsrFeedContentAction: replaceDsrFeedContent,
      })

      // Update in backend DB
      const deletePostPayload = {
        dsrId: dsrId,
        postId,
      }

      deleteDsrFeedPost(deletePostPayload)
    }

    // Close & reset temp UX control parameters
    handleConfirmationModalClose()

    // LATER improvement scope: return value to display general error using outcome redux
  }

  const handleDeleteReply = ({ postIdToEdit, replyIdToEdit }) => {
    const updateDsrMessageFields = {
      dsrMessageContentArray: dsrFeedContent,
      operation: 'update-deleted-reply-status',
      postId: postIdToEdit,
      replyId: replyIdToEdit,
    }
    updateDsrPost({ ...updateDsrMessageFields, replaceDsrFeedContentAction: replaceDsrFeedContent })


    // Manage UX without re-loading the page

    // Write the new soft deleted status into DB
    const deleteReplyPayload = {
      dsrId: dsrId,
      postIdToEdit,
      replyIdToEdit,
    }

    deleteDsrReply(deleteReplyPayload)

    // Close & reset temp UX control parameters
    handleConfirmationModalClose()
  }

  const handleCreateNewPost = ({ userActionRequired, inputValue, files, postType, loomVideo, userTagProperties }) => {
    switch (userActionRequired) {
      case 'create-new-post': {
        handleSetShowDsrModalLoader()

        createNewDsrFeedPost({
          dsrId,
          postType: postType ? postType : 'short-text',
          postContent: inputValue,
          files,
          userTagProperties
        })
        break
      }
      case 'create-video-post': {
        handleSetShowDsrModalLoader()

        createNewDsrFeedPost({
          dsrId,
          postType: postType,
          postContent: inputValue,
          loomVideo,
          userTagProperties
        })
        break
      }
      case 'create-faq-question': {
        handleSetShowDsrModalLoader()

        createNewDsrFeedPost({
          dsrId,
          postType: 'faq-question-post',
          postContent: inputValue,
        })
        break
      }
      default: {
        console.log('Cannot create new post when handling this type of action:', userActionRequired)
        break
      }
    }
  }

  const handleSelectSubpage = (newSelectedDisplayPageId, newSelectedDisplayFixedSubpageType) => {

    if (newSelectedDisplayPageId && newSelectedDisplayFixedSubpageType) {
      // Error state if both values are provided to avoid showing 
      changeSelectSubpage("")
      changeDsrPageDisplay("")
     } else {
      if (newSelectedDisplayPageId && !newSelectedDisplayFixedSubpageType && (newSelectedDisplayPageId !== selectedDisplayPageId)) {
        // Takes care of Analytics before changing pageId/ subpageType
    
        changeDsrPageDisplay(newSelectedDisplayPageId)
       } else if (!newSelectedDisplayPageId && newSelectedDisplayFixedSubpageType && (newSelectedDisplayFixedSubpageType !== selectedDisplaySubpage)) {
      
        changeSelectSubpage(newSelectedDisplayFixedSubpageType)
       }

       if (isGuestUser) {
        // For Sales intelligence
        setResetEngagementTimer(true)
  
        logGuestDsrAnalytics({
          eventType: 'guest-views-subpage',
          analyticsPayload: {
            userId: getThisUserIdToken(), 
            dsrId, 
            sessionId: sessionId,
            engagementDurationSeconds: activeSeconds,
            viewedPageId: selectedDisplayPageId ? selectedDisplayPageId : selectedDisplaySubpage,
          }
        })
      }

      // For internal BI
       sendToGA('view_dsr_page', {
        'eventTimestamp': new Date().toISOString(),
        'dsrId': dsrId,
        'userIdToken': getThisUserIdToken(),
        'pageId': selectedDisplayPageId ? selectedDisplayPageId : selectedDisplaySubpage,
        'pageEngagementTimeSeconds': activeSeconds
      });
     } 
    
  }
  
  const loadMoreFeedContent = () => {
    loadMoreFeedContentAct(dsrId)
  }
  // have new state that controls displaying loader inside Modal
  
  return (
    // <Flex className="dsr-content__section">
    
    <Flex justifyContent={['flex-start', 'flex-start', 'flex-start', 'center', 'center']} className="dsr-content__section">
        {/* // Engagement Time is meant to be hidden and has no displayed UI */}
        {isGuestUser && 
        <EngagementTimer 
          resetEngagementTimer={resetEngagementTimer} 
          handleResetEngagementTimer={handleResetEngagementTimer}
          // activeSeconds={activeSeconds}
          setActiveSeconds={setActiveSeconds}
        />
      }
      <Flex className="dsr-content__container">
        <Flex direction="column" align="flex-start" w="100%" height="100%">
          <Flex direction="row" align="flex-start" width="100%">
            <Flex display={['none', 'none','flex','flex']} position='sticky' top={headerMenuHeight} bg="white">
              <DsrPageSideMenu
                pageArray={dsrPagesRedux}
                selectedDisplayPageId={selectedDisplayPageId}
                handleSelectSubpage={handleSelectSubpage}
                headerMenuHeight={headerMenuHeight}
              />
            </Flex>
            <Flex
              direction={['column', 'column', 'column', 'row']}
              justify="center"
              flexWrap="wrap"
              width="100%"
              background="#F4F4F4"
              h='auto'
              minH='100vh'
            >
              <Box className="dsr-content__middle-column-container" maxWidth={['90%','90%','800px','800px']} px="1em" >
                {selectedDisplaySubpage === 'updates-subpage' &&
                  <DisplayNewsFeed
                    dsrId={dsrId}
                    dsrTitle={dsrConfiguration?.dsrTitle}
                    userIdToken={localState.userIdToken}
                    displayedPostContent={dsrFeedContent}
                    loadMoreFeedContent={loadMoreFeedContent}
                    loadingMoreContent={dsrFeedContent?.isLoading || false}
                    showLoadMore={showLoadMoreRedux}
                    userProfile={userProfile}
                    dsrTeamMembers={dsrConfiguration?.dsrTeamMembers}
                    handleCreateNewPost={handleCreateNewPost}
                    handleAddRemoveMessageReaction={handleAddRemoveMessageReaction}
                    onClickDeleteMessageOption={handleConfirmationModalOpen}
                    onClickDeleteReplyOption={handleConfirmationModalOpen} // Refactor possible?
                    // handleRemoveMessageReaction={this.handleRemoveMessageReaction}
                    showDsrModal={showDsrModalState}
                    showDsrModalLoader={localState.showDsrModalLoader}
                    userActionRequired={userActionRequiredState}
                    modalTransferPayload={localState.modalTransferPayload}
                    handleChangeUserActionRequired={handleChangeUserActionRequired}
                    handleEditPost={handleEditPost}
                    handleDsrModalClose={handleDsrModalClose}
                    postContentToEdit={localState.postContentToEdit}
                    handleUpdatePostContent={handleUpdatePostContent}
                    handleCreateNewPostReply={handleCreateNewPostReply}
                    handleEditPostReply={handleEditPostReply}
                    handleOpenNewPostModal={handleOpenNewPostModal}
                    faqPageId={faqPageIdRedux}
                    refreshData={refreshData}
                    handleSelectSubpage={handleSelectSubpage}
                    handleStopDsrModalLoader={handleStopDsrModalLoader}
                  />
                }
              
                <RenderSubpageContent
                  pageArray={dsrPagesRedux}
                  selectedDisplaySubpage={selectedDisplaySubpage}
                  selectedDisplayPageId={selectedDisplayPageId}
                  dsrTeamMembers={dsrTeamMembersRedux}
                  dsrId={dsrId}
                  dsrActionList={dsrActionList}
                  dsrProperties={dsrPropertiesRedux}
                  handleSelectSubpage={handleSelectSubpage}
                  handleChangeUserActionRequired={handleChangeUserActionRequired}
                />

              {isMobileView && showBranding && displayBrandingLabel("mobile")}
             
   
              </Box>
            </Flex>

            {/* // This section houses dynamically rendered components that depends on user journey */}
            {localState.showConfirmationModal && localState.confirmationType && (
              <DsrConfirmationModal
                // Logics
                postIdToEdit={localState.postIdToEdit}
                replyIdToEdit={localState.replyIdToEdit}
                confirmationType={localState.confirmationType} // Get from state to make this dynamic
                // Function
                handleCloseConfirmationModal={handleConfirmationModalClose}
                handleDeleteMessagePost={handleDeleteMessagePost}
                handleDeleteReply={handleDeleteReply}
              />
            )}

              <DsrDynamicModal
                dsrId = {dsrId}
                userActionRequired={userActionRequiredState}
                showDsrModal={showDsrModalState}
                showDsrModalLoader={localState.showDsrModalLoader}
                handleDsrModalClose={handleDsrModalClose}
                postContentToEdit={localState.postContentToEdit}
                postTagProperties={localState.postTagProperties}
                handleUpdatePostContent={handleUpdatePostContent}
                handleEditPostReply={handleEditPostReply}
                postId={localState.postIdToEdit}
                replyId={localState.replyIdToEdit}
                dsrTeamMembers={dsrTeamMembersRedux}
                handleCreateNewPost={handleCreateNewPost}
                handleChangeUserActionRequired={handleChangeUserActionRequired}
                modalTransferPayload={localState.modalTransferPayload}
                editDsrFeedReplyOutcome={editDsrFeedReplyOutcome}
                handleStopDsrModalLoader={handleStopDsrModalLoader}
                showPostUploadImageOption={showPostUploadImageOption}
              />
            
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )

}

const mapStateToProps = (state) => {
  return {
    dsrActionList: state.dsrActionList.results.data,
    userActionRequiredState: state.dsrPopup.UserActionRequired,
    showDsrModalState: state.dsrModal.showDsrModal,
    selectedDisplaySubpage: state.dsrPage.selectedDisplaySubpage,
    selectedDisplayPageId: state.dsrPage.selectedDisplayPageId,
    currentDsrId: state.dsrFeedContent.id,
    showLoadMoreRedux: state.dsrFeedContent.allowLoadMore,
    dsrPagesRedux: state.dsrPages.pages,
    faqPageIdRedux: state.faqPageId.id,

    dsrPropertiesRedux: state.draftDsrProperties.properties,
    isMobileView: state.mobileViewMode.isMobileView,
    dsrTeamMembersRedux: state.dsrTeamMembers.members,
    dsrUserTeam: state.dsrUserTeam.userTeam,
    createNewDsrFeedPostOutcome: state.createNewDsrFeedPostOutcome.results.data,
    editDsrFeedPostOutcome: state.editDsrFeedPostOutcome.results.data,
    editDsrFeedReplyOutcome: state.editDsrFeedReplyOutcome.results.data,

    userPrivileges: state.userPrivileges.results
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      editDsrReaction,
      editDsrFeedPost,
      deleteDsrFeedPost,
      deleteDsrReply,
      createNewDsrFeedPost,
      createNewDsrFeedReply,
      editDsrReply,
      changeDsrPopupState,
      changeDsrModalState,
      changeSelectSubpage,
      changeDsrPageDisplay,
      loadMoreFeedContentAct,
      replaceDsrFeedContent,
      logGuestDsrAnalytics,
      getDsrUpdatedFeedContent, // TODO: Review - Is this required?
    },
    dispatch,
  )

export default memo(withRouter(connect(mapStateToProps, mapDispatchToProps)(RenderDsrContent)))
