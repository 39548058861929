import React, {memo} from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Box, Divider, Flex } from '@chakra-ui/react'
import DsrCreatePostTypeButton from '../../dsr-create-post/DsrCreatePostTypeButton';


function PublicDsrCreatePostDecoy({ handleOpenSignupOrLoginModal, isMobileView }) {

  return (
    <React.Fragment>
      <Box p='1em' w='100%' bg='white' rounded='0.25em' border='1px solid' borderColor='gray.300'>
        <Flex mt='0.625em' justify='flex-start' width='100%'>
          <Box
            px='0.5em'
            width='100%'
            border='1px solid' borderColor='gray.400' rounded='0.25em'
            cursor='pointer'
            onClick={() => handleOpenSignupOrLoginModal()}>
            <Box color='gray.500' px='0.8em' py='0.5em' fontSize='0.9em'>Start a Post</Box>
          </Box>
        </Flex>
        {isMobileView ?
          <Flex justify='space-between' align='center'>
            <Flex style={{ gap: '20px' }} my='1em' mt='1.5em' width='100%' justifyContent='space-between' flexWrap='wrap'>
              <DsrCreatePostTypeButton isProductMarketing={true} type='ask-question' handleOpenSignupOrLoginModal={handleOpenSignupOrLoginModal} />
              <DsrCreatePostTypeButton isProductMarketing={true} type='upload-image' handleOpenSignupOrLoginModal={handleOpenSignupOrLoginModal} />
            </Flex>
          </Flex>
          :
          <Flex justify='space-between' align='center'>
            <Flex style={{ gap: '20px' }} my='1em' mt='1.5em' width='100%' justifyContent='space-between' flexWrap='wrap'>
              <DsrCreatePostTypeButton isProductMarketing={true} type='ask-question' handleOpenSignupOrLoginModal={handleOpenSignupOrLoginModal} />
              <DsrCreatePostTypeButton isProductMarketing={true} type='upload-image' handleOpenSignupOrLoginModal={handleOpenSignupOrLoginModal} />
              <DsrCreatePostTypeButton isProductMarketing={true} type='record-loom-video-decoy' handleOpenSignupOrLoginModal={handleOpenSignupOrLoginModal} />
              <DsrCreatePostTypeButton isProductMarketing={true} type='create-action-item-decoy' handleOpenSignupOrLoginModal={handleOpenSignupOrLoginModal} />
            </Flex>
          </Flex>
        }
      </Box>

      <Box mt='1.5em' mb='0.5em'>
        <Divider />
      </Box>
    </React.Fragment>
  );

}

const mapStateToProps = (state) => ({
  isMobileView: state.mobileViewMode.isMobileView
})

export default memo(withRouter(connect(mapStateToProps, null)(PublicDsrCreatePostDecoy)))
