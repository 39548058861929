import { Box, Flex } from '@chakra-ui/react'
import DsrConfirmationModal from '../../components/DsrConfirmationModal'
import React, { useState, useEffect } from 'react'
import { bindActionCreators } from 'redux'
import {
  changeDsrPageDisplay,
  changeSelectSubpage,
  setNewDsrDraftCurrentPage,
} from '../../actions/dsr-actions'
import { connect } from 'react-redux'
import { useParams, withRouter } from 'react-router-dom'
import DsrProspectDraftSideMenu from './DsrProspectDraftSideMenu'
import RenderDraftSubpageContent from '../../dsr-draft/RenderDraftSubpageContent'


// Note: Render Dsr Content for DRAFT states
const RenderProspectDraftContent = ({
  changeDsrPageDisplay,
  changeSelectSubpage,
  selectedDisplaySubpage,
  pagesLocalState,
  replaceDsrPages,
  addDsrPage,
  removeDsrPage,
  selectedDisplayPageId,
  dsrPropertiesRedux
}) => {

  const { pDsrId } = useParams()
  const [localState, setLocalState] = useState({
    showConfirmationModal: false,
    confirmationType: '',
  })

  useEffect(() => {
    if ((!selectedDisplayPageId && pagesLocalState)) {
      changeDsrPageDisplay(pagesLocalState[0].pageId)
    }
}, [selectedDisplayPageId, pagesLocalState])

  // Page Management
  const handleSelectSubpage = (selectedDisplayPageId, selectedDisplayFixedSubpageType) => {

    if (selectedDisplayPageId && !selectedDisplayFixedSubpageType) {
     changeDsrPageDisplay(selectedDisplayPageId)
 
    } else if (!selectedDisplayPageId && selectedDisplayFixedSubpageType) {
     changeSelectSubpage(selectedDisplayFixedSubpageType)
 
    } else {
     // Error state if both values are provided to avoid showing 
     changeSelectSubpage("")
     changeDsrPageDisplay("")
    }
   
   }

  // Functions to change frontend local state of dsrPages
  const handleDisplayPageChange = (pageId, event) => {
    const updatedPages = pagesLocalState.map(page =>
      ((page.pageId === pageId) && event.target) ? ({ ...page, displayPage: event.target.checked }) : page
    )

    replaceDsrPages(updatedPages)
  }


  const handleConfirmationModalClose = () => {
    setLocalState((prevState) => ({
      showConfirmationModal: false,
      confirmationType: '',
    }))
  }


  return (
    <Flex className='dsr-content__section'>
      <Flex className='dsr-content__container'>
        <Flex direction="column" align="flex-start" w='100%' minHeight="40em">
        
          <Flex direction="row" align="flex-start" width="100%" height='100%'>
            <Flex bg="white" position='sticky' top='140px'>
              <DsrProspectDraftSideMenu
                pageArray={pagesLocalState}
                // currentDisplaySubpage={selectedDisplaySubpage}
                handleSelectSubpage={handleSelectSubpage}
                handleDisplayPageChange={handleDisplayPageChange}
                addNewDsrPage={addDsrPage}
                removeDsrPage={removeDsrPage}
                selectedDisplayPageId={selectedDisplayPageId}
                selectedDisplaySubpage={selectedDisplaySubpage}
                replaceDsrPages={replaceDsrPages}
              />
            </Flex>
            <Flex
              direction={['column', 'column', 'row', 'row']}
              justify="center"
              flexWrap="wrap"
              width="100%"
              bg='#F4F4F4'
              height='100%'
              pb='3em'
            >
              <Box mt={'2em'} w="100%" px="3em" maxWidth='900px'>
                <RenderDraftSubpageContent
                  dsrPagesLocalState={pagesLocalState}
                  dsrId={pDsrId}
                  replacePages={replaceDsrPages}
                  dsrProperties={dsrPropertiesRedux}
                />
              </Box>
            </Flex>


            {/* // This section houses dynamically rendered components that depends on user journey */}
            {localState.showConfirmationModal && localState.confirmationType && (
              <DsrConfirmationModal
                // Logics
                confirmationType={localState.confirmationType} // Get from state to make this dynamic
                // Function
                handleCloseConfirmationModal={handleConfirmationModalClose}
              />
            )}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

const mapStateToProps = (state) => ({
  selectedDisplaySubpage: state.dsrPage.selectedDisplaySubpage,
  // dsrFeedContent: state.dsrFeedContent.results,
  // isDsrFeedLoading: state.dsrFeedContent,
  // dsrPagesRedux: state.dsrPages.pages,
  draftDsrCurrentPageRedux: state.dsrFeedContent.currentPage,
  // dsrTeamMembersRedux: state.dsrTeamMembers.members,
  // dsrTitleRedux: state.dsrTitle.title,
  // showLoadMoreRedux: state.dsrFeedContent.allowLoadMore,
  // faqPageIdRedux: state.faqPageId.id,
  // loadingMoreContentRedux: state.dsrFeedContent.isLoading,
  // dsrPropertiesRedux: state.draftDsrProperties.properties,
  // currentDsrFeedContentDsrId: state.dsrFeedContent.id,

  dsrPropertiesRedux: state.draftDsrProperties.properties,
  // New Frontend optimization
  selectedDisplayPageId: state.dsrPage.selectedDisplayPageId,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      changeDsrPageDisplay,
      changeSelectSubpage,
      setNewDsrDraftCurrentPage
    },
    dispatch,
  )

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RenderProspectDraftContent))
