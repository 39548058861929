import { MANAGE_SHARED_DOC_REQUEST, MANAGE_SHARED_DOC_SUCCESS, MANAGE_SHARED_DOC_FAILURE, MANAGE_SHARED_DOC_RESET } from '../../actions/dsr-actions-types'

const INITIAL_STATE_DSR_SHARED_DOC = { results: '' }

export function dsrSharedDocReducer(state = INITIAL_STATE_DSR_SHARED_DOC, action) {
    switch (action.type) {
        // case SET_DSR_SHARED_DOCS:
        //     return { dsrSharedDocs: action.payload };
        case MANAGE_SHARED_DOC_RESET:
			return INITIAL_STATE_DSR_SHARED_DOC;
        case MANAGE_SHARED_DOC_REQUEST:
			return { ...state, isLoading: true, results: {} };
		case MANAGE_SHARED_DOC_SUCCESS:
			return { ...state, isLoading: false, results: action.payload };
		case MANAGE_SHARED_DOC_FAILURE:
			return { ...state, isLoading: false, error: 'Error while trying to handle shared docs in DSR' };
        default:
            return state;
    }
}