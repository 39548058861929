import { SET_DSR_TEAM_MEMBERS } from '../../actions/dsr-actions-types'

const INITIAL_STATE_DSR_TEAM_MEMBERS = { members: '' }

export function dsrTeamMembersReducer(state = INITIAL_STATE_DSR_TEAM_MEMBERS, action) {
    switch (action.type) {
        case SET_DSR_TEAM_MEMBERS:
            return { members: action.payload };
        default:
            return state;
    }
}