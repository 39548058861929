import { SET_IS_DSR_ADMIN } from '../../actions/dsr-actions-types'

const INITIAL_STATE_SET_DSR_ADMIN = { isDsrAdmin: '' }

export function setDsrAdminReducer(state = INITIAL_STATE_SET_DSR_ADMIN, action) {
    switch (action.type) {
        case SET_IS_DSR_ADMIN:
            return { isDsrAdmin: action.payload }
        default:
            return state;
    }
}