import * as types from '../actions/file-upload-actions-types';


const INITIAL_STATE_UPLOAD_TEMP_AUDIENCE_LOGO = {
	isLoading: false,
	results: {},
	error: '',
};

export function uploadTempAudienceLogoReducer(state = INITIAL_STATE_UPLOAD_TEMP_AUDIENCE_LOGO, action) {
	switch (action.type) {
		case types.UPLOAD_TEMP_AUDIENCE_LOGO_REQUEST:
			return { ...state, isLoading: true, results: {} };
		case types.UPLOAD_TEMP_AUDIENCE_LOGO_SUCCESS:
			return { ...state, isLoading: false, results: action.payload };
		case types.UPLOAD_TEMP_AUDIENCE_LOGO_FAILURE:
			return { ...state, isLoading: false, error: 'Error while trying to upload temp audience logo' };
		default:
			return state;
	}
}



