import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'

import { useToast } from '@chakra-ui/react'

import { setDsrPages, updateDsr, resetUpdateDsrOutcome } from '../actions/dsr-actions'
import LaunchdeckButton from '../../global/components/helper-components/components/LaunchdeckButton'
import { triggerLaunchdeckToast } from '../../global/helpers/global-helpers'


function SaveDsrDraftButton(props) {
  const toast = useToast();
  const {dsrId, dsrPages, updateDsrOutcome, isMobileView} = props || {};

  useEffect(() => {
    if (updateDsrOutcome) {
      const {updateDsrStatus, updatedDsrId, sourceEvent} = updateDsrOutcome || {};
      if ( sourceEvent==="save-draft-dsr") {
        if (updateDsrStatus && updatedDsrId === dsrId && dsrId !== undefined) {
          toast(
            triggerLaunchdeckToast({
            useCase: "show-success-state",
            label: "Your draft has been successfully saved", 
            isMobileView
            })
          )

        } else if (!updateDsrStatus) {
          toast(
            triggerLaunchdeckToast({
            useCase: "show-error-state",
            label: "Unfortunately something went wrong. Please try again later.", 
            isMobileView
            })
          )
        }
  
          props.action.resetUpdateDsrOutcome()
      }
      }
  }, [updateDsrOutcome, toast, dsrId, props.action]);

  function handleClick() {
    props.action.updateDsr({
      dsrId,
      // Note: Future scope - save only the parts that have changed, and not all the pages. Less risk of wiping out error data.
      dsrPages,
      sourceEvent: 'save-draft-dsr'
    })
    props.action.setDsrPages(dsrPages)

  }

  return (
    <LaunchdeckButton bg='white' color='brandDark.500' onClick={() => handleClick()} label='Save' />
							
  );
}

function mapStateToProps(state) {
  return {
    updateDsrOutcome: state.updateDsrOutcome.results.data,
    isMobileView: state.mobileViewMode.isMobileView
  };
}

function mapDispatchToProps(dispatch) {
  return {
    action: bindActionCreators(
      {
        updateDsr,
        setDsrPages,
        resetUpdateDsrOutcome
      },
      dispatch
    )
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SaveDsrDraftButton));
