import * as types from '../actions/genesis-setup-action-types';


// Access Authentication
const INITIAL_STATE_GENESIS_SETUP_REQUEST = {
	isLoading: false,
	results: {},
	error: '',
};

export function genesisSetupReducer(state = INITIAL_STATE_GENESIS_SETUP_REQUEST, action) {

	switch (action.type) {
		case types.GENESIS_SETUP_RESET:
			return INITIAL_STATE_GENESIS_SETUP_REQUEST;
		case types.GENESIS_SETUP_REQUEST:
			return { ...state, isLoading: true, results: {} };
		case types.GENESIS_SETUP_SUCCESS:
			return { ...state, isLoading: false, results: action.payload };
		case types.GENESIS_SETUP_FAILURE:
			return { ...state, isLoading: false, error: 'Error while trying to setup genesis user' };
		default:
			return state;
	}
}