import { Box, Flex } from '@chakra-ui/react'
import StaticDsrHeader from './StaticDsrHeader'
import DsrGenericErrorModal from './DsrGenericErrorModal'
import React, { memo, useEffect, useRef, useState } from 'react'
import RenderStaticDsrContent from './RenderStaticDsrContent'
import { useParams } from 'react-router-dom'
import { generateSessionId, generateRandomString } from '../../global/helpers/global-helpers'
import { sendToGA } from '../../global/helpers/google-tag-helpers'
import { checkIsLoggedInUser } from '../../auth/helpers/auth-checker'

import { useLocation, useHistory } from 'react-router-dom';
import PageLoader from '../../global/components/helper-components/components/PageLoader'

const StaticDsrDisplayPage = ({
  dsrConfiguration,
  dsrStatusRedux,
  dsrTeamMembersRedux,
  fetchPublicDsrData,
  dsrInvalidRequestRedux,
  logGuestDsrAnalytics,
  isMobileView
}) => {
  const { dsrId } = useParams()
  const location = useLocation();
  const history = useHistory();

  const initRef = useRef(false);
  const analyticsRef = useRef(false);
  const [sessionId, setSessionId] = useState(null)
  const [userId, setUserId] = useState("")
  const [showRoomLoadingState, setShowRoomLoadingState] = useState(true)
  const [isLoggedInUser, setIsLoggedInUser] = useState("")
  const headerMenuHeight = isMobileView ? '170px' : '70px';

  // Page Setup
  useEffect(() => {
    if (!initRef.current && dsrId !== 'new') {
      document.title = 'Launchdeck Digital Sales Room'
      document.description =
        'Create customised private digital sales rooms that let Sales & Purchasing teams agree on deals quickly'

      const isLoggedInUser = checkIsLoggedInUser();
      setIsLoggedInUser(isLoggedInUser)

      const { publicViewsAllowed, authProcess, authProcessSuccess } = location.state || {};

      if ((publicViewsAllowed === true) || (!publicViewsAllowed && authProcess === "access-code-challenge" && authProcessSuccess === true)) {
        if (isLoggedInUser === true && authProcess !== "confirm-public-dsr-access") {
          window.location.href = `/dsr/${dsrId}`
        } else {
          fetchPublicDsrData(dsrId)

          const generatedSessionId = generateSessionId()
          setSessionId(generatedSessionId)

          // GA4
          const tempUserIdToken = 'pub-visitor__' + generateRandomString(8)
          setUserId(tempUserIdToken)

          sendToGA('view_dsr', {
            'eventTimestamp': new Date().toISOString(),
            'dsrId': dsrId,
            'userIdToken': userId
          });
        }
      } else {

        if (!authProcess) {
          // URL hacking scenario
          history.push(`/dsr/access/${dsrId}`, {})
        } else {
          // Random error has occured
          history.push(`/page-not-found`, {
            redirectionUri: `https://www.launchdeck.app`
          })
        }
      }

      initRef.current = true
    }
  }, [dsrId, fetchPublicDsrData]) // fetchPublicDsrData is a new end point to get non sensitive data

  // Analytics
  useEffect(() => {
    if (!analyticsRef.current) {
      logGuestDsrAnalytics({
        eventType: 'guest-enters-dsr',
        analyticsPayload: {
          userId: userId,
          dsrId,
          sessionId: sessionId
        }
      })

      analyticsRef.current = true
    }

  }, [])

  // Business Logic
  useEffect(() => {
    if (
      // (currentDsrId === dsrId) && 
      initRef.current && (dsrInvalidRequestRedux === true)) {

      history.push(`/page-not-found`, {
        redirectionUri: `https://www.launchdeck.app`
      })
    }

    if (dsrStatusRedux) {
      switch (dsrStatusRedux) {
        // case("active"): {
        //   // Allow room to display retrieved data
        //   setShowRoomLoadingState(false)
        // }
        case ("reverted-draft"):
        case ("draft"): {
          history.push(`/page-not-found`, {
            redirectionUri: `https://www.launchdeck.app`
          })

          break;
        }
        default: {
          setShowRoomLoadingState(false)
          // Do not put any response here (activated while page is loading)
          break;
        }
      }

    }


  }, [dsrId, dsrInvalidRequestRedux, dsrStatusRedux])


  return (
    <Flex direction="column" height='100%'>
      <Flex direction="row" flexGrow="1">
        {showRoomLoadingState ?
          <PageLoader />
          :
          <Flex w="100%" direction="column" alignItems="center" bg="white">
            <Box width='100%' position='fixed' zIndex='2'>
              <StaticDsrHeader
                dsrTeamMembers={dsrTeamMembersRedux}
                isLoggedInUser={isLoggedInUser}
              />
            </Box>

            <Flex w="100%" className="authentication-container" justify="center" pt={headerMenuHeight} bg={'#F4F4F4'}>
              <RenderStaticDsrContent
                dsrId={dsrId}
                sessionId={sessionId}
                dsrConfiguration={dsrConfiguration}
              />
              <DsrGenericErrorModal dsrId={dsrId} />
            </Flex>
          </Flex>
        }
      </Flex>
    </Flex>
  )


}

export default memo(StaticDsrDisplayPage)
