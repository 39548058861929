
import React from 'react';

import { Box, Text } from '@chakra-ui/react';
import OrgUserTitleCard from './OrgUserTitleCard';

export default function OrgProfileTeamModule(props) {
  const {
    isAdmin,
    orgIdToken,
    orgName,
    orgAdminUsers,
    orgBasicUsers,
  } = props || {};

  const isLastAdmin = (orgAdminUsers && orgAdminUsers?.length === 1) ?  true : false;

  const renderAdministrators = () => {

    const displayedTitles = orgAdminUsers.map((adminUser) => {

      const { userId } = adminUser || {};
      return (
        <Box key={userId}>
          <OrgUserTitleCard orgUser={adminUser} orgIdToken={orgIdToken} role={'admin-user'} isLastAdmin={isLastAdmin}/>
        </Box>
      )
    });

    return <Box mt='2em'>{displayedTitles}</Box>;
  }

  const renderBasicUsers = () => {
    if (orgBasicUsers && orgBasicUsers.length === 0) {
      return <Text color='gray.400' mt='1em'>None</Text>;
    }

    const displayedTitles = orgBasicUsers.map((basicUser) => {
      const { userId } = basicUser || {}

      return (
        <Box key={userId}>
          <OrgUserTitleCard orgUser={basicUser} orgIdToken={orgIdToken} role={'basic-user'} />
        </Box>
      )
    });

    return <Box mt='2em'>{displayedTitles}</Box>;
  }


  return (
    <Box maxWidth='40em'>
      <Box mt='1.8em'>
        <Text lineHeight='1.375em' fontWeight='500' fontSize='1em' color='gray.700'>Administrators</Text>
        <Text lineHeight='1.375em' fontWeight='400' fontSize='0.8em' color='gray.500' mt='0.5em'>Users tasked with managing {orgName}'s account and settings for its community</Text>
        {orgAdminUsers && renderAdministrators()}
      </Box>

      {(isAdmin && orgBasicUsers) && 
        <Box mt='3em'>
          <Box>
            <Text lineHeight='1.375em' fontWeight='500' fontSize='1em' color='gray.700'>Users</Text>
            <Text lineHeight='1.375em' fontWeight='400' fontSize='0.8em' color='gray.500' mt='0.5em'>Team members empowered to create and manage {orgName}'s Rooms</Text>
            {renderBasicUsers()}
          </Box>
        </Box>
      }
    </Box>
  )
}
