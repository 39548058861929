import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Heading, Flex, Text } from "@chakra-ui/react";
import PageNotFoundImage from '../styles/images/page-not-found.png';


export default class PageNotFoundContent extends Component {
	render() {
		return (

			<Flex direction="column" align="center" bg='white' p='3%' my='2em' rounded='0.8em'>
				<Heading as="h1" boxSize="2xl">Page Not Found</Heading>
				<Text>But you found a sleeping dog instead!</Text>
				<img src={PageNotFoundImage} alt=""/>
				<Text color='brand.500' fontWeight='semibold'><Link to='/login' >Ssshh...quietly head back? </Link></Text>
			</Flex>
		
		)
	}
}

