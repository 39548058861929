import auth from '../../../config/app-specific-config/auth'
import * as types from './duplicate-dsr-page-actions-types'
import { axiosInstance } from '../../../config/app-specific-config/axiosConfig'




////////////////////////////////////////////////////////////
// DSR POST TAG SERVICES
////////////////////////////////////////////////////////////
// POST method
export const duplicateDsrPageSuccess = (response) => {
	return {
		type: types.DUPLICATE_DSR_PAGE_SUCCESS,
    payload: response
	};
};

export const duplicateDsrPageFailure = (error) => {
	return {
		type: types.DUPLICATE_DSR_PAGE_FAILURE,
    error
	};
};

export const duplicateDsrPageRequest = () => {
	return {
		type: types.DUPLICATE_DSR_PAGE_REQUEST
	};
};


export function duplicateDsrPage(duplicatePageMetadata) {

	// POST API
	return async (dispatch) => {
		dispatch(duplicateDsrPageRequest());
		try {
			const url = '/dsr/duplicate-page'; 
			const headers = await auth.getAccessToken(); 

			const response = await axiosInstance.post(
				url,
				{duplicatePageMetadata},
				{ headers }
			);
      
			dispatch(duplicateDsrPageSuccess(response.data.data));

		} catch (error) {
			dispatch(duplicateDsrPageFailure(error));
		}
	};
}


export const duplicateDsrPageReset = () => {
	return {
		type: types.DUPLICATE_DSR_PAGE_RESET
	};
};

export function resetDuplicateDsrPage() {

	return async (dispatch) => {
	
		try {
			dispatch(duplicateDsrPageReset());

		} catch (error) {
			dispatch(duplicateDsrPageFailure(error));
		}
	};
}