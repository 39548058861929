import React from 'react';
import {
  Flex,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton, useDisclosure, IconButton, Box, DrawerFooter,
} from "@chakra-ui/react";
import { AiOutlineMenu } from "react-icons/ai";
import DsrPageSideMenu from "./DsrPageSideMenu";
import { bindActionCreators } from "redux";
import {
  changeDsrPageDisplay,
  changeSelectSubpage
} from "../actions/dsr-actions";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import DsrProspectSideMenu from '../dsr-prospecting/DsrProspectSideMenu';
import { displayBrandingLabel, redirectToNewPage } from '../../global/helpers/global-helpers';
import { displayReactIcon } from '../helpers/dsr-display-helpers';


function DsrPageSideMenuDrawer({ selectedDisplayPageId, dsrPagesRedux, changeDsrPageDisplay, changeSelectSubpage, isProspectPage, isMobileView }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleSelectSubpage = (selectedDisplayPageId, selectedDisplayFixedSubpageType) => {
    if (selectedDisplayPageId && !selectedDisplayFixedSubpageType) {
      changeDsrPageDisplay(selectedDisplayPageId)

    } else if (!selectedDisplayPageId && selectedDisplayFixedSubpageType) {
      changeSelectSubpage(selectedDisplayFixedSubpageType)

    }

    else {
      // Error state if both values are provided to avoid showing
      changeSelectSubpage("")
      changeDsrPageDisplay("")
    }

  }

  return (
    <>
      <IconButton
        aria-label="Launchdeck Menu Sidebar"
        fontSize="2rem"
        fontWeight='semibold'
        color='#29ABE2'
        icon={<AiOutlineMenu />}
        onClick={onOpen}
        bg='transparent'
      />
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
      >
        <DrawerOverlay />
        <DrawerContent maxW='17em'>
          <DrawerCloseButton fontSize='0.65em' top='1.5em' right='1.5em' color='white'/>
          <DrawerHeader p="1em 2em" bg='brandDark.500' color='white' fontSize='0.875em'>Room Menu</DrawerHeader>

          <DrawerBody>
            <Box marginLeft='-20px' height='80vh'>
              {isProspectPage ?
                <DsrProspectSideMenu
                  pageArray={dsrPagesRedux}
                  selectedDisplayPageId={selectedDisplayPageId}
                  handleSelectSubpage={handleSelectSubpage}
                  closeMenuDrawer={onClose}
                />
                :
                <DsrPageSideMenu
                  pageArray={dsrPagesRedux}
                  selectedDisplayPageId={selectedDisplayPageId}
                  handleSelectSubpage={handleSelectSubpage}
                  closeMenuDrawer={onClose}
                // isProspectPage={isProspectPage}
                />
              }
            </Box>
          </DrawerBody>

          {isMobileView &&
            <DrawerFooter>
              <Flex direction='column' w='100%'>
              <Flex
                py='1em' pl='1em' bg='gray.50' border='1px solid'
                justify='flex-start' fontSize='0.875em'
                borderColor='gray.200' align='center'
                rounded='0.5em'
                margin='1em auto'
                bottom='1em'
                width='-webkit-fill-available'
                onClick={()=>redirectToNewPage(`/home`)}
              >
                {displayReactIcon('dashboard', '1.5em')}
                <Box > Back to Home </Box>
              </Flex>
              <Box>
              {displayBrandingLabel(isMobileView ? "mobile" : "web")}
              </Box>
              </Flex>
            </DrawerFooter>
          }
        </DrawerContent>
      </Drawer>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    selectedDisplayPageId: state.dsrPage.selectedDisplayPageId,
    dsrPagesRedux: state.dsrPages.pages,
	  isMobileView: state.mobileViewMode.isMobileView
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      changeSelectSubpage,
      changeDsrPageDisplay
    },
    dispatch,
  )

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DsrPageSideMenuDrawer))