import React, { useState, useEffect, memo } from "react";
import { Box, Flex, Image } from "@chakra-ui/react";

import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { uploadTempAudienceLogo } from '../../global/actions/file-upload-actions';
import { changeDsrModalState, changeDsrPopupState } from '../../digital-sales-room/actions/dsr-actions'
import LaunchdeckButton from "../../global/components/helper-components/components/LaunchdeckButton";

function DsrManageOrgLogosModule({
  logoDisplayMode,
  isPagePreviewMode,
  dsrDisplayLogoImages,
  dsrStatusRedux,
  changeDsrPopupState,
  changeDsrModalState,
  dsrTextLogoString,
  // Redux
  isPublicDsrEnvironment

}) {
  const [isPublishedDsr, setIsPublishedDsr] = useState("")
  
  useEffect(() => {
    if (dsrStatusRedux === 'active') {
      setIsPublishedDsr(true)
    } else setIsPublishedDsr(false)
  }, [dsrStatusRedux])


  const {
    displayedAudienceLogo,
    displayedHostLogo,
    hideDisplayLogoModule,
    foundAudienceOrgLogo,
    noGuestLogo 
  } = dsrDisplayLogoImages || {};

  const handleOpenEditLogoModal = () => {
    if (!isPublishedDsr) {
      changeDsrPopupState('manage-dsr-audience-logo')
      changeDsrModalState(true)
    }
  }

  const uploadAudienceLogo = () => {
    return (
      <Flex align='center' ml='1em'>
        <LaunchdeckButton bg='#29ABE21A' color='#29ABE2' fontSize='0.875em' onClick={() => handleOpenEditLogoModal()} label='Add Guest logo' />
      </Flex>
    )
  }

  const renderLogoImage = (team) => {
    if (team === 'host' && displayedHostLogo) {
      return (
        <Box border='2px solid' borderColor='white' rounded='0.5em' bg='white'>
          <Image maxH={'2.5em'} rounded='0.25em' src={displayedHostLogo} />
        </Box>

      )
    } else if (team === 'audience') {
      if ((!!isPublishedDsr && !!noGuestLogo) || (!!isPagePreviewMode && !!noGuestLogo)) {
        return null
      } 

      if (dsrTextLogoString && !displayedAudienceLogo) {
        return (
          <Flex align='center'>
            <Box mx='0.5em' fontSize='0.875em'>x</Box>
            <Box p='0.5em' border='2px solid' borderColor='white' rounded='0.5em' bg='white'>
              {dsrTextLogoString}
            </Box>
          </Flex>
        )
      } else if (displayedAudienceLogo) {
        return (
          <Flex align='center'>
            {/* <Box mx='0.5em'>&</Box> */}
            <Box border='2px solid' borderColor='white' rounded='0.5em' bg='white' ml='1em'>
              <Image maxH={'2.5em'} rounded='0.25em' src={displayedAudienceLogo} />
           </Box>
          </Flex>
        )
      } else if (foundAudienceOrgLogo) {
        return (
          <Flex align='center'>
            <Box mx='0.5em'>&</Box>
            <Box border='2px solid' borderColor='white' rounded='0.5em' bg='white'>
            <Image maxH={'2.5em'} rounded='0.25em' src={foundAudienceOrgLogo} />
           </Box>
          </Flex>
        )
      } else {
        if (isPublishedDsr === true || isPagePreviewMode === true) {
          return null
        } else {
          return uploadAudienceLogo()
        }

      }

    } else return null;
  }

  if (!hideDisplayLogoModule) {
    switch (logoDisplayMode) {
      case ('default-dsr'): {
        // This is the default case
        return (
          <Box>
            <Flex w='100%' align='center' justify='center'>
              {renderLogoImage('host')}
        
              <Box cursor={(isPublishedDsr || isPublicDsrEnvironment || isPagePreviewMode) ? 'default' : 'pointer'} onClick={() => (isPublishedDsr || isPublicDsrEnvironment || isPagePreviewMode) ? null : handleOpenEditLogoModal()}>
                {renderLogoImage('audience')}
              </Box>
              
            </Flex>
          </Box>
        )
      }
      case ('prospect-dsr'): {
        return (
          <Box p='1em'>
          <Flex w='100%' align='center' justify='center'>
            {renderLogoImage('host')}
          </Flex>
        </Box>
        )
      }
      default: {
        return null;
      }
    }
  } else return null;




}

const mapStateToProps = (state) => ({
  dsrStatusRedux: state.dsrStatus.status,
  dsrDisplayLogoImages: state.dsrDisplayLogoImages.dsrDisplayLogos,
  dsrTextLogoString: state.draftDsrProperties.properties.dsrTextLogoString,
  uploadTempAudienceLogoOutcome: state.uploadTempAudienceLogoOutcome.results.data,
  isPublicDsrEnvironment: state.publicDsrEnvironment.publicDsrEnv
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      uploadTempAudienceLogo, // TODO: Review necessity of this
      changeDsrPopupState,
      changeDsrModalState
    },
    dispatch,
  )

export default memo(withRouter(connect(mapStateToProps, mapDispatchToProps)(DsrManageOrgLogosModule)))
