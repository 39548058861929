import React from "react";
import { Button } from '@chakra-ui/react';

import { Box, Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";
import { IoChevronDownOutline } from "react-icons/io5";


export default function DsrInvitedUserOptionsButton(props) {

  const { userId, role, handleRemoveUserAccess } = props;

  return (
    <React.Fragment>
      <Menu closeOnSelect={true} placement="bottom-end">
        <MenuButton
          as={Button}
          py="9px"
          px="12px"
          w="128px"
          fontSize="14px"
          padding="16px 12px"
          background="gray.100"
          border="1px solid #CBD5E0"
          {...{
            rightIcon: <IoChevronDownOutline />
          }}
        >
          <Box className='chosen-option-text-display' color='gray.600'>Invited</Box>
        </MenuButton>
        <MenuList minWidth="8em" >
          <MenuItem name='showDeleteReplyModal'>
            <Box className='member-role-options-dropdown-menu__dropdown-option-display' onClick={() => handleRemoveUserAccess(userId, role)}>
              Remove Access
            </Box>
          </MenuItem>
        </MenuList>
      </Menu>
    </React.Fragment>
  );

}


