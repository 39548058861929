import React, { useState } from 'react';
import { Flex, PinInput, PinInputField } from '@chakra-ui/react';

import './styles/alphanumeric-input.scss';
import LaunchdeckButton from '../../global/components/helper-components/components/LaunchdeckButton';
import WarningTextMessage from '../../global/components/helper-components/components/WarningTextMessage';

function AlphanumericInput({ defaultValue, handleButtonClick, errorLogic, isMobileView }) {
  const [inputValue, setInputValue] = useState(defaultValue ? defaultValue : "");

  const handleInputChange = (input) => {
    const value = input.replace(/[^a-zA-Z0-9]/g, ''); // Remove non-alphanumeric characters
    setInputValue(value.substring(0, 6).toUpperCase()); // Limit to 6 characters
  };

  const inputSize = isMobileView ? "2.5em" : "4em"
  return (
    <>
      <Flex className="alphanumeric-container" justify='center' mb='0.5em'>
        <Flex w={isMobileView ? '90%' : 'auto'} justify='center' px={!isMobileView && '2em'}>

          <PinInput value={inputValue} onChange={handleInputChange} autoFocus type='alphanumeric'>
            <PinInputField w={inputSize} h={inputSize} mx='0.25em' fontWeight='600' fontSize='1em' />
            <PinInputField w={inputSize} h={inputSize} mx='0.25em' fontWeight='600' fontSize='1em' />
            <PinInputField w={inputSize} h={inputSize} mx='0.25em' fontWeight='600' fontSize='1em' />
            <PinInputField w={inputSize} h={inputSize} mx='0.25em' fontWeight='600' fontSize='1em' />
            <PinInputField w={inputSize} h={inputSize} mx='0.25em' fontWeight='600' fontSize='1em' />
            <PinInputField w={inputSize} h={inputSize} mx='0.25em' fontWeight='600' fontSize='1em' />
          </PinInput>

        </Flex>

      </Flex>
      <Flex w='100%' justify='center'>
        <WarningTextMessage errorLogic={errorLogic} message='It seems to be incorrect. Contact your Host for help.' />
      </Flex>

      <Flex w='100%' mt='2em' py='0.625em'>
        <Flex justify='center' w='100%'>
          <LaunchdeckButton noHover bg='brand.500' color='gray.50' w='100%' label='Enter Room' onClick={() => handleButtonClick(inputValue)} />
        </Flex>
      </Flex>
    </>
  );
}

export default AlphanumericInput;