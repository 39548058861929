// DSR invite process
// Check if user is existing

export const CHECK_IF_EXISTING_USER_REQUEST = 'check_if_existing_user_request';
export const CHECK_IF_EXISTING_USER_SUCCESS = 'check_if_existing_user_success';
export const CHECK_IF_EXISTING_USER_FAILURE = 'check_if_existing_user_failure';
export const CHECK_IF_EXISTING_USER_RESET = 'check_if_existing_user_reset'; 

// Check if user is existing

export const MANAGE_JOIN_ROOM_REQUEST = 'manage_join_room_request';
export const MANAGE_JOIN_ROOM_SUCCESS = 'manage_join_room_success';
export const MANAGE_JOIN_ROOM_FAILURE = 'manage_join_room_failure';
export const MANAGE_JOIN_ROOM_RESET = 'manage_join_room_reset'; 
