
import React from 'react'
import { bindActionCreators } from 'redux'
import {
  fetchInitialDsrData,
  getDsrFeedContent,
  resetDsrFeedContent,
  resetDsrPages,
  resetDsrSelectedDisplaySubpage,
  resetDsrTitle,
  setShowDsrModal,
  setUserActionRequired,
  updateDsrReset,
  changeDsrPopupState,
  changeDsrModalState
} from '../actions/dsr-actions'
import { connect } from 'react-redux'
import { DsrEditDraftPage } from './DsrEditDraftPage'
import { withRouter } from 'react-router-dom'
import { duplicateDsrPage } from '../actions/duplicate-dsr-page-actions'

const DsrEditDraftPageContainer = (props) => {
  return <DsrEditDraftPage {...props} />
}

const mapStateToProps = (state) => ({
  // dsrConfiguration: state.publicDsrConfiguration.results.data,
  // dsrConfiguration: state.dsrConfiguration.results.data,
  dsrFeedContent: state.dsrFeedContent.results,
  currentDsrId: state.dsrFeedContent.id,
  dsrPagesRedux: state.dsrPages.pages,
  dsrInvalidRequestRedux: state.dsrInvalidRequest.request,
  dsrTeamMembersRedux: state.dsrTeamMembers.members,
  dsrStatusRedux: state.dsrStatus.status,
  showDsrModalRedux: state.dsrModal.showDsrModal,
  userActionRequiredRedux: state.dsrPopup.UserActionRequired,
  dsrTitle: state.dsrTitle.title,
  userProfileRedux: state.userProfile.profile,
  showDsrModalLoaderRedux: state.showDsrModalLoader.show,
  selectedDisplaySubpage: state.dsrPage.selectedDisplaySubpage,
  isMobileView: state.mobileViewMode.isMobileView
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getDsrFeedContent,
      setShowDsrModal,
      setUserActionRequired,
      fetchInitialDsrData,
      resetDsrFeedContent,
      resetDsrTitle,
      resetDsrSelectedDisplaySubpage,
      resetDsrPages,
      duplicateDsrPage,
      updateDsrReset,
      changeDsrPopupState,
      changeDsrModalState
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DsrEditDraftPageContainer),
)
