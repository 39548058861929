import { SET_PUBLIC_DSR_DISPLAY } from '../../digital-sales-room/actions/public-dsr-actions-types';

const INITIAL_STATE_SET_PUBLIC_DSR_ENV = { publicDsrEnv: '' }

export function setPublicDsrEnvironmentReducer(state = INITIAL_STATE_SET_PUBLIC_DSR_ENV, action) {
    switch (action.type) {
        case SET_PUBLIC_DSR_DISPLAY:
            return { publicDsrEnv: action.payload }
        default:
            return state;
    }
}