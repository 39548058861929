import { SET_DSR_DRAFT_FAQ_PAGE_ID } from '../../actions/dsr-actions-types'

const INITIAL_STATE_DSR_DRAFT_FAQ_PAGE_ID = { id: '' }

export function faqPageIdReducer(state = INITIAL_STATE_DSR_DRAFT_FAQ_PAGE_ID, action) {
    switch (action.type) {
        case SET_DSR_DRAFT_FAQ_PAGE_ID:
            return { id: action.payload };
        default:
            return state;
    }
}