import { SET_USER_PRIVILEGES } from "../actions/auth-action-types";

const INITIAL_STATE_SET_USER_PRIVILEGES = { results: {} }

export function setUserPrivilegesReducer(state = INITIAL_STATE_SET_USER_PRIVILEGES, action) {
    switch (action.type) {
        case SET_USER_PRIVILEGES:
            return { results: action.payload }
        default:
            return state;
    }
}