import config from "../../../config/app-config"; 

export function sendToGA(eventType, eventData) {
  // Check if dataLayer is defined
  const {distEnv} = config || {}

  if (typeof window.dataLayer !== 'undefined' && distEnv === "production") {
    // Use gtag to send the data to Google Analytics
    window.dataLayer.push({
      'event': eventType,
      ...eventData
    });
  } else {
    console.error('dataLayer is not defined. Make sure it is properly initialized.');
  }
}