import React, { useEffect, useRef } from "react";
import { Box, Flex } from "@chakra-ui/react";
import { DayPicker } from 'react-day-picker';
// import { format, parseISO } from 'date-fns';

import { IoCalendarOutline } from "react-icons/io5";
import 'react-day-picker/dist/style.css';
import '../styles/day-picker.scss';
import { formatDisplayDateOnly } from "../../../digital-sales-room/helpers/timestamp-display-helper";


export default function LaunchdeckDatePicker({ 
  top,
  left,
  right,
  handleClick, 
  handlePickDate, 
  showDatePicker,
  closeDatePicker,
  actionId, 
  focusActionId,
  mapCategoryId,
  dateInput,
  isMobileView
 }) {
  const ref = useRef(null);

  useEffect(() => {
    if (showDatePicker && ref.current && (focusActionId === actionId)) {
      const handleClickOutside = (event) => {
        if (ref?.current && !ref?.current.contains(event.target)) {
          closeDatePicker();
        }
      };

      setTimeout(() => {
        window.addEventListener('click', handleClickOutside);
      }, 0);

      return () => {
        window.removeEventListener('click', handleClickOutside);
      };
    }
  }, [showDatePicker, ref]);



  const displayedDate = dateInput && formatDisplayDateOnly(dateInput)
  // TODO: DayPicker needs a specific date format to display the picked date in the calendar (UI). However postgres formats the date format when saving into DB
  // TODO: Transform postgres timestamp back into compatible format for DayPicker?

  const handleDatePickerClick = (pickedDate) => {
    handlePickDate(pickedDate, mapCategoryId, actionId)
  }

  

  return (
    <Box w="100%" fontWeight='400' color='gray.500' lineHeight='1.375em' position='relative'>
      <Flex whiteSpace='nowrap' w='100%' justify='flex-start'>
        <Flex className='due-date-button' 
        fontSize={isMobileView ? '0.7em' : '0.875em'} 
          onClick={() => handleClick(!showDatePicker, actionId)}>
          <Box as={IoCalendarOutline} boxSize='1em' className='due-date-button__icon' />
          <Box className='due-date-button__button-text'>{displayedDate ? displayedDate : "Add Date"}</Box>

        </Flex>
      </Flex>

      {(showDatePicker && (focusActionId === actionId)) &&
        <Box ref={ref} position='absolute' top={top ? top : '3em'} right={right && right} left={left && left} className="day-picker-container">
          <DayPicker
            mode="single"
            selected={displayedDate}
            onSelect={handleDatePickerClick}
          />
        </Box>
      }
    </Box>
  );
}


