import React, { useState } from 'react';

import { Box, Flex } from '@chakra-ui/react';
import FormTextInput from '../../forms/TextInputField/components/FormTextInput';
import { validateEmail } from '../../global/helpers/global-helpers';
import LaunchdeckButton from '../../global/components/helper-components/components/LaunchdeckButton';
import WarningTextMessage from '../../global/components/helper-components/components/WarningTextMessage';

export default function DsrDetermineUidForm({ handleRetrieveUid }) {

	const [userEmailInput, setUserEmailInput] = useState('');
	const [showInputError, setShowInputError] = useState(false);


	//Function
	const handleButtonClick = () => {
		if (validateEmail(userEmailInput)) {
			handleRetrieveUid(userEmailInput)
		} else {
			setShowInputError(true)
		}
	}

	const onChangeHandler = (event) => {
		setShowInputError(false)

		switch (event.target.name) {
			case ('email'): {
				setShowInputError(false)
				setUserEmailInput(event.target.value.trim())
				break;
			}
			default: {
				break;
			}
		}
	};


	return (
		<Box w="100%" align="center">
			<Box mt='1em' w='100%'>
				<FormTextInput
					name={'email'}
					placeholder={'me@email.com'}
					onChange={onChangeHandler}
					defaultValue={userEmailInput}
					mb='0.2em'
				/>
			</Box>
			<WarningTextMessage errorLogic={showInputError} message='Please provide a valid email' />

			<Flex w='100%' mt='2em' py='0.625em'>
				<Flex justify='center' w='100%'>
					<LaunchdeckButton bg='brand.500' color='gray.50' w='100%' label='Proceed' onClick={() => handleButtonClick()} />
				</Flex>
			</Flex>
		</Box>
	);
};

