import { SET_USER_ACCOUNT_PROFILE } from '../../../profile/actions/users-profile-actions-types';
import { SET_DSR_DRAFT_USER_PROFILE } from '../../actions/dsr-actions-types'

const INITIAL_STATE_DSR_DRAFT_USER_PROFILE = { profile: '' }

export function userProfileReducer(state = INITIAL_STATE_DSR_DRAFT_USER_PROFILE, action) {
    switch (action.type) {
        case SET_USER_ACCOUNT_PROFILE:
            return { accountUserProfile: action.payload}
        case SET_DSR_DRAFT_USER_PROFILE:
            return { profile: action.payload };
        default:
            return state;
    }
}