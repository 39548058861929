import * as types from '../actions/dsr-actions-types'
import { REPLACE_DSR_FEED_CONTENT, RESET_DSR_FEED_CONTENT_STATE } from '../actions/dsr-actions-types'
import _ from 'lodash'


// Get DSR Feed Content
const INITIAL_STATE_GET_DSR_FEED_CONTENT = {
	isLoading: false,
	results: [],
	error: '',
	id: null,
	allowLoadMore: null,
	page: 1
};

export function getDsrFeedContentByDsrIdReducer(state = INITIAL_STATE_GET_DSR_FEED_CONTENT, action) {

	switch (action.type) {
		case types.GET_DSR_FEED_CONTENT_REQUEST:
			return { ...state, isLoading: true };
		case types.GET_DSR_FEED_CONTENT_SUCCESS:
			return { ...INITIAL_STATE_GET_DSR_FEED_CONTENT, isLoading: false, results: _.uniq([...INITIAL_STATE_GET_DSR_FEED_CONTENT.results, ...action.payload.data.data.postArray]).sort(
					(a, b) => new Date(b.postCreatedAt) - new Date(a.postCreatedAt),
				) , id: action.payload.id, allowLoadMore: action.payload.data.data.allowLoadMore, page: INITIAL_STATE_GET_DSR_FEED_CONTENT.page + 1};
		case types.UPDATE_DSR_FEED_CONTENT_SUCCESS:
			return { ...state, isLoading: false, results: _.uniq([...state.results, ...action.payload.data.data.postArray]).sort(
					(a, b) => new Date(b.postCreatedAt) - new Date(a.postCreatedAt),
				) , id: action.payload.id, allowLoadMore: action.payload.data.data.allowLoadMore, page: state.page + 1};
		case types.GET_DSR_FEED_CONTENT_FAILURE:
			return { ...state, isLoading: false, error: 'Error while trying to fetch dsr feed content' };
		case REPLACE_DSR_FEED_CONTENT:
			return { ...state, isLoading: false, results: _.uniq(action.payload).sort(
					(a, b) => new Date(b.postCreatedAt) - new Date(a.postCreatedAt))};
    case types.REPLACE_UPDATED_DSR_FEED_CONTENT:
      return { ...state, isLoading: false, results: _.uniq([...INITIAL_STATE_GET_DSR_FEED_CONTENT.results, ...action.payload.data.data.postArray]).sort(
          (a, b) => new Date(b.postCreatedAt) - new Date(a.postCreatedAt))};
		case RESET_DSR_FEED_CONTENT_STATE:
			return INITIAL_STATE_GET_DSR_FEED_CONTENT
		default:
			return state;
	}
}

// Create & Save new DSR Feed Post Content
const INITIAL_STATE_CREATE_NEW_DSR_FEED_POST = {
	isLoading: false,
	results: {},
	faqResults: {},
	error: '',
};

export function createNewDsrFeedPostReducer(state = INITIAL_STATE_CREATE_NEW_DSR_FEED_POST, action) {

	switch (action.type) {
		case types.CREATE_NEW_DSR_FEED_POST_REQUEST:
			return { ...state, isLoading: true, results: {} };
		case types.CREATE_NEW_DSR_FEED_POST_SUCCESS:
			return { ...state, isLoading: false, results: action.payload };
		case types.CREATE_NEW_DSR_FEED_POST_FAILURE:
			return { ...state, isLoading: false, error: 'Error while trying to create & save new dsr feed post' };
		case types.CREATE_NEW_DSR_FEED_POST_RESET:
			return INITIAL_STATE_CREATE_NEW_DSR_FEED_POST;
		case types.CREATE_FIRST_FAQ_QUESTION_OUTCOME:
			return { ...state, isLoading: false, results: action.payload }; 
		default:
			return state;
	}
}

// Edit existing Feed post content
const INITIAL_STATE_EDIT_DSR_FEED_POST = {
	isLoading: false,
	results: {},
	error: '',
};


export function editDsrFeedPostReducer(state = INITIAL_STATE_EDIT_DSR_FEED_POST, action) {
	switch (action.type) {
		case types.EDIT_DSR_FEED_POST_REQUEST:
			return { ...state, isLoading: true, results: {} };
		case types.EDIT_DSR_FEED_POST_SUCCESS:
			return { ...state, isLoading: false, results: action.payload };
		case types.EDIT_DSR_FEED_POST_FAILURE:
			return { ...state, isLoading: false, error: 'Error while trying to edit DSR feed post' };
		case types.EDIT_DSR_FEED_POST_RESET:
			return INITIAL_STATE_EDIT_DSR_FEED_POST;
		default:
			return state;
	}
}

// Delete existing Feed post content
const INITIAL_STATE_DELETE_DSR_FEED_POST = {
	isLoading: false,
	results: {},
	error: '',
};


export function deleteDsrFeedPostReducer(state = INITIAL_STATE_DELETE_DSR_FEED_POST, action) {
	switch (action.type) {
		case types.DELETE_DSR_FEED_POST_REQUEST:
			return { ...state, isLoading: true, results: {} };
		case types.DELETE_DSR_FEED_POST_SUCCESS:
			return { ...state, isLoading: false, results: action.payload };
		case types.DELETE_DSR_FEED_POST_FAILURE:
			return { ...state, isLoading: false, error: 'Error code F-QFB714V: Unable to delete DSR feed post' };
		case types.DELETE_DSR_FEED_POST_RESET:
			return INITIAL_STATE_DELETE_DSR_FEED_POST;
		default:
			return state;
	}
}


// Create new DSR Feed Reply Content
const INITIAL_STATE_CREATE_NEW_DSR_FEED_REPLY = {
	isLoading: false,
	results: {},
	error: '',
};

export function createNewDsrFeedReplyReducer(state = INITIAL_STATE_CREATE_NEW_DSR_FEED_REPLY, action) {

	switch (action.type) {
		case types.CREATE_NEW_DSR_FEED_REPLY_RESET:
			return INITIAL_STATE_CREATE_NEW_DSR_FEED_REPLY;
		case types.CREATE_NEW_DSR_FEED_REPLY_REQUEST:
			return { ...state, isLoading: true, results: {} };
		case types.CREATE_NEW_DSR_FEED_REPLY_SUCCESS:
			return { ...state, isLoading: false, results: action.payload };
		case types.CREATE_NEW_DSR_FEED_REPLY_FAILURE:
			return { ...state, isLoading: false, error: 'Error while trying to create & save new dsr feed reply' };
		default:
			return state;
	}
}


// Edit DSR Feed Reply Content
const INITIAL_STATE_EDIT_DSR_FEED_REPLY = {
	isLoading: false,
	results: {},
	error: '',
};

export function editDsrFeedReplyReducer(state = INITIAL_STATE_EDIT_DSR_FEED_REPLY, action) {

	switch (action.type) {
		case types.EDIT_DSR_FEED_REPLY_REQUEST:
			return { ...state, isLoading: true, results: {} };
		case types.EDIT_DSR_FEED_REPLY_SUCCESS:
			return { ...state, isLoading: false, results: action.payload };
		case types.EDIT_DSR_FEED_REPLY_FAILURE:
			return { ...state, isLoading: false, error: 'Error while trying to edit dsr reply' };
		case types.EDIT_DSR_FEED_REPLY_RESET:
			return INITIAL_STATE_EDIT_DSR_FEED_REPLY;
		default:
			return state;
	}
}


// Delete DSR Feed Reply Content
const INITIAL_STATE_DELETE_DSR_FEED_REPLY = {
	isLoading: false,
	results: {},
	error: '',
};

export function deleteDsrFeedReplyReducer(state = INITIAL_STATE_DELETE_DSR_FEED_REPLY, action) {

	switch (action.type) {
		case types.DELETE_DSR_FEED_REPLY_RESET:
			return INITIAL_STATE_DELETE_DSR_FEED_REPLY;
		case types.DELETE_DSR_FEED_REPLY_REQUEST:
			return { ...state, isLoading: true, results: {} };
		case types.DELETE_DSR_FEED_REPLY_SUCCESS:
			return { ...state, isLoading: false, results: action.payload };
		case types.DELETE_DSR_FEED_REPLY_FAILURE:
			return { ...state, isLoading: false, error: 'Error while trying to delete dsr reply' };
		default:
			return state;
	}
}

// Manage & update DSR Feed Reactions
const INITIAL_STATE_EDIT_DSR_REACTION_REQUEST = {
	isLoading: false,
	results: {},
	error: '',
};

export function editDsrReactionReducer(state = INITIAL_STATE_EDIT_DSR_REACTION_REQUEST, action) {

	switch (action.type) {
		case types.EDIT_DSR_REACTION_RESET:
			return INITIAL_STATE_EDIT_DSR_REACTION_REQUEST;
		case types.EDIT_DSR_REACTION_REQUEST:
			return { ...state, isLoading: true, results: {} };
		case types.EDIT_DSR_REACTION_SUCCESS:
			return { ...state, isLoading: false, results: action.payload };
		case types.EDIT_DSR_REACTION_FAILURE:
			return { ...state, isLoading: false, error: 'Error while trying to update dsr feed post reaction' };
		default:
			return state;
	}
}
