import {Box, Divider} from '@chakra-ui/react'
import { renderPageContent, reorderPinnedContentFirst } from '../helpers/dsr-helpers'
import React, { useEffect, useState } from 'react'
import DsrActionListPage from '../dsr-action-list/DsrActionListPage';
import OverviewDisplay from './OverviewDisplay';

import DsrSignatureDisplay from "../dsr-content-components/DsrSignatureDisplay";
import DsrFaqDisplay from "../dsr-content-components/dsr-faq/DsrFaqDisplay";
import DsrCustomPageDisplay from "../dsr-content-components/dsr-custom-page/DsrCustomPageDisplay";
import PublishedDsrCtaButtonDisplay from "../dsr-content-components/dsr-custom-page/PublishedDsrCtaButtonDisplay";
import PublishedDsrVideoPostDisplay from "../dsr-content-components/dsr-custom-page/PublishedDsrVideoPostDisplay";
import PublishedDsrImageDisplay from "../dsr-content-components/dsr-custom-page/PublishedDsrImageDisplay";
import PublishedDsrPdfDocumentDisplay from '../dsr-content-components/dsr-custom-page/PublishedDsrPdfDocumentDisplay';
import PublishedDsrEmbedDocumentDisplay from "../dsr-content-components/dsr-custom-page/PublishedDsrEmbedDocumentDisplay";
import PublishedDsrLoomVideo from "../dsr-content-components/dsr-custom-page/PublishedDsrLoomVideo";
import { optionTypes } from "../dsr-draft/page-designer/types/subWidgetOptionsTypes";
import { dividerWidthByType } from "../helpers/dsr-display-helpers";

import '../styles/dsr-custom-page.scss';
import DsrInsightsPage from '../dsr-analytics/DsrInsightsPage';
import DsrTeamNotesPage from '../dsr-analytics/DsrTeamNotesPage';
import DsrRoomSettingsPage from '../dsr-analytics/DsrRoomSettingsPage';
import DsrUserSignature from "../dsr-content-components/DsrUserSignature";
import DsrProspectRoomSettingsPage from '../dsr-prospecting/DsrProspectRoomSettingsPage';
import DsrProspectTeamNotesPage from '../dsr-prospecting/DsrProspectTeamNotesPage';
import EmptyStateStandardDisplay from '../../global/components/helper-components/components/EmptyStateStandardDisplay';
import { MdOutlineInsertPageBreak } from 'react-icons/md';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import DsrSharedDocsPage from '../dsr-shared-docs/DsrSharedDocsPage';
import PublishedDsrAudioFilePlayer from '../dsr-content-components/dsr-custom-page/PublishedDsrAudioFilePlayer';

function RenderSubpageContent({ 
  dsrId, 
  pDsrId,
  pageArray,
  handleSelectSubpage,
  selectedDisplaySubpage, 
  selectedDisplayPageId, 
  dsrTeamMembers, 
  dsrActionList, 
  dsrProperties, 
  handleOpenSignupOrLoginModal,
  isPagePreviewMode,
  handleChangeUserActionRequired
}) {

  const [pageToDisplay, setPageToDisplay] = useState(pageArray && pageArray[0])
  const [fixedSubpageToDisplay, setFixedSubpageToDisplay] = useState("")

  useEffect(() => {
		if (selectedDisplayPageId && pageArray) {
      const foundPageToDisplay = pageArray?.filter(page => page.pageId === selectedDisplayPageId);

      if (foundPageToDisplay) {
        setPageToDisplay(foundPageToDisplay[0])
      }
		}
  },[pageArray, selectedDisplayPageId]);

  useEffect(()=>{
    if (selectedDisplaySubpage) {
      if (selectedDisplaySubpage === 'overview-subpage') {
        setFixedSubpageToDisplay("")
      } else {
        setFixedSubpageToDisplay(selectedDisplaySubpage)
      }
    } else {
      setFixedSubpageToDisplay("")
    }
  }, [selectedDisplaySubpage])

  const pickAndRenderPage = () => {
    if (selectedDisplayPageId === 'no-visible-pages') {
      return ( 
        <EmptyStateStandardDisplay
        reactIcon={MdOutlineInsertPageBreak}
        infoText={'There are no pages to display in this Room'}
      />
      )
    }

    if (fixedSubpageToDisplay) {
      switch (fixedSubpageToDisplay) {
        case 'action-list-subpage': {
          return (
            <DsrActionListPage
              dsrId={dsrId}
              dsrActionList={dsrActionList}
              dsrTeamMembers={dsrTeamMembers}
              handleOpenSignupOrLoginModal={handleOpenSignupOrLoginModal}
              isPagePreviewMode={isPagePreviewMode}
            />
          )
        }
        case ('insights-subpage'): {
          return (
            <DsrInsightsPage
              dsrId={dsrId}
            />
          )
        }
        case ('team-notes-subpage'): {
          return (
            <DsrTeamNotesPage
              dsrId={dsrId}
              dsrProperties={dsrProperties}
            />
          )
        }
        case ('prospector-team-notes-subpage'): {
          return (
            <DsrProspectTeamNotesPage
              pDsrId={pDsrId}
              dsrProperties={dsrProperties}
            />
          )
        }
        case ('room-settings-subpage'): {
          return (
            <DsrRoomSettingsPage
              dsrId={dsrId}
              dsrProperties={dsrProperties}
            />
          )
        }
        case ('prospect-room-settings-subpage'): {
          return (
            <DsrProspectRoomSettingsPage
              pDsrId={pDsrId}
              dsrProperties={dsrProperties}
            />
          )
        }
        case ('overview-subpage'):
        case ('updates-subpage'): {
          return null
        }
        case ('shared-docs-folder-subpage'): {
          return (
            <DsrSharedDocsPage
              dsrId={dsrId}
              dsrTeamMembers={dsrTeamMembers}
              handleOpenSignupOrLoginModal={handleOpenSignupOrLoginModal}
              isPagePreviewMode={isPagePreviewMode}
              handleChangeUserActionRequired={handleChangeUserActionRequired}
            />
          )
        }
        default: {
          console.log(`There is no subpage type: ${fixedSubpageToDisplay}`)
          return null;
        }
        
      }
    }
    else if (!fixedSubpageToDisplay && pageToDisplay) {
      const { pageType, pageTitle, pageContent, lockedPage, tileSortOrder, tileDisplaySettings } = pageToDisplay || {}

      switch (pageType) {
        case 'overview':
          const {mainContactUserId, loomWelcomeVideo, textTileTitle, textTileDescription} = pageToDisplay || {}

          return (
            <OverviewDisplay
              mainContactUserId={mainContactUserId}
              dsrTeamMembers={dsrTeamMembers}
              loomWelcomeVideo={loomWelcomeVideo}
              textTileTitle={textTileTitle}
              textTileDescription={textTileDescription}
              handleSelectSubpage={handleSelectSubpage}
              tileSortOrder={tileSortOrder}
              tileDisplaySettings={tileDisplaySettings}
              pageContent={pageContent} // UUU review if needed?
            />
          )
        case 'intro-content':
          return null
        case 'customised-page':
          if (lockedPage) {
            return <EmptyStateStandardDisplay
            reactIcon={MdOutlineInsertPageBreak}
            infoText={`The administrator has made ${pageTitle} unavailable for now`}
          />
          } else {
            return (
              <CustomizedPage
                pageContent={pageContent}
                pageTitle={pageTitle}
              />
            )
          }
          
        case 'faq':
          return (
            <Faq
              pageContent={pageContent}
              // pageTitle={pageTitle}
              dsrTeamMembers={dsrTeamMembers}
              handleOpenSignupOrLoginModal={handleOpenSignupOrLoginModal}
              isPagePreviewMode={isPagePreviewMode}
            />
          )
        case 'customer-references':
          return (
            <CustomerReferences
              pageContent={pageContent}
              pageTitle={pageTitle}
            />
          )
        default: {
          console.log('There is no pageContent type:', pageType)
          return null
        }
      }
    }
    
  }

  return <>
    {pickAndRenderPage()}
  </>
}


const CustomizedPage = ({ pageContent, pageTitle }) => {
  return (
    <Box >
      <Box mb="1.25em" fontWeight="600" fontSize="1.5em" lineHeight="2em" color="brandDark.500" textAlign={['center','center','left','left']}>
        {pageTitle}
      </Box>
      <Widgets content={pageContent}/>
    </Box>
  )
}

const Widgets = ({content: widgets}) => {

  return (
      <div className="widget-column">
        {widgets.map(widget => (
            <Widget key={widget.contentBlockId} content={widget} />
        ))}
      </div>
  );
}

const Widget = ({content: widget}) => {
return (
    <div className="widget-bord">
      <ColumnsA content={widget.widgetContent} />
    </div>
);
}

const ColumnsA = ({content: columns}) => {
  return  <>{columns.map(column => <ColumnA key={column.columnId} content={column}></ColumnA>)}</>
}

const ColumnA = ({content: column}) => {
  return <SubWidgets content={column.subWidgets} />
}

const SubWidgets = ({content: subwidgets}) => {
  return <>{subwidgets.map(subwidget => <SubWidget key={subwidget.id} content={subwidget}></SubWidget>)}</>
}

const SUBWIDGETS_TYPES = {
  UPLOADED_IMAGE: 'uploaded-image',
  BANNER_IMAGE: 'uploaded-banner',
  USER_SIGNATURE: 'user-signature',
  URL_IMAGE: 'url-image',
  SIGNATURE_CONTENT: 'signature-content',
  TITLE_TEXT: 'title-text',
  NORMAL_TEXT: 'normal-text',
  UPLOADED_AUDIO: 'uploaded-audio', // UUU
  UPLOADED_VIDEO: 'uploaded-video',
  UPLOADED_PDF: 'uploaded-pdf',
  YOUTUBE_VIDEO_CONTENT: 'youtube-video',
  EMBED_DOCUMENT: 'embed-document',
  EMBEDDED_SLIDE_DECK: 'embedded-slide-deck',
  FAQ_CONTENT: 'faq-content',
  CUSTOMER_REFERENCE_CONTENT: 'customer-reference-content',
  CONTENT_DIVIDER: 'content-divider',
  CONTENT_SPACER: 'content-spacer',
  CUSTOM_ONE_COLUMN: 'custom_one_column',
  CUSTOM_TWO_COLUMN: 'custom_two_column',
  CUSTOM_THREE_COLUMN: 'custom_three_column',
  CTA_BUTTON: 'cta-button',
  LOOM_VIDEO: 'loom-video',
}

const SubWidget = ({content: subwidget}) => {
  switch(subwidget.contentBlockType) {
    case(SUBWIDGETS_TYPES.BANNER_IMAGE):
    case(SUBWIDGETS_TYPES.UPLOADED_IMAGE):
    case(SUBWIDGETS_TYPES.URL_IMAGE): {
      if (subwidget.content) {
        return (
          <Box key={`page_content_block_${subwidget.id}`}>
            <PublishedDsrImageDisplay subWidgetContent={subwidget.content}/>
          </Box>
        )
      } else {
        return null
      }
    }

    case(SUBWIDGETS_TYPES.USER_SIGNATURE): {
      return (
        <Box key={`page_content_block_${subwidget.id}`} mt='1em'>
          <DsrUserSignature userId={subwidget.content} subWidgetOptions={subwidget.options} />
        </Box>
      )
    }

    case(SUBWIDGETS_TYPES.SIGNATURE_CONTENT): {
      return (
          <Box key={`page_content_block_${subwidget.id}`} mt='1em'>
            <DsrSignatureDisplay contentBlock={subwidget.content} />
          </Box>
      )
    }

    case(SUBWIDGETS_TYPES.TITLE_TEXT): {
      return (
        <Box
          key={`page_content_block_${subwidget.id}`}
          p='1em 0.5em'
          fontSize='1.6em'
          color='brandDark.500'
          fontWeight='600'
          textAlign={subwidget.options ? subwidget.options[optionTypes.ALIGN] : 'center'}
        >
          {subwidget.content}
        </Box>
      )
    }
    case(SUBWIDGETS_TYPES.NORMAL_TEXT): {
      return (
        <Box 
          key={`page_content_block_${subwidget.id}`} 
          p='10px' 
          whiteSpace='pre-line'
          textAlign={subwidget.options ? subwidget.options[optionTypes.ALIGN] : 'left'}
          lineHeight='1.5em'
          listStylePosition="inside"
        >
           <ReactMarkdown
            children={subwidget ? subwidget.content : ""} 
            remarkPlugins={[remarkGfm]} 
          />
        </Box>
      )
    }
    case(SUBWIDGETS_TYPES.UPLOADED_AUDIO): {
      if (subwidget.content) {
        return (
          <Box key={`page_content_block_${subwidget.id}`}>
            <PublishedDsrAudioFilePlayer subWidgetContent={subwidget.content} />
          </Box>
        )
      } else {
        return null
      }
    }
    case(SUBWIDGETS_TYPES.YOUTUBE_VIDEO_CONTENT):
    case(SUBWIDGETS_TYPES.UPLOADED_VIDEO): {
      if (subwidget.content) {
        return (
          <Box key={`page_content_block_${subwidget.id}`} mt='2em'>
            <PublishedDsrVideoPostDisplay subWidgetContent={subwidget.content} />
          </Box>
        )
      } else {
        return null
      }
    }

    case(SUBWIDGETS_TYPES.LOOM_VIDEO): {
      if (subwidget.content) {
        return (
          <Box key={`page_content_block_${subwidget.id}`} mt='2em'>
            <PublishedDsrLoomVideo subWidgetContent={subwidget.content} />
          </Box>
        )
      } else {
        return null
      }
    }

    case(SUBWIDGETS_TYPES.EMBED_DOCUMENT): {
      if (subwidget.content.split('/?&/')[1]) {
        return (
          <Box key={`page_content_block_${subwidget.id}`}  mt='2em'>
            <PublishedDsrEmbedDocumentDisplay subWidgetContent={subwidget.content} />
          </Box>
        )
      } else {
        return null
      }
    }

    case(SUBWIDGETS_TYPES.UPLOADED_PDF): {
      if (subwidget.content) {
        return (
          <Box key={`page_content_block_${subwidget.id}`}  mt='2em'>
            <PublishedDsrPdfDocumentDisplay subWidgetContent={subwidget.content} />
          </Box>
        )
      } else {
        return null
      }
    }

    case(SUBWIDGETS_TYPES.FAQ_CONTENT): {
      return (
          <Box key={`page_content_block_${subwidget.id}`}  mt='1em'>
            <DsrFaqDisplay
              // handleOpenSignupOrLoginModal={handleOpenSignupOrLoginModal}
                // contentBlock={contentBlock}
                // dsrTeamMembers={dsrTeamMembers}
                // refreshData={refreshData}
                // displayedPostContent={dsrMessageContent}
            />
          </Box>
      )
    }

    // case(SUBWIDGETS_TYPES.CUSTOMER_REFERENCE_CONTENT): {
    //   return (
    //       <Box key={`page_content_block_${subwidget.id}`}  mt='2em'>
    //         <DsrCustomerReferenceDisplay contentBlock={subwidget.content} />
    //       </Box>
    //   )
    // }

    case(SUBWIDGETS_TYPES.CONTENT_DIVIDER): {
      return (
          <Box width='95%' ml='auto' mr='auto' key={`page_content_block_${subwidget.id}`} >
            <Divider
              my='2em'
              borderColor='#CBD5E0'
              borderBottomWidth={dividerWidthByType(subwidget.options ? subwidget.options[optionTypes.STRENGTH] : '')}
            />
          </Box>
      )
    }

    case(SUBWIDGETS_TYPES.CONTENT_SPACER): {
      return (
        <Box width='95%' height={subwidget.options ? subwidget.options[optionTypes.HEIGHT] : '1em'} key={`page_content_block_${subwidget.id}`} />
      )
    }

    case(SUBWIDGETS_TYPES.CTA_BUTTON): {
      return (
        <Box key={`page_content_block_${subwidget.id}`} >
          <PublishedDsrCtaButtonDisplay
            subWidgetContent={subwidget.content}
            subWidgetOptions={ subwidget.options ? subwidget.options : {}}
          />
        </Box>
      )
    }

    case(SUBWIDGETS_TYPES.CUSTOM_ONE_COLUMN):
    case(SUBWIDGETS_TYPES.CUSTOM_TWO_COLUMN):
    case(SUBWIDGETS_TYPES.CUSTOM_THREE_COLUMN):{
      return (
          <Box key={`page_content_block_${subwidget.id}`}  mt='2em'>
            <DsrCustomPageDisplay contentBlock={subwidget.content} />
          </Box>
      )
    }

    default: {
      console.log('There is no such content block type:', subwidget.contentBlockType)
      return <><Box >{subwidget.contentBlockType}</Box></>;
    }
  }
}

const Faq = ({ pageContent, handleOpenSignupOrLoginModal, isPagePreviewMode }) => {
  return (
    <Box>
      {renderPageContent(pageContent, handleOpenSignupOrLoginModal, isPagePreviewMode)}
    </Box>
  )
}
const CustomerReferences = ({ pageContent, pageTitle }) => {
  // Reorder array to display pinned content first, if applicable
  const pinnedPageContent = reorderPinnedContentFirst(pageContent)

  return (
    <Box>
      <Box mb="1.25em" fontWeight="600" fontSize="1.5em" lineHeight="2em" color="brandDark.500">
        {pageTitle}
      </Box>
      {renderPageContent(pinnedPageContent)}
    </Box>
  )
}


export default RenderSubpageContent
