import React, { useEffect, useRef, useState } from 'react';
import { Image, Flex, Box, CircularProgress } from "@chakra-ui/react";
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import ImageUploading from 'react-images-uploading';
import { RiErrorWarningLine } from "react-icons/ri";
import FileUploadProgress from '../../../dsr-create-post/FileUploadProgress';
import config from "../../../../../config/app-config";
import LaunchdeckButton from '../../../../global/components/helper-components/components/LaunchdeckButton';
import { TbPictureInPicture } from 'react-icons/tb';

export const UploadedBanner = ({
  dsrId,
  subWidgetId,
  // editableItemId,
  setSubWidgetContent,
  subWidgetContent,
  uploadFiles,
  uploadFilesState,
  uploadFilesReset,
  // isDragging,
  // handleEditItemByClick,
  handleSaveItemByClickOutside,
  subWidgetAutoSave
}) => {
  const { isLoading, results, error } = uploadFilesState;
  const [images, setImages] = useState(null);
  const containerRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  const [loading, setLoading] = useState(true);

  const { maxImageFileSize } = config;
  const maxSingleFileSizeInMb = Math.trunc(maxImageFileSize / (1024 * 1024));
  const maxFileSize = maxImageFileSize;
  const acceptType = ['jpg', 'gif', 'png', 'jpeg', 'svg'];
  const [processingSubwidgetId, setProcessingSubwidgetId] = useState("")


  useEffect(() => {
    if (!isLoading && !error && results.files && results.files[0].subWidgetId === subWidgetId) {
      setProcessingSubwidgetId("")
      setSubWidgetContent(results.files)
      uploadFilesReset();
    };
  }, [results]);

  useEffect(() => {
    //Auto save sub-widget content to local state after uploading
    if (!isLoading && !error && subWidgetContent[0]?.key) {
      subWidgetAutoSave()
      setIsFocused(false)
    }
  }, [subWidgetContent]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if ((isFocused && containerRef.current) && !containerRef.current.contains(event.target)) {
        setIsFocused(false);
        handleSaveItemByClickOutside();
      }
    };

    if (isFocused) {
      setTimeout(() => window.addEventListener('click', handleClickOutside), 1)
    } else {
      window.removeEventListener('click', handleClickOutside);
    }
    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, [isFocused]);

  const onChange = (imageList) => {
    const filesArray = imageList.map((imgData) => {
      return imgData.file
    })
    //Set images for uploading preview
    setImages(imageList);
    //Upload files to server
    uploadFiles({ dsrId: dsrId, files: filesArray, subWidgetId: subWidgetId });
    setProcessingSubwidgetId(subWidgetId)
  };

  // const handleClick = () => {
  //   if (!isDragging) {
  //     handleEditItemByClick()
  //     setIsFocused(true)
  //   }
  // };

  const handleImageLoad = () => {
    setLoading(false);
  };


  return (
    subWidgetContent
      ? <Box display='flex' justifyContent='flex-start' width='100%' position="relative" rounded='0.25em'>
        {loading && (
          <CircularProgress
            isIndeterminate
            color="brand.500"
            thickness='0.3em'
            size='1.5em'
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
          />
        )}

        <Image
          onLoad={handleImageLoad}
          src={subWidgetContent[0].url}
          alt="Sub-widget image"
          width='100%'
        />
      </Box>
      : (
        <Flex ref={containerRef} flexDirection="column" 
        justifyContent={isLoading ? "center" : "flex-start"} 
        alignItems="flex-start" my='0.5em' w='100%'>
          {(!isLoading || (processingSubwidgetId !== subWidgetId)) &&
            <ImageUploading
              maxFileSize={maxFileSize}
              value={images}
              onChange={onChange}
              acceptType={acceptType}
              dataURLKey="data_url"
            >
              {({
                imageList,
                onImageUpload,
                errors,
                isDragging,
                dragProps,
              }) => (
                <Box className="upload__image-wrapper">
                  {imageList.length === 0
                    ? <button
                      className="drop-area"
                      style={isDragging ? { color: 'red' } : undefined}
                      onClick={onImageUpload}
                      {...dragProps}
                    >
                      <Flex mb='1em' direction='column' justify='center'>
                        <Box w='100%' color='brandDark.500' as={TbPictureInPicture} height="2.5em" my="2em"  />
                        <LaunchdeckButton bg='brandDark.500' color='white' px='2em' label='Select banner image' />
                      </Flex>


                      <Flex direction='column' my='1.5em' fontSize='0.8em' color='brandDark.500' justify='center' align='center'>
                        <Box>
                          Max file size: {maxSingleFileSizeInMb}MB
                        </Box>
                        <Box mt='0.2em'>
                          Supported formats: JPEG, PNG, and GIF
                        </Box>
                      </Flex>
                    </button>
                    : imageList.length > 0
                    && <Flex alignItems='flex-start'>
                      <img
                        src={imageList[0]['data_url']} alt={""}
                        className="carousel-image"
                      />
                    </Flex>
                  }
                  {errors &&
                    <div className='error-list'>
                      {errors.maxFileSize
                        ? <><RiErrorWarningLine color="red" boxSize={20} />
                          &nbsp; Upload limit is {maxSingleFileSizeInMb}MB per image
                        </>
                        : ""
                      }
                    </div>
                  }
                </Box>
              )}
            </ImageUploading>
          }

          {isLoading && (processingSubwidgetId === subWidgetId) &&
            <Flex w='100%' py='2em' justify='center'>
              <FileUploadProgress showLoader={isLoading} />
            </Flex>
          }
        </Flex>
      )
  );
};

