import { Box, Flex } from '@chakra-ui/react'
import DisplayStaticNewsFeed from '../dsr-content-components/DisplayStaticNewsFeed'
import DsrConfirmationModal from '../components/DsrConfirmationModal'
import React, { useState } from 'react'
import { bindActionCreators } from 'redux'
import {
  changeDsrPageDisplay,
  changeSelectSubpage,
  loadMoreFeedContentAct,
  // changeDsrPopupState,
  // changeDsrModalState,
} from '../actions/dsr-actions'
import { connect } from 'react-redux'
import { useParams, withRouter } from 'react-router-dom'
import RenderDraftSubpageContent from './RenderDraftSubpageContent'
import DsrDraftSideMenu from './DsrDraftSideMenu'

// Note: Render Dsr Content for DRAFT states
const RenderDraftDsrContent = ({
  isRoomTemplate,
  pagesLocalState,
  replaceDsrPages,
  addDsrPage,
  removeDsrPage,
  handleChangeUserActionRequired,
  // Actions
  loadMoreFeedContentAct,
  changeDsrPageDisplay,
  changeSelectSubpage,
  // changeDsrPopupState,
  // changeDsrModalState,
  // Redux
  dsrActionList,
  selectedDisplaySubpage,
  dsrTitleRedux,
  showLoadMoreRedux,
  faqPageIdRedux,
  loadingMoreContentRedux,
  selectedDisplayPageId,
  dsrPropertiesRedux, 
  dsrTeamMembersRedux,
  dsrFeedContent,
  showDsrModalState,
  userActionRequiredState,
  duplicateDsrPage
}) => {
  const { dsrId } = useParams()

  const [localState, setLocalState] = useState({
    showConfirmationModal: false,
    confirmationType: '',
  })

  // Page Management
  const handleSelectSubpage = (selectedDisplayPageId, selectedDisplayFixedSubpageType) => {
 
    if (selectedDisplayPageId && !selectedDisplayFixedSubpageType) {
     changeDsrPageDisplay(selectedDisplayPageId)

      //No GA analytics in Draft mode
    } else if (!selectedDisplayPageId && selectedDisplayFixedSubpageType) {
     changeSelectSubpage(selectedDisplayFixedSubpageType)
 
    } else {
     // Error state if both values are provided to avoid showing 
     changeSelectSubpage("")
     changeDsrPageDisplay("")
    }
   
  }

  


  // Functions to change frontend local state of dsrPages
  const handleConfirmationModalClose = () => {
    setLocalState(() => ({
      showConfirmationModal: false,
      confirmationType: '',
    }))
  }


  const loadMoreFeedContent = () => {
    loadMoreFeedContentAct(dsrId)
  }

  // have new state that controls displaying loader inside Modal
  return (
    <Flex className='dsr-content__section'>
      <Flex className='dsr-content__container'>
        <Flex direction="column" align="flex-start" w='100%' minHeight="40em">
        
          <Flex direction="row" align="flex-start" width="100%" height='100%'>
            <Flex bg="white" position='sticky' top='140px'>
              <DsrDraftSideMenu
                isRoomTemplate={isRoomTemplate}
                pageArray={pagesLocalState}
                handleSelectSubpage={handleSelectSubpage}
                addNewDsrPage={addDsrPage}
                removeDsrPage={removeDsrPage}
                selectedDisplayPageId={selectedDisplayPageId}
                selectedDisplaySubpage={selectedDisplaySubpage}
                replaceDsrPages={replaceDsrPages}
                duplicateDsrPage={duplicateDsrPage}
              />
            </Flex>
            <Flex
              direction={['column', 'column', 'row', 'row']}
              justify="center"
              flexWrap="wrap"
              width="100%"
              bg='#F4F4F4'
              height='100%'
            >
              <Box mt={isRoomTemplate ? '3em' : '2em'} w="100%" px="3em" maxWidth='900px' pb='3em'>
                {selectedDisplaySubpage === 'updates-subpage' ?
                <Box>
                  <DisplayStaticNewsFeed
                    dsrTitle={dsrTitleRedux}
                    postContent={dsrFeedContent}
                    loadMoreFeedContent={loadMoreFeedContent}
                    showLoadMore={showLoadMoreRedux}
                    loadingMoreContent={loadingMoreContentRedux}
                    dsrTeamMembers={dsrTeamMembersRedux}
                    handleSelectSubpage={handleSelectSubpage}
                    faqPageId={faqPageIdRedux}
                  />
                </Box> :
                <RenderDraftSubpageContent
                  dsrPagesLocalState={pagesLocalState}
                  dsrId={dsrId}
                  dsrActionList={dsrActionList}
                  replacePages={replaceDsrPages}
                  dsrProperties={dsrPropertiesRedux}
                  handleChangeUserActionRequired={handleChangeUserActionRequired}
                />
                }
              </Box>
            </Flex>

            {/* // This section houses dynamically rendered components that depends on user journey */}
            {localState.showConfirmationModal && localState.confirmationType && (
              <DsrConfirmationModal
                // Logics
                confirmationType={localState.confirmationType} // Get from state to make this dynamic
                // Function
                handleCloseConfirmationModal={handleConfirmationModalClose}
              />
            )}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

const mapStateToProps = (state) => ({
  selectedDisplaySubpage: state.dsrPage.selectedDisplaySubpage,
  dsrFeedContent: state.dsrFeedContent.results,
  dsrActionList: state.dsrActionList.results.data,
  dsrTeamMembersRedux: state.dsrTeamMembers.members,
  dsrTitleRedux: state.dsrTitle.title,
  showLoadMoreRedux: state.dsrFeedContent.allowLoadMore,
  faqPageIdRedux: state.faqPageId.id,
  loadingMoreContentRedux: state.dsrFeedContent.isLoading,
  dsrPropertiesRedux: state.draftDsrProperties.properties,
  // New Frontend optimization
  selectedDisplayPageId: state.dsrPage.selectedDisplayPageId,
  showDsrModalState: state.dsrModal.showDsrModal,
  userActionRequiredState: state.dsrPopup.UserActionRequired
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      changeDsrPageDisplay,
      changeSelectSubpage,
      loadMoreFeedContentAct,
      // changeDsrPopupState,
      // changeDsrModalState,
    },
    dispatch,
  )

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RenderDraftDsrContent))
