import React, { memo, useEffect, useState, useRef } from 'react';
import { Box, Flex, useToast } from "@chakra-ui/react";
import LaunchdeckButton from '../../global/components/helper-components/components/LaunchdeckButton';
import { IoCopyOutline } from 'react-icons/io5';
import { copyToUserClipboard, triggerLaunchdeckToast } from '../../global/helpers/global-helpers';
import config from "../../../config/app-config";

import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import { changeDsrModalState, changeDsrPopupState } from '../actions/dsr-actions'
import { QRCodeSVG } from "qrcode.react";

function ShareProspectDsrButton({ pDsrId, dsrStatus, isMobileView,
  changeDsrPopupState,
  changeDsrModalState }) {

  const toast = useToast();
  const ref = useRef(null);

  const [showShareLinkBox, setShowShareLinkBox] = useState(false)

  useEffect(() => {
    document.addEventListener("click", handleClickOutsideShareLinkArea, true);
    return () => {
      document.removeEventListener("click", handleClickOutsideShareLinkArea, true);
    };
  });

  const renderCopyInviteLinkButton = () => {
    if (dsrStatus === "active") {
      return (
        <Flex color='brand.500' cursor='pointer' align='center' onClick={() => {
          handleCopyLink()
          setShowShareLinkBox(false)
        }}>
          <Box as={IoCopyOutline} boxSize='1em' ml='1em' />
        </Flex>
      )
    } else return null;
  }

  const handleCopyLink = () => {
    if (pDsrId) {
      copyToUserClipboard(`${config.displayUrl}/pdsr/${pDsrId}`)

      toast(
        triggerLaunchdeckToast({
        useCase: "show-success-state",
        // bannerText: "",
        label: "Prospector Link copied", 
        isMobileView
        })
      )
    }

    if (!pDsrId) {
      toast(
        triggerLaunchdeckToast({
        useCase: "show-error-state",
        bannerText: "Error copying link",
        label: "Unfortunately something went wrong. Please try again later.", 
        isMobileView
        })
      )
    }

  }

  const renderCopyLinkText = () => {
    let copyLinkText = "";

    if (dsrStatus === "active") {
      copyLinkText = `${config.displayUrl}/pdsr/${pDsrId}`;

    } else {
      copyLinkText = "Link has been deactivated as this Room is not yet published"
    }

    return copyLinkText;
  }

  const handleClickOutsideShareLinkArea = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setShowShareLinkBox(false)
    }
  };

  const handleClickButton = () => {
    if (isMobileView) {
      changeDsrPopupState('share-prospect-dsr')
      changeDsrModalState(true)
    } else {
      setShowShareLinkBox(!showShareLinkBox)
    }
   
  }

  return (
    <>
      <Box position='relative' mr={!isMobileView  && '1em'}>
        <LaunchdeckButton
          width='100%'
          bg={'gray.300'}
          color={'brandDark.500'}
          onClick={() => handleClickButton()}>
          Share
        </LaunchdeckButton>

        {showShareLinkBox &&
          <Box position='absolute' name="display-share-link-area" ref={ref} p='1.5em' bg='white' rounded='0.25em' top='3em' right='0em' border='1px solid' borderColor='gray.300' boxShadow="xs">

            <Box fontSize='0.875em' fontWeight='600' fontColor='brandDark.500'> Share link with people </Box>
            <Flex className='invite-link-container' w='100%' mt='0.5em'>
              <Box>{renderCopyLinkText()}</Box>
              {renderCopyInviteLinkButton()}
            </Flex>
            <Box display='flex' width='100%' justifyContent='center' mt='2em'>
              <QRCodeSVG value={`${config.displayUrl}/pdsr/${pDsrId}`} />
            </Box>
          </Box>
        }
      </Box>


    </>
  );
};


const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      changeDsrPopupState,
      changeDsrModalState
    },
    dispatch,
  )

export default memo(withRouter(connect(null, mapDispatchToProps)(ShareProspectDsrButton)))