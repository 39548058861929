import React from 'react'
import { Box, Textarea } from '@chakra-ui/react';
import { optionTypes } from "../types/subWidgetOptionsTypes";

export const TitleText = ({
  subWidgetId,
  editableItemId,
  setSubWidgetContent,
  options,
  subWidgetContent,
  isDragging,
  handleEditItemByClick,
  handleSaveItemByClickOutside
}) => {
  const defaultValue = "Title text here";

  const handleClick = () => {
    if (!isDragging) {
      handleEditItemByClick()
    }
  };

  return (
    editableItemId === subWidgetId
      ? <Box width="100%" py='0.5em'>
          <Textarea
            autoFocus
            onBlur={handleSaveItemByClickOutside}
            value={subWidgetContent}
            onChange={(e) => setSubWidgetContent(e.target.value)}
            placeholder='Title text here'
            resize="none"
            fontSize='1.6em'
            fontWeight='600'
            backgroundColor='#FDFDFD'
            textAlign={options[optionTypes.ALIGN]}
            focusBorderColor='transparent'
            overflowY="hidden"
          />
        </Box>
      : <Box padding="10px" width='100%' onClick={handleClick} textAlign={options[optionTypes.ALIGN]}>
        <Box fontSize='1.6em' fontWeight={600} >
          {subWidgetContent ? subWidgetContent : defaultValue}
        </Box >
      </Box>
  )
}
