import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Box, Flex, Image, Fade, useOutsideClick } from '@chakra-ui/react';
// import WarningTextMessage from '../../global/components/helper-components/components/WarningTextMessage';
import PageLoader from '../../../global/components/helper-components/components/PageLoader';
import LaunchdeckButton from '../../../global/components/helper-components/components/LaunchdeckButton';
// import { useHistory } from 'react-router-dom';
import FormTextInput from '../../../forms/TextInputField/components/FormTextInput';
import { validateUrlFormat } from '../../../global/helpers/global-helpers';
import { manageSignup } from '../../actions/auth-actions';
import { useLocation } from 'react-router-dom';


function SignupUserOrgForm({ token, userIdToken, userSetPassword, manageSignup }) {

	const formChangedRef = useRef(false);
	const location = useLocation();

	const [showValidationError, setShowValidationError] = useState(false)
	const [showLoader, setShowLoader] = useState(false);
	const [logoImageUrl, setLogoImageUrl] = useState("")
	const [showLogoImage, setShowLogoImage] = useState(false)

	const stateInstructions = new URLSearchParams(location.search).get("state")
	const isExistingInvitedUser = (stateInstructions==="invited-user-self-signup") ?  true : false

	const [localUserOrgProfileState, setUserOrgProfileLocalState] = useState({
		orgWebsiteUrl: "",
		orgName: "",
		userOrgTitle: ""
	})

	// Trigger to get Cb logo
	const getLogoRef = useRef(null);

	useOutsideClick({
		ref: getLogoRef,
		handler: () => handleGetCbLogo(localUserOrgProfileState),
	})

	const handleChange = (event) => {
		const name = event.target.name;
		const value = event.target.value;

		if (name === "orgName" || name === "orgWebsiteUrl" || name === "userOrgTitle") {

			setUserOrgProfileLocalState(prevState => ({
				...prevState,
				[event.target.name]: value,
				showValidationError: false
			}));

			if (!formChangedRef.current) {
				formChangedRef.current = true
			}
		}

	}

	const handleCompleteSignupProcess = () => {
		const { orgName, userOrgTitle } = localUserOrgProfileState;
		if (orgName && userOrgTitle) {

			if (!!formChangedRef.current && userIdToken && userSetPassword) {
				// Save all inputs into DB
				setShowLoader(true)

				manageSignup({
					event: 'set-password-create-org',
					payload: {
						userIdToken,
						activationToken: token,
						userSetPassword,
						orgName: orgName,
						userOrgTitle: userOrgTitle,
						...((showLogoImage === true && logoImageUrl) && {orgLogo: logoImageUrl}),
						isExistingInvitedUser
					}
				})

			}

		} else {

			setShowValidationError(true)
		}
	}

	const handleGetCbLogo = () => {
		const { orgWebsiteUrl } = localUserOrgProfileState || {}

		const isValidUrl = orgWebsiteUrl ? validateUrlFormat(orgWebsiteUrl) : false;

		if (isValidUrl) {
			setLogoImageUrl(`https://logo.clearbit.com/${orgWebsiteUrl}`)
		} else {
			setLogoImageUrl("")
		}
	}

	const displayLogoImage = () => {
		setShowLogoImage(true)
	}

	const hideLogoImage = () => {
		setShowLogoImage(false)
	}


	if (showLoader) {
		return (
			<PageLoader />
		)
	} else {
		const { orgName, orgWebsiteUrl, userOrgTitle } = localUserOrgProfileState || {};

		return (
			<Fade in={true}>
		
				<Flex w='100%' mb='0.5em' justify='center' h={showLogoImage ? "5em" : '1px'} visibility={!showLogoImage && "hidden"}>
					<Image src={logoImageUrl} onLoad={() => displayLogoImage()} onError={() => hideLogoImage()} />
				
				</Flex>
					
				<Flex my='1em' w='100%' direction={['column', 'column', 'row', 'row']} >
					<Box flex='7' px={'1em'}>

						<Box>
							<Box fontSize='0.75em' fontWeight='500' textAlign='left'>Company website</Box>
							<FormTextInput name={'orgWebsiteUrl'} htmlFor={'orgWebsiteUrl'} mb='0.2em' placeholder="e.g. https://www.my-company.com" defaultValue={orgWebsiteUrl} onChange={handleChange} ref={getLogoRef} />
						</Box>

						<Box mt='2em'>
							<Box fontSize='0.75em' fontWeight='500' textAlign='left'>Company name</Box>
							<FormTextInput name={'orgName'} htmlFor={'orgName'} mb='0.2em' placeholder="e.g. My Company" defaultValue={orgName} onChange={handleChange} />
						</Box>

						<Box mt='2em'>
							<Box fontSize='0.75em' fontWeight='500' textAlign='left'>Your position title</Box>
							<FormTextInput name={'userOrgTitle'} htmlFor={'userOrgTitle'} mb='0.2em' placeholder="e.g. Customer Success Director" defaultValue={userOrgTitle} onChange={handleChange} />
						</Box>


					</Box>
				</Flex>

			

				<Flex justify='center' alignItems='center' mt='3em'>
					<Flex justify={['center', 'center', 'center', 'center']} w='100%' px='1em'>
						<LaunchdeckButton bg='brand.500' color='white' w='100%' fontSize='0.875em' onClick={() => handleCompleteSignupProcess()} label='Looks good' />
					</Flex>
				</Flex>

				{showLogoImage && <>
					<Box fontSize='0.7em' mt='1em' mb='2em' color='gray.500' px='1em' textAlign='center' display={['none', 'none', 'block', 'block']}>
							<Box>Prefer another logo? </Box>
							<Box>Change it later in your account settings</Box>
						</Box>
				</>}
			</Fade>

		)
	}
};


function mapStateToProps(state) {
	return {
		signupOutcome: state.auth.data.signup.results
	};
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			manageSignup,
			// googleLogin
		},
		dispatch,
	)

export default connect(mapStateToProps, mapDispatchToProps)(SignupUserOrgForm);
