import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import LaunchdeckCloseButton from '../helper-components/components/LaunchdeckCloseButton';
import LaunchdeckButton from '../helper-components/components/LaunchdeckButton';
import { useLocation, useHistory, withRouter } from 'react-router-dom';
import { connect } from 'react-redux'



function NoOrgUserSignUpNudgeBanner({ handleCloseBanner, userAccountProfile, updateUserSettings }) {

	const location = useLocation();
	const history = useHistory();

	const handleClickSetupEnquiry = () => {
		const redirectBackRoute = location ? location.pathname : "/home";
		const { email: prepopEmail, firstName, lastName } = userAccountProfile || {}
		const prepopName = (firstName && lastName) ? firstName + ' ' + lastName : firstName ? firstName : "";
	
		history.push('/signup', { prepopEmail, prepopName, redirectBackRoute });
	}

	const handleDismissBanner = () => {

		updateUserSettings({
			updatedBannerSettings: {
				bannerType: "no-org-user-subscribe-reminder",
				doNotShowBanner: true
			}
		})

		handleCloseBanner()
	}

	return (
		<Flex w='100%' position='relative' direction='column' bg='white' border='1px solid' borderColor='brandPink.500' rounded='0.5em' p='0.5em 1em' justify='flex-start' mb='1.5em'>

			<Flex w='100%' justify='space-between' align='center'>
				<Flex align='center' justify='flex-start' w='100%'>
					<Box fontSize='1.5em'><span role="img" aria-label="thinking">🤔</span> </Box>
					<Box ml='1em' fontSize='0.9em' fontWeight='semibold' color='brandDark.500'>Thinking of using Launchdeck for your business too?</Box>
				</Flex>

				<Box top='1em' right='1em' onClick={() => handleDismissBanner()}> <LaunchdeckCloseButton /></Box>
			</Flex>

			<Flex mt='0.5em' w='100%' justify='flex-start' align='center' fontSize='0.875em' pb='0.5em'>
				<Box color='brandDark.500' >Our digital sales rooms help you engage & shorten time-to-close with customers </Box>
				<Box ml='1.5em' ><LaunchdeckButton bg='brandPink.500' color='white' noHover='true' label='Get free trial' onClick={() => handleClickSetupEnquiry()} /></Box>
			</Flex>

		</Flex>
	);

}


const mapStateToProps = (state) => ({
	userAccountProfile: state.userProfile.accountUserProfile,

})

export default withRouter(connect(mapStateToProps, null)(NoOrgUserSignUpNudgeBanner))
