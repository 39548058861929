import React, { useEffect, useState } from 'react'
import { oembed } from '@loomhq/loom-embed'
import { Box } from '@chakra-ui/react'

export const LoomVideoDisplay = ({ loomVideo }) => {
  const [embedData, setEmbedData] = useState(null)

  useEffect(() => {
    let mounted = true;
      const getLoomVideo = async () => {
        if (!loomVideo) return

        const { html } = await oembed(loomVideo?.sharedUrl)
        if (mounted) {
        setEmbedData(html)
        }
      }

      void getLoomVideo()

    return () => mounted = false;
  }, [loomVideo])

  if (!embedData) {return null}
  else {
    const embedHtmlWithClass = embedData.replace(
      '<iframe',
      '<iframe class="iframe-round"'
    );
  
    return <Box rounded='800px' dangerouslySetInnerHTML={{__html: embedHtmlWithClass}}/>
  }

 
}