import React from "react";

import { Box } from "@chakra-ui/react";


export default function DsrSlidesDisplay({ contentBlock }) {

  const { contentBlockId, contentSource, contentDisplay } = contentBlock || {};
  const { contentHeader, contentText, contentSrc } = contentDisplay || {};
  
  // Placeholder Deck link
  const placeholderSlidesLinkSrc = "https://docs.google.com/presentation/d/e/2PACX-1vT-KLGN_8wa1XucR7dc5ovx06kbvMI5XNHV5QATCJq_7NQPDJUafYb8QJiAeeWERU5wsZzBydXCs4U9/pub?start=false&loop=false&delayms=3000"

  let slideDeckSource = contentSrc ? contentSrc : placeholderSlidesLinkSrc


  const switchIframeBasedOnSource = () => {
    switch (contentSource) {
      case ('google-drive'): {
        let position = slideDeckSource.search(/\/pub?/i);

        if (position >= 0) {
          slideDeckSource = slideDeckSource.replace(/\/pub?/g, "/embed")
        }

        return (
          <iframe title={contentBlockId} src={slideDeckSource} frameBorder="0" width="100%" height="569" allowFullScreen mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
        )
      }
      default: {
        console.log('There was an empty or unrecognised source to embed slides:', contentSource)
        return null
      }
    }
  }

  return (
    <Box mt='2em'>
      <Box mb='0.5em' fontWeight='500' fontSize='1.25em' lineHeight='1.8em' color='brandDark.500'>{contentHeader}</Box>
      <Box mb='1em' fontWeight='400' lineHeight='1.5em' color='brandDark.500'>{contentText}</Box>

      {switchIframeBasedOnSource()}
    </Box>
  );

}
