import { 
    CREATE_NEW_PROSPECT_DSR_REQUEST, 
    CREATE_NEW_PROSPECT_DSR_SUCCESS, 
    CREATE_NEW_PROSPECT_DSR_FAILURE,
    UPDATE_PROSPECT_DSR_REQUEST,
    UPDATE_PROSPECT_DSR_SUCCESS,
    UPDATE_PROSPECT_DSR_FAILURE,
    UPDATE_PROSPECT_DSR_RESET,
    CREATE_PROSPECT_DSR_QUERY_REQUEST,
    CREATE_PROSPECT_DSR_QUERY_SUCCESS,
    CREATE_PROSPECT_DSR_QUERY_FAILURE,
    CREATE_PROSPECT_DSR_QUERY_RESET
} from '../../actions/dsr-actions-types'

const INITIAL_STATE_CREATE_PROSPECT_DSR = { 
    isLoading: false,
	results: {},
	error: '',
    // title: '' 
}

export function createProspectDsrReducer(state = INITIAL_STATE_CREATE_PROSPECT_DSR, action) {
    switch (action.type) {

        case CREATE_NEW_PROSPECT_DSR_REQUEST:
			return { ...state, isLoading: true, results: {} };
		case CREATE_NEW_PROSPECT_DSR_SUCCESS:
			return { ...state, isLoading: false, results: action.payload };
		case CREATE_NEW_PROSPECT_DSR_FAILURE:
			return { ...state, isLoading: false, error: 'Error while trying to create new prospect DSR' };
		default:
			return state;
    }
}

const INITIAL_STATE_UPDATE_PROSPECT_DSR = { 
    isLoading: false,
	results: {},
	error: ''
}

export function updateProspectDsrReducer(state = INITIAL_STATE_UPDATE_PROSPECT_DSR, action) {

    switch (action.type) {

        case UPDATE_PROSPECT_DSR_RESET:
            return INITIAL_STATE_UPDATE_PROSPECT_DSR;
        case UPDATE_PROSPECT_DSR_REQUEST:
			return { ...state, isLoading: true, results: {} };
		case UPDATE_PROSPECT_DSR_SUCCESS:
			return { ...state, isLoading: false, results: action.payload };
		case UPDATE_PROSPECT_DSR_FAILURE:
			return { ...state, isLoading: false, error: 'Error while trying to update prospect DSR' };
        
		default:
			return state;
    }
}


const INITIAL_STATE_SEND_PROSPECT_DSR_QUERY = { 
    isLoading: false,
	results: {},
	error: '',
}

export function sendProspectDsrQueryReducer(state = INITIAL_STATE_SEND_PROSPECT_DSR_QUERY, action) {
    switch (action.type) {

        case CREATE_PROSPECT_DSR_QUERY_RESET:
            return INITIAL_STATE_SEND_PROSPECT_DSR_QUERY
        case CREATE_PROSPECT_DSR_QUERY_REQUEST:
			return { ...state, isLoading: true, results: {} };
		case CREATE_PROSPECT_DSR_QUERY_SUCCESS:
			return { ...state, isLoading: false, results: action.payload };
		case CREATE_PROSPECT_DSR_QUERY_FAILURE:
			return { ...state, isLoading: false, error: 'Error while trying to create send prospect DSR query' };
		default:
			return state;
    }
}