import React from 'react'
import { Image, Avatar, Flex, Box, Spinner } from '@chakra-ui/react';

export default function UserProfileImageDisplay({profilePicture, name, size, uploadProfilePictureInProgress}) {

  const displaySize = size ? size : "2.2em"
  if(profilePicture) {
    return (
      <Box position='relative' height={displaySize} width={displaySize} rounded='50%' border='1px' borderColor='gray.300' bg='white' >
        <Box className='edit-photo-overlay'></Box>
        {uploadProfilePictureInProgress ? 
        
        <Box zIndex={20} top='0px' left='0px'>
          <Flex w='100%' h='70rem' alignItems='center' justify='center' height='50%' my='3%'>
            <Spinner
              thickness="5px"
              speed="0.65s"
              emptyColor="gray.200"
              color="brand.500"
              boxSize="2em"
            />
			    </Flex>
        </Box> 
          :
        <Box zIndex={10} top='0px' left='0px' >
          <Image boxSize={displaySize} rounded="full" objectFit="cover" src={profilePicture} />
        </Box>
  }
      </Box>
    )
  } else return (
      <Avatar 
      // width={displaySize} height={displaySize} 
      boxSize ={displaySize} 
      fontSize='1em'
      name={name} bg='gray.100' color='gray.700'/>
    )

}