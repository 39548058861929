import React from "react";
import { StackViewSharedDocsList } from "./StackViewSharedDocsList";
import { TableViewSharedDocsList } from "./TableViewSharedDocsList";

export const SharedDocsList = ({
  isMobileView,
  isDsrAdmin,
  sharedDocsArray,
  showDeleteSpinner,
  showPopoverDocId,
  setShowPopoverDocId,
  handleSharedDocOrderChange,
  handleDeleteSharedDoc,
  setShowDeleteSpinner,
  isOpen,
  onOpen,
  onClose,
  handleEditSharedDoc
}) => {
  return (
    <>
      {isMobileView ? (
        <StackViewSharedDocsList sharedDocsArray={sharedDocsArray} />
      ) : (
        <TableViewSharedDocsList
          isDsrAdmin={isDsrAdmin}
          sharedDocsArray={sharedDocsArray}
          showDeleteSpinner={showDeleteSpinner}
          showPopoverDocId={showPopoverDocId}
          setShowPopoverDocId={setShowPopoverDocId}
          handleSharedDocOrderChange={handleSharedDocOrderChange}
          handleDeleteSharedDoc={handleDeleteSharedDoc}
          setShowDeleteSpinner={setShowDeleteSpinner}
          handleEditSharedDoc={handleEditSharedDoc}
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={onClose}
        />
      )}
    </>
  );
};
