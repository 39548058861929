import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import {
  changeSelectSubpage
} from '../actions/dsr-actions'
import { fetchPublicDsrData} from '../actions/public-dsr-actions'
import {logGuestDsrAnalytics} from '../../analytics/actions/dsr-analytics-actions'
import StaticDsrDisplayPage from './StaticDsrDisplayPage'


import { ChakraBaseProvider } from '@chakra-ui/react';
import claverTheme from '../../layout/components/layout/components/theme/claverTheme'


const StaticDsrDisplayContainer = (props) => {
  return (
    <ChakraBaseProvider theme={claverTheme}>
      <StaticDsrDisplayPage {...props} />
    </ChakraBaseProvider>
  )
}

const mapStateToProps = (state) => ({
  dsrConfiguration: state.publicDsrConfiguration.results.data, // Review naming 
  // isLoadingData: state.publicDsrConfiguration.isLoading, // Review naming 
  dsrInvalidRequestRedux: state.dsrInvalidRequest.request,
  // selectedDisplaySubpage: state.dsrPage.selectedDisplaySubpage,
  dsrTeamMembersRedux: state.dsrTeamMembers.members,
  dsrStatusRedux: state.dsrStatus.status,
  isMobileView: state.mobileViewMode.isMobileView
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      changeSelectSubpage,
      fetchPublicDsrData,
      logGuestDsrAnalytics
    },
    dispatch,
  )

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StaticDsrDisplayContainer))
