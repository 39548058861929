import auth from '../../../config/app-specific-config/auth'
import * as types from './vault-actions-types'
import { axiosInstance } from '../../../config/app-specific-config/axiosConfig'
import { displayModeCheck } from '../../global/helpers/global-helpers';
import { setIsMobileView } from '../../global/actions/global-actions';
import { setUserPrivileges } from '../../auth/actions/auth-actions';




////////////////////////////////////////////////////////////
// VAULT DSR SEARCH SERVICES
////////////////////////////////////////////////////////////
// POST method
export const vaultSearchRequest = () => {
	return {
		type: types.VAULT_SEARCH_REQUEST
	};
};

export const vaultSearchSuccess = (response) => {
	return {
		type: types.VAULT_SEARCH_SUCCESS,
    payload: response.data
	};
};

export const vaultSearchFailure = (error) => {
	return {
		type: types.VAULT_SEARCH_FAILURE,
    error
	};
};


export function vaultSearch(searchDsrMetadata) {

	// POST API
	return async (dispatch) => {
		dispatch(vaultSearchRequest());
		try {
			const url = '/dsr/search'; 
			const headers = await auth.getAccessToken(); 

			const response = await axiosInstance.post(
				url,
				{searchDsrMetadata},
				{ headers }
			);

			dispatch(vaultSearchSuccess(response));

		} catch (error) {
			dispatch(vaultSearchFailure(error));
		}
	};
}


export const receivedRoomsSearchRequest = () => {
	return {
		type: types.RECEIVED_ROOMS_SEARCH_REQUEST
	};
};

export const receivedRoomsSearchSuccess = (response) => {
	return {
		type: types.RECEIVED_ROOMS_SEARCH_SUCCESS,
    payload: response.data
	};
};

export const receivedRoomsSearchFailure = (error) => {
	return {
		type: types.RECEIVED_ROOMS_SEARCH_FAILURE,
    error
	};
};


export function receivedRoomsSearch(searchReceivedDsrMetadata) {
	// POST API
	return async (dispatch) => {
		dispatch(receivedRoomsSearchRequest());
		try {
			const url = '/dsr/search/received-dsrs';
			const headers = await auth.getAccessToken(); 

			const response = await axiosInstance.post(
				url,
				{ searchReceivedDsrMetadata },
				{ headers }
			);

			dispatch(receivedRoomsSearchSuccess(response));

		} catch (error) {
			dispatch(receivedRoomsSearchFailure(error));
		}
	};
}


export const prospectorDsrSearchRequest = () => {
	return {
		type: types.PROSPECTOR_SEARCH_REQUEST 
	};
};

export const prospectorDsrSearchSuccess = (response) => {
	return {
		type: types.PROSPECTOR_SEARCH_SUCCESS,
    payload: response.data
	};
};

export const prospectorDsrSearchFailure = (error) => {
	return {
		type: types.PROSPECTOR_SEARCH_FAILURE,
    error
	};
};


export function prospectorDsrSearch(searchProspectorDsrMetadata) {
	// POST API
	return async (dispatch) => {
		dispatch(prospectorDsrSearchRequest());
		try {
			const url = '/dsr/search/received-prospectors';
			const headers = await auth.getAccessToken(); 
			const response = await axiosInstance.post(
				url,
				{ searchProspectorDsrMetadata },
				{ headers }
			);

			dispatch(prospectorDsrSearchSuccess(response));

		} catch (error) {
			dispatch(prospectorDsrSearchFailure(error));
		}
	};
}

////////////////////////////////////////////////////////////
// VAULT MANAGEMENT
////////////////////////////////////////////////////////////
// Get DSRs that belong to this particular user
// GET method
export const getUserVaultDsrsSuccess = (response) => {
	return {
		type: types.GET_USER_VAULT_DSR_SUCCESS,
		payload: response.data
	};
};

export const getUserVaultDsrsFailure = (error) => {
	return {
		type: types.GET_USER_VAULT_DSR_FAILURE,
		error
	};
};

export const getUserVaultDsrsRequest = () => {
	return {
		type: types.GET_USER_VAULT_DSR_REQUEST
	};
};

export const getUserVaultDsrsReset = () => {
	return {
		type: types.GET_USER_VAULT_DSR_RESET
	}
}


export function getUserVaultDsrs(vaultQueryParams) {

	return async (dispatch) => {
		dispatch(getUserVaultDsrsRequest());
		try {
			const url = `/admin/get-user-vault-dsrs`;
			const headers = await auth.getAccessToken();
			const response = await axiosInstance.post(
				url,
				vaultQueryParams,
				{headers});
				
			const {userPrivileges} = response.data.data || {};
			if (userPrivileges) {
				dispatch(setUserPrivileges(userPrivileges))
			}
			dispatch(getUserVaultDsrsSuccess(response));
		} catch (error) {
			dispatch(getUserVaultDsrsFailure(error));
		}
	};
}

export function fetchVaultData(vaultQueryParams) {
	return async (dispatch) => {
		dispatch(getUserVaultDsrs(vaultQueryParams))

		const isMobileView = displayModeCheck();
		dispatch(setIsMobileView(isMobileView))		
	}
}

export function resetFetchVaultData() {

	return async (dispatch) => {
	
		try {
			dispatch(getUserVaultDsrsReset());

		} catch (error) {
			dispatch(getUserVaultDsrsFailure(error));
		}
	};
}