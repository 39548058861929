import React, { useState, useEffect, useRef } from 'react';
import config from "../../../config/app-config";

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { Box, Grid, Flex, Switch, useToast } from "@chakra-ui/react";
import { editDsrTeamMembers, resetEditDsrTeamMembers } from '../actions/dsr-actions';
import FormRadioInput from '../../forms/radio/components/FormRadioInput';
import { QRCodeSVG } from "qrcode.react";

import { IoCopyOutline } from 'react-icons/io5'
import { copyToUserClipboard, generateRandomString, triggerLaunchdeckToast } from '../../global/helpers/global-helpers';
import { updateDsr, resetUpdateDsrOutcome } from '../../digital-sales-room/actions/dsr-actions';
import FormTextInput from '../../forms/TextInputField/components/FormTextInput';
import LaunchdeckButton from '../../global/components/helper-components/components/LaunchdeckButton';
import WarningTextMessage from '../../global/components/helper-components/components/WarningTextMessage';
import TempDisplayBanner from '../../global/components/helper-components/components/TempDisplayBanner';

function DsrAccessSettingsForm(props) {

  const toast = useToast();
  const minCharacters = 6;
  
  const toggleSaveCodeRef = useRef(false)

  const { dsrId, thisUserRole, dsrStatusRedux, dsrPropertiesRedux, updateDsrOutcome, isMobileView } = props;
  const { displayUrl } = config;
  const routeLink = `/dsr/access/${dsrId}`;

  const publishedDsrLinkDisplay = (displayUrl && routeLink) ? `${displayUrl}${routeLink}` : null


  // Inputs
  const [accessCodeInput, setAccessCodeInput] = useState("")
  const [selectedOption, setSelectedOption] = useState("")

  const [latestDsrProperties, setLatestDsrProperties] = useState(dsrPropertiesRedux ? dsrPropertiesRedux : {})

  // UX management
  const [showAccessCodeInput, setShowAccessCodeInput] = useState(false)
  const [showValidationError, setShowValidationError] = useState(false)
  const [unlockChangeCodeButton, setUnlockChangeCodeButton] = useState(false)
  const [showAccessCodeSetBanner, setShowAccessCodeSetBanner] = useState(false)
  const [showCodeSetConfirmation, setShowCodeSetConfirmation] = useState(false)
  const [isDsrAdmin, setIsDsrAdmin] = useState(null)
  const [isGuestMember, setIsGuestMember] = useState(false)
  const [triggerToggleSave, setTriggerToggleSave] = useState(false)

  useEffect(() => {
    switch (thisUserRole) {
      case ("seller-lead"): {
        setIsDsrAdmin(true)
        setIsGuestMember(false)
        break;
      }
      case ("seller-member"): {
        setIsGuestMember(false)
        break;
      }
      case ("buyer-member"): {
        setIsGuestMember(true)
        break;
      }
      default: {
        break;
      }
    }
  }, [thisUserRole])

  useEffect(() => {
    if (dsrPropertiesRedux) {
      const { roomSettings } = dsrPropertiesRedux || {};
      const { inviteMode, accessCodeProtected, accessCodeChallenge } = roomSettings || {};

      setLatestDsrProperties(dsrPropertiesRedux)
      setSelectedOption(inviteMode ? inviteMode : "")
      setShowAccessCodeInput(accessCodeProtected ? accessCodeProtected : false)

      if (accessCodeProtected === true) {
        setAccessCodeInput(accessCodeChallenge ? accessCodeChallenge : "")
      }

    }

  }, [dsrPropertiesRedux]);

  useEffect(() => {
    if (updateDsrOutcome) {
      const { sourceEvent } = updateDsrOutcome || {};

      if (sourceEvent === "save-room-access-code") {
        setShowAccessCodeSetBanner(true)
        setShowCodeSetConfirmation(true)
      }

      if (sourceEvent === "save-room-properties") {
        toast(triggerLaunchdeckToast({ label: "New settings updated", isMobileView }))
      }

      props.action.resetUpdateDsrOutcome()
    }
  }, [updateDsrOutcome, props.action])

  useEffect(() => {

    if (accessCodeInput && triggerToggleSave && !toggleSaveCodeRef.current) {
      handleSetAccessCode('set-code')
      setTriggerToggleSave(false)
      toggleSaveCodeRef.current = true
    }

  }, [accessCodeInput, triggerToggleSave])


  const onChangeHandler = event => {
    let { roomSettings } = latestDsrProperties || {}
    let updatedDsrProperties = '';

    switch (event.target.name) {
      case ('access-code-input'): {
        setAccessCodeInput(event.target.value.toUpperCase())
        setShowCodeSetConfirmation(false)
        setShowAccessCodeSetBanner(false)
        break;
      }
      case ('anyone'):
      case ('invited-members-only'): {
        setSelectedOption(event.target.value)

        if (roomSettings) {
          updatedDsrProperties = {
            ...latestDsrProperties,
            roomSettings: {
              ...roomSettings,
              inviteMode: event.target.value
            }
          }
        } else {
          // Trigger unexpected error warning that there is no roomSettings found
          toast(
            triggerLaunchdeckToast({
            useCase: "show-error-state",
            label: "Unfortunately something went wrong. Please try again later.", 
            isMobileView
            })
          )
        }
        break;
      }
      default: {
        break;
      }
    }

    if (updatedDsrProperties) {
      props.action.updateDsr({
        dsrId: dsrId,
        dsrProperties: updatedDsrProperties,
        sourceEvent: "save-room-properties"
      })
    }
  };

  const handleSetAccessCode = (mode) => {
    //Possible modes: 'set-code' & 'remove-code'
    let { roomSettings } = latestDsrProperties || {}
    let updatedDsrProperties = '';
    let sourceEvent = "";

    if (accessCodeInput && accessCodeInput?.length >= minCharacters) {
      setShowValidationError(false)
      sourceEvent = (mode === "set-code") ? "save-room-access-code" : (mode === "remove-code") ? "remove-room-access-code" : "";

      if (roomSettings) {
        updatedDsrProperties = {
          ...latestDsrProperties,
          roomSettings: {
            ...roomSettings,
            accessCodeProtected: (mode === 'set-code') ? true : false,
            accessCodeChallenge: (mode === 'set-code') && accessCodeInput
          }
        }

      } else {
        updatedDsrProperties = {
          ...latestDsrProperties,
          roomSettings: {
            accessCodeProtected: (mode === 'set-code') ? true : false,
            accessCodeChallenge: (mode === 'set-code') && accessCodeInput
          }
        }

      }

      if (updatedDsrProperties) {
        props.action.updateDsr({
          dsrId,
          dsrProperties: updatedDsrProperties,
          sourceEvent
        })
      }
    } else {
      setShowValidationError(true)
    }
  }

  const handleAccessCodeProtectToggle = () => {
    if (showAccessCodeInput === true) {
      // Currently true, before getting switched off
      handleSetAccessCode('remove-code')
      setShowAccessCodeSetBanner(false)
      setTriggerToggleSave(false)
      toggleSaveCodeRef.current = false
    } else {
      if (!accessCodeInput) {
        setAccessCodeInput(generateRandomString(minCharacters).toUpperCase())
        setTriggerToggleSave(true)
      }

      if (accessCodeInput) {
        setTriggerToggleSave(true)
        handleSetAccessCode('set-code')
      }
    }

    setShowAccessCodeInput(!showAccessCodeInput)
  }


  const handleCopyLink = () => {
    copyToUserClipboard(publishedDsrLinkDisplay)

    toast(
      triggerLaunchdeckToast({
        useCase: "show-success-state",
        // bannerText: "",
        label: "Invite link copied",
        isMobileView
      })
    )
  }

  const renderCopyLinkText = () => {
    let copyLinkText = "";

    if (dsrStatusRedux === "active") {
      copyLinkText = publishedDsrLinkDisplay;

    } else {
      copyLinkText = "An invite link will be generated once this Room is published"
    }

    return copyLinkText;
  }

  const renderCopyInviteLinkButton = () => {
    if (dsrStatusRedux === "active") {
      return (
        <Flex color='brand.500' cursor='pointer' align='center' onClick={() => handleCopyLink()}>
          <Box as={IoCopyOutline} color='brandDark.500' boxSize='1.2em' />
        </Flex>
      )
    } else return null;
  }

  const renderCopyAccessCodeButton = () => {
    const handleCopyAccessCode = () => {
      copyToUserClipboard(accessCodeInput)

      toast(
        triggerLaunchdeckToast({
          useCase: "show-success-state",
          // bannerText: "",
          label: "Access Code copied",
          isMobileView
        })
      )
    }

    return (
      <Box as={IoCopyOutline} boxSize='1em' position='absolute' top='0.8em' right='1em' cursor='pointer' onClick={() => handleCopyAccessCode()} />
    )
  }

  const renderAccessCodeManagement = () => {
    if (isDsrAdmin) {
      return (
        <Box mt='4em'>
          <Flex w='100%' justify='space-between' align='center'>
            <Box fontSize='0.875em' fontWeight='600' color="brandDark.500">Access code protection</Box>
            <Switch size="lg" isChecked={showAccessCodeInput ? showAccessCodeInput : false} onChange={handleAccessCodeProtectToggle} />
          </Flex>
          {showAccessCodeInput &&
            <Flex className="fade-in" justify='space-between' align='center' w='100%' mt='1em'>
              <Box w='100%' position='relative'>
                <FormTextInput name={'access-code-input'} htmlFor={'accessCodeInput'}
                  defaultValue={accessCodeInput} upperCaseInputOnly={true}
                  charLimit={minCharacters} w='100%' mb='0em' mt='0em'
                  isDisabled={!unlockChangeCodeButton}
                  focusThisInput={unlockChangeCodeButton}
                  onChange={onChangeHandler} />
                {renderCopyAccessCodeButton()}
              </Box>

              <Box ml='1em'>
                {
                  (unlockChangeCodeButton === false) ?
                    <LaunchdeckButton bg='gray.100' color='brandDark.500' onClick={() => setUnlockChangeCodeButton(true)} label='Unlock' />
                    :
                    showCodeSetConfirmation ?
                      <LaunchdeckButton bg='gray.500' color='white' label='Access code set' dummy={true} />
                      :
                      <LaunchdeckButton bg='brand.500' color='white' onClick={() => handleSetAccessCode('set-code')} label='Save & Lock' />
                }
              </Box>
            </Flex>

          }

          <WarningTextMessage errorLogic={showValidationError} message={`Your access code needs to contain ${minCharacters} characters`} />

          {/* <Flex w='100%' justify='space-apart' align='center'>
            <Box>Include thumbnail with the link</Box>
            <Switch size="lg" onChange={handleAccessCodeProtectToggle} />
          </Flex> */}
        </Box>
      )
    } else {
      if (accessCodeInput) {
        return (
          <Box mt='4em'>
            <Flex w='100%' justify='space-between' align='center'>
              <Box>Access code protected</Box>
            </Flex>
            <Flex className="fade-in" justify='flex-start' align='center' w='100%' mt='1em' position='relative'>
              <FormTextInput isDisabled={true} name={'access-code-input'} htmlFor={'accessCodeInput'} defaultValue={accessCodeInput} upperCaseInputOnly={true} w='100%' mb='0em' mt='0em' />
              {renderCopyAccessCodeButton()}
            </Flex>
          </Box>
        )
      } else return null;

    }
  }
  
  const renderManageAccessOptions = () => {
    if (isGuestMember) return null

    if (isDsrAdmin) {
      return (
        <>
        <Box fontWeight='600' color="brandDark.500" fontSize='0.875em' lineHeight='1.375em'>Who can access the room</Box>
        <Grid mt='1.5em' templateRows="1fr 1fr" gap='1em'>
          <FormRadioInput name="invited-members-only" label='Invited members only' fontSize='0.875em' value={"invited-members-only"} selectedOption={selectedOption} onChangeHandler={onChangeHandler} />
          <FormRadioInput name="anyone" label='Anyone with a link' fontSize='0.875em' value={"anyone"} selectedOption={selectedOption} onChangeHandler={onChangeHandler} />
        </Grid>
      </>
      )
    } else {
      return (
        <>
        <Box fontWeight='600' color="brandDark.500" fontSize='0.875em' lineHeight='1.375em'>Who can access the room</Box>
        <Box mt='1.5em' fontSize='0.875em'>
          {selectedOption==="invited-members-only" ? "Invited members only" : selectedOption==="anyone" ? "Anyone with a link" : "-"}  
        </Box>
      </>
      )
    }
  }


  return (
    <Flex direction='column' justify='space-around' alignItems='center' pb='2em'>
      <Flex w='100%' direction='column'>
        {renderManageAccessOptions()}

        <Box mt={!isGuestMember ? '4em' : '0'} fontWeight='600' color="brandDark.500" fontSize='0.875em' lineHeight='1.375em'>Share with QR Code</Box>
        {dsrStatusRedux === "active" &&
          <Box display='flex' width='100%' justifyContent='center' mt='2em'>
            <QRCodeSVG value={publishedDsrLinkDisplay ? publishedDsrLinkDisplay : ''} />
          </Box>
        }
        <Flex className='invite-link-container'>
          <Box className="truncate-long-text">{renderCopyLinkText()}</Box>
          {renderCopyInviteLinkButton()}
        </Flex>

        {renderAccessCodeManagement()}
      </Flex>

      <TempDisplayBanner
        isVisible={showAccessCodeSetBanner}
        handleCloseThisBanner={() => setShowAccessCodeSetBanner(false)}
        displayMessage='Access Code has been set for this Room'
      />


    </Flex>
  )
}


function mapStateToProps(state) {
  return {
    editDsrTeamMembersOutcome: state.editDsrTeamMembersOutcome.results.data,
    editingTeamInProgress: state.editDsrTeamMembersOutcome.isLoading,
    dsrUserTeam: state.dsrUserTeam.userTeam,
    dsrStatusRedux: state.dsrStatus.status,
    dsrPropertiesRedux: state.draftDsrProperties.properties,
    updateDsrOutcome: state.updateDsrOutcome.results.data,
    isMobileView: state.mobileViewMode.isMobileView
  };
}

function mapDispatchToProps(dispatch) {
  return {
    action: bindActionCreators(
      {
        editDsrTeamMembers,
        resetEditDsrTeamMembers,
        updateDsr,
        resetUpdateDsrOutcome
      },
      dispatch
    )
  };
}



export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DsrAccessSettingsForm));
