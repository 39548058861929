import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import LaunchdeckCloseButton from '../helper-components/components/LaunchdeckCloseButton';
import StripeCheckoutButton from '../../../payments/components/StripeCheckoutButton';
import { useLocation } from 'react-router-dom';
import { displayEmoji } from '../../../digital-sales-room/helpers/dsr-helpers';


export default function SubscriptionNudgeBanner({ handleCloseBanner, updateUserSettings, offerFreeTrial, freeTrialCode, isUndismissable }) {
	const location = useLocation();
	const redirectBackRoute = location ? location.pathname : "/home";

	const handleDismissBanner = () => {

		if (!isUndismissable) {
			updateUserSettings({
				updatedBannerSettings: {
					bannerType: "org-admin-subscribe-reminder",
					doNotShowBanner: true
				}
			})
		}

		handleCloseBanner()
	}

	const titleCopy = (offerFreeTrial && freeTrialCode) ? `30-day free promo code - ${freeTrialCode}`: `30-day free promo code - LAUNCHNOW24`
	const textCopy = `Build rapport, close sales, and achieve customer success more quickly. Get started for $0. `

	return (

		<Flex w='100%' position='relative' direction='column' bg='white' border='1px solid' borderColor='yellow.400' rounded='0.5em' p='0.5em 1em' justify='flex-start' mb='1.5em'>

			<Flex w='100%' justify='space-between' align='center'>
				<Flex align='center' justify='flex-start' w='100%'>
					{offerFreeTrial
						?
						<Box fontSize='1.2em'>{displayEmoji('gift')}</Box>
						:
						<Box fontSize='1.2em'>
							{displayEmoji('label')}
						</Box>
						}
					<Box ml='1em' fontSize='0.9em' fontWeight='semibold' color='brandDark.500'>{titleCopy}</Box>
				</Flex>

				<Box top='1em' right='1em' visibility={isUndismissable ? "hidden" : ""} onClick={() => handleDismissBanner()}> <LaunchdeckCloseButton /></Box>
			</Flex>

			<Flex mt='0.5em' w='100%' justify='flex-start' align='center' fontSize='0.875em' pb='0.5em'>
				<Box color='brandDark.500' >{textCopy}</Box>
				<Box ml='1.5em' >	<StripeCheckoutButton sourceRoute={redirectBackRoute} isFreeTrial={offerFreeTrial} buttonText={'Use Code'}/></Box>
			</Flex>

		</Flex>
	);

}

