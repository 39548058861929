import * as types from '../../actions/manage-dsr-member-action-types';



const INITIAL_MANAGE_JOIN_ROOM_REQUEST_STATE = {
	isLoading: false,
	results: {},
	error: '',
};

export function manageJoinRoomRequestReducer(state = INITIAL_MANAGE_JOIN_ROOM_REQUEST_STATE, action) {
	switch (action.type) {
		case types.MANAGE_JOIN_ROOM_RESET:
			return INITIAL_MANAGE_JOIN_ROOM_REQUEST_STATE;
		case types.MANAGE_JOIN_ROOM_REQUEST:
			return { ...state, isLoading: true, results: {} };
		case types.MANAGE_JOIN_ROOM_SUCCESS:
			return { ...state, isLoading: false, results: action.payload };
		case types.MANAGE_JOIN_ROOM_FAILURE:
			return { ...state, isLoading: false, error: 'Error updating user join room request' };
		default:
			return state;
	}
}