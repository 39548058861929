import React from 'react';
import { Redirect } from 'react-router-dom';

import auth from '../../../config/app-specific-config/auth';

const Home = () => {
	if (auth.isLoggedIn() !== null) {
		return <Redirect to="/home" />;
	} else {
		return <Redirect to="/login" />;
	}
};

export default Home;
