import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'

import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputLeftAddon,
  Image,
  InputRightElement,
  useDisclosure,
  Popover,
  PopoverContent,
  PopoverArrow,
  PopoverHeader,
  PopoverTrigger,
  useOutsideClick,
  useMediaQuery
} from "@chakra-ui/react";

import { Redirect } from 'react-router-dom';
import SearchIcon from './styles/images/search-icon.svg';
import VaultDisplayDsrModule from './VaultDisplayDsrModule';
import { receivedRoomsSearch } from './actions/vault-actions';
import VaultDisplaySearchResultsModule from './VaultDisplaySearchResultsModule';

import { GoChevronDown } from 'react-icons/go';
import { IoMdAddCircle, IoIosClose } from 'react-icons/io';
import { determineIsNonCustomerUserAccount } from '../profile/helpers/user-helper';
import DsrDynamicModal from '../digital-sales-room/messages/DsrDynamicModal';
import VaultMobileNavMenu from './VaultMobileNavMenu';
import { getUserProfile, getUserSettings } from '../profile/actions/users-profile-actions';
import DynamicAppBannerDisplay from '../global/components/helper-components/components/DynamicAppBannerDisplay';
import { checkIfDismissedBanner } from '../global/helpers/global-helpers';
import LoggedOutConfirmationModal from '../digital-sales-room/dsr-general-mgmt/LoggedOutConfirmationModal';

function VaultReceivedRoomsPage({
  vaultIsProcessing,
  allUserDsrs,
  fetchVaultData,
  resetFetchVaultData,
  receivedRoomsSearch,
  receivedRoomsSearchResults,
  receivedRoomsSearchIsProcessing,
  changeDsrModalState,
  changeDsrPopupState,
  userActionRequiredState,
  showDsrModalState,
  isMobileView,
  userPrivileges,
  // Action
  getUserProfile,
  getUserSettings,
  //Redux states
  userAccountProfile,
  userSettings,
  isOrgAdmin
}) {

  const initRef = useRef(false);
  const [localState, setLocalState] = useState({
    isLoading: true,
    isMobileView: false,
    isVerified: null,
    // isOnboarded: null,

    isEmptyState: '',
    requestCategory: "initialize-received-rooms",

    //Data Mgmt
    displayedDsrs: "",

    // UX Management
    // showCreateDsrModal: "",
    isNoOrgUser: "",
    itemsPerPage: 9, // TODO: Allow user configurability
    pageNumber: 1
  })

  // Manage banner
  const [showAlertBanner, setShowAlertBanner] = useState(false)
  const [showDemoConversionBanner, setShowDemoConversionBanner] = useState(false)
  const [offerFreeTrial,setOfferFreeTrial] = useState(false)
  const [freeTrialCode, setFreeTrialCode] = useState("")

  // Manage search bar
  const [displaySearchResults, setDisplaySearchResults] = useState(false)
  const [searchInputValue, setSearchInputValue] = useState("");

  // Manage Sort function
  const [showSortDropdown, setShowSortDropdown] = useState(false)
  const [chosenSortValue, setChosenSortValue] = useState("Latest")


  useEffect(() => {
    if (!initRef.current) {
      document.title = 'Launchdeck Digital Sales Room'
      document.description =
        'Create customised private digital sales rooms that let Sales & Purchasing teams agree on deals quickly'

      fetchVaultData({ requestCategory: "initialize-received-rooms", pageNumber: 1, itemsPerPage: localState.itemsPerPage, sortRequest: "default" })
      initRef.current = true
    }
  }, [fetchVaultData, localState.itemsPerPage])


  useEffect(() => {
		if (!userAccountProfile) {
			getUserProfile({
				getOrgProfile: true
			})
		} else {
      const {userMetaData} = userAccountProfile || {};
      const {productMarketing} = userMetaData || {};
      const {offerFreeTrial, freeTrialCode} =  productMarketing || {};

      if (offerFreeTrial && freeTrialCode) {
        setOfferFreeTrial(true)
        setFreeTrialCode(freeTrialCode)
      }
    }
	}, [userAccountProfile, getUserProfile])

  useEffect(() => {
		if (!userSettings) {
			getUserSettings({emailSettingsRequest: false, bannerSettingsRequest: true, checkDemoSignupUserRequest: true})
		} else if (userSettings) {
      const {useDemoSignupUserNudge} = userSettings || {};
      const isNoOrgUser = determineIsNonCustomerUserAccount();

      if (!!useDemoSignupUserNudge) {
        const isDismissedBanner = checkIfDismissedBanner(userSettings, "demo-user-acquistion-reminder")

        if (!!isDismissedBanner) {
          setShowDemoConversionBanner(false)
        } else {
          setShowDemoConversionBanner(true)
        }
        
      } else if (userPrivileges.subscriptionReminder === true) {

        const requiredBannerType = isNoOrgUser ? "no-org-user-subscribe-reminder" : isOrgAdmin ? "org-admin-subscribe-reminder" : ""
       
        if (requiredBannerType) {
          const isDismissedBanner = checkIfDismissedBanner(userSettings, requiredBannerType)
      
          if (!!isDismissedBanner) {
            setShowAlertBanner(false)
          } else {
            setShowAlertBanner(true)
          }
        } 
      }
    }
	}, [userSettings, getUserSettings, userPrivileges.subscriptionReminder, isOrgAdmin])



  useEffect(() => {
    if (allUserDsrs) {
      const { isVerified, foundDsrsTotal, displayedDsrs } = allUserDsrs || {};
      const isEmptyState = displayedDsrs ? false : true;

      const isNoOrgUser = determineIsNonCustomerUserAccount();

      setLocalState((localState) => ({
        ...localState,
        isLoading: false,
        // isMobileView,
        isVerified,
        isEmptyState,
        displayedDsrs,
        ...isNoOrgUser && { isNoOrgUser },
        foundDsrsTotal
      }))

      resetFetchVaultData()
    }

  }, [allUserDsrs, resetFetchVaultData])


  const toggleFilter = (pageNumber, sortRequest) => {

    setLocalState({
      ...localState,
      pageNumber,
      displayedDsrs: "",
      requestCategory: "buyers-published-dsrs"
    })

    fetchVaultData({
      requestCategory: "buyers-published-dsrs",
      sortRequest: sortRequest ? sortRequest : "default",
      pageNumber: pageNumber ? pageNumber : 1,
      itemsPerPage: parseInt(localState.itemsPerPage)
    })

    setDisplaySearchResults(false)
  }

  const onTextSearch = (query) => {
    let sortRequest = "default";

    switch (chosenSortValue) {
      case ('Latest'): {
        sortRequest = "latest"
        break;
      }
      case ('Room name (A - Z)'): {
        sortRequest = "a-to-z"
        break;
      }
      case ('Room name (Z - A)'): {
        sortRequest = "z-to-a"
        break;
      }
      default: {
        break;
      }
    }

    const searchDsrMetadata = {
      queryInput: query,
      requestCategory: "buyers-published-dsrs",
      sortRequest
    }

    setDisplaySearchResults(true)
    receivedRoomsSearch(searchDsrMetadata)

  }

  const onClickShowProductMarketingModal = () => {
    changeDsrModalState(true)
    changeDsrPopupState('show-product-marketing-nudge')
  }


  // Master close all modals
  const handleDsrModalClose = () => {
    changeDsrPopupState('')
    changeDsrModalState(false)
  }

  const renderVaultPageDisplay = () => {
    const { isLoading, foundDsrsTotal, itemsPerPage, pageNumber, requestCategory,
      isOnboarded, isEmptyState, displayedDsrs, isNoOrgUser } = localState;

    if (isLoading === false) {
      if (isOnboarded === false && !isNoOrgUser) {
        return <Redirect to={'/welcome'} />
      }

      if (isEmptyState === false || isEmptyState === null) {

        if (displaySearchResults === true) {
          return (
            <React.Fragment>
              <VaultSearchToolbar
                onTextSearch={onTextSearch}
                toggleFilter={toggleFilter}
                showSortDropdown={showSortDropdown}
                setShowSortDropdown={setShowSortDropdown}
                chosenSortValue={chosenSortValue}
                setChosenSortValue={setChosenSortValue}
                searchInputValue={searchInputValue}
                setSearchInputValue={setSearchInputValue}
                isNoOrgUser={isNoOrgUser}
                onClickShowProductMarketingModal={onClickShowProductMarketingModal}
                isMobileView={isMobileView}
              />
              <Box className="display-dsr-container">
                <VaultDisplaySearchResultsModule
                  vaultSearchResults={receivedRoomsSearchResults}
                  itemsPerPage={itemsPerPage}
                  currentPageNumber={pageNumber}
                  isMobileView={isMobileView}
                  vaultSearchIsProcessing={receivedRoomsSearchIsProcessing}
                  chosenSortValue={chosenSortValue}
                  toggleFilter={toggleFilter}
                  setDisplaySearchResults={setDisplaySearchResults}
                  setSearchInputValue={setSearchInputValue}
                  isReceivedRoomsPage={true}
                />
              </Box>
            </React.Fragment>
          )
        } else {
          return (
            <React.Fragment>
              <VaultReceivedRoomsToolbar
                displaySearchResults={displaySearchResults}
                toggleFilter={toggleFilter}
                onTextSearch={onTextSearch}
                pageNumber={localState.pageNumber}
                showSortDropdown={showSortDropdown}
                setShowSortDropdown={setShowSortDropdown}
                chosenSortValue={chosenSortValue}
                setChosenSortValue={setChosenSortValue}
                searchInputValue={searchInputValue}
                setSearchInputValue={setSearchInputValue}
                isNoOrgUser={isNoOrgUser}
                onClickShowProductMarketingModal={onClickShowProductMarketingModal}
                isMobileView={isMobileView}
              />
              <Box className="display-dsr-container">
                <VaultDisplayDsrModule
                  requestCategory={requestCategory}
                  displayedDsrs={displayedDsrs}
                  isMobileView={isMobileView}
                  vaultIsProcessing={vaultIsProcessing}
                  foundDsrsTotal={foundDsrsTotal}
                  itemsPerPage={itemsPerPage}
                  toggleFilter={toggleFilter}
                  currentPageNumber={pageNumber}
                  vaultSearchIsProcessing={receivedRoomsSearchIsProcessing}
                  chosenSortValue={chosenSortValue}
                  isReceivedRoomsPage={true}
                />
              </Box>
            </React.Fragment>
          )
        }
      }
    }
  }


  return (
    <React.Fragment>
      {showDemoConversionBanner && <DynamicAppBannerDisplay bannerType={"demo-conversion-nudge"} />}
      {showAlertBanner && <DynamicAppBannerDisplay bannerType={localState.isNoOrgUser ? "audience-sign-up-nudge" : offerFreeTrial ? "free-trial-subscribe-nudge" :"subscription-nudge"} freeTrialCode={offerFreeTrial && freeTrialCode}/>}
      <Box>
        {renderVaultPageDisplay()}
      </Box>
      <Box>
        <DsrDynamicModal
          showDsrModal={showDsrModalState}
          userActionRequired={userActionRequiredState}
          handleDsrModalClose={handleDsrModalClose} // UUU fake, swap this out
        />
        <LoggedOutConfirmationModal />
      </Box>
    </React.Fragment>

  )
}

function VaultReceivedRoomsToolbar({ toggleFilter, onTextSearch, pageNumber,
  showSortDropdown, setShowSortDropdown, chosenSortValue, setChosenSortValue,
  searchInputValue, setSearchInputValue, isNoOrgUser, onClickShowProductMarketingModal,
  isMobileView
}) {
  const sortOrderDropdownRef = useRef(null);
  const { isOpen, onToggle, onClose } = useDisclosure()
  const [isError, setIsError] = useState(false);
  const [isLargerThan1000] = useMediaQuery('(min-width: 1000px)')

  useOutsideClick({
    ref: sortOrderDropdownRef,
    handler: () => setShowSortDropdown(false),
  })

  const handleChange = (event) => {
    setSearchInputValue(event.target.value)
    isError && setIsError(false)
  }

  const handleClearSearchText = () => {
    setSearchInputValue("")
    toggleFilter(1, "default")
  }

  const handleEnter = (event) => {
    if (event.key === 'Enter' && event.target.name === "received-rooms-search-bar") {
      if (searchInputValue.trim().length > 2) {
        onTextSearch(searchInputValue)
        onClose()
      } else {
        setIsError(true)
        onToggle()
      }
    }
  }

  const handlePickSortOption = (option) => {
    let sortRequest = "default";
    switch (option) {
      case ('Latest'): {
        sortRequest = "latest"
        break;
      }
      case ('Room name (A - Z)'): {
        sortRequest = "a-to-z"
        break;
      }
      case ('Room name (Z - A)'): {
        sortRequest = "z-to-a"
        break;
      }
      default: {
        break;
      }
    }
    if (option) {
      setChosenSortValue(option)
      toggleFilter(pageNumber, sortRequest)
    }
  }



  return (
    <>
      {!isLargerThan1000 ?
        <VaultMobileNavMenu currentMode="received-rooms" />
        :
        <Flex w='100%' justify='space-between' align='center'>

 
          <Box>
          <Box className="page-title">Received Rooms</Box>
          <Box fontSize='0.8em'> Rooms you've joined as an audience member</Box> 
          </Box>

          <Box whiteSpace='nowrap' >
            {isNoOrgUser &&
              <Flex className='create-new-room-button'
                onClick={() => onClickShowProductMarketingModal()}>
                <Box className='create-new-room-button__button-text'>New Room</Box>
                <Box as={IoMdAddCircle} boxSize='1.5em' className='create-new-room-button__add-icon' />
              </Flex>
            }
          </Box>
        </Flex>
      }

      <Flex className='menu-bar' style={{ gap: '10px' }} justifyContent='center' flexDirection={['column', 'column', 'row', 'row']}>

        <Flex justifyContent={['center', 'center', 'flex-start', 'flex-start']} align='center' w='100%' >
          <Box className="chosen-category-tab">All</Box>
        </Flex>

        <Flex style={{ gap: '10px' }} w='100%'  flexDirection={['column', 'column', 'row', 'row']} align='center'>

          <Flex className='filter-menu' onClick={() => { setShowSortDropdown(!showSortDropdown) }} ref={sortOrderDropdownRef}>
            <Flex>
              <Box mr='0.5em' color='gray.500' fontSize='0.875em' fontWeight='400' lineHeight='1.375em'>Sort By:</Box>
              <Box className='chosen-option-text-display'>{chosenSortValue}</Box>
            </Flex>

            <Box as={GoChevronDown} boxSize="0.75em" color="gray.300" />

            {/* Show Dropdown bar here */}
            <Box className='filter-menu__dropdown' display={showSortDropdown ? 'inline-block' : 'none'}>
              <React.Fragment>
                <Box className={(chosenSortValue !== 'Latest') ? 'filter-menu__dropdown-option-display' : 'filter-menu__dropdown-chosen-option-display'} onClick={() => (chosenSortValue !== 'Latest') && handlePickSortOption('Latest')}>Latest</Box>
                <Box className={(chosenSortValue !== 'Room name (A - Z)') ? 'filter-menu__dropdown-option-display' : 'filter-menu__dropdown-chosen-option-display'} onClick={() => (chosenSortValue !== 'Room name (A - Z)') && handlePickSortOption('Room name (A - Z)')}>Room name (A - Z)</Box>
                <Box className={(chosenSortValue !== 'Room name (Z - A)') ? 'filter-menu__dropdown-option-display' : 'filter-menu__dropdown-chosen-option-display'} onClick={() => (chosenSortValue !== 'Room name (Z - A)') && handlePickSortOption('Room name (Z - A)')}>Room name (Z - A)</Box>
              </React.Fragment>
            </Box>

          </Flex>


          {/* Search bar */}
          <Popover
            isOpen={isOpen}
            onClose={onClose}
          >
            <PopoverTrigger      isOpen={isError}>
          <Flex w='100%' justify='space-between' minWidth='max-content' maxWidth='256px'>
            <InputGroup
              size="2em"
              m="0 1em"
              width="100%"
              maxWidth='20em'>
              <InputLeftAddon roundedTopLeft='0.25em' roundedBottomLeft='0.25em'>
                <Image src={SearchIcon} mx="0.75em" />
              </InputLeftAddon>
              <Input
                name='received-rooms-search-bar'
                value={searchInputValue}
                onChange={handleChange}
                onKeyDown={handleEnter}
                placeholder="Search"
                padding="0.5em"
                rounded='0.25em'
                fontSize='0.875em'
              />
               {searchInputValue && <InputRightElement children={<Box as={IoIosClose} cursor='pointer' boxSize='1em' onClick={() => handleClearSearchText()} />} top="0" bottom="0" right="8px" m="auto" />}
            </InputGroup>


          </Flex>
          </PopoverTrigger>
            <PopoverContent 
            // isOpen={isError} 
            zIndex={4}>
              <PopoverArrow />
              <PopoverHeader fontSize='0.875em'>Type in 3 or more characters to search</PopoverHeader>
            </PopoverContent>
          </Popover>
      
        </Flex>
      </Flex>
    </>

  )

}

function VaultSearchToolbar({ onTextSearch,
  showSortDropdown, setShowSortDropdown, chosenSortValue, setChosenSortValue,
  searchInputValue, setSearchInputValue, isNoOrgUser, toggleFilter, onClickShowProductMarketingModal,
  isMobileView
}) {


  const { isOpen, onToggle, onClose } = useDisclosure()
  const sortOrderDropdownRef = useRef(null);

  const [isError, setIsError] = useState(false);
  const [isLargerThan1000] = useMediaQuery('(min-width: 1000px)')

  useOutsideClick({
    ref: sortOrderDropdownRef,
    handler: () => setShowSortDropdown(false),
  })

  const handleChange = (event) => {
    setSearchInputValue(event.target.value)
    isError && setIsError(false)
  }

  const handleClearSearchText = () => {
    setSearchInputValue("")
    toggleFilter(1, "default")
  }

  const handleEnter = (event) => {
    if (event.key === 'Enter') {
      if (event.target.name === "rooms-search-bar") {
        // onTextSearch(searchInputValue)
        if (searchInputValue.trim().length > 2) {
          onTextSearch(searchInputValue)
          onClose()
        } else {
          setIsError(true)
          onToggle()
        }
      }
    }
  }

  const handlePickSortOption = (option) => {

    if (option) {
      setChosenSortValue(option)
    }
  }


  return (
    <>
      {!isLargerThan1000 ?
        <VaultMobileNavMenu currentMode="received-rooms" />
        :
        <Flex w='100%' justify='space-between' align='center'>

          <Box className="page-title">Received Rooms</Box>

          <Box whiteSpace='nowrap' >
            {isNoOrgUser &&
              <Flex className='create-new-room-button' onClick={() => onClickShowProductMarketingModal()}>
                <Box className='create-new-room-button__button-text'>New Room</Box>
                <Box as={IoMdAddCircle} boxSize='1.5em' className='create-new-room-button__add-icon' />
              </Flex>
            }
          </Box>
        </Flex>
      }
      <Flex className='menu-bar' style={{ gap: '10px' }} justifyContent='center' flexDirection={['column', 'column', 'row', 'row']}>

        <Flex justifyContent={['center', 'flex-start', 'flex-start', 'flex-start']} align='center' w='100%' >
          <Box className="chosen-category-tab">All</Box>
        </Flex>

        <Flex style={{ gap: '10px' }} w='100%' justify='flex-end' flexDirection={['column', 'row', 'row', 'row']} align='center'>

          <Flex className='filter-menu' onClick={() => { setShowSortDropdown(!showSortDropdown) }} ref={sortOrderDropdownRef}>
            <Flex>
              <Box mr='0.5em' color='gray.500' fontSize='0.875em' fontWeight='400' lineHeight='1.375em'>Sort By:</Box>
              <Box className='chosen-option-text-display'>{chosenSortValue}</Box>
            </Flex>

            <Box as={GoChevronDown} boxSize="0.75em" color="gray.300" />

            {/* Show Dropdown bar here */}
            <Box className='filter-menu__dropdown' display={showSortDropdown ? 'inline-block' : 'none'}>
              <React.Fragment>
                <Box className={(chosenSortValue !== 'Latest') ? 'filter-menu__dropdown-option-display' : 'filter-menu__dropdown-chosen-option-display'} onClick={() => (chosenSortValue !== 'Latest') && handlePickSortOption('Latest')}>Latest</Box>
                <Box className={(chosenSortValue !== 'Room name (A - Z)') ? 'filter-menu__dropdown-option-display' : 'filter-menu__dropdown-chosen-option-display'} onClick={() => (chosenSortValue !== 'Room name (A - Z)') && handlePickSortOption('Room name (A - Z)')}>Room name (A - Z)</Box>
                <Box className={(chosenSortValue !== 'Room name (Z - A)') ? 'filter-menu__dropdown-option-display' : 'filter-menu__dropdown-chosen-option-display'} onClick={() => (chosenSortValue !== 'Room name (Z - A)') && handlePickSortOption('Room name (Z - A)')}>Room name (Z - A)</Box>
              </React.Fragment>
            </Box>

          </Flex>


          {/* Search bar */}
          <Popover
          isOpen={isOpen}
          onClose={onClose}
          >
            <PopoverTrigger>
              <Flex w='100%' justify='space-between' minWidth='max-content' maxWidth='256px'>
                <InputGroup
                  size='2em'
                  m="0 1em"
                  width="100%"
                  maxWidth='20em'>
                  <InputLeftAddon roundedTopLeft='0.25em' roundedBottomLeft='0.25em'>
                    <Image src={SearchIcon} mx="0.75em" />
                  </InputLeftAddon><Input
                  name="rooms-search-bar"
                  value={searchInputValue}
                  onChange={handleChange}
                  onKeyDown={handleEnter}
                  placeholder="Search"
                  padding="0.5em"
                  rounded='0.25em'
                  fontSize='0.875em'
                />
                  {searchInputValue && <InputRightElement children={<Box as={IoIosClose} cursor='pointer' boxSize='1em' onClick={() => handleClearSearchText()} />} top="0" bottom="0" right="8px" m="auto" />}
                </InputGroup>
              </Flex>
            </PopoverTrigger>
            <PopoverContent 
            // isOpen={isError} 
            zIndex={4}>
              <PopoverArrow />
              <PopoverHeader fontSize='0.875em'>Type in 3 or more characters to search</PopoverHeader>
            </PopoverContent>
          </Popover>

        </Flex>
      </Flex>
    </>

  )

}



const mapStateToProps = (state) => ({
  receivedRoomsSearchResults: state.receivedRoomsSearchResults.results.data,
  receivedRoomsSearchIsProcessing: state.receivedRoomsSearchResults.isLoading,
  userActionRequiredState: state.dsrPopup.UserActionRequired,
  showDsrModalState: state.dsrModal.showDsrModal,
  isMobileView: state.mobileViewMode.isMobileView,
  userPrivileges: state.userPrivileges.results,
  userAccountProfile: state.userProfile.accountUserProfile,
  isOrgAdmin:  state.isOrgAdmin.results,
  userSettings: state.userSettings.results.data
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      receivedRoomsSearch,
      getUserProfile,
      getUserSettings
    },
    dispatch,
  )

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VaultReceivedRoomsPage))
