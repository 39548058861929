import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { bindActionCreators } from "redux";

import { useDisclosure, useToast } from "@chakra-ui/react";
import {
  changeDsrPopupState,
  changeDsrModalState,
  deleteFiles,
  deleteFilesReset,
  manageSharedDoc,
  resetManageSharedDoc,
} from "../actions/dsr-actions";

import "./styles/dsr-shared-docs-display.scss";
import PublicDsrSharedDocSignupPrompt from "../dsr-content-components/public-dsr/PublicDsrSharedDocSignupPrompt";
import {
  searchArrayofObjects,
  triggerLaunchdeckToast,
} from "../../global/helpers/global-helpers";
import { DisplayFullState } from "./DisplayFullState";
import { DisplayEmptyState } from "./DisplayEmptyState";

function DsrSharedDocsPage(props) {
  const {
    dsrId,
    isDsrAdmin,
    dsrTeamMembers,
    handleOpenSignupOrLoginModal,
    dsrSharedDocOutcome,
    isMobileView,
    isPublicDsrEnvironment,
    // Redux,
    dsrSharedDocs,
    deleteFilesState,
    // Actions
    deleteFilesReset,
    manageSharedDoc,
    resetManageSharedDoc,
    handleChangeUserActionRequired,
    changeDsrPopupState,
    changeDsrModalState,
  } = props || {};

  const sharedDocsRef = useRef(null);
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [sharedDocsArray, setSharedDocsArray] = useState("");

  const [displayEmptyState, setDisplayEmptyState] = useState(null);
  const [displaySignupPrompt, setDisplaySignupPrompt] = useState(null);

  // UX for search
  const [searchTextInput, setSearchTextInput] = useState("");
  const [, setShowClearButton] = useState(false);

  // Sort UX
  const [showAddDocumentForm, setShowAddDocumentForm] = useState(false);
  const [showDeleteSpinner, setShowDeleteSpinner] = useState(false);
  const [showPopoverDocId, setShowPopoverDocId] = useState("");

  useEffect(() => {
    if (dsrSharedDocOutcome) {
      resetManageSharedDoc();
    }
  }, []);

  useEffect(() => {
    if (isPublicDsrEnvironment) {
      setDisplaySignupPrompt(true);
    } else if (!!dsrSharedDocs) {
      const dsrSharedDocsInitialize = Array.isArray(dsrSharedDocs)
        ? [...dsrSharedDocs]
        : [];
      const showEmptyState = !(
        Array.isArray(dsrSharedDocsInitialize) &&
        dsrSharedDocsInitialize.length > 0
      );

      sharedDocsRef.current = dsrSharedDocsInitialize;
      setSharedDocsArray(dsrSharedDocsInitialize);
      setDisplayEmptyState(showEmptyState);
    }
  }, [dsrSharedDocs, isPublicDsrEnvironment]);

  useEffect(() => {
    if (dsrSharedDocOutcome) {
      const { manageEvent, sharedDocManaged } = dsrSharedDocOutcome || {};

      if (sharedDocManaged === true) {
        switch (manageEvent) {
          case "create-new-shared-doc": {
            setShowDeleteSpinner(false);
            toast(
              triggerLaunchdeckToast({
                useCase: "show-success-state",
                label: "Document shared",
                isMobileView,
              }),
            );
            break;
          }
          case "delete-shared-doc": {
            setShowDeleteSpinner(false);
            setShowPopoverDocId("");

            if (sharedDocManaged === true) {
              toast(
                triggerLaunchdeckToast({
                  useCase: "show-success-state",
                  label: "Document removed",
                  isMobileView,
                }),
              );
            } else {
              toast(
                triggerLaunchdeckToast({
                  useCase: "show-error-state",
                  label:
                    "Unfortunately something went wrong. Please try again later.",
                  isMobileView,
                }),
              );
            }

            break;
          }
          case "edit-shared-doc": {
   

            if (sharedDocManaged === true) {
              toast(
                triggerLaunchdeckToast({
                  useCase: "show-success-state",
                  label: "Edit changes saved successfully",
                  isMobileView,
                }),
              );
            } else {
              toast(
                triggerLaunchdeckToast({
                  useCase: "show-error-state",
                  label:
                    "Unfortunately something went wrong. Please try again later.",
                  isMobileView,
                }),
              );
            }

            handleDsrModalClose()
            break;
          }

          default: {
            break;
          }
        }
      }
    }
  }, [dsrSharedDocOutcome]);

  useEffect(() => {
    if (deleteFilesState) {
      // const { deleted: deletedObjects } = deleteFilesState || {}
      deleteFilesReset();
    }
  }, [deleteFilesState]);

  const handleFilterOwner = (owner) => {
    const { userId: docOwnerId } = owner || {};

    if (docOwnerId) {
      const filteredOwnerArray =
        sharedDocsArray &&
        sharedDocsArray?.filter((doc) => doc.docOwnerId === docOwnerId);
      setSharedDocsArray(filteredOwnerArray);
    } else {
      setSharedDocsArray(sharedDocsArray);
    }
  };

  const clearSearch = () => {
    setSharedDocsArray(sharedDocsRef.current);
    setShowClearButton(false);
    setSearchTextInput("");
  };

  const onSearchChange = (e) => {
    setSearchTextInput(e.target.value);
  };

  const handleSearch = () => {
    if (!searchTextInput) return; // Added early return

    const searchResultsArray = searchArrayofObjects(
      sharedDocsArray,
      searchTextInput,
      ["docName"],
    );

    setSharedDocsArray(searchResultsArray);
  };

  const handleDeleteSharedDoc = (deletedDocId, deletedDocFileKey) => {
    setShowDeleteSpinner(true);

    if (deletedDocFileKey) {
      deleteFiles([{ Key: deletedDocFileKey }]);
    }

    manageSharedDoc({
      dsrId: dsrId,
      manageEvent: "delete-shared-doc",
      docId: deletedDocId,
    });
  };

  const handleSharedDocOrderChange = (
    activeIndex,
    overIndex,
    sharedDocsArray,
  ) => {
    const reorderedSharedDocsArray = [...sharedDocsArray];
    const [movedDoc] = reorderedSharedDocsArray.splice(activeIndex, 1);
    reorderedSharedDocsArray.splice(overIndex, 0, movedDoc);

    const reorderDocs = reorderedSharedDocsArray.reduce((acc, doc, index) => {
      const previousDisplayOrder = sharedDocsArray[index].displayOrder;
      if (doc.displayOrder !== previousDisplayOrder) {
        acc[doc.docId] = previousDisplayOrder;
      }
      return acc;
    }, {});

    setSharedDocsArray(reorderedSharedDocsArray);
    manageSharedDoc({
      dsrId,
      manageEvent: "reorder-shared-docs",
      reorderDocs,
    });
  };

  const handleEditSharedDoc = (sharedDocId, editedSharedDocName, editedSharedDocLink, fileKey) => {

    handleChangeUserActionRequired("edit-shared-doc", {editedSharedDocId: sharedDocId, editedSharedDocName, editedSharedDocLink, editedSharedDocFileKey: fileKey})
  }

  const handleDsrModalClose = () => {
    changeDsrPopupState('')
    changeDsrModalState(false)
  }

  if (displaySignupPrompt === true) {
    return (
      <PublicDsrSharedDocSignupPrompt
        handleOpenSignupOrLoginModal={() => handleOpenSignupOrLoginModal()}
      />
    );
  } else if (displayEmptyState === true) {
    return (
      <DisplayEmptyState
        dsrId={dsrId}
        isMobileView={isMobileView}
        showAddDocumentForm={showAddDocumentForm}
        setShowAddDocumentForm={setShowAddDocumentForm}
        displaySignupPrompt={displaySignupPrompt}
        handleOpenSignupOrLoginModal={handleOpenSignupOrLoginModal}
      />
    );
  } else if (displayEmptyState === false) {
    return (
      <DisplayFullState
        dsrId={dsrId}
        isDsrAdmin={isDsrAdmin}
        sharedDocsArray={sharedDocsArray}
        searchTextInput={searchTextInput}
        clearSearch={clearSearch}
        onSearchChange={onSearchChange}
        handleSearch={handleSearch}
        isMobileView={isMobileView}
        showAddDocumentForm={showAddDocumentForm}
        handleFilterOwner={handleFilterOwner}
        handleSharedDocOrderChange={handleSharedDocOrderChange}
        dsrTeamMembers={dsrTeamMembers}
        setShowAddDocumentForm={setShowAddDocumentForm}
        showDeleteSpinner={showDeleteSpinner}
        handleDeleteSharedDoc={handleDeleteSharedDoc}
        setShowDeleteSpinner={setShowDeleteSpinner}
        showPopoverDocId={showPopoverDocId}
        setShowPopoverDocId={setShowPopoverDocId}
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        displaySignupPrompt={displaySignupPrompt}
        handleOpenSignupOrLoginModal={handleOpenSignupOrLoginModal}
        handleEditSharedDoc={handleEditSharedDoc}
      />
    );
  } else return null;
}

function mapStateToProps(state) {
  return {
    isDsrAdmin: state.dsrAdmin.isDsrAdmin,
    dsrSharedDocs: state.currentDsrSharedDocs.dsrSharedDocs,
    dsrSharedDocOutcome: state.dsrSharedDocOutcome.results.data,
    dsrTeamMembers: state.dsrTeamMembers.members,
    isMobileView: state.mobileViewMode.isMobileView,
    isPublicDsrEnvironment: state.publicDsrEnvironment.publicDsrEnv,
    deleteFilesState: state.deleteFilesOutcome.results,
  };
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      manageSharedDoc,
      deleteFiles,
      deleteFilesReset,
      resetManageSharedDoc,
      changeDsrPopupState,
      changeDsrModalState,
    },
    dispatch,
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DsrSharedDocsPage),
);
