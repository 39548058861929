import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';

import { resetPassword } from '../actions/auth-actions';
import { Flex, Box } from '@chakra-ui/react';

import WarningTextMessage from '../../global/components/helper-components/components/WarningTextMessage';
import FormTextInput from '../../forms/TextInputField/components/FormTextInput';
import LaunchdeckButton from '../../global/components/helper-components/components/LaunchdeckButton';
import { redirectToNewPage } from '../../global/helpers/global-helpers';


function PasswordResetForm(props) {

	const [localState, setLocalState] = useState({
		showSubmitted: false,
		email: "",
		isInvalidEmail: ""
	})

	const onPressEnterAndLogin = (e) => {
		if (e.key === 'Enter') {
			validateForgotPasswordForm();
		}
	}

	function validateForgotPasswordForm() {
		const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(localState.email);

		if (isValidEmail) {
			handleSubmitForm(email)
		} else {
			setLocalState({ ...localState, isInvalidEmail: true })
		}
	}


	const handleChange = (event) => {
		const value = event.target.value;
		setLocalState({
			...localState,
			email: value,
			isInvalidEmail: ""
		});
	}

	const handleSubmitForm = (email) => {
		props.action.resetPassword(email);
		setLocalState({ ...localState, showSubmitted: true });
	}


	const { email, showSubmitted, isInvalidEmail } = localState || {};

	return (
		<Flex mt='2em' justify='center' w='100%'>
			{showSubmitted === false ?
				<Flex my='0.5em' w='80%' justify='center' onKeyDown={(e) => onPressEnterAndLogin(e)}>
					<Flex direction='column' w='100%' maxW='35em'>
						<FormTextInput name="email" htmlFor='email' label="Email Address" placeholder="" mb='0.2em' value={email} onChange={handleChange} />
						<WarningTextMessage errorLogic={isInvalidEmail} message='Please use a valid email' />

						<Flex direction='column' mt='1.5em' justify='center' alignItems='center'>
							<Flex justify='center' w='100%'>
								<LaunchdeckButton bg='brand.500' color='white' px='5em' w={["100%", "100%", "auto", "auto"]} onClick={validateForgotPasswordForm} label='Get Instructions' />
							</Flex>
						</Flex>

					</Flex>
				</Flex>
				: <Flex direction='column' w={['100%', '100%', '60%', '60%']} >
					<Box border='1px' borderColor='brand.500' rounded={8} bg='white' px='2%' py='0.8em' mt='2em' >
						<Box textAlign='center' className='flip-in-hor-bottom'>   <span role="img" aria-label="ok-hand"> 👌</span> We have received your request to reset your password  </Box>
						<Box mt='1em' textAlign='center' fontSize='0.8em'>If things are in order, you should be receiving an email from us soon. See you in a bit! </Box>
					</Box>
					<Flex alignItems='center' justify='center' mt='2em'>
						<LaunchdeckButton bg='gray.100' color='brandDark.500' onClick={() => redirectToNewPage('/login')} label='Go back' />
					</Flex>

				</Flex>
			}
		</Flex>
	);

};


function mapDispatchToProps(dispatch) {
	return {
		action: bindActionCreators(
			{
				resetPassword
			},
			dispatch
		)
	};
}

export default withRouter(connect(null, mapDispatchToProps)(PasswordResetForm));