import * as types from '../actions/dsr-actions-types';


// Create & Save new DSR Feed Post Content
const INITIAL_STATE_CREATE_NEW_ERROR_REPORT = {
	isLoading: false,
	results: {},
	error: '',
};

export function createNewErrorReportReducer(state = INITIAL_STATE_CREATE_NEW_ERROR_REPORT, action) {
	
	switch (action.type) {
		case types.CREATE_NEW_ERROR_REPORT_REQUEST:
			return { ...state, isLoading: true, results: {} };
		case types.CREATE_NEW_ERROR_REPORT_SUCCESS:
			return { ...state, isLoading: false, results: action.payload };
		case types.CREATE_NEW_ERROR_REPORT_FAILURE:
			return { ...state, isLoading: false, error: 'Error while trying to create a new error report' };
		default:
			return state;
	}
}

//////
// DSR Auth
// Get DSR Authentication status
const INITIAL_STATE_GET_DSR_AUTH = {
	isLoading: false,
	results: {},
	error: '',
};

export function getDsrAuthReducer(state = INITIAL_STATE_GET_DSR_AUTH, action) {
	
	switch (action.type) {
		case types.GET_DSR_AUTH_REQUEST:
			return { ...state, isLoading: true, results: {} };
		case types.GET_DSR_AUTH_SUCCESS:
			return { ...state, isLoading: false, results: action.payload };
		case types.GET_DSR_AUTH_FAILURE:
			return { ...state, isLoading: false, error: 'Error while trying to get dsr authentication status' };
		default:
			return state;
	}
}


//////
// External Service - LOOM
// Get Loom Token
const INITIAL_STATE_GET_LOOM_TOKEN = {
	isLoading: false,
	results: {},
	error: '',
};

export function getLoomTokenReducer(state = INITIAL_STATE_GET_LOOM_TOKEN, action) {
	switch (action.type) {
		case types.GET_LOOM_TOKEN_REQUEST:
			return { ...state, isLoading: true, results: {} };
		case types.GET_LOOM_TOKEN_SUCCESS:
			return { ...state, isLoading: false, results: action.payload };
		case types.GET_LOOM_TOKEN_FAILURE:
			return { ...state, isLoading: false, error: 'Error while trying to get token' };
		default:
			return state;
	}
}

// Get Loom Video
const INITIAL_STATE_GET_LOOM_VIDEOS = {
	isLoading: false,
	results: {},
	error: '',
};

export function getLoomVideosReducer(state = INITIAL_STATE_GET_LOOM_VIDEOS, action) {
	switch (action.type) {
		case types.GET_LOOM_VIDEOS_REQUEST:
			return { ...state, isLoading: true, results: {} };
		case types.GET_LOOM_VIDEOS_SUCCESS:
			return { ...state, isLoading: false, results: action.payload };
		case types.GET_LOOM_VIDEOS_FAILURE:
			return { ...state, isLoading: false, error: 'Error while trying to get embedded videos' };
		default:
			return state;
	}
}

