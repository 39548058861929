import { SET_IS_MOBILE_VIEW } from "../../global/actions/global-actions-types";

const INITIAL_STATE_SET_IS_MOBILE_VIEW = { isMobileView: '' }

export function setIsMobileViewReducer(state = INITIAL_STATE_SET_IS_MOBILE_VIEW, action) {
    switch (action.type) {
        case SET_IS_MOBILE_VIEW:
            return { isMobileView: action.payload }
        default:
            return state;
    }
}