import { SET_UPLOAD_PROGRESS_METRIC, RESET_UPLOAD_PROGRESS_METRIC } from '../../actions/dsr-actions-types'

const INITIAL_STATE_UPLOAD_FILES = { percentProcessed: 0 }

export function setUploadProgressReducer(state = INITIAL_STATE_UPLOAD_FILES, action) {
  switch (action.type) {
    case RESET_UPLOAD_PROGRESS_METRIC:
      return INITIAL_STATE_UPLOAD_FILES
    case SET_UPLOAD_PROGRESS_METRIC:
      return { percentProcessed: action.payload }
    default:
      return state;
  }
}