import auth from '../../../config/app-specific-config/auth';
import { axiosInstance } from '../../../config/app-specific-config/axiosConfig';
import * as types from './onboarding-actions-types';


// // Launchdeck - Join Waitlist
export const selfOnboardDemoUserSuccess = (response) => {
	return {
		type: types.SELF_ONBOARD_DEMO_USER_SUCCESS,
		payload: response.data
	};
};

export const selfOnboardDemoUserFailure = (error) => {
	return {
		type: types.SELF_ONBOARD_DEMO_USER_FAILURE,
		error
	};
};

export const selfOnboardDemoUserRequest = () => {
	return {
		type: types.SELF_ONBOARD_DEMO_USER_REQUEST
	};
};

export function selfOnboardDemoUser(payload) {
	return async (dispatch) => {
		dispatch(selfOnboardDemoUserRequest());
		try {
			const url = `/onboarding/switch-demo-user`;
			const headers = await auth.getAccessToken();
			const response = await axiosInstance.post(url, payload, {headers});

			if (response.data.data.userType) {
				localStorage.setItem('USER_CATG', response.data.data.userType); 
			}
		
	
			dispatch(selfOnboardDemoUserSuccess(response));
		} catch (error) {
			dispatch(selfOnboardDemoUserFailure(error));
		}
	};
}

// // Launchdeck - Send Contact Message
// export const sendContactLaunchdeckMessageSuccess = (response) => {
// 	return {
// 		type: types.SEND_LAUNCHDECK_MESSAGE_SUCCESS,
// 		payload: response.data
// 	};
// };

// export const sendContactLaunchdeckMessageFailure = (error) => {
// 	return {
// 		type: types.SEND_LAUNCHDECK_MESSAGE_FAILURE,
// 		error
// 	};
// };

// export const sendContactLaunchdeckMessageRequest = () => {
// 	return {
// 		type: types.SEND_LAUNCHDECK_MESSAGE_REQUEST
// 	};
// };

// export function sendContactLaunchdeckMessage(payload) {
// 	return async (dispatch) => {
// 		dispatch(sendContactLaunchdeckMessageRequest());
// 		try {
// 			const url = `/support/contact`;
// 			const header = auth.getContentType();
// 			const response = await axiosInstance.post(url, payload, header);
	
// 			dispatch(sendContactLaunchdeckMessageSuccess(response));
// 		} catch (error) {
// 			dispatch(sendContactLaunchdeckMessageFailure(error));
// 		}
// 	};
// }

// export const sendContactLaunchdeckMessageReset = () => {
// 	return {
// 		type: types.SEND_LAUNCHDECK_MESSAGE_RESET
// 	};
// };


// export const resetSendContactLaunchdeckMessage = () => {
// 	return async (dispatch) => {

// 		try {
// 			dispatch(sendContactLaunchdeckMessageReset());

// 		} catch (error) {
// 			dispatch(sendContactLaunchdeckMessageFailure(error));
// 		}
// 	};
// }