import React, { useEffect, useRef } from 'react';
import { Image, Flex, Box, CircularProgress } from "@chakra-ui/react";
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import ImageUploading from 'react-images-uploading';
import { RiErrorWarningLine } from "react-icons/ri";
import { FaRegImages } from 'react-icons/fa6';
import { useState } from 'react';
import FileUploadProgress from '../../../dsr-create-post/FileUploadProgress';
import config from "../../../../../config/app-config";
import LaunchdeckButton from '../../../../global/components/helper-components/components/LaunchdeckButton';
import { MdOutlineImageNotSupported } from "react-icons/md";

export const UploadedImages = ({
  dsrId,
  subWidgetId,
  setSubWidgetContent,
  subWidgetContent,
  uploadFiles,
  uploadFilesState,
  uploadFilesReset,
  handleSaveItemByClickOutside,
  subWidgetAutoSave
}) => {
  const { isLoading, results, error } = uploadFilesState;
  const { maxImageFileUploadLimit, maxImageFileSize } = config;

  const [images, setImages] = useState(null);
  const containerRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  const [loading, setLoading] = useState(true);
  const [processingSubwidgetId, setProcessingSubwidgetId] = useState("")

  const maxSingleFileSizeInMb = Math.trunc(maxImageFileSize / (1024 * 1024));
  const maxNumber = maxImageFileUploadLimit;
  const maxFileSize = maxImageFileSize;
  const acceptType = ['jpg', 'gif', 'png', 'jpeg'];


  const configs = {
    autoPlay: false,
    infiniteLoop: true,
    showThumbs: false,
    swipeable: true,
    showStatus: false,
    showIndicators: true,
    showArrows: true,
    dynamicHeight: false,
  };

  useEffect(() => {
    if (!isLoading && !error && results.files && results.files[0].subWidgetId === subWidgetId) {
      setSubWidgetContent(results.files)
      setProcessingSubwidgetId("")
      uploadFilesReset();
    };
  }, [results]);

  useEffect(() => {
    //Auto save sub-widget content to local state after uploading
    if (!isLoading && !error && subWidgetContent[0]?.key) {
      subWidgetAutoSave()
      setIsFocused(false)
    }
  }, [subWidgetContent]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if ((isFocused && containerRef.current) && !containerRef.current.contains(event.target)) {
        setIsFocused(false);
        handleSaveItemByClickOutside();
      }
    };

    if (isFocused) {
      setTimeout(() => window.addEventListener('click', handleClickOutside), 1)
    } else {
      window.removeEventListener('click', handleClickOutside);
    }

    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, [isFocused]);

  const onChange = (imageList) => {
    const filesArray = imageList.map((imgData) => {
      return imgData.file
    })
    //Set images for uploading preview
    setImages(imageList);
    setProcessingSubwidgetId(subWidgetId)
    //Upload files to server
    uploadFiles({ dsrId: dsrId, files: filesArray, subWidgetId: subWidgetId });

  };

  const handleImageLoad = () => {
    setLoading(false);
  };

  if (error) {
    // TODO: Make this a reuseable component for other file retrievable
    return <Box border='1px solid' borderColor='gray.100' rounded='0.25em' w='100%' minH='5em'>
      <Flex direction='column' justify='center' align='center' m='1em auto'>
        <Box as={MdOutlineImageNotSupported} boxSize="1.5em" color="brandDark.500"/>
      <Box fontSize='0.8em' color='gray.500' mt='1em' fontStyle='italic'>Uh oh, this image cannot be displayed</Box>
      </Flex>
    </Box>
  }

  return (
    subWidgetContent.length > 1
      ? (
        <Carousel
          {...configs}
        >
          {subWidgetContent?.map((image, index) => (
            <div key={index} style={{ position: 'relative' }} className="carousel-container">
              <img
                src={image['url']} alt={""}
                className="carousel-image"
              />
            </div>
          ))}
        </Carousel>
      )
      : subWidgetContent
        ?
        <Box position="relative" minH={loading && '18em'} border='1px solid' borderColor='gray.100' rounded='0.25em'>
          {loading && (
            <CircularProgress
              isIndeterminate
              color="brand.500"
              thickness='0.3em'
              size='1.5em'
              position="absolute"
              top="50%"
              left="50%"
              transform="translate(-50%, -50%)"
            />
          )}
          <Image
            onLoad={handleImageLoad}
            src={subWidgetContent[0].url}
            alt="Sub-widget image"
            width="100%"
            rounded='0.25em'
            display={loading ? 'none' : 'block'}
          />
        </Box>
        :
        (
          <Flex ref={containerRef} flexDirection="column" justifyContent="center" alignItems="center"
            w='100%' mx='1em'>
            {(!isLoading || (processingSubwidgetId !== subWidgetId)) &&
              <ImageUploading
                multiple
                maxFileSize={maxFileSize}
                value={images}
                onChange={onChange}
                acceptType={acceptType}
                maxNumber={maxNumber}
                dataURLKey="data_url"
              >
                {({
                  imageList,
                  onImageUpload,
                  errors,
                  isDragging,
                  dragProps,
                }) => (
                  <div className="upload__image-wrapper">
                    {imageList.length === 0
                      ? <button
                        className="drop-area"
                        style={isDragging ? { color: 'red' } : undefined}
                        onClick={onImageUpload}
                        {...dragProps}
                      >

                        <Flex ref={containerRef} justifyContent="center" alignItems="center" direction="column" p='5px' m='1em'>
                          <Box><Box fill='brandDark.500' as={FaRegImages} width="3em" height="3em" my="2em" /></Box>
                          <LaunchdeckButton bg='brandDark.500' color='white' px='2em' label='Select image(s)' />
                        </Flex>

                        <Flex direction='column' my='1.5em' fontSize='0.8em' color='brandDark.500' justify='center' align='center'>
                          <Box>
                            Support file formats: JPEG, PNG, and GIF
                          </Box>
                          <Box mt='0.2em'>
                            Max image size: {maxSingleFileSizeInMb}MB each
                          </Box>
                        </Flex>

                      </button>
                      : imageList.length > 0
                      && <div>
                        <Carousel
                          {...configs}
                        >
                          {imageList.map((image, index) => (
                            <div key={index} style={{ position: 'relative' }} className="carousel-container">
                              <img
                                src={image['data_url']} alt={""}
                                className="carousel-image"
                              />
                            </div>
                          ))}
                        </Carousel>
                      </div>
                    }
                    {errors &&
                      <div className='error-list'>
                        {errors.maxNumber ? <><RiErrorWarningLine color="brandPink.500" boxSize={20}  fontSize='0.875em'/> &nbsp; Only 5 images can be uploaded at any one time </> : errors.maxFileSize ? <><RiErrorWarningLine
                            color="red" boxSize={20} /> &nbsp; Upload limit is 20 MB per image</> : ""}
                      </div>
                    }
                  </div>
                )}
              </ImageUploading>
            }
            {isLoading && (processingSubwidgetId === subWidgetId) &&
              <Flex w='100%' py='2em' justify='center'>
                <FileUploadProgress showLoader={isLoading} />
              </Flex>
            }
          </Flex>
        )
  );
};

