import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
  changeSelectSubpage,
  fetchInitialDsrData,
  getDsrFeedContent,
  resetDsrFeedContent,
  resetDsrPages,
  resetDsrSelectedDisplaySubpage,
  resetDsrTitle,
  updateDsrReset,
} from '../actions/dsr-actions'
import {logGuestDsrAnalytics} from '../../analytics/actions/dsr-analytics-actions'
import DsrDisplayPage from './DsrDisplayPage'
import { bindActionCreators } from 'redux'

const DsrDisplayPageContainer = (props) => {
  return (
    <DsrDisplayPage {...props} />
  )
}

const mapStateToProps = (state) => ({
  dsrConfiguration: state.dsrConfiguration.results.data,
  dsrFeedContent: state.dsrFeedContent.results,
  dsrInvalidRequestRedux: state.dsrInvalidRequest.request,
  selectedDisplaySubpage: state.dsrPage.selectedDisplaySubpage,
  currentFeedContentDsrId: state.dsrFeedContent.id, // Rename property name from 'currentDsrId' to 'currentFeedContentDsrId'
  dsrTeamMembersRedux: state.dsrTeamMembers.members,
  userProfileRedux: state.userProfile.profile,
  dsrTitleRedux: state.dsrTitle.title,
  dsrStatusRedux: state.dsrStatus.status,
  dsrUserTeam: state.dsrUserTeam.userTeam
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getDsrFeedContent,
      changeSelectSubpage,
      fetchInitialDsrData,
      resetDsrFeedContent,
      resetDsrTitle,
      resetDsrSelectedDisplaySubpage,
      resetDsrPages,
      updateDsrReset,
      logGuestDsrAnalytics
    },
    dispatch,
  )

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DsrDisplayPageContainer))
