import React, { memo, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'

import { Box, Flex, useToast } from '@chakra-ui/react'

import { updateDsr, resetUpdateDsrOutcome } from '../../digital-sales-room/actions/dsr-actions';
import InputTextAreaExpandable from '../../forms/TextareaField/components/InputTextAreaExpandable'
import LaunchdeckButton from '../../global/components/helper-components/components/LaunchdeckButton'
import { triggerLaunchdeckToast } from '../../global/helpers/global-helpers'

function DsrTeamNotesPage({
  // Parent component
  dsrId,
  dsrProperties,
  // Redux actions
  updateDsr,
  resetUpdateDsrOutcome,
  // Redux state
  isMobileView,
  updateDsrOutcome
}) {


  const toast = useToast();
  const [latestDsrProperties, setLatestDsrProperties] = useState(dsrProperties)

  const { dsrUserNotes } = latestDsrProperties || {};
  const { noteContent } = dsrUserNotes || {}

  useEffect(() => {
    if (updateDsrOutcome) {
      const { updateDsrStatus, updatedDsrId, sourceEvent } = updateDsrOutcome || {};
      if (sourceEvent === "save-room-properties") {
        if (updateDsrStatus && updatedDsrId === dsrId && dsrId !== undefined) {
          toast(
            triggerLaunchdeckToast({
            useCase: "show-success-state",
            label: "Team notes successfully updated", 
            isMobileView
            })
          )
          
        } else if (!updateDsrStatus) {
          toast(
            triggerLaunchdeckToast({
            useCase: "show-error-state",
            label: "Unfortunately something went wrong. Please try again later.", 
            isMobileView
            })
          )
          
        }

        resetUpdateDsrOutcome()
      }
    }
  }, [updateDsrOutcome, toast, dsrId, resetUpdateDsrOutcome]);

  const onChangeHandler = event => {
    switch (event.target.name) {
      case ('dsrNotes'): {
        setLatestDsrProperties({
          ...latestDsrProperties,
          dsrUserNotes: {
            ...dsrUserNotes,
            noteContent: event.target.value,
            noteEditedAt: new Date()
          }
        })
        break;
      }
      default: {
        break;
      }
    }
  };

  const handleSaveDsrProperties = () => {
    if (dsrId) {
      updateDsr({
        dsrId: dsrId,
        dsrProperties: latestDsrProperties,
        sourceEvent: "save-room-properties"
      })
    }
  }

  return (
    <Box>
      <Flex justify='space-between' align='center'>
        <Box>
          <Box color='brandDark.500' fontSize={['1.2em','1.2em','1.5em','1.5em']} lineHeight='1.4em' fontWeight='600'>Team Notes</Box>
          <Box color='brandDark.500' fontSize={['0.75em','0.75em','0.875em','0.875em']} fontWeight='400'>
            Share private team notes to coordinate & collaborate with other Room Administrators
          </Box>
        </Box>
      </Flex>

      <Box w='100%' mt='2em'>
        <InputTextAreaExpandable
          name={'dsrNotes'}
          onChangeHandler={onChangeHandler}
          inputValue={noteContent}
          placeholder={"Share team admin notes here"}
          backgroundColor='white'
        />
      </Box>


      <Flex w='100%' justify='flex-end' mt='2em'>
        <LaunchdeckButton bg='brand.500' color='white' onClick={() => handleSaveDsrProperties()} label='Save' />
      </Flex>

    </Box>

  )

}

const mapStateToProps = (state) => {
  return {
    updateDsrOutcome: state.updateDsrOutcome.results.data,
  	isMobileView: state.mobileViewMode.isMobileView
  }
}


const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateDsr,
      resetUpdateDsrOutcome
    },
    dispatch,
  )

export default memo(withRouter(connect(mapStateToProps, mapDispatchToProps)(DsrTeamNotesPage)))
