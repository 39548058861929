import React, { useEffect } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux';
import { useHistory } from 'react-router-dom';

import { Flex, Box, Image } from '@chakra-ui/react';
import PublicUserHeader from './PublicUserHeader';
import PublicPageLayout from '../../layout/components/layout/components/PublicPageLayout';
import { genesisSetup, resetGenesisSetup } from '../actions/genesis-setup-actions';
import { useState } from 'react';
import SetGenesisPasswordForm from './SetGenesisPasswordForm';
import { useRef } from 'react';
import { displayEmoji } from '../../digital-sales-room/helpers/dsr-helpers';
import PageLoader from '../../global/components/helper-components/components/PageLoader';
import LaunchdeckButton from '../../global/components/helper-components/components/LaunchdeckButton';
import SetupProfileGenesisForm from './SetupProfileGenesisForm';
import auth from '../../../config/app-specific-config/auth';
import { createNewErrorReport } from '../../digital-sales-room/actions/dsr-actions';
import { redirectToLandingPage } from '../../global/helpers/global-helpers';
import InvalidImage from '../../auth/styles/images/invalid.svg';

function GenesisUserWelcomePage({ match, 
	createErrorReportOutcome,
	genesisSetupOutcome, 
	genesisSetup, resetGenesisSetup, createNewErrorReport }) {

	const history = useHistory();
	
	const [token, setToken] = useState("")
	const [userInfoLocalState, setUserInfoLocalState] = useState("")
	const [orgInfoLocalState, setOrgInfoLocalState] = useState("")

	const [displayState, setDisplayState] = useState("initializing")

	const [errorReportSuccess, setErrorReportSuccess] = useState(false)
	const [outcomeMessageDisplay, setOutcomeMessage] = useState(false)
	const [errorReportMetadata, setErrorReportMetadata] = useState("")

	const initRef = useRef(false);

	useEffect(() => {
		if (!initRef.current) {

			const { verifyToken } = match.params || {};
			setToken(verifyToken)
			genesisSetup({ useCase: 'get-credentials', verifyToken })

			initRef.current = true;
		}

	}, []);

	useEffect(() => {
		if (genesisSetupOutcome) {
			switch (genesisSetupOutcome.useCaseProcessed) {
				case ("get-credentials"): {
					const { foundUser, nextUseCase, errorReportMetadata } = genesisSetupOutcome || {}
		
					if (nextUseCase === "invalid-request") {
						setErrorReportMetadata(errorReportMetadata)
					} else {
						foundUser && setUserInfoLocalState(foundUser)
					}
				
					setDisplayState(nextUseCase)
					break;
				}
				case ("set-genesis-password"): {
					const { foundUserOrganization, nextUseCase, errorReportMetadata } = genesisSetupOutcome || {}

					setErrorReportMetadata(errorReportMetadata)
					foundUserOrganization && setOrgInfoLocalState(foundUserOrganization)
					
					setDisplayState(nextUseCase)
					break;
				}
				case ("setup-user-and-org-info"): {
					const { nextUseCase, errorReportMetadata } = genesisSetupOutcome || {}

					setErrorReportMetadata(errorReportMetadata)
					if (auth.isLoggedIn() !== null && nextUseCase==="enter-vault") {
						history.push(`/home`, { 
							showGenesisOnboarding: true
						})  
					}
					break; 
				}
				default: {
					setErrorReportMetadata("")
					break;
				}
			}
			
			resetGenesisSetup()
		}

	}, [genesisSetupOutcome]);
	
	useEffect(() => {
    if (createErrorReportOutcome) {
    const {createErrorReportSuccess, outcomeMessage} = createErrorReportOutcome || {};

    setOutcomeMessage(outcomeMessage)
    setErrorReportSuccess(createErrorReportSuccess)
    }
   }
   , [createErrorReportOutcome]);

	// User action functions
	const handleReportIssue = () => {
		
		createNewErrorReport({
			reportFormat:"slack-notification-public-user",
			errorCode: "RHES79",
			errorMessage: "Genesis user is requesting for help with its experience in onboarding page",
			internalNotes: "Public user; no id token. First verify whether this is an unauthorised access based on business logic",
			errorReportMetadata
		})
	}

	// Render functions
	const renderPageContent = () => {

		if (errorReportSuccess===true) {
      return (
        <Box w="100%" my="2em" px='1em' align="center" color='brandDark.500'>
          <Box textAlign='center'>{outcomeMessageDisplay ? outcomeMessageDisplay : "We're on it and will get back to you soon"}</Box>
					<Flex direction='column' mt='3em' justify='center' alignItems='center'>
						<Flex justify='center' w='100%'>
						<LaunchdeckButton label='Back' fontSize='0.9em' py='0.8em' px='3em' bg='brand.500' color='white' onClick={()=>redirectToLandingPage()} />
         </Flex>
					</Flex>
    
        </Box>
      )
    }

		switch (displayState) {
			case ("initializing"): {
				return (
					<PageLoader />
				)
			}
			case ("set-genesis-password"): {
				const {firstName} = userInfoLocalState || {}
				return (
					<>
						<Box  textAlign='center'>
						{firstName && <Box fontSize='1.4em' fontWeight='600'>Hey {firstName} </Box>}
							<Box color='brandDark.500' fontSize='1.2em'>Welcome to Launchdeck 👋</Box>
						
						</Box>
						<Box fontSize='0.875em' color='gray.700' textAlign='center' my='1.2em'> We'll help you set up in 2 quick steps. First, a secure password</Box>


						<SetGenesisPasswordForm
							resetPasswordToken={token}
							handleSetGenesisPassword={genesisSetup} />
					</>
				)
			}
			case ("invalid-request"): {
				return (
					<>
					<Box mb='0.25em' textAlign='center' minH='45vh' h='100%'>
						<Flex mb='1em' justify='center' align='center'>
							<Box fontSize={['1.5em','1.5em','1.2em','1.2em']} color='brandDark.500' >Expired or invalid link </Box>
						</Flex>
						<Flex justify='center' align='center'>
						<Image src={InvalidImage} boxSize='8em' />
						</Flex>
						<Box fontSize='0.875em' color='gray.700' mt='2em'> Uh-oh, if you think this was unexpected, let us know about it</Box>
						<Flex justify='center' alignItems='center' mt='1em'>
					<Flex justify={['center', 'center', 'center', 'center']} w='100%'>
						<LaunchdeckButton bg='brandDark.500' color='white' px='3em' fontSize='1em' onClick={() => handleReportIssue()} label='Report issue' />
					</Flex>
				</Flex>
					</Box>
					</>
				)
			}
			case ("setup-user-and-org-info"): {
				return (
					<>
						<Box mb='0.25em' textAlign='center'>
							<Flex fontSize='1.5em' mb='0.25em' justify='center' align='center'>
								<Box mr='0.5em' fontSize='1.5em' color='brandDark.500' >Nicely done </Box>
								{displayEmoji('celebrate')}
							</Flex>
							<Box fontSize='0.875em' color='gray.700' > Finally, make your audiences comfortable engaging with your team by letting them know who you are</Box>
						</Box>

						<Box fontSize='0.875em' color='gray.700' textAlign='center' w={['100%','100%','80%','80%']} maxW='50em'>

							<Box mt='1em'>
								<SetupProfileGenesisForm
									userProfile={userInfoLocalState}
									orgProfile={orgInfoLocalState}
									handleSetupGenesisProfile={genesisSetup}
								/>
							</Box>
						</Box>

					</>
				)
			}
			default: {
				break;
			}
		}
	}

	return (
		<PublicPageLayout>
			<PublicUserHeader />
			<Flex direction='column' className='access-page-background'>
				<Flex direction='row' flexGrow='1'>
					<Flex w='100%' direction='column' alignItems='center' bg='white' rounded='0.8em'>
						<Flex w='100%' className="access-page-container" minHeight='100vh' justify='center' alignItems='center'>
							<Flex w='100%' justify='center'>
								<Flex minW='50%' maxW='80%' direction='column' justify='center' align='center' p='1.5em' bg='white' border='1px solid' borderColor='gray.300' rounded='0.25em'>
									{renderPageContent()}
								</Flex>
							</Flex>
						</Flex>
					</Flex>
				</Flex>
			</Flex>
		</PublicPageLayout>
	);

}



const mapStateToProps = (state) => ({
	genesisSetupOutcome: state.genesisSetupOutcome.results.data,
	// After sending error report
	createErrorReportOutcome: state.createErrorReportOutcome.results.data,
})


const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			genesisSetup,
			resetGenesisSetup,
			createNewErrorReport
		},
		dispatch,
	)


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GenesisUserWelcomePage));
