import React from 'react'
import { Box, Button, Flex, Link} from "@chakra-ui/react";
import { IoDocumentText } from "react-icons/io5";
// import { BsFileEarmarkSlidesFill } from "react-icons/bs";
// import { SiGooglesheets } from "react-icons/si";

const PublishedDsrEmbedDocumentDisplay = ({ subWidgetContent }) => {
  const ctaText = subWidgetContent ? subWidgetContent.split('/?&/')[0] : "";
  const ctaLink = subWidgetContent ? subWidgetContent.split('/?&/')[1] : "";

  return (
    <Flex flexDirection='column' justifyContent='center' alignItems='center'>
      <Link color="white" href={ctaLink} isExternal>
        <Button boxSize="lg" bg='brandDark.500' color='white' height="50px" fontSize='0.875em' rounded='0.85em'>
          {ctaText}
          <Box as={IoDocumentText} fill="#fff" width="25px" height="25px" pl="5px"/>
        </Button>
      </Link>
    </Flex>
  )
};

/*const PublishedDsrEmbedDocumentDisplay = ({ subWidgetContent }) => {
  const placeholder = <Flex flexDirection="column">
    <Flex justifyContent="center" alignItems="center" pb="10px">
      <Box as={IoDocumentText} fill="#36a8f3" width="65px" height="65px" pr="10px"/>
      <Box as={BsFileEarmarkSlidesFill} fill="#ffb959" width="60px" height="60px" pr="10px" />
      <Box as={SiGooglesheets} fill="#29a75f" width="50px" height="50px"/>
    </Flex>
    <Box>
      Embed google document. Add document <Link target="_blank" color="#0000ff" href="https://support.google.com/docs/answer/183965?hl=en&co=GENIE.Platform%3DDesktop">URL</Link>
    </Box>
  </Flex>;

  const switchIframeBasedOnSource = () => {
    //Presentation
    const isPresentation = subWidgetContent.includes("/presentation/d/e");
    //Document
    const isDocument = subWidgetContent.includes('/document/d/e/');
    //Spreadsheets
    const isSpreadsheets = subWidgetContent.includes('/spreadsheets/d/e/');

    if (isPresentation) {
      return (
        <Flex w='100%' height='100%' direction='column' overflow='hidden' pt="15px" pb="15px">
          <iframe
            className="loader-img"
            loading="lazy"
            title="Embed presentation"
            src={subWidgetContent.includes("/pub?") ? subWidgetContent.replace("/pub?", "/embed?") : subWidgetContent}
            frameBorder="0"
            width="960"
            height="569"
            allowFullScreen={true}
            mozallowfullscreen="true"
            webkitallowfullscreen="true"
          >
            Text
          </iframe>
        </Flex>
      );
    }

    if (isDocument || isSpreadsheets) {
      return (
        <Flex w='100%' height='100%' direction='column' overflow='hidden' pt="15px" pb="15px">
          <iframe className="iframe-loader" title="Embed document" src={subWidgetContent} frameBorder="0" height="1000"></iframe>
        </Flex>

      )
    }

    return (
      <Box>
        Embed document have incorrect URL <Link target="_blank" color="#0000ff" href="https://support.google.com/docs/answer/183965?hl=en&co=GENIE.Platform%3DDesktop">here</Link> you can see hove to take correct link
      </Box>
    )

  };

  return (
    subWidgetContent
      ? switchIframeBasedOnSource()
      : placeholder
  )
};*/

export default PublishedDsrEmbedDocumentDisplay;
