import React from 'react';

import { NavHashLink as NavLink } from 'react-router-hash-link';
import { Box, Flex, Text, Stack } from "@chakra-ui/react";

import { MdOutlineWidgets, MdOutlineMoveToInbox } from 'react-icons/md';
import { BiTargetLock } from "react-icons/bi";
import { getUserAccountType } from '../../../../profile/helpers/user-helper';


function NavigationLink({ title, linkPath, ...rest }) {
	
	const renderNavIcon = (linkPath) => {
		switch (linkPath) {
			case ('/home'):
			case ('/vault'): {
				return MdOutlineWidgets
			}
			case ('/prospectors'): {
				return BiTargetLock
			}
			case ('/received-rooms'): {
				return MdOutlineMoveToInbox
			}
			default: {
				return null
			}
		}
	}

	return (
		<NavLink to={linkPath}>
			<Flex color="gray.500" {...rest} >
				<Box as={renderNavIcon(linkPath)} boxSize='1.5em' />

				<Text fontSize="1em" fontWeight='400' lineHeight='1.5em' width='max-content' ml="0.5em" textTransform='capitalize'>{title}</Text>

			</Flex>
		</NavLink>
	);
}

export default function DashboardNavigationMenu() {

	const userType = getUserAccountType();
	const isNoOrgUser = (userType==="no-org-user") ? true : false;

	if (isNoOrgUser) {
		return (
			<Stack direction='row' spacing={12} pl='1em'>
				<NavigationLink title="Received Rooms" linkPath="/received-rooms" />
			</Stack>
		);
	} else {
		return (
			<Stack direction='row' spacing={12} pl='1em'>
				<NavigationLink title="Rooms" linkPath="/vault" />

				<Box ml='2em'>
					<NavigationLink title="Prospectors" linkPath="/prospectors" /> 
				</Box>

				<Box ml='2em'>
					<NavigationLink title="Received Rooms" linkPath="/received-rooms" />
				</Box>
			</Stack>
		);
	}
	

}
