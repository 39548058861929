import React, { createContext, useContext, useEffect, useRef, useState } from 'react'
import { bindActionCreators } from 'redux'
import { getLoomToken } from '../actions/dsr-actions'
import { connect } from 'react-redux'
import { engineName } from 'react-device-detect'
import { isSupported, setup } from '@loomhq/record-sdk'
import { loomSdkStates } from '../../../config/app-specific-config/constants'

const defaultConfigureBtnFn = (options={}) => console.warn("configureButton is not initialized")

const DEFAULT_SDK_STATE = loomSdkStates.sdkNotLoaded;

const LoomContext = createContext({
  configureButton: null,
  sdkState: DEFAULT_SDK_STATE,
  initialized: false,
  isLoomSupported: false,
});

const LoomProv = ({action,loomToken, children}) => {
  const configureButtonFnRef = useRef(defaultConfigureBtnFn);
  const isAuthorized = !!(localStorage.getItem('JWT_TOKEN_KEY') || null);
  const isMobileView = window.innerWidth < 750;

  const [isLoomSupported, setIsLoomSupported] = useState(false)
  const [sdkState, setSdkState] = useState(DEFAULT_SDK_STATE)
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    if (isAuthorized) {
      action.getLoomToken();
    }
  }, [action])

  useEffect(() => {
    let isMounted = true;

    const init = async () => {
      if (!loomToken?.serverJws) {
        setSdkState(DEFAULT_SDK_STATE);
        setIsInitialized(false);
        setIsLoomSupported(false);
        return;
      }

      if (loomToken?.serverJws && !isMobileView) {
        try {
          const supported = await checkCompatibility();
          if (!isMounted) return;

          setIsLoomSupported(supported);

          if (!supported) {
            setSdkState(loomSdkStates.recordingNotSupported);
            return;
          }

          const { configureButton, status } = await loadRecordSDK({ jws: loomToken.serverJws });
          if (!isMounted) return;

          configureButtonFnRef.current = configureButton;
          setIsInitialized(true);
          setSdkState(loomSdkStates.loaded);
        } catch (error) {
          console.error('Error:', error);
        }
      }
    };

    init().then();

    return () => {
      isMounted = false;
    };
  }, [loomToken, isMobileView]);


  const checkCompatibility = async () => {
    const supportedBrowserEngine = 'Blink'
    const isBrowserEngineSupported = engineName === supportedBrowserEngine

    const { supported, error } = await isSupported()

    return isBrowserEngineSupported && supported && !error;
  }

  const loadRecordSDK = async ({ jws }) => {
    setSdkState(loomSdkStates.loading)
    return await setup({
      jws,
    })
  }

  return (
    <LoomContext.Provider
      value={{
        sdkState,
        isLoomSupported,
        initialized: isInitialized,
        configureButton: configureButtonFnRef.current,
      }}
    >
      {children}
    </LoomContext.Provider>
  );
}







export const useLoomContext = () => useContext(LoomContext)


function mapStateToProps(state) {
  return {
    loomToken: state.loomToken.results.data
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    action: bindActionCreators({
      getLoomToken,
    }, dispatch),
  }
}

export const LoomProvider = connect(mapStateToProps, mapDispatchToProps)(LoomProv)