import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';

import { Box, Flex, Text } from "@chakra-ui/react";
import LaunchdeckButton from '../../global/components/helper-components/components/LaunchdeckButton';
import FormTextInput from '../../forms/TextInputField/components/FormTextInput';
import '../styles/dsr-display.scss';

import { updateDsrAction } from '../actions/dsr-actions';
import PageLoader from '../../global/components/helper-components/components/PageLoader';
import InputTextAreaExpandable from '../../forms/TextareaField/components/InputTextAreaExpandable';


function EditCategoryForm(props) {
  const { dsrId, mapCategoryId, categoryToEdit, handleCloseModal, createNewDsrActionOutcome } = props || {};
  const {mapCategoryName, mapCategoryDescription} = categoryToEdit || {}; 
  const titleCharacterLimit = 100;

  // Input management
  const [categoryInput, setCategoryInput] = useState(mapCategoryName)
  const [mapDescriptionInput, setMapDescriptionInput] = useState(mapCategoryDescription)

  // Validation for required input
  const [showNoCategoryError, setShowNoCategoryError] = useState(false)

  // UX Management
  const [showLoader, setShowLoader] = useState(false)


  const onChangeHandler = (event) => {
    setShowNoCategoryError(false)

    switch (event.target.name) {
      case ('action-category'): {
        setShowNoCategoryError(false)
        setCategoryInput(event.target.value.trim())
        break;
      }
      case ('action-category-description'): {
        setMapDescriptionInput(event.target.value)
        break;
      }
      default: {
        break;
      }
    }
  };

  const handleCloseCreateActionCategoryModal = () => {
    setCategoryInput("")
    setMapDescriptionInput("")

    handleCloseModal()
  }

  const handleCreateNewAction = () => {

    if (categoryInput.trim().length < 1) {
      setShowNoCategoryError(true)
    } else {
      const updateActionMetadata = {
        dsrId,
        mapCategoryId,
        mapCategoryName: categoryInput,
        mapCategoryDescription: mapDescriptionInput,
        event: "update-category"
      }

      setShowLoader(true)
      props.action.updateDsrAction(updateActionMetadata)
    }
  }

  useEffect(() => {
    if (createNewDsrActionOutcome) {
      setShowLoader(false)
      handleCloseCreateActionCategoryModal()
    }
  }, [createNewDsrActionOutcome]);

  if (showLoader) {
    return (
      <Box w="100%" align="center" >
        <Box px='1em'>
          <Box>
            <PageLoader />
          </Box>
        </Box>
      </Box>
    )
  }

  if (!showLoader) {
    return (
      <Box w="100%" align="center" >
        <Box px='2em'>
          <Box>
            <Flex w='100%' mt='1em'justify='space-between' align='center'>
              <Box fontSize='0.8em'>Category name<Box as='span' color='pink.500'>*</Box></Box>
              <Box fontSize='0.8em' color='gray.500'>{categoryInput.length}/{titleCharacterLimit}</Box>
            </Flex>
            <FormTextInput
              name={'action-category'}
              defaultValue={categoryInput}
              onChange={onChangeHandler}
              charLimit={titleCharacterLimit}
              mb='0.2em'
              borderColor={showNoCategoryError ? 'pink.500' : ""}
            />
            {showNoCategoryError && <Text color='pink.500' fontSize='0.8em'>Provide a name for your Category </Text>}
          </Box>

          <Box mt='2em'>
            <Flex w='100%' justify='space-between' align='center'>
              <Flex justify='flex-start' align='baseline' fontSize='0.8em'>Description<Box fontSize='0.7em' color='gray.500' ml='1em'>OPTIONAL</Box></Flex>

            </Flex>
              <InputTextAreaExpandable
                  name="action-category-description"
                onChangeHandler={onChangeHandler}
                inputValue={mapDescriptionInput}
                // placeholder={`Your thoughts here...`}
                border={'1px solid #E5EAEF'}
              />
          </Box>
        </Box>

        <Flex w='100%' mt='4em' px='2em' py='0.625em' borderTop='1px solid' borderTopColor='gray.300'>
          <Flex justify='space-between' w='100%'>
            <LaunchdeckButton name='closeCreateActionCategoryModal' label='Cancel' borderColor='gray.300' fontSize='0.9em' py='0.8em' onClick={handleCloseCreateActionCategoryModal} />
            <LaunchdeckButton label='Save edit' fontSize="0.9em" bg='brand.500' color='gray.50' onClick={() => handleCreateNewAction()} />
          </Flex>
        </Flex>


      </Box>
    )
  }

}


function mapStateToProps(state) {
  return {
    updateDsrActionOutcome: state.updateDsrActionOutcome.results.data
  };
}

function mapDispatchToProps(dispatch) {
  return {
    action: bindActionCreators(
      {
        updateDsrAction
      },
      dispatch
    )
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditCategoryForm));
