import React, { useEffect, useRef, useState } from 'react';
import { Box, Flex, Avatar, Drawer, DrawerBody, DrawerHeader, DrawerOverlay, DrawerContent, IconButton, useDisclosure } from "@chakra-ui/react";
import LaunchdeckButton from '../../../global/components/helper-components/components/LaunchdeckButton';
import { IoChevronBack } from 'react-icons/io5';
import CreateProspectDsrQueryForm from './CreateProspectDsrQueryForm';
import { getFullName } from '../../../profile/helpers/user-helper';

export default function CreateProspectDsrQueryDrawer({ pDsrId, dsrMainContact }) {
  const bgColorDrawer = '#2D3748';
  const textColorDrawer = 'white';

  const initRef = useRef(false);
  const { isOpen, onOpen, onClose } = useDisclosure();


  const [mainContactInfo, setMainContactInfo] = useState({
    mainContactName: "",
    profilePicture: ""
  });


  useEffect(() => {
    if (!initRef.current && dsrMainContact) {
      const { profilePicture } = dsrMainContact || {};
      const mainContactName = getFullName(dsrMainContact)

      setMainContactInfo({
        mainContactName,
        profilePicture: profilePicture ? profilePicture : "",
      })
      initRef.current = true
    }
  }, [dsrMainContact])

  return (
    <>
      <LaunchdeckButton
        width='100%'
        bg={'brand.500'}
        color={'white'}
        onClick={onOpen}>
        Get in touch
      </LaunchdeckButton>

      <Drawer placement='right' onClose={onClose} isOpen={isOpen} preserveScrollBarGap={true}>
        <DrawerOverlay />
        <DrawerContent backgroundColor='#2D3748' maxW={'30em'}>
          <DrawerHeader fontSize='1em'>
            <Flex direction='row' width='100%' alignItems='center' justify='flex-start' py='1em'>
              <IconButton
                aria-label="Room Property Sidebar"
                fontSize="1em"
                fontWeight='semibold'
                color={textColorDrawer}
                icon={<IoChevronBack />}
                onClick={onClose}
                bg={bgColorDrawer}
                ml='1em'
                _hover={{color:'brandDark.500', bg:'whiteAlpha.500'}}
              />
              <Box ml='1em' color={textColorDrawer}> Get in touch </Box>
            </Flex>

            <Box px='1em' mt='1.5em'>

              <Box fontSize='0.875em' fontWeight='300' color={textColorDrawer}>
                Hi there! Glad you're reaching out. Drop me a message here so I can get in touch with you as soon as I can.
              </Box>

              <Flex justify='flex-start' align='center' mt='1em'>
                {mainContactInfo.profilePicture &&
                  <Avatar h='3.5em' w='3.5em' bg='gray.100' color='gray.700' src={mainContactInfo.profilePicture} />
                }

                <Box className='handwriting-font' color='white' fontSize='1.1em' ml='1em'>
                  {mainContactInfo.mainContactName}
                </Box>
              </Flex>

            </Box>
          </DrawerHeader>
          <DrawerBody>
            <Flex direction='column' justify='space-between' height='90vh'>
              <Box mt='2em'>
                <CreateProspectDsrQueryForm
                  pDsrId={pDsrId}
                  mainContactName={mainContactInfo.mainContactName}
                  handleCloseDrawer={onClose}
                />

              </Box>
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};