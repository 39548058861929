import React from 'react';
import { Flex } from '@chakra-ui/react';
import '../styles/radio.scss'; 

function FormRadioInput({name, value, label, selectedOption, fontSize, onChangeHandler}) {
  return (
    <Flex className="radio-label" fontSize={fontSize}>
      <input 
        type="radio" 
        name={name} 
        value={value} 
        className="radio-input" 
        checked={selectedOption === name}
        onChange={onChangeHandler}
        />
      <Flex ml='1em'>{label}</Flex>
    </Flex>
  );
}

export default FormRadioInput;
