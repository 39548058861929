import auth from '../../../config/app-specific-config/auth';
import * as types from './admin-user-action-types';
import { axiosInstance } from '../../../config/app-specific-config/axiosConfig';
import { displayModeCheck } from '../../global/helpers/global-helpers';
import { setIsMobileView } from '../../global/actions/global-actions';



// New LD actions
// Getting Organization Profile info to show Organization page in Administrator view
export const getAdminOrgProfileSuccess = (response) => {
	return {
		type: types.GET_ADMIN_ORG_PROFILE_SUCCESS,
		payload: response.data
	};
};

export const getAdminOrgProfileFailure = (error) => {
	return {
		type: types.GET_ADMIN_ORG_PROFILE_FAILURE,
		error
	};
};

export const getAdminOrgProfileRequest = () => {
	return {
		type: types.GET_ADMIN_ORG_PROFILE_REQUEST
	};
};

export function getAdminOrgProfile() {
	
	return async (dispatch) => {
		dispatch(getAdminOrgProfileRequest());
		try {
			const url = `/organizations/admin/get-profile`;
			const headers = await auth.getAccessToken();
			const response = await axiosInstance.get(url, {
				headers
			});
			dispatch(getAdminOrgProfileSuccess(response));

			// Mobile View setting
			const isMobileView = displayModeCheck();
			dispatch(setIsMobileView(isMobileView))

		} catch (error) {
			dispatch(getAdminOrgProfileFailure(error));
		}
	};
}

// Org Users CRUD

/////Create User profile
export const createOrgUserProfileSuccess = (response) => {
	return {
		type: types.CREATE_ORG_USERS_SUCCESS,
		payload: response.data
	};
};

export const createOrgUserProfileFailure = (error) => {
	return {
		type: types.CREATE_ORG_USERS_FAILURE,
		error
	};
};

export const createOrgUserProfileRequest = () => {
	return {
		type: types.CREATE_ORG_USERS_REQUEST
	};
};

export const createOrgUserProfile = (createdUserParams, orgIdToken) => {
	return async (dispatch) => {
		dispatch(createOrgUserProfileRequest());
		try {

			const url = `/organizations/admin/users/create/${orgIdToken}`; 
			const headers = await auth.getAccessToken();
		
			const response = await axiosInstance.post(
				url,
				createdUserParams,
				{ headers }
			);
			dispatch(createOrgUserProfileSuccess(response));
	
		} catch (error) {
			dispatch(createOrgUserProfileFailure(error));
		}
	};
};

// Reset state for updating Org User Profile
export const createOrgUserProfileOutcomeReset = () => {
	return {
		type: types.CREATE_ORG_USERS_RESET
	};
};

export function createOrgUserProfileReset() {

	return async (dispatch) => {
	
		try {
			dispatch(createOrgUserProfileOutcomeReset());

		} catch (error) {
			dispatch(createOrgUserProfileFailure(error));
		}
	};
}


/////Edit User profile
export const updateOrgUserSuccess = (response) => {
	return {
		type: types.UPDATE_ORG_USERS_SUCCESS,
		payload: response.data
	};
};

export const updateOrgUserFailure = (error) => {
	return {
		type: types.UPDATE_ORG_USERS_FAILURE,
		error
	};
};

export const updateOrgUserRequest = () => {
	return {
		type: types.UPDATE_ORG_USERS_REQUEST
	};
};

export const updateOrgUsers = (updatedUserParams, orgIdToken) => {
	return async (dispatch) => {
		dispatch(updateOrgUserRequest());
		try {
			const url = `/organizations/admin/users/edit/${orgIdToken}`; 
			const headers = await auth.getAccessToken();
		
			const response = await axiosInstance.put(
				url,
				updatedUserParams,
				{ headers }
			);

			dispatch(updateOrgUserSuccess(response));
	
		} catch (error) {
			dispatch(updateOrgUserFailure(error));
		}
	};
};

// Reset state for updating Org User Profile
export const updateOrgUserOutcomeReset = () => {
	return {
		type: types.UPDATE_ORG_USERS_RESET
	};
};

export function updateOrgUserReset() {

	return async (dispatch) => {
	
		try {
			dispatch(updateOrgUserOutcomeReset());

		} catch (error) {
			dispatch(updateOrgUserFailure(error));
		}
	};
}



export const reactivateUserSetupLinkSuccess = (response) => {
	return {
		type: types.RESET_PENDING_USER_ACTIVATION_LINK_SUCCESS,
		payload: response.data
	};
};

export const reactivateUserSetupLinkFailure = (error) => {
	return {
		type: types.RESET_PENDING_USER_ACTIVATION_LINK_FAILURE,
		error
	};
};

export const reactivateUserSetupLinkRequest = () => {
	return {
		type: types.RESET_PENDING_USER_ACTIVATION_LINK_REQUEST
	};
};

export const reactivateUserSetupLink = (reactivateUserMeta) => {
	return async (dispatch) => {
		dispatch(reactivateUserSetupLinkRequest());
		try {

			const url = `/organizations/admin/users/reactivate`;
			const headers = await auth.getAccessToken();
		
			const response = await axiosInstance.post(
				url,
				{reactivateUserMeta},
				{ headers }
			);
			dispatch(reactivateUserSetupLinkSuccess(response));
	
		} catch (error) {
			dispatch(reactivateUserSetupLinkFailure(error));
		}
	};
};

// Reset state for updating Org User Profile
export const reactivateUserSetupLinkReset = () => {
	return {
		type: types.RESET_PENDING_USER_ACTIVATION_LINK_RESET
	};
};

export function resetReactivateUserSetupLink() {

	return async (dispatch) => {
	
		try {
			dispatch(reactivateUserSetupLinkReset());

		} catch (error) {
			dispatch(reactivateUserSetupLinkFailure(error));
		}
	};
}