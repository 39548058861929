import React from "react";

import { Box, Fade, Flex, Link, useDisclosure } from "@chakra-ui/react";
import { FaRegFilePdf } from "react-icons/fa6";
import { FiExternalLink } from "react-icons/fi";

const PublishedDsrPdfDocumentDisplay = ({ subWidgetContent }) => {
  const fileUrl = subWidgetContent ? subWidgetContent[0].url : '';
  const regex = /\/([A-Za-z0-9%\- ]+)\.pdf$/;

  const { isOpen, onOpen, onClose } = useDisclosure();

  const match = fileUrl.match(regex);
  const documentName = match ? decodeURIComponent(match[1]) : "PDF document";

  const pdfPlaceholder = <Flex direction="column" alignItems="center" justifyContent="center" height="70px">
    <Box as={FaRegFilePdf} width="70px" height="50px" mb="5px" />
    <p>Upload PDF document</p>
  </Flex>;

  return (
    subWidgetContent ?
      <Box width="100%"
        onMouseEnter={onOpen}
        onMouseLeave={onClose}
        position='relative'>
        <Fade in={isOpen}>
          <Flex
            position="absolute"
            top={0}
            left={0}
            width="100%"
            align='center'
            justify='space-between'
            bg='blackAlpha.800'
            color="white"
            textAlign="center"
            p='0.75em 1.5em'
            zIndex={1}
            style={{ transition: "top 3s ease" }}
            roundedTop='0.5em'
          >
            <Box textAlign='left' color='white' fontSize='0.875em' >{documentName}</Box>

            <Link href={fileUrl} lineHeight='1.625em' fontSize='1em' isExternal>
              <FiExternalLink />
            </Link>
          </Flex>
        </Fade>

        <iframe
          className="loader-img"
          title={documentName}
          src={`${subWidgetContent[0].url}#toolbar=0`} // hide pdf preview menu
          width="100%"
          height="750"
        />


      </Box>
      : pdfPlaceholder
  )
};

export default PublishedDsrPdfDocumentDisplay;
