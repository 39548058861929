import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Box, Flex } from "@chakra-ui/react";
import { MdOutlineDragIndicator, MdVisibility, MdVisibilityOff } from 'react-icons/md';
import { LuReplace } from "react-icons/lu";

export const SortableOverviewPageTiles = ({ id, children, tileIsDisplayed, toggleTileDisplay, isEmbeddedLoom, isEmptyState, showReplaceButton, setShowRemoveVideoModal, isFullWidthTile, isMobileView }) => {

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging
  } = useSortable({ id: id });


  const sortableStyle = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? "100" : "auto",
    opacity: isDragging ? 0.3 : 1,
    ...(!!isFullWidthTile && {gridColumn: !isMobileView && "span 2"})
  };

  if (!!isEmbeddedLoom){
    return (
      <Box className="tile-container-loom-video" ref={setNodeRef} style={sortableStyle}>
        <Flex w='100%' gap={2} bg='white' color='black' justify='flex-end' align='center'p='1em 0.5em'>
          {showReplaceButton && <Box as={LuReplace} color={'gray.500'} boxSize="1em" py='0.1em' cursor='pointer' onClick={()=>setShowRemoveVideoModal(true)} />}
          
          <Flex justify='flex-between' align='center'>
            {(!showReplaceButton) ? <Box mr='0.5em' fontSize='0.6em' color='gray.500'>No video • Tile hidden</Box>
              : <Box mr='0.5em' fontSize='0.6em' color='gray.500' visibility={tileIsDisplayed ? "hidden" : "block"}>Tile hidden</Box>
            }
            <Box as={ tileIsDisplayed ? MdVisibility : MdVisibilityOff} color={'gray.500'} boxSize="1em" py='0.1em' cursor={"pointer"} onClick={()=>toggleTileDisplay(id, !tileIsDisplayed)} />
          </Flex>
          <Box as={MdOutlineDragIndicator} {...listeners} {...attributes}  color={'gray.500'} boxSize="1em" py='0.1em' cursor='grab' />
      
        </Flex>

        {children}
      </Box>
    );
  }

  return (
    <Box ref={setNodeRef} style={sortableStyle} position='relative'>
      <Flex gap={2}  position='absolute' top='1em' right='1em' bg='white'>
      <Flex justify='flex-between' align='center'>
        {isEmptyState ? 
           <Box mr='0.5em' fontSize='0.6em' color='gray.500'>
           Tile unused • Tile hidden
         </Box>
         :
        <Box mr='0.5em' fontSize='0.6em' color='gray.500' visibility={tileIsDisplayed ? "hidden" : "block"}>
          Tile hidden
        </Box>
}
        <Box as={tileIsDisplayed? MdVisibility : MdVisibilityOff} color={'gray.500'} boxSize="1em" py='0.1em' cursor='pointer' onClick={()=>toggleTileDisplay(id, !tileIsDisplayed)} />    
    </Flex>
        <Box as={MdOutlineDragIndicator} {...listeners} {...attributes}  color={'gray.500'} boxSize="1em" py='0.1em' cursor='grab' />
      </Flex>
      {children}
    </Box>
  );
};