import React, { useRef, useState } from 'react';
import { Box, CloseButton, Flex, Alert, AlertIcon } from "@chakra-ui/react";
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import ImageUploading from 'react-images-uploading';
import './image-uploader.css'
import { IoIosTrash, IoMdImage } from "react-icons/io";
import config from "../../../config/app-config";
import LaunchdeckButton from '../../global/components/helper-components/components/LaunchdeckButton';

// TODO: This component is extremely messy. Refactor when capacity allows.
const DsrPostImagesUpload = ({ getFiles }) => {
  const carouselRef = useRef(null);
  const [images, setImages] = React.useState([]);
  const { maxImageFileUploadLimit, maxImageFileSize } = config;

  const maxSingleFileSizeInMb = Math.trunc(maxImageFileSize / (1024 * 1024));
  const maxNumber = maxImageFileUploadLimit;
  const maxFileSize = maxImageFileSize;
  const acceptType = ['jpg', 'gif', 'png', 'jpeg'];
  const onChange = (imageList, addUpdateIndex) => {
    let allFiles = imageList.map(item => item.file)
    // setImageList(imageList) // TODO: Review whether this is still needed. const imageList is not used 
    getFiles(allFiles, "images")
    setImages(imageList);
  };

  const [files, setFiles] = useState([]);
  const configs = {
    autoPlay: false,
    infiniteLoop: true,
    showThumbs: false,
    swipeable: true,
    showStatus: false,
    showIndicators: true,
    showArrows: true,
    dynamicHeight: false,
  };

  const removeImage = (index) => {
    const newImages = [...files];
    newImages.splice(index, 1);
    setFiles(newImages);
  };

  const customArrowPrev = (onClickHandler, hasPrev, label) => {
    return (
      <button type="button" onClick={onClickHandler} disabled={!hasPrev} className="custom-arrow-prev">
        {"<"}
      </button>
    );
  };

  const customArrowNext = (onClickHandler, hasNext, label) => {
    return (
      <button type="button" onClick={onClickHandler} disabled={!hasNext} className="custom-arrow-next">
        {">"}
      </button>
    );
  };
  return (
    <Box w='100%' mx='1em'>
      <Box px='1em' >
        <ImageUploading
          multiple
          maxFileSize={maxFileSize}
          value={images}
          onChange={onChange}
          acceptType={acceptType}
          maxNumber={maxNumber}
          dataURLKey="data_url"
        >
          {({
            imageList,
            onImageUpload,
            // onImageRemoveAll,
            errors,
            // onImageUpdate,
            onImageRemove,
            isDragging,
            dragProps,
          }) => (

            <Box>
              {imageList.length === 0 ?
                <button
                  className="drop-area"
                  style={isDragging ? { color: 'red' } : undefined}
                  onClick={onImageUpload}
                  {...dragProps}
                >

                  <Flex className="drop-area-title" direction='column' justify='center' align='center' fontSize='1em' textAlign='center'>

                    <Box mb='2em'><Box fill='brandDark.500' as={IoMdImage} width="48px" height="3.5em" /></Box>
                    <LaunchdeckButton bg='brandDark.500' color='white' px='2em' label='Select image(s)' />


                  </Flex>
                  <Flex direction='column' mt='2em' fontSize='0.75em' >

                    <Flex justify='space-around' align='center' gap={16}>
                      <Box>
                        <Box mt='0.75em' color='gray.500' textAlign='center'>
                          Max images
                        </Box>
                        <Box textAlign='center' mt='0.5em' fontWeight='600'>
                          {maxImageFileUploadLimit}
                        </Box>

                      </Box>

                      <Box>
                        <Box mt='0.75em' color='gray.500' textAlign='center'>
                          Max size
                        </Box>
                        <Box textAlign='center' mt='0.5em' fontWeight='600'>
                          {maxSingleFileSizeInMb}MB each
                        </Box>

                      </Box>


                      <Box>

                        <Box mt='0.75em' color='gray.500' textAlign='center'>
                          Formats
                        </Box>
                        <Box textAlign='center' mt='0.5em' fontWeight='600'>
                          JPEG, PNG & GIF
                        </Box>


                      </Box>
                    </Flex>

                    {errors &&
                      <div className='error-list'>

                        {errors.maxNumber ?
                          <Alert px='1em' status="error" variant="solid" bg='brandPink.500' rounded='0.25em' color='white'>
                            <AlertIcon boxSize='1em' />
                            <Box fontSize='0.875em'>  You can upload only {maxImageFileUploadLimit} images per post</Box>
                          </Alert>

                          : errors.maxFileSize ?
                            <Alert px='1em' status="error" variant="solid" bg='brandPink.500' rounded='0.25em' color='white'>
                              <AlertIcon boxSize='1em' />
                              <Box fontSize='0.875em'> Upload limit is {maxSingleFileSizeInMb}MB per image</Box>
                            </Alert>


                            : ""}
                      </div>}
                  </Flex>
                </button>
                :
                imageList.length > 0 &&
                <div>
                  <Carousel
                    ref={carouselRef}
                    {...configs}
                  >
                    {/*<div className="carousel-container">*/}
                    {imageList.map((image, index) => (
                      <div key={index} style={{ position: 'relative' }} className="carousel-container">
                        <img
                          src={image['data_url']} alt={""}
                          className="carousel-image"
                        // style={{height: "400px", backgroundColor: 'black'}}
                        />
                        <div
                          style={{
                            cursor: 'pointer',
                            position: "absolute",
                            top: 3,
                            right: 10,
                            backgroundColor: "grey",
                            color: "white",
                            borderRadius: "50%",
                            width: 25,
                            height: 25,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                          }}
                          onClick={() => {
                            // console.log(carouselRef.current)
                            carouselRef.current.onClickNext();
                            onImageRemove(index);
                          }}
                        >
                          <IoIosTrash size={18} />
                        </div>


                      </div>
                    ))}
                  </Carousel>


                </div>
              }
              {/* {errors &&
                <div className='error-list'>

                  {errors.maxNumber ? <><RiErrorWarningLine color="red" size={20} /> &nbsp; You can
                    upload only {maxImageFileUploadLimit} images per post</> : errors.maxFileSize ? <><RiErrorWarningLine
                      color="red" size={20} /> &nbsp; Upload limit is 2MB per image</> : ""}
                </div>} */}

            </Box>
          )}
        </ImageUploading>

      </Box>
      <Flex justifyContent="center" alignItems="center" marginBottom="20px">
        {files.length > 0 &&
          <Carousel
            {...configs}
            renderArrowPrev={customArrowPrev}
            renderArrowNext={customArrowNext}
          >
            {files.map((image, index) => (
              <div key={index} style={{ position: 'relative' }} >
                <img
                  src={URL.createObjectURL(image)} alt={image.name}
                  style={{ height: "450px" }}
                />
                <CloseButton size="1em" style={{
                  position: "absolute",
                  top: 0,
                  right: 30,
                  backgroundColor: "grey",
                  color: "white"
                }} onClick={() => removeImage(index)}><IoIosTrash /></CloseButton>
              </div>
            ))}
          </Carousel>}
      </Flex>

    </Box>
  );
};

export default DsrPostImagesUpload;
