import EmptyStateStandardDisplay from "../../global/components/helper-components/components/EmptyStateStandardDisplay";
import { TbDeviceTabletSearch } from "react-icons/tb";
import React from "react";

export const EmptyTable = ({ searchTextInput, clearSearch }) => {
  return (
    <>
      <EmptyStateStandardDisplay
        reactIcon={TbDeviceTabletSearch}
        infoText={`No documents found for '${searchTextInput}'`}
        ctaButtonText="Clear search"
        handleCtaButtonClick={clearSearch}
      />
    </>
  );
};
