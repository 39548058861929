import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';


import { Box, Flex, Text, Grid } from '@chakra-ui/react';
import WarningTextMessage from '../../global/components/helper-components/components/WarningTextMessage';
import FormTextInput from '../../forms/TextInputField/components/FormTextInput';
import { validateEmail } from '../../global/helpers/global-helpers';
import LaunchdeckButton from '../../global/components/helper-components/components/LaunchdeckButton';
import { joinWaitlist } from '../../marketing/actions/marketing-actions'
import PageLoader from '../../global/components/helper-components/components/PageLoader';


function JoinWaitlistForm(props) {

	const [emailInput, setEmailInput] = useState("")
	const [nameInput, setNameInput] = useState("")

	const [isInvalidEmail, setIsInvalidEmail] = useState(false)
	const [showLoader, setShowLoader]= useState(false)
	const [showSuccessState, setShowSuccessState]= useState(null)
	const {marketingUserTrackId, joinWaitlistOutcome, prepopEmailDisplay, prepopNameDisplay} = props || {};

	useEffect(() => {
    if (joinWaitlistOutcome) {

			const {waitlistRequestCreated} = joinWaitlistOutcome || {};
      setShowLoader(false)

			if (waitlistRequestCreated === true) {
				setShowSuccessState(true)
				setEmailInput("")
				setNameInput("")
			} else {
				setShowLoader(false)
				setEmailInput("")
				setNameInput("")
			}
    }

  }, [joinWaitlistOutcome]);

	useEffect(() => {
		if (prepopEmailDisplay) {
			setEmailInput(prepopEmailDisplay)
		}
  },[prepopEmailDisplay]);
	

	useEffect(() => {
		if (prepopNameDisplay) {
			setNameInput(prepopNameDisplay)
		}
  },[prepopNameDisplay]);

	const handleChange = (event) => {

		if (event.target.name === "emailInput") {
			setEmailInput(event.target.value)
		} else if (event.target.name === "nameInput") {
			setNameInput(event.target.value)
		}
	}


	const createJoinWaitlistRequest = () => {
		const isValidEmail = validateEmail(emailInput.trim());
		const params = new URLSearchParams(window.location.search);
		const referralCode = params.has('ref') ? params.get('ref') : 'non-referral';

		if (!!isValidEmail) {
			const payload = {
				waitlistUserEmail: emailInput.trim(),
				waitlistUsername: nameInput,
				marketingUserTrackId,
				referralCode
			}

			props.action.joinWaitlist(payload)
			setShowLoader(true)
		} else {
			setIsInvalidEmail(true)
		}
	}


	if (showLoader) {
		return <PageLoader />
	} 
	
	if (!showLoader && showSuccessState) {
		return (
				<Flex p='1em' height='100%' justify='center' align='center'><span role="img" aria-label="party-face">🥳</span> <Box ml='0.5em'>Thanks! You're now on the waitlist and we'll contact you very soon.</Box> </Flex>
		)
	} else {
		return (
			<Grid my='2em' w='100%' templateRows="1fr 1fr auto 1fr" gap='0.5em'>	
					<Box mb='0.5em'>
						<FormTextInput name={'emailInput'} label={'Email'} sublabel={''} htmlFor={'emailInput'} mb='0.2em' value={emailInput && emailInput} onChange={handleChange} />
						<WarningTextMessage errorLogic={isInvalidEmail} message='Please provide a valid email' />
					</Box>
	
					<Box mb='0.5em'>
						<FormTextInput name={'nameInput'} label={'Your name'} sublabel={''} mb='0.2em' htmlFor={'nameInput'} value={nameInput && nameInput}  onChange={handleChange} />
						<Text visibility='hidden' fontSize='0.8em'>	&nbsp;</Text>
					</Box>
	
					<Box />
	
	
					<Flex direction='column' mt='1em' justify='center' alignItems='center'>
						<Flex justify={['center', 'flex-end', 'flex-end', 'flex-end']} w='100%'>
							<LaunchdeckButton bg='brand.500' color='white' onClick={() => createJoinWaitlistRequest()} label='Join waitlist' />
						</Flex>
					</Flex>
			</Grid>
		);
	}
	

};




function mapStateToProps(state) {
  return {
    joinWaitlistOutcome: state.joinWaitlistOutcome.results.data
  };
}


function mapDispatchToProps(dispatch) {
	return {
		action: bindActionCreators(
			{
				joinWaitlist
			},
			dispatch
		)
	};
}


export default connect(mapStateToProps, mapDispatchToProps)(JoinWaitlistForm);
