import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
// import _ from 'lodash';

export const Sortable = ({ id, children, isDraggable }) => {
  const sortable = useSortable({
    id,
    disabled: isDraggable
  });

  const {
    attributes,
    setNodeRef,
    listeners,
    transform,
    transition,
    isDragging
  } = sortable;

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.3 : 1,
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      {children}
    </div>
  );
};