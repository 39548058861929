import React from 'react';
import { createRoot } from 'react-dom/client';

// import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { AxiosInterceptors } from "./config/app-specific-config/axiosConfig"

import * as serviceWorker from './serviceWorker';
import Routes from '../src/Routes';
import { ChakraProvider } from '@chakra-ui/react';
import store from "./config/redux/store";
// import { LoomProvider } from './features/digital-sales-room/dsr-record-content/LoomContext'

// const jwt = localStorage.getItem("JWT");

// const initialState = {
// 	users: {
// 		data: { session: { jwt } }
// 	}
// };

// const StoreInstance = Store(initialState);
const StoreInstance = store;

AxiosInterceptors.setupInterceptors(StoreInstance);

// ReactDOM.render(
createRoot(document.getElementById('root')).render(
	<React.StrictMode>
	<Provider store={StoreInstance}>
		<ChakraProvider>
			<Routes />
		</ChakraProvider>
	</Provider>
	</React.StrictMode>
	// ,
	// document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
