import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';

import { Box, Flex, Image, ChakraBaseProvider } from "@chakra-ui/react";
import claverTheme from '../../layout/components/layout/components/theme/claverTheme';
import { useHistory } from 'react-router-dom';

// Retrieve data
import { getDsrAuth } from '../actions/dsr-actions';
import { accessAuthentication } from '../../auth/actions/access-auth-actions';
import { launchdeckLogin } from '../../auth/actions/auth-actions';

// Helpers
import { manageDsrUrlTags } from '../../global/helpers/url-tag-helpers';

// Components
import PageLoader from '../../global/components/helper-components/components/PageLoader';
import DsrAccessSetPasswordForm from './DsrAccessSetPasswordForm';
import logo from '../../global/styles/images/launchdeck_logo.svg';
import UnauthorisedAccessDisplay from './UnauthorisedAccessDisplay';

// CSS
import './styles/access-page.scss'
import DsrDetermineUidForm from './DsrDetermineUidForm';
import AlphanumericInput from './AlphanumericInput';
import DsrReturningLoginForm from './DsrReturningLoginForm';
import DsrRequestInviteForm from './DsrRequestInviteForm';
import { splitNameInput } from '../../profile/helpers/user-helper';
import LaunchdeckButton from '../../global/components/helper-components/components/LaunchdeckButton';
import { displayModeCheck, getThisUserIdToken, redirectToLandingPage, redirectToNewPage } from '../../global/helpers/global-helpers';
import {setIsMobileView} from '../../global/actions/global-actions';
import { redirectToDsrIfLoggedIn } from '../../auth/helpers/auth-redirect';


function DsrManageAccessPage(props) {

  const { accessAuthOutcome, dsrAuth, isMobileView, setIsMobileView,
    getDsrAuth,
    accessAuthentication,
    launchdeckLogin } = props || {}

  const history = useHistory();
  const initRef = useRef(false);
  const [refInputEmail, setRefInputEmail] = useState("")
  const [refUsername, setRefUsername] = useState("")

  const [userId, setUserId] = useState("")
  const [isActiveDsr, setIsActiveDsr] = useState("")
  const [isAdminDraftDsr, setIsAdminDraftDsr] = useState("")
  const [nextStep, setNextStep] = useState("")

  const [newProfileToken, setNewProfileToken] = useState("")
  const [defaultEmail, setDefaultEmail] = useState("")
  const [redirectPath, setRedirectPath] = useState("")

  // UX Management
  const [isProcessing, setIsProcessing] = useState(false)
  const [processingAccessCode, setProcessingAccessCode] = useState(false)
  const [showAccessCodeError, setShowAccessCodeError] = useState(false)
  const [teamLogos, setTeamLogos] = useState("")

  const [requestSuccessMessage, setRequestSuccessMessage] = useState("")

  const dsrId = props ? props.match.params.dsrId : "";

  // Change to control log in via actions and through component blanket action
  // useEffect(() => {
  //   if (dsrId) {
  //     redirectToDsrIfLoggedIn(dsrId)
  //   }

  // },[dsrId])

  useEffect(() => {
    if (!initRef.current) {
      // Determine whether there is a pre-determined user ID associated with this visit
      const userId = manageDsrUrlTags(window.location.search, 'uid')
      setUserId(userId ? userId : "")

      // Determine whether is mobile view
      const isMobileView = displayModeCheck();
      
      if (isMobileView) {
        setIsMobileView(isMobileView)
      }

   

      if (!userId) {
        // Note: Next to attempt to determine whether it is a currently logged in user
        const loggedInUserIdToken = getThisUserIdToken() 
     
        if (loggedInUserIdToken) {
          getDsrAuth(dsrId, loggedInUserIdToken)
          initRef.current = true
        } else {
          // Note: Confirmation that this user needs to be handled as a public user
          getDsrAuth(dsrId, "") // If no userId, make an API call to backend to see if DSR allows 'anyone' to join & view
          initRef.current = true
        }

      } else {
        if (dsrId) {
          getDsrAuth(dsrId, userId)
          initRef.current = true

        } else if (!dsrId) {
          setNextStep('show-error')
        }
      }
    }
  }, [getDsrAuth, setIsMobileView, dsrId])


  // Business logic management 
  useEffect(() => {
    if (dsrAuth) {
      
      const { isActiveDsr, nextProcess, adminAccessDraftDsr, resetPasswordToken, retrievedEmail, retrievedUsername, displayLogos } = dsrAuth || {}

      setIsActiveDsr(isActiveDsr)
      // setNextStep(nextProcess ? nextProcess : "unexpected-error")
      nextProcess && setNextStep(nextProcess)
      adminAccessDraftDsr && setIsAdminDraftDsr(adminAccessDraftDsr)
      
      switch (nextProcess) {
        case ("confirm-public-dsr-access"): {
          // Redirect user to display public DSR (anyone can join type)
          // To check whether dsr Id is active/anyone can join / no access Code required

          const accessAuthPayload = {
            processRequired: "confirm-public-dsr-access",
            dataPayload: {
              dsrId: dsrId,
              // authToken?
            }
          }

          accessAuthentication(accessAuthPayload)
          break;
        }
        case ("enter-email-to-determine-uid"): {
          // DSR is for DSR members only and require identification by email
          setNextStep("enter-email-to-determine-uid")
          break;
        }
        case ("access-code-challenge"): {

          displayLogos && setTeamLogos(displayLogos)
          break;
        }
        case ("set-password-edit-profile"): {
          setRefInputEmail(retrievedEmail)
          setNewProfileToken(resetPasswordToken)

          break;
        }
        case ("seek-login-or-redirect"): {
          setRefInputEmail(retrievedEmail)
          break;
        }
        case ("request-invite-to-room"): {
          setRefUsername(retrievedUsername)
          setRefInputEmail(retrievedEmail)
          break;
        }
        default: {
          break;
        }

      }

    }
  }, [dsrAuth, accessAuthentication])

  useEffect(() => {
    if (accessAuthOutcome) {
      const { authProcess, nextProcess, authProcessSuccess,
        isActiveDsr, uid, displayLogos, defaultEmail, memberName,
        resetPasswordToken, publicViewsAllowed,
        outcomeMessage, redirectionUri } = accessAuthOutcome || {};

      switch (authProcess) {
        case ("retrieve-uid-by-email"): {
          setUserId(uid)
          setIsProcessing(false)
          nextProcess && setNextStep(nextProcess)

          if (authProcessSuccess === true) {

            if (nextProcess === "display-static-public-dsr") {
              dsrId ?
                history.push(`/dsr/pub/${dsrId}`, {
                  publicViewsAllowed: publicViewsAllowed ? publicViewsAllowed : false,
                  // authProcess,
                  authProcessSuccess
                })
                : setShowAccessCodeError(true)
              break;
            } else if (nextProcess === "request-invite-to-room") {
              memberName && setRefUsername(memberName)
              setRefInputEmail(defaultEmail)
            }
            else {
              setTeamLogos(displayLogos && displayLogos)
              setIsActiveDsr(isActiveDsr)
              setRefInputEmail(defaultEmail)
            }
          }
          break;
        }
        case ("access-code-challenge"): {
          setProcessingAccessCode(false)
          setNewProfileToken(resetPasswordToken)
          setNextStep(nextProcess)
          setRefInputEmail(defaultEmail)

          if (nextProcess === "show-failed-authentication-error") {
            setShowAccessCodeError(true)
            break;
          }
          if (nextProcess === "display-static-public-dsr") {
            dsrId ?
              history.push(`/dsr/pub/${dsrId}`, {
                publicViewsAllowed: publicViewsAllowed ? true : false,
                authProcess,
                authProcessSuccess
              })
              : setShowAccessCodeError(true)
            break;
          }

          else if (nextProcess === "set-password-edit-profile") {
            setDefaultEmail(defaultEmail)
            break;
          }

          break;
        }
        case ("seek-login-or-redirect"): {
          setNextStep(nextProcess)
          break;
        }
        case ("request-new-user-invite"): {

          if (nextProcess === "show-request-sent-to-admin-success") {
            setRequestSuccessMessage(outcomeMessage)
            setNextStep("show-request-sent-to-admin-success")
          }

          if (nextProcess === "show-user-already-invited-confirmation") {
            setRequestSuccessMessage(outcomeMessage)
            setRedirectPath(redirectionUri)
            setNextStep("show-user-already-invited")
          }

          if (nextProcess === "show-authentication-error") {
            setNextStep("show-authentication-error")
          }

          break;
        }
        case ("confirm-public-dsr-access"): {
          if (nextProcess === "display-static-public-dsr") {
            dsrId ?
              history.push(`/dsr/pub/${dsrId}`, {
                publicViewsAllowed: publicViewsAllowed ? true : false,
                authProcess,
                authProcessSuccess
              })
              : setShowAccessCodeError(true)
           
          }
          break;
        }
        case("set-password-edit-profile"): {
          if (nextProcess === "redirect-user-to-dsr") {
            redirectToNewPage(`/dsr/${dsrId}`)
          }

          break;
        }
        default: {
          setNextStep("unexpected-error")
          break;
        }
      }
    }
  }, [accessAuthOutcome])




  const handleRetrieveUid = (emailInput) => {
    const accessAuthPayload = {
      processRequired: "retrieve-uid-by-email",
      dataPayload: {
        dsrId: dsrId,
        email: emailInput
      }
    }
    setRefInputEmail(emailInput)
    setIsProcessing(true)
    accessAuthentication(accessAuthPayload)
  }

  const handleSetPasswordProfile = (newPasswordInput, newProfileToken) => {
    if (dsrId && newPasswordInput && newProfileToken) {
      const accessAuthPayload = {
        processRequired: "set-password-edit-profile",
        dataPayload: {
          dsrId,
          resetPasswordToken: newProfileToken,
          newPasswordInput
        }
      }

      setIsProcessing(true)
      accessAuthentication(accessAuthPayload)
    }
  }

  const handleRequestInvite = (requestUserMessage, requestUserName) => {
    const { firstName, lastName } = splitNameInput(requestUserName)

    const accessAuthPayload = {
      processRequired: "request-new-user-invite",
      dataPayload: {
        dsrId: dsrId,
        requestUserMessage,
        requestUserFirstName: firstName,
        requestUserLastName: lastName,
        requestUserEmail: refInputEmail
      }
    }

    setProcessingAccessCode(true)
    accessAuthentication(accessAuthPayload)
  }

  const handleSubmitAccessCode = (accessCodeInput) => {
    const accessAuthPayload = {
      processRequired: "access-code-challenge",
      dataPayload: {
        dsrId: dsrId,
        accessCodeInput,
        userIdToken: userId
      }
    }

    setProcessingAccessCode(true)
    accessAuthentication(accessAuthPayload)
  }

  const handleSubmitLoginRequest = (emailInput, passwordInput) => {
    launchdeckLogin(emailInput, passwordInput)
  }

  const renderContent = () => {
    
    if (nextStep) {
      if (nextStep === "unexpected-error") {
        return (
          <Box>
            <UnauthorisedAccessDisplay />
          </Box>
        )
      }

      if (nextStep === "show-authentication-error") {
        return <UnauthorisedAccessDisplay />
      }

      if (nextStep === "request-invite-to-room") {
        return (
          <Box bg='white' p='3em' rounded='0.25em' w={['90%','90%','35em','35em']}>
            <Flex w='100%' justify='center'>
              <Image src={logo} alt="Launchdeck Logo" width='50%' minWidth='15em' maxWidth='20em' mb='2em' />
            </Flex>
            <Box minW='15em' maxW='28.5em'>
              <Box textAlign='center' fontSize='1.5em' color='brandDark.500' mb='0.5em'>Request Access</Box>
              <Box textAlign='center' fontSize='0.875em' color='gray.500' fontWeight='400' lineHeight='1.375em' mb='3.5em'>Room owner has limited its access only to specific invited members</Box>

              <DsrRequestInviteForm handleRequestInvite={handleRequestInvite} requestUserEmail={refInputEmail} requestUsername={refUsername}/>
            </Box>
          </Box>
        )
      }

      if (nextStep === "enter-email-to-determine-uid") {
        return (
          <Box bg='white' p='3em' rounded='0.25em' w={['90%','90%','35em','35em']}>
            <Flex w='100%' justify='center'>
              <Image src={logo} alt="Launchdeck Logo" width='50%' minWidth='15em' maxWidth='20em' mb='2em' />
            </Flex>
            <Box minW='15em' maxW='28.5em'>
              <Box textAlign='center' fontSize='1.5em' color='brandDark.500' mb='0.5em'>Email Verification</Box>
              <Box textAlign='center' fontSize='0.875em' color='gray.500' fontWeight='400' lineHeight='1.375em' mb='3.5em'>Room owner has limited its access only to specific invited members</Box>

              <DsrDetermineUidForm handleRetrieveUid={handleRetrieveUid} />
            </Box>
          </Box>
        )
      }

      if (nextStep === "show-request-sent-to-admin-success") {
        return (
          <Box bg='white' p='3em' rounded='0.25em' w={['90%','90%','35em','35em']}>
            <Flex w='100%' justify='center'>
              <Image src={logo} alt="Launchdeck Logo" width='50%' minWidth='15em' maxWidth='20em' mb='2em' />
            </Flex>
            <Box minW='15em' maxW='28.5em'>
              <Box textAlign='center' fontSize='1.5em' color='brandDark.500' mb='0.5em'>Administrators notified</Box>
              <Box textAlign='center' fontSize='0.875em' color='gray.500' fontWeight='400' lineHeight='1.375em' mb='3.5em'>{requestSuccessMessage}</Box>

              <Flex w='100%' justify='center'>
                <LaunchdeckButton label='Send me back' fontSize="0.875em" bg='brand.500' color='gray.50' onClick={() => redirectToLandingPage()} />
              </Flex>
            </Box>
          </Box>
        )
      }

      if (nextStep === "show-user-already-invited") {
        return (
          <Box bg='white' p='3em' rounded='0.25em' w={['90%','90%','35em','35em']}>
            <Flex w='100%' justify='center'>
              <Image src={logo} alt="Launchdeck Logo" width='50%' minWidth='15em' maxWidth='20em' mb='2em' />
            </Flex>
            <Box minW='15em' maxW='28.5em'>
              <Box textAlign='center' fontSize='1.5em' color='brandDark.500' mb='0.5em'>Invite had been sent</Box>
              <Box textAlign='center' fontSize='0.875em' color='gray.500' fontWeight='400' lineHeight='1.375em' mb='3.5em'>{requestSuccessMessage}</Box>

              <Flex w='100%' justify='center'>
                <LaunchdeckButton label='Send me back' fontSize="0.875em" bg='brand.500' color='gray.50' onClick={() => redirectToNewPage(redirectPath ? `/login?redirect=${redirectPath}` : `/login`)} />
              </Flex>
            </Box>
          </Box>
        )

      }

      if (isActiveDsr === true) {
        switch (nextStep) {
          case ('seek-login-or-redirect'): 
          case ('prompt-login'): {
            // If logged in then redirect, else show this login prompt
            const loggedInUserIdToken = getThisUserIdToken();

            if(loggedInUserIdToken) {
              return (
                <Box bg='white' p='3em' rounded='0.25em' w={['90%','90%','35em','35em']}>
                  <Box minW='15em' maxW='28.5em'>
                    { redirectToDsrIfLoggedIn(dsrId)}
                    <PageLoader />
                    <Box textAlign='center'>Working on it...</Box>
                  </Box>
                </Box>
              ) 
            } else {
              return (
                <Box bg='white' p='3em' rounded='0.25em' w={['90%','90%','35em','35em']}>
                  <Box minW='15em' maxW='28.5em'>
                    <Flex w='100%' justify='center'>
                      <Image src={logo} alt="Launchdeck Logo" width='50%' minWidth='15em' maxWidth='20em' mb='2em' />
                    </Flex>
  
  
                    <Box textAlign='center' fontSize='1.5em' color='brandDark.500' mb='0.5em'>Welcome back </Box>
                    <Box textAlign='center' fontSize='0.875em' color='gray.500' fontWeight='400' lineHeight='1.375em' mb='3.5em'>Log in to view all your Rooms  </Box>
  
                    <DsrReturningLoginForm
                      dsrId={dsrId}
                      defaultEmail={refInputEmail}
                      handleSubmitLoginRequest={handleSubmitLoginRequest}
                    />
                  </Box>
  
                </Box>
              )
            }

       
          }
          case ('access-code-challenge'): {
            function renderLogos() {
              const { displayedHostLogo, displayedAudienceLogo } = teamLogos || {}

              // Todo: Make image appear only after they have loaded
              if (displayedHostLogo) {
                return (
                  <Flex w='100%' justify='center' align='center' mb='2em' >
                    <Image src={displayedHostLogo} alt="Host Logo" maxHeight='3em' />
                    {/*                         
                            { hostLogoLoaded ? 
                            <Image src={displayedHostLogo} alt="Host Logo" maxHeight='3em' onLoad={()=> {setHostLogoLoaded(true)}}/>
                    :
                    <Skeleton height='3em' width='6em' />
                } */}
                    {displayedAudienceLogo &&
                      <Flex align='center' >
                        <Flex mx='1em'>&</Flex>
                        <Image src={displayedAudienceLogo} alt="Guest Logo" maxHeight='3em' />
                        {/* { guestLogoLoaded ? 
                        <Image src={displayedAudienceLogo} alt="Guest Logo" maxHeight='3em' onLoad={setGuestLogoLoaded(true)}/>
                        :
                        <Skeleton height='3em' width='6em' />
                        } */}
                      </Flex>}
                  </Flex>
                )
              } else {
                return (
                  <Flex w='100%' justify='center'>
                    <Image src={logo} alt="Launchdeck Logo" width='50%' minWidth='15em' maxHeight='3em' mb='2em' />
                  </Flex>
                )
              }

            }

            return (
              <Box bg='white' p='3em' rounded='0.25em' w={isMobileView && '90%'}>

                {renderLogos()}

                <Box minW='15em' maxW='35em'>
                  <Box textAlign='center' fontSize='0.875em' color='brandDark.500' fontWeight='400' lineHeight='1.375em' mb='3.5em'>
                    Enter access code provided by the Host to access it</Box>
                  {processingAccessCode ?
                    <PageLoader />
                    :
                    <AlphanumericInput defaultValue={''} errorLogic={showAccessCodeError} handleButtonClick={handleSubmitAccessCode} isMobileView={isMobileView}/>
                  }
                </Box>

              </Box>
            )
          }
          case ("set-password-edit-profile"): {
            return (
              <Box bg='white' p='3em' rounded='0.25em' w={['90%','90%','35em','35em']}>
                <Box minW='15em' maxW='28.5em'>
                  <Flex w='100%' justify='center'>
                    <Image src={logo} alt="Launchdeck Logo" width='50%' minWidth='15em' maxWidth='20em' mb='2em' />
                  </Flex>


                  <Box textAlign='center' fontSize='1.5em' color='brandDark.500' mb='0.5em'>Set a password</Box>
                  <Box textAlign='center' fontSize='0.875em' color='gray.500' fontWeight='400' lineHeight='1.375em' mb='3.5em'>It'll help you access the Room more easily for future visits </Box>
                  <DsrAccessSetPasswordForm
                    newUserId={userId}
                    newProfileToken={newProfileToken}
                    defaultEmail={refInputEmail ? refInputEmail : defaultEmail ? defaultEmail : ""}
                    handleSetPasswordProfile={handleSetPasswordProfile}
                  />
                </Box>

              </Box>
            )
          }
          case('confirm-public-dsr-access'): 
          case ('display-static-public-dsr'): {
  
            // return (
            //   <Box bg='white' p='3em' rounded='0.25em' width='50%' maxW='50em' height='40vh'>
            //     <PageLoader />
            //   </Box>
            // );
            break;
          }
          case ('show-authentication-error'):
          case ('show-error'): 
          default: {
            return (
              <Box>
                <UnauthorisedAccessDisplay />
              </Box>
            )
          }
        }
      } else if (isActiveDsr === false) {
        if (isAdminDraftDsr === true && nextStep === "redirect-admin-to-draft") {
          return (
            <Box bg='white' p='3em' rounded='0.25em' w={['90%','90%','35em','35em']}>
              <Box minW='15em' maxW='28.5em'>
                <Flex w='100%' justify='center'>
                  <Image src={logo} alt="Launchdeck Logo" width='50%' minWidth='15em' maxWidth='20em' mb='2em' />
                </Flex>


                <Box textAlign='center' fontSize='1.5em' color='brandDark.500' mb='0.5em'>Welcome back </Box>
                <Box textAlign='center' fontSize='0.875em' color='gray.500' fontWeight='400' lineHeight='1.375em' mb='3.5em'>Log in to view all your Rooms  </Box>

                <DsrReturningLoginForm
                  dsrId={dsrId}
                  defaultEmail={refInputEmail}
                  handleSubmitLoginRequest={handleSubmitLoginRequest}
                />
              </Box>

            </Box>
          )
        }

        return (
          <Box>
            <UnauthorisedAccessDisplay />
          </Box>
        )
      }
    }
  }


  return (
    <ChakraBaseProvider theme={claverTheme}>
      <Flex direction='column' className='access-page-background'>
        <Flex direction='row' flexGrow='1'>
          <Flex w='100%' direction='column' alignItems='center' bg='white' rounded='0.8em'>
            <Flex w='100%' className="access-page-container" minHeight='100vh' justify='center' alignItems='center'>

              {isProcessing ?
                <Box bg='white' p='3em' rounded='0.25em' width='50%' maxW='50em' height='40vh'>
                  <PageLoader />
                </Box>
                : renderContent()}

            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </ChakraBaseProvider>
  )
}



function mapStateToProps(state) {
  return {
    dsrAuth: state.dsrAuth.results.data,
    accessAuthOutcome: state.accessAuthOutcome.results.data,
  	isMobileView: state.mobileViewMode.isMobileView
  };
}

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
      {
        setIsMobileView,
        getDsrAuth,
        accessAuthentication,
        launchdeckLogin
      },
      dispatch,
    )

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DsrManageAccessPage));