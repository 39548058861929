import React from 'react';

import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { useHistory } from 'react-router-dom';

import { Box, Flex, Image, Modal, ModalOverlay, ModalBody, ModalContent  } from '@chakra-ui/react';
import LaunchdeckButton from '../../global/components/helper-components/components/LaunchdeckButton';
import NudgeModalGraphic from '../styles/images/convert-plan-join-waitlist.svg'
import LaunchdeckCloseButton from '../../global/components/helper-components/components/LaunchdeckCloseButton';


function ProductMarketingNudgeModal({ 
  handleCloseModal, 
  userAccountProfile
}) {

  const history = useHistory();
  const { email, firstName, lastName } = userAccountProfile || {};
  const prepopName = (firstName && lastName) ? firstName + ' ' + lastName : firstName ? firstName : "";

  const handleClickSetupEnquiry = () => {
    history.push('/signup', { prepopEmail: email, prepopName, redirectBackRoute: "/home" });
  }


  const displayContent = () => {
    return (
      <Box>

        <Flex direction='column' mt='1em' justify='center' alignItems='center' px='2em'>

        <Flex w='80%' fontSize='1em' color='brandDark.500' fontWeight='600' my='1em' textAlign='center'>
            Start creating your own Rooms to engage with your customers
          </Flex>

          <Image src={NudgeModalGraphic} height='12em'/>

          <Flex fontSize='0.875em' color='gray.500' my='1em' textAlign='center'>
            Get started for free. Explore with us today.
          </Flex>

          <Flex justify='center' w='100%' my='2em'>
            <LaunchdeckButton bg='brand.500' color='white' onClick={() => handleClickSetupEnquiry()} label='Explore with us' />
          </Flex>

        </Flex>

      </Box>
    )
  }

  return (
    <Modal isOpen={true} closeOnOverlayClick={false} isCentered={true} >
      <ModalOverlay className="modal-background" />
      <ModalContent rounded='0.25em' minW={['90%', '500px']} maxW='700px'>
        <ModalBody px='0em'>
          <Flex direction='column' justify='space-around' alignItems='center' w='100%' p='1em'>
            <Flex justify='flex-end' w='100%'>
              <Box pr='1.5em'>
                <LaunchdeckCloseButton handleCloseModal={() => handleCloseModal()} />
              </Box>
            </Flex>

            <React.Fragment>
              {displayContent()}
            </React.Fragment>

          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )


}


const mapStateToProps = (state) => ({
  userAccountProfile: state.userProfile.accountUserProfile
})


export default withRouter(connect(mapStateToProps, null)(ProductMarketingNudgeModal))
