import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'

import {
  Box,
  Flex,
  Grid,
  Image,
  AccordionItem,
  Accordion,
  AccordionButton,
  AccordionPanel,
  Spinner,
  useOutsideClick,
  useToast
} from '@chakra-ui/react'
import DisplayActionDetailsModal from './DisplayActionDetailsModal'

import { AiFillCheckCircle } from 'react-icons/ai'
import { BsCircle } from 'react-icons/bs'
import DsrActionOptionsButton from './DsrActionOptionsButton'
import EditActionModal from './EditActionModal'
import DeleteActionModal from './DeleteActionModal'
import {
  updateDsrAction,
  updateDsrActionReset,
  resetCreateNewDsrAction,
  changeDsrPopupState,
  changeDsrModalState,
  updateReduxActionList
} from '../actions/dsr-actions'

import mapEmptyStateImage from './styles/images/map-empty-state.svg'
import { IoMdAddCircle } from 'react-icons/io';
import './styles/mutual-action-page-display.scss'
import CreateNewMapCategoryModal from './CreateNewMapCategoryModal'
import CreateNewActionBarForm from './CreateNewActionBarForm'
import LaunchdeckDatePicker from '../../forms/DatePickerField/components/LaunchdeckDatePicker'
import DsrMemberPicker from '../dsr-team-mgmt/DsrMemberPicker'
import DsrDisplayAssignedMembersAvatar from '../dsr-team-mgmt/DsrDisplayAssignedMembersAvatar'
import { IoEllipsisVertical } from "react-icons/io5";
import { findActionItemInCategoryArray } from '../helpers/dsr-helpers'
import DeleteCategoryModal from './DeleteCategoryModal'
import EditCategoryModal from './EditCategoryModal'
import PublicDsrMapSignupPrompt from '../dsr-content-components/public-dsr/PublicDsrMapSignupPrompt'
import { MdSort } from "react-icons/md";
import { BsSortDown, BsSortUp } from "react-icons/bs";
import { FiCheckCircle } from "react-icons/fi";
import { triggerLaunchdeckToast } from '../../global/helpers/global-helpers'

function DsrActionListPage(props) {

  const { dsrId, dsrActionList, dsrTeamMembers,
    handleOpenSignupOrLoginModal, createNewDsrActionOutcome, createNewActionIsLoading, updateDsrActionOutcome,
    isMobileView,
    isPublicDsrEnvironment,
    updateReduxActionList,
    updateDsrAction,
    updateDsrActionReset,
    resetCreateNewDsrAction,
    changeDsrPopupState,
    changeDsrModalState,
    isPagePreviewMode
  } = props || {}

  const toast = useToast();

  const [showActionDetailModal, setShowActionDetailModal] = useState(false);

  const [displayActionItem, setDisplayActionItem] = useState(null);
  const [actionItemToEdit, setActionItemToEdit] = useState(null);
  const [categoryToEdit, setCategoryToEdit] = useState(null);

  const [showCreateNewActionCategoryModal, setshowCreateNewActionCategoryModal] = useState(false);
  const [showEditActionModal, setShowEditActionModal] = useState(false);
  const [showDeleteActionModal, setShowDeleteActionModal] = useState(false);

  const [actionListArray, setActionListArray] = useState(null)
  const [displayEmptyState, setDisplayEmptyState] = useState(null)
  const [displaySignupPrompt, setDisplaySignupPrompt] = useState(null)
  const [isActionListUpdating, setIsActionListUpdating] = useState(false)
  const [updatingActionItemId, setUpdatingActionItemId] = useState(null)

  // UX to pick date
  const [showDatePicker, setShowDatePicker] = useState(false)
  // const [dueDateInput, setDueDateInput] = useState("")

  const [focusActionId, setFocusActionId] = useState("")
  const [focusCategoryId, setFocusCategoryId] = useState("")
  const [showCategoryOptionDropdown, setShowCategoryOptionDropdown] = useState(false)

  // Manage Categories
  const [showEditCategoryModal, setShowEditCategoryModal] = useState(false);
  const [showDeleteCategoryModal, setShowDeleteCategoryModal] = useState(false);

  // Sort UX
  const [dateSortMode, setDateSortMode] = useState("no-sort")

  // Manage dropdown menu UX
  const categoryButtonRef = useRef(null);


  const [showOptionButton, setShowOptionButton] = useState(false)
  const [showOptionButtonActionId, setShowOptionButtonActionId] = useState("")

  const [showCategoryButton, setShowCategoryButton] = useState(false)
  const [showOptionButtonCategoryId, setShowOptionButtonCategoryId] = useState("")


  useOutsideClick({
    ref: categoryButtonRef,
    handler: () => setShowCategoryOptionDropdown(false),
  })

  useEffect(() => {
    if (isPublicDsrEnvironment) {
      setDisplaySignupPrompt(true)
    } else
      if (dsrActionList) {
        const actionListArrayInitialize = Array.isArray(dsrActionList) ? [...dsrActionList] : [];
        const showEmptyState = (Array.isArray(actionListArrayInitialize) && actionListArrayInitialize.length > 0) ? false : true;
        setActionListArray(actionListArrayInitialize)
        setDisplayEmptyState(showEmptyState)
      }
  }, [dsrActionList, isPublicDsrEnvironment])

  useEffect(() => {
    if (createNewDsrActionOutcome) {
      const { updatedActionList } = createNewDsrActionOutcome || {};

      updateReduxActionList(updatedActionList)
      setDisplayEmptyState(false)

      toast(
        triggerLaunchdeckToast({
          useCase: "show-success-state",
          label: "New action created",
          isMobileView
        })
      )
    }

    handleCloseAllModals()
    resetCreateNewDsrAction()
  }, [createNewDsrActionOutcome]);


  useEffect(() => {
    if (updateDsrActionOutcome) {
      const { updatedActionList } = updateDsrActionOutcome || {};

      updateReduxActionList(updatedActionList)

      toast(
        triggerLaunchdeckToast({
          useCase: "show-success-state",
          label: "Action updated",
          isMobileView
        })
      )
    }

    handleCloseAllModals()
    updateDsrActionReset()
    setUpdatingActionItemId(null)
    setIsActionListUpdating(false)
  }, [updateDsrActionOutcome]);


  function handleClickActionItemRow(mapCategoryId, actionId) {
    const foundActionItem = findActionItemInCategoryArray(actionListArray, mapCategoryId, actionId)

    if (!!foundActionItem) {
      setDisplayActionItem(foundActionItem)
      setShowActionDetailModal(true)
    }
  }

  function handleClickEditActionOption(mapCategoryId, actionId) {
    const foundActionItem = findActionItemInCategoryArray(actionListArray, mapCategoryId, actionId)

    if (!!foundActionItem) {
      setActionItemToEdit(foundActionItem)
      setFocusCategoryId(mapCategoryId)
      setShowEditActionModal(true)
    }
  }

  function handleClickDeleteActionOption(mapCategoryId, actionId) {
    const foundActionItem = findActionItemInCategoryArray(actionListArray, mapCategoryId, actionId)

    if (!!foundActionItem) {
      setActionItemToEdit(foundActionItem)
      setFocusCategoryId(mapCategoryId)
      setShowDeleteActionModal(true)
    }
  }

  const handleClickAddCategory = () => {
    if (!isPagePreviewMode) {
      setshowCreateNewActionCategoryModal(true)
    } else {
      openPreviewModal()
    }
  }

  const openPreviewModal = () => {
    changeDsrPopupState("show-preview-page-action-modal")
    changeDsrModalState(true)
  }


  // Sorting UX
  const dateSortButton = () => {
    let icon = <MdSort />
    let nextSortAction = "no-sort"

    switch (dateSortMode) {
      case ('latest-first'): {
        icon = <BsSortDown />
        nextSortAction = "earliest-first"
        break;
      }
      case ('earliest-first'): {
        icon = <BsSortUp />
        nextSortAction = "no-sort"
        break;
      }
      case ('no-sort'):
      default: {
        nextSortAction = "latest-first"
        break;
      }
    }

    return (
      <Box cursor='pointer' fontSize='1.2em' onClick={() => setDateSortMode(nextSortAction)}>
        {icon}
      </Box>
    )

  }

  function sortActionItemsByDate(mapCategoryActionItemList) {

    function parseTimestamp(obj) {
      const { actionCreatedAt, actionDueDate } = obj;

      return new Date((dateSortMode === "no-sort") ? actionCreatedAt : actionDueDate);
    }

    mapCategoryActionItemList.sort((a, b) => {
      const dateA = parseTimestamp(a);
      const dateB = parseTimestamp(b);

      // Compare status first (active first, then inactive or missing)
      if (a.actionStatus === 'active' && b.actionStatus !== 'active') {
        return -1; // Place 'active' status before other statuses
      } else if (a.actionStatus !== 'active' && b.actionStatus === 'active') {
        return 1; // Place 'active' status before other statuses
      }

      // Sort logic to place objects with missing/empty timestamps towards the end
      if (isNaN(dateA) && isNaN(dateB)) {
        return 0; // Both are invalid dates (shouldn't happen based on parseTimestamp logic)
      } else if (isNaN(dateA)) {
        return 1; // Object A has a missing/empty timestamp, place it after B
      } else if (isNaN(dateB)) {
        return -1; // Object B has a missing/empty timestamp, place it after A
      }

      // Sort by timestamps in descending order (latest first)
      if (dateSortMode === "latest-first") {
        return dateB - dateA;
      } else {
        return dateA - dateB;
      }

    });

    return mapCategoryActionItemList

  }


  // Consolidate all close modals into a master closemodal function that resets all the values?
  function handleCloseAllModals() {
    setShowActionDetailModal(false)
    setshowCreateNewActionCategoryModal(false)
    setShowEditActionModal(false)
    setShowDeleteActionModal(false)
    setDisplayActionItem(null)
    setActionItemToEdit(null)
    setFocusActionId("")
    // setDueDateInput("")
    setFocusCategoryId("")
    setShowCategoryOptionDropdown(false)
    setShowEditCategoryModal(false)
    setShowDeleteCategoryModal(false)
    setCategoryToEdit(null)
  }

  function handleClickRadioButton(mapCategoryId, actionId) {
    if (isPagePreviewMode) {
      openPreviewModal()
    } else {
      if (mapCategoryId && actionId) {
        const updateActionMetadata = {
          dsrId,
          mapCategoryId,
          actionId,
          event: "update-action-status"
        }
        setUpdatingActionItemId(actionId)
        setIsActionListUpdating(true)
        updateDsrAction(updateActionMetadata)
      }
    }
  }

  const handlePickDate = (pickedDate, mapCategoryId, actionId) => {
    if (pickedDate && actionId) {
      setFocusActionId(actionId)
      // setDueDateInput(pickedDate)

      const updateActionMetadata = {
        dsrId,
        mapCategoryId,
        actionId,
        pickedDate,
        event: "update-action-due-date"
      }

      updateDsrAction(updateActionMetadata)
    }

    setShowDatePicker(false)
  }

  const handleClickDatePicker = (showDatePickerLogic, actionId) => {

    if (isPagePreviewMode) {
      openPreviewModal()
    } else {
      setFocusActionId(actionId)
      setShowDatePicker(showDatePickerLogic)
    }

  }

  const handleCloseDatePicker = () => {
    setShowDatePicker(false)
  }

  const handleCheckboxClick = (mapCategoryId, actionId, userId, checkLogicValue) => {
    if (mapCategoryId && actionId && userId) {
      const updateActionMetadata = {
        dsrId,
        mapCategoryId,
        actionId,
        assignedToNewUserId: userId,
        event: (checkLogicValue === true) ? "update-action-add-assigned-person" : "update-action-remove-assigned-person"
      }

      updateDsrAction(updateActionMetadata)
    }
  }

  const handleCategoryOptionClick = (mapCategoryId) => {
    setFocusCategoryId(mapCategoryId)
    setShowCategoryOptionDropdown(!showCategoryOptionDropdown)
  }

  const handleEditCategoryClick = (mapCategoryId) => {
    // Resets
    setShowCategoryOptionDropdown(false)
    setFocusCategoryId(mapCategoryId)

    // Find Category to Edit
    if (actionListArray && mapCategoryId) {
      const categoryToEdit = actionListArray?.find(category => category.mapCategoryId === mapCategoryId)

      if (categoryToEdit) {
        setCategoryToEdit(categoryToEdit)
        setShowEditCategoryModal(true)
      }
    }
  }

  const handleDeleteCategoryClick = (mapCategoryId) => {
    // Resets
    setShowCategoryOptionDropdown(false)
    setFocusCategoryId(mapCategoryId)

    setShowDeleteCategoryModal(true)
  }


  const renderCategoryHeader = () => {
    if (isMobileView) return null

    return (
      <Grid className='item-container' templateColumns="3.1fr 1.8fr 2.3fr" gap='0.5em'>
        <Box w="100%" fontSize='0.75em' fontWeight='400' color='gray.500' lineHeight='1.375em' pl='0.6em'>Task</Box>
        <Flex w="100%" fontSize='0.75em' fontWeight='400' color='gray.500' lineHeight='1.375em' align='center' gap={8}>
          <Box>Due date</Box>
          {dateSortButton()}
        </Flex>
        <Box w="100%" fontSize='0.75em' fontWeight='400' color='gray.500' lineHeight='1.375em'>Assign to</Box>
        <Box w="100%"> </Box>
      </Grid>
    )
  }

  const renderMapPage = () => {
    const renderInCategories = () => {
      const categoryGroups = Array.isArray(actionListArray) && actionListArray?.map((category) => {
        const { mapCategoryId, mapCategoryName, mapCategoryDescription, mapCategoryActionItemList } = category || {}
        const isEmptyList = (mapCategoryActionItemList?.length === 0) ? true : false;

        const renderItemList = () => {
          const actionItemRow = mapCategoryActionItemList && sortActionItemsByDate(mapCategoryActionItemList).map((item, index) => {

            const { actionId, actionStatus, actionTaskTitle, actionDueDate, assignedToUserId } = item || {};
            const isActive = (actionStatus === 'active') ? true : false;
            const isCompleted = (actionStatus === "completed") ? true : false;

            if (isMobileView) {
              return (
                <Flex className='item-container' align='center' position='relative' key={'action-item-' + actionId}>
                  <Flex direction='column' w='100%' py='0.5em'>
                    <Flex justify='space-between' align='center'>
                      <Box flex='1'>
                        {(isActionListUpdating && actionId === updatingActionItemId)
                          ? <Flex justifyContent='center' alignItems='center' boxSize='1em'><Spinner size='md' color='blue.500' /></Flex>
                          : isActive
                            ? <Box as={BsCircle} color='gray.500' boxSize='1em' cursor='pointer' onClick={() => handleClickRadioButton(mapCategoryId, actionId)} />
                            : <Box as={AiFillCheckCircle} color='brand.500' boxSize='1em' cursor='pointer' onClick={() => handleClickRadioButton(mapCategoryId, actionId)} />
                        }
                      </Box>

                      <Flex flex='5' justify='flex-start' align='center'>
                      {isCompleted ?
                        <Flex whiteSpace='nowrap' w='100%' justify='flex-start'>
                          <Flex className='completed-map-tag' fontSize='0.6em'>
                            <Box as={FiCheckCircle} boxSize='1em' className='completed-map-tag__icon' />
                            <Box className='completed-map-tag__button-text'>Completed</Box>
                          </Flex>
                        </Flex>
                        :
                        <LaunchdeckDatePicker
                          right='-3.5em'
                          actionId={actionId}
                          focusActionId={focusActionId}
                          mapCategoryId={mapCategoryId}
                          handleClick={handleClickDatePicker}
                          handlePickDate={handlePickDate}
                          showDatePicker={showDatePicker}
                          closeDatePicker={handleCloseDatePicker}
                          dateInput={actionDueDate}
                          isMobileView={isMobileView}
                        />
                      }
                    </Flex>

                    <DsrActionOptionsButton
                          actionId={actionId}
                          mapCategoryId={mapCategoryId}
                          isActive={isActive}
                          handleClickViewDetailsOption={handleClickActionItemRow}
                          handleClickEditActionOption={handleClickEditActionOption}
                          handleClickDeleteActionOption={handleClickDeleteActionOption}
                        />
                    </Flex>

                    <Box className="action-task-name-display__mobile" onClick={() => handleClickActionItemRow(mapCategoryId, actionId)}>
                      {actionTaskTitle}
                    </Box>

                    <Flex justify='space-between' align='center' mt='1.5em'>
                        <Flex w='100%' justify='flex-start' align='center' flexWrap='wrap' mr='0.5em' mt='0.5em'>
                          <DsrMemberPicker
                            actionId={actionId}
                            // focusActionId={focusActionId}
                            mapCategoryId={mapCategoryId}
                            setFocusActionId={setFocusActionId}
                            handleCheckboxClick={handleCheckboxClick}
                            dsrTeamMembers={dsrTeamMembers}
                            currentAssignedMembers={assignedToUserId} // Array
                            isMobileView={true}
                            isPagePreviewMode={isPagePreviewMode}
                            openPreviewModal={openPreviewModal}
                          />
                          <DsrDisplayAssignedMembersAvatar dsrTeamMembers={dsrTeamMembers} assignedToUsers={assignedToUserId} isMobileView={isMobileView} />
                        </Flex>

                     
                      </Flex>

            
                  </Flex>
                </Flex>
              )
            } else {
              return (
                <Grid
                  key={'action-item-' + actionId}
                  className='item-container'
                  templateColumns="0.1fr 3fr 2fr 2fr 0.1fr" gap='0.5em' align='center' position='relative'
                  onMouseOver={() => {
                    setShowOptionButton(true)
                    setShowOptionButtonActionId(actionId)
                  }}
                  onMouseOut={() => {
                    setShowOptionButton(false)
                    setShowOptionButtonActionId("")
                  }}
                >
                  <Box w="100%" px='0.5em'>
                    {(isActionListUpdating && actionId === updatingActionItemId)
                      ? <Flex justifyContent='center' alignItems='center' boxSize='1em'><Spinner size='md' color='blue.500' /></Flex>
                      : isActive
                        ? <Box as={BsCircle} color='gray.500' boxSize='1em' cursor='pointer' onClick={() => handleClickRadioButton(mapCategoryId, actionId)} />
                        : <Box as={AiFillCheckCircle} color='brand.500' boxSize='1em' cursor='pointer' onClick={() => handleClickRadioButton(mapCategoryId, actionId)} />
                    }
                  </Box>

                  <Box className={isActive ? "action-task-name-display truncate-long-text" : "completed-action-task-name-display truncate-long-text"} textAlign='left' onClick={() => handleClickActionItemRow(mapCategoryId, actionId)}>
                    {actionTaskTitle}
                  </Box>

                  {isCompleted ?
                    <Flex whiteSpace='nowrap' w='100%' justify='flex-start'>
                      <Flex className='completed-map-tag' fontSize='0.75em'>
                        <Box as={FiCheckCircle} boxSize='1em' className='completed-map-tag__icon' />
                        <Box className='completed-map-tag__button-text'>Completed</Box>

                      </Flex>
                    </Flex>
                    :
                    <LaunchdeckDatePicker
                      right='2.6em'
                      actionId={actionId}
                      focusActionId={focusActionId}
                      mapCategoryId={mapCategoryId}
                      handleClick={handleClickDatePicker}
                      handlePickDate={handlePickDate}
                      showDatePicker={showDatePicker}
                      closeDatePicker={handleCloseDatePicker}
                      dateInput={actionDueDate}
                    />
                  }
                  <Flex w='100%' justify='flex-start' align='center' flexWrap='wrap'>

                    <DsrMemberPicker
                      actionId={actionId}
                      // focusActionId={focusActionId}
                      mapCategoryId={mapCategoryId}
                      setFocusActionId={setFocusActionId}
                      handleCheckboxClick={handleCheckboxClick}
                      dsrTeamMembers={dsrTeamMembers}
                      currentAssignedMembers={assignedToUserId} // Array
                      isPagePreviewMode={isPagePreviewMode}
                      openPreviewModal={openPreviewModal}
                    />

                    <DsrDisplayAssignedMembersAvatar dsrTeamMembers={dsrTeamMembers} assignedToUsers={assignedToUserId} />
                  </Flex>

                  <Flex 
                    opacity={(showOptionButton && showOptionButtonActionId === actionId) ? '1.0' : '0'}
                    visibility={isPagePreviewMode ? 'hidden' : ''}
                  >
                      <DsrActionOptionsButton
                        actionId={actionId}
                        mapCategoryId={mapCategoryId}
                        isActive={isActive}
                        handleClickViewDetailsOption={handleClickActionItemRow}
                        handleClickEditActionOption={handleClickEditActionOption}
                        handleClickDeleteActionOption={handleClickDeleteActionOption}
                      />
                  </Flex>
                </Grid>
              )
            }


          })

          return actionItemRow
        }

        return (
          <Box key={`map-category-${mapCategoryId}`} className='task-category-group-container'
            onMouseOver={() => {
              setShowCategoryButton(true)
              setShowOptionButtonCategoryId(mapCategoryId)
            }}
            onMouseOut={() => {
              setShowCategoryButton(false)
              setShowOptionButtonCategoryId("")
            }}

          >
            <Flex w='100%' justify='space-between' align='center' p='1em 1.5em' bg={isMobileView && 'brand.50'} roundedTopLeft={isMobileView && '0.3em'} roundedTopRight={isMobileView && '0.3em'}>
              <Box>

                <Box lineHeight='1.625em' fontSize='1.125em' color='brandDark.500'>{mapCategoryName}</Box>
                <Box lineHeight='1.3em' fontSize='0.875em' color='gray.500'>{mapCategoryDescription}</Box>
              </Box>

              {!isPagePreviewMode ? <Box px='0.5em' position='relative' opacity={(showCategoryButton && showOptionButtonCategoryId === mapCategoryId) ? '1.0' : '0'}>
                <Box as={IoEllipsisVertical} cursor='pointer' onClick={() => handleCategoryOptionClick(mapCategoryId)} />
                {
                  (showCategoryOptionDropdown && (focusCategoryId === mapCategoryId)) &&
                  <Box position='absolute' top='1.5em' right='0.7em' width='max-content' className="category-button-dropdown" ref={categoryButtonRef}>
                    <Box color="#718096" p='0.5em 1em' lineHeight='1.375em' fontSize='0.875em' cursor='pointer' onClick={() => handleEditCategoryClick(mapCategoryId)}>Edit category</Box>
                    <Box color="#F56565" p='0.5em 1em' lineHeight='1.375em' fontSize='0.875em' cursor='pointer' onClick={() => handleDeleteCategoryClick(mapCategoryId)}>Delete category</Box>
                  </Box>
                }

              </Box> : <Box px='0.5em' />}
            </Flex>
            {!isEmptyList && renderCategoryHeader()}
            {renderItemList()}

            {isPagePreviewMode ?
              <Box w='100%' bg='gray.200' p='0.75em 2em' cursor='pointer' onClick={() => openPreviewModal()}
                fontSize='0.875em' roundedBottomLeft='0.5em' roundedBottomRight='0.5em'
              >


                <Box as="span" flex='1' textAlign='left'>
                  <Flex w='100%' justify='flex-start' align='center'>
                    <Box as={IoMdAddCircle} boxSize='1.5em' color='brandDark.500' />
                    <Box ml='1em' color='brandDark.500' lineHeight='1.375em'>Add another task</Box>
                  </Flex>
                </Box>

              </Box>
              :
              <Accordion width='100%' allowToggle={!isPagePreviewMode}>
                <AccordionItem border='none' isDisabled={isPagePreviewMode}
                  style={{ opacity: '1.0', color: "brand.500" }}
                >
                  <AccordionPanel p='0em'>

                    {isEmptyList && renderCategoryHeader()}
                    <Flex key={`map-action-entry-${mapCategoryId}`}>
                      <CreateNewActionBarForm
                        dsrId={dsrId}
                        mapCategoryId={mapCategoryId}
                        focusCategoryId={focusCategoryId}
                        isLoadingState={createNewActionIsLoading}
                        setFocusCategoryId={setFocusCategoryId}
                      />

                    </Flex>
                  </AccordionPanel>

                  <AccordionButton bg='gray.200' p='0.75em 2em' cursor='pointer'
                    fontSize='0.875em' roundedBottomLeft='0.5em' roundedBottomRight='0.5em'
                    style={{ cursor: 'pointer' }}
                  >
                    <Box as="span" flex='1' textAlign='left'>
                      <Flex w='100%' justify='flex-start' align='center'>
                        <Box as={IoMdAddCircle} boxSize='1.5em' color='brandDark.500' />
                        <Box ml='1em' color='brandDark.500' lineHeight='1.375em'>Add another task</Box>
                      </Flex>
                    </Box>
                  </AccordionButton>

                </AccordionItem>
              </Accordion>
            }
          </Box>
        )
      })

      return categoryGroups;
    }
    return (
      <>
        {renderInCategories()}
        <Flex whiteSpace='nowrap' mt='2em' w='100%' justify='center'>
          <Flex className='create-new-category-button'
            onClick={handleClickAddCategory}>
            <Box className='create-new-category-button__button-text'>Add Category</Box>
            <Box as={IoMdAddCircle} boxSize='1.5em' className='create-new-room-button__add-icon' />
          </Flex>
        </Flex>
      </>
    )
  }

  if (displaySignupPrompt === true) {
    return <PublicDsrMapSignupPrompt handleOpenSignupOrLoginModal={() => handleOpenSignupOrLoginModal()} />
  } else if (displayEmptyState === true) {
    return (
      <Box>
        <Box textAlign={['center','center','left','left']}>
          <Box color='brandDark.500' fontSize='1.5em' fontWeight='600' lineHeight='2.25em'>
            Mutual Action Plan
          </Box>
          <Box fontSize={isMobileView ? "0.875em":"1em"} color="brandDark.500" fontWeight='400' lineHeight={['1.25em','1.25em','2em','2em']}>
            Create and assign important tasks to keep everyone involved and up-to-date
          </Box>
        </Box>


        <Flex mt='2em' justify='center' align='center' py='2.75em' border='1px solid #E5EAEF' rounded='0.5em' bg='white'>

          <Flex direction='column' justify='center' align='center'>
            <Image src={mapEmptyStateImage} alt="Empty Mutual Action Plan Page" />

            <Box fontSize='0.875em' fontWeight='400' lineHeight='1.5em' color='gray.500' py='1.5em' textAlign='center'>
              Add a category to start creating and grouping your tasks
            </Box>

            <Box whiteSpace='nowrap' >
              <Flex className='create-new-category-button'
                onClick={handleClickAddCategory}>
                <Box className='create-new-category-button__button-text'>Add Category</Box>
                <Box as={IoMdAddCircle} boxSize='1.5em' className='create-new-room-button__add-icon' />
              </Flex>
            </Box>
            <CreateNewMapCategoryModal
              dsrId={dsrId}
              showModal={showCreateNewActionCategoryModal}
              handleCloseModal={handleCloseAllModals}
              dsrTeamMembers={dsrTeamMembers}
            />
          </Flex>

        </Flex>


      </Box>
    )
  } else if (displayEmptyState === false) {
    return (
      <Box>
        <Flex justify='space-between' align='center'>
          <Box>
            <Box color='brandDark.500' fontSize='1.5em' lineHeight='1.4em' fontWeight='600'>Mutual Action Plan</Box>
            <Box color='brandDark.500' fontSize='1em' fontWeight='400'>Create and assign important tasks to keep everyone involved and up-to-date</Box>
          </Box>
        </Flex>


        {actionListArray && renderMapPage()}

        {/* // Refactor- render modal only when necessary */}
        {/* {renderModal} */}

        {/* // Category Modal  */}
        <CreateNewMapCategoryModal
          dsrId={dsrId}
          showModal={showCreateNewActionCategoryModal}
          handleCloseModal={handleCloseAllModals}
          dsrTeamMembers={dsrTeamMembers}
        />

        {/* // Action Modal */}
        <DisplayActionDetailsModal
          showModal={showActionDetailModal}
          actionItem={displayActionItem}
          handleCloseActionDetailsModal={handleCloseAllModals}
          dsrTeamMembers={dsrTeamMembers}
          isMobileView={isMobileView}
        />

        <EditActionModal
          dsrId={dsrId}
          mapCategoryId={focusCategoryId}
          actionItemToEdit={actionItemToEdit}
          showModal={showEditActionModal}
          handleCloseEditActionModal={handleCloseAllModals}
          dsrTeamMembers={dsrTeamMembers}
        />

        <DeleteActionModal
          dsrId={dsrId}
          mapCategoryId={focusCategoryId}
          actionItemToEdit={actionItemToEdit}
          showModal={showDeleteActionModal}
          handleCloseDeleteActionModal={handleCloseAllModals}
        />

        <DeleteCategoryModal
          dsrId={dsrId}
          mapCategoryId={focusCategoryId}
          showModal={showDeleteCategoryModal}
          handleCloseModal={handleCloseAllModals}
        />

        <EditCategoryModal
          dsrId={dsrId}
          mapCategoryId={focusCategoryId}
          categoryToEdit={categoryToEdit}
          showModal={showEditCategoryModal}
          handleCloseModal={handleCloseAllModals}
        />



      </Box>
    );
  } else return null;

}

function mapStateToProps(state) {
  return {
    createNewDsrActionOutcome: state.createNewDsrActionOutcome.results.data,
    createNewActionIsLoading: state.createNewDsrActionOutcome.isLoading,
    updateDsrActionOutcome: state.updateDsrActionOutcome.results.data,
    isMobileView: state.mobileViewMode.isMobileView,
    isPublicDsrEnvironment: state.publicDsrEnvironment.publicDsrEnv,
  };
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateDsrAction,
      updateDsrActionReset,
      resetCreateNewDsrAction,
      changeDsrPopupState,
      changeDsrModalState,
      updateReduxActionList
    },
    dispatch,
  )

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DsrActionListPage));
