export const JWT_TOKEN_KEY = 'JWT_TOKEN_KEY';

//Authentication helper file used to store/delete token and deal with headers
const Auth = {
	async storeToken(token) {
		return await localStorage.setItem(JWT_TOKEN_KEY, token);
	},

	async clearSession() {
		await localStorage.removeItem(JWT_TOKEN_KEY);
		await localStorage.removeItem('USER_ID_TOKEN');
		await localStorage.removeItem('USER_TYPE');
		await localStorage.removeItem('USER_CATG');
		await localStorage.removeItem('USER_CATG_TYPE');
		await localStorage.removeItem('USER_PRIVILEGES');
	},

	async getToken() {
		return await localStorage.getItem(JWT_TOKEN_KEY);
	},

	async getAccessToken() {
		const token = await this.getToken();
		return {
			Authorization: `Bearer ${token}`
		};
	},

	getContentType() {
		return {
			'Content-Type': 'application/json'
		};
	},

	async getHeaders() {
		const token = await this.getToken();
		return {
			Authorization: `Bearer ${token}`,
			'Content-Type': 'application/json'
		};
	},

	async getUploadHeaders() {
		const token = await this.getToken();
		return {
			Authorization: `Bearer ${token}`,
			'Content-Type': 'fileType'
		};
	},

	isLoggedIn() {
		return localStorage.getItem(JWT_TOKEN_KEY);
	},

};

export default Auth;
