import auth from '../../../config/app-specific-config/auth'
import * as types from './dsr-actions-types'
import {
  CHANGE_DSR_POPUP,
  CHANGE_SELECT_SUBPAGE,
  CHANGE_DSR_PAGE_DISPLAY,
  CHANGE_SHOW_DSR_MODAL,
  RESET_DSR_FEED_CONTENT_STATE,
  RESET_DSR_PAGES,
  RESET_DSR_SELECTED_DISPLAY_SUBPAGE,
  RESET_DSR_TITLE,
  SET_DSR_DRAFT_CURRENT_PAGE,
  SET_DSR_DRAFT_FAQ_PAGE_ID,
  SET_DSR_DRAFT_MODAL,
  SET_DSR_DRAFT_PROPERTIES,
  SET_DSR_USER_TEAM,
  SET_IS_DSR_ADMIN,
  SET_DSR_DRAFT_SHOW_LOAD_MORE,
  SET_DSR_DRAFT_STATUS,
  SET_DSR_TEAM_MEMBERS,
  SET_ROOM_MAIN_CONTACT_INFO,
  SET_DSR_DRAFT_TITLE,
  SET_DSR_DRAFT_USER_ACTION,
  SET_DSR_DRAFT_USER_PROFILE,
  SET_DSR_INVALID_REQUEST,
  SET_DSR_PAGES,
  SET_DSR_FAQ_CONTENT,
  SET_DSR_LOGO_IMAGES,
  SET_JOIN_DSR_REQUESTS,
  SET_DSR_UI_INDICATORS,
	SET_DSR_ACTION_LIST_OVERVIEW,
	SET_DSR_LAST_UPDATED,
	SET_DSR_SHARED_DOCS_OVERVIEW
} from './dsr-actions-types'
import { axiosInstance } from '../../../config/app-specific-config/axiosConfig'
import { queryParamArrayParser } from '../../global/helpers/url-tag-helpers'
import { getUserDetailsUsingDsrMembersArray } from '../../profile/helpers/user-helper'
import { displayModeCheck, getThisUserIdToken } from '../../global/helpers/global-helpers'
import { findPageIdByPageType } from '../helpers/dsr-helpers'
import { checkUserIsDsrAdmin, checkUserTeam } from '../helpers/dsr-permission-helpers'
import { setIsMobileView } from '../../global/actions/global-actions'
import { setUserPrivileges } from '../../auth/actions/auth-actions'
import {Uploader} from "../../utils/multithreadedUploader";


// Get DSR Configuration
export const getDsrConfigSuccess = (response) => {
	return {
		type: types.GET_DSR_CONFIG_SUCCESS,
		payload: response.data
	};
};

export const getDsrConfigFailure = (error) => {
	return {
		type: types.GET_DSR_CONFIG_FAILURE,
		error
	};
};

export const getDsrConfigRequest = () => {
	return {
		type: types.GET_DSR_CONFIG_REQUEST
	};
};

export function  loadMoreFeedContentAct (dsrId) {
	return async (dispatch) => {
		dispatch(getDsrFeedContent(dsrId, true))
	}
}

export function  replaceDsrFeedContent (p) {
	return {
		type: types.REPLACE_DSR_FEED_CONTENT,
		payload: p
	};
}

export function  replaceUpdatedDsrFeedContent (p) {
  return {
    type: types.REPLACE_UPDATED_DSR_FEED_CONTENT,
    payload: p
  };
}

export function fetchInitialDsrData(dsrId, type) {
	return async (dispatch) => {
		dispatch(getDsrConfig(dsrId, type))
		dispatch(getDsrActionList(dsrId))
		dispatch(manageSharedDoc({manageEvent:"get-dsr-shared-doc",dsrId}))

		const isMobileView = displayModeCheck();
		dispatch(setIsMobileView(isMobileView))
	}
}

export const setDsrFaqContent = (p) => {
	return {
		type: SET_DSR_FAQ_CONTENT,
		payload: p
	};
};

export const setDsrLogos = (p) => {
	return {
		type: SET_DSR_LOGO_IMAGES,
		payload: p
	};
}

export const setJoinRoomRequests = (p) => {
	return {
		type: SET_JOIN_DSR_REQUESTS,
		payload: p
	};
}

export const setDsrUiIndicators = (p) => {
	return {
		type: SET_DSR_UI_INDICATORS,
		payload: p
	};
}

export const setDsrActionListOverview = (p) => {
	return {
		type: SET_DSR_ACTION_LIST_OVERVIEW,
		payload: p
	};
}

export const setDsrSharedDocsOverview = (p) => {
	return {
		type: SET_DSR_SHARED_DOCS_OVERVIEW,
		payload: p
	};
}

export function getDsrConfig(dsrId, dsrStatuses) {

	// Note: dsrStatuses is an array
	const parsedQueryParamString = queryParamArrayParser('statuses', dsrStatuses)
	
	return async (dispatch) => {
		dispatch(getDsrConfigRequest());
		try {
			const url = `/dsr/config/${dsrId}${parsedQueryParamString}`;
			const headers = await auth.getAccessToken();
			const response = await axiosInstance.get(url, {
				headers
			});

			if (response.data.data.invalidRequest === true) {
					dispatch(setInvalidRequest(response.data.data.invalidRequest))				
			} 
			
			else {
				dispatch(getDsrConfigSuccess(response));
				dispatch(setDsrPages(response.data.data.dsrPages))

				// Get current user profile info
				const userProfile = getUserDetailsUsingDsrMembersArray(
					response.data.data.dsrTeamMembers,
					getThisUserIdToken(),
				)
				dispatch(setUserProfile(userProfile))

				// Get user's team in this DSR
				const userDsrTeam = checkUserTeam(response.data.data.dsrTeamMembers, getThisUserIdToken())
				const isDsrAdministrator = checkUserIsDsrAdmin(response.data.data.dsrTeamMembers, getThisUserIdToken())
			
				if (response.data.data.updatedAt) {
					dispatch(setDsrLastUpdated(response.data.data.updatedAt))
				}

				if (userDsrTeam) {
					dispatch(setDsrUserTeam(userDsrTeam))
				}

				if (response.data.data.userPrivileges) {
					dispatch(setUserPrivileges(response.data.data.userPrivileges))
				}
				
				// Set these additional states if user is Room Administrator
				if (isDsrAdministrator){
					dispatch(setIsDsrAdmin(isDsrAdministrator))

					// Requests invite
					if(response.data.data.joinRoomRequests) {
						const {requestCount, activeRequests} = response.data.data.joinRoomRequests || {};

						if (requestCount) {
							dispatch(setJoinRoomRequests(activeRequests))
						}
					}

					if (response.data.data.uiIndicators) {
						dispatch(setDsrUiIndicators(response.data.data.uiIndicators))
					}
				}

				// Get other metadata
				const {pageId, dsrPage} = findPageIdByPageType(response.data.data.dsrPages, 'faq')
				
				if (dsrPage) {
					dispatch(setFaqPageId(pageId))
					const faqArray = dsrPage.pageContent[0].faqArray;
					dispatch(setDsrFaqContent(faqArray))
				}

				if (response.data.data.latestDsrNotifications) {
					dispatch(setDsrLatestNotifications(response.data.data.latestDsrNotifications))
				}

				if (response.data.data.actionListOverview) {
					dispatch(setDsrActionListOverview(response.data.data.actionListOverview))
				}


				if (response.data.data.sharedDocsOverview) {
					dispatch(setDsrSharedDocsOverview(response.data.data.sharedDocsOverview))
				}

				dispatch(setDsrLogos(response.data.data.dsrDisplayLogos))
				dispatch(setDsrProperties(response.data.data.dsrProperties))
				dispatch(setDsrTeamMembers(response.data.data.dsrTeamMembers)) 
				dispatch(setDsrDraftStatus(response.data.data.status))
				dispatch(setDsrTitle(response.data.data.dsrTitle))
			}
				
		} catch (error) {
			dispatch(getDsrConfigFailure(error));
		}
	};
}

///// DSR TEAM MEMBER INVITE & MANAGEMENT

/// Check if would-be invited user is an existing user

/// Edit DSR Team Members based on Dsr Id
export const editDsrTeamMembersSuccess = (response) => {
	return {
		type: types.EDIT_DSR_TEAM_MEMBERS_SUCCESS,
		payload: response.data
	};
};

export const editDsrTeamMembersFailure = (error) => {
	return {
		type: types.EDIT_DSR_TEAM_MEMBERS_FAILURE,
		error
	};
};

export const editDsrTeamMembersRequest = () => {
	return {
		type: types.EDIT_DSR_TEAM_MEMBERS_REQUEST
	};
};


export const editDsrTeamMembers = (invitedUserParams) => {
	return async (dispatch) => {
		dispatch(editDsrTeamMembersRequest());
		try {
			const url = '/dsr/team';
			const headers = await auth.getAccessToken();

			const response = await axiosInstance.put(
				url,
				invitedUserParams,
				{ headers }
			);
			
			dispatch(editDsrTeamMembersSuccess(response));
			console.log(response.data.data.updatedDsrTeamMembers)
			if (response.data.data.updatedDsrTeamMembers) {
				
				dispatch(setDsrTeamMembers(response.data.data.updatedDsrTeamMembers))
			}
		
			if (response.data.data.updatedJoinRoomRequests) {
				const {requestCount, activeRequests} = response.data.data.updatedJoinRoomRequests || {};
				dispatch(setJoinRoomRequests(requestCount ? activeRequests : ""))
			}

		} catch (error) {
			dispatch(editDsrTeamMembersFailure(error));
		}
	};
};

// Reset edit Dsr Team Members
export const editDsrTeamMembersReset = () => {
	return {
		type: types.EDIT_DSR_TEAM_MEMBERS_RESET
	};
};

export function resetEditDsrTeamMembers() {

	return async (dispatch) => {

		try {
			dispatch(editDsrTeamMembersReset());

		} catch (error) {
			dispatch(editDsrTeamMembersFailure(error));
		}
	};
}


///// Edit DSR Member Role based on Dsr Id, Usr Id, and selected role
export const editDsrMemberRoleSuccess = (response) => {
	return {
		type: types.EDIT_DSR_MEMBER_ROLE_SUCCESS,
		payload: response.data
	};
};

export const editDsrMemberRoleFailure = (error) => {
	return {
		type: types.EDIT_DSR_MEMBER_ROLE_FAILURE,
		error
	};
};

export const editDsrMemberRoleRequest = () => {
	return {
		type: types.EDIT_DSR_MEMBER_ROLE_REQUEST
	};
};


export const editDsrMemberRole = (editedUserParams) => {
	return async (dispatch) => {
		dispatch(editDsrMemberRoleRequest());
		try {
			const url = '/dsr/team/change-role';
			const headers = await auth.getAccessToken();

			const response = await axiosInstance.put(
				url,
				{editedUserParams},
				{ headers }
			);

			dispatch(setDsrTeamMembers(response.data.data)) 
			dispatch(editDsrMemberRoleSuccess(response));
		} catch (error) {
			dispatch(editDsrMemberRoleFailure(error));
		}
	};
};

export const editDsrMemberRoleReset = () => {
	return {
		type: types.EDIT_DSR_MEMBER_ROLE_RESET
	};
};

export function resetEditDsrMemberRole() {

	return async (dispatch) => {
		try {
			dispatch(editDsrMemberRoleReset());

		} catch (error) {
			dispatch(editDsrMemberRoleFailure(error));
		}
	};
}


////////////////////////////////////////////////////////////
// DSR FEED CONTENT
////////////////////////////////////////////////////////////

///// Get DSR Feed Content based on DSR ID
export const getDsrFeedContentSuccess = (response, dsrId) => {
	return {
		type: types.GET_DSR_FEED_CONTENT_SUCCESS,
		payload: {data: response.data, id: dsrId}
	};
};

export const updateDsrFeedContentSuccess = (response, dsrId) => {
	return {
		type: types.UPDATE_DSR_FEED_CONTENT_SUCCESS,
		payload: {data: response.data, id: dsrId}
	};
};

export const getDsrFeedContentFailure = (error) => {
	return {
		type: types.GET_DSR_FEED_CONTENT_FAILURE,
		error
	};
};

export const getDsrFeedContentRequest = () => {
	return {
		type: types.GET_DSR_FEED_CONTENT_REQUEST
	};
};

export function getDsrFeedContent(dsrId, makeUpdate) {

	return async (dispatch, getState) => {
		const nextPage = getState().dsrFeedContent.page;

		dispatch(getDsrFeedContentRequest());
		try {
			const url = `/dsr/feed-content/${dsrId}/${makeUpdate ? nextPage : 1}`;
			const headers = await auth.getAccessToken();
			const response = await axiosInstance.get(url, {
				headers
			});

			makeUpdate ? dispatch(updateDsrFeedContentSuccess(response, dsrId)) : dispatch(getDsrFeedContentSuccess(response, dsrId))
		} catch (error) {
			console.log(error)
			dispatch(getDsrFeedContentFailure(error));
		}
	};
}

export function getDsrUpdatedFeedContent(dsrId, page) {

  return async (dispatch) => {
    dispatch(getDsrFeedContentRequest());
    try {
      const url = `/dsr/feed-content/${dsrId}/${page ? page : 1}`;
      const headers = await auth.getAccessToken();
      const response = await axiosInstance.get(url, {
        headers
      });

      dispatch(replaceUpdatedDsrFeedContent(response))
    } catch (error) {
      console.log(error)
      dispatch(getDsrFeedContentFailure(error));
    }
  };
}


///// Create & Save new DSR Feed Content based on DSR ID (POST)
// POST method
export const createNewDsrFeedPostSuccess = (response) => {
	return {
		type: types.CREATE_NEW_DSR_FEED_POST_SUCCESS,
		payload: response.data
	};
};

export const createNewDsrFeedPostFailure = (error) => {
	return {
		type: types.CREATE_NEW_DSR_FEED_POST_FAILURE,
		error
	};
};

export const createNewDsrFeedPostRequest = () => {
	return {
		type: types.CREATE_NEW_DSR_FEED_POST_REQUEST
	};
};

export const createFirstFaqQuestionOutcome = (response) => {
	return {
		type: types.CREATE_FIRST_FAQ_QUESTION_OUTCOME,
		payload: response.data
	};
};

export const changeDsrPopupState = (p) => {
	return {
		type: CHANGE_DSR_POPUP,
		payload: p
	};
};

export const changeDsrModalState = (p) => {
	return {
		type: CHANGE_SHOW_DSR_MODAL,
		payload: p
	};
};

export const changeSelectSubpage = (p) => {
	return {
		type: CHANGE_SELECT_SUBPAGE,
		payload: p
	};
};

export const changeDsrPageDisplay = (p) => {
	return {
		type: CHANGE_DSR_PAGE_DISPLAY,
		payload: p
	};
};

export const setDsrPages = (p) => {
	return {
		type: SET_DSR_PAGES,
		payload: p
	};
};

export const setInvalidRequest = (p) => {
	return {
		type: SET_DSR_INVALID_REQUEST,
		payload: p
	};
};

export const resetDsrPages = () => {
	return {
		type: RESET_DSR_PAGES,
	};
};

export const setDsrTeamMembers = (p) => {
	return {
		type: SET_DSR_TEAM_MEMBERS,
		payload: p
	};
};

export const setMainContactInfo = (p) => {
	return {
		type: SET_ROOM_MAIN_CONTACT_INFO,
		payload: p
	};
};

export const setNewDsrDraftCurrentPage = (p) => {
	return {
		type: SET_DSR_DRAFT_CURRENT_PAGE,
		payload: p
	};
};

// TODO: Rename this action -> it is used for all types of DSR status. Not just draft
export const setDsrDraftStatus = (p) => {
	return {
		type: SET_DSR_DRAFT_STATUS,
		payload: p
	};
};

export const setShowDsrModal = (p) => {
	return {
		type: SET_DSR_DRAFT_MODAL,
		payload: p
	};
};

export const setUserActionRequired = (p) => {
	return {
		type: SET_DSR_DRAFT_USER_ACTION,
		payload: p
	};
};

export const setDsrTitle = (p) => {
	return {
		type: SET_DSR_DRAFT_TITLE,
		payload: p
	};
};

export const setUserProfile = (p) => {
	return {
		type: SET_DSR_DRAFT_USER_PROFILE,
		payload: p
	};
};

export const resetDsrFeedContent = () => {
	return {
		type: RESET_DSR_FEED_CONTENT_STATE,
	};
};

export const resetDsrTitle = () => {
	return {
		type: RESET_DSR_TITLE,
	};
};
export const resetDsrSelectedDisplaySubpage = () => {
	return {
		type: RESET_DSR_SELECTED_DISPLAY_SUBPAGE,
	};
};

export const setShowLoadMore = (p) => {
	return {
		type: SET_DSR_DRAFT_SHOW_LOAD_MORE,
		payload: p
	};
};

export const setFaqPageId = (p) => {
	return {
		type: SET_DSR_DRAFT_FAQ_PAGE_ID,
		payload: p
	};
};

export const setDsrProperties = (p) => {
	return {
		type: SET_DSR_DRAFT_PROPERTIES,
		payload: p ? p : {}
	};
};

export const setDsrUserTeam = (p) => {
	return {
		type: SET_DSR_USER_TEAM,
		payload: p
	};
};

export const setIsDsrAdmin = (p) => {
	return {
		type: SET_IS_DSR_ADMIN,
		payload: p
	};
};

export const setUploadProgressMetric = (p) => {
	return {
		type: types.SET_UPLOAD_PROGRESS_METRIC,
		payload: p
	};
};

export const resetUploadProgressMetric = (p) => {
	return {
		type: types.RESET_UPLOAD_PROGRESS_METRIC,
		payload: p
	};
};

export const setDsrSharedDocs = (p) => {
	return {
		type: types.SET_DSR_SHARED_DOCS,
		payload: p
	};
};

export const setDsrLatestNotifications = (p) => {
	return {
		type: types.SET_DSR_LATEST_NOTIFICATIONS,
		payload: p
	};
};

export const setDsrLastUpdated = (p) => {
	return {
		type: SET_DSR_LAST_UPDATED,
		payload: p
	};
};

export function createNewDsrFeedPost(newDsrFeedPostMetadata) {

	return async (dispatch) => {
		dispatch(createNewDsrFeedPostRequest());
		try {
			const url = `/dsr/feed-content`;
			let data;
			if(newDsrFeedPostMetadata.files && newDsrFeedPostMetadata.files.length > 0){
				data = new FormData();
				data.append("dsrId", newDsrFeedPostMetadata.dsrId)
				data.append("postType", newDsrFeedPostMetadata.postType)
				data.append("postContent", newDsrFeedPostMetadata.postContent)
				data.append("userTagProperties", JSON.stringify(newDsrFeedPostMetadata.userTagProperties))
				newDsrFeedPostMetadata.files.forEach(item => {
				data.append('file[]', item)
				})
			} else {
				data = newDsrFeedPostMetadata
			}
			const headers = await auth.getAccessToken();
			const response = await axiosInstance.post(
				url,
				data,
				{ headers, onUploadProgress: (progressEvent) => {
            let progressMetric;
            if (progressEvent.total > 0) {
              progressMetric = Math.round((progressEvent.loaded / progressEvent.total) * 100);
            } else {
              progressMetric = 100;
            }

            dispatch(setUploadProgressMetric(progressMetric));
          }
				},
			);

			// Handling first faq question from FAQ subpage
			const {updatedFaqQuestionArray} = response?.data?.data || {};
			if (updatedFaqQuestionArray && Array.isArray(updatedFaqQuestionArray)) {
				dispatch(createFirstFaqQuestionOutcome(response)) // TODO: Review whether this is still needed with setDsrFaqContent?
				
				// New FAQ content redux
				dispatch(setDsrFaqContent(updatedFaqQuestionArray))

			} else {
				// Other normal posts from Updates Feed Page
				dispatch(createNewDsrFeedPostSuccess(response));
			}
			
			
		} catch (error) {
			dispatch(createNewDsrFeedPostFailure(error));
		}
	};
}

// Reset
export const createNewDsrFeedPostReset = () => {
	return {
		type: types.CREATE_NEW_DSR_FEED_POST_RESET
	};
};

export function resetCreateNewDsrFeedPost() {

	return async (dispatch) => {
		try {
			dispatch(createNewDsrFeedPostReset());

		} catch (error) {
			dispatch(createNewDsrFeedPostFailure(error));
		}
	};
}


///// Edit an existing DSR Feed Post text content based on DSR ID
// PUT method
export const editDsrFeedPostSuccess = (response) => {
	return {
		type: types.EDIT_DSR_FEED_POST_SUCCESS,
		payload: response.data
	};
};

export const editDsrFeedPostFailure = (error) => {
	return {
		type: types.EDIT_DSR_FEED_POST_FAILURE,
		error
	};
};

export const editDsrFeedPostRequest = () => {
	return {
		type: types.EDIT_DSR_FEED_POST_REQUEST
	};
};

export function editDsrFeedPost(editedPostPayload) {

	// PUT API
	return async (dispatch) => {
		dispatch(editDsrFeedPostRequest());
		try {
			const url = '/dsr/feed-content/edit';
			const headers = await auth.getAccessToken();

			const response = await axiosInstance.put(
				url,
				editedPostPayload,
				{ headers }
			);
			dispatch(editDsrFeedPostSuccess(response));

		} catch (error) {
			dispatch(editDsrFeedPostFailure(error));
		}
	};
}

// Reset
export const editDsrFeedPostReset = () => {
	return {
		type: types.EDIT_DSR_FEED_POST_RESET
	};
};

export function resetEditDsrFeedPost() {

	return async (dispatch) => {

		try {
			dispatch(editDsrFeedPostReset());

		} catch (error) {
			dispatch(editDsrFeedPostFailure(error));
		}
	};
}

///// Soft-delete an existing DSR Feed Post text content based on DSR ID
// POST method
export const deleteDsrFeedPostSuccess = (response) => {
	return {
		type: types.DELETE_DSR_FEED_POST_SUCCESS,
		payload: response.data
	};
};

export const deleteDsrFeedPostFailure = (error) => {
	return {
		type: types.DELETE_DSR_FEED_POST_FAILURE,
		error
	};
};

export const deleteDsrFeedPostRequest = () => {
	return {
		type: types.DELETE_DSR_FEED_POST_REQUEST
	};
};

export function deleteDsrFeedPost(deletePostPayload) {

	// POST API
	return async (dispatch) => {
		dispatch(deleteDsrFeedPostRequest());
		try {
			const url = '/dsr/feed-content/delete';
			const headers = await auth.getAccessToken();

			const response = await axiosInstance.post(
				url,
				deletePostPayload,
				{ headers }
			);

			dispatch(deleteDsrFeedPostSuccess(response));

		} catch (error) {
			dispatch(deleteDsrFeedPostFailure(error));
		}
	};
}

// Reset
export const deleteDsrFeedPostReset = () => {
	return {
		type: types.DELETE_DSR_FEED_POST_RESET
	};
};

export function resetDeleteDsrFeedPost() {

	return async (dispatch) => {

		try {
			dispatch(deleteDsrFeedPostReset());

		} catch (error) {
			dispatch(deleteDsrFeedPostFailure(error));
		}
	};
}


///// Create new DSR Feed reply based on Dsr ID & post ID (POST)
// POST method
export const createNewDsrFeedReplySuccess = (response) => {
	return {
		type: types.CREATE_NEW_DSR_FEED_REPLY_SUCCESS,
		payload: response.data
	};
};

export const createNewDsrFeedReplyFailure = (error) => {
	return {
		type: types.CREATE_NEW_DSR_FEED_REPLY_FAILURE,
		error
	};
};

export const createNewDsrFeedReplyRequest = () => {
	return {
		type: types.CREATE_NEW_DSR_FEED_REPLY_REQUEST
	};
};

export function createNewDsrFeedReply(newDsrFeedReplyMetadata) {

	return async (dispatch) => {
		dispatch(createNewDsrFeedReplyRequest());
		try {
			const url = `/dsr/feed-content/reply`;
			const headers = await auth.getAccessToken();
			const response = await axiosInstance.post(
				url,
				{
					// Things to pass to backend
					newDsrFeedReplyMetadata
				},
				{ headers }
			);

			dispatch(createNewDsrFeedReplySuccess(response));
		} catch (error) {
			dispatch(createNewDsrFeedReplyFailure(error));
		}
	};
}

// Reset
export const createNewDsrFeedReplyReset = () => {
	return {
		type: types.CREATE_NEW_DSR_FEED_REPLY_RESET
	};
};

export function resetCreateDsrReply() {

	return async (dispatch) => {

		try {
			dispatch(createNewDsrFeedReplyReset());

		} catch (error) {
			dispatch(createNewDsrFeedReplyFailure(error));
		}
	};
}


///// Edit an existing DSR Reply text content based on Dsr ID & Post ID
// PUT method
export const editDsrReplySuccess = (response) => {
	return {
		type: types.EDIT_DSR_FEED_REPLY_SUCCESS,
		payload: response.data
	};
};

export const editDsrReplyFailure = (error) => {
	return {
		type: types.EDIT_DSR_FEED_REPLY_FAILURE,
		error
	};
};

export const editDsrReplyRequest = () => {
	return {
		type: types.EDIT_DSR_FEED_REPLY_REQUEST
	};
};

export function editDsrReply(editedReplyPayload) {

	// PUT API
	return async (dispatch) => {
		dispatch(editDsrReplyRequest());
		try {
			const url = '/dsr/feed-content/reply/edit';
			const headers = await auth.getAccessToken();

			const response = await axiosInstance.put(
				url,
				editedReplyPayload,
				{ headers }
			);
			dispatch(editDsrReplySuccess(response));

		} catch (error) {
			dispatch(editDsrReplyFailure(error));
		}
	};
}

// Reset
export const editDsrReplyReset = () => {
	return {
		type: types.EDIT_DSR_FEED_REPLY_RESET
	};
};

export function resetEditDsrReply() {

	return async (dispatch) => {

		try {
			dispatch(editDsrReplyReset());

		} catch (error) {
			dispatch(editDsrReplyFailure(error));
		}
	};
}


///// Soft delete an existing DSR Reply text content based on Dsr ID & Post ID
// POST method
export const deleteDsrReplySuccess = (response) => {
	return {
		type: types.DELETE_DSR_FEED_REPLY_SUCCESS,
		payload: response.data
	};
};

export const deleteDsrReplyFailure = (error) => {
	return {
		type: types.DELETE_DSR_FEED_REPLY_FAILURE,
		error
	};
};

export const deleteDsrReplyRequest = () => {
	return {
		type: types.DELETE_DSR_FEED_REPLY_REQUEST
	};
};

export function deleteDsrReply(deleteReplyPayload) {

	// POST API
	return async (dispatch) => {
		dispatch(deleteDsrReplyRequest());
		try {
			const url = '/dsr/feed-content/reply/delete';
			const headers = await auth.getAccessToken();

			const response = await axiosInstance.post(
				url,
				deleteReplyPayload,
				{ headers }
			);

			dispatch(deleteDsrReplySuccess(response));

		} catch (error) {
			dispatch(deleteDsrReplyFailure(error));
		}
	};
}

// Reset
export const deleteDsrReplyReset = () => {
	return {
		type: types.DELETE_DSR_FEED_REPLY_RESET
	};
};

export function resetDeleteDsrReply() {

	return async (dispatch) => {

		try {
			dispatch(deleteDsrReplyReset());

		} catch (error) {
			dispatch(deleteDsrReplyFailure(error));
		}
	};
}


///// Manages the user action of using a reaction for a Feed Post, based on Dsr ID & Post ID
// Does the following:
// (1) Checks if that reaction had been used in that post. If not, create a new entry
// (2) Checks whether this particular user has used that reaction before when adding reaction. If yes, no double-counting allowed.
// (3) Checks whether this particular user has used that reaction before when removing reaction. If no, do not allow removal

// POST method
export const editDsrReactionSuccess = (response) => {
	return {
		type: types.EDIT_DSR_REACTION_SUCCESS,
		payload: response.data
	};
};

export const editDsrReactionFailure = (error) => {
	return {
		type: types.EDIT_DSR_REACTION_FAILURE,
		error
	};
};

export const editDsrReactionRequest = () => {
	return {
		type: types.EDIT_DSR_REACTION_REQUEST
	};
};

export function editDsrReaction(updatePostReactionPayload) {

	// POST API
	return async (dispatch) => {
		dispatch(editDsrReactionRequest());
		try {
			const url = '/dsr/feed-content/reaction';
			const headers = await auth.getAccessToken();

			const response = await axiosInstance.post(
				url,
				updatePostReactionPayload,
				{ headers }
			);

			dispatch(editDsrReactionSuccess(response));

		} catch (error) {
			dispatch(editDsrReactionFailure(error));
		}
	};
}

// Reset
export const editDsrReactionReset = () => {
	return {
		type: types.EDIT_DSR_REACTION_RESET
	};
};

export function resetEditDsrReaction() {

	return async (dispatch) => {

		try {
			dispatch(editDsrReactionReset());

		} catch (error) {
			dispatch(editDsrReactionFailure(error));
		}
	};
}


// Manage DSR Action Lists
// GET method
export const getDsrActionListSuccess = (response) => {
	return {
		type: types.GET_DSR_ACTION_LIST_SUCCESS,
		payload: response.data
	};
};

export const getDsrActionListFailure = (error) => {
	return {
		type: types.GET_DSR_ACTION_LIST_FAILURE,
		error
	};
};

export const getDsrActionListRequest = () => {
	return {
		type: types.GET_DSR_ACTION_LIST_REQUEST
	};
};

export const setReduxActionList = (actionList) => {
	return {
		type: types.UPDATE_REDUX_DSR_ACTION_LIST,
		payload: actionList
	};
}

export function updateReduxActionList(actionList) {
  return async (dispatch) => {
		dispatch(setReduxActionList(actionList));
    // dispatch({ type: types.UPDATE_REDUX_DSR_ACTION_LIST, payload: actionList })
  };
}

export function getDsrActionList(dsrId) {

	return async (dispatch) => {
		dispatch(getDsrActionListRequest());
		try {
			const url = `/dsr/action-list/${dsrId}`;
			const headers = await auth.getAccessToken();
			const response = await axiosInstance.get(url, {
				headers
			});
			dispatch(getDsrActionListSuccess(response));
		} catch (error) {
			dispatch(getDsrActionListFailure(error));
		}
	};
}

// POST method :: Create new Action
export const createNewDsrActionSuccess = (response) => {
	return {
		type: types.CREATE_NEW_DSR_ACTION_SUCCESS,
		payload: response.data
	};
};

export const createNewDsrActionFailure = (error) => {
	return {
		type: types.CREATE_NEW_DSR_ACTION_FAILURE,
		error
	};
};

export const createNewDsrActionRequest = () => {
	return {
		type: types.CREATE_NEW_DSR_ACTION_REQUEST
	};
};

export function createNewDsrAction(newActionMetadata) {

	return async (dispatch) => {
		dispatch(createNewDsrActionRequest());
		try {
			const url = `/dsr/action/create`;
			const headers = await auth.getAccessToken();
			const response = await axiosInstance.post(
				url,
				{newActionMetadata},
				{ headers }
			);

			if (response.data.data.actionListOverviewOnly) {
				dispatch(setDsrActionListOverview(response.data.data.actionListOverviewOnly))
			}

			dispatch(createNewDsrActionSuccess(response));
		} catch (error) {
			dispatch(createNewDsrActionFailure(error));
		}
	};
}

export const createNewDsrActionReset = () => {
	return {
		type: types.CREATE_NEW_DSR_ACTION_RESET
	};
};

export function resetCreateNewDsrAction() {

	return async (dispatch) => {

		try {
			dispatch(createNewDsrActionReset());

		} catch (error) {
			dispatch(createNewDsrActionFailure(error));
		}
	};
}

// POST method :: Update existing action item status
export const updateDsrActionSuccess = (response) => {
	return {
		type: types.UPDATE_DSR_ACTION_SUCCESS,
		payload: response.data
	};
};

export const updateDsrActionFailure = (error) => {
	return {
		type: types.UPDATE_DSR_ACTION_FAILURE,
		error
	};
};

export const updateDsrActionRequest = () => {
	return {
		type: types.UPDATE_DSR_ACTION_REQUEST
	};
};

export function updateDsrAction(updateActionMetadata) {

	return async (dispatch) => {
		dispatch(updateDsrActionRequest());
		try {
			const url = `/dsr/action/update`;
			const headers = await auth.getAccessToken();
			const response = await axiosInstance.post(
				url,
				{updateActionMetadata},
				{ headers }
			);

			if (response.data.data.actionListOverviewOnly) {
				dispatch(setDsrActionListOverview(response.data.data.actionListOverviewOnly))
			}

			dispatch(updateDsrActionSuccess(response));
		} catch (error) {
			dispatch(updateDsrActionFailure(error));
		}
	};
}

export const updateDsrActionReset = () => {
	return {
		type: types.UPDATE_DSR_ACTION_RESET
	};
};

export function resetUpdateDsrAction() {

	return async (dispatch) => {

		try {
			dispatch(updateDsrActionReset());

		} catch (error) {
			dispatch(updateDsrActionFailure(error));
		}
	};
}


///// Manages any admin functions for product marketing and operations purposes
// POST method
export const createNewErrorReportSuccess = (response) => {
	return {
		type: types.CREATE_NEW_ERROR_REPORT_SUCCESS,
		payload: response.data
	};
};

export const createNewErrorReportFailure = (error) => {
	return {
		type: types.CREATE_NEW_ERROR_REPORT_FAILURE,
		error
	};
};

export const createNewErrorReportRequest = () => {
	return {
		type: types.CREATE_NEW_ERROR_REPORT_REQUEST
	};
};

export function createNewErrorReport(errorReportMetadata) {

	// POST API
	return async (dispatch) => {
		dispatch(createNewErrorReportRequest());
		try {
			const usePublicEndpoint = ( errorReportMetadata.reportFormat === "slack-notification-public-user") ? true : false 
			const url = usePublicEndpoint ? '/admin/public-error-report' : '/admin/error-report'
			const headers = await auth.getAccessToken();

			const response = await axiosInstance.post(
				url,
				errorReportMetadata,
				{ headers }
			);

			dispatch(createNewErrorReportSuccess(response));

		} catch (error) {
			dispatch(createNewErrorReportFailure(error));
		}
	};
}


// Manage DSR Authentication
// GET method
export const getDsrAuthSuccess = (response) => {
	return {
		type: types.GET_DSR_AUTH_SUCCESS,
		payload: response.data
	};
};

export const getDsrAuthFailure = (error) => {
	return {
		type: types.GET_DSR_AUTH_FAILURE,
		error
	};
};

export const getDsrAuthRequest = () => {
	return {
		type: types.GET_DSR_AUTH_REQUEST
	};
};

export function getDsrAuth(dsrId, userId) {

	return async (dispatch) => {
		dispatch(getDsrActionListRequest());
		try {
			const url = `/admin/auth`;
			// const headers = await auth.getAccessToken();
			const values={dsrId, userId}
			const header = auth.getContentType();
			const response = await axiosInstance.post(url, values, header);
		
			dispatch(getDsrAuthSuccess(response));
		} catch (error) {
			dispatch(getDsrAuthFailure(error));
		}
	};
}


////////////////////////////////////////////////////////////
// EXTERNAL SERVICE - LOOM
////////////////////////////////////////////////////////////

// Get Loom JWToken from server
// GET method
export const getLoomTokenSuccess = (response) => {
	return {
		type: types.GET_LOOM_TOKEN_SUCCESS,
		payload: response.data
	};
};

export const getLoomTokenFailure = (error) => {
	return {
		type: types.GET_LOOM_TOKEN_FAILURE,
		error
	};
};

export const getLoomTokenRequest = () => {
	return {
		type: types.GET_LOOM_TOKEN_REQUEST
	};
};

export function getLoomToken() {

	return async (dispatch) => {
		dispatch(getLoomTokenRequest());
		try {
			const url = `/dsr/external/loom/auth`;
			const headers = await auth.getAccessToken();
			const response = await axiosInstance.get(url, {
				headers
			});
			dispatch(getLoomTokenSuccess(response));
		} catch (error) {
			dispatch(getLoomTokenFailure(error));
		}
	};
}



// Get Loom videos from Loom via server
// GET method
export const getLoomVideosSuccess = (response) => {
	return {
		type: types.GET_LOOM_VIDEOS_SUCCESS,
		payload: response.data
	};
};

export const getLoomVideosFailure = (error) => {
	return {
		type: types.GET_LOOM_VIDEOS_FAILURE,
		error
	};
};

export const getLoomVideosRequest = () => {
	return {
		type: types.GET_LOOM_VIDEOS_REQUEST
	};
};

export function getLoomVideos() {

	return async (dispatch) => {
		dispatch(getLoomVideosRequest());
		try {
			const url = `/dsr/external/loom/videos`;
			const headers = await auth.getAccessToken();
			const response = await axiosInstance.get(url, {
				headers
			});
			dispatch(getLoomVideosSuccess(response));
		} catch (error) {
			dispatch(getLoomVideosFailure(error));
		}
	};
}





////////////////////////////////////////////////////////////
// DSR MANAGEMENT SERVICE - CREATE, EDIT, DELETE DSRs
////////////////////////////////////////////////////////////
// Create brand new DSRs
// POST method
export const createNewDsrSuccess = (response) => {
	return {
		type: types.CREATE_NEW_DSR_SUCCESS,
		payload: response.data
	};
};

export const createNewDsrFailure = (error) => {
	return {
		type: types.CREATE_NEW_DSR_FAILURE,
		error
	};
};

export const createNewDsrRequest = () => {
	return {
		type: types.CREATE_NEW_DSR_REQUEST
	};
};

export function createNewDsr(createNewDsrMetadata) {

	// POST API
	return async (dispatch) => {
		dispatch(createNewDsrRequest());
		try {
			const url = '/dsr/create';
			const headers = await auth.getAccessToken();

			const response = await axiosInstance.post(
				url,
				{createNewDsrMetadata},
				{ headers }
			);

			dispatch(createNewDsrSuccess(response));

		} catch (error) {
			dispatch(createNewDsrFailure(error));
		}
	};
}

// Duplicate existing DSR and create new ones
// POST method
export const createDuplicateDsrSuccess = (response) => {
	return {
		type: types.CREATE_DUPLICATE_DSR_SUCCESS,
		payload: response.data
	};
};

export const createDuplicateDsrFailure = (error) => {
	return {
		type: types.CREATE_DUPLICATE_DSR_FAILURE,
		error
	};
};

export const createDuplicateDsrRequest = () => {
	return {
		type: types.CREATE_DUPLICATE_DSR_REQUEST
	};
};

export function createDuplicateDsr(duplicateDsrMetadata) {

	// POST API
	return async (dispatch) => {
		dispatch(createDuplicateDsrRequest());
		try {
			const url = '/dsr/create-duplicate';
			const headers = await auth.getAccessToken();

			const response = await axiosInstance.post(
				url,
				{duplicateDsrMetadata},
				{ headers }
			);

			dispatch(createDuplicateDsrSuccess(response));

		} catch (error) {
			dispatch(createDuplicateDsrFailure(error));
		}
	};
}


// Edit configuration of existing DSRs (e.g. change status)
// PUT method
export const updateDsrSuccess = (response) => {
	return {
		type: types.UPDATE_DSR_SUCCESS,
    payload: response.data
	};
};


export const updateDsrFailure = (error) => {
	return {
		type: types.UPDATE_DSR_FAILURE,
    error
	};
};

export const updateDsrRequest = () => {
	return {
		type: types.UPDATE_DSR_REQUEST
	};
};

export function updateDsr(updateDsrMetadata) {
	// PUT API
	return async (dispatch) => {
		dispatch(updateDsrRequest());
		try {
			const url = '/dsr/update';
			const headers = await auth.getAccessToken();

			const response = await axiosInstance.put(
				url,
				updateDsrMetadata,
				{ headers }
			);
		
			dispatch(updateDsrSuccess(response));

			if (response.data.data.updateDsrStatus === 'success') {
				const {sourceEvent} = response.data.data || {};
				switch (sourceEvent) {
					case('save-room-access-code'):
					case('remove-room-access-code'):
					case('save-room-properties'): {
						const {dsrProperties, dsrTitle} = updateDsrMetadata || {};
						
						if (dsrProperties) dispatch(setDsrProperties(dsrProperties));
						if (dsrTitle) dispatch(setDsrTitle(dsrTitle));
						
						break;
					}
					case('save-dsr-main-contact'): {
						const {dsrPages} = updateDsrMetadata || {};
						dispatch(setDsrPages(dsrPages))
						break;
					}
					case('update-dsr-title'): {
						const {dsrTitle} = updateDsrMetadata || {};
						dispatch(setDsrTitle(dsrTitle)) 
						break;
					}
					case('save-guest-logo'): {
						const {dsrProperties} = updateDsrMetadata || {};
						dispatch(setDsrProperties(dsrProperties));
						dispatch(setDsrLogos(response.data.data.dsrDisplayLogos));
						break;
					}
					default: {
						break;
					}
				}
			}

			if (response.data.data.updatedDsrProperties) {
				dispatch(setDsrProperties(response.data.data.updatedDsrProperties))
			}

		} catch (error) {
			dispatch(updateDsrFailure(error));
		}
	};
}


// Reset update DSR
export const updateDsrReset = () => {
	return {
		type: types.UPDATE_DSR_RESET
	};
};

export function resetUpdateDsrOutcome() {

	return async (dispatch) => {

		try {
			dispatch(updateDsrReset());

		} catch (error) {
			dispatch(updateDsrFailure(error));
		}
	};
}

////////////////////////////////////////////////////////////
// PROSPECT DSR MANAGEMENT SERVICE
////////////////////////////////////////////////////////////

// Global PDSR actions
// export const setProspectorDsrManager = (p) => {
// 	return {
// 		type: SET_PROSPECTOR_DSR_MANAGER,
// 		payload: p
// 	};
// }

// Get Prospect DSR
export const getProspectDsrSuccess = (response) => {
	return {
		type: types.GET_PROSPECT_DSR_SUCCESS,
		payload: response.data
	};
};

export const getProspectDsrFailure = (error) => {
	return {
		type: types.GET_PROSPECT_DSR_FAILURE,
		error
	};
};

export const getProspectDsrRequest = () => {
	return {
		type: types.GET_PROSPECT_DSR_REQUEST
	};
};

export function getProspectDsr({pDsrId, status}) {
// No token required
	return async (dispatch) => {
		dispatch(getProspectDsrRequest());
		try {
			const thisUserIdToken = localStorage.getItem('USER_ID_TOKEN');

			const url = thisUserIdToken ? `/dsr/prospect/${pDsrId}?vuid=${thisUserIdToken}` : `/dsr/prospect/${pDsrId}`;
			const draftUrl = `/dsr/prospect/draft/${pDsrId}`
			const headers = await auth.getAccessToken(); 
			const response = (status === "active") ? await axiosInstance.get(url, {
				// headers // No log in required since external public user is going to use it
			}) : await axiosInstance.get(draftUrl, { headers });

			if (response.data.data.invalidRequest) {
				dispatch(setInvalidRequest(response.data.data.invalidRequest))
			} else {
				dispatch(setDsrPages(response.data.data.dsrPages))
				dispatch(setDsrLogos(response.data.data.dsrDisplayLogos))
				dispatch(setDsrDraftStatus(response.data.data.status))
				dispatch(setDsrTitle(response.data.data.dsrTitle))
				dispatch(setMainContactInfo(response.data.data.mainContactInfo))
				dispatch(setDsrProperties(response.data.data.dsrProperties))


				if (response.data.data.userPrivileges) {
					dispatch(setUserPrivileges(response.data.data.userPrivileges))
				}
				
				const isMobileView = displayModeCheck();
				dispatch(setIsMobileView(isMobileView))
				
				const isDsrAdministrator = (response.data.data.dsrOwnerId === getThisUserIdToken()) ? true : false
				if (isDsrAdministrator){
					dispatch(setIsDsrAdmin(isDsrAdministrator))
				}

				dispatch(setInvalidRequest(false))
			}

			dispatch(getProspectDsrSuccess(response));
		
		} catch (error) {
			dispatch(getProspectDsrFailure(error));
		}
	};
}


// POST method
export const createProspectDsrSuccess = (response) => {
	return {
		type: types.CREATE_NEW_PROSPECT_DSR_SUCCESS,
    payload: response.data
	};
};

export const createProspectDsrFailure = (error) => {
	return {
		type: types.CREATE_NEW_PROSPECT_DSR_FAILURE,
    error
	};
};

export const createProspectDsrRequest = () => {
	return {
		type: types.CREATE_NEW_PROSPECT_DSR_REQUEST
	};
};

export function createProspectDsr(createNewProspectDsrMetadata) {
	return async (dispatch) => {
		dispatch(createProspectDsrRequest());
		try {
			const url = '/dsr/prospect/create-new-pdsr';
			const headers = await auth.getAccessToken();

			const response = await axiosInstance.post(
				url,
				{createNewProspectDsrMetadata},
				{ headers }
			);

			dispatch(createProspectDsrSuccess(response));

		} catch (error) {
			dispatch(createProspectDsrFailure(error));
		}
	};
}

// Update Prospect DSR
// PUT method

export const updatePdsrSuccess = (response) => {
	return {
		type: types.UPDATE_PROSPECT_DSR_SUCCESS,
    payload: response.data
	};
};


export const updatePdsrFailure = (error) => {
	return {
		type: types.UPDATE_PROSPECT_DSR_FAILURE,
    error
	};
};

export const updatePdsrRequest = () => {
	return {
		type: types.UPDATE_PROSPECT_DSR_REQUEST
	};
};

export function updatePdsr(updatePdsrMetadata) {
	return async (dispatch) => {
		dispatch(updatePdsrRequest());
		try {
			const url = '/dsr/prospect/update';
			const headers = await auth.getAccessToken();

			const response = await axiosInstance.put(
				url,
				{ updatePdsrMetadata },
				{ headers }
			);

			dispatch(updatePdsrSuccess(response));

			if (response.data.data.updatePdsrStatus === 'success') {
				const {sourceEvent} = response.data.data || {};
				switch (sourceEvent) {
					case('save-prospect-dsr-properties'): {
						const {dsrProperties, dsrTitle} = updatePdsrMetadata || {};
						if (dsrProperties) dispatch(setDsrProperties(dsrProperties));
						if (dsrTitle) dispatch(setDsrTitle(dsrTitle));
						break;
					}
					case('update-pdsr-title'): {
						const {dsrTitle} = updatePdsrMetadata || {};
						dispatch(setDsrTitle(dsrTitle)) 
						break;
					}
					case('save-draft-prospect-pdsr'): {
						const {dsrPages} = updatePdsrMetadata || {};
						dispatch(setDsrPages(dsrPages)) 
						break;
					}
					case('unpublish-prospector-dsr'):
					default: {
						break;
					}
				}
			}

		} catch (error) {
			dispatch(updatePdsrFailure(error));
		}
	};
}

// Reset update Prospect DSR
export const updatePdsrReset = () => {
	return {
		type: types.UPDATE_PROSPECT_DSR_RESET
	};
};

export function resetUpdatePdsrOutcome() {

	return async (dispatch) => {

		try {
			dispatch(updatePdsrReset());

		} catch (error) {
			dispatch(updatePdsrFailure(error));
		}
	};
}

// PDSR conversion email notification
// POST method

export const sendProspectorQuerySuccess = (response) => {
	return {
		type: types.CREATE_PROSPECT_DSR_QUERY_SUCCESS,
    payload: response.data
	};
};

export const sendProspectorQueryFailure = (error) => {
	return {
		type: types.CREATE_PROSPECT_DSR_QUERY_FAILURE,
    error
	};
};

export const sendProspectorQueryRequest = () => {
	return {
		type: types.CREATE_PROSPECT_DSR_QUERY_REQUEST
	};
};

export function sendProspectorQuery(sendQueryMetadata) {

	return async (dispatch) => {
		dispatch(sendProspectorQueryRequest());
		try {
			const url = '/dsr/prospect/query'; 
			// Public exposed API
			// const headers = await auth.getAccessToken(); 

			const response = await axiosInstance.post(
				url,
				{sendQueryMetadata},
				{ 
					// headers       
				}
			);

			dispatch(sendProspectorQuerySuccess(response));

		} catch (error) {
			dispatch(sendProspectorQueryFailure(error?.response?.data?.error));
		}
	};
}

export const sendProspectorQueryReset = () => {
	return {
		type: types.CREATE_PROSPECT_DSR_QUERY_RESET
	};
};

export function resetSendProspectorQuery() {

	return async (dispatch) => {

		try {
			dispatch(sendProspectorQueryReset());

		} catch (error) {
			dispatch(sendProspectorQueryFailure(error));
		}
	};
}


////////////////////////////////////////////////////////////
// DSR Q&A SERVICES
////////////////////////////////////////////////////////////
// POST method
export const createFaqAnswerSuccess = (response) => {
	return {
		type: types.CREATE_FAQ_ANSWER_SUCCESS,
    payload: response.data
	};
};

export const createFaqAnswerFailure = (error) => {
	return {
		type: types.CREATE_FAQ_ANSWER_FAILURE,
    error
	};
};

export const createFaqRequest = () => {
	return {
		type: types.CREATE_FAQ_ANSWER_REQUEST
	};
};


export function createFaqAnswer(createFaqAnswerMetadata) {

	// POST API
	return async (dispatch) => {
		dispatch(createFaqRequest());
		try {
			const url = '/dsr/faq/create'; 
			const headers = await auth.getAccessToken(); 

			const response = await axiosInstance.post(
				url,
				{createFaqAnswerMetadata},
				{ headers }
			);

			dispatch(createFaqAnswerSuccess(response));

		} catch (error) {
			dispatch(createFaqAnswerFailure(error));
		}
	};
}

///

export const createFaqAnswerReset = () => {
  return {
    type: types.CREATE_FAQ_ANSWER_RESET
  };
};

export function resetCreateFaqAnswer() {

  return async (dispatch) => {

    try {
      dispatch(createFaqAnswerReset());

    } catch (error) {
      dispatch(createFaqAnswerFailure(error));
    }
  };
}

///
export const createFaqQuestionSuccess = (response) => {
	return {
		type: types.CREATE_FAQ_QUESTION_SUCCESS,
    payload: response.data
	};
};

export const createFaqQuestionFailure = (error) => {
	return {
		type: types.CREATE_FAQ_QUESTION_FAILURE,
    error
	};
};

export const createFaqQuestionRequest = () => {
	return {
		type: types.CREATE_FAQ_QUESTION_REQUEST
	};
};

export function createFaqQuestion(createFaqQuestionWithoutPostMetadata) {

	// POST API
	return async (dispatch) => {
		dispatch(createFaqQuestionRequest());
		try {
			const url = '/dsr/faq/create-question'; 
			const headers = await auth.getAccessToken(); 

			const response = await axiosInstance.post(
				url,
				{createFaqQuestionWithoutPostMetadata},
				{ headers }
			);

			dispatch(createFaqQuestionSuccess(response));

		} catch (error) {
			dispatch(createFaqQuestionFailure(error));
		}
	};
}

// Reset
export const createFaqQuestionReset = () => {
	return {
		type: types.CREATE_FAQ_QUESTION_RESET
	};
};

export function resetCreateFaqQuestion() {

	return async (dispatch) => {

		try {
			dispatch(createFaqQuestionReset());

		} catch (error) {
			dispatch(createFaqQuestionFailure(error));
		}
	};
}

// Edit Q&A
// PUT method
export const editFaqSuccess = (response) => {
	return {
		type: types.EDIT_FAQ_ANSWER_SUCCESS,
    payload: response.data
	};
};


export const editFaqFailure = (error) => {
	return {
		type: types.EDIT_FAQ_ANSWER_FAILURE,
    error
	};
};

export const editFaqRequest = () => {
	return {
		type: types.EDIT_FAQ_ANSWER_REQUEST
	};
};

export function editFaqAnswer(editFaqAnswerMetadata) {
	// PUT API
	return async (dispatch) => {
		dispatch(editFaqRequest());
		try {
			const url = '/dsr/faq/update';
			const headers = await auth.getAccessToken();

			const response = await axiosInstance.put(
				url,
				{editFaqAnswerMetadata},
				{ headers }
			);
			dispatch(editFaqSuccess(response));

		} catch (error) {
			dispatch(editFaqFailure(error));
		}
	};
}

export const editFaqAnswerReset = () => {
  return {
    type: types.EDIT_FAQ_ANSWER_RESET
  };
};

export function resetEditFaqAnswer() {

  return async (dispatch) => {

    try {
      dispatch(editFaqAnswerReset());

    } catch (error) {
      dispatch(editFaqFailure(error));
    }
  };
}

///
export const editFaqQuestionSuccess = (response) => {
	return {
		type: types.EDIT_FAQ_QUESTION_SUCCESS,
    payload: response.data
	};
};

export const editFaqQuestionFailure = (error) => {
	return {
		type: types.EDIT_FAQ_QUESTION_FAILURE,
    error
	};
};

export const editFaqQuestionRequest = () => {
	return {
		type: types.EDIT_FAQ_QUESTION_REQUEST
	};
};

export function editFaqQuestion(editFaqQuestionMetadata) {

	// POST API
	return async (dispatch) => {
		dispatch(editFaqQuestionRequest());
		try {
			const url = '/dsr/faq/edit-question'; 
			const headers = await auth.getAccessToken(); 

			const response = await axiosInstance.post(
				url,
				{editFaqQuestionMetadata},
				{ headers }
			);

			dispatch(editFaqQuestionSuccess(response));

		} catch (error) {
			dispatch(editFaqQuestionFailure(error));
		}
	};
}

// Reset
export const editFaqQuestionReset = () => {
	return {
		type: types.EDIT_FAQ_QUESTION_RESET
	};
};

export function resetEditFaqQuestion() {

	return async (dispatch) => {

		try {
			dispatch(editFaqQuestionReset());

		} catch (error) {
			dispatch(editFaqQuestionFailure(error));
		}
	};
}

// Deleting an answer from Q&A question (the question remains; updating the answer to empty string)
export const deleteFaqAnswerSuccess = (response) => {
	return {
		type: types.DELETE_FAQ_ANSWER_SUCCESS,
    payload: response.data
	};
};


export const deleteFaqAnswerFailure = (error) => {
	return {
		type: types.DELETE_FAQ_ANSWER_FAILURE,
    error
	};
};

export const deleteFaqAnswerRequest = () => {
	return {
		type: types.DELETE_FAQ_ANSWER_REQUEST
	};
};


export function deleteFaqAnswer(deleteFaqAnswerMetadata) {
	// PUT API
	return async (dispatch) => {
		dispatch(deleteFaqAnswerRequest());
		try {
			const url = '/dsr/faq/delete-answer';
			const headers = await auth.getAccessToken();

			const response = await axiosInstance.put(
				url,
				{deleteFaqAnswerMetadata},
				{ headers }
			);
			dispatch(deleteFaqAnswerSuccess(response));

		} catch (error) {
			dispatch(deleteFaqAnswerFailure(error));
		}
	};
}

// Reset
export const deleteFaqAnswerReset = () => {
	return {
		type: types.DELETE_FAQ_ANSWER_RESET
	};
};

export function resetDeleteFaqAnswer() {

	return async (dispatch) => {

		try {
			dispatch(deleteFaqAnswerReset());

		} catch (error) {
			dispatch(deleteFaqAnswerFailure(error));
		}
	};
}

// Deleting a question from Q&A page
export const deleteFaqQuestionSuccess = (response) => {
	return {
		type: types.DELETE_FAQ_QUESTION_SUCCESS,
    payload: response.data
	};
};


export const deleteFaqQuestionFailure = (error) => {
	return {
		type: types.DELETE_FAQ_QUESTION_FAILURE,
    error
	};
};

export const deleteFaqQuestionRequest = () => {
	return {
		type: types.DELETE_FAQ_QUESTION_REQUEST
	};
};


export function deleteFaqQuestion(deleteFaqQuestionMetadata) {
	// PUT API
	return async (dispatch) => {
		dispatch(deleteFaqQuestionRequest());
		try {
			const url = '/dsr/faq/delete-question';
			const headers = await auth.getAccessToken();

			const response = await axiosInstance.put(
				url,
				{deleteFaqQuestionMetadata},
				{ headers }
			);
			dispatch(deleteFaqQuestionSuccess(response));

		} catch (error) {
			dispatch(deleteFaqQuestionFailure(error));
		}
	};
}

// Reset
export const deleteFaqQuestionReset = () => {
	return {
		type: types.DELETE_FAQ_QUESTION_RESET
	};
};

export function resetDeleteFaqQuestion() {

	return async (dispatch) => {

		try {
			dispatch(deleteFaqQuestionReset());

		} catch (error) {
			dispatch(deleteFaqQuestionFailure(error));
		}
	};
}

// Manage shared doc
export const manageSharedDocSuccess = (response) => {
	return {
		type: types.MANAGE_SHARED_DOC_SUCCESS,
    payload: response.data
	};
};

export const manageSharedDocFailure = (error) => {
	return {
		type: types.MANAGE_SHARED_DOC_FAILURE,
    error
	};
};

export const manageSharedDocRequest = () => {
	return {
		type: types.MANAGE_SHARED_DOC_REQUEST
	};
};


export function manageSharedDoc(manageSharedDocMetadata) {

	// POST API
	return async (dispatch) => {
		dispatch(manageSharedDocRequest());
		try {
			const url = '/dsr/shared-doc/manage'; 
			const headers = await auth.getAccessToken();	

			const response = await axiosInstance.post(
				url,
				{manageSharedDocMetadata},
				{ headers }
			);

			const {dsrSharedDocs} = response.data.data || {};

			if (dsrSharedDocs) {
				dispatch(setDsrSharedDocs(dsrSharedDocs))
			}


			dispatch(manageSharedDocSuccess(response));

		} catch (error) {
			dispatch(manageSharedDocFailure(error));
		}
	};
}

///

export const manageSharedDocReset = () => {
  return {
    type: types.MANAGE_SHARED_DOC_RESET
  };
};

export function resetManageSharedDoc() {

  return async (dispatch) => {
    try {
      dispatch(manageSharedDocReset());

    } catch (error) {
      dispatch(manageSharedDocFailure(error));
    }
  };
}

// Upload files
export const uploadFilesRequest = () => {
	return {
		type: types.UPLOAD_FILES_REQUEST
	};
};

export const uploadFilesSuccess = (response) => {
	if (!response?.data) throw new Error('Response must include data field');

	return {
		type: types.UPLOAD_FILES_SUCCESS,
		payload: response.data
	};
};

export const uploadFilesFailure = (error) => {
	return {
		type: types.UPLOAD_FILES_FAILURE,
		error
	};
};

export const uploadFilesReset = () => {
	return async (dispatch) => {
		dispatch({ type: types.UPLOAD_FILES_RESET })
	};
};

// POST API
export function uploadFiles(uploadFilesMetadata) {

	return async (dispatch) => {
		dispatch(uploadFilesRequest());
		try {
			const url = '/dsr/files';
			const headers = await auth.getAccessToken();
			let data;

			if(uploadFilesMetadata.files && uploadFilesMetadata.files.length > 0){
				data = new FormData();
				data.append("dsrId", uploadFilesMetadata.dsrId)
				data.append("subWidgetId", uploadFilesMetadata.subWidgetId)
				uploadFilesMetadata.files.forEach(item => {
					data.append('file[]', item)
				})

			} else {
				data = uploadFilesMetadata
			}

			const response = await axiosInstance.post(
				url,
				data,
				{ headers, onUploadProgress: (progressEvent) => {
					let progressMetric;
					if (progressEvent.total > 0) {
						progressMetric = Math.round((progressEvent.loaded / progressEvent.total) * 100);
					} else {
						progressMetric = 100;
					}

					dispatch(setUploadProgressMetric(progressMetric));
				} }
			);

			dispatch(uploadFilesSuccess(response));

		} catch (error) {
			dispatch(uploadFilesFailure(error));
		}
	};
}

export function uploadFileMultithreaded(uploadFileMetadata) {
	const { dsrId, file, subWidgetId } = uploadFileMetadata;
	if (!dsrId || !file || !subWidgetId)
		throw new Error(
			"uploadFilesMultithreaded input does not have all required fields",
		);

	return async (dispatch) => {
		dispatch(uploadFilesRequest());
		try {
			const multithreadedUploaderOptions = {
				dsrId,
				subWidgetId,
				file,
			};
			
			const uploader = new Uploader(multithreadedUploaderOptions);
			let percentage = undefined;

			uploader
				.onProgress(({ percentage: newPercentage }) => {
					// to avoid the same percentage to be logged twice
					if (newPercentage === percentage) return;

					percentage = newPercentage;
					dispatch(setUploadProgressMetric(percentage));
				})
				.onFinish((response) => {
					if (!response?.data?.file?.Location || !response?.data?.file?.Key)
						throw new Error(
							"uploadFilesMultithreaded response does not have all required fields",
						);

					const result = {
						data: {
							files: [
								{
									url: response.data.file.Location,
									key: response.data.file.Key,
									subWidgetId: uploadFileMetadata.subWidgetId,
								},
							],
						},
					};
					dispatch(uploadFilesSuccess(result));
				})
				.onError((error) => {
					dispatch(uploadFilesFailure(error));
				});

			uploader.start();
		} catch (error) {
			dispatch(uploadFilesFailure(error));
		}
	};
}

// Delete files
export const deleteFilesRequest = () => {
  return {
    type: types.DELETE_FILES_REQUEST
  };
};

export const deleteFilesSuccess = (response) => {
  return {
    type: types.DELETE_FILES_SUCCESS,
    payload: response.data
  };
};

export const deleteFilesFailure = (error) => {
  return {
    type: types.DELETE_FILES_FAILURE,
    error
  };
};

export const deleteFilesReset = () => {
  return async (dispatch) => {
    dispatch({ type: types.DELETE_FILES_RESET })
  };
};

// POST API
export function deleteFiles(deleteFilesMetadata) {

  return async (dispatch) => {
    dispatch(deleteFilesRequest());

    try {
      const url = '/dsr/files';
      const token = await auth.getAccessToken();
      const data = {
        Objects: deleteFilesMetadata
      };


      const response = await axiosInstance.delete(url, {
        headers: token,
        data
      });

      dispatch(deleteFilesSuccess(response));

    } catch (error) {
      dispatch(deleteFilesFailure(error));
    }
  };
}