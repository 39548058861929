import { 
    MANAGE_DSR_CUSTOM_PAGES_ACCESSIBILITY_REQUEST,
    MANAGE_DSR_CUSTOM_PAGES_ACCESSIBILITY_SUCCESS,
    MANAGE_DSR_CUSTOM_PAGES_ACCESSIBILITY_FAILURE,
    MANAGE_DSR_CUSTOM_PAGES_ACCESSIBILITY_RESET } from '../../actions/dsr-page-accessibility-actions-types';

const INITIAL_STATE_CUSTOM_PAGE_ACCESSIBILITY = { 
    isLoading: false,
	results: "",
	error: ''
}

export function manageDsrCustomPagesAccessibilityReducer(state = INITIAL_STATE_CUSTOM_PAGE_ACCESSIBILITY, action) {
    switch (action.type) {
        case MANAGE_DSR_CUSTOM_PAGES_ACCESSIBILITY_RESET:
            return INITIAL_STATE_CUSTOM_PAGE_ACCESSIBILITY;
        case MANAGE_DSR_CUSTOM_PAGES_ACCESSIBILITY_REQUEST:
			return { ...state, isLoading: true, results: "" };
		case MANAGE_DSR_CUSTOM_PAGES_ACCESSIBILITY_SUCCESS:
			return { ...state, isLoading: false, results: action.payload };
		case MANAGE_DSR_CUSTOM_PAGES_ACCESSIBILITY_FAILURE:
			return { ...state, isLoading: false, error: 'Error while trying to manage custom pages accessibility' };
		default:
			return state;
    }
}
