import React, { useRef, useState, useEffect } from 'react'
import { Box, Flex } from "@chakra-ui/react";

import { RiArrowDropDownLine } from "react-icons/ri";
import { redirectToNewPage } from '../global/helpers/global-helpers';
import '../digital-sales-room/styles/dsr-mobile-display.scss'
import { getUserAccountType } from '../profile/helpers/user-helper';


export default function VaultMobileNavMenu({ currentMode }) {

  const dropdownRef = useRef(null);

  const [showMobilePageChoiceList, setShowMobilePageChoiceList] = useState(false)
  const [localState, setLocalState] = useState({
    mainDisplay: "",
    option1Display: "",
    option1Route: "",
    option2Display: "",
    option2Route: ""
  })

  useEffect(() => {
    // Add event listener when the component mounts
    document.addEventListener('mousedown', handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    switch (currentMode) {
      case ("prospectors"): {
        setLocalState({
          mainDisplay: "Prospectors",
          option1Display: "Rooms",
          option1Route: "/home",
          option2Display: "Received Rooms",
          option2Route: "/received-rooms"
        })
        break;
      }
      case ("received-rooms"): {
        setLocalState({
          mainDisplay: "Received Rooms",
          option1Display: "Rooms",
          option1Route: "/home",
          option2Display: "Prospectors",
          option2Route: "/prospectors"
        })
        break;
      }
      case ("dsr"):
      default: {
        setLocalState({
          mainDisplay: "Rooms",
          option1Display: "Prospectors",
          option1Route: "/prospectors",
          option2Display: "Received Rooms",
          option2Route: "/received-rooms"
        })
      }
    }
  }, [currentMode]);


  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      // Clicked outside the dropdown, close it
      setShowMobilePageChoiceList(false);
    }
  };

  const mobileDirectToNewPage = (route) => {
    if (route) {
      redirectToNewPage(route)
    } else {
      console.log('Error Code HBNMO5 : There are no routes provided')
    }
  }

  const { mainDisplay, option1Display, option1Route, option2Display, option2Route } = localState || {}
	const userType = getUserAccountType();

  if (userType !=="no-org-user") {
    return (
      <Box className="page-title-mobile" ref={dropdownRef} >
  
  
        <Flex onClick={() => setShowMobilePageChoiceList(!showMobilePageChoiceList)}
  
          justify='flex-start'
          align='center'
        >{mainDisplay} <Box ml='0.6em' as={RiArrowDropDownLine} />
          {/* Dropdown bar */}
          {showMobilePageChoiceList &&
            <Box className='mobile-page-nav-dropdown-menu__dropdown'
              display={(showMobilePageChoiceList) ? 'inline-block' : 'none'}
            >
              <Box className='mobile-page-nav-dropdown-menu__dropdown-option-display'
                onClick={() => mobileDirectToNewPage((option1Route))}
              >{option1Display}</Box>
  
              <Box className='mobile-page-nav-dropdown-menu__dropdown-option-display'
                onClick={() => mobileDirectToNewPage((option2Route))}
              >{option2Display}</Box>
  
            </Box>
          }
  
        </Flex>
      </Box>
    )
  } else {
    return (
      <Box className="page-title-mobile" >
      {/* No Dropdown bar */}
      <Flex justify='flex-start' align='center'>Received Rooms </Flex>
    </Box>
    )
  }

  
}