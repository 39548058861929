import auth from '../../../config/app-specific-config/auth';
import * as types from './marketing-actions-types';
import { axiosInstance } from '../../../config/app-specific-config/axiosConfig';


// Launchdeck - Join Waitlist
export const joinWaitlistSuccess = (response) => {
	return {
		type: types.CREATE_JOIN_WAITLIST_SUCCESS,
		payload: response.data
	};
};

export const joinWaitlistFailure = (error) => {
	return {
		type: types.CREATE_JOIN_WAITLIST_FAILURE,
		error
	};
};

export const joinWaitlistRequest = () => {
	return {
		type: types.CREATE_JOIN_WAITLIST_REQUEST
	};
};

export function joinWaitlist(payload) {
	return async (dispatch) => {
		dispatch(joinWaitlistRequest());
		try {
			const url = `/onboarding/join-waitlist`;
			const header = auth.getContentType();
			const response = await axiosInstance.post(url, payload, header);
	
			dispatch(joinWaitlistSuccess(response));
		} catch (error) {
			dispatch(joinWaitlistFailure(error));
		}
	};
}

// Launchdeck - Send Contact Message
export const sendContactLaunchdeckMessageSuccess = (response) => {
	return {
		type: types.SEND_LAUNCHDECK_MESSAGE_SUCCESS,
		payload: response.data
	};
};

export const sendContactLaunchdeckMessageFailure = (error) => {
	return {
		type: types.SEND_LAUNCHDECK_MESSAGE_FAILURE,
		error
	};
};

export const sendContactLaunchdeckMessageRequest = () => {
	return {
		type: types.SEND_LAUNCHDECK_MESSAGE_REQUEST
	};
};

export function sendContactLaunchdeckMessage(payload) {
	return async (dispatch) => {
		dispatch(sendContactLaunchdeckMessageRequest());
		try {
			const url = `/support/contact`;
			const header = auth.getContentType();
			const response = await axiosInstance.post(url, payload, header);
	
			dispatch(sendContactLaunchdeckMessageSuccess(response));
		} catch (error) {
			dispatch(sendContactLaunchdeckMessageFailure(error));
		}
	};
}

export const sendContactLaunchdeckMessageReset = () => {
	return {
		type: types.SEND_LAUNCHDECK_MESSAGE_RESET
	};
};


export const resetSendContactLaunchdeckMessage = () => {
	return async (dispatch) => {

		try {
			dispatch(sendContactLaunchdeckMessageReset());

		} catch (error) {
			dispatch(sendContactLaunchdeckMessageFailure(error));
		}
	};
}