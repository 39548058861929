import React, { useEffect, useRef, useState } from "react";
import { Alert, AlertIcon, Box, Flex } from "@chakra-ui/react";
import FileUploadProgress from "../../../dsr-create-post/FileUploadProgress";
import config from "../../../../../config/app-config";
import LaunchdeckButton from "../../../../global/components/helper-components/components/LaunchdeckButton";
import { PiFileVideo } from "react-icons/pi";

export const UploadedVideo = ({
  dsrId,
  subWidgetId,
  setSubWidgetContent,
  subWidgetContent,
  uploadFiles,
  uploadFileMultithreaded,
  uploadFilesState,
  uploadFilesReset,
  handleSaveItemByClickOutside,
  subWidgetAutoSave,
}) => {
  const [errorMessage, setErrorMessage] = useState(uploadFilesState.error);
  const { isLoading, results, error } = uploadFilesState;
  const containerRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  const [processingSubwidgetId, setProcessingSubwidgetId] = useState("")
  const {
    maxVideoFileSize,
    minVideoFileSizeForMultithreadedUpload,
    displayMaxFileSize,
  } = config;

  useEffect(() => {
    // Save uploaded file response to sub-widget content
    if (
      !isLoading &&
      !error &&
      Array.isArray(results?.files) &&
      results?.files.length > 0 &&
      results?.files[0]?.subWidgetId === subWidgetId
    ) {
      setSubWidgetContent(results.files);
      setProcessingSubwidgetId("")
      uploadFilesReset();
    }
  }, [results]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isFocused &&
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setIsFocused(false);
        handleSaveItemByClickOutside();
      }
    };

    if (isFocused) {
      setTimeout(() => window.addEventListener("click", handleClickOutside), 1);
    } else {
      window.removeEventListener("click", handleClickOutside);
    }
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, [isFocused]);

  useEffect(() => {
    //Auto save sub-widget content to local state after file uploading
    if (!isLoading && !error && subWidgetContent[0]?.key) {
      subWidgetAutoSave();
      setIsFocused(false);
    }
  }, [subWidgetContent]);

  const validateInput = (file) => {
    if (!file) {
      throw new Error("UploadedVideo must be object");
    }

    if (!file?.name) {
      throw new Error("UploadedVideo does not have filename");
    }

    if (file?.size > maxVideoFileSize) {
      // File size exceeds the limit
      setErrorMessage(
        `We support the display of video files that are ${displayMaxFileSize / (1024 * 1024)} MB or below.`,
      );
      throw new Error("File size exceeds the limit");
    }
  };

  const handleFileInputChange = (e) => {
    const file = e?.target?.files[0];

    try {
      validateInput(file);
    } catch (e) {
      if (process.env.NODE_ENV === "development") console.log(e);

      return;
    }
    setProcessingSubwidgetId(subWidgetId)
    file?.size <= minVideoFileSizeForMultithreadedUpload
      ? uploadFiles({ dsrId: dsrId, files: [file], subWidgetId: subWidgetId })
      : uploadFileMultithreaded({ dsrId, file, subWidgetId });
  };

  return subWidgetContent ? (
    <video
      className="loader-img"
      width="100%"
      src={subWidgetContent[0].url}
      controls
      autobuffer="true"
    ></video>
  ) : (
    <div ref={containerRef} className="video-uploader">
      <div className="video-preview">
        {subWidgetContent && !isLoading && (
          <Box position="relative" mt="20px">
            <video
              className="loader-img"
              width="100%"
              src={subWidgetContent[0].url}
              controls
              autobuffer="true"
            ></video>
          </Box>
        )}
        {isLoading && (processingSubwidgetId === subWidgetId) && (
          <Flex w="100%" py="2em" justify="center">
            <FileUploadProgress showLoader={isLoading} />
          </Flex>
        )}
      </div>
      {(!isLoading || (processingSubwidgetId !== subWidgetId)) && (
        <Flex
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          minHeight="12.5em"
          fontSize="0.875em"
        >
          <Box>
            <Box
              fill="brandDark.500"
              as={PiFileVideo}
              width="48px"
              height="3.5em"
              my="2em"
            />
          </Box>
          <div className="video-actions">
            <label>
              <input
                style={{ display: "none" }}
                type="file"
                accept="video/mp4,video/avi,video/mpeg,video/webm,video/x-matroska"
                onChange={handleFileInputChange}
              />
              <LaunchdeckButton
                bg="brandDark.500"
                color="white"
                px="2em"
                label="  Click to upload video"
              />
            </label>
          </div>

          <Flex
            direction="column"
            my="1.5em"
            fontSize="0.875em"
            color="brandDark.500"
            justify="center"
            align="center"
          >
            <Box mt="0.75em" color="gray.500" textAlign="center">
              Supported Formats
            </Box>
            <Box textAlign="center">MP4, AVI, MPEG, MKV or WEBM</Box>
            <Box mt="0.8em" textAlign="center">
              <Box color="gray.500">Upload limit</Box>
              200MB
            </Box>
          </Flex>
        </Flex>
      )}
      {errorMessage && (
        <Box justifyContent="center" mt="15px" mb="15px" px="1em">
          <Alert
            status={"error"}
            variant="solid"
            px="1em"
            bg="pink.50"
            rounded="0.25em"
            border="1px solid"
            borderColor="brandPink.500"
          >
            <AlertIcon boxSize={10} color="brandPink.500" />
            <Box fontSize="0.875em" color="brandDark.500" ml="1em" py="1em">
              <Box fontWeight="600">
                The current upload limit is {displayMaxFileSize / (1024 * 1024)}{" "}
                MB
              </Box>
              <Box mt="0.5em">
                For larger video files, we recommend embedding Youtube videos or
                provide Dropbox/Google drive links instead{" "}
              </Box>
            </Box>
          </Alert>
        </Box>
      )}
    </div>
  );
};
