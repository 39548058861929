import * as types from '../actions/duplicate-dsr-page-actions-types';


// Get Public DSR Config
const INITIAL_STATE_DUPLICATE_DSR_PAGE = {
	isLoading: false,
	results: {},
	error: '',
};

export function duplicateDsrPageReducer(state = INITIAL_STATE_DUPLICATE_DSR_PAGE, action) {
	switch (action.type) {
		case types.DUPLICATE_DSR_PAGE_RESET:
			return INITIAL_STATE_DUPLICATE_DSR_PAGE;
		case types.DUPLICATE_DSR_PAGE_REQUEST:
			return { ...state, isLoading: true, results: {} };
		case types.DUPLICATE_DSR_PAGE_SUCCESS:
			return { ...state, isLoading: false, results: action.payload };
		case types.DUPLICATE_DSR_PAGE_FAILURE:
			return { ...state, isLoading: false, error: 'Error while trying to duplicate DSR page' };
		default:
			return state;
	}
}
