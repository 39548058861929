import { SET_DSR_INVALID_REQUEST } from '../../actions/dsr-actions-types'

const INITIAL_STATE_DSR_INVALID_REQUEST = { request: false, }

export function dsrInvalidRequestReducer(state = INITIAL_STATE_DSR_INVALID_REQUEST, action) {
    switch (action.type) {
        case SET_DSR_INVALID_REQUEST:
            return { request: action.payload };
        default:
            return state;
    }
}