import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';

import { Box, Flex, Text, useToast } from "@chakra-ui/react";
import FormTextInput from '../../forms/TextInputField/components/FormTextInput';

import config from "../../../config/app-config";
import '../styles/dsr-display.scss';

import { manageSharedDoc, resetManageSharedDoc, uploadFileMultithreaded, uploadFiles, uploadFilesReset } from '../actions/dsr-actions';
import PageLoader from '../../global/components/helper-components/components/PageLoader';
import LaunchdeckButton from '../../global/components/helper-components/components/LaunchdeckButton';
import { generateRandomString, triggerLaunchdeckToast, validateUrlFormat } from '../../global/helpers/global-helpers';
import LaunchdeckCloseButton from '../../global/components/helper-components/components/LaunchdeckCloseButton';
import { MdKeyboardArrowRight } from "react-icons/md";
import { IoCloseOutline } from "react-icons/io5";
import { FaFileCircleCheck } from 'react-icons/fa6';
import FileUploadProgress from '../dsr-create-post/FileUploadProgress';


function CreateSharedDocForm({
  dsrId,
  handleCloseForm,
  //Actions
  manageSharedDoc,
  resetManageSharedDoc,
  uploadFiles,
  uploadFilesReset,
  uploadFileMultithreaded,
  // Redux
  isMobileView,
  uploadFilesState,
  dsrSharedDocOutcome
}) {

  const {
    maxVideoFileSize, // Biggest file size allocation
    minVideoFileSizeForMultithreadedUpload,
    displayMaxFileSize,
  } = config;

  const toast = useToast();

  // Input management
  const [docNameInput, setDocNameInput] = useState("")
  const [docLinkInput, setDocLinkInput] = useState("")
  const [localStatePickedFile, setLocalStatePickedFile] = useState(null)

  // Validation for required input
  const [showError, setShowError] = useState(false)
  // setShowError(false)

  // UX Management
  const [showLoader, setShowLoader] = useState(false)
  const [isLinkDocMode, setIsLinkDocMode] = useState(true)

  const [uploadErrorMessage, setUploadErrorMessage] = useState("");
  const [fileToUpload, setFileToUpload] = useState(null)
  const [uploadFileInProgress, setUploadFileInProgress] = useState(false)

  // Functions
  const resetForm = () => {
    setDocNameInput("")
    setDocLinkInput("")
    setFileToUpload(null)
    setUploadFileInProgress(false)
  }
  const onChangeHandler = (event) => {

    switch (event.target.name) {
      case ('doc-name'): {
        setDocNameInput(event.target.value.trim())
        break;
      }
      case ('doc-link'): {
        setDocLinkInput(event.target.value.trim())
        break;
      }
      default: {
        break;
      }
    }
  };

  const handleCreateNewDoc = () => {
    if (isLinkDocMode === true) {
      const isValidUrl = validateUrlFormat(docLinkInput)
      if (docNameInput && isValidUrl) {
        resetForm()
        setShowLoader(true)

        manageSharedDoc({
          dsrId: dsrId,
          manageEvent: "create-new-shared-doc",
          newDocName: docNameInput,
          newDocLink: docLinkInput
        })
      } else {
        setShowError(true)
      }
    } else {
      // Upload document mode    
      if (docNameInput && fileToUpload) {
        const decoySubWidgetId = "shared-doc-" + generateRandomString(10);

        fileToUpload?.size <= minVideoFileSizeForMultithreadedUpload
        ? uploadFiles({ dsrId, files: [fileToUpload] }) // Upload file first. Creating record will happen in useEffect once a file url is provided
        : uploadFileMultithreaded({ dsrId, file: fileToUpload, subWidgetId: decoySubWidgetId });
      } else {
        setShowError(true)
      }

    }

  }

  const handleClickCloseForm = () => {
    setShowLoader(false)
    handleCloseForm()
    resetForm()
  }

  useEffect(() => {
    if (dsrSharedDocOutcome) {      
      handleClickCloseForm()
      resetManageSharedDoc()
    }

  }, [dsrSharedDocOutcome]);

  useEffect(() => {
    if (uploadFilesState) {

      const { isLoading, results, error } = uploadFilesState;
      setUploadFileInProgress(isLoading)

      if (!isLoading && error) {
        toast(
          triggerLaunchdeckToast({
            useCase: "show-error-state",
            label: "Unfortunately we weren't able to upload the file. Please try again later.",
            isMobileView
          })
        )
      }

      if (!isLoading && !error && results.files) {

        manageSharedDoc({
          dsrId: dsrId,
          manageEvent: "create-new-shared-doc",
          newDocName: docNameInput,
          newDocLink: results?.files[0]?.url,
          fileKey: results?.files[0]?.key
        })

        uploadFilesReset();
      }

    }
  }, [uploadFilesState, setUploadFileInProgress, manageSharedDoc])

  const handleFileInputChange = async (e) => {
    const file = e?.target?.files[0];

    const validateInput = (file) => {
      let inputValidated = true;

      if (!file) {
        inputValidated = false;
        throw new Error("No valid file is chosen - please check and try again ");
      }

      if (!file?.name) {
        inputValidated = false;
        throw new Error("File does not have a filename. Please provide one and try uploading again");
      }

      if (file?.size > maxVideoFileSize) {
        inputValidated = false;
        // File size exceeds the limit
        setUploadErrorMessage(
          `Your file size exceeds the current limit of ${displayMaxFileSize / (1024 * 1024)}MB. For larger files, try using a shared Google drive or Dropbox file link`,
        );
        throw new Error("File size exceeds the limit");
      }

      setLocalStatePickedFile(file)

      return inputValidated;
    };

    try {
      setUploadErrorMessage("")
      const fileValidated = validateInput(file);
      !!fileValidated && setFileToUpload(file)

    } catch (e) {
      if (process.env.NODE_ENV === "development") console.log(e);

      return;
    }
  };

  if (uploadFileInProgress) {
    return (
      <Box w="100%" align="center" >
        <Box py='2em'>
          <FileUploadProgress showLoader={uploadFileInProgress} />
        </Box>
      </Box>
    )
  }

  if (showLoader) {
    return (
      <Box w="100%" align="center" >
        <Box px='1em'>
          <Box>
            <PageLoader />
          </Box>
        </Box>
      </Box>
    )
  }

  if (!showLoader) {
    return (
      <Box w="100%" align="center" >
        <Flex justify='space-between' align='center' bg='gray.50' roundedTopRight='0.5em' roundedTopLeft='0.5em' borderTop='1px solid' borderLeft='1px solid' borderRight='1px solid' borderColor='gray.300' p='0.5em 1.5em'>
          <Box>Share new document</Box>
          <LaunchdeckCloseButton handleCloseModal={() => handleClickCloseForm()} />
        </Flex>

        <Box borderLeft='1px solid' borderRight='1px solid' borderBottom='1px solid' borderColor='gray.300' roundedBottom='0.5em'>

          <Box p='1em 1.5em' >

            <Flex fontSize='0.875em' fontWeight='500' textAlign='left'>
              Name
            </Flex>

            <Box className="action-detail-text-value">
              <FormTextInput
                name={'doc-name'}
                onChange={onChangeHandler}
                // charLimit={titleCharacterLimit}
                fontSize='14px'
                mb='0.2em'
                // borderColor={showNoTitleError ? 'pink.500' : ""}
                defaultValue={docNameInput}
              />

            </Box>


            {isLinkDocMode ? <Box className="fade-in">
              <Flex fontSize='0.875em' fontWeight='500' textAlign='left' mt='1em'>
                Link
              </Flex>

              <Box className="action-detail-text-value">
                <FormTextInput
                  name={'doc-link'}
                  onChange={onChangeHandler}
                  // charLimit={titleCharacterLimit}
                  fontSize='14px'
                  mb='0.2em'
                  // borderColor={showNoTitleError ? 'pink.500' : ""}
                  defaultValue={docLinkInput}
                />
              </Box>
              <Flex justify='flex-end' mb='0.5em' w='100%'>
                <Flex mt='0.5em' alignItems='center' cursor='pointer' color='brand.500' onClick={() => { setShowError(false); setIsLinkDocMode(false) }}>
                  <Box cursor='pointer' fontSize='0.8em' color='gray.500'>Upload document instead</Box>
                  <Box as={MdKeyboardArrowRight} boxSize="0.875em" />
                </Flex>


              </Flex>
            </Box>
              :
              <>
                <Box justifyContent={"flex-start"} justifyItems={"left"} className="fade-in">
                  <Flex fontSize='0.875em' fontWeight='500' textAlign='left' mt='1em' mb='1em'>
                    Upload file
                  </Flex>

                  {fileToUpload ?
                    <Flex w='100%' justify='flex-start'>
                      <Flex position='relative' justify='flex-start' border='1px solid' borderColor='brandDark.500' p='0.875em' rounded='0.25em' maxW='20em'>
                        <Box position='absolute' top='0.5em' right='0.5em' mb='1em'>
                          <Box as={IoCloseOutline} boxSize='0.875em' cursor='pointer' onClick={() => { setFileToUpload(null); setLocalStatePickedFile(null) }} />
                        </Box>

                        <Box>
                          <Box
                            fill="brandDark.500"
                            as={FaFileCircleCheck}
                            width="2.5em"
                            height="1.5em"
                            my="0.75em"
                          />
                          <Box fontSize='0.875em' maxW='15em' className='truncate-long-text'>{localStatePickedFile?.name}</Box>
                        </Box>


                      </Flex>

                    </Flex>
                    :

                    <Flex w='100%' justify='flex-start'>
                      <label>
                        <input
                          type="file"
                          style={{ display: "none" }}
                          accept="video/mp4,video/avi,video/mpeg,video/webm,video/x-matroska,application/pdf,image/jpeg,image/jpg,image/png"
                          className="file-input"
                          onChange={handleFileInputChange}
                        />
                        <LaunchdeckButton
                          bg="brandDark.500"
                          color="white"
                          px="2em"
                          label="Choose"
                        />
                        <Box fontSize='0.75em' color='gray.500' mt='0.5em'>Max file size: 200MB</Box>
                      </label>
                    </Flex>

                  }
                </Box>

                <Flex justify='flex-end' mb='0.5em' w='100%'>
                  <Flex display={fileToUpload ? 'none' : 'flex'} mt='0.5em' alignItems='center' cursor='pointer' color='brand.500' onClick={() => { setShowError(false); setIsLinkDocMode(true) }}>
                    <Box cursor='pointer' fontSize='0.8em' color='gray.500'>Link document instead</Box>
                    <Box as={MdKeyboardArrowRight} boxSize="0.875em" />
                  </Flex>


                </Flex>
              </>
            }

          </Box>



          <Flex w='100%' my='1em' px='1em' py='0.625em' direction='column'>
            {uploadErrorMessage && <Text color='pink.500' fontSize='0.8em' mb='0.5em'>{uploadErrorMessage}</Text>}
            {showError && <Text color='pink.500' fontSize='0.8em' mb='0.5em'>{isLinkDocMode ? `Requires both a document name & valid link` : `Requires both a document name & valid file`}</Text>}
            <Flex justify='center' w='100%'>
              <LaunchdeckButton bg='brand.500' color='white' onClick={() => handleCreateNewDoc()} label='Share document' />
            </Flex>
          </Flex>
        </Box>

      </Box>
    )
  }
}


function mapStateToProps(state) {
  return {
    dsrSharedDocOutcome: state.dsrSharedDocOutcome.results.data,
    uploadFilesState: state.uploadFilesOutcome,
    isMobileView: state.mobileViewMode.isMobileView,
  };
}


const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      manageSharedDoc,
      resetManageSharedDoc,
      uploadFiles,
      uploadFilesReset,
      uploadFileMultithreaded
    },
    dispatch,
  )

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateSharedDocForm));
