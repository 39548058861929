import { Box, Flex, Fade } from "@chakra-ui/react";
import { redirectToNewPage } from '../../../global/helpers/global-helpers';
import LaunchdeckButton from "../../../global/components/helper-components/components/LaunchdeckButton";

export default function SignupUnexpectedError() {
  return (
    <Flex pt='2em' mb='2em' justify='center' align='center'>
      <Fade in={true}>
        <Box textAlign='center' pt='3em' maxW='400px'>
          <Box fontSize='1em' fontWeight='600'>Uh oh, there was an unexpected error on our end </Box>
          <Box color='brandDark.500' fontSize='0.875em' mt='1em'>A report on has been generated to help us fix it asap</Box>
          <Box color='brandDark.500' fontSize='0.875em' mt='1em'>Please try again later or contact us directly if you require urgent help</Box>
          <Flex direction='column' mt='3em' justify='center' alignItems='center'>
            <Flex justify='center' w='100%'>
              <LaunchdeckButton w='100%' bg='brandPink.500' color='white' onClick={() => redirectToNewPage('/contact-launchdeck')} label='Seek help' />
            </Flex>
          </Flex>
        </Box>
      </Fade>
    </Flex>
  )
}