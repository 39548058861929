import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import {
  getProspectDsr,
  updatePdsr,
  resetDsrPages,
  resetDsrSelectedDisplaySubpage,
  resetDsrTitle,
  updatePdsrReset,
  changeDsrPopupState,
  changeDsrModalState
} from '../actions/dsr-actions'
import DsrProspectDisplayPage from './DsrProspectDisplayPage'
import { signOut } from '../../auth/actions/auth-actions';

const ProspectDsrDisplayPageContainer = (props) => {
  return (
    <DsrProspectDisplayPage {...props} />
  )
}

const mapStateToProps = (state) => ({
  retrievedDsrProspect: state.dsrProspect.results.data,
  dsrPagesRedux: state.dsrPages.pages,
  dsrTitleRedux: state.dsrTitle.title,
  dsrInvalidRequestRedux: state.dsrInvalidRequest.request,
  dsrStatusRedux: state.dsrStatus.status,
  showDsrModalRedux: state.dsrModal.showDsrModal,
  showDsrModalLoaderRedux: state.showDsrModalLoader.show,
  userActionRequiredRedux: state.dsrPopup.UserActionRequired,
  dsrMainContact: state.dsrMainContact.mainContactInfo,

  signout: state.auth.data.signout
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getProspectDsr,
      resetDsrTitle,
      resetDsrSelectedDisplaySubpage,
      resetDsrPages,
      updatePdsrReset,
      changeDsrPopupState,
      changeDsrModalState,
      updatePdsr,
      signOut
    },
    dispatch,
  )

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProspectDsrDisplayPageContainer))
