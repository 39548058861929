import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { resendEmailVerification, getUserProfile } from '../actions/users-profile-actions';
import { getAdminOrgProfile } from '../../profile/actions/admin-user-actions'
import { bindActionCreators } from 'redux'
import UserProfilePage from './UserProfilePage'

const UserProfilePageContainer = (props) => {
  return (
    <UserProfilePage {...props} />
  )
}

const mapStateToProps = (state) => ({
  userProfile: state.profile.results.data,
  adminOrgProfile: state.adminOrgProfile.results.data,
  manageAdminOrgUsersOutcome: state.manageAdminOrgUsersOutcome.results.data,
  editProfileOutcome: state.editProfile.results.data,
  userProfilePicture: state.setUserProfilePicture.userProfilePicture,
  isMobileView: state.mobileViewMode.isMobileView,
  
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getUserProfile,
      resendEmailVerification,
      getAdminOrgProfile
    },
    dispatch,
  )

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserProfilePageContainer))
