import React, { useEffect, useRef, useState } from "react";

import { Box, Flex, Icon } from "@chakra-ui/react";
import PublicDsrAddEmojiDecoyButton from './PublicDsrAddEmojiDecoyButton';
import { displayEmoji } from "../../helpers/dsr-helpers";


export default function PublicDsrMessageToolbarDecoy(props) {

  const { postReactions,
    replyCount,
    messageIndex,

    handleOpenSignupOrLoginModal
  } = props || {};


  // User tagging feature management
  const [activateUserTagMenu, setActivateUserTagMenu] = useState(false)
  const buttonRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (buttonRef.current !== e.target) {
        setActivateUserTagMenu(false)
      }
    }
    if (activateUserTagMenu) {
      window.addEventListener('click', handleClickOutside)
    }

    return () => {
      window.addEventListener('click', handleClickOutside)
    };
  }, [activateUserTagMenu]);





  
  function renderCommentNumber(replyCount) {
    switch (true) {
      case (replyCount === 1): {
        return `1 comment`
      }
      case (replyCount > 0): {
        return `${replyCount} comments`
      }
      default: {
        return null;
      }
    }
  }

  return (
    <React.Fragment>

      <Flex align='center' pt='0.8em' justify='space-between' px='0.5em'>


        {/* // Reactions & Reply Management */}
        <Flex>
          {
            postReactions?.map((reaction, reactionIndex) => {

              const { reactionType, reactionCount } = reaction || {};
              const displayedEmoji = displayEmoji(reactionType);

              if (reactionCount > 0) {
               
                // const reactionProps = {
                //   ...reaction,
                //   postId,
                //   userMadeThisReaction
                // }

                return <Box key={`messageReaction_${reactionIndex}`}
                  px='0.6em'
                  py='0.1em'
                  mr='0.5em'
                  rounded='0.8em'
                  border="1px"
                  borderColor={'gray.100'}
                  bg={""}
                  cursor='pointer'
                  onClick={() => handleOpenSignupOrLoginModal()}
                >
                  <Flex>
                    <Box fontSize='0.8em' >  {displayedEmoji} </Box>
                    <Box pl='0.5em' fontSize='0.8em'> {reactionCount}</Box>
                  </Flex>

                </Box>
              } else return null;

            })
          }

        
            <Box key={`dsr_reactions_${messageIndex}`} minWidth='50px'>
              <PublicDsrAddEmojiDecoyButton
                handleOpenSignupOrLoginModal={handleOpenSignupOrLoginModal}
              />
            </Box> 

          <Flex cursor='pointer' pl='1em' align='center' color='gray.800' fontSize='0.75em' fontWeight='600' onClick={() => handleOpenSignupOrLoginModal()}>
            <Icon name="chat" />
            <Box pl='0.5em' >Comment</Box>
          </Flex>

      
        </Flex>

        <Flex width='100%' justifyContent='flex-end' display={['none','none','flex','flex']}>
          {(replyCount > 0) && <Box color='gray.500' fontSize='0.75em' lineHeight='1.125em'> {renderCommentNumber(replyCount)} </Box>}
        </Flex>
      </Flex>



    </React.Fragment>
  );

}

