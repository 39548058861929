import React, { memo, useEffect } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'

import { Box, Flex, Avatar, Grid, Skeleton } from "@chakra-ui/react";
import { getDsrAnalyticsCategoryDetails } from '../../analytics/actions/dsr-analytics-actions'
import { getUserDetailsUsingDsrMembersArray } from '../../profile/helpers/user-helper';

import { IoPersonCircleOutline } from 'react-icons/io5';
import { displayElapsedDurationSinceTimestamp, formatSecondsValueDisplay } from '../../global/helpers/time-format-helpers';
import EmptyStateStandardDisplay from '../../global/components/helper-components/components/EmptyStateStandardDisplay';
import { TbTimeDurationOff } from "react-icons/tb";

function DsrGuestStats({
  dsrId,
  guestStatsAvailable,
  dsrGuestAnalytics,
  handleLoadGuestData,
  getDsrAnalyticsCategoryDetails,
  dsrTeamMembersRedux
}) {

  useEffect(() => {

    if (!guestStatsAvailable && getDsrAnalyticsCategoryDetails) {

      getDsrAnalyticsCategoryDetails({ dsrId, requestType: 'retrieve-guest-analytics' })
      handleLoadGuestData()
    }
  }, [guestStatsAvailable, getDsrAnalyticsCategoryDetails]);


  const renderStatRows = () => {
    if (Array.isArray(dsrGuestAnalytics)) {
      const displayRows = dsrGuestAnalytics?.map((guestMember, memberIndex) => {
        const { userId, totalTimeInDsr, userLastActive } = guestMember || {};
        const userProfile = getUserDetailsUsingDsrMembersArray(dsrTeamMembersRedux, userId)
        const { memberName, memberProfilePicSrc, email, memberOrgName, memberOrgTitle } = userProfile || {}
        const supplementaryUserInfo = (memberOrgTitle && memberOrgName) ? `${memberOrgTitle}, ${memberOrgName}` : memberOrgName ? memberOrgName : email;

        return (
          <Box key={`dsr-analytics-stats-${memberIndex}`} py='0.5em' >
            <Flex w='100%' direction={["column", "column", "row", "row"]} 
              py='0.5em'
              border={['1px solid','1px solid',"none","none"]} 
              rounded='0.25em'
              borderColor='gray.200' alignItems='center' justifyContent='center'>
              <Flex w='100%' flex='3' gap='1em'>
                <Box display={['none','none','block','block']}>
                  {memberProfilePicSrc ?
                    <Flex justify='flex-start' align='center'>
                      <Avatar h='3.5em' w='3.5em' bg='gray.100' color='gray.700' name={memberName} src={memberProfilePicSrc} />
                    </Flex>
                    :
                    // Change this to fallback Avatar image with the same imagery. Currently this is slightly smaller than any Avatar image
                    <Flex justify='center' align='center' h='2.2em' w='2.2em' border='1px dotted' borderColor='gray.300' rounded='50%'>
                    <Avatar bg='white'  color='gray.300' icon={<IoPersonCircleOutline fontSize='2em' />} />
                    </Flex>
                  }
                </Box>
                <Box flex='4' p={['0.5em 1em','0.5em 1em','0em','0em']}>
                  <Box lineHeight='1.4em' fontWeight='500' fontSize='0.875em' color='brandDark.500' maxW='13em' className="text-ellipsis">{memberName}</Box>
                  <Box lineHeight='1em' fontWeight='400' fontSize='0.75em' color='gray.500' maxW='15em' className="text-ellipsis">{supplementaryUserInfo}</Box>
                </Box>
              </Flex>

              <Flex flex='2' w='100%' my={["0.5em","0.5em","",""]} px={['1em','1em','0','0']}>
                <Box flex='1' fontSize='0.875em' fontWeight='400' lineHeight='1.5em' color='brandDark.500'>
                  <Box fontSize='0.75em' color='gray.500' textAlign={['start','start','center','center']} display={['', '', 'none', 'none']}>Time Spent</Box>
                  <Box textAlign={["start","start","",""]} fontSize={["0.8em", "0.8em","",""]}>{formatSecondsValueDisplay(totalTimeInDsr)}</Box>
                </Box>
                <Box flex='1' fontSize='0.875em' fontWeight='400' lineHeight='1.5em' color='brandDark.500'>
                  <Box fontSize='0.75em' color='gray.500' textAlign={['start','start','center','center']} display={['', '', 'none', 'none']}> Last Visited</Box>
                  <Box textAlign={["start","start","",""]} fontSize={["0.8em", "0.8em","",""]}>{displayElapsedDurationSinceTimestamp(userLastActive)}</Box>
                </Box>
              </Flex>

            </Flex>
          </Box>
        )
      })

      if (displayRows.length === 0) {
        return <Box minH='50vh'>
          <EmptyStateStandardDisplay
            reactIcon={TbTimeDurationOff}
            infoText={'No visitor activities found during this time period'}
          />
        </Box>
      }

      return <Box minH='50vh'>{displayRows}</Box>;
    } else {
      const skeleton = Array.from({ length: 8 }, (_, index) => (
        <Flex w='100%' px='1.5em' py='0.5em' borderBottom='1px solid' borderColor='gray.200' key={`dsr-guest-stats-loading-bar-${index}`}>
          <Skeleton ml='0.2em' mr='0.1em' height="2em" width='2em' rounded='50%' />
          <Box flex='1' py='0.5em'>
            <Skeleton w='4em' h='1.25em' py='0.5em' />
          </Box>
          <Box flex='1' py='0.5em'>
            <Skeleton w='2em' h='1.25em' py='0.5em' />
          </Box>
          <Box flex='1' py='0.5em'>
            <Skeleton w='5em' h='1.25em' py='0.5em' />
          </Box>
        </Flex>
      ));

      return (
        <Box >
          { skeleton }
        </Box>
      )
    }
  }

  return (
    <Box w='100%' bg='white' rounded='0.5em' h='auto' px='1.5em'>

      <Flex w='100%' py='1em' borderBottom={['0','0','1px solid','1px solid']} borderColor={["",'','gray.200','gray.200']}>
        <Grid w='100%' templateColumns="3fr 1fr 1fr" px='1.5em' display={["none", "none", "grid", "grid"]}>
          <Box />
          <Box fontSize='0.75em' fontWeight='400' lineHeight='1.5em' color='gray.500'>
            Time Spent
          </Box>

          <Box fontSize='0.75em' fontWeight='400' lineHeight='1.5em' color='gray.500'>
            Last Visited
          </Box>
        </Grid>

          <Box display={["", "", "none", "none"]} fontSize='1.25em' fontWeight='500' lineHeight='1.3em' color='brandDark.500' pl={['0','0','1em','1em']}>
            Visitors
          </Box>
      </Flex>
      {renderStatRows()}
    </Box>

  )
}


const mapStateToProps = (state) => {
  return {
    dsrTeamMembersRedux: state.dsrTeamMembers.members,
  }
}


const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getDsrAnalyticsCategoryDetails
    },
    dispatch,
  )

export default memo(withRouter(connect(mapStateToProps, mapDispatchToProps)(DsrGuestStats)))