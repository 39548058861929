import { createStore, applyMiddleware, compose } from "redux";
// import thunk from 'redux-thunk';
import { thunk } from "redux-thunk";
import rootReducer from "./rootReducer";
import logger from "redux-logger";

// Initializing Redux Devtools
// const initializeReduxDevTools = window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();
const composeEnhancers =
  ("__REDUX_DEVTOOLS_EXTENSION_COMPOSE__" in window &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      trace: true,
      traceLimit: 25,
    })) ||
  compose;

const initialState = {}; // define your initial state

const store =
  process.env.REACT_APP_DIST_ENV === "production"
    ? createStore(rootReducer, initialState, applyMiddleware(thunk))
    : createStore(
        rootReducer,
        initialState,
        composeEnhancers(applyMiddleware(thunk, logger)),
      );

export default store;
