// Vault Get all DSRs for this user
export const GET_USER_VAULT_DSR_REQUEST = 'get_user_vault_dsr_request';
export const GET_USER_VAULT_DSR_SUCCESS = 'get_user_vault_dsr_success';
export const GET_USER_VAULT_DSR_FAILURE = 'get_user_vault_dsr_failure';
export const GET_USER_VAULT_DSR_RESET = 'get_user_vault_dsr_reset';

// Vault Search for DSRs
export const VAULT_SEARCH_REQUEST = 'vault_search_request';
export const VAULT_SEARCH_SUCCESS = 'vault_search_success';
export const VAULT_SEARCH_FAILURE = 'vault_search_failure';

// Received Rooms Search for DSRs
export const RECEIVED_ROOMS_SEARCH_REQUEST = 'received_rooms_search_request';
export const RECEIVED_ROOMS_SEARCH_SUCCESS = 'received_rooms_search_success';
export const RECEIVED_ROOMS_SEARCH_FAILURE = 'received_rooms_search_failure';

// Vault Search for Prospector DSRs
export const PROSPECTOR_SEARCH_REQUEST = 'prospector_search_request';
export const PROSPECTOR_SEARCH_SUCCESS = 'prospector_search_success';
export const PROSPECTOR_SEARCH_FAILURE = 'prospector_search_failure';