// export const BASE_URL = 'http://localhost:4000';
export const BASE_URL = 'https://f4fu0o1a42.execute-api.ap-southeast-1.amazonaws.com/ncap';

export const loomSdkStates = {
  sdkNotLoaded: "SDK not loaded",
  recordingNotSupported: "Recording not supported",
  loading: "Loading...",
  loaded: "Loaded",
}

export const loomRecordButtonStates = {
  record: 'Record',
  recording: 'Recording...',
  uploading: 'Uploading...',
}