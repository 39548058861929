import React, { Component } from 'react';
import claverTheme from '../../../../layout/components/layout/components/theme/claverTheme';
import { Box, ChakraBaseProvider, Heading, Divider, Flex, Text, Stack, Switch} from '@chakra-ui/react';
import GeneralHeader from '../../../../layout/components/header/components/GeneralHeader';
import GeneralPageFooter from '../../../../auth/components/GeneralPageFooter';





export default class TermsOfService extends Component {
  constructor(props) {
		super(props);

		this.state = {
      showTldr:false,
    };

 
    
	}
  render() {
    const showTldr = this.state.showTldr;
    return (
      <ChakraBaseProvider theme={claverTheme}>
        <GeneralHeader logoToMainLandingPage/>
        <Flex justify='flex-end'pr={['2em','2.2em','4em','6em']}>
          <Text mr={['0.5em','0.7em','1em','1em']} color='brand.500' fontWeight='semibold'>{showTldr===false ? 'View TLDR Version':'Turn Off TLDR'}</Text>
          <Switch value={this.state.showTldr} id="show-tldr-switch" boxSize="lg" onChange={()=> this.setState({showTldr:!this.state.showTldr})}/>
        </Flex>
        {showTldr===false ?
        <Flex direction='column' px={['2em','2.2em','4em','6em']} pb='8em' className='fade-in-right'>
        <Heading my='1em'> <Text fontSize='1.2em'>PRIVACY</Text></Heading>
        <Box>

          This Privacy Policy by Launchdeck ("Launchdeck", “Us”, or "We") explains what type of information may be gathered or tracked on our Website (“Site”) and through Launchdeck’s Products (collectively referred to as “Services”), how such information may be used.
          
          <Text mt='2em' mb='0.3em' color='brand.500' fontSize='1.2em'>OVERVIEW</Text>
          Launchdeck obtains Personal Data about you from various sources to provide our Services and to manage our Sites. “You” may be a visitor to one of our websites, a user of one or more of our Services (“User”), or a registered member of an User (“Member”).
          
          <Text mt='2em' mb='0.3em' color='brand.500' fontSize='1.2em'>COLLECTION OF PERSONAL DATA</Text>

          <Box mb='0.7em'>
          1. Personal Data is any information that relates to an identified or identifiable individual.</Box>

          <Box mb='0.7em'>
          2. One channel of acquiring Personal Data is when You proactively provide Personal Data through one of our Sites or Services. The Personal Data that you provide directly to us through our Sites and Services will be mostly apparent from the context in which you provide the data. In particular, when you contact us by email, chat, telephone, or via a contact form, we will store the data you share with us (your email address and possibly your name and phone number) in order to respond to your questions as well as to be able to provide you with the best possible service in the future.
          </Box>

          <Box mb='0.7em'>
          3. We may collect Personal Data without proactive consent through the utilization of publicly available resources if it is in compliance with applicable regulatory standards.
          </Box>

          <Box mb='0.7em'>
          4. All personal data collected by Launchdeck is driven by necessity in order to deliver Services, respond to inquiries, improve the Site and Services, or else governed by GDPR Article 6(1) for applicable EU individuals, or applicable local equivalent data protection guidelines for non-EU users.
          </Box>

          <Text mt='2em' mb='0.3em' color='brand.500' fontSize='1.2em'>YOUR RIGHTS</Text>
          The General Data Protection Regulation gives you specific rights around your personal data. For example, you can request to be informed about the information we hold and what we use it for, you can ask for a copy of the personal information we hold about you, you can ask us to correct any inaccuracies with the personal data we hold, you can ask us to stop sending you emails, or in some circumstances ask us to stop processing your details.
          <Text mt='2em' mb='0.3em' color='brand.500' fontSize='1.2em'>COOKIES</Text>
          Cookies are small text files that are stored on your browser or device by websites, apps, online media, and advertisements.

          <Box my='1em'>
          We use cookies to:
          </Box> 

          <Stack direction='column' spacing='1em' ml='3em'>
          <Box>•	Validate our authenticated Users</Box>
          <Box>•	Remember user preferences and settings</Box>
          <Box>•	Determine frequency of accessing our content</Box>
          <Box>•	Measure the effectiveness of advertising campaigns</Box>
          <Box>•	Analyse site visits and trends</Box>
          </Stack>
          
          <Text mt='2em' mb='0.3em' color='brand.500' fontSize='1.2em'>SECURITY</Text>
          We understand the sensitive nature of the data you share with us. We encrypt our data that is used for providing Users our services using high encryption standards to ensure security wherever possible. Where transmission of data is require, we use Secure Socket Layer (SSL) technology to enhance the security measures taken to protect data.
          
          <Text mt='2em' mb='0.3em' color='brand.500' fontSize='1.2em'>DATA RETENTION</Text>

          <Box mb='0.7em'>
          1. Personal Data shall be processed and stored for as long as required by Launchdeck for the purpose they have been collected for, and not any longer.
          </Box>
          <Box mb='0.7em'>
          2. If a retention period is applicable and that it expires, Personal Data shall be deleted. Therefore, the right to access, the right to erasure, the right to rectification and the right to data portability cannot be enforced after the expiration of the retention period.
          </Box>
          <Text mt='2em' mb='0.3em' color='brand.500' fontSize='1.2em'>QUESTIONS REGARDING THIS POLICY</Text>
          If you have questions concerning this Terms of Service, please contact us at this email address: support (at) launchdeck (dot) app 
          
          <Text mt='2em' mb='0.3em' color='brand.500' fontSize='1.2em'>MODIFICATIONS TO THIS POLICY</Text>
          Launchdeck may update this policy from time to time by posting a new or amended policy on this Site. If we make material changes to this policy affecting handling of your Personal Information we will provide you an opportunity to opt in/out under the terms of the revised policy through an email notice, or conspicuous notice on the Site or Services' home page, if applicable.

          </Box>

          <Divider my='2em' />
          <Heading my='1em'> <Text fontSize='1.2em'>TERMS OF SERVICE</Text></Heading>
         

          <Box>
          You may use the Services only if you agree to form a binding contract with Launchdeck. If you are accepting these Terms and using the Services on behalf of a company, organization, government, or other legal entity, you represent and warrant that you are authorized to do so and have the authority to bind such entity to these Terms, in which case the words “you” and “your” as used in these Terms shall refer to such entity.
          This agreement is between you and Launchdeck, which allows Launchdeck to provide you access to and usage of an Internet based software service.

          <Text mt='2em' mb='0.3em' color='brand.500' fontSize='1em' fontWeight='semibold'>Content on the Services</Text>
          You are responsible for your use of the Services and for any information and content you provide, including compliance with applicable laws, rules, and regulations.
          <Box my='1em'>
          The templates made available through Launchdeck, provide suggested boilerplate content. They require you to read, understand, and provide your own information to meet your own specific needs. The templates, including any provided content within, shall not be considered as legal advice.
          You retain the rights to any content or information you submit, display on or through our Services. You agree that such Content will not contain material subject to copyright or other proprietary rights. You grant Launchdeck the right to use, display and distribute the information to the intended party that you indicate through our Services.
          </Box>

          <Text mt='2em' mb='0.3em' color='brand.500' fontSize='1em' fontWeight='semibold'>Using the Services</Text> 
          Our Services evolve constantly, and therefore may change from time to time, at our discretion. We may stop providing the Services or any features within the Services to you or to Users generally. We also retain the right to create limits on use and storage at our sole discretion at any time. We may also remove or refuse to distribute any Content on the Services, limit distribution or visibility of any Content on the service, suspend or terminate users, and reclaim usernames without liability to you.
          <Box my='1em'>You agree not to misuse our Services, for example, by interfering with them or accessing them using a method other than the interface that we provide. You may not do any of the following while accessing or using the Services:
          </Box>

          <Stack direction='column' spacing='1em' ml='3em'>
          <Box>(i)	access, tamper with, or use non-public areas of the Services, Launchdeck’s computer systems or its technical delivery systems;</Box>
          <Box>(ii)	probe, scan, or test the vulnerability of any system or network or breach or circumvent any security or authentication measures;</Box>
          <Box>(iii)	access or search or attempt to access or search the Services by any means (automated or otherwise) other than through our currently available, published interfaces that are provided by Launchdeck;</Box>
          <Box>(iv)	forge any TCP/IP packet header or any part of the header information in any email or posting, or in any way use the Services to send altered, deceptive or false source-identifying information; or</Box>
          <Box>(v)	interfere with, or disrupt, (or attempt to do so), the access of any user, host or network, including, without limitation, sending a virus, overloading, flooding, spamming, mail-bombing the Services, or by scripting the creation of Content in such a manner as to interfere with or create an undue burden on the Services.</Box>
          </Stack>
          
          <Box my='1em'>
          We also reserve the right to access, read, preserve, and disclose any information as we reasonably believe is necessary to:
          </Box>
          <Stack direction='column' spacing='1em' ml='3em'>
          <Box>(i)	satisfy any applicable law, regulation, legal process or governmental request,</Box>
          <Box>(ii)	enforce the Terms, including investigation of potential violations hereof,</Box>
          <Box>(iii)	detect, prevent, or otherwise address fraud, security or technical issues,</Box>
          <Box>(iv)	respond to user support requests, or</Box>
          <Box>(v) protect the rights, property or safety of Launchdeck and its Users.</Box>
          </Stack>
          
          <Text mt='2em' mb='0.3em' color='brand.500' fontSize='1em' fontWeight='semibold'>Your Account</Text> 
          You may need to create an account to use some of our Services. You are responsible for safeguarding your account through the use of a strong password and limit its use to this account. We cannot and will not be liable for any loss or damage arising from your failure to comply with the above.
          <Box my='1em'>If you added your phone number to your account and you later change or deactivate that phone number, you must update your account information to help prevent us from communicating with anyone who acquires your old number.
          </Box>

          <Text mt='2em' mb='0.3em' color='brand.500' fontSize='1em' fontWeight='semibold'>Ending These Terms</Text> 
          You may end this agreement with Launchdeck at any time by deactivating your account and discontinuing your use of the Services.
          
          <Box my='1em'>
          We may suspend or terminate your account or cease providing you with all or part of the Services if we reasonably believe: 
          </Box>
          <Stack direction='column' spacing='1em' ml='3em'>
            <Box>(i) you have violated these Terms </Box>
            <Box>(ii) you create risk or possible legal exposure for us; </Box>
            <Box>(iii) your account is involved in unlawful conduct, </Box>
            <Box>(iv) your account should be removed due to prolonged inactivity; or </Box>
            <Box>(v) our provision of the Services to you is no longer commercially viable. </Box>
          </Stack>

          <Box my='1em'>We will make reasonable efforts to notify you by the email address associated with your account or the next time you attempt to access your account, depending on the circumstances. These Terms survive the deactivation or termination of your account.</Box>
          <Box my='1em'>
          If any term of this agreement is invalid or unenforceable, the other terms will remain in effect.
          </Box>

          <Text mt='2em' mb='0.3em' color='brand.500' fontSize='1em' fontWeight='semibold'>Liability</Text> 
          By using the Services you agree that Launchdeck, its officers, directors, employees, agents representatives, partners and licensors, any liability is limited to the maximum extent permissible in your country of residence.

          If a third-party claim is brought against Launchdeck, or requires Launchdeck to respond to a legal process, related to an Agreement between You and the third-party, You must indemnify and hold Launchdeck harmless from and against all damages, losses, and expenses of any kind (including reasonable legal fees and costs) related to such claim or request.

          <Text mt='2em' mb='0.3em' color='brand.500' fontSize='1em' fontWeight='semibold'>General</Text> 
          If you have further questions, please get in contact with us directly at support@Launchdeck.app.


      </Box>
      
      
      </Flex>
      :
      <Flex direction='column' px='6em' pb='8em' className='fade-in-left'>
      <Heading my='1em'> <Text fontSize='1.2em'>PRIVACY</Text></Heading>
        <Box>

          This Privacy Policy explains what information is gathered through the use of Launchdeck, and how they are used.
          
          <Box my='1em'>
          This TLDR summary version of our Privacy and Terms and Services is to help you understand quickly what we are trying to communicate, and it is not a substitute for its full original version. If you think there are unclear or contradictory portions in this TLDR summary, please refer to the original (non-TDLR) version where the terms and conditions there shall apply.  
          </Box>

             
          <Text mt='2em' mb='0.3em' color='brand.500' fontSize='1.2em'>COLLECTION OF PERSONAL DATA</Text>
          We collect Personal Data about you, primarily for the purpose of providing our services to you. The personal data is obtain either directly from asking you, or from public domains. We follow the stringent GDPR and Singapore’s PDPA standards. 
          

          <Text mt='2em' mb='0.3em' color='brand.500' fontSize='1.2em'>YOUR RIGHTS</Text>
          You have access to your personal information, and can request for us to correct, edit, and remove them at any time.
          
           <Text mt='2em' mb='0.3em' color='brand.500' fontSize='1.2em'>COOKIES</Text>
          We use Cookies -  small text files that are stored on your browser or devices - to:


          <Stack direction='column' spacing='1em' ml='3em'>
          <Box>•	Validate our authenticated Users</Box>
          <Box>•	Remember user preferences and settings</Box>
          <Box>•	Determine frequency of accessing our content</Box>
          <Box>•	Measure the effectiveness of advertising campaigns</Box>
          <Box>•	Analyse site visits and trends</Box>
          </Stack>
          
          <Text mt='2em' mb='0.3em' color='brand.500' fontSize='1.2em'>SECURITY</Text>
          We encrypt our data generated or provided to us using high encryption standards to protect data security.

          <Text mt='2em' mb='0.3em' color='brand.500' fontSize='1.2em'>DATA RETENTION</Text>

          <Box mb='0.7em'>
         Launchdeck stores and uses your data to provide the services or purposes you provided them for, and not any longer.
          </Box>
          <Box mb='0.7em'>
          If a retention period is applicable (e.g. legal reasons), your Personal Data shall then be deleted only after its expiry. They will then no longer be available for access or further usage by any party thereafter.
          </Box>

          <Text mt='2em' mb='0.3em' color='brand.500' fontSize='1.2em'>QUESTIONS REGARDING THIS POLICY</Text>
          Send an email over to this address - support(at)launchdeck(dot)app 
          
          <Text mt='2em' mb='0.3em' color='brand.500' fontSize='1.2em'>MODIFICATIONS TO THIS POLICY</Text>
          As we evolve Launchdeck, we may need to update this policy to keep up with the new stuff that we roll out. 

          </Box>

          <Divider my='2em' />
          <Heading my='1em'> <Text fontSize='1.2em'>TERMS OF SERVICE</Text></Heading>
         

          <Box>
         You may use Launchdeck only if you agree to these Terms of Service. If you use Launchdeck on behalf of an organization, it is because you have the authority to agree to these Terms on behalf of the organization.

         <Box my='1em'>
         This agreement is between you and Launchdeck, for the purpose for Launchdeck to provide you access and use of an Internet based software service. 
         </Box>

          <Text mt='2em' mb='0.3em' color='brand.500' fontSize='1em' fontWeight='semibold'>Content on the Services</Text>
          You are fully responsible for your use of the Services and for any information and content you provide. 
          <Box my='1em'>
          Launchdeck’s agreement templates act as suggested boilerplate texts. You need to read, understand, and provide your own information to make it fit your specific needs. You should not consider the use of the templates as legal advice. There is no attorney-client relationship established. (If you are dealing with a highly complex situation, our recommendation is to seek help from established legal professionals)
          </Box>

          <Box my='1em'>
          You continue to own the content or information you provide through our Services. As such, you agree that you have the rights to access and use them, and grant Launchdeck the right to use, display and send the information to your peers on our platform.
          </Box>

          <Text mt='2em' mb='0.3em' color='brand.500' fontSize='1em' fontWeight='semibold'>Using the Services</Text> 
          Our Services evolve constantly, and we may change them to improve our services. 
          <Box my='1em'>In the spirit of improving the Launchdeck user community and services, we ask you and all other users not to misuse our Services. Examples of misuse, not limited to this list, include: 
          </Box>

          <Stack direction='column' spacing='1em' ml='3em'>
          <Box>(i)	access, tamper with, or use non-public areas of Launchdeck’s server its delivery systems. </Box>
          <Box>(ii)	attempt to bypass or exploit cyber security measures; </Box>
          <Box>(iii)	access to access Launchdeck’s Services outside the available and published interfaces provided by Launchdeck; </Box>
          <Box>(iv)	forge your identity while using Launchdeck; or </Box>
          <Box>(v)	manipulating the use or access of other users, host or the network. </Box>
          </Stack>
          
          <Box my='1em'>
          In extraordinary situations however, we would need to reserve the right to access, read, preserve, and disclose information as we believe is necessary to 
          </Box>
          <Stack direction='column' spacing='1em' ml='3em'>
          <Box>(i) satisfy regulatory and legal obligations, </Box>
          <Box>(ii) enforce the Terms, and investigate potential violations,</Box>
          <Box>(iii)	troubleshoot fraud, security or technical issues, </Box>
          <Box>(iv)	respond to user support requests, or </Box>
          <Box>(v)	protect the rights, property or safety of Launchdeck’s Users. </Box>
          </Stack>
          
          <Text mt='2em' mb='0.3em' color='brand.500' fontSize='1em' fontWeight='semibold'>Your Account</Text> 
          You are responsible to safeguard your Launchdeck account through the use of a strong and unique password. We will not be in a position to help mitigate your loss or damage if your security and personal information are made public because of failure to comply with the above.
          <Box my='1em'>If you had changed your contact details in your profile, you must update your account information so that we can continue to reach you successfully if ever required.</Box>

          <Text mt='2em' mb='0.3em' color='brand.500' fontSize='1em' fontWeight='semibold'>Ending These Terms</Text> 
          You may end this Agreement by deactivating your accounts and discontinuing your use of our Services. 
          
          <Box my='1em'>
          We may be forced to suspend or terminate your account if we believe you have violated these Terms, engaged in illegal acts, or conducted acts that are unacceptable to the User community. Even after your account is deactivated, the Terms will continue to apply to your data and content provided/created.
          </Box>
    
          <Box my='1em'>
          If any part of this agreement is not applicable or invalid, the rest of the agreemnt will remain in effect. 
          </Box>

          <Text mt='2em' mb='0.3em' color='brand.500' fontSize='1em' fontWeight='semibold'>Liability</Text> 
          If you get into a dispute resulted from an Agreement between you and a third party, Launchdeck can support you to stake your legal claims by providing information and documentation that you and your counterparty had created on Launchdeck systems. 

          However, Launchdeck will not be liable for any third-party claims brought to you. You must indemnify and hold Launchdeck free from any responsibilities, damages, losses, or expenses in such situations. 

          <Text mt='2em' mb='0.3em' color='brand.500' fontSize='1em' fontWeight='semibold'>General</Text> 
          If you have further questions, please get in contact with us directly at support@Launchdeck.app.


      </Box>

      </Flex>}
      <GeneralPageFooter />
      </ChakraBaseProvider>
    );
  }
}
