import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { Box, Modal, ModalOverlay, ModalBody, ModalContent, Flex  } from "@chakra-ui/react";
import CreateNewCategoryForm from './CreateNewCategoryForm';
import LaunchdeckCloseButton from '../../global/components/helper-components/components/LaunchdeckCloseButton';



function CreateNewMapCategoryModal(props) {

  const { showModal, handleCloseModal, dsrId, 
    // Properties specific to creating new action from Updates page
    createdFromCreatePostModule,
    //Redux state
    createNewDsrActionOutcome
  } = props || {};

  useEffect(() => {
    if (createNewDsrActionOutcome) {
      if (createdFromCreatePostModule) {
        window.location.href = `/dsr/${dsrId}?view=action-list`;
        handleCloseModal();
      }
    }

  }, [createNewDsrActionOutcome, createdFromCreatePostModule, handleCloseModal, dsrId]);

  if (!showModal) {
    return null;
  }

  if (showModal) {
    return (
      <Modal isOpen={true} closeOnOverlayClick={false} isCentered={true} preserveScrollBarGap={true} >
        <ModalOverlay />
        <ModalContent rounded='0.25em' minW={['90%', '500px']} maxW='700px'>
          <ModalBody px='0em'>
            <Flex direction='column' justify='space-around' alignItems='center' w='100%'>

              <Flex justify='space-between' align='center' w='100%' borderBottom='1px solid' borderBottomColor='gray.300'>
                <Flex px='1.5em' py='0.5em' justify='flex-start' align='center'>
                  <Box fontWeight='500' fontSize='1.1em' lineHeight='1.375em' color='brandDark.500'>Create new category</Box>
                </Flex>
                <Box pr='1.5em'>
                  <LaunchdeckCloseButton handleCloseModal={() => handleCloseModal()} />
                </Box>
              </Flex>

              <Flex justify='center' mt='1em' w='100%'>
                <CreateNewCategoryForm
                  dsrId={dsrId}
                  handleCloseModal={handleCloseModal}
                />

              </Flex>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    )
  }


}

function mapStateToProps(state) {
  return {
  // Action List
  createNewDsrActionOutcome: state.createNewDsrActionOutcome.results.data
  };
}


export default withRouter(connect(mapStateToProps, null)(CreateNewMapCategoryModal));
