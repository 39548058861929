import React, { useEffect, useState } from 'react';
import { Box, Flex, Tooltip, Avatar, Image} from "@chakra-ui/react";

import { redirectToNewPage, validateUrlFormat } from '../../../global/helpers/global-helpers';

import '../../styles/vault.scss';
import { IoCalendarOutline } from "react-icons/io5";
import { formatDisplayDateOnly } from '../../../digital-sales-room/helpers/timestamp-display-helper';
import LaunchdeckTag from '../../../global/components/helper-components/components/LaunchdeckTag';

export default function DsrGuestCardDisplay({ dsrData }) {
  const { dsrId, dsrTitle, dsrOwnerUsername,
    status, ownerProfilePic, 
    audienceLogoCardDisplay, updatedAt
  } = dsrData || {};

  const [redirectLink, setRedirectLink] = useState('')
  const isRevertedDraft  = (status === "reverted-draft") ? true : false

  useEffect(() => {
    if (status) {
      switch (status) {
        case ('reverted-draft'):
        case ('draft'): {
          // setRedirectLink(`/draft/${dsrId}`)
          break;
        }
        case ('active'): {
          setRedirectLink(`/dsr/${dsrId}`)
          break;
        }
        default: {
          console.log(`There has been an error - there is no status for this dsr card ${dsrId}`)
          break;
        }
      }
    }

  }, [dsrId, status]);

  // TODO: Should display Host / DSR owner logo instead. Else it will just be their own logos
  const renderAudienceLogo = () => {
    const { orgLogo, orgName } = audienceLogoCardDisplay || {};
    const isUrl = orgLogo ? validateUrlFormat(orgLogo) : false ;

    if (isUrl) {
      if (orgName) {
        return (
          <Box boxSize='2em' mr='0.5em'>
            <Tooltip label={orgName} fontSize='0.8em' fontWeight='400' placement="bottom" bg="#4A5568" rounded='0.25em'>
               <Box p='0.2em' rounded='0.25em' border='1px solid' borderColor='gray.200'>
                <Image maxH='2em' src={orgLogo} />
              </Box> 
            </Tooltip>
          </Box>
        )
      } else if (!orgName) {
        return (
          <Box mr='0.5em'>
          <Box p='0.2em' rounded='0.25em' border='1px solid' borderColor='gray.200'>
            <Image maxH={'2em'} src={orgLogo} />
          </Box>
        </Box>
        )
      }
     

    } else if (orgName) {
      return (
        <Flex className="status-tag__draft-container status-tag__text-light" mr='0.5em'>
          <Box >{orgName}</Box>
        </Flex>
      )
    } else return null;

  }

  return (
    <Flex className="card-display" >

      <Box className="card-content-stack">
        <Flex align='center' justify='space-between' w='100%'>
          <Box opacity={isRevertedDraft ? 0.5 : 1.0}>{renderAudienceLogo()}</Box>
          {isRevertedDraft && <LaunchdeckTag bg='gray.600' color='white' label='In draft state' />}
        </Flex>
      </Box>


      <Box w='100%'  opacity={isRevertedDraft ? 0.5 : 1.0} onClick={() => !isRevertedDraft && redirectToNewPage(redirectLink)} cursor={!isRevertedDraft && 'pointer'}>
        <Box className="card-content-stack__column">
          <Box maxWidth={['60vw', '20vw']} className="dsr-title-display" title={dsrTitle ? dsrTitle : "-"}>
            {dsrTitle ? dsrTitle : "-"}
          </Box>
          <Flex className="stats-display">
            <Flex align='center'>
              <Box as={IoCalendarOutline} />
              <Box ml='0.25em'> {formatDisplayDateOnly(updatedAt)} </Box>
            </Flex>

          </Flex>
        </Box>

        <Box className="card-content-stack" mt='0.75em'>
          <Flex justify='flex-start' gap={4}>
            <Box boxSize='2em' opacity={isRevertedDraft ? 0.5 : 1.0}>
              <Avatar h="3em" w="3em" name={dsrOwnerUsername} bg='gray.100' color='gray.700' src={ownerProfilePic} />
            </Box>
          </Flex>
        </Box>

      </Box>
    </Flex>


  )
}


