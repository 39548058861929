import * as types from '../actions/users-profile-actions-types';


const INITIAL_STATE = {
	isLoading: false,
	results: {},
	error: '',
};

export function profileReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case types.GET_USER_PROFILE_REQUEST:
			return { ...state, isLoading: true, results: {} };
		case types.GET_USER_PROFILE_SUCCESS:
			return { ...state, isLoading: false, results: action.payload };
		case types.GET_USER_PROFILE_FAILURE:
			return { ...state, isLoading: false, error: 'Error while trying to fetch user profiles' };
		default:
			return state;
	}
}



const INITIAL_EDIT_PROFILE_STATE = {
	isLoading: false,
	results: {},
	error: '',
};

export function editProfileReducer(state = INITIAL_EDIT_PROFILE_STATE, action) {
	switch (action.type) {
		case types.EDIT_USER_PROFILE_REQUEST:
			return { ...state, isLoading: true, results: {} };
		case types.EDIT_USER_PROFILE_SUCCESS:
			return { ...state, isLoading: false, results: action.payload };
		case types.EDIT_USER_PROFILE_FAILURE:
			return { ...state, isLoading: false, error: 'Error while trying to edit user profile' };
		default:
			return state;
	}
}


const INITIAL_GET_USER_SETTINGS_STATE = {
	isLoading: false,
	results: {},
	error: '',
};

export function getUserSettingsReducer(state = INITIAL_GET_USER_SETTINGS_STATE, action) {
	switch (action.type) {
		case types.GET_USER_SETTINGS_REQUEST:
			return { ...state, isLoading: true, results: {} };
		case types.GET_USER_SETTINGS_SUCCESS:
			return { ...state, isLoading: false, results: action.payload };
		case types.GET_USER_SETTINGS_FAILURE:
			return { ...state, isLoading: false, error: 'Error while trying to retrieve user settings' };
		default:
			return state;
	}
}

const INITIAL_UPLOAD_PROFILE_PICTURE_STATE = {
	isLoading: false,
	results: {},
	error: '',
};

export function uploadProfilePictureReducer(state = INITIAL_UPLOAD_PROFILE_PICTURE_STATE, action) {
	switch (action.type) {
		// case types.UPLOAD_PROFILE_PICTURE_RESET: 
		// 	return INITIAL_UPLOAD_PROFILE_PICTURE_STATE;
		case types.UPLOAD_PROFILE_PICTURE_REQUEST:
			return { ...state, isLoading: true, results: {} };
		case types.UPLOAD_PROFILE_PICTURE_SUCCESS:
			return { ...state, isLoading: false, results: action.payload };
		case types.UPLOAD_PROFILE_PICTURE_FAILURE:
			return { ...state, isLoading: false, error: 'Error while trying to upload profile picture' };
		default:
			return state;
	}
}

const INITIAL_UPDATE_NEW_PASSWORD_STATE = {
	isLoading: false,
	results: {},
	error: '',
};

export function updateNewPasswordReducer(state = INITIAL_UPDATE_NEW_PASSWORD_STATE, action) {
	switch (action.type) {
		case types.UPDATE_USER_PASSWORD_REQUEST:
			return { ...state, isLoading: true, results: {} };
		case types.UPDATE_USER_PASSWORD_SUCCESS:
			return { ...state, isLoading: false, results: action.payload };
		case types.UPDATE_USER_PASSWORD_FAILURE:
			return { ...state, isLoading: false, error: 'Error while trying to update new password' };
		default:
			return state;
	}
}
