import { SET_USER_IS_ORG_ADMIN } from "../../../profile/actions/users-profile-actions-types";


const INITIAL_STATE_USER_IS_ORG_ADMIN = { results: "" }

export function setisOrgAdminReducer(state = INITIAL_STATE_USER_IS_ORG_ADMIN, action) {
    switch (action.type) {
        case SET_USER_IS_ORG_ADMIN:
            return { results: action.payload }
        default:
            return state;
    }
}