import React from 'react';
import { Box, Text, Flex } from '@chakra-ui/react';
import LaunchdeckButton from '../../global/components/helper-components/components/LaunchdeckButton';
import { redirectToNewPage } from '../../global/helpers/global-helpers';


export default function PaymentsSuccess() {
	return (
		<Box bg='white' p='3%' rounded='1em'>
			<Text textAlign='center' color='brandDark.500' fontWeight='semibold'> Thanks! Your organization is now active </Text>
			<Text mt='2em' textAlign='center' fontSize='0.875em' color='gray.500'> Start publishing Rooms to your audience & add co-workers to your team today </Text>

			<Flex alignItems='center' justify='center' mt='3em'>
				<LaunchdeckButton bg='brandDark.500' color='white' px='3em' fontSize='1em' onClick={() => redirectToNewPage(`/home`)} label='Back' />
			</Flex>

		</Box>
	);

}