import React, { useState } from "react";
// import { connect } from 'react-redux';
// import { withRouter } from 'react-router';
// import { bindActionCreators } from 'redux';

// import { Button } from '@chakra-ui/react';

import { Box, Flex, Image } from "@chakra-ui/react";
import AddEmojiIconPng from '../styles/images/add-reaction-icon.png';
import EmojiMenu from './EmojiMenu';


export default function DsrAddNewEmojiReactionButton({messageIndex, postId, postReactions, handleAddRemoveMessageReaction}) {

  const [showEmojiMenu, setShowEmojiMenu] = useState(false);
    
  return (
    <React.Fragment>
          <Flex
          px='0.6em' 
          py='0.1em' 
          // mx='0.5em' 
          justify='center'
          align='center'
          rounded='0.8em' 
          border="1px"
          borderColor='gray.100'
          cursor={(showEmojiMenu===false) ? 'pointer'  : ''}
          fontSize='0.8em'
          onClick={(showEmojiMenu===false) ? ()=>setShowEmojiMenu(!showEmojiMenu) : null}
          >
          
              <Box display={showEmojiMenu ? "none" : "block"} onClick={(showEmojiMenu===true) ? ()=>setShowEmojiMenu(!showEmojiMenu) : null} cursor={(showEmojiMenu===true) ? 'pointer'  : ''}>
                <Box py='0.1em'>
                  <Image src={AddEmojiIconPng} boxSize="1.3em"  objectFit="cover" alt="Add Emoji Reaction" />
                </Box>
              </Box>

              {showEmojiMenu=== true && 
                <Flex key={'emoji_menu_'+ messageIndex}> 
                  <EmojiMenu 
                    messageIndex={messageIndex} 
                    postReactions={postReactions} 
                    handleAddRemoveMessageReaction={handleAddRemoveMessageReaction}
                    postId={postId}  
                    setShowEmojiMenu={setShowEmojiMenu}
                  /> 
                    
                </Flex>}
        </Flex>
    </React.Fragment>
  );
  
}


// function mapStateToProps(state) {
//   return {
//     retrievedAgreement: state.activeAgreementByToken.results.data,
//     retrievedAgreementData: state.agreement.results.data,
//     //NEW
//     agreementPdf: state.agreements.data.agreementPdf,
//   };
// }

// function mapDispatchToProps(dispatch) {
//   return {
//     action: bindActionCreators(
//       {
//         cancelEndAgreement
//       },
//       dispatch
//     )
//   };
// }

// export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ActiveAgreementOptionsButton));
