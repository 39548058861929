import React, { memo, useState, useEffect } from 'react';

import {
	Box, Flex, Divider, IconButton, Button, Portal, Popover,
	PopoverTrigger,
	PopoverContent,
	PopoverHeader,
	PopoverBody,
	PopoverArrow,
	useToast,
} from '@chakra-ui/react';

import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import UserProfileImageDisplay from '../../global/components/helper-components/components/UserProfileImageDisplay';
import ClaverModal from '../../global/components/helper-components/components/ClaverModal';
import EditTeamMemberModule from './edit-team/EditTeamMemberModule';
import { AiOutlineEdit } from 'react-icons/ai';
import LaunchdeckButton from '../../global/components/helper-components/components/LaunchdeckButton';
import { copyToUserClipboard, triggerLaunchdeckToast } from '../../global/helpers/global-helpers';
import { reactivateUserSetupLink, resetReactivateUserSetupLink } from '../../profile/actions/admin-user-actions'

function OrgUserTitleCard(props) {
	const { orgUser, orgIdToken, role, isLastAdmin,
		// Action
		reactivateUserSetupLink,
		resetReactivateUserSetupLink,
		//Redux
		reactivateUserSetupLinkOutcome
	} = props || {};
	const { userId: userIdToken, memberName, userOrgTitle, memberProfilePicSrc, userStatus, pendingStatusExpired, pendingUserSetupLink } = orgUser || {};

	const [showPendingPopover, setShowPendingPopover] = useState((userStatus === 'pending') ? true : false)
	const [showExpiredStatus, setShowExpiredStatus] = useState(pendingStatusExpired ? pendingStatusExpired : false)
	const [showEditModal, setShowEditModal] = useState(false)
	const [showEditButton, setShowEditButton] = useState(false)
	const [reactivationLinkUrl, setReactivationLinkUrl] = useState(pendingUserSetupLink)
	const toast = useToast();

	useEffect(() => {
		if (reactivateUserSetupLinkOutcome) {
			
			const { linkReactivated, updatedUserIdToken, pendingUserSetupLink, linkActivationMessage } = reactivateUserSetupLinkOutcome || {};
			const isThisUser = (updatedUserIdToken === userIdToken) ? true : false;

			if (!!linkReactivated && isThisUser) {
				setShowPendingPopover(true)
				setShowExpiredStatus(false)
				setReactivationLinkUrl(pendingUserSetupLink)

				toast(
					triggerLaunchdeckToast({
						useCase: "show-success-state",
						// bannerText: "",
						label: linkActivationMessage,
						isMobileView: false
					})
				)

				resetReactivateUserSetupLink()
			} else if (linkReactivated === false && linkActivationMessage) {
				toast(
					triggerLaunchdeckToast({
						useCase: "show-error-state",
						// bannerText: "",
						label: linkActivationMessage,
						isMobileView: false
					})
				)

				resetReactivateUserSetupLink()
			}


		}
	}, [reactivateUserSetupLinkOutcome])


	const popOverTag = () => {

		const handleClickCopyLink = () => {
			copyToUserClipboard(reactivationLinkUrl)

			toast(
				triggerLaunchdeckToast({
					useCase: "show-success-state",
					// bannerText: "",
					label: "User activation link copied",
					isMobileView: false
				})
			)
		}

		const handleClickReactivate = () => {
			reactivateUserSetupLink({
				reactivatedUserIdToken: userIdToken,
				orgId: orgIdToken
			})

		}

		if (showExpiredStatus) {
			return (
				<Popover>
					<PopoverTrigger>
						<Button
							bg='pink.50' color='brandDark.500'
							px='1em'
							py='0.4em'
							rounded='18.75em'
							fontSize='0.65em'
							fontWeight='400'
							justify='center'
							alignItems='center'
							whiteSpace='pre'
							_hover={{ color: "white", bg: "brandPink.500" }}

						>
							Not responded
						</Button>
					</PopoverTrigger>
					<Portal>
						<PopoverContent minW={['25em','25em','25em','25em']}>
							<PopoverArrow />
							<PopoverHeader fontSize='0.875em' pl='1em' bg='pink.50' fontWeight='600'>Manage</PopoverHeader>
							{/* <PopoverCloseButton /> */}
							<PopoverBody my='0.5em' px='1em'>
								<Flex w='100%'>
									<Box flex='8' fontSize='0.875em'>
										Setup link has expired. Reactivate?
									</Box>
									<Box flex='2'>
										<LaunchdeckButton bg='brandDark.500' color='white' fontSize='0.75em' onClick={() => handleClickReactivate()} label='Reactivate' />

									</Box>

								</Flex>


							</PopoverBody>
						</PopoverContent>
					</Portal>
				</Popover>
			)
		} else {
			return (
				<Popover>
					<PopoverTrigger>
						<Button
							bg='brand.50' color='brandDark.500'
							px='1em'
							py='0.4em'
							rounded='18.75em'
							fontSize='0.65em'
							fontWeight='400'
							justify='center'
							alignItems='center'
							whiteSpace='pre'
							_hover={{ color: "white", bg: "brand.500" }}
						>
							Pending
						</Button>
					</PopoverTrigger>
					<Portal>
						<PopoverContent minW='25em'>
							<PopoverArrow />
							<PopoverHeader fontSize='0.875em' fontWeight='600' bg='brand.50' pl='1em'>Personal setup link</PopoverHeader>
							<PopoverBody my='0.5em' px='1em'>
								<Flex w='100%'>
									<Box flex='8'
										className='truncate-long-text'
										maxW="25em"
										border='1px solid' borderColor='gray.200' p='0.2em 1em' fontSize='0.875em'>
										{reactivationLinkUrl}
									</Box>
									<Box flex='2' ml='1em'>
										<LaunchdeckButton bg='brand.500' color='white' fontSize='0.75em' onClick={() => handleClickCopyLink()} label="Copy" />
									</Box>

								</Flex>

							</PopoverBody>
							
						</PopoverContent>
					</Portal>
				</Popover>
			)
		}

	}

	return (
		<Box
			onMouseOver={() => setShowEditButton(true)} onMouseOut={() => setShowEditButton(false)}
			my='1em'
		>
			<Flex direction={['column','column','row','row']} w='100%' py='1rem' px='2rem' justify='space-between' alignItems={['flex-start','flex-start','center','center']}>
				<Flex flex='80%' w='100%'>
					<UserProfileImageDisplay name={memberName} size='2em' profilePicture={memberProfilePicSrc} />
					<Box flex='70%' pl='3%'>
						<Box lineHeight='1.375em' fontWeight='500' color='gray.700' fontSize='0.9em'>{memberName}</Box>
						<Box lineHeight='1.25em' fontSize='0.8em' fontWeight='400' color='gray.500'>{userOrgTitle ? userOrgTitle : "-"}</Box>

					</Box>
				</Flex>
				<Flex flex='20%' justify='flex-end' align='center' mt={['1em','1em','0','0']}>
					{showPendingPopover && popOverTag()}
					<Box p='0.1em' ml='1em' border='1px solid' visibility={showEditButton ? 'unset' : "hidden"} borderColor='gray.200' rounded='0.25em'
						onClick={() => {
							// setShowEditButton(false)
							setShowEditModal(true)
						}
						}>
						<IconButton
							aria-label="Edit Org Member"
							fontSize="1.2em"
							icon={<AiOutlineEdit />}
							bg='white'
						/>
					</Box>

				</Flex>
			</Flex>

			<Divider />


			<ClaverModal
				title='Deactivate User'
				handleCloseModal={() => setShowEditModal(false)}
				showModal={showEditModal}
			>
				<EditTeamMemberModule orgUser={orgUser} orgIdToken={orgIdToken} handleCloseModal={() => setShowEditModal(false)} role={role} isLastAdmin={isLastAdmin} />
			</ClaverModal>
		</Box>
	);
}


const mapStateToProps = (state) => ({
	reactivateUserSetupLinkOutcome: state.reactivateUserSetupLinkOutcome.results.data,
})

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			reactivateUserSetupLink,
			resetReactivateUserSetupLink
		},
		dispatch,
	)

export default memo(withRouter(connect(mapStateToProps, mapDispatchToProps)(OrgUserTitleCard)))
