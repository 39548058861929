import { Box, Flex, Fade } from "@chakra-ui/react";
import LaunchdeckButton from "../../../global/components/helper-components/components/LaunchdeckButton";
import { redirectToNewPage } from "../../../global/helpers/global-helpers";
import { MdOutlineMarkEmailUnread } from "react-icons/md";

export default function ReferToEmailContent({ titleText, bodyText, sendToEmail, redirectionLink, buttonText }) {

  return (
    <Box pt='2em'>
      <Fade in={true}>
        <Box bg='white' p='3em 1em' rounded='0.25em' boxShadow={'xs'} mx='auto' maxW={['90%', '600px']}>
          <Box fontSize='1.4em' fontWeight='600' textAlign='center'>
            {titleText}
          </Box>

          <Box fontSize='0.875em' px='2em' fontWeight='500' mt='2em' color='brandDark.500' textAlign='center' whiteSpace={'pre-line'}>
            {bodyText}
          </Box>

          {sendToEmail &&
          <Box mt='4em' >
            <Flex justify='center' align='center' w='100%' mb='1em'>
            <Box as={MdOutlineMarkEmailUnread} color={'gray.700'} boxSize='2.5em'/>
            </Flex>

            <Box >
              <Box fontSize='0.6em' fontWeight='700' color='brandDark.500' textAlign='center' whiteSpace={'pre-line'}>
                SENT TO
              </Box>

              <Box fontSize='0.7em' fontWeight='500' my='0.25em' color='brandDark.500' textAlign='center' fontStyle='italic'>
                {sendToEmail}
              </Box>

              <Box fontSize='0.6em' fontWeight='400' color='gray.500' textAlign='center' whiteSpace={'pre-line'}>
                a few minutes ago
              </Box>
            </Box >
            </Box>
          }
          {redirectionLink && buttonText &&
            <Flex direction='column' mt='3em' justify='center' alignItems='center'>
              <Flex justify='center' minW='400px'>
                <LaunchdeckButton w='100%' bg='brand.500' color='white' onClick={() => redirectToNewPage(redirectionLink)} label={buttonText ? buttonText : 'OK'} />
              </Flex>
            </Flex>
            // :
            //      <Flex direction='column' mt='3em' justify='center' alignItems='center' px='2em'>
            //     <Box mt='0.5em' fontSize='0.875em' color='brandDark.500' fontWeight='600' mb='0.25em'> Why use an activation link?</Box>
            //     <Box fontSize='0.75em' textAlign='center' color='gray.600' fontWeight='400'> 
            //       This verifies your email, AND helps us ensure that real email addresses & genuine accounts are created in our ecosystem to benefit all users, including you! 
            //     </Box>
            //    </Flex>
          }
        </Box>
      </Fade>
    </Box>

  )
}