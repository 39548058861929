import React from 'react'
import { Avatar, Box, Divider, Grid, Flex } from '@chakra-ui/react'
import DsrCreatePostTypeButton from '../dsr-create-post/DsrCreatePostTypeButton'
import { DsrLoomRecordButton } from '../dsr-record-content/DsrLoomRecordButton'

import { connect } from 'react-redux'
import { withRouter } from 'react-router'


function DsrCreateContentPostModule({ 
  userProfile, 
  handleOpenNewPostModal, 
  handleSelectSubpage, 
  handleCreateNewPost,
  isMobileView
 }) {

  const { memberName, memberProfilePicSrc } = userProfile || {};

  const renderUserOptions = () => {
    if (isMobileView) {
      return (
        <Flex justify='space-between' align='center'>
        <Grid templateColumns="repeat(3, 1fr)" mb='1em'  mt='1.5em' width='100%' flexWrap='wrap'>
          {isMobileView &&  <DsrCreatePostTypeButton type='write-post' handleOpenNewPostModal={() => handleOpenNewPostModal("create-new-post")}/>}
          <DsrCreatePostTypeButton type='ask-question' handleOpenNewPostModal={handleOpenNewPostModal}/>
          <DsrCreatePostTypeButton type='upload-image' handleOpenNewPostModal={handleOpenNewPostModal}/>
          </Grid>
      </Flex>
      )
    } else {
      return (
        <Flex justify='space-between' align='center'>
        <Flex style={{gap: '20px'}}  my='1em'  mt='1.5em' width='100%' justifyContent='space-between' flexWrap='wrap'>
          {isMobileView &&  <DsrCreatePostTypeButton type='write-post' handleOpenNewPostModal={() => handleOpenNewPostModal("create-new-post")}/>}
          <DsrCreatePostTypeButton type='ask-question' handleOpenNewPostModal={handleOpenNewPostModal}/>
          <DsrCreatePostTypeButton type='upload-image' 
          handleOpenNewPostModal={
            
            () => handleOpenNewPostModal("create-new-post", {setShowUploadImage: true})}
            /> 
          {/* <DsrCreatePostTypeButton type='post-poll' handleOpenNewPostModal={handleOpenNewPostModal}/> */}
          {/* <DsrCreatePostTypeButton type='make-voice-post' handleOpenNewPostModal={handleOpenNewPostModal}/> */}
          <DsrCreatePostTypeButton type='create-action-item' handleSelectSubpage={handleSelectSubpage} />
          <Box display={['none', 'none', 'flex', 'flex']}>
            <DsrLoomRecordButton
              handleCreateNewPost={handleCreateNewPost}
              sending={false}
              submitRecording={(v)=>{}}
              displayClassName='create-post-button'
            />
          </Box>

       
          </Flex>

        {/* Use More dropdown menu if there are more options to collapse it into */}
        {/* <Box px='1.125em' mx='1em'>
          <Menu>
            <MenuButton as={Button} rightIcon="chevron-down" fontSize='1em' fontWeight='500' bg='white'>
              <Box fontSize='1em' fontWeight='500' py='1em'>More</Box>
            </MenuButton>
            <MenuList fontSize='1em' fontWeight='500'>
              <MenuItem as="a" href="#" onClick={() => handleOpenNewPostModal("show-sales-cta")}>
                Action E
              </MenuItem>
            </MenuList>
          </Menu>
        </Box> */}
      </Flex>
      )
    }
  }


  return (
    <React.Fragment>
      <Box p='1em' w='100%' bg='white' rounded='0.25em' border='1px solid' borderColor='gray.300'>
        <Flex mt='0.625em' justify='flex-start' width='100%'>
          <Box px='0.5em'>
            <Avatar h='4.2em' w='4.2em' name={memberName} bg='gray.100' color='gray.700' src={memberProfilePicSrc ? memberProfilePicSrc : ""} />
          </Box>

          <Box
            width='100%'
            border='1px solid' borderColor='gray.400' rounded='0.25em'
            cursor='pointer'
            alignContent='center'
            onClick={() => handleOpenNewPostModal("create-new-post")}>
            <Box color='gray.500' px='0.8em' py='0.5em' fontSize='0.9em'>Write a Post</Box>
          </Box>
        </Flex>

        {renderUserOptions()}
       
      </Box>

      <Box mt='1.5em' mb='0.5em'>
        <Divider />
      </Box>
    </React.Fragment>
  );

}



function mapStateToProps(state) {
	return {
    isMobileView: state.mobileViewMode.isMobileView
	};
}

export default withRouter(connect(mapStateToProps, null)(DsrCreateContentPostModule))