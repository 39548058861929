import React, { useState, useEffect, useRef } from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { GoChevronDown } from 'react-icons/go'

import { Box, Text, Flex, Avatar, useOutsideClick, useToast, CircularProgress } from "@chakra-ui/react";
import FormTextInput from '../../forms/TextInputField/components/FormTextInput';
import { getThisUserIdToken, triggerLaunchdeckToast, validateEmail } from '../../global/helpers/global-helpers';
import { editDsrTeamMembers, resetEditDsrTeamMembers } from '../actions/dsr-actions';
import { checkIfExistingUser, resetCheckIfExistingUser } from '../actions/manage-dsr-member-actions';
import PageLoader from '../../global/components/helper-components/components/PageLoader';
import LaunchdeckButton from '../../global/components/helper-components/components/LaunchdeckButton';
import { getUserAccountType } from '../../profile/helpers/user-helper';
import { displayUserTeamRolePermissions } from '../helpers/dsr-helpers';
import LaunchdeckFormInput from '../../forms/TextInputField/components/LaunchdeckFormInput';
import { BsPersonVcard } from 'react-icons/bs';
import LaunchdeckTag from '../../global/components/helper-components/components/LaunchdeckTag';

function DsrAddTeamMemberForm(props) {

  const { dsrId, editDsrTeamMembersOutcome, dsrUserTeam,
    isDsrAdmin,
    editingTeamInProgress, handleCloseModal, checkIfExistingUserOutcome,
    editDsrTeamMembers,
    resetEditDsrTeamMembers,
    checkIfExistingUser,
    resetCheckIfExistingUser,
    isMobileView,
  } = props;

  const toast = useToast();

  // Local States
  const [memberCardLocalState, setMemberCardLocalState] = useState({
    nextActionPath: ""
  })

  // Inputs
  const [emailInput, setEmailInput] = useState("")

  const [firstNameInput, setFirstNameInput] = useState("")
  const [lastNameInput, setLastNameInput] = useState("")
  const [companyNameInput, setCompanyNameInput] = useState("")
  const [titleInput, setTitleInput] = useState("")
  const [detectedExistingUserIdToken, setDetectedExistingUserIdToken] = useState("")

  const [teamNameInputDisplay, setTeamNameInputDisplay] = useState("")
  const [updatedTeamStatus, setUpdatedTeamStatus] = useState(null)

  // Validation
  const [showIncompleteFormError, setShowIncompleteFormError] = useState(null)
  const [showNotValidEmailError, setShowNotValidEmailError] = useState(null)
  const [allInputsValidated, setAllInputsValidated] = useState(null)

  // UX management
  const [teamInput, setTeamInput] = useState("")
  const [showPickTeamDropdown, setShowPickTeamDropdown] = useState(false)
  const [buttonType, setbuttonType] = useState('proceed') // Possible values: 'proceed' & 'start-invite'

  const [showMemberCard, setShowMemberCard] = useState(false)
  const [showCustomiseInviteSection, setShowCustomiseInviteSection] = useState(false)

  const [isSearchingForUser, setIsSearchingForUser] = useState(false)

  const [chosenRole, setChosenRole] = useState("") // Possible Values: "" / "Member" / "Admin"
  const [showPickRoleDropdown, setShowPickRoleDropdown] = useState(false)
  const [isNoOrgUser, setIsNoOrgUser] = useState("")

  const initRef = useRef(null);
  const teamPickerDropdownRef = useRef(null);
  const rolePickerDropdownRef = useRef(null);

  useOutsideClick({
    ref: teamPickerDropdownRef,
    handler: () => setShowPickTeamDropdown(false),
  })

  useOutsideClick({
    ref: rolePickerDropdownRef,
    handler: () => setShowPickRoleDropdown(false),
  })

  useEffect(() => {
    // Business Logic: If user is in Audience/Buyer team, they can only invite other users to the Audience / Buyer team
    // There will be no inputs to determine which team to add for Audience
    // This is required to pass validation checks 
    if (!initRef.current) {
      const userType = getUserAccountType();
      const isNoOrgUser = (userType === "no-org-user") ? true : false;
      setIsNoOrgUser(isNoOrgUser)
    }
    initRef.current = true

  }, []);

  

  useEffect(() => {
    // Business Logic: If user is in Audience/Buyer team, they can only invite other users to the Audience / Buyer team
    // There will be no inputs to determine which team to add for Audience
    // This is required to pass validation checks 
    if (dsrUserTeam === 'audience') {
      setTeamInput('buyer-team')
    }

  }, [dsrUserTeam]);

  useEffect(() => {
    // Business Logic: Validate & allow user to click on Add user button
    if ((emailInput && teamInput === "seller-team" && chosenRole) || (emailInput && teamInput === "buyer-team")) {
      setAllInputsValidated(true)
      // setbuttonType('start-invite')
    } else {
      setAllInputsValidated(false)
      setbuttonType('proceed')
    }
  }, [emailInput, teamInput, chosenRole]);


  useEffect(() => {
    if (editDsrTeamMembersOutcome) {
      const {updatedTeamMembers} = editDsrTeamMembersOutcome || {}

      if (updatedTeamMembers) {
        toast(
          triggerLaunchdeckToast({
            useCase: "show-success-state",
            label: "Invitation successfully sent. Go right ahead & invite another!", 
            isMobileView
          })
        )
        
      } else if (!updatedTeamMembers) {
          // Case: Duplicated email user
          toast(
            triggerLaunchdeckToast({
            useCase: "show-error-state",
            label: "Unfortunately something went wrong. Please try again later.", 
            isMobileView
            })
          )

          setUpdatedTeamStatus(updatedTeamMembers)
      }
      
      resetEditDsrTeamMembers()
      resetForm()
    }

  }, [editDsrTeamMembersOutcome, resetEditDsrTeamMembers]);

  useEffect(() => {
    if (checkIfExistingUserOutcome) {
      const { nextActionPath, userCardData } = checkIfExistingUserOutcome || {};

      setMemberCardLocalState(prevState => ({
        ...prevState,
        nextActionPath,
        ...userCardData && { userCardData }
      }))

      if (userCardData?.memberIdToken) {
        setDetectedExistingUserIdToken(userCardData.memberIdToken)
      }

      if (nextActionPath === "show-cannot-add-user-card") {
        
        toast(
          triggerLaunchdeckToast({
          useCase: "show-error-state",
          duration: "9000",
          bannerText: "User cannot be added to this Room",
          label: "This user has been deactivated or has left their organization", 
          isMobileView
          })
        )

        resetForm()

      } else {
        setShowMemberCard(true) // UUU review

        if (nextActionPath === "show-new-user-preview") {
          setShowCustomiseInviteSection(true)
        }

        setbuttonType('start-invite')
      }
      setIsSearchingForUser(false)
      resetCheckIfExistingUser()
    }
  }, [checkIfExistingUserOutcome, resetCheckIfExistingUser, memberCardLocalState])

  const renderMemberCard = () => {
    if (showMemberCard) {
      const { userCardData } = memberCardLocalState || {};
      const { memberFirstName, memberLastName, memberEmail, memberProfilePicSrc, memberOrgName, memberOrgTitle } = userCardData || {};
      const credentials = (memberOrgName && memberOrgTitle) ? memberOrgTitle + ', ' + memberOrgName : memberOrgName ? memberOrgName : memberEmail ? memberEmail : emailInput;
      const memberFullName = (memberFirstName && memberLastName) ? (memberFirstName + ' ' + memberLastName).trim() : memberFirstName ? memberFirstName.trim() : memberLastName ? memberLastName.trim() : "";
      return (
        <Flex className='flip-in-hor-bottom' justify="flex-start" align='center' mb='1.5em' bg='white' rounded='0.25em' border='1px solid' borderColor='gray.300' p='1em' minW='16em' w={isMobileView && '100%'}>
          {memberProfilePicSrc ?
            <Avatar width='3.8em' height='3.8em' bg='gray.100' color='gray.700' src={memberProfilePicSrc} />
            :
            <Box boxSize='2.6em' rounded='100%' border='1px dashed' borderColor='gray.500' />
          }
          <Box ml='1em'>
            <Box fontSize='0.875em' lineHeight='1.375em' fontWeight='500' color='brandDark.500'>{memberFullName && memberFullName}</Box>
            <Box fontSize='0.75em' lineHeight='1em' fontWeight='400' color='gray.500'>{credentials}</Box>
          </Box>
        </Flex>
      )
    }
  }


  const resetForm = () => {
    setEmailInput("")
    setTeamInput("")
    setTeamNameInputDisplay("")
    setChosenRole("")
    setFirstNameInput("")
    setLastNameInput("")
    setCompanyNameInput("")
    setTitleInput("")
    setbuttonType("proceed")
    setShowMemberCard(false)
    setMemberCardLocalState({ nextActionPath: "" })
    setShowCustomiseInviteSection(false)
    setUpdatedTeamStatus(null)
    setIsSearchingForUser(false)
  }

  const onChangeHandler = event => {
    setShowNotValidEmailError(null)
    setShowIncompleteFormError(null)

    switch (event.target.name) {
      case ('email'): {
        setEmailInput(event.target.value.trim())
        setAllInputsValidated(false)
        break;
      }
      case ('invitee-first-name'): {
        const firstNameInput = event.target.value.trim()
        setFirstNameInput(firstNameInput)
        setMemberCardLocalState({
          ...memberCardLocalState,
          userCardData: {
            ...memberCardLocalState.userCardData,
            memberFirstName: firstNameInput
          }
        })
        break;
      }
      case ('invitee-last-name'): {
        const lastNameInput = event.target.value.trim()
        setLastNameInput(lastNameInput)
        setMemberCardLocalState({
          ...memberCardLocalState,
          userCardData: {
            ...memberCardLocalState.userCardData,
            memberLastName: lastNameInput
          }
        })
        break;
      }
      case ('company-name'): {
        const companyNameInput = event.target.value.trim()
        setCompanyNameInput(companyNameInput)
        setMemberCardLocalState({
          ...memberCardLocalState,
          userCardData: {
            ...memberCardLocalState.userCardData,
            memberOrgName: companyNameInput
          }
        })
        break;
      }
      case ('company-title'): {
        const companyTitleInput = event.target.value.trim()
        setTitleInput(companyTitleInput)
        setMemberCardLocalState({
          ...memberCardLocalState,
          userCardData: {
            ...memberCardLocalState.userCardData,
            memberOrgTitle: companyTitleInput
          }
        })
        break;
      }
      default: {
        break;
      }
    }
  };

  const handleAddMemberButton = () => {
    const isValidEmail = validateEmail(emailInput.trim());

    if (allInputsValidated === true && isValidEmail === true) {
      const updateParams = {
        teamToUpdate: teamInput,
        updateAction: 'add-member',
        inviteeEmail: emailInput,
        chosenRole: chosenRole ? chosenRole : 'Member', // defaults to Member (no input required for Guests adding other guests) 
        firstName: firstNameInput,
        lastName: lastNameInput,
        inviteeCompanyName: companyNameInput,
        inviteeCompanyTitle: titleInput,
        userId: detectedExistingUserIdToken,
        inviterUserId: getThisUserIdToken()
      }

      handleEditDsrMembers(updateParams);

      // UUU UX: Then loading state, show adding is successful, and have a state that shows successful, OK button to bring back to team tab to show pending invite user
    } else if (!isValidEmail) {
      setShowNotValidEmailError(true)
    }

  }

  // UX: Pick Team
  const handlePickTeamOption = (teamToUpdate) => {
    setTeamInput(teamToUpdate);

    if (teamToUpdate === 'buyer-team') {
      setTeamNameInputDisplay("Guests")
      setChosenRole("")
      setShowPickRoleDropdown(false)
    } else if (teamToUpdate === 'seller-team') {
      setTeamNameInputDisplay("Hosts")
    }

    setShowIncompleteFormError(false);
  }

  const handlePickRoleOption = (pickedRole) => {
    setChosenRole(pickedRole)
  }

  const handleClickProceedButton = () => {
    setShowNotValidEmailError(false)
    setIsSearchingForUser(true)

    const isValidEmail = validateEmail(emailInput.trim());

    if (isValidEmail) {
      checkIfExistingUser(emailInput)
    } else {
      setShowNotValidEmailError(true)
    }

  }


  const displayDuplicateMemberDetected = () => {
    const handleButtonClick = () => {
      handleCloseModal()
      setUpdatedTeamStatus("")
      resetEditDsrTeamMembers()
    }

    return (
      <Box mb='1em' w='100%'>
        <Box>{emailInput} has already joined this Room. You won't have to do anything else!</Box>
        <Flex w='100%' justify='center' mt='1.5em'>
          <LaunchdeckButton bg='brand.500' color='white' onClick={() => handleButtonClick()} label='OK' />
        </Flex>
      </Box>
    )
  }

  const handleEditDsrMembers = async (updateParams) => {

    const updateTeamParams = { dsrId, updateParams }
    await editDsrTeamMembers(updateTeamParams);
  }

  const renderTempAdditionalInfo = () => {

    return (
      <Flex bg='gray.50' direction='column' rounded='0.25em' w='100%' mb='1em' p='1em' mt='1.5em'>

        <Flex justify='flex-start' align='center' fontSize='0.875em' lineHeight='1.25em' gap='0.75em'>
          <BsPersonVcard />
          <Box color='brandDark.500' fontWeight='600'> Customise your invite</Box>
          <LaunchdeckTag bg='none' color='brand.500' label='(Optional)' fontSize='0.8em' />
        </Flex>
        <Box my='1em' w='100%'>
          {/* // Company Name & Designation */}
          <Flex justify='space-between' align='flex-start'>
            <Box w='100%' pr='1em'>
              {/* <Flex justify='flex-start' align='center'>
              </Flex> */}
              {/* <FormTextInput
                label={'First Name'}
                name={'invitee-first-name'}
                onChange={onChangeHandler}
                borderColor='gray.400'
              /> */}
               <LaunchdeckFormInput
                 onChange={onChangeHandler}
                 name={'invitee-first-name'}
                 label="First Name"
                />

            </Box>

            <Box w='100%'>
              {/* <Flex justify='flex-start' align='center'>
              </Flex> */}
              {/* <FormTextInput
                label={'Last Name'}
                name={'invitee-last-name'}
                onChange={onChangeHandler}
                borderColor='gray.400'
              /> */}
                    <LaunchdeckFormInput
                 onChange={onChangeHandler}
                 name={'invitee-last-name'}
                 label="Last Name"
                />

            </Box>
          </Flex>
          {/* // Company Name & Designation */}
          <Flex justify='space-between' align='flex-start' mt='1em'>
            <Box w='100%' pr='1em'>
              {/* <Flex justify='flex-start' align='center'>
              </Flex> */}
              {/* <FormTextInput
                label={'Company'}
                name={'company-name'}
                onChange={onChangeHandler}
                borderColor='gray.400'
              /> */}
                <LaunchdeckFormInput
                 onChange={onChangeHandler}
                 name={'company-name'}
                 label="Company"
                />
            </Box>

            <Box w='100%'>
              {/* <Flex justify='flex-start' align='center'>
              </Flex> */}
              {/* <FormTextInput
                label={'Company Role'}
                name={'company-title'}
                onChange={onChangeHandler}
                borderColor='gray.400'
              /> */}
               <LaunchdeckFormInput
                 onChange={onChangeHandler}
                 name={'company-title'}
                 label="Company Role"
                />

            </Box>
          </Flex>
        </Box>
      </Flex>
    )
  }

  const renderCtaButton = () => {
    const className = allInputsValidated ? "invite-form-button__activated" : "invite-form-button__deactivated"

    switch (buttonType) {
      case ('proceed'): {
        return (
          <Box className={className} cursor={isSearchingForUser && "default"} onClick={() => allInputsValidated && handleClickProceedButton()}>
            {isSearchingForUser ?
              <CircularProgress isIndeterminate color='brand.500' thickness='8px' size='1.2em'/> : 
            <Box fontWeight='500' lineHeight='1.5em' fontSize='0.875em'>Search</Box>
            }
          </Box>
        )
      }
      case ('start-invite'): {
        return (
          <Flex>
            <LaunchdeckButton noHover bg='gray.200' color='brandDark.500' onClick={() => resetForm()} label='Cancel' />
            <Box mx='0.5em' />
            <LaunchdeckButton bg='brand.500' color='white' onClick={() => allInputsValidated && handleAddMemberButton()} label='Send invite' />
          </Flex>
        )
      }
      default: {
        return null;
      }
    }
  }

  if (editingTeamInProgress) {
    return <PageLoader />
  } else {
    return (
      <Flex direction='column' justify={['flex-start', 'flex-start', 'center', 'center']} align='center' mt='2em' mb='1em' p='1em' border='1px dotted #E5EAEF' rounded='0.5em' bg='gray.50' w='100%' h={['auto', 'auto', '100%', '100%']}>
        <Flex justify='flex-start' w='100%' fontSize='0.875em' color='brandDark.500' mb='1em' fontWeight='600' px='1em'>{isNoOrgUser ? 'Invite others to this Room' : 'Invite someone from outside your organization'} </Flex>
        {updatedTeamStatus === false ? displayDuplicateMemberDetected() :
          <React.Fragment>
            <Box className="invite-form-input-container">
              <Box className="invite-form-input-container__email-input-container">
                {
                  showMemberCard ?
                    renderMemberCard()
                    :
                    <FormTextInput
                      w='100%'
                      color='#2D3748'
                      mb='0em'
                      name={'email'}
                      label={'Email'}
                      onChange={onChangeHandler}
                      borderColor='gray.400'
                      bg='white'
                    />
                }

              </Box>


              <Box w='100%' mt='1em'>
                {(dsrUserTeam === 'host') &&
                  <Box className="invite-form-input-container__team-input-container">
                    <Box w='100%'>
                      <Box mb='0.55em' lineHeight='1.275em' fontWeight='500' fontSize='0.875em'> Team </Box>
                      <Flex className='user-invite-dropdown-menu' onClick={() => setShowPickTeamDropdown(!showPickTeamDropdown)} ref={teamPickerDropdownRef}>
                        {teamNameInputDisplay ? <Box color='brandDark.500' fontSize='0.875em'>{teamNameInputDisplay}</Box> : <Box color="gray.300" fontSize='0.875em'>Pick Team</Box>}
                        <Box as={GoChevronDown} boxSize="0.75em" color="gray.500" />
                        <Box className='user-invite-dropdown-menu__dropdown' display={(showPickTeamDropdown) ? 'inline-block' : 'none'}>
                          <Box className='user-invite-dropdown-menu__dropdown-option-display' onClick={() => handlePickTeamOption('seller-team')}>{"Hosts"}</Box>
                          <Box className='user-invite-dropdown-menu__dropdown-option-display' onClick={() => handlePickTeamOption('buyer-team')}>{"Guests"}</Box>
                        </Box>
                      </Flex>
                    </Box>
                  </Box>
                }

                {
                  (dsrUserTeam === 'audience') &&
                  <Box className="invite-form-input-container__team-input-container" mt='2em'>
                    <Box w='100%'>
                      <Box mb='0.56em' lineHeight='1.275em' fontWeight='500'> Team </Box>
                      <Flex>
                        <Box color='brandDark.500' fontSize='0.875em'>Guests</Box>
                      </Flex>
                    </Box>
                  </Box>
                }

                {
                  (teamInput === 'seller-team') &&
                  <Box className="invite-form-input-container__team-input-container" mt='2em'>
                    <Box w='100%'>
                      <Box mb='0.56em' lineHeight='1.275em' fontWeight='500' fontSize='0.875em'> Role </Box>
                      <Flex className='user-invite-dropdown-menu' onClick={() => setShowPickRoleDropdown(!showPickRoleDropdown)} ref={rolePickerDropdownRef}>
                        {chosenRole ? <Box color='brandDark.500' fontSize='0.875em'>{chosenRole}</Box> : <Box color="gray.300" fontSize='0.875em'>Choose Role</Box>}
                        <Box as={GoChevronDown} boxSize="0.75em" color="gray.500" />
                        <Box className='user-invite-dropdown-menu__dropdown' display={(showPickRoleDropdown) ? 'inline-block' : 'none'}>
                          <Box className='user-invite-dropdown-menu__dropdown-option-display' onClick={() => handlePickRoleOption('Member')}>{"Member"}</Box>
                          {isDsrAdmin && <Box className='user-invite-dropdown-menu__dropdown-option-display' onClick={() => handlePickRoleOption('Admin')}>{"Admin"}</Box>}
                        </Box>
                      </Flex>
                    </Box>
                  </Box>
                }

                {displayUserTeamRolePermissions({dsrUserTeam, teamInput, chosenRole, isMobileView})}
              </Box>
            </Box>

            <Flex w='100%' height='auto'>
              {showCustomiseInviteSection && renderTempAdditionalInfo()}

              {/* // Change text to say the invite will be sent when the Room is published if NOT seller-lead */}
              {showIncompleteFormError && <Text textAlign='center' color='pink.500' fontSize='0.8em'>Please provide a valid Email, Team, and Role to proceed</Text>}
              {showNotValidEmailError && <Text textAlign='center' color='pink.500' fontSize='0.8em'>Invalid email format - Please check & try again</Text>}
            </Flex>

            <Flex w='100%' justify='flex-end' mt='2em'>
              {renderCtaButton()}
            </Flex>
          </React.Fragment>
        }

      </Flex>
    )
  }
}


function mapStateToProps(state) {
  return {
    isDsrAdmin: state.dsrAdmin.isDsrAdmin,
    editDsrTeamMembersOutcome: state.editDsrTeamMembersOutcome.results.data,
    editingTeamInProgress: state.editDsrTeamMembersOutcome.isLoading,
    dsrUserTeam: state.dsrUserTeam.userTeam,

    checkIfExistingUserOutcome: state.checkIfExistingUserOutcome.results.data,
  	isMobileView: state.mobileViewMode.isMobileView
  };
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      editDsrTeamMembers,
      resetEditDsrTeamMembers,
      checkIfExistingUser,
      resetCheckIfExistingUser,
    },
    dispatch,
  )


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DsrAddTeamMemberForm));
