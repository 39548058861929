import React, { useState } from 'react'
import { Box, Flex } from '@chakra-ui/react'
import { MdOutlineDragIndicator } from 'react-icons/md'
import { IoSettingsOutline } from "react-icons/io5";
import { displayReactIcon } from '../../helpers/dsr-display-helpers'
import { useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities";
import { CustomPageSettingsModal } from "./CustomPageSettingsModal";
import LaunchdeckSpinner from '../../../global/components/helper-components/components/LaunchdeckSpinner';

export const SortableCustomPageMenuTab = ({
  tabs,
  tabIndex,
  selectedDisplayPageId,
  handleSelectSubpage,
  handleRemovePageClick,
  handleEditPageData,
  handleDuplicatePageClick
}) => {
  const { pageId, pageTitle, pageDescription, displayPage, lockedPage, pageType, awaitingPageGeneration } = tabs || {};

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging
  } = useSortable({ id: pageId });

  const sortableStyle = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? "100" : "auto",
    opacity: isDragging ? 0.3 : 1
  };

  const [showSettingsModal, setShowSettingsModal] = useState(false);


  return (
    <Flex
      key={pageId + '-' + tabIndex}
      justify='space-between' align='center' px='1.1em' py='0.875em'
      className={(selectedDisplayPageId === `${pageId}`) ? "chosen-subpage-option" : "unchosen-subpage-option"}
      onClick={() => !awaitingPageGeneration && handleSelectSubpage(pageId, "")}
      cursor={awaitingPageGeneration && 'default'}
      style={sortableStyle}
      ref={setNodeRef}
    >
      <Flex justify='flex-start' align='center' color={awaitingPageGeneration && 'gray.400'}>
        <Box>{displayReactIcon(pageType, '1.5em', displayPage, lockedPage)}</Box>
     
        <Box ml='1em' mr='1em' color={(!displayPage || lockedPage) && 'gray.400'}>{pageTitle}</Box>

      </Flex>


      {awaitingPageGeneration === true ?
        <LaunchdeckSpinner />
        :
        <Flex>
          <Box pr="5px" onClick={() => setShowSettingsModal(true)}>
            <Box as={IoSettingsOutline} color={'#171b21'} boxSize="1em" py='3%' />
          </Box>

          <Box {...listeners} {...attributes} >
            <Box as={MdOutlineDragIndicator} color={'gray.500'} boxSize="1em" py='3%' />
          </Box>

          <CustomPageSettingsModal
            pageId={pageId}
            showModal={showSettingsModal}
            handleClosePageSettingsModal={() => setShowSettingsModal(false)}
            pageTitle={pageTitle}
            pageDescription={pageDescription}
            handleEditPageData={handleEditPageData}
            displayPage={displayPage}
            lockedPage={lockedPage}
            handleRemovePage={handleRemovePageClick}
            handleDuplicatePageClick={handleDuplicatePageClick}
          />
        </Flex>
      }
    </Flex>
  )
}