import { Box, Flex } from '@chakra-ui/react'
import DsrPageSideMenu from './DsrPageSideMenu'
import DisplayNewsFeedPublic from '../dsr-content-components/DisplayNewsFeedPublic'

import React, { memo, useState, useEffect } from 'react'
import { withRouter, useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  changeDsrModalState,
  changeDsrPopupState,
  changeSelectSubpage,
  changeDsrPageDisplay
} from '../actions/dsr-actions'
import {logGuestDsrAnalytics} from '../../analytics/actions/dsr-analytics-actions'
import { bindActionCreators } from 'redux'
import RenderSubpageContent from './RenderSubpageContent'
import '../../layout/components/layout/styles/dsr-content.scss'
import DsrDynamicModal from '../messages/DsrDynamicModal'
import { sendToGA } from '../../global/helpers/google-tag-helpers'
import { getThisUserIdToken } from '../../global/helpers/global-helpers'
import EngagementTimer from '../../global/components/timer/EngagementTimer'

// Note: Render Dsr Content for ACTIVE states
const RenderStaticDsrContent = ({
  dsrId,
  dsrConfiguration,
  dsrUserTeam,
  sessionId,
  selectedDisplaySubpage,
  selectedDisplayPageId,
  changeSelectSubpage, 
  changeDsrPageDisplay,
  logGuestDsrAnalytics,
  changeDsrPopupState,
  userActionRequiredState,
  changeDsrModalState,
  showDsrModalState,

  // Redux states
  dsrPagesRedux,
  faqPageIdRedux,
  dsrPropertiesRedux,
  // dsrTeamMembersRedux
}) => {

  const { pageIdToken } = useParams();

  const [localState, setLocalState] = useState({
    showDsrModalLoader: false,
    showConfirmationModal: false,
    confirmationType: '',
    postContentToEdit: '',
    faqPageId: '',
    replyIdToEdit: '',
    userIdToken: '',
    modalTransferPayload: '',
    postTagProperties: [],
  })
  const [resetEngagementTimer, setResetEngagementTimer] = useState(false)
  const [activeSeconds, setActiveSeconds] = useState(0);
  const isGuestUser = (dsrUserTeam==="audience") ? true : false;



  useEffect(() => {
		if (pageIdToken) {
      const pageId = 'pg__' + pageIdToken;
      changeDsrPageDisplay(pageId)
    } 
    else if (!pageIdToken) {
      // Default page
      const overviewPageId = dsrPagesRedux ? dsrPagesRedux[0].pageId : "";
      changeDsrPageDisplay(overviewPageId)
    }
  },[pageIdToken, changeDsrPageDisplay, dsrPagesRedux]);

  // Analytics 
  useEffect(() => {
    // Register the duration in which the last subpage was viewed before user closes the window or exits the DSR
    const handleBeforeUnload = () => {
     
      logGuestDsrAnalytics({
        eventType: 'guest-views-subpage',
        analyticsPayload: {
          userId: getThisUserIdToken(), 
          dsrId, 
          sessionId: sessionId,
          engagementDurationSeconds: activeSeconds,
          viewedPageId: selectedDisplayPageId
        }
      })
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  },[logGuestDsrAnalytics, dsrId, activeSeconds, sessionId, selectedDisplayPageId])

  // UX Handlers
  const handleResetEngagementTimer = () => {
    setResetEngagementTimer(false)
  }


  const handleChangeUserActionRequired = (newUserActionRequired, modalTransferPayload) => {
    changeDsrPopupState(newUserActionRequired)
    changeDsrModalState(true)
    setLocalState((prevState) => ({
      ...prevState,
      modalTransferPayload,
    }))
  }


  const handleDsrModalClose = () => {
    changeDsrPopupState('')
    changeDsrModalState(false)

    setLocalState((prevState) => ({
      ...prevState,
      postIdToEdit: '',
    }))
  }


  const handleOpenSignupOrLoginModal = () => {
    changeDsrPopupState("show-signup-login-prompt")
    changeDsrModalState(true)
  }



  const handleSelectSubpage = (newSelectedDisplayPageId, newSelectedDisplayFixedSubpageType) => {

    if (newSelectedDisplayPageId && newSelectedDisplayFixedSubpageType) {
      // Error state if both values are provided to avoid showing 
      changeSelectSubpage("")
      changeDsrPageDisplay("")
     } else {
      if (newSelectedDisplayPageId && !newSelectedDisplayFixedSubpageType && (newSelectedDisplayPageId !== selectedDisplayPageId)) {
        // Takes care of Analytics before changing pageId/ subpageType
    
        changeDsrPageDisplay(newSelectedDisplayPageId)
       } else if (!newSelectedDisplayPageId && newSelectedDisplayFixedSubpageType && (newSelectedDisplayFixedSubpageType !== selectedDisplaySubpage)) {
      
        changeSelectSubpage(newSelectedDisplayFixedSubpageType)
       }

       if (isGuestUser) {
        // For Sales intelligence
        setResetEngagementTimer(true)
  
        logGuestDsrAnalytics({
          eventType: 'guest-views-subpage',
          analyticsPayload: {
            userId: getThisUserIdToken(), 
            dsrId, 
            sessionId: sessionId,
            engagementDurationSeconds: activeSeconds,
            viewedPageId: selectedDisplayPageId ? selectedDisplayPageId : selectedDisplaySubpage,
          }
        })
      }

      // For internal BI
       sendToGA('view_dsr_page', {
        'eventTimestamp': new Date().toISOString(),
        'dsrId': dsrId,
        'userIdToken': getThisUserIdToken(),
        'pageId': selectedDisplayPageId ? selectedDisplayPageId : selectedDisplaySubpage,
        'pageEngagementTimeSeconds': activeSeconds
      });
     } 
    
  }

  return (    
    <Flex justifyContent={['flex-start', 'flex-start', 'center', 'center']} className="dsr-content__section">
        {/* // Engagement Time is meant to be hidden and has no displayed UI */}
        {isGuestUser && 
        <EngagementTimer 
          resetEngagementTimer={resetEngagementTimer} 
          handleResetEngagementTimer={handleResetEngagementTimer}
          // activeSeconds={activeSeconds}
          setActiveSeconds={setActiveSeconds}
        />
      }
      <Flex className="dsr-content__container">
        <Flex direction="column" align="flex-start" w="100%" height='100%'>
          <Flex direction="row" align="flex-start" width="100%">
            <Flex display={['none', 'none','flex', 'flex']} position="sticky" top='70px' bg="white">
              <DsrPageSideMenu
                pageArray={dsrPagesRedux}
                selectedDisplayPageId={selectedDisplayPageId}
                handleSelectSubpage={handleSelectSubpage}
                headerMenuHeight="70px"
              />
            </Flex>
            <Flex
              direction={['column', 'column', 'column', 'row']}
              justify="center"
              flexWrap="wrap"
              width="100%"
              background="#F4F4F4"
              h='auto'
              minH='100vh'
            >
              <Box className="dsr-content__middle-column-container" maxWidth={['90%','90%','800px','800px']} px="1em" >
                {selectedDisplaySubpage === 'updates-subpage' &&

                <DisplayNewsFeedPublic
                    dsrTitle={dsrConfiguration?.dsrTitle}
                    displayedPostContent={dsrConfiguration?.staticFeedContent.postArray ? dsrConfiguration.staticFeedContent.postArray : []}
                    dsrTeamMembers={dsrConfiguration?.dsrTeamMembers}
                    showDsrModal={showDsrModalState}
                    showDsrModalLoader={localState.showDsrModalLoader}
                    modalTransferPayload={localState.modalTransferPayload}
                    handleChangeUserActionRequired={handleChangeUserActionRequired}
                    handleDsrModalClose={handleDsrModalClose}
                    faqPageId={faqPageIdRedux}
                    handleSelectSubpage={handleSelectSubpage}
                    handleOpenSignupOrLoginModal={handleOpenSignupOrLoginModal}
                  />

                }
              
               {/* Dev Note: This component is to display static public content, sharing RenderSubpageContent with the normal DSR display */}
                <RenderSubpageContent
                  pageArray={dsrPagesRedux}
                  selectedDisplaySubpage={selectedDisplaySubpage}
                  selectedDisplayPageId={selectedDisplayPageId}
                  dsrTeamMembers={dsrConfiguration?.dsrTeamMembers}

                  dsrId={dsrId}
                  dsrActionList={[]}
                  dsrProperties={dsrPropertiesRedux}
                  handleOpenSignupOrLoginModal={handleOpenSignupOrLoginModal}

                  handleSelectSubpage={handleSelectSubpage}
                  // isPublicDsr={isPublicDsrEnvironment}
                />
              </Box>
            </Flex>

            {/* // This section houses dynamically rendered components that depends on user journey */}
        

              <DsrDynamicModal
                dsrId = {dsrId}
                userActionRequired={userActionRequiredState}
                showDsrModal={showDsrModalState}
                showDsrModalLoader={localState.showDsrModalLoader}
                handleDsrModalClose={handleDsrModalClose}
                postContentToEdit={localState.postContentToEdit}
                postTagProperties={localState.postTagProperties}
                postId={localState.postIdToEdit}
                handleChangeUserActionRequired={handleChangeUserActionRequired}
                modalTransferPayload={localState.modalTransferPayload}
              />
            
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )

}

const mapStateToProps = (state) => {
  return {
    // dsrConfiguration: state.publicDsrConfiguration.results.data, // Review naming 
    // dsrActionList: state.dsrActionList.results.data,
    userActionRequiredState: state.dsrPopup.UserActionRequired,
    showDsrModalState: state.dsrModal.showDsrModal,
    selectedDisplaySubpage: state.dsrPage.selectedDisplaySubpage,
    selectedDisplayPageId: state.dsrPage.selectedDisplayPageId,
    // isPublicDsrEnvironment: state.publicDsrEnvironment.publicDsrEnv,
    // currentDsrId: state.dsrFeedContent.id,
    // showLoadMoreRedux: state.dsrFeedContent.allowLoadMore,
    dsrPagesRedux: state.dsrPages.pages,
    faqPageIdRedux: state.faqPageId.id,

    dsrPropertiesRedux: state.draftDsrProperties.properties,
    dsrTeamMembersRedux: state.dsrTeamMembers.members,
    dsrUserTeam: state.dsrUserTeam.userTeam
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      
      changeDsrPopupState,
      changeDsrModalState,
      changeSelectSubpage,
      changeDsrPageDisplay,
      logGuestDsrAnalytics,
    },
    dispatch,
  )

export default memo(withRouter(connect(mapStateToProps, mapDispatchToProps)(RenderStaticDsrContent)))
