import React, { memo, useEffect, useState } from 'react';
import { Flex, Box, Image, Text, Card, CardBody, Stack, StackDivider, Spinner, useToast } from "@chakra-ui/react";
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import { getUserNotifications, markAllUserNotificationsAsViewed, markUserNotificationAsViewed } from '../../../profile/actions/user-notifications-actions';
import { displayElapsedDurationSinceTimestamp } from '../../../global/helpers/time-format-helpers';
import { determineNotificationImage, determineNotificationMessage } from '../../../profile/helpers/user-notification-helper';
import { redirectToNewPage, triggerLaunchdeckToast } from '../../../global/helpers/global-helpers';
import LaunchdeckButton from '../../../global/components/helper-components/components/LaunchdeckButton';

function UserNotificationPage({
	// Redux
	currentUserNotificationBatch,
	markAllNotificationsAsViewedOutcome,
	isMobileView,
	// Actions
	getUserNotifications,
	markUserNotificationAsViewed,
	markAllUserNotificationsAsViewed

}) {
	const toast = useToast();

	const [displayArray, setDisplayArray] = useState([])
	const [currentBatchNumber, setCurrentBatchNumber] = useState(1)
	const [nextBatchNumber, setNextBatchNumber] = useState(1)
	const [hideGetMoreButton, setHideGetMoreButton] = useState(false)
	const [loadingState, setLoadingState] = useState(false)
	const [markAllLoadingState, setMarkAllLoadingState] = useState(false)
	const [hideViewedIndicator, setHideViewedIndicator] = useState(false)

	useEffect(() => {
		if (!currentUserNotificationBatch) {
			getUserNotifications({
				requestCategory: "all-notifications-paginated",
				paginationRequirements: {
					pageNumber: nextBatchNumber,
					itemsPerPage: 10,
				},
				sortRequest: "most-recent-first"
			})

			setCurrentBatchNumber(currentBatchNumber + 1)
		} else {
			if ((currentBatchNumber === nextBatchNumber + 1)) {
				setLoadingState(false)

				const updatedArray = [...displayArray, ...currentUserNotificationBatch]
				setDisplayArray(updatedArray)

				setNextBatchNumber(nextBatchNumber + 1)

				if (currentUserNotificationBatch?.length < 10) {
					setHideGetMoreButton(true)
				}
			}
		}


	}, [currentUserNotificationBatch]);

	useEffect(() => {
		if (markAllNotificationsAsViewedOutcome) {
			const { successfullyProcessed } = markAllNotificationsAsViewedOutcome || {}

			if (successfullyProcessed) {
				setHideViewedIndicator(true)

				toast(
					triggerLaunchdeckToast({
						useCase: "show-success-state",
						label: "All notifications marked as viewed",
						isMobileView
					})
				)

				setMarkAllLoadingState(false)

			} else {
				toast(
					triggerLaunchdeckToast({
						useCase: "show-error-state",
						label: "Unfortunately something went wrong. Please try again later.",
						isMobileView
					})
				)
			}
		}
	}, [markAllNotificationsAsViewedOutcome]);

	const handleGetMoreNotifications = () => {
		setLoadingState(true)

		getUserNotifications({
			requestCategory: "all-notifications-paginated",
			paginationRequirements: {
				pageNumber: nextBatchNumber,
				itemsPerPage: 10,
			},
			sortRequest: "most-recent-first"
		})

		setCurrentBatchNumber(currentBatchNumber + 1)
	}

	const handleNotificationClick = (notificationId, notificationRedirectLink, relatedNotificationIds) => {
		if (notificationRedirectLink) {
			const notificationsToMarkAsViewed = relatedNotificationIds ? [notificationId, ...relatedNotificationIds] : notificationId ? [notificationId] : ""
			notificationsToMarkAsViewed && markUserNotificationAsViewed([notificationsToMarkAsViewed])
			redirectToNewPage(notificationRedirectLink)
		}
	}

	const renderNotificationList = () => {
		const renderedList = displayArray?.length > 0 ? displayArray.map((notification, noteIndex) => {

			const { notificationId, notificationType, createdAt: notificationDate, viewed, notificationRedirectLink, relatedNotificationsCount, relatedNotificationIds } = notification || {}
			const displayedNotificationMessage = notificationType ? determineNotificationMessage(notification) : "";


			if (displayedNotificationMessage) {
				return (
					<Flex key={`header-notification--${notificationId}-${noteIndex}`} color='gray.700'
						py='0.5em'
						minW={['0','20em']}
						maxW='30em'
						align='flex-start'
						justify='flex-start'
						cursor={notificationRedirectLink ? 'pointer' : "default"} onClick={() => handleNotificationClick(notificationId, notificationRedirectLink, relatedNotificationIds)}
					>
						<Image src={determineNotificationImage(notificationType)} boxSize='2.5em' mr='1em' />

						<Box px='0.5em' pt='0.2em'>
							<Text className={"header__profile-info-type"}>
								{displayedNotificationMessage}
							</Text>
							<Flex justify='flex-start' align='center'>
								{(viewed === false && !hideViewedIndicator) && <Box color='brandPink.500' fontSize='0.7em' mr='0.5em'>•</Box>}
								<Text fontSize='0.6em' color='gray.500' mt='0.2em'>
									{displayElapsedDurationSinceTimestamp(notificationDate)} 	{!!relatedNotificationsCount && `• ${relatedNotificationsCount} related notifications`}
								</Text>
							</Flex>

						</Box>
					</Flex>
				)
			} else return <Box key={`header-notification--${notificationId}-${noteIndex}`} />;

		}) : <Box fontSize='0.8em' px='0.5em' color='gray.700'>
			No new notifications. You are all caught up.
		</Box>

		return (

			<Card rounded='0.8em'>
				<CardBody>
					<Stack divider={<StackDivider />} spacing='4'>
						{renderedList}
					</Stack>
				</CardBody>
			</Card>
		)
	}

	const handleMarkAllAsViewed = () => {
		markAllUserNotificationsAsViewed()
		setHideViewedIndicator(true)
		setMarkAllLoadingState(true)
	}

	return (
		<Flex w='100%' direction={['column','row']} justify='space-between' align='flex-start' gap={25}>
			<Box p={['1em 0','2em']} flex={['0','auto']} w={['100%',"auto"]}>
				<Box w='100%'>
					<Box w='100%' bg='white' rounded='0.8em' p='1em' >
						<Box fontWeight='600'>All notifications</Box>
						{markAllLoadingState ? <Spinner
							thickness="2px"
							speed="0.65s"
							emptyColor="gray.200"
							color="brand.500"
							boxSize="1.2em"
							mr='1em' /> : hideViewedIndicator
							? <Box color='gray.500' fontSize='0.65em'>All marked as read</Box>
							: <Box cursor='pointer' onClick={() => handleMarkAllAsViewed()} color='gray.500' fontSize='0.65em'>Mark all as read</Box>}
					</Box>



				</Box>
			</Box>
			<Flex flex={['0','8']} direction="column" align="center" p={['0','2em 3%']} mb='2em' rounded='0.8em'  w={['100%',"auto"]}>

				{renderNotificationList()}

				<Flex direction='column' mt='1em' justify='center' alignItems='center'>
					<Flex justify={['center', 'flex-end', 'flex-end', 'flex-end']} w='100%'>
						{loadingState ? <Spinner
							thickness="2px"
							speed="0.65s"
							emptyColor="gray.200"
							color="brand.500"
							boxSize="1.2em" /> : hideGetMoreButton ? <Text color='gray.500' mt='1em' fontSize='0.875em'>No other notifications</Text> : displayArray?.length > 0 && <LaunchdeckButton bg='brand.500' color='white' onClick={() => handleGetMoreNotifications()} label='More Notifications' />}
					</Flex>
				</Flex>
			</Flex>
		</Flex>
	)
}

const mapStateToProps = (state) => {
	return {
		currentUserNotificationBatch: state.currentUserNotificationBatch.currentUserNotificationBatch,
		markAllNotificationsAsViewedOutcome: state.markAllNotificationsAsViewedOutcome.results.data,
		isMobileView: state.mobileViewMode.isMobileView
	}
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			getUserNotifications,
			markUserNotificationAsViewed,
			markAllUserNotificationsAsViewed
		},
		dispatch,
	)

export default memo(withRouter(connect(mapStateToProps, mapDispatchToProps)(UserNotificationPage)))

