import React from "react";
import { Flex, Avatar, AvatarGroup, Divider, Tooltip, Box } from "@chakra-ui/react";


export default function DsrDisplayTeamAvatar({ perspective, dsrTeamMembers }) {

  let cloneArr = "";
  let showSeparator = false;

  if (perspective === 'seller') {
    cloneArr = dsrTeamMembers && dsrTeamMembers["sellerTeam"];
  } else if (perspective === 'buyer') {
    cloneArr = dsrTeamMembers && dsrTeamMembers["buyerTeam"];
    showSeparator = true;
  } else if (perspective === 'all') {
    const cloneSellerArr = dsrTeamMembers && dsrTeamMembers["sellerTeam"];
    const cloneBuyerArr = dsrTeamMembers && dsrTeamMembers["sellerTeam"];
    cloneArr = [...cloneSellerArr, ...cloneBuyerArr]

    showSeparator = cloneBuyerArr ? true : false;
  }

  let teamToRender = cloneArr ? [...cloneArr] : "";
  let teamMembersToRender = [];

  if (teamToRender) {
    for (const organization of teamToRender) {
      const { orgTeamMembers } = organization || {};
      teamMembersToRender = (Array.isArray(orgTeamMembers) && orgTeamMembers.length > 0) && [...teamMembersToRender, ...orgTeamMembers]
    }
  }

  // Filter away non-active members
  teamMembersToRender = teamMembersToRender ? teamMembersToRender.filter((member) => { return (member.memberStatus === 'active' || member.memberStatus === 'pending') }) : "";

  const displayTeam = teamMembersToRender && teamMembersToRender?.map((teamMember, teamMemberIndex) => {
    const { memberName, memberProfilePicSrc, memberStatus } = teamMember || {};

    if (memberStatus === 'active' || memberStatus === 'pending') {
      // Note: Having 2 layers (Box & Tooltip) between AvatarGroup and Avatar components is throwing the showBorder error in the FE
      return (
        <Box key={`${perspective}_team_member_display_${teamMemberIndex}`} >
          <Tooltip label={memberName} fontSize='0.8em' fontWeight='400' placement="bottom" bg="#4A5568" rounded='0.25em'>
            <Avatar
              border='2px solid #fff'
              h="3.8em" w='3.8em' bg='gray.100' color='gray.700'
              name={memberName}
              src={memberProfilePicSrc ? memberProfilePicSrc : ""} />
          </Tooltip>
        </Box>
      );
    } else return null;
  })

  return (


    <Flex px='0.5em' width='100%' justify='center' align='center' cursor='default'>
      {showSeparator && <Divider orientation="vertical" />}
      {/* // UUU Resolve showBorder warning in FE */}
      <AvatarGroup max={2} justify='center' align='center' spacing='-0.8em'>
        {displayTeam}
      </AvatarGroup>
    </Flex>


  )

}
