import React, { useEffect, useState } from 'react'
import { Box, Flex, Tab, TabList, TabPanel, TabPanels, Tabs, Image, Input, useToast } from "@chakra-ui/react";
import { RiErrorWarningLine } from "react-icons/ri";
import { IoCloudUploadOutline } from "react-icons/io5";
import ImageUploading from "react-images-uploading";
import { bindActionCreators } from "redux";
import { uploadFiles, uploadFilesReset } from "../../digital-sales-room/actions/dsr-actions";
import { connect } from "react-redux";
import PageLoader from "../../global/components/helper-components/components/PageLoader";
import LaunchdeckButton from '../../global/components/helper-components/components/LaunchdeckButton';
import FormTextInput from '../../forms/TextInputField/components/FormTextInput';
import { triggerLaunchdeckToast, validateUrlFormat } from '../../global/helpers/global-helpers';
import WarningTextMessage from '../../global/components/helper-components/components/WarningTextMessage';
import { TbBrandCake } from "react-icons/tb";
import config from "../../../config/app-config";


const CreatedDsrClientBranding = ({
  createDsrForm,
  setCreateDsrForm,
  handleCreateNewDsr,
  setIsBrandingModal,
  uploadFiles,
  uploadFilesState,
  uploadFilesReset
}) => {
  const { dsrClientWebsite, dsrClientName, dsrLogoUrl, dsrUploadedLogoUrl, dsrTextLogoString } = createDsrForm;
  const { isLoading, results, error } = uploadFilesState;

  const [logoFile, setLogoFile] = useState(null)
  const [textLogo, setTextLogo] = useState(dsrClientName ? dsrClientName : '')
  const [imageLoaded, setImageLoaded] = useState(false);
  const [imageError, setImageError] = useState(false);

  const textLogoCharacterLimit = 20;

  const [uriInput, setUriInput] = useState(dsrClientWebsite ? dsrClientWebsite : "")
  const [feedUriInput, setFeedUriInput] = useState("")
  const [searchedUri, setSearchedUri] = useState("")
  const [showInvalidUrlError, setShowInvalidUrlError] = useState(false)
  // const [newCbLogoFound, setNewCbLogoFound] = useState(false)
  const [showLogoLoader, setShowLogoLoader] = useState(false)
  const [showLogoImage, setShowLogoImage] = useState(false)
  const [changeLogoMode, setChangeLogoMode] = useState(false)

  const toast = useToast();

  const { maxImageFileSize } = config;

  useEffect(() => {
    if (dsrLogoUrl || dsrTextLogoString || dsrUploadedLogoUrl) {
      handleCreateNewDsr()
    }
  }, [dsrLogoUrl, dsrTextLogoString, dsrUploadedLogoUrl]);

  
  useEffect(() => {
    if (!isLoading && !error && results.files) {
      setCreateDsrForm({
        ...createDsrForm,
        dsrLogoType: "uploaded-dsr-logo",
        dsrUploadedLogoUrl: `${results?.files[0]?.url}`,
        displayedLogoImageKey: `${results?.files[0]?.key}`,
        dsrLogoUrl: "",
        dsrTextLogoString: ""
      });
      uploadFilesReset();
    }
  }, [results]);

  const handleAcceptProposedLogo = () => {
  
    const dsrLogoUrl = feedUriInput ? feedUriInput 
    : validateUrlFormat(dsrClientWebsite) ? 
      `https://logo.clearbit.com/${dsrClientWebsite}`
      : ""

    if (dsrLogoUrl) {
      setCreateDsrForm({
        ...createDsrForm,
        dsrLogoType: dsrLogoUrl ? "cb-dsr-logo" : "",
        dsrLogoUrl: dsrLogoUrl,
        dsrTextLogoString: "",
        dsrUploadedLogoUrl: ""
      })

      if (!dsrLogoUrl) {
           toast(
            triggerLaunchdeckToast({
              useCase: "show-error-state",
              label: "Unfortunately we are unable to set the logo right now. Please try again later.",
              isMobileView: false
            })
          )
      }
    } 
  }

  const handeUploadLogo = () => {
    uploadFiles({ files: [logoFile[0].file] })
  }

  const handleAcceptTextLogo = () => {
    setCreateDsrForm({
      ...createDsrForm,
      dsrLogoType: "textual-dsr-logo",
      dsrTextLogoString: textLogo,
      dsrLogoUrl: "",
      dsrUploadedLogoUrl: ""
    })
  }

  const handleImageError = () => {
    setImageError(true)
    setImageLoaded(false)
    setShowLogoImage(false)
    setSearchedUri(uriInput)
    setChangeLogoMode(false)
  };

  const handleImageLoad = () => {
    setImageError(false)
    setImageLoaded(true)
    setShowLogoImage(true)
  };

  const handleInputChange = (event) => {
    setUriInput(event.target.value)
  }

  const handleGetCbLogo = () => {
    setShowLogoLoader(true)
    setShowInvalidUrlError(false)
    const isValidUrl = uriInput ? validateUrlFormat(uriInput) : false;
    // setShowImageError(false)
    // setCurrentLogoImage(false)

    if (isValidUrl && uriInput) {
      setFeedUriInput(`https://logo.clearbit.com/${uriInput}`)
      setShowLogoImage(true)
      // setNewCbLogoFound(true)

    } else {
      setShowInvalidUrlError(true)
    }
  }

  const handlePressEnter = (event) => {
    if (event.key === 'Enter' && event.target.name === "logo-search-input") {
      setShowLogoLoader(true)
      setShowInvalidUrlError(false)
      const isValidUrl = uriInput ? validateUrlFormat(uriInput) : false;
      // setShowImageError(false)
      // setCurrentLogoImage(false)

      if (isValidUrl && uriInput) {
        setFeedUriInput(`https://logo.clearbit.com/${uriInput}`)
        setShowLogoImage(true)
        // setNewCbLogoFound(true)

      } else {
        setShowInvalidUrlError(true)
      }

    }
  }

  const handleImageFound = () => {
    // setShowLogoInput(false)
    // setShowChangeLogoButton(false)
    setShowLogoLoader(false)
  }

  const displaySearchLogoModule = () => {
    return (<Box minW='20em' my='1em'>

      <FormTextInput
        name="logo-search-input"
        w='100%'
        color='#2D3748'
        mb='0em'
        onChange={handleInputChange}
        onKeyDown={handlePressEnter}
        borderColor='gray.400'
        placeholder='e.g. www.google.com'
        textAlign='center'
      // defaultValue={uriInput}
      />
      <WarningTextMessage textAlign='center' errorLogic={showInvalidUrlError} message='Please use a valid weblink' />

      <Box fontSize='0.75em' textAlign='center' color='gray.500'>
        Tip: Remove extensions. Try 'www.website.com', and not 'www.website.com/home'</Box>

      <Flex mt='2em' align='center' justify='center'>
        <LaunchdeckButton name='userProviderUri' label='Search for logo' fontSize='0.9em' py='0.8em' bg='brandDark.500' color='gray.50' onClick={() => handleGetCbLogo()} />
      </Flex>

    </Box>)
  }

  const backButton = (
    <LaunchdeckButton bg='gray.100' color='brandDark.500' onClick={() => setIsBrandingModal(false)} label='Go back' />
  )

  return (
    <Box width='100%'>
      <Tabs defaultIndex={0} width='100%' >
        <TabList padding='0 24px'>
          <Tab fontSize='0.875em' fontWeight='500' p='0.75em 1em'>Search for logo</Tab>
          <Tab fontSize='0.875em' fontWeight='500' p='0.75em 1em'>Upload a logo</Tab>
          <Tab fontSize='0.875em' fontWeight='500' p='0.75em 1em'>Use textual logo</Tab>
        </TabList>

        <TabPanels padding='1.5em'>
          <TabPanel>
            {(imageError) ?
              <Flex
                direction='column'
                border='1px dashed #b7b7b7'
                borderRadius='0.625em'
                minH='16em'
                //  width='100%'
                justify='center'
                align='center'
                m='0 1em 2em 1em'

              >

                {showLogoImage ?
                  <React.Fragment>
                    <Flex boxSize='128px' direction='column' mt='1em' mb='3em' justify='center' align='center' border='1px solid' borderColor='gray.200' rounded='0.25em' p='0.5em'>
                      <Box display={showLogoLoader ? "block" : "none"}>
                        <PageLoader />
                      </Box>
                      <Box border='2px solid' borderColor='white' rounded='0.5em' bg='white'>
                        <Image src={`${feedUriInput}`} onError={handleImageError} onLoad={handleImageFound} />
                      </Box>
                    </Flex>

                    <LaunchdeckButton bg='brandDark.500' color='white'
                      onClick={() => {
                        setShowLogoImage(false);
                        // setShowLogoInput(true);
                        // setShowChangeLogoButton(false)
                        setChangeLogoMode(true)
                      }}
                      label='Change logo' />

                  </React.Fragment>
                  :
                  <>
                    {(dsrClientWebsite || imageError) ?
                      <Box mb='1em' textAlign='center' minH='' >
                        {changeLogoMode ?
                          <Box mt='2em'>
                            <TbBrandCake size='5em' />
                          </Box>
                          :
                          <Box color='brandDark.500'>
                            {searchedUri ? <Box>No record found for <span style={{ fontStyle: 'italic' }}> {searchedUri}</span></Box> : <Box>No logo found</Box>}
                          </Box>
                        }
                      </Box>
                      : <>
                        <Box mb='1em' textAlign='center' minH='' color='brandDark.500'>
                          Search for and use their website's logo
                        </Box>

                      </>}
                    {displaySearchLogoModule()}
                  </>

                }
              </Flex>

              :
              <Flex
                direction='column'
                border='1px dashed #b7b7b7'
                borderRadius='0.625em'
                minH='16em'
                justify='center'
                align='center'
                m='0 1em 2em 1em'

              >
                <Box mb='1em' display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
                  <Flex boxSize='128px' mt='1em' direction='column' mb='3em' justify='center' align='center' border='1px solid' borderColor='gray.200' rounded='0.25em' p='0.5em'>

                    <Image
                      width='3.125em'
                      height='3.125em'
                      src={`https://logo.clearbit.com/${dsrClientWebsite}`}
                      alt="Brand logo"
                      fallback={
                        <PageLoader boxSize='6em' />
                      }
                      onLoad={handleImageLoad}
                      onError={handleImageError}
                    />
                  </Flex>


                  <Box>
                    <LaunchdeckButton bg='brandDark.500' color='white'
                      onClick={() => {
                        setShowLogoImage(false);
                        // setShowLogoInput(true);
                        //  setShowChangeLogoButton(false)
                        setChangeLogoMode(true)
                        setImageError(true)
                        setUriInput("")
                        setFeedUriInput("")
                        setImageLoaded(false)
                      }}
                      label='Change logo' />
                  </Box>
                </Box>
              </Flex>
            }



            <Flex w='100%' py='0.625em'>
              <Flex justify='space-between' w='100%'>
                {backButton}
                {(imageLoaded || showLogoImage) && <LaunchdeckButton bg='brand.500' color='gray.50' onClick={handleAcceptProposedLogo} label='Use logo' />}
                {/* {(imageLoaded) && <LaunchdeckButton bg='brand.500' color='gray.50' onClick={handleAcceptProposedLogo} label='Use logo' />} */}
           
              </Flex>
            </Flex>

          </TabPanel>
          <TabPanel>
            <Box margin='0 1em'>
              <UploadingLogo logoFile={logoFile} setLogoFile={setLogoFile} isLoading={isLoading} maxImageFileSize={maxImageFileSize}/>

              {/* <Flex  py='0.625em'>
                          <Flex justify='center' w='100%'> */}
                         
                          {/* </Flex>
                        </Flex> */}

            </Box>
            <Flex w='100%' py='0.625em'>
              <Flex justify='space-between' w='100%'>
                {backButton}
                {(logoFile && !isLoading)
                  ?
                  <LaunchdeckButton bg='brand.500' color='gray.50' fontSize='0.9em' onClick={handeUploadLogo} label='Upload logo' />
                  :
                  <LaunchdeckButton bg='#CBD5E0' color='#fff' dummy fontSize='0.9em' label='Upload logo' />
                }
              </Flex>
            </Flex>
          </TabPanel>


          <TabPanel >
            <Box margin='1em 0.75em' minH='16em'>
              <Flex w='100%' justify='center' pb='2em'>
                <Flex boxSize='5em' justifyContent='center' alignItems='center' textAlign='center' color={!textLogo && 'gray.300'} className="default-text-logo-font" fontSize='1.8em' border='2px solid' borderColor='gray.50' rounded='0.25em'>
                  {textLogo ? textLogo : 'Preview'}
                </Flex>
              </Flex>

              <Flex w='100%' justify='center'>
                <Box width='24em'>
                  <Input
                    name='dsrTitle'
                    value={textLogo}
                    onChange={(e) => setTextLogo(e.target.value)}
                    placeholder="Enter logo text"
                    maxLength={textLogoCharacterLimit}
                    mb='0.2em'
                    h='1.4em'
                    fontSize='0.875em'
                    fontWeight='400'
                    color='brandDark.500'
                    border='1px solid'
                    borderColor='gray.200'
                    borderRadius='4px'
                    padding='16px'
                    textAlign='center'
                  />
                  <Flex w='100%' justify='flex-end' align='center' mb='10px'>
                    <Flex align='center'>
                      <Box fontWeight='600' fontSize='0.875em'>{textLogo.trim().length}</Box>/{textLogoCharacterLimit}
                    </Flex>

                  </Flex>
                </Box>
              </Flex>
            </Box>
            <Flex w='100%' py='0.625em'>
              <Flex justify='space-between' w='100%'>
                {backButton}
                {textLogo
                  ?
                  <LaunchdeckButton bg='brand.500' color='gray.50' onClick={handleAcceptTextLogo} label='Use text as logo' />
                  :
                  <LaunchdeckButton bg='#CBD5E0' color='white' dummy label='Use text as logo' />
                }
              </Flex>
            </Flex>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  )
}

function UploadingLogo({ logoFile, setLogoFile, isLoading, maxImageFileSize }) {
  const maxFileSize = maxImageFileSize;
  const acceptType = ['jpg', 'gif', 'png', 'jpeg'];

  const onChange = (image) => {
    setLogoFile(image);
  };

  if (isLoading) {
    return (
      <Box w="100%" align="center" >
        <Box px='1em'>
          <Box>
            <PageLoader />
          </Box>
        </Box>
      </Box>
    )
  }

  return (
    <ImageUploading
      maxFileSize={maxFileSize}
      value={logoFile}
      onChange={onChange}
      acceptType={acceptType}
      dataURLKey="logoUrl"
    >
      {({
        imageList,
        onImageUpload,
        errors,
        isDragging,
        dragProps,
      }) => (
        <Box
          style={isDragging ? { color: 'red' } : undefined}
          border='1px dashed #b7b7b7'
          borderRadius='10px'
          minH='16em'
          width='100%'
          display='flex'
          justifyContent='center'
          alignItems='center'
          mb='2em'
        >
          {imageList.length === 0
            ? <Box
              display='flex'
              flexDirection='column'
              justifyContent='center'
              alignItems='center'
              cursor='pointer'
              padding='16px'
              width="100%"
              onClick={onImageUpload}
              {...dragProps}
            >
              <Box mb='1.5em' height='50px' width='50px'>
                <IoCloudUploadOutline style={{ width: '100%', height: '100%' }} fill='#b7b7b7' />
              </Box>
              <Box mb='0.75em'>
                Select a file or drag and drop here
              </Box>
              <Box fontSize='12px' color='#b7b7b7' mb='24px'>
                <p>JPG or PNG, file size no more than 10MB</p>
              </Box>

              <Flex mt='1em' align='center' justify='center'>
                <LaunchdeckButton label='Select image' fontSize='0.9em' py='0.8em' bg='brandDark.500' color='gray.50' />
              </Flex>
            </Box>
            : imageList.length > 0
            && 
            <Box>
            <Flex boxSize='8em' direction='column' mb='1em' justify='center' align='center' border='1px solid' borderColor='gray.200' rounded='0.25em' p='0.5em'>
           
              <Image
                src={logoFile[0]?.['logoUrl']} alt={""}
                height='100px'
              />
                
            </Flex>
            {(logoFile) &&
                              <Box mt='1.5em'>
                               {/* <LaunchdeckButton bg='brand.500' color='gray.50' fontSize='0.9em' onClick={handeUploadLogo} label='Upload image as logo' /> */}
                                <Box fontSize='0.8em' color='gray.500' mt='0.5em' fontStyle='italic' cursor='pointer' textAlign='center' onClick={()=> setLogoFile(null)}>Use another image</Box>
                              </Box>
                              
                            }
            </Box>
          }
          {errors &&
            <><RiErrorWarningLine color="red" boxSize={20} /> &nbsp; Upload limit is 10MB per image</>
          }
        </Box>
      )}
    </ImageUploading>
  )
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      uploadFiles,
      uploadFilesReset,
    },
    dispatch,
  );

const mapStateToProps = (state) => {
  return {
    uploadFilesState: state.uploadFilesOutcome,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreatedDsrClientBranding);


