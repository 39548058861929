import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { useHistory } from 'react-router-dom';

import { Box, Flex, useToast } from "@chakra-ui/react";
import WarningTextMessage from '../../../global/components/helper-components/components/WarningTextMessage';
import ProfileFormTextInput from '../../../forms/TextInputField/components/ProfileFormTextInput';
import ClaverModalButtonBar from '../../../global/components/helper-components/components/ClaverModalButtonBar';

import { createOrgUserProfile, createOrgUserProfileReset } from '../../../profile/actions/admin-user-actions'

import { BsCheckCircle, BsCircle } from 'react-icons/bs';
import { redirectToNewPage, triggerLaunchdeckToast, validateEmail } from '../../../global/helpers/global-helpers';
import LaunchdeckButton from '../../../global/components/helper-components/components/LaunchdeckButton';


function AddNewOrgUserForm(props) {

	const { role, orgIdToken, handleCloseModal, manageAdminOrgUsersOutcome, isMobileView } = props || {};
	const toast = useToast();
	const history = useHistory()

	const [showNoRoleError, setShowNoRoleError] = useState(false);
	const [addingInProgress, setAddingInProgress] = useState(false);
	const [showConfirmationModal, setShowConfirmationModal] = useState(false)
	const [showUserFromOtherOrgModal, setShowUserFromOtherOrgModal] = useState(false)
	const [attemptedUserEmail, setAttemptedUserEmail] = useState("")
	const [showUnableToAddInactiveUser, setShowUnableToAddInactiveUser] = useState(false)

	// Inputs
	const [chosenRole, setChosenRole] = useState(role)
	const [memberEmail, setMemberEmail] = useState("")
	const [userOrgTitleDisplay, setUserOrgTitle] = useState("")
	const [memberFirstNameDisplay, setUserFirstName] = useState("")
	const [memberLastNameDisplay, setUserLastName] = useState("")

	// Input validation Errors
	const [showMissingEmailError, setShowMissingEmailError] = useState(false)
	const [showMissingTitleError, setShowMissingTitleError] = useState(false)
	const [showMissingFirstNameError, setShowMissingFirstNameError] = useState(false)
	const [showMissingLastNameError, setShowMissingLastNameError] = useState(false)


	// UX & State Management
	useEffect(() => {
		if (manageAdminOrgUsersOutcome) {
			const { updatedSuccessfully, errorMessage, isUnauthorised, isInactiveUser,
				duplicateUserInOrg, addedMemberFromOtherOrg, attemptedUserEmail } = manageAdminOrgUsersOutcome || {};

			const closeModalAndReset = () => {
				setAddingInProgress(false)
				setAttemptedUserEmail("")
				handleCloseModal()
				props.action.createOrgUserProfileReset()
			}

			if (isUnauthorised === true) {
				redirectToNewPage('/invalid')
			}

			if (updatedSuccessfully === true) {
				toast(
					triggerLaunchdeckToast({
						useCase: "show-success-state",
						// bannerText: "",
						label: "New user successfully created & added to your team",
						isMobileView
					})
				)

				closeModalAndReset()
			}

			if (updatedSuccessfully === false) {
				if (duplicateUserInOrg) {
					setShowConfirmationModal(true)
					props.action.createOrgUserProfileReset()
				}

				if (addedMemberFromOtherOrg) {
					setAttemptedUserEmail(attemptedUserEmail)
					setShowUserFromOtherOrgModal(true)
					props.action.createOrgUserProfileReset()
				}

				if (isInactiveUser) {
					setAttemptedUserEmail(attemptedUserEmail)
					setShowUnableToAddInactiveUser(true)
					props.action.createOrgUserProfileReset()
				}

				if (errorMessage) {
					toast(
						triggerLaunchdeckToast({
							useCase: "show-error-state",
							bannerText: "Error",
							label: errorMessage,
							isMobileView
						})
					)

					closeModalAndReset()
				}
			}
		}
	}, [manageAdminOrgUsersOutcome, props.action, handleCloseModal, toast]);

	// Component functionality
	const handleChange = (event) => {
		const value = event.target.value;
		const variableName = event.target.name;
		switch (variableName) {
			case ('userOrgTitle'): {
				setUserOrgTitle(value)
				break;
			}
			case ('memberFirstName'): {
				setUserFirstName(value)
				break;
			}
			case ('memberLastName'): {
				setUserLastName(value)
				break;
			}
			case ('memberEmail'): {
				setMemberEmail(value)
				break;
			}
			default: {
				console.log('There is no variable with this name', variableName)
				break;
			}
		}
	}

	const handleChooseRole = (role) => {
		// Scenario: When user wants to remove his choice 
		if (chosenRole === role) {
			setChosenRole('')
			setShowNoRoleError(false)
		} else {
			// Scenario: When user picks a role for the first time
			// Scenario: Also when user switches from one role to the other role
			setChosenRole(role)
			setShowNoRoleError(false)
		}
	}

	const handleCreateNewUser = () => {
		const emailValidated = validateEmail(memberEmail)

		if (!userOrgTitleDisplay) {
			setShowMissingTitleError(true)
		} else if (userOrgTitleDisplay) {
			setShowMissingTitleError(false)
		}

		if (!memberFirstNameDisplay) {
			setShowMissingFirstNameError(true)
		} else if (memberFirstNameDisplay) {
			setShowMissingFirstNameError(false)
		}

		if (!memberLastNameDisplay) {
			setShowMissingLastNameError(true)
		} else if (memberLastNameDisplay) {
			setShowMissingLastNameError(false)
		}

		if (!emailValidated) {
			setShowMissingEmailError(true)
		} else if (emailValidated) {
			setShowMissingEmailError(false)
		}

		if (!chosenRole) {
			setShowNoRoleError(true)
		} else if (chosenRole) {
			setShowNoRoleError(false)
		}

		if (chosenRole && userOrgTitleDisplay && memberFirstNameDisplay && memberLastNameDisplay && emailValidated === true) {
			setAddingInProgress(true)

			let newOrgUserProperties = {
				updateEvent: 'add-new-org-user',
				email: memberEmail.trim(),
				firstName: memberFirstNameDisplay.trim(),
				lastName: memberLastNameDisplay.trim(),
				userOrgTitle: userOrgTitleDisplay.trim(),
				chosenRole: chosenRole
			}

			props.action.createOrgUserProfile(newOrgUserProperties, orgIdToken)

		}
	}

	const handleGetSupport = (source) => {

		let suggestedSupportMessage = "";
		switch (source) {
			case ("deactivated-user"): {
				suggestedSupportMessage = `Hi there, I would like to add a member to my team, but it says they have been deactivated or offboarded. Can you please help?\n\nSome helpful information:\n\nEmail:${attemptedUserEmail}`

				break;
			}
			case ("user-from-another-org"): {
				suggestedSupportMessage = `Hi there, I would like to add a member to my team, but it says they belong to another organization currently. Can you please help?\n\nSome helpful information:\n\nEmail:${attemptedUserEmail}`
				break;
			}
			default: {
				break;
			}
		}

		history.push(`/support`, {
			suggestedSupportMessage
		})
	}


	if (showConfirmationModal === false && showUserFromOtherOrgModal === false && showUnableToAddInactiveUser === false) {
		return (
			<React.Fragment>
				<Box px='2em'>
					<Flex p='1em' justify='flex-start' align='center' w='100%' color='brandDark.500' bg='brand.100' rounded='0.5em' fontSize='0.8em' my='1em'>
						A seat will be added to your Organization after adding this new user. Pro rated fees applies.
					</Flex>

					<Box>
						<Box lineHeight='1.375em' mt='1.5em' fontWeight='500' color='gray.700'>
							Role
						</Box>

						<Flex direction={['column', 'row', 'row', 'row']} justify='space-evenly' align='stretch' mt='1em'>
							<Flex flex='1 1 0' border='1px solid' borderColor={(chosenRole === 'admin-user') ? 'brand.300' : 'gray.300'} bg={(chosenRole === 'admin-user') && 'gray.50'} rounded='0.25em' py='0.75em' px='1.25em' cursor='pointer' onClick={() => handleChooseRole('admin-user')}>
								<Box className='flip-in-hor-bottom'>
									{
										(chosenRole === 'admin-user') ? <Box as={BsCheckCircle} className='flip-in-hor-bottom' color='brand.500' width='2em' height='1.4em' mr='0.5em' /> :
											<Box as={BsCircle} className='flip-in-hor-bottom' color='gray.300' width='2em' height='1.4em' mr='0.5em' />
									}
								</Box>
								<Box>
									<Box color='gray.700' lineHeight='1.375em'>Administrator</Box>
									<Box mt='0.5em' fontSize='0.75em' color='gray.500' fontWeight='400'> Administrators can manage Rooms like all its users, plus set organization-wide settings for its users</Box>
								</Box>
							</Flex>

							<Box w='6%' />

							<Flex flex='1 1 0' mt={['1em', '0', '0', '0']} border='1px solid' borderColor={(chosenRole === 'basic-user') ? 'brand.300' : 'gray.300'} bg={(chosenRole === 'basic-user') && 'gray.50'} rounded='0.25em' py='0.75em' px='1.25em' cursor='pointer' onClick={() => handleChooseRole('basic-user')}>
								<Box className='flip-in-hor-bottom'>
									{
										(chosenRole === 'basic-user') ? <Box as={BsCheckCircle} className='flip-in-hor-bottom' color='brand.500' width='2em' height='1.4em' mr='0.5em' /> :
											<Box as={BsCircle} className='flip-in-hor-bottom' color='gray.300' width='2em' height='1.4em' mr='0.5em' />
									}
								</Box>
								<Box>
									<Box color='gray.700' lineHeight='1.375em'>User</Box>
									<Box mt='0.5em' fontSize='0.75em' color='gray.500' fontWeight='400'> Users have access to features and settings chosen by their Administrators</Box>
								</Box>
							</Flex>
						</Flex>
						<Box mt='0.5em'>
							<WarningTextMessage errorLogic={showNoRoleError} message='Please indicate a role for this user' />
						</Box>

						<Box>
							<ProfileFormTextInput name='memberEmail' label='Email' defaultValue={''} placeholder='' mt='1em' onChange={handleChange} />
							<Box mt='0.5em'>
								<WarningTextMessage errorLogic={showMissingEmailError} message='Please provide a valid email for this user' />
							</Box>
						</Box>

						<Flex w='100%' justify='space-between' align='flex-start'>
							<Box flex='1' >
								<ProfileFormTextInput name='memberFirstName' label='First Name' defaultValue={''} placeholder='' mt='1em' onChange={handleChange} />
								<Box mt='0.5em'>
									<WarningTextMessage errorLogic={showMissingFirstNameError} message='Please provide a first name for this user' />
								</Box>
							</Box>

							<Box flex='1' ml='1em'>
								<ProfileFormTextInput name='memberLastName' label='Last Name' defaultValue={''} placeholder='' mt='1em' onChange={handleChange} />
								<Box mt='0.5em'>
									<WarningTextMessage errorLogic={showMissingLastNameError} message='Please provide a last name for this user' />
								</Box>
							</Box>
						</Flex>


						<Box>
							<ProfileFormTextInput name='userOrgTitle' label='Title' defaultValue={''} placeholder='e.g. Head of Marketing, Cofounder' mt='1em' onChange={handleChange} />
							<Box mt='0.5em'>
								<WarningTextMessage errorLogic={showMissingTitleError} message='Please provide an official title for this user' />
							</Box>
						</Box>



					</Box>

				</Box>

				<Box mt='2em'>
					<ClaverModalButtonBar
						handleCloseModal={handleCloseModal}
						loadingLogic={addingInProgress}
						ctaFunction={() => handleCreateNewUser()}
						ctaLabel='Add User'
						cancelLabel='Cancel'
					/>
				</Box>


			</React.Fragment>
		)
	}

	if (showConfirmationModal === true) {

		return (
			<React.Fragment>
				<Box p='2em' className='fade-in-left'>
					<Box>
						{memberEmail} is already a member of your team
					</Box>

					<Flex mt='1.5em' w='100%' justify='center'>
						<LaunchdeckButton bg='brand.500' color='white' onClick={() => handleCloseModal()} label='OK' />
					</Flex>

				</Box>
			</React.Fragment>
		)

	}

	if (showUserFromOtherOrgModal === true) {
		return (
			<React.Fragment>
				<Box p='2em' className='fade-in' fontSize='0.875em'>
					<Box>
						This member belongs to another organization and cannot be added to yours
					</Box>

					<Box my='1em'>
						If you think there has been an error, get in touch with Support, and we'll look into it immediately.
					</Box>

					<Flex mt='1.5em' w='100%' justify='center'>
						<LaunchdeckButton bg='brandPink.500' color='white' mx="0.5em" onClick={() => handleGetSupport("user-from-another-org")} label='Get support' />
						<LaunchdeckButton bg='brand.500' color='white' mx="0.5em" onClick={() => handleCloseModal()} label='OK' />
					</Flex>

				</Box>
			</React.Fragment>
		)
	}

	if (showUnableToAddInactiveUser === true) {
		return (
			<Box p='2em' className='fade-in' fontSize='0.875em'>
				<Box>
					You are attempting to add a deactivated or offboarded member.
				</Box>

				<Box my='1em'>
					If you think there has been an error, get in touch with Support, and we'll look into it immediately.
				</Box>

				<Flex mt='1.5em' w='100%' justify='center'>
					<LaunchdeckButton bg='brandPink.500' color='white' mx="0.5em" onClick={() => handleGetSupport("deactivated-user")} label='Get support' />
					<LaunchdeckButton bg='brand.500' color='white' mx="0.5em" onClick={() => handleCloseModal()} label='OK' />
				</Flex>

			</Box>
		)
	}

}


function mapStateToProps(state) {
	return {
		manageAdminOrgUsersOutcome: state.manageAdminOrgUsersOutcome.results.data,
		isMobileView: state.mobileViewMode.isMobileView
	};
}

function mapDispatchToProps(dispatch) {
	return {
		action: bindActionCreators(
			{
				createOrgUserProfile,
				createOrgUserProfileReset
			},
			dispatch
		)
	};
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddNewOrgUserForm));
