import React, { useEffect, useState, useRef } from 'react';

import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';


import { Box, Flex, Text, Switch, useToast } from "@chakra-ui/react";
import WarningTextMessage from '../../../global/components/helper-components/components/WarningTextMessage';

import {
	createGenesisUser, createGenesisUserReset, offboardUser, offboardUserReset, editGenesisUser, createOrgForExistingUser,
	resetCreateNewOrgForExistingUser, manageExistingUser, resetManageExistingUser,
	manageExistingOrganization,
	resetManageExistingOrganization
} from '../../../profile/actions/app-admin-actions'
import AppAdminCreateUserForm from './AppAdminCreateUserForm';
import AppAdminCreateOrgForm from './AppAdminCreateOrgForm';
import LaunchdeckButton from '../../../global/components/helper-components/components/LaunchdeckButton';
import { copyToUserClipboard, reloadPage, routeToUnexpectedLoggedInErrorPage, triggerLaunchdeckToast, validateEmail } from '../../../global/helpers/global-helpers';
import { getUserAccountType } from '../../helpers/user-helper';
import PageLoader from '../../../global/components/helper-components/components/PageLoader';
import { GoChevronDown } from 'react-icons/go';
import AppAdminOffboardUserForm from './AppAdminOffboardUserForm';
import AppAdminResetTokenForm from './AppAdminResetTokenForm';
import AppAdminCreateOrgForExistingUserForm from './AppAdminCreateOrgForExistingUserForm';
import AppAdminEditUserForm from './AppAdminEditUserForm';
import AppAdminEditOrgForm from './AppAdminEditOrgForm';

function CustomerSetupPage({
	// Action
	createGenesisUser,
	createGenesisUserReset,
	offboardUser,
	offboardUserReset,
	editGenesisUser,

	createOrgForExistingUser,
	resetCreateNewOrgForExistingUser,
	manageExistingUser,
	resetManageExistingUser,
	manageExistingOrganization,
	resetManageExistingOrganization,
	// Redux
	createGenesisUserOutcome,
	offboardUserOutcome,
	createNewOrgForExistingUserOutcome,
	manageExistingUserOutcome,
	manageUserIsProcessing,
	manageExistingOrgOutcome,
	manageOrgIsProcessing
}) {


	const saaDropdownRef = useRef(null);

	// Local states
	const [localPageState, setLocalPageState] = useState({
		emailInput: "",
		nameInput: "",
		userOrgTitle: "",
		orgName: "",
		orgLogo: "",
		orgSeats:"",
		inviteLinkType: "",
		inviteLinkUrl: "",
		orgIdInput:""
	})

	const toast = useToast()
	const [offboardUserlocalState, setOffboardUserLocalState] = useState("")

	// UX
	const [showPage, setShowPage] = useState(false)
	const [showOrgCreationSection, setShowOrgCreationSection] = useState(true)
	const [processCompleted, setProcessCompleted] = useState(false)

	const [showCreatedUserFeedback, setShowCreatedUserFeedback] = useState(false)
	const [showCreatedOrgFeedback, setShowCreatedOrgFeedback] = useState(false)
	const [showMappedToUserFeedback, setShowMappedToUserFeedback] = useState(false)
	const [showUnexpectedError, setShowUnexpectedError] = useState(false)
	const [errorReason, setErrorReason] = useState("")
	const [showOffboardedUserFeedback, setShowOffboardedUserFeedback] = useState(false)
	const [mapItToExistingUser, setMapItToExistingUser] = useState(true)
	const [existingUserNotFound, setExistingUserNotFound] = useState(false)
	const [displayedInviteUrl,setDisplayedInviteUrl] = useState("")
	const [existingOrgNotFound, setExistingOrgNotFound] = useState(false)

	// Manage dropdown function
	const [saaUseCase, setSaaUseCase] = useState("create-genesis-user")
	const [showDropdown, setShowDropdown] = useState(false)

	// Input validation Errors
	const [showMissingInputError, setShowMissingInputError] = useState(false)
	const [isInvalidEmail, setIsInvalidEmail] = useState(false)

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (saaDropdownRef.current && !saaDropdownRef.current.contains(event.target)) {
				setShowDropdown(false);
			}
		};

		document.addEventListener('click', handleClickOutside);

		return () => {
			document.addEventListener('click', handleClickOutside);
		};
	}, []);

	// Validation
	useEffect(() => {
		const userType = getUserAccountType();
		if (userType !== "super-app-admin") {
			routeToUnexpectedLoggedInErrorPage()
		} else {
			setShowPage(true)
		}

	}, []);

	// UX & State Management
	useEffect(() => {
		if (createGenesisUserOutcome) {
			const { userCreated, orgCreated, inviteLinkUrl } = createGenesisUserOutcome || {};

			if (userCreated === true) {
				setShowCreatedUserFeedback(true)
			}

			if (orgCreated === true) {
				setShowCreatedOrgFeedback(true)
			}

			if (!!inviteLinkUrl) {
				setDisplayedInviteUrl(inviteLinkUrl)
			}

			setProcessCompleted(true)
			createGenesisUserReset()
		}
	}, [createGenesisUserOutcome]);

	useEffect(() => {
		if (offboardUserOutcome) {
			// const { userOffboarded, processError, errorMessage } = offboardUserOutcome || {};

			setOffboardUserLocalState({
				...offboardUserOutcome // Contains userOffboarded, processError, errorMessage
			})

			setShowOffboardedUserFeedback(true)
			setProcessCompleted(true)

			offboardUserReset()
		}
	}, [offboardUserOutcome]);

	useEffect(() => {
		if (createNewOrgForExistingUserOutcome) {
			const { orgCreated, orgMappedToUser, unexpectedError, errorReason, existingUserNotFound, userCurrentOrganization } = createNewOrgForExistingUserOutcome || {};

			if (orgCreated === true && orgMappedToUser === true) {
				setShowCreatedOrgFeedback(true)
				setShowMappedToUserFeedback(true)

			} else if (!orgCreated) {
				if (existingUserNotFound) {
					setExistingUserNotFound(true)
				}

				if (userCurrentOrganization) {
					const {orgName} = userCurrentOrganization || {};
					setErrorReason(`Unable to create organization for this user. \n\nReason: The user is currently a team member of "${orgName}"`)
				}
			}

			if ((orgMappedToUser === false && errorReason)) {
				setErrorReason(errorReason ? errorReason : "Unknown unexpected error")
			}

			if (!!unexpectedError) {
				setShowUnexpectedError(true)
				setErrorReason(errorReason ? errorReason : "Unknown unexpected error")
			}

			setProcessCompleted(true)
			resetCreateNewOrgForExistingUser()
		}
	}, [createNewOrgForExistingUserOutcome]);

	useEffect(() => {
		if (manageExistingUserOutcome) {
			const { caseProcessed, foundExistingUser, inviteLink } = manageExistingUserOutcome || {};

			if (caseProcessed === "retrieve-existing-user" && foundExistingUser) {
				const { firstName, lastName, status, email, idToken: userIdToken, userGroupName, userNotFound } = foundExistingUser || {};

				if (!!userNotFound) {
					setExistingUserNotFound(true)
				} else {
					setLocalPageState((prevState) => ({
						...prevState,
						userIdToken,
						emailInput: email,
						status,
						firstName,
						lastName,
						userGroupName,
						...inviteLink && { inviteLinkType: inviteLink.type },
						...inviteLink && { inviteLinkUrl: inviteLink.url }
					}))
				}

			}

			resetManageExistingUser()
		}
	}, [manageExistingUserOutcome])


	useEffect(()=> {
		if (manageExistingOrgOutcome) {
			const { caseProcessed, foundExistingOrganization, orgUserUpdatedSuccessfully, orgInfoUpdatedSuccessfully, updatedOrgInfo } = manageExistingOrgOutcome || {};
		
			if (caseProcessed === "retrieve-existing-organization" && foundExistingOrganization) {
				const { orgName, idToken: foundOrgId, orgNotFound, orgSeats, orgLogo, orgDescription, stripeOrgCustomerId, status, orgAdminUsers, orgBasicUsers, orgMemberStatusOptions, orgStatusOptions } = foundExistingOrganization || {};

				if (!!orgNotFound) {
					setExistingOrgNotFound(true)
				} else {

					setLocalPageState((prevState) => ({
						...prevState,
						orgName,
						foundOrgId,
						orgStatus: status,
						orgSeats,
						orgLogo,
						orgDescription,
						stripeOrgCustomerId,
						orgAdminUsers,
						orgBasicUsers,
						orgMemberStatusOptions,
						orgStatusOptions
					}))
				}

			}

			if (caseProcessed === "save-edit-organization-membership" && orgUserUpdatedSuccessfully === true) {
   
				const { orgBasicUsers, orgAdminUsers } = manageExistingOrgOutcome || {};

				setLocalPageState((prevState) => ({
					...prevState,
					...orgAdminUsers && {orgAdminUsers},
					...orgBasicUsers && {orgBasicUsers}
				}))

      }

			if (caseProcessed === "save-edit-org-info" && orgInfoUpdatedSuccessfully === true) {

				if (updatedOrgInfo) {
					setLocalPageState((prevState) => ({
						...prevState,
						...updatedOrgInfo
					}))
				}
			}

			resetManageExistingOrganization()
		}
	},[manageExistingOrgOutcome])

	// Feature functions
	const handleCreateNewOrgToggle = () => {
		setShowOrgCreationSection(!showOrgCreationSection)
	}

	const handleChange = (event) => {
		if (event.target) {
			setShowMissingInputError(false)

			if (event.target.name === "emailInput") {
				setIsInvalidEmail(false)
			}

			setLocalPageState(prevState => ({
				...prevState,
				[event.target.name]: event.target.value
			}));
		}
	}

	const resetLocalState = () => {
		setLocalPageState({
			emailInput: "",
			nameInput: "",
			userOrgTitle: "",
			orgName: "",
			orgLogo: "",
			inviteLinkType: "",
			inviteLinkUrl: "",
			orgIdInput:""
		})
		setOffboardUserLocalState("")
		setShowOffboardedUserFeedback(false)
		setExistingUserNotFound(false)

		setProcessCompleted(false)
		setShowMissingInputError(false)
		setMapItToExistingUser(true)
		setShowMappedToUserFeedback(false)
		setShowUnexpectedError(false)
		setErrorReason("")
		setDisplayedInviteUrl("")
	}

	const handleCreateGenesisUser = () => {
		const isValidEmail = validateEmail(localPageState.emailInput.trim());

		if (isValidEmail) {
			if (showOrgCreationSection) {
				if (!localPageState.orgName || !localPageState.orgLogo || !localPageState.userOrgTitle || !localPageState.emailInput || !localPageState.nameInput) {
					setShowMissingInputError(true)
				} else {
					createGenesisUser({
						...localPageState,
						createNewOrgInstance: showOrgCreationSection
					})
				}
			} else {
				if (!localPageState.emailInput || !localPageState.nameInput) {
					setShowMissingInputError(true)
				} else {
					createGenesisUser({
						...localPageState,
						createNewOrgInstance: showOrgCreationSection
					})
				}
			}
		} else {
			setIsInvalidEmail(true)
		}

	}

	const handleOffboardUser = () => {
		const isValidEmail = validateEmail(localPageState.emailInput.trim());

		if (isValidEmail) {
			if (localPageState.emailInput) {
				offboardUser(localPageState.emailInput.trim())
				resetLocalState()
			} else {
				setShowMissingInputError(true)
			}
		} else {
			setIsInvalidEmail(true)
		}

	}

	const handleEditGenesisUser = (useCase, providedPayload) => {
		let editGenUserPayload = ""

		switch (useCase) {
			case ("reset-token"): {
				const { genUserEmail } = providedPayload || {};
				editGenUserPayload = {
					useCase,
					genUserEmail
				}
				break;
			}
			default: {
				console.log(`Error Code DFI68N : there is no such useCase: ${useCase}`)
				break;
			}
		}

		if (useCase && editGenUserPayload) {
			editGenesisUser({ useCase, editGenUserPayload })
		}
	}

	const handleManageExistingUser = ({ isEditUser }) => {
		const isValidEmail = validateEmail(localPageState.emailInput.trim());
		setExistingUserNotFound(false)

		if (!!isEditUser) {
			manageExistingUser({
				useCase: "edit-existing-user",
				manageExistingUserPayload: {
					userToEditIdToken: localPageState.userIdToken,
					firstName: localPageState.firstName,
					lastName: localPageState.lastName,
					userGroupName: localPageState.userGroupName ? localPageState.userGroupName : "",
				}
			})
		} else {
			if (!!isValidEmail) {
				manageExistingUser({
					useCase: "retrieve-existing-user",
					manageExistingUserPayload: {
						email: localPageState.emailInput
					}
				})
			} else {
				setIsInvalidEmail(true)
			}
		}


	}

	const handleManageExistingOrg = () => {
		manageExistingOrganization({
			useCase: "retrieve-existing-organization",
			manageExistingOrganizationPayload: {
				orgIdToken: localPageState.orgIdInput
			}
		})
	}

	const handleCreateNewOrg = () => {

		if (mapItToExistingUser) {
			const {emailInput} = localPageState || {}
			const isValidEmail = emailInput ? validateEmail(emailInput.trim()) : false;

			if (isValidEmail) {
				createOrgForExistingUser(
					{
						...localPageState,
						mapItToExistingUser: mapItToExistingUser
					}
				)
			} else {
				setIsInvalidEmail(true)
			}
		}



	}

	const handleMapNewOrgToExistingUserToggle = () => {
		setMapItToExistingUser(!mapItToExistingUser)
	}

	// Sub components
	const renderUseCaseDropdown = () => {
		const handlePickChoice = (choice) => {
			setSaaUseCase(choice)
			resetLocalState()
		}

		const renderChoiceText = () => {
			let displayText = ""
			switch (saaUseCase) {
				case ("create-genesis-user"): {
					displayText = "Create Customer User & Org";
					break;
				}
				case ("edit-customer-user"): {
					displayText = "Edit Customer User";
					break;
				}
				case ("offboard-customer-user"): {
					displayText = "Offboard Customer User";
					break;
				}
				case ("reset-genesis-user-token"): {
					displayText = "Reset Change Password Token";
					break;
				}
				case ("create-new-org-for-existing-user"): {
					displayText = "Create Org for existing user";
					break;
				}
				default: {
					displayText = "Choose what you want to do...";
					break;
				}
			}

			return displayText;
		}

		return (
			<Flex direction={["column", "column", "row", "row"]} mt={["1em", "1em", "0", "0"]} width={["100%", "100%", "", ""]} justify={["center", "center", 'flex-end', 'flex-end']} align={['flex-start', 'flex-start', 'center', 'center']}>
				<Flex className='filter-menu' minW='20em' onClick={() => setShowDropdown(!showDropdown)} ref={saaDropdownRef}>
					<Flex>
						<Box className='chosen-option-text-display'>{renderChoiceText()}</Box>
					</Flex>

					<Box as={GoChevronDown} boxSize="0.75em" color="gray.700" />

					{/* Show Dropdown bar here */}
					<Flex className='filter-menu__dropdown' display={showDropdown ? 'flex' : 'none'} direction='column' flexWrap='wrap'>
						<Box className={(saaUseCase !== "create-genesis-user") ? 'filter-menu__dropdown-option-display' : 'filter-menu__dropdown-chosen-option-display'} onClick={() => (saaUseCase !== "") && handlePickChoice("create-genesis-user")}>Create new customer</Box>
						<Box className={(saaUseCase !== "create-new-org-for-existing-user") ? 'filter-menu__dropdown-option-display' : 'filter-menu__dropdown-chosen-option-display'} onClick={() => (saaUseCase !== "") && handlePickChoice("create-new-org-for-existing-user")}>Create new organization</Box>
						<Box className={(saaUseCase !== "reset-genesis-user-token") ? 'filter-menu__dropdown-option-display' : 'filter-menu__dropdown-chosen-option-display'} onClick={() => (saaUseCase !== "") && handlePickChoice("reset-genesis-user-token")}>Reset password token</Box>
						<Box className={(saaUseCase !== "edit-customer-user") ? 'filter-menu__dropdown-option-display' : 'filter-menu__dropdown-chosen-option-display'} onClick={() => (saaUseCase !== "") && handlePickChoice("edit-customer-user")}>Search / edit user</Box>
						<Box className={(saaUseCase !== "offboard-customer-user") ? 'filter-menu__dropdown-option-display' : 'filter-menu__dropdown-chosen-option-display'} onClick={() => (saaUseCase !== "") && handlePickChoice("offboard-customer-user")}>Offboard user</Box>
						<Box className={(saaUseCase !== "edit-customer-user") ? 'filter-menu__dropdown-option-display' : 'filter-menu__dropdown-chosen-option-display'} onClick={() => (saaUseCase !== "") && handlePickChoice("edit-organization")}>Search / edit organization</Box>

					</Flex>

				</Flex>
			</Flex>
		)
	}

	const renderOffboardUserFeedback = () => {
		const { userOffboarded, processError, errorMessage } = offboardUserlocalState || {}
		if (userOffboarded) {
			return (
				<Box fontSize='0.875em' p='1em' border='1px solid' borderColor='green.500' bg='green.50' rounded='0.25em'>
					User offboarded successfully
				</Box>
			)
		} else {
			if (processError) {
				return (
					<Flex w='100%' justify='flex-start' fontSize='0.875em' p='1em' border='1px solid' borderColor='pink.500' bg='pink.50' rounded='0.25em'>
						{errorMessage}
					</Flex>
				)
			} else return null;
		}

	}

	const handleCopyLink = () => {
    copyToUserClipboard(displayedInviteUrl)

    toast(
      triggerLaunchdeckToast({
        useCase: "show-success-state",
        // bannerText: "",
        label: "Genesis User Invite link copied",
        isMobileView: false // SAA mode not in mobile
      })
    )
  }

	const renderForm = () => {
		switch (saaUseCase) {
			case ("create-genesis-user"): {
				
				return (
					<>
						<Box mb='1em'>
							<Box fontSize='0.875em' fontWeight='semibold'>User Creation</Box>
							<Text fontSize='0.8em' color='gray.600' mb='0.8em'>Creates New Genesis user</Text>
						</Box>

						{showCreatedUserFeedback ? <Flex p='1em' fontSize='0.875em' w='100%' bg='green.50' border='1px solid' borderColor='green.300' rounded='0.5em'>User Created Successfully</Flex> :
							<AppAdminCreateUserForm
								handleChange={handleChange}
								localPageState={localPageState}
								isInvalidEmail={isInvalidEmail}
							/>
						}

						{showCreatedOrgFeedback ? <Flex w='100%' mt='2em' fontSize='0.875em' p='1em' bg='green.50' border='1px solid' borderColor='green.300' rounded='0.5em'>Org Created Successfully</Flex>
							:
							<React.Fragment>
								<Flex w='100%' justify='flex-end' p='0.5em 1em' align='center' bg='gray.100' rounded='0.5em' border='1px solid' borderColor='gray.300'>
									<Box fontSize='0.875em'>Create New Organization?</Box>
									<Switch size="lg" ml='1em' isChecked={showOrgCreationSection} onChange={handleCreateNewOrgToggle} />
								</Flex>

								{showOrgCreationSection &&
									<AppAdminCreateOrgForm
										handleChange={handleChange}
										localPageState={localPageState}
									/>
								}
								<WarningTextMessage errorLogic={showMissingInputError} message={`Please ensure all inputs are provided`} />

							</React.Fragment>
						}

						{
							displayedInviteUrl && 
							<Box p='1em 0' mt='1em' fontSize='0.875em' w='100%'>
								<Box fontWeight='600' mb='1em'>Genesis User Invite Link</Box>
								<LaunchdeckButton bg='brandDark.500' color='white' px='3em' fontSize='1em' onClick={() => handleCopyLink()} label='Copy Invite Link' />
							</Box>
						}

						<Flex mt='3em' w='100%' justify='flex-end'>
							{processCompleted ?
								<LaunchdeckButton label='Create another user' bg='brand.500' color='gray.50' onClick={() => reloadPage()} />
								:
								<LaunchdeckButton label='Create Genesis User' bg='brand.500' color='gray.50' onClick={() => handleCreateGenesisUser()} />
							}
						</Flex>
					</>
				)
			}
			case ("create-new-org-for-existing-user"): {

				if (existingUserNotFound) {
					return (
						<Box>
							<Flex w='100%' mt='2em' fontSize='0.875em' p='1em' bg='white' border='1px solid' borderColor='brandPink.500' rounded='0.5em'>Existing user cannot be found. Organization not created</Flex>
							<Box mt='1em'>
								<LaunchdeckButton label='Try another email' bg='brand.500' color='gray.50' onClick={() => reloadPage()} />
							</Box>
						</Box>
					)
				}

				if (showMappedToUserFeedback) {
					return (
						<Box>
							<Flex w='100%' mt='2em' fontSize='0.875em' p='1em' bg='white' border='1px solid' borderColor='brandGreen.500' rounded='0.5em'>Org successfully created & mapped to user. User is now a Genesis User type</Flex>
					
							<Box mt='1em'>
								<LaunchdeckButton label='OK' bg='brand.500' color='gray.50' onClick={() => reloadPage()} />
							</Box>

						</Box>
					)
				}

				if (showUnexpectedError || errorReason) {
					return (
						<Box>
							<Flex w='100%' mt='2em' fontSize='0.875em' p='1em' bg='white' border='1px solid' borderColor='brandPink.500' rounded='0.5em'>{errorReason ? errorReason : "There was an unexpected error"}</Flex>
							<Box mt='1em'>
								<LaunchdeckButton label='OK' bg='brand.500' color='gray.50' onClick={() => reloadPage()} />
							</Box>

						</Box>
					)
				}

				return (
					<Box>
						{showCreatedOrgFeedback ?
							<Flex w='100%' mt='2em' fontSize='0.875em' p='1em' bg='green.50' border='1px solid' borderColor='green.300' rounded='0.5em'>Org Created Successfully</Flex>
							:
							<AppAdminCreateOrgForExistingUserForm
								handleChange={handleChange}
								localPageState={localPageState}
								isInvalidEmail={isInvalidEmail}
								mapItToExistingUser={mapItToExistingUser}
								handleMapNewOrgToExistingUserToggle={handleMapNewOrgToExistingUserToggle}
							/>
						}

						<Flex mt='3em' w='100%' justify='flex-end'>
							{processCompleted ?
								<LaunchdeckButton label='OK' bg='brand.500' color='gray.50' onClick={() => reloadPage()} />
								:
								<LaunchdeckButton label='Create Organization' bg='brand.500' color='gray.50' onClick={() => handleCreateNewOrg()} />
							}
						</Flex>
					</Box>
				)
			}
			case ("edit-customer-user"): {
				return (
					<>
						<Box>
							<Box color='brandDark.500' fontSize='1em' letterSpacing='1px'>
								Search / Edit User
							</Box>
							<AppAdminEditUserForm
								handleChange={handleChange}
								localPageState={localPageState}
								isInvalidEmail={isInvalidEmail}
								handleManageExistingUser={handleManageExistingUser}
								existingUserNotFound={existingUserNotFound}
							/>

							<Flex mt='3em' w='100%' justify='flex-end'>
								{processCompleted ?
									<LaunchdeckButton label='Create another organization' bg='brand.500' color='gray.50' onClick={() => reloadPage()} />
									: localPageState.userIdToken ?

										<React.Fragment>

											<LaunchdeckButton noHover loadingLogic={manageUserIsProcessing} label='Cancel' bg='gray.200' color='gray.500' onClick={() => resetLocalState()} />
											<Box w='1em' />
											<LaunchdeckButton noHover loadingLogic={manageUserIsProcessing} label='Edit User' bg='brand.500' color='gray.50' onClick={() => handleManageExistingUser({ isEditUser: true })} />

										</React.Fragment>
										: <Box />
								}
							</Flex>
						</Box>
					</>
				)
			}
			case ("offboard-customer-user"): {
				return (
					<>
						<Box mb='1em'>
							<Box fontSize='0.875em' fontWeight='semibold'>Offboard User</Box>
							<Text fontSize='0.8em' color='gray.600' mb='0.8em'>Includes removing user from all DSRs & PDSRs + housekeeping tasks </Text>
						</Box>

						{showOffboardedUserFeedback
							?
							renderOffboardUserFeedback()
							:
							<AppAdminOffboardUserForm
								handleChange={handleChange}
								localPageState={localPageState}
								isInvalidEmail={isInvalidEmail}
							/>
						}

						<Flex mt='3em' w='100%' justify='flex-end'>
							{processCompleted ?
								<LaunchdeckButton label='Offboard another user' bg='brand.500' color='gray.50' onClick={() => resetLocalState()} />
								:
								<LaunchdeckButton label='Offboard User' bg='brand.500' color='gray.50' onClick={() => handleOffboardUser()} />
							}
						</Flex>
					</>
				)
			}
			case ("reset-genesis-user-token"): {
				return (
					<>
						<Box mb='1em'>
							<Box fontSize='0.875em' fontWeight='600'>Reset Password Token</Box>
							<Box fontSize='0.8em' color='gray.600' mb='0.8em'>Resets password token & expiry for genesis user to resolve onboarding issues</Box>
						</Box>

						<AppAdminResetTokenForm
							handleChange={handleChange}
							localPageState={localPageState}
							handleEditGenesisUser={handleEditGenesisUser}
						/>
					</>
				)
			}
			case ("edit-organization"): {
				return (
					<>
						<Box>
							<Box color='brandDark.500' fontSize='1em' letterSpacing='1px'>
								Search / Edit Organization
							</Box>
							<AppAdminEditOrgForm
								handleChange={handleChange}
								handleClearOrgSearch={resetLocalState}
								localPageState={localPageState}
								handleManageExistingOrg={handleManageExistingOrg}
								existingOrgNotFound={existingOrgNotFound}
							/>

							<Flex mt='3em' w='100%' justify='flex-end'>
								{/* {processCompleted ?
									<LaunchdeckButton label='Create another organization' bg='brand.500' color='gray.50' onClick={() => reloadPage()} />
									: localPageState.userIdToken ?

										<React.Fragment>

											<LaunchdeckButton noHover loadingLogic={manageUserIsProcessing} label='Cancel' bg='gray.200' color='gray.500' onClick={() => resetLocalState()} />
											<Box w='1em' />
											<LaunchdeckButton noHover loadingLogic={manageUserIsProcessing} label='Edit User' bg='brand.500' color='gray.50' onClick={() => handleManageExistingUser({ isEditUser: true })} />

										</React.Fragment>
										: <Box />
								} */}
							</Flex>
						</Box>
					</>
				)
			}
			default: {
				return null;
			}
		}
	}

	// Component
	if (showPage) {
		return (
			<Box bg='white' px='3%' pt='1.5em' pb='3em' minHeight='40em' rounded={8}>
				<Flex w='100%' justify='space-between' pb='1em' mb='2em' borderBottom='1px solid' borderColor='gray.100'>
					<	Box fontSize='1.5em' letterSpacing='1px' color='brand.500'>ADMIN PORTAL</Box>
					<Box>{renderUseCaseDropdown()}</Box>
				</Flex>

				{renderForm()}
			</Box>
		)
	} else {
		return <PageLoader />
	}


}


function mapStateToProps(state) {
	return {
		createGenesisUserOutcome: state.createGenesisUserOutcome.results.data,
		offboardUserOutcome: state.offboardUserOutcome.results.data,
		createNewOrgForExistingUserOutcome: state.createNewOrgForExistingUserOutcome.results.data,
		manageExistingUserOutcome: state.manageExistingUserOutcome.results.data,
		manageUserIsProcessing: state.manageExistingUserOutcome.isLoading,
		manageExistingOrgOutcome: state.manageExistingOrgOutcome.results,
		manageOrgIsProcessing: state.manageExistingOrgOutcome.isLoading
	};
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			createGenesisUser,
			createGenesisUserReset,
			offboardUser,
			offboardUserReset,
			editGenesisUser,

			createOrgForExistingUser,
			resetCreateNewOrgForExistingUser,
			manageExistingUser,
			resetManageExistingUser,
			manageExistingOrganization,
			resetManageExistingOrganization
		},
		dispatch,
	)

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomerSetupPage));
