import React, { memo, useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'

import { Box, Flex, Avatar, Skeleton, useOutsideClick } from "@chakra-ui/react";
import { getDsrAnalyticsCategoryDetails } from '../../analytics/actions/dsr-analytics-actions'
import { getUserDetailsUsingDsrMembersArray, sortArrayToListActiveMembersFirst } from '../../profile/helpers/user-helper';
import { formatTimestamp } from '../helpers/timestamp-display-helper';
import { displayInLocalTime, formatSecondsValueDisplay } from '../../global/helpers/time-format-helpers';
import { GoChevronDown } from 'react-icons/go';
import EmptyStateStandardDisplay from '../../global/components/helper-components/components/EmptyStateStandardDisplay';
import { MdOutlinePersonPin } from "react-icons/md";
import { TbTimeDurationOff } from "react-icons/tb";

function DsrActivityLog({
  dsrId,
  activityLogAvailable,
  dsrActivityLog,
  getDsrAnalyticsCategoryDetails,
  dsrTeamMembersRedux
}) {

  // UX Management
  const [refreshLogRequest, setRefreshLogRequest] = useState(null)

  const [filterType, setFilterType] = useState("all-members")
  const [chosenTimeFrame, setChosenTimeFrame] = useState("7daysBefore")
  const [chosenTimeFrameDisplay, setChosenTimeFrameDisplay] = useState("Past 7 days")
  const [chosenUserId, setChosenUserId] = useState("")
  const [chosenUserName, setChosenUserName] = useState("")
  const [isPendingUser, setIsPendingUser] = useState("")
  const [displayActivityLog, setDisplayActivityLog] = useState("")

  // Manage Filter function
  const [showUserFilterDropdown, setshowUserFilterDropdown] = useState(false)
  const [showTimeframeDropdown, setShowTimeframeDropdown] = useState(false)

  const userFilterDropdownRef = useRef(null);
  const dateRangeDropdownRef = useRef(null);

  useOutsideClick({
    ref: userFilterDropdownRef,
    handler: () => setshowUserFilterDropdown(false),
  })

  useOutsideClick({
    ref: dateRangeDropdownRef,
    handler: () => setShowTimeframeDropdown(false),
  })

  useEffect(() => {
    if (dsrActivityLog) {

      setDisplayActivityLog(dsrActivityLog)
    }
  }, [dsrActivityLog]);

  useEffect(() => {

    if (!activityLogAvailable && getDsrAnalyticsCategoryDetails) {

      getDsrAnalyticsCategoryDetails({
        dsrId,
        requestType: 'retrieve-dsr-activity-log',
        filter: {
          filterType: filterType,
          chosenUserId: chosenUserId,
          chosenTimeFrame: chosenTimeFrame
        }
      })
    }
  }, [activityLogAvailable, getDsrAnalyticsCategoryDetails]);

  useEffect(() => {

    if (refreshLogRequest && getDsrAnalyticsCategoryDetails) {

      getDsrAnalyticsCategoryDetails({
        dsrId,
        requestType: 'retrieve-dsr-activity-log',
        filter: {
          filterType: filterType,
          chosenUserId: chosenUserId,
          chosenTimeFrame: chosenTimeFrame
        }
      })

      setRefreshLogRequest(false)
    }
  }, [refreshLogRequest, getDsrAnalyticsCategoryDetails]);


  const renderActivityLog = () => {
    if (!!isPendingUser) {
      return <Box minH='50vh'>
        <EmptyStateStandardDisplay
          reactIcon={MdOutlinePersonPin}
          infoText={'User has yet to join this Room'}
        />
      </Box>
    }

    if (Array.isArray(displayActivityLog) && !refreshLogRequest) {
      // TODO Optimise: Do not re-render this when user clicks on the dropdown menus
      const displayRows = displayActivityLog?.slice().reverse().map((dailyActivities, activityIndex) => {
        const { activityLogDate, activityArray } = dailyActivities || {}

        const displayActivityEvents = activityArray?.map((activity, activityIndex) => {

          const { analyticsId, userId, eventTimestamp, eventType, visitedPageName, engagementDurationSeconds } = activity || {};
          const userProfile = getUserDetailsUsingDsrMembersArray(dsrTeamMembersRedux, userId);
          const { memberName, memberProfilePicSrc } = userProfile || {}
     
          switch (eventType) {
            case ("guest-enters-dsr"): {
              return (
                <Flex key={`${analyticsId}-${activityIndex}`} w='100%' className="activity-display-container">
                  <Box boxSize='2em' className="profile-pic-container" pt='1em'>
                    <Avatar h='3.8em' w='3.8em' p='0.3em' name={memberName} bg='gray.100' color='gray.700' src={memberProfilePicSrc} />
                  </Box>
                  <Box className="activity-content-display-container">
                    <Box className="activity-time-display">{displayInLocalTime(eventTimestamp)}</Box>
                    <Box fontSize='0.875em'>{memberName} visited this Room</Box>
                  </Box>

                </Flex>
              )
            }
            case ("guest-views-subpage"): {
              return (
                <Flex key={`${analyticsId}-${activityIndex}`} w='100%' className="activity-display-container">
                  <Box boxSize='2em' className="profile-pic-container" pt='1em'>
                    <Avatar h='3.8em' w='3.8em' p='0.3em' name={memberName} bg='gray.100' color='gray.700' src={memberProfilePicSrc} />
                  </Box>
                  <Box className="activity-content-display-container">
                    <Box className="activity-time-display">{displayInLocalTime(eventTimestamp)}</Box>
                    <Box fontSize='0.875em'>{memberName} viewed the {visitedPageName} page for {formatSecondsValueDisplay(engagementDurationSeconds)}</Box>
                  </Box>

                </Flex>
              )
            }
            default: {
              return null
            }
          }

        })

        return (
          <Box w='100%' key={`activity-row-${activityIndex}`}>
            <Flex className="activity-date-container">
              <Box className="activity-date-display">{formatTimestamp(activityLogDate)}</Box>
            </Flex>
            <Box mt='1em' pb='2em'>
              {displayActivityEvents}
            </Box>
          </Box>
        )


      })

      if (displayRows.length === 0) {
        return <Box minH='50vh'>
          <EmptyStateStandardDisplay
            reactIcon={TbTimeDurationOff}
            infoText={'No activities found in this time period'}
          />
        </Box>
      }

      return <Box minH='50vh'>{displayRows}</Box>;
    } else {
      const skeleton = Array.from({ length: 8 }, (_, index) => (
        <Flex justify='flex-start' align='center' w='100%' py='0.5em' pl='1em' key={`dsr-analytics-stats-loading-bar-${index}`}>
          <Skeleton rounded='50%'>
            <Box height="2em" width='2em' rounded='50%' />
          </Skeleton>
          <Skeleton w='40%' height='1.5em' ml='1em' />
        </Flex>
      ));

      return (
        <Box mt='1em' minH='25em'>
          {skeleton}
        </Box>
      )
    }
  }

  const handlePickGuestUser = (userId) => {
    setChosenUserId(userId)

    const userProfile = getUserDetailsUsingDsrMembersArray(dsrTeamMembersRedux, userId);
    const { memberName, userStatus } = userProfile || {}

    if (userId) {
      setFilterType("specific-member")
      setChosenUserName(memberName ? memberName : "All Guests")
      setIsPendingUser((userStatus === 'pending') ? true : false)

    } else {
      setFilterType("all-members")
      setChosenUserName("All Guests")
      setIsPendingUser(false)
    }

    setRefreshLogRequest(true)
  }

  const handlePickTimeframe = (timeframe) => {
    setChosenTimeFrame(timeframe)

    switch (timeframe) {
      case ("7daysBefore"): {
        setChosenTimeFrameDisplay("Past 7 days")
        break;
      }
      case ("30daysBefore"): {
        setChosenTimeFrameDisplay("Past 30 days")
        break;
      }
      default: {
        setChosenTimeFrameDisplay("")
        break;
      }
    }

    setRefreshLogRequest(true)
  }

  const renderFilterChoices = () => {
    if (dsrTeamMembersRedux) {
      const guestTeam = dsrTeamMembersRedux["buyerTeam"]
      const sortedGuestTeam = sortArrayToListActiveMembersFirst(guestTeam)

      const renderChoices = sortedGuestTeam?.map((org, orgIndex) => {
        const { orgTeamMembers } = org || {}

        const renderThisOrgChoices = orgTeamMembers?.map((member, memberIndex) => {
          const { userId } = member || {};
          const userProfile = getUserDetailsUsingDsrMembersArray(dsrTeamMembersRedux, userId);
          const { memberName } = userProfile || {}

          return (
            <Box key={`analytics-choice-${memberIndex}`} className={(chosenUserId !== userId) ? 'filter-menu__dropdown-option-display' : 'filter-menu__dropdown-chosen-option-display'} onClick={() => (chosenUserId !== userId) && handlePickGuestUser(userId)}>
              <Box maxW='13em' className="truncate-long-text">{memberName}</Box>
            </Box>
          )
        })

        return <Box key={`analytics-choice-org-${orgIndex}`}>{renderThisOrgChoices}</Box>
      })

      return (
        <React.Fragment >
          {renderChoices}
        </React.Fragment>
      )
    }
  }

  const renderFilterTimeframeChoices = () => {
    const possibleChoices = [{ choiceName: "7daysBefore", displayChoiceName: "Past 7 days" }, { choiceName: "30daysBefore", displayChoiceName: "Past 30 days" }]

    const renderTimeframeChoices = possibleChoices.map((choice, choiceIndex) => {
      const { choiceName, displayChoiceName } = choice || {}

      return <Box key={`analytics-timeframe-choice-${choiceIndex}`}
        className={(choiceName !== chosenTimeFrame) ? 'filter-menu__dropdown-option-display' : 'filter-menu__dropdown-chosen-option-display'}
        onClick={() => (choiceName !== chosenTimeFrame) && handlePickTimeframe(choiceName)}
      > {displayChoiceName}</Box>
    })

    return (
      <React.Fragment>
        {renderTimeframeChoices}
      </React.Fragment>
    )
  }

  return (
    <Box w='100%' bg='white' rounded='0.5em' h='auto' px='1.5em'>
      {/* // TODO: use classNAme. too many logics for CSS */}
      <Flex w='100%' py='1em' borderBottom='1px solid' borderColor='gray.200'>
        <Flex w='100%' direction={['column', 'column', 'row', 'row']} justify={['space-between', "space-between", "", ""]} align={['flex-start', 'flex-start', 'center', 'center']} >
          <Box fontSize='1.25em' fontWeight='500' lineHeight='1.3em' color='brandDark.500' pl={['0', '0', '1em', '1em']}>
            Activity
          </Box>

          <Flex direction={["column", "column", "row", "row"]} mt={["1em", "1em", "0", "0"]} width={["100%", "100%", "", ""]} justify={["center", "center", 'flex-end', 'flex-end']} align={['flex-start', 'flex-start', 'center', 'center']}>
            <Flex className='filter-menu' w='100%' onClick={() => { setshowUserFilterDropdown(!showUserFilterDropdown); setShowTimeframeDropdown(false) }} ref={userFilterDropdownRef}>
              <Flex minW='11em'>
                {chosenUserName ? <Box className='chosen-option-text-display truncate-long-text'>{chosenUserName}</Box>
                  : <Box mr='0.5em' color='brandDark.500' fontSize='0.875em' fontWeight='400' lineHeight='1.375em'>All Guests</Box>
                }
              </Flex>

              <Box as={GoChevronDown} boxSize="0.75em" color="gray.300" />

              {/* Show Dropdown bar here */}
              <Box className='filter-menu__dropdown' display={showUserFilterDropdown ? 'inline-block' : 'none'}>
                <Box className={(chosenUserId !== "") ? 'filter-menu__dropdown-option-display' : 'filter-menu__dropdown-chosen-option-display'} onClick={() => (chosenUserId !== "") && handlePickGuestUser("")}>All Guests</Box>
                <Box>
                  {renderFilterChoices()}
                </Box>
              </Box>
            </Flex>

            <Flex className='filter-menu' ml={['0', '0', '0.5em', '0.5em']} mt={["1em", "1em", "0", "0"]} minW='11em' onClick={() => { setShowTimeframeDropdown(!showTimeframeDropdown); setshowUserFilterDropdown(false) }} ref={dateRangeDropdownRef}>
              <Flex>

                <Box mr='0.5em' fontSize='0.875em' fontWeight='400' color='brandDark.500' lineHeight='1.375em'>{chosenTimeFrameDisplay}</Box>

              </Flex>

              <Box as={GoChevronDown} boxSize="0.75em" color="gray.300" />

              {/* Show Dropdown bar here */}
              <Box className='filter-menu__dropdown' display={showTimeframeDropdown ? 'inline-block' : 'none'}>
                <Box>
                  {renderFilterTimeframeChoices()}
                </Box>
              </Box>

            </Flex>
          </Flex>
        </Flex>
      </Flex>
      {renderActivityLog()}
    </Box>

  )
}


const mapStateToProps = (state) => {
  return {
    dsrTeamMembersRedux: state.dsrTeamMembers.members,
  }
}


const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getDsrAnalyticsCategoryDetails
    },
    dispatch,
  )

export default memo(withRouter(connect(mapStateToProps, mapDispatchToProps)(DsrActivityLog)))