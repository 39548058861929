import React from 'react';
import moment from 'moment';
import 'moment-timezone';


//Format Time Display
export function formatTimestamp(rawTimestamp) {
  return moment(rawTimestamp).format('ll');
}

export function getCurrentDateEs6() {
  return new Date().toLocaleDateString('en-GB', {  
    day:   'numeric',
    month: 'short',
    year:  'numeric',
  });
}

export function getCurrentTimeEs6() {
  return new Date().toLocaleTimeString(undefined, {
    hour:   '2-digit',
    minute: '2-digit',
    second: '2-digit',
	});
}

export function formatDisplayLocalDateTime(rawTimestamp) {
  if (!rawTimestamp) {
    return null;
  }

  const formattedTimestamp = new Date(rawTimestamp);

  const formattedDate = formattedTimestamp.toLocaleDateString('en-US', {  
    day:   'numeric',
    month: 'short',
    year:  'numeric',
  });

  const formattedTime = formattedTimestamp.toLocaleTimeString('en-US', {hour: '2-digit', minute:'2-digit'});

  return (formattedDate + ', ' + formattedTime)

}

export function formatTimezoneToGmt(rawTimestamp) {
  if (!rawTimestamp) {
    return null;
  }

  let momentTimestamp = moment(rawTimestamp);
  let timestampWithTimezone = momentTimestamp.tz('Europe/London').format('HH:mm z'); 
  return <span>{'    ' + timestampWithTimezone}</span>;
}

export function formatDisplayDateOnly(rawTimestamp) {
  if (!rawTimestamp) {
    return null;
  }

  const formattedTimestamp = new Date(rawTimestamp);
  
  const formattedDate = formattedTimestamp.toLocaleDateString('en-US', {  
    day:   'numeric',
    month: 'short',
    year:  'numeric',
  });

  return formattedDate
}