import React from 'react';

import { Box, Modal, ModalOverlay, ModalBody, ModalContent, Flex  } from "@chakra-ui/react";
import DsrPostTextAreaInput from '../../forms/TextareaField/components/DsrPostTextAreaInput';
import PageLoader from '../../global/components/helper-components/components/PageLoader';
import LaunchdeckCloseButton from '../../global/components/helper-components/components/LaunchdeckCloseButton';


export default function EditDsrPostInputModal(props) {

  const {
    handleCloseModal,
    handleStopDsrModalLoader,
    postContentToEdit,
    handleUpdatePostContent,
    dsrId,
    postId,
    postTagProperties,
    showDsrModalLoader
  } = props;

  return (
    <Modal isOpen={true} closeOnOverlayClick={false} isCentered={true} preserveScrollBarGap={true}>
      <ModalOverlay />
      <ModalContent rounded='0.25em' minW={['90%', '500px']} maxW='700px'>
        <ModalBody p='0em'>
          <Flex direction='column' justify='space-around' alignItems='center'>
            <Flex w='100%' p='1em' justify='space-between' align='center' borderBottom='1px solid' borderBottomColor='gray.100' >
              <Flex w='100%' fontSize='1.25em' fontWeight='500' lineHeight='1.375em'>Edit Post</Flex>
              <Flex justify='flex-end' w='100%'><LaunchdeckCloseButton handleCloseModal={handleCloseModal} /></Flex>
            </Flex>

            <Flex justify='center' w='100%'>
              <Flex w="100%" align="center" opacity={ showDsrModalLoader ? '0' : '1'}>
                <DsrPostTextAreaInput
                  userActionRequired='edit'
                  name="editDsrPostContent"
                  sublabel=""
                  fontSize='0.875em'
                  htmlFor="editDsrPostContent"
                  onClickEditPost={handleUpdatePostContent}
                  currentValue={postContentToEdit}
                  dsrId={dsrId}
                  postId={postId}
                  postTagProperties={postTagProperties}
                  handleStopDsrModalLoader={handleStopDsrModalLoader}
                />
              </Flex>
            </Flex>

            {showDsrModalLoader &&
              <Box position='absolute' zIndex='2'>
                <PageLoader />
              </Box>
            }
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
