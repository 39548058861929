import React, { useState } from 'react';
import { Box, Flex, Text, Image, Divider, Collapse } from '@chakra-ui/react';

import FormTextInput from '../../../forms/TextInputField/components/FormTextInput';
import { validateUrlFormat } from '../../../global/helpers/global-helpers';
import LaunchdeckButton from '../../../global/components/helper-components/components/LaunchdeckButton';
import WarningTextMessage from '../../../global/components/helper-components/components/WarningTextMessage';


export default function AppAdminCreateOrgForExistingUserForm({
	handleChange,
	localPageState,
	isInvalidEmail,
	mapItToExistingUser,
	// handleMapNewOrgToExistingUserToggle
}) {

	const { emailInput, orgName, orgLogo } = localPageState || {};

	const [feedUriInput, setFeedUriInput] = useState("")

	const [showLogoImage, setShowLogoImage] = useState(false)
	const [showInvalidUrlError, setShowInvalidUrlError] = useState(false)
	const [showImageError, setShowImageError] = useState(false)


	const handleGetCbLogo = () => {
		const isValidUrl = orgLogo ? validateUrlFormat(orgLogo) : false;
		setShowImageError(false)

		if (isValidUrl && orgLogo) {
			setFeedUriInput(orgLogo)
			setShowLogoImage(true)

		} else {
			setShowInvalidUrlError(true)
		}
	}

	const handleImageError = () => {
		setShowLogoImage(false)
		setShowImageError(true)
	}


	return (
		<Box my='2em' w='100%' gap='0.5em'>
			<Collapse in={mapItToExistingUser} animateOpacity>
				<Box my='1em'>
					<Box fontSize='0.875em' fontWeight='semibold'>User Email</Box>
					<Text fontSize='0.8em' color='gray.600' mb='0.8em'>Important fieid. This user will be automatically be assigned as the new Organization's Administrator</Text>
					<Box mb='0.5em'>
						<FormTextInput name={'emailInput'} label={''} sublabel={''} htmlFor={'emailInput'} mb='0.2em' value={emailInput && emailInput} onChange={handleChange} />
						<WarningTextMessage errorLogic={isInvalidEmail} message='Please provide a valid email' />
					</Box>
				</Box>

				<Divider my='2em' />
			</Collapse>

			<Box mt='3em' mb='1em'>
				<Box fontSize='0.875em' fontWeight='semibold'>Organization Creation</Box>
				<Text fontSize='0.8em' color='gray.600' mb='0.8em'>Creates New Organization mapped to the newly created user above as orgAdminUser</Text>
			</Box>
			<Box mb='0.5em'>
				<FormTextInput name={'orgName'} label={'Organization Name'} sublabel={'Display name'} htmlFor={'orgName'} mb='0.2em' value={orgName && orgName} onChange={handleChange} />
				{/* <WarningTextMessage errorLogic={} message='Organization name is required' /> */}
			</Box>

			<Flex w='100%' mb='0.5em' align='center'>
				<Box flex='1'>Logo</Box>

				<Box flex='7' mx='1em'>
					<FormTextInput name={'orgLogo'} label={''} sublabel={''} mb='0.2em' htmlFor={'orgLogo'} value={orgLogo && orgLogo} onChange={handleChange} />
				</Box>

				<Box flex='2'>
					<LaunchdeckButton name='userProviderUri' label='Find Logo' fontSize='0.9em' py='0.8em' bg='brandDark.500' color='gray.50' onClick={() => handleGetCbLogo()} />
				</Box>
			</Flex>

			<Box mt='2em'>
				{showLogoImage && <Image src={`https://logo.clearbit.com/${feedUriInput}`} onError={handleImageError} />}
				{showImageError && <Flex boxSize='128px' justify='center' align='center' border='1px solid' borderColor='gray.100'><Box>Show Upload option</Box></Flex>}
				Preview Logo
			</Box>

			<Box fontSize='0.7em' color={showInvalidUrlError ? 'pink.500' : 'gray.500'} my='0.5em' fontStyle='italic'>Find a company's logo by using its weblink. For example: 'https://www.google.com'</Box>


			<Box />


		</Box>
	);
};







