import auth from '../../../config/app-specific-config/auth'
import * as types from './user-tag-actions-types'
import { axiosInstance } from '../../../config/app-specific-config/axiosConfig'




////////////////////////////////////////////////////////////
// DSR POST TAG SERVICES
////////////////////////////////////////////////////////////
// POST method
export const tagUserInPostSuccess = (response) => {
	return {
		type: types.TAG_USER_SUCCESS,
    payload: response.data
	};
};

export const tagUserInPostFailure = (error) => {
	return {
		type: types.TAG_USER_FAILURE,
    error
	};
};

export const tagUserInPostRequest = () => {
	return {
		type: types.TAG_USER_REQUEST
	};
};


export function tagUserInPost(tagUserMetadata) {

	// POST API
	return async (dispatch) => {
		dispatch(tagUserInPostRequest());
		try {
			const url = '/dsr/tag-in-post/create'; 
			const headers = await auth.getAccessToken(); 

			const response = await axiosInstance.post(
				url,
				{tagUserMetadata},
				{ headers }
			);

			dispatch(tagUserInPostSuccess(response));

		} catch (error) {
			dispatch(tagUserInPostFailure(error));
		}
	};
}

// Untagging users
export const untagUserInPostSuccess = (response) => {
	return {
		type: types.TAG_USER_SUCCESS,
    payload: response.data
	};
};

export const untagUserInPostFailure = (error) => {
	return {
		type: types.TAG_USER_FAILURE,
    error
	};
};

export const untagUserInPostRequest = () => {
	return {
		type: types.TAG_USER_REQUEST
	};
};


export function untagUserInPost(untagUserMetadata) {

	// POST API
	return async (dispatch) => {
		dispatch(untagUserInPostRequest());
		try {
			const url = '/dsr/tag-in-post/untag'; 
			const headers = await auth.getAccessToken(); 

			const response = await axiosInstance.post(
				url,
				{untagUserMetadata},
				{ headers }
			);

			dispatch(untagUserInPostSuccess(response));

		} catch (error) {
			dispatch(untagUserInPostFailure(error));
		}
	};
}

//Tagging user in reply

export const tagUserInReplySuccess = (response) => {
  return {
    type: types.TAG_USER_IN_REPLY_SUCCESS,
    payload: response.data
  };
};

export const tagUserInReplyFailure = (error) => {
  return {
    type: types.TAG_USER_IN_REPLY_FAILURE,
    error
  };
};

export const tagUserInReplyRequest = () => {
  return {
    type: types.TAG_USER_IN_REPLY_REQUEST
  };
};


export function tagUserInReply(tagUserInReplyMetadata) {

  return async (dispatch) => {
    dispatch(tagUserInReplyRequest());
    try {
      const url = '/dsr/tag-in-reply/tagging';
      const headers = await auth.getAccessToken();

      const response = await axiosInstance.post(
        url,
        {tagUserInReplyMetadata},
        { headers }
      );

      dispatch(tagUserInReplySuccess(response));

    } catch (error) {
      dispatch(tagUserInReplyFailure(error));
    }
  };
}

//Untagging user in reply

export const untagUserInReplySuccess = (response) => {
  return {
    type: types.UNTAG_USER_IN_REPLY_SUCCESS,
    payload: response.data
  };
};

export const untagUserInReplyFailure = (error) => {
  return {
    type: types.UNTAG_USER_IN_REPLY_FAILURE,
    error
  };
};

export const untagUserInReplyRequest = () => {
  return {
    type: types.UNTAG_USER_IN_REPLY_REQUEST
  };
};


export function untagUserInReply(untagUserInReplyMetadata) {

  return async (dispatch) => {
    dispatch(untagUserInReplyRequest());
    try {
      const url = '/dsr/tag-in-reply/untagging';
      const headers = await auth.getAccessToken();

      const response = await axiosInstance.post(
        url,
        {untagUserInReplyMetadata},
        { headers }
      );

      dispatch(untagUserInReplySuccess(response));

    } catch (error) {
      dispatch(untagUserInReplyFailure(error));
    }
  };
}

