import React from "react";

import { Box, Flex } from "@chakra-ui/react";
import { MdOutlineVideoFile } from "react-icons/md";
import { getYoutubeEmbedLink } from "../../../global/helpers/global-helpers";

const DsrVideoPostDisplay = ({ subWidgetContent }) => {

  const  videoPlaceholder = <Flex direction="column" alignItems="center" justifyContent="center" height="80px">
    <Box as={MdOutlineVideoFile} width="90px" height="70px" mb="5px" />
    <p>Upload your video file</p>
  </Flex>;

  const embedYouTubeVideoUrl = (url) => {
    const packagedUrl = getYoutubeEmbedLink(url)

    return packagedUrl;
  };

  return (
    subWidgetContent
      ? typeof subWidgetContent === 'string'
        ? <Box width="100%" height={['200px', '240px', '480px', '480px']}>
          <iframe
            width="100%"
            height="100%"
            title="Sub widget video"
            src={embedYouTubeVideoUrl(subWidgetContent)}
            allowFullScreen
            style={{ "borderRadius": "15px" }}
          />
        </Box>
        : <video className="loader-img" width="100%" src={subWidgetContent[0].url}  controls autobuffer="true"></video>
      : videoPlaceholder
  );

}

export default DsrVideoPostDisplay;
