import React, { useState } from 'react';

import { Box, Flex, Fade } from '@chakra-ui/react';
import FormTextInput from '../../../forms/TextInputField/components/FormTextInput';
import WarningTextMessage from '../../../global/components/helper-components/components/WarningTextMessage';
import LaunchdeckButton from '../../../global/components/helper-components/components/LaunchdeckButton';
import SignupUserOrgForm from './SignupUserOrgForm';


export default function SignupUserOnboardForm({ setupUserMetadata, token }) {

	const [localState, setLocalState] = useState({
		inputSignupUserPassword: "",
		repeatPassword: "",
		showValidationError: false
	})
	const [showSetupOrgForm, setShowSetupOrgForm] = useState(false)

	const handleChange = (event) => {
		const value = event.target.value;
		setLocalState({
			...localState,
			[event.target.name]: value,
			showValidationError: false
		});
	}

	const submitSetNewPassword = () => {
		const { inputSignupUserPassword, repeatPassword } = localState;
		const sameInputs = ((inputSignupUserPassword && inputSignupUserPassword.length > 5) && (inputSignupUserPassword === repeatPassword)) ? true : false;

		if (sameInputs) {
			setShowSetupOrgForm(true)
		} else {
			setLocalState({ ...localState, showValidationError: true })
		}
	}


	const { showValidationError, inputSignupUserPassword } = localState;
	const {idToken: userIdToken, firstName} = setupUserMetadata || {}

	if (showSetupOrgForm) {
		return (
			<>
				<Box textAlign='center' pt='3em'>
					<Box fontSize='1.4em' fontWeight='600'>About your team </Box>
					<Box color='brandDark.500' fontSize='0.875em' py='1.2em'>And finally, let your audience know who you'd be representing</Box>
				</Box>

				<Box my='3%' mx='auto' maxW={['100%', '400px']} bg='white' p='1em' rounded='0.25em'>
					<SignupUserOrgForm userSetPassword={inputSignupUserPassword} userIdToken={userIdToken} token={token} />
				</Box>
			</>

		)
	} else {
		return (
			<>
				<Box textAlign='center' pt='3em'>
					{firstName && <Box fontSize='1.4em' fontWeight='600'>Hey {firstName} </Box>}
					<Box color='brandDark.500' fontSize='1.2em'>Welcome to Launchdeck 👋</Box>

				</Box>
				<Box fontSize='0.875em' color='gray.700' textAlign='center' my='1.2em'> We'll help you set up in 2 quick steps. First, a secure password </Box>

				<Fade in={true}>
					<Box bg='white' p='1em' rounded='0.25em' boxShadow={'xs'} m='3em auto' maxW={['100%', '400px']}>
						<Box m="3%">
							<FormTextInput name={'inputSignupUserPassword'} label={'Password'} sublabel={''} htmlFor={'inputSignupUserPassword'} type='password' mb='0.2em' onChange={handleChange} />
						</Box>

						<Box m="3%" pt='2em'>
							<FormTextInput name={'repeatPassword'} label={'Confirm Password'} sublabel={''} htmlFor={'repeatPassword'} type='password' mb='0.2em' onChange={handleChange} />
							<WarningTextMessage errorLogic={showValidationError} message=' Your confirmation password did not match, or needs to be more than 6 characters' />
						</Box>

						<Flex direction='column' mt='3em' justify='center' alignItems='center'>
							<Flex justify='center' w='100%'>
								<LaunchdeckButton w='100%' bg='brand.500' color='white' onClick={() => submitSetNewPassword()} label='Set Password' />
							</Flex>
						</Flex>

					</Box>

				</Fade>
			</>

		)
	}

};