import React from 'react';

import { Flex } from "@chakra-ui/react";
import LaunchdeckButton from './LaunchdeckButton';


export default function ClaverModalButtonBar({handleCloseModal, bg, color, loadingLogic, ctaFunction, ctaLabel, cancelLabel}) {
  const displayedCancelLabel = cancelLabel ? cancelLabel : ' Cancel';
  const displayedCtaLabel = ctaLabel ? ctaLabel : 'Next';
  const ctaButtonBg = bg ? bg : 'brand.500';
  const displayCtaTextColor = color ? color : 'white';

  return (
    <Flex w='100%' justify={loadingLogic ? 'flex-end' : 'space-between'} align='center' px='2em' py='1em' fontSize='0.875em' >
      {!loadingLogic && <LaunchdeckButton bg='gray.100' color='brand.700' onClick={handleCloseModal} label={`${displayedCancelLabel}`} />}      
      <LaunchdeckButton loadingLogic={loadingLogic} bg={`${ctaButtonBg}`} color={`${displayCtaTextColor}`} onClick={ctaFunction} label={`${displayedCtaLabel}`} />
    </Flex>
  )  

}
