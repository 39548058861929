import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import '../styles/audio-player.scss'
import { getPrettyDocumentName } from '../../../../digital-sales-room/helpers/dsr-helpers';

import { BsSoundwave } from 'react-icons/bs';

export default function LaunchdeckAudioPlayer({file, autoPlay, showFileName}) {
	
	const {url: src, fileName} = file || {}
	const audioFileName = fileName ? fileName : src ? getPrettyDocumentName(src) : "";

	if (src) {
		return (
			<>
			{(showFileName && audioFileName) &&
			<Flex  mb='0.5em' w='100%' pl='0.5em' 
			justify={['center','center','flex-start','flex-start']} align='center'>
				<Box as={BsSoundwave} mr='0.5em' fontSize='1.2em' />
				<Box fontSize='0.875em'>{audioFileName}</Box>
				</Flex>}
			
			<AudioPlayer
			autoPlay={autoPlay ? autoPlay : false}
			src={src}
			onPlay={e => console.log("onPlay")}
			// other props here
		/>
		</>
	
		)
	} else return null
	
	
}
