import React from 'react';
import { Flex, Spinner } from '@chakra-ui/react';


export default function LoaderCentered({ size }) {
  return (
    <Flex justify='center' py='3em' >
      <Spinner
          thickness="3px"
          speed="0.65s"
          emptyColor="gray.200"
          color="brand.500"
          boxSize={ size ? size : '2em' }
        />
    </Flex>
  )
}