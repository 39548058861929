import React, { useState, useRef, useEffect } from 'react';

import { Box, Flex, Image, Spinner, Divider } from "@chakra-ui/react";
import OrgProfileTeamModule from '../../../organizations/components/OrgProfileTeamModule';
import OrgInfoDisplay from '../../../organizations/components/OrgInfoDisplay';
import AddOrgMemberModule from '../../../organizations/components/edit-team/AddOrgMemberModule';
import DynamicAppBannerDisplay from '../../../global/components/helper-components/components/DynamicAppBannerDisplay';



export default function OrgManagementModule({
	getAdminOrgProfile,
	adminOrgProfile,
	manageAdminOrgUsersOutcome,
}) {
	
	const [localState, setLocalState] = useState({
		orgIdToken: "",
		orgName: "",
		orgDescription: "",
		orgRegisteredAddress: "",
		orgRegisteredName: "",
		orgRegisteredNumber: "",
		orgRegisteredCountry: "",
		orgLogo: "",
		//Upload
		uploadedLogo: "",
		orgSeats: "",
		//UX
		buttonIsLoading: false,
		noOrgFound: "",
		isAdministrator: "",
		logoImageIsLoading: true,
		// Users management
		orgAdminUsers: null,
		orgBasicUsers: null
	})

	const initRef = useRef(false);

	useEffect(() => {
		if (getAdminOrgProfile && !initRef.current) {
			getAdminOrgProfile();
			initRef.current = true
		}
	}, [getAdminOrgProfile])

	useEffect(() => {
		if (adminOrgProfile) {

			const {
				orgId,
				orgName,
				orgDescription,
				orgRegisteredAddress,
				orgRegisteredName,
				orgRegisteredNumber,
				orgRegisteredCountry,
				isAdministrator,
				orgLogo,
				orgSeats,
				orgAdminUsers,
				orgBasicUsers,
				subscriptionReminder
			} = adminOrgProfile || {};

			
			setLocalState(prevState => ({
				...prevState,
				isAdministrator,
				orgName,
				orgDescription,
				orgRegisteredAddress,
				orgRegisteredName,
				orgRegisteredNumber,
				orgRegisteredCountry,
				orgLogo,
				orgSeats,
				noOrgFound: false,
				logoImageIsLoading: orgLogo ? true : false,
				orgIdToken: orgId,
				// Org Users
				orgAdminUsers,
				orgBasicUsers,
				subscriptionReminder
			}))
		} else {
			setLocalState(prevState => ({
				...prevState,
				noOrgFound: true
			}));
		}
	}, [adminOrgProfile])

	const preloadImages = () => {
		const { orgLogo } = localState || {};

		const handleLogoImageLoading = () => {
			setLocalState({
				...localState,
				logoImageIsLoading: false
			})
		}

		return (
			<Box className="image-loading__in-progress">
				<Image src={orgLogo} onLoad={() => handleLogoImageLoading()} h='1px' w='1px' />
			</Box>
		)
	}

	useEffect(() => {
		if (manageAdminOrgUsersOutcome) {
			const updateWithNewOutcome = (manageAdminOrgUsersOutcome) => {
				const { updatedSuccessfully, updatedOrgAdminUsers, updatedOrgBasicUsers } = manageAdminOrgUsersOutcome || {};

				if (updatedSuccessfully === true) {
					setLocalState({
						...localState,
						orgAdminUsers: updatedOrgAdminUsers,
						orgBasicUsers: updatedOrgBasicUsers
					})
				}
			}

			updateWithNewOutcome(manageAdminOrgUsersOutcome)
		}
	}, [manageAdminOrgUsersOutcome, localState])


	const {
		noOrgFound,
		isAdministrator,
		orgName,
		orgAdminUsers,
		orgBasicUsers,
		orgIdToken,
		subscriptionReminder
	} = localState;


	// if (noOrgFound === true || !isAdministrator) {
	// 	return (<PageNotFoundContent />)
	// }

	if (noOrgFound === false && isAdministrator) {
		return (
			<Flex w='100%' direction='column'>
				{/* {subscriptionReminder && 	<DynamicAppBannerDisplay bannerType="subscription-nudge" />} */}
				<OrgInfoDisplay
          getAdminOrgProfile={getAdminOrgProfile}
        />
				<Box id="organization-team-edit" mt='8em'>

					<Flex alignItems='center' justify='space-between'>
						<Flex justify={['flex-start', 'center', 'center', 'center']} alignItems={['flex-start', 'center', 'center', 'center']} direction={['column', 'row', 'row', 'row']}>
							<Box className="user-profile-section__first-label"> Team </Box>
						</Flex>

						<AddOrgMemberModule orgIdToken={orgIdToken} subscriptionReminder={subscriptionReminder}/>

					</Flex>

					<Divider mt='0.5em'/>
					<OrgProfileTeamModule
						isAdmin={isAdministrator}
						orgIdToken={orgIdToken}
						orgName={orgName}
						orgAdminUsers={orgAdminUsers}
						orgBasicUsers={orgBasicUsers}
					/>

				</Box>

			</Flex>
		)
	}

	if (!noOrgFound) {
		return (
			<Flex w='100%' h='70rem' alignItems='center' justify='center' height='50%' my='3%'>
				<Spinner
					thickness="5px"
					speed="0.65s"
					emptyColor="gray.200"
					color="brand.500"
					boxSize="5em"
				/>

				{preloadImages()}
			</Flex>
		)
	} else return null;
}