import React from 'react';

import { Box, Flex } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import WarningTextMessage from '../../../global/components/helper-components/components/WarningTextMessage';
import LaunchdeckButton from '../../../global/components/helper-components/components/LaunchdeckButton';
import FormTextInput from '../../../forms/TextInputField/components/FormTextInput';

export default function PublicDsrJoinRoomLoginForm({ handleLoginJoinRoom, onChangeHandler, showPasswordError, showIncorrectPasswordError, greetingName }) {
	const welcomeMessage = greetingName ? `Welcome back, ${greetingName}` : 'Welcome back';

	return (
		<Box w='100%'>
			<Box fontSize='1em' color='brandDark.500' fontWeight='600' my='1em' textAlign='center'>
				{welcomeMessage}
			</Box>


			<Box w="100%" align="center">
				<Box px='1em' mt='1em'>

					<Box mt='1em' w='100%'>
						<FormTextInput
							name={'login-password-input'}
							placeholder={'Your password here'}
							onChange={onChangeHandler}
							mb='0.2em'
							borderColor={showPasswordError ? 'pink.500' : ""}
							type='password'
						/>
						<Flex w='100%' justify='center'>
							<WarningTextMessage errorLogic={showPasswordError} message='The password is too short. Please check and try again.' />
						</Flex>
					</Box>
				</Box>
				{
					showIncorrectPasswordError && <Flex w='100%' justify='center'>
						<WarningTextMessage errorLogic={showIncorrectPasswordError} message='We are unable to authenticate your login with this password' />
					</Flex>
				}

				<Flex w='100%' mt='2em' px='1em' py='0.625em'>
					<Flex justify='flex-end' w='100%'>
						<LaunchdeckButton bg='brand.500' color='gray.50' w='100%' label='Log in' onClick={() => handleLoginJoinRoom()} />
					</Flex>
				</Flex>
				{showIncorrectPasswordError &&
					<Link to="/reset-password">
						<Flex w='100%' px='1em' py='0.625em'>
							<Flex justify='flex-end' w='100%'>

								<LaunchdeckButton bg='gray.500' color='white' w='100%' label='Forget password' />

							</Flex>

						</Flex>
					</Link>

				}

			</Box>
		</Box>

	);
};


