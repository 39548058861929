import React from 'react';
import { Flex, Box, Image } from "@chakra-ui/react";
import UnavailableImage from '../styles/images/unavailable.png';
import LaunchdeckButton from './LaunchdeckButton';
import { redirectToNewPage } from '../../../helpers/global-helpers';


export default function CurrentlyUnavailablePage() {

	return (

		<Flex direction="column" align="center" bg='white' p='2em 3%' mb='2em' rounded='0.8em'>
			<Box fontSize='1.2em' fontWeight='600'>Room is currently unavailable  </Box>
			<Box my='1em' fontSize='0.875em' color='brandDark.500'>The administrators have put it back as a draft to make some important changes. Please check in again later.</Box>
	
			<Image src={UnavailableImage} alt="Unavailable room" my='3em'/>

			<Flex direction='column' mt='1em' justify='center' alignItems='center'>
				<Flex justify={['center', 'flex-end', 'flex-end', 'flex-end']} w='100%'>
					<LaunchdeckButton bg='brand.500' color='white' onClick={() => redirectToNewPage('/home')} label='Back' />
				</Flex>
			</Flex>
		</Flex>

		
	)
}

