import React, { useState, useEffect } from 'react';
import { Box, Flex, Grid } from "@chakra-ui/react";
import VaultEmptyState from './VaultEmptyState';
import DsrCardDisplay from '../dashboard/components/vault/DsrCardDisplay';

import PageLoader from '../global/components/helper-components/components/PageLoader';
import './styles/vault-display.scss'
import { getThisUserIdToken } from '../global/helpers/global-helpers';
import ProspectDsrCardDisplay from '../dashboard/components/vault/ProspectDsrCardDisplay';


export default function VaultDisplaySearchResultsModule(props) {
  const {
    vaultSearchResults,
    vaultSearchIsProcessing,
    itemsPerPage,
    chosenSortValue,
    chosenFilterValue,
    chosenStatusTab,
    resetAllFilterValues,
    toggleFilter,
    setDisplaySearchResults,
    setSearchInputValue,
    isReceivedRoomsPage,
    isPdsrDisplayMode
  } = props || {};
  const [imageClassName, setImageClassName] = useState('image-loading__in-progress')
  const [displayedDsrs, setDisplayedDsrs] = useState("")
  const [totalPages, setTotalPages] = useState(1)

  const [searchResultsPageNumber, setSearchResultsPageNumber] = useState(1)

  const { searchTerms, allFoundDsrs, foundDsrsByDsrTitle, foundDsrsByMemberName, foundDsrsByOrgName } = vaultSearchResults || {};


  const toggleSearchResultsFilter = (filterValue, pageNumber) => {

    if (filterValue) {
      switch (filterValue) {
        case ('search-title'): {

          setDisplayedDsrs(foundDsrsByDsrTitle)
          break;
        }
        case ('search-member-name'): {

          setDisplayedDsrs(foundDsrsByMemberName)
          break;
        }
        case ('search-org-name'): {

          setDisplayedDsrs(foundDsrsByOrgName)
          break;
        }
        case ('all-results'): {

          setDisplayedDsrs(allFoundDsrs)
          break;
        }
        default: {

          setDisplayedDsrs(allFoundDsrs)
          break;
        }
      }

      // setFilterValue(filterValue)
      setSearchResultsPageNumber(pageNumber)
    }
  }

  const handlePageButtonClick = (filterValue, pageNumber) => {
    toggleSearchResultsFilter(filterValue, pageNumber)
  }

  const handleClearRoomsSearchResults = () => {
    toggleFilter("initialize", 1, "default")
    setDisplaySearchResults(false)
    setSearchInputValue("")
    resetAllFilterValues()
  }

  useEffect(() => {
    const filterSearchedDsrs = (arrayOfObjects) => {
      let sortedArray = arrayOfObjects;
     
      // Sorting
      if (chosenSortValue === "Latest") {
      
        sortedArray = sortedArray.sort((a, b) => {
          return b.updatedAt - a.updatedAt
        })
      } else if (chosenSortValue === "Room name (A - Z)") {
     
        sortedArray = sortedArray.sort((a, b) => {
          const titleA = a.dsrTitle.toUpperCase(); // ignore upper and lowercase
          const titleB = b.dsrTitle.toUpperCase(); // ignore upper and lowercase
          if (titleA < titleB) {
            return -1;
          }
          if (titleA > titleB) {
            return 1;
          }
    
          // names must be equal
          return 0;
        });

      } else if ( chosenSortValue === "Room name (Z - A)") {
        sortedArray = sortedArray.sort((a, b) => {
          const titleA = a.dsrTitle.toUpperCase(); // ignore upper and lowercase
          const titleB = b.dsrTitle.toUpperCase(); // ignore upper and lowercase
          if (titleA > titleB) {
            return -1;
          }
          if (titleA < titleB) {
            return 1;
          }
    
          // names must be equal
          return 0;
        });
      }

      // Filtering by ownership
      if (chosenFilterValue  === "All by me") {
        const thisUserId = getThisUserIdToken();
        sortedArray = sortedArray.filter(dsr => dsr.dsrOwnerId === thisUserId)
      }

      // Filtering by DSR status 
      if (chosenStatusTab==="drafts") {
        sortedArray = sortedArray.filter(dsr => (dsr.status === "draft" || dsr.status === "reverted-draft"))
      } else if (chosenStatusTab==="published") {
        sortedArray = sortedArray.filter(dsr => (dsr.status === "active"))
      }

      if (Array.isArray(sortedArray)) return sortedArray
      else return [];
    }

    const filterSearchedReceivedDsrs = (arrayOfObjects) => {
      let sortedArray = arrayOfObjects;
     
      // Sorting
      if (chosenSortValue === "Latest") {
      
        sortedArray = sortedArray.sort((a, b) => {
          return b.updatedAt - a.updatedAt
        })
      } else if (chosenSortValue === "Room name (A - Z)") {
     
        sortedArray = sortedArray.sort((a, b) => {
          const titleA = a.dsrTitle.toUpperCase(); // ignore upper and lowercase
          const titleB = b.dsrTitle.toUpperCase(); // ignore upper and lowercase
          if (titleA < titleB) {
            return -1;
          }
          if (titleA > titleB) {
            return 1;
          }
    
          // names must be equal
          return 0;
        });

      } else if ( chosenSortValue === "Room name (Z - A)") {
        sortedArray = sortedArray.sort((a, b) => {
          const titleA = a.dsrTitle.toUpperCase(); // ignore upper and lowercase
          const titleB = b.dsrTitle.toUpperCase(); // ignore upper and lowercase
          if (titleA > titleB) {
            return -1;
          }
          if (titleA < titleB) {
            return 1;
          }
    
          // names must be equal
          return 0;
        });
      }

      if (Array.isArray(sortedArray)) return sortedArray
      else return [];
    }

    if (vaultSearchResults && itemsPerPage) {

      const { allFoundDsrs } = vaultSearchResults || {};
      let filteredDsrs = allFoundDsrs;


      filteredDsrs = isReceivedRoomsPage ? filterSearchedReceivedDsrs(allFoundDsrs) : filterSearchedDsrs(allFoundDsrs);
      const totalFoundDsrsFiltered = Array.isArray(filteredDsrs) ? filteredDsrs?.length : 0;
      setTotalPages(Math.ceil(parseInt(totalFoundDsrsFiltered) / itemsPerPage))
      setDisplayedDsrs([...filteredDsrs])
    }
  }, [isReceivedRoomsPage, vaultSearchResults, itemsPerPage, chosenSortValue, chosenFilterValue, chosenStatusTab])



  function handleImageLoaded() {
    setImageClassName("image-loading__completed");
  }

  const renderPaginationButtons = () => {
    if (totalPages > 1) {
      const maxButtonsDisplayed = 5;
      let nextNumber = 0;
      let buttonTextArray = [];

      const lowerBound = (maxButtonsDisplayed > totalPages) ? totalPages : maxButtonsDisplayed;
      const includeTruncateButton = (maxButtonsDisplayed > totalPages) ? false : true;

      while (lowerBound > nextNumber) {
        buttonTextArray = [...buttonTextArray, nextNumber + 1]
        nextNumber++;
      }

      if (Array.isArray(buttonTextArray)) {
        const renderedButtons = buttonTextArray?.map((element, index) => {
          const pageNumber = index + 1;
          return (
            <Box key={`page-button-${index}`}
              className={searchResultsPageNumber === pageNumber ? "chosen-page-button" : "unchosen-page-button"}
              onClick={() => handlePageButtonClick("all-results", pageNumber)}>
              {element}
            </Box>
          )
        })

        return <Flex w='100%' justify='flex-end' mt='1em'>
          <Flex>
            {(searchResultsPageNumber > 1) &&
              <Box className="unchosen-page-button" onClick={() => handlePageButtonClick("all-results", searchResultsPageNumber - 1)}> {`<`} </Box>
            }
            {renderedButtons}
            {includeTruncateButton && <Box>...</Box>}
            {(searchResultsPageNumber < nextNumber) &&
              <Box className="unchosen-page-button" onClick={() => handlePageButtonClick("all-results", searchResultsPageNumber + 1)}> {`>`} </Box>
            }
          </Flex>

        </Flex>;
      }
    } else return null;

  }

  const renderDsrCards = () => {
    const firstIndex = (searchResultsPageNumber - 1) * itemsPerPage;
    const paginatedArray = displayedDsrs.slice(firstIndex, firstIndex + itemsPerPage)

    return (
      <Grid templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)', 'repeat(2, 1fr)', 'repeat(3, 1fr)']} gap={12} w='100%'>
        {paginatedArray.map((dsr) => {
          return (
            <Box key={`dsr-card-${dsr.dsrId}`} className='flip-in-hor-bottom'>
              <DsrCardDisplay dsrData={dsr} />
            </Box>
          )
        })}
      </Grid>
    )
  }

  const renderProspectDsrCards =() => {
    const firstIndex = (searchResultsPageNumber - 1) * itemsPerPage;
    const paginatedArray = displayedDsrs.slice(firstIndex, firstIndex + itemsPerPage)

    return (
      <Grid templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)', 'repeat(2, 1fr)', 'repeat(3, 1fr)']} gap={12} w='100%'>
        {paginatedArray.map((dsr) => {
          return (
            <Box key={`dsr-card-${dsr.pDsrId}`} className='flip-in-hor-bottom'>
              <ProspectDsrCardDisplay dsrData={dsr} />
            </Box>
          )
        })}
      </Grid>
    )
  }

  if (vaultSearchIsProcessing === true || !displayedDsrs) {
    return <PageLoader />
  } else if (displayedDsrs?.length > 0) {

    return (
      <React.Fragment>
        <Flex w='100%' justify='flex-start' align='center'>
          <Box fontWeight='600' fontSize='1.1em' color='brandDark.500'>SEARCH RESULTS</Box>
          {isReceivedRoomsPage ? 
           <Box className='clear-search-button' onClick={()=>{
            toggleFilter(1, "default")
            setDisplaySearchResults(false)
            setSearchInputValue("")
            }}>Clear</Box>
            :
          <Box className='clear-search-button' onClick={()=>{
            toggleFilter("initialize", 1, "default")
            setDisplaySearchResults(false)
            setSearchInputValue("")
            }}>Clear</Box>
          }
        </Flex>
        {isPdsrDisplayMode ? renderProspectDsrCards() : renderDsrCards()}
        {renderPaginationButtons()}
      </React.Fragment>

    )
  } else if (displayedDsrs?.length === 0) {
    return (
      <React.Fragment>
        <VaultEmptyState 
        displayType="no-search-results" 
        handleImageLoaded={() => handleImageLoaded()} 
        imageClassName={imageClassName} 
        copyText={searchTerms ? `No search results`: "Nothing found"} 
        searchTerms={searchTerms}
        chosenFilterValue={chosenFilterValue}
        chosenStatusTab={chosenStatusTab}
        handleClearRoomsSearchResults={handleClearRoomsSearchResults}
        />
        {renderPaginationButtons()}
      </React.Fragment>
    )
  } else return null

}
