import React from 'react';

import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay
} from "@chakra-ui/react";
import LaunchdeckCloseButton from '../../../global/components/helper-components/components/LaunchdeckCloseButton';
import { LuReplace } from 'react-icons/lu';
import LaunchdeckButton from '../../../global/components/helper-components/components/LaunchdeckButton';

export default function RemoveOverviewVideoConfirmationModal(props) {

  const { handleCloseModal, handleRemoveVideoOverviewPage} = props || {};

  const handleClickCtaButton = () => {
    handleRemoveVideoOverviewPage('removeLoomWelcomeVideo', "")
    handleCloseModal()
  }


  return (
    <Modal isOpen={true} closeOnOverlayClick={false} isCentered={true} >
      <ModalOverlay className="modal-background" />
      <ModalContent rounded='0.25em' minW={['90%', '500px']} maxW='700px'>
        <ModalBody px='0em'>
          <Flex direction='column' justify='space-around' alignItems='center' w='100%'>
           
              <React.Fragment>
          
              <Flex justify='flex-end' align='center' w='100%' >
                {/* <Flex w='100%' p='1em'  justify='flex-start' align='center' whiteSpace="pre">
                  Remove Loom Video?
                </Flex> */}
              <Flex justify='flex-end' w='100%' pr='1em'><LaunchdeckCloseButton handleCloseModal={handleCloseModal} /></Flex>
      </Flex>
            <Flex w='100%' justify='center' align='center' p='1em' height='50vh'  direction='column'>
              <Box as={LuReplace} mb='2em' boxSize='4em' />
          
         
            <Box  alignSelf="stretch"
            color='brandDark.500'
            textAlign='center'
            fontWeight='400'
            lineHeight='1.5em'
            fontSize='0.875em'
            mb='1em'>
              Remove this video and record a new one?
            </Box>

            <LaunchdeckButton
                    bg='brand.500' color='white'
                    onClick={() => handleClickCtaButton()}
                    label='Remove video' />
            {/* <Box className='tutorial-button' width='max-content' onClick={()=>handleClickCtaButton()}>
              Remove Video
            </Box> */}
            </Flex>
              </React.Fragment>


          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

