import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'

import { Box , Flex, Modal, ModalBody, ModalContent, ModalOverlay, useToast } from '@chakra-ui/react'
import '../styles/dsr-display.scss'

import { copyToUserClipboard, triggerLaunchdeckToast } from '../../global/helpers/global-helpers';
import config from "../../../config/app-config";
import LaunchdeckButton from '../../global/components/helper-components/components/LaunchdeckButton'
import {QRCodeSVG} from "qrcode.react";
import LaunchdeckCloseButton from '../../global/components/helper-components/components/LaunchdeckCloseButton'

function ShareProspectDsrModal(props) {

  const { pDsrId, handleCloseModal, dsrStatusRedux, isMobileView } = props || {};
  const toast = useToast();
  // UX Management

  const renderCopyLinkText = () => {
    let copyLinkText = "";

    if (dsrStatusRedux === "active") {
      copyLinkText = `${config.displayUrl}/pdsr/${pDsrId}`;

    } else {
      copyLinkText = "Link has been deactivated as this Room is not yet published"
    }

    return copyLinkText;
  }

  const handleCopyLink = () => {
    if (pDsrId) {
      copyToUserClipboard(`${config.displayUrl}/pdsr/${pDsrId}`)

      toast(
        triggerLaunchdeckToast({
        useCase: "show-success-state",
        // bannerText: "",
        label: "Share link copied", 
        isMobileView
        })
      )
    }

    if (!pDsrId) {
      toast(
        triggerLaunchdeckToast({
        useCase: "show-error-state",
        bannerText: "Error copying link",
        label: "Unfortunately something went wrong. Please try again later.", 
        isMobileView
        })
      )
    }

  }

  const renderCopyInviteLinkButton = () => {
    if (dsrStatusRedux === "active") {
      return (
        // <Flex color='brand.500' cursor='pointer' align='center' onClick={() => {
        //   handleCopyLink()
        //   handleCloseModal()
        // }}>
        //   <Box as={IoCopyOutline} boxSize='1em' ml='1em' />
        // </Flex>

<Flex direction='column' my='1em' justify='center' alignItems='center'>
<Flex justify={['center', 'flex-end', 'flex-end', 'flex-end']} w='100%'>
  <LaunchdeckButton bg='brand.500' color='white' onClick={() => {
          handleCopyLink()
          handleCloseModal()
        }} label='Copy link' />

</Flex>
</Flex>
      )
    } else return null;
  }

  function displayContent() {
      return (
        <Flex justify='flex-start' px='1.5em' mt='1em' w='100%'>
          <Box w='100%'>

            <Box className='invite-link-container truncate-long-text' mt='0.5em' p='0.5em'>
                {renderCopyLinkText()}
            </Box>
            {renderCopyInviteLinkButton()}
            <Box display='flex' flexDirection='column' width='100%' justifyContent='center' alignItems='center' mb='2em'>
              <Box mb='1em'>or</Box>
              <QRCodeSVG value={`${config.displayUrl}/pdsr/${pDsrId}`} />
            </Box>
          </Box>

       
        </Flex>
      )
    
  }

  return (
    <Modal isOpen={true} closeOnOverlayClick={false} isCentered={true} >
      <ModalOverlay className="modal-background" />
      <ModalContent rounded='0.25em' minW={['90%', '500px']} maxW='700px'>
        <ModalBody px='0em'>
          <Flex direction='column' justify='space-around' alignItems='center' w='100%'>
            <Flex justify='space-between' w='100%'>
              <Flex px='1em' justify='flex-start' align='center'>
                <Box p='0.5em' color='brandDark.500'>Share it with others</Box>
              </Flex>

              <Box pr='1.5em'>
                <LaunchdeckCloseButton handleCloseModal={() => handleCloseModal()} />
              </Box>
            </Flex>

            <React.Fragment>
              {displayContent()}
            </React.Fragment>

          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

function mapStateToProps(state) {
  return {
  dsrStatusRedux: state.dsrStatus.status,
  isMobileView: state.mobileViewMode.isMobileView
  };
}

function mapDispatchToProps(dispatch) {
  return {
    action: bindActionCreators(
      {
      
      },
      dispatch
    )
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ShareProspectDsrModal));
