import * as types from './users-self-setup-action-types';
import { axiosInstance } from '../../../config/app-specific-config/axiosConfig';



export const userSelfSetupRequest = () => {
	return {
		type: types.SELF_SETUP_NEW_ORG_USER_REQUEST
	};
};

export const userSelfSetupSuccess = (response) => {
	return {
		type: types.SELF_SETUP_NEW_ORG_USER_SUCCESS,
		payload: response.data
	};
};

export const userSelfSetupFailure = (error) => {
	return {
		type: types.SELF_SETUP_NEW_ORG_USER_FAILURE,
		error
	};
};

export function userSelfSetup(setupMetadata) {
	
	return async (dispatch) => {
		dispatch(userSelfSetupRequest());
		try {
			const url = `/organizations/setup-pending-user`;

			const response = await axiosInstance.post(
				url,
				{setupMetadata},
				{ }
			);

			dispatch(userSelfSetupSuccess(response));

		} catch (error) {
			dispatch(userSelfSetupFailure(error));
		}
	};
}


export const userSelfSetupReset = () => {
	return {
		type: types.SELF_SETUP_NEW_ORG_USER_RESET
	};
};

export function resetUserSelfSetup() {

	return async (dispatch) => {
	
		try {
			dispatch(userSelfSetupReset());

		} catch (error) {
			dispatch(userSelfSetupFailure(error));
		}
	};
}
