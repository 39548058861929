import React, { useEffect, useRef, useState } from 'react'
import { VideoButton } from './VideoButton'
import { withTooltipWrapper } from './TooltipWrapper'
import { ConditionalWrapper } from './ConditionalWrapper'
import { useLoomContext } from './LoomContext'
import { loomRecordButtonStates, loomSdkStates } from '../../../config/app-specific-config/constants';

import '../../digital-sales-room/styles/loom.scss';
import { Box } from '@chakra-ui/react'

export const DsrLoomRecordButton = ({  submitRecording, handleCreateNewPost, handleSaveVideoToSubWidget, handleSaveVideoToOverviewPage, displayClassName }) => {
  const recordButtonRef = useRef()
  const DEFAULT_BUTTON_TEXT = loomRecordButtonStates.record

  const {configureButton, sdkState, initialized, isLoomSupported} = useLoomContext()
  const [buttonText, setButtonText] = useState(sdkState)


  useEffect(() => {
    if (!initialized) return
    if (!isLoomSupported) return

    void applyConfig()
  }, [initialized, isLoomSupported])

  useEffect(() => {
    sdkState !== loomSdkStates.loaded ? setButtonText(sdkState) : setButtonText(DEFAULT_BUTTON_TEXT)
  }, [sdkState])

  const applyConfig = async () => {
    const loomButton = configureButton?.({ element: recordButtonRef.current })

    const onRecStart = () => setButtonText(loomRecordButtonStates.recording)
    const onCancel = () => setButtonText(DEFAULT_BUTTON_TEXT)
    const onRecComplete = () => setButtonText(loomRecordButtonStates.uploading)
    const onUploadComplete = async (video) => {
      await submitRecording(video) // this video SHOULD have a thumbnail
      setButtonText(DEFAULT_BUTTON_TEXT)
    }
    const onInsertClick = (video) => {
      if(!video) return

      if (!!handleSaveVideoToSubWidget) {
        handleSaveVideoToSubWidget([video])
      }

      if (!!handleCreateNewPost) {
        const videoCreateData = {
          userActionRequired: "create-video-post",
          inputValue: "",
          postType: "loom-video-post",
          loomVideo: video,
        }
        handleCreateNewPost(videoCreateData)
      }
 
      if (!!handleSaveVideoToOverviewPage) {
        handleSaveVideoToOverviewPage("loomWelcomeVideo", {loomWelcomeVideo: video})
      }
    }
    buttonConfig({loomButton, onRecStart, onCancel,  onRecComplete, onUploadComplete, onInsertClick })
  }

  const buttonConfig = ({loomButton, onRecStart, onCancel, onRecComplete, onUploadComplete, onInsertClick}) => {
    loomButton.on('recording-start', onRecStart)
    loomButton.on('cancel', onCancel)
    loomButton.on('recording-complete', onRecComplete)
    loomButton.on('upload-complete', onUploadComplete)
    loomButton.on("insert-click", onInsertClick)
  }

  useEffect(() => {
    return () => {
      if (!configureButton) return
      configureButton({ element: null })
    }
  }, [])

  if (displayClassName==="create-post-button") {
    return (
      <>
        <ConditionalWrapper condition={buttonText === loomSdkStates.recordingNotSupported} wrapper={withTooltipWrapper}>
          <Box className={displayClassName}>

          <VideoButton
            label={buttonText}
            ref={recordButtonRef}
            disabled={buttonText === loomSdkStates.recordingNotSupported}
            mode={"create-post-button"}
          />
          </Box>
        </ConditionalWrapper>
      </>
    )
  }

  else {
    return (
      <>
        <ConditionalWrapper condition={buttonText === loomSdkStates.recordingNotSupported} wrapper={withTooltipWrapper}>

          <VideoButton
            label={buttonText}
            ref={recordButtonRef}
            disabled={buttonText === loomSdkStates.recordingNotSupported}
          />
        </ConditionalWrapper>
      </>
    )
  }
}
