import { Box, Flex, CircularProgress, CircularProgressLabel } from '@chakra-ui/react';
import {resetUploadProgressMetric} from '../../digital-sales-room/actions/dsr-actions';
import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

function FileUploadProgress({ percentProcessed, showLoader, resetUploadProgressMetric }) {
  const [showFinalizing, setShowFinalizing] = useState(false)

  useEffect(() => {
    
		if (percentProcessed > 0) {
      if (percentProcessed === 100) {
        resetUploadProgressMetric()
        setShowFinalizing(true)
      } 
		}

  }, [percentProcessed, resetUploadProgressMetric]);

  if (showLoader) {
    return (
      <Flex direction='column' align='center' justify='center' bg='white'>
        <CircularProgress isIndeterminate color='brand.500' size='3em' thickness='4px'>
          <CircularProgressLabel fontSize='0.5em' color='gray.700'>{!showFinalizing && `${percentProcessed}%`}</CircularProgressLabel>
        </CircularProgress>
        <Box mt='1em' fontSize='0.875em' color='brandDark.500'>{showFinalizing ? "Finalizing..." :'Working on it ...'}</Box>
      </Flex>
    );
  } else {
    return null;
  }
  
};

const mapStateToProps = (state) => {
  return {
    percentProcessed: state.fileUploadPercentProcessed.percentProcessed
  };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
      {
        resetUploadProgressMetric
      },
      dispatch,
    )


export default connect(mapStateToProps, mapDispatchToProps)(FileUploadProgress);
