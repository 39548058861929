import React from 'react';
import { Box } from "@chakra-ui/react";


export default function LaunchdeckTag(props) {
	
	const { label, w, width, px, border, borderColor, textTransform, bg, fontSize, color, fontWeight, onClick } = props;
	const isFullWidth = (width==='100%' || w==='100%') ? true : false;

	return (
		<Box
			display={!isFullWidth && 'inline-flex'}
			px={px ? px : "1em"}
			py='0.4em'
			rounded='18.75em'
			fontSize={fontSize ? fontSize : "0.65em"}
			bg={bg ? bg : "#f5f6f7"}
			border={border && border}
			borderColor={borderColor ? borderColor : "#ccd0d5"}
			color={color ? color : "#4b4f56"}
			textTransform={textTransform ? textTransform : ''}
			w={width ? width : w ? w : ''}
			fontWeight={fontWeight ? fontWeight : '400'}
			justify='center'
			alignItems='center'
			whiteSpace='pre'
			onClick={onClick && onClick}
			cursor={onClick ? 'pointer' : 'default'}
		>
			{label ? label : props.children}		
		</Box>

	)
	
}
