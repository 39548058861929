import React from "react";

import { Box } from "@chakra-ui/react";
import EditDsrPostInputModal from './EditDsrPostInputModal';
import DsrEditReplyModal from './DsrEditReplyModal';
import DsrManageTeamModal from '../dsr-team-mgmt/DsrManageTeamModal';
import CreateDsrPostInputModal from "./CreateDsrPostInputModal";
import DsrLoomRecordModal from "../dsr-record-content/DsrLoomRecordModal";
import DsrRevertToDraftModal from "../dsr-general-mgmt/DsrRevertToDraftModal";
import DsrRepublishModal from "../dsr-general-mgmt/DsrRepublishModal";
import DsrPublishModal from "../dsr-general-mgmt/DsrPublishModal";
import ConfirmDeleteFaqAnswerModal from "../dsr-content-components/dsr-faq/ConfirmDeleteFaqAnswerModal";
import DsrConfirmRemoveMemberModal from "../dsr-general-mgmt/DsrConfirmRemoveMemberModal";
import ConfirmDeleteFaqQuestionModal from "../dsr-content-components/dsr-faq/ConfirmDeleteFaqQuestionModal";
import DsrDeleteModal from "../dsr-general-mgmt/DsrDeleteModal";
import ManageDsrLogoModal from "../dsr-draft/ManageDsrLogoModal";
import ProductMarketingNudgeModal from "../../marketing/components/ProductMarketingNudgeModal";
import ProspectDsrPublishModal from "../dsr-prospecting/draft-prospect-dsr/ProspectDsrPublishModal";
import ProspectDsrRepublishModal from "../dsr-prospecting/draft-prospect-dsr/ProspectDsrRepublishModal";
import ProspectDsrUnpublishModal from "../dsr-prospecting/draft-prospect-dsr/ProspectDsrUnpublishModal";
import ProspectDsrDeleteModal from "../dsr-prospecting/ProspectDsrDeleteModal";
import SignupLoginModal from "../dsr-content-components/public-dsr/SignupLoginModal";
import ShareProspectDsrModal from "../dsr-prospecting/ShareProspectDsrModal";
import DsrPreviewPageActionModal from "../dsr-general-mgmt/DsrPreviewPageActionModal";
import SubscriptionNudgeModal from "../../marketing/components/SubscriptionNudgeModal";
import LoggedInUserJoinRoomModal from "../dsr-content-components/public-dsr/LoggedInUserJoinRoomModal";
import SwitchDemoToOrgAdminUserModal from "../../global/components/product-marketing/SwitchDemoToOrgAdminUserModal";
import DuplicateDsrModal from "../../dashboard/components/vault/DuplicateDsrModal";
import DsrMassManageCustomPagesModal from "../dsr-general-mgmt/DsrMassManageCustomPagesModal";
import EditSharedDocModal from "../dsr-shared-docs/EditSharedDocModal";
import CreateDsrTemplateModal from "../../dashboard/components/vault/CreateDsrTemplateModal";


export default function DsrDynamicModal({
  userActionRequired,
  modalTransferPayload,
  showDsrModal,
  showDsrModalLoader,
  handleDsrModalClose, 
  postContentToEdit,
  postTagProperties,
  handleUpdatePostContent,
  handleEditPostReply,
  handleEditTeam,
  handleCreateNewPost,
  // handleManagePageAccessibility,
  // Content
  dsrId,
  // dsrStatus,
  pDsrId,
  // dsrTeamMembers,
  dsrPages,
  // Post management
  postId,
  replyId,
  // Q&A management
  questionId,
  // UX Management
  handleChangeUserActionRequired,
  editDsrFeedReplyOutcome,
  handleStopDsrModalLoader,
  showPostUploadImageOption
}) {

  const displayModalContent = () => {
    switch (userActionRequired) {
      // Creating
      case ('create-new-post'): {
        if (showDsrModal === true) {
          return (
           <CreateDsrPostInputModal
             handleCloseModal={handleDsrModalClose} 
             handleCreateNewPost={handleCreateNewPost}
             userActionRequired={userActionRequired}
             showDsrModalLoader={showDsrModalLoader}
             showPostUploadImageOption={showPostUploadImageOption}
             postTagProperties={postTagProperties}
             dsrId={dsrId}
           />
          )
        } else return null;
      }
      case ('prepare-faq-question-in-draft'): {
        if (showDsrModal === true) {
          return (
            // To do: Consider using a separate modal since it is not creating a new post
            <CreateDsrPostInputModal
              handleCloseModal={handleDsrModalClose}
              handleCreateNewPost={handleCreateNewPost}
              userActionRequired={userActionRequired}
            />
          )
        } else return null;
      }
      case ('create-faq-question'): {
        if (showDsrModal === true) {
          return (
            <CreateDsrPostInputModal
              handleCloseModal={handleDsrModalClose}
              handleCreateNewPost={handleCreateNewPost} // TODO:  Consider changing this to creating a new Q&A question, with automated features
              userActionRequired={userActionRequired}
            />
          )
        } else return null;
      }
      // Updating
      case ('edit-dsr-message-post'): {
        if (showDsrModal === true) {
          
          return (
            <EditDsrPostInputModal
              postId={postId}
              handleCloseModal={handleDsrModalClose}
              postContentToEdit={postContentToEdit}
              handleUpdatePostContent={handleUpdatePostContent}
              handleStopDsrModalLoader={handleStopDsrModalLoader}
              dsrId={dsrId}
              postTagProperties={postTagProperties}
              showDsrModalLoader={showDsrModalLoader}
            />
          )
        } else return null;
      }
      case ('edit-dsr-reply'): {

        if (showDsrModal === true) {
          return (
            <DsrEditReplyModal
              handleCloseModal={handleDsrModalClose}
              postContentToEdit={postContentToEdit}
              handleEditPostReply={handleEditPostReply}
              dsrId={dsrId}
              postId={postId}
              replyId={replyId}
              editDsrFeedReplyOutcome={editDsrFeedReplyOutcome}
              handleStopDsrModalLoader={handleStopDsrModalLoader}
              showDsrModalLoader={showDsrModalLoader}
            />
          )

        } else return null;
      }
      case ('view-team-members'): {
        if (showDsrModal === true) {
          return (
            <DsrManageTeamModal
              dsrId={dsrId}
              handleCloseModal={handleDsrModalClose}
              // dsrTeamMembers={dsrTeamMembers}
              handleEditTeam={handleEditTeam}
              handleChangeUserActionRequired={handleChangeUserActionRequired}
            />
          )
        } else return null;
      }
      case ('revert-dsr-to-draft'): {
        return (
          <DsrRevertToDraftModal
            dsrId={dsrId}
            handleCloseModal={handleDsrModalClose}
          />
        )
      }
      case ('re-publish-dsr'): {
        return (
          <DsrRepublishModal
            dsrId={dsrId}
            dsrPages={dsrPages}
            handleCloseModal={handleDsrModalClose}
          />
        )
      }
      case ('publish-dsr'): {
        return (
          <DsrPublishModal
            dsrId={dsrId}
            dsrPages={dsrPages}
            handleCloseModal={handleDsrModalClose}
            handleChangeUserActionRequired={handleChangeUserActionRequired}
          />
        )
      }
      case ('publish-prospect-dsr'): {
        return (
          <ProspectDsrPublishModal
            pDsrId={pDsrId}
            dsrPages={dsrPages}
            handleCloseModal={handleDsrModalClose}
          />
        ) 
      }
      case ('unpublish-prospect-dsr'): {
        if (showDsrModal === true) {
          return (
            <ProspectDsrUnpublishModal
              pDsrId={pDsrId}
              handleCloseModal={handleDsrModalClose}
            />
          )
        } else return null;
      }
      case ('re-publish-prospect-dsr'): {
        if (showDsrModal === true) {
          return (
            <ProspectDsrRepublishModal
              pDsrId={pDsrId}
              dsrPages={dsrPages}
              handleCloseModal={handleDsrModalClose}
            />
          )
        } else return null
      }
      case ('delete-prospect-dsr'): {
        if (showDsrModal === true) {
          return (
            <ProspectDsrDeleteModal
            pDsrId={pDsrId}
            handleCloseModal={handleDsrModalClose}
          />
          )
        } else return null
      }
      case ('delete-dsr'): {
        if (showDsrModal) {
          return (
            <DsrDeleteModal
              dsrId={dsrId}
              handleCloseModal={handleDsrModalClose}
            />
          )
        } else return null;
      }
      case ('create-new-reply'): {
        // Replies are not managed through modals
        break;
      }
      case ('create-video-post'): {
        return <DsrLoomRecordModal
          handleCloseModal={handleDsrModalClose}
          handleCreateNewPost={handleCreateNewPost}
          userActionRequired={userActionRequired}
          showDsrModalLoader={showDsrModalLoader}
        />
      }
      case ('confirm-delete-faq-answer'): {
        if (showDsrModal === true) {
          return (
            <ConfirmDeleteFaqAnswerModal
              dsrId={dsrId}
              questionId={questionId}
              handleCloseModal={handleDsrModalClose}
            />
          )
        } else return null;
      }
      case ('confirm-delete-faq-question'): {
        if (showDsrModal === true) {
          return (
            <ConfirmDeleteFaqQuestionModal
              dsrId={dsrId}
              questionId={questionId}
              handleCloseModal={handleDsrModalClose}
            />
          )
        } else return null;
      }
      case ('confirm-dsr-remove-user'): {
        if (showDsrModal === true) {
          return (
            <DsrConfirmRemoveMemberModal
              handleChangeUserActionRequired={handleChangeUserActionRequired}
              handleCloseModal={() => handleChangeUserActionRequired('view-team-members', null)}
              modalTransferPayload={modalTransferPayload}
            />
          )
        } else return null;
      }
      case ("manage-dsr-audience-logo"): {
        if (showDsrModal === true) {
          return (
            <ManageDsrLogoModal
              dsrId={dsrId}
              handleCloseModal={handleDsrModalClose}
            />
          )
        } else return null;
      }

      // Product Marketing
      case ('subscription-nudge'): {
        if (showDsrModal === true) {
          return (
            <SubscriptionNudgeModal
              handleCloseModal={handleDsrModalClose}
            />
          )
        } else return null;
      }
      case ('show-product-marketing-nudge'): {
        if (showDsrModal === true) {
          return (
            <ProductMarketingNudgeModal
              handleCloseModal={handleDsrModalClose}
            />
          )
        } else return null;
      }
      case ('show-signup-login-prompt'): {
        if (showDsrModal === true) {
          return (
            <SignupLoginModal
              dsrId={dsrId}
              handleCloseModal={handleDsrModalClose}
            />
          )
        } else return null;
      }
      case ('show-preview-page-action-modal'): {
        if (showDsrModal === true) {
          return (
            <DsrPreviewPageActionModal handleCloseModal={handleDsrModalClose} />
          )
        } else return null;
      }
      case('share-prospect-dsr'): {
        if (showDsrModal === true) {
          return (
            <ShareProspectDsrModal
            pDsrId={pDsrId}
            handleCloseModal={handleDsrModalClose}
          />
          )
        } else return null;
      }
      case('show-add-logged-in-user-prompt'): {
        if (showDsrModal === true) {
          return (
            <LoggedInUserJoinRoomModal
            dsrId={dsrId}
            handleCloseModal={handleDsrModalClose}
          />
          )
        } else return null;
      }
      case('switch-demo-user-to-org-admin-view'): {
        if (showDsrModal === true) {
          return (
            <SwitchDemoToOrgAdminUserModal
            dsrId={dsrId}
            handleCloseModal={handleDsrModalClose}
          />
          )
        } else return null;
      }
      case('duplicate-dsr'): {
        if (showDsrModal === true) {
          return (
            <DuplicateDsrModal
            dsrId={dsrId}
            handleCloseModal={handleDsrModalClose}
            modalTransferPayload={modalTransferPayload}
          />
          )
        } else return null;
      }
      case('make-dsr-template'): {
        if (showDsrModal === true) {
          return (
            <CreateDsrTemplateModal
            dsrId={dsrId}
            handleCloseModal={handleDsrModalClose}
            modalTransferPayload={modalTransferPayload}
          />
          )
        } else return null;
      }
      case('mass-manage-dsr-custom-pages'): {
        if (showDsrModal === true) {
          return (
            <DsrMassManageCustomPagesModal
            dsrId={dsrId}
            pDsrId={pDsrId}
            handleCloseModal={handleDsrModalClose}
          />
          )
        } else return null;
      }
      case('edit-shared-doc'): {
        if (showDsrModal === true) {
          return (
            <EditSharedDocModal
            dsrId={dsrId}
            handleCloseModal={handleDsrModalClose}
            modalTransferPayload={modalTransferPayload}
          />
          )
        } else return null;
      }
      default: {
        if (userActionRequired) {
          console.log('This user action is not valid for dynamic modal:', userActionRequired)
        }

        return null;
      }
    }
  }

  return <Box>{displayModalContent()}</Box>;



}
